import {Injectable} from '@angular/core';
import {ZonesConfigBackup, ZoneConfigData, AddonZones} from '@app/shared/annotation-shared/models/annotations.model';
import {ImageSnapshot} from '@app/shared/manage-zones-dialog/model/manager-zones.model';
import {
  selectImageSnapshot,
  selectIsCanvasReady,
  selectZones,
  selectZonesConfigBackup,
  selectZonesConfigData,
  selectPolygonZones,
  selectIsSelectedPerspectiveZone,
  selectSelectedZoneColor,
  selectSelectedZone,
  selectHasPerspectiveZone,
  selectHint,
  selectIsZonesLoading
} from '@app/shared/manage-zones-dialog/store/manager-zones.newer-selector';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import * as actions from '../store/manager-zones.actions';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {TriggerPoint} from '@app/shared/trigger-point/trigger-point.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerZonesStoreFacadeService {
  private takeSnapshot: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public takeSnapshot$: Observable<string> = this.takeSnapshot.asObservable();
  public zonesConfigData$: Observable<ZoneConfigData> = this.store.pipe(select(selectZonesConfigData));
  public zonesConfigBackup$: Observable<any> = this.store.pipe(select(selectZonesConfigBackup));
  public isCanvasReady$: Observable<boolean> = this.store.pipe(select(selectIsCanvasReady));
  public imageSnapshot$: Observable<ImageSnapshot> = this.store.pipe(select(selectImageSnapshot));
  public zones$: Observable<Zone[]> = this.store.pipe(select(selectZones));
  public polygonZones$: Observable<Zone[]> = this.store.pipe(select(selectPolygonZones));
  public isSelectedPerspectiveZone$: Observable<boolean> = this.store.pipe(select(selectIsSelectedPerspectiveZone));
  public selectedZoneColor$: Observable<LabelColorName> = this.store.pipe(select(selectSelectedZoneColor));
  public selectedZone$: Observable<Zone> = this.store.pipe(select(selectSelectedZone));
  public hasPerspectiveZone$: Observable<boolean> = this.store.pipe(select(selectHasPerspectiveZone));
  public hint$: Observable<string> = this.store.pipe(select(selectHint));
  public isZonesLoading$: Observable<boolean> = this.store.pipe(select(selectIsZonesLoading));

  constructor(private store: Store) {}

  public watchDrawShapesOnCanvas(): void {
    this.store.dispatch(actions.watchDrawShapesOnCanvas());
  }

  public setZonesConfigData(zonesConfigData: ZoneConfigData): void {
    this.store.dispatch(actions.setZonesConfigData({payload: {zonesConfigData}}));
  }

  public setZonesConfigBackup(zonesConfigBackup: ZonesConfigBackup): void {
    this.store.dispatch(actions.setZonesConfigBackup({payload: {zonesConfigBackup}}));
  }

  public updateCurrentZonesConfigBackup(addonZones: AddonZones): void {
    this.store.dispatch(actions.updateCurrentZonesConfigBackup({payload: {addonZones}}));
  }

  public canvasIsReady(): void {
    this.store.dispatch(actions.canvasIsReady());
  }

  public canvasIsNotReady(): void {
    this.store.dispatch(actions.canvasIsNotReady());
  }

  public setImageSnapshot(imageSnapshot: ImageSnapshot): void {
    this.store.dispatch(actions.setImageSnapshot({payload: {imageSnapshot}}));
  }

  public clearImageSnapshot(): void {
    this.store.dispatch(actions.clearImageSnapshot());
  }

  public initZones(): void {
    this.store.dispatch(actions.initZones());
  }

  public clearZones(): void {
    this.store.dispatch(actions.clearZones());
  }

  public setZones(zones: Zone[]): void {
    this.store.dispatch(actions.setZones({payload: {zones}}));
  }

  public removeZone(zone: Zone): void {
    this.store.dispatch(actions.removeZone({payload: {zone}}));
  }

  public addZone(zone: Zone): void {
    this.store.dispatch(actions.addZone({payload: {zone}}));
  }

  public updateZone(property: Partial<Zone>, zoneId: string): void {
    this.store.dispatch(actions.updateZone({payload: {property, zoneId}}));
  }

  public updateZoneName(displayName: string, zoneId: string): void {
    this.store.dispatch(actions.updateZoneName({payload: {displayName, zoneId}}));
  }

  public setSelectedZoneId(zoneId: string): void {
    this.store.dispatch(actions.setSelectedZoneId({payload: {zoneId}}));
  }

  public storePhysicalDimensions(perspectiveValues: {a: number; b: number}) {
    this.store.dispatch(actions.storePhysicalDimensions({payload: {perspectiveValues}}));
  }

  public storeDisplayName(displayName: string): void {
    this.store.dispatch(actions.storeDisplayName({payload: {displayName}}));
  }

  public updateCurrentColor(color: {
    name: LabelColorName;
    background: LabelColorName;
    stroke: string;
    fill: string;
  }): void {
    this.store.dispatch(actions.updateCurrentColor({payload: {color}}));
  }

  public updateCurrentColorByZone(
    color: {name: LabelColorName; background: LabelColorName; stroke: string; fill: string},
    zone: Zone
  ): void {
    this.store.dispatch(actions.updateCurrentColorByZone({payload: {color, zone}}));
  }

  public prepareHint(shapeType: ShapeTypes, action?: string): void {
    this.store.dispatch(actions.prepareHint({payload: {shapeType, action}}));
  }

  public setHint(hint: string): void {
    this.store.dispatch(actions.setHint({payload: {hint}}));
  }

  public hideHint(): void {
    this.store.dispatch(actions.hideHint());
  }

  public setTriggerPoint(blockName: TriggerPoint, zoneId: string) {
    this.store.dispatch(actions.setTriggerPoint({payload: {blockName, zoneId}}));
  }

  public setIsZonesLoading(isZonesLoading: boolean) {
    this.store.dispatch(actions.setIsZonesLoading({payload: {isZonesLoading}}));
  }

  public toggleIn(): void {
    this.store.dispatch(actions.toggleIn());
  }

  public toggleOut(): void {
    this.store.dispatch(actions.toggleOut());
  }

  public atLeastOneInOutPositionEnabled(): void {
    this.store.dispatch(actions.atLeastOneInOutPositionEnabled());
  }

  public triggerSnapshot(id: string) {
    this.takeSnapshot.next(id);
  }
}

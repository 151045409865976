<h1 mat-dialog-title class="title">{{ 'library.dialogs.confirmDelete.title' | translate }}</h1>
<mat-dialog-content>
  {{ 'library.dialogs.confirmDelete.description' | translate }}
  <ul>
    <li *ngFor="let item of itemsToDelete">
      {{ item }}
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button data-test-id="cancel-button" color="primary" mat-button (click)="emitCancel()" tabindex="1">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <button data-test-id="confirm-button" color="warn" mat-flat-button (click)="emitConfirm()" tabindex="2">
    <mat-icon>delete</mat-icon>
    {{ 'common.delete' | translate | uppercase }}
  </button>
</mat-dialog-actions>

import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as fromFlightLogDetailDialog from './flight-log-detail-dialog.reducer';

export const flightLogDetailKey = 'flight-log-detail';

export const selectFlightsSelectorState =
  createFeatureSelector<State>(flightLogDetailKey);

export const selectFlightLogDetailDialogState = createSelector(
  selectFlightsSelectorState,
  (state: State) =>
    state[fromFlightLogDetailDialog.flightLogDetailDialogFeatureKey]
);

export interface State {
  [fromFlightLogDetailDialog.flightLogDetailDialogFeatureKey]: fromFlightLogDetailDialog.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromFlightLogDetailDialog.flightLogDetailDialogFeatureKey]:
    fromFlightLogDetailDialog.reducer
};

import {Action, createReducer, on} from '@ngrx/store';
import {reasonFormSubmit, reasonFormSubmitSuccess} from './reason.actions';
import {Reason} from './reason.model';

export interface ReasonState {
  form: Reason;
  isInProgress: boolean;
}

const initialState = {
  form: {reason: ''},
  isInProgress: false
};

export const reducer = createReducer(
  initialState,
  on(reasonFormSubmit, (state, {form}) => ({
    ...state,
    isInProgress: true,
    form
  })),
  on(reasonFormSubmitSuccess, state => ({
    ...state,
    isInProgress: false
  }))
);

export function reasonStateReducer(
  state: ReasonState,
  action: Action
): ReasonState {
  return reducer(state, action);
}

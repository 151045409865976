import {Pipe, PipeTransform} from '@angular/core';
import {TriggerPoint, TriggerPointName} from './trigger-point.model';

@Pipe({
  name: 'uTriggerPointDisplayName',
  standalone: true
})
export class TriggerPointDisplayNamePipe implements PipeTransform {
  public transform(value: TriggerPoint): TriggerPointName {
    let temporalTriggerPointName = TriggerPointName[value];

    if (!temporalTriggerPointName) {
      temporalTriggerPointName = TriggerPointName[value.toLocaleLowerCase().split(' ').join('_')];
    }

    return temporalTriggerPointName;
  }
}

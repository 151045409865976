import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ua-model-loading-spinner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './model-loading-spinner.component.html',
  styleUrls: ['./model-loading-spinner.component.scss']
})
export class ModelLoadingSpinnerComponent implements OnInit, OnChanges {
  @Input() isSpinning: boolean;
  @Output() startAgain: EventEmitter<void> = new EventEmitter();
  secondsPassed: number;
  displayValue: number;
  private intervalHandle: any; /*1-100*/
  private LONG_START_MSG_TIME = 150;

  constructor(
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.resetCountdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    // THIS stop the ai when it was running, it needs review
    const spinning = changes.isSpinning;
    if (spinning.currentValue && !spinning.previousValue) {
      // this.startCountdownForAI();
    } else if (!spinning.currentValue && spinning.previousValue) {
      // this.stopCountdown();
    } else {
    }
  }

  /* track seconds of wait time for AI model */
  startCountdownForAI() {
    if (!!this.intervalHandle) {
      // sometimes triggered twice due to over-reactive UI
      return;
    }
    this.resetCountdown();
    const intervalTime = 100;
    /*ms*/
    const interval = setInterval(() => {
      this.intervalHandle = interval;
      const seconds = this.secondsPassed + intervalTime / 1000;
      /*a trick to speed up in the beginning and slow down at the end, used plot from https://mycurvefit.com/
       * 100 seconds == 100%*/
      const displayValue = 1.74 + 2 * seconds - 0.01 * seconds * seconds;
      this.secondsPassed = seconds;
      this.displayValue = displayValue;
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
      if (seconds >= this.LONG_START_MSG_TIME) {
        this.resetCountdown();
        this.startAgain.emit();
        this.translateService.get('live.model-loading-spinner.error').subscribe(i18loadingError =>
          this.snackBar.open(i18loadingError, null, {
            duration: 3000,
            verticalPosition: 'bottom'
          } as MatSnackBarConfig)
        );
      }
    }, intervalTime);
  }

  private resetCountdown() {
    this.stopCountdown();
    this.secondsPassed = 0;
    this.displayValue = 0;
  }

  private stopCountdown() {
    if (!!this.intervalHandle) {
      this.changeDetector.detectChanges();
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DeleteAllMediaInfoDialogComponent} from '../delete-all-media-info-dialog/delete-all-media-info-dialog.component';

@Component({
  selector: 'app-delete-all-media-dialog',
  templateUrl: './delete-all-media-dialog.component.html',
  styleUrls: ['./delete-all-media-dialog.component.scss']
})
export class DeleteAllMediaDialogComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  runDeleteAllMedia() {
    // TODO send email ?
    this.dialog.open(DeleteAllMediaInfoDialogComponent, {
      width: '80vw',
      maxWidth: '800px'
    });
  }
}

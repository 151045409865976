<div class="container">
  <h2 mat-dialog-title class="title">{{ 'jobs.task' | translate }}</h2>
  <section class="content" mat-dialog-content>
    <form class="form" [formGroup]="form">
      <div>
        <div class="row__item">
          <h1 class="row__title">
            {{ 'jobs.editTask.name' | translate }}
            <mat-icon (click)="toggleEditTitleEnabled()" class="row__edit-icon">edit</mat-icon>
          </h1>
          <input
            (blur)="toggleEditTitleEnabled()"
            (keydown.enter)="toggleEditTitleEnabled()"
            *ngIf="isEditTitleEnabled; else noEditTitleTemplate"
            formControlName="title"
            type="text"
            class="row__input"
          />
          <ng-template #noEditTitleTemplate>
            {{ form.controls.title.value }}
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="row__item">
          <h1 class="row__title">{{ 'jobs.editTask.created' | translate }}</h1>
          {{ data.task?.createdAt | date: 'dd MMM YYYY' }}
        </div>
        <div class="row__item">
          <h1 class="row__title">{{ 'jobs.editTask.status' | translate }}</h1>
          <div [matMenuTriggerFor]="statusMenu" class="row__selection">
            <div class="task-status" [class]="statusMapping[form.controls.status.value]?.class">
              {{ statusMapping[form.controls.status.value]?.name || '-' }}
            </div>
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
          <mat-menu #statusMenu>
            <menu
              (click)="setStatus(taskStatus)"
              *ngFor="let taskStatus of taskStatuses"
              class="task-edit-menu"
              mat-menu-item
            >
              <div class="task-status" [class]="statusMapping[taskStatus]?.class">
                {{ statusMapping[taskStatus]?.name || '-' }}
              </div>
            </menu>
          </mat-menu>
        </div>
        <!-- TODO: Check if backend will save this information -->
        <!-- <div class="row__item">
          <h1 class="row__title">Completed</h1>
          {{ data.task?.updatedAt }}
        </div> -->
        <div class="row__item">
          <h1 class="row__title">{{ 'jobs.editTask.assignee' | translate }}</h1>
          <div [matMenuTriggerFor]="userMenu" class="row__selection">
            {{ data.teamMembers[form.controls.assignedId.value]?.name || '-' }}
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
          <mat-menu #userMenu>
            <button
              (click)="setUserId(assignedId)"
              [class.row__button--selected]="form.controls.assignedId.value === assignedId"
              *ngFor="let assignedId of data.jobUserIds"
              mat-menu-item
            >
              <div class="row__user-menu">
                <div class="row__user-item">
                  <mat-icon>person</mat-icon>
                  <span class="row__user-name">{{ data.teamMembers[assignedId]?.name }}</span>
                </div>
                <mat-icon *ngIf="form.controls.assignedId.value === assignedId" class="check" color="primary"
                  >check</mat-icon
                >
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="row">
        <div class="row__item">
          <h1 class="row__title">{{ 'jobs.editTask.type' | translate }}</h1>
          {{ data.task?.context?.asset ? ('jobs.tasks.markers' | translate) : ('jobs.tasks.mission' | translate) }}
        </div>
        <div class="row__item">
          <h1 class="row__title">{{ 'jobs.editTask.details' | translate }}</h1>
          {{
            data.task?.context?.asset
              ? data.task?.context?.asset?.totalMarkers || '-'
              : data.task?.context?.mission?.name || '-'
          }}
        </div>
      </div>
      <div>
        <div class="row__item">
          <ng-container *ngIf="isEditDescriptionEnabled; else detailsTemplate">
            <p class="row__title">{{ 'jobs.editTask.additionalElements' | translate }}</p>
            <div class="details">
              <mat-form-field appearance="fill">
                <mat-label> {{ 'jobs.editTask.description' | translate }}</mat-label>
                <input
                  placeholder="{{ 'jobs.editTask.descriptionPlaceholder' | translate }}"
                  matInput
                  formControlName="description"
                  type="text"
                  class="row__input"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <ng-template #detailsTemplate>
            <div *ngIf="form.controls.description.value" class="row__item">
              <h1 class="row__title">
                {{ 'jobs.editTask.description' | translate }}
                <mat-icon (click)="toggleEditDescription()" class="row__edit-icon">edit</mat-icon>
              </h1>
              {{ form.controls.description.value }}
            </div>
          </ng-template>
        </div>
      </div>
    </form>
  </section>

  <div *ngIf="this.data.task?.context?.mission?.route as route" class="row__item">
    <h1 class="row__title">{{ 'jobs.tasks.layer' | translate }}</h1>
    <unleash-job-mini-map
      class="map"
      #map
      [hasToShowTitle]="false"
      [route]="route"
      [isFullScreenEnabled]="isFullScreenEnabled | async"
      (enableFullScreen)="setEnableFullScreen($event)"
    ></unleash-job-mini-map>
  </div>

  <div class="actions">
    <section class="actions__left">
      <mat-icon svgIcon="u_api_docs"></mat-icon>
      <span class="actions__info"> {{ 'jobs.editTask.footer' | translate }} </span>
    </section>
    <section class="actions__right">
      <a [disabled]="isLoading" mat-button (click)="cancelDialog()" color="primary">{{ 'jobs.cancel' | translate }}</a>
      <a [disabled]="isLoading" mat-flat-button color="primary" (click)="finishEdit()"
        >{{ 'jobs.editTask.save' | translate }}
        <unleash-spinner *ngIf="isLoading" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
      </a>
    </section>
  </div>
</div>

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {AssetType, AtlasAssetModel} from '../../../../core/models/api/atlas.model';
import {AssetsFilterService} from '@app/atlas/services/assets-filter.service';
import {BehaviorSubject, Observable, filter, map, shareReplay, take} from 'rxjs';
import {AtlasSelectMarkersService} from '@app/atlas/services/atlas-select-markers.service';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {MatMenuTrigger} from '@angular/material/menu';
import {ContextMenuComponent} from '@app/shared/context-menu/context-menu/context-menu.component';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {UserService} from '@app/core/services/api/user.service';
import {AtlasHeaderService, AtlasHeaderStatus} from '@app/atlas/services/atlas-header.service';
declare const L; // leaflet global

@Component({
  selector: 'app-layers-control-items',
  templateUrl: './layers-control-items.component.html',
  styleUrls: ['./layers-control-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersControlItemsComponent {
  @ViewChild(CdkVirtualScrollViewport) public viewPort: CdkVirtualScrollViewport;
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;
  @ViewChild('contextMenuControlItems', {static: false, read: ContextMenuComponent})
  public contextMenu: ContextMenuComponent;

  @Input() public map;
  @Input() public totalSelectedLayers: number;
  @Input('assets')
  public set setAssets(assets: AtlasAssetModel[]) {
    this.updateAssets(assets);
  }
  @Input() public updatedAsset: AtlasAssetModel;

  @Input('updatedAsset')
  public set setUpdatedAsset(updatedAsset: AtlasAssetModel) {
    if (!updatedAsset) {
      return;
    }
    if (updatedAsset.groupName) {
      this.updateGroupDataSource(updatedAsset);
      return;
    }
    this.updateSingleLayerDataSource(updatedAsset);
  }
  @Input() public saveLayersView: boolean;
  @Input() public goToDefaultLayers: boolean;
  @Input() public hasToBlockMenu: boolean = false;
  @Input() public isGpsDevice: boolean = false;
  @Input() public isListOnly: boolean = false;
  @Output() public toggleStream: EventEmitter<string> = new EventEmitter();
  @Output() public refreshView: EventEmitter<void> = new EventEmitter();
  @Output() public openSearchResults: EventEmitter<any> = new EventEmitter();

  public treeControl: FlatTreeControl<FlatNode>;
  public treeFlattener: MatTreeFlattener<AtlasAssetNode, FlatNode>;
  public dataSource: MatTreeFlatDataSource<AtlasAssetNode, FlatNode>;
  public layerGroupSavedInLocalStorage = [];
  public hasToDetectChangesSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public selectedControlItemAsset: AtlasAssetModel;
  public assetType = AssetType;
  public aclPermissions = AclPermissions;
  public availableColors = this.atlasService.availableColors;
  public hasToDetectChanges$ = this.atlasService.hasToDetectChanges$.pipe(shareReplay(1));
  public events = EVENTS;
  public hasAllLayersLoaded$ = this.atlasService.hasAllLayersLoaded$.asObservable();
  public gpsDevices: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public isJobsEnabled$: Observable<boolean> = this.userService.myCompany$.pipe(map(company => !!company.useUserTask));
  public headerStatus$: Observable<AtlasHeaderStatus> = this.atlasHeaderService.headerStatus$.pipe(shareReplay(1));
  public atlasHeaderStatus = AtlasHeaderStatus;

  constructor(
    private assetsFilterService: AssetsFilterService,
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private atlasService: AtlasService,
    private userService: UserService,
    private atlasHeaderService: AtlasHeaderService
  ) {
    this.treeControl = new FlatTreeControl<FlatNode>(
      node => node.level,
      node => node.expandable
    );
    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      node => node.level,
      node => node.expandable,
      node => node.children
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.assetsFilterService.openGroup.pipe(filter(data => !!data)).subscribe(({groupName, assetId}) => {
      const nodeIndex = this.treeControl.dataNodes.findIndex(node => node.expandable && node.name === groupName);
      setTimeout(() => {
        this.treeControl.expand(this.treeControl.dataNodes[nodeIndex]);
        const elementToScroll = document.getElementById(assetId);
        elementToScroll.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      });
    });
  }

  public hasChild = (_: number, node: FlatNode) => node.expandable;

  private createNewNode(layer) {
    const newData = this.dataSource.data;
    const groups = newData.filter(asset => !!asset.children);
    const assets = newData.filter(asset => !asset.children);
    this.dataSource.data = [...groups, {...layer, iconName: 'notes'}, ...assets];
  }

  private updateGroupDataSource(updatedAsset: AtlasAssetModel) {
    for (const data of this.dataSource.data) {
      if (data.children && data.name === updatedAsset.groupName) {
        const dataNode = data.children.find(asset => asset.id === updatedAsset.id);
        dataNode.isSelected = true;
        this.hasToDetectChangesSidebar.next(!this.hasToDetectChangesSidebar.value);
        return;
      }
    }
  }

  private updateSingleLayerDataSource(updatedAsset: AtlasAssetModel) {
    const dataNode: AtlasAssetModel = this.dataSource.data.find(
      asset => asset.id === updatedAsset.id
    ) as AtlasAssetModel;
    if (dataNode) {
      dataNode.isSelected = true;
      this.hasToDetectChangesSidebar.next(!this.hasToDetectChangesSidebar.value);
    }
  }

  public emitRefreshView(): void {
    this.refreshView.emit();
  }

  public openLayerControlItemMenu(mouseEvent: MouseEvent, asset: any): void {
    if (this.hasToBlockMenu) {
      return;
    }
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
    this.selectedControlItemAsset = asset;
    this.contextMenu.open({x: mouseEvent.x, y: mouseEvent.y} as MouseEvent);
  }

  public deviceUpdated(): void {
    this.atlasService.deviceUpdated(this.map);
  }

  public trackByFn(index: number, node: FlatNode) {
    return node.id;
  }

  public emitOpenSearchResults(properties: any): void {
    this.openSearchResults.emit(properties);
  }

  public getBaseAsset(baseLayerId) {
    return this.atlasService.getAssetById(baseLayerId);
  }

  public get selectedFeature() {
    return (this.selectedControlItemAsset?.geojson as any)?.features[0]?.properties;
  }

  public get isJobAvailable() {
    return !!this.selectedFeature?.jobId;
  }

  public get baseAsset() {
    return this.getBaseAsset(this.selectedFeature?.baseLayerId);
  }

  public get leafletLayer() {
    return (this.selectedControlItemAsset?.leafletLayer as any)?.getLayers()[0];
  }

  private updateAssets(assets: AtlasAssetModel[]): void {
    const dataSourceAssets = this.groupByGroupName(assets);
    this.gpsDevices.next(dataSourceAssets);
    this.dataSource.data = this.atlasSelectMarkersService.hasToShowCreatedLayerAtTop
      ? this.customSortForNewLayer(dataSourceAssets)
      : this.sortAssetsByCreatedAt(dataSourceAssets);
    this.assetsFilterService.searchFilterQuery.pipe(take(1)).subscribe((query: string) => {
      if (query) {
        this.treeControl.expandAll();
      }
    });
  }

  private customSortForNewLayer(groups: AtlasAssetNode[]): AtlasAssetNode[] {
    const sortGroup: AtlasAssetNode[] = this.sortAssetsByCreatedAt(groups);
    const newItems = this.atlasSelectMarkersService.createdAssets;
    newItems.forEach(asset => {
      this.selectedMarkersSorting(asset, sortGroup);
    });
    return sortGroup;
  }

  private selectedMarkersSorting(asset: AtlasAssetModel, sortGroup) {
    const newItemIndex = sortGroup.findIndex(g => g.id === asset.id);
    if (newItemIndex === -1) {
      return;
    }
    const finalGroupIndex = (sortGroup as any).findLastIndex(group => !!group.children);
    sortGroup.splice(finalGroupIndex + 1, 0, sortGroup[newItemIndex]);
    const newItemLastIndex = (sortGroup as any).findLastIndex(g => g.id === asset.id);
    sortGroup.splice(newItemLastIndex, 1);
    this.atlasSelectMarkersService.setHasToShowCreatedLayerAtTop(false);
  }

  private sortAssetsByCreatedAt(groups: AtlasAssetNode[]): AtlasAssetNode[] {
    let sortGroup: AtlasAssetNode[] = groups.map(group => {
      if (!group.children) {
        return group;
      }

      group.children = group.children.sort((assetA, assetB) => assetB.createdAt - assetA.createdAt);
      return group;
    });

    sortGroup = sortGroup.sort((groupA, groupB) => {
      if (groupA.children && !groupB.children) {
        return -1;
      }

      if (!groupA.children && groupB.children) {
        return 1;
      }

      return groupB.createdAt - groupA.createdAt;
    });
    return sortGroup;
  }

  private groupByGroupName(assets: AtlasAssetModel[]): AtlasAssetNode[] {
    return assets.reduce((accumulator: AtlasAssetNode[], currentValue) => {
      const groupName = currentValue.groupName;
      if (!!groupName) {
        // assets is grouped, add to group
        let group = accumulator.find(k => k.name === groupName && k.children);
        if (!group) {
          // new group
          group = {name: groupName, children: [], id: currentValue.id};
          accumulator.push(group);
        }
        group.children.push(currentValue);
      } else {
        // asset not grouped, display on first level without children

        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);
  }

  private _transformer = (node: AtlasAssetNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      asset: !node.children ? node : null,
      children: node.children,
      id: node.id
    };
  };
}

export interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  asset: AtlasAssetNode;
  children: AtlasAssetNode[];
  id: string;
}
interface AtlasAssetNode {
  name: string;
  children?: AtlasAssetModel[];
  createdAt?: number;
  id?: string;
  isHighlighted?: boolean;
}

import {createAction, props} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {ConfirmRegistration} from './confirm-registration.model';

export const actionConfirmRegistrationFormSubmit = createAction(
  '[ConfirmRegistrationForm] Submit',
  props<{form: ConfirmRegistration; isDeveloperMode: boolean}>()
);

export const actionConfirmRegistrationFormError = createAction(
  '[ConfirmRegistrationForm] Submit error',
  props<{error: AuthErrorModel}>()
);

export const actionConfirmRegistrationReSendCode = createAction(
  '[ConfirmRegistrationForm] Resend code',
  props<{form: Pick<ConfirmRegistration, 'email'>}>()
);

export const actionConfirmRegistrationSuccess = createAction('[ConfirmRegistrationForm] Submit success');

export const actionConfirmRegistrationReSendCodeSuccess = createAction('[ConfirmRegistrationForm] Resend code success');

export const actionShowResendAlert = createAction('[ConfirmRegistrationForm] Show resend code');

import {FlightFrame} from '../components/flight-log/flight-log-parser';
import {CameraVideoStreamSource} from './remote-cockpit-lens.model';
import {MissionState} from '../components/remote-cockpit/remote-cockpit-control-tile/mission-tile/mission-state.model';

export enum RemoteAccess {
  NO_BROADCAST,
  BROADCAST_ONLY,
  CAMERA_CONTROL,
  FULL_CONTROL
}

export enum SignalStrength {
  BAD,
  POOR,
  AVERAGE,
  GOOD,
  EXCELLENT
}

export enum IoTConnectionStatus {
  ERROR = 'Connection lost',
  SUCCESS = 'Connected',
  CONNECTING = 'Connecting...',
  DISCONNECTED = 'Disconnected'
}

export interface DroneState {
  timeLeft: number;
  remoteAccess: RemoteAccess;
  deviceConnected: boolean;
  isStreaming?: boolean;
  signalStrength: SignalStrength;
  flycState?: FlycState; // drone state P-GPS, ATTI, RTH, autoland
  gpsNum?: number;
  relativeCapacity?: number[];
  cameraMode: CameraMode;
  flightFrame: FlightFrame;
  gpsConnected: boolean;
  isFlightInProgress?: boolean;
  isMissionInProgress?: boolean;
  missionState: MissionState;
  isRecording?: boolean;
  isHoldOn?: boolean;
  lenses?: CameraVideoStreamSource[];
  currentLens?: CameraVideoStreamSource;
  isMultiLens?: boolean;
  dock?: DockState;
  droneStatus?: DroneStatus;
  flightModeStatus?: FlightModeLevelState;
  wifiLevel?: number;
  rcSignal?: number;
  droneOn?: boolean;
  timeElapsed?: number;
  missionTimeRemaining?: number;
  altEGM96?: number;
  velX?: number;
  velY?: number;
  velZ?: number;
  distHome?: number;
  alt?: number;
  yaw?: number;
  pitch?: number;
  gimPitch?: number;
  gimYaw?: number;
  preciseLand?: boolean;
}

//TODO:check real states this is provitional
export enum DockState {
  NO_DATA,
  READY,
  NOT_READY,
  WARNING,
  ERROR
}

export interface RemoteCockpitLog {
  date: number;
  message: string;
  userName: string;
  deviceName: string;
  origin: LOG_ORIGIN;
}

export enum LOG_ORIGIN {
  REMOTE_COCKPIT = 'Remote Cockpit',
  AUTOFLY = 'Autofly',
  DOCK = 'Dock'
}

export interface DroneAction {
  command: string;
  response?: boolean;
}

export interface ShadowDocument {
  state: {
    desired: DroneAction;
    reported: DroneAction;
  };
  timestamp: number;
}

export enum FlightActions {
  START_STREAM = 'drone start stream',
  STOP_STREAM = 'drone stop stream',
  TAKE_OFF = 'drone take off',
  CANCEL_GO_HOME = 'drone cancel go home',
  GO_HOME = 'drone go home',
  LAND = 'drone land',
  TAKE_PICTURE = 'drone take picture',
  START_RECORDING_VIDEO = 'drone start recording video',
  STOP_RECORDING_VIDEO = 'drone stop recording video',
  CHANGE_MODE_PHOTO = 'drone change camera mode to photo',
  CHANGE_MODE_VIDEO = 'drone change camera mode to video recording',
  CHANGE_MODE_IR = 'drone change camera mode to ir',
  CHANGE_MODE_VISUAL = 'drone change camera mode to visual',
  ZOOM_IN = 'drone camera zoom in',
  ZOOM_OUT = 'drone camera zoom out',
  MOVE_UP = 'move up',
  MOVE_DOWN = 'move down',
  MOVE_FORWARD = 'move forward',
  MOVE_BACKWARD = 'move backward',
  MOVE_RIGHT = 'move right',
  MOVE_LEFT = 'move left',
  ROTATE_RIGHT = 'rotate right',
  ROTATE_LEFT = 'rotate left',
  GIMBAL_UP = 'gimbal up',
  GIMBAL_DOWN = 'gimbal down',
  GIMBAL_LEFT = 'gimbal left',
  GIMBAL_RIGHT = 'gimbal right',
  GIMBAL_CENTER = 'gimbal center',
  MISSION_LOAD = 'drone load mission',
  MISSION_UPLOAD = 'drone upload mission',
  MISSION_START = 'drone start mission',
  MISSION_STOP = 'drone stop mission',
  MISSION_PAUSE = 'drone pause mission',
  MISSION_RESUME = 'drone resume mission',
  SYNC_MEDIA = 'drone sync media',
  HOLD_ON = 'drone hold',
  HOLD_OFF = 'drone unhold',
  SWITCH_LENS = 'lens',
  MOVE_ACTIONS = 'move actions ',
  RESTART_AUTOFLY = 'restart autofly',
  CANCEL_RETURN_HOME = 'drone cancel return home',
  CANCEL_LANDING = 'drone cancel landing',
  PRECISE_LANDING = 'precise land'
}

// https://developer.dji.com/api-reference/android-api/Components/Camera/DJICamera_DJICameraSettingsDef.html#djicamera_djicameramode_inline
/* eslint-disable no-magic-numbers */
export enum CameraMode {
  SHOOT_PHOTO = 0,
  RECORD_VIDEO = 1,
  PLAYBACK = 2,
  MEDIA_DOWNLOAD = 3,
  BROADCAST = 5,
  UNKNOWN = 255
}

export type FlycState = 'P-GPS' | 'ATTI' | 'RTH' | 'N/A' | 'Offline';

export enum CameraKeyCode {
  Z = 'KeyZ',
  X = 'KeyX',
  CapsLock = 'CapsLock',
  Q = 'KeyQ',
  M = 'KeyM',
  E = 'KeyE',
  Space = 'Space',
  I = 'KeyI',
  J = 'KeyJ',
  K = 'KeyK',
  L = 'KeyL',
  O = 'KeyO',
  U = 'KeyU',
  P = 'KeyP',
  Digit1 = 'Digit1',
  Digit2 = 'Digit2',
  Digit3 = 'Digit3',
  Digit4 = 'Digit4',
  Digit5 = 'Digit5',
  Digit6 = 'Digit6',
  Digit7 = 'Digit7',
  Digit8 = 'Digit8',
  Digit9 = 'Digit9',
  Digit0 = 'Digit0',
  Tab = 'Tab'
}

export enum DroneKeyCode {
  W = 'KeyW',
  S = 'KeyS',
  A = 'KeyA',
  D = 'KeyD',
  Up = 'ArrowUp',
  Down = 'ArrowDown',
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
  Center = 'KeyO'
}

export enum ParallelMovements {
  MOVE_RIGHT,
  MOVE_LEFT,
  MOVE_FORWARD,
  MOVE_BACKWARD,
  MOVE_UP,
  MOVE_DOWN,
  ROTATE_LEFT,
  ROTATE_RIGHT,
  GIMBAL_UP,
  GIMBAL_DOWN,
  GIMBAL_LEFT,
  GIMBAL_RIGHT
}

export enum GAMEPAD_ACTIONS {
  THROTTLE = 'THROTTLE',
  YAW = 'YAW',
  ROLL = 'ROLL',
  PITCH = 'PITCH'
}

export enum REMOTE_COCKPIT_ACTIONS {
  RESTART_DRONE = 'restartDrone',
  RESTART_CONTROLLER = 'restartController',
  RESTART_AUTOFLY = 'restartAutofly'
}

/*
 * {"alt":0.0,"batt":36,"flightMode":"P_GPS","gimPitch":-26.7,"gimRoll":0.0,"gimYaw":99.9,"gpsLevel":2,"lat":53.814744781512424,"lng":22.831776240474316,"pitch":-0.2,"roll":3.6,"timestamp":1534795198765,"velX":0.0,"velY":0.0,"velZ":0.0,"yaw":100.0, "signalStrength": 2, "remoteAccess":1}
 * */
export interface IoTSensorsData {
  alt: number;
  lat: number;
  lng: number;
  pitch: number;
  yaw: number;
  roll: number;
  gimPitch: number;
  gimYaw: number;
  gimRoll: number;
  height: number;
  timeLeft: number;
  velX: number;
  velY: number;
  velZ: number;
  gpsLevel: number;
  batt: number[];
  timestamp: number;
  flightMode: FlycState;
  signalStrength: SignalStrength;
  remoteAccess: RemoteAccess;
  cameraMode: CameraMode;
  isFlying: boolean;
  isHoldOn: boolean;
  isMissionInProgress: boolean;
  missionState: MissionState;
  isRecording: boolean;
  streamNum?: number;
  lenses?: CameraVideoStreamSource[];
  currentLens?: CameraVideoStreamSource;
  isMultiLens?: boolean;
  deviceId?: string;
  droneStatus?: DroneStatus;
  flightModeStatus?: FlightModeLevelState;
  wifiLevel?: number;
  rcSignal?: number;
  droneOn?: boolean;
  distHome?: number;
  timeElapsed?: number;
  missionTimeRemaining?: number;
  altEGM96?: number;
  preciseLand?: boolean;
}

export enum FlightModeLevelState {
  GOOD = 'GOOD',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

export enum DroneStatus {
  IN_FLIGHT_RTK,
  IN_FLIGHT_GPS,
  READY_TO_GO_RTK,
  READY_TO_GO_GPS,
  NOT_READY,
  WARNING,
  RC_BATTERY_LOW
}

export interface DisplayedIoTSensorsData {
  longitude: number;
  latitude: number;
  height: number;
  XspeedX: number;
  Yspeed: number;
  Zspeed: number;
  pitch: number;
  roll: number;
  yaw: number;
  gpsNum: number;
  relativeCapacity: number[];
  signalStrength: SignalStrength;
  streamNum: number;
  remoteAccess: RemoteAccess;
  flycState: FlycState;
  cameraMode: CameraMode;
  lenses?: CameraVideoStreamSource[];
  currentLens?: CameraVideoStreamSource;
  isMultiLens?: boolean;
  gimYaw?: number;
}

import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './cancel-plan-message-dialog.component.html',
  styleUrls: ['./cancel-plan-message-dialog.component.scss']
})
export class CancelPlanMessageDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {
  @Input() public acceptPaste: boolean = false;
  @Input() public acceptDot: boolean = false;
  @Input() public includeZero: boolean = false;
  @Input() public includeNegatives: boolean = false;

  private onlyNumbers: RegExp = new RegExp(/^(0|[1-9]*)$/);

  private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent) {
    if (this.includeZero) {
      this.onlyNumbers = new RegExp(/^([0-9]*)$/);
    }

    if (this.acceptDot) {
      this.onlyNumbers = new RegExp(/^(0|[0-9]*|-?[0-9]*\.[0-9]*)$/);
    }

    if (this.includeNegatives) {
      this.onlyNumbers = new RegExp(/^(-?[0-9]*|-?[0-9]*\.[0-9]*)$/);
    }

    if (this.acceptPaste) {
      this.specialKeys = [...this.specialKeys, 'Control', 'v'];
    }

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.elementRef.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.onlyNumbers)) {
      event.preventDefault();
    }
  }
}

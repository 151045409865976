<div class="container">
  <h1 class="title">
    {{ 'calibration.addCalibrationForPoint' | translate: {value: data.index} }}
  </h1>
  <div [formGroup]="form" class="lat-lng">
    <mat-form-field appearance="fill" class="coordinates">
      <mat-label>{{ 'calibration.lat' | translate }}</mat-label>
      <input numbersOnly [acceptPaste]="true" [includeNegatives]="true" matInput formControlName="lat" />
      <mat-error *ngIf="(form.controls.lat.invalid && form.controls.lat.touched) || form.controls.lat.dirty">
        {{
          form.controls.lat.hasError('pattern')
            ? ('calibration.invalidLat' | translate)
            : ('calibration.latNotEmpty' | translate)
        }}</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="fill" class="coordinates">
      <mat-label>{{ 'calibration.lng' | translate }}</mat-label>
      <input numbersOnly [acceptPaste]="true" [includeNegatives]="true" matInput formControlName="lng" />
      <mat-error *ngIf="(form.controls.lng.invalid && form.controls.lng.touched) || form.controls.lng.dirty">
        {{
          form.controls.lng.hasError('pattern')
            ? ('calibration.invalidLng' | translate)
            : ('calibration.lngNotEmpty' | translate)
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-dialog-close mat-button color="primary">{{ 'calibration.cancel' | translate }}</button>
    <button [disabled]="form.invalid" (click)="sendCoordinates()" mat-flat-button color="primary">
      {{ 'calibration.add' | translate }}
    </button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';

@Injectable({providedIn: 'root'})
export class AwsTokenService {
  constructor(private apiGateway: ApiGateway) {}

  checkAwsDeviceToken(awsMarketplaceToken: string) {
    return this.apiGateway.get(
      `/marketplace/aws/token/valid?awsMarketplaceToken=${encodeURIComponent(awsMarketplaceToken)}`,
      {}
    );
  }
}

<form [formGroup]="form" (submit)="emitContinue()" class="form">
  <ng-container *ngIf="isDeveloperMode; else nonDeveloperProfileForm">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'profile.publisherName' | translate }}</mat-label>
      <input matInput formControlName="publisherName" autofocus="true" data-test-id="publisherNameInput" />
      <mat-error
        *ngIf="
          (form.controls.publisherName.invalid && form.controls.publisherName.touched) ||
          form.controls.publisherName.dirty
        "
        >{{ 'profile.publisherName' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
      >
    </mat-form-field>
  </ng-container>

  <ng-template #nonDeveloperProfileForm>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'profile.organizationName' | translate }}</mat-label>
      <input matInput formControlName="organization" autofocus="true" data-test-id="organizationInput" />
      <mat-error
        *ngIf="
          (form.controls.organization.invalid && form.controls.organization.touched) || form.controls.organization.dirty
        "
        >{{ 'profile.organizationName' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'common.industry' | translate }}</mat-label>
      <mat-select formControlName="industry" data-test-id="industrySelect">
        <mat-option *ngFor="let industry of industries" name="industry" [value]="industry">
          {{ industry }}
        </mat-option>
        <mat-divider></mat-divider>
        <mat-option value="other"> {{ 'common.other' | translate }} </mat-option>
      </mat-select>
      <mat-error
        *ngIf="(form.controls.industry.invalid && form.controls.industry.touched) || form.controls.industry.dirty"
      >
        {{ 'profile.chooseIndustry' | translate }}
      </mat-error>
    </mat-form-field>
  </ng-template>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.phoneOptional' | translate }}</mat-label>
    <input matInput type="tel" formControlName="phone" data-test-id="phoneInput" />
    <mat-error *ngIf="(form.controls.phone.invalid && form.controls.phone.touched) || form.controls.phone.dirty">
      {{ 'profile.phoneError' | translate }}
    </mat-error>
  </mat-form-field>

  <button
    class="form__item continue-button"
    [appLadda]="isInProgress"
    [disabled]="!form.valid || isInProgress"
    mat-raised-button
    color="primary"
    data-test-id="continueButton"
  >
    {{ 'common.continue' | translate }}
  </button>
</form>

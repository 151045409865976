import {yourProfileStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {YourProfileState} from './your-profile.reducers';

export const selectYourProfileFormValue = createSelector(
  yourProfileStateSelector,
  (state: YourProfileState) => state.form
);

export const selectYourProfileFormError = createSelector(
  yourProfileStateSelector,
  (state: YourProfileState) => state.error
);

export const selectYourProfileIsInProgress = createSelector(
  yourProfileStateSelector,
  (state: YourProfileState) => state.isInProgress
);

export const selectYourProfileError = createSelector(
  yourProfileStateSelector,
  (state: YourProfileState) => state.error
);

<div fxLayout="row">
  <mat-checkbox (change)="showStreamToUrlInput($event)">
    Stream to URL
  </mat-checkbox>
</div>
<div fxLayout="row">
  <form *ngIf="isOutputStream" [formGroup]="streamForm" novalidate autocomplete="off" class="form-size">
    <mat-form-field fxFlex>
      <input matInput placeholder="URL" formControlName="url">
      <mat-error *ngIf="streamForm.get('url').hasError('invalidUrl')">
        Is not a valid url.
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div class="margin-bottom" fxLayout="row">
  <mat-button-toggle fxFlex *ngIf="isOutputStream" [value]="isStreamingToUrl" (change)="streamingState($event)">
    {{ isStreamingToUrl ? 'STOP STREAM' : 'START STREAM' }}
    <mat-icon matTooltip="{{ isStreamingToUrl ? 'STOP STREAM': 'START STREAM' }}">wifi</mat-icon>
  </mat-button-toggle>
</div>

<h1 mat-dialog-title fxLayoutAlign="center"><span *ngIf="data.ai">AI</span> Stream finished</h1>
<div mat-dialog-content class="reminder-text">
  <p *ngIf="!data.ai">Thank you for using Unleash Live Stream service.</p>
  <p>
    <span *ngIf="!data.ai">If You didn't end streaming session on your device, it's because </span>You are reaching
    maximum <span *ngIf="data.ai">AI</span> stream duration and it will end soon.<br /><br />For further service usage
    and information contact us at
    <a href="mailto:getstarted@unleashlive.com" mat-button color="primary">getstarted&#64;unleashlive.com</a>.
  </p>
</div>
<div mat-dialog-actions fxLayoutAlign="center">
  <button mat-button [mat-dialog-close]="false" tabindex="0">OK</button>
</div>

<div (swipeleft)="stepper.next()" (swiperight)="stepper.previous()" class="container">
  <div class="stepper-container">
    <div class="previous-arrow" *ngIf="stepper.selectedIndex !== 0 || hasFirstWelcome" (click)="stepper.previous()">
      <button mat-icon-button color="primary"><mat-icon>chevron_left</mat-icon></button>
    </div>
    <mat-horizontal-stepper #stepper [linear]="true" class="hidden-header-stepper">
      <mat-step *ngIf="!hasFirstWelcome">
        <div class="welcome-step">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <h2 class="welcome-to-text">{{ 'core.welcome.title' | translate }}</h2>
          </div>

          <div class="img-container">
            <img src="assets/icons/welcome/hurrah.png" alt="hurrah" />
          </div>

          <div class="welcome-text" fxLayout="column" fxLayoutAlign="center center">
            <h3 class="welcome-text__subtitle">{{ 'core.welcome.takeALook' | translate }}</h3>
          </div>

          <p></p>
          <button mat-raised-button color="primary" matStepperNext>
            {{ 'core.welcome.whatYouCanDo' | translate }}
          </button>
        </div>
      </mat-step>
      <mat-step>
        <div class="welcome-step">
          <h4>{{ 'core.welcome.liveStreaming' | translate }}</h4>
          <div class="welcome-step-content" fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
            <p>{{ 'core.welcome.activateDevices' | translate }}</p>
            <img class="step-screen-img" src="/assets/images/welcome/step1.jpeg" />
            <div class="highlight-actions">
              <span>{{ 'core.welcome.selectAndActivate' | translate }}</span>
              <span>{{ 'core.welcome.viewLiveStream' | translate }}</span>
              <span>{{ 'core.welcome.embedVideoStreams' | translate }}</span>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step completed="true">
        <div class="welcome-step">
          <h4>{{ 'core.welcome.interactiveModels' | translate }}</h4>
          <div class="welcome-step-content" fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
            <p>{{ 'core.welcome.exploreModels' | translate }}</p>
            <img class="step-screen-img" src="/assets/images/welcome/step2.jpeg" />
            <div class="highlight-actions">
              <span>{{ 'core.welcome.accessAndNavigate' | translate }}</span>
              <span>{{ 'core.welcome.generateModels' | translate }}</span>
              <span>{{ 'core.welcome.exportModels' | translate }}</span>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="welcome-step">
          <h4>{{ 'core.welcome.mediaLibrary' | translate }}</h4>
          <div class="welcome-step-content" fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
            <p>{{ 'core.welcome.accessMedia' | translate }}</p>
            <img class="step-screen-img" src="/assets/images/welcome/step3.jpeg" />
            <div class="highlight-actions">
              <span>{{ 'core.welcome.mediaManagement' | translate }}</span>
              <span>{{ 'core.welcome.mediaSearch' | translate }}</span>
              <span>{{ 'core.welcome.browseAndFilter' | translate }}</span>
              <span>{{ 'core.welcome.compareMedia' | translate }}</span>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="welcome-step">
          <h4>{{ 'core.welcome.aiAppStore' | translate }}</h4>
          <div class="welcome-step-content" fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
            <p>{{ 'core.welcome.accessAi' | translate }}</p>
            <img class="step-screen-img" src="/assets/images/welcome/step6.jpeg" />
            <div class="highlight-actions">
              <span>Media management</span>
              <span>Media search</span>
              <span>Browse and filter media</span>
              <span>Compare media</span>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="welcome-step">
          <h4>{{ 'core.welcome.configureSettings' | translate }}</h4>
          <div class="welcome-step-content" fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
            <p>{{ 'core.welcome.manageAndSetup' | translate }}</p>
            <img class="step-screen-img" src="/assets/images/welcome/step4.jpeg" />
            <div class="highlight-actions">
              <span>{{ 'core.welcome.customizeProfile' | translate }}</span>
              <span>{{ 'core.welcome.liveStreamSettings' | translate }}</span>
              <span>{{ 'core.welcome.connectDevices' | translate }}</span>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
    <div
      class="next-arrow"
      [ngClass]="{'next-arrow--hidden': stepper.selectedIndex === steps.length}"
      *ngIf="stepper.selectedIndex !== 0 || hasFirstWelcome"
      (click)="stepper.next()"
    >
      <button mat-icon-button color="primary"><mat-icon>chevron_right</mat-icon></button>
    </div>
  </div>

  <div class="step-action-buttons" *ngIf="stepper.selectedIndex !== 0 || hasFirstWelcome">
    <button
      (click)="browserSettingsService.setWelcomeSeenFlag(true)"
      [ngClass]="{'skip-button--hidden': stepper.selectedIndex === steps.length}"
      mat-button
      color="primary"
      (click)="close()"
    >
      {{ 'common.skip' | translate }}
    </button>
    <div class="step-dots">
      <div
        (click)="stepper.selectedIndex = index"
        *ngFor="let index of steps"
        class="dot {{ stepper.selectedIndex === index ? 'active' : 'inactive' }}"
      ></div>
    </div>
    <button
      color="primary"
      (click)="browserSettingsService.setWelcomeSeenFlag(true)"
      *ngIf="stepper.selectedIndex === steps.length"
      mat-raised-button
      (click)="close()"
    >
      {{ 'common.complete' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="stepper.next()" *ngIf="stepper.selectedIndex !== steps.length">
      {{ 'common.next' | translate }}
    </button>
  </div>
</div>

import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './prevent-lose-changes.component.html',
  styleUrls: ['./prevent-lose-changes.component.scss']
})
export class PreventLoseChangesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import {Injectable} from '@angular/core';
import {DELETE_REPORT_TYPES, ParsedReport, ReportType} from '@app/analysis/models/analysis.model';
import {AnalysisFilter} from '@app/analysis/models/filter.model';
import {FilterAttributes, Paginator} from '@app/analysis/models/paginator.model';
import {
  clearFilter,
  clearSelectedReports,
  copyKey,
  deleteAnalysisReports,
  filterReports,
  generateApiKey,
  generateApiKeySuccess,
  generatePaginatedReports,
  getAllReports,
  hideFooter,
  openMobileFilterDialog,
  removeKey,
  removeKeyDialog,
  removeKeySuccess,
  selectActiveFilters,
  selectApiKey,
  selectDisplayedReports,
  selectFilter,
  selectIsApiKeyActionInProgress,
  selectIsLoadingApiKey,
  selectIsLoadingReports,
  selectPaginator,
  selectReportsTypes,
  selectSelectedReportsId,
  selectSelectedReportsNumber,
  sortAllReports,
  toggleSelectedReports,
  updateKey,
  updateKeyDialog,
  updateKeySuccess
} from '@app/analysis/store';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsStoreFacadeService {
  public filter$: Observable<AnalysisFilter> = this.store.pipe(select(selectFilter));
  public reportsTypes$: Observable<ReportType[]> = this.store.select(selectReportsTypes);
  public paginator$: Observable<Paginator> = this.store.pipe(select(selectPaginator));
  public displayedReports$: Observable<ParsedReport[]> = this.store.pipe(select(selectDisplayedReports));
  public isLoading$: Observable<boolean> = this.store.pipe(select(selectIsLoadingReports));
  public selectedReportsNumber$: Observable<number> = this.store.pipe(select(selectSelectedReportsNumber));
  public selectSelectedReportsId$: Observable<string[]> = this.store.pipe(select(selectSelectedReportsId));
  public activeFilters$: Observable<number> = this.store.pipe(select(selectActiveFilters));

  public isLoadingApiKey$ = this.store.select(selectIsLoadingApiKey);
  public apiKey$ = this.store.select(selectApiKey);
  public isApiKeyActionInProgress$: Observable<boolean> = this.store.select(selectIsApiKeyActionInProgress);

  constructor(private store: Store) {}

  public generateApiKeySuccess(key: string) {
    this.store.dispatch(generateApiKeySuccess({payload: {key}}));
  }

  public fetchAllReports(): void {
    this.store.dispatch(getAllReports());
  }

  public performSort(sortOptions: FilterAttributes): void {
    this.store.dispatch(sortAllReports({payload: sortOptions.sort}));
  }

  public performPagination(sortOptions: {pageIndex: number; pageSize: number}): void {
    this.store.dispatch(generatePaginatedReports({payload: sortOptions}));
  }

  public filterAnalysis(analysisFilter: AnalysisFilter): void {
    this.store.dispatch(filterReports({payload: analysisFilter}));
  }

  public clearFilter(): void {
    this.store.dispatch(clearFilter());
  }

  public deleteAnalysisReports(sessionIds: string[], deleteReportType: DELETE_REPORT_TYPES): void {
    this.store.dispatch(deleteAnalysisReports({payload: {sessionIds, deleteReportType}}));
  }

  public toggleSelectedReports(report: ParsedReport): void {
    this.store.dispatch(toggleSelectedReports({payload: {report}}));
  }

  public clearSelectedReports(): void {
    this.store.dispatch(clearSelectedReports());
  }

  public openMobileFilterDialog(): void {
    this.store.dispatch(openMobileFilterDialog());
  }

  public hideFooter(hasToHideFooter: boolean): void {
    this.store.dispatch(hideFooter({payload: {hasToHideFooter}}));
  }

  public generateApiKey(): void {
    this.store.dispatch(generateApiKey());
  }

  public copyKey(): void {
    this.store.dispatch(copyKey());
  }

  public removeKeyDialog(): void {
    this.store.dispatch(removeKeyDialog());
  }

  public removeKey(): void {
    this.store.dispatch(removeKey());
  }

  public updateKeyDialog(): void {
    this.store.dispatch(updateKeyDialog());
  }

  public updateKey(): void {
    this.store.dispatch(updateKey());
  }

  public updateKeySuccess(apiKey: any): void {
    this.store.dispatch(updateKeySuccess({payload: {key: apiKey}}));
  }

  public removeKeySuccess(): void {
    this.store.dispatch(removeKeySuccess());
  }
}

import {AfterViewInit, Directive, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {AclService} from '@app/core/services/acl.service';

@Directive({
  selector: '[uaDisabledFormControl]',
  standalone: true
})
export class AclDisabledFormControlDirective implements AfterViewInit {
  @Input() public uaDisabledFormControl: string;

  constructor(private aclService: AclService, private control: NgControl) {}

  public ngAfterViewInit() {
    if (this.aclService.hasPermission(this.uaDisabledFormControl)) {
      this.control.control.enable();
      return;
    }

    this.control.control.disable();
  }
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {NotificationsService} from '../services/appsync-notifications/notifications.service';
import {
  actionHideNotification,
  actionLoadNotifications,
  actionShowNotificationProgressUpdate,
  actionNewNotification,
  actionSetNotificationAsRead
} from './notifications.actions';

@Injectable()
export class NotificationsStoreEffects {
  newNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionNewNotification),
      debounceTime(6000),
      switchMap(action => {
        return [actionHideNotification()];
      })
    )
  );

  newGenericNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionShowNotificationProgressUpdate),
      debounceTime(6000),
      switchMap(action => {
        return [actionHideNotification()];
      })
    )
  );

  loadNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionLoadNotifications),
        tap(() => {
          this.notificationsService.getNotInProgressNotifications();
          this.notificationsService.getInProgressNotifications();
        })
      ),
    {dispatch: false}
  );

  markNotificationAsRead$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSetNotificationAsRead),
        tap(action => {
          this.notificationsService.setNotificationAsRead(action.payload);
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private notificationsService: NotificationsService) {}
}

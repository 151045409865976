import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {take, tap, catchError, EMPTY} from 'rxjs';

@Component({
  selector: 'unleash-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameDialog {
  public renameJobsOptions: typeof RENAME_JOBS_OPTIONS = RENAME_JOBS_OPTIONS;
  public isLoading: boolean = false;
  public form: FormGroup = this.fb.group({
    name: [this.data.name, Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<RenameDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      renameOption: RENAME_JOBS_OPTIONS;
      jobId: string;
      name: string;
    },
    public jobsApiService: JobsApiService,
    private fb: FormBuilder
  ) {}

  public renameAction(): void {
    if (this.form.valid) {
      switch (this.data.renameOption) {
        case RENAME_JOBS_OPTIONS.JOB:
          this.renameJob();
          break;
        case RENAME_JOBS_OPTIONS.TASK:
          this.renameTask();
          break;
      }
    }
  }

  public renameJob() {
    this.isLoading = true;
    this.jobsApiService
      .updateJob(this.data.jobId, {title: this.form.controls.name.value})
      .pipe(
        take(1),
        tap(response => this.dialogRef.close({jobId: this.data.jobId, data: response})),
        catchError(() => {
          this.dialogRef.close({error: true});
          return EMPTY;
        })
      )
      .subscribe();
  }

  public renameTask() {
    this.dialogRef.close(this.form.controls.name.value);
  }
}

export enum RENAME_JOBS_OPTIONS {
  JOB,
  TASK
}

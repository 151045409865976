import {HttpClient} from '@angular/common/http';
import {ApplicationRef, createComponent, Injectable, NgZone, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ThumblerParams} from '@app/library/models/thumbler-params.model';
import {ParamsHelper} from '@app/library/services/params-helper';
import {ThumblerSourceCategoryModel} from '@app/shared/pipes/models/thumbler.model';
import {Feature, GeoJSON, GeoJsonObject, Geometry} from 'geojson';
import {
  FeatureGroup,
  geoJSON,
  icon,
  LatLngLiteral,
  LatLngTuple,
  Layer,
  Map,
  Marker,
  marker,
  PathOptions,
  point,
  rectangle
} from 'leaflet';
import {environment} from '../../../environments/environment';
import {
  ASSET_GEOMETRY_TYPE,
  ASSET_PROPERTY_NAME,
  AssetType,
  AtlasAssetModel,
  AtlasGeojsonAssetModel
} from '../../core/models/api/atlas.model';
import {AssetProperty} from '../../core/models/api/user-model';
import {createHouseIcon} from '../house-icon';
import {AbstractAssetLoaderService} from './abstract-asset-loader.service';
import {AtlasService} from './atlas.service';
import {TileSessionService} from './tile-session.service';
import {createMapIcon} from '../marker-icons/custom-map-pointer';
import {createInProgressMarkerIcon} from '../marker-icons/in-progress-marker';
import {MarkerStateMenuComponent} from '../components/marker-state-menu/marker-state-menu.component';
import {GeojsonFeatureState} from '../model/marker.model';
import {createCompletedMarkerIcon} from '../marker-icons/completed-marker';
import {createIncompletedMarkerIcon} from '../marker-icons/incomplete-marker';
import {MarkerStatusInfoComponent} from '../components/marker-status-info/marker-status-info.component';
import {JobPolygonMenuComponent} from '../components/job-polygon-menu/job-polygon-menu.component';
import {filter, fromEvent, take, takeWhile, timer} from 'rxjs';
import {atlasConfig, defaultMarkerSize} from '@app/atlas/atlas.config';
import {MarkerClusterService} from '@app/atlas/services/marker-cluster.service';

declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class GeojsonAssetLoaderService extends AbstractAssetLoaderService {
  constructor(
    protected atlasService: AtlasService,
    protected http: HttpClient,
    protected tileSessionService: TileSessionService,
    protected sanitizer: DomSanitizer,
    protected route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private appRef: ApplicationRef,
    private markerClusterService: MarkerClusterService
  ) {
    super(atlasService, http, tileSessionService, sanitizer, route);
  }

  public async load(asset: AtlasGeojsonAssetModel, map: Map): Promise<number> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const assetError = await this.catchAssetsErrors(asset);
        if (assetError) {
          reject(assetError);
        }
        asset.geojson = await this.fetchFile(asset.key);
        const features = (asset.geojson as any).features;
        features.forEach((feature, i) => {
          feature.index = i;
        });
        const totalCount = features.length;
        this.atlasService.setTotalGeojsonFeatures(this.atlasService.totalGeojsonFeatures + totalCount);
        this.atlasService.handleDetectAssetChanges();
        this.atlasService.handleGeojsonAssetsLoaded();
        const geoJsonLayer: FeatureGroup<any> = await this.createLayer(asset.geojson, asset, map);
        this.atlasService.finishedLoadGeojson.next();
        super.addAsset(map, {
          id: asset.id,
          layer: geoJsonLayer,
          name: asset.name,
          bounds: geoJsonLayer.getBounds()
        });

        resolve(totalCount);
      } catch (error) {
        console.warn(error);
      }
    });
  }

  public createLayer(
    geoJsonObject: GeoJsonObject | any,
    asset: AtlasAssetModel,
    map: Map,
    avoidShowInMap?: boolean
  ): Promise<FeatureGroup> {
    return new Promise((resolve, reject) => {
      try {
        const geoJsonLayerGroup = geoJSON(geoJsonObject, {
          style: feature => {
            return {
              color: asset.color || asset.strokeColor || feature.properties.stroke || 'red',
              weight: asset.weight || feature.properties['stroke-width'] || 1,
              opacity: feature.properties['stroke-opacity'] || 1,
              fillColor: asset.fillColor || feature.properties.fill,
              fillOpacity: 0.05 // feature.properties['fill-opacity']
            } as PathOptions;
          },
          pointToLayer: (feature, latlng) => {
            return this.generateMarker(feature, latlng, asset);
          },
          onEachFeature: (feature, layer) => {
            this.onEachFeature(feature, layer, asset, map);
          }
        });
        this.markerClusterService.addToMap(map);
        this.markerClusterService.checkIn(geoJsonLayerGroup);
        if (!avoidShowInMap) {
          geoJsonLayerGroup.addTo(map);
        }
        resolve(geoJsonLayerGroup);
      } catch (e) {
        const msg = `Could not create geoJSON layer: ${e}`;
        console.warn(msg, asset);
        reject(msg);
      }
    });
  }

  public generateHouseMarker(iconColor, latlng) {
    return marker(latlng, {
      icon: createHouseIcon(false, 20, iconColor),
      bubblingMouseEvents: false
    });
  }

  public generateCustomHrefMarker(asset, properties, latlng) {
    const iconUrl = this.getIconUrl(asset.key, properties.iconHref);
    let iconSize = [30, 30];
    if (!asset.iconSize) {
      iconSize = !!properties.iconScale ? iconSize.map(size => size * properties.iconScale) : iconSize;
    } else if (typeof asset.iconSize === 'number') {
      iconSize = !!properties.iconScale ? asset.iconSize * properties.iconScale : asset.iconSize;
    } else {
      iconSize = !!properties.iconScale
        ? (asset.iconSize.map(size => size * properties.iconScale) as [number, number])
        : asset.iconSize;
    }
    return marker(latlng, {
      icon: icon({
        iconUrl,
        iconSize,
        iconAnchor: [15, 30]
      } as any),
      bubblingMouseEvents: false
    });
  }

  public generatePinMarker(asset, feature, latlng, size) {
    // other point with default marker icon
    const defaultMarkerIconOptions = {
      size,
      color: asset?.color || feature.properties?.color || atlasConfig.defaultMarkerColor,
      className: feature?.properties?.name ? feature?.properties?.name.replace('.', '') : ''
    };
    return marker(latlng, {
      icon: createMapIcon(defaultMarkerIconOptions),
      bubblingMouseEvents: false
    });
  }

  public generateStatusMarker(latlng, state): Marker {
    switch (state) {
      case GeojsonFeatureState.IN_PROGRESS:
        return marker(latlng, {
          icon: createInProgressMarkerIcon(defaultMarkerSize),
          bubblingMouseEvents: false
        });
      case GeojsonFeatureState.COMPLETED:
        return marker(latlng, {
          icon: createCompletedMarkerIcon(defaultMarkerSize),
          bubblingMouseEvents: false
        });
      case GeojsonFeatureState.NOT_COMPLETED:
        return marker(latlng, {
          icon: createIncompletedMarkerIcon(defaultMarkerSize),
          bubblingMouseEvents: false
        });
      default:
        return marker(latlng, {
          icon: createInProgressMarkerIcon(defaultMarkerSize),
          bubblingMouseEvents: false
        });
    }
  }

  public generateMarker(feature, latlng, asset, markerSize = defaultMarkerSize) {
    const properties: any = feature?.properties;
    if (!properties) {
      console.warn('Empty geojson properties', feature);
      return;
    }

    const state = properties.state || feature.state;
    if (state) {
      return this.generateStatusMarker(latlng, state);
    }

    if (properties.boundingBox) {
      const iconColor = properties.color || '#c0c0c0';
      return this.generateHouseMarker(iconColor, latlng);
    }

    if (properties.iconHref && !!asset) {
      return this.generateCustomHrefMarker(asset, properties, latlng);
    }

    return this.generatePinMarker(asset, feature, latlng, markerSize);
  }

  public onEachFeature(feature, layer, asset, map) {
    const jobId = feature.properties.jobId;
    if (jobId) {
      const jobName = feature.properties?.jobName;
      const baseLayerId = feature.properties?.baseLayerId;
      this.addPolygonJobEvents(layer, jobId, jobName, asset, baseLayerId);
      return;
    }
    if (feature.properties.layerName === ASSET_PROPERTY_NAME.LGAS) {
      map.on('viewreset', () => {
        this.handleLgaTooltip(feature, layer, map);
      });
      map.on('zoomend', () => {
        this.handleLgaTooltip(feature, layer, map);
      });
    }

    layer.on('click', () => {
      layer.unbindPopup();
      layer.closePopup();

      if (this.atlasService.hasToBlockEvents) {
        return;
      }

      if (feature.geometry.type === ASSET_GEOMETRY_TYPE.POINT) {
        this.atlasService.setIsMarkerMenuOpened(true);
        this.stateMarkersOnClickEvent(feature, layer, asset, map);
        return;
      }
      this.drawBoundingBox(feature, map);
      const parsedDescription = this.parseDescription({assetName: asset.name, ...feature.properties});

      let popupContent = '';
      if (typeof feature.properties.description === 'string') {
        popupContent =
          (!!feature.properties.description && feature.properties.description.includes('img')
            ? parsedDescription
            : feature.properties.description) || this.getContent({assetName: asset.name, ...feature.properties});
      }

      if (!!asset.properties) {
        if (!!popupContent) {
          console.warn('Appending properties to existing description');
        }
        const mappedProperties = this.getPopupFromProperties(asset.properties, feature);
        popupContent = popupContent + mappedProperties;
      }
      this.setupPopup(layer, popupContent);
      layer.openPopup();
    });

    layer.on('mouseover', event => {
      this.atlasService.setCurrentPopupIndex(feature.index);
      if (this.atlasService.hasToHideCursor && layer._icon) {
        layer._icon.style.pointerEvents = 'none';
      }
      event.originalEvent.preventDefault();
      event.originalEvent.stopPropagation();

      if (this.atlasService.hasToBlockEvents) {
        return;
      }

      if (!this.atlasService.hasToShowInfoPopup) {
        return;
      }

      if (this.atlasService.isMarkerMenuOpened) {
        return;
      }

      if (feature.geometry.type === ASSET_GEOMETRY_TYPE.POINT) {
        this.atlasService.isPopupOpened
          .pipe(
            filter(isPopupOpened => !isPopupOpened && this.atlasService.currentPopupIndex === feature.index),
            take(1)
          )
          .subscribe(() => {
            this.atlasService.setCurrentPopupIndex(-1);
            if (feature.properties.rawPath) {
              this.stateMarkerOnHoverEvent(feature, layer, asset);
              return;
            }
            const popupContent = this.getContent({assetName: asset.name, ...feature.properties});
            this.setupPopup(layer, popupContent);
            layer.openPopup();
            if (this.atlasService.isCompareLayersOpen) {
              const popupContainer = document.querySelector('.leaflet-pane.leaflet-popup-pane');
              const container = document.querySelector('.leaflet-pane.leaflet-map-pane');
              const transformValue = window.getComputedStyle(container).getPropertyValue('transform');
              (popupContainer as HTMLElement).style.transform = transformValue;
            }
          });
      }
    });
    layer.on('mouseout', () => {
      this.atlasService.setCurrentPopupIndex(-1);
      if (!layer) {
        return;
      }
      if (this.atlasService.hasToBlockEvents) {
        return;
      }
      if (!this.atlasService.isMarkerMenuOpened) {
        this.handlePopupVisibility(layer);
      }
    });
  }

  private fetchFile(key: string): Promise<GeoJSON> {
    return this.atlasService.getAssetData<GeoJSON>(encodeURIComponent(key), 'json').catch(err => {
      return this.atlasService.handleError(key, err);
    });
  }

  private handlePopupVisibility(layer) {
    let isPopupHovered = false;
    const popupElement = layer?.getPopup()?.getElement();
    if (!popupElement) {
      return;
    }
    popupElement.addEventListener('mouseover', () => {
      this.atlasService.setCurrentPopupIndex(-1);
      isPopupHovered = true;
    });
    popupElement.addEventListener('mouseleave', () => {
      layer?.closePopup();
      this.atlasService.setCurrentPopupIndex(-1);
    });
    const userTimeRangeToHoverPopup = 300;
    timer(userTimeRangeToHoverPopup)
      .pipe(take(1))
      .subscribe(() => {
        if (!isPopupHovered) {
          layer?.closePopup();
        }
      });
  }

  private stateMarkerOnHoverEvent(feature, layer, asset): void {
    const component = createComponent(MarkerStatusInfoComponent, {
      environmentInjector: this.appRef.injector
    });
    component.instance.feature = feature;
    component.instance.assetName = asset.name;
    component.changeDetectorRef.detectChanges();
    layer.unbindPopup();
    layer.closePopup();
    layer.setPopupContent(null);
    layer.bindPopup(component.location.nativeElement, {
      closeButton: false,
      maxWidth: 281,
      autoPan: false,
      className: 'marker-state-hover'
    });
    layer.openPopup();
  }

  private stateMarkersOnClickEvent(feature, layer, asset, map) {
    const component = createComponent(MarkerStateMenuComponent, {
      environmentInjector: this.appRef.injector
    });
    component.instance.feature = feature;
    component.instance.layer = layer;
    component.instance.markerGenerator = this.generateStatusMarker.bind(this);
    component.instance.asset = asset;
    component.instance.mapZoom = map.getZoom();
    component.instance.getLibraryItem().subscribe(() => {
      component.instance.detectChanges();
    });
    component.instance.hasToCloseComponent.subscribe(() => {
      layer.closePopup();
      layer.unbindPopup();
    });
    component.instance.detectChanges();
    layer.unbindPopup();
    const popup = layer.bindPopup(component.location.nativeElement, {
      closeButton: false,
      offset: point(13, 0),
      minWidth: 244,
      autoPan: false,
      className: 'marker-state'
    });

    layer.openPopup();
    fromEvent(popup, 'popupclose')
      .pipe(takeWhile(() => this.atlasService.isMarkerMenuOpened))
      .subscribe(() => {
        this.atlasService.setIsMarkerMenuOpened(false);
      });
  }

  private setupTooltip(layer: Layer, layerName: string): void {
    if (!!layerName) {
      layer.bindTooltip(`<p class="tooltip">${layerName}</p>`, {
        direction: 'center',
        permanent: true,
        interactive: false,
        opacity: 0.8
      });
    }
  }

  private setupPopup(layer: Layer, popupContent: string): void {
    if (!!popupContent) {
      const popup = layer.bindPopup(popupContent, {
        closeButton: false,
        offset: point(200, 80),
        minWidth: 180,
        autoPan: false,
        className: 'feature-popup'
      });

      popup.on('popupclose', () => {
        popup.off('popupclose');
      });
    }
  }

  private handleLgaTooltip(feature: Feature<Geometry, any>, layer: Layer, map: Map) {
    if (
      map.getZoom() > 7 &&
      (feature.geometry.type === ASSET_GEOMETRY_TYPE.POLYGON ||
        feature.geometry.type === ASSET_GEOMETRY_TYPE.MULTI_POLYGON)
    ) {
      this.setupTooltip(layer, feature.properties.name);
      layer.openTooltip();
    } else {
      if (map.getZoom() <= 7) {
        layer.closeTooltip();
      }
    }
  }

  private drawBoundingBox(feature, map) {
    let bbox = feature.properties.boundingBox;
    if (!!bbox) {
      if (typeof bbox === 'string') {
        // the array will be a string if point was edited on geojson.io
        bbox = JSON.parse(bbox);
      }
      // lng lat => lat lng
      const cornerA: LatLngTuple = bbox[0];
      const cornerB: LatLngTuple = bbox[1];
      const latLngBox: LatLngTuple[] = [
        [cornerA[1], cornerA[0]],
        [cornerB[1], cornerB[0]]
      ];
      const polygon = rectangle(latLngBox, {color: feature.properties.color});
      polygon.addTo(map);
      // remove box on map click
      map.on('click', () => {
        polygon.removeFrom(map);
      });
    }
  }

  /** Icon path is relative to the asset file
   * */
  private getIconUrl(fileKey, iconHref) {
    if (iconHref.startsWith('http')) {
      return iconHref;
    } // support direct url icons
    const fileKeySplitted = fileKey.split('/');
    const folderKey = fileKeySplitted.slice(0, fileKeySplitted.length - 1).join('/');
    if (!iconHref.startsWith('/') && !folderKey.endsWith('/')) {
      iconHref = `/${iconHref}`;
    }
    const s3IconKey = folderKey + iconHref;

    return `${environment.atlasCDNDomain}/${s3IconKey}`;
  }

  /** Map selected properties to display in the popup */
  private getPopupFromProperties(properties: AssetProperty[], feature: any): string {
    if (!properties) {
      return '';
    }
    let content: string = '\n';
    properties.forEach((property: AssetProperty): void => {
      if (properties.length === 1) {
        content = `<h2>${feature.properties[property.key]}</h2>`;
      } else {
        content += `<p><strong>${property.displayName}:</strong> ${feature.properties[property.key]}</p>`;
      }
    });
    return content;
  }

  private addBladeProperties(properties) {
    properties['Blades'].forEach(blade => {
      properties[blade.label] = blade.serial;
    });
  }

  private getContent(properties: any, latlng?: LatLngLiteral): string {
    if (!properties || Object.keys(properties).length === 0) {
      return '';
    }
    let content: string = "<div class='content'>";
    this.addBladeProperties(properties);
    Object.keys(properties)
      .filter(this.isNotIgnoredKey)
      .forEach((key: string): void => {
        let property: string = properties[key];
        if (!property || property === '' || key === 'layerName') {
          // empty value for key
          return;
        }
        if (key === 'assetName') {
          content += `<h1 class="property__header"><img class="property__header-image" src='/assets/images/list.svg'>
                      <span class="property__header-text">${property}</span>
                      </h1>`;
          return;
        }
        // sanitize the html to remove dangerous <script> tag
        property = this.sanitizer.sanitize(SecurityContext.HTML, property);
        property = property.replace(/&#10;/g, '<br>');
        if (property.startsWith('http')) {
          property = `<a href='${property}' target="_blank" >${property}</a>`;
        }
        content += `<div class="property"> <p class="property__key">${this.normalizeKeyName(
          key
        )}</p><p class="property__value">${property}</p></div>`;
      });
    if (!!latlng && !!latlng.lat && !!latlng.lng) {
      content += `<div class="property"> <p class="property__key">LOCATION:</p><p class="property__value">${this.atlasService.convertDMS(
        latlng.lat,
        latlng.lng
      )}</p></div>`;
    }
    content += '</div>';
    if (content === "<div class='content'></div>") {
      return '';
    }
    return content;
  }

  private normalizeKeyName(key: string) {
    key = key.replace(/_/g, ' ');
    key = key.replace(/-/g, ' ');
    return key.toLocaleLowerCase();
  }

  private isNotIgnoredKey(key: any) {
    // properties we don't want to display in the popup
    const ignorePropertiesList: string[] = [
      'marker-color',
      'marker-size',
      'marker-symbol',
      'color',
      'boundingbox',
      'stroke',
      'iconhref',
      'iconscale',
      'state',
      'icon-color',
      'icon-opacity',
      'icon-scale',
      'icon',
      'styleurl',
      'label-color',
      'label-opacity',
      'label-scale',
      'stroke-width',
      'blades'
    ];
    return ignorePropertiesList.indexOf(key.toLocaleLowerCase()) === -1;
  }

  private parseDescription(properties) {
    const thumbParams: ThumblerParams = {
      width: '400',
      height: '400',
      quality: '75'
    };
    const parsedUrl = ParamsHelper.getUrlWithParams(
      `${environment.THUMBLER_API_CDN}/${ThumblerSourceCategoryModel.library}/${properties.rawPath}`,
      thumbParams
    );
    return `
    <h1 class="property__header"><img class="property__header-image" src='/assets/images/list.svg'>
    <span class="property__header-text">${properties.assetName}</span>
    </h1>
    <img class="popup-image" src='${parsedUrl}'>`;
  }

  private catchAssetsErrors(asset: AtlasGeojsonAssetModel): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!asset || !asset.name || !asset.key) {
        console.warn('Asset missing data');
        resolve('Asset missing data');
      }
      if (!asset.type || asset.type !== AssetType.GEOJSON) {
        console.warn('Atlas geojson: unsupported asset type: ' + asset.type);
        resolve('Atlas geojson: unsupported asset type: ' + asset.type);
      }
      resolve(null);
    });
  }
  private addPolygonJobEvents(layer, jobId, jobName, asset, baseLayerId) {
    layer.on('click', () => {
      const component = createComponent(JobPolygonMenuComponent, {
        environmentInjector: this.appRef.injector
      });
      component.instance.jobId = jobId;
      component.instance.jobName = jobName;
      component.instance.baseAsset = this.atlasService.getAssetById(baseLayerId);
      component.instance.polygonAsset = this.atlasService.getAssetById(asset.id);
      component.instance.map = layer._map;
      component.instance.hasToCloseComponent.subscribe(() => {
        layer.closePopup();
        layer.unbindPopup();
      });
      component.changeDetectorRef.detectChanges();
      layer.bindPopup(component.location.nativeElement, {
        closeButton: false,
        maxWidth: 172,
        autoPan: false,
        className: 'marker-state-hover'
      });
      layer.openPopup();
    });
    if (jobName) {
      layer.on('mouseover', () => {
        layer.bindTooltip(jobName, {
          closeButton: false,
          maxWidth: 172,
          autoPan: false,
          className: 'shape-mouse-over'
        });
        layer.openTooltip();
      });
    }
  }
}

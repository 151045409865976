import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  templateUrl: './atlas-snackbar-message.component.html',
  styleUrls: ['./atlas-snackbar-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlasSnackbarMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}

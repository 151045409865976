import {createReducer, on} from '@ngrx/store';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {StreamingErrors} from '../models/streaming-errors.models';
import * as fromActions from './live.actions';
import {initialState, LiveAnalyzeState} from './live.state';
import {GridLayout, GridState} from '../models/grid-state.model';

export const liveFeatureKey = 'liveStore';

export const liveReducer = createReducer(
  initialState,
  on(
    fromActions.enablePublicLivePage,
    (state: LiveAnalyzeState): LiveAnalyzeState => ({
      ...state,
      isSharedBroadcast: true
    })
  ),
  on(
    fromActions.loadingSlug,
    (state: LiveAnalyzeState): LiveAnalyzeState => ({
      ...state,
      isLoadingSlug: !state.isLoadingSlug
    })
  ),
  on(
    fromActions.enablingLivePage,
    (state: LiveAnalyzeState): LiveAnalyzeState => ({
      ...state,
      isEnablingLivePage: !state.isEnablingLivePage
    })
  ),
  on(
    fromActions.updateErrorMessage,
    (state: LiveAnalyzeState, {errorMessage}: {errorMessage: string}): LiveAnalyzeState => ({
      ...state,
      errorMessage: errorMessage
    })
  ),
  on(
    fromActions.updateErrorCode,
    (state: LiveAnalyzeState, {errorCode}: {errorCode: StreamingErrors}): LiveAnalyzeState => ({
      ...state,
      errorCode: errorCode
    })
  ),
  on(
    fromActions.updateDevice,
    (state: LiveAnalyzeState, {device}: {device: UserDeviceJoined}): LiveAnalyzeState => ({
      ...state,
      device: device
    })
  ),
  on(
    fromActions.removeLiveDevice,
    (state: LiveAnalyzeState, {payload}: {payload: {device: UserDeviceJoined}}): LiveAnalyzeState => ({
      ...state,
      liveDevicesId: state.liveDevicesId.filter(id => id !== payload.device.id)
    })
  ),
  on(
    fromActions.setLiveDevicesId,
    (state: LiveAnalyzeState, {payload}: {payload: {liveDevicesId: string[]}}): LiveAnalyzeState => ({
      ...state,
      liveDevicesId: payload.liveDevicesId
    })
  ),
  on(fromActions.setIsInitialized, (state: LiveAnalyzeState, {payload}: {payload: {isInitialized: boolean}}) => ({
    ...state,
    isInitialized: payload.isInitialized
  })),
  on(fromActions.setLayoutState, (state: LiveAnalyzeState, {payload}: {payload: {layoutState: GridLayout}}) => ({
    ...state,
    layoutState: payload.layoutState
  })),
  on(
    fromActions.setIsSelectedDeviceRunningModel,
    (state: LiveAnalyzeState, {payload}: {payload: {isSelectedDeviceRunningModel: boolean}}) => ({
      ...state,
      isSelectedDeviceRunningModel: payload.isSelectedDeviceRunningModel
    })
  ),
  on(
    fromActions.setDisplayedDeviceIds,
    (state: LiveAnalyzeState, {payload}: {payload: {displayedDeviceIds: string[]}}) => ({
      ...state,
      displayedDeviceIds: payload.displayedDeviceIds
    })
  ),
  on(fromActions.setSelectedDeviceId, (state: LiveAnalyzeState, {payload}: {payload: {selectedDeviceId: string}}) => ({
    ...state,
    selectedDeviceId: payload.selectedDeviceId
  })),
  on(fromActions.updateGridState, (state: LiveAnalyzeState, {payload}: {payload: {gridState: GridState}}) => ({
    ...state,
    gridState: {...state.gridState, ...payload.gridState}
  }))
);

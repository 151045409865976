<form class="form" [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" placeholder="Email" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Team</mat-label>
    <mat-select formControlName="teamId" placeholder="Team">
      <mat-option *ngFor="let team of teams" [value]="team.id">
        {{ team.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Role</mat-label>
    <mat-select formControlName="role" placeholder="Role">
      <mat-option *ngFor="let role of roles" [value]="role">
        {{ role }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

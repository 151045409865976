import {CameraMode, FlightModeLevelState, IoTSensorsData, SignalStrength} from './remote-cockpit.model';
import {MissionState} from '../components/remote-cockpit/remote-cockpit-control-tile/mission-tile/mission-state.model';

export const iotSensorDataMock: IoTSensorsData = {
  alt: 10.0,
  batt: [36],
  flightMode: 'P-GPS',
  gimPitch: -26.7,
  gimRoll: 0.0,
  gimYaw: 9.9,
  gpsLevel: 2,
  lat: 0.001,
  lng: 0.001,
  pitch: -0.2,
  roll: 3.6,
  timestamp: 1534795198765,
  velX: 3.0,
  velY: 4.0,
  velZ: 8.0,
  yaw: 100.0,
  signalStrength: SignalStrength.GOOD,
  remoteAccess: 1,
  height: 5,
  cameraMode: CameraMode.BROADCAST,
  isFlying: false,
  isHoldOn: true,
  isMissionInProgress: false,
  missionState: MissionState.DISCONNECTED,
  isRecording: false,
  timeLeft: 0,
  flightModeStatus: 'GOOD' as FlightModeLevelState,
  distHome: 10.0,
  timeElapsed: 88.0,
  missionTimeRemaining: 140.1,
  altEGM96: 13.22
};

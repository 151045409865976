<p class="device__title">{{ 'live.device.label' | translate }}</p>

<mat-tab-group class="tab-group" (selectedTabChange)="logTabSwitch($event)">
  <mat-tab label="{{ 'live.device.table.headers.0' | translate }}">
    <app-device-table [dataSource]="allDevices$ | async"></app-device-table>
  </mat-tab>
  <mat-tab label="{{ 'live.device.table.headers.1' | translate }}">
    <app-device-table [dataSource]="onlineDevices$ | async"></app-device-table>
  </mat-tab>
  <mat-tab label="{{ 'live.device.table.headers.2' | translate }}">
    <app-device-table [dataSource]="offlineDevices$ | async"></app-device-table>
  </mat-tab>
  <mat-tab label="{{ 'live.device.table.headers.3' | translate }}">
    <app-device-table [dataSource]="favouriteDevices$ | async"></app-device-table>
  </mat-tab>
  <mat-tab disabled>
    <ng-template mat-tab-label>
      <div class="sort-container">
        {{ 'live.device.sortLabel' | translate
        }}<button class="btn-sort" (click)="sortData(sortType, true)" [analyticsTrackerType]="events.DEVICES_SORTED">
          <div>
            {{ (sortType === 'updatedAt' ? 'live.device.sortType.recent' : 'live.device.sortType.a-z') | translate }}
          </div>
          <img src="assets/icons/live-stream/device-table/history.svg" class="filter-gray ml-10" alt="history" />
        </button>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

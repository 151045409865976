import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RoundPipe} from '@app/shared/pipes/round.pipe';
import {PinchZoomComponent} from './pinch-zoom.component';

@NgModule({
  declarations: [PinchZoomComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, RoundPipe],
  exports: [PinchZoomComponent],
  providers: [],
  bootstrap: []
})
export class PinchZoomModule {}

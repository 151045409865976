export function addToArray(item: string, array: string[]): string[] {
  return array.concat([item]);
}

export function getArrayUntilGivenIndex(index: number, array: string[]): string[] {
  if (index > -1) {
    array = array.slice(0, index);
    return array;
  }
  return [];
}

export function deleteLastArrayItem(array: string[]): string[] {
  const pathIndex = array.length - 1;
  array = array.slice(0, pathIndex);
  return array;
}

export function getPreviousArrayItem(array: string[]): string {
  if (array.length > 1) {
    const previousPathIndex = array.length - 2;
    const pathId = array[previousPathIndex];
    return pathId;
  }
  return '';
}

export function replaceLastItemArray(item: string, array: string[]): string[] {
  if (array.length > 1) {
    return [...array.slice(0, array.length - 1), item];
  }
  return [item];
}

<div class="header">
  <div class="header__links">
    <p class="header__text">
      {{'auth.signUp.alreadyHave' | translate}}
      <span class="header__link" (click)="signIn()">{{'common.signIn' | translate}}</span>
    </p>
  </div>
</div>

<div class="spacer"></div>

<unleash-alert
  class="unleash-alert"
  *ngIf="errorMessage$ | async as errorMessage"
  type="error"
  [label]="errorMessage"
  iconName="close"
></unleash-alert>

<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>

<div class="title">
  <ng-container *ngIf="isOrganizationSignUp$ | async; else otherTitlesTemplate">
    <div *ngIf="organizationName$ | async as organizationName" class="title__text">
      {{'auth.signUp.withOrg' | translate: organizationName}}
    </div>
  </ng-container>
  <ng-template #otherTitlesTemplate>
    <ng-container *ngIf="hasAwsToken$ | async; else simpleSignUpTemplate">
      <div class="title__text">{{'auth.signUp.withAws' | translate}}</div>
      <div class="title__sub-text">{{'auth.signUp.withAwsHelp' | translate}}</div>
    </ng-container>
    <ng-template #simpleSignUpTemplate>
      <div class="title__text">
        {{ isDeveloperMode ? ('auth.signUp.developer' | translate) : ('common.signUp' | translate) }}
      </div>
      <div class="title__sub-text">{{'auth.signUp.signUpHelp' | translate}}</div>
    </ng-template>
  </ng-template>
</div>

<unleash-sign-up
  class="form form--fixed"
  (signUp)="signUp($event)"
  [signInForm]="signInForm$ | async"
  [inProgress]="inProgress$ | async"
  [isOrganizationSignUp]="isOrganizationSignUp$ | async"
></unleash-sign-up>

<footer class="footer">
  {{'auth.signUp.alreadyHave' | translate}}
  <span class="footer__link" (click)="signIn()">{{'common.signIn' | translate}}</span>
</footer>

<div class="spacer"></div>

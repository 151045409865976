import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'unleash-loader',
  templateUrl: './unleash-loader.component.html',
  styleUrls: ['./unleash-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnleashLoaderComponent {
  @Input() public visible: boolean = true;
  @Input() public showLogo?: boolean = false;
  @Input() public text: string = '';
  @Input() public color: string = 'black';
  @Input() public whiteSpinner?: boolean = false;
}

<div class="container" #container (resized)="resizeChanges()">
  <div
    #drawZones
    *ngIf="!!src"
    [id]="canvasId"
    appMousemove
    (throttledMousemove)="isMouseMoveActive ? emitMouseMoveEvent($event) : null"
    (click)="emitMouseOverEvent($event)"
    (mouseleave)="isMouseMoveActive ? emitMouseLeaveEvent($event) : null"
  >
    <img
      #imgRefMidRes
      class="fit-image"
      id="imageToLoad"
      [src]="srcMidRes"
      (error)="hideMidResImageOnFail()"
      [style.visibility]="isMidResVisible ? 'visible' : 'hidden'"
    />
    <img
      #imgRefLowRes
      class="fit-image"
      [src]="isHiResVisible ? null : srcLowRes"
      (load)="isHiResVisible ? null : setupCanvas(imgRefLowRes, canvasImageQuality.lowRes)"
      (error)="hideLowResImageOnFail()"
      [style.visibility]="isLowResVisible ? 'visible' : 'hidden'"
    />
    <img
      #imgRef
      id="imageToLoadFull"
      [src]="src"
      (load)="setupCanvas(imgRef, canvasImageQuality.hiRes)"
      [style.visibility]="isHiResVisible ? 'visible' : 'hidden'"
    />

    <img
      class="mask--final"
      *ngIf="maskImg"
      (load)="hasToDisplayPhosphorescent = true"
      [src]="maskImg"
      [style.visibility]="!imgHoverMask ? 'visible' : 'hidden'"
    />
    <img
      class="mask"
      [class.phosphorescent]="hasToDisplayPhosphorescent"
      *ngIf="imgHoverMask"
      [src]="imgHoverMask"
      (load)="hasToDisplayPhosphorescent = false"
    />
  </div>
</div>

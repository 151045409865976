import {Pipe, PipeTransform} from '@angular/core';
import {FILE_EXTENSION} from '@app/analysis/models/analysis.model';

@Pipe({
  name: 'appFileExtensionName'
})
export class FileExtensionNamePipe implements PipeTransform {
  public transform(filePath: string): string {
    const fileExtensions: FILE_EXTENSION[] = Object.values(FILE_EXTENSION);

    return fileExtensions
      .find((extension: FILE_EXTENSION): boolean => filePath.includes(extension))
      ?.toLocaleUpperCase();
  }
}

export enum ThumblerDefaultImagesSrc {
  img = 'assets/images/placeholder.png',
  folder = 'assets/images/placeholder.png',
  newFolderPlaceholder = 'assets/icons/library/folder_24px.svg',
  model = 'assets/images/placeholder.png',
  specialFolderResults = 'assets/images/library/folder-sync-results.svg',
  specialFolderArchive = 'assets/images/library/folder-sync-archive.svg',
  flightLogCard = 'assets/images/library/flight-log-card.png'
}

export enum ThumblerSourceCategoryModel {
  account = 'account',
  library = 'library',
  addon = 'addon',
  device = 'device'
}

export interface ThumblerImageConfig {
  sourceCategory: string;
  imgResolution: {
    width?: string;
    height?: string;
    quality?: string;
  };
}

export interface ThumblerAvailableConfigs {
  librarySmallTile: ThumblerImageConfig;
  libraryTile: ThumblerImageConfig;
  libraryLarge: ThumblerImageConfig;
  sideBarCompanyThumbLogo: ThumblerImageConfig;
  organisationCompanyLogo: ThumblerImageConfig;
  addonLogo: ThumblerImageConfig;
  addonScreenShot: ThumblerImageConfig;
  addonStoreLogo: ThumblerImageConfig;
  deviceThumbLogo: ThumblerImageConfig;
  deviceLogo: ThumblerImageConfig;
}

export const THUMBLER_AVAILABLE_CONFIGS = {
  librarySmallTile: {
    sourceCategory: ThumblerSourceCategoryModel.library,
    imgResolution: {
      width: '48',
      height: '48',
      quality: '75'
    }
  },
  libraryTile: {
    sourceCategory: ThumblerSourceCategoryModel.library,
    imgResolution: {
      width: '400',
      height: '400',
      quality: '75'
    }
  },
  libraryLarge: {
    sourceCategory: ThumblerSourceCategoryModel.library,
    imgResolution: {
      width: '1200',
      quality: '60'
    }
  },
  sideBarCompanyThumbLogo: {
    sourceCategory: ThumblerSourceCategoryModel.account,
    imgResolution: {
      width: '96',
      height: '96',
      quality: '75'
    }
  },
  organisationCompanyLogo: {
    sourceCategory: ThumblerSourceCategoryModel.account,
    imgResolution: {
      width: '400',
      height: '400',
      quality: '75'
    }
  },
  addonLogo: {
    sourceCategory: ThumblerSourceCategoryModel.addon,
    imgResolution: {
      width: '400',
      height: '400',
      quality: '75'
    }
  },
  addonScreenShot: {
    sourceCategory: ThumblerSourceCategoryModel.addon,
    imgResolution: {
      width: '400',
      height: '400',
      quality: '75'
    }
  },
  addonStoreLogo: {
    sourceCategory: ThumblerSourceCategoryModel.addon,
    imgResolution: {
      width: '515',
      height: '190',
      quality: '100'
    }
  },
  deviceThumbLogo: {
    sourceCategory: ThumblerSourceCategoryModel.device,
    imgResolution: {
      width: '100',
      height: '100',
      quality: '100'
    }
  },
  atlasDeviceThumbLogo: {
    sourceCategory: ThumblerSourceCategoryModel.device,
    imgResolution: {
      width: '24',
      height: '24',
      quality: '75'
    }
  },
  deviceLogo: {
    sourceCategory: ThumblerSourceCategoryModel.device,
    imgResolution: {
      width: '400',
      height: '400',
      quality: '100'
    }
  },
  annotationsSummaryTile: {
    sourceCategory: ThumblerSourceCategoryModel.library,
    imgResolution: {
      width: '32',
      height: '',
      quality: '75'
    }
  }
};

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'unleash-delete-team-dialog',
  templateUrl: './delete-team-dialog.component.html',
  styleUrls: ['./delete-team-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteTeamDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {team: {teamId: string; name: string}}
  ) {}

  public emitCancel(): void {
    this.dialogRef.close({hasToDelete: false});
  }

  public emitDelete(): void {
    this.dialogRef.close({hasToDelete: true, teamId: this.data.team.teamId});
  }
}

import {icon} from 'leaflet';

export const createMapIcon = ({size, color, className}: {size: number[]; color: string; className?: string}) => {
  const customSvg = `
  <svg width="${size[0]}" height="${size[1]}" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9" cy="8.5" r="3.5" fill="white"/>
  <path d="M9.00016 11.4168C8.22661 11.4168 7.48475 11.1095 6.93777 10.5626C6.39079 10.0156 6.0835 9.27371 6.0835 8.50016C6.0835 7.72661 6.39079 6.98475 6.93777 6.43777C7.48475 5.89079 8.22661 5.5835 9.00016 5.5835C9.77371 5.5835 10.5156 5.89079 11.0626 6.43777C11.6095 6.98475 11.9168 7.72661 11.9168 8.50016C11.9168 8.88318 11.8414 9.26246 11.6948 9.61632C11.5482 9.97019 11.3334 10.2917 11.0626 10.5626C10.7917 10.8334 10.4702 11.0482 10.1163 11.1948C9.76246 11.3414 9.38318 11.4168 9.00016 11.4168ZM9.00016 0.333496C6.83423 0.333496 4.757 1.19391 3.22546 2.72546C1.69391 4.257 0.833496 6.33423 0.833496 8.50016C0.833496 14.6252 9.00016 23.6668 9.00016 23.6668C9.00016 23.6668 17.1668 14.6252 17.1668 8.50016C17.1668 6.33423 16.3064 4.257 14.7749 2.72546C13.2433 1.19391 11.1661 0.333496 9.00016 0.333496Z" fill="${color}"/>
  </svg>`;
  const svgAsURI = 'data:image/svg+xml;base64,' + btoa(customSvg).replace('#', '%23');
  return icon({
    iconUrl: svgAsURI,
    attribution: color,
    className,
    iconAnchor: [size[0] / 2, size[1]]
  });
};

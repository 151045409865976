import {Injectable} from '@angular/core';
import {AddonZones, ZoneConfig} from '../models/annotations.model';

@Injectable({
  providedIn: 'root'
})
export class UnleashAnnotationCanvasService {
  private zonesConfig: ZoneConfig;

  public async save(modelId: string, addZones: AddonZones, saveZones: (addonZones: AddonZones) => void) {
    this.zonesConfig = {[modelId]: addZones};
    const addonZones = this.zonesConfig[modelId] as AddonZones;

    if (!this.zonesConfig) {
      this.zonesConfig = {};
      saveZones(null);
      return;
    }

    if (!addonZones) {
      saveZones(null);
      return;
    }

    saveZones(addonZones);
  }
}

<div class="analysis-filter">
  <section class="analysis-filter__header">
    <h1 class="analysis-filter__header-title">{{ 'analysis.filters.filters' | translate }}</h1>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </section>
  <mat-divider></mat-divider>
  <section>
    <form class="analysis-filter__content" [formGroup]="form" (ngSubmit)="filter()">
      <mat-form-field appearance="fill">
        <mat-icon class="analysis-filter__icon" [class.full]="form.value.search" matPrefix>search</mat-icon>
        <mat-icon *ngIf="form.value.search" (click)="clearData('search')" class="analysis-filter__icon" matSuffix
          >close</mat-icon
        >
        <mat-label> {{ 'analysis.filters.reportName' | translate }}</mat-label>
        <input matInput formControlName="search" />
      </mat-form-field>
      <mat-form-field class="analysis-filter__date" [class.full-date]="form.value.from" appearance="fill">
        <mat-icon *ngIf="form.value.from" (click)="clearData('from')" class="analysis-filter__icon" matSuffix
          >close</mat-icon
        >
        <mat-label>{{ 'analysis.filters.from' | translate }}</mat-label>
        <mat-datepicker-toggle matPrefix [for]="fromPicker"></mat-datepicker-toggle>
        <input
          (click)="fromPicker.open()"
          [max]="currentDate"
          matInput
          formControlName="from"
          [matDatepicker]="fromPicker"
        />
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="analysis-filter__date" [class.full-date]="form.value.to" appearance="fill">
        <mat-icon *ngIf="form.value.to" (click)="clearData('to')" class="analysis-filter__icon" matSuffix
          >close</mat-icon
        >
        <mat-label>{{ 'analysis.filters.to' | translate }}</mat-label>
        <mat-datepicker-toggle matPrefix [for]="toPicker"></mat-datepicker-toggle>
        <input (click)="toPicker.open()" [max]="currentDate" matInput formControlName="to" [matDatepicker]="toPicker" />
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-icon
          class="analysis-filter__icon"
          svgIcon="double-camera"
          [class.full]="form.value.type"
          matPrefix
        ></mat-icon>
        <mat-icon
          *ngIf="form.value.type"
          (click)="clearData('type'); $event.stopPropagation(); $event.preventDefault()"
          class="analysis-filter__icon"
          matSuffix
          >close</mat-icon
        >
        <mat-label>{{ 'analysis.filters.type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of types" [value]="type.value">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        *ngIf="hasClear"
        (click)="resetFilters()"
        class="analysis-filter__clear-filters"
        mat-button
        color="primary"
      >
        <mat-icon class="analysis-filter__clear-icon" svgIcon="clear-filters"></mat-icon>
        {{ 'analysis.filters.clearFilters' | translate }}
      </button>
      <button type="submit" class="analysis-filter__done" mat-button>{{ 'analysis.filters.done' | translate }}</button>
    </form>
  </section>
</div>

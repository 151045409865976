import {Component, ChangeDetectionStrategy, EventEmitter, Input, Output} from '@angular/core';
import {TeamRole} from '@app/profile/models/team.model';
import {UserTeamTileScreen} from '@app/profile/models/user-team-tile-screen.model';
import {
  UserTeamAction,
  UserTeamInvitation,
  UserTeamTable,
  UserTeamTableActions
} from '@app/profile/models/user-team.model';
import {Roles, UserAction, UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-user-teams-tile',
  templateUrl: './user-teams-tile.component.html',
  styleUrls: ['./user-teams-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTeamsTileComponent {
  @Input() public hasToDisplaySubtitle: boolean = true;
  @Input() public userTeams: UserTeamTable[] = [];
  @Input() public selectedUser: UserTable = {email: '', userId: '0', lastActive: 0, name: '', role: TeamRole.viewer};
  @Input() public selectedUserTeam: UserTeamTable | null = null;
  @Input() public menuItems: UserTeamTableActions[] = [];
  @Input() public userTeamsScreen: UserTeamTileScreen = UserTeamTileScreen.userTeams;
  @Input() public roles: Roles = {};
  @Output() public addUsers: EventEmitter<void> = new EventEmitter();
  @Output() public searchTeam: EventEmitter<string> = new EventEmitter();
  @Output() public invite: EventEmitter<UserTeamInvitation> = new EventEmitter();
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();
  @Output() public userTeam: EventEmitter<UserTeamTable> = new EventEmitter();
  @Output() public action: EventEmitter<UserTeamAction> = new EventEmitter();
  @Output() public userTeamAction: EventEmitter<UserAction> = new EventEmitter();

  public userTeamTileScreen = UserTeamTileScreen;

  public performSearch(search: string): void {
    this.searchTeam.emit(search);
  }

  public emitAddUsers(): void {
    this.addUsers.emit();
  }

  public emitInvite(userInvitation: UserTeamInvitation): void {
    this.invite.emit({...userInvitation, userId: this.selectedUser.userId});
  }

  public emitCancel(): void {
    this.cancel.emit();
  }

  public emitBack(): void {
    this.back.emit();
  }

  public emitUserTeam(userTeam: UserTeamTable): void {
    this.userTeam.emit(userTeam);
  }

  public emitAction(action: UserTeamAction): void {
    this.action.emit(action);
  }

  public emitUserTeamAction(action: UserTeamAction): void {
    this.userTeamAction.emit(action);
  }
}

<div class="calibration-add" [ngSwitch]="currentCalibrationSidebarMode$ | async">
  <ng-template [ngSwitchCase]="calibrationSidebarMode.POINT_LIST">
    <div class="calibration-add__content">
      <div class="calibration-add__header">
        <button (click)="emitBackToCalibrationMainView()" mat-icon-button class="calibration-add__back-button">
          <mat-icon class="calibration-add__back-icon">arrow_back</mat-icon>
        </button>
        <h2 class="calibration-add__title">{{ 'calibration.addCalibration' | translate }}</h2>
      </div>
      <mat-divider></mat-divider>
      <div class="calibration-add__type">
        <section class="calibration-add__section-container">
          <h2 class="calibration-add__subtitle">{{ 'calibration.type' | translate }}</h2>
          <p class="calibration-add__text">{{ 'calibration.sceneMapping' | translate }}</p>
        </section>
        <section class="calibration-add__section-container">
          <h2 class="calibration-add__subtitle">{{ 'calibration.markerColour' | translate }}</h2>
          <unleash-color-picker
            [selectedColor]="selectedColor$ | async"
            [isLeftLayout]="true"
            (updateColorTag)="emitColor($event)"
          ></unleash-color-picker>
        </section>
      </div>
      <div class="calibration-add__total-points">
        <h2 class="calibration-add__subtitle">
          {{ 'calibration.calibrationPoints' | translate }} ({{ (pointsLatLng$ | async).length }})
        </h2>
        <button *ngIf="(pointsLatLng$ | async).length > 0" mat-button color="primary" (click)="isReadyToAddPoint()">
          <mat-icon class="calibration-add__add-icon">add</mat-icon>
          <span class="calibration-add__add"> {{ 'calibration.add' | translate }} </span>
        </button>
      </div>
      <div *ngIf="(pointsLatLng$ | async).length === 0; else pointsListTemplate" class="calibration-add__no-points">
        <span class="calibration-add__no-points-message">
          {{ 'calibration.noCalibrationPoints' | translate }}
        </span>
        <button (click)="isReadyToAddPoint()" mat-button color="primary">
          <mat-icon class="calibration-add__add-icon">location_on</mat-icon>
          <span> {{ 'calibration.addPoint' | translate }} </span>
        </button>
      </div>
      <ng-template #pointsListTemplate>
        <div class="calibration-add__point-container">
          <div
            (mouseover)="emitHightLightMarkerByIndex(i + 1)"
            (mouseleave)="emitHightLightMarkerByIndex(-1)"
            class="calibration-add__point"
            [class.highlight]="(hightLightMarkerByIndex$ | async) === i + 1"
            *ngFor="let point of pointsLatLng$ | async; let i = index"
          >
            <span class="calibration-add__point-text" (click)="enableEditMode(i)">
              {{ 'calibration.point' | translate }} {{ i + 1 }}
            </span>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matMenuTriggerData]="{index: i}"
              class="calibration-add__point-menu"
            >
              <div class="calibration-add__point-menu-item">
                <mat-icon class="calibration-add__point-menu-icon">more_vert</mat-icon>
              </div>
            </button>
          </div>
          <div *ngIf="(pointsLatLng$ | async).length < 12" class="calibration-add__info">
            <mat-icon>info_outline</mat-icon>
            {{ 'calibration.recommendedPoints' | translate }}
          </div>
          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-index="index">
              <button (click)="enableEditMode(index)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span> {{ 'calibration.edit' | translate }}</span>
              </button>
              <button (click)="deletePointByIndex(index)" mat-menu-item class="calibration-add__point-edit">
                <mat-icon>delete</mat-icon>
                <span> {{ 'calibration.delete' | translate }}</span>
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </ng-template>
    </div>
    <div class="calibration-add__actions">
      <button
        mat-button
        color="primary"
        class="calibration-add__cancel-button"
        (click)="emitBackToCalibrationMainView()"
      >
        {{ 'calibration.cancel' | translate }}
      </button>
      <button
        [appLadda]="isSaving$ | async"
        [disabled]="isSaving$ | async"
        [mtxTooltip]="tooltipTpl"
        [mtxTooltipDisabled]="(pointsLatLng$ | async).length >= 20"
        mat-raised-button
        color="primary"
        class="calibration-add__confirm-button"
        (click)="finishSceneMapping()"
      >
        {{ 'calibration.finishSceneMapping' | translate }}
      </button>
      <ng-template #tooltipTpl>
        <div class="tooltip-container">
          <span class="tooltip-container__text">{{ 'calibration.perspectiveTooltipTitle' | translate }}</span>
          <ul class="tooltip-container__list">
            <li>{{ 'calibration.recommendedPoints' | translate }}</li>
          </ul>
        </div>
      </ng-template>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="calibrationSidebarMode.ADD_POINT">
    <div class="calibration-add__header">
      <button (click)="addPointBack()" mat-icon-button class="calibration-add__back-button">
        <mat-icon class="calibration-add__back-icon">arrow_back</mat-icon>
      </button>
      <h2 class="calibration-add__title">
        {{ 'calibration.addPoint' | translate }} {{ (pointsLatLng$ | async).length + 1 }}
      </h2>
    </div>
    <mat-divider></mat-divider>
    <div class="calibration-add__add-marker">
      <img src="/assets/icons/manager-zones/calibration/add_marker_to_image.svg" alt="Add marker to image" />
      <h2 class="calibration-add__add-marker-title">{{ 'calibration.addMarkerToImage' | translate }}</h2>
      <p class="calibration-add__add-marker-info">{{ 'calibration.clickToAddMarker' | translate }}</p>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="calibrationSidebarMode.ADD_MARKER_IN_MAP">
    <div class="calibration-add__header">
      <button (click)="addPointBack()" mat-icon-button class="calibration-add__back-button">
        <mat-icon class="calibration-add__back-icon">arrow_back</mat-icon>
      </button>
      <h2 class="calibration-add__title">
        {{ 'calibration.addPoint' | translate }} {{ (pointsLatLng$ | async).length + 1 }}
      </h2>
    </div>
    <mat-divider></mat-divider>
    <div (click)="addPointBack()" class="calibration-add__canvas">
      <unleash-image-annotation-shared
        *ngIf="imageSnapshot$ | async as image"
        [canvasId]="'calibration'"
        (canvasLoaded)="loadMarker()"
        [srcLowRes]="image.data | uThumbler: thumblerConfig"
        [isMobileView]="isMobileView$ | async"
        [src]="image.data | uThumbler: thumblerConfig"
        [drawStatus]="drawStatus$ | async"
        [style.width]="(isLoadingImage$ | async) ? '0' : '100%'"
      ></unleash-image-annotation-shared>
    </div>
    <div class="calibration-add__add-marker top">
      <mat-tab-group (focusChange)="setCanLoadLayersAddMode(true)">
        <mat-tab label="Points">
          <div class="calibration-add__tab">
            <img
              class="calibration-add__add-marker-img"
              src="/assets/icons/manager-zones/calibration/add_marker_to_map.svg"
              alt="Add marker to map"
            />
            <h2 class="calibration-add__add-marker-title">{{ 'calibration.addMarkerOnMap' | translate }}</h2>
            <p class="calibration-add__add-marker-info">
              {{ 'calibration.addMarkerOnMapDescription' | translate }}
            </p>
            <div class="calibration-add__or">
              <div class="calibration-add__or-line"></div>
              {{ 'calibration.or' | translate }}
              <div class="calibration-add__or-line"></div>
            </div>
            <button (click)="openLocationDialog()" mat-button color="primary">
              <div class="calibration-add__enter-location">
                <mat-icon class="calibration-add__edit-icon">edit</mat-icon>
                {{ 'calibration.enterLocationManually' | translate }}
              </div>
            </button>
          </div>
        </mat-tab>
        <mat-tab label="Layers">
          <div *ngIf="canLoadLayersAddMode" class="calibration-add__map">
            <unleash-map-layers-list *ngIf="calibrationMap$ | async as map" [map]="map"> </unleash-map-layers-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="calibrationSidebarMode.EDIT_CANVAS_POINT">
    <ng-container
      [ngTemplateOutlet]="editCanvasTemplate"
      [ngTemplateOutletContext]="{editPointIndex: editPointIndex$ | async}"
    ></ng-container>
    <ng-template #editCanvasTemplate let-editPointIndex="editPointIndex">
      <div class="calibration-add__header">
        <button (click)="addPointBack(editPointIndex)" mat-icon-button class="calibration-add__back-button">
          <mat-icon class="calibration-add__back-icon">arrow_back</mat-icon>
        </button>
        <h2 class="calibration-add__title">{{ 'calibration.editPoint' | translate }} {{ editPointIndex + 1 }}</h2>
      </div>
      <mat-divider></mat-divider>
      <div class="calibration-add__add-marker">
        <img src="/assets/icons/manager-zones/calibration/add_marker_to_image.svg" alt="Add marker to image" />
        <h2 class="calibration-add__add-marker-title">{{ 'calibration.editPointTitle' | translate }}</h2>
        <p class="calibration-add__add-marker-info">{{ 'calibration.editPointMessage' | translate }}</p>
      </div>
      <div class="calibration-add__actions">
        <button (click)="cancelEditing()" mat-button color="primary">{{ 'calibration.cancel' | translate }}</button>
        <button (click)="continueEditing()" mat-flat-button color="primary">
          {{ 'calibration.continue' | translate }}
        </button>
      </div>
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="calibrationSidebarMode.EDIT_MAP_POINT">
    <ng-container
      [ngTemplateOutlet]="editMapTemplate"
      [ngTemplateOutletContext]="{editPointIndex: editPointIndex$ | async}"
    ></ng-container>
    <ng-template #editMapTemplate let-editPointIndex="editPointIndex">
      <div class="calibration-add__header">
        <button (click)="addPointBack(editPointIndex)" mat-icon-button class="calibration-add__back-button">
          <mat-icon class="calibration-add__back-icon">arrow_back</mat-icon>
        </button>
        <h2 class="calibration-add__title">{{ 'calibration.editPoint' | translate }} {{ editPointIndex + 1 }}</h2>
      </div>
      <mat-divider></mat-divider>
      <div (click)="addPointBack(editPointIndex)" class="calibration-add__canvas">
        <unleash-image-annotation-shared
          *ngIf="imageSnapshot$ | async as image"
          [canvasId]="'calibration'"
          (canvasLoaded)="loadEditMarker()"
          [srcLowRes]="image.data | uThumbler: thumblerConfig"
          [isMobileView]="isMobileView$ | async"
          [src]="image.data | uThumbler: thumblerConfig"
          [drawStatus]="drawStatus$ | async"
          [style.width]="(isLoadingImage$ | async) ? '0' : '100%'"
        ></unleash-image-annotation-shared>
      </div>
      <div class="calibration-add__add-marker top">
        <mat-tab-group (focusChange)="setCanLoadLayersEditMode(true)">
          <mat-tab label="Points">
            <div class="calibration-add__tab">
              <div class="calibration-add__add-marker top">
                <img src="/assets/icons/manager-zones/calibration/add_marker_to_map.svg" alt="Add marker to map" />
                <h2 class="calibration-add__add-marker-title">
                  {{ 'calibration.editMapMarkerPlacement' | translate }}
                </h2>
                <p class="calibration-add__add-marker-info">
                  {{ 'calibration.editMarkerOnMapDescription' | translate }}
                </p>
                <div class="calibration-add__or">
                  <div class="calibration-add__or-line"></div>
                  {{ 'calibration.or' | translate }}
                  <div class="calibration-add__or-line"></div>
                </div>
                <button (click)="openEditLocationDialog()" mat-button color="primary">
                  <div class="calibration-add__enter-location">
                    <mat-icon class="calibration-add__edit-icon">edit</mat-icon>
                    {{ 'calibration.editLatLngManually' | translate }}
                  </div>
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Layers">
            <div *ngIf="canLoadLayersEditMode" class="calibration-add__map">
              <unleash-map-layers-list *ngIf="calibrationMap$ | async as map" [map]="map"> </unleash-map-layers-list>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div class="calibration-add__actions">
        <button (click)="cancelEditing()" mat-button color="primary">{{ 'calibration.cancel' | translate }}</button>
        <button (click)="finishEditing(editPointIndex)" mat-flat-button color="primary">
          {{ 'calibration.continue' | translate }}
        </button>
      </div>
    </ng-template>
  </ng-template>
</div>

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PlanModel} from '@app/core/models/api/plan.model';

@Component({
  selector: 'unleash-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutComponent {
  @Input() public plan: PlanModel;
  @Input() public plans: {[key: string]: PlanModel};
  @Input() public monthly: number = 0;
  @Input() public annual: number = 0;
  @Input() public hasYearlyDiscount: boolean;
  @Input() public hasProfessionalDiscount: boolean;
}

import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';
import {Mission, MissionSettingsService} from '../services/mission-settings.service';

@Directive({
  selector: '[ulRevertMissionChanges]'
})
export class RevertMissionChangesDirective implements AfterViewInit {
  constructor(private missionSettingsService: MissionSettingsService, private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  public onRevertChanges() {
    this.missionSettingsService.revertAllChanges();
  }

  public ngAfterViewInit(): void {
    this.missionSettingsService.undoStack.subscribe(stack => {
      this.setDisabledButton(stack);
    });
  }

  private setDisabledButton(stack: Mission[]): void {
    if (stack.length === 1) {
      this.elementRef.nativeElement.classList.add('mat-button-disabled');
      this.elementRef.nativeElement.setAttribute('disabled', 'true');
    } else {
      this.elementRef.nativeElement.classList.remove('mat-button-disabled');
      this.elementRef.nativeElement.removeAttribute('disabled');
    }
  }
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ThumblerImageConfig, THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';

@Component({
  selector: 'app-delete-device-dialog',
  templateUrl: './delete-device-dialog.component.html',
  styleUrls: ['./delete-device-dialog.component.scss']
})
export class DeleteDeviceDialogComponent {
  public thumblerConfig: ThumblerImageConfig = THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UPLOAD, UPLOAD_TYPES} from '@app/library/models/upload-types.model';

@Component({
  selector: 'unleash-upload-files-menu-item',
  templateUrl: './upload-files-menu-item.component.html',
  styleUrls: ['./upload-files-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFilesMenuItemComponent {
  @Input() public disabled: boolean = false;
  @Input() public entryType: string = UPLOAD_TYPES.FILE;
  @Input() public isUploadInProgress: boolean;

  @Output() public files: EventEmitter<UPLOAD> = new EventEmitter();
  public uploadTypes: typeof UPLOAD_TYPES = UPLOAD_TYPES;

  public emitFile(files: FileList, uploadType: UPLOAD_TYPES): void {
    if (uploadType === UPLOAD_TYPES.FILE) {
      const fileListArray = Array.from(files);
      const filteredArray = fileListArray.filter(file => !!file.type || this.getFileExtension(file.name));
      if (fileListArray.length === filteredArray.length) {
        this.files.emit({files, uploadType});
        return;
      }
      const newFileList = new DataTransfer();
      filteredArray.forEach(file => newFileList.items.add(file));
      if (filteredArray.length > 0) {
        this.files.emit({files: newFileList.files, uploadType});
      }
      return;
    }
    this.files.emit({files, uploadType});
  }

  private getFileExtension(file): string {
    const regexp = /\.([0-9a-z]+)/;
    const extension = file.match(regexp);
    return extension ? extension[1] : null;
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DataDashboardComponent} from './pages/data-dashboard/data-dashboard.component';

const routes: Routes = [{path: '', component: DataDashboardComponent, title: 'Data Dashboard - Unleash live'}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataRoutingModule {}

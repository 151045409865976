import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'uRouterLinkQueryParams', standalone: true})
export class RouterLinkQueryParamsPipe implements PipeTransform {
  public transform(url: string): Record<string, string> {
    if (!url) {
      return {};
    }

    const tokens = url.split('?');

    if (tokens.length === 1) {
      return {};
    }

    const queryParamsTokens = tokens[1].split('&');
    const queryParams = {};

    queryParamsTokens.forEach(token => {
      const [key, value] = token.split('=');
      queryParams[key] = value;
    });

    return queryParams;
  }
}

<div class="restream" fxFlex="0 1 auto" fxLayout="column">
  <mat-form-field fxFlex>
    <mat-label>Destination URL</mat-label>
    <input
      matInput
      id="destUrl"
      name="destUrl"
      type="text"
      placeholder="rtmp://"
      [value]="device.destUrl || ''"
      (change)="saveDestUrlOnDevice($event)"
    />
  </mat-form-field>
  <!--  <span class="error-container">-->
  <!--    <mat-error-->
  <!--      *ngIf="restreamForm.get('url').hasError('invalidUrl') && restreamForm.get('url').dirty">-->
  <!--      Invalid URL-->
  <!--    </mat-error>-->
  <!--    <mat-error *ngIf="restreamForm.get('url').hasError('required')">-->
  <!--      Field is required.-->
  <!--    </mat-error>-->
  <!--  </span>-->
  <div fxFlex="0 1 auto" fxLayout="column" fxLayoutAlign="center start" class="restream-controls">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <button
        *ngIf="!device.isRestreamingTo"
        class="restream-controls__start"
        mat-raised-button
        color="primary"
        [disabled]="!device.destUrl || starting"
        (click)="startRestreaming()"
      >
        START
      </button>
      <mat-progress-spinner
        *ngIf="starting && !device.isRestreamingTo"
        mode="indeterminate"
        [diameter]="25"
        matTooltip="Starting stream"
      ></mat-progress-spinner>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <button
        mat-raised-button
        class="restream-controls__stop"
        color="primary"
        *ngIf="device.isRestreamingTo"
        [disabled]="stopping"
        (click)="stopRestream()"
      >
        STOP
      </button>
      <mat-progress-spinner
        *ngIf="stopping && device.isRestreamingTo"
        mode="indeterminate"
        [diameter]="25"
        matTooltip="Stopping stream"
      ></mat-progress-spinner>
    </div>
  </div>
</div>

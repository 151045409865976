import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';

@Component({
  selector: 'unleash-streaming-info',
  templateUrl: './streaming-info.component.html',
  styleUrls: ['./streaming-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamingInfoComponent {
  @Input() public isStreaming: boolean = false;
  @Input() public isStartingStreaming: boolean = false;
  @Input() public streamStopped: boolean = false;
  @Input() public isConnected: boolean = false;
  @Input() public isShared: boolean = false;
  @Input() public isloadingSlug: boolean = false;
  @Input() public isEnablingLivePage: boolean = false;
  @Input() public publicWatchPageURL: string = '';
  @Input() public isLoadingCompanyInfo: boolean = true;
  @Input('userDevices') public set setUserDevices(userDevices: UserDeviceJoined[]) {
    if (!userDevices) {
      return;
    }
    this.userDevices = userDevices;
    this.deviceForm = this.fb.group({
      deviceId: [this.userDevices.find(device => device.usage === 'default').id]
    });
  }
  @Input() public isStreamProxyEnabled: false;

  @Output() public startStreaming: EventEmitter<void> = new EventEmitter();
  @Output() public copyUrl: EventEmitter<void> = new EventEmitter();
  @Output() public enablePage: EventEmitter<void> = new EventEmitter();
  @Output() public generateUrl: EventEmitter<void> = new EventEmitter();
  @Output() public secureTunnel: EventEmitter<boolean> = new EventEmitter();
  @Output() public newDeviceId: EventEmitter<string> = new EventEmitter();

  public userDevices: UserDeviceJoined[] = [];
  public deviceForm: FormGroup = null;

  constructor(private fb: FormBuilder) {}

  public emitStartStreaming(): void {
    this.startStreaming.emit();
  }

  public emitCopyUrl(): void {
    this.copyUrl.emit();
  }

  public emitEnablePage(): void {
    this.enablePage.emit();
  }

  public emitGenerateUrl(): void {
    this.generateUrl.emit();
  }

  public emitSecureTunnel(checked: boolean): void {
    this.secureTunnel.emit(checked);
  }

  public emitNewDevice(event: MatSelectChange): void {
    this.newDeviceId.emit(event.value);
  }
}

<unleash-slideshow
  #slideShow
  class="unleash-slideshow"
  [slideshowItems]="slideshowItems"
  [automaticTransition]="false"
></unleash-slideshow>
<div class="container" [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<a mat-button (click)="goToKnowledgePage()" class="help"
  ><mat-icon class="help__icon">help</mat-icon>
  <span class="help__text"> {{'common.help' | translate}} </span>
</a>

import {newPasswordStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {NewPasswordFormState} from './new-password.reducers';

export const selectNewPasswordFormValue = createSelector(
  newPasswordStateSelector,
  (state: NewPasswordFormState) => state.form
);

export const selectNewPasswordFormInProgress = createSelector(
  newPasswordStateSelector,
  (state: NewPasswordFormState) => state.inProgress
);

export const selectNewPasswordFormError = createSelector(
  newPasswordStateSelector,
  (state: NewPasswordFormState) => state.error
);

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FilterTagNamePipe} from '@app/library/pipes/filter-tag-name.pipe';
import {AddonIsEditablePipe} from '@app/shared/pipes/addon-is-editable.pipe';
import {AddonNameFromIdPipe} from '@app/shared/pipes/addon-name-from-id';
import {DateFormatPipe} from '@app/shared/pipes/dateFormat.pipe';
import {DurationPipe} from '@app/shared/pipes/duration.pipe';
import {FormatRelativePipe} from '@app/shared/pipes/formatRelative.pipe';
import {FromUnixPipe} from '@app/shared/pipes/fromUnix.pipe';
import {TimeAgoPipe} from '@app/shared/pipes/timeAgo.pipe';
import {UtcPipe} from '@app/shared/pipes/utc.pipe';
import {BreadcrumbsPipe} from './breadcrumb.pipe';
import {DeviceNamePipe} from './device-name.pipe';
import {FileExtensionNamePipe} from './file-extension-name.pipe';
import {FilterPipe} from './filter.pipe';
import {FolderNamePipe} from './folder-name.pipe';
import {SplitPipe} from './split.pipe';
import {ThumblerPipe} from './thumbler.pipe';
import {RoleNamePipe} from '@app/profile/pipes/role-name.pipe';
import {InjectTokenIdPipe} from '@app/shared/pipes/inject-token-id.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    SplitPipe,
    BreadcrumbsPipe,
    FolderNamePipe,
    DeviceNamePipe,
    FileExtensionNamePipe,
    FilterTagNamePipe,
    AddonNameFromIdPipe,
    AddonIsEditablePipe
  ],

  imports: [
    CommonModule,
    DateFormatPipe,
    TimeAgoPipe,
    UtcPipe,
    DurationPipe,
    FormatRelativePipe,
    FromUnixPipe,
    RoleNamePipe,
    InjectTokenIdPipe,
    ThumblerPipe
  ],
  providers: [ThumblerPipe],
  exports: [
    ThumblerPipe,
    FilterPipe,
    SplitPipe,
    BreadcrumbsPipe,
    FolderNamePipe,
    DeviceNamePipe,
    FilterTagNamePipe,
    FileExtensionNamePipe,
    AddonNameFromIdPipe,
    DateFormatPipe,
    TimeAgoPipe,
    UtcPipe,
    DurationPipe,
    FormatRelativePipe,
    FromUnixPipe,
    AddonIsEditablePipe,
    RoleNamePipe,
    InjectTokenIdPipe
  ]
})
export class SharedPipesModule {}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, filter} from 'rxjs';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';
import {Mission} from '../services/mission-settings.service';

@Injectable({
  providedIn: 'root'
})
export class MissionResolver {
  constructor(private missionManagerService: MissionManagerService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mission[]> {
    return this.missionManagerService.missions$.pipe(filter(missions => missions.length > 0));
  }
}

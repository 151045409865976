import {cloneDeep} from 'lodash';

export const reducerSetSelectedRule = (state, {ruleId}) => {
  const selectedRule = state.rules.find(rule => rule.id === ruleId);
  return {
    ...state,
    selectedRule: {id: selectedRule.id, name: selectedRule.ruleName.name, enabled: selectedRule.enabled}
  };
};

export const reducerSetRuleConfigSelectedRule = state => {
  const selectedRule = state.rules.find(rule => rule.id === state.selectedRule.id);
  return {...state, ruleForm: selectedRule};
};

export const reducerAddAnalyzeNotification = (state, {rule}) => {
  return {...state, rules: [...state.rules, rule]};
};

export const reducerSetRuleConfig = (state, {rule}) => {
  const newRules = state.rules.map(r => {
    if (r.id === rule.id) {
      return rule;
    }
    return r;
  });

  return {...state, ruleForm: rule, rules: newRules};
};

export const reducerDeleteAnalyzeNotification = (state, {ruleId}) => {
  const newRules = state.rules;
  const selectedRule = newRules.find(rule => rule.id === ruleId);
  const ruleIndex = newRules.findIndex(rule => rule === selectedRule);
  newRules.splice(ruleIndex, 1);

  return {...state, rules: newRules, selectedRule: null, ruleForm: null};
};

export const reducerEnableAnalyzeNotification = (state, {ruleId}) => {
  const selectedRule = state.rules.find(rule => rule.id === ruleId);
  const newSelectedRule = {id: selectedRule.id, name: selectedRule.ruleName.name, enabled: true};
  const newRule = {...selectedRule, enabled: true};
  const newRules = state.rules.map(rule => {
    if (rule.id === selectedRule.id) {
      return newRule;
    }
    return rule;
  });

  return {...state, selectedRule: newSelectedRule, rules: newRules, ruleForm: newRule};
};

export const reducerDisableAnalyzeNotification = (state, {ruleId}) => {
  const selectedRule = state.rules.find(rule => rule.id === ruleId);
  const newSelectedRule = {id: selectedRule.id, name: selectedRule.ruleName.name, enabled: false};
  const newRule = {...selectedRule, enabled: false};
  const newRules = state.rules.map(rule => {
    if (rule.id === selectedRule.id) {
      return newRule;
    }
    return rule;
  });

  return {...state, selectedRule: newSelectedRule, rules: newRules, ruleForm: newRule};
};

export const reducerStartEditMode = state => ({
  ...state,
  isRuleEditMode: true
});

export const reducerStopEditMode = state => ({
  ...state,
  isRuleEditMode: false
});

export const reducerUpdateRuleNameOnSelectedRule = (state, {ruleName}) => {
  const ruleId = state.selectedRule.id;
  const ruleToUpdate = cloneDeep({...state.rules.find(rule => rule.id === ruleId)});

  ruleToUpdate.ruleName.name = ruleName;

  const newRules = state.rules.map(rule => {
    if (rule.id === ruleId) {
      return ruleToUpdate;
    }
    return rule;
  });

  return {
    ...state,
    rules: newRules,
    ruleForm: ruleToUpdate
  };
};

export const reducerUpdateAlertSeverityOnSelectedRule = (state, {alertId}) => {
  const ruleId = state.selectedRule.id;
  const ruleToUpdate = cloneDeep({...state.rules.find(rule => rule.id === ruleId)});

  ruleToUpdate.settings.alert = alertId;

  const newRules = state.rules.map(rule => {
    if (rule.id === ruleId) {
      return ruleToUpdate;
    }
    return rule;
  });

  return {
    ...state,
    rules: newRules,
    ruleForm: ruleToUpdate
  };
};

export const reducerPerformAnalysisLoading = state => ({...state, isPerformingAnalysis: true});

export const reducerStopPerformAnalysisLoading = state => ({...state, isPerformingAnalysis: false});
export const reducerClearRules = state => ({
  ...state,
  selectedRule: null,
  ruleForm: null,
  rules: [],
  isRuleEditMode: false
});
export const reducerLoadRulesState = (state, {rules}) => ({...state, rules});
export const reducerToggleAdvancedModeState = state => ({
  ...state,
  ruleForm: {...state.ruleForm, advancedMode: !state.ruleForm.advancedMode}
});

<div class="delete">
  <div class="delete__title">{{ 'atlas.mission.deleteMission' | translate }} {{ data.mission?.name }}</div>
  <div class="delete__description" [innerHTML]="'atlas.mission.deleteMissionDescription' | translate"></div>
  <div class="delete__actions">
    <a color="primary" mat-button mat-dialog-close>{{ 'common.cancel' | translate }}</a>
    <a [disabled]="isDeleting" class="delete__confirm-action" mat-button (click)="deleteMission()" data-test-id="confirm-delete-mission-button">
      <ng-container *ngIf="isDeleting; else deleteTemplate">
        <unleash-spinner diameter="20" borderWidth="2.1" color="#111"  data-test-id="loading-spinner"></unleash-spinner>
        {{ 'common.deleting' | translate }}
      </ng-container>
      <ng-template #deleteTemplate> {{ 'common.delete' | translate }}</ng-template>
    </a>
  </div>
</div>

import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {RuleItem, RuleType, RuleMenuOption, RuleAction} from '../../models/rule.model';

@Component({
  selector: 'unleash-rule-item',
  templateUrl: './rule-item.component.html',
  styleUrls: ['./rule-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleItemComponent implements OnInit {
  @Input() rule: RuleItem = {id: '', name: '', enabled: true};
  @Input() selected: boolean = false;
  @Output() selectedRuleId: EventEmitter<string> = new EventEmitter();
  @Output() action: EventEmitter<RuleAction> = new EventEmitter();
  menuOptions: RuleMenuOption[] = [];

  constructor() {}

  @Input('menuOptions')
  set setMenuOptions(menuOptions: RuleMenuOption[]) {
    this.menuOptions = menuOptions.filter(menuOption => {
      if (this.rule.enabled && menuOption.type === RuleType.enable) {
        return false;
      }

      if (!this.rule.enabled && menuOption.type === RuleType.disable) {
        return false;
      }

      return true;
    });
  }

  ngOnInit(): void {}

  emitRuleId(ruleId: string) {
    this.selectedRuleId.emit(ruleId);
  }

  emitAction(action: RuleType): void {
    this.action.emit({ruleId: this.rule.id, action});
  }
}

import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {DrawTool} from './draw-tool';
import {Canvas, Draw, SingleLabel} from './image-annotation.model';
import {LabelColor, LabelColorName} from './colors';

export class Marker extends DrawTool {
  constructor(ref: Draw, canvas: Canvas) {
    super(canvas);
    this.ref = ref;
    this.type = ShapeTypes.marker;
  }

  public generateDrawTemplate(): SingleLabel {
    throw new Error('Method not implemented.');
  }

  public exportDraw(singleLabel: SingleLabel): [SingleLabel, string] {
    throw new Error('Method not implemented.');
  }

  public importDraw(
    label: SingleLabel,
    scalingFactor: number,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ): SingleLabel {
    throw new Error('Method not implemented.');
  }

  public saveShapeBackup() {
    throw new Error('Method not implemented.');
  }

  public hasDraw(): boolean {
    throw new Error('Method not implemented.');
  }

  public moveMarker(x: number, y: number): void {
    this.ref.move(x, y);
  }

  public updateColor(color: LabelColorName): void {
    (this.ref as any).get(0).attr('fill', LabelColor[color]);
  }

  public emptyMarkerColor(color: LabelColorName): void {
    (this.ref as any).get(0).attr('fill', LabelColor.white);
    (this.ref as any).get(1).attr('fill', LabelColor[color]);
  }
}

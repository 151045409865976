<ng-container *ngIf="!sideNavbarStateService.expanded">
  <mat-list-item (click)="triggerAction()" class="menu-item disable-text-selection collapsed custom-tooltip">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    <ng-container *ngIf="menuItem.href; else defaultTooltip">
      <div class="tooltiptext">
        {{ menuItem.label | translate }}
        <mat-icon class="tooltiptext__icon">open_in_new</mat-icon>
      </div>
    </ng-container>
    <ng-template #defaultTooltip>
      <span class="tooltiptext">{{ menuItem.label | translate }}</span>
    </ng-template>
  </mat-list-item>
</ng-container>

<ng-container *ngIf="sideNavbarStateService.expanded">
  <mat-list-item (click)="triggerAction()" class="menu-item disable-text-selection">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
    <p class="menu-item-label">{{ menuItem.label | translate }}</p>
    <mat-icon *ngIf="menuItem.href" class="menu-item-label__icon">open_in_new</mat-icon>
  </mat-list-item>
</ng-container>

<ng-template #iconTemplate>
  <div class="img-wrapper">
    <ng-template #standardIcon>
      <mat-icon
        class="menu-item-img"
        [ngClass]="menuItem.styleClass ? menuItem.styleClass : null"
        [svgIcon]="menuItem.imagePath"
      ></mat-icon>
    </ng-template>
    <ng-container *ngIf="menuItem.label === 'core.sidebar.menu.notifications'; else standardIcon">
      <ng-container
        *ngIf="unreadNotificationCounter$ | async as unSeenNotificationsCounter; else notificationIconTemplate"
      >
        <mat-icon
          *ngIf="unSeenNotificationsCounter !== 0; else notificationIconTemplate"
          matBadgeSize="small"
          [matBadge]="unSeenNotificationsCounter > 99 ? '99+' : unSeenNotificationsCounter"
          matBadgeColor="warn"
          class="menu-item-img"
          [svgIcon]="menuItem.imagePath"
        ></mat-icon>
      </ng-container>
      <ng-template #notificationIconTemplate>
        <mat-icon class="menu-item-img" [svgIcon]="menuItem.imagePath"></mat-icon>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

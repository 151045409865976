import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {UploadFileMetadata} from '@app/library/models/upload-file-metadata.model';

@Component({
  templateUrl: './upload-file-by-device.component.html',
  styleUrls: ['./upload-file-by-device.component.scss']
})
export class UploadFileByDeviceComponent implements OnInit {
  public form: UntypedFormGroup = this.fb.group({
    deviceId: [null, Validators.required],
    isFastUpload: false,
    hasToCreateFolder: false
  });
  public devices: UserDeviceJoined[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadFileByDeviceComponent, UploadFileMetadata>,
    @Inject(MAT_DIALOG_DATA)
    public data: UploadFileMetadata,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService
  ) {}
  public ngOnInit(): void {
    this.devices = this.data.devices;
    this.form.patchValue({
      deviceId: this.data.deviceId,
      isFastUpload: this.data.isFastUpload,
      hasToCreateFolder:
        this.localStorageService.getItem('hasToCreateNewUploadFolder') && this.data.hasToShowCreateFolderOption
    });
  }

  public emitClose(): void {
    this.dialogRef.close();
  }

  public emitUploadFiles(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}

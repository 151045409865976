<h1 mat-dialog-title>
  {{ 'plans.confirmation.youveChosenX' | translate: {planName: plan.planName.toUpperCase()} }}
</h1>
<mat-dialog-content align="left" class="dialog-content-container">
  <div *ngIf="isPlanActive">{{ 'plans.confirmation.willBeUnsubscribed' | translate }}</div>
  <hr *ngIf="isPlanActive" />
  {{ 'plans.confirmation.chooseBillingPeriod' | translate }} <br />
  {{ 'plans.confirmation.monthly' | translate }}
  <mat-slide-toggle [(ngModel)]="annually" [color]="'primary'"></mat-slide-toggle>
  {{ 'plans.confirmation.annually' | translate }}<br />
  <hr />
  {{ 'plans.confirmation.pricePerMonthIs' | translate }} <b>US${{ annually ? plan.priceAnnual : plan.priceMonthly }}</b
  ><br />
  {{ 'plans.confirmation.willBeBilled.0' | translate }}
  <b>US${{ annually ? 12 * plan.priceAnnual : plan.priceMonthly }}</b>
  <span *ngIf="!userUsedTrial">{{ 'plans.confirmation.willBeBilled.1' | translate: {days: days} }}</span
  ><span *ngIf="userUsedTrial"> {{ 'plans.confirmation.willBeBilled.2' | translate }}</span>
  <hr />
  <div class="dialog-content-line">
    <mat-form-field appearance="outline" class="dialog-content-line-code">
      <mat-label>{{ 'plans.confirmation.addReferral' | translate }}</mat-label>
      <input matInput placeholder="{{ 'plans.confirmation.enterCode' | translate }}" [(ngModel)]="couponCode" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-button [mat-dialog-close]="false" tabindex="0">
    {{ 'common.cancel' | translate }}
  </button>
  <span fxFlex></span>
  <button color="primary" mat-raised-button tabindex="1" [mat-dialog-close]="data" (click)="startSubscription()">
    {{ 'plans.confirmation.payNow' | translate }}
  </button>
</mat-dialog-actions>

import {Component, ChangeDetectionStrategy, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Team} from '@app/profile/models/team.model';
import {UserInvitation} from '@app/profile/models/user-roles.model';
import {UserTileScreen} from '@app/profile/models/user-tile-screen.model';
import {Roles, UserAction, UserTable, UserTableActions} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-users-tile',
  templateUrl: './users-tile.component.html',
  styleUrls: ['./users-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersTileComponent {
  @Input() public form: FormGroup = new FormGroup({
    search: new FormControl('')
  });
  @Input() public users: UserTable[] = [];
  @Input() public selectedUser: UserTable | null = null;
  @Input() public menuItems: UserTableActions[] = [];
  @Input() public userScreen: UserTileScreen = UserTileScreen.users;
  @Input() public roles: Roles = {};
  @Input() public teams: Team[] = [];
  @Input() public hasMoreThanOneAdmin: boolean = false;
  @Input() public currentTeamId: string = '';
  @Input() public isAddUserMode: boolean = true;
  @Output() public addUsers: EventEmitter<void> = new EventEmitter();
  @Output() public searchUser: EventEmitter<string> = new EventEmitter();
  @Output() public invite: EventEmitter<UserInvitation[]> = new EventEmitter();
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();
  @Output() public user: EventEmitter<UserTable> = new EventEmitter();
  @Output() public action: EventEmitter<UserAction> = new EventEmitter();
  @Output() public openInviteUsersDialog: EventEmitter<void> = new EventEmitter();

  public userTileScreen = UserTileScreen;

  public performSearch(search: string): void {
    this.searchUser.emit(search);
  }

  public emitAddUsers(): void {
    this.addUsers.emit();
  }

  public emitInvite(userInvitation: UserInvitation[]): void {
    this.invite.emit(userInvitation);
  }

  public emitCancel(): void {
    this.cancel.emit();
  }

  public emitBack(): void {
    this.back.emit();
  }

  public emitUser(user: UserTable): void {
    this.user.emit(user);
  }

  public emitAction(action: UserAction): void {
    this.action.emit(action);
  }

  public emitOpenInviteUsersDialog(): void {
    this.openInviteUsersDialog.emit();
  }
}

import {Sort} from '@angular/material/sort';

export interface ReportModel {
  updatedAt: number;
  createdAt: number;
  id: string;
  name: string;
  type: REPORT_TYPE;
  path: string;
  sessionId: string; // Note: parentId is stored under that key,
  // should be changed once backend will be updated
  sessionCreatedAt: string;
}

export interface ReportsRequest {
  page: number;
  pageSize: number;
  sort: Sort;
}

export interface ReportType {
  value: REPORT_TYPE;
  name: REPORT_TYPE;
}

export interface ParsedReport {
  id: string;
  name: string;
  type: REPORT_TYPE;
  paths: string[];
  createdAt: number;
  updatedAt: number;
  sessionId: string;
  isSelected?: boolean;
}

export interface ReportsFilterOptions {
  from: number | Date;
  to: number | Date;
  timeFrom: number | Date;
  timeTo: number | Date;
  search: string;
  type: string;
  status: string;
}

export enum REPORT_TYPE {
  AI_REPORT = 'AI Report',
  ANNOTATION = 'Annotation',
  PDF_REPORT = 'PDF Report'
}

export enum FILE_EXTENSION {
  JSON = 'json',
  ZIP = 'zip',
  PDF = 'pdf',
  CSV = 'csv'
}

export const DEFAULT_PAGE_INDEX: number = 0;
export const DEFAULT_PAGE_SIZE: number = 25;

export enum DELETE_REPORT_TYPES {
  AI = 'AI Report',
  PDF = 'PDF Report',
  MULTIPLE = 'Multiple'
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ua-offline-devices',
  templateUrl: './offline-devices.component.html',
  styleUrls: ['./offline-devices.component.scss']
})
export class OfflineDevicesComponent implements OnInit {

  @Input() devices;
  @Input() activeDevicesNum;
  constructor() { }

  ngOnInit() {
  }

}

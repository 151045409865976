import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {TeamRole} from '@app/profile/models/team.model';
import {EMPTY, catchError, take, tap} from 'rxjs';

@Component({
  selector: 'unleash-add-users-dialog',
  templateUrl: './add-users-dialog.component.html',
  styleUrls: ['./add-users-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUsersDialog implements OnInit {
  public isLoading: boolean = false;
  public originalAssignedUsers: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddUsersDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      teamName: string;
      teamMembers: {[key: string]: {id: string; name: string; role: TeamRole}};
      jobAssignedUsers: string[];
      jobId: string;
    },
    public jobsApiService: JobsApiService
  ) {}

  public ngOnInit(): void {
    this.originalAssignedUsers = [...this.data.jobAssignedUsers];
  }

  public toggleSelectedUser(userId: string): void {
    const index = this.data.jobAssignedUsers.indexOf(userId);
    index !== -1 ? this.data.jobAssignedUsers.splice(index, 1) : this.data.jobAssignedUsers.push(userId);
  }

  public addUsers(): void {
    this.isLoading = true;
    this.jobsApiService
      .updateJob(this.data.jobId, {userIds: this.data.jobAssignedUsers})
      .pipe(
        take(1),
        tap(response => this.dialogRef.close({jobId: this.data.jobId, data: response})),
        catchError(() => {
          this.dialogRef.close({error: true});
          return EMPTY;
        })
      )
      .subscribe();
  }

  public hasOriginalUserIds(): boolean {
    if (this.data.jobAssignedUsers.length !== this.originalAssignedUsers.length) {
      return false;
    }

    const sortedAssignedUsers = this.data.jobAssignedUsers.slice().sort();
    const sortedOriginalUsers = this.originalAssignedUsers.slice().sort();

    for (let i = 0; i < sortedAssignedUsers.length; i++) {
      if (sortedAssignedUsers[i] !== sortedOriginalUsers[i]) {
        return false;
      }
    }

    return true;
  }
}

import {Component, ChangeDetectionStrategy, EventEmitter, Output, Input} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {
  CoolDownPeriod,
  NestedRule,
  RuleAlert,
  RuleAreaOfInterest,
  RuleCondition,
  RuleOperator
} from '../../models/rule.model';

@Component({
  selector: 'unleash-rule-tab',
  templateUrl: './rule-tab.component.html',
  styleUrls: ['./rule-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleTabComponent {
  @Input()
  public form = this.fb.group({
    ruleName: this.fb.group({name: ['', Validators.required]}),
    enabled: [true],
    rules: this.fb.array([this.fb.group({zone: ['', Validators.required], logicOperator: ''})]),
    settings: this.fb.group({alert: ['', Validators.required], coolDownPeriod: ['']})
  });
  @Input()
  public simpleForm = this.fb.group({
    zone: ['', Validators.required],
    condition: ['', Validators.required],
    operator: ['', Validators.required],
    value: ['', {updateOn: 'blur'}]
  });

  @Input() public editMode: boolean = false;
  @Input() public zones: RuleAreaOfInterest[] = [];
  @Input() public zoneList: Zone[] = [];
  @Input() public simpleConditions: RuleCondition[] = [];
  @Input() public conditions: RuleCondition[] = [];
  @Input() public operators: RuleOperator[] = [];
  @Input() public alerts: RuleAlert[] = [];
  @Input() public coolDownPeriods: CoolDownPeriod[] = [];
  @Input() public hasToDisplayCoolDown: boolean = false;
  @Input() public hasToDisableSeverity: boolean = false;

  @Output() public done: EventEmitter<string> = new EventEmitter();
  @Output() public edit: EventEmitter<void> = new EventEmitter();
  @Output() public zoneChange: EventEmitter<number> = new EventEmitter();
  @Output() public conditionChange: EventEmitter<number> = new EventEmitter();
  @Output() public alertChange: EventEmitter<string> = new EventEmitter();
  @Output() public valueChange: EventEmitter<string> = new EventEmitter();
  @Output() public delete: EventEmitter<number> = new EventEmitter();
  @Output() public simpleModeChange: EventEmitter<{rules: NestedRule[]}> = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  public emitDone(ruleName: string): void {
    this.done.emit(ruleName);
  }

  public emitEdit(): void {
    this.edit.emit();
  }

  public emitZoneChange(formIndex: number): void {
    this.zoneChange.emit(formIndex);
  }

  public emitConditionChange(formIndex: number): void {
    this.conditionChange.emit(formIndex);
  }

  public emitAlertChange(alertId: string): void {
    this.alertChange.emit(alertId);
  }

  public emitValueChange(): void {
    this.valueChange.emit();
  }

  public emitDelete(formIndex: number): void {
    this.delete.emit(formIndex);
  }

  public validateLogicOperatorChange(formIndex: number): void {
    if (this.form.controls.rules.value.length > 0 && this.form.controls.rules.value[formIndex].logicOperator === null) {
      return;
    }

    this.valueChange.emit();
  }

  public emitSimpleModeChange(simpleModeChange: {rules: NestedRule[]}): void {
    this.simpleModeChange.emit(simpleModeChange);
  }
}

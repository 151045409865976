import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AclCanActivateGuard} from '@app/core/guards/acl.guards';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {ExitStreamGuard} from './guards/exit-stream.guard';
import {LiveStreamPage} from './pages/live-stream-page/live-stream-page.component';
import {StreamWebrtcComponent} from './pages/stream-webrtc/stream-webrtc.component';
import {ManageZonesDialogComponent} from '@app/shared/manage-zones-dialog/manage-zones-dialog.component';

const routes: Routes = [
  {
    path: '',
    component: LiveStreamPage,
    title: 'Stream - Unleash live',
    canActivate: [AclCanActivateGuard],
    data: {
      permission: AclPermissions.LivestreamApiStreamRead
    }
  },
  {
    path: 'stream',
    component: StreamWebrtcComponent,
    canDeactivate: [ExitStreamGuard],
    title: 'Stream Device - Unleash live',
    canActivate: [AclCanActivateGuard],
    data: {
      permission: AclPermissions.LivestreamApiStreamPublish
    }
  },
  {
    path: 'stream/:deviceId',
    component: StreamWebrtcComponent,
    title: 'Stream Device - Unleash live',
    data: {
      permission: AclPermissions.LivestreamApiStreamPublish
    }
  },
  {
    path: 'analyze',
    component: ManageZonesDialogComponent,
    title: 'Manage Zones - Unleash live',
    data: {
      permission: AclPermissions.LivestreamApiStreamPublish
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LiveRoutingModule {}

<div class="header">
  <button class="header__button" mat-button color="primary" (click)="emitAddRule()" [disabled]="disabledAddRules">
    <div class="header__button__content">
      <mat-icon color="primary" [class.header__button--disabled]="disabledAddRules">add</mat-icon>
      <span class="header__button__text">{{
        'live.manage-zones-dialog.notificationsTab.sidebar.add_notification' | translate
      }}</span>
    </div>
  </button>
</div>
<mat-divider></mat-divider>
<ng-container *ngIf="rules && rules.length === 0; else rulesListTemplate">
  <div class="empty-content">
    <div>
      <mat-icon class="empty-content__icon">notifications</mat-icon>
    </div>
    <div class="empty-content__title">
      {{ 'live.manage-zones-dialog.notificationsTab.sidebar.empty.title' | translate }}
    </div>
    <div class="empty-content__description">
      {{ 'live.manage-zones-dialog.notificationsTab.sidebar.empty.description' | translate }}
    </div>
  </div>
</ng-container>
<ng-template #rulesListTemplate>
  <div class="content">
    <unleash-rule-item
      *ngFor="let rule of rules"
      [rule]="rule"
      [menuOptions]="menuOptions"
      [selected]="selectedRule?.id === rule.id"
      (selectedRuleId)="emitSelectedRuleId($event)"
      (action)="emitAction($event)"
    ></unleash-rule-item>
  </div>
</ng-template>
<div class="footer">
  <div class="footer__actions">
    <button class="footer__button" mat-stroked-button color="primary" (click)="emitBack()">
      {{ 'live.manage-zones-dialog.notificationsTab.sidebar.empty.actions.back' | translate }}
    </button>
    <button
      class="footer__button"
      mat-raised-button
      color="primary"
      (click)="emitAnalyze()"
      [appLadda]="performAnalysisLoading"
    >
      {{ 'live.manage-zones-dialog.notificationsTab.sidebar.empty.actions.save' | translate }}
    </button>
  </div>
</div>

import {ActionReducerMap, createReducer} from '@ngrx/store';
import {Team, TeamTable} from '../models/team.model';
import {Roles, UserTeam} from '../models/users-table.model';
import {
  selectedTeamIdReducer,
  teamManagementReducer
} from '@app/profile/store/teams-management/team-management.reducer';
import {
  rolesManagementReducer,
  selectedUserIdReducer,
  userManagementLoadingReducer,
  usersManagementReducer
} from '@app/profile/store/users-management/users-management.reducer';

export const profileFeatureKey = 'profile';

export interface State {
  users: UserTeam[];
  teams: Team[];
  roles: Roles;
  selectedUserId: UserTeam['userId'];
  selectedTeamId: Team['id'];
  selectedUserTeam: TeamTable;
  isUserManagementLoading: boolean;
}

export const reducer: ActionReducerMap<State> = {
  users: usersManagementReducer,
  teams: teamManagementReducer,
  roles: rolesManagementReducer,
  selectedUserId: selectedUserIdReducer,
  selectedTeamId: selectedTeamIdReducer,
  selectedUserTeam: createReducer(null),
  isUserManagementLoading: userManagementLoadingReducer
};

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'usplit'
})
export class SplitPipe implements PipeTransform {
  transform(value: string, split: string): unknown {
    return value.split(split);
  }
}

<mat-toolbar class="toolbar">
  <div fxFlex="nogrow">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="text-wrapper">
    <div class="truncate-text">{{text}}</div>
  </div>
</mat-toolbar>

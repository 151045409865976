import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {LibraryItem} from '@app/library/models/folder-file.model';
import {LatLngBoundsExpression, Map} from 'leaflet';
import {filter, take} from 'rxjs/operators';
import {AssetType, AtlasAssetModel} from '../../core/models/api/atlas.model';
import {atlasConfig} from '../atlas.config';
import {AssetAddedEvent} from '../components/atlas-map/atlas-map.component';
import {AtlasService} from './atlas.service';
import {TileSessionService} from './tile-session.service';
import {Injectable} from '@angular/core';

export type ASSET_ICON_NAMES = 'flight' | 'bubble_chart' | 'notes' | 'map' | 'category';

/** This class is instantiated multiple times for each AssetLoader type
 * see AssetLoaderService.getLoaderByType() */
@Injectable({providedIn: 'root'})
export abstract class AbstractAssetLoaderService {
  constructor(
    protected atlasService: AtlasService,
    protected http: HttpClient,
    protected tileSessionService: TileSessionService,
    protected sanitizer: DomSanitizer,
    protected route: ActivatedRoute
  ) {}

  public abstract load(asset: AtlasAssetModel, map: Map): Promise<number>;

  public addAsset(map: Map, options: AssetAddedEvent): void {
    if (!map) {
      // true if user navigates away from the view and map is already destroyed
      console.warn('Could not add layer, because map is not available.');
      return;
    }

    this.addLeafletLayer(options);
    const isViewerClosed = this.route.snapshot.queryParams.isViewerClosed;
    if (!isViewerClosed) {
      this.moveMap(map, options);
    }
  }

  private addLeafletLayer(event: AssetAddedEvent) {
    const assetId = event.id;
    const asset = this.atlasService.getAssetById(assetId);

    if (!asset) {
      console.warn('Could not find asset for event', event);
      return;
    }

    asset.iconName = this.getIconName(asset);
    asset.leafletLayer = event.layer;
    asset.bounds = event.bounds;
    asset.hasState = this.atlasService.hasAllMarkersStatusAssigned(asset);
    if (this.atlasService.hasToMoveToUploadedLayer) {
      asset.isSelected = true;
      this.atlasService.addSelectedLayer(asset);
    }
    this.displayAssets(asset);
  }

  private async displayAssets(asset: AtlasAssetModel): Promise<void> {
    const modelId: LibraryItem['id'] = this.route.snapshot.queryParams.modelId;
    const sampleId: LibraryItem['id'] = 'example-golf-atlas-asset';

    if (!this.isUserRedirectedFromLibrary()) {
      asset.isDisplaying = true;
      return;
    }

    this.atlasService.assets$
      .pipe(
        filter((data: AtlasAssetModel[]): boolean => !!data.length),
        take(1)
      )
      .subscribe((assets: AtlasAssetModel[]) => {
        if (!assets.some((atlasAsset: AtlasAssetModel) => atlasAsset.modelId === modelId)) {
          asset.isDisplaying = asset.id === sampleId;
          return;
        }

        asset.isDisplaying = asset.modelId === modelId;
      });
  }

  private isUserRedirectedFromLibrary(): boolean {
    return (
      !!this.route.snapshot.queryParams.lat &&
      !!this.route.snapshot.queryParams.lng &&
      !!this.route.snapshot.queryParams.modelId
    );
  }

  /** If user navigated to this asset, move the map immediately
   * otherwise move to the very first loaded asset
   * Note: it's using an artificial map semaphore property to lock the map */
  private moveMap(map: Map, options: AssetAddedEvent): void {
    if (this.atlasService.hasToMoveToUploadedLayer) {
      this.fitBounds(map, options?.bounds);

      return;
    }
    if (this.atlasService.hasToSkipFitBoundsOnLoadLayers) {
      return;
    }
    if (this.route.snapshot.queryParams?.id === options?.id || this.atlasService.loadedAssetsCount === 1) {
      this.fitBounds(map, options?.bounds);
      return;
    }
    if (this.atlasService.hasMapPropertiesStored()) {
      this.atlasService.loadInitialPositionFromLocalStorage(map);
    }
  }

  private fitBounds(map: Map, bounds: LatLngBoundsExpression): void {
    map.fitBounds(bounds, {
      ...atlasConfig.FIT_BOUNDS_OPTIONS,
      maxZoom: atlasConfig.FIT_BOUNDS_OPTIONS.maxZoom + 1
    });
  }

  private getIconName(layer: AtlasAssetModel): ASSET_ICON_NAMES {
    switch (layer.type) {
      case AssetType.FLIGHT_LOG:
        return 'flight';
      case AssetType.HEATMAP:
        return 'bubble_chart';
      case AssetType.GEOJSON:
        return 'notes';
      case AssetType.ORTHOPHOTOMAP:
        return 'map';
      case AssetType.DEMO:
        return 'category';
      default:
        return 'map';
    }
  }
}

import {OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType, TemplatePortal} from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  selector: '[uaCustomTooltip]',
  standalone: true
})
export class CustomTooltipDirective implements OnInit {
  @Input('uaCustomTooltip') public tooltipContent: TemplateRef<any> | ComponentType<any>;
  @Input() public tooltipPosition: ConnectedPosition[];
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  public ngOnInit(): void {
    if (!this.tooltipContent) {
      return;
    }

    const position = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.tooltipPosition);

    this.overlayRef = this.overlay.create({
      positionStrategy: position,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      panelClass: 'custom-tooltip'
    });
  }

  @HostListener('mouseover')
  private show(): void {
    if (this.overlayRef) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;
      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(this.tooltipContent, this.viewContainerRef);
      } else {
        containerPortal = new ComponentPortal(this.tooltipContent, this.viewContainerRef);
      }

      this.overlayRef.attach(containerPortal);
    }
  }

  @HostListener('mouseout')
  private hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}

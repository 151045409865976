import {Injectable} from '@angular/core';
import {Mission} from '@app/atlas/services/mission-settings.service';
import * as convert from 'xml-js';
import {MissionPoint, MissionType} from '@app/atlas/model/mission.model';
import {MissionBuilderService} from '@app/flights/services/mission-builder.service';

@Injectable({
  providedIn: 'root'
})
export class MissionImportKMLService {
  constructor(private missionBuilder: MissionBuilderService) {}

  public doImport(xmlString: string): Mission {
    const jsonResult = JSON.parse(
      convert.xml2json(xmlString, {
        compact: true,
        spaces: 4,
        ignoreAttributes: true,
        ignoreDeclaration: true
      })
    );
    const mission = this.mapMission(jsonResult.kml.Document.Folder);
    return this.missionBuilder.buildMissionFromWaypoints(mission);
  }

  private mapMission(folder: any) {
    return {
      name: folder.name._text || 'DJI Pilot mission',
      description: folder.description._text || 'Imported DJI Pilot mission',
      route: this.mapPoints(folder.Placemark),
      type: MissionType.DJI_PILOT
    };
  }

  private mapPoints(points: any[]): MissionPoint[] {
    return points.map((p, index) => {
      let point;
      try {
        const coordinates = p.Point.coordinates['_text'].split(',');
        point = {
          lat: parseFloat(coordinates[1].toString()),
          lng: parseFloat(coordinates[0].toString()),
          altitude: parseFloat(coordinates[2].toString()),
          heading: parseFloat(p.ExtendedData['mis:heading']['_text'].toString()),
          pitch: parseFloat(p.ExtendedData['mis:gimbalPitch']['_text'].toString()),
          speed: parseFloat(p.ExtendedData['mis:speed']['_text'].toString())
        };
      } catch (e) {
        console.error('Failed to parse waypoint', point, e);
        throw new Error(e);
      }
      this.validatePoint(point);
      return point;
    });
  }

  private validatePoint(point: MissionPoint) {
    const requiredProps = ['lat', 'lng', 'altitude', 'heading', 'pitch'];
    this.missionBuilder.validate(point, requiredProps);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {GROW_DOWN_ANIMATION} from './banner.animation';

@Component({
  selector: 'unleash-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [GROW_DOWN_ANIMATION]
})
export class BannerComponent {
  @Input() isOpen: boolean;
  constructor() {}
}

export interface BannerAction {
  type: 'simple' | 'action';
  actionName: string;
}

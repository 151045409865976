import {createReducer, on} from '@ngrx/store';
import {LibraryItemMoveModel} from '@app/library/models/move-items.model';
import {
  addToCachedStore,
  addToStore,
  clearStore,
  initStore,
  saveMoveItemsDialogSubfolders,
  startLoadingMoveItemsDialogSubfolders,
  stopLoadingMoveItemsDialogSubfolders
} from './move-item-dialog.actions';

export interface State {
  items: {[key: string]: LibraryItemMoveModel};
  folders: {[key: string]: string[]};
  currentLocation: string;
  isLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  folders: {},
  items: {},
  currentLocation: ''
};

export const reducer = createReducer(
  initialState,
  on(clearStore, (state: State): State => ({...initialState})),
  on(
    saveMoveItemsDialogSubfolders,
    (state: State, {payload}: {payload: {currentLocation: string; folders: LibraryItemMoveModel[]}}) => {
      const locationParts = payload.currentLocation.split('/');
      const parentId = locationParts.pop();
      const items = {};
      const folderIds = [];

      for (const item of payload.folders) {
        items[item.id] = item;
        folderIds.push(item.id);
      }

      return {
        ...state,
        folders: {...state.folders, ...{[parentId]: folderIds}},
        items: {...state.items, ...items},
        currentLocation: payload.currentLocation
      };
    }
  ),
  on(
    startLoadingMoveItemsDialogSubfolders,
    (state: State): State => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    stopLoadingMoveItemsDialogSubfolders,
    (state: State): State => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    initStore,
    (state: State, {payload}: {payload: {item: LibraryItemMoveModel}}): State => ({
      ...state,
      items: {...state.items, ...{[payload.item.id]: payload.item}},
      currentLocation: payload.item.location
    })
  ),
  on(addToStore, (state: State, {payload}: {payload: {item: LibraryItemMoveModel}}): State => {
    const locationParts = payload.item.location.split('/');
    const parentId = locationParts.pop();
    const folderItems = {
      [parentId]: [...state.folders[parentId], payload.item.id]
    };

    return {
      ...state,
      items: {...state.items, ...{[payload.item.id]: payload.item}},
      folders: {...state.folders, ...folderItems}
    };
  }),
  on(addToCachedStore, (state: State, {payload}: {payload: {item: LibraryItemMoveModel}}) => {
    const locationParts = payload.item.location.split('/');
    const parentId = locationParts.pop();
    if (!state.folders.hasOwnProperty(parentId)) {
      return state;
    }
    const folderItems = {
      [parentId]: [...state.folders[parentId], payload.item.id]
    };

    return {
      ...state,
      items: {...state.items, ...{[payload.item.id]: payload.item}},
      folders: {...state.folders, ...folderItems}
    };
  })
);

export interface PlanModel {
  id?: string;
  packageName: PackageNames;
  planName: string;
  description: string;
  priceAnnual?: number;
  priceMonthly?: number;
  currency?: string;
  freeTrial: boolean;
  trialDays: number;
  isLivestreaming: boolean;
  isLivestreamingPublic?: boolean;
  isRestreaming?: boolean;
  maxStreamingHours?: number;
  maxLiveChannels?: number;
  isAiAnalytics?: boolean;
  aiInsightsNum?: number;
  maxAiProcessingHours?: number;
  maxStorageGb?: number;
  maxDevices: number;
  isAtlas?: boolean;
  isModelling?: boolean;
  isEmbeddedStream: boolean;
  isBranding: boolean;
  isLinkSharing: boolean;
  supportDescription: string;
  supportCode: string;
  reseller?: string;
  stripe_id?: string;
  maxAiLiveProcessingHours?: number;
  createdAt?: number;
  updatedAt?: number;
  // added for developer signup needs - TO BE VERIFIED
  maxAiProcessingGb?: number;
}

export enum PackageNames {
  broadcast = 'Live Streaming',
  modelling = 'Modeling and GIS',
  control = 'A.I. Analytics and Modeling and GIS',
  insights = 'A.I. Analytics',
  operational = 'Live Streaming and Modeling and GIS',
  supervision = 'Live Streaming and A.I. Analytics',
  unleashed = 'Unleashed',
  aiInsights = 'AI Insights',
  developer = 'Developer'
}

export enum PlanNames {
  essentials = 'Essentials',
  professional = 'Professional',
  enterprise = 'Enterprise',
  thirdParty = 'Third Party'
}

export enum ADDON_TIERS {
  PROFESSIONAL = 'Professional',
  ESSENTIALS = 'Essentials',
  STARTER = 'Starter',
  CUSTOM = 'Custom Solution'
}

export enum SupportCodeNames {
  online = 'Online support',
  pro = 'Professional support',
  enterprise = 'Enterprise'
}

export type PlansByPlanName = {[P in PlanNames]: PlanModel};

export type PlansByPackageName = {[P in PackageNames]: PlansByPlanName};

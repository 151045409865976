import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {actionSignInFormSubmit, actionVerificationFormSubmit} from '../sign-in/sign-in.actions';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/services/auth.service';
import {BehaviorSubject, Subscription, take} from 'rxjs';
import {selectSignInForm} from '../sign-in/sign-in.selectors';

@Component({
  selector: 'unleash-mfa-sms-verification',
  templateUrl: './mfa-sms-verification.component.html',
  styleUrls: ['./mfa-sms-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MfaSmsVerificationComponent {
  public verificationForm: FormGroup = this.fb.group({
    code: ['', Validators.required]
  });
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public isVerificationCodeValid: BehaviorSubject<boolean> = this.authService.isVerificationCodeValid;
  public isInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isVerificationCodeSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  public ngOnDestroy(): void {
    if (this.isVerificationCodeSub) {
      this.isVerificationCodeSub.unsubscribe();
      this.isVerificationCodeSub = null;
    }
  }

  public ngAfterViewInit(): void {
    this.isVerificationCodeSub = this.isVerificationCodeValid.subscribe(isValid => {
      if (!isValid) {
        this.isInProgress.next(false);
        this.verificationForm.controls.code.setErrors({invalidCode: true});
      }
    });
  }

  public signOut(): void {
    this.router.navigate(['/auth/sign-in']);
  }

  public continue(): void {
    if (this.verificationForm.valid) {
      this.isInProgress.next(true);
      this.store.dispatch(
        actionVerificationFormSubmit({
          code: this.verificationForm.value.code,
          isDeveloperMode: this.isDeveloperMode
        })
      );
    }
  }
  public sendCode(): void {
    this.store
      .select(selectSignInForm)
      .pipe(take(1))
      .subscribe(value => {
        this.store.dispatch(
          actionSignInFormSubmit({
            form: value,
            isDeveloperMode: this.isDeveloperMode
          })
        );
      });
  }
}

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    // TODO: SentryErrorHandler conflict, circular dependency for ErrorHandler
    ...(environment.production ? [] : [StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production})])
  ]
})
export class AppStoreModule {}

import {Directive, HostListener, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateJobDialog} from '../components/create-job-dialog/create-job-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';

@Directive({
  selector: '[ulCreateJob]',
  standalone: true
})
export class CreateJobDirective {
  @Input('ulCreateJob') public selectedLayers = [];
  @Input() public currentLayer;
  @Input() public map;

  constructor(public dialog: MatDialog) {}
  @HostListener('click', ['$event'])
  public createJob() {
    this.dialog.open(CreateJobDialog, {
      ...STANDARD_DIALOG_CONFIG,
      maxWidth: '40vw',
      minWidth: '350px',
      data: {
        selectedLayers: this.selectedLayers,
        currentLayer: this.currentLayer,
        map: this.map
      },
      disableClose: true
    });
  }
}

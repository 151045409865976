import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MissionType} from '@app/atlas/model/mission.model';
import {Mission, MissionRoutePoint} from '@app/atlas/services/mission-settings.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'unleash-mission-viewer-control',
  templateUrl: './mission-viewer-control.component.html',
  styleUrls: ['./mission-viewer-control.component.scss']
})
export class MissionViewerControlComponent implements AfterViewInit {
  @Input() public mission: Mission = null;
  @Input() public isNewRoute: boolean = false;
  @Input() public isMissionDrawerClosed: boolean = true;
  @Input() public defaultSettings: MissionRoutePoint = null;
  @Input('isEditing') public set setIsEditing(isEditing: boolean) {
    this.isEditing.next(isEditing);
    if (!isEditing) {
      this.hasToShowSettings.next(false);
      this.hasToShowSettingsButton.next(false);
      return;
    }
    this.hasToShowSettingsButton.next(true);
  }

  @Input('selectedWaypointIndex') public set setSelectedWaypointIndex(index: number) {
    if (index !== -1) {
      this.emitSelectedWaypoint(this.mission.route[index], index);
      return;
    }
    this.selectedIndex = -1;
  }
  @Output() public selectedWaypoint: EventEmitter<{waypoint: MissionRoutePoint; index: number; isLastIndex: boolean}> =
    new EventEmitter();
  @Output() public updatedMission: EventEmitter<Mission> = new EventEmitter();
  @ViewChild('summaryContainer') public summaryContainer;
  public selectedIndex: number = -1;
  public hasToShowCompleteSummary: boolean = true;
  public hasToShowEllipsis: boolean = false;
  public hasToShowSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasToShowSettingsButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public missionType = MissionType;

  constructor(private cd: ChangeDetectorRef) {}

  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: {preventDefault: any; returnValue: boolean}): void {
    if (this.isEditing.value) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  public ngAfterViewInit(): void {
    if (this.summaryContainer) {
      const maxHeightWithoutEllipsis = 60;
      if (this.summaryContainer.nativeElement.clientHeight <= maxHeightWithoutEllipsis) {
        return;
      }
      if (this.summaryContainer.nativeElement.clientHeight > maxHeightWithoutEllipsis) {
        this.hasToShowEllipsis = true;
        this.showLess();
        this.cd.detectChanges();
      }
    }
  }

  public showMore(): void {
    this.summaryContainer.nativeElement.style.height = '100%';
    this.hasToShowCompleteSummary = true;
  }

  public showLess(): void {
    this.summaryContainer.nativeElement.style.height = '60px';
    this.hasToShowCompleteSummary = false;
  }

  public emitSelectedWaypoint(waypoint: MissionRoutePoint, index: number) {
    this.selectedIndex = index;
    this.selectedWaypoint.emit({waypoint, index, isLastIndex: index === this.mission.route.length - 1});
  }

  public setShowSettings(hasToShowSettings: boolean): void {
    this.hasToShowSettings.next(hasToShowSettings);
  }

  public updatedMissionSetting(setting: Partial<MissionRoutePoint>): void {
    this.mission.route = this.mission.route.map(waypoint => ({...waypoint, ...setting}));
    this.updatedMission.emit(this.mission);
  }

  public hideMissionSettings(): void {
    this.hasToShowSettings.next(false);
  }

  public updateSmartInspect(isSmartInspect: boolean): void {
    this.mission.isSmartInspect = isSmartInspect;
    this.updatedMission.emit(this.mission);
  }
}

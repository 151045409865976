import {Injectable} from '@angular/core';
import {BehaviorSubject, filter} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AclService {
  // eslint-disable-next-line rxjs/finnish
  public hasSetupPermission: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasSetupPermission$ = this.hasSetupPermission
    .asObservable()
    .pipe(filter(hasSetupPermission => !!hasSetupPermission));

  private permissions: Map<string, string>;

  constructor() {
    this.permissions = new Map<string, string>();
  }

  public setPermissions(permissions: string[]) {
    this.permissions.clear();

    for (let i = 0; i < permissions.length; i++) {
      this.permissions.set(permissions[i], permissions[i]);
    }

    this.hasSetupPermission.next(true);
  }

  public addToPermissions(permission: string) {
    if (this.permissions.has(permission)) {
      return;
    }
    this.permissions.set(permission, permission);
  }

  public hasPermission(str: string): boolean {
    return this.permissions.get(str) !== undefined;
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {actionSignInFormSubmit} from '../sign-in/sign-in.actions';
import {
  actionSignUpFormConfirmationRequired,
  actionSignUpFormReconfirmationRequired,
  actionSignUpFormSuccess
} from '../sign-up/sign-up.actions';
import {
  actionConfirmRegistrationFormError,
  actionConfirmRegistrationFormSubmit,
  actionConfirmRegistrationReSendCode,
  actionConfirmRegistrationReSendCodeSuccess,
  actionConfirmRegistrationSuccess
} from './confirm-registration.actions';
import {ConfirmRegistration} from './confirm-registration.model';

export interface ConfirmRegistrationState {
  form: ConfirmRegistration;
  error?: AuthErrorModel;
  inProgress: boolean;
  resendInProgress: boolean;
  isDisplayResendAlert: boolean;
}

export const initialState: ConfirmRegistrationState = {
  form: {code: '', email: ''},
  error: undefined,
  inProgress: false,
  resendInProgress: false,
  isDisplayResendAlert: false
};

const reducer = createReducer(
  initialState,
  on(actionConfirmRegistrationFormSubmit, (state, {form, isDeveloperMode}) => ({
    ...state,
    error: undefined,
    inProgress: true,
    isDisplayResendAlert: false,
    isDeveloperMode,
    form
  })),
  on(actionConfirmRegistrationFormError, (state, {error}) => ({
    ...state,
    error,
    inProgress: false,
    isDisplayResendAlert: false
  })),
  on(actionConfirmRegistrationSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: true,
    isDisplayResendAlert: false
  })),
  on(actionSignUpFormReconfirmationRequired, (state, action) => ({
    ...state,
    error: undefined,
    form: {email: action.form.email, code: ''}
  })),
  on(actionSignUpFormConfirmationRequired, (state, {form, isDeveloperMode}) => ({
    ...state,
    error: undefined,
    form: {email: form.email, code: ''},
    isDeveloperMode
  })),
  on(actionSignInFormSubmit, (state, action) => ({
    ...state,
    error: undefined,
    form: {email: action.form.email, code: ''}
  })),
  on(actionSignUpFormSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: false
  })),
  on(actionConfirmRegistrationReSendCode, state => ({
    ...state,
    error: undefined,
    inProgress: false,
    resendInProgress: true
  })),
  on(actionConfirmRegistrationReSendCodeSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: false,
    resendInProgress: false,
    isDisplayResendAlert: true
  }))
);

export function confirmRegistrationStateReducer(
  state: ConfirmRegistrationState | undefined,
  action: Action
): ConfirmRegistrationState {
  return reducer(state, action);
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MissionRoutePoint} from '@app/atlas/services/mission-settings.service';

@Component({
  selector: 'unleash-edit-waypoint-settings',
  templateUrl: './edit-waypoint-settings.component.html',
  styleUrls: ['./edit-waypoint-settings.component.scss']
})
export class EditWaypointSettingsComponent implements OnInit {
  @Input('selectedWaypoint') public set setselectedWaypoint(selectedWaypoint: MissionRoutePoint) {
    if (!selectedWaypoint) {
      return;
    }
    this.selectedWaypoint = selectedWaypoint;
    if (Object.values(this.editForm.controls).length > 0) {
      this.editForm.setValue({
        lat: this.selectedWaypoint.lat,
        lng: this.selectedWaypoint.lng,
        speed: this.selectedWaypoint.speed,
        altitude: this.selectedWaypoint.altitude,
        heading: this.selectedWaypoint.heading,
        pitch: this.selectedWaypoint.pitch,
        gimbal: this.selectedWaypoint.gimbal
      });
    }
  }
  @Input() public selectedWaypointIndex: number;
  @Input() public defaultSettings: MissionRoutePoint;
  @Output() public closeDrawer: EventEmitter<void> = new EventEmitter();

  public editForm: FormGroup = new FormGroup({});
  public selectedWaypoint: MissionRoutePoint;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.editForm = this.fb.group({
      lat: [this.selectedWaypoint?.lat, [Validators.required]],
      lng: [this.selectedWaypoint?.lng, [Validators.required]],
      altitude: [this.selectedWaypoint?.altitude],
      speed: [this.selectedWaypoint?.speed],
      heading: [this.selectedWaypoint?.heading],
      pitch: [this.selectedWaypoint?.pitch],
      gimbal: [this.selectedWaypoint?.gimbal]
    });
  }

  public emitClose(): void {
    this.closeDrawer.emit();
  }
}

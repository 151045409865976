import * as fromSignUp from '@app/auth/components/sign-up/sign-up.selectors';
import {selectAuthState} from '@app/store/app.state';
import {createSelector} from '@ngrx/store';
import {AuthState} from './auth.state';

export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);

export const selectIsAuthenticated = createSelector(selectAuthState, (state: AuthState) => state.isAuthenticated);

export const selectAuthUserId = createSelector(selectAuthState, (state: AuthState) => state.userId);

export const selectAuthIoTInitRequired = createSelector(selectAuthState, (state: AuthState) => state.iotInitRequired);

export const selectAuthUserCreationRequired = createSelector(
  selectAuthState,
  (state: AuthState) => state.userCreationRequired
);

export const selectAuthUserInitializationFinished = createSelector(
  selectAuthState,
  (state: AuthState) => state.userInitializationFinished
);

export const selectAuthPlanActive = createSelector(selectAuthState, (state: AuthState) => state.planActive);

export const selectAwsMarketplaceToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.awsMarketplaceToken
);

export const selectHasDeveloperMode = createSelector(
  fromSignUp.selectSignUpFormIsDeveloperMode,
  isDeveloperMode => isDeveloperMode
);

export const selectPromoCode = createSelector(selectAuthState, (state: AuthState) => state.promoCode);

export const selectSignUpAdditionalParams = createSelector(
  selectAuthState,
  selectAwsMarketplaceToken,
  selectPromoCode,
  (state, awsToken, promoCode) => {
    return {...state, awsMarketplaceToken: awsToken, promoCode};
  }
);

export const selectOrganizationName = createSelector(selectAuthState, (state: AuthState) => state.organizationName);

import {Injectable} from '@angular/core';
import {TeamRole, TeamTable} from '@app/profile/models/team.model';
import {Roles, UserTeam} from '@app/profile/models/users-table.model';
import * as actions from '@app/profile/store/users-management/users-management.actions';
import {
  selectHasMoreThanOneAdmin,
  selectIsUserManagementLoading,
  selectRoles,
  selectSelectedUserTable,
  selectSelectedUserTeamTable,
  selectUsers,
  selectUsersTable
} from '@app/profile/store/profile.selectors';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';
import {UserInvitation} from '../models/user-roles.model';

@Injectable({
  providedIn: 'root'
})
export class UsersManagementStoreFacadeService {
  public users$: Observable<UserTeam[]> = this.store.pipe(select(selectUsers));
  public roles$: Observable<Roles> = this.store.pipe(select(selectRoles));
  public usersTable$: Observable<UserTeam[]> = this.store.pipe(select(selectUsersTable));
  public selectedUserTable$: Observable<UserTeam> = this.store.pipe(select(selectSelectedUserTable));
  public selectedUserTeam$: Observable<TeamTable> = this.store.pipe(select(selectSelectedUserTeamTable));
  public hasMoreThanOneAdmin$: Observable<boolean> = this.store.pipe(select(selectHasMoreThanOneAdmin));
  public isUserManagementActionLoading$: Observable<boolean> = this.store.pipe(select(selectIsUserManagementLoading));

  constructor(private store: Store) {}

  public setUsers(users: UserTeam[]): void {
    this.store.dispatch(actions.setUsers({payload: {users}}));
  }

  public setRoles(roles: Roles): void {
    this.store.dispatch(actions.setRoles({payload: {roles}}));
  }

  public deleteUser(userId: string): void {
    this.store.dispatch(actions.deleteUser({payload: {userId}}));
  }

  public deleteUserSuccess(userId: string): void {
    this.store.dispatch(actions.deleteUserSuccess({payload: {userId}}));
  }

  public updateUserTeamRole(payload: {userId: string; role: TeamRole}): void {
    this.store.dispatch(actions.updateUserTeamRole({payload}));
  }

  public updateUserTeamRoleSuccess(payload: {userId: string; role: TeamRole; teamId: string}): void {
    this.store.dispatch(actions.updateUserTeamRoleSuccess({payload}));
  }

  public setSelectedUserId(userId: UserTeam['userId']) {
    this.store.dispatch(actions.setSelectedUserId({payload: {userId}}));
  }

  public removeSelectedUserId() {
    this.store.dispatch(actions.removeSelectedUserId());
  }

  public addUsersToTeamSuccess(response: {
    users: {userId: string; role: TeamRole; teamId: string}[];
    teamId: string;
    action: UpdateUserTeamAction;
  }) {
    this.store.dispatch(actions.addUsersToTeamSuccess({payload: response}));
  }

  public removeUsersFromTeam(userId: string): void {
    this.store.dispatch(actions.removeUsersFromTeam({payload: {userId}}));
  }

  public removeUsersToTeamSuccess(payload: {
    users: {userId: string; role: TeamRole}[];
    teamId: string;
    action: UpdateUserTeamAction;
  }): void {
    this.store.dispatch(actions.removeUsersToTeamSuccess({payload}));
  }

  public inviteUsers(users: UserInvitation[]): void {
    this.store.dispatch(actions.inviteUsers({payload: {users}}));
  }

  public inviteUsersSuccess(users: UserTeam[]): void {
    this.store.dispatch(actions.inviteUsersSuccess({payload: {users}}));
  }

  public displayLoading(): void {
    this.store.dispatch(actions.displayLoading());
  }

  public hideLoading() {
    this.store.dispatch(actions.hideLoading());
  }
}

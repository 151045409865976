import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {TranslateService} from '@ngx-translate/core';
import {Map} from 'leaflet';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay, filter, shareReplay, take, tap} from 'rxjs/operators';
import {AtlasAssetModel} from '../../../core/models/api/atlas.model';
import {AssetsFilterService} from '../../services/assets-filter.service';
import {SearchLayerComponent} from '../search-layer/search-layer.component';

@Component({
  selector: 'app-layers-control',
  templateUrl: './layers-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./layers-control.component.scss']
})
export class LayersControlComponent implements AfterViewInit {
  @Input()
  public map: Map;
  @Input()
  public totalSelectedLayers: number;
  @Input()
  public saveLayersView: boolean;
  @Input()
  public goToDefaultLayers: boolean;
  @Input()
  public isModellingEnabled: boolean = false;
  @Input()
  public hasToBlockMenu: boolean = false;
  @Input()
  public isListOnly: boolean = false;
  @Input() public hasAllLayersLoaded: boolean = false;

  @Output() public showDashboard: EventEmitter<{
    dashboard: string;
    layerName: string;
  }> = new EventEmitter();
  @Output() public toggleStream: EventEmitter<string> = new EventEmitter();
  @Output() public exportShape: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public exportToGeojson: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public exportToGeotiff: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public compareLayer: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public refreshView: EventEmitter<void> = new EventEmitter();
  @Output() public showOnlyThisLayer: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public selectMarkers: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public hideSideBar: EventEmitter<void> = new EventEmitter();
  @Output() public openSearchResults: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchLayer', {read: SearchLayerComponent, static: false}) public searchLayer: SearchLayerComponent;

  public searchValue$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public searchPlaceholder$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public assets$: Observable<AtlasAssetModel[]>;
  public devices$: Observable<UserDeviceJoined[]>;
  public searchedAssets$: Observable<AtlasAssetModel[]>;
  public aclPermissions = AclPermissions;
  public searchedStreamingGpsDevices$: Observable<AtlasAssetModel[]>;
  public searchFilterQuery$: Observable<string>;
  public isExpanded: boolean = true;
  public isExpandedLayers: boolean = true;
  private assets: AtlasAssetModel[] = [];
  private devices: UserDeviceJoined[] = [];
  private updatedAsset$: Observable<AtlasAssetModel> = null;

  constructor(
    public assetsFilterService: AssetsFilterService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.assets$ = this.assetsFilterService.assets$;
    this.updatedAsset$ = this.assetsFilterService.updatedAsset;
    this.devices$ = this.assetsFilterService.streamingGpsDevices$;
    //TODO Look a way to fire this observable after the sidebar animation
    this.searchedAssets$ = this.assetsFilterService.searchedAssets$.pipe(
      delay(500),
      tap(() => {
        this.assetsFilterService.detectAtlasChanges();
      }),
      shareReplay(1)
    );
    this.searchedStreamingGpsDevices$ = this.assetsFilterService.searchedStreamingGpsDevices$;
    this.searchFilterQuery$ = this.assetsFilterService.searchFilterQuery.asObservable();
    this.searchedStreamingGpsDevices$.subscribe(searchedStreamingGpsDevices => {
      this.isExpanded = !!searchedStreamingGpsDevices.length;
    });
    this.searchedAssets$.subscribe(searchedAssets => {
      this.isExpandedLayers = !!searchedAssets.length;
    });
    this.assets$.subscribe(assets => {
      this.assets = assets;
      this.setSearchPlaceholder();
    });
    this.devices$.subscribe(devices => {
      this.devices = devices;
      this.setSearchPlaceholder();
    });
  }

  public ngAfterViewInit(): void {
    this.handleLayerId();
  }

  public emitShowDashboard(dataDashboard: {dashboard: string; layerName: string}) {
    this.showDashboard.emit(dataDashboard);
  }

  public emitCompareLayer(asset: AtlasAssetModel): void {
    this.compareLayer.emit(asset);
  }

  public clearSearch() {
    this.searchLayer.closeAndCleanDisplayInput();
  }

  public handleLayerId(): void {
    let value = '';
    const assetId = this.route.snapshot.params.id;

    if (assetId) {
      this.assetsFilterService.assets$
        .pipe(
          filter(data => !!data.length),
          take(1)
        )
        .subscribe(assets => {
          const matchedAsset = assets.find(asset => asset.id === assetId);
          value = !!matchedAsset ? matchedAsset.name : '';

          this.searchValue$.next(value);
        });
    }
  }

  public toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  public toggleExpandedLayers() {
    this.isExpandedLayers = !this.isExpandedLayers;
  }

  public emitExportShape(asset: AtlasAssetModel): void {
    this.exportShape.emit(asset);
  }

  public emitRefreshView(): void {
    this.refreshView.emit();
  }

  public emitShowOnlyThisLayer(asset): void {
    this.showOnlyThisLayer.emit(asset);
  }

  public emitExportToGeojson(asset): void {
    this.exportToGeojson.emit(asset);
  }

  public emitExportToGeotiff(asset): void {
    this.exportToGeotiff.emit(asset);
  }

  public emitSelectMarkers(asset: AtlasAssetModel) {
    this.selectMarkers.emit(asset);
  }

  public emitHideSideBar() {
    this.hideSideBar.emit();
  }

  public emitOpenSearchResults(properties: any): void {
    this.openSearchResults.emit(properties);
  }

  private setSearchPlaceholder(): void {
    let translation = '';
    if (this.assets.length && this.devices.length) {
      const hasGroup = this.assets.findIndex(asset => asset.groupName) > -1;
      if (hasGroup) {
        translation = this.translateService.instant('atlas.search.placeholder');
      } else {
        translation = this.translateService.instant('atlas.search.placeholderLayersDevices');
      }
    } else if (this.assets.length) {
      const hasGroup = this.assets.findIndex(asset => asset.groupName) > -1;
      if (hasGroup) {
        translation = this.translateService.instant('atlas.search.placeholderLayersGroups');
      } else {
        translation = this.translateService.instant('atlas.search.placeholderLayers');
      }
    } else if (this.devices.length) {
      translation = this.translateService.instant('atlas.search.placeholderDevices');
    }
    this.searchPlaceholder$.next(translation);
  }
}

import {createReducer, on} from '@ngrx/store';
import {Mission} from '@app/atlas/services/mission-settings.service';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {
  CameraMode,
  DockState,
  DroneState,
  DroneStatus,
  FlightModeLevelState,
  IoTConnectionStatus,
  RemoteCockpitLog
} from '@app/flights/models/remote-cockpit.model';
import * as RemoteCockpitActions from './remote-cockpit.actions';
import {MissionState} from '@app/flights/components/remote-cockpit/remote-cockpit-control-tile/mission-tile/mission-state.model';

export const remoteCockpitFeatureKey = 'remoteCockpit';

export interface State {
  currentDeviceState: DroneState;
  currentDeviceLogs: RemoteCockpitLog[];
  currentDevice: UserDeviceJoined;
  currentMission: Mission;
  iotConnectionStatus: string;
  isCurrentMissionPaused: boolean;
  missions: {[key: string]: Mission};
}

const emptyDeviceState: DroneState = {
  timeLeft: 0,
  remoteAccess: null,
  isStreaming: false,
  deviceConnected: false,
  signalStrength: null,
  cameraMode: CameraMode.SHOOT_PHOTO,
  flightFrame: null,
  isMissionInProgress: false,
  missionState: MissionState.DISCONNECTED,
  gpsConnected: false,
  isFlightInProgress: false,
  isHoldOn: false,
  isRecording: false,
  relativeCapacity: [],
  dock: DockState.NO_DATA,
  droneStatus: DroneStatus.NOT_READY,
  flightModeStatus: FlightModeLevelState.ERROR
};

export const initialState: State = {
  currentDevice: null,
  currentDeviceLogs: [],
  currentMission: null,
  missions: null,
  iotConnectionStatus: IoTConnectionStatus.DISCONNECTED,
  isCurrentMissionPaused: false,
  currentDeviceState: emptyDeviceState
};
export const reducer = createReducer(
  initialState,
  on(RemoteCockpitActions.setSelectedDevice, (state, {device}) => ({
    ...state,
    currentDevice: device,
    currentDeviceLogs: [],
    currentDeviceState: emptyDeviceState
  })),
  on(RemoteCockpitActions.addCurrentDeviceLogSuccess, (state, {log}) => {
    return {
      ...state,
      currentDeviceLogs: [log, ...state.currentDeviceLogs]
    };
  }),
  on(RemoteCockpitActions.setCurrentDeviceState, (state, {currentDeviceState}) => ({
    ...state,
    currentDeviceState: {...state.currentDeviceState, ...currentDeviceState}
  })),
  on(RemoteCockpitActions.disconnectCurrentDevice, state => ({
    ...state,
    currentDeviceLogs: [],
    currentDeviceState: emptyDeviceState
  })),
  on(RemoteCockpitActions.setCurrentMission, (state, {mission}) => ({
    ...state,
    currentMission: mission,
    currentDeviceState: {...state.currentDeviceState, missionState: MissionState.READY_TO_EXECUTE}
  })),
  on(RemoteCockpitActions.setMissions, (state, {missions}) => ({
    ...state,
    missions
  })),
  on(RemoteCockpitActions.setMissionInProgress, (state, {isMissionInProgress}) => ({
    ...state,
    currentDeviceState: {
      ...state.currentDeviceState,
      isMissionInProgress
    }
  })),
  on(RemoteCockpitActions.setFlightInProgress, (state, {isFlightInProgress}) => ({
    ...state,
    currentDeviceState: {
      ...state.currentDeviceState,
      isFlightInProgress
    }
  })),
  on(RemoteCockpitActions.setIoTConnectionStatus, (state, {iotConnectionStatus}) => ({
    ...state,
    iotConnectionStatus
  })),
  on(RemoteCockpitActions.pauseCurrentMissionMap, state => ({
    ...state,
    isCurrentMissionPaused: true
  })),
  on(RemoteCockpitActions.reconnectCurrentMissionMap, state => ({
    ...state,
    isCurrentMissionPaused: false
  })),
  on(RemoteCockpitActions.updateCurrentLens, (state, {lensId}) => ({
    ...state,
    currentDeviceState: {...state.currentDeviceState, currentLens: lensId}
  }))
);

import {LibraryItem} from '@app/library/models/folder-file.model';
import {createAction, props} from '@ngrx/store';

export enum DownloadAnalyticsActionTypes {
  ExportAnalytics = '[Library] Export analytics',
  DownloadAnalyticsMenuItems = '[Library] Download library analytics menu items'
}

export const downloadAnalyticsMenuItems = createAction(
  DownloadAnalyticsActionTypes.DownloadAnalyticsMenuItems,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const exportAnalytics = createAction(
  DownloadAnalyticsActionTypes.ExportAnalytics,
  props<{payload: {path: string}}>()
);

import {Directive, HostListener} from '@angular/core';
import {MissionSettingsService} from '../services/mission-settings.service';
import {MissionManagerService} from '../services/mission-manager.service';

@Directive({
  selector: '[ulNewRoute]'
})
export class NewRouteDirective {
  @HostListener('click', ['$event'])
  public onClick() {
    this.missionSettingsService.setMission(null);
    this.missionSettingsService.clearRoute();
    this.missionSettingsService.newRoute();
    this.missionManagerService.setMissionName(null);
  }
  constructor(
    private missionSettingsService: MissionSettingsService,
    private missionManagerService: MissionManagerService
  ) {}
}

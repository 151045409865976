import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSliderChange} from '@angular/material/slider';
import {format} from 'date-fns';
import {Map} from 'leaflet';

@Component({
  selector: 'ua-flight-time-control',
  templateUrl: './flight-time-control.component.html',
  styleUrls: ['./flight-time-control.component.scss']
})
export class FlightTimeControlComponent {
  @Input() numFrames: number;
  @Input() map: Map;
  @Input() isSliderVisible: boolean = true;
  @Output() timeChanged = new EventEmitter();

  isScrubbing: boolean = false;
  isPlaying: boolean = false;
  time: number = 0; // number of frames passed from beginning (milliseconds / 100)
  /* Speed and interval affect frames step
   * tickInterval = 100 && speed = 1 => 10fps
   * tickInterval = 100 && speed = 2 => 20fps
   * tickInterval = 100 && speed = 4 => 40fps
   */
  speed: number = 1;
  private timerId: any /* NodeJS.Timer */;
  private tickInterval: number = 100;

  constructor() {}

  ngOnInit() {}

  play() {
    this.isPlaying = true;
    this.timerId = setInterval(() => this.tick(), this.tickInterval) as any /* as NodeJS.Timer */;
  }

  pause() {
    this.isPlaying = false;
    clearInterval(this.timerId);
  }

  changeSpeed() {
    this.speed = this.speed * 2 <= 16 ? this.speed * 2 : 1;
  }

  changeTime(event: MatSliderChange) {
    this.time = event.value;
    this.timeChanged.emit({frameNum: this.time, redraw: true});
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    let date = new Date(value * 100);
    date = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
      )
    );

    return format(date, 'mm:ss');
  }

  disableMapPanning() {
    this.isScrubbing = true;
    this.map.dragging.disable();
  }

  enableMapPanning() {
    this.isScrubbing = false;
    this.map.dragging.enable();
  }

  private tick() {
    if (this.isScrubbing) {
      return;
    }

    if (this.time + this.speed <= this.numFrames) {
      this.time += this.speed;
    } else {
      this.pause();
      this.time = this.numFrames; // set time to the last frame num
    }

    this.timeChanged.emit({frameNum: this.time});
  }
}

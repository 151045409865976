import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DeviceService} from '@app/core/services/api/device.service';
import {BehaviorSubject, Subscription, map, shareReplay, take, tap} from 'rxjs';
import {CalibrationService} from '../../services/calibration.service';
import {CalibrationLayout, CalibrationType} from '../../calibration.component';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {AnalyticsConfig} from '@app/core/models/api/user-device.model';

@Component({
  selector: 'app-delete-dialog',
  template: `
    <h2 mat-dialog-title class="title">{{ 'calibration.delete' | translate }} {{ calibrationType }}</h2>
    <mat-dialog-content>
      <p *ngIf="pointsLength$ | async as pointsLength">
        {{ 'calibration.deleteDescription' | translate: {value: pointsLength} }}
      </p>
      <p>{{ 'calibration.deleteConfirmation' | translate }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close color="primary" [disabled]="isLoading$ | async">
        {{ 'calibration.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        (click)="deleteCalibration()"
        [appLadda]="isLoading$ | async"
        color="warn"
        [disabled]="isLoading$ | async"
      >
        <mat-icon>delete</mat-icon>
        {{ 'calibration.delete' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .title {
        margin: 0;
      }
    `
  ],
  standalone: true,
  imports: [MatDialogModule, LaddaModule, AsyncPipe, MatButtonModule, MatIconModule, NgIf, TranslateModule]
})
/* eslint-disable camelcase */
export class CalibrationConfirmDeleteDialogComponent implements OnInit, OnDestroy {
  private isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoading.asObservable().pipe(shareReplay(1));

  private pointsLength = new BehaviorSubject<number>(0);
  public pointsLength$ = this.pointsLength.asObservable().pipe(shareReplay(1));

  public calibrationType = CalibrationType.perspective;
  private pointsLatLngSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CalibrationConfirmDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      deviceId: string;
      addonId: string;
      calibrationType: CalibrationType;
      analyticsConfig: AnalyticsConfig;
    },
    private deviceService: DeviceService,
    private calibrationService: CalibrationService
  ) {}

  public ngOnInit(): void {
    this.pointsLatLngSub = this.calibrationService.pointsLatLng$
      .pipe(map(points => this.pointsLength.next(points.length)))
      .subscribe();
    this.calibrationType = this.data.calibrationType;
  }

  public ngOnDestroy(): void {
    if (this.pointsLatLngSub) {
      this.pointsLatLngSub.unsubscribe();
      this.pointsLatLngSub = null;
    }
  }

  private updateAnlysisConfig(): AnalyticsConfig {
    const analyticsConfig = {...this.data.analyticsConfig};
    const analytics = analyticsConfig[this.data.addonId].analytics;
    const totalAnalytics = Object.keys(analytics).length;
    //Only calibration plugin -> Remove all addon
    if (analytics.ul_camera_calibration_analytics && totalAnalytics === 1) {
      const {[this.data.addonId]: addonId, ...rest} = analyticsConfig;
      if (Object.keys(rest).length === 0) {
        //No more addons then no Analysis Config
        return '' as any;
      }
      return {[this.data.addonId]: '' as any};
    }
    //More than one plugin -> remove only the calibration plugin
    if (analytics.ul_camera_calibration_analytics && totalAnalytics > 1) {
      return {[this.data.addonId]: {analytics: {ul_camera_calibration_analytics: ''}}} as any;
    }
  }

  public deleteCalibration(): void {
    const analyticsConfig = this.updateAnlysisConfig();
    this.isLoading.next(true);
    this.deviceService
      .updateDevice(this.data.deviceId, {analyticsConfig})
      .pipe(
        take(1),
        tap(() => {
          this.isLoading.next(false);
          this.calibrationService.emitDeleteAllPoints();
          this.calibrationService.setCurrentLayout(CalibrationLayout.empty);
          this.calibrationService.deleteSceneMappingSuccess({deviceId: this.data.deviceId, addonId: this.data.addonId});
          this.dialogRef.close({hasBeenDeleted: true, calibrationType: this.data.calibrationType});
        })
      )
      .subscribe();
  }
}

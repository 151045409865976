import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataDashboardComponent} from './pages/data-dashboard/data-dashboard.component';
import {DataRoutingModule} from './data-routing.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';

@NgModule({
  declarations: [DataDashboardComponent],
  imports: [CommonModule, DataRoutingModule, UnleashLoaderModule],
  exports: [DataDashboardComponent]
})
export class DataModule {}

import {createAction, props} from '@ngrx/store';
import {FlightLogsItem} from '@app/flights/models/flight-logs.model';

export enum FlightLogActionTypes {
  OpenUploadDialog = '[Flight-log] Upload flight log file',
  SaveItems = '[Flight-log] Write items to store',
  LoadFlightLogItems = '[Flight-log] Load items from database',
  UpdateFlightLogItem = '[Flight-log] Update flight log file',
  UpdateCheckedFlightLogsItems = '[Flight-log] Update checked flight log files',
  DeleteFlightLogsItems = '[Flight-log] Delete flight log files',
  DeleteFlightLogsItemsSuccess = '[Flight-log] Delete flight log files success',
  DownloadFlightLogsItems = '[Flight-log] Download flight log files'
}

export const openUploadDialog = createAction(
  FlightLogActionTypes.OpenUploadDialog,
  props<{payload: {files: File[]; deviceId: string}}>()
);

export const loadFlightLogItems = createAction(FlightLogActionTypes.LoadFlightLogItems);
export const saveItems = createAction(
  FlightLogActionTypes.SaveItems,
  props<{payload: {flightLogsItems: FlightLogsItem[]}}>()
);
export const updateFlightLogItem = createAction(
  FlightLogActionTypes.UpdateFlightLogItem,
  props<{payload: {flightLogItem: FlightLogsItem}}>()
);
export const updateCheckedFlightLogsItems = createAction(
  FlightLogActionTypes.UpdateCheckedFlightLogsItems,
  props<{payload: {checked: boolean}}>()
);

export const deleteFlightLogsItems = createAction(
  FlightLogActionTypes.DeleteFlightLogsItems,
  props<{payload: {flightLogsItems: FlightLogsItem[]; isAllChecked: boolean}}>()
);

export const deleteFlightLogsItemsSuccess = createAction(
  FlightLogActionTypes.DeleteFlightLogsItemsSuccess,
  props<{payload: {flightLogsItemsIds: string[]}}>()
);

export const downloadFlightLogsItems = createAction(
  FlightLogActionTypes.DownloadFlightLogsItems,
  props<{payload: {flightLogsItems: FlightLogsItem[]; isAllChecked: boolean}}>()
);

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {GROW_DOWN_ANIMATION} from '../../banner/banner.animation';

@Component({
  selector: 'app-header-navbar',
  templateUrl: './header-navbar.component.html',
  styleUrls: ['./header-navbar.component.scss'],
  animations: [GROW_DOWN_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavbarComponent {
  @Input() public mobileHeaderLabel: string = '';
  @Output() public openMobileMenu: EventEmitter<void> = new EventEmitter();

  public emitOpenMobileMenu(): void {
    this.openMobileMenu.emit();
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {DeviceService} from '@app/core/services/api/device.service';
import {UserService} from '@app/core/services/api/user.service';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {DeviceDialog} from '@app/profile/components/device-dialog/device-dialog.component';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, shareReplay, tap, take} from 'rxjs/operators';
import {RestreamService} from '../../../core/services/api/restream.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '../../../shared/pipes/models/thumbler.model';
import {FourGridLayoutService} from '../four-grid-layout/four-grid-layout.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {AclService} from '@app/core/services/acl.service';
import {LowBatteryDialog} from '../low-battery-dialog/low-battery-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-device-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.scss']
})
export class DeviceTableComponent implements OnInit {
  public events = EVENTS;
  @Input()
  public dataSource: UserDeviceJoined[] = [];

  public displayedColumns: string[] = [];
  public selectedDeviceId$: Observable<string> = this.liveStreamPageService.selectedDeviceId$.pipe(shareReplay());
  public THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;
  public isLoading = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoading.asObservable();

  public aclPermissions = AclPermissions;

  constructor(
    public dialog: MatDialog,
    public deviceService: DeviceService,
    public fourGridLayoutService: FourGridLayoutService,
    private liveStreamPageService: LiveStreamPageService,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private aclService: AclService,
    private translateService: TranslateService,
    private restreamSevice: RestreamService
  ) {}

  public ngOnInit() {
    this.aclService.hasSetupPermission$
      .pipe(
        filter(hasSetupPermission => !!hasSetupPermission),
        take(1)
      )
      .subscribe(() => {
        this.displayedColumns = this.aclService.hasPermission(this.aclPermissions.LivestreamApiStreamPublish)
          ? ['device', 'restream', 'status', 'stream', 'addToViewport', 'ai', 'manage']
          : ['device', 'status', 'stream', 'addToViewport', 'ai'];
        this.isLoading.next(false);
      });
  }

  public toggleFavourite(device: UserDeviceJoined) {
    device.isFavourite = !device.isFavourite;

    this.deviceService
      .updateDevice(device.id, device)
      .pipe(take(1))
      .subscribe({
        next: success => {
          this.userService.updateDeviceCache(device);
        },
        error: console.error
      });
  }

  public addToCinemaViewport(device: UserDeviceJoined) {
    this.selectedDeviceId$.pipe(take(1)).subscribe(selectedDeviceId => {
      if (device.isDisplayed === false) {
        const selectedDevice = {...this.dataSource.find(dev => dev.id === selectedDeviceId)};
        selectedDevice.isDisplayed = false;
        this.updateDisplayedDevice(selectedDevice);
      }

      device = {...device, isDisplayed: true};
      this.updateDisplayedDevice(device);
      this.fourGridLayoutService.setDeviceOnGrid(device, 'device');
    });
  }

  public trackById(index: number, device: UserDeviceJoined) {
    return device.id;
  }

  public showDeviceDialog(device?: UserDeviceJoined): void {
    this.dialog.open(DeviceDialog, {
      width: '80vw',
      maxWidth: '800px',
      data: device || {}
    } as MatDialogConfig);
  }

  private openLowBatteryDialog(device: UserDeviceJoined) {
    this.dialog
      .open(LowBatteryDialog, {
        data: {deviceName: device.name}
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(confirmation => !!confirmation),
        tap(() => {
          this.startStream(device);
        })
      )
      .subscribe();
  }

  public verifyStartStream(device: UserDeviceJoined): void {
    const stopAfter = device.automation?.batteryPowered?.stopAfter;
    if (stopAfter) {
      const batteryDurationTime = (stopAfter || 0) - Date.now();
      batteryDurationTime > 0 ? this.openLowBatteryDialog(device) : this.startStream(device);
      return;
    }
    this.startStream(device);
  }

  public startStream(device: UserDeviceJoined) {
    this.userService.startStream(device);
  }

  public stopStream(device: UserDeviceJoined) {
    this.translateService
      .get('live.device-table.stopStreamFrom')
      .pipe(take(1))
      .subscribe(i18StopStreamFrom =>
        this.snackBar.open(`${i18StopStreamFrom} ${device.name}`, null, {
          duration: 2000
        } as MatSnackBarConfig)
      );

    this.userService.user$.pipe(take(1)).subscribe(user => {
      this.restreamSevice
        .stopStreamFrom(device.id, user.streamKey)
        .pipe(take(1))
        .subscribe({
          next: () => this.liveStreamPageService.removeLiveDevice(device),
          error: error => {
            this.snackBar.open(error.message, null, {
              duration: 2000
            } as MatSnackBarConfig);
          }
        });
    });
  }

  private updateDisplayedDevice(device: UserDeviceJoined) {
    this.userService.updateDeviceCache(device);
  }
}

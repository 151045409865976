import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {Polygon} from '@app/shared/image-annotation-shared/models/polygon';
import {Canvas} from './image-annotation.model';

export class PolygonExcluded extends Polygon {
  constructor(ref: any, canvas: Canvas) {
    super(ref, canvas);
    this.type = ShapeTypes.polygon_excluded;
  }
}

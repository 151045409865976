export const DARK_COLORS = [
  'rgb(0,0,255)',
  'rgb(128,0,128)',
  'rgb(128,128,128)',
  'rgb(168,42,42)',
  'rgb(58, 125, 223)',
  'rgb(39, 152, 177)',
  'rgb(32, 156, 94)',
  'rgb(78, 78, 78)'
];

export const LIGHT_COLORS = [
  'rgb(255,0,0)',
  'rgb(0,255,0)',
  'rgb(255,255,0)',
  'rgb(255,165,0)',
  'rgb(255,215,0)',
  'rgb(211,211,211)',
  'rgb(135,206,235)',
  'rgb(238,130,238)',
  'rgb(0,206,209)',
  'rgb(186,186,186)',
  'rgb(173,145,250)',
  'rgb(243,134,168)',
  'rgb(250,66,47)',
  'rgb(255,105,56)',
  'rgb(255,162,72)',
  'rgb(252,229,128)',
  'rgb(65,208,139)',
  'rgb(152,232,79)',
  'rgb(137,248,90)',
  'rgb(202,253,41)',
  'rgb(106,218,9)'
];

export const AVAILABLE_COLORS = [...LIGHT_COLORS, ...DARK_COLORS];

export enum LabelColor {
  grey = 'rgb(186, 186, 186)',
  blue = 'rgb(58, 125, 223)',
  skyblue = 'rgb(39, 152, 177)',
  purple = 'rgb(173, 145, 250)',
  pink = 'rgb(243, 134, 168)',
  red = 'rgb(250, 66, 47)',
  orange = 'rgb(255, 105, 56)',
  lightorange = 'rgb(255, 162, 72)',
  yellow = 'rgb(252, 229, 128)',
  lightgreen = 'rgb(65, 208, 139)',
  green = 'rgb(32, 156, 94)',
  black = 'rgb(78, 78, 78)',
  white = 'rgb(250, 250, 250)'
}

export enum LabelColorName {
  grey = 'grey',
  blue = 'blue',
  skyblue = 'skyblue',
  purple = 'purple',
  pink = 'pink',
  red = 'red',
  orange = 'orange',
  lightorange = 'lightorange',
  yellow = 'yellow',
  lightgreen = 'lightgreen',
  green = 'green',
  black = 'black',
  white = 'white'
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LaddaModule} from '@app/shared/ladda/ladda.module';

@NgModule({
  imports: [
    CommonModule,
    LaddaModule
  ],
  exports: [LaddaModule]
})
export class LoadingSpinnerModule {}

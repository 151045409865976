import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'not-found',
  templateUrl: './notFound.html'
})
export class PageNotFoundComponent implements OnInit {

  constructor(public router: Router) {
  }

  ngOnInit() {

  }

}

<div class="container">
  <unleash-spinner diameter="20" borderWidth="2.1" color="#fff"></unleash-spinner>
  <div class="content">
    <div class="content__info">
      {{
        'live.live-stream-page.stillWatchingMessage' | translate: {name: data.deviceName, time: (timer$ | async) / 1000}
      }}
    </div>
    <div (click)="continueWatching()" class="content__action">
      {{ 'live.live-stream-page.continueWatching' | translate }}
    </div>
  </div>
</div>

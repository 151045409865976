import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {NestedRule, RuleOperator} from '../../models/rule.model';

@Component({
  selector: 'unleash-simple-rule-row',
  templateUrl: './simple-rule-row.component.html',
  styleUrls: ['./simple-rule-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleRuleRowComponent implements OnInit {
  @Input()
  form: UntypedFormGroup = new UntypedFormGroup({
    zone: new UntypedFormControl(''),
    condition: new UntypedFormControl(''),
    operator: new UntypedFormControl(''),
    value: new UntypedFormControl('', {updateOn: 'blur'})
  });
  @Input() zones: Zone[] = [];
  @Input() conditions: any[] = [
    {name: 'Total', id: 'total'}, // class name -> translate extra trigger
    {name: 'All objects', id: 'all'}
  ]; // no filter
  @Input() operators: RuleOperator[] = [];

  @Output() formChange: EventEmitter<{rules: NestedRule[]}> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  emitFormChange(): void {
    const rules = [];

    if (this.form.value.condition !== 'all-classes') {
      const mainNestedRule: NestedRule = {
        zone: 'triggered_zones',
        logicOperator: 'AND',
        condition: 'disabled',
        operator: 'eq',
        value: this.form.value.zone
      };

      const mainClassNestedRule: NestedRule = {
        zone: 'class_name',
        logicOperator: 'AND',
        condition: 'disabled',
        operator: 'eq',
        value: this.form.value.condition
      };

      const nextClassNestedRule: NestedRule = {
        zone: 'any',
        logicOperator: '',
        condition: 'count',
        operator: this.form.value.operator,
        value: this.form.value.value
      };

      rules.push(mainNestedRule, mainClassNestedRule, nextClassNestedRule);
    } else if (this.form.value.zone === 'all-scene') {
      const nextNestedRule: NestedRule = {
        zone: 'triggered_zones',
        logicOperator: '',
        condition: 'count',
        operator: this.form.value.operator,
        value: this.form.value.value
      };
      rules.push(nextNestedRule);
    } else {
      const mainNestedRule: NestedRule = {
        zone: 'triggered_zones',
        logicOperator: 'AND',
        condition: 'disabled',
        operator: 'eq',
        value: this.form.value.zone
      };

      const nextNestedRule: NestedRule = {
        zone: 'triggered_zones',
        logicOperator: '',
        condition: 'count',
        operator: this.form.value.operator,
        value: this.form.value.value
      };
      rules.push(mainNestedRule, nextNestedRule);
    }

    console.log('rules to emit', rules);

    this.formChange.emit({rules});
  }

  updateValueOnBlur(controlName: string, input: HTMLInputElement): void {
    this.form.controls[controlName].setValue(input.value);
    this.emitFormChange();
  }
}

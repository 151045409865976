import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TeamRole, TeamTable} from '@app/profile/models/team.model';
import {Roles} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-add-user-row',
  templateUrl: './add-user-row.component.html',
  styleUrls: ['./add-user-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AddUserRowComponent {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
    teamId: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl('', Validators.required)
  });
  @Input() public teams: TeamTable[] = [];
  @Input() public roles: Roles = {};
  @Input() public hasDelete: boolean = true;

  @Output() public delete: EventEmitter<void> = new EventEmitter();

  public teamRole = TeamRole;

  public emitDelete(): void {
    this.delete.emit();
  }
}

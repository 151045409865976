import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'unleash-current-plan',
  templateUrl: './current-plan.component.html',
  styleUrls: ['./current-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentPlanComponent implements OnInit {
  @Output() changeAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() returnAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  clickReturnAction() {
    this.returnAction.emit(true);
  }

  clickChangeAction() {
    this.changeAction.emit(true);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PayloadAction} from '@app/shared/models/payload-action';
import {FlightLogParser} from '@app/flights/components/flight-log/flight-log-parser';
import {Actions, createEffect, CreateEffectMetadata, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, mergeMap, switchMap, tap} from 'rxjs/operators';
import {FlightLogDetailData} from '../flight-log-detail-dialog.component';
import {FlightLogDetailDialogFacadeService} from '../services/flight-log-detail-dialog-facade.service';
import {FlightLogDetailDialogActionTypes} from './flight-log-detail-dialog.actions';

@Injectable({
  providedIn: 'root'
})
export class FlightLogDetailDialogEffects {
  public loadFlightLogs$: Observable<string | FlightLogDetailData> & CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FlightLogDetailDialogActionTypes.LoadFlightLogs),
        tap(() => this.flightLogDetailDialogFacadeService.displayLoading()),
        switchMap(({payload}: PayloadAction<{url: string}>) =>
          this.httpClient.get(payload.url, {responseType: 'blob'}).pipe(
            mergeMap((file: File) => {
              return this.parser.readFile(file);
            })
          )
        ),
        tap((data: FlightLogDetailData) => {
          this.flightLogDetailDialogFacadeService.loadFlightLogsSuccess(data);
        }),
        catchError(() => {
          const errorMessage = 'Error on load flight detail logs';
          this.flightLogDetailDialogFacadeService.loadFlightLogsFail(errorMessage);
          return of(errorMessage);
        })
      ),
    {dispatch: false}
  );

  public loadFlightLogsSuccess$: Observable<PayloadAction<FlightLogDetailData>> & CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FlightLogDetailDialogActionTypes.LoadFlightLogsSuccess),
        tap(() => this.flightLogDetailDialogFacadeService.hideLoading()),
        tap((action: PayloadAction<FlightLogDetailData>) =>
          this.flightLogDetailDialogFacadeService.setFlightLogDialogDetailData(action.payload)
        )
      ),
    {dispatch: false}
  );

  public loadFlightLogsFail$: Observable<PayloadAction<{error: string}>> & CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FlightLogDetailDialogActionTypes.LoadFlightLogsFail),
        tap(() => this.flightLogDetailDialogFacadeService.hideLoading()),
        tap((action: PayloadAction<{error: string}>) => console.error(action.payload.error))
      ),
    {dispatch: false}
  );

  private parser: FlightLogParser = new FlightLogParser();

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private flightLogDetailDialogFacadeService: FlightLogDetailDialogFacadeService
  ) {}
}

import {reasonStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {ReasonState} from './reason.reducer';

export const selectReasonFormValue = createSelector(
  reasonStateSelector,
  (state: ReasonState) => state.form
);

export const selectReasonIsInProgress = createSelector(
  reasonStateSelector,
  (state: ReasonState) => state.isInProgress
);

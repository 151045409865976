import {Properties} from './interfaces';
import {defaultProperties} from './properties';
import {Touches} from './touches';

type PropertyName = keyof Properties;

export class IvyPinch {
  properties: Properties = defaultProperties;
  touches: any;
  element: any;
  elementTarget: any;
  parentElement: any;
  i: number = 0;
  scale: number = 1;
  initialScale: number = 1;
  elementPosition: any;
  eventType: any;
  startX: number = 0;
  startY: number = 0;
  moveX: number = 0;
  moveY: number = 0;
  initialMoveX: number = 0;
  initialMoveY: number = 0;
  moveXC: number = 0;
  moveYC: number = 0;
  lastTap: number = 0;
  draggingMode: boolean = false;
  distance: number = 0;
  doubleTapTimeout: number = 0;
  initialDistance: number = 0;
  events: any = {};
  maxScale!: number;
  defaultMaxScale: number = 3;
  disableDragByLeftMouse: boolean = false;

  // Minimum scale at which panning works
  get minPanScale() {
    return this.getPropertiesValue('minPanScale');
  }

  get minScale() {
    return this.getPropertiesValue('minScale');
  }

  get fullImage() {
    return this.properties.fullImage;
  }

  constructor(properties: any) {
    this.element = properties.element;

    if (!this.element) {
      return;
    }

    this.elementTarget = this.element.querySelector('*').tagName;
    this.parentElement = this.element.parentElement;
    this.properties = {...defaultProperties, ...properties};
    this.detectLimitZoom();

    this.touches = new Touches({
      element: properties.element,
      listeners: properties.listeners,
      resize: properties.autoHeight,
      mouseListeners: {
        mousedown: 'handleMousedown',
        mouseup: 'handleMouseup',
        wheel: 'handleWheel'
      }
    });

    /* Init */
    this.setBasicStyles();

    /*
     * Listeners
     */

    this.touches.on('touchstart', this.handleTouchstart);
    this.touches.on('touchend', this.handleTouchend);
    this.touches.on('mousedown', this.handleTouchstart);
    this.touches.on('mouseup', this.handleTouchend);
    this.touches.on('pan', this.handlePan);
    this.touches.on('mousemove', this.handlePan);
    this.touches.on('pinch', this.handlePinch);

    if (this.properties.wheel) {
      this.touches.on('wheel', this.handleWheel);
    }

    if (this.properties.doubleTap) {
      this.touches.on('double-tap', this.handleDoubleTap);
    }

    if (this.properties.autoHeight) {
      this.touches.on('resize', this.handleResize);
    }
  }

  public disableDraggable(): void {
    this.touches.on('mousedown', () => null);
  }

  public enableDraggable(): void {
    this.touches.on('mousedown', this.handleTouchstart);
  }

  /* Custom events */

  public emitEvent(properties: any): void {
    /*
        this.events[properties.name] = new CustomEvent(properties.name, {
            'detail': properties.detail
        });
        this.element.dispatchEvent(this.events[properties.name]);
        */

    /* Emit angular event */
    if (this.properties.eventHandler) {
      this.properties.eventHandler.emit({
        name: properties.name,
        detail: properties.detail
      });
    }
  }

  /* Touchstart */

  public handleTouchstart = (event: any) => {
    if (!this.disableDragByLeftMouse || event.which === 2) {
      this.touches.addEventListeners('mousemove', 'handleMousemove');
      this.getElementPosition();

      if (this.eventType === undefined) {
        this.getTouchstartPosition(event);
      }

      this.emitEvent({
        name: 'touchstart'
      });
    }
  };

  /* Touchend */

  public handleTouchend = (event: any) => {
    /* touchend */
    if (event.type === 'touchend') {
      this.i = 0;
      this.draggingMode = false;
      const touches = event.touches;

      // Min scale
      if (this.scale < 1) {
        this.scale = 1;
      }

      // Auto Zoom Out
      if (this.properties.autoZoomOut && this.eventType === 'pinch') {
        this.scale = 1;
      }

      // Align image
      if (this.eventType === 'pinch' || (this.eventType === 'pan' && this.scale > this.minPanScale)) {
        this.alignImage();
      }

      // Update initial values
      if (
        this.eventType === 'pinch' ||
        this.eventType === 'pan' ||
        this.eventType === 'horizontal-swipe' ||
        this.eventType === 'vertical-swipe'
      ) {
        this.updateInitialValues();
      }

      this.eventType = 'touchend';

      if (touches && touches.length === 0) {
        this.eventType = undefined;
      }
    }

    /* mouseup */
    if (event.type === 'mouseup') {
      this.draggingMode = false;
      this.updateInitialValues();
      this.eventType = undefined;
    }

    this.emitEvent({
      name: 'touchend'
    });
    this.touches.removeEventListeners('mousemove', 'handleMousemove');
  };

  /*
   * Handlers
   */

  public handlePan = (event: any) => {
    if (this.scale < this.minPanScale || this.properties.disablePan) {
      return;
    }

    event.preventDefault();
    const {clientX, clientY} = this.getClientPosition(event);

    if (!this.eventType) {
      this.startX = clientX - this.elementPosition.left;
      this.startY = clientY - this.elementPosition.top;
    }

    this.eventType = 'pan';
    this.moveX = this.initialMoveX + (this.moveLeft(event, 0) - this.startX);
    this.moveY = this.initialMoveY + (this.moveTop(event, 0) - this.startY);

    if (this.properties.limitPan) {
      this.limitPanY();
      this.limitPanX();
    }

    this.emitEvent({
      name: 'pan',
      detail: {
        moveX: this.moveX,
        moveY: this.moveY
      }
    });
    this.transformElement(0);
  };

  public handleDoubleTap = (event: any) => {
    this.emitEvent({
      name: 'double-tap',
      detail: {
        scale: this.scale
      }
    });
    this.toggleZoom(event);
    return;
  };

  public handlePinch = (event: any) => {
    event.preventDefault();

    if (this.eventType === undefined || this.eventType === 'pinch') {
      const touches = event.touches;

      if (!this.eventType) {
        this.initialDistance = this.getDistance(touches);
        const moveLeft0 = this.moveLeft(event, 0);
        const moveLeft1 = this.moveLeft(event, 1);
        const moveTop0 = this.moveTop(event, 0);
        const moveTop1 = this.moveTop(event, 1);
        this.moveXC = (moveLeft0 + moveLeft1) / 2 - this.initialMoveX;
        this.moveYC = (moveTop0 + moveTop1) / 2 - this.initialMoveY;
      }

      this.eventType = 'pinch';
      this.distance = this.getDistance(touches);
      this.scale = this.initialScale * (this.distance / this.initialDistance);
      this.moveX = this.initialMoveX - ((this.distance / this.initialDistance) * this.moveXC - this.moveXC);
      this.moveY = this.initialMoveY - ((this.distance / this.initialDistance) * this.moveYC - this.moveYC);

      this.handleLimitZoom();

      if (this.properties.limitPan) {
        this.limitPanY();
        this.limitPanX();
      }

      if (this.fullImage) {
        this.replaceImagePath();
      }

      this.emitEvent({
        name: 'pinch',
        detail: {
          scale: this.scale
        }
      });
      this.transformElement(0);
    }
  };

  public handleWheel = (event: any) => {
    event.preventDefault();

    const wheelZoomFactor = this.properties.wheelZoomFactor || 0;
    const zoomFactor = event.deltaY < 0 ? wheelZoomFactor : -wheelZoomFactor;
    let newScale = this.initialScale + zoomFactor;
    const minScale = this.properties.minScale || 0;

    if (newScale <= minScale) {
      if (minScale === 1) {
        this.resetScale();
      }
      return;
    }

    if (newScale < this.maxScale && newScale > this.maxScale - wheelZoomFactor) {
      newScale = this.maxScale;
    }

    if (newScale === this.scale) {
      return;
    }

    this.getElementPosition();
    this.scale = newScale;

    /* Get cursor position over image */
    const xCenter = event.clientX - this.elementPosition.left - this.initialMoveX;
    const yCenter = event.clientY - this.elementPosition.top - this.initialMoveY;

    this.setZoom({
      scale: newScale
    });
  };

  public handleResize = (_event: any) => {
    this.setAutoHeight();
  };

  public handleLimitZoom() {
    const limitZoom = this.maxScale;
    const minScale = this.properties.minScale || 0;

    if (this.scale > limitZoom || this.scale <= minScale) {
      const imageWidth = this.getImageWidth();
      const imageHeight = this.getImageHeight();
      const enlargedImageWidth = imageWidth * this.scale;
      const enlargedImageHeight = imageHeight * this.scale;
      const moveXRatio = this.moveX / (enlargedImageWidth - imageWidth);
      const moveYRatio = this.moveY / (enlargedImageHeight - imageHeight);

      if (this.scale > limitZoom) {
        this.scale = limitZoom;
      }

      if (this.scale <= minScale) {
        this.scale = minScale;
      }

      const newImageWidth = imageWidth * this.scale;
      const newImageHeight = imageHeight * this.scale;

      this.moveX = -Math.abs(moveXRatio * (newImageWidth - imageWidth));
      this.moveY = -Math.abs(-moveYRatio * (newImageHeight - imageHeight));
    }
  }

  public moveLeft(event: any, index: number = 0) {
    const clientX = this.getClientPosition(event, index).clientX;
    return clientX - this.elementPosition.left;
  }

  public moveTop(event: any, index: number = 0) {
    const clientY = this.getClientPosition(event, index).clientY;
    return clientY - this.elementPosition.top;
  }

  /*
   * Detection
   */

  public centeringImage() {
    const img = this.element.getElementsByTagName(this.elementTarget)[0];
    const initialMoveX = this.moveX;
    const initialMoveY = this.moveY;

    if (img) {
      this.limitPanY();
      this.limitPanX();
    }

    return initialMoveX !== this.moveX || initialMoveY !== this.moveY;
  }

  public limitPanY() {
    let zeroOffset = 0.1;
    if (this.scale != 1) {
      zeroOffset = 1;
    }

    const imgHeight = this.getImageHeight();
    const scaledImgHeight = imgHeight * this.scale;
    const parentHeight = this.parentElement.offsetHeight * zeroOffset;
    const elementHeight = this.element.offsetHeight * zeroOffset;

    if (scaledImgHeight < parentHeight) {
      this.moveY = (parentHeight - elementHeight * this.scale) / 2;
    } else {
      const imgOffsetTop = ((imgHeight - elementHeight) * this.scale) / 2;

      if (this.moveY > imgOffsetTop) {
        this.moveY = imgOffsetTop;
      } else if (scaledImgHeight + Math.abs(imgOffsetTop) - parentHeight + this.moveY < 0) {
        this.moveY = -(scaledImgHeight + Math.abs(imgOffsetTop) - parentHeight);
      }
    }
  }

  public limitPanX() {
    let zeroOffset = 0.1;
    if (this.scale != 1) {
      zeroOffset = 1;
    }

    const imgWidth = this.getImageWidth();
    const scaledImgWidth = imgWidth * this.scale;
    const parentWidth = this.parentElement.offsetWidth * zeroOffset;
    const elementWidth = this.element.offsetWidth * zeroOffset;

    if (scaledImgWidth < parentWidth) {
      this.moveX = (parentWidth - elementWidth * this.scale) / 2;
    } else {
      const imgOffsetLeft = ((imgWidth - elementWidth) * this.scale) / 2;

      if (this.moveX > imgOffsetLeft) {
        this.moveX = imgOffsetLeft;
      } else if (scaledImgWidth + Math.abs(imgOffsetLeft) - parentWidth + this.moveX < 0) {
        this.moveX = -(imgWidth * this.scale + Math.abs(imgOffsetLeft) - parentWidth);
      }
    }
  }

  public setBasicStyles() {
    this.element.style.display = 'flex';
    this.element.style.alignItems = 'center';
    this.element.style.justifyContent = 'center';
    this.element.style.transformOrigin = '0 0';
    this.setImageSize();
    this.setDraggableImage();
  }

  public removeBasicStyles() {
    this.element.style.display = '';
    this.element.style.alignItems = '';
    this.element.style.justifyContent = '';
    this.element.style.transformOrigin = '';
    this.removeImageSize();
    this.removeDraggableImage();
  }

  public setDraggableImage() {
    const imgElement = this.getImageElement();

    if (imgElement) {
      imgElement.draggable = this.properties.draggableImage;
    }
  }

  public removeDraggableImage() {
    const imgElement = this.getImageElement();

    if (imgElement) {
      imgElement.draggable = true;
    }
  }

  public setImageSize() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (imgElement.length) {
      imgElement[0].style.maxWidth = '100%';
      imgElement[0].style.maxHeight = '100%';

      this.setAutoHeight();
    }
  }

  public setAutoHeight() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (!this.properties.autoHeight || !imgElement.length) {
      return;
    }

    const imgNaturalWidth = imgElement[0].getAttribute('width');
    const imgNaturalHeight = imgElement[0].getAttribute('height');
    const sizeRatio = imgNaturalWidth / imgNaturalHeight;
    const parentWidth = this.parentElement.offsetWidth;

    imgElement[0].style.maxHeight = parentWidth / sizeRatio + 'px';
  }

  public removeImageSize() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (imgElement.length) {
      imgElement[0].style.maxWidth = '';
      imgElement[0].style.maxHeight = '';
    }
  }

  public getElementPosition() {
    this.elementPosition = this.element.parentElement.getBoundingClientRect();
  }

  public getTouchstartPosition(event: any) {
    const {clientX, clientY} = this.getClientPosition(event);

    this.startX = clientX - this.elementPosition.left;
    this.startY = clientY - this.elementPosition.top;
  }

  public getClientPosition(event: any, index: number = 0) {
    let clientX;
    let clientY;

    if (event.type === 'touchstart' || event.type === 'touchmove') {
      clientX = event.touches[index].clientX;
      clientY = event.touches[index].clientY;
    }
    if (event.type === 'mousedown' || event.type === 'mousemove') {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    return {
      clientX,
      clientY
    };
  }

  public resetScale() {
    this.scale = 1;
    this.moveX = 0;
    this.moveY = 0;
    this.updateInitialValues();
    this.setZoom({
      scale: 1,
      center: [this.moveX, this.moveY]
    });
  }

  public updateInitialValues() {
    this.initialScale = this.scale;
    this.initialMoveX = this.moveX;
    this.initialMoveY = this.moveY;
  }

  public getDistance(touches: any) {
    return Math.sqrt(
      Math.pow(touches[0].pageX - touches[1].pageX, 2) + Math.pow(touches[0].pageY - touches[1].pageY, 2)
    );
  }

  public getImageHeight() {
    const img = this.element.getElementsByTagName(this.elementTarget)[0];
    return img.offsetHeight;
  }

  public getImageWidth() {
    const img = this.element.getElementsByTagName(this.elementTarget)[0];
    return img.offsetWidth;
  }

  public transformElement(duration: any) {
    this.element.style.transition = 'all ' + duration + 'ms';
    this.element.style.transform =
      'matrix(' +
      Number(this.scale) +
      ', 0, 0, ' +
      Number(this.scale) +
      ', ' +
      Number(this.moveX) +
      ', ' +
      Number(this.moveY) +
      ')';
  }

  public isTouchScreen() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    if ('ontouchstart' in window) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return this.getMatchMedia(query);
  }

  public getMatchMedia(query: any) {
    return window.matchMedia(query).matches;
  }

  public isDragging() {
    if (this.properties.disablePan) {
      return false;
    }

    if (this.scale <= this.minScale + 0.1) {
      return false;
    }

    return true;
  }

  public replaceImagePath() {
    if (!this.fullImage) {
      return;
    }

    const minScale = this.fullImage.minScale;

    if (minScale) {
      if (this.scale < minScale) {
        return;
      }
    }

    if (!minScale && this.properties.limitZoom === 'original image size') {
      if (this.scale < this.maxScale) {
        return;
      }
    }

    let img: any;
    let imgTemp;

    if (this.elementTarget === 'IMG') {
      img = this.element.getElementsByTagName('img')[0];
      imgTemp = new Image();

      if (img.src !== this.fullImage.path) {
        this.emitEvent({
          name: 'startLoadingFullImage',
          detail: {
            url: img.src,
            fullImagePath: this.fullImage.path
          }
        });

        imgTemp.src = this.fullImage.path;
        imgTemp.onload = () => {
          if (!this.fullImage) {
            return;
          }

          this.emitEvent({
            name: 'loadedFullImage',
            detail: {
              url: img.src,
              fullImagePath: this.fullImage.path
            }
          });

          img.src = this.fullImage.path;
          this.detectLimitZoom();
        };
      }
    }
  }

  public detectLimitZoom(): void {
    this.maxScale = this.properties.maxScaleConfig || this.defaultMaxScale;

    if (this.properties.limitZoom === 'original image size' && this.elementTarget === 'IMG') {
      // We are waiting for the element with the image to be available
      this.pollLimitZoomForOriginalImage();
    }
  }

  public pollLimitZoomForOriginalImage() {
    const poll = setInterval(() => {
      const maxScaleForOriginalImage = this.getMaxScaleForOriginalImage();
      if (typeof maxScaleForOriginalImage === 'number') {
        this.maxScale = maxScaleForOriginalImage;
        clearInterval(poll);
      }
    }, 10);
  }

  public getMaxScaleForOriginalImage() {
    let maxScale!: number;
    const img = this.element.getElementsByTagName('img')[0];

    if (img.naturalWidth && img.offsetWidth) {
      maxScale = img.naturalWidth / img.offsetWidth;
    }

    return maxScale;
  }

  public getImageElement() {
    const imgElement = this.element.getElementsByTagName(this.elementTarget);

    if (imgElement.length) {
      return imgElement[0];
    }
  }

  /* Public properties and methods */

  public setMoveX(value: number, transitionDuration: number): void {
    this.moveX = value;
    this.transformElement(transitionDuration || this.properties.transitionDuration);
  }

  public setMoveY(value: number, transitionDuration: number): void {
    this.moveY = value;
    this.transformElement(transitionDuration || this.properties.transitionDuration);
  }

  public toggleZoom(event: any = false) {
    if (this.initialScale === 1) {
      if (event && event.changedTouches) {
        if (this.properties.doubleTapScale === undefined) {
          return;
        }

        const changedTouches = event.changedTouches;
        this.scale = this.initialScale * this.properties.doubleTapScale;
        this.moveX =
          this.initialMoveX -
          (changedTouches[0].clientX - this.elementPosition.left) * (this.properties.doubleTapScale - 1);
        this.moveY =
          this.initialMoveY -
          (changedTouches[0].clientY - this.elementPosition.top) * (this.properties.doubleTapScale - 1);
      } else {
        const zoomControlScale = this.properties.zoomControlScale || 0;
        this.scale = this.initialScale * (zoomControlScale + 1);
        this.moveX = this.initialMoveX - (this.element.offsetWidth * (this.scale - 1)) / 2;
        this.moveY = this.initialMoveY - (this.element.offsetHeight * (this.scale - 1)) / 2;
      }

      if (this.properties.fullImage) {
        this.replaceImagePath();
      }

      this.centeringImage();
      this.updateInitialValues();
      this.emitEvent({
        name: 'zoom-in',
        detail: {
          scale: this.scale
        }
      });
      this.transformElement(this.properties.transitionDuration);
    } else {
      this.emitEvent({
        name: 'zoom-out',
        detail: {
          scale: this.scale
        }
      });
      this.resetScale();
    }
  }

  public setZoom(properties: {scale: number; center?: number[]}) {
    this.scale = properties.scale;

    let xCenter;
    let yCenter;
    const visibleAreaWidth = this.element.offsetWidth;
    const visibleAreaHeight = this.element.offsetHeight;
    const scalingPercent = (visibleAreaWidth * this.scale) / (visibleAreaWidth * this.initialScale);

    if (properties.center) {
      xCenter = properties.center[0];
      yCenter = properties.center[1];
    } else {
      xCenter = visibleAreaWidth / 2 - this.initialMoveX;
      yCenter = visibleAreaHeight / 2 - this.initialMoveY;
    }

    this.moveX = this.initialMoveX - (scalingPercent * xCenter - xCenter);
    this.moveY = this.initialMoveY - (scalingPercent * yCenter - yCenter);

    if (this.properties.fullImage) {
      this.replaceImagePath();
    }

    if (this.scale < this.properties.minScale + 0.2) {
      this.centeringImage();
    }

    this.updateInitialValues();
    this.transformElement(this.properties.transitionDuration);
  }

  public alignImage() {
    const isMoveChanged = this.centeringImage();

    if (isMoveChanged) {
      this.updateInitialValues();
      this.transformElement(this.properties.transitionDuration);
    }
  }

  public setTransform(properties: {x?: number; y?: number; scale?: number; transitionDuration?: number}) {
    const transitionDuration = properties.transitionDuration || this.properties.transitionDuration;

    if (properties.x) {
      this.moveX = properties.x;
    }

    if (properties.y) {
      this.moveY = properties.y;
    }

    if (properties.scale) {
      this.scale = properties.scale;
    }

    if (this.properties.fullImage) {
      this.replaceImagePath();
    }

    this.updateInitialValues();
    this.transformElement(transitionDuration);
  }

  public destroy() {
    this.removeBasicStyles();
    this.touches.destroy();
  }

  public getPropertiesValue(propertyName: PropertyName) {
    if (this.properties && this.properties[propertyName]) {
      return this.properties[propertyName];
    }
    return defaultProperties[propertyName];
  }
}

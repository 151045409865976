import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsScriptService {

  /**
   * Initialize the Google Maps API
   */
  init() {
    this.loadScript()
  }

  private loadScript() {
    const node = document.createElement('script');
    node.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GMAPS_KEY}`;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}

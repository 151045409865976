<div class="header">
  <ng-container *ngIf="(hasMarketPlaceToken$ | async) || isDeveloperMode; else allStepsTemplate">
    <div class="header__steps">{{'auth.confirm.step1of3' | translate}}</div>
  </ng-container>
  <ng-template #allStepsTemplate>
    <div class="header__steps">{{'auth.confirm.step1of4' | translate}}</div>
  </ng-template>
  <div class="header__links">
    <span class="header__link header__link--sign-out" (click)="signOut()"
      ><mat-icon class="icon">vpn_key</mat-icon> {{'profile.headers.signOut' | translate}}</span
    >

    <button mat-icon-button [matMenuTriggerFor]="menu" class="header__link header__link--more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="signOut()" class="header__menu-item">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'profile.headers.signOut' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="spacer"></div>
<unleash-alert
  class="unleash-alert"
  *ngIf="isDisplayResendAlert$ | async"
  type="success"
  iconName="check"
  label="{{'auth.confirm.confirmationCodeResent' | translate}}"
></unleash-alert>
<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>
<div class="title">
  <div class="title__text">{{'auth.confirm.confirmRegistration' | translate}}</div>
  <div class="title__sub-text">
    {{'auth.confirm.confirmationCodeResentHelp.0' | translate}}
    <span class="confirm-email">{{confirmationEmail$ | async}}</span>{{'auth.confirm.confirmationCodeResentHelp.1' |
    translate}}
  </div>
</div>
<unleash-confirm-registration
  class="form form--fixed"
  [isInProgress]="isInProgress$ | async"
  [isResendInProgress]="isResendInProgress$ | async"
  [error]="errorMessage$ | async"
  [confirmRegistration]="confirmRegistration$ | async"
  (reSendCode)="reSendCode($event)"
  (confirm)="confirm($event)"
></unleash-confirm-registration>
<div class="spacer"></div>

<div class="rule" [class.rule--selected]="selected" (click)="emitRuleId(rule.id)">
  <div class="rule__name">{{ rule.name }}</div>
  <div class="rule__options">
    <button
      class="rule__options__menu"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let menuOption of menuOptions">
        <button class="menu-item-button" mat-menu-item (click)="emitAction(menuOption.type)">
          <mat-icon [class]="'menu-item-button__icon--' + menuOption.color">{{ menuOption.icon }}</mat-icon>
          <span [class]="'menu-item-button__name--' + menuOption.color">{{ menuOption.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>

import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {AddonService} from '@app/core/services/api/addon.service';
import {DeviceService} from '@app/core/services/api/device.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {LibraryApiService} from '@app/library/services/library-api.service';
import {Addon} from '@app/store/addon/models/addon';
import {BehaviorSubject, take} from 'rxjs';

@Component({
  selector: 'model-selector',
  templateUrl: './model-selector.component.html',
  styleUrls: ['./model-selector.component.scss']
})
export class ModelSelectorComponent implements OnInit {
  @Input('device') private set setDevice(device: UserDeviceJoined) {
    if (device) {
      this._device.next(device);
    }
  }

  private _device = new BehaviorSubject(null);
  public device$ = this._device.asObservable();
  @Input()
  public models: Addon[];
  @Input()
  public streamKey: string;
  @Output()
  public setCurrentModel: EventEmitter<{device: UserDeviceJoined; modelId: Addon['id']}> = new EventEmitter();
  @Output()
  public openZone: EventEmitter<{device: UserDeviceJoined; model: Addon}> = new EventEmitter();
  @Output()
  public stopModel: EventEmitter<{device: UserDeviceJoined; modelId: string}> = new EventEmitter();
  @Output()
  public errorStartModel: EventEmitter<{deviceId: string; modelId: string}> = new EventEmitter();

  public rawStreamId = 'RAW_STREAM';
  public form: UntypedFormGroup = this.fb.group({currentModel: this.rawStreamId});
  public aclPermissions = AclPermissions;
  public currentModelId: Addon['id'] = null;

  constructor(
    private unleashAnalytics: UnleashAnalyticsService,
    private deviceService: DeviceService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private addonService: AddonService,
    private libraryApiService: LibraryApiService,
    private addonStoreFacadeService: AddonStoreFacadeService
  ) {}

  public ngOnInit(): void {
    this.preselectRunningModel();
  }

  public runNewModel(device: UserDeviceJoined): void {
    const startingModelId: Addon['id'] = this.form.controls.currentModel.value;
    if (this.addonService.isModelRunning(device, startingModelId)) {
      this.broadcastNewModel(device, startingModelId);
      return;
    }
    this.currentModelId = startingModelId;
    // not raw and not streaming - call API to start this model
    this.addonService.scheduleModel(this._device.value, startingModelId);
    this.libraryApiService.startLiveAi(device, startingModelId, this.streamKey).subscribe(
      () => {
        const addon = this.models.find(model => model.id === startingModelId);
        const addonName = addon ? addon.name : 'A.I.';

        this.snackBar.open(
          'Applying ' +
            addonName +
            ' to live stream - Please wait, it can take few minutes until your AI stream starts.',
          'OK',
          {
            duration: 6000,
            verticalPosition: 'bottom'
          } as MatSnackBarConfig
        );
        this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER_AI, {model: startingModelId});
      },
      (err: HttpErrorResponse) => {
        console.error('Error while requesting AI model', err);
        this.errorStartModel.emit({deviceId: device.id, modelId: startingModelId});
        if (!!err && !!err.error && !!err.error.message) {
          this.snackBar.open(err.error.message, null, {
            duration: 2000
          } as MatSnackBarConfig);
        }
      }
    );
  }

  public stopAI(device: UserDeviceJoined, selectedModelId: string): void {
    this.deviceService.stopAi(device.id, selectedModelId).subscribe(() => {
      this.snackBar.open('AI Stopped. Switching to raw stream', null, {
        duration: 3000,
        verticalPosition: 'bottom'
      } as MatSnackBarConfig);
      const runningModels = device.runningModels.filter((runningId: string) => runningId !== selectedModelId);
      this.form.controls.currentModel.setValue(this.rawStreamId);
      this.stopModel.emit({device: {...device, runningModels}, modelId: selectedModelId});
      this.broadcastNewModel(device, this.rawStreamId);
      this.currentModelId = null;
    });
  }

  public broadcastNewModel(device: UserDeviceJoined, modelId: Addon['id']): void {
    this.setCurrentModel.emit({device, modelId});
  }

  public areThereWaitingModels(device: UserDeviceJoined): boolean {
    return !!device.waitingModels && device.waitingModels.length > 0;
  }

  public watchModel(device: UserDeviceJoined, modelId: Addon['id']): void {
    this.setCurrentModel.emit({device, modelId});
  }

  public openZonesDialog(device: UserDeviceJoined, addonId: string): void {
    this.addonStoreFacadeService
      .getAddon(addonId)
      .pipe(take(1))
      .subscribe(addon => {
        if ('supportedZonesTypes' in addon) {
          this.openZone.emit({device, model: addon});
        } else {
          this.snackBar.open("This model doesn't support Zones", 'INFO', {
            duration: 1000,
            verticalPosition: 'bottom'
          } as MatSnackBarConfig);
        }
      });
  }

  public addonIdComparator(option: Addon['id'], value: Addon['id']): boolean {
    return option === value;
  }

  public trackById(index: number, model: Addon) {
    if (!this._device.value) {
      return model.id;
    }

    const device = this._device.value;

    let customId = `${device.id}`;
    const runningModels = !!device.runningModels
      ? device.runningModels.map(modelId => modelId).reduce((prev, curr, index) => prev + '-' + curr, '')
      : '';
    const waitingModels = !!device.waitingModels
      ? device.waitingModels.map(modelId => modelId).reduce((prev, curr, index) => prev + '-' + curr, '')
      : '';

    if (device.selectedModel) {
      customId = `${device.id}-${device.selectedModel}-${runningModels}-${waitingModels}`;
    }

    return customId;
  }

  public startAgain(): void {
    this.stopAI(this.updateWaitingModels(), this.currentModelId);
  }

  private updateWaitingModels(): UserDeviceJoined {
    const waitingModelIndex = this._device.value.waitingModels.findIndex(modelId => modelId === this.currentModelId);
    if (waitingModelIndex !== -1) {
      const waitingModels = [...this._device.value.waitingModels];
      waitingModels.splice(waitingModelIndex, 1);
      return {...this._device.value, waitingModels};
    }
  }

  private preselectRunningModel(): void {
    this.form.controls.currentModel.setValue(this._device.value.selectedModel || this.rawStreamId);
  }
}

import {Injectable} from '@angular/core';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {PACKAGE_SELECTOR_STEP_INDEX, PlansByStep} from '@app/plans/models/package-selector-steps.model';
import {PackageSelectorPageService} from '@app/plans/services/package-selector-page.service';
import {PackageSelectorStoreFacadeService} from '@app/plans/services/package-selector-store-facade.service';
import {PlansService} from '@app/plans/services/plans.service';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, map, switchMap, take, tap, zip} from 'rxjs';
import * as actions from '../store/package-selector.actions';

@Injectable({
  providedIn: 'root'
})
export class PackageSelectorEffects {
  public calcTotalPlanByStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actions.setPlanOnStep,
          actions.setPaymentPeriod,
          actions.removePlanOnStep,
          actions.upgradePlanOnStep,
          actions.downgradePlanOnStep,
          actions.setCurrentStep
        ),
        switchMap(() =>
          zip([
            this.packageSelectorStoreFacadeService.plansByStep$,
            this.packageSelectorStoreFacadeService.currentStep$,
            this.packageSelectorStoreFacadeService.paymentPeriod$,
            this.packageSelectorStoreFacadeService.firstProfessionalStepIndex$
          ]).pipe(take(1))
        ),
        filter(
          ([plansByStep, currentStep, paymentPeriod, firstProfessionalStepIndex]: [
            PlansByStep,
            number,
            PaymentPeriod,
            number
          ]) => firstProfessionalStepIndex < PACKAGE_SELECTOR_STEP_INDEX.BILLING
        ),
        map(
          ([plansByStep, currentStep, paymentPeriod, firstProfessionalStepIndex]: [
            PlansByStep,
            number,
            PaymentPeriod,
            number
          ]) =>
            this.packageSelectorPageService.calcTotalPlanByStep(
              plansByStep,
              currentStep,
              paymentPeriod,
              firstProfessionalStepIndex
            )
        ),
        tap(totalPayment => {
          this.packageSelectorStoreFacadeService.setTotalPayment(totalPayment);
        })
      ),
    {
      dispatch: false
    }
  );

  public editEssentialSelection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setPlanOnStep),
        switchMap(() => this.packageSelectorStoreFacadeService.hasProfessionalPlanSelected$),
        tap(hasProfessionalPlanSelected => {
          if (hasProfessionalPlanSelected) {
            this.packageSelectorStoreFacadeService.disableEssentialSelection();
            return;
          }
          this.packageSelectorStoreFacadeService.enableEssentialSelection();
        })
      ),
    {dispatch: false}
  );

  public calcCheckoutPlans$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actions.setPlanOnStep,
          actions.setPaymentPeriod,
          actions.removePlanOnStep,
          actions.upgradePlanOnStep,
          actions.downgradePlanOnStep,
          actions.setCurrentStep
        ),
        switchMap(() =>
          zip(
            this.packageSelectorStoreFacadeService.plansByStep$,
            this.plansService.userPlan$,
            this.packageSelectorStoreFacadeService.plans$,
            this.userFacadeStoreFacadeService.currentUser$,
            this.packageSelectorStoreFacadeService.paymentPeriod$
          )
        ),
        filter(([plansByStep, userPlan]) => Object.values(plansByStep).filter(plan => !!plan).length > 0 && !!userPlan),
        tap(([plansByStep, userPlan, plans, user, paymentPeriod]) => {
          const packagesObject = this.packageSelectorPageService.updateCheckoutPlans(plansByStep as PlansByStep);
          this.packageSelectorStoreFacadeService.setIsSamePackage(
            packagesObject.id === userPlan.id && user.subscriptionPeriod === paymentPeriod
          );
        }),
        tap(([plansByStep, userPlan, plans]) => {
          const plan = this.packageSelectorPageService.mapSelectedPlansIntoCombinedPlan(
            Object.values(plansByStep).filter(plan => !!plan),
            Object.values(plans)
          );

          if (plan) {
            this.packageSelectorStoreFacadeService.setCombinedPlan(plan.id);
          }
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private packageSelectorStoreFacadeService: PackageSelectorStoreFacadeService,
    private packageSelectorPageService: PackageSelectorPageService,
    private plansService: PlansService,
    private userFacadeStoreFacadeService: UserStoreFacadeService
  ) {}
}

import {createAction, props} from '@ngrx/store';
import {UserProfile} from './your-profile.model';
import {AuthErrorModel} from '@app/auth/models/amplify-error';

export const actionYourProfileSubmit = createAction('[CreateActionForm] Submit', props<{form: Partial<UserProfile>}>());

export const actionYourProfileError = createAction('[CreateActionForm] Submit error', props<{error: AuthErrorModel}>());

export const actionYourProfileSuccess = createAction('[CreateActionForm] Submit success');

export const actionYourDeveloperProfileSuccess = createAction('[CreateActionForm] Submit developer profile success');

export const actionWelcomeAwsTokenSuccess = createAction('[CreateActionForm] Submit success aws token success');

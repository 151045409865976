import {Component, Input, OnInit} from '@angular/core';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {Map} from 'leaflet';

@Component({
  selector: 'app-zoom-buttons',
  templateUrl: './zoom-buttons.component.html',
  styleUrls: ['./zoom-buttons.component.scss']
})
export class ZoomButtonsComponent implements OnInit {
  events = EVENTS;
  @Input()
  map: Map;
  constructor() {}

  ngOnInit() {}

  zoomIn() {
    this.map.setZoom(this.map.getZoom() + 1);
  }

  zoomOut() {
    this.map.setZoom(this.map.getZoom() - 1);
  }
}

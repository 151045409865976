import {PlanModel} from '@app/core/models/api/plan.model';

/* eslint-disable no-magic-numbers */
export enum PACKAGE_SELECTOR_STEP_INDEX {
  LIVE_STREAMING,
  AI_ANALYTICS,
  MODELLING_AND_GIS,
  BILLING,
  CHECKOUT,
  DONE
}

export enum PLAN_STEP_INDEX {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH
}

export enum PACKAGE_LEVEL {
  ESSENTIALS = 1,
  PRO = 2
}

export enum PLAN_MAPPING {
  BROADCAST = 1,
  MODELLING = 2,
  OPERATIONAL = 3,
  INSIGHTS = 4,
  SUPERVISION = 5,
  CONTROL = 6
}

export interface PlansByStepId {
  '0': PlanModel['id'];
  '1': PlanModel['id'];
  '2': PlanModel['id'];
}

export interface PlansByStep {
  '0': PlanModel;
  '1': PlanModel;
  '2': PlanModel;
}

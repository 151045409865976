import {Injectable} from '@angular/core';
import {ParsedReport, ReportModel} from '../models/analysis.model';
import {Paginator, SORT_ORDER} from '../models/paginator.model';

@Injectable({
  providedIn: 'root'
})
export class ReportOperationsService {
  constructor() {}

  public sortReports(
    reports: ReportModel[],
    sortOptions: {active: string; direction: SORT_ORDER}
  ): ReportModel[] {
    switch (sortOptions.active) {
      case 'createdAt':
        return this.numbersSort(
          [...reports],
          sortOptions.direction,
          sortOptions.active
        );
      case 'name':
      case 'type':
        return this.stringSort(
          [...reports],
          sortOptions.direction,
          sortOptions.active
        );
      default:
        break;
    }
  }

  public generateDisplayedReports(
    reports: ParsedReport[],
    pageSize: number,
    pageIndex: number
  ): ParsedReport[] {
    let displayedReports = [];
    if (!reports || (reports && reports.length === 0)) {
      return displayedReports;
    }

    const copyReports: ParsedReport[] = [...reports];

    if (pageSize === 0) {
      return copyReports;
    }

    const emptyArrayChunks = [...Array(Math.ceil(reports.length / pageSize))];
    const reportsChunks: ParsedReport[][] = emptyArrayChunks.map(_ =>
      copyReports.splice(0, pageSize)
    );
    displayedReports = reportsChunks[pageIndex];
    return displayedReports;
  }

  public generatePaginator(reportsLength: number, pageSize: number): Paginator {
    if (reportsLength === 0) {
      return null;
    }

    return {
      sizeOptions:
        reportsLength > 100
          ? [5, 10, 25, 50, 100, reportsLength]
          : [5, 10, 25, 50, 100],
      length: reportsLength,
      pageSize
    };
  }

  private stringSort(
    reports: ReportModel[],
    direction: SORT_ORDER,
    sortByColumnName: string
  ): ReportModel[] {
    const reportsSorted: ReportModel[] = reports.sort(
      (reportA: ReportModel, reportB: ReportModel) =>
        reportA[sortByColumnName].localeCompare(reportB[sortByColumnName])
    );
    return direction === SORT_ORDER.ASCENDENT
      ? reportsSorted
      : reportsSorted.reverse();
  }

  private numbersSort(
    reports: ReportModel[],
    sortDirection: SORT_ORDER,
    sortByColumnName: string
  ): ReportModel[] {
    const reportsSorted: ReportModel[] = reports.sort(
      (reportA: ReportModel, reportB: ReportModel): number =>
        reportB[sortByColumnName] - reportA[sortByColumnName]
    );
    return sortDirection === SORT_ORDER.ASCENDENT
      ? reportsSorted
      : reportsSorted.reverse();
  }
}

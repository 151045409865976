<div class="settings">
  <section class="settings__header">
    <div class="settings__waypoint">
      {{ 'atlas.mission.waypoint' | translate }}
      {{ selectedWaypointIndex + 1 }}
    </div>
    <mat-icon class="settings__close-icon" (click)="emitClose()">close</mat-icon>
  </section>
  <mat-divider></mat-divider>
  <section class="settings__values">
    <form [formGroup]="editForm">
      <mat-form-field appearance="fill" class="settings__text-input">
        <mat-label>Lat</mat-label>
        <input
          numbersOnly
          [acceptDot]="true"
          [waypointIndex]="selectedWaypointIndex"
          ulWaypointSettings="lat"
          matInput
          formControlName="lat"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" class="settings__text-input">
        <mat-label>Lng</mat-label>
        <input
          numbersOnly
          [acceptDot]="true"
          [waypointIndex]="selectedWaypointIndex"
          ulWaypointSettings="lng"
          matInput
          formControlName="lng"
        />
      </mat-form-field>
      <p class="settings__name">{{ 'atlas.mission.height' | translate }}</p>
      <span class="settings__value"
        >{{ altitudeSlider.value | number: '0.0-0' }} {{ 'atlas.mission.altitudeMeasurement' | translate }}</span
      >
      <div class="settings__slider-container">
        <div class="settings__initial-value">0m</div>
        <mat-slider
          ulWaypointSettings="altitude"
          [waypointIndex]="selectedWaypointIndex"
          #altitudeSlider
          class="settings__slider"
          [min]="0"
          [max]="200"
          formControlName="altitude"
          color="primary"
        >
          <input />
        </mat-slider>
        <div class="settings__initial-value">200m</div>
      </div>

      <p class="settings__name">{{ 'atlas.mission.speed' | translate }}</p>
      <span class="settings__value">{{ speedSlider.value }} {{ 'atlas.mission.speedMeasurement' | translate }}</span>
      <div class="settings__slider-container">
        <div class="settings__initial-value">0m</div>
        <mat-slider
          ulWaypointSettings="speed"
          [waypointIndex]="selectedWaypointIndex"
          #speedSlider
          class="settings__slider"
          [min]="0"
          [max]="20"
          formControlName="speed"
          color="primary"
        >
          <input />
        </mat-slider>
        <div class="settings__initial-value">20m/s</div>
      </div>

      <p class="settings__name">{{ 'atlas.mission.heading' | translate }}</p>
      <span class="settings__value">{{ headingSlider.value | number: '0.0-0' }} &deg;</span>
      <div class="settings__slider-container">
        <div class="settings__initial-value">0 &deg;</div>
        <mat-slider
          ulWaypointSettings="heading"
          [waypointIndex]="selectedWaypointIndex"
          #headingSlider
          class="settings__slider"
          [min]="0"
          [max]="360"
          formControlName="heading"
          color="primary"
        >
          <input />
        </mat-slider>
        <div class="settings__initial-value">360 &deg;</div>
      </div>

      <p class="settings__name">{{ 'atlas.mission.pitch' | translate }}</p>
      <span class="settings__value">{{ pitchSlider.value }} &deg;</span>
      <div class="settings__slider-container">
        <div class="settings__initial-value">-90 &deg;</div>
        <mat-slider
          #pitchSlider
          ulWaypointSettings="pitch"
          [waypointIndex]="selectedWaypointIndex"
          class="settings__slider"
          [min]="-90"
          [max]="30"
          formControlName="pitch"
          color="primary"
        >
          <input />
        </mat-slider>
        <div class="settings__initial-value">30 &deg;</div>
      </div>

      <p class="settings__name">{{ 'atlas.mission.gimbalHeading' | translate }}</p>
      <span class="settings__value">{{ gimbalSlider.value }} &deg;</span>
      <div class="settings__slider-container">
        <div class="settings__initial-value">-180 &deg;</div>
        <mat-slider
          #gimbalSlider
          ulWaypointSettings="gimbal"
          [waypointIndex]="selectedWaypointIndex"
          class="settings__slider"
          [min]="-180"
          [max]="180"
          formControlName="gimbal"
          color="primary"
        >
          <input />
        </mat-slider>
        <div class="settings__initial-value">180 &deg;</div>
      </div>
    </form>
    <button [ulDeleteWaypoint]="selectedWaypointIndex" class="settings__remove" mat-button>
      <mat-icon class="settings__remove-icon">delete</mat-icon> {{ 'atlas.mission.removeWaypoint' | translate }}
    </button>
  </section>
</div>

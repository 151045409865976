import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {selectAuthPlanActive, selectAuthUserInitializationFinished} from '@app/auth/state/auth.selectors';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import {combineLatest, from, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class IsUserAuthGuard {
  constructor(private store: Store, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveTemplate(route, state);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveTemplate(route, state);
  }

  private canActiveTemplate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return from(Auth.currentAuthenticatedUser()).pipe(
      take(1),
      switchMap(() =>
        combineLatest([
          this.store.pipe(
            select(selectAuthUserInitializationFinished),
            filter(finished => !!finished)
          ),
          this.store.pipe(select(selectAuthPlanActive))
        ])
      ),
      switchMap(([finished, planActive]) => {
        if (finished && !planActive) {
          return this.router.navigate(['auth/reason']);
        }
        return of(true);
      }),
      catchError(() => {
        console.error('User not authenticated');
        return this.router.navigate(['auth/sign-in']);
      })
    );
  }
}

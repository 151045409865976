import {createAction, props} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {MFA} from '@app/auth/models/auth.models';
import {FederatedProviders, SignInForm} from './sign-in.model';

export const actionSignInFormUpdate = createAction(
  '[SignInForm] Update',
  props<{form: SignInForm; isDeveloperMode?: boolean}>()
);

export const actionSignInFormSubmit = createAction(
  '[SignInForm] Submit',
  props<{form: SignInForm; isDeveloperMode?: boolean}>()
);

export const actionVerificationFormSubmit = createAction(
  '[SignInForm] Submit verification code',
  props<{code: string; isDeveloperMode?: boolean}>()
);

export const actionFederatedSignIn = createAction(
  '[SignInForm] actionFederatedSignIn',
  props<{provider: FederatedProviders}>()
);

export const actionSignInFormError = createAction(
  '[SignInForm] Submit error',
  props<{form?: Partial<SignInForm>; error?: AuthErrorModel; isDeveloperMode?: boolean}>()
);

export const actionVerifyMfa = createAction('[SignInForm] verify MFA', props<{code: string}>());

export const actionSetupMFA = createAction('[SignInForm] setup MFA', props<{mfaType: MFA}>());

export const actionConfirmMfaToken = createAction('[SignInForm] confirm MFA token', props<{code: string}>());

export const actionSetupPhoneMFA = createAction('[SignInForm] verify Setup phone mfa', props<{phoneNumber: string}>());

export const actionConfirmPhoneMFA = createAction('[SignInForm] Setup confirm Phone', props<{code: string}>());

export const actionSignInFormSuccess = createAction('[SignInForm] Submit success');

export const actionSignInRequired = createAction('[SignInForm] Required');

export const actionSignInShowSignOutAlert = createAction('[SignInForm] Show sign out alert');

export const actionSignInHideSignOutAlert = createAction('[SignInForm] Hide sign out alert');
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'alertDisplayName'
})
export class AlertDisplayNamePipe implements PipeTransform {
  alertsName = {info: 'Info', warning: 'Warning', alert: 'Alert'};

  transform(alertId: string): string {
    return this.alertsName[alertId];
  }
}

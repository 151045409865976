import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {REGISTRATION_STAGE} from '../models/auth.models';
import {selectAuthUserInitializationFinished} from '../state/auth.selectors';
import {RegistrationStage} from '../state/auth.state';

@Injectable()
export class IsUserAuthOnboardingGuard {
  isLoading$: Observable<boolean>;
  private isLoading = new BehaviorSubject<boolean>(false);
  constructor(private store: Store, private router: Router, private localStoreService: LocalStorageService) {
    this.isLoading$ = this.isLoading.asObservable();
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveTemplate(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActiveTemplate(route, state);
  }

  private canActiveTemplate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.isLoading.next(true);
    return from(Auth.currentAuthenticatedUser()).pipe(
      take(1),
      switchMap(() => this.store.pipe(select(selectAuthUserInitializationFinished))),
      filter(finished => finished !== undefined),
      switchMap(finished => {
        if (finished && this.localStoreService.getItem(REGISTRATION_STAGE) === RegistrationStage.done) {
          this.isLoading.next(false);
          return of(false);
        }
        this.isLoading.next(false);
        return of(true);
      }),
      catchError(() => {
        this.isLoading.next(false);
        console.error('User not authenticated');
        return this.router.navigate(['auth/sign-in']);
      })
    );
  }
}

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {LoadingSpinnerModule} from '@app/shared/loading-spinner/loading-spinner';
import {PlansSharedModule} from '@app/shared/plans/plans.module';
import {SlideshowModule} from '@app/shared/slideshow/slideshow.module';
import {UnleashMaterialSharedModule} from '@app/shared/unleash-material/unleash-material-shared.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {PaymentService} from '../plans/services/payment.service';
import {StripeElementsModule} from '../shared/stripe-elements/stripe-elements.module';
import {UnleashMaterialModule} from '../theme/unleash-material.module';
import {ConfirmRegistrationComponent} from './components/confirm-registration/confirm-registration.component';
import {ConfirmRegistrationEffects} from './components/confirm-registration/confirm-registration.effects';
import {DeveloperPlanFeaturesComponent} from './components/developer-plan-features/developer-plan-features.component';
import {ForgotPasswordStepTwoComponent} from './components/forgot-password-step-two/forgot-password-step-two.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {LogoutComponent} from './components/logout/logout.component';
import {NewPasswordComponent} from './components/new-password/new-password.component';
import {PlanViewerComponent} from './components/plan-viewer/plan-viewer.component';
import {ReasonComponent} from './components/reason/reason.component';
import {ReasonFormEffects} from './components/reason/reason.effects';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {SignInEffects} from './components/sign-in/sign-in.effects';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {SignUpEffects} from './components/sign-up/sign-up.effects';
import {YourProfileComponent} from './components/your-profile/your-profile.component';
import {YourProfileEffects} from './components/your-profile/your-profile.effects';
import {ConfirmRegistrationPage} from './pages/confirm-registration/confirm-registration.page';
import {ForgotPasswordStepTwoPage} from './pages/forgot-password-step-two/forgot-password-step-two.page';
import {ForgotPasswordPage} from './pages/forgot-password/forgot-password.page';
import {NewPasswordPage} from './pages/new-password/new-password.page';
import {PaymentPage} from './pages/payment/payment.page';
import {ReasonPage} from './pages/reason/reason.page';
import {SignInPage} from './pages/sign-in/sign-in.page';
import {SignUpPage} from './pages/sign-up/sign-up.page';
import {WelcomePage} from './pages/welcome/welcome.page';
import {YourProfilePage} from './pages/your-profile/your-profile.page';
import {authFormFeatureKey, authFormFeatureReducers} from './state/auth-forms.state';
import {MfaSmsVerificationComponent} from './components/mfa-sms-verification/mfa-sms-verification.component';
import {SetupMfaComponent} from './components/setup-mfa/setup-mfa.component';
import {TotpVerificationComponent} from './components/totp-verification/totp-verification.component';
import {TotpTokenComponent} from './components/totp-token/totp-token.component';
import {SetupMobileNumberComponent} from './components/setup-mobile-number/setup-mobile-number.component';
import {ConfirmMobileNumberComponent} from './components/confirm-mobile-number/confirm-mobile-number.component';

@NgModule({
  declarations: [
    LogoutComponent,
    SignUpComponent,
    SignInComponent,
    SignUpPage,
    ConfirmRegistrationComponent,
    YourProfileComponent,
    ReasonComponent,
    SignInPage,
    ConfirmRegistrationPage,
    YourProfilePage,
    ReasonPage,
    PlanViewerComponent,
    PaymentPage,
    NewPasswordComponent,
    NewPasswordPage,
    ForgotPasswordComponent,
    ForgotPasswordPage,
    ForgotPasswordStepTwoComponent,
    ForgotPasswordStepTwoPage,
    WelcomePage,
    DeveloperPlanFeaturesComponent,
    MfaSmsVerificationComponent,
    SetupMfaComponent,
    TotpVerificationComponent,
    TotpTokenComponent,
    SetupMobileNumberComponent,
    ConfirmMobileNumberComponent
  ],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    UnleashMaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UnleashMaterialSharedModule,
    SlideshowModule,
    PlansSharedModule,
    RouterModule.forChild([]),
    StoreModule.forFeature(authFormFeatureKey, authFormFeatureReducers),
    EffectsModule.forFeature([
      SignInEffects,
      SignUpEffects,
      YourProfileEffects,
      ConfirmRegistrationEffects,
      ReasonFormEffects
    ]),
    StripeElementsModule,
    UnleashLoaderModule
  ],
  providers: [PaymentService],
  exports: []
})
export class AuthModule {}

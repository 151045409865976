<table mat-table #table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.name' | translate }}</th>
    <td mat-cell *matCellDef="let element" (click)="emitUser(element)">{{ element.name }}</td>
  </ng-container>
  <ng-container matColumnDef="members">
    <th mat-header-cell *matHeaderCellDef>{{ 'profile.teams.members' | translate }}</th>
    <td mat-cell *matCellDef="let element">{{ element.members }}</td>
  </ng-container>
  <!-- <ng-container matColumnDef="lastActive">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.lastActive' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastActive | uDateFormat: 'Do MMM' }} '{{ element.lastActive | uDateFormat: 'yy' }}
    </td>
  </ng-container> -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button data-test-id="menu-actions-button" [matMenuTriggerFor]="menu">
        <mat-icon color="grey-5">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          [attr.data-test-id]="'actions-' + menuItem.name + '-button'"
          *ngFor="let menuItem of menuItems"
          mat-menu-item
          (click)="emitAction(menuItem.type, element.teamId)"
        >
          {{ menuItem.name }}
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

import {Injectable} from '@angular/core';
import {RouterParamsData} from '@app/core/models/api/router-params-data.model';
import {listenPreviousUrl, listenNavigation, routerSavePreviousUrl} from '@app/store/router/router.actions';
import {select, Store} from '@ngrx/store';
import {selectPreviousUrl, selectRouterParamsData, selectUrl} from '@app/store/router/router.selector';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterFacadeStoreService {
  public url$: Observable<string> = this.store.pipe(select(selectUrl));

  constructor(private store: Store) {}

  // eslint-disable-next-line rxjs/finnish
  public selectRouteParams(): Observable<RouterParamsData> {
    return this.store.pipe(select(selectRouterParamsData));
  }

  public savePreviousUrl(url: string): void {
    this.store.dispatch(routerSavePreviousUrl({url}));
  }

  public listenPreviousUrl(): void {
    this.store.dispatch(listenPreviousUrl());
  }

  public listenNavigation(): void {
    this.store.dispatch(listenNavigation());
  }

  // eslint-disable-next-line rxjs/finnish
  public selectPreviousUrl(): Observable<string> {
    return this.store.pipe(select(selectPreviousUrl));
  }
}

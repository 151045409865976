import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'unleash-social-button',
  templateUrl: './social-button.component.html',
  styleUrls: ['./social-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() textContent: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.buttonClick.emit();
  }
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {ProfileActionTypes} from './profile.actions';

@Injectable({providedIn: 'root'})
export class ProfileEffects {
  public searchUser$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(ProfileActionTypes.SearchUser));
    },
    {dispatch: false}
  );

  public searchTeam$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(ProfileActionTypes.SearchTeam));
    },
    {dispatch: false}
  );

  constructor(private actions$: Actions) {}
}

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DeleteAllMediaDialogComponent} from '../delete-all-media-dialog/delete-all-media-dialog.component';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['../myprofile.component.common.scss', './data-privacy.component.scss']
})
export class DataPrivacyComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  deleteAllMedia() {
    this.dialog.open(DeleteAllMediaDialogComponent, {
      width: '80vw',
      maxWidth: '800px'
    });
  }
}

import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'unleash-notification-channels-tab',
  templateUrl: './notification-channels-tab.component.html',
  styleUrls: ['./notification-channels-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationChannelsTabComponent {
  @Input() public form = new UntypedFormGroup({
    email: new UntypedFormControl(true),
    sms: new UntypedFormControl(false)
  });

  @Output() public channelChange: EventEmitter<void> = new EventEmitter();
  @Output() public openHelp: EventEmitter<void> = new EventEmitter();

  public emitChannelChange(): void {
    this.channelChange.emit();
  }

  public emitOpenHelp(): void {
    this.openHelp.emit();
  }
}

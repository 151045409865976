import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  AREAS,
  checkSystemRequirements,
  ClientConfig,
  createCameraVideoTrack,
  createClient,
  createMicrophoneAudioTrack,
  getCameras,
  getSupportedCodec,
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  setArea,
  setLogLevel
} from 'agora-rtc-sdk-ng/esm';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AddonService} from '@app/core/services/api/addon.service';
import {UserService} from '@app/core/services/api/user.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';

import {environment} from 'environments/environment';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {StreamingErrors} from '../models/streaming-errors.models';
import {LiveFacadeService} from './live-facade.service';
import {Addon} from '@app/store/addon/models/addon';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {CompanyModel} from '@app/core/models/api/company-model';
import {LogLevel} from '@app/shared/models/agora-log-level';

@Injectable({
  providedIn: 'root'
})
export class AgoraService {
  public availableCameras$: BehaviorSubject<MediaDeviceInfo[]> = new BehaviorSubject([]);
  public errorCode$: Observable<StreamingErrors>;
  public errorMessage$: Observable<string>;
  public isConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isStartingStreaming$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public streamStopped$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public videoTime$: BehaviorSubject<string>;
  public isStreaming$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public streamingDevice$: BehaviorSubject<UserDeviceJoined> = new BehaviorSubject({} as UserDeviceJoined);
  public defaultDeviceId$: Observable<string> = this.userService.defaultDevice$.pipe(map(device => device?.id));
  public videoStats$: BehaviorSubject<{
    frame: number;
    width: number;
    height: number;
  }> = new BehaviorSubject({
    frame: 0,
    width: 0,
    height: 0
  });
  public streamKey: string;
  public devices: UserDeviceJoined[] = [];
  public activeAddons$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public agoraToken$: BehaviorSubject<string> = new BehaviorSubject(null);
  public isShared$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public companyId$: BehaviorSubject<string> = new BehaviorSubject(null);
  public companySlug$: BehaviorSubject<string> = new BehaviorSubject(null);
  public publicWatchPageURL$: BehaviorSubject<string> = new BehaviorSubject(null);
  public isLoadingCompanyInfo$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public client: IAgoraRTCClient;
  public streamUrl: string;
  public uid: number | string;
  public userDevices$: Observable<UserDeviceJoined[]> = this.userService.userDevices$;
  public currentUserCompany$: Observable<CompanyModel> = this.userService.myCompany$;

  private localVideo: ICameraVideoTrack;
  private localAudio: IMicrophoneAudioTrack;
  private clientConfig: ClientConfig = {
    mode: 'live',
    codec: 'h264'
  };

  private activeCameraOrder: number = 1;
  private videoStatsInterval: number;
  private static AGORA_CLOUD_PROXY_TCP = 5;

  constructor(
    private userService: UserService,
    private unleashAnalytics: UnleashAnalyticsService,
    private snackBar: MatSnackBar,
    private addonService: AddonService,
    private liveFacadeService: LiveFacadeService,
    private browserSettingsService: BrowserSettingsService
  ) {
    this.errorMessage$ = this.liveFacadeService.getErrorMessage();
    this.errorCode$ = this.liveFacadeService.getErrorCode();
    setLogLevel(LogLevel.ERROR);
  }

  public supportIOS(): boolean {
    if (!checkSystemRequirements()) {
      this.liveFacadeService.supportIOSErrorMessage();
      return;
    }
  }

  public async startAgora(deviceId: string, hasToEnableSecureTunnel: boolean): Promise<void> {
    //Agora rtc sdk 4.7.2 is the stable version for all OS included IOS
    setArea({
      areaCode: [AREAS.GLOBAL],
      excludedArea: AREAS.CHINA
    });
    try {
      const supportedCodec = await getSupportedCodec();
      console.debug(`Supported video codec  : ${supportedCodec.video.join(',')}`);
      console.debug(`Supported audio codec: ${supportedCodec.audio.join(',')}`);
      if (!!supportedCodec && !!supportedCodec.video && !supportedCodec.video.length) {
        console.error('No supported codec found.');
      }
      this.client = createClient(this.clientConfig);
      if (hasToEnableSecureTunnel) {
        this.enableSecureTunnel();
      }
      const mediaDeviceInfoCameras = await getCameras(false);
      await this.start();
      this.availableCameras$.next(mediaDeviceInfoCameras);
      this.liveFacadeService.verifyDevicesAndJoinChannel(deviceId);
    } catch (error) {
      error.code === 'PERMISSION_DENIED'
        ? this.liveFacadeService.updateErrorCode(StreamingErrors.PERMISSION_DENIED)
        : this.liveFacadeService.updateErrorCode(error.code);
      console.error('Unleash RTC streaming error: ', error);
      this.liveFacadeService.manuallyPermissionErrorMessage();
    }
  }

  public async startStream(): Promise<void> {
    this.isStartingStreaming$.next(true);
    this.streamStopped$.next(false);
    this.unleashAnalytics.logEvent(EVENTS.STREAM_START);
    try {
      await this.client.setClientRole('host');
      if (this.localAudio) {
        await this.client.publish([this.localAudio, this.localVideo]);
      } else {
        await this.client.publish([this.localVideo]);
      }
      console.info('Publish local stream successfully', this.streamUrl);
      this.updateVideostats();
      await this.client.startLiveStreaming(this.streamUrl);
      this.getAiModelsData();
      this.isStreaming$.next(true);
      this.isStartingStreaming$.next(false);
    } catch (err) {
      console.error('Publish local stream error: ' + err);
      this.liveFacadeService.updateErrorMessage(JSON.stringify(err));
      this.liveFacadeService.updateErrorCode(StreamingErrors.FAILED_STREAMING);
      this.unleashAnalytics.logEvent(EVENTS.STREAM_FAILED, err);
      this.isStreaming$.next(false);
      this.snackBar.open(`Oops! Stream couldn't reach our servers. Please try again!`, null, {duration: 3000});
      throw new Error('Could not publish ' + err);
    }
  }

  public getAiModelsData(): void {
    this.userService.defaultDevice$.subscribe(defaultDevice => {
      this.liveFacadeService.updateDevice({
        ...defaultDevice,
        runningModels: [],
        waitingModels: []
      });
      this.liveFacadeService.getStreamKey();
      this.liveFacadeService.getAllDevices();
    });
  }

  public async stopStream(): Promise<void> {
    this.streamingDevice$.next({
      ...this.streamingDevice$.value,
      runningModels: [],
      waitingModels: []
    });
    try {
      await this.client.stopLiveStreaming(this.streamUrl);
      this.unleashAnalytics.logEvent(EVENTS.STREAM_STOP);
      if (this.localAudio) {
        await this.client.unpublish([this.localAudio, this.localVideo]);
      } else {
        await this.client.unpublish([this.localVideo]);
      }
      this.isStreaming$.next(false);
      this.streamStopped$.next(true);
      this.activeAddons$.next([]);
    } catch (err) {
      console.error('Unpublish local stream error: ' + err);
      this.unleashAnalytics.logEvent(EVENTS.STREAM_FAILED, err);
    }
  }

  public disableStream(): void {
    if (this.localVideo) {
      this.localVideo.setEnabled(false);
    }

    if (this.localAudio) {
      this.localAudio.setEnabled(false);
    }
  }

  public async switchCamera(): Promise<void> {
    const isLastCamera = this.activeCameraOrder === this.availableCameras$.value.length - 1;
    this.activeCameraOrder = isLastCamera ? 0 : this.activeCameraOrder + 1;
    const activeCameraId = this.availableCameras$.value[this.activeCameraOrder].deviceId;
    this.localVideo.getMediaStreamTrack().stop();
    try {
      await this.localVideo.setDevice(activeCameraId);
    } catch (err) {
      console.error(err);
    }
  }

  public addWaitingModel(model: Addon): void {
    const updatedDevice: UserDeviceJoined = {
      ...this.streamingDevice$.value,
      waitingModels: [...this.streamingDevice$.value.waitingModels, model.id]
    };
    this.liveFacadeService.updateDevice(updatedDevice);
  }

  public removeWaitingModel(modelIdToRemove: string): void {
    const updatedModels: string[] = [...this.streamingDevice$.value.waitingModels];
    updatedModels.forEach((modelId: string, index: number) => {
      if (modelId === modelIdToRemove) {
        updatedModels.splice(index, 1);
      }
    });

    const updatedDevice: UserDeviceJoined = {
      ...this.streamingDevice$.value,
      waitingModels: [...updatedModels]
    };

    this.liveFacadeService.updateDevice(updatedDevice);
  }

  // TODO: Test when ai works
  public addRunningModel(model: Addon): void {
    const updatedDevice: UserDeviceJoined = {
      ...this.streamingDevice$.value,
      runningModels: [...this.streamingDevice$.value.runningModels, model.id]
    };
    this.liveFacadeService.updateDevice(updatedDevice);
  }

  public removeRunningModel(modelIdToRemove: string): void {
    const updatedModels: string[] = [...this.streamingDevice$.value.runningModels];
    updatedModels.forEach((modelId: string, index: number) => {
      if (modelId === modelIdToRemove) {
        updatedModels.splice(index, 1);
      }
    });

    const updatedDevice: UserDeviceJoined = {
      ...this.streamingDevice$.value,
      runningModels: [...updatedModels]
    };

    this.liveFacadeService.updateDevice(updatedDevice);
  }

  public runAi(startingModel: Addon): void {
    this.addWaitingModel(startingModel);
    if (this.addonService.isModelRunning(this.streamingDevice$.value, startingModel.id)) {
      this.setCurrentModel(this.streamingDevice$.value, startingModel);
      return;
    }

    // not raw and not streaming - call API to start this model
    this.addonService.scheduleModel(this.streamingDevice$.value, startingModel.id);
    this.liveFacadeService.getAgoraToken(this.streamingDevice$.value.id);
    this.liveFacadeService.startLiveAi(
      {...this.streamingDevice$.value, agoraToken: this.agoraToken$.value},
      startingModel,
      this.streamKey
    );
  }

  public stopAI(selectedModelId: string): void {
    if (this.streamingDevice$.value.waitingModels.length > 0) {
      this.removeWaitingModel(selectedModelId);
    }
    this.liveFacadeService.stopLiveAi(this.streamingDevice$.value.id, selectedModelId);
  }

  public async leave(): Promise<void> {
    if (!this.client || !this.isConnected$.value) {
      return;
    }
    try {
      await this.client.leave();
      this.unleashAnalytics.logEvent(EVENTS.STREAM_LEAVE_CHANNEL);
      this.isConnected$.next(false);
      if (this.localVideo) {
        this.localVideo.stop();
        this.localVideo.close();
      }
    } catch (err) {
      console.error('Leave channel failed');
      this.unleashAnalytics.logEvent(EVENTS.STREAM_FAILED, err);
    }
    clearInterval(this.videoStatsInterval);
  }

  public setCurrentModel(device: UserDeviceJoined, model: Addon): void {
    let updatedDevice: UserDeviceJoined = device;
    updatedDevice = {...updatedDevice, selectedModel: model.id};
    this.liveFacadeService.updateDevice(updatedDevice);
  }

  public getCompanyInfo(): void {
    this.liveFacadeService.getCompanyInfo();
  }

  public getWatchPageLink(slug: string): string {
    return environment.STREAM_DASHBOARD_URL + slug;
  }

  public waitForUserAndJoinChannel(deviceId: string): void {
    this.liveFacadeService.waitForUserAndJoinChannel(deviceId);
  }

  public getStreamUrl(deviceId: string, streamKey: string): string {
    return `${environment.RTMP_STREAM_URL}/rtmp/${deviceId}?token=${streamKey}&player=webrtc`;
  }

  public joinChannel(): void {
    this.liveFacadeService.joinChannel();
  }

  public onUrlCopied(): void {
    this.liveFacadeService.showCopyUrlMessage();
  }

  public enablePublicLivePage(): void {
    this.liveFacadeService.enablePublicLivePage(this.companyId$.value, {
      isPublicWatchPageEnabled: true
    });
  }

  public generateCompanySlug(): void {
    this.liveFacadeService.generateCompanySlug(this.companyId$.value, this.companySlug$.value);
  }

  public updateErrorMessage(errorMessage: string): void {
    this.liveFacadeService.updateErrorMessage(errorMessage);
  }

  public updateErrorCode(errorCode: StreamingErrors): void {
    this.liveFacadeService.updateErrorCode(errorCode);
  }

  public manuallyPermissionErrorMessage(): void {
    this.liveFacadeService.manuallyPermissionErrorMessage();
  }

  public getisLoadingSlug(): Observable<boolean> {
    return this.liveFacadeService.getisLoadingSlug();
  }

  public getisEnablingLivePage(): Observable<boolean> {
    return this.liveFacadeService.getisEnablingLivePage();
  }

  public enableSecureTunnel(): void {
    this.client.startProxyServer(AgoraService.AGORA_CLOUD_PROXY_TCP);
  }

  private updateVideostats(): void {
    const videoTrack = this.localVideo.getMediaStreamTrack() as MediaStreamTrack;
    this.videoStats$.value.frame = videoTrack.getSettings().frameRate;
    this.videoStats$.value.width = videoTrack.getSettings().width;
    this.videoStats$.value.height = videoTrack.getSettings().height;
  }

  private async start(): Promise<void> {
    try {
      this.localVideo = await createCameraVideoTrack({
        facingMode: 'environment',
        encoderConfig: this.browserSettingsService.isIOS() ? '720p' : '1080p'
      });
      this.localVideo.play('stream_local', {mirror: false});
    } catch (error) {
      this.liveFacadeService.updateErrorMessage(error);
      this.liveFacadeService.updateErrorCode(StreamingErrors.PERMISSION_DENIED);
      console.error('Start error', error);
      this.askPermissionErrorMessage();
    }
    try {
      this.localAudio = await createMicrophoneAudioTrack();
    } catch (error) {
      console.warn('Sound not enabled');
    }
  }

  private askPermissionErrorMessage(): void {
    this.liveFacadeService.askPermissionErrorMessage();
  }
}

<form class="form" [formGroup]="form">
  <mat-form-field appearance="fill" class="email-container">
    <mat-label>{{ 'common.email' | translate }}</mat-label>
    <input formControlName="email" matInput placeholder="{{ 'common.email' | translate }}" />
  </mat-form-field>

  <mat-form-field appearance="fill" class="team-container">
    <mat-label>Team</mat-label>
    <mat-select formControlName="teamId" placeholder="Team">
      <mat-option *ngFor="let team of teams" [value]="team.id">
        {{ team.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="role-container">
    <mat-label>{{ 'profile.users.role' | translate }}</mat-label>
    <mat-select formControlName="role" placeholder="{{ 'profile.users.role' | translate }}">
      <mat-select-trigger>
        {{ form.controls.role.value | roleName | async }}
      </mat-select-trigger>
      <mat-option *ngFor="let role of roles | keyvalue" class="role__option" [value]="role.key">
        <div class="role">
          <div class="role__title">{{ role.value }}</div>
          <div class="role__description">
            <!-- {{ 'profile.users.roles.adminDescription' | translate }} -->
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button
    (click)="emitDelete()"
    *ngIf="hasDelete"
    color="grey-6"
    data-test-id="add-user-delete-button"
    class="delete-button"
    mat-icon-button
  >
    <mat-icon>delete</mat-icon>
  </button>
</form>

<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  {{ data.message }}
</div>

<div *ngIf="!data.severity || data.severity === ioTSeverityMessage.CONFIRM" mat-dialog-actions>
  <span fxFlex></span>
  <button color="primary" mat-button [mat-dialog-close]="false" tabindex="1">NO</button>
  <button color="warn" mat-button [mat-dialog-close]="true" tabindex="2">YES</button>
</div>

<div *ngIf="data.severity === ioTSeverityMessage.INFO || data.severity === ioTSeverityMessage.ERROR" mat-dialog-actions>
  <span fxFlex></span>
  <button color="primary" mat-button [mat-dialog-close]="null" tabindex="1">OK</button>
</div>

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-create-group-layers-dialog',
  templateUrl: './create-group-layers-dialog.component.html',
  styleUrls: ['./create-group-layers-dialog.component.scss']
})
export class CreateGroupLayersDialogComponent implements OnInit {
  groupName: string;
  constructor() {}

  ngOnInit() {}
}

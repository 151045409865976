import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatDrawer, MatDrawerContainer, MatSidenav} from '@angular/material/sidenav';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Device, StreamPlayer, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserModel} from '@app/core/models/api/user-model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {StatusService} from '@app/core/services/api/status.service';
import {UserService} from '@app/core/services/api/user.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {LayerNotFoundDialogComponent} from '@app/atlas/components/layer-not-found-dialog/layer-not-found-dialog.component';
import {AtlasLocalStorageService} from '@app/atlas/services/atlas-local-storage.service';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {DashboardService} from '@app/data/services/dashboard.service';
import {ContextMenuComponent} from '@app/shared/context-menu/context-menu/context-menu.component';
import {
  Control,
  control,
  ControlPosition,
  GeoJSON,
  GridLayer,
  latLng,
  LatLngBoundsExpression,
  LatLngTuple,
  Layer,
  Map,
  MapOptions,
  Marker,
  PanOptions,
  TileLayer,
  ZoomOptions,
  ZoomPanOptions
} from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import 'leaflet-minimap';
import 'leaflet.heat';
import {Clipboard} from '@angular/cdk/clipboard';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import 'sidebar-v2/js/leaflet-sidebar';
import {PermissionService} from '../../../core/services/permission.service';
import {
  atlasConfig,
  getHybridViewLayer,
  getMapViewLayer,
  getMapWeatherLayer,
  getSatelliteViewLayer
} from '../../atlas.config';
import {AssetLoaderService} from '../../services/asset-loader.service';
import {AssetsFilterService} from '../../services/assets-filter.service';
import {AtlasService} from '../../services/atlas.service';
import {TileSessionService} from '../../services/tile-session.service';
import {MAP_LEGEND, MapItem, MapLegend} from './atlas-map-legend.config';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {AclService} from '@app/core/services/acl.service';
import {WebrtcService} from '@app/core/services/api/webrtc.service';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {SensorsDataIotService} from '@app/flights/services/sensors-data-iot.service';
import {AtlasUploadComponent} from '../atlas-upload/atlas-upload.component';
import {
  AssetType,
  AtlasAssetModel,
  AtlasGeojsonAssetModel,
  AtlasSupportedLayers
} from '@app/core/models/api/atlas.model';
import {ConfirmDeleteDialog} from '@app/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {CompareLayersService} from '@app/atlas/services/compare-layers.service';
import {AtlasSnackbarMessageComponent} from '../atlas-snackbar-message/atlas-snackbar-message.component';
import {AtlasVideoComponent} from '../atlas-video/atlas-video.component';
import {Mission, MissionRoutePoint, MissionSettingsService} from '@app/atlas/services/mission-settings.service';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';
import {AtlasSelectMarkersService} from '@app/atlas/services/atlas-select-markers.service';
import {SidebarStateService} from '@app/core/services/sidebar-state.service';
import {MoveMarkersDialogComponent} from '../move-markers-dialog/move-markers-dialog.component';
import {AddToGroupComponent} from '../add-to-group/add-to-group.component';
import {AtlasHeaderService, AtlasHeaderStatus} from '@app/atlas/services/atlas-header.service';
import {AtlasAnnotationsSettigsService} from '@app/atlas/services/atlas-annotations-settings.service';
import {AtlasSelectMarkersSettingsService} from '@app/atlas/services/atlas-select-markers-settings.service';
import {LiveFacadeService} from '@app/live/services/live-facade.service';
import {JobsFacadeService} from '@app/jobs/services/jobs-facade.service';
import {MarkerClusterService} from '@app/atlas/services/marker-cluster.service';
import {MAP_VIEW} from '@app/atlas/model/map-view.mode';
import {IoTSensorsData} from '@app/flights/models/remote-cockpit.model';

declare const L; // leaflet global

export interface AssetAddedEvent {
  layer: AtlasSupportedLayers;
  name: string;
  bounds?: LatLngBoundsExpression;
  id?: string;
  groupName?: string;
  modelId?: string;
  geojson?: GeoJSON;
  color?: string;
  jobId?: string;
}
@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-atlas',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './atlas-map.component.html',
  styleUrls: ['./atlas-map.component.scss'],
  providers: [{provide: 'map', useValue: 'recaptcha-container'}, SensorsDataIotService]
})
export class AtlasMapComponent implements OnInit, OnDestroy {
  public streamPlayer = StreamPlayer;
  @ViewChild('contextMenu', {static: false, read: ContextMenuComponent})
  public contextMenu: ContextMenuComponent;
  @Input() public pointLatLng: LatLngTuple;
  @Input() public pointMarker: Marker;
  @Input('hasGoBack')
  public set setHasGoBack(hasGoBack: boolean) {
    const hasUserNavigated: boolean = !!this.atlasLocalStorageService.getItem('unleash-navigation-start');
    hasUserNavigated ? this.hasGoBack.next(hasGoBack) : this.hasGoBack.next(false);
  }

  private hasGoBack: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasGoBack$: Observable<boolean> = this.hasGoBack.asObservable();
  public options: MapOptions;
  public panOptions: PanOptions;
  public zoomOptions: ZoomOptions;
  public zoomPanOptions: ZoomPanOptions;
  public map: Map;
  public roadView: GridLayer;
  public satelliteView: GridLayer;
  public hybridView: GridLayer;
  public weatherMap: TileLayer;
  public gpsDevices$: Observable<UserDeviceJoined[]>;
  public initialZoom = atlasConfig.INITIAL_ZOOM_LEVEL;
  public isLayersControlSideBarOpen$ = this.atlasService.isLayersControlSideBarOpen$.pipe(
    distinctUntilChanged(),
    tap(() => {
      const animationDuration = 400; //https://github.com/angular/components/blob/ae839775c21cb37355075ef4624913a1fd8391a9/src/material/sidenav/drawer-animations.ts#L48
      setTimeout(() => {
        this.map.invalidateSize();
      }, animationDuration);
    })
  );
  public translations = {enterAddress: 'Enter address'};
  public isMissionPlannerActive$: Observable<boolean> = this.activatedRoute.queryParams.pipe(
    filter(() => this.aclService.hasPermission(AclPermissions.MissionsApiRead)),
    map(params => params['mission'] && params['mission'] === '1'),
    tap(isMissionPlannerActive => {
      const headerStatus = isMissionPlannerActive ? AtlasHeaderStatus.MISSION_PLANNER : AtlasHeaderStatus.DEFAULT;
      this.atlasHeaderService.setHeaderStatus(headerStatus);
      if (this.map) {
        this.atlasService.setAsCloseLayersControlSideBar();
        setTimeout(() => {
          this.atlasService.setAsOpenLayersControlSideBar();
        });
      }
    })
  );
  public isWeatherMapEnabledForUser: boolean = false;
  public isMapLegendEnabledForUser: boolean = false;
  public dashboardTitle: string = '';
  public saveLayersView$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public goToDefaultLayers$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public saveLayersView: boolean = false;
  public goToDefaultLayers: boolean = false;
  public hasDashboardId$: Observable<boolean> = this.dashboardService.hasDashboardId$;
  public isWeatherMapDisplayed: boolean = true;
  public mapView: MAP_VIEW = MAP_VIEW.SATELLITE;
  public addonModellingEnabled$: Observable<boolean>;
  public isModellingEnabled$: Observable<boolean> = this.addonStoreFacade.getIsModellingEnabled();
  public hasToOpenAnnotationsOptions$: Observable<boolean> =
    this.atlasAnnotationsSettigsService.hasToOpenAnnotationsOptions$.pipe(
      tap(hasToOpenAnnotationsOptions => {
        this.atlasService.setHasToShowInfoPopup(!hasToOpenAnnotationsOptions);
      })
    );
  public totalSelectedLayers$: Observable<number> = this.atlasService.selectedLayers$.pipe(
    map(selectedLayers => selectedLayers.length),
    tap(totalSelectedLayers => {
      if (this.atlasHeaderService.isHeaderEqualTo(AtlasHeaderStatus.COMPARE_LAYERS)) {
        return;
      }
      const headerStatus = totalSelectedLayers ? AtlasHeaderStatus.ACTION_BAR : AtlasHeaderStatus.DEFAULT;
      this.atlasHeaderService.setHeaderStatus(headerStatus);
    }),
    shareReplay(1)
  );

  @ViewChild('drawerContainer', {read: MatDrawerContainer, static: false})
  public drawer: MatDrawer;
  @ViewChild('atlasVideo') public atlasVideoComponent: AtlasVideoComponent;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  public assetType: typeof AssetType = AssetType;
  public aclPermissions = AclPermissions;
  public hasToDetectChanges: boolean;
  public isPlanMissionsVisible: boolean = false;
  public selectedWaypoint: BehaviorSubject<{waypoint: MissionRoutePoint; index: number}> = new BehaviorSubject(null);
  public isMissionDrawerClosed: boolean = true;
  public selectedWaypointIndex: BehaviorSubject<number> = new BehaviorSubject(-1);
  public currentMission$ = this.missionSettingsService.currentMission$;
  public headerStatus$: Observable<AtlasHeaderStatus> = this.atlasHeaderService.headerStatus$.pipe(shareReplay(1));
  public atlasHeaderStatus: typeof AtlasHeaderStatus = AtlasHeaderStatus;
  public currentLayer$: Observable<AtlasGeojsonAssetModel> = this.atlasSelectMarkersService.currentLayer;
  public hasToShowSelectOptions$: Observable<boolean> = this.atlasSelectMarkersSettingsService.hasToShowSelectOptions$;
  public selectedMarkers$ = this.atlasSelectMarkersService.selectedMarkers.pipe(
    tap(() => {
      setTimeout(() => {
        this.cd.detectChanges();
      });
    }),
    shareReplay(1)
  );
  public hasDrawnItems: BehaviorSubject<boolean> = this.atlasSelectMarkersService.hasDrawnItems;
  public selectMarkersCurrentOption: BehaviorSubject<SelectMarkersOption> =
    this.atlasSelectMarkersService.selectMarkersCurrentOption;
  public selectMarkersOption: typeof SelectMarkersOption = SelectMarkersOption;
  public hasToDetectChanges$: Observable<boolean> = this.atlasService.hasToDetectChanges$;
  public isDisplayingCompareLayer$: Observable<boolean> = this.compareLayersService.isDisplayingCompareLayer$;
  public hasAllLayersLoaded$ = this.atlasService.hasAllLayersLoaded$.asObservable();
  public hasToEnableCreateJob$: Observable<boolean> = this.selectedMarkers$.pipe(
    map(selectedMarkers => this.hasToEnableCreateJob(selectedMarkers))
  );
  public createJobTooltip: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isJobsEnabled$: Observable<boolean> = this.userService.myCompany$.pipe(map(company => !!company.useUserTask));
  public searchFilterQuery$ = this.assetsFilterService.searchFilterQuery.asObservable();
  public searchResult$: Observable<AtlasAssetModel> = this.assetsFilterService.searchResult$.pipe(shareReplay(1));
  public editJobLayerTitle$: Observable<string> = this.atlasHeaderService.editJobLayerTitle$;
  public hasAddedEditChanges$: Observable<boolean> = this.atlasSelectMarkersService.hasAddedEditChanges;

  private isSideBarClose = false;
  private contextMenuData: Partial<{latLng: {lat: number; lng: number}}> = {};
  private initialCenter: LatLngTuple = atlasConfig.INITIAL_MAP_CENTER as LatLngTuple;
  private searchedPositionMarker = null;
  private hasToSetMapView: boolean = true;
  private getLatestDays: number;
  private mapLegend: HTMLDivElement;
  private assetsSubscription: Subscription;
  private isLoadingInitialPositionSubscription: Subscription;
  private compareLayersCounter: number = 0;
  private miniMap: Control;
  private selectMarkersSnackbar: MatSnackBarRef<any>;
  private hasToOpenSnackbarSubscription: Subscription;
  private hasToUpdateAssetsSubscription: Subscription;
  private newShapeCreatedSubscription: Subscription;
  private selectMarkersSubscription: Subscription;
  private isMissionPlannerSubscription: Subscription;

  public newMapOptions: MapOptions = {
    layers: [getSatelliteViewLayer()],
    zoom: 5,
    center: latLng(46.879966, -121.726909),
    attributionControl: false
  };

  constructor(
    public atlasService: AtlasService,
    public assetsFilterService: AssetsFilterService,
    public permissionService: PermissionService,
    public dashboardService: DashboardService,
    public webrtcService: WebrtcService,
    private userService: UserService,
    private statusService: StatusService,
    private unleashAnalytics: UnleashAnalyticsService,
    private assetLoaderService: AssetLoaderService,
    private tileSessionService: TileSessionService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private browserSettingsService: BrowserSettingsService,
    private addonStoreFacade: AddonStoreFacadeService,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private atlasLocalStorageService: AtlasLocalStorageService,
    private aclService: AclService,
    private liveStreamPageService: LiveStreamPageService,
    private sensorDataIoTService: SensorsDataIotService,
    private compareLayersService: CompareLayersService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private missionManagerService: MissionManagerService,
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private sidebarStateService: SidebarStateService,
    private zone: NgZone,
    private atlasHeaderService: AtlasHeaderService,
    private atlasAnnotationsSettigsService: AtlasAnnotationsSettigsService,
    private atlasSelectMarkersSettingsService: AtlasSelectMarkersSettingsService,
    private missionSettingsService: MissionSettingsService,
    private liveFacadeService: LiveFacadeService,
    private jobsFacadeService: JobsFacadeService,
    private markerClusterService: MarkerClusterService
  ) {
    this.gpsDevices$ = this.getGpsDevices().pipe(shareReplay(1));
    this.addonModellingEnabled$ = this.addonStoreFacade.getIsModellingEnabled();
    this.pointLatLng =
      !!Object.keys(this.route.snapshot.queryParams).length &&
      this.route.snapshot.queryParams.lat &&
      this.route.snapshot.queryParams.lng
        ? [this.route.snapshot.queryParams.lat, this.route.snapshot.queryParams.lng]
        : null;
    if (this.pointLatLng) {
      this.atlasService.setHasToSkipFitBoundsOnLoadLayers(true);
    }
    this.translateService.get('atlas.map.enterAddress').subscribe(res => (this.translations.enterAddress = res));

    this.hasToUpdateAssetsSubscription = this.atlasSelectMarkersService.hasToUpdateAssets.subscribe(
      hasToUpdateAssets => {
        if (!hasToUpdateAssets) {
          this.atlasService.setAvoidLoadLocalStorageAssets(false);
          setTimeout(() => {
            this.atlasSelectMarkersService.addCreatedAssets();
          });
          this.closeSelectedMarkers();
          return;
        }
        this.disableSelectByClick();
        this.setCurrentSelectionMarker(SelectMarkersOption.NONE, null);
        this.atlasSelectMarkersService.clearSelections(this.map);
        this.atlasSelectMarkersService.clearControl(this.map);
      }
    );
    this.newShapeCreatedSubscription = this.atlasSelectMarkersService.newShapeCreated
      .pipe(filter(isNewShapeCreated => !!isNewShapeCreated))
      .subscribe(() => {
        this.setCurrentSelectionMarker(SelectMarkersOption.NONE, null);
      });
    this.selectMarkersSubscription = this.atlasService.selectMarkers.subscribe(layer => {
      this.atlasAnnotationsSettigsService.closeAnnotationsOptions();
      this.atlasService.sethasToSkipLoadAssets(true);
      this.atlasService.showOnlyThisLayer(layer);
      this.cd.detectChanges();
      this.atlasSelectMarkersService.setCurrentLayer(layer);
      const headerStatus = AtlasHeaderStatus.SELECT_MARKERS;
      this.atlasHeaderService.setHeaderStatus(headerStatus);
      this.atlasSelectMarkersSettingsService.showSelectOptions();
      this.atlasService.setHasToRemoveAssetsHiglight(true);
      this.atlasService.setHasToBlockEvents(true);
      this.atlasSelectMarkersService.setOpenSelectMarkersTime(new Date());
    });
    this.toggleInfoMarkerPopup();
    this.jobsFacadeService.storeTeamMembers();
  }

  public trackById(index: number, item: {id: string}): string {
    return item.id;
  }

  private setCreateJobTooltip(areAllSelectedMarkersInsideShape): void {
    if (!areAllSelectedMarkersInsideShape) {
      this.createJobTooltip.next('createJobErrorTooltip');
      return;
    }
    this.createJobTooltip.next(null);
  }

  private hasToEnableCreateJob(selectedMarkers): boolean {
    const markersOnShape = this.atlasSelectMarkersService.markersOnShape;
    const selectedMarkersSet = new Set(selectedMarkers);
    const selectedMarkersOnShape = markersOnShape.filter(element => selectedMarkersSet.has(element));
    const areAllSelectedMarkersInsideShape = selectedMarkersOnShape.length === selectedMarkers.length;
    this.setCreateJobTooltip(areAllSelectedMarkersInsideShape);
    if (markersOnShape.length === 0 || selectedMarkers.length === 0) {
      return false;
    }
    return areAllSelectedMarkersInsideShape;
  }

  private toggleInfoMarkerPopup() {
    this.isMissionPlannerSubscription = this.route.queryParams.subscribe(queryParams => {
      const isMissionPlanningEnabled = queryParams.mission;
      if (isMissionPlanningEnabled) {
        this.atlasAnnotationsSettigsService.closeAnnotationsOptions();
      }
      this.atlasService.setHasToBlockEvents(isMissionPlanningEnabled);
    });
  }

  public showLayer(device: UserDeviceJoined): void {
    this.moveToLocation(device.lat, device.lng);
    this.sensorDataIoTService
      .subscribe(device.id)
      // .subscribeToMockData(device.id)
      .pipe(skip(1), take(1))
      .subscribe((data: IoTSensorsData) => {
        if (data && data.lat && data.lng) {
          this.moveToLocation(data.lat, data.lng);
        }
      });
  }

  private moveToLocation(lat: number, lng: number): void {
    this.map.invalidateSize();
    const bounds: LatLngBoundsExpression = [[lat, lng]];

    this.map.fitBounds(bounds, atlasConfig.FIT_BOUNDS_OPTIONS);
    if (this.browserSettingsService.getIsMobileBrowser()) {
      this.closeLayersControlSideBar();
    }
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {
      type: 'View location'
    });
  }

  public getAssets() {
    this.atlasService
      .getAssets(this.getLatestDays)
      .pipe(
        filter(data => !!data.length),
        concatMap(assets => {
          return of(assets.sort());
        })
      )
      .subscribe(() => {
        this.showNewLayerNotification();
      });
  }

  public ngOnInit(): void {
    this.initMap();
    // reset search results in the sidebar
    this.assetsFilterService.updateFilterQuery(null);
    this.compareLayersService.listenNavigation();
  }

  public isDeviceStreaming$(device: Device): Observable<boolean> {
    return this.liveStreamPageService.liveDevicesId$.pipe(map(devices => devices.indexOf(device.id) !== -1));
  }

  public ngOnDestroy(): void {
    this.atlasService.clearSelectedLayers();
    this.atlasService.setAvoidLoadLocalStorageAssets(false);
    this.atlasService.setHasToMoveToUploadedLayer(false);
    this.atlasService.setHasToSkipFitBoundsOnLoadLayers(false);
    this.atlasService.setHasToShowInfoPopup(true);
    this.closeSelectedMarkers();
    this.closeEditJobSelection();
    this.atlasService.destroy();
  }

  public onNewMapReady(map: Map): void {
    this.compareLayersService.setCompareLayerMap(map);

    setTimeout(() => {
      map.invalidateSize();
    }, 1000);
  }

  public onMapReady(map: Map): void {
    this.map = map;
    map.on('popupopen', () => {
      this.atlasService.setIsPopupOpened(true);
    });
    map.on('popupclose', () => {
      this.atlasService.setIsPopupOpened(false);
    });
    this.hasAllLayersLoaded$.pipe(take(1)).subscribe(hasAllLayersLoaded => {
      if (hasAllLayersLoaded) {
        this.showMapWhenAssetsExist();
        return;
      }
      this.actWhenMapIsReady(this.map);
      this.dashboardService.setDrawerRef(this.drawer);
    });
  }

  private showMapWhenAssetsExist(): void {
    this.initAssetLoaders();
    this.createSearchBox('topleft');
    this.userService.user$.subscribe(user => {
      this.setInitialMapCenter(user);
    });
    this.loadDefaultView();
    this.markerClusterService.addToMap(this.map);
    this.goToDefaultView();
    if (this.route.snapshot.queryParams.new) {
      this.showNewLayerNotification();
    }
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(routeParams => {
      this.setMissionBasedOnQueryParams(routeParams);
    });
  }

  private actWhenMapIsReady(map: Map): void {
    setTimeout((): void => {
      map.invalidateSize();
    }, 20);

    this.createSearchBox('topleft');
    this.addScaleBar('bottomright');
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this), withLatestFrom(this.userService.user$))
      .subscribe(async ([routeParams, user]) => {
        this.getLatestDays =
          user && user.atlas && user.atlas.settings && user.atlas.settings && user.atlas.settings.getLatestDays;
        this.isWeatherMapEnabledForUser = user?.atlas?.settings?.weatherMapEnabled;
        this.isMapLegendEnabledForUser = routeParams?.mission === '1' ? false : user?.atlas?.settings?.isLegendEnabled;

        this.setMissionBasedOnQueryParams(routeParams);

        if (this.hasToSetMapView) {
          this.setInitialMapCenter(user);
        }

        await this.weatherMap;
        if (this.isWeatherMapEnabledForUser) {
          this.loadDefaultWeatherView();
          this.atlasService.isWeatherMapEnabledForUser = true;
          if (!this.atlasLocalStorageService.getItem('isWeatherMapDisplayed')) {
            this.atlasLocalStorageService.setItem('isWeatherMapDisplayed', true + '');
          }
        }

        this.loadDeviceUrl();
        this.getAssets();

        this.addMiniMap('bottomright');
        if (this.isMapLegendEnabledForUser) {
          this.addMapLegend('topright');
        } else {
          this.removeMapLegend();
        }
        this.watchStreamingDevices(user);
      });

    // Implement clicking tile layers and navigating to session
    //  @more https://github.com/unleashlive/unleashcloudfront/issues/942
    map.doubleClickZoom.disable();
    map.on('dblclick', (e: any): void => {
      const assetId = this.tileSessionService.getAssetIdFromMapClick(e);
      if (!assetId) {
        this.closeLayersControlSideBar();
        return;
      }
      this.openLayersControlSideBar();
      this.assetsFilterService.filterAsset(assetId);
    });
    this.loadDefaultView();
    this.markerClusterService.enableClustering();
    this.markerClusterService.addToMap(this.map);
    this.setupContextMenu();
    if (window.innerWidth >= 600) {
      this.toggleLayerControlSideBar(); // should be on non-mobile only
    }
    this.initAssetLoaders();

    const moveElement = document.querySelector('.unleash-tools');
    const leafletTopLeft = document.querySelector('.leaflet-top.leaflet-left');
    leafletTopLeft.insertBefore(moveElement, leafletTopLeft.firstChild);
  }

  private setMissionBasedOnQueryParams(routeParams) {
    if (routeParams.missionId) {
      this.atlasService.setAsOpenLayersControlSideBar();

      this.missionManagerService.missions$
        .pipe(
          filter(missions => missions?.length > 0),
          take(1)
        )
        .subscribe(missionsGroupedByOwner => {
          const mission = missionsGroupedByOwner.find(mission => mission.id === routeParams.missionId);
          if (mission) {
            this.missionSettingsService.setMission(mission);
            // Hack to remove the grey area in map
            // Reference: https://stackoverflow.com/questions/56201012/leaflet-gray-stripes-after-panning#answer-56316350
            window.dispatchEvent(new Event('resize'));
          }
        });
    }
  }

  public compareLayers(): void {
    const headerStatus = AtlasHeaderStatus.COMPARE_LAYERS;
    this.atlasHeaderService.setHeaderStatus(headerStatus);
    this.atlasService.setIsCompareLayersOpen(true);
    const showMessageLimit = 3;
    if (this.compareLayersCounter < showMessageLimit) {
      this.translateService
        .get('atlas.actionBar.compareLayersInfo')
        .pipe(take(1))
        .subscribe(message => {
          this.snackBar.openFromComponent(AtlasSnackbarMessageComponent, {
            duration: 3000,
            panelClass: 'atlas-snackbar-message--0',
            data: {
              message
            }
          } as MatSnackBarConfig);
        });
    }
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_COMPARE_LAYERS, {
      totalLayersCompared: this.atlasService.selectedLayers.length,
      typesOfLayersCompared: this.atlasService.selectedLayers.map(layer => layer.type)
    });
    this.compareLayersService.compareLayers(this.map);
    this.compareLayersCounter++;
  }

  public closeCompareLayers() {
    const headerStatus = AtlasHeaderStatus.ACTION_BAR;
    this.atlasHeaderService.setHeaderStatus(headerStatus);
    this.atlasService.setIsCompareLayersOpen(false);
    this.compareLayersService.resetCompareLayers(this.map);
    this.closeHeader();
  }

  public closeEditJobSelection() {
    this.atlasSelectMarkersService.closeEditJobShapeWithoutSave(this.map);
    this.closeJobEditMode();
  }

  public discardJobsEdit() {
    this.atlasSelectMarkersService.discardJobsEdit(this.map);
  }

  public saveEditedJob() {
    this.atlasSelectMarkersService.saveNewJobPolygon();
    this.atlasSelectMarkersService.updateJobMarkers();
    this.atlasSelectMarkersService.closeEditJobShape(this.map);
    this.closeJobEditMode();
  }

  private closeJobEditMode(): void {
    this.atlasHeaderService.setHeaderStatus(AtlasHeaderStatus.DEFAULT);
    this.atlasSelectMarkersSettingsService.hideSelectOptions();
    this.atlasService.displayAssets();
  }

  public async closeGenericHeader(atlasHeaderStatus: AtlasHeaderStatus) {
    switch (atlasHeaderStatus) {
      case AtlasHeaderStatus.COMPARE_LAYERS:
        this.closeCompareLayers();
        break;
      case AtlasHeaderStatus.SELECT_MARKERS:
        this.atlasService.setAvoidLoadLocalStorageAssets(true);
        this.atlasSelectMarkersService.addCreatedAssets();
        this.closeSelectedMarkers();
        this.atlasService.displayAssets();
        break;
      case AtlasHeaderStatus.EDIT_JOB_SHAPE:
        this.closeEditJobSelection();
        break;
    }
  }

  public closeSelectedMarkers() {
    const headerStatus = AtlasHeaderStatus.DEFAULT;
    this.atlasHeaderService.setHeaderStatus(headerStatus);
    this.atlasSelectMarkersSettingsService.hideSelectOptions();
    this.atlasSelectMarkersService.enableMarkerEvents();
    this.disableSelectByClick();
    this.setCurrentSelectionMarker(SelectMarkersOption.NONE, null);
    this.atlasSelectMarkersService.clearSelections(this.map);
    this.atlasSelectMarkersService.clearControl(this.map);
    this.closeHeader();
    this.atlasService.setHasToRemoveAssetsHiglight(false);
    this.atlasService.sethasToSkipLoadAssets(false);
    this.atlasSelectMarkersService.setCurrentLayer(null);
  }

  public loadDefaultView() {
    const storedMapView = this.atlasLocalStorageService.getItem('mapView') as MAP_VIEW;
    if (!storedMapView) {
      this.map.addLayer(this.satelliteView);
      return;
    }
    this.mapView = storedMapView;
    switch (storedMapView) {
      case MAP_VIEW.HYBRID:
        this.map.addLayer(this.hybridView);
        break;
      case MAP_VIEW.SATELLITE:
        this.map.addLayer(this.satelliteView);
        break;
      case MAP_VIEW.ROADMAP:
        this.map.addLayer(this.roadView);
        break;
      default:
        this.map.addLayer(this.satelliteView);
        break;
    }
  }

  public loadDefaultWeatherView() {
    if (
      !this.atlasLocalStorageService.getItem('isWeatherMapDisplayed') ||
      this.atlasLocalStorageService.getItem('isWeatherMapDisplayed') === 'true'
    ) {
      this.map.addLayer(this.weatherMap);
      return;
    }
    this.map.removeLayer(this.weatherMap);
  }

  public toggleLayerControlSideBar() {
    this.isSideBarClose
      ? this.map.setMaxBounds(atlasConfig.MAX_BOUNDS_FULL_SCREEN as LatLngBoundsExpression)
      : this.map.setMaxBounds(atlasConfig.MAX_BOUNDS as LatLngBoundsExpression);

    this.isSideBarClose
      ? this.atlasService.setAsCloseLayersControlSideBar()
      : this.atlasService.setAsOpenLayersControlSideBar();
    this.compareLayersService.reOpenCompareLayers(this.map);
  }

  public toggleClusters() {
    this.markerClusterService.toggleClusters();
  }

  public onSidebarClose(): void {
    this.compareLayersService.reOpenCompareLayers(this.map);
  }

  public openedSidebarChange(isClose: boolean): void {
    this.isSideBarClose = isClose;
  }

  public closeLayersControlSideBar(): void {
    this.map.setMaxBounds(atlasConfig.MAX_BOUNDS_FULL_SCREEN as LatLngBoundsExpression);
    this.atlasService.setAsCloseLayersControlSideBar();
  }

  public openLayersControlSideBar(): void {
    this.atlasService.setAsOpenLayersControlSideBar();
  }

  public addMarker(marker: Marker): void {
    this.map.addLayer(marker);
  }

  public addLayers(layers: Layer[]): void {
    layers.forEach(layer => {
      this.map.addLayer(layer);
    });
  }

  public copyLatLngContextMenu(): void {
    const latlngAsString: string = `${this.contextMenuData.latLng.lat.toFixed(
      6
    )},${this.contextMenuData.latLng.lng.toFixed(6)}`;
    this.clipboard.copy(latlngAsString);
    this.translateService.get('atlas.map.copiedToClipboard').subscribe(res => {
      this.snackBar.open('Copied to clipboard', null, {
        duration: 3000
      } as MatSnackBarConfig);
    });
  }

  public saveCurrentViewAsDefault() {
    this.atlasLocalStorageService.setItem('savedLayer', '{}');
    this.atlasLocalStorageService.setItem('savedGroup', '{}');
    this.saveLayersView = !this.saveLayersView;
    this.saveLayersView$.next(this.saveLayersView);
    this.atlasService.isStoredDefaultHardCodedPosition = false;
    this.atlasService.setFavouritePropertiesInStorage(
      this.map.getCenter(),
      this.map.getZoom(),
      this.mapView,
      this.map.hasLayer(this.weatherMap)
    );
    this.translateService.get('atlas.map.currentViewSetAsDefault').subscribe(translation => {
      this.snackBar.open(translation, null, {
        duration: 3000,
        horizontalPosition: 'start'
      });
    });
  }

  public goToDefaultView() {
    this.goToDefaultLayers = !this.goToDefaultLayers;
    this.goToDefaultLayers$.next(this.goToDefaultLayers);
    if (this.atlasService.hasMapPropertiesStored()) {
      this.atlasService.loadInitialPositionFromLocalStorage(this.map);
      this.changeMapView();
      this.displayWeatherMap();
    }
  }

  public toggleWeather() {
    if (this.weatherMap && this.map.hasLayer(this.weatherMap)) {
      this.map.removeLayer(this.weatherMap);
      this.isWeatherMapDisplayed = false;
      return;
    }
    if (this.weatherMap && !this.map.hasLayer(this.weatherMap)) {
      this.map.addLayer(this.weatherMap);
      this.isWeatherMapDisplayed = true;
    }
    return;
  }

  public toggleMap() {
    this.mapView = this.atlasService.toggleMap(
      this.map,
      {
        roadView: this.roadView,
        hybridView: this.hybridView,
        satelliteView: this.satelliteView
      },
      this.mapView
    );
    this.verifyWeatherMapEnabled();
  }

  private verifyWeatherMapEnabled() {
    if (this.isWeatherMapDisplayed && this.isWeatherMapEnabledForUser) {
      this.weatherMap.bringToFront();
    }
  }

  private getGpsDevices(): Observable<UserDeviceJoined[]> {
    return this.userService.userDevices$.pipe(
      map((devices: UserDeviceJoined[]): UserDeviceJoined[] =>
        devices.filter((device: UserDeviceJoined): boolean => !!device && !!device.gps)
      ),
      distinctUntilChanged((a, b) => {
        const ignoreValuesToCompare = device => {
          const {player, isLive, updatedAt, waitingModels, runningModels, lastSeen, ...rest} = device;
          return rest;
        };
        const aData = a.map(ignoreValuesToCompare);
        const bData = b.map(ignoreValuesToCompare);
        return JSON.stringify(aData) === JSON.stringify(bData);
      })
    );
  }

  public openUploadDialog(): void {
    if (!this.permissionService.canUseAtlas()) {
      console.error('Atlas plan not enabled');
      return;
    }
    this.dialog
      .open(AtlasUploadComponent, {
        width: '80vw',
        maxWidth: '800px',
        disableClose: true,
        hasBackdrop: true,
        panelClass: 'space-bottom',
        closeOnNavigation: true
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(newDataAdded => !!newDataAdded),
        switchMap(() => this.atlasService.getCreatedAssets())
      )
      .subscribe(newDataAdded => {
        this.atlasService.setHasToMoveToUploadedLayer(true);
        this.atlasService.addAssets(newDataAdded);
      });
  }

  public toggleOpenAnnotations(): void {
    this.atlasAnnotationsSettigsService.toggleAnnotationsOptions();
  }

  public selectAllLayers(): void {
    this.atlasService.selectAllLayers();
  }

  public closeHeader(): void {
    this.atlasService.clearSelectedLayers();
  }

  public moveSelectedAssets(): void {
    const selectedLayers: AtlasAssetModel[] = this.atlasService.selectedLayers;
    this.dialog.open(AddToGroupComponent, {
      ...STANDARD_DIALOG_CONFIG,
      panelClass: 'move-items-dialog',
      width: '80vw',
      maxWidth: '800px',
      data: {
        assets: selectedLayers
      },
      disableClose: true
    });
  }

  public bringLayersToFront(): void {
    this.atlasService.selectedLayers.forEach(asset => {
      (asset.leafletLayer as TileLayer).bringToFront();
    });
  }

  public sendLayersToBack(): void {
    this.atlasService.selectedLayers.forEach(asset => {
      (asset.leafletLayer as TileLayer).bringToBack();
    });
  }

  public removeSelectedAssets(): void {
    const selectedLayers: AtlasAssetModel[] = this.atlasService.selectedLayers;
    this.dialog
      .open(ConfirmDeleteDialog, {
        ...STANDARD_DIALOG_CONFIG,
        width: '500px',
        data: {items: selectedLayers.map(l => l.name)}
      } as MatDialogConfig)
      .afterClosed()
      .pipe(filter(Boolean), take(1))
      .subscribe(() => {
        this.atlasService.removeAssets(selectedLayers).subscribe(resultArr => {
          if (resultArr.length === 1) {
            this.translateService
              .get('atlas.control.removeSuccess', {value: selectedLayers[0].name})
              .subscribe(message => {
                this.snackBar.open(message, null, {duration: 5000});
              });
            return;
          }
          this.snackBar.open(`Successfully deleted ${resultArr.length} assets`, null, {duration: 5000});
        });
      });
  }

  public refreshView() {
    this.cd.detectChanges();
  }

  public showSelectedLayers(): void {
    this.markerClusterService.enableClustering();
    this.atlasService.selectedLayers.forEach(layer => {
      layer.isDisplaying = true;
    });
    this.detectLayerChanges();
  }

  public hideSelectedLayers(): void {
    this.atlasService.selectedLayers.forEach(layer => {
      layer.isDisplaying = false;
    });
    this.detectLayerChanges();
  }

  public showOnlyThisLayer(layer: AtlasAssetModel): void {
    this.markerClusterService.enableClustering();
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = layer.id === asset.id;
      asset.isHighlighted = false;
    });
    this.detectLayerChanges();
  }

  public showOnlySelectedLayers(): void {
    this.markerClusterService.enableClustering();
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = this.atlasService.selectedLayers.includes(asset);
    });
    this.detectLayerChanges();
  }

  public deviceUpdated(): void {
    this.atlasService.deviceUpdated(this.map);
  }

  public openPlanMissions(): void {
    this.hasToSetMapView = false;
    this.router.navigate(['secure/atlas'], {queryParams: {mission: 1}});
  }

  public assignSelectedWaypoint(selectedWaypoint: {waypoint: MissionRoutePoint; index: number; isLastIndex: boolean}) {
    if (!selectedWaypoint) {
      this.selectedWaypoint.next(null);
      this.setSelectedWaypointIndex(-1);
      return;
    }
    this.selectedWaypoint.next(selectedWaypoint);
    this.setSelectedWaypointIndex(selectedWaypoint.index);
    this.isMissionDrawerClosed = false;
    setTimeout(() => {
      this.map.invalidateSize();
      this.map.panTo({lat: selectedWaypoint.waypoint.lat, lng: selectedWaypoint.waypoint.lng});
    }, 200);
  }

  public missionViewerEnabled(mission: Mission) {
    this.missionSettingsService.setMission(mission);
    this.router.navigate(['secure/atlas'], {queryParams: {mission: 1, missionId: mission.id}});
  }

  public newWaypoint(vertex: {waypoint: MissionRoutePoint; distance: number; time: number}) {
    this.missionSettingsService.currentMission$.pipe(take(1)).subscribe(currentMission => {
      this.missionSettingsService.setMission({
        ...currentMission,
        route: [...currentMission.route, vertex.waypoint],
        distance: vertex.distance,
        time: vertex.time
      });

      this.cd.detectChanges();
    });
  }

  public setIsMissionDrawerClosed() {
    this.isMissionDrawerClosed = true;
    setTimeout(() => {
      this.map.invalidateSize();
    }, 200);
  }

  public setSelectedWaypointIndex(routeIndex: number) {
    this.selectedWaypointIndex.next(routeIndex);
    this.cd.detectChanges();
  }

  public closeMissionViewer() {
    this.missionSettingsService.setMission(null);
    this.selectedWaypoint.next(null);
    this.selectedWaypointIndex.next(-1);
    this.router.navigate(['secure/atlas'], {queryParams: {mission: 1}});
  }

  public missionCopiedToClipboard(): void {
    this.translateService
      .get('atlas.mission.copiedJson')
      .pipe(take(1))
      .subscribe(text => {
        this.snackBar.open(text, null, {
          duration: 3000,
          panelClass: 'mission-copied'
        });
      });
  }

  public selectByBox(): void {
    this.startDrawer();
    this.disableSelectByClick();
    this.setCurrentSelectionMarker(SelectMarkersOption.BOX, 'atlas.selectLayers.drawBox');
    const rectangle = document.querySelector('.leaflet-draw-draw-rectangle') as any;
    rectangle?.click();
  }

  public selectByPolygon(): void {
    this.startDrawer();
    this.disableSelectByClick();
    this.setCurrentSelectionMarker(SelectMarkersOption.POLYGON, 'atlas.selectLayers.drawPolygon');
    const polygon = document.querySelector('.leaflet-draw-draw-polygon') as any;
    polygon?.click();
  }

  public selectByClick(): void {
    this.atlasSelectMarkersService.clearControl(this.map);
    this.setCurrentSelectionMarker(SelectMarkersOption.CLICK, 'atlas.selectLayers.selectMarker');
    this.atlasSelectMarkersService.selectMarkersByClick();
  }

  public disableSelectByClick(): void {
    if (this.selectMarkersCurrentOption.value === SelectMarkersOption.CLICK) {
      this.atlasSelectMarkersService.disableSelectMarkersByClick();
    }
  }

  public clearSelections(): void {
    this.atlasSelectMarkersService.clearSelections(this.map);
  }

  public moveSelectedMarkers(): void {
    this.zone.run(() => {
      this.dialog.open(MoveMarkersDialogComponent, {
        ...STANDARD_DIALOG_CONFIG,
        disableClose: true,
        autoFocus: false,
        width: '800px',
        data: {
          currentLayer: this.atlasSelectMarkersService.currentLayer.value,
          layers: this.atlasService.assets.filter(assets => assets.type === AssetType.GEOJSON),
          map: this.map
        }
      });
    });
  }

  public copySelectedMarkers(): void {
    this.zone.run(() => {
      this.dialog.open(MoveMarkersDialogComponent, {
        ...STANDARD_DIALOG_CONFIG,
        disableClose: true,
        autoFocus: false,
        width: '800px',
        data: {
          currentLayer: this.atlasSelectMarkersService.currentLayer.value,
          layers: this.atlasService.assets.filter(assets => assets.type === AssetType.GEOJSON),
          map: this.map,
          isCopyDialog: true,
          hasToDisableCheckbox: this.atlasSelectMarkersService.selectedMarkers.value.every(marker => {
            return !!marker.feature.properties?.state;
          })
        }
      });
    });
  }

  public setSearchResultsActive(asset: AtlasAssetModel) {
    this.assetsFilterService.setSearchResultsActive(asset);
  }

  public selectSearchedLayer(layer: any) {
    this.assetsFilterService.selectSearchedLayer(layer, this.map);
  }

  public displayLayer(asset: AtlasAssetModel) {
    this.markerClusterService.enableClustering();
    this.map.fitBounds(asset.bounds, atlasConfig.FIT_BOUNDS_OPTIONS);
    this.map.invalidateSize();
    this.assetsFilterService.displayLayer(asset);
  }

  public closeSearchSidebar(asset: AtlasAssetModel) {
    this.assetsFilterService.closeSearchSidebar(asset);
  }

  private detectLayerChanges(): void {
    this.atlasService.toogleHasToDetectChanges();
  }

  private startDrawer(): void {
    this.atlasSelectMarkersService.clearControl(this.map);
    this.atlasSelectMarkersService.startDrawing(this.map);
  }

  private openInfoSnackBar(translation: string): void {
    if (!translation) {
      return;
    }
    this.translateService
      .get(translation)
      .pipe(take(1))
      .subscribe(message => {
        this.selectMarkersSnackbar = this.snackBar.openFromComponent(AtlasSnackbarMessageComponent, {
          data: {
            message
          },
          panelClass: this.getSnackbarClass(),
          duration: 3000
        });
      });
  }

  private getSnackbarClass(): string {
    if (!this.sidebarStateService.expanded && this.sidenav.opened) {
      return 'atlas-snackbar-message--1';
    }
    if (!this.sidebarStateService.expanded && !this.sidenav.opened) {
      return 'atlas-snackbar-message--2';
    }
    if (this.sidebarStateService.expanded && this.sidenav.opened) {
      return 'atlas-snackbar-message--3';
    }
    if (this.sidebarStateService.expanded && !this.sidenav.opened) {
      return 'atlas-snackbar-message--4';
    }
    return 'atlas-snackbar-message--0';
  }

  private setCurrentSelectionMarker(newSelection: SelectMarkersOption, translation: string): void {
    if (this.selectMarkersCurrentOption.value === newSelection) {
      this.atlasSelectMarkersService.setSelectMarkersCurrentOption(SelectMarkersOption.NONE);
      this.atlasSelectMarkersService.clearControl(this.map);
      return;
    }
    this.atlasSelectMarkersService.setSelectMarkersCurrentOption(newSelection);
    this.openInfoSnackBar(translation);
  }

  private initAssetLoaders() {
    this.assetsSubscription = this.atlasService.assets$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.length === curr.length),
        filter(data => !!data.length && !this.atlasService.hasToSkipLoadAssets.value),
        map(data =>
          data
            .sort((assetA, assetB) => assetA.name.localeCompare(assetB.name))
            .filter(asset => !(asset as any)?.leafletLayer)
        )
      )
      .subscribe(assets => {
        let loadTime = Date.now();
        Promise.allSettled(
          assets.map(asset => {
            if (asset.color) {
              asset.customColorIndex = this.atlasService.availableColors.findIndex(color => color === asset.color);
            }
            return this.assetLoaderService.load(asset, this.map);
          })
        ).then(r => {
          loadTime = (Date.now() - loadTime) / 1000;
          console.info('Atlas load time', loadTime);
          // Sentry.setMeasurement('atlasLoadTime', loadTime, 'second');
          // Sentry.setMeasurement('atlasAssetsCount', assets.length, 'none');
          // Sentry.setMeasurement('atlasMarkersCount', this.assetLoaderService.totalMarkerCount, 'none');
          this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYERS_LOADED, {
            loadTime: loadTime,
            assets: assets.length,
            markers: this.assetLoaderService.totalMarkerCount
          });
          console.table(this.assetLoaderService.loadTimes);
        });
      });
  }

  private watchStreamingDevices(user: UserModel): void {
    this.liveFacadeService.startPolling();
    this.liveFacadeService.watchForStreamingDevicesChange();
    this.liveFacadeService.watchForRestreamChanges();
    this.statusService.getActiveStream(user.streamKey);
  }

  private setInitialMapCenter(user: UserModel): void {
    if (!!this.pointLatLng) {
      if (!!this.pointMarker) {
        this.map.addLayer(this.pointMarker);
      }
      this.initialCenter = this.pointLatLng;
      this.initialZoom = this.initialZoom + 1; // zoom slightly more than usual
    } else {
      const isViewerClosed = this.route.snapshot.queryParams.isViewerClosed;
      if (isViewerClosed) {
        this.atlasService.loadPreviousPositionFromLocalStorage(this.map);
        return;
      }
      if (this.atlasService.hasMapPropertiesStored()) {
        this.atlasService.loadInitialPositionFromLocalStorage(this.map);
        return;
      }
      const atlasSettings = user && user.atlas && user.atlas.settings && user.atlas.settings;
      this.initialCenter = (atlasSettings && atlasSettings.center) || this.initialCenter;
      this.initialZoom = (atlasSettings && atlasSettings.initialZoom) || this.initialZoom;
    }
    const mapCenter = latLng(this.initialCenter);
    this.map.setView(mapCenter, this.initialZoom, {
      animate: false
    } as ZoomPanOptions);
    this.atlasService.isStoredDefaultHardCodedPosition = true;
  }

  private displayWeatherMap() {
    if (this.atlasService.isWeatherMapDisplayed()) {
      this.map.addLayer(this.weatherMap);
      this.isWeatherMapDisplayed = true;
      return;
    }
    this.isWeatherMapDisplayed = false;
    this.map.removeLayer(this.weatherMap);
  }

  private changeMapView() {
    const mapView = this.atlasService.getStoredMapview();
    if (!mapView) {
      return;
    }
    this.mapView = mapView;
    this.atlasService.changeMapView(this.map, mapView, this.satelliteView, this.roadView, this.hybridView);
  }

  private initMap(): void {
    this.satelliteView = getSatelliteViewLayer();
    this.hybridView = getHybridViewLayer();
    this.roadView = getMapViewLayer();
    this.weatherMap = getMapWeatherLayer();
    this.options = {
      preferCanvas: true, // improve performance
      attributionControl: false,
      zoomControl: false, // need to turn it off and add own in another position
      zoom: this.initialZoom,
      minZoom: atlasConfig.MIN_ZOOM_LEVEL,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      maxBounds: atlasConfig.MAX_BOUNDS as LatLngBoundsExpression
    };
    this.panOptions = {
      animate: true
    };
    this.zoomOptions = {
      animate: true
    };
    this.zoomPanOptions = {
      animate: true
    };
  }

  private addScaleBar(position: ControlPosition): void {
    control.scale({position}).addTo(this.map);
  }

  private createSearchBox(position: string) {
    const searchControl = GeoSearchControl({
      position: position,
      provider: new OpenStreetMapProvider(),
      autoComplete: true,
      autoCompleteDelay: 250,
      style: 'button',
      showMarker: false,
      showPopup: false,
      popupFormat: ({query, result}) => result.label,
      maxMarkers: 1,
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: this.translations.enterAddress,
      keepResult: true,
      updateMap: true,
      resultFormat: ({result}) => result.label,
      marker: {
        draggable: false
      }
    });
    this.map.addControl(searchControl);
    this.map.on('geosearch/showlocation', (location: any) => {
      const searchInput = (document.querySelector('.geosearch input') as any)?.value;
      const [lat, lng] = searchInput.split(',').map(coord => parseFloat(coord.trim()));
      // search results clicked from dropdown
      // there are other geo search events that are not triggered by this (e.g. a search like 'sydney' will jump to location without
      // triggering callback)
      if (this.searchedPositionMarker) {
        this.map.removeLayer(this.searchedPositionMarker);
      }
      this.searchedPositionMarker =
        !isNaN(lat) && !isNaN(lng)
          ? L.marker([lat, lng]).addTo(this.map).bindPopup(`Coordinates: ${lat}, ${lng}`)
          : location.marker;
      this.atlasService.createLocationIcon(this.searchedPositionMarker);
      this.searchedPositionMarker._popup.options.closeButton = false;
      this.searchedPositionMarker.addTo(this.map);
      this.unleashAnalytics.logEvent(EVENTS.ATLAS_MAP_SEARCH);
    });
  }

  private removeMapLegend(): void {
    if (this.mapLegend) {
      this.mapLegend.remove();
    }
  }

  private addMapLegend(position: ControlPosition): void {
    const legend = L.control({position});
    legend.onAdd = (): HTMLElement => {
      const legendContent: MapLegend = MAP_LEGEND;
      this.mapLegend = L.DomUtil.create('div', 'leaflet-control-legend');
      let content: string =
        '<div class="dropdown"><input id="dropdown-button" type="checkbox"><label for="dropdown-button">Map Legend</label>';
      let list: string = '';

      Object.keys(legendContent).forEach((key: string): void => {
        list += `<p class="category">${key}</p>`;

        legendContent[key].forEach((item: MapItem): void => {
          list += `<div class="item">
            <div class="item__icon" style="background-color: ${item.color};">
              <i class="material-icons">${item.icon}</i>
            </div>
            <div class="item__content">
              <p class="item__name">${item.name}</p>
              <p class="item__description">${item.description}</p>
            </div>
          </div>`;
        });
      });

      content += `<div class="dropdown-list">${list}</div>`;
      content += '</div>';

      this.mapLegend.innerHTML = content;
      return this.mapLegend;
    };
    legend.addTo(this.map);
  }

  private addMiniMap(position: ControlPosition): void {
    if (this.miniMap) {
      return;
    }

    this.miniMap = new L.Control.MiniMap(getSatelliteViewLayer(), {
      position,
      zoomLevelOffset: -4,
      toggleDisplay: true,
      minimized: true,
      width: 186,
      height: 108
    }).addTo(this.map);
  }

  private setupContextMenu(): void {
    this.map.on('contextmenu', (e: any): void => {
      this.contextMenu.open(e.originalEvent);
      this.contextMenuData.latLng = {lat: e.latlng.lat, lng: e.latlng.lng};
      this.contextMenu.close$.pipe(take(1)).subscribe(() => (this.contextMenuData = {}));
    });
  }

  private showNewLayerNotification() {
    const isNewLayer = !!this.route.snapshot.queryParams.new;
    const id = this.route.snapshot.queryParams.id;
    let alertText = '';

    this.translateService
      .get([
        'atlas.layerAlerts.successfullyLoadedLayer',
        'atlas.layerAlerts.createdNewLayer',
        'atlas.layerAlerts.signInToLoadLinksToFusionAtlas'
      ])
      .pipe(take(1))
      .subscribe(translations => {
        const {
          'atlas.layerAlerts.successfullyLoadedLayer': successfullyLoadedLayer,
          'atlas.layerAlerts.createdNewLayer': createdNewLayer
        } = translations;

        if (id) {
          this.hasGoBack.next(true);
          const asset = this.atlasService.getAssetById(id);

          if (asset) {
            alertText = `${successfullyLoadedLayer} ${asset.name}`;

            if (isNewLayer) {
              alertText = `${createdNewLayer} ${asset.name}`;
            }

            this.snackBar.open(alertText, null, {
              duration: 5000,
              horizontalPosition: 'left'
            });
          } else {
            this.dialog.open(LayerNotFoundDialogComponent);
          }
        }
      });
  }

  private loadDeviceUrl() {
    const id = this.route.snapshot.queryParams.deviceId;
    const verifyLiveStream = this.route.snapshot.queryParams.verifyLiveStream;

    if (!id) {
      return;
    }
    this.translateService
      .get('atlas.deviceAlerts.successfullyLoadedDevice')
      .pipe(take(1))
      .subscribe(alertText => {
        this.atlasVideoComponent.toggleStream({
          id,
          alertText,
          verifyLiveStream
        });
      });
  }
}

export enum SelectMarkersOption {
  NONE,
  BOX,
  POLYGON,
  CLICK
}

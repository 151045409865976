import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'unleash-add-team-row',
  templateUrl: './add-team-row.component.html',
  styleUrls: ['./add-team-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default // to update form state from parent component
})
export class AddTeamRowComponent {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl([], Validators.required)
  });
}

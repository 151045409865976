<div class="container">
  <div class="logo">
    <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
  </div>
  <div class="title">
    <div class="title__text">
      {{ 'auth.totpToken.title' | translate }}
      <span class="title__token">
        {{ token }}
      </span>
      <button
        (click)="copyToken()"
        [matTooltip]="'auth.totpToken.copyToClipboard' | translate"
        mat-button
        color="primary"
        [cdkCopyToClipboard]="token"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="totpForm">
    <p class="verification-label">
      {{ 'auth.verification.totpVerification' | translate }}
    </p>
    <mat-form-field appearance="fill" class="code">
      <mat-label>{{ 'auth.verification.label' | translate }}</mat-label>
      <input matInput formControlName="code" />
      <mat-error *ngIf="totpForm.controls.code.hasError('required')"
        >{{ 'auth.verification.code' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
      >
      <mat-error *ngIf="totpForm.controls.code.hasError('invalidCode')">
        {{ 'auth.verification.invalidCode' | translate }}
        <a (click)="generateNewToken()" class="resend"> {{ 'auth.verification.generateNewToken' | translate }} </a>
      </mat-error>
      <mat-error *ngIf="totpForm.controls.code.hasError('maxlength')">
        {{ 'auth.verification.maxLengthValidation' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="actions">
      <button type="button" (click)="back()" mat-button color="primary">
        {{ 'auth.setupPhone.back' | translate }}
      </button>
      <button
        type="submit"
        [appLadda]="isInProgress | async"
        [disabled]="isInProgress | async"
        (click)="continue()"
        mat-flat-button
        color="primary"
      >
        {{ 'auth.totpToken.continue' | translate }}
      </button>
    </div>
  </form>
</div>

import {Injectable} from '@angular/core';
import {LinkMenuItem} from '@app/core/models/gui/link-menu-item';
import {MenuItem} from '@app/core/models/gui/menu-item';
import {setLastTopIndex, setMenuItems, setProfileMenuItem} from '@app/core/store/sidenav-menu/sidenav-menu.actions';
import {
  selectSidebarMenu,
  selectSidebarMenuLastTopIndex,
  selectSidebarMenuProfileItem
} from '@app/core/store/sidenav-menu/sidenav-menu.selectors';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavMenuFacadeService {
  menuItems$: Observable<MenuItem[]> = this.store.pipe(select(selectSidebarMenu));
  indexLastTopMenuItems$: Observable<number> = this.store.pipe(select(selectSidebarMenuLastTopIndex));
  profileMenuItem$: Observable<LinkMenuItem> = this.store.pipe(select(selectSidebarMenuProfileItem));

  constructor(private store: Store) {}

  public setMenuItems(menuItems: MenuItem[]): void {
    this.store.dispatch(setMenuItems({payload: {menuItems}}));
  }

  public setLastTopIndex(index: number): void {
    this.store.dispatch(setLastTopIndex({payload: {index}}));
  }

  public setProfileMenuItem(profileMenuItem: LinkMenuItem): void {
    this.store.dispatch(setProfileMenuItem({payload: {profileMenuItem}}));
  }
}

<div class="container">
  <div class="feature" *ngIf="plan.packageName === packagesName.insights">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="15"></mat-icon>
    </div>
    <div class="feature-name">{{ plan.planName === 'Essentials' ? 'In-built' : 'Advanced' }} A.I. Apps</div>
  </div>
  <ng-container *ngIf="plan.packageName === packagesName.modelling">
    <div class="feature">
      <div class="icon-container">
        <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="10"></mat-icon>
      </div>
      <div class="feature-name">Atlas access for geographic insights</div>
    </div>
    <div class="feature">
      <div class="icon-container">
        <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="9"></mat-icon>
      </div>
      <div class="feature-name">Generate and export 2D orthomaps</div>
    </div>
    <ng-container *ngIf="plan.planName === 'Professional'">
      <div class="feature">
        <div class="icon-container">
          <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="8"></mat-icon>
        </div>
        <div class="feature-name">Generate and export 3D models and point clouds</div>
      </div>
      <div class="feature">
        <div class="icon-container">
          <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="7"></mat-icon>
        </div>
        <div class="feature-name">Explore models with VR</div>
      </div>
    </ng-container>
  </ng-container>
  <div class="feature" *ngIf="plan.maxStreamingHours">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="4"></mat-icon>
    </div>
    <div class="feature-name">{{ plan.maxStreamingHours }} hours live streaming</div>
  </div>
  <div class="feature" *ngIf="plan.maxStorageGb">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="5"></mat-icon>
    </div>
    <div class="feature-name">{{ plan.maxStorageGb }}GB upload & storage</div>
  </div>
  <div class="feature" *ngIf="plan.packageName === packagesName.modelling && plan.planName === 'Professional'">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="11"></mat-icon>
    </div>
    <div class="feature-name">Import LIDAR data</div>
  </div>
  <div class="feature" *ngIf="plan.maxAiLiveProcessingHours">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="12"></mat-icon>
    </div>
    <div class="feature-name">{{ plan.maxAiLiveProcessingHours }} A.I. processing hours</div>
  </div>
  <div class="feature" *ngIf="plan.packageName === packagesName.insights && plan.planName === 'Professional'">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="13"></mat-icon>
    </div>
    <div class="feature-name">Real-time A.I. Insights</div>
  </div>
  <div class="feature" *ngIf="plan.maxDevices">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="3"></mat-icon>
    </div>
    <div class="feature-name">{{ plan.maxDevices }} {{ plan.maxDevices > 1 ? 'devices' : 'device' }}</div>
  </div>
  <div class="feature" *ngIf="plan.maxLiveChannels">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="2"></mat-icon>
    </div>
    <div class="feature-name">
      {{ plan.maxLiveChannels }} {{ plan.maxLiveChannels > 1 ? 'live channels' : 'live channel' }}
    </div>
  </div>
  <ng-container *ngIf="plan.packageName === packagesName.broadcast && plan.planName === 'Professional'">
    <div class="feature">
      <div class="icon-container">
        <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="1"></mat-icon>
      </div>
      <div class="feature-name">Re-streaming</div>
    </div>
    <div class="feature">
      <div class="icon-container">
        <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="0"></mat-icon>
      </div>
      <div class="feature-name">Secure link sharing</div>
    </div>
  </ng-container>
  <div class="feature" *ngIf="plan.isRestreaming">
    <div class="icon-container">
      <mat-icon [color]="isInactive ? 'gray' : 'primary'" svgIcon="6"></mat-icon>
    </div>
    <div class="feature-name">Online & in-app support</div>
  </div>
</div>

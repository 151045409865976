import {Injectable} from '@angular/core';
import {Integration} from '../models/integration.model';
import {SORT_ORDER} from '../models/paginator.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationOperationsService {
  constructor() {}

  sortReports(integrations: Integration[], sortOptions: {active: string; direction: SORT_ORDER}) {
    switch (sortOptions.active) {
      case 'lastUpdated':
        return this.numbersSort([...integrations], sortOptions.direction, sortOptions.active);
      case 'name':
      case 'integration':
        return this.stringSort([...integrations], sortOptions.direction, sortOptions.active);
      default:
        break;
    }
  }

  generateDisplayedReports(integrations: Integration[], pageSize: number, pageIndex: number): Integration[] {
    let displayedReports = [];
    if (!integrations || (integrations && integrations.length === 0)) {
      return displayedReports;
    }

    const copyReports = [...integrations];
    const emptyArrayChunks = [...Array(Math.ceil(integrations.length / pageSize))];
    const reportsChunks = emptyArrayChunks.map(_ => copyReports.splice(0, pageSize));
    displayedReports = reportsChunks[pageIndex];
    return displayedReports;
  }

  private stringSort(reports: Integration[], direction: SORT_ORDER, active: string): Integration[] {
    const reportsSorted = reports.sort((reportA, reportB) => reportA.name.localeCompare(reportB.name));
    return direction === SORT_ORDER.ASCENDENT ? reportsSorted : reportsSorted.reverse();
  }

  private numbersSort(reports: Integration[], sortDirection: SORT_ORDER, sortActive: string): Integration[] {
    const reportsSorted = reports.sort((reportA, reportB) => reportB[sortActive] - reportA[sortActive]);
    return sortDirection === SORT_ORDER.ASCENDENT ? reportsSorted : reportsSorted.reverse();
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {
  actionSignInFormError,
  actionSignInFormSubmit,
  actionSignInFormSuccess,
  actionSignInHideSignOutAlert,
  actionSignInShowSignOutAlert
} from './sign-in.actions';
import {SignInForm} from './sign-in.model';

export const signInFeatureKey = 'signIn';

export interface SignInFormState {
  form: SignInForm;
  error?: AuthErrorModel;
  inProgress: boolean;
  isSignOutAlert: boolean;
  isDeveloperMode: boolean;
}

export const initialState: SignInFormState = {
  form: {password: '', email: ''},
  inProgress: false,
  isSignOutAlert: false,
  isDeveloperMode: false
};

const reducer = createReducer(
  initialState,
  on(actionSignInFormSubmit, (state, action) => ({
    ...state,
    error: undefined,
    inProgress: true,
    form: action.form,
    isSignOutAlert: false,
    isDeveloperMode: action.isDeveloperMode
  })),
  on(actionSignInFormSuccess, (state, action) => ({
    ...state,
    error: undefined,
    inProgress: false,
    isSignOutAlert: false
  })),
  on(actionSignInFormError, (state, action) => ({
    ...state,
    error: action.error,
    inProgress: false,
    isSignOutAlert: false
  })),
  on(actionSignInShowSignOutAlert, (state, action) => ({
    ...state,
    error: undefined,
    inProgress: false,
    isSignOutAlert: true
  })),
  on(actionSignInHideSignOutAlert, (state, action) => ({
    ...state,
    isSignOutAlert: false
  }))
);

export function signInStateReducer(state: SignInFormState | undefined, action: Action): SignInFormState {
  return reducer(state, action);
}

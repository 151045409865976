import {Component, Input} from '@angular/core';
import {HelpService} from '@app/core/services/help.service';
import {NewTabMenuItem} from '../../../../models/gui/newtab-menu-item';
import {SidebarStateService} from '../../../../services/sidebar-state.service';
import {NotificationsService} from '@app/core/services/appsync-notifications/notifications.service';
import {NotificationService} from '@app/core/services/notifications/notification.service';

@Component({
  selector: 'app-sidebar-menu-item-newtab',
  templateUrl: './sidebar-menu-item-newtab.component.html',
  styleUrls: ['./sidebar-menu-item-newtab.component.scss']
})
export class SidebarMenuItemNewtabComponent {
  @Input() public menuItem: NewTabMenuItem;
  public unreadNotificationCounter$ = this.notificationsService.unreadNotifications$;

  constructor(
    public sideNavbarStateService: SidebarStateService,
    private helpService: HelpService,
    private notificationsService: NotificationsService,
    private notificationService: NotificationService
  ) {}

  public triggerAction() {
    switch (this.menuItem.label) {
      case 'core.sidebar.menu.help':
        this.goToKnowledgePage();
        break;
      case 'core.sidebar.menu.notifications':
        this.openNotificationSidebar();
        break;
      default:
        if (this.menuItem.href) {
          this.openInNewTab(this.menuItem.href);
        }
    }
  }

  public openInNewTab(href: string) {
    window.open(href, '_blank').focus();
  }

  public goToKnowledgePage() {
    this.helpService.goToKnowledgePage();
  }

  public openNotificationSidebar(): void {
    this.notificationService.openNotificationSidebar();
  }
}

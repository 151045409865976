import {NextToken} from './library-api-list-response.model';
import {SortOptions} from './sort-menu.model';

export interface LibraryFilter {
  search: string;
  dateFrom: number | string;
  hourFrom: string;
  dateTo: number | string;
  hourTo: string;
  type: string;
  device: string;
  analysis: string;
  tags: string[];
  timestamp?: number;
  filterAllLevels?: boolean;
}

export interface LibraryListQueryParams {
  location: string;
  deviceId?: string;
  name?: string;
  tags?: string[];
  type?: string;
  dateFrom?: string;
  dateTo?: string;
  sort?: SortOptions;
  limit?: number;
  nextToken?: NextToken;
}

export interface LibraryItemQueryParams {
  id: string;
}

export interface LibraryListSubfoldersQueryParams {
  currentLocation: string;
}

export interface LibraryFilterChip {
  key?: string;
  name: string;
  icon: string;
  value: string | boolean | {};
  chips?: LibraryFilterChip[];
}

export enum LibraryFilterChipIcon {
  device = 'devices',
  dateFrom = 'date',
  dateTo = 'date',
  type = 'double_camera',
  analysis = 'analysis',
  search = 'search',
  tags = 'tag',
  timestamp = 'timestamp_filter',
  filterAllLevels = 'file-tree'
}

export enum LibraryFilterChipName {
  device = 'device',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  type = 'type',
  analysis = 'analysis',
  search = 'search',
  tags = 'tags',
  timestamp = 'timestamp'
}

export enum LibraryFilterChipDisplayName {
  device = 'Device',
  dateFrom = 'From',
  dateTo = 'To',
  type = 'Type',
  analysis = 'Analysis',
  search = 'Name',
  tag = 'Tag',
  timestamp = 'Timestamp',
  filterAllLevels = 'Filter all levels'
}

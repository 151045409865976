import {Injectable} from '@angular/core';
import {init, configureScope, Breadcrumbs} from '@sentry/angular';
import {first} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserModel} from '../models/api/user-model';
import {UserService} from './api/user.service';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const {version: appVersion, name: appName} = require('../../../../package.json');

/**
 * https://sentry.io/ error tracking
 * */
@Injectable({providedIn: 'root'})
export class SentryErrorHandler {
  constructor(private userService: UserService) {
    if (!environment.production) {
      return;
    }

    init({
      dsn: 'https://e2a5533c1530427582a417d4dc4b1931@sentry.io/1400094',
      release: `${appName}@${appVersion}`,
      environment: environment.name,
      sampleRate: 1,
      integrations: [new Breadcrumbs({dom: {serializeAttribute: 'data-test-id'}})]
    });

    this.userService.user$.pipe(first()).subscribe((user: UserModel) => {
      configureScope(scope => {
        scope.setUser({
          email: user.email,
          username: user.username,
          id: user.identityId
        });
      });
    });
  }
}

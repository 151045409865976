/* eslint-disable rxjs/finnish */
import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {TeamRole} from '@app/profile/models/team.model';
import {Observable, share} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserTeamsApiService {
  constructor(private apiGateway: ApiGateway) {}

  public getAdminAccountData(): Observable<any> {
    return this.apiGateway.get(`organization`, {}, undefined).pipe(share());
  }

  public updateUserTeamRole(payload: {userId: string; role: TeamRole; teamId: string}): Observable<any> {
    return this.apiGateway
      .patch(`organization/team/${payload.teamId}/user`, null, {roles: [payload.role], userId: payload.userId})
      .pipe(share());
  }
}

export function parseQueryParams(queryParams: any): any[] {
  const attr = [];
  Object.entries(queryParams)
    .filter(([key, value]) => !(typeof key === 'string' && key.charAt(0) === '_'))
    .forEach(([key, value]) => {
      if (!value && value !== 0) {
        return;
      }
      if (key === 'sort') {
        attr.push(`${key}: ${value}`);
      } else if (Array.isArray(value)) {
        attr.push(`${key}: "${value.join(',')}"`);
      } else if (typeof value === 'string') {
        attr.push(`${key}: "${value}"`);
      } else if (key === 'nextToken') {
        attr.push(`${key}: {${parseQueryParams(value).join(',')}}`);
      } else {
        attr.push(`${key}: ${value}`);
      }
    });

  return attr;
}

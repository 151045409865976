import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'unleash-create-ai-app-dialog',
  templateUrl: './create-ai-app-dialog.component.html',
  styleUrls: ['./create-ai-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAiAppDialogComponent {
  public form = this.fb.group({name: ['', Validators.required]});

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<CreateAiAppDialogComponent>) {}

  public createAiApp(): void {
    if (this.form.controls.name.invalid) {
      this.form.controls.name.markAsTouched();
      return;
    }

    this.dialogRef.close({name: this.form.controls.name.value});
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {ApiGateway} from './api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {
  constructor(public apiGateway: ApiGateway) {}

  getWebRTCPublisherToken(deviceId: string): Observable<string> {
    return this.apiGateway.get(`streams/webrtc/pwa/${deviceId}/publisher`, {}).pipe(share());
  }

  getWebRTCSubscriberToken(deviceId: string): Observable<string> {
    return this.apiGateway.get(`streams/webrtc/pwa/${deviceId}/subscriber`, {}).pipe(share());
  }

  // to be used when pwa and drone streaming are separated into different agora apps
  getWebRTCTokenSubscriberDrone(deviceId: string): Observable<string> {
    return this.apiGateway.get(`streams/webrtc/drone/${deviceId}/subscriber`, {}).pipe(share());
  }
}

export interface RuleItem {
  id: string;
  name: string;
  enabled: boolean;
}

export enum RuleType {
  delete = 'delete',
  disable = 'disable',
  enable = 'enable'
}

export interface RuleMenuOption {
  name: string;
  type: RuleType;
  icon: string;
  color: string;
}

export interface RuleAction {
  ruleId: string;
  action: RuleType;
}

export interface RuleCondition {
  id: string;
  name: string;
}

export interface RuleOperator {
  id: string;
  name: string;
}

export interface RuleAreaOfInterest {
  id: string;
  display_name: string;
}

export interface RuleAlert {
  id: string;
  description: string;
  icon: string;
  name: string;
}

export interface Rule {
  id: string;
  ruleName: {name: string};
  enabled: boolean;
  advancedMode: boolean;
  rules: NestedRule[];
  settings: {alert: string; coolDownPeriod: number; title: string; message: string};
  channels: {email: boolean; sms: boolean; support: boolean; app: boolean};
}

export interface NestedRule {
  zone: string;
  logicOperator: string;
  condition?: string;
  operator?: string;
  value?: string;
}

export interface CoolDownPeriod {
  name: string;
  value: number;
}

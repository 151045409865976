import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@NgModule({
  imports: [HttpClientModule]
})
export class MatIconRegistryModule {
  private readonly ICONS = [
    {name: 'isRead', path: 'assets/icons/isRead-notification.svg'},
    {name: 'model_white', path: 'assets/icons/model_white.svg'},
    {name: 'model_black', path: 'assets/icons/model_black.svg'},
    {
      name: 'settings',
      path: 'assets/icons/live-stream/device-table/settings.svg'
    },
    {
      name: 'create_model_blue',
      path: 'assets/icons/notifications/create-model-blue.svg'
    },
    {
      name: 'analysing_media_blue',
      path: 'assets/icons/notifications/analysing-media-blue.svg'
    },
    {
      name: 'open-notifications',
      path: 'assets/icons/notifications/open-notifications.svg'
    },
    {name: 'autodesk', path: 'assets/icons/autodesk-icon.svg'},
    {name: 'creative-cloud', path: 'assets/icons/creative-cloud-icon.svg'},
    {name: 'drone', path: 'assets/icons/drone-icon.svg'},
    {name: 'quadcopter', path: 'assets/icons/quadcopter.svg'},
    {name: 'ai', path: 'assets/icons/ai-icon.svg'},
    {name: 'dash-library', path: 'assets/icons/menu/library.svg'},
    {name: 'dash-ai-store', path: 'assets/icons/menu/ai-apps.svg'},
    {
      name: 'dash-mission-planning',
      path: 'assets/icons/menu/mission-control.svg'
    },
    {name: 'dash-account', path: 'assets/icons/menu/account.svg'},
    {name: 'logout-nav', path: 'assets/icons/menu/logout.svg'},
    {name: 'umbrella', path: 'assets/icons/umbrella.svg'},
    {name: 'wb_sunny', path: 'assets/icons/wb_sunny.svg'},
    {name: 'status', path: 'assets/icons/profile/status.svg'},
    {
      name: 'code-greater-than-or-equal',
      path: 'assets/icons/developer-dashboard/code-greater-than-or-equal.svg'
    },
    {
      name: 'file-document',
      path: 'assets/icons/developer-dashboard/file-document.svg'
    },
    {
      name: 'file-info',
      path: 'assets/icons/library/file-info.svg'
    },
    {
      name: 'payment-and-publisher-details',
      path: 'assets/icons/developer-dashboard/payment-and-publisher-details.svg'
    },
    {
      name: 'request-payment',
      path: 'assets/icons/developer-dashboard/request-payment.svg'
    },
    {
      name: 'un-publish',
      path: 'assets/icons/developer-dashboard/un-publish.svg'
    },
    {name: 'ai-model', path: 'assets/icons/developer-dashboard/ai-model.svg'},
    {
      name: 'ai-model-info',
      path: 'assets/icons/developer-dashboard/ai-model-info.svg'
    },
    {
      name: 'app-details',
      path: 'assets/icons/developer-dashboard/app-details.svg'
    },
    {
      name: 'configure-settings',
      path: 'assets/icons/developer-dashboard/configure-settings.svg'
    },
    {
      name: 'create-listing',
      path: 'assets/icons/developer-dashboard/create-listing.svg'
    },
    {
      name: 'publish-ai-app',
      path: 'assets/icons/developer-dashboard/publish-ai-app.svg'
    },
    {
      name: 'publish-app-info',
      path: 'assets/icons/developer-dashboard/publish-app-info.svg'
    },
    {
      name: 'publish-app',
      path: 'assets/icons/developer-dashboard/publish-app.svg'
    },
    {
      name: 'submit-for-review',
      path: 'assets/icons/developer-dashboard/submit-for-review.svg'
    },
    {
      name: 'submit-for-review-info',
      path: 'assets/icons/developer-dashboard/submit-for-review-info.svg'
    },
    {name: 'upload', path: 'assets/icons/developer-dashboard/upload.svg'},
    {name: '0', path: 'assets/icons/plans/0.svg'},
    {name: '1', path: 'assets/icons/plans/1.svg'},
    {name: '2', path: 'assets/icons/plans/2.svg'},
    {name: '3', path: 'assets/icons/plans/3.svg'},
    {name: '4', path: 'assets/icons/plans/4.svg'},
    {name: '5', path: 'assets/icons/plans/5.svg'},
    {name: '6', path: 'assets/icons/plans/6.svg'},
    {name: '7', path: 'assets/icons/plans/7.svg'},
    {name: '8', path: 'assets/icons/plans/8.svg'},
    {name: '9', path: 'assets/icons/plans/9.svg'},
    {name: '10', path: 'assets/icons/plans/10.svg'},
    {name: '11', path: 'assets/icons/plans/11.svg'},
    {name: '12', path: 'assets/icons/plans/12.svg'},
    {name: '13', path: 'assets/icons/plans/13.svg'},
    {name: '14', path: 'assets/icons/plans/14.svg'},
    {name: '15', path: 'assets/icons/plans/15.svg'},
    {name: '16', path: 'assets/icons/plans/16.svg'},
    {name: 'logout', path: 'assets/icons/logout.svg'},
    {
      name: 'folder_info',
      path: 'assets/icons/image-annotation/folder_info.svg'
    },
    {
      name: 'multiple_annotations',
      path: 'assets/icons/image-annotation/multiple_annotations.svg'
    },
    {
      name: 'polygon_selector',
      path: 'assets/icons/image-annotation/polygon_selector.svg'
    },
    {
      name: 'rectangle_selector',
      path: 'assets/icons/image-annotation/rectangle_selector.svg'
    },
    {name: 'ai_badge', path: 'assets/icons/ai_badge.svg'},
    {
      name: 'image_outline',
      path: '/assets/icons/image-annotation/image_outline.svg'
    },
    {name: 'plugged', path: '/assets/icons/remote-cockpit/plugged.svg'},
    {name: 'stop_circle', path: '/assets/icons/remote-cockpit/stop-circle.svg'},
    {
      name: 'arrow_expand',
      path: '/assets/icons/remote-cockpit/arrow-expand.svg'
    },
    {
      name: 'arrow_collapse',
      path: '/assets/icons/remote-cockpit/arrow-collapse.svg'
    },
    {name: 'camera_flip', path: '/assets/icons/remote-cockpit/camera-flip.svg'},
    {
      name: 'chev_double_down',
      path: '/assets/icons/remote-cockpit/chev-double-down.svg'
    },
    {
      name: 'chev_double_up',
      path: '/assets/icons/remote-cockpit/chev-double-up.svg'
    },
    {name: 'chev_down', path: '/assets/icons/remote-cockpit/chev-down.svg'},
    {name: 'chev_left', path: '/assets/icons/remote-cockpit/chev-left.svg'},
    {name: 'chev_right', path: '/assets/icons/remote-cockpit/chev-right.svg'},
    {name: 'chev_up', path: '/assets/icons/remote-cockpit/chev-up.svg'},
    {
      name: 'fil_center_focus',
      path: '/assets/icons/remote-cockpit/fil-center-focus.svg'
    },
    {name: 'rot_left', path: '/assets/icons/remote-cockpit/rot_left.svg'},
    {name: 'rot_right', path: '/assets/icons/remote-cockpit/rot_right.svg'},
    {
      name: 'zoom_in_community',
      path: '/assets/icons/remote-cockpit/zoom-in.svg'
    },
    {
      name: 'zoom_out_community',
      path: '/assets/icons/remote-cockpit/zoom-out.svg'
    },
    {name: 'take_off', path: '/assets/icons/remote-cockpit/take-off.svg'},
    {
      name: 'take_in_place',
      path: '/assets/icons/remote-cockpit/land-in-place.svg'
    },
    {
      name: 'take_in_place_white',
      path: '/assets/icons/remote-cockpit/land-in-place-white.svg'
    },
    {
      name: 'double_check_community',
      path: '/assets/icons/remote-cockpit/double-check-community.svg'
    },
    {
      name: 'home_community',
      path: '/assets/icons/remote-cockpit/home_community.svg'
    },
    {
      name: 'home_community_white',
      path: '/assets/icons/remote-cockpit/home_community_white.svg'
    },
    {
      name: 'playlist_add',
      path: '/assets/icons/remote-cockpit/playlist-add.svg'
    },
    {
      name: 'camera_enhance_community',
      path: '/assets/icons/remote-cockpit/camera-enhance-community.svg'
    },
    {name: 'cctv', path: '/assets/icons/remote-cockpit/cctv.svg'},
    {name: 'quadcopter', path: '/assets/icons/remote-cockpit/quadcopter.svg'},
    {name: 'drone', path: '/assets/icons/remote-cockpit/drone.svg'},
    {
      name: 'network_wifi_warning',
      path: '/assets/icons/remote-cockpit/network_wifi_warning.svg'
    },
    {
      name: 'map_subtract',
      path: '/assets/icons/remote-cockpit/map_subtract.svg'
    },
    {name: 'mic_off', path: '/assets/icons/remote-cockpit/mic-off.svg'},
    {name: 'mic_on', path: '/assets/icons/remote-cockpit/mic-on.svg'},
    {name: 'camera_off', path: '/assets/icons/remote-cockpit/camera-off.svg'},
    {name: 'leading', path: '/assets/icons/remote-cockpit/leading.svg'},
    {name: 'leading-white', path: '/assets/icons/remote-cockpit/leading-white.svg'},
    {
      name: 'take_in_place_disabled',
      path: '/assets/icons/remote-cockpit/land-in-place-disabled.svg'
    },
    {
      name: 'camera_control_disabled',
      path: '/assets/icons/remote-cockpit/camera_control_disabled.svg'
    },
    {
      name: 'map_marker_path_disabled',
      path: '/assets/icons/remote-cockpit/map_marker_path_disabled.svg'
    },
    {name: 'down', path: '/assets/icons/remote-cockpit/down.svg'},
    {name: 'up', path: '/assets/icons/remote-cockpit/up.svg'},
    {
      name: 'push_to_talk',
      path: '/assets/icons/remote-cockpit/push-to-talk.svg'
    },
    {
      name: 'shutter_trigger',
      path: '/assets/icons/remote-cockpit/shutter-trigger.svg'
    },
    {
      name: 'smart_inspect',
      path: '/assets/icons/remote-cockpit/smart-inspect.svg'
    },
    {
      name: 'stream_start',
      path: '/assets/icons/remote-cockpit/stream-start.svg'
    },
    {name: 'stream_stop', path: '/assets/icons/remote-cockpit/stream-stop.svg'},
    {name: 'toggle_mic', path: '/assets/icons/remote-cockpit/toggle-mic.svg'},
    {
      name: 'pause_mission',
      path: '/assets/icons/remote-cockpit/pause-mission.svg'
    },
    {
      name: 'gimbal_center',
      path: '/assets/icons/remote-cockpit/gimbal-center.svg'
    },
    {name: 'uhd', path: '/assets/icons/library/ultra-high-definition.svg'},
    {
      name: 'earth_arrow_right',
      path: '/assets/icons/library/earth-arrow-right.svg'
    },
    {name: 'u_analyze', path: '/assets/icons/library/analyze.svg'},
    {name: 'u_analyze_white', path: '/assets/icons/library/analyze-white.svg'},
    {name: 'u_analyze_black', path: '/assets/icons/library/analyze-black.svg'},
    {name: 'u_macos', path: '/assets/icons/developer/apple.svg'},
    {name: 'u_ubuntu', path: '/assets/icons/developer/ubuntu.svg'},
    {name: 'u_windows', path: '/assets/icons/developer/microsoft-windows.svg'},
    {name: 'double_camera', path: '/assets/icons/library/double_camera.svg'},
    {name: 'devices', path: '/assets/icons/library/camera.svg'},
    {name: 'analysis', path: '/assets/icons/library/analysis.svg'},
    {name: 'date', path: '/assets/icons/library/date.svg'},
    {name: 'search', path: '/assets/icons/library/search.svg'},
    {name: 'sorting_az', path: '/assets/icons/library/sortingAZ.svg'},
    {name: 'sorting_newest', path: '/assets/icons/library/sortingNewest.svg'},
    {
      name: 'arrow_drop_down',
      path: '/assets/icons/library/arrow_drop_down.svg'
    },
    {name: 'new_folder', path: '/assets/icons/library/new_folder.svg'},
    {name: 'u_move', path: '/assets/icons/library/move.svg'},
    {name: 'u_move_white', path: '/assets/icons/library/move-white.svg'},
    {name: 'u_move_black', path: '/assets/icons/library/move-black.svg'},
    {name: 'u_from', path: '/assets/icons/analysis/from.svg'},
    {name: 'u_add_shape', path: '/assets/icons/image-annotation/add_shape.svg'},
    {name: 'u_undo', path: '/assets/icons/image-annotation/undo_24px.svg'},
    {name: 'u_redo', path: '/assets/icons/image-annotation/redo.svg'},
    {
      name: 'u_finish_shape',
      path: '/assets/icons/image-annotation/finish_shape.svg'
    },
    {name: 'u_save', path: '/assets/icons/image-annotation/save_24px.svg'},
    {
      name: 'u_zoom_in',
      path: '/assets/icons/image-annotation/zoom_in_24px.svg'
    },
    {
      name: 'u_zoom_out',
      path: '/assets/icons/image-annotation/zoom_out_24px.svg'
    },
    {name: 'u_pan', path: '/assets/icons/image-annotation/pan.svg'},
    {name: 'u_delete', path: '/assets/icons/image-annotation/delete_24px.svg'},
    {
      name: 'u_arrow_back',
      path: '/assets/icons/image-annotation/arrow_back_24px.svg'
    },
    {name: 'chart_scatter_plot', path: '/assets/icons/chart-scatter-plot.svg'},
    {name: 'center_focus', path: '/assets/icons/center-focus.svg'},
    {name: 'chat', path: '/assets/icons/chat.svg'},
    {name: 'angle', path: '/assets/icons/developer-store/angle.svg'},
    {name: 'distance', path: '/assets/icons/developer-store/distance.svg'},
    {name: 'lighting', path: '/assets/icons/developer-store/lighting.svg'},
    {name: 'movie_open', path: '/assets/icons/streams/movie_open.svg'},
    {name: 'upload', path: '/assets/icons/streams/upload.svg'},
    {name: 'copy', path: '/assets/icons/streams/copy.svg'},
    {name: 'streaming', path: '/assets/icons/streams/streaming.svg'},
    {name: 'secure-proxy', path: '/assets/icons/streams/secure-proxy.svg'},
    {name: 'cash', path: '/assets/icons/auth/cash.svg'},
    {name: 'review', path: '/assets/icons/auth/review.svg'},
    {name: 'u_upload_library', path: '/assets/icons/library/u_upload_library.svg'},
    {name: 'model', path: '/assets/icons/library/model.svg'},
    {name: 'export', path: '/assets/icons/library/export.svg'},
    {name: 'export-white', path: '/assets/icons/library/export-white.svg'},
    {name: 'flight_log', path: '/assets/icons/library/flight_log.svg'},
    {
      name: 'information_variant',
      path: '/assets/icons/atlas/information_variant.svg'
    },
    {
      name: 'add-group',
      path: '/assets/icons/atlas/add-group.svg'
    },
    {
      name: 'compare',
      path: '/assets/icons/atlas/compare.svg'
    },
    {
      name: 'visible_layer',
      path: '/assets/icons/atlas/visible-layer.svg'
    },
    {
      name: 'folder-open',
      path: '/assets/icons/atlas/folder-open.svg'
    },
    {
      name: 'nested-layer',
      path: '/assets/icons/atlas/nested-layer.svg'
    },
    {
      name: 'export-layer',
      path: '/assets/icons/atlas/export-layer.svg'
    },
    {
      name: 'rename',
      path: '/assets/icons/atlas/rename.svg'
    },
    {
      name: 'add',
      path: '/assets/icons/atlas/add.svg'
    },
    {
      name: 'user',
      path: '/assets/icons/atlas/user.svg'
    },
    {name: 'bar_chart', path: '/assets/icons/atlas/bar_chart.svg'},
    {name: 'check-circle', path: '/assets/icons/library/check-circle.svg'},
    {name: 'coco-json', path: '/assets/icons/library/coco-json.svg'},
    {name: 'csv', path: '/assets/icons/library/csv.svg'},
    {name: 'geojson', path: '/assets/icons/library/geojson.svg'},
    {name: 'geojson_gray', path: '/assets/icons/library/geojson_gray.svg'},
    {name: 'pdf', path: '/assets/icons/library/pdf.svg'},
    {name: 'tag', path: '/assets/icons/library/tag.svg'},
    {name: 'tag-delete', path: '/assets/icons/library/tag-delete.svg'},
    {name: 'tag-remove', path: '/assets/icons/library/tag-remove.svg'},
    {name: 'no-tag', path: '/assets/icons/library/no-tag.svg'},
    {name: 'error-mark', path: '/assets/icons/library/error-mark.svg'},
    {name: 'label-off', path: '/assets/icons/library/label-off.svg'},
    {name: 'home_map_marker', path: '/assets/icons/atlas/home-map-marker.svg'},
    {name: 'go_to_default', path: '/assets/icons/atlas/go-to-default.svg'},
    {name: 'export-atlas', path: '/assets/icons/atlas/export-atlas.svg'},
    {name: 'movable-divider', path: '/assets/icons/atlas/movable-divider.svg'},
    {name: 'circle', path: '/assets/icons/atlas/circle.svg'},
    {
      name: 'set_default_view',
      path: '/assets/icons/atlas/set-default-view.svg'
    },
    {name: 'u_ruler', path: '/assets/icons/image-annotation/ruler.svg'},
    {
      name: 'u-no-active-ai-apps',
      path: '/assets/icons/image-viewer/no-active-ai-apps.svg'
    },
    {
      name: 'u-no-plan-active',
      path: '/assets/icons/image-viewer/no-plan-active.svg'
    },
    {
      name: 'analysis-report',
      path: '/assets/icons/analysis/analysis_report.svg'
    },
    {
      name: 'pdf-report',
      path: '/assets/icons/analysis/pdf_report.svg'
    },
    {
      name: 'clear-filters',
      path: '/assets/icons/library/clear-filters.svg'
    },
    {
      name: 'double-camera',
      path: '/assets/icons/library/double_camera.svg'
    },
    {name: 'summarize', path: '/assets/icons/library/summarize.svg'},
    {name: 'summarize-white', path: '/assets/icons/library/summarize-white.svg'},
    {name: 'summarize-black', path: '/assets/icons/library/summarize-black.svg'},
    {name: 'create-model', path: '/assets/icons/library/create-model.svg'},
    {name: 'export-csv', path: '/assets/icons/annotations-summary/export-csv.svg'},
    {name: 'export-coco-json', path: '/assets/icons/annotations-summary/export-coco-json.svg'},
    {name: 'annotation-severity', path: '/assets/icons/annotations-summary/annotation-severity.svg'},
    {name: 'annotation-severity-white', path: '/assets/icons/annotations-summary/annotation-severity-white.svg'},
    {name: 'check-all', path: '/assets/icons/annotations-summary/check-all.svg'},
    {name: 'hide-all', path: '/assets/icons/annotations-summary/hide-all.svg'},
    {
      name: 'pano-image',
      path: '/assets/icons/library/pano-image.svg'
    },
    {
      name: 'show-detail',
      path: '/assets/icons/library/show_detail.svg'
    },
    {
      name: 'hide-detail',
      path: '/assets/icons/library/hide_detail.svg'
    },
    {
      name: 'videocam_on',
      path: '/assets/icons/atlas/videocam.svg'
    },
    {
      name: 'videocam_off',
      path: '/assets/icons/atlas/videocam_off.svg'
    },
    {name: 'search-layer', path: '/assets/icons/atlas/search-layer.svg'},
    {
      name: 'annotation-area',
      path: '/assets/icons/annotations-summary/area.svg'
    },
    {
      name: 'export-pdf',
      path: '/assets/icons/annotations-summary/export-pdf.svg'
    },
    {name: 'export-pdf-white', path: '/assets/icons/annotations-summary/export-pdf-white.svg'},
    {
      name: 'info',
      path: '/assets/icons/annotations-summary/info.svg'
    },
    {
      name: 'u_magic_click',
      path: '/assets/icons/image-viewer/u_magic_click.svg'
    },
    {
      name: 'u_magic_box',
      path: '/assets/icons/image-viewer/u_magic_box.svg'
    },
    {
      name: 'u_magic_everything',
      path: '/assets/icons/image-viewer/u_magic_everything.svg'
    },
    {
      name: 'u_magic_add',
      path: '/assets/icons/image-viewer/u_magic_add.svg'
    },
    {
      name: 'u_magic_remove',
      path: '/assets/icons/image-viewer/u_magic_remove.svg'
    },
    {
      name: 'upload-flight-log',
      path: '/assets/icons/flight-log/upload.svg'
    },
    {
      name: 'no-missions',
      path: '/assets/icons/atlas/no-missions.svg'
    },
    {
      name: 'corridor-mission',
      path: '/assets/icons/mission/corridor.svg'
    },
    {
      name: 'waypoint-mission',
      path: '/assets/icons/mission/waypoint.svg'
    },
    {
      name: 'mapping2d-mission',
      path: '/assets/icons/mission/mapping2d-mission.svg'
    },
    {
      name: 'pilot-mission',
      path: '/assets/icons/mission/pilot.svg'
    },
    {
      name: 'smart-inspect-mission',
      path: '/assets/icons/mission/smart.svg'
    },
    {
      name: 'drone-harmony-mission', //todo provide
      path: '/assets/icons/mission/drone-harmony.svg'
    },
    {
      name: 'analytics',
      path: '/assets/icons/library/analytics.svg'
    },
    {
      name: 'media-drive-menu',
      path: '/assets/icons/menu/media-drive.svg'
    },
    {
      name: 'developer-dashboard',
      path: '/assets/icons/menu/code.svg'
    },
    {
      name: 'stream-menu',
      path: '/assets/icons/menu/stream.svg'
    },
    {
      name: 'atlas-menu',
      path: '/assets/icons/menu/atlas.svg'
    },
    {
      name: 'analysis-menu',
      path: '/assets/icons/menu/analysis.svg'
    },
    {
      name: 'help-menu',
      path: '/assets/icons/menu/help.svg'
    },
    {
      name: 'jobs',
      path: '/assets/icons/menu/jobs.svg'
    },
    {
      name: 'data-menu',
      path: '/assets/icons/data/poll.svg'
    },
    {
      name: 'notifications',
      path: '/assets/icons/menu/notifications.svg'
    },
    {
      name: 'no-teams',
      path: '/assets/icons/menu/no-teams.svg'
    },
    {
      name: 'u_color_picker',
      path: 'assets/icons/u_color_picker.svg'
    },
    {
      name: 'u_edit_zone',
      path: 'assets/icons/u_edit_zone.svg'
    },
    {
      name: 'u_leading',
      path: 'assets/icons/u_leading.svg'
    },
    {
      name: 'u_trailing',
      path: 'assets/icons/u_trailing.svg'
    },
    {
      name: 'u_api_docs',
      path: 'assets/icons/analysis/u_api_docs.svg'
    },
    {
      name: 'rename-mission',
      path: '/assets/icons/mission/rename-mission.svg'
    },
    {
      name: 'upload-mission',
      path: '/assets/icons/mission/upload-mission.svg'
    },
    {
      name: 'mission-format-list',
      path: '/assets/icons/mission/mission-format-list.svg'
    },
    {
      name: 'drag-drop',
      path: '/assets/icons/mission/drag-drop.svg'
    },
    {
      name: 'no-waypoints',
      path: '/assets/icons/mission/no-waypoints.svg'
    },
    {
      name: 'restore',
      path: '/assets/icons/mission/restore.svg'
    },
    {
      name: 'undo',
      path: '/assets/icons/mission/undo.svg'
    },
    {
      name: 'redo',
      path: '/assets/icons/mission/redo.svg'
    },
    {
      name: 'save-changes',
      path: '/assets/icons/mission/save-changes.svg'
    },
    {
      name: 'altitude-waypoint',
      path: '/assets/icons/mission/height.svg'
    },
    {
      name: 'heading-waypoint',
      path: '/assets/icons/mission/heading.svg'
    },
    {
      name: 'speed-waypoint',
      path: '/assets/icons/mission/speed.svg'
    },
    {
      name: 'load-mission',
      path: '/assets/icons/remote-cockpit/load.svg'
    },
    {
      name: 'remote-cockpit-plan',
      path: '/assets/icons/remote-cockpit/plan.svg'
    },
    {
      name: 'remote-cockpit-mission',
      path: '/assets/icons/remote-cockpit/mission-menu.svg'
    },
    {
      name: 'dock-green',
      path: '/assets/icons/remote-cockpit/dock-green.svg'
    },
    {
      name: 'dock-red',
      path: '/assets/icons/remote-cockpit/dock-red.svg'
    },
    {
      name: 'dock-yellow',
      path: '/assets/icons/remote-cockpit/dock-yellow.svg'
    },
    {
      name: 'drone-red',
      path: '/assets/icons/remote-cockpit/drone-red.svg'
    },
    {
      name: 'drone-green',
      path: '/assets/icons/remote-cockpit/drone-green.svg'
    },
    {
      name: 'drone-yellow',
      path: '/assets/icons/remote-cockpit/drone-yellow.svg'
    },
    {
      name: 'access-camera',
      path: '/assets/icons/remote-cockpit/camera.svg'
    },
    {
      name: 'access-broadcast',
      path: '/assets/icons/remote-cockpit/broadcast.svg'
    },
    {
      name: 'radio-tower',
      path: '/assets/icons/remote-cockpit/radio-tower.svg'
    },
    {
      name: 'reload-autofly',
      path: '/assets/icons/remote-cockpit/reload-autofly.svg'
    },
    {
      name: 'u-preview-3d',
      path: 'assets/icons/mission/preview-3d.svg'
    },
    {
      name: 'select-markers',
      path: '/assets/icons/atlas/select-markers.svg'
    },
    {
      name: 'atlas-box',
      path: '/assets/icons/atlas/box.svg'
    },
    {
      name: 'atlas-polygon',
      path: '/assets/icons/atlas/polygon.svg'
    },
    {
      name: 'atlas-click',
      path: '/assets/icons/atlas/click.svg'
    },
    {
      name: 'move-selected-markers',
      path: '/assets/icons/atlas/move-selected-markers.svg'
    },
    {
      name: 'copy-selected-markers',
      path: '/assets/icons/atlas/copy-selected-markers.svg'
    },
    {
      name: 'clear-selection',
      path: '/assets/icons/atlas/clear-selection.svg'
    },
    {
      name: 'u_file_link',
      path: '/assets/icons/library/file-link.svg'
    },
    {
      name: 'u_file_link_grey',
      path: '/assets/icons/library/file-link-grey.svg'
    },
    {
      name: 'custom-layer',
      path: '/assets/icons/atlas/custom-layer.svg'
    },
    {
      name: 'generic_folder',
      path: '/assets/icons/atlas/generic_folder.svg'
    },
    {
      name: 'in-progress-marker',
      path: '/assets/icons/atlas/in-progress-marker.svg'
    },
    {
      name: 'complete-marker',
      path: '/assets/icons/atlas/complete-marker.svg'
    },
    {
      name: 'incomplete-marker',
      path: '/assets/icons/atlas/incomplete-marker.svg'
    },
    {
      name: 'u_upload_file',
      path: '/assets/icons/u_upload_file.svg'
    },
    {
      name: 'u_upload_folder',
      path: '/assets/icons/u_upload_folder.svg'
    },
    {
      name: 'u_import_from_google_drive',
      path: '/assets/icons/u_import_from_google_drive.svg'
    },
    {
      name: 'u_import_from_s3',
      path: '/assets/icons/u_import_from_s3.svg'
    },
    {
      name: 'u_import_from_skydio',
      path: '/assets/icons/u_import_from_skydio.svg'
    },
    {
      name: 'u_leading_analytics',
      path: '/assets/icons/library/u_leading_analytics.svg'
    },
    {
      name: 'u_calibrate_by_perspective',
      path: '/assets/icons/manager-zones/calibration/calibrate_by_perspective.svg'
    },
    {
      name: 'u_calibrate_by_scene_mapping',
      path: '/assets/icons/manager-zones/calibration/calibrate_by_scene_mapping.svg'
    },
    {
      name: 'start_streaming',
      path: 'assets/icons/live-stream/start_streaming.svg'
    },
    {name: 'link', path: '/assets/icons/live-stream/link.svg'},
    {name: 'lock_plus', path: '/assets/icons/live-stream/lock_plus.svg'},
    {name: 'stream_mobile_device', path: '/assets/icons/live-stream/stream_mobile_device.svg'},
    {name: 'no_device_filter', path: '/assets/icons/library/no-device-filter.svg'},
    {name: 'timestamp_filter', path: '/assets/icons/library/timestamp-filter.svg'},
    {name: 'file-tree', path: '/assets/icons/library/file-tree.svg'},
    {name: 'view_grid', path: '/assets/icons/library/view_grid.svg'},
    {name: 'import_library', path: '/assets/icons/library/import.svg'},
    {name: 'file_upload', path: '/assets/icons/library/file-upload.svg'},
    {name: 'remote-controller', path: '/assets/icons/remote-cockpit/remote-controller.svg'},
    {name: 'u_convert_layer_to_mission', path: '/assets/icons/atlas/convert-layer-to-mission.svg'},
    {name: 'job_created', path: '/assets/icons/jobs/job-created.svg'},
    {name: 'task_created', path: '/assets/icons/jobs/task-created.svg'},
    {name: 'no_complete_jobs', path: '/assets/icons/jobs/no-complete-jobs.svg'},
    {name: 'no_failed_jobs', path: '/assets/icons/jobs/no-failed-jobs.svg'},
    {name: 'no_jobs', path: '/assets/icons/jobs/no-jobs.svg'},
    {name: 'failed_task', path: '/assets/icons/jobs/failed-task.svg'},
    {name: 'no_job', path: '/assets/icons/jobs/no-job.svg'},
    {name: 'rename_job', path: '/assets/icons/jobs/rename-job.svg'},
    {name: 'change_status', path: '/assets/icons/jobs/change-status.svg'},
    {name: 'empty-tasks', path: '/assets/icons/jobs/empty-tasks.svg'},
    {name: 'empty_layers', path: '/assets/icons/library/empty-layers.svg'}
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.registerIcons();
  }

  private registerIcons() {
    this.ICONS.forEach(icon =>
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path))
    );
  }
}

/* eslint-disable rxjs/finnish */
import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {NotificationLayout, NotificationModel} from '@app/core/models/api/notifications.model';
import {actionSetNotificationAsRead} from '@app/core/notifications/notifications.actions';
import {NotificationsService} from '@app/core/services/appsync-notifications/notifications.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {Observable, map} from 'rxjs';
import {
  selectNotificationsInProgress,
  selectNotificationsNotInProgress
} from '../../notifications/notifications.selectors';
import {MatTabGroup} from '@angular/material/tabs';
import {NotificationService} from '@app/core/services/notifications/notification.service';
import {MatDialogRef} from '@angular/material/dialog';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {Team} from '@app/profile/models/team.model';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSidebarComponent implements OnInit {
  @ViewChild(MatTabGroup, {read: MatTabGroup, static: true})
  public matTabGroup: MatTabGroup;
  public notificationLayout = NotificationLayout;
  public notifications$: Observable<NotificationModel[]> = null;
  public notificationsInProgress$: Observable<NotificationModel[]> = null;
  public currentTeamName$: Observable<string> = this.userStoreFacadeService.currentTeam$.pipe(
    map((team: Team) => {
      return team.name;
    })
  );

  constructor(
    public store: Store,
    public notificationService: NotificationService,
    public notificationsService: NotificationsService,
    private unleashAnalyticsService: UnleashAnalyticsService,
    public dialogRef: MatDialogRef<NotificationSidebarComponent>,
    private userStoreFacadeService: UserStoreFacadeService
  ) {
    this.notifications$ = this.store.pipe(select(selectNotificationsNotInProgress));
    this.notificationsInProgress$ = this.store.pipe(select(selectNotificationsInProgress));
  }

  public ngOnInit(): void {
    this.notificationService.sidebarMatGroup = this.matTabGroup;
  }

  public markAllNotificationsAsSeen(): void {
    this.notificationsService.markAllNotificationsAsSeen();
  }

  public deleteNotification(notification: NotificationModel): void {
    this.notificationsService.deleteNotification(notification);
  }

  public getNextToken$(): void {
    this.notificationsService.getNextToken$();
  }

  public showMoreNotifications(): void {
    this.notificationsService.showMoreNotifications();
  }

  public updateNotificationCounter(notification: NotificationModel): void {
    this.store.dispatch(actionSetNotificationAsRead({payload: notification}));
  }

  public closeSidebar(): void {
    this.dialogRef.close();
  }

  public trackByNotificationCreatedAt(index, notification: NotificationModel): number {
    // use createdAt, because updatedAt is not available for new items
    return notification.createdAt;
  }

  public triggerEventTracking(action: string): void {
    this.unleashAnalyticsService.logEvent(EVENTS.PRESS_NOTIFICATION_ACTION_BUTTON, {actionName: action});
  }
}

import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserModel} from '@app/core/models/api/user-model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {UserService} from '@app/core/services/api/user.service';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {LibraryLayout} from '@app/library/models/library-layout.model';
import {UPLOAD_TYPES} from '@app/library/models/upload-types.model';
import {UploadFilesFacadeService} from '@app/shared/services/upload/upload-files-facade.service';
import {Addon} from '@app/store/addon/models/addon';
import {LatLngBoundsExpression} from 'leaflet';
import {orderBy} from 'lodash';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';
import {ActionMenu, actionMenuItem, MenuActions, MenuActionType} from '../models/action-menu.model';
import {LibraryItem, LibraryViewMode} from '../models/folder-file.model';
import {NextToken} from '../models/library-api-list-response.model';
import {LibraryFilter, LibraryFilterChip, LibraryListQueryParams} from '../models/library-filter.model';
import {LibraryMimeType} from '../models/mime-type.model';
import {ModelData} from '../models/models.model';
import {SortOptions} from '../models/sort-menu.model';
import {Tag} from '../models/tag.model';
import {LibraryFilterService} from './library-filter.service';
import {LibraryStoreFacadeService} from './library-store-facade.service';
import {ModelMenuService} from './model-menu.service';
import {AclService} from '@app/core/services/acl.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {DownloadAnalyticsService} from './download-analytics.service';
import {DatePriority} from '../components/filters/models/filters.model';
import {RouterFacadeStoreService} from '@app/core/services/api/router-store-facade.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryPageService {
  public libraryItems$: Observable<LibraryItem[]>;
  public isLoading$: Observable<boolean>;
  public path$: Observable<string[]>;
  public keyName$: Observable<{[key: string]: string}>;
  public devices$: Observable<UserDeviceJoined[]>;
  public selectedItems$: Observable<LibraryItem[]>;
  public filterFormValue$: Observable<LibraryFilter>;
  public hasEnabledFilters$: Observable<boolean>;
  public filterChips$: Observable<{[key: string]: LibraryFilterChip}>;
  public sort: SortOptions;
  public limit: number;
  public scroller: CdkVirtualScrollViewport;
  public libraryItemMimeType: LibraryMimeType;
  public imageActionMenu$: Observable<ActionMenu[]>;
  private folderActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  public folderActionMenu$: Observable<ActionMenu[]> = this.folderActionMenu.asObservable();
  public panoImageActionMenu$: Observable<ActionMenu[]>;
  public genericActionMenu$: Observable<ActionMenu[]>;
  public modelActionMenu$: Observable<ActionMenu[]>;
  public videoActionMenu$: Observable<ActionMenu[]>;
  public flightLogActionMenu$: Observable<ActionMenu[]>;
  public specialFoldersIds$: Observable<string[]> = this.libraryStoreFacadeService.specialFoldersIds$;
  public hasSelectedSpecialFolders$: Observable<boolean> = this.libraryStoreFacadeService.hasSelectedSpecialFolders$;
  public currentFolderId$: Observable<string> = this.routerFacadeStoreService
    .selectRouteParams()
    .pipe(map(routerParams => routerParams.data.baseItem.id || routerParams.data.companyId));

  public hasSelectedAllCurrentViewItems$: Observable<boolean> =
    this.libraryStoreFacadeService.hasSelectedAllCurrentViewItems$;
  public currentPath$: Observable<string[]> = this.libraryStoreFacadeService.currentPath$;
  public availableModels$: Observable<LibraryItem[]> = this.libraryStoreFacadeService.availableModels$;
  public selectedModel$: Observable<ModelData> = this.libraryStoreFacadeService.selectedModel$;
  public selectAiVideoMenu$: Observable<Partial<LibraryItem>[]> = this.libraryStoreFacadeService.selectAiVideoMenu$;
  public selectIsLoadingAiVideoMenu$: Observable<boolean> = this.libraryStoreFacadeService.selectIsLoadingAiVideoMenu$;

  public tags$: Observable<Tag[]> = this.libraryStoreFacadeService.selectTags();
  public tagsObject$: Observable<{[key: string]: Tag}> = this.libraryStoreFacadeService.tagsObject$;
  public tagsColors$: Observable<{[key: string]: string}> = this.libraryStoreFacadeService.selectTagsColors();
  public suggestedTags$: Observable<Tag[]> = this.libraryStoreFacadeService.suggestedTags$;
  public activeTags$: Observable<{[key: string]: Tag[]}> = this.libraryStoreFacadeService.activeTags$;
  public libraryItemForSidebar$: Observable<LibraryItem> = this.libraryStoreFacadeService.libraryItemForSidebar$;
  public activeTagsForSidebar$: Observable<Tag[]> = this.libraryStoreFacadeService.activeTagsForSidebar$;
  public activeTagsForSelectionBar$: Observable<Tag[]> = this.libraryStoreFacadeService.activeTagsForSelectionBar$;
  public annotationsSummaryItems$: Observable<LibraryItem[]> = this.libraryStoreFacadeService.annotationsSummaryItems$;
  public tagsLoading$: Observable<boolean> = this.libraryStoreFacadeService.tagsLoading$;
  public totalImagesSelected$: Observable<number> = this.libraryStoreFacadeService.totalImagesSelected$;
  public isOnlyItemsTypeImageSelected$: Observable<boolean> =
    this.libraryStoreFacadeService.isOnlyItemsTypeImageSelected$;
  public hasSelectionAFolder$: Observable<boolean> = this.libraryStoreFacadeService.hasSelectionAFolder$;
  public hasPanoramaSelected$: Observable<boolean> = this.libraryStoreFacadeService.hasPanoramaSelected$;
  public isUploadInProgress$: Observable<boolean> = this.uploadFilesService.isUploading$;
  public hasExportAnnotationsOption$: Observable<boolean> = this.libraryStoreFacadeService.hasExportAnnotationsOption$;
  public libraryItemForContextMenu$: Observable<LibraryItem> =
    this.libraryStoreFacadeService.libraryItemForContextMenu$;
  public hasPressedShiftKey: boolean = false;
  public topSelectedLibraryItem: {rowIndex: number; itemIndex: number} = {rowIndex: -1, itemIndex: -1};
  public dateFilterPriority: BehaviorSubject<DatePriority> = new BehaviorSubject<DatePriority>(
    DatePriority.DATE_AND_TIME
  );
  public rootFolderId$: Observable<string> = this.routerFacadeStoreService
    .selectRouteParams()
    .pipe(map(routeParams => routeParams.data.companyId));

  private imageActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  private panoImageActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  private genericActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  private modelActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  private videoActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  private flightLogActionMenu: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  private videoStartFromData: BehaviorSubject<{startFrom: number; libraryItemId: string}> = new BehaviorSubject(null);

  constructor(
    private libraryStoreFacadeService: LibraryStoreFacadeService,
    private userService: UserService,
    private libraryFilterService: LibraryFilterService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private browserSettingsService: BrowserSettingsService,
    private modelMenuService: ModelMenuService,
    private downloadAnalyticsService: DownloadAnalyticsService,
    private router: Router,
    private analyticsService: UnleashAnalyticsService,
    private uploadFilesService: UploadFilesFacadeService,
    private aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private routerFacadeStoreService: RouterFacadeStoreService
  ) {
    this.libraryItems$ = this.libraryStoreFacadeService.selectListViewSorted();
    this.isLoading$ = this.libraryStoreFacadeService.selectLibraryIsLoading();
    this.path$ = this.libraryStoreFacadeService.selectLibraryFolderPath();
    this.devices$ = this.userService.userDevices$;
    this.selectedItems$ = this.libraryStoreFacadeService.getSelectedLibraryItems();
    this.filterFormValue$ = this.libraryFilterService.filterFormValue$;
    this.hasEnabledFilters$ = this.libraryFilterService.filterFormValue$.pipe(
      map(filters => {
        const filterValues = Object.values(filters).map(filter => !!(filter && filter.length > 0));
        return !filterValues.every(filter => !filter);
      })
    );
    this.filterChips$ = this.libraryFilterService.filterChips$;
    this.imageActionMenu$ = this.imageActionMenu.asObservable();
    this.panoImageActionMenu$ = this.panoImageActionMenu.asObservable();
    this.genericActionMenu$ = this.genericActionMenu.asObservable();
    this.modelActionMenu$ = this.modelActionMenu.asObservable();
    this.videoActionMenu$ = this.videoActionMenu.asObservable();
    this.flightLogActionMenu$ = this.flightLogActionMenu.asObservable();

    const mobileLoadingItemsLimit = 8;
    const desktopLoadingItemsLimit = 48;

    this.sort = SortOptions.desc;
    this.limit = this.browserSettingsService.getIsMobileBrowser() ? mobileLoadingItemsLimit : desktopLoadingItemsLimit;
  }

  public setDateFilterPriority(dateFilterPriority: DatePriority) {
    this.dateFilterPriority.next(dateFilterPriority);
  }

  public setVideoStartFromData({startFrom, libraryItemId}: {startFrom: number; libraryItemId: string}): void {
    this.videoStartFromData.next({startFrom, libraryItemId});
  }

  // eslint-disable-next-line rxjs/finnish
  public getVideoStartFromData(): Observable<{startFrom: number; libraryItemId: string}> {
    return this.videoStartFromData.asObservable();
  }

  public watchFeaturesFromAddonsFeatures(): void {
    combineLatest([
      this.addonStoreFacadeService.getIsAnalyticsEnabled(),
      this.addonStoreFacadeService.getIsModellingEnabled(),
      this.aclService.hasSetupPermission$.pipe(filter(hasSetupPermission => !!hasSetupPermission)),
      this.hasSelectedSpecialFolders$,
      this.downloadAnalyticsService.availableAnalyticsMenuItems$,
      this.downloadAnalyticsService.hasAnalyticsMenuItems$,
      this.downloadAnalyticsService.isLoading$
    ]).subscribe(
      ([
        isAnalyticsEnabled,
        isModellingEnabled,
        hasSetupACLPermissions,
        hasSelectedSpecialFolders,
        availableAnalyticsMenuItems,
        hasAnalyticsMenuItems,
        isLoadingDownloadAnalytics
      ]: [boolean, boolean, boolean, boolean, ActionMenu[], boolean, boolean]) => {
        const hasSummarizeAccess = this.aclService.hasPermission(AclPermissions.LibraryApiAnnotationSummary);
        const hasDownloadPermission = this.aclService.hasPermission(AclPermissions.LibraryApiBatchDownload);
        const hasDeletePermission = this.aclService.hasPermission(AclPermissions.LibraryApiGraphQlDeleteItems);
        const hasMovePermission = this.aclService.hasPermission(AclPermissions.LibraryApiGraphQlMoveItems);
        const hasTagPermission = this.aclService.hasPermission(AclPermissions.TagApiUpdate);
        const hasRenamePermission = this.aclService.hasPermission(AclPermissions.LibraryApiRename);
        const hasExportData = this.aclService.hasPermission(AclPermissions.ReportApiExportData);
        const hasAnalyzePermission = this.aclService.hasPermission(AclPermissions.BatchJobsApiComputerVisionJob);
        const hasAddToAtlas = this.aclService.hasPermission(AclPermissions.AtlasApiGenerate);
        const hasCreate2D3DModel = this.aclService.hasPermission(AclPermissions.BatchJobsApiOpenDroneMapJob);
        const hasAssignAssets = this.aclService.hasPermission(AclPermissions.AtlasApiAssignAssets);
        const hasOpenPdf = false;

        const imageActionMenu = this.generateImageActionMenu(
          isModellingEnabled,
          isAnalyticsEnabled,
          hasSummarizeAccess,
          hasDownloadPermission,
          hasDeletePermission,
          hasMovePermission,
          hasTagPermission,
          hasRenamePermission,
          hasExportData,
          hasAnalyzePermission,
          hasAddToAtlas,
          hasSelectedSpecialFolders,
          availableAnalyticsMenuItems,
          hasAnalyticsMenuItems,
          isLoadingDownloadAnalytics
        );
        this.imageActionMenu.next(imageActionMenu);

        const folderActionMenu = this.generateFolderActionMenu(
          isAnalyticsEnabled,
          isModellingEnabled,
          hasSummarizeAccess,
          hasDownloadPermission,
          hasDeletePermission,
          hasMovePermission,
          hasTagPermission,
          hasRenamePermission,
          hasExportData,
          hasAnalyzePermission,
          hasCreate2D3DModel,
          hasAddToAtlas,
          hasAssignAssets,
          hasSelectedSpecialFolders,
          availableAnalyticsMenuItems,
          hasAnalyticsMenuItems,
          isLoadingDownloadAnalytics
        );
        this.folderActionMenu.next(folderActionMenu);

        const panoImageActionMenu = this.generatePanoImageActionMenu(
          hasTagPermission,
          hasDownloadPermission,
          hasRenamePermission,
          hasMovePermission,
          hasDeletePermission,
          hasAddToAtlas
        );
        this.panoImageActionMenu.next(panoImageActionMenu);

        const genericActionMenu = this.generateGenericActionMenu(
          hasDownloadPermission,
          hasDeletePermission,
          hasMovePermission,
          hasTagPermission,
          hasRenamePermission,
          hasOpenPdf
        );
        this.genericActionMenu.next(genericActionMenu);

        const videoActionMenu = this.generateVideoActionMenu(
          isAnalyticsEnabled,
          isModellingEnabled,
          hasDownloadPermission,
          hasDeletePermission,
          hasMovePermission,
          hasTagPermission,
          hasRenamePermission,
          hasAnalyzePermission,
          hasCreate2D3DModel,
          availableAnalyticsMenuItems,
          hasAnalyticsMenuItems,
          isLoadingDownloadAnalytics
        );
        this.videoActionMenu.next(videoActionMenu);

        this.modelMenuService.mimeType$.subscribe((mimeType: LibraryMimeType) => {
          let modelActionMenu;
          if (mimeType === LibraryMimeType.model) {
            modelActionMenu = this.generateModelActionMenu(
              hasDownloadPermission,
              hasDeletePermission,
              hasMovePermission,
              hasTagPermission,
              hasRenamePermission
            );
            this.modelActionMenu.next(modelActionMenu);
            return;
          }

          modelActionMenu = this.generateSingleModelActionsMenu(
            mimeType,
            isModellingEnabled,
            isAnalyticsEnabled,
            hasSummarizeAccess,
            hasDownloadPermission,
            hasDeletePermission,
            hasMovePermission,
            hasTagPermission,
            hasRenamePermission,
            hasExportData,
            hasAnalyzePermission,
            hasAddToAtlas
          );
          this.modelActionMenu.next(modelActionMenu);
        });

        const flightLogActionMenu: ActionMenu[] = this.generateFlightLogActionMenu(
          hasDownloadPermission,
          hasDeletePermission,
          hasMovePermission,
          hasTagPermission,
          hasRenamePermission
        );
        this.flightLogActionMenu.next(flightLogActionMenu);
      }
    );
  }

  public watchCreatedLibraryItems(): void {
    this.userService.user$
      .pipe(
        take(1),
        tap((user: UserModel) => this.libraryStoreFacadeService.watchUploadedLibraryItems(user.id))
      )
      .subscribe();
  }

  public watchCreatedLibraryItem(): void {
    this.userService.user$
      .pipe(
        take(1),
        tap((user: UserModel) => this.libraryStoreFacadeService.watchUploadedLibraryItem(user.id))
      )
      .subscribe();
  }

  public watchDeleteLibraryItem(): void {
    this.libraryStoreFacadeService.watchDeleteLibraryItem();
  }

  public watchBackStateLibraryItem(): void {
    this.libraryStoreFacadeService.watchBackStateLibraryItem();
  }

  public addToBreadcrumbPath(parentId: string): void {
    this.libraryStoreFacadeService.addToBreadcrumbPath(parentId);
  }

  public deleteLastBreadcrumbPathByIndex(pathIndex: number): void {
    this.libraryStoreFacadeService.deleteLastBreadcrumbPathByIndex(pathIndex);
  }

  public clearBreadcrumb(): void {
    this.libraryStoreFacadeService.clearBreadcrumb();
  }

  public openFolder(libraryItemId: string, openInNewTab?: boolean): void {
    this.libraryStoreFacadeService.openFolder(libraryItemId, openInNewTab);
  }

  public openFolderFromBreadcrumb(libraryItemId: string) {
    this.libraryStoreFacadeService.openFolderFromBreadcrumb(libraryItemId);
  }

  public selectLibraryItem(libraryItemId: string): void {
    this.libraryStoreFacadeService.markAsSelectedLibraryItem(libraryItemId);
  }

  public unselectLibraryItem(libraryItemId: string): void {
    this.libraryStoreFacadeService.markAsUnselectedLibraryItem(libraryItemId);
  }

  public appendLoadLibraryItems(): void {
    // TODO: Review this error when delete a library item
    // it happens when I'm inside new folder and deleting last folder
    try {
      this.libraryStoreFacadeService.appendLoadLibraryItems();
    } catch (error) {
      console.warn('exist library facade?', !!this.libraryStoreFacadeService);
    }
  }

  public updateAnalyticsFilter(timestamp: number): void {
    this.libraryStoreFacadeService.updateFilterUrlParams({timestamp});
  }

  public updateDeviceFilter(deviceId: string): void {
    const parseFilter = this.libraryFilterService.parseFilter('device', deviceId);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateDateFilter(date: {dateFrom: number; dateTo: number}): void {
    const parseFilter = this.libraryFilterService.parseFilter('date', date);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateDateFromFilter(dateFrom: string): void {
    const parseFilter = this.libraryFilterService.parseFilter('dateFrom', dateFrom);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateDateToFilter(dateTo: string): void {
    const parseFilter = this.libraryFilterService.parseFilter('dateTo', dateTo);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateTypeFilter(type: string): void {
    const parseFilter = this.libraryFilterService.parseFilter('type', type);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateAnalysisFilter(analysis: string): void {
    const parseFilter = this.libraryFilterService.parseFilter('analysis', analysis);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateTagsFilter(tag: Tag, afterDelete: boolean = false): void {
    const selectedTags: string[] = this.libraryFilterService.filterFormValue.value.tags;
    const newSelectedTags: string[] = selectedTags?.some(tagId => tagId === tag.id)
      ? selectedTags.filter(tagId => tagId !== tag.id)
      : !afterDelete
      ? selectedTags.concat(tag.id)
      : selectedTags;
    const parseFilter: Partial<LibraryFilter> = this.libraryFilterService.parseFilter('tags', newSelectedTags);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateSearchFilter(search: string): void {
    const parseFilter = this.libraryFilterService.parseFilter('search', search);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public filterAllLevels(filterAllLevels: boolean) {
    const parseFilter = this.libraryFilterService.parseFilter('filterAllLevels', filterAllLevels);
    this.libraryStoreFacadeService.updateFilterUrlParams(parseFilter);
  }

  public updateAllFormFilter(formFilter: LibraryFilter): void {
    this.libraryFilterService.updateAllFormFilter(formFilter);
    this.libraryStoreFacadeService.setFilterUrlParams(formFilter);
  }

  public setAllFormFilter(formFilter: LibraryFilter): void {
    this.libraryFilterService.setAllFormFilter(formFilter);
    this.libraryStoreFacadeService.setFilterUrlParams(formFilter);
  }

  public deleteTimeAndDateFilter(): void {
    const filterParams = {
      ['dateFrom']: null,
      ['hourFrom']: null,
      ['hourTo']: null,
      ['dateTo']: null,
      ['timestamp']: null
    };
    this.libraryStoreFacadeService.updateFilterUrlParams(filterParams);
  }

  public deleteFilter(filterKey: string): void {
    this.libraryFilterService.deleteFilter(filterKey);
    let filterParams = filterKey.includes('tags#') ? {tags: null} : {[filterKey]: null};

    switch (filterKey) {
      case 'dateFrom':
        filterParams = {['dateFrom']: null, ['hourFrom']: null};
        break;
      case 'dateTo':
        filterParams = {['hourTo']: null, ['dateTo']: null};
        break;
      case 'timestamp':
        filterParams = {['timestamp']: null};
        break;
      case 'device':
        filterParams = {['timestamp']: null, ['device']: null};
        break;
      default:
        if (filterKey.includes('tags#')) {
          const INDEX_UNTIL_TAG_ID = 5;
          const tagId = filterKey.slice(INDEX_UNTIL_TAG_ID);
          filterParams = {tags: this.libraryFilterService.filterFormValue.value.tags.filter(id => id !== tagId)};
        } else {
          filterParams = {[filterKey]: null};
        }
        break;
    }
    this.libraryStoreFacadeService.updateFilterUrlParams(filterParams);
  }

  public deleteAllFilters(): void {
    this.libraryFilterService.deleteAllFilters();

    this.libraryStoreFacadeService.updateFilterUrlParams({
      search: null,
      dateFrom: null,
      hourFrom: null,
      dateTo: null,
      hourTo: null,
      type: null,
      device: null,
      analysis: null,
      tags: [],
      timestamp: null,
      filterAllLevels: null
    });
  }

  public loadLibraryItems(): void {
    this.libraryStoreFacadeService.loadLibraryItemsFromStoreCache();
  }

  public loadLibraryTags(): void {
    this.libraryStoreFacadeService.loadLibraryTags();
  }

  public createNewTag(tagName: Tag['name'], libraryItem?: LibraryItem, layout?: LibraryLayout): void {
    this.libraryStoreFacadeService.createNewTag(tagName, libraryItem, layout);
  }

  public openMoveLibraryItemsDialog(): void {
    this.analyticsService.logEvent(EVENTS.LIBRARY_MOVE_FILE, {layout: LibraryLayout.ACTION_BAR});
    this.libraryStoreFacadeService.openMoveLibraryItemsDialog();
  }

  public openUploadDialog(
    files: File[],
    parentId: string,
    uploadType: UPLOAD_TYPES,
    totalItemsDropped: number = 0
  ): void {
    this.libraryStoreFacadeService.openUploadDialog(Array.from(files), parentId, uploadType, totalItemsDropped);
  }

  public openUploadFilesFromGoogleDriveDialog(): void {
    this.libraryStoreFacadeService.openUploadFilesFromGoogleDriveDialog();
  }

  public exportToS3Dialog(): void {
    this.libraryStoreFacadeService.openExportToS3Dialog();
  }

  public openUploadFilesFromSkydio(): void {
    this.libraryStoreFacadeService.openUploadFilesFromSkydioDialog();
  }

  public openImportFromS3Dialog(): void {
    this.libraryStoreFacadeService.openImportFromS3Dialog();
  }

  public openDeleteItemsDialog(): void {
    this.libraryStoreFacadeService.openDeleteItemsDialog();
  }

  public unselectAllLibraryItems(): void {
    this.libraryStoreFacadeService.unselectAllLibraryItems();
  }

  public unselectAllLibraryItemsFromCurrentView(): void {
    this.libraryStoreFacadeService.unselectAllLibraryItemsFromCurrentView();
  }

  public openImageViewer(libraryItemId: string): void {
    this.router.navigateByUrl(`/secure/view/${libraryItemId}`);
  }

  public openImageViewerAnnotation(libraryItemId: string): void {
    this.router.navigate([`/secure/view/${libraryItemId}`], {queryParams: {annotation: true}} as NavigationExtras);
  }

  public openAnalyze(): void {
    this.libraryStoreFacadeService.openAnalyze();
  }

  public openAnalyzeFromCard(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.unselectAllLibraryItems();
    this.libraryStoreFacadeService.markAsSelectedLibraryItem(libraryItem.id);
    this.libraryStoreFacadeService.hasAnalyzeOption();
    this.libraryStoreFacadeService.openAnalyze();
  }

  public openAnnotate(libraryItem: LibraryItem, addonId: string): void {
    this.libraryStoreFacadeService.openAnnotate(libraryItem, addonId);
  }

  public openRenameItemDialog(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.openRenameItemDialog(libraryItem.id);
  }

  public openMoveItemDialog(libraryItem: LibraryItem): void {
    this.analyticsService.logEvent(EVENTS.LIBRARY_MOVE_FILE, {layout: LibraryLayout.CONTEXT_MENU});
    this.libraryStoreFacadeService.unselectAllLibraryItems();
    this.libraryStoreFacadeService.markAsSelectedLibraryItem(libraryItem.id);
    this.libraryStoreFacadeService.openMoveLibraryItemsDialog();
  }

  public openDeleteItemDialog(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.unselectAllLibraryItems();
    this.libraryStoreFacadeService.markAsSelectedLibraryItem(libraryItem.id);
    this.libraryStoreFacadeService.openDeleteItemsDialog();
  }

  public openVideoModelDialog(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.unselectAllLibraryItems();
    this.libraryStoreFacadeService.markAsSelectedLibraryItem(libraryItem.id);
    this.libraryStoreFacadeService.openVideoModelDialog(libraryItem.id);
  }

  public openImageModelDialog(): void {
    this.libraryStoreFacadeService.openImageModelDialog();
  }

  public renameTag(tag: Tag): void {
    this.libraryStoreFacadeService.renameTag(tag);
  }

  public updateColorTag(tag: Tag): void {
    this.libraryStoreFacadeService.updateColorTag(tag);
  }

  public deleteTag(tag: Tag): void {
    this.libraryStoreFacadeService.deleteTag(tag);
  }

  public watchLoadLibraryItemsOnScroll(): void {
    this.libraryStoreFacadeService.watchLoadLibraryItemsOnScroll();
  }

  public getListQueryParams(
    folder: LibraryItem,
    nextToken?: NextToken,
    limit: number = this.limit,
    location?: string
  ): LibraryListQueryParams {
    const queryParams: LibraryListQueryParams = {
      sort: this.sort,
      location: folder ? folder.location : location
    };

    const searchName = this.libraryFilterService.filterFormValue.value.search;
    if (searchName) {
      queryParams.name = searchName;
    }

    if (folder && !(folder.location === folder.id)) {
      queryParams.location = [folder.location, folder.id].join('/');
    }

    const deviceId = this.libraryFilterService.filterFormValue.value.device;
    if (deviceId) {
      queryParams.deviceId = deviceId;
    }

    const dateFrom = this.libraryFilterService.filterFormValue.value.dateFrom;
    if (dateFrom && this.dateFilterPriority.value === DatePriority.DATE_AND_TIME) {
      queryParams.dateFrom = dateFrom.toString();
    }

    const dateTo = this.libraryFilterService.filterFormValue.value.dateTo;
    if (dateTo && this.dateFilterPriority.value === DatePriority.DATE_AND_TIME) {
      queryParams.dateTo = dateTo.toString();
    }

    const type = this.libraryFilterService.filterFormValue.value.type;
    if (type) {
      queryParams.type = type;
    }

    const tags = this.libraryFilterService.filterFormValue.value.tags;
    if (tags?.length > 0) {
      queryParams.tags = tags;
    }

    const isLibraryFiltersEmpty = Object.values(this.libraryFilterService.filterFormValue.value).every(
      (value: string | number) => {
        if (Array.isArray(value) && value.length === 0) {
          return true;
        }
        return !value;
      }
    );

    const timestamp = this.libraryFilterService.filterFormValue.value.timestamp;
    if (timestamp && this.dateFilterPriority.value === DatePriority.UNIX_TIMESTAMP) {
      queryParams.dateFrom = timestamp - 21 * 60 * 1000 + '';
      queryParams.dateTo = timestamp + 21 * 60 * 1000 + '';
    }

    if (limit && isLibraryFiltersEmpty) {
      queryParams.limit = limit;
    }

    if (nextToken) {
      queryParams.nextToken = nextToken;
    }
    return queryParams;
  }

  public updateFilterUrlParams(filterFormValue: LibraryFilter): void {
    this.libraryStoreFacadeService.updateFilterUrlParams(filterFormValue);
  }

  public download(): void {
    this.libraryStoreFacadeService.download();
  }

  public share(libraryItem: LibraryItem): void {
    this.analyticsService.logEvent(EVENTS.LIBRARY_SHARE_FILE);
    this.libraryStoreFacadeService.share(libraryItem);
  }

  public openFlightLogDialog(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.openFlightLogDialog(libraryItem);
  }

  public selectAllLibraryItemsFromCurrentView(): void {
    this.libraryStoreFacadeService.selectAllLibraryItemsFromCurrentView();
  }

  public navigateToAtlas({bounds, modelId}: {bounds: LatLngBoundsExpression; modelId: LibraryItem['parentId']}): void {
    this.router.navigate(['/secure/atlas'], {
      queryParams: {modelId, lat: bounds[0][0], lng: bounds[0][1]}
    });
  }

  public openModelPickerDialog(libraryItem: LibraryItem, openModelPickerDialog: Addon[]): void {
    this.libraryStoreFacadeService.openModelPickerDialog(libraryItem, openModelPickerDialog);
  }

  public loadRequirementsForModelPickerDialog(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.loadRequirementsForModelPickerDialog(libraryItem);
  }

  public generateSubmenuItems(models: LibraryItem[]): ActionMenu[] {
    const availableTypes = models.map((model: LibraryItem) => model.mimeType);
    const items = availableTypes.map((type: LibraryMimeType) => {
      if (type === LibraryMimeType.two_d_model) {
        return {
          type: MenuActionType.MENU,
          name: '2D',
          actionType: MenuActions.TWO_D_MODEL
        };
      }
      if (type === LibraryMimeType.three_d_model) {
        return {
          type: MenuActionType.MENU,
          name: '3D',
          actionType: MenuActions.THREE_D_MODEL
        };
      }
      if (type === LibraryMimeType.vr_model) {
        return {
          type: MenuActionType.MENU,
          name: 'VR',
          actionType: MenuActions.VR_MODEL
        };
      }
      if (type === LibraryMimeType.point_cloud) {
        return {
          type: MenuActionType.MENU,
          name: 'Point Cloud',
          actionType: MenuActions.POINTCLOUD
        };
      }
    });
    return orderBy(items, 'name', 'asc');
  }
  public generateExportSubmenuItems(models: LibraryItem[]): ActionMenu[] {
    const getTwoDModelMenuItems = () => [
      {
        type: MenuActionType.MENU,
        name: 'Orthophoto GeoTIFF',
        actionType: MenuActions.EXPORT_ORTHO
      },
      {
        type: MenuActionType.MENU,
        name: 'Web tiles',
        actionType: MenuActions.EXPORT_TILES
      },
      {
        type: MenuActionType.MENU,
        name: 'DTM, DSM, Countours',
        actionType: MenuActions.EXPORT_DEM
      }
    ];

    const getMenuItem = (model: LibraryItem) => {
      switch (model.mimeType) {
        case LibraryMimeType.two_d_model:
          return getTwoDModelMenuItems();
        case LibraryMimeType.three_d_model:
          return [
            {
              type: MenuActionType.MENU,
              name: 'OBJ Georeferenced',
              actionType: MenuActions.EXPORT_3D
            }
          ];
        case LibraryMimeType.point_cloud:
          return [
            {
              type: MenuActionType.MENU,
              name: 'LAS Georeferenced',
              actionType: MenuActions.EXPORT_LAS
            }
          ];
        default:
          return null;
      }
    };

    const exportItems = models.map(getMenuItem).filter(Boolean).flat();
    return orderBy(exportItems, 'name', 'asc');
  }

  public navigateToModel({
    modelMimeType,
    libraryItemId
  }: {
    modelMimeType: LibraryMimeType;
    libraryItemId: string;
  }): void {
    this.libraryStoreFacadeService.openSelectedModel({
      modelMimeType,
      libraryItemId
    });
  }

  public saveCurrentParentId(parentId: string): void {
    this.libraryStoreFacadeService.saveCurrentParentId(parentId);
  }

  public scrollUp(): void {
    this.libraryStoreFacadeService.scrollUp();
  }

  public setScrollerToTop(): void {
    this.scroller?.scrollToIndex(0);
  }

  public exportCocoJson(libraryItem?: LibraryItem): void {
    this.analyticsService.logEvent(EVENTS.LIBRARY_EXPORT_ANNOTATION, {fileType: 'JSON'});
    this.libraryStoreFacadeService.exportCocoJson(libraryItem);
  }

  public exportCsv(libraryItem?: LibraryItem): void {
    this.analyticsService.logEvent(EVENTS.LIBRARY_EXPORT_ANNOTATION, {fileType: 'CSV'});
    this.libraryStoreFacadeService.exportCsv(libraryItem);
  }

  public requestAiVideos(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.requestAiVideo(libraryItem);
  }

  public switchVideo(aiVideo: LibraryItem, id: LibraryItem['id']): void {
    this.libraryStoreFacadeService.switchVideo(id, aiVideo);
  }

  public addToAtlas(): void {
    this.libraryStoreFacadeService.addToAtlas();
  }

  public updateLibraryItemTag(tag: Tag, libraryItem: LibraryItem, layout: LibraryLayout): void {
    this.libraryStoreFacadeService.updateLibraryItemTag(tag, libraryItem, layout);
  }

  public showLibraryItemTags(libraryItemId: string): void {
    this.libraryStoreFacadeService.showLibraryItemTags(libraryItemId);
  }

  public clearLibraryItemTags(): void {
    this.libraryStoreFacadeService.clearLibraryItemTags();
  }

  public openAnnotationsSummary(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.openAnnotationsSummary(libraryItem);
  }

  public listenPreviousUrl(): void {
    this.libraryStoreFacadeService.listenPreviousUrl();
  }

  public navigateToPreviousUrl(redirectedFrom?: string, isPositiveAction?: boolean): void {
    this.libraryStoreFacadeService.navigateToPreviousUrl(redirectedFrom, isPositiveAction);
  }

  public compareImages(): void {
    this.libraryStoreFacadeService.compareImages();
  }

  public downloadAnalyticsMenuItems(libraryItem: LibraryItem): void {
    this.libraryStoreFacadeService.downloadAnalyticsMenuItems(libraryItem);
  }

  public fileCount(payload: {id: LibraryItem['id']}[]): void {
    this.libraryStoreFacadeService.fileCount(payload);
  }

  public setLibraryItemFromContextMenu(libraryItemId: string): void {
    this.libraryStoreFacadeService.setLibraryItemFromContextMenu(libraryItemId);
  }

  public clearLibraryIteamContextMenu(): void {
    this.libraryStoreFacadeService.clearLibraryIteamContextMenu();
  }

  public exportModel(payload: {id: LibraryItem['id']; type: MenuActions}): void {
    this.libraryStoreFacadeService.exportModel(payload);
  }

  public clearCurrentPositionStore(): void {
    this.libraryStoreFacadeService.clearCurrentPositionStore();
  }

  public assingToAssets(libraryItem: LibraryItem) {
    this.libraryStoreFacadeService.assingToAssets(libraryItem);
  }

  public exportAnalytics(path: string) {
    this.libraryStoreFacadeService.exportAnalytics(path);
  }

  private generateImageActionMenu(
    isModellingEnabled: boolean,
    isAnalyticsEnabled: boolean,
    hasSummarizeAccess: boolean,
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean,
    hasExportData: boolean,
    hasAnalyzePermission: boolean,
    hasAddToAtlas: boolean,
    hasSelectedSpecialFolders: boolean,
    availableAnalyticsMenuItems: ActionMenu[],
    hasAnalyticsMenuItems: boolean,
    isLoadingDownloadAnalytics: boolean
  ): ActionMenu[] {
    let actions: ActionMenu[] = [];

    if (isAnalyticsEnabled) {
      if (hasAnalyzePermission) {
        actions.push(actionMenuItem[MenuActions.ANALYZE]);
      }

      this.insertAnalyticsMenuItems(
        hasAnalyticsMenuItems,
        availableAnalyticsMenuItems,
        actions,
        isLoadingDownloadAnalytics
      );
      actions.push(actionMenuItem[MenuActions.ANNOTATE]);

      if (hasExportData) {
        actions.push(actionMenuItem[MenuActions.EXPORT_ANNOTATIONS]);
      }
    }

    if (hasSummarizeAccess) {
      actions.push(actionMenuItem[MenuActions.SUMMARIZE]);
    }

    if (hasAddToAtlas && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.ADD_TO_ATLAS]);
    }

    if (hasTagPermission) {
      actions.push(actionMenuItem[MenuActions.TAGS]);
    }

    actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
    actions.push(actionMenuItem[MenuActions.SHARE]);

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.DOWNLOAD]);
    }

    if (hasRenamePermission && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    return actions;
  }

  private insertAnalyticsMenuItems(
    hasAnalyticsMenuItems: boolean,
    availableAnalyticsMenuItems: ActionMenu[],
    actions: ActionMenu[],
    isLoadingDownloadAnalytics: boolean
  ) {
    if (hasAnalyticsMenuItems) {
      if (availableAnalyticsMenuItems.length > 0) {
        actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
        const downloadAnalyticsItems = actionMenuItem[MenuActions.DOWNLOAD_ANALYTICS];
        downloadAnalyticsItems.submenuItems = availableAnalyticsMenuItems;
        actions.push(downloadAnalyticsItems);
        actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
      } else {
        actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
        actions.push({
          ...actionMenuItem[MenuActions.DOWNLOAD_ANALYTICS],
          isLoading: isLoadingDownloadAnalytics,
          submenuItems: [],
          isDisabled: true
        });
        actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
      }
    }
  }

  private generatePanoImageActionMenu(
    hasTagPermission: boolean,
    hasDownloadPermission: boolean,
    hasRenamePermission: boolean,
    hasMovePermission: boolean,
    hasDeletePermission: boolean,
    hasAddToAtlas: boolean
  ): ActionMenu[] {
    let actions: ActionMenu[] = [];

    if (hasAddToAtlas) {
      actions.push(actionMenuItem[MenuActions.ADD_TO_ATLAS]);
    }

    if (hasTagPermission) {
      actions.push(actionMenuItem[MenuActions.TAGS]);
      actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
    }

    actions.push(actionMenuItem[MenuActions.SHARE]);

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.DOWNLOAD]);
    }

    if (hasRenamePermission) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    return actions;
  }

  private generateFolderActionMenu(
    isAnalyticsEnabled: boolean,
    isModellingEnabled: boolean,
    hasSummarizeAccess: boolean,
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean,
    hasExportData: boolean,
    hasAnalyzePermission: boolean,
    hasCreate2D3DModel: boolean,
    hasAddToAtlas: boolean,
    hasAssignAssets: boolean,
    hasSelectedSpecialFolders: boolean,
    availableAnalyticsMenuItems: ActionMenu[],
    hasAnalyticsMenuItems: boolean,
    isLoadingDownloadAnalytics: boolean
  ): ActionMenu[] {
    let actions: ActionMenu[] = [];

    if (isModellingEnabled && hasCreate2D3DModel) {
      actions.push(actionMenuItem[MenuActions.IMAGE_MODEL]);
    }

    if (isAnalyticsEnabled) {
      if (hasAnalyzePermission) {
        actions.push(actionMenuItem[MenuActions.ANALYZE]);
      }

      this.insertAnalyticsMenuItems(
        hasAnalyticsMenuItems,
        availableAnalyticsMenuItems,
        actions,
        isLoadingDownloadAnalytics
      );
      if (hasExportData) {
        actions.push(actionMenuItem[MenuActions.EXPORT_ANNOTATIONS]);
      }
    }

    if (hasSummarizeAccess) {
      actions.push(actionMenuItem[MenuActions.SUMMARIZE]);
    }

    if (hasAddToAtlas && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.ADD_TO_ATLAS]);
    }

    if (hasTagPermission) {
      actions.push(actionMenuItem[MenuActions.TAGS]);
    }

    if (hasAssignAssets && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.ASSIGN_TO_ASSETS]);
    }

    actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR]]);

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.DOWNLOAD]);
    }

    if (hasRenamePermission && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission && !hasSelectedSpecialFolders) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    actions.push(actionMenuItem[MenuActions.FILECOUNT]);

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    return actions;
  }

  private generateGenericActionMenu(
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean,
    hasOpenPdf: boolean
  ): ActionMenu[] {
    let actions: ActionMenu[] = [];
    if (hasOpenPdf) {
      actions.push(actionMenuItem[MenuActions.OPEN_PDF]);
    }

    if (hasTagPermission) {
      actions = actions.concat([actionMenuItem[MenuActions.TAGS], actionMenuItem[MenuActionType.SEPARATOR]]);
    }

    actions.push(actionMenuItem[MenuActions.SHARE]);

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.DOWNLOAD]);
    }

    if (hasRenamePermission) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    if (actions.length === 1 && actions[0] === actionMenuItem[MenuActionType.SEPARATOR]) {
      return [];
    }

    return actions;
  }

  private generateModelActionMenu(
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean
  ): ActionMenu[] {
    let actions = [actionMenuItem[MenuActions.OPEN_MODEL], actionMenuItem[MenuActions.VIEW_IN_ATLAS]];
    if (hasTagPermission) {
      actions.push(actionMenuItem[MenuActions.TAGS]);
    }

    actions.push(actionMenuItem[MenuActionType.SEPARATOR]);

    if (hasRenamePermission) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.EXPORT_MODEL]);
    }

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    this.availableModels$
      .pipe(
        filter((models: LibraryItem[]): boolean => !!models.length),
        take(1)
      )
      .subscribe((availableModels: LibraryItem[]): void => {
        actions.find(
          (action: ActionMenu): boolean => action.name === actionMenuItem[MenuActions.OPEN_MODEL].name
        ).submenuItems = this.generateSubmenuItems(availableModels);
        actions.find(
          (action: ActionMenu): boolean => action.name === actionMenuItem[MenuActions.EXPORT_MODEL].name
        ).submenuItems = this.generateExportSubmenuItems(availableModels);
      });
    return actions;
  }

  private generateSingleModelActionsMenu(
    mimeType: LibraryMimeType,
    isModellingEnabled: boolean,
    isAnalyticsEnabled: boolean,
    hasSummarizeAccess: boolean,
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean,
    hasExportData: boolean,
    hasAnalyzePermission: boolean,
    hasAddToAtlas: boolean
  ): ActionMenu[] {
    const actions = [];

    actions.push(actionMenuItem[MenuActions.OPEN_MODEL]);

    if (hasTagPermission) {
      actions.push(actionMenuItem[MenuActions.TAGS]);
    }

    actions.push(actionMenuItem[MenuActionType.SEPARATOR]);

    if (hasRenamePermission) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDeletePermission) {
      actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
      actions.push(actionMenuItem[MenuActions.DELETE]);
    }

    if (mimeType === LibraryMimeType.point_cloud) {
      this.setupSingleModelSubmenuItem(actions, [actionMenuItem[MenuActions.POINTCLOUD]]);
      return actions;
    }

    if (mimeType === LibraryMimeType.vr_model) {
      this.setupSingleModelSubmenuItem(actions, [actionMenuItem[MenuActions.VR_MODEL]]);
      return actions;
    }

    if (mimeType === LibraryMimeType.three_d_model) {
      this.setupSingleModelSubmenuItem(actions, [actionMenuItem[MenuActions.THREE_D_MODEL]]);
      return actions;
    }

    if (mimeType === LibraryMimeType.two_d_model) {
      this.setupSingleModelSubmenuItem(actions, [actionMenuItem[MenuActions.TWO_D_MODEL]]);
      return actions;
    }

    return actions;
  }

  private setupSingleModelSubmenuItem(actions: ActionMenu[], actionItem: ActionMenu[]): void {
    actions.find(
      (action: ActionMenu): boolean => action.name === actionMenuItem[MenuActions.OPEN_MODEL].name
    ).submenuItems = actionItem;
  }

  private generateVideoActionMenu(
    isModellingEnabled: boolean,
    isAnalyticsEnabled: boolean,
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean,
    hasAnalyzePermission: boolean,
    hasCreate2D3DModel: boolean,
    availableAnalyticsMenuItems: ActionMenu[],
    hasAnalyticsMenuItems: boolean,
    isLoadingDownloadAnalytics: boolean
  ): ActionMenu[] {
    let actions = [];

    if (isModellingEnabled && hasAnalyzePermission) {
      actions.push(actionMenuItem[MenuActions.ANALYZE]);
    }

    if (isAnalyticsEnabled && hasCreate2D3DModel) {
      this.insertAnalyticsMenuItems(
        hasAnalyticsMenuItems,
        availableAnalyticsMenuItems,
        actions,
        isLoadingDownloadAnalytics
      );
      actions.push(actionMenuItem[MenuActions.VIDEO_MODEL]);
    }

    if (hasTagPermission) {
      actions.push(actionMenuItem[MenuActions.TAGS]);
    }

    actions.push(actionMenuItem[MenuActionType.SEPARATOR]);
    this.activatedRoute.queryParams.subscribe(params => {
      const separatorIndex = actions.indexOf(actionMenuItem[MenuActionType.SEPARATOR]);
      const goToTimestampIndex = actions.indexOf(actionMenuItem[MenuActions.GO_TO_TIMESTAMP]);
      if (params.timestamp && separatorIndex > -1 && goToTimestampIndex === -1) {
        actions.splice(separatorIndex + 1, 0, actionMenuItem[MenuActions.GO_TO_TIMESTAMP]);
      }
      if (goToTimestampIndex > -1 && !params.timestamp) {
        actions.splice(goToTimestampIndex, 1);
      }
    });
    actions.push(actionMenuItem[MenuActions.SHARE]);

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.DOWNLOAD]);
    }

    if (hasRenamePermission) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    if (actions.length === 1 && actions[0] === actionMenuItem[MenuActionType.SEPARATOR]) {
      return [];
    }

    return actions;
  }

  private generateFlightLogActionMenu(
    hasDownloadPermission: boolean,
    hasDeletePermission: boolean,
    hasMovePermission: boolean,
    hasTagPermission: boolean,
    hasRenamePermission: boolean
  ): ActionMenu[] {
    let actions = [];

    if (hasTagPermission) {
      actions = actions.concat([actionMenuItem[MenuActions.TAGS], actionMenuItem[MenuActionType.SEPARATOR]]);
    }

    actions.push(actionMenuItem[MenuActions.SHARE]);

    if (hasDownloadPermission) {
      actions.push(actionMenuItem[MenuActions.DOWNLOAD]);
    }

    if (hasRenamePermission) {
      actions.push(actionMenuItem[MenuActions.RENAME]);
    }

    if (hasMovePermission) {
      actions.push(actionMenuItem[MenuActions.MOVE]);
    }

    if (hasDeletePermission) {
      actions = actions.concat([actionMenuItem[MenuActionType.SEPARATOR], actionMenuItem[MenuActions.DELETE]]);
    }

    if (actions.length === 1 && actions[0] === actionMenuItem[MenuActionType.SEPARATOR]) {
      return [];
    }

    return actions;
  }

  public handleShiftSelection(
    libraryItem: LibraryItem,
    rowIndex: number,
    itemIndex: number,
    libraryItemsGrid: LibraryItem[][]
  ): void {
    if (!this.hasPressedShiftKey) {
      this.hasPressedShiftKey = true;
    }

    const [startRowIndex, endRowIndex] = this.getSelectionRowRange(rowIndex);
    const [startItemIndex, endItemIndex] = this.getSelectionItemRange(
      startRowIndex,
      endRowIndex,
      rowIndex,
      itemIndex,
      libraryItemsGrid
    );

    this.selectItemsInRange(libraryItemsGrid, startRowIndex, startItemIndex, endRowIndex, endItemIndex);
  }

  public handleSingleSelection(libraryItem: LibraryItem, rowIndex: number, itemIndex: number): void {
    if (this.hasPressedShiftKey) {
      this.hasPressedShiftKey = false;
      this.unselectAllLibraryItems();
    }

    this.selectLibraryItem(libraryItem.id);
    if (
      this.topSelectedLibraryItem.rowIndex !== -1 &&
      this.topSelectedLibraryItem.itemIndex !== -1 &&
      rowIndex < this.topSelectedLibraryItem.rowIndex &&
      itemIndex < this.topSelectedLibraryItem.itemIndex
    ) {
      this.topSelectedLibraryItem.rowIndex = rowIndex;
      this.topSelectedLibraryItem.itemIndex = itemIndex;
    }

    if (this.topSelectedLibraryItem.rowIndex === -1 && this.topSelectedLibraryItem.itemIndex === -1) {
      this.topSelectedLibraryItem.rowIndex = rowIndex;
      this.topSelectedLibraryItem.itemIndex = itemIndex;
    }
  }

  public isTopItemSelected(): boolean {
    return this.topSelectedLibraryItem.rowIndex !== -1 && this.topSelectedLibraryItem.itemIndex !== -1;
  }

  public selectItemsInRange(
    libraryItemsGrid: LibraryItem[][],
    startRow: number,
    startItem: number,
    endRow: number,
    endItem: number
  ): void {
    for (let r = startRow; r <= endRow; r++) {
      for (
        let i = r === startRow ? startItem : 0;
        i <= (r === endRow ? endItem : libraryItemsGrid[r].length - 1);
        i++
      ) {
        this.selectLibraryItem(libraryItemsGrid[r][i].id);
      }
    }
  }

  public getSelectionRowRange(currentRowIndex: number): [number, number] {
    const isSelectingBackwards = this.isSelectingBackwards(currentRowIndex, 0);

    return isSelectingBackwards
      ? [currentRowIndex, this.topSelectedLibraryItem.rowIndex]
      : [this.topSelectedLibraryItem.rowIndex, currentRowIndex];
  }

  private isSelectingBackwards(currentRowIndex: number, currentItemIndex: number): boolean {
    return (
      currentRowIndex < this.topSelectedLibraryItem.rowIndex ||
      (currentRowIndex === this.topSelectedLibraryItem.rowIndex &&
        currentItemIndex < this.topSelectedLibraryItem.itemIndex)
    );
  }

  public getSelectionItemRange(
    startRowIndex: number,
    endRowIndex: number,
    currentRowIndex: number,
    currentItemIndex: number,
    libraryItemsGrid: LibraryItem[][]
  ): [number, number] {
    const isSelectingBackwards = this.isSelectingBackwards(currentRowIndex, currentItemIndex);

    const startItemIndex = isSelectingBackwards
      ? currentItemIndex
      : startRowIndex === this.topSelectedLibraryItem.rowIndex
      ? this.topSelectedLibraryItem.itemIndex
      : 0;
    const endItemIndex = isSelectingBackwards
      ? endRowIndex === this.topSelectedLibraryItem.rowIndex
        ? this.topSelectedLibraryItem.itemIndex
        : libraryItemsGrid[currentRowIndex].length - 1
      : currentItemIndex;

    return [startItemIndex, endItemIndex];
  }

  public changeViewMode(viewMode: LibraryViewMode) {
    this.libraryStoreFacadeService.changeViewMode(viewMode);
  }

  public openImageViewerOnNewTab(libraryItemId: string): void {
    window.open(`/secure/view/${libraryItemId}`);
  }
}

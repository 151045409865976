export enum LibraryMimeType {
  video = 'video',
  image = 'image',
  folder = 'application/vnd.unleashlive.folder',
  model = 'application/vnd.unleashlive.model',
  two_d_model = 'application/vnd.unleashlive.model.2d',
  three_d_model = 'application/vnd.unleashlive.model.3d',
  vr_model = 'application/vnd.unleashlive.model.vr',
  point_cloud = 'application/vnd.unleashlive.model.pc',
  PDF = 'application/pdf'
}

export enum LIBRARY_ITEM_TYPE_MODEL {
  IMAGE = 'I',
  VIDEO = 'V',
  MODEL = 'M',
  FOLDER = 'F',
  REPORT = 'R',
  FLIGHT_LOG = 'FL'
}

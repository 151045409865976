<form [formGroup]="form">
  <div class="container">
    <mat-checkbox (change)="emitChannelChange()" formControlName="email">{{ 'common.email' | translate }}</mat-checkbox>
    <mat-checkbox (change)="emitChannelChange()" formControlName="sms">{{ 'common.sms' | translate }}</mat-checkbox>
  </div>
</form>

<unleash-alert
  [label]="'live.manage-zones-dialog.notificationsTab.notificationChannelTab.label' | translate"
  type="warning-light"
  buttonText="{{ 'live.manage-zones-dialog.notificationsTab.notificationChannelTab.letUsKnow' | translate }}"
  (clicked)="emitOpenHelp()"
  buttonClass="let-us-know-button"
></unleash-alert>

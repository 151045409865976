import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AclApiService {
  constructor(private apiGateway: ApiGateway) {}

  public acl$(): Observable<any> {
    return this.apiGateway.get('acl');
  }
}

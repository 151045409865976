import {Component, ChangeDetectionStrategy, Input, EventEmitter, Output} from '@angular/core';
import {TeamActions, TeamRole, TeamTable} from '@app/profile/models/team.model';
import {UserActions} from '@app/profile/models/user-actions.model';
import {UserAction, UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamDetailComponent {
  @Input() public team: TeamTable = {teamId: '', lastActive: 0, name: '', createdAt: 0, members: 3};
  @Input() public userBySelectedTeam: UserTable[] = [];
  @Input() public roles: TeamRole[];
  @Input() public hasMoreThanOneAdmin: boolean = false;
  @Output() public back: EventEmitter<void> = new EventEmitter();
  @Output() public openAddUsersToTeam: EventEmitter<void> = new EventEmitter();
  @Output() public action: EventEmitter<UserAction> = new EventEmitter();
  @Output() public userTeamAction: EventEmitter<UserAction> = new EventEmitter();
  @Output() public userAction: EventEmitter<UserAction> = new EventEmitter();

  public menuItems = [
    {
      name: 'Delete',
      type: TeamActions.deleteUserFromTeam,
      icon: 'delete',
      iconColor: 'warn'
    }
  ];

  public emitBack(): void {
    this.back.emit();
  }

  public emitOpenAddUsersToTeam(): void {
    this.openAddUsersToTeam.emit();
  }

  public emitAction(event): void {
    if (event.action === TeamActions.deleteUserFromTeam) {
      this.userTeamAction.emit(event);
      return;
    }

    if (event.action === UserActions.editRole) {
      this.userAction.emit(event);
      return;
    }

    this.action.emit(event);
  }
}

<div
  #data
  style="visibility: visible; position: fixed"
  [style.left]="menuTopLeftPositionX$ | async"
  [style.top]="menuTopLeftPositionY$ | async"
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="matMenu"
  (menuClosed)="menuClosed()"
></div>
<ng-content></ng-content>

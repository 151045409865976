import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {SignUpForm} from './sign-up.model';
import {emailRegex, passwordRegex} from '../../regex';
import {HelpService} from '@app/core/services/help.service';

@Component({
  selector: 'unleash-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent implements OnInit {
  @Input() public signInForm: SignUpForm;
  @Input() public inProgress: boolean;
  @Input() public isOrganizationSignUp: boolean;
  @Output() public signUp: EventEmitter<Partial<SignUpForm>> = new EventEmitter();
  @Output() public facebook: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public google: EventEmitter<boolean> = new EventEmitter<boolean>();

  public hasToHide: boolean = true;

  public form = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
    terms: [false, Validators.requiredTrue]
  });

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.form.setValue(this.signInForm);
  }

  public clickSignUpButton(): void {
    if (this.form.valid) {
      this.signUp.emit(this.form.value);
    } else {
      this.form.controls.email.markAsTouched();
      this.form.controls.password.markAsTouched();
      this.form.controls.terms.markAsTouched();
    }
  }

  public cleanTermsAndConditionError(): void {
    this.form.controls.terms.markAsUntouched();
  }
}

<div class="marker-info">
  <div class="marker-info__title">
    <img src="/assets/images/list.svg" />
    {{ assetName }}
  </div>
  <div
    *ngIf="feature.properties.state === geojsonFeatureState.COMPLETED || !feature.properties.state"
    class="marker-info__image"
  >
    <img [src]="parsedUrl" />
  </div>
  <div *ngIf="feature.properties.poleId" class="marker-info__data">
    <span class="marker-info__data-title">{{ 'atlas.markerPopup.poleId' | translate }}</span>
    <span class="marker-info__data-content">{{ feature.properties.poleId }}</span>
  </div>
  <div *ngIf="feature.properties.state" class="marker-info__data">
    <span class="marker-info__data-title">{{ 'atlas.markerPopup.status' | translate }}</span>
    <ng-container [ngSwitch]="feature.properties.state">
      <ng-container *ngSwitchCase="geojsonFeatureState.COMPLETED">
        <span class="marker-info__data-content">{{ 'atlas.markerMenu.complete' | translate }}</span>
      </ng-container>
      <ng-container *ngSwitchCase="geojsonFeatureState.NOT_COMPLETED">
        <span class="marker-info__data-content">{{ 'atlas.markerMenu.incomplete' | translate }}</span>
      </ng-container>
      <ng-container *ngSwitchCase="geojsonFeatureState.IN_PROGRESS">
        <span class="marker-info__data-content">{{ 'atlas.markerMenu.inProgress' | translate }}</span>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="!feature.properties.state && feature.properties.name" class="marker-info__data">
    <span class="marker-info__data-title">{{ 'atlas.markerPopup.fileName' | translate }}</span>
    <span class="marker-info__data-content">{{ feature.properties.name }}</span>
  </div>
</div>

<h3 class="subtitle">{{ 'common.delete' | translate }}</h3>

<mat-card>
  <mat-card-content>
    <div class="data-delete-text">
      {{ 'profile.dataPrivacy.description' | translate }}
    </div>
    <button mat-raised-button color="warn" (click)="deleteAllMedia()">
      {{ 'profile.dataPrivacy.deleteMedia' | translate | uppercase }}
    </button>
  </mat-card-content>
</mat-card>

import {createAction, props} from '@ngrx/store';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';

import {StreamingErrors} from '../models/streaming-errors.models';
import {Addon} from '@app/store/addon/models/addon';
import {Stream} from '@app/core/models/api/stream.model';
import {GridLayout, GridState} from '../models/grid-state.model';

export enum LiveActionTypes {}

export const showCopyUrlMessage = createAction('[Streams] translate copy url event');
export const enablePublicLivePage = createAction(
  '[Streams] enable public live page',
  props<{companyId: string; shared: {[key: string]: boolean}}>()
);
export const generateCompanySlug = createAction(
  '[Streams] generate company slug',
  props<{companyId: string; companySlug: string}>()
);
export const loadingSlug = createAction('[Streams] loading slug');
export const getStreamKey = createAction('[Streams] get user stream key');
export const getAllDevices = createAction('[Streams] get all user devices');
export const getCompanyInfo = createAction('[Streams] get company info');
export const enablingLivePage = createAction('[Streams] enabling live page');
export const supportIOSErrorMessage = createAction('[Streams] supporting IOS error');
export const verifyDevicesAndJoinChannel = createAction(
  '[Streams] verify devices and join channel',
  props<{deviceId: string}>()
);
export const manuallyPermissionErrorMessage = createAction('[Streams] manually permission error message');
export const updateErrorMessage = createAction('[Streams] update error message', props<{errorMessage: string}>());
export const updateErrorCode = createAction('[Streams] update error code', props<{errorCode: StreamingErrors}>());
export const updateDevice = createAction('[Streams] update device params', props<{device: UserDeviceJoined}>());
export const getAgoraToken = createAction('[Streams] get agora token', props<{deviceId: string}>());
export const startLiveAi = createAction(
  '[Streams] start live ai',
  props<{device: UserDeviceJoined; model: Addon; streamKey: string}>()
);

export const stopLiveAi = createAction('[Streams] stop live ai', props<{deviceId: string; modelId: string}>());
export const waitForUserAndJoinChannel = createAction(
  '[Streams] wait for user and channel to join channel',
  props<{deviceId: string}>()
);
export const askPermissionErrorMessage = createAction('[Streams] ask permission error message');
export const joinChannel = createAction('[Streams] join channel');
export const renewToken = createAction('[Streams] renew token');

export const watchForStreamingDevicesChange = createAction('[Streams] watch for streaming devices change');
export const removeLiveDevice = createAction(
  '[Streams] remove live device',
  props<{payload: {device: UserDeviceJoined}}>()
);
export const setLiveDevicesId = createAction(
  '[Streams] set live devices id',
  props<{payload: {liveDevicesId: string[]}}>()
);
export const addLiveDevice = createAction('[Streams] add live device', props<{payload: {device: UserDeviceJoined}}>());
export const setIsInitialized = createAction(
  '[Streams] set is initialized',
  props<{payload: {isInitialized: boolean}}>()
);
export const watchForStreamSourceChanges = createAction('[Streams] watch for stream source changes');
export const watchForRestreamChanges = createAction('[Streams] watch for restream changes');
export const startPolling = createAction('[Streams] start polling');
export const addDevice = createAction('[Streams] add device');
export const setLayoutState = createAction('[Streams] set layout state', props<{payload: {layoutState: GridLayout}}>());
export const watchSelectModelWhenDeviceChange = createAction('[Streams] watch select model when device change');
export const setIsSelectedDeviceRunningModel = createAction(
  '[Streams] set is selected device running model',
  props<{payload: {isSelectedDeviceRunningModel: boolean}}>()
);
export const setDisplayedDeviceIds = createAction(
  '[Streams] set displayed device ids',
  props<{payload: {displayedDeviceIds: string[]}}>()
);
export const setSelectedDeviceId = createAction(
  '[Streams] set selected device id',
  props<{payload: {selectedDeviceId: string}}>()
);
export const updateGridState = createAction('[Streams] update grid state', props<{payload: {gridState: GridState}}>());

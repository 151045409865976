import {createAction, props} from '@ngrx/store';
import {SignUpForm} from '../components/sign-up/sign-up.model';

export const authInitializationStarted = createAction('[Auth] Initialization Started');

export const authInitializationFinished = createAction('[Auth] Initialization Finished');

export const authLogin = createAction('[Auth] Login');

export const authLogout = createAction('[Auth] Logout', props<{isDeveloperMode: boolean}>());

export const authSignIn = createAction('[Auth] SignIn', props<{form: Partial<SignUpForm>}>());
export const authPostRegistrationSignIn = createAction(
  '[Auth] authPostRegistrationSignIn',
  props<{form: Partial<SignUpForm>}>()
);

export const authSignedIn = createAction('[Auth] SignedIn', props<{payload: {userId: string}}>());
export const authSignedOut = createAction('[Auth] SignedOut');

export const authUserCreationRequired = createAction('[Auth] User Creation Required');

export const authUserCreationFinished = createAction(
  '[Auth] User Creation Finished',
  props<{nickname; email; developer}>()
);

export const authPlanSelectionRequired = createAction(
  '[Auth] Plan selection required',
  props<{isDeveloperMode: boolean}>()
);

export const authRegistrationFinished = createAction('[Auth] Registration finished');

export const authTokensRefreshed = createAction('[Auth] Tokens Refreshed');

export const authSessionExpired = createAction('[Auth] Session Expired');

export const authPlanExpired = createAction('[Auth] Plan expired');

export const authPlanPaid = createAction('[Auth] Plan paid');

export const authIoTInitRequired = createAction('[Auth] IoT init required');

export const authIoTInitFinished = createAction('[Auth] IoT init finished');

export const actionSetupAwsMarketPlaceToken = createAction(
  '[Auth] Setup AWS marketplace token',
  props<{awsMarketplaceToken: string}>()
);

export const actionSetupPromoCode = createAction('[Auth] Setup Promo Code', props<{promoCode: string}>());

export const actionClearAwsMarketPlaceToken = createAction('[Auth] Clear AWS marketplace token');

export const actionSetupOrganizationName = createAction(
  '[Auth] Setup Organization Name',
  props<{payload: {organizationName: string}}>()
);

import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'unleash-rule-name',
  templateUrl: './rule-name.component.html',
  styleUrls: ['./rule-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleNameComponent implements OnInit {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({name: new UntypedFormControl('', Validators.required)});
  @Input() editMode: boolean = false;
  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() done: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  emitEdit(): void {
    this.edit.emit();
  }

  emitDone(): void {
    this.done.emit(this.form.value.name);
  }
}

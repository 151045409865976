<section class="sidebar-calibration">
  <ng-container [ngSwitch]="currentLayout$ | async">
    <ng-container *ngSwitchCase="calibrationLayout.empty">
      <ua-calibration-sidebar-empty></ua-calibration-sidebar-empty>
    </ng-container>
    <ng-container *ngSwitchCase="calibrationLayout.addCalibration">
      <unleash-calibration-sidebar-add></unleash-calibration-sidebar-add>
    </ng-container>
    <ng-container *ngSwitchCase="calibrationLayout.addPerspectiveCalibration">
      <ua-calibration-perspective></ua-calibration-perspective>
    </ng-container>
    <ng-container *ngSwitchCase="calibrationLayout.list">
      <ua-calibration-sidebar-list></ua-calibration-sidebar-list>
    </ng-container>
  </ng-container>
</section>

<section class="canvas-calibration">
  <div
    *ngIf="
      (currentCalibrationSidebarMode$ | async) === calibrationSidebarMode.EDIT_CANVAS_POINT ||
        (currentCalibrationSidebarMode$ | async) === calibrationSidebarMode.EDIT_MAP_POINT;
      else defaultTemplate
    "
    class="calibration-canvas-main"
  >
    <unleash-edit-calibration-marker></unleash-edit-calibration-marker>
  </div>
  <ng-template #defaultTemplate>
    <ua-calibration-annotation [currentLayout]="currentLayout$ | async"></ua-calibration-annotation>
    <ng-container *ngIf="hint$ | async as hint">
      <div class="alert-container">
        <unleash-alert class="alert" [label]="hint" type="warning-dark" iconName="info"></unleash-alert>
      </div>
    </ng-container>
  </ng-template>
</section>

<h2 mat-dialog-title>
  {{ 'atlas.layerNotFound.thereWasAnIssueLoadingTheLinkedLayer' | translate }}
</h2>
<mat-dialog-content>
  {{
    'atlas.layerNotFound.checkCorrectAccountAndCheckIfTheLayerHasBeenDeleted'
      | translate
  }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    data-test-id="layer-not-found-close-button"
    color="primary"
    (click)="close()"
  >
    OK
  </button>
</mat-dialog-actions>

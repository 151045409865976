<div class="header">
  <ng-container *ngIf="(hasMarketPlaceToken$ | async) || isDeveloperMode; else allStepsTemplate">
    <div class="header__steps">{{'auth.yourProfile.step2of3' | translate}}</div>
  </ng-container>
  <ng-template #allStepsTemplate>
    <div class="header__steps">{{'auth.yourProfile.step2of4' | translate}}</div>
  </ng-template>
  <div class="header__links">
    <span class="header__link header__link--sign-out" (click)="signOut()"
      ><mat-icon class="icon">vpn_key</mat-icon> {{'common.signOut' | translate}}</span
    >

    <button mat-icon-button [matMenuTriggerFor]="menu" class="header__link header__link--more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="signOut()" class="header__menu-item">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'common.signOut' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="spacer"></div>
<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>

<unleash-alert
  class="unleash-alert form--fixed"
  *ngIf="errorMessage$ | async as errorMessage"
  type="error"
  [label]="errorMessage"
  iconName="close"
></unleash-alert>

<div class="title">
  <div class="title__text">{{'auth.yourProfile.yourProfile' | translate}}</div>
  <div class="title__sub-text">{{'auth.yourProfile.help' | translate}}</div>
</div>

<unleash-your-profile
  class="form form--fixed"
  [isInProgress]="isInProgress$ | async"
  [userProfileForm]="userProfileForm$ | async"
  [industries]="industries"
  [isDeveloperMode]="isDeveloperMode"
  [currentUser]="currentUser$ | async"
  [currentUserCompany]="currentUserCompany$ | async"
  (continue)="continue($event)"
></unleash-your-profile>
<div class="spacer"></div>

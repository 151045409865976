import {Pipe} from '@angular/core';
import {formatDistanceToNow} from 'date-fns';
import {timer, Observable} from 'rxjs';
import {map, distinctUntilChanged} from 'rxjs/operators';

@Pipe({
  name: 'uTimeAgo',
  standalone: true
})
export class TimeAgoPipe {
  private time: Date;
  private formatted$: Observable<string>;

  constructor() {
    this.formatted$ = timer(0, 1000).pipe(
      map(() => formatDistanceToNow(this.time, {addSuffix: true, includeSeconds: true})),
      distinctUntilChanged()
    );
  }

  public transform(value: any): Observable<string> {
    this.time = value;
    return this.formatted$;
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatIconRegistryModule} from '@app/core/modules/icon-register/icon-register.module';
import {CalibrationService} from '../../services/calibration.service';
import {CalibrationLayout} from '../../calibration.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'ua-calibration-sidebar-empty',
  templateUrl: './calibration-sidebar-empty.component.html',
  styleUrls: ['./calibration-sidebar-empty.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatIconRegistryModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalibrationSidebarEmptyComponent {
  constructor(private calibrationService: CalibrationService) {}

  public emitOpenCalibrateByPerspective(): void {
    this.calibrationService.setCurrentLayout(CalibrationLayout.addPerspectiveCalibration);
  }

  public emitOpenCalibrateBySceneMapping(): void {
    this.calibrationService.setCurrentLayout(CalibrationLayout.addCalibration);
  }
}

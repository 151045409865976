import {ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UntilDestroy} from '@ngneat/until-destroy';

import {StreamPlayer, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserService} from '@app/core/services/api/user.service';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {environment} from 'environments/environment';
import {combineLatest, Subscription, of, zip} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {WebrtcService} from '../../../core/services/api/webrtc.service';
import {FourGridLayoutService} from './four-grid-layout.service';
import {Addon} from '@app/store/addon/models/addon';
import {ManagerZonesStoreFacadeService} from '@app/shared/manage-zones-dialog/services/manager-zones-store-facade.service';
import {UnleashAgoraService, WebrtcPlayerComponent} from 'unleash-components/dist/webrtc-player';
import {LiveFacadeService} from '@app/live/services/live-facade.service';
import {GridLayout} from '@app/live/models/grid-state.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-four-grid-layout',
  templateUrl: './four-grid-layout.component.html',
  styleUrls: ['./four-grid-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FourGridLayoutComponent implements OnInit {
  @ViewChildren(WebrtcPlayerComponent) public webrtcPlayers: QueryList<WebrtcPlayerComponent>;

  public streamPlayer = StreamPlayer;
  // eslint-disable-next-line no-magic-numbers
  public MAX_NUMBER_OF_STREAMS: number = 4;
  public streamAppId = environment.PWA_STREAMING_APP_ID;

  public rawStream: Partial<Addon> = {
    id: 'RAW_STREAM',
    name: 'Raw stream',
    subtitle: 'No AI activated',
    description: 'Watch your raw live stream without any A.I. analysis.'
  };
  public companyLogo: string;
  public selectedDeviceId$ = this.liveStreamPageService.selectedDeviceId$.pipe(shareReplay(1));
  public layoutState$ = this.liveStreamPageService.layoutState$;
  public gridState$ = this.liveStreamPageService.gridState$;
  public liveDevices$ = this.userService.liveDevices$.pipe(
    distinctUntilChanged((prev, curr) => {
      if (!!prev && !!curr) {
        return (
          prev
            .map(
              device =>
                `${device.id}-${device.selectedModel}-${device.waitingModels ? device.waitingModels.join('') : ''}-${
                  device.runningModels ? device.runningModels.join('') : ''
                }`
            )
            .join() ===
          curr
            .map(
              device =>
                `${device.id}-${device.selectedModel}-${device.waitingModels ? device.waitingModels.join('') : ''}-${
                  device.runningModels ? device.runningModels.join('') : ''
                }`
            )
            .join()
        );
      }
      return false;
    }),
    switchMap(liveDevices => {
      const tokenDevice = liveDevices.map(device => {
        let deviceWithToken$ = of({...device});
        if (device.player === this.streamPlayer.WEBRTC) {
          deviceWithToken$ = this.webrtcService
            .getWebRTCSubscriberToken(device.deviceId || device.id)
            .pipe(map(token => ({...device, agoraToken: token})));
        }
        return deviceWithToken$;
      });
      if (!tokenDevice.length) {
        this.setSelectedDevice(null);
      }
      return combineLatest(tokenDevice);
    }),
    map(devices => {
      if (devices.length > 0) {
        this.selectedDeviceId$.pipe(take(1)).subscribe(selectedDeviceId => {
          this.setSelectedDevice(selectedDeviceId || devices[0].id);
        });
      }

      this.liveFacadeService.gridState$.pipe(take(1)).subscribe(gridState => {
        const gridStateToUpdate = {};
        for (let index = 0; index < devices.length; index++) {
          const device = devices[index];

          if (index <= this.MAX_NUMBER_OF_STREAMS) {
            gridStateToUpdate[device.id] = this.getClassByIndex(index);
          } else {
            gridStateToUpdate[device.id] = '';
          }
        }

        this.liveFacadeService.updateGridState(gridStateToUpdate);
      });

      let numberOfDisplayedDevices = 0;
      const devicesToUpdate = {};
      const newDevices = [...devices].map((device, index) => {
        let cloneDevice = JSON.parse(JSON.stringify(device));

        if (index <= this.MAX_NUMBER_OF_STREAMS) {
          numberOfDisplayedDevices++;
          devicesToUpdate[cloneDevice.id] = {isDisplayed: true};
          return {...cloneDevice, isDisplayed: true};
        } else {
          devicesToUpdate[cloneDevice.id] = {isDisplayed: false};
          return {...cloneDevice, isDisplayed: false};
        }
      });

      if (numberOfDisplayedDevices === 1) {
        this.setState(GridLayout.STATE_TWO);
      } else {
        this.setState(GridLayout.STATE_ONE);
      }

      if (numberOfDisplayedDevices === 0) {
        this.setSelectedDevice(null);
      }
      this.userService.updateDevicesCache(devicesToUpdate);
      return newDevices;
    })
  );

  private setDeviceOnGridSubscription: Subscription;
  private triggerSnapshotSub: Subscription;

  constructor(
    private fourGridLayoutService: FourGridLayoutService,
    private liveStreamPageService: LiveStreamPageService,
    public webrtcService: WebrtcService,
    public dialog: MatDialog,
    private userService: UserService,
    private managerZonesStoreFacadeService: ManagerZonesStoreFacadeService,
    private unleashAgoraService: UnleashAgoraService,
    private liveFacadeService: LiveFacadeService
  ) {
    this.triggerSnapshotSub = this.unleashAgoraService.triggerSnapshot$.subscribe(playerId => {
      const webrtcPlayer = this.webrtcPlayers.find(player => player.deviceId === playerId);
      if (webrtcPlayer) {
        webrtcPlayer.captureSnapshot();
      }
    });
  }

  public ngOnInit() {
    this.userService.myCompany$.pipe(take(1)).subscribe(company => {
      this.companyLogo = company.logo;
    });

    this.setDeviceOnGridSubscription = this.fourGridLayoutService.setDeviceOnGrid$
      .pipe(filter(item => !!item))
      .subscribe(item => {
        zip(this.userService.liveDevices$, this.selectedDeviceId$, this.liveFacadeService.gridState$)
          .pipe(take(1))
          .subscribe(([liveDevices, selectedDeviceId, gridState]) => {
            const selectedDevice = item.hasToSwitchToCinema
              ? liveDevices.find(displayDevice => gridState[displayDevice.id] === 'one')
              : liveDevices.find(displayDevice => displayDevice.id === selectedDeviceId);
            const deviceToSwitch = liveDevices.find(liveDevice => liveDevice.id === item.device.id);

            if (!selectedDevice || !deviceToSwitch) {
              return;
            }

            const gridStateToUpdate = {
              [selectedDevice.id]: gridState[deviceToSwitch.id],
              [deviceToSwitch.id]: gridState[selectedDevice.id]
            };

            this.liveFacadeService.updateGridState(gridStateToUpdate);
          });
      });
  }

  public setSelectedDevice(deviceId: string) {
    this.liveStreamPageService.setSelectedDeviceId(deviceId || null);
  }

  public trackById(index: number, device: UserDeviceJoined) {
    if (!device) {
      return;
    }
    let customId = `${device.id}`;
    const runningModels = !!device.runningModels
      ? device.runningModels.map(modelId => modelId).reduce((prev, curr) => prev + '-' + curr, '')
      : '';
    const waitingModels = !!device.waitingModels
      ? device.waitingModels.map(modelId => modelId).reduce((prev, curr) => prev + '-' + curr, '')
      : '';

    if (device.selectedModel) {
      customId = `${device.id}-${device.selectedModel}-${runningModels}-${waitingModels}`;
    }

    return customId;
  }

  public setState(state: GridLayout) {
    this.liveFacadeService.setLayoutState(state);
  }

  public async catchSnapshot(imageUrl: string) {
    const {width, height} = await this.getImageSizeFromBase64(imageUrl);
    this.managerZonesStoreFacadeService.setImageSnapshot({
      data: imageUrl,
      height,
      width
    });
  }

  private getClassByIndex(index: number) {
    const INDEX_PLAYER_ONE = 0;
    const INDEX_PLAYER_TWO = 1;
    const INDEX_PLAYER_THREE = 2;
    const INDEX_PLAYER_FOUR = 3;

    switch (index) {
      case INDEX_PLAYER_ONE:
        return 'one';
      case INDEX_PLAYER_TWO:
        return 'two';
      case INDEX_PLAYER_THREE:
        return 'three';
      case INDEX_PLAYER_FOUR:
        return 'four';
      default:
        return '';
    }
  }

  private getImageSizeFromBase64(base64string: string): Promise<{width: number; height: number}> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };

      img.onerror = error => {
        reject(new Error(`Failed to load image: ${error}`));
      };

      img.src = base64string;
    });
  }
}

import {AfterContentInit, Directive, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {BehaviorSubject, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Directive({
  selector: '[responsiveTiles]',
  exportAs: 'tiles'
})
export class TilesDirective implements OnDestroy, AfterContentInit {
  @Output() colsChange: EventEmitter<number> = new EventEmitter();
  @Input() sizeColMap = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5
  };

  cols$: BehaviorSubject<number> = new BehaviorSubject(null);
  private mediaSub: Subscription;

  constructor(private observableMedia: MediaObserver) {
    this.observeResponsiveColLayout();
  }

  ngAfterContentInit() {
    // fixes via workaround "expression has changed after it was checked"
    // setTimeout(this.observeResponsiveColLayout.bind(this));
  }

  ngOnDestroy(): void {
    if (!!this.mediaSub) {
      this.mediaSub.unsubscribe();
    }
  }

  private observeResponsiveColLayout() {
    const getAlias = (mediaChange: MediaChange[]) => {
      const indexFirstPriorityMedia = 0;
      return mediaChange[indexFirstPriorityMedia].mqAlias;
    };
    this.mediaSub = this.observableMedia
      .asObservable()
      .pipe(distinctUntilChanged((p, c) => getAlias(p) === getAlias(c)))
      .subscribe(change => {
        const cols = this.sizeColMap[getAlias(change)];
        this.colsChange.emit(cols);
        this.cols$.next(cols);
      });
  }
}

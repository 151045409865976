export interface MapItem {
  name: string;
  description: string;
  icon: string;
  color: string;
}

export interface MapLegend {
  [key: string]: MapItem[];
}

export const MAP_LEGEND: MapLegend = {
  'Issue Severity': [
    {
      name: 'None',
      description: '',
      icon: 'info',
      color: '#006BA8'
    },
    {
      name: 'Low',
      description: 'No likely impact',
      icon: 'warning',
      color: '#F6B100'
    },
    {
      name: 'Medium',
      description: 'Possible threat in coming days',
      icon: 'warning',
      color: '#FF6932'
    },
    {
      name: 'High',
      description: 'Imminent threat',
      icon: 'warning',
      color: '#F00001'
    }
  ]
};

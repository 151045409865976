import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-teaser-message',
  templateUrl: './teaser-message.component.html',
  styleUrls: ['./teaser-message.component.scss']
})
export class TeaserMessageComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }
}

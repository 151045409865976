import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PlanTemplate} from '@app/auth/pages/payment/payment.config';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';

@Component({
  selector: 'unleash-plan-viewer',
  templateUrl: './plan-viewer.component.html',
  styleUrls: ['./plan-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanViewerComponent {
  @Input() plan: PlanTemplate;
  @Input() paymentPeriod: PaymentPeriod;
  @Input() isDeveloperMode: boolean;
}

<div class="container mat-elevation-z2">
  <div class="image-container content-item">
    <img src="/assets/icons/plans/done.svg" alt="plan done icon" />
  </div>
  <div class="title content-item">{{ 'plans.done.success' | translate }}</div>
  <div class="description content-item">
    <ng-container *ngIf="plan; else noPlanTemplate">
      {{ 'plans.done.description.0' | translate }}
      {{ (isAnnualPrice ? plan.priceAnnual * 12 : plan.priceMonthly) | number: '1.2-2' }}
      {{ 'plans.done.description.1' | translate }}
    </ng-container>
    <ng-template #noPlanTemplate>
      {{ 'plans.done.defaultDescription' | translate }}
    </ng-template>
  </div>
  <div class="buttons content-item">
    <button mat-raised-button color="primary" (click)="clickAction()">
      {{ 'plans.currentPlan.returnToAccount' | translate }}
    </button>
  </div>
</div>

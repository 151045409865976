import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, firstValueFrom } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CompanyModel } from '@app/core/models/api/company-model';
import { selectCompany, selectUserId, selectUserTeamId } from '@app/store/user/user.selectors';
import { selectSelectedDevice } from '@app/flights/store/remote-cockpit/remote-cockpit.selectors';

export enum AutoflyScope {
  TEAM = 'team',
  USER = 'user'
}
export enum IotServiceType {
  MESSAGES = 'MESSAGES',
  COMMANDS = 'COMMANDS',
  SENSORS = 'SENSORS'
}

@Injectable({
  providedIn: 'root'
})
export class IotTopicService {
  private readonly TOPIC_PATTERNS: Record<IotServiceType, string> = {
    [IotServiceType.MESSAGES]: 'droneflight/${scopeId}/${deviceId}/messages',
    [IotServiceType.COMMANDS]: 'droneflight/${scopeId}/${deviceId}/commands',
    [IotServiceType.SENSORS]: 'droneflight/${scopeId}/${deviceId}/sensors'
  };

  constructor(private store: Store) {}

  async getTopicName(topicType: IotServiceType): Promise<string> {
    const [scopeId, deviceId] = await Promise.all([
      this.getScopeId(),
      this.getDeviceId()
    ]);

    if (!deviceId) {
      throw new Error("No device selected. Please select a device before getting the topic name.");
    }

    const topic = this.interpolate(this.TOPIC_PATTERNS[topicType], scopeId, deviceId);
    console.info('IoT topic:', topic);
    return topic;
  }

  private async getScopeId(): Promise<string> {
    const scopeIdObservable = combineLatest([
      this.store.pipe(select(selectCompany)),
      this.store.pipe(select(selectUserTeamId)),
      this.store.pipe(select(selectUserId))
    ]).pipe(
      filter(([company, teamId, userId]) => !!company && !!teamId && !!userId),
      take(1),
      map(([company, teamId, userId]) => company.autoflyScope === AutoflyScope.TEAM ? teamId : userId)
    );

    return firstValueFrom(scopeIdObservable);
  }

  private async getDeviceId(): Promise<string | null> {
    return firstValueFrom(
      this.store.pipe(
        select(selectSelectedDevice),
        take(1),
        map(device => device?.id ?? null)
      )
    );
  }

  private interpolate(pattern: string, scopeId: string, deviceId: string): string {
    if (!scopeId) throw new Error("Can't resolve topic without scopeId!");
    if (!deviceId) throw new Error("Can't resolve topic without deviceId!");
    return pattern.replace(/\${scopeId}/g, scopeId).replace(/\${deviceId}/g, deviceId);
  }
}

/* eslint-disable rxjs/finnish */
import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsApiService {
  constructor(private api: ApiGateway) {}

  public createTeam(teamName: string): Observable<any> {
    return this.api.post(`/organization/team`, null, {name: teamName});
  }

  public deleteTeam(teamId: string): Observable<any> {
    return this.api.delete(`/organization/team/${teamId}`, null);
  }

  public editTeam({teamId, name}: {teamId: string; name: string}): Observable<any> {
    return this.api.patch(`/organization/team/${teamId}`, null, {name});
  }

  public editUserTeam({
    users,
    teamId
  }: {
    teamId: string;
    users: {userId: string; roles?: string[]; action: UpdateUserTeamAction}[];
  }): Observable<any> {
    return this.api.post(`/organization/team/${teamId}/users`, null, users);
  }
}

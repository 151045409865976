import {MatIconType} from '@app/core/models/gui/icon-type.model';

export enum MenuActions {
  ANALYZE = 'ANALYZE',
  ANNOTATE = 'ANNOTATE',
  IMAGE_MODEL = 'IMAGE_MODEL',
  EXPORT_ANNOTATIONS = 'EXPORT_ANNOTATIONS',
  EXPORT_CSV = 'EXPORT_CSV',
  EXPORT_COCO_JSON = 'EXPORT_COCO_JSON',
  DOWNLOAD = 'DOWNLOAD',
  RENAME = 'RENAME',
  MOVE = 'MOVE',
  DELETE = 'DELETE',
  VIDEO_MODEL = 'VIDEO_MODEL',
  OPEN_MODEL = 'OPEN_MODEL',
  VIEW_IN_ATLAS = 'VIEW_IN_ATLAS',
  REQUEST_EXPORT = 'REQUEST_EXPORT',
  TWO_D_MODEL = '2D_MODEL',
  THREE_D_MODEL = '3D_MODEL',
  VR_MODEL = 'VR_MODEL',
  POINTCLOUD = 'POINTCLOUD',
  EXPORT_MODEL = 'EXPORT_MODEL',
  EXPORT_TILES = 'EXPORT_TILES',
  EXPORT_ORTHO = 'EXPORT_ORTHO',
  EXPORT_3D = 'EXPORT_3D',
  EXPORT_DEM = 'EXPORT_DEM',
  EXPORT_LAS = 'EXPORT_LAS',
  EXPORT_ANALYTICS = 'EXPORT_ANALYTICS',
  TAGS = 'TAGS',
  SUMMARIZE = 'SUMMARIZE',
  FILECOUNT = 'FILECOUNT',
  DOWNLOAD_ANALYTICS = 'DOWNLOAD_ANALYTICS',
  START_STREAMING = 'START_STREAMING',
  STREAMING_SHARE = 'STREAMING_SHARE',
  COPY_STREAMING_LINK_STREAMING = 'COPY_STREAMING_LINK_STREAMING',
  REGENERATE_SECURE_LINK_STREAMING = 'REGENERATE_SECURE_LINK_STREAMING',

  // TODO: Implement this actions
  RECOGNISE = 'RECOGNISE',
  CONCAT = 'CONCAT',
  COMPARE = 'COMPARE',
  SHARE = 'SHARE',
  ADD_TO_ATLAS = 'ADD_TO_ATLAS',
  GO_TO_TIMESTAMP = 'GO_TO_TIMESTAMP',
  OPEN_PDF = 'OPEN_PDF',
  ASSIGN_TO_ASSETS = 'ASSIGN_TO_ASSETS'
}

export enum MenuActionType {
  MENU,
  SEPARATOR
}

export interface ActionMenu {
  type: MenuActionType;
  name?: string;
  iconType?: MatIconType;
  iconName?: string;
  actionType?: MenuActions;
  color?: string;
  submenuItems?: ActionMenu[];
  hasMobileSeparator?: boolean;
  componentTemplateName?: any;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const actionMenuStreamTop: {[key: string]: ActionMenu} = {
  [MenuActions.STREAMING_SHARE]: {
    type: MenuActionType.MENU,
    name: 'Share',
    iconType: MatIconType.SimpleIcon,
    iconName: 'share',
    actionType: MenuActions.STREAMING_SHARE,
    color: 'white',
    submenuItems: [
      {
        type: MenuActionType.MENU,
        name: 'Copy Link',
        iconType: MatIconType.SvgIcon,
        iconName: 'link',
        color: 'white'
      },
      {
        type: MenuActionType.MENU,
        name: 'Re-generate Secure link',
        iconType: MatIconType.SimpleIcon,
        iconName: 'lock_plus',
        color: 'white'
      }
    ]
  }
};

export const actionMenuStreamBottom: {[key: string]: ActionMenu} = {
  [MenuActions.COPY_STREAMING_LINK_STREAMING]: {
    type: MenuActionType.MENU,
    name: 'Copy Link',
    iconType: MatIconType.SvgIcon,
    iconName: 'link',
    color: 'white',
    actionType: MenuActions.COPY_STREAMING_LINK_STREAMING
  },
  [MenuActions.REGENERATE_SECURE_LINK_STREAMING]: {
    type: MenuActionType.MENU,
    name: 'Re-generate Secure link',
    iconType: MatIconType.SimpleIcon,
    iconName: 'lock_plus',
    color: 'white',
    actionType: MenuActions.REGENERATE_SECURE_LINK_STREAMING
  }
};

export const actionMenuImageViewer: {[key: string]: ActionMenu} = {
  [MenuActions.ANALYZE]: {
    type: MenuActionType.MENU,
    name: 'Analyze',
    iconType: MatIconType.SvgIcon,
    iconName: 'u_analyze_white',
    actionType: MenuActions.ANALYZE,
    color: 'white'
  },
  [MenuActions.SUMMARIZE]: {
    type: MenuActionType.MENU,
    name: 'Summarize',
    iconType: MatIconType.SvgIcon,
    iconName: 'summarize',
    actionType: MenuActions.SUMMARIZE,
    color: 'white'
  },
  [MenuActions.EXPORT_ANNOTATIONS]: {
    type: MenuActionType.MENU,
    name: 'Export annotations',
    iconType: MatIconType.SimpleIcon,
    iconName: 'playlist_add_check',
    actionType: MenuActions.EXPORT_ANNOTATIONS,
    color: 'white',
    submenuItems: [
      {
        type: MenuActionType.MENU,
        name: 'Coco JSON',
        actionType: MenuActions.EXPORT_COCO_JSON,
        iconType: MatIconType.SvgIcon,
        iconName: 'coco-json'
      },
      {
        type: MenuActionType.MENU,
        name: 'CSV',
        actionType: MenuActions.EXPORT_CSV,
        iconType: MatIconType.SvgIcon,
        iconName: 'csv'
      }
    ]
  },
  [MenuActions.TAGS]: {
    type: MenuActionType.MENU,
    name: 'Tags',
    iconType: MatIconType.SvgIcon,
    iconName: 'tag',
    submenuItems: [
      {
        type: MenuActionType.MENU,
        name: '',
        componentTemplateName: 'tagsSearch'
      }
    ]
  },
  [MenuActions.VIEW_IN_ATLAS]: {
    type: MenuActionType.MENU,
    name: 'View in Atlas',
    iconType: MatIconType.SimpleIcon,
    iconName: 'place',
    actionType: MenuActions.VIEW_IN_ATLAS,
    color: 'white',
    hasMobileSeparator: true
  },
  [MenuActions.DOWNLOAD]: {
    type: MenuActionType.MENU,
    name: 'Download',
    iconType: MatIconType.SimpleIcon,
    iconName: 'file_download',
    actionType: MenuActions.DOWNLOAD,
    color: 'white'
  },
  [MenuActions.SHARE]: {
    type: MenuActionType.MENU,
    name: 'Share',
    iconType: MatIconType.SimpleIcon,
    iconName: 'share',
    actionType: MenuActions.SHARE,
    color: 'white'
  },
  [MenuActions.RENAME]: {
    type: MenuActionType.MENU,
    name: 'Rename',
    iconType: MatIconType.SimpleIcon,
    iconName: 'edit',
    actionType: MenuActions.RENAME,
    color: 'white'
  },
  [MenuActions.MOVE]: {
    type: MenuActionType.MENU,
    name: 'Move',
    iconType: MatIconType.SvgIcon,
    iconName: 'u_move_white',
    actionType: MenuActions.MOVE,
    hasMobileSeparator: true
  },
  [MenuActions.DELETE]: {
    type: MenuActionType.MENU,
    name: 'Delete',
    iconType: MatIconType.SimpleIcon,
    iconName: 'delete',
    actionType: MenuActions.DELETE,
    color: 'white'
  }
};

export const actionMenuItem: {[key: string]: ActionMenu} = {
  [MenuActions.ANALYZE]: {
    type: MenuActionType.MENU,
    name: 'Analyze',
    iconType: MatIconType.SvgIcon,
    iconName: 'analysis',
    actionType: MenuActions.ANALYZE
  },
  [MenuActions.ASSIGN_TO_ASSETS]: {
    type: MenuActionType.MENU,
    name: 'Assign to assets',
    iconType: MatIconType.SvgIcon,
    iconName: 'u_file_link_grey',
    actionType: MenuActions.ASSIGN_TO_ASSETS,
    color: 'white'
  },
  [MenuActions.GO_TO_TIMESTAMP]: {
    type: MenuActionType.MENU,
    name: 'Go to timestamp',
    iconType: MatIconType.SimpleIcon,
    iconName: 'fast_forward',
    actionType: MenuActions.GO_TO_TIMESTAMP
  },
  [MenuActions.SUMMARIZE]: {
    type: MenuActionType.MENU,
    name: 'Summarize',
    iconType: MatIconType.SvgIcon,
    iconName: 'summarize-black',
    actionType: MenuActions.SUMMARIZE
  },
  [MenuActions.ANNOTATE]: {
    type: MenuActionType.MENU,
    name: 'Annotate',
    iconType: MatIconType.SimpleIcon,
    iconName: 'label',
    actionType: MenuActions.ANNOTATE
  },
  [MenuActions.IMAGE_MODEL]: {
    type: MenuActionType.MENU,
    name: 'Create 2D/3D Model',
    iconType: MatIconType.SimpleIcon,
    iconName: 'burst_mode',
    actionType: MenuActions.IMAGE_MODEL
  },
  [MenuActions.VIDEO_MODEL]: {
    type: MenuActionType.MENU,
    name: 'Create 2D/3D Model',
    iconType: MatIconType.SimpleIcon,
    iconName: 'burst_mode',
    actionType: MenuActions.VIDEO_MODEL
  },
  [MenuActions.EXPORT_ANNOTATIONS]: {
    type: MenuActionType.MENU,
    name: 'Export annotations',
    iconType: MatIconType.SimpleIcon,
    iconName: 'playlist_add_check',
    actionType: MenuActions.EXPORT_ANNOTATIONS,
    submenuItems: []
  },
  [MenuActions.TAGS]: {
    type: MenuActionType.MENU,
    name: 'Tags',
    iconType: MatIconType.SvgIcon,
    iconName: 'tag',
    submenuItems: [
      {
        type: MenuActionType.MENU,
        name: '',
        componentTemplateName: 'tagsSearch'
      }
    ]
  },
  [MenuActions.EXPORT_COCO_JSON]: {
    type: MenuActionType.MENU,
    name: 'Coco JSON',
    iconName: 'coco-json',
    actionType: MenuActions.EXPORT_COCO_JSON
  },
  [MenuActions.EXPORT_ANALYTICS]: {
    type: MenuActionType.MENU,
    name: '',
    iconName: '',
    actionType: MenuActions.EXPORT_ANALYTICS
  },
  [MenuActions.EXPORT_CSV]: {
    type: MenuActionType.MENU,
    name: 'CSV',
    iconName: 'csv',
    actionType: MenuActions.EXPORT_CSV
  },
  [MenuActionType.SEPARATOR]: {
    type: MenuActionType.SEPARATOR
  },
  [MenuActions.DOWNLOAD]: {
    type: MenuActionType.MENU,
    name: 'Download',
    iconType: MatIconType.SimpleIcon,
    iconName: 'file_download',
    actionType: MenuActions.DOWNLOAD
  },
  [MenuActions.SHARE]: {
    type: MenuActionType.MENU,
    name: 'Share',
    iconType: MatIconType.SimpleIcon,
    iconName: 'share',
    actionType: MenuActions.SHARE,
    color: 'white'
  },
  [MenuActions.RENAME]: {
    type: MenuActionType.MENU,
    name: 'Rename',
    iconType: MatIconType.SimpleIcon,
    iconName: 'edit',
    actionType: MenuActions.RENAME
  },
  [MenuActions.MOVE]: {
    type: MenuActionType.MENU,
    name: 'Move',
    iconType: MatIconType.SvgIcon,
    iconName: 'u_move',
    actionType: MenuActions.MOVE
  },
  [MenuActions.DELETE]: {
    type: MenuActionType.MENU,
    name: 'Delete',
    iconType: MatIconType.SimpleIcon,
    iconName: 'delete',
    actionType: MenuActions.DELETE,
    color: 'warn'
  },
  [MenuActions.OPEN_MODEL]: {
    type: MenuActionType.MENU,
    name: 'Open Model',
    iconType: MatIconType.SimpleIcon,
    iconName: 'insert_drive_file',
    submenuItems: []
  },
  [MenuActions.EXPORT_MODEL]: {
    type: MenuActionType.MENU,
    name: 'Export',
    iconType: MatIconType.SimpleIcon,
    iconName: 'file_download',
    submenuItems: []
  },
  [MenuActions.POINTCLOUD]: {
    type: MenuActionType.MENU,
    name: 'Point Cloud',
    iconType: MatIconType.SimpleIcon,
    iconName: 'insert_drive_file',
    actionType: MenuActions.POINTCLOUD
  },
  [MenuActions.VR_MODEL]: {
    type: MenuActionType.MENU,
    name: 'VR Model',
    iconType: MatIconType.SimpleIcon,
    iconName: 'insert_drive_file',
    actionType: MenuActions.VR_MODEL
  },
  [MenuActions.THREE_D_MODEL]: {
    type: MenuActionType.MENU,
    name: '3D Model',
    iconType: MatIconType.SimpleIcon,
    iconName: 'insert_drive_file',
    actionType: MenuActions.THREE_D_MODEL
  },
  [MenuActions.TWO_D_MODEL]: {
    type: MenuActionType.MENU,
    name: '2D Model',
    iconType: MatIconType.SimpleIcon,
    iconName: 'insert_drive_file',
    actionType: MenuActions.TWO_D_MODEL
  },
  [MenuActions.VIEW_IN_ATLAS]: {
    type: MenuActionType.MENU,
    name: 'View in Atlas',
    iconType: MatIconType.SimpleIcon,
    iconName: 'language',
    actionType: MenuActions.VIEW_IN_ATLAS
  },
  [MenuActions.FILECOUNT]: {
    type: MenuActionType.MENU,
    name: 'Count Files',
    iconType: MatIconType.SvgIcon,
    iconName: 'file-info',
    actionType: MenuActions.FILECOUNT
  },
  [MenuActions.ADD_TO_ATLAS]: {
    type: MenuActionType.MENU,
    name: 'Add to Atlas',
    iconType: MatIconType.SvgIcon,
    iconName: 'geojson_gray',
    actionType: MenuActions.ADD_TO_ATLAS
  },

  [MenuActions.OPEN_PDF]: {
    type: MenuActionType.MENU,
    name: 'Open PDF',
    iconType: MatIconType.SimpleIcon,
    iconName: 'picture_as_pdf',
    actionType: MenuActions.OPEN_PDF
  },
  [MenuActions.DOWNLOAD_ANALYTICS]: {
    type: MenuActionType.MENU,
    name: 'Download analytics',
    iconType: MatIconType.SvgIcon,
    iconName: 'u_leading_analytics',
    actionType: MenuActions.DOWNLOAD_ANALYTICS,
    submenuItems: []
  }
};

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';

@Component({
  selector: 'unleash-properties-search-results',
  templateUrl: './properties-search-results.component.html',
  styleUrls: ['./properties-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesSearchResultsComponent {
  @Input() public asset: AtlasAssetModel = null;
  @Input() public searchFilterQuery: string = null;

  @Output() public goBack: EventEmitter<AtlasAssetModel> = new EventEmitter();
  @Output() public selectSearchedLayer: EventEmitter<any> = new EventEmitter();

  public propertiesToIgnore = ['type', 'leafletLayer'];
  public selectedKey = null;

  constructor(public sanitizer: DomSanitizer) {}

  public emitGoBack(): void {
    this.goBack.emit(this.asset);
  }

  public emitSelectSearchedLayer(layer) {
    this.selectSearchedLayer.emit(layer);
  }

  public setSelectedKey(selectedKey: number): void {
    this.selectedKey = selectedKey;
  }
}

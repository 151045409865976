import {Directive, HostListener} from '@angular/core';
import {MissionSettingsService, SELECTED_WAYPOINT} from '../services/mission-settings.service';

@Directive({
  selector: '[ulDisableDrawer]'
})
export class DisableDrawerDirective {
  constructor(private missionSettingsService: MissionSettingsService) {}
  @HostListener('click', ['$event'])
  public disableDrawer() {
    this.missionSettingsService.removeRoute();
    this.missionSettingsService.isNewRoute.next(false);
    this.missionSettingsService.stopDrawing();
    this.missionSettingsService.setEditingMission({
      mission: null,
      selectedWaypointIndex: SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED
    });
    this.missionSettingsService.undoStack.next([]);
  }
}

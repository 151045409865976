<div class="rename-title">{{ 'atlas.mission.renameMission' | translate }}</div>
<form class="rename-form" [formGroup]="form">
  <mat-form-field>
    <mat-label>{{ 'atlas.mission.name' | translate }}</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <div class="rename-form__actions">
    <button mat-dialog-close mat-button color="primary">{{ 'atlas.mission.cancel' | translate }}</button>
    <button (click)="renameMission()" class="rename-form__rename-action" mat-button [disabled]="!form.valid">
      {{ 'atlas.mission.rename' | translate }}
    </button>
  </div>
</form>

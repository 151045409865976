import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ForgotPasswordStepTwo} from './forgot-password-step-two.model';
import {passwordRegex} from '../../regex';

@Component({
  selector: 'unleash-forgot-password-step-two',
  templateUrl: './forgot-password-step-two.component.html',
  styleUrls: ['./forgot-password-step-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordStepTwoComponent implements OnInit {
  @Input() public forgotPasswordForm: ForgotPasswordStepTwo;
  @Input() public inProgress: boolean;
  @Output() public submitForm: EventEmitter<Partial<ForgotPasswordStepTwo>> = new EventEmitter<
    Partial<ForgotPasswordStepTwo>
  >();

  public form = this.fb.group(
    {
      code: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
      passwordConfirmation: ['', [Validators.required, Validators.pattern(passwordRegex)]]
    },
    {validator: this.passwordMatchValidator}
  );
  hidePassword: boolean = true;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.form.setValue(this.forgotPasswordForm);
  }

  public clickForgotPasswordButton() {
    if (this.form.controls.password.value !== this.form.controls.passwordConfirmation.value) {
      this.form.controls.passwordConfirmation.markAsTouched();
      this.form.controls.passwordConfirmation.markAsDirty();
      return;
    }

    if (this.form.valid) {
      this.submitForm.emit(this.form.value);
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }

  private passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password').value;
    const passwordConfirmation = formGroup.get('passwordConfirmation').value;
    if (password !== passwordConfirmation) {
      formGroup.get('passwordConfirmation').setErrors({mismatch: true});
    } else {
      return null;
    }
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {ConfirmRegistration} from './confirm-registration.model';

@Component({
  selector: 'unleash-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmRegistrationComponent implements OnInit {
  @Input() confirmRegistration: ConfirmRegistration;
  @Input() isInProgress: boolean;
  @Input() isResendInProgress: boolean;
  @Input() error: AuthErrorModel;

  @Output() reSendCode: EventEmitter<Pick<ConfirmRegistration, 'email'>> = new EventEmitter();
  @Output() confirm: EventEmitter<Partial<ConfirmRegistration>> = new EventEmitter();

  form = this.fb.group({
    code: ['', Validators.required],
    email: ['', Validators.required]
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form.setValue(this.confirmRegistration);
  }

  clickReSendButton() {
    this.reSendCode.emit({email: this.form.value.email});
  }

  clickConfirmButton() {
    if (this.form.valid) {
      this.confirm.emit({...this.form.value});
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }
}

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserService} from '../../core/services/api/user.service';
import {IoTSensorsData} from '../models/remote-cockpit.model';
import {AbstractTrackingService} from './abstract-tracking-service.service';
import {IotServiceType, IotTopicService} from '@app/flights/services/iot-topic.service';

@Injectable({
  providedIn: 'root'
})
export class SensorsDataIotService extends AbstractTrackingService<IoTSensorsData> {
  constructor(userService: UserService, store: Store, iotTopicService: IotTopicService) {
    super(userService, 1, IotServiceType.SENSORS, store, iotTopicService);
  }
}

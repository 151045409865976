import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {AddToGroupComponent} from '../components/add-to-group/add-to-group.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {MatDialog} from '@angular/material/dialog';

@Directive({
  selector: '[ulGroupAsset]',
  standalone: true
})
export class GroupAssetDirective {
  @Input('ulGroupAsset') public asset: AtlasAssetModel;

  constructor(private atlasService: AtlasService, private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  public groupAsset(): void {
    const asset = this.asset;
    this.selectAsset(asset);
    this.dialog.open(AddToGroupComponent, {
      ...STANDARD_DIALOG_CONFIG,
      panelClass: 'move-items-dialog',
      width: '80vw',
      maxWidth: '800px',
      data: {
        assets: [asset]
      },
      disableClose: true
    });
  }

  private selectAsset(asset: AtlasAssetModel) {
    if (!asset.isSelected) {
      asset.isSelected = true;
      this.atlasService.addSelectedLayer(asset);
    }
  }
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AtlasSelectMarkersService} from '@app/atlas/services/atlas-select-markers.service';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {GeojsonAssetLoaderService} from '@app/atlas/services/geojson-asset-loader.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Job} from '@app/jobs/models/jobs.models';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {JobsFacadeService} from '@app/jobs/services/jobs-facade.service';
import {TeamRole} from '@app/profile/models/team.model';
import {AtlasUploadService} from '@app/shared/services/upload/atlas-upload.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, catchError, from, of, switchMap, take, tap, zip} from 'rxjs';
import {JobUploadService} from '@app/shared/services/upload/job-upload.service';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'unleash-create-job',
  templateUrl: './create-job-dialog.component.html',
  styleUrls: ['./create-job-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateJobDialog {
  public teamMembers$: Observable<{[key: string]: {id: string; name: string; role: TeamRole}}> =
    this.jobsFacadeService.teamMembers$;
  public form = this.fb.group({
    jobName: new FormControl('', [Validators.required]),
    users: new FormControl([])
  });
  public hasToDisplaySpinner: boolean = false;

  constructor(
    private jobsFacadeService: JobsFacadeService,
    private fb: FormBuilder,
    private jobsApiService: JobsApiService,
    private atlasService: AtlasService,
    private translateService: TranslateService,
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateJobDialog>,
    private atlasUploadService: AtlasUploadService,
    private jobUploadService: JobUploadService,
    private geojsonAssetLoaderService: GeojsonAssetLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public async saveJob(): Promise<void> {
    this.hasToDisplaySpinner = true;
    const assetId = uuidv4();
    const features = this.data.selectedLayers.map(selectedLayer => selectedLayer.feature);
    const newJobParams = {
      title: this.form.value.jobName,
      userIds: this.form.value.users.map(user => user.id),
      assetId
    };

    if (features.length > 0) {
      const featureCollection = {
        type: 'FeatureCollection',
        features: features
      };
      await this.atlasSelectMarkersService.uploadJobFile(featureCollection, this.form.value.jobName);
      newJobParams['s3Path'] = this.jobUploadService.lastFilePath;
    }

    this.jobsApiService
      .createJob(newJobParams)
      .pipe(
        take(1),
        tap(response => {
          this.jobsFacadeService.createNewJobSuccess(response);
        }),
        switchMap(newJob => this.createAsset(newJob, assetId)),
        switchMap(([leafletLayer, emptyAsset]) => {
          this.updateAssetValues(emptyAsset, leafletLayer);
          this.data.currentLayer?.leafletLayer.bringToBack();
          this.atlasService.setAvoidLoadLocalStorageAssets(true);
          this.atlasService.sethasToSkipLoadAssets(true);
          this.atlasService.updateCache([this.data.currentLayer], {groupName: this.data.currentLayer.name});
          this.atlasSelectMarkersService.shapeColorIndex++;
          this.atlasSelectMarkersService.clearSelections(this.data.map);
          this.atlasSelectMarkersService.clearSelectedMarkers();
          this.atlasService.updateAsset(
            this.data.currentLayer,
            {
              groupName: this.data.currentLayer.name
            },
            true
          );
          return this.translateService.get('jobs.jobSaved').pipe(
            take(1),
            tap(translation => {
              this.snackBar.open(translation, null, {
                panelClass: 'center',
                duration: 3000
              });
              this.dialogRef.close();
            })
          );
        }),
        catchError(() => {
          return this.translateService.get('jobs.internalError').pipe(
            take(1),
            tap(translation => {
              this.snackBar.open(translation, null, {
                panelClass: 'center',
                duration: 3000
              });
              this.dialogRef.close();
            })
          );
        })
      )
      .subscribe();
  }

  private updateAssetValues(emptyAsset: AtlasAssetModel, leafletLayer: any): void {
    const newAsset = this.atlasService.getAssetById(emptyAsset.id);
    newAsset.leafletLayer = leafletLayer;
    newAsset.bounds = leafletLayer.getBounds();
    newAsset.geojson = leafletLayer.toGeoJSON();
    newAsset.createdAt = new Date().getTime();
  }

  private createAsset(newJob: Job, assetId: string) {
    const featureCollection = {
      type: 'FeatureCollection',
      features: []
    };
    this.atlasSelectMarkersService.drawnItems.eachLayer(polygon => {
      const geojson = polygon.toGeoJSON();
      geojson.properties = {
        jobId: newJob.id,
        baseLayerId: this.data.currentLayer.id,
        jobName: newJob.title,
        fill: polygon?.options.color,
        stroke: polygon?.options.color,
        customGroupName: this.data.currentLayer.name
      };
      featureCollection.features.push(geojson);
    });
    this.uploadAtlasFile(featureCollection, newJob.title, assetId);
    const emptyAsset = {
      groupName: this.data.currentLayer.name,
      id: assetId,
      name: this.form.value.jobName,
      isDisplaying: true,
      iconName: 'notes',
      type: 'geojson',
      geojson: featureCollection
    } as AtlasAssetModel;
    this.atlasService.addAssets([emptyAsset]);
    return zip(
      from(this.geojsonAssetLoaderService.createLayer(featureCollection, emptyAsset, this.data.map)),
      of(emptyAsset)
    );
  }

  private uploadAtlasFile(featureCollection, title, assetId) {
    const file = this.atlasSelectMarkersService.createFile(JSON.stringify(featureCollection), `${title}.geojson`);
    this.atlasUploadService.addToQueue([file], {assetId});
  }
}

import {Injectable} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {LiveFacadeService} from '@app/live/services/live-facade.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FourGridLayoutService {
  public layoutState$ = this.liveFacadeService.layoutState$;
  public setDeviceOnGrid$: BehaviorSubject<{
    device: UserDeviceJoined;
    className: string;
    hasToSwitchToCinema: boolean;
  }> = new BehaviorSubject<{
    device: UserDeviceJoined;
    className: string;
    hasToSwitchToCinema: boolean;
  }>(null);

  constructor(private liveFacadeService: LiveFacadeService) {}

  public setDeviceOnCinema(device: UserDeviceJoined): void {
    this.setDeviceOnGrid$.next({device, className: 'one', hasToSwitchToCinema: true});
  }

  public setDeviceOnGrid(device: UserDeviceJoined, className: string): void {
    this.setDeviceOnGrid$.next({device, className, hasToSwitchToCinema: false});
  }
}

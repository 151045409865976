import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {ProfileModule} from '@app/profile/profile.module';
import {BannerModule as BannerSharedModule} from '@app/shared/banner/banner.module';
import {StripeElementsModule} from '@app/shared/stripe-elements/stripe-elements.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {PlansSharedModule} from '../shared/plans/plans.module';
import {UnleashMaterialModule} from '../theme/unleash-material.module';
import {BillingComponent} from './components/billing/billing.component';
import {CheckoutComponent} from './components/checkout/checkout.component';
import {CurrentPlanComponent} from './components/current-plan/current-plan.component';
import {DoneComponent} from './components/done/done.component';
import {PackageGroupComponent} from './components/package-group/package-group.component';
import {PlanConfirmationDialog} from './components/plan-confirmation/plan-confirmation.component';
import {PlanUnpaidComponent} from './components/plan-unpaid/plan-unpaid.component';
import {PreventLoseChangesComponent} from './components/prevent-lose-changes-dialog/prevent-lose-changes.component';
import {TrialDialogComponent} from './components/trial-dialog/trial-dialog.component';
import {TrialExpiredComponent} from './components/trial-expired/trial-expired.component';
import {PreventLosePlanChangesGuard} from './guards/prevent-lose-plan-changes.guard';
import {packageSelectorFeatureKey, packageSelectorReducer} from '@app/plans/store/package-selector.reducer';
import {PackageSelectorPage} from './pages/package-selector/package-selector.page';
import {PlansRoutingModule} from './plans-routing.module';
import {ExpiryService} from './services/expiry.service';
import {PaymentService} from './services/payment.service';
import {CancelPlanMessageDialogComponent} from './components/cancel-plan-message-dialog/cancel-plan-message-dialog.component';
import {CancelPlanDialog} from '@app/plans/components/cancel-plan/cancel-plan.component';
import {PackageSelectorEffects} from '@app/plans/store/package-selector.effects';
import {EffectsModule} from '@ngrx/effects';
import {OrganisationComponent} from '@app/profile/components/organisation/organisation.component';
import {AddonDetailsComponent} from '@app/profile/components/addon-details/addon-details.component';
import {EditTeamDialogComponent} from '@app/profile/components/teams/edit-team-dialog/edit-team-dialog.component';

@NgModule({
  declarations: [
    PlanUnpaidComponent,
    CancelPlanDialog,
    PlanConfirmationDialog,
    TrialExpiredComponent,

    PlanConfirmationDialog,
    TrialDialogComponent,
    PackageSelectorPage,
    PackageGroupComponent,
    BillingComponent,
    CheckoutComponent,
    DoneComponent,
    CurrentPlanComponent,
    PreventLoseChangesComponent,
    CancelPlanMessageDialogComponent
  ],
  imports: [
    CommonModule,
    UnleashMaterialModule,
    FormsModule,
    PlansRoutingModule,
    PlansSharedModule,
    BannerSharedModule,
    StripeElementsModule,
    UnleashLoaderModule,
    OrganisationComponent,
    AddonDetailsComponent,
    EditTeamDialogComponent,
    StoreModule.forFeature(packageSelectorFeatureKey, packageSelectorReducer),
    EffectsModule.forFeature([PackageSelectorEffects])
  ],
  providers: [ExpiryService, PaymentService, PreventLosePlanChangesGuard],
  exports: []
})
export class PlansModule {}

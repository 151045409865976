import {createFeatureSelector, createSelector} from '@ngrx/store';

export const authStateFeatureKey = 'authState';

export const authStateSelector = createFeatureSelector<AuthState>(authStateFeatureKey);
export const getAuthIsAuthenticated = createSelector(authStateSelector, (state: AuthState) => state?.isAuthenticated);

export interface AuthState {
  isAuthenticated: boolean;
  userId?: string;
  planSelected?: boolean;
  planActive?: boolean;
  iotInitRequired?: boolean;
  userCreationRequired?: boolean;
  userInitializationFinished?: boolean;
  awsMarketplaceToken?: string;
  promoCode?: string;
  organizationName?: string;
}

export enum RegistrationStage {
  started = 'started',
  confirmation = 'confirmation',
  creation = 'creation',
  details = 'details',
  payment = 'payment',
  welcome = 'welcome',
  done = 'done',
  developerPayment = 'developerPayment',
  developerDetails = 'developerDetails'
}

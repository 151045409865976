import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {IoTSeverityMessage} from '@app/flights/services/messages-iot.service';

@Component({
  selector: 'unleash-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public ioTSeverityMessage = IoTSeverityMessage;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string; message: string; severity: IoTSeverityMessage}) {}
}

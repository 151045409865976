import {Component, Input} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {RestreamService} from '@app/core/services/api/restream.service';
import {DeviceService} from '../../../core/services/api/device.service';
import {UserService} from '../../../core/services/api/user.service';
import {EVENTS, UnleashAnalyticsService} from '../../../core/services/unleash-analytics.service';

@Component({
  selector: 'app-restream',
  templateUrl: './restream.component.html',
  styleUrls: ['./restream.component.scss']
})
export class RestreamComponent {
  @Input() public device: UserDeviceJoined = null;

  @Input() public streamKey: string;

  // this flag is needed to display spinner when user clicked start and before stream triggers
  public starting: boolean = false;
  public stopping: boolean = false;

  constructor(
    private streamService: RestreamService,
    private snackBar: MatSnackBar,
    private deviceService: DeviceService,
    private userService: UserService,
    private unleashAnalytics: UnleashAnalyticsService
  ) {}

  public startRestreaming(): void {
    this.starting = true;

    this.streamService.startStreamTo(this.device.destUrl, this.device.id, this.streamKey).subscribe(
      data => {
        this.showNotification('Starting streaming to destination.');
        this.saveDeviceRestreamingState({destUrlSet: true, isRestreamingTo: true});
        this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER_RESTREAM);
      },
      err => {
        this.starting = false;
        console.error(err);
        this.showNotification('Could not start stream ' + err.message);
      }
    );
  }

  public stopRestream(): void {
    this.stopping = true;
    this.streamService.stopStreamTo(this.device.id, this.streamKey).subscribe(
      data => {
        this.showNotification('Streaming stopped.');
        this.saveDeviceRestreamingState({destUrlSet: false, isRestreamingTo: false});
        this.stopping = false;
      },
      err => {
        console.error(err);
        this.stopping = false;
        this.showNotification('Could not stop stream: ' + err.message);
      },
      () => {
        this.starting = false;
      }
    );
  }

  public saveDestUrlOnDevice(data: Event): void {
    const destUrl = (data.target as HTMLInputElement).value;
    this.userService.setDestUrlOnSelectedDevice(destUrl, this.device.id);
  }

  private saveDeviceRestreamingState(payload: {destUrlSet: boolean; isRestreamingTo: boolean}) {
    const patchBody: Partial<UserDeviceJoined> = {
      destUrl: this.device.destUrl,
      destUrlSet: payload.destUrlSet
    };

    if (payload.isRestreamingTo) {
      patchBody.isRestreamingTo = payload.isRestreamingTo;
    }

    this.deviceService.updateDevice(this.device.id, patchBody).subscribe(success => {
      this.userService.updateDeviceCache({...patchBody});
    }, console.error);
  }

  private showNotification(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 6000,
      verticalPosition: 'bottom'
    } as MatSnackBarConfig);
  }
}

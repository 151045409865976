import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {PlanModel, PlanNames} from '@app/core/models/api/plan.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {selectEssentialSelectionDisabled} from '@app/plans/store/package-selector.selectors';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PlanCardComponent implements OnInit {
  @Input() public title: string;
  @Input() public name: string;
  @Input() public backgroundImage: string;
  @Input() public price: number;
  @Input() public plan: PlanModel;
  @Input() public buttonName: ActionName;
  @Input() public isInactive: boolean;
  @Input() public isSelected: boolean;
  @Input() public isFirstGroup: boolean;

  @Output() public action: EventEmitter<ActionName> = new EventEmitter();
  // eslint-disable-next-line rxjs/finnish
  public isEssentialDisabled: Observable<boolean>;
  public planNames = PlanNames;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.isEssentialDisabled = this.store.pipe(select(selectEssentialSelectionDisabled));
  }

  public clickOnAction() {
    this.action.emit(this.isSelected ? ActionName.REMOVE : this.buttonName);
  }
}

export interface Feature {
  icon: string;
  description: string;
}

export enum ActionName {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE'
}

import {ScrollingModule, ViewportRuler} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MAT_DIALOG_SCROLL_STRATEGY} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {RouterModule} from '@angular/router';
import {AnalyticsTrackerDirective} from '@app/core/directives/analytics.directive';
import {BodyBlockScrollStrategy} from '@app/core/scroll-strategy/block-body-strategy';
import {DeviceDialog} from '@app/profile/components/device-dialog/device-dialog.component';
import {LogoUploadComponent} from '@app/profile/components/logo-upload/logo-upload.component';
import {LoadingSpinnerModule} from '@app/shared/loading-spinner/loading-spinner';
import {PinchZoomModule} from '@app/shared/pinch-zoom/pinch-zoom.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {SessionExpiredDialog} from '../auth/components/session-expired/session-expired.component';
import {TilesDirective} from '../core/directives/tiles.directive';
import {RestreamingToUrlComponent} from '../profile/components/restreaming-to-url/restreaming-to-url.component';
import {RtmpUrlValidatorDirective} from '../profile/directives/rtmp-url-validator.directive';
import {I18nPaginatorService} from '../shared/i18n/i18n-paginator.service';
import {I18nSharedModule} from '../shared/i18n/i18n-shared.module';
import {LanguagePickerComponent} from '../shared/language-picker/language-picker.component';
import {SharedPipesModule} from '../shared/pipes/shared-pipes.module';
import {NavBackComponent} from './components/nav-back/nav-back.component';
import {TeaserMessageComponent} from './components/teaser-message/teaser-message.component';
import {ThemeStorage} from './components/theme-picker/theme-storage/theme-storage';
import {ExperimentalDirective} from './directives/experimental.directive';
import {UlRouterLinkActiveDirective} from '@app/core/directives/ul-router-link-active.directive';
import {AclDirective} from '@app/core/directives/acl.directive';
import {AclDisabledDirective} from '@app/core/directives/acl-disable.directive';

export function scrollFactoryBlock(viewportRuler: ViewportRuler): () => BodyBlockScrollStrategy {
  return () => new BodyBlockScrollStrategy(viewportRuler, document);
}

const materialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatStepperModule,
  MatDividerModule,
  ScrollingModule,
  MatBadgeModule
];

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexModule,
  PinchZoomModule,
  ClipboardModule,
  ReactiveFormsModule,
  LoadingSpinnerModule,
  I18nSharedModule,
  AclDirective,
  AclDisabledDirective,
  ...materialModules
];

@NgModule({
  imports: [...modules, SharedPipesModule, LogoUploadComponent],
  declarations: [
    TilesDirective,
    UlRouterLinkActiveDirective,
    NavBackComponent,
    ExperimentalDirective,
    DeviceDialog,
    RestreamingToUrlComponent,
    RtmpUrlValidatorDirective,
    TeaserMessageComponent,
    SessionExpiredDialog,
    AnalyticsTrackerDirective,
    LanguagePickerComponent
  ],
  exports: [
    ...modules,
    TilesDirective,
    UlRouterLinkActiveDirective,
    ExperimentalDirective,
    NavBackComponent,
    DeviceDialog,
    LogoUploadComponent,
    RestreamingToUrlComponent,
    RtmpUrlValidatorDirective,
    TeaserMessageComponent,
    AnalyticsTrackerDirective,
    LanguagePickerComponent
  ],
  providers: [
    ThemeStorage,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'}
    },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: scrollFactoryBlock,
      deps: [ViewportRuler]
    },
    {
      provide: MatPaginatorIntl,
      useClass: I18nPaginatorService
    }
  ]
})
export class UnleashMaterialModule {}

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'unleash-edit-team-dialog',
  templateUrl: './edit-team-dialog.component.html',
  styleUrls: ['./edit-team-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ]
})
export class EditTeamDialogComponent implements OnInit {
  public form = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(
    private dialogRef: MatDialogRef<EditTeamDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {team: {teamId: string; name: string}}
  ) {}

  public ngOnInit(): void {
    this.form.controls.name.setValue(this.data.team.name);
  }

  public emitCancel(): void {
    this.dialogRef.close({hasToUpdate: false});
  }

  public emitRename(): void {
    if (this.form.valid) {
      this.dialogRef.close({hasToUpdate: true, teamId: this.data.team.teamId, name: this.form.controls.name.value});
      return;
    }

    this.form.controls.name.markAsTouched();
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {
  actionClearAwsMarketPlaceToken,
  actionSetupAwsMarketPlaceToken,
  actionSetupOrganizationName,
  actionSetupPromoCode,
  authInitializationFinished,
  authIoTInitFinished,
  authIoTInitRequired,
  authLogin,
  authLogout,
  authPlanExpired,
  authPlanPaid,
  authPlanSelectionRequired,
  authPostRegistrationSignIn,
  authSignedIn,
  authUserCreationRequired
} from './auth.actions';
import {AuthState} from './auth.state';

export const initialState: AuthState = {
  isAuthenticated: false
};

const reducer = createReducer(
  initialState,
  on(authLogin, state => ({
    ...state,
    isAuthenticated: true
  })),
  on(authLogout, state => ({
    ...state,
    isAuthenticated: false
  })),
  on(authSignedIn, (state, action) => ({
    ...state,
    isAuthenticated: true,
    userId: action.payload.userId
  })),
  on(authPlanExpired, (state, action) => ({
    ...state,
    planActive: false
  })),
  on(authPlanPaid, (state, action) => ({
    ...state,
    planActive: true
  })),
  on(authPlanSelectionRequired, (state, action) => ({
    ...state,
    planActive: false
  })),
  on(authPlanExpired, (state, action) => ({
    ...state,
    planActive: false
  })),
  on(authIoTInitRequired, (state, action) => ({
    ...state,
    iotInitRequired: true
  })),
  on(authIoTInitFinished, (state, action) => ({
    ...state,
    iotInitRequired: false
  })),
  on(authUserCreationRequired, (state, action) => ({
    ...state,
    userCreationRequired: true
  })),
  on(authPostRegistrationSignIn, state => ({
    ...state,
    userInitializationFinished: true
  })),
  on(authInitializationFinished, state => ({
    ...state,
    userInitializationFinished: true
  })),
  on(actionSetupAwsMarketPlaceToken, (state, {awsMarketplaceToken}) => ({
    ...state,
    awsMarketplaceToken
  })),
  on(actionSetupPromoCode, (state, {promoCode}) => ({
    ...state,
    promoCode
  })),
  on(actionClearAwsMarketPlaceToken, state => ({
    ...state,
    awsMarketplaceToken: null
  })),
  on(actionSetupOrganizationName, (state, {payload}) => ({...state, organizationName: payload.organizationName}))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}

import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {StreamingErrors} from '../models/streaming-errors.models';
import {GridLayout, GridState} from '../models/grid-state.model';

export interface LiveAnalyzeState {
  isSharedBroadcast: boolean;
  isLoadingSlug: boolean;
  isEnablingLivePage: boolean;
  errorMessage: string;
  errorCode: StreamingErrors;
  device: UserDeviceJoined;
  liveDevicesId: string[];
  isInitialized: boolean;
  layoutState: GridLayout;
  isSelectedDeviceRunningModel: boolean;
  selectedDeviceId: string;
  gridState: GridState;
}

export const initialState: LiveAnalyzeState = {
  isSharedBroadcast: false,
  isLoadingSlug: false,
  isEnablingLivePage: false,
  errorMessage: null,
  errorCode: null,
  device: null,
  liveDevicesId: [],
  isInitialized: false,
  layoutState: GridLayout.STATE_ONE,
  isSelectedDeviceRunningModel: false,
  selectedDeviceId: '',
  gridState: {}
};

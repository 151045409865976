<form [formGroup]="form" (submit)="clickContinue()" class="form">
  <unleash-radio-card
    formControlName="reason"
    [options]="options"
    [selectedCard]="form.value.reason"
    (valueSelected)="emitReasonSelected()"
    data-test-id="unleashRadioCard"
  ></unleash-radio-card>
  <div class="buttons">
    <button
      class="form__item continue-button"
      [appLadda]="isInProgress || !isAuthInitialized"
      [disabled]="!form.valid || !isAuthInitialized"
      mat-raised-button
      data-test-id="continueButton"
      color="primary"
    >
      {{ 'common.continue' | translate }}
    </button>
  </div>
</form>

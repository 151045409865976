import {PlanModel} from '@app/core/models/api/plan.model';
import {PACKAGE_LEVEL, PlansByStep, PLAN_STEP_INDEX} from '@app/plans/models/package-selector-steps.model';
import {PackageSelectorState, selectPackageSelectorState} from '@app/plans/store/package-selector.reducer';
import {createSelector} from '@ngrx/store';

export const selectLastSelectedPlan = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.plans[state.lastSelectedPlan]
);

export const selectCombinedPlan = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.plans[state.combinedPlan]
);

export const selectPlansByStep = createSelector(selectPackageSelectorState, (state: PackageSelectorState) => {
  return {
    [PLAN_STEP_INDEX.FIRST]: state.plans[state.plansByStep[PLAN_STEP_INDEX.FIRST]],
    [PLAN_STEP_INDEX.SECOND]: state.plans[state.plansByStep[PLAN_STEP_INDEX.SECOND]],
    [PLAN_STEP_INDEX.THIRD]: state.plans[state.plansByStep[PLAN_STEP_INDEX.THIRD]]
  };
});

export const selectCheckoutPlans = createSelector(
  selectPackageSelectorState,
  selectPlansByStep,
  (state: PackageSelectorState, plansByStep) => {
    const plans = Object.values(plansByStep).filter(plan => !!plan);

    const checkoutPlans = {};
    plans.forEach((plan, planIndex) => {
      checkoutPlans[planIndex] = plan;
    });

    return checkoutPlans;
  }
);

export const selectPlans = createSelector(selectPackageSelectorState, (state: PackageSelectorState) => state.plans);

export const selectCurrentStep = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.currentStep
);

export const selectPaymentPeriod = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.paymentPeriod
);

export const selectFirstProfessionalStepIndex = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.firstProfessionalStepIndex
);

export const selectHasProfessionalPlanSelected = createSelector(
  selectPackageSelectorState,
  selectPlansByStep,
  (state: PackageSelectorState, plansByStep: PlansByStep) =>
    Object.values(plansByStep).some((plan: PlanModel) => plan?.id.includes(`${PACKAGE_LEVEL.PRO}`))
);

export const selectEssentialSelectionDisabled = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.isEssentialSelectionDisabled
);

export const selectTotalPayment = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.totalPayment
);

export const selectPlansSelected = createSelector(selectPackageSelectorState, (state: PackageSelectorState) =>
  Object.values(state.plansByStep)
    .filter((plan, index) => !!plan && index <= state.currentStep)
    .map(planId => state.plans[planId])
);

export const selectIsSamePackage = createSelector(
  selectPackageSelectorState,
  (state: PackageSelectorState) => state.isSamePackage
);

<h1 mat-dialog-title>{{ 'jobs.newJob' | translate }}</h1>
<section class="content" [formGroup]="form" mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'jobs.name' | translate }}</mat-label>
    <input matInput formControlName="jobName"/>
    <mat-error
      *ngIf="(form.controls.jobName.invalid && form.controls.jobName.touched) || form.controls.jobName.dirty"
      >{{ 'jobs.nameRequired' | translate }}</mat-error
    >
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'jobs.addUsersOptional' | translate }}</mat-label>
    <mat-select formControlName="users" multiple>
      <mat-select-trigger>
        {{form.controls.users.value?.[0]?.name || '-'}}
        <span *ngIf="(form.controls.users.value?.length || 0) > 1">
          (+{{ (form.controls.users.value?.length || 0) - 1 }}
          {{ form.controls.users.value?.length === 2 ? ('jobs.other' | translate) : ('jobs.others' | translate) }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let user of teamMembers$ | async | keyvalue" [value]="user.value">{{
        user.value.name || '-'
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</section>
<section mat-dialog-actions>
  <button [disabled]="hasToDisplaySpinner" mat-dialog-close mat-button color="primary">
    {{ 'jobs.cancel' | translate }}
  </button>
  <button [disabled]="form.invalid || hasToDisplaySpinner" mat-flat-button color="primary" (click)="saveJob()">
    {{ 'jobs.saveJob' | translate }}
    <unleash-spinner *ngIf="hasToDisplaySpinner" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
  </button>
</section>

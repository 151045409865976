import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {environment} from 'environments/environment';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UserDeviceJoined} from '../../../core/models/api/user-device.model';
import {DeviceService} from '../../../core/services/api/device.service';
import {UserService} from '../../../core/services/api/user.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '../../../shared/pipes/models/thumbler.model';
import {DeleteDeviceDialogComponent} from '../delete-device-dialog/delete-device-dialog.component';
import {UPLOAD_LOGO_SOURCE} from '@app/profile/models/upload-logo-source.model';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {LogoUploadComponent} from '@app/profile/components/logo-upload/logo-upload.component';

@UntilDestroy({checkProperties: true})
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'device-dialog',
  templateUrl: 'device-dialog.component.html',
  styleUrls: ['./device-dialog.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeviceDialog implements OnInit {
  @ViewChild(LogoUploadComponent, {static: true}) public logoUploadComponent: LogoUploadComponent;

  public streamKey: string;
  public isLogoUploading: boolean;
  public isLastDevice: boolean = false;
  // eslint-disable-next-line rxjs/finnish
  public isSaving$ = new BehaviorSubject(false);
  // eslint-disable-next-line rxjs/finnish
  public isDeleting$ = new BehaviorSubject(false);
  public form: UntypedFormGroup;
  public environment = environment;
  public THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;
  public uploadLogoSource: typeof UPLOAD_LOGO_SOURCE = UPLOAD_LOGO_SOURCE;
  public aclPermissions = AclPermissions;

  // eslint-disable-next-line no-magic-numbers
  private MIN_LATITUDE_VALUE = -90;
  // eslint-disable-next-line no-magic-numbers
  private MAX_LATITUDE_VALUE = 90;
  // eslint-disable-next-line no-magic-numbers
  private MIN_LONGITUDE_VALUE = -180;
  // eslint-disable-next-line no-magic-numbers
  private MAX_LONGITUDE_VALUE = 180;

  private userSub: Subscription;
  private userDevicesSubscription: Subscription;
  private gpsAtlasCheckboxSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DeviceDialog>,
    public deviceService: DeviceService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: UserDeviceJoined,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private unleashAnalytics: UnleashAnalyticsService,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.form = this.fb.group(
      {
        id: [this.data.id],
        logo: [this.data.logo],
        name: [this.data.name, [Validators.required, Validators.pattern(/^(?!\s).*/)]],
        description: [this.data.description, [Validators.required, Validators.pattern(/^(?!\s).*/)]],
        sourceUrlSet: [this.data.sourceUrlSet || false, Validators.required],
        sourceUrl: [this.data.sourceUrl, this.isStreamURLValid],
        gps: [this.data.gps],
        lat: [
          this.data.lat,
          this.data.gps ? [Validators.min(this.MIN_LATITUDE_VALUE), Validators.max(this.MAX_LATITUDE_VALUE)] : []
        ],
        lng: [
          this.data.lng,
          this.data.gps ? [Validators.min(this.MIN_LONGITUDE_VALUE), Validators.max(this.MAX_LONGITUDE_VALUE)] : []
        ]
      },
      {validators: this.addValidationsOnFormChanges()}
    );
  }

  public ngOnInit(): void {
    this.userSub = this.userService.user$.subscribe(user => {
      this.streamKey = user.streamKey;
    });

    this.userDevicesSubscription = this.userService.userDevices$.subscribe(devices => {
      this.isLastDevice = devices.length === 1;
    });

    this.watchGPSAtlasCheckbox();
  }

  public onAddClick(): void {
    if (this.form.valid) {
      this.startSpinner();
      const form = this.form.value;
      if (this.form.get('sourceUrlSet').value === false) {
        delete form['sourceUrl'];
      }
      if (this.form.get('gps').value === false) {
        delete form['lat'];
        delete form['lng'];
      }
      this.deviceService.addDevice(form).subscribe(
        async data => {
          const url = await this.logoUploadComponent.startFileUpload(null, data.id);

          this.data = {...this.data, ...form, ...data};
          this.userService.addDeviceToUser(this.data);
          this.form.controls['id'].setValue(data.id);
          this.userService.updateDeviceCache({id: data.id, logo: url});

          this.dialogRef.close(this.data);
          this.unleashAnalytics.logEvent(EVENTS.DEVICE_ADD_TO_USER);

          this.translateService.get('profile.devices.deviceCreated').subscribe(res =>
            this.snackBar.open(res, null, {
              duration: 2000
            } as MatSnackBarConfig)
          );
        },
        error => {
          this.handleError(error);
          this.stopSpinner();
        },
        () => {
          this.stopSpinner();
        }
      );
    } else {
      this.markAsTouchUntypedFormControls();
    }
  }

  public onUpdateClick(): void {
    if (this.form.valid) {
      this.startSpinner();
      const changedProperties = this.getDirtyValues(this.form);
      this.deviceService.updateDevice(this.form.get('id').value, changedProperties).subscribe(
        success => {
          // eslint-disable-next-line rxjs/finnish
          const {statsRestreamingFrom$, statsRestreamingTo$, ...device} = this.data;
          this.userService.updateDeviceCache({...device, ...changedProperties});
          this.dialogRef.close({...device, ...changedProperties});
        },
        error => {
          this.handleError(error);
          this.stopSpinner();
        },
        () => {
          this.stopSpinner();
        }
      );
    } else {
      this.markAsTouchUntypedFormControls();
    }
  }

  public startSpinner() {
    this.isSaving$.next(true);
  }

  public stopSpinner() {
    this.isSaving$.next(false);
  }

  public onStreamUrlCopied() {
    this.translateService.get('profile.devices.streamUrlCopied').subscribe(res =>
      this.snackBar.open(res, null, {
        duration: 5000
      } as MatSnackBarConfig)
    );
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public deleteDevice(): void {
    const dialogRef = this.dialog.open(DeleteDeviceDialogComponent, {
      width: '80vw',
      maxWidth: '800px',
      data: {name: this.form.get('name').value, id: this.form.get('id').value, logo: this.form.get('logo').value}
    } as MatDialogConfig);
    dialogRef.afterClosed().subscribe((yesRemove: boolean) => {
      if (yesRemove) {
        this.isDeleting$.next(true);
        return this.deviceService.removeDevice({id: this.form.get('id').value}).subscribe(() => {
          this.translateService.get('profile.devices.deviceDeleted').subscribe(res => {
            this.isDeleting$.next(false);
            this.snackBar.open(res, null, {
              duration: 2000
            } as MatSnackBarConfig);
          });
          this.unleashAnalytics.logEvent(EVENTS.DEVICE_REMOVE_FROM_USER);
          this.dialogRef.close({...this.data, ...this.form.value});
          this.userService.removeDeviceFromCache(this.form.get('id').value);
        }, this.handleError.bind(this));
      }
    });
  }

  public updateDeviceLogo(s3Url: string) {
    this.form.get('logo').setValue(s3Url);
    this.form.get('logo').markAsDirty();
    this.unleashAnalytics.logEvent(EVENTS.DEVICE_UPDATE_LOGO);
  }

  private handleError(err) {
    let errorMessage = err;

    if (typeof err === 'object' && err.name === 'ValidationError') {
      errorMessage = 'Invalid RTMP/RTSP address';
    }

    this.snackBar.open(errorMessage, null, {
      duration: 2000
    } as MatSnackBarConfig);
    console.error(err);
    this.unleashAnalytics.logEvent(EVENTS.DEVICE_FAILED, errorMessage);
  }

  private getDirtyValues(form: UntypedFormGroup) {
    const dirtyValues = {};
    Object.keys(form.controls).forEach(key => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });
    return dirtyValues;
  }

  private addValidationsOnFormChanges() {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const isExternalURL = form.get('sourceUrlSet');
      const isSetupSourceUrl = form.get('sourceUrl');
      if (isExternalURL.value && this.isStreamURLValid(isSetupSourceUrl)) {
        form.get('sourceUrl').setErrors({invalidUrl: true});
      } else {
        form.get('sourceUrl').setErrors(null);
      }

      const isDisplayGPS = form.get('gps');
      const latitude = form.get('lat');
      const longitude = form.get('lng');
      this.isGPSValid(latitude, isDisplayGPS);
      this.isGPSValid(longitude, isDisplayGPS);
      return null;
    };
  }

  private isStreamURLValid(control: AbstractControl): boolean {
    const regexUrl = /(^(rtsp|rtmp):\/\/.*)|(^http.*m3u8$)/;
    return control.value !== undefined && !regexUrl.test(control.value);
  }

  private isGPSValid(gpsAttribute: AbstractControl, isDisplayGPS: AbstractControl) {
    if (
      isDisplayGPS.value &&
      (gpsAttribute.touched || gpsAttribute.dirty || gpsAttribute.pristine) &&
      (!gpsAttribute.value || isNaN(gpsAttribute.value))
    ) {
      gpsAttribute.setErrors({...gpsAttribute.errors, ...{invalidGPS: true}});
    } else {
      const err = gpsAttribute.errors;
      if (err && Object.keys(err).length > 0) {
        delete err['invalidGPS'];
      }
      gpsAttribute.setErrors(err);
    }
  }

  private watchGPSAtlasCheckbox(): void {
    this.gpsAtlasCheckboxSub = this.form.controls.gps.valueChanges.subscribe(isChecked => {
      if (isChecked) {
        this.form.controls.lat.setValidators([
          Validators.min(this.MIN_LATITUDE_VALUE),
          Validators.max(this.MAX_LATITUDE_VALUE)
        ]);
        this.form.controls.lng.setValidators([
          Validators.min(this.MIN_LONGITUDE_VALUE),
          Validators.max(this.MAX_LONGITUDE_VALUE)
        ]);
      } else {
        this.form.controls.lat.clearValidators();
        this.form.controls.lng.clearValidators();
        this.form.controls.lat.setErrors(null);
        this.form.controls.lng.setErrors(null);
      }
    });
  }

  private markAsTouchUntypedFormControls(): void {
    this.form.controls.name.markAsTouched();
    this.form.controls.description.markAsTouched();
    if (this.form.get('sourceUrlSet').value) {
      this.form.controls.sourceUrl.markAllAsTouched();
    }
    if (this.form.get('gps').value) {
      this.form.controls.lat.markAsTouched();
      this.form.controls.lng.markAsTouched();
    }
  }
}

import * as actions from '@app/atlas/store/atlas.actions';
import {createReducer, on} from '@ngrx/store';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import * as fromMoveItemDialog from '@app/library/components/move-item-dialog/store/move-item-dialog.reducer';
import {LibraryState} from '@app/library/store/library.reducer';

export const atlasFeatureKey = 'atlas';
export interface AtlasState {
  items: AtlasAssetModel[];
}

export interface State {
  atlas: AtlasState;
}

export const initialState: AtlasState = {
  items: null
};

export const atlasReducer = createReducer(
  initialState,
  on(actions.saveItems, (state: AtlasState, action: {payload: {atlasItems: AtlasAssetModel[]}}) => {
    return {
      items: action.payload.atlasItems
    };
  })
);

import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ExportDateRange, ExportType, /* teamExport, */ ExportDataOption} from '@app/profile/models/export-data.model';
/* import {Team} from '@app/profile/models/team.model'; */

@Component({
  selector: 'unleash-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportDataComponent implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    dateRange: new UntypedFormControl(ExportDateRange.past24Hours, Validators.required),
    type: new UntypedFormControl(ExportType.devices, Validators.required)
    /* team: new UntypedFormControl(teamExport.allTeams, Validators.required) */
  });

  @Input() dataRangeOptions: ExportDataOption<ExportDateRange>[] = [];
  @Input() typeOptions: ExportDataOption<ExportType>[] = [];
  /* @Input() teams: Team[] = []; */

  @Output() requestExport: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  emitRequestExport(): void {
    if (this.form.invalid) {
      this.form.controls.dateRange.markAsTouched();
      this.form.controls.type.markAsTouched();
      /* this.form.controls.team.markAsTouched(); */
      return;
    }

    this.requestExport.emit(this.form.value);
  }
}

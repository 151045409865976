import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {NgForOf} from '@angular/common';
import {StartStreamCard, StartStreamCardComponent} from '../start-stream-card/start-stream-card.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {HelpService} from '@app/core/services/help.service';
import {UserService} from '@app/core/services/api/user.service';
import {DeviceService} from '@app/core/services/api/device.service';
import {combineLatest, filter, switchMap, take, tap} from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RestreamService} from '@app/core/services/api/restream.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {AclDirective} from '@app/core/directives/acl.directive';

@Component({
  selector: 'unleash-empty-state-stream-cards',
  standalone: true,
  imports: [AclDirective, NgForOf, StartStreamCardComponent, TranslateModule],
  templateUrl: './empty-state-stream-cards.component.html',
  styleUrls: ['./empty-state-stream-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateStreamCardsComponent implements OnInit {
  public startStreamCards: StartStreamCard[] = [];
  public aclPermissions = AclPermissions;

  constructor(
    private translateService: TranslateService,
    private helpService: HelpService,
    private userService: UserService,
    private deviceService: DeviceService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private restreamService: RestreamService
  ) {}

  ngOnInit(): void {
    this.translateService
      .get('live.live-stream-page.streamCards')
      .pipe(take(1))
      .subscribe(i18streamCards => {
        this.startStreamCards = [
          {
            title: i18streamCards[0].title,
            description: i18streamCards[0].description,
            actions: [{name: i18streamCards[0].actions[0], route: '/secure/live/stream'}],
            icon: 'linked_camera'
          },
          {
            title: i18streamCards[1].title,
            description: i18streamCards[1].description,
            actions: [],
            icon: '',
            svgIcon: 'stream_mobile_device'
          },
          {
            title: i18streamCards[2].title,
            description: i18streamCards[2].description,
            actions: [
              {name: i18streamCards[2].actions[0], route: '', click: this.onClickFirstDeviceHelpButton.bind(this)},
              {name: i18streamCards[2].actions[1], route: '', click: this.copyDeviceURLStream.bind(this)}
            ],
            icon: 'link'
          },
          {
            title: i18streamCards[3].title,
            description: i18streamCards[3].description,
            actions: [{name: i18streamCards[3].actions[0], route: '', click: this.startDemoStream.bind(this)}],
            icon: 'image'
          }
        ];
      });
  }

  private onClickFirstDeviceHelpButton(): void {
    this.helpService.goToKnowledgePage();
  }

  private copyDeviceURLStream(): void {
    combineLatest([this.userService.defaultDevice$, this.userService.user$])
      .pipe(take(1))
      .subscribe(([defaultDevice, user]) => {
        const streamUrl = this.deviceService.getStreamUrl(defaultDevice, user.streamKey);
        this.clipboard.copy(streamUrl);
        const messageWithTranslation = 'live.live-stream-page.copyDeviceURLStream.message';
        this.showSnackBarMessage(messageWithTranslation);
      });
  }

  private startDemoStream(): void {
    combineLatest([this.userService.defaultDevice$, this.userService.user$])
      .pipe(
        take(1),
        tap(([defaultDevice]) => {
          if (!defaultDevice) console.error('Could not find a default device');
        }),
        filter(([defaultDevice]) => !!defaultDevice),
        switchMap(([defaultDevice, user]) =>
          this.restreamService.startStreamFrom(RestreamService.DEMO_STREAM_URL, defaultDevice.id, user.streamKey)
        )
      )
      .subscribe(
        defaultMessage => {
          const messageWithTranslation =
            defaultMessage === 'STARTING' ? 'live.live-stream-page.startDemoStream.starting.message' : '';
          this.showSnackBarMessage(messageWithTranslation, defaultMessage);
        },
        error => {
          const messageWithTranslation = 'live.live-stream-page.startDemoStream.err';
          this.showSnackBarMessage(messageWithTranslation);
        }
      );
  }

  private showSnackBarMessage(i18nKey: string, defaultMessage: string = ''): void {
    this.translateService
      .get(i18nKey)
      .pipe(take(1))
      .subscribe(i18Message => this.snackBar.open(i18Message || defaultMessage, '', {duration: 3000}));
  }
}

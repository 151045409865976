import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {atlasConfig, getSatelliteViewLayer} from '@app/atlas/atlas.config';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LatLngBoundsExpression, LatLngExpression, Layer, Marker, latLng} from 'leaflet';
import {Map, MapOptions, PanOptions, ZoomOptions, ZoomPanOptions} from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {
  Observable,
  Subscription,
  concatMap,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  fromEvent,
  map,
  of,
  take
} from 'rxjs';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AssetLoaderService} from '@app/atlas/services/asset-loader.service';
import L from 'leaflet';
import {CalibrationService} from '../../services/calibration.service';
import {AssetsFilterService} from '@app/atlas/services/assets-filter.service';
import {CommonModule} from '@angular/common';
import {Device, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {AtlasModule} from '@app/atlas/atlas.module';
import {StatusService} from '@app/core/services/api/status.service';
import {UserService} from '@app/core/services/api/user.service';

@Component({
  selector: 'unleash-calibration-map',
  templateUrl: './calibration-map.component.html',
  styleUrls: ['./calibration-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeafletModule, CommonModule, AtlasModule]
})
export class CalibrationMapComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('markerColor')
  public set setupMarkerColor(color: LabelColorName) {
    this.markerColor = color || LabelColorName.red;
    this.clearAllMarkers();
    this.loadMarkers(this.imageCoordinates);
  }
  public markerColor: LabelColorName = LabelColorName.red;
  @Input() public deviceLocation: {lat: number; lng: number} = null;
  @Input() public coordinates: {lat: number; lng: number}[] = [];
  @Input() public currentIndex: number = -1;
  private imageCoordinates: {lat: number; lng: number}[];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('imageCoordinates')
  private set setImageCoordinates(coordinates: {lat: number; lng: number}[]) {
    if (coordinates && this.redrawPoints && coordinates.length === 0) {
      this.clearAllMarkers();
      return;
    }

    if (coordinates && coordinates.length > 0) {
      this.imageCoordinates = coordinates;
      this.loadMarkers(this.imageCoordinates);
    }
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('openMap')
  private set setOpenMap(openMap: boolean) {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  @Input() public redrawPoints = false;
  @Input() public hasToDisplayMarkerCount = false;
  @Input() public isCalibrationEditMode = false;

  @Output() public markerCoordinates: EventEmitter<{lat: number; lng: number}> = new EventEmitter();

  @ViewChild('leafletMap') public leafletMap;

  public options: MapOptions;
  public map: Map;
  public panOptions: PanOptions;
  public zoomOptions: ZoomOptions;
  public zoomPanOptions: ZoomPanOptions;
  public markers: any[] = [];
  public assets$ = this.assetsFilterService.assets$;
  private defaultZoom: number = 18;
  public iconUrl: string = '';

  public devices$ = this.userService.liveDevices$.pipe(
    map((devices: UserDeviceJoined[]): UserDeviceJoined[] =>
      devices.filter((device: UserDeviceJoined): boolean => !!device && !!device.gps)
    ),
    distinctUntilChanged((a, b) => {
      const ignoreValuesToCompare = device => {
        const {player, isLive, updatedAt, waitingModels, runningModels, lastSeen, ...rest} = device;
        return rest;
      };
      const aData = a.map(ignoreValuesToCompare);
      const bData = b.map(ignoreValuesToCompare);
      return JSON.stringify(aData) === JSON.stringify(bData);
    })
  );
  private hasToCancelDrawMarkers: boolean = false;
  private detectChangesSub: Subscription;
  private getLayersSub: Subscription;
  private customCursorMouseMoveSub: Subscription;
  private customCursorMouseLeaveSub: Subscription;

  constructor(
    private atlasService: AtlasService,
    private assetLoaderService: AssetLoaderService,
    private calibrationService: CalibrationService,
    private assetsFilterService: AssetsFilterService,
    private liveStreamPageService: LiveStreamPageService,
    private statusService: StatusService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {
    this.statusService.clearDataStore();
    this.atlasService.activeStreamingDevice$.next({} as any);
  }

  public ngOnInit(): void {
    this.initMap();
    this.watchStreamingDevices();
    this.iconUrl = `assets/icons/image-viewer/map-pin--${this.markerColor}.svg`;
    this.detectChangesSub = this.atlasService.hasToDetectChanges$.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  public ngAfterViewInit(): void {
    if (!this.isCalibrationEditMode) {
      const container = (this.map.getRenderer(this.map as any) as any)._container;
      container.style.cursor = 'none';
      this.addCustomCursorEvents();
      this.addMapClickEvent();
    }
  }

  public addCustomCursorEvents(): void {
    const cursor = document.querySelector('#map-cursor');
    const mapContainer = this.leafletMap.nativeElement;
    this.customCursorMouseMoveSub = fromEvent(mapContainer, 'mousemove').subscribe((event: any) => {
      (cursor as any).style.left = `${event.pageX}px`;
      (cursor as any).style.top = `${event.pageY}px`;
      (cursor as any).style.visibility = `visible`;
    });
    this.customCursorMouseLeaveSub = fromEvent(mapContainer, 'mouseleave').subscribe(() => {
      (cursor as any).style.visibility = `hidden`;
    });
  }

  public ngOnDestroy(): void {
    if (this.detectChangesSub) {
      this.detectChangesSub.unsubscribe();
      this.detectChangesSub = null;
    }
    if (this.getLayersSub) {
      this.getLayersSub.unsubscribe();
      this.getLayersSub = null;
    }
    if (this.customCursorMouseLeaveSub) {
      this.customCursorMouseLeaveSub.unsubscribe();
      this.customCursorMouseLeaveSub = null;
    }
    if (this.customCursorMouseMoveSub) {
      this.customCursorMouseMoveSub.unsubscribe();
      this.customCursorMouseMoveSub = null;
    }
    this.atlasService.setHasToSkipFitBoundsOnLoadLayers(false);
    this.atlasService.setHasToBlockEvents(false);
  }

  public getAssets() {
    this.getLayersSub = this.atlasService
      .getAssets()
      .pipe(
        filter(data => !!data.length),
        concatMap(assets => {
          return of(assets.sort());
        })
      )
      .subscribe();
  }

  private initAssetLoaders() {
    const assets$ = this.atlasService.assets$.pipe(
      filter(data => !!data.length),
      map(data => data.sort((assetA, assetB) => assetA.name.localeCompare(assetB.name))),
      distinctUntilChanged((prev, curr) => prev.length === curr.length),
      map(assets => {
        return Promise.allSettled(
          assets.map(asset => {
            if (asset.color) {
              asset.customColorIndex = this.atlasService.availableColors.findIndex(color => color === asset.color);
            }
            return this.assetLoaderService.load(asset, this.map);
          })
        );
      })
    );

    return firstValueFrom(assets$);
  }

  private createMarker(lat: number, lng: number, index: number): void {
    const iconSize = 32;

    if (lat && lng) {
      const marker: Marker = new L.Marker([lat, lng], {
        icon: this.createMarkerIcon(index, iconSize),
        draggable: this.currentIndex === index
      });
      if (index === this.currentIndex) {
        // eslint-disable-next-line no-magic-numbers
        marker.setZIndexOffset(10);
      }
      this.markers[index] = marker;
      this.map?.addLayer(marker);
      if (this.isCalibrationEditMode && this.currentIndex === index) {
        marker.on('dragend', event => {
          const coordinates = event.target.getLatLng();
          this.markerCoordinates.emit({lat: coordinates.lat, lng: coordinates.lng});
        });
      }
    }
  }

  public createMarkerIcon(index: number, iconSize: number): L.DivIcon {
    if (this.hasToDisplayMarkerCount) {
      return L.divIcon({
        className: this.isCalibrationEditMode ? `marker-${index + 1}` : `marker-${index + 1} calibration-map-marker`,
        html: `<div style="text-align: center;">
               <img src="${this.iconUrl}" style="width:${iconSize}px; height:${iconSize}px;">
               <span class="${this.markerColor}" style="position: absolute; top: 44%; left: 50%;
                            transform: translate(-50%, -50%);
                            color: #fff; border-radius: 50%; font-weight: 600; height: 14px; width:14px;
                            font-family: Arial; font-size: 10px; text-anchor: middle;">
                 ${index + 1}
               </span>
             </div>`,
        iconSize: [iconSize, iconSize],
        iconAnchor: [iconSize / 2, iconSize],
        popupAnchor: [0, -iconSize]
      });
    }

    return L.icon({
      iconSize: [iconSize, iconSize],
      popupAnchor: [0, -iconSize],
      // eslint-disable-next-line no-magic-numbers
      iconAnchor: [iconSize / 2, iconSize],
      iconUrl: this.iconUrl
    });
  }

  public async onMapReady(map: Map) {
    this.map = map;
    this.atlasService.setHasToBlockEvents(true);
    this.atlasService.setHasToHideCursor(true);
    this.calibrationService.setCalibrationMap(this.map);
    this.map.addLayer(getSatelliteViewLayer());
    this.map.setZoom(this.defaultZoom);
    this.loadMarkers(this.imageCoordinates);
    this.createSearchBox('topleft');
    this.goToDeviceLocation();
    this.atlasService.setHasToSkipFitBoundsOnLoadLayers(true);
    this.getAssets();
    await this.initAssetLoaders();
    this.cd.detectChanges();
  }

  private addMapClickEvent() {
    this.map.on('click', event => {
      if (!this.hasToCancelDrawMarkers) {
        this.hasToCancelDrawMarkers = true;
        this.markerCoordinates.emit({lat: event.latlng.lat, lng: event.latlng.lng});
      }
    });
  }

  private watchStreamingDevices(): void {
    this.userService.user$.pipe(take(1)).subscribe(user => {
      this.statusService.getActiveStream(user.streamKey);
    });
  }

  public addLayers(layers: Layer[]): void {
    layers.forEach(layer => {
      this.map.addLayer(layer);
      if ((layer as any)._icon) {
        (layer as any)._icon.style.pointerEvents = 'none';
      }
    });
  }

  public isDeviceStreaming$(device: Device): Observable<boolean> {
    return this.liveStreamPageService.liveDevicesId$.pipe(map(devices => devices.indexOf(device.id) !== -1));
  }

  private goToDeviceLocation() {
    const location = this.isCalibrationEditMode
      ? this.imageCoordinates[this.currentIndex] || this.deviceLocation
      : this.deviceLocation;
    if (location?.lat && location?.lng) {
      const mapCenter = latLng([location.lat, location.lng]);
      this.map.setView(mapCenter, this.defaultZoom);
    }
  }

  private createSearchBox(position: string) {
    const searchControl = GeoSearchControl({
      position: position,
      provider: new OpenStreetMapProvider(),
      autoComplete: true,
      autoCompleteDelay: 250,
      style: 'button',
      showMarker: false,
      showPopup: false,
      popupFormat: ({query, result}) => result.label,
      maxMarkers: 1,
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: 'Search map or GPS location',
      keepResult: false,
      updateMap: true,
      resultFormat: ({result}) => result.label,
      marker: {
        draggable: false
      }
    });
    this.map.addControl(searchControl);
  }

  private initMap(): void {
    this.options = {
      preferCanvas: true,
      attributionControl: false,
      zoomControl: false,
      zoom: this.defaultZoom,
      center: atlasConfig.INITIAL_MAP_CENTER as LatLngExpression,
      minZoom: 1,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      maxBounds: atlasConfig.MAX_BOUNDS as LatLngBoundsExpression
    };
    this.panOptions = {
      animate: true
    };
    this.zoomOptions = {
      animate: true
    };
    this.zoomPanOptions = {
      animate: true
    };
  }

  private loadMarkers(coordinates: {lat: number; lng: number}[]): void {
    if (!this.coordinates || !coordinates) {
      return;
    }

    if (this.redrawPoints && this.map) {
      this.clearAllMarkers();
      this.addMarkers(coordinates);
      const index = this.currentIndex + 1;
      this.map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          if (index === -1) {
            layer.setOpacity(1);
            return;
          }

          const currentIndex = layer.options.icon.options.className.split('-')[1];
          const opacity = currentIndex !== index.toString() ? 0.5 : 1;
          layer.setOpacity(opacity);
        }
      });
      return;
    }
  }

  private clearAllMarkers() {
    this.markers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.markers = [];
    this.coordinates = [];
  }

  private addMarkers(coordinates: {lat: number; lng: number}[]): void {
    this.coordinates = [...coordinates];
    this.coordinates.forEach((coordinate: {lat: number; lng: number}, index: number) => {
      coordinate.lat && coordinate.lng
        ? this.createMarker(coordinate.lat, coordinate.lng, index)
        : this.markers.push({});
    });
  }
}

<ng-container [ngSwitch]="userScreen">
  <ng-container *ngSwitchCase="userTileScreen.users">
    <unleash-users
      [form]="form"
      [users]="users"
      [menuItems]="menuItems"
      [hasMoreThanOneAdmin]="hasMoreThanOneAdmin"
      [roles]="roles"
      [isAddUserMode]="isAddUserMode"
      (addUsers)="emitAddUsers()"
      (searchUser)="performSearch($event)"
      (user)="emitUser($event)"
      (action)="emitAction($event)"
      (openInviteUsersDialog)="emitOpenInviteUsersDialog()"
    ></unleash-users>
  </ng-container>
  <ng-container *ngSwitchCase="userTileScreen.addUsers">
    <unleash-add-user
      (cancel)="emitCancel()"
      (invite)="emitInvite($event)"
      (back)="emitBack()"
      [roles]="roles"
      [teams]="teams"
      [currentTeamId]="currentTeamId"
    ></unleash-add-user>
  </ng-container>
  <ng-container *ngSwitchCase="userTileScreen.userDetails">
    <h3 class="subtitle">{{ 'profile.users.details' | translate }}</h3>
    <unleash-user-detail [user]="selectedUser" (back)="emitBack()"></unleash-user-detail>
  </ng-container>
  <ng-container *ngSwitchDefault> </ng-container>
</ng-container>

<ng-template #noCompanySetupMessage>
  <div class="option">
    <div class="option-title">
      {{ 'profile.streaming.setupMessage.0' | translate }}
      <a [routerLink]="['/secure/profile/']" fragment="organisation" href="#organisation">{{
        'profile.streaming.setupMessage.1' | translate
      }}</a>
      {{ 'profile.streaming.setupMessage.2' | translate }}
    </div>
  </div>
</ng-template>

<div class="option" *ngIf="!!this.company; else noCompanySetupMessage">
  <ng-container *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany">
    <div class="option-title">
      {{ 'profile.streaming.shareBroadcasts' | translate }}
    </div>
    <div class="option-content">
      <div class="disabled-toogle-label" [ngClass]="{enabled: !isShared, disabled: isShared}">
        {{ 'common.disabled' | translate }}
      </div>
      <mat-slide-toggle [ngModel]="isShared" (ngModelChange)="toogleShareBroadcast()" [color]="'primary'"
        >{{ 'common.enabled' | translate }}
      </mat-slide-toggle>
    </div>
  </ng-container>
  <div class="buttons">
    <ng-container *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany">
      <div class="buttons__secure-url" [appLadda]="!(isSlugLoaded | async)">
        <button mat-button color="primary" (click)="updateCompanySlug()">
          {{ 'profile.streaming.generateSecureUrl' | translate | uppercase }}
          <mat-icon>lock</mat-icon>
        </button>
      </div>
    </ng-container>
    <a
      [disabled]="!isShared || !(isSlugLoaded | async)"
      mat-button
      color="primary"
      [href]="publicWatchPageURL"
      target="_blank"
      >{{ 'profile.streaming.publicLivePage' | translate | uppercase }}
      <mat-icon>open_in_new</mat-icon>
    </a>
    <button
      *ngIf="!!isShared"
      [disabled]="!(isSlugLoaded | async)"
      mat-button
      color="primary"
      [cdkCopyToClipboard]="publicWatchPageURL"
      (copied)="onUrlCopied()"
    >
      {{ 'profile.streaming.copyUrl' | translate | uppercase }}
      <mat-icon>screen_share</mat-icon>
    </button>
  </div>
</div>

<h2 mat-dialog-title class="title">{{ 'plans.prevent.title' | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <p class="text-content">
    {{ 'plans.prevent.message' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>{{ 'plans.prevent.continueCustomizing' | translate }}</button>
  <button mat-button color="primary" [mat-dialog-close]="true">{{ 'plans.prevent.dontChange' | translate }}</button>
</mat-dialog-actions>

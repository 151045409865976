import {createSelector} from '@ngrx/store';
import * as fromFlightLogDetailDialog from './flight-log-detail-dialog.reducer';
import * as fromFlights from './flight-log-detail-dialog.state';

export const selectFlightInfo = createSelector(
  fromFlights.selectFlightLogDetailDialogState,
  (state: fromFlightLogDetailDialog.State) => {
    return state.flightInfo;
  }
);

export const selectFlightFrames = createSelector(
  fromFlights.selectFlightLogDetailDialogState,
  (state: fromFlightLogDetailDialog.State) => {
    return state.flightFrames;
  }
);

export const selectFlightDate = createSelector(
  fromFlights.selectFlightLogDetailDialogState,
  (state: fromFlightLogDetailDialog.State) => {
    return state.flightDate;
  }
);

export const selectIsLoading = createSelector(
  fromFlights.selectFlightLogDetailDialogState,
  (state: fromFlightLogDetailDialog.State) => {
    return state.isLoading;
  }
);

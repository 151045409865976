import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngrx/store';
import {PlanModel, PlanNames, PlansByPlanName} from '@app/core/models/api/plan.model';
import {ActionName} from '@app/shared/plans/plan-card/plan-card.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, Observable} from 'rxjs';
import {selectEssentialSelectionDisabled} from '@app/plans/store/package-selector.selectors';
import {PACKAGE_LEVEL} from '@app/plans/models/package-selector-steps.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-package-group',
  templateUrl: './package-group.component.html',
  styleUrls: ['./package-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PackageGroupComponent),
      multi: true
    }
  ]
})
export class PackageGroupComponent implements ControlValueAccessor {
  @Input() public disabled = false;
  @Input() public options: PlansByPlanName;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedCardId') public set setupSelectedCardId(selectedCardId: string) {
    this.selectedCardId = selectedCardId;
    this.generateButtonName();
  }
  @Input() public backgroundImage: string;
  @Input() public stepIndex: number;
  @Input() public professionalIndex: number;

  @Output() public card: EventEmitter<{plansByPlanName: PlanModel; actionName: ActionName}> = new EventEmitter();

  public initButtonName$: BehaviorSubject<{[key: string]: ActionName}> = new BehaviorSubject({
    [PlanNames.essentials]: ActionName.ADD,
    [PlanNames.professional]: ActionName.ADD
  });
  public selectedPlan$: Observable<PlanModel>;
  // eslint-disable-next-line rxjs/finnish
  public isEssentialSelectionDisabled: Observable<boolean>;
  public selectedCardId: string = null;

  constructor(private store: Store) {
    this.isEssentialSelectionDisabled = this.store.select(selectEssentialSelectionDisabled);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (value: string) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};

  public writeValue(value: string): void {
    this.onChange(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public doAction(actionName: ActionName, key: string) {
    switch (actionName) {
      case ActionName.REMOVE:
        this.card.emit({plansByPlanName: null, actionName});
        this.writeValue(null);
        break;
      default:
        this.selectCard(key, actionName);
        break;
    }
  }

  private selectCard(key: string, actionName: ActionName) {
    const selectedCardId = (this.options[key] as PlanModel).id;
    this.writeValue(selectedCardId);
    this.card.emit({plansByPlanName: this.options[key], actionName});
  }

  private generateButtonName() {
    let buttonNameByPackageLevel = {
      [PlanNames.essentials]: ActionName.ADD,
      [PlanNames.professional]: ActionName.ADD
    };

    if (!this.selectedCardId) {
      this.initButtonName$.next(buttonNameByPackageLevel);
      return;
    }

    const [, level] = this.selectedCardId.split('_');

    if (parseInt(level) === PACKAGE_LEVEL.ESSENTIALS) {
      buttonNameByPackageLevel = {
        [PlanNames.essentials]: ActionName.REMOVE,
        [PlanNames.professional]: ActionName.UPGRADE
      };
      this.initButtonName$.next(buttonNameByPackageLevel);
      return;
    }

    buttonNameByPackageLevel = {
      [PlanNames.essentials]: ActionName.DOWNGRADE,
      [PlanNames.professional]: ActionName.REMOVE
    };

    this.initButtonName$.next(buttonNameByPackageLevel);
  }
}

<div class="header">
  <div class="header__steps">{{'auth.welcome.step3of3' | translate}}</div>

  <div class="header__links">
    <span class="header__link header__link--sign-out" (click)="signOut()"
      ><mat-icon class="icon">vpn_key</mat-icon> {{'common.signOut' | translate}}</span
    >

    <button mat-icon-button [matMenuTriggerFor]="menu" class="header__link header__link--more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="signOut()" class="header__menu-item">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'common.signOut' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="spacer"></div>
<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>
<div class="title">
  <div class="title__text">{{'auth.welcome.signUpSuccess' | translate}}</div>
  <div *ngIf="currentPlan$ | async as currentPlan" class="title__sub-text">
    {{'auth.welcome.currentPlan' | translate:({packageName: currentPlan.packageName, planName: currentPlan.planName})}}
  </div>
</div>

<div class="image">
  <img src="/assets/icons/welcome/hurrah.png" alt="welcome" />
</div>

<div class="continue">
  <button mat-raised-button color="primary" data-test-id="continueWelcomeButton" (click)="continue()">
    {{'common.continue' | translate}}
  </button>
</div>

<div class="spacer"></div>

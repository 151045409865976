import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {MissionSettingsService, SELECTED_WAYPOINT} from '../services/mission-settings.service';
import {MatDialog} from '@angular/material/dialog';
import {LeaveEditModeComponent} from '../components/leave-edit-mode/leave-edit-mode.component';
import {Subscription, take} from 'rxjs';
import {MissionManagerService} from '../services/mission-manager.service';

@Directive({
  selector: '[ulCloseEdit]'
})
export class CloseEditDirective implements OnDestroy, AfterViewInit {
  @Input('ulCloseEdit') public selectedWaypointIndex: number;
  @Output() public updateHeader: EventEmitter<void> = new EventEmitter();
  private isUpdatingSubscription: Subscription;
  constructor(
    private missionSettingsService: MissionSettingsService,
    private dialog: MatDialog,
    private missionManagerService: MissionManagerService,
    private elementRef: ElementRef
  ) {}

  public ngOnDestroy(): void {
    if (this.isUpdatingSubscription) {
      this.isUpdatingSubscription.unsubscribe();
      this.isUpdatingSubscription = null;
    }
  }

  public ngAfterViewInit(): void {
    this.isUpdatingSubscription = this.missionSettingsService.isUpdatingMission.subscribe(isUpdating => {
      if (isUpdating) {
        this.elementRef.nativeElement.classList.add('mat-button-disabled');
        this.elementRef.nativeElement.setAttribute('disabled', 'true');
        return;
      }
      this.elementRef.nativeElement.classList.remove('mat-button-disabled');
      this.elementRef.nativeElement.removeAttribute('disabled');
    });
  }

  @HostListener('click', ['$event'])
  public closeEdit() {
    this.missionSettingsService.undoStack.pipe(take(1)).subscribe(stack => {
      if (stack.length === 1) {
        this.closeSuccess();
        return;
      }
      const dialog = this.dialog.open(LeaveEditModeComponent, {width: '60vw', maxWidth: '800px'});
      dialog
        .afterClosed()
        .pipe(take(1))
        .subscribe(hasToClose => {
          if (hasToClose) {
            this.closeSuccess();
          }
        });
    });
  }

  public closeSuccess() {
    this.missionSettingsService.setIsEditingMission(false);
    this.missionSettingsService.resetEditMode(this.missionSettingsService.undoStack.value[0], -1);
    this.missionSettingsService.editSave();
    this.updateHeader.emit();
    this.missionManagerService.updateMissionUi(this.missionSettingsService.undoStack.value[0]);
    this.missionSettingsService.undoStack.next([this.missionSettingsService.undoStack.value[0]]);
    this.missionSettingsService.redoStack.next([]);
    this.missionSettingsService.setSelectedWaypointIndex(SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED);
  }
}

import {Injectable} from '@angular/core';
import {authPlanPaid} from '@app/auth/state/auth.actions';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {PlanModel, PlansByPackageName} from '@app/core/models/api/plan.model';
import {UserModel} from '@app/core/models/api/user-model';
import {AclService} from '@app/core/services/acl.service';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {UserService} from '@app/core/services/api/user.service';
import {PackageSelectorStoreFacadeService} from '@app/plans/services/package-selector-store-facade.service';
import {Auth} from '@aws-amplify/auth';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, flatMap, map, share, skip, switchMap, take, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  // eslint-disable-next-line rxjs/finnish
  public plans: Observable<PlanModel[]>;
  // eslint-disable-next-line rxjs/finnish
  public plansByPackage: Observable<PlansByPackageName>;
  public isModelingEnabled$: Observable<boolean>;
  public userServiceSub: Subscription;
  public plansSub: Subscription;
  public _isAIAnalyticsEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _isAIInsightEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _isStreamingEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _isRestreamingEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _isModelingEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  private userCurrentPlanId: string;
  private _plans: BehaviorSubject<PlanModel[]> = new BehaviorSubject<PlanModel[]>([]);

  private userPlan: BehaviorSubject<PlanModel> = new BehaviorSubject<PlanModel>(null);
  public userPlan$: Observable<PlanModel> = this.userPlan.asObservable();

  private dataStore: {
    plans: PlanModel[];
    userPlan: PlanModel;
  } = {plans: [], userPlan: null};
  private user: UserModel;

  constructor(
    public apiGateway: ApiGateway,
    public userService: UserService,
    private store: Store,
    private aclService: AclService,
    private packageSelectorStoreFacadeService: PackageSelectorStoreFacadeService
  ) {
    this.initDataStore();
    this.plans = this._plans.asObservable().pipe(filter((arr: PlanModel[]) => !!arr && arr.length > 0));
    this.plansByPackage = this._plans.pipe(flatMap(this.groupPlansPerPackage));
    /* listen for plans changes and user data changes
     * handles sign in event, plan selected event, trial events etc.*/
    // TODO move this to effect when user switch plans
    this.userServiceSub = this.userService.user$
      .pipe(
        distinctUntilChanged(
          (user1: UserModel, user2: UserModel) => user1.id === user2.id && user1.currentPlan === user2.currentPlan
        ),
        tap(user => {
          this.user = user;
          this.plans
            .pipe(
              filter(plans => plans.length > 0),
              take(1)
            )
            .subscribe(plans => {
              if (!!this.user && !!plans) {
                this.updateUserPlan(plans);
              }
            });
        }),
        skip(1),
        switchMap((user: UserModel) => this.getPlans(false).pipe(map((plans: PlanModel[]) => [user, plans])))
      )
      .subscribe();

    this.isModelingEnabled$ = this._isModelingEnabled.asObservable().pipe(
      switchMap(isModelingEnabled =>
        this.aclService.hasSetupPermission$.pipe(
          filter(hasSetupPermission => !!hasSetupPermission),
          map(() => isModelingEnabled && this.aclService.hasPermission(AclPermissions.AtlasApiRead))
        )
      )
    );
  }

  // eslint-disable-next-line rxjs/finnish
  public getPlans(usePublicEndpoint?: boolean): Observable<PlanModel[]> {
    const endPoint = usePublicEndpoint ? 'plan/public' : 'plan';
    const plans$ = this.apiGateway.get(endPoint, {}).pipe(share());
    if (!!this.plansSub) {
      // release existing sub
      this.plansSub.unsubscribe();
    }
    this.plansSub = plans$.subscribe((plans: PlanModel[]) => {
      this.dataStore.plans = plans;
      this._plans.next({...this.dataStore}.plans);
      this.packageSelectorStoreFacadeService.setPlans(plans);
    });
    return plans$;
  }

  private initPlans(): void {
    /* lists plans using anonymous endpoint on /plans page */
    if (this.isPublicPlansPage()) {
      Auth.currentAuthenticatedUser()
        .then((user: UserModel) => {
          if (!user) {
            // console.debug('Use public endpoint because credentials are not set');
            this.getPlans(true);
          }
          // console.debug('User is logged in because credentials available', credentials);
          this.getPlans();
        })
        .catch(() => {
          // console.debug('Use public endpoint, cant fetch credentials', err);
          this.getPlans(true);
        });
    }
  }

  private isPublicPlansPage(): boolean {
    /* Plans should not be fetched on some pages*/
    return /^\/plans/.test(window.location.pathname);
  }

  private updateUserPlan(plans: PlanModel[]): void {
    if (!this.user) {
      /* this may happen when user doesnt have plan assigned
       * and user data was not initialized*/
      console.warn('User is not initialized');
      return;
    }
    this.userCurrentPlanId = this.user.currentPlan;
    if (!!plans && this.user.currentPlan !== (!!this.dataStore.userPlan && this.dataStore.userPlan.id)) {
      this.dataStore.userPlan = plans.find((plan: PlanModel) => plan.id === this.user.currentPlan);
      if (!!this.dataStore.userPlan) {
        this.populateEnabledFeatures(this.dataStore.userPlan);
        this.store.dispatch(authPlanPaid());
      }
      this.userPlan.next(this.dataStore.userPlan);
    }
  }

  private populateEnabledFeatures(currentPlan: PlanModel): void {
    this._isAIAnalyticsEnabled.next(!!currentPlan.isAiAnalytics);
    this._isModelingEnabled.next(!!currentPlan.isModelling);
    this._isAIInsightEnabled.next(!!currentPlan.aiInsightsNum);
    this._isStreamingEnabled.next(!!currentPlan.isLivestreaming);
    this._isRestreamingEnabled.next(!!currentPlan.isRestreaming);
  }

  private initDataStore(): void {
    this.dataStore = {
      plans: [],
      userPlan: null
    };
    this._plans.next({...this.dataStore}.plans);
    this.userPlan.next({...this.dataStore}.userPlan);
    this._isAIAnalyticsEnabled.next(false);
    this._isAIInsightEnabled.next(false);
    this._isStreamingEnabled.next(false);
    this._isRestreamingEnabled.next(false);
  }

  // eslint-disable-next-line rxjs/finnish
  private groupPlansPerPackage(plans: PlanModel[]): Observable<PlansByPackageName> {
    // TODO: packageName below is missing in PackageNames enum and can't to typedef data for groupByReducer
    // eslint-disable-next-line
    const groupByReducer = (allPlansSoFar, currentPlan) => {
      if (!currentPlan.packageName) {
        return allPlansSoFar;
      } // skip plans without package name
      // we are treating 2D Operational Awareness and 3D Operational Awareness as Mapping and Modelling
      let packageName = currentPlan.packageName;
      if (packageName === '2D Operational Awareness' || packageName === '3D Operational Awareness') {
        packageName = 'Mapping & Modelling';
      }
      if (!allPlansSoFar[packageName]) {
        allPlansSoFar[packageName] = {};
      }
      allPlansSoFar[packageName][currentPlan.planName] = currentPlan;
      return allPlansSoFar;
    };
    const plansByPackage = plans.reduce(groupByReducer, {});
    return of(plansByPackage);
  }
}

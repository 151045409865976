<div class="header">
  <div class="header__links">
    <span class="header__link header__link--back" (click)="backLastLocation()">← {{'common.back' | translate}}</span>

    <button mat-icon-button [matMenuTriggerFor]="menu" class="header__link header__link--more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="backLastLocation()" class="header__menu-item">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'common.back' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>

<div class="spacer"></div>

<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>

<div class="title">
  <div class="title__text">{{'auth.forgot.forgotPassword' | translate}}</div>
  <div class="title__sub-text">{{'auth.forgot.forgotPasswordHelp' | translate}}</div>
</div>

<unleash-forgot-password
  class="form form--fixed"
  [isInProgress]="isInProgress$ | async"
  [savedEmail]="savedEmail$ | async"
  (submitForm)="continue($event)"
>
</unleash-forgot-password>
<div class="spacer"></div>

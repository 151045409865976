import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, Subscription} from 'rxjs';

@Directive({
  selector: '[ulRouterLinkActive]'
})
export class UlRouterLinkActiveDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public ulRouterLinkActive: string;
  private sub: Subscription;

  constructor(private router: Router, private el: ElementRef) {}

  public ngAfterViewInit(): void {
    this.sub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkLinkActive();
    });
  }

  public ngOnChanges(): void {
    this.checkLinkActive();
  }

  private checkLinkActive() {
    const isLibraryActive = this.ulRouterLinkActive === '/secure/library' && this.router.url.startsWith('/secure/view');
    const isAtlasActive = this.ulRouterLinkActive === '/secure/atlas' && this.router.url === '/secure/flights/logs';

    if (
      this.router.url === this.ulRouterLinkActive ||
      this.router.url.startsWith(this.ulRouterLinkActive) ||
      isLibraryActive ||
      isAtlasActive
    ) {
      this.el.nativeElement.classList.add('active');
    } else {
      this.el.nativeElement.classList.remove('active');
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {S3ExportDialogComponent} from './s3-export-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UnleashMaterialModule} from '@app/theme/unleash-material.module';

@NgModule({
  declarations: [S3ExportDialogComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, MatIconModule, MatTooltipModule, UnleashMaterialModule],
  exports: [S3ExportDialogComponent]
})
export class S3ExportDialogModule {}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SidebarStateService} from '@app/core/services/sidebar-state.service';
import {LinkMenuItem} from '@app/core/models/gui/link-menu-item';

@Component({
  selector: 'app-sidebar-menu-item-link',
  templateUrl: './sidebar-menu-item-link.component.html',
  styleUrls: ['./sidebar-menu-item-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarMenuItemLinkComponent {
  @Input() public menuItem: LinkMenuItem;
  @Input() public isExpanded: boolean = true;

  constructor(public sideNavbarStateService: SidebarStateService) {}
}

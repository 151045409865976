import {Pipe, PipeTransform} from '@angular/core';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import {map, Observable} from 'rxjs';

@Pipe({
  name: 'roleName',
  standalone: true
})
export class RoleNamePipe implements PipeTransform {
  constructor(private usersManagementStoreFacadeService: UsersManagementStoreFacadeService) {}

  // eslint-disable-next-line rxjs/finnish
  public transform(roleId: string): Observable<string> {
    return this.usersManagementStoreFacadeService.roles$.pipe(map(roles => roles[roleId]));
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IsUserAuthNoNavigateGuard} from '@app/auth/guards/is-auth-no-navigate.guard';
import {IsUserAuthOnboardingGuard} from '@app/auth/guards/is-auth-onboarding.guard';
import {IsUserAuthGuard} from '@app/auth/guards/is-auth.guard';
import {IsNotAwsUserGuard} from '@app/auth/guards/is-not-aws-user-guard.service';
import {RedirectGuard} from '@app/auth/guards/redirect.guard';
import {ConfirmRegistrationPage} from '@app/auth/pages/confirm-registration/confirm-registration.page';
import {ForgotPasswordStepTwoPage} from '@app/auth/pages/forgot-password-step-two/forgot-password-step-two.page';
import {ForgotPasswordPage} from '@app/auth/pages/forgot-password/forgot-password.page';
import {InviteAlreadyAcceptedPage} from '@app/auth/pages/invite-already-accepted/invite-already-accepted.page';
import {NewPasswordPage} from '@app/auth/pages/new-password/new-password.page';
import {PaymentPage} from '@app/auth/pages/payment/payment.page';
import {ReasonPage} from '@app/auth/pages/reason/reason.page';
import {SignInPage} from '@app/auth/pages/sign-in/sign-in.page';
import {SignUpPage} from '@app/auth/pages/sign-up/sign-up.page';
import {WelcomePage} from '@app/auth/pages/welcome/welcome.page';
import {YourProfilePage} from '@app/auth/pages/your-profile/your-profile.page';
import {AclCanActivateGuard} from '@app/core/guards/acl.guards';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {LogoutComponent} from '../auth/components/logout/logout.component';
import {PlanUnpaidComponent} from '../plans/components/plan-unpaid/plan-unpaid.component';
import {TrialExpiredComponent} from '../plans/components/trial-expired/trial-expired.component';
import {MyProfileComponent} from '../profile/components/myprofile.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {HomeComponent} from './components/public-home/home.component';
import {SecureHomeComponent} from './components/secure-home/home.component';
import {WelcomePageComponent} from './components/welcome-page/welcome-page.component';
import {TrialExpiredGuard} from './guards/trial-expired.guard';
import {MfaSmsVerificationComponent} from '@app/auth/components/mfa-sms-verification/mfa-sms-verification.component';
import {MfaGuard} from './guards/mfa.guard';
import {SetupMfaComponent} from '@app/auth/components/setup-mfa/setup-mfa.component';
import {TotpVerificationComponent} from '@app/auth/components/totp-verification/totp-verification.component';
import {TotpGuard} from './guards/totp.guard';
import {TotpTokenComponent} from '@app/auth/components/totp-token/totp-token.component';
import {MFA_SETUP_STATE} from '@app/auth/models/auth.models';
import {SetupMobileNumberComponent} from '@app/auth/components/setup-mobile-number/setup-mobile-number.component';
import {SetupPhoneGuard} from './guards/setup-phone.guard';
import {ConfirmMobileNumberComponent} from '@app/auth/components/confirm-mobile-number/confirm-mobile-number.component';
import {MobileNumberGuard} from './guards/mobile-number.guard';

const routes: Routes = [
  {path: '', redirectTo: '/auth/sign-in', pathMatch: 'full'},
  /** Routes that don't need user to be logged in and no navigation needed */
  {
    path: 'plans/trial/expired',
    title: 'Trial Expired - Unleash live',
    canActivate: [IsUserAuthOnboardingGuard],
    component: TrialExpiredComponent
  },
  {
    path: 'plans/unpaid',
    title: 'Payment Failed - Unleash live',
    component: PlanUnpaidComponent
  },
  {
    path: 'plans',
    title: 'Plans - Unleash live',
    canActivate: [IsNotAwsUserGuard],
    loadChildren: () => import('@app/plans/plans.module').then(m => m.PlansModule)
  },
  {
    path: 'live/embed/:deviceId',
    pathMatch: 'full',
    loadChildren: () => import('../live-embed/live-embed.module').then(m => m.LiveEmbedModule)
  },
  {path: 'welcome', component: WelcomePageComponent, title: 'Welcome - Unleash live'},
  {
    path: 'secure',
    component: SecureHomeComponent,
    canActivate: [IsUserAuthGuard, TrialExpiredGuard],
    children: [
      {
        path: 'view',
        title: 'Image Viewer - Unleash live',
        loadChildren: () => import('@app/image-viewer/image-viewer.module').then(m => m.ImageViewerModule)
      },
      {
        path: 'dashboard',
        title: 'Home - Unleash live',
        component: DashboardComponent
      },
      {
        path: 'developer-dashboard',
        loadChildren: () =>
          import('../developer-dashboard/developer-dashboard.module').then(m => m.DeveloperDashboardModule)
      },
      {
        path: 'live',
        title: 'Stream - Unleash live',
        loadChildren: () => import('../live/live.module').then(m => m.LiveModule)
      },
      {
        path: 'library',
        title: 'Media Drive - Unleash live',
        loadChildren: () => import('../library/library.module').then(m => m.LibraryModule)
      },
      {
        path: 'atlas',
        title: 'Atlas - Unleash live',
        loadChildren: () => import('../atlas/atlas.module').then(m => m.AtlasModule),
        canActivate: [AclCanActivateGuard],
        data: {
          permission: AclPermissions.AtlasApiRead
        }
      },
      {
        path: 'analysis',
        title: 'Analysis - Unleash live',
        loadChildren: () => import('@app/analysis/analysis.module').then(m => m.AnalysisModule),
        canActivate: [AclCanActivateGuard],
        data: {
          permission: AclPermissions.ReportApiRead
        }
      },
      {
        path: 'flights',
        title: 'Robotics Control - Unleash live',
        loadChildren: () => import('../flights/flights.module').then(m => m.FlightsModule)
      },
      {
        path: 'store',
        title: 'A.I. Apps - Unleash live',
        // loadChildren: () => import('@app/app-store/app-store.module').then(m => m.AppStoreModule)
        loadChildren: () =>
          import('@app/app-developer-store/app-developer-store.module').then(m => m.AppDeveloperStoreModule),
        canActivate: [AclCanActivateGuard],
        data: {
          permission: AclPermissions.AddonApiListPublic
        }
      },
      {
        path: 'profile',
        title: 'Account - Unleash live',
        component: MyProfileComponent,
        canDeactivate: [MobileNumberGuard]
      },
      {
        path: 'plans',
        data: {hideHeader: true},
        title: 'Plans - Unleash live',
        canActivate: [IsNotAwsUserGuard],
        loadChildren: () => import('@app/plans/plans.module').then(m => m.PlansModule)
      },
      {
        path: 'coming-soon',
        title: 'Coming soon - Unleash live',
        loadChildren: () => import('@app/holding-page/holding-page.module').then(m => m.HoldingPageModule)
      },
      {
        path: 'image-comparison',
        title: 'Image comparison - Unleash live',
        loadChildren: () =>
          import('@app/library/pages/compare-images/compare-images.module').then(m => m.CompareImagesModule)
      },
      {
        path: 'jobs',
        title: 'Jobs - Unleash live',
        loadChildren: () => import('@app/jobs/jobs.module').then(m => m.JobsModule),
        canActivate: [AclCanActivateGuard],
        data: {
          permission: AclPermissions.JobsApiListByTeamId,
          extraConditions: {
            page: 'jobs'
          }
        }
      },
      {path: '', redirectTo: '/secure/dashboard', pathMatch: 'full'}
    ]
  },
  {
    path: 'auth',
    component: HomeComponent,
    children: [
      {path: '', redirectTo: '/auth/sign-in', pathMatch: 'full'},
      {
        path: 'sign-up',
        canActivate: [RedirectGuard],
        component: SignInPage,
        data: {
          externalUrl: 'https://unleashlive.com/contact'
        }
      },
      {
        path: 'setup-mfa',
        component: SetupMfaComponent,
        canActivate: [MfaGuard],
        data: {
          mfa: MFA_SETUP_STATE.SETUP
        }
      },
      {
        path: 'totp-verification',
        component: TotpVerificationComponent,
        canActivate: [MfaGuard],
        data: {
          mfa: MFA_SETUP_STATE.VERIFY_TOTP
        }
      },
      {
        path: 'confirm-totp-token',
        component: TotpTokenComponent,
        canActivate: [TotpGuard]
      },
      {
        path: 'sms-verification',
        component: MfaSmsVerificationComponent,
        canActivate: [MfaGuard],
        data: {
          mfa: MFA_SETUP_STATE.VERIFY_SMS
        }
      },
      {
        path: 'setup-mobile-number',
        component: SetupMobileNumberComponent,
        canActivate: [SetupPhoneGuard]
      },
      {
        path: 'confirm-mobile-number',
        component: ConfirmMobileNumberComponent,
        canActivate: [SetupPhoneGuard]
      },
      {
        path: 'internal-signup',
        component: SignUpPage,
        canActivate: [IsUserAuthNoNavigateGuard],
        title: 'Sign Up - Unleash live',
        data: {animation: 'down', isDeveloperMode: false}
      },
      {
        path: 'sign-in',
        component: SignInPage,
        canActivate: [IsUserAuthNoNavigateGuard],
        title: 'Sign In - Unleash live',
        data: {animation: 'fadeInUp', isDeveloperMode: false}
      },
      {
        path: 'confirm-registration',
        component: ConfirmRegistrationPage,
        title: 'Confirm Registration - Unleash live',
        data: {animation: 1, isDeveloperMode: false}
      },
      {
        path: 'your-profile',
        component: YourProfilePage,
        canActivate: [IsUserAuthOnboardingGuard],
        title: 'Your Profile - Unleash live',
        data: {animation: 2, isDeveloperMode: false}
      },
      {
        path: 'reason',
        component: ReasonPage,
        canActivate: [IsUserAuthOnboardingGuard],
        title: 'Reason - Unleash live',
        data: {animation: 3, isDeveloperMode: false}
      },
      {
        path: 'welcome',
        component: WelcomePage,
        canActivate: [IsUserAuthOnboardingGuard],
        title: 'Welcome - Unleash live',
        data: {animation: 3}
      },
      {
        path: 'payment',
        component: PaymentPage,
        canActivate: [IsUserAuthOnboardingGuard],
        title: 'Payment - Unleash live',
        data: {animation: 4, isDeveloperMode: false}
      },
      {path: 'new-password', component: NewPasswordPage, data: {animation: 1}, title: 'New Password - Unleash live'},
      {
        path: 'forgot-password',
        component: ForgotPasswordPage,
        title: 'Forgot Password - Unleash live',
        data: {animation: 1}
      },
      {
        path: 'change-password',
        component: ForgotPasswordStepTwoPage,
        title: 'Forgot Password - Unleash live',
        data: {animation: 2}
      },
      {path: 'logout', pathMatch: 'full', component: LogoutComponent, title: 'Logout - Unleash live'},
      {
        path: 'developer-sign-up',
        component: SignUpPage,
        canActivate: [IsUserAuthNoNavigateGuard],
        data: {animation: 'down', isDeveloperMode: true}
      },
      {
        path: 'developer-sign-in',
        component: SignInPage,
        canActivate: [IsUserAuthNoNavigateGuard],
        data: {animation: 'fadeInUp', isDeveloperMode: true}
      },
      {
        path: 'developer-confirm-registration',
        component: ConfirmRegistrationPage,
        data: {animation: 1, isDeveloperMode: true}
      },
      {
        path: 'developer-profile',
        component: YourProfilePage,
        canActivate: [IsUserAuthOnboardingGuard],
        data: {animation: 2, isDeveloperMode: true}
      },
      {
        path: 'developer-payment',
        component: PaymentPage,
        canActivate: [IsUserAuthOnboardingGuard],
        data: {animation: 4, isDeveloperMode: true}
      },
      {
        path: 'developer-forgot-password',
        component: ForgotPasswordPage,
        data: {animation: 1, isDeveloperMode: true}
      },
      {
        path: 'developer-change-password',
        component: ForgotPasswordStepTwoPage,
        data: {animation: 2, isDeveloperMode: true}
      },
      {
        path: 'invite-already-accepted',
        loadComponent: () =>
          import('./../auth/pages/invite-already-accepted/invite-already-accepted.page').then(
            m => m.InviteAlreadyAcceptedPage
          ),
        data: {animation: 1, isDeveloperMode: false}
      }
    ]
  },
  {
    path: 'admin',
    title: 'Admin',
    component: SecureHomeComponent,
    canActivate: [IsUserAuthGuard],
    loadChildren: () => import('@app/admin/admin.module').then(m => m.AdminModule)
  },
  {path: '**', redirectTo: '/secure/dashboard'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {AnalysisConfigurationModule} from '../analysis-configuration/analysis-configuration.module';
import {AnnotationSharedModule} from '../annotation-shared/annotation-shared.module';
import {ManageZonesDialogComponent} from './manage-zones-dialog.component';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {ImageAnnotationSharedModule} from '@app/shared/image-annotation-shared/image-annotation-shared.module';
import {PinchZoomModule} from '@app/shared/pinch-zoom/pinch-zoom.module';
import {StoreModule} from '@ngrx/store';
import {
  managerZonesFeatureKey,
  managerZonesReducer
} from '@app/shared/manage-zones-dialog/store/manager-zones.newer-reducer';
import {EffectsModule} from '@ngrx/effects';
import {ManagerZonesEffects} from '@app/shared/manage-zones-dialog/store/manager-zones.effects';
import {AclDirective} from '@app/core/directives/acl.directive';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {ColorPickerComponent} from '@app/shared/color-picker/color-picker.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {UnleashMaterialSharedModule} from '@app/shared/unleash-material/unleash-material-shared.module';
import {DecimalInputMaskDirective} from '@app/shared/decimal-input-directive/decimal-input-mask.directive';
import {TriggerPointComponent} from '@app/shared/trigger-point/trigger-point.component';
import {MtxTooltipModule} from '@ng-matero/extensions/tooltip';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {TriggerPointDisplayNamePipe} from '../trigger-point/trigger-point-display-name.pipe';
import {CalibrationComponent} from '../calibration/calibration.component';
@NgModule({
  declarations: [ManageZonesDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTabsModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    AnalysisConfigurationModule,
    ImageAnnotationSharedModule,
    MatInputModule,
    UnleashLoaderModule,
    AnnotationSharedModule,
    LaddaModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    PinchZoomModule,
    AclDirective,
    ColorPickerComponent,
    MatMenuModule,
    MatDividerModule,
    StoreModule.forFeature(managerZonesFeatureKey, managerZonesReducer),
    EffectsModule.forFeature([ManagerZonesEffects]),
    SharedPipesModule,
    UnleashMaterialSharedModule,
    DecimalInputMaskDirective,
    TriggerPointComponent,
    MtxTooltipModule,
    SpinnerModule,
    TriggerPointDisplayNamePipe,
    CalibrationComponent
  ],
  exports: [ManageZonesDialogComponent]
})
export class ManageZonesDialogModule {}

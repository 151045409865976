import {Injectable} from '@angular/core';
import {share} from 'rxjs/operators';
import {ApiGateway} from './api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class RestreamService {
  public static DEMO_STREAM_URL: string = 'rtsp://demo.unleashlive.com/stream';

  constructor(public apiGateway: ApiGateway) {}

  startStreamFrom(sourceUrl: string, deviceId: string, streamKey: string) {
    return this.apiGateway.post('streams/from/start', {}, {sourceUrl, deviceId, streamKey}).pipe(share());
  }

  startStreamTo(destUrl: string, deviceId: string, streamKey: string) {
    return this.apiGateway.post('streams/to/start', {}, {destUrl, deviceId, streamKey}).pipe(share());
  }

  stopStreamFrom(deviceId: string, streamKey: string) {
    return this.apiGateway.post('streams/from/stop', {}, {deviceId, streamKey}).pipe(share());
  }

  stopStreamTo(deviceId: string, streamKey: string) {
    return this.apiGateway.post('streams/to/stop', {}, {deviceId, streamKey}).pipe(share());
  }

  getActiveStreams(streamKey: string) {
    return this.apiGateway.get(`streams/${encodeURIComponent(streamKey)}`, {}).pipe(share());
  }
}

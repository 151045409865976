import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {TileLayer} from 'leaflet';

@Directive({
  selector: '[ulBringToBackLayer]',
  standalone: true
})
export class BringToBackLayerDirective {
  @Input('ulBringToBackLayer') public asset: AtlasAssetModel;

  constructor() {}

  @HostListener('click', ['$event'])
  public bringToBackLayer(): void {
    const asset = this.asset;
    (asset.leafletLayer as TileLayer).bringToBack();
  }
}

<h1 mat-dialog-title>{{ 'atlas.layerName.title' | translate }}</h1>
<div mat-dialog-content [formGroup]="form">
  <p>{{ 'atlas.layerName.description' | translate }}</p>
  <mat-form-field>
    <input matInput placeholder="{{ 'atlas.renameLayer.layerName' | translate }}" formControlName="name" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button color="primary" [mat-dialog-close]="false">{{ 'common.cancel' | translate }}</button>
  <button mat-button color="primary" [disabled]="!form.value.name" [mat-dialog-close]="form.value.name">
    {{ 'common.save' | translate }}
  </button>
</div>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NumbersOnlyDirective} from '@app/shared/numbers-only-directive/numbers-only.directive';

@NgModule({
  declarations: [NumbersOnlyDirective],
  imports: [CommonModule],
  exports: [NumbersOnlyDirective]
})
export class NumbersOnlyDirectiveModule {}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'uUtc', standalone: true})
export class UtcPipe implements PipeTransform {
  transform(date: Date | number): Date {
    const parseDate = new Date(date);
    return new Date(
      Date.UTC(
        parseDate.getUTCFullYear(),
        parseDate.getUTCMonth(),
        parseDate.getUTCDate(),
        parseDate.getUTCHours(),
        parseDate.getUTCMinutes(),
        parseDate.getUTCSeconds(),
        parseDate.getUTCMilliseconds()
      )
    );
  }
}

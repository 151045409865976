import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input} from '@angular/core';
import {AclService} from '@app/core/services/acl.service';

@Directive({
  selector: '[uaDisabledPermissionsButton]',
  standalone: true
})
export class AclDisabledDirective implements AfterViewInit {
  @Input() public uaDisabledPermissionsButton: string;

  constructor(private el: ElementRef<any>, private aclService: AclService, private cd: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    const button = this.el.nativeElement;

    if (this.aclService.hasPermission(this.uaDisabledPermissionsButton)) {
      button.disabled = false;
      button?.classList?.remove('mat-button-disabled');
      return;
    }

    button.disabled = true;
    button?.classList?.add('mat-button-disabled');
  }
}

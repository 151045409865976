import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';

@Directive({
  selector: '[ulShowOnlyThisLayer]',
  standalone: true
})
export class ShowOnlyThisLayerDirective {
  @Input('ulShowOnlyThisLayer') public asset: AtlasAssetModel;

  constructor(private atlasService: AtlasService) {}

  @HostListener('click', ['$event'])
  public showOnlyThisLayer(): void {
    const assetToSelect = this.asset;
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = assetToSelect.id === asset.id;
      asset.isHighlighted = false;
    });
    this.atlasService.toogleHasToDetectChanges();
  }
}

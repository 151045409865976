import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {TeamRole} from '@app/profile/models/team.model';
import {UserActions} from '@app/profile/models/user-actions.model';
import {UserTeamAction, UserTeamTable, UserTeamTableActions} from '@app/profile/models/user-team.model';
import {Roles, UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-user-teams-table',
  templateUrl: './user-teams-table.component.html',
  styleUrls: ['./user-teams-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTeamsTableComponent {
  @Input() public dataSource: UserTeamTable[] = [];
  @Input() public menuItems: UserTeamTableActions[] = [];
  @Input() public selectedUser: UserTable = {email: '', userId: '0', lastActive: 0, name: '', role: TeamRole.viewer};
  @Input() public roles: Roles = {};
  @Output() public action: EventEmitter<UserTeamAction> = new EventEmitter();
  @Output() public userTeam: EventEmitter<UserTeamTable> = new EventEmitter();
  @Output() public editUserTeamRole: EventEmitter<UserTeamAction> = new EventEmitter();

  public displayedColumns = ['team', 'role', 'actions'];
  public teamRole = TeamRole;

  public emitAction(type: UserActions): void {
    this.action.emit({action: type, userId: this.selectedUser.userId});
  }

  public emitUserTeam(userTeam: UserTeamTable): void {
    this.userTeam.emit(userTeam);
  }

  public emitUserTeamRole(userTeamRole: {action: UserActions; userId: string; payload: TeamRole}): void {
    this.editUserTeamRole.emit(userTeamRole);
  }
}

import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {TeamRole} from '@app/profile/models/team.model';
import {UserActions} from '@app/profile/models/user-actions.model';
import {Roles, UserAction, UserTable, UserTableActions} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersTableComponent implements OnInit {
  @Input() public dataSource: UserTable[] = [];
  @Input() public menuItems: UserTableActions[] = [];
  @Input() public hasMoreThanOneAdmin: boolean = false;
  @Input() public roles: Roles = {};
  @Input() public isSelectionEnabled: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public hasToHideRole: boolean = false;
  @Output() public action: EventEmitter<UserAction> = new EventEmitter();
  @Output() public user: EventEmitter<UserTable> = new EventEmitter();
  @Output() public clearFilter: EventEmitter<void> = new EventEmitter();

  public formGroup: FormGroup;
  public checkBoxes: FormArray;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.checkBoxes = new FormArray(this.dataSource.map(() => new FormControl()));
    this.formGroup = this.fb.group({
      allCheckbox: new FormControl(false),
      checkBoxControls: this.checkBoxes
    });

    if (this.isSelectionEnabled) {
      this.displayedColumns = ['select', 'name', 'role'];
    } else if (this.hasToHideRole) {
      this.displayedColumns = ['name', 'actions'];
    } else {
      this.displayedColumns = ['name', 'role', 'actions'];
    }
  }

  public aclPermissions = AclPermissions;
  public teamRole = TeamRole;
  public displayedColumns = [];

  public emitAction(type: UserActions, userId: string): void {
    this.action.emit({action: type, userId});
  }

  public emitUser(user: UserTable): void {
    this.user.emit(user);
  }

  public emitUserTeamRole(userTeamRole: {action: UserActions; userId: string; payload: TeamRole}): void {
    this.action.emit({
      action: userTeamRole.action,
      userId: userTeamRole.userId,
      payload: {role: userTeamRole.payload}
    });
  }

  public emitClearFilter() {
    this.clearFilter.emit();
  }

  public toggleAllRowsWithFormArray(checkbox: MatCheckboxChange): void {
    const checkBoxControls = this.formGroup.get('checkBoxControls') as FormArray;
    checkBoxControls.controls.forEach((control: FormControl) => {
      control.setValue(checkbox.checked);
    });
  }

  public isAllCheckboxSelected(): void {
    const isAllCheckboxSelected = this.formGroup.value['checkBoxControls'].every(checkBox => !!checkBox);
    this.formGroup.get('allCheckbox').setValue(isAllCheckboxSelected);
  }

  public toggleSelection(index: number): void {
    const checkBoxControls = this.formGroup.get('checkBoxControls') as FormArray;
    const currentCheckboxValue = checkBoxControls.controls[index].value;
    checkBoxControls.controls[index].setValue(!currentCheckboxValue);
    this.isAllCheckboxSelected();
  }

  public emitSelectedValues(): void {
    return this.formGroup.value['checkBoxControls']
      .map((isSelected: boolean, index: number) => (isSelected ? this.dataSource[index] : null))
      .filter(user => !!user);
  }
}

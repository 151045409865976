import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StreamWebrtcComponent} from '../pages/stream-webrtc/stream-webrtc.component';
import {AgoraService} from '../services/agora.service';

@Injectable({
  providedIn: 'root'
})
export class ExitStreamGuard {
  constructor(private agoraService: AgoraService) {}
  public canDeactivate(
    component: StreamWebrtcComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.isStreaming$.value
      ? component.openDialog().pipe(
          map(isPageLeaved => {
            if (isPageLeaved) {
              this.agoraService.isStreaming$.next(false);
              this.agoraService.streamStopped$.next(true);
              return true;
            }
            return false;
          })
        )
      : true;
  }
}

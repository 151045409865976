import {Component, ChangeDetectionStrategy, Input, EventEmitter, Output} from '@angular/core';
import {TeamRole} from '@app/profile/models/team.model';
import {UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailComponent {
  @Input() public user: UserTable = {
    email: '',
    userId: '',
    lastActive: 0,
    name: '',
    role: TeamRole.viewer,
    createdAt: 0
  };
  @Output() public back: EventEmitter<void> = new EventEmitter();

  public emitBack(): void {
    this.back.emit();
  }
}

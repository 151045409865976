import {authReducer} from '@app/auth/state/auth.reducer';
import {AuthState} from '@app/auth/state/auth.state';
import {aclReducer, AclState} from '@app/core/store/acl/store/acl.reducer';
import {reducer as addonReducers, State as AddonState} from '@app/core/store/addon/addon.reducer';
import {reducer, State} from '@app/core/store/sidenav-menu/sidenav-menu.reducer';
import {ActionReducerMap, createFeatureSelector, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {initStateFromLocalStorage} from './meta-reducers/init-state-from-local-storage.reducer';
import {routerRedirectReducer, RouterRedirectState, routerReducers, RouterState} from './router/router.reducer';
import {UserState, userStateReducer} from './user/user.reducer';

export interface AppState {
  authState: AuthState;
  router: RouterState;
  redirect: RouterRedirectState;
  user: UserState;
  addon: AddonState;
  sidenavMenu: State;
  acl: AclState;
}

export const selectAuthState = createFeatureSelector<AppState, AuthState>('authState');
export const selectRouterRedirectState = createFeatureSelector<AppState, RouterRedirectState>('redirect');
export const selectRouterNavigatedAction = createFeatureSelector<AppState, RouterState>('router');
export const selectUserState = createFeatureSelector<AppState, UserState>('user');

export const reducers: ActionReducerMap<AppState> = {
  authState: authReducer,
  router: routerReducers,
  redirect: routerRedirectReducer,
  user: userStateReducer,
  addon: addonReducers,
  sidenavMenu: reducer,
  acl: aclReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [initStateFromLocalStorage]
  : [initStateFromLocalStorage];

import {Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Params;
}

export class MinimalRouterSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: {queryParams}
    } = routerState;
    const {params, data} = route;
    const urlWithoutQueryParams = url.split('?')[0];

    return {url: urlWithoutQueryParams, params, queryParams, data};
  }
}

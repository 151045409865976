import {Directive, ElementRef, HostListener, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[uaDecimalInputMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalInputMaskDirective),
      multi: true
    }
  ],
  standalone: true
})
export class DecimalInputMaskDirective implements ControlValueAccessor {
  private _value: any;

  constructor(private elementRef: ElementRef) {}

  public writeValue(value: any): void {
    if (value) {
      const valueToString = value + '';
      this._value = this.sanitize(valueToString);
      this.elementRef.nativeElement.value = this.display(this._value);
    } else {
      this._value = '';
    }
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  private onChange = (_: any) => {};

  @HostListener('input', ['$event.target.value'])
  public onInput(value: string): void {
    this.writeValue(value.toString());
    this.onChange(this._value);
  }

  private sanitize(value: string): any {
    const numberValue = value.replace(/[^\d,\\.]/g, '').replace(/,/g, '.');
    const [integerPart, decimalPart] = numberValue.split('.');

    const sanitizedIntegerPart = integerPart ? parseInt(integerPart, 10) : 0;
    let sanitizedDecimalPart = 0;

    if (decimalPart) {
      // eslint-disable-next-line no-magic-numbers
      const sanitizedDecimalPartString = decimalPart.length > 2 ? decimalPart.substring(0, 2) : decimalPart;
      sanitizedDecimalPart = parseFloat(`0.${sanitizedDecimalPartString}`);

      if (sanitizedDecimalPart >= 0 && decimalPart === '0') {
        return sanitizedIntegerPart.toString() + '.0';
      }

      return (sanitizedIntegerPart + sanitizedDecimalPart).toString();
    }

    if (numberValue.includes('.') && decimalPart === '') {
      return sanitizedIntegerPart.toString() + '.';
    }

    return sanitizedIntegerPart.toString();
  }

  private display(value: string): string {
    return value ? value : '00,00';
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {LocalStorageService} from '../services/local-storage/local-storage.service';
import {MFA_SETUP_STATE} from '@app/auth/models/auth.models';
import {AuthService} from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MfaGuard {
  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const mfaGuard = route.data.mfa;
    const MFASetupState = this.localStorageService.getItem('MFASetupState');
    let hasToActivate: boolean = false;
    switch (mfaGuard) {
      case MFA_SETUP_STATE.SETUP:
        hasToActivate = MFASetupState === MFA_SETUP_STATE.SETUP || MFASetupState === MFA_SETUP_STATE.CONFIRM_TOTP;
        break;
      case MFA_SETUP_STATE.VERIFY_TOTP:
        hasToActivate = MFASetupState === MFA_SETUP_STATE.VERIFY_TOTP && this.authService.cognitoUser;
        break;
      case MFA_SETUP_STATE.VERIFY_SMS:
        hasToActivate = MFASetupState === MFA_SETUP_STATE.VERIFY_SMS && this.authService.cognitoUser;
        break;
    }
    if (!hasToActivate) {
      this.router.navigate(['/auth/sign-in']);
    }
    return hasToActivate;
  }
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {}
}

import {HttpErrorResponse} from '@angular/common/http';
import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Addon} from '@app/store/addon/models/addon';
import {AddonService} from '../../../core/services/api/addon.service';

@Component({
  selector: 'app-activate-dialog',
  templateUrl: './trial-dialog.component.html',
  styleUrls: ['./trial-dialog.component.scss']
})
export class TrialDialogComponent {
  isActivating: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {addon: Addon},
    public dialogRef: MatDialogRef<TrialDialogComponent>,
    public addonService: AddonService,
    private snackBar: MatSnackBar
  ) {}

  async startTrial(): Promise<void> {
    this.isActivating = true;
    this.addonService.startTrialForUser(this.data.addon.id).subscribe(
      (isTrialStarted: Addon) => {
        this.dialogRef.close(!!isTrialStarted);
        this.isActivating = false;
      },
      (err: HttpErrorResponse) => {
        this.isActivating = false;
        const errorMessage = err.error.message ? err.error.message : err.error;
        this.snackBar.open(errorMessage, 'OK');
      }
    );
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {
  actionForgotPasswordFormError,
  actionForgotPasswordFormSubmit,
  actionForgotPasswordFormSuccess,
  actionSaveEmail
} from './forgot-password.actions';
import {ForgotPassword} from './forgot-password.model';

export interface ForgotPasswordFormState {
  form: ForgotPassword;
  error?: AuthErrorModel;
  inProgress: boolean;
}

export const initialState: ForgotPasswordFormState = {
  form: {email: ''},
  inProgress: false
};

const reducer = createReducer(
  initialState,
  on(actionForgotPasswordFormSubmit, (state, {form}) => ({
    ...state,
    error: undefined,
    inProgress: true,
    form
  })),
  on(actionForgotPasswordFormError, (state, {error}) => ({
    ...state,
    error,
    inProgress: false
  })),
  on(actionForgotPasswordFormSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: false
  })),
  on(actionSaveEmail, (state, action: {payload: {email: string}}) => ({
    ...state,
    form: {...state.form, ...action.payload}
  }))
);

export function forgotPasswordStateReducer(
  state: ForgotPasswordFormState | undefined,
  action: Action
): ForgotPasswordFormState {
  return reducer(state, action);
}

import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, Inject} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {emailRegex} from '@app/auth/regex';
import {Team} from '@app/profile/models/team.model';
import {UserInvitation} from '@app/profile/models/user-roles.model';
import {Roles} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUserComponent implements OnInit {
  @Input() public roles: Roles = {};
  @Input() public teams: Team[] = [];
  @Output() public invite: EventEmitter<Partial<UserInvitation[]>> = new EventEmitter();
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();

  public isDialogMode: boolean = false;
  public forms = new FormArray([]);

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {isDialogMode: boolean; teams: Team[]; roles: Roles},
    private dialog: MatDialogRef<AddUserComponent>
  ) {
    if (this.data?.isDialogMode) {
      this.isDialogMode = this.data.isDialogMode;
      this.teams = this.data.teams;
      this.roles = this.data.roles;
    }
  }

  public ngOnInit(): void {
    this.addAnotherUser();
  }

  public addAnotherUser(): void {
    this.forms.push(
      this.fb.group({
        email: ['', [Validators.required, Validators.pattern(emailRegex)]],
        teamId: ['', Validators.required],
        role: ['', Validators.required]
      })
    );
  }

  public deleteUserRow(userRowIndex: number): void {
    this.forms.removeAt(userRowIndex);
  }

  public emitInvite(): void {
    if (this.forms.invalid) {
      (this.forms.controls as FormGroup[]).forEach(formGroup => {
        formGroup.controls.email.markAsTouched();
        formGroup.controls.role.markAsTouched();
        formGroup.controls.teamId.markAsTouched();
      });
      return;
    }
    this.invite.emit(this.forms.value as any);
  }

  public emitCancel(): void {
    this.cancel.emit();
  }

  public emitBack(): void {
    this.back.emit();
  }

  public emitCloseDialog(): void {
    this.dialog.close({hasToAddUsers: false});
  }

  public emitInviteDialog(): void {
    if (this.forms.invalid) {
      (this.forms.controls as FormGroup[]).forEach(formGroup => {
        formGroup.controls.email.markAsTouched();
        formGroup.controls.role.markAsTouched();
        formGroup.controls.teamId.markAsTouched();
      });
      return;
    }
    this.dialog.close({hasToAddUsers: true, users: this.forms.value});
  }
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiKeyDialogMode} from '@app/analysis/components/api-key-dialog/api-key-dialog.model';
import {AnalysisPageService} from '@app/analysis/services/analysis-page.service';
import {Observable, share} from 'rxjs';

@Component({
  selector: 'unleash-api-key-dialog',
  templateUrl: './api-key-dialog.component.html',
  styleUrls: ['./api-key-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiKeyDialogComponent {
  public dialogMode: ApiKeyDialogMode = ApiKeyDialogMode.UPDATE;
  public apiKeyDialogModeEnum = ApiKeyDialogMode;
  public isApiKeyActionInProgress$: Observable<boolean> = this.analysisPageService.isApiKeyActionInProgress$.pipe(
    share()
  );

  constructor(
    private dialogRef: MatDialogRef<ApiKeyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {dialogMode: ApiKeyDialogMode},
    private analysisPageService: AnalysisPageService
  ) {
    this.dialogMode = this.data.dialogMode || ApiKeyDialogMode.UPDATE;
  }

  public emitClose(): void {
    this.dialogRef.close({hasToExecute: false});
  }

  public emitUpdateKey(): void {
    this.analysisPageService.updateKey();
  }

  public emitRemoveKey(): void {
    this.analysisPageService.removeKey();
  }
}

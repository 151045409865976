import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {latLng, Marker, Polyline} from 'leaflet';
import {FlightFrame} from '../../../flights/components/flight-log/flight-log-parser';
import {AtlasAssetModel} from '../../../core/models/api/atlas.model';
import {FlightLogAssetLoaderService, FlightLogData} from '../../services/flight-log-asset-loader.service';
import {filter, map, take} from 'rxjs/operators';
import {Map} from 'leaflet';

@Component({
  selector: 'atlas-flight-log',
  templateUrl: './atlas-flight-log.component.html',
  styleUrls: ['./atlas-flight-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlasFlightLogComponent implements OnInit {
  timeControlsVisible: boolean = false;
  currentFrame: FlightFrame = {} as any;
  @Input()
  asset: AtlasAssetModel;
  @Input()
  map: Map;
  private flightFrames: FlightFrame[] = [];
  private realTimePathPolyline: Polyline;
  private droneMarker: Marker | any; // use any because of missing typing in leaflet-rotatedmarker

  constructor(
    private http: HttpClient,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private flightLogAssetLoaderService: FlightLogAssetLoaderService
  ) {}

  ngOnInit() {
    this.flightLogAssetLoaderService.flights$
      .pipe(
        map(f => f[this.asset.id]),
        filter(Boolean),
        take(1)
      )
      .subscribe((flightData: FlightLogData) => {
        this.initializeFlightLogPlayer(flightData);
      });
  }

  initializeFlightLogPlayer(flightData: FlightLogData) {
    this.realTimePathPolyline = flightData.realTimePathPolyline;
    this.flightFrames = flightData.flightFrames;
    this.droneMarker = flightData.droneMarker;
    this.droneMarker.on('click', () => {
      this.timeControlsVisible = !this.timeControlsVisible;
      this.changeDetector.detectChanges();
    });
  }

  newFrame({frameNum, redraw}: {frameNum: number; redraw?: boolean}) {
    if (!this.flightFrames || this.flightFrames.length == 0) {
      console.warn('Empty frames information');
      return;
    }
    const frame = this.flightFrames[frameNum];
    const dronePosition = latLng(frame.latitude, frame.longitude);
    if (!!this.flightFrames && this.flightFrames.length === 1) {
      // console.info('panning to dronePosition',dronePosition );
      // this.map.panTo(dronePosition);
    }
    this.droneMarker.setLatLng(dronePosition);
    this.droneMarker.setRotationAngle(frame.yaw);
    this.currentFrame = frame;

    if (redraw) {
      // used for jumping in time
      this.realTimePathPolyline.setLatLngs(
        this.flightFrames.slice(0, frameNum).map(f => latLng(f.latitude, f.longitude))
      );
    } else {
      if (frame.latitude === 0) {
        console.warn('Skipping empty latlng');
        return;
      }
      // just mark the next frame, don't redraw
      this.realTimePathPolyline.addLatLng(dronePosition);
    }
  }
}

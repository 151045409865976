<div fxLayout="column" fxLayout.xs="column" fxFlexAlign="center">
  <form *ngIf="!isReadOnly" [formGroup]="addonForm" novalidate id="addon-form" autocomplete="off">
    <mat-list>
      <mat-list-item *ngIf="allowPhotoEdit" class="logo-upload-container">
        <logo-upload
          (s3Url)="setLogoUrl($event)"
          (isUploadingEmitter)="imageUpload($event)"
          [subfolder]="'addons'"
          [inputBucket]="uploadLogoSource.ADDON"
          [parentId]="addon.id"
          [url]="addonForm.get('logo').value"
        ></logo-upload>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <input type="text" matInput formControlName="name" placeholder="Name" required />
          <mat-error *ngIf="addonForm.get('name').hasError('required')"> Name is required </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <input type="text" matInput formControlName="subtitle" placeholder="Subtitle" required />
          <mat-error *ngIf="addonForm.get('subtitle').hasError('required')"> Subtitle is required </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item class="category-input">
        <mat-form-field>
          <mat-select placeholder="Select Model Category" formControlName="category" required>
            <mat-option *ngFor="let category of CATEGORIES" [value]="category">
              {{ category }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addonForm.get('category').hasError('required')"> Category is required </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item class="description-container">
        <mat-form-field>
          <textarea type="text" matInput formControlName="description" [rows]="3" placeholder="Description" required>
          </textarea>
          <mat-error *ngIf="addonForm.get('description').hasError('required')"> Description is required </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <input matInput value="{{ addon.developer }}" placeholder="Developer" disabled />
          <mat-error *ngIf="addonForm.get('developer').hasError('required')">
            Set your company name or your full name in profile
          </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <input type="number" matInput formControlName="price" placeholder="Price" required />
          <mat-error *ngIf="addonForm.get('price').hasError('required')"> Price is required </mat-error>
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <input type="text" matInput formControlName="currency" placeholder="Currency Code" required />
          <mat-error *ngIf="addonForm.get('currency').hasError('required')"> Currency Code is required </mat-error>
          <mat-error *ngIf="addonForm.get('currency').hasError('minLength')">
            Currency Code has to be 3 characters long
          </mat-error>
        </mat-form-field>
      </mat-list-item>
      <!--<mat-list-item class="clipping-input">-->
      <!--<mat-radio-group formControlName="clipping" fxLayout="column" fxLayoutGap=".6rem">-->
      <!--<mat-radio-button *ngFor="let type of CLIPPING_TYPES" [value]="type">{{type}}</mat-radio-button>-->
      <!--</mat-radio-group>-->
      <!--</mat-list-item>-->
      <mat-list-item class="checkbox-container">
        <mat-checkbox type="checkbox" formControlName="stream">Live Stream</mat-checkbox>
      </mat-list-item>
      <mat-list-item class="checkbox-container">
        <mat-checkbox type="checkbox" formControlName="clipping">Video Clipping</mat-checkbox>
      </mat-list-item>
      <mat-list-item class="checkbox-container">
        <mat-checkbox type="checkbox" formControlName="image">Image Analysis</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="classes"
            placeholder="Number of clasification classes"
            required
          />
          <mat-error *ngIf="addonForm.get('classes').hasError('required')"> Price is required </mat-error>
        </mat-form-field>
      </mat-list-item>
      <!--<mat-list-item *ngIf="allowPhotoEdit" fxLayout="row" fxLayoutWrap fxLayoutGap="10px">-->
      <!--&lt;!&ndash;<mat-grid-list cols="2">&ndash;&gt;-->
      <!--&lt;!&ndash;<mat-grid-tile *ngFor="let screenshot of addon.screenshots; let i = index" [attr.data-index]="i">&ndash;&gt;-->
      <!--<div *ngFor="let screenshot of addon.screenshots; let i = index" [attr.data-index]="i" fxLayout="row">-->
      <!--<logo-upload (s3Url)="setScreenshotUrl($event, i)" (isUploading)="imageUpload($event)" [subfolder]="'addons'" [parentId]="addon.id" [url]="screenshot"></logo-upload>-->
      <!--</div>-->
      <!--&lt;!&ndash;</mat-grid-tile>&ndash;&gt;-->
      <!--&lt;!&ndash;<mat-grid-tile>&ndash;&gt;-->
      <!--<div>-->
      <!--<logo-upload (s3Url)="addNewScreenshot($event)" (isUploading)="imageUpload($event)" [subfolder]="'addons'" [parentId]="addon.id" [url]=""></logo-upload>-->
      <!--</div>-->
      <!--&lt;!&ndash;</mat-grid-tile>&ndash;&gt;-->
      <!--&lt;!&ndash;</mat-grid-list>&ndash;&gt;-->
      <!--</mat-list-item>-->
    </mat-list>
  </form>
  <div *ngIf="isReadOnly">
    <div class="screenshots" *ngIf="expanded && addon.screenshots && addon.screenshots.length > 0">
      <ul>
        <li *ngFor="let screenshotUrl of addon.screenshots"><img [src]="screenshotUrl" alt="Screenshot" /></li>
      </ul>
    </div>
    <div>
      <h2>Description</h2>
      <!-- THIS COMPONENT IS NO USED SO I WONT REPLACE THE ngx-md package -->
      <!-- <ngx-md>{{ addon.description }}</ngx-md> -->
    </div>
    <div>
      <h2>Created by</h2>
      <p>{{ addon.developer }}</p>
    </div>
    <div>
      <h2>Price</h2>
      <div *ngIf="!addon.unpublished">
        <p *ngIf="addon.price !== 0">{{ addon.currency }} {{ addon.price }}/month</p>
        <p *ngIf="addon.price === 0">Free</p>
      </div>
      <div *ngIf="addon.unpublished">
        If you are interested in pricing and usage of this addon
        <a class="button" mat-button href="mailto:getstarted@unleashlive.com" class="mat-primary">Contact us</a>
      </div>
    </div>
  </div>
</div>

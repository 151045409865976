import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AnalysisFilter} from '@app/analysis/models/filter.model';

@Component({
  selector: 'unleash-analysis-mobile-filter',
  templateUrl: './analysis-mobile-filter.component.html',
  styleUrls: ['./analysis-mobile-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalysisMobileFilterComponent {
  public form: FormGroup;
  public types: {name: string; value: string}[];
  public currentDate: Date = new Date();
  public hasClear: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<AnalysisMobileFilterComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {types: {name: string; value: string}[]; filters: AnalysisFilter}
  ) {
    this.types = data.types;
    this.form = this.formBuilder.group({
      search: [this.data.filters.search],
      from: [this.data.filters.from ? new Date(this.data.filters.from) : null],
      to: [this.data.filters.to ? new Date(this.data.filters.to) : null],
      type: [this.data.filters.type]
    });
    this.hasClear = Object.keys(this.data.filters).some(key => this.form.value[key]);
    this.onFormValueChange();
  }

  private onFormValueChange(): void {
    const initialValue = this.form.value;
    this.form.valueChanges.subscribe(value => {
      this.hasClear = Object.keys(initialValue).some(key => this.form.value[key]);
    });
  }

  public filter(): void {
    this.dialogRef.close(this.form.value);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public clearData(field: string): void {
    this.form.controls[field].reset();
  }

  public resetFilters(): void {
    this.form.reset();
  }
}

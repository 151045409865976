import {Component, ElementRef, forwardRef, OnInit, ViewChild, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'unleash-chip-list',
  templateUrl: './unleash-chip-list.component.html',
  styleUrls: ['./unleash-chip-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnleashChipListComponent),
      multi: true
    }
  ]
})
export class UnleashChipListComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() placeholder;
  @Input() chipList: string[] = [];

  constructor() {}

  addToChipList(typeName: {input: string; value: string}) {
    if (typeName.value !== '') {
      this.chipList.push(typeName.value);
      this.writeValue([...this.chipList]);
    }
  }

  removeFromChipList(chipIndex: number) {
    this.chipList.splice(chipIndex, 1);
    this.writeValue([...this.chipList]);
    this.onChange([...this.chipList]);
  }

  onChange = (value: string[]) => {};
  onTouched = () => {};
  writeValue(value: string[]): void {
    this.chipList = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {}
}

import {divIcon} from 'leaflet';

export const createDeviceIcon = (droneIcon: DRONE_ICON, isOffline?: boolean) => {
  let className = 'device-icon';
  if (droneIcon.selected) {
    className += ' icon-selected';
  }
  // eslint-disable-next-line no-constant-condition
  const textStyles =
    'font-family:Rubik; font-weight: 600; color: white;text-shadow: 2px 0px 0px black, -2px 0px 0px black,  0px 2px 0px black, 0px -2px 0px black';
  const rawHtml = isOffline
    ? `<div style="opacity: 0.5; display:flex; align-items:center;"><img src='${generateIcon(
        droneIcon,
        '/assets/icons/atlas/videocam-small.svg'
      )}'/><span style=${JSON.stringify(textStyles)}>${droneIcon.iconText}</span></div>`
    : `<img src='${generateIcon(droneIcon, '/assets/icons/atlas/videocam-small.svg')}'/><span style=${JSON.stringify(
        textStyles
      )}>${droneIcon.iconText}</span>`;
  return divIcon({
    html: droneIcon.iconText && rawHtml,
    iconSize: [droneIcon.size, droneIcon.size],
    className
  });
};

function generateIcon(droneIcon, defaultIcon): string {
  return droneIcon.iconUrl !== '/assets/images/placeholder.png' ? droneIcon.iconUrl : defaultIcon;
}

export const createDeviceGimYawIcon = (droneIcon: DRONE_ICON) => {
  const offsetAngle = 90;
  let className = 'device-icon';
  if (droneIcon.selected) {
    className += ' icon-selected';
  }
  // eslint-disable-next-line no-constant-condition

  const rawHtml = `
      <div class="triangle-circle" style="transform: rotate(${
        droneIcon.droneYaw + offsetAngle
      }deg)"><span class="triangle"></span></div>
      <div class="triangle-circle" style="transform: rotate(${
        droneIcon.gimYaw + offsetAngle
      }deg)"><span class="circle"></span></div>`;

  return divIcon({
    html: rawHtml,
    iconSize: [droneIcon.size, droneIcon.size],
    className
  });
};

export interface DRONE_ICON {
  selected?: boolean;
  size?: number;
  iconUrl?: string;
  iconText?: string;
  droneYaw?: number;
  gimYaw?: number;
}

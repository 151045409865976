export enum ImagePath {
  DEVELOPER_DASHBOARD_IMG_PATH = 'developer-dashboard',
  LIVE_STREAM_ICON_IMG_PATH = 'stream-menu',
  LIBRARY_ICON_IMG_PATH = 'media-drive-menu',
  FUSION_ATLAS_ICON_IMG_PATH = 'atlas-menu',
  ANALYSIS_ICON_IMG_PATH = 'analysis',
  MISSION_CONTROL_ICON_IMG_PATH = 'dash-mission-planning',
  AI_APPS_STORE_ICON_IMG_PATH = 'dash-ai-store',
  HELP_ICON_IMG_PATH = 'help-menu',
  ACCOUNT_ICON_IMG_PATH = 'dash-account',
  DATA_ICON_IMG_PATH = 'data-menu',
  NOTIFICATIONS = 'notifications',
  JOBS = 'jobs'
}

<div class="container">
  <div class="header" [class.list]="isListOnly">
    <div class="header__search is-active">
      <app-search-layer
        #searchLayer
        class="search-layer__input"
        [hasAllLayersLoaded]="hasAllLayersLoaded"
        [searchValue]="searchValue$ | async"
        [placeholder]="searchPlaceholder$ | async"
      >
      </app-search-layer>
    </div>
    <button class="hide-sidebar" (click)="emitHideSideBar()" mat-flat-button color="primary">
      <mat-icon>layers</mat-icon>
    </button>
  </div>
  <div
    [class.list]="isListOnly"
    class="layer-list {{ totalSelectedLayers ? 'is-selecting' : '' }}"
    (click)="$event.stopPropagation()"
  >
    <ng-container *ngIf="devices$ | async as devices">
      <ng-container *ngIf="searchedStreamingGpsDevices$ | async as searchedStreamingGpsDevices">
        <ng-container *ngIf="devices.length">
          <div class="layer-list-header" (click)="toggleExpanded()">
            <span
              >{{ 'atlas.layerControl.devices' | translate }}
              <span *ngIf="searchFilterQuery$ | async"
                >({{ searchedStreamingGpsDevices.length }}/{{ devices.length }})</span
              ></span
            >
            <button mat-icon-button>
              <mat-icon inline="true">
                {{ isExpanded ? 'expand_less' : 'expand_more' }}
              </mat-icon>
            </button>
          </div>
          <ng-container *ngIf="isExpanded">
            <app-layers-control-items
              class="device-list-container"
              *ngIf="searchedStreamingGpsDevices.length; else noResults"
              [map]="map"
              [assets]="searchedStreamingGpsDevices"
              (showDashboard)="emitShowDashboard($event)"
              [saveLayersView]="saveLayersView"
              [goToDefaultLayers]="goToDefaultLayers"
              (toggleStream)="toggleStream.emit($event)"
              [hasToBlockMenu]="hasToBlockMenu"
              [isGpsDevice]="true"
              [isListOnly]="isListOnly"
            >
            </app-layers-control-items>
            <ng-template #noResults>
              <div class="layer-list-no-results">
                {{ 'atlas.layerControl.noDeviceMatched' | translate }}. <a (click)="clearSearch()">Clear</a>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="searchedAssets$ | async as searchedAssets">
      <div class="layer-list-header" (click)="toggleExpandedLayers()">
        <span
          >{{ 'atlas.layerControl.layers' | translate }}
          <span *ngIf="searchFilterQuery$ | async"
            >({{ searchedAssets.length }}/{{ (assets$ | async)?.length }})</span
          ></span
        >
        <button mat-icon-button>
          <mat-icon inline="true">
            {{ isExpandedLayers ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        </button>
      </div>
      <ng-container *ngIf="isExpandedLayers">
        <app-layers-control-items
          class="layers-container"
          *ngIf="searchedAssets.length; else noResults"
          [map]="map"
          [assets]="searchedAssets"
          [updatedAsset]="updatedAsset$ | async"
          (showDashboard)="emitShowDashboard($event)"
          (exportShape)="emitExportShape($event)"
          (exportToGeojson)="emitExportToGeojson($event)"
          (exportToGeotiff)="emitExportToGeotiff($event)"
          (compareLayer)="emitCompareLayer($event)"
          (refreshView)="emitRefreshView()"
          (showOnlyThisLayer)="emitShowOnlyThisLayer($event)"
          (selectMarkers)="emitSelectMarkers($event)"
          (openSearchResults)="emitOpenSearchResults($event)"
          [saveLayersView]="saveLayersView"
          [goToDefaultLayers]="goToDefaultLayers"
          [totalSelectedLayers]="totalSelectedLayers"
          [hasToBlockMenu]="hasToBlockMenu"
          [isListOnly]="isListOnly"
        >
        </app-layers-control-items>
        <ng-template #noResults>
          <div class="layer-list-no-results">
            {{ 'atlas.layerControl.noLayerMatched' | translate }}. <a (click)="clearSearch()">Clear</a>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AuthFacade} from '@app/auth/state/auth.facade';
import {HelpService} from '@app/core/services/help.service';

@Component({
  selector: 'app-plan-unpaid',
  templateUrl: './plan-unpaid.component.html',
  styleUrls: ['./plan-unpaid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanUnpaidComponent {
  constructor(private authFacade: AuthFacade, private helpService: HelpService) {}

  public goToContactUs() {
    this.helpService.goToContactUs();
  }

  public signOut(): void {
    this.authFacade.authLogout();
  }
}

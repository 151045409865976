import {NotificationIcon, NotificationLevel, NotificationModel, NotificationState} from './notifications.model';

const getDefaults = (): NotificationModel =>
  ({
    action: null,
    actionUrl: null,
    createdAt: new Date().getTime(),
    data: null,
    deviceId: 'device-id',
    icon: NotificationIcon.info,
    isRead: false,
    level: NotificationLevel.info,
    message: 'message',
    priority: 'priority',
    progress: 0,
    source: 'source',
    sourceType: 'source-type',
    state: NotificationState.START,
    title: 'title',
    updatedAt: new Date().getTime(),
    userId: 'userId',
    isInProgress: false,
    sourceCreatedAt: 'sourceCreatedAt'
  } as NotificationModel);

const getNotificationMock = (p?: Partial<NotificationModel>): NotificationModel => {
  return {
    ...getDefaults(),
    ...p
  } as NotificationModel;
};

export {getNotificationMock};

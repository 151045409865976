import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getNotificationMock} from '@app/core/models/api/notifications.mock';
import {actionNewNotification} from '@app/core/notifications/notifications.actions';
import {NotificationModel} from '@app/core/models/api/notifications.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationStoreFacadeService {
  constructor(private store: Store) {}

  displayNewNotificationOnClientSide(payload: Partial<NotificationModel>) {
    this.store.dispatch(
      actionNewNotification({
        payload: getNotificationMock({
          ...payload
        })
      })
    );
  }
}

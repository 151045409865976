import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {PackageSelectorStoreFacadeService} from '@app/plans/services/package-selector-store-facade.service';
import {map, Observable, of, switchMap, take} from 'rxjs';
import {PackageSelectorPage} from '../pages/package-selector/package-selector.page';

@Injectable()
export class PreventLosePlanChangesGuard {
  constructor(private packageSelectorStoreFacadeService: PackageSelectorStoreFacadeService) {}

  public canDeactivate(
    component: PackageSelectorPage,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!component || !component.stepper || !component.stepper.steps) {
      return of(true);
    }

    const DONE_STEP_INDEX = 5;
    return this.packageSelectorStoreFacadeService.selectIsSamePackage$.pipe(
      take(1),
      switchMap(isSamePackage => {
        if (!isSamePackage && component.stepper.selectedIndex !== DONE_STEP_INDEX) {
          return component.openPreventLoseChangesDialog().pipe(map(dialogAction => !!dialogAction));
        }
        return of(true);
      })
    );
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {getCognitoIdentityValue} from '@app/core/services/api/cognito-identity-value';

@Pipe({
  name: 'injectTokenId',
  standalone: true
})
export class InjectTokenIdPipe implements PipeTransform {
  public transform(url: string): string {
    return url + '?token=' + getCognitoIdentityValue('idToken');
  }
}

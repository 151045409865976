import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SlideshowItem} from '@app/core/components/public-home/home.config';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, Subject, timer} from 'rxjs';

const INTERVAL_TIME_TO_CHANGE_IMAGE = 10000;

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideshowComponent implements OnInit {
  @Input() public slideshowItems: SlideshowItem[];
  @Input() public automaticTransition: boolean = true;

  public slideToDisplay$: BehaviorSubject<SlideshowItem> = new BehaviorSubject<SlideshowItem>(null);
  private slideIndex: number = 0;
  private intervalTimeToChangeImage: number = INTERVAL_TIME_TO_CHANGE_IMAGE;

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.automaticTransition) {
      this.setAutomaticTransition();
      return;
    }

    this.slideIndex = 0;
    this.slideToDisplay$.next(this.slideshowItems[this.slideIndex]);
    this.cd.detectChanges();
  }

  public switchIndex(slideIndex: number): void {
    if (slideIndex) {
      this.slideToDisplay$.next(this.slideshowItems[slideIndex]);
      this.cd.detectChanges();
    }
  }

  private setAutomaticTransition(): void {
    timer(0, this.intervalTimeToChangeImage).subscribe(() => {
      const isGreaterIndex: boolean = this.slideIndex >= this.slideshowItems.length - 1;
      this.slideIndex = isGreaterIndex ? 0 : this.slideIndex + 1;
      this.slideToDisplay$.next(this.slideshowItems[this.slideIndex]);
    });
  }
}

<h3 class="subtitle">{{ 'profile.export.title' | translate }}</h3>

<mat-card>
  <mat-card-content>
    <form [formGroup]="form" class="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'profile.export.dataRange' | translate }}</mat-label>
        <mat-select placeholder="{{ 'profile.export.dataRange' | translate }}" formControlName="dateRange">
          <mat-option *ngFor="let dataRange of dataRangeOptions" [value]="dataRange.id">
            {{ dataRange.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'profile.export.type' | translate }}</mat-label>
        <mat-select placeholder="{{ 'profile.export.type' | translate }}" formControlName="type">
          <mat-option *ngFor="let type of typeOptions" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill">
        <mat-label>Team</mat-label>
        <mat-select placeholder="Team" formControlName="team">
          <mat-option value="allTeams">All teams</mat-option>
          <mat-option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</mat-option>
        </mat-select>
      </mat-form-field> -->
    </form>
    <button mat-raised-button color="primary" (click)="emitRequestExport()">
      {{ 'profile.export.request' | translate }}
    </button>
  </mat-card-content>
</mat-card>

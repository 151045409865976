import {Injectable} from '@angular/core';
import {Storage} from '@aws-amplify/storage';
import * as Sentry from '@sentry/angular';
import {BehaviorSubject} from 'rxjs';
import {Store} from '@ngrx/store';
import {actionUpdateNotification} from '@app/core/notifications/notifications.actions';
import {getNotificationMock} from '@app/core/models/api/notifications.mock';
import {UploadFileMetadata} from '@app/library/models/upload-file-metadata.model';
import {NotificationLevel, NotificationState} from '@app/core/models/api/notifications.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ImageResizeService} from '@app/shared/services/upload/image-resize/image-resize.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {differenceInSeconds} from 'date-fns';
import {v4 as uuidv4} from 'uuid';
import {Queue} from '@app/shared/services/upload/upload-queue';
import {backOff} from 'exponential-backoff';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export abstract class UploadService {
  protected abstract readonly bucket;
  public ACCEPTED_FILE_TYPES: string[] = [
    'image/png',
    'image/jpeg',
    'image/tiff',
    'video/quicktime',
    'video/mpeg',
    'video/mp4',
    'video/avi',
    'application/zip',
    'application/x-zip-compressed',
    'text/plain'
  ];
  public filesToUpload: Queue<File> = new Queue<File>();
  public currentFiles: File[] = [];
  // eslint-disable-next-line no-magic-numbers
  public concurrentUploads = 5;
  public fileSizeInBytesTotal = 0;
  public fileSizeInBytesUploaded = 0;
  public totalFileToUploadCount = 0;
  public progressMap: Map<File, number> = new Map<File, number>();
  public metadataMap: Map<File, UploadFileMetadata> = new Map<File, UploadFileMetadata>();
  private isUploading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isUploading$ = this.isUploading.asObservable();
  public atlasTimeout: number = 0;
  public isProcessingFiles: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public totalProgress: BehaviorSubject<number> = new BehaviorSubject(0);

  private startTime: number;
  private foldersCount: number;
  private lastProgressUpdateTimestamp: number = 0;

  protected constructor(
    private store: Store,
    private imageResizeService: ImageResizeService,
    private unleashAnalytics: UnleashAnalyticsService
  ) {}

  public lastFilePath: string = undefined;
  public abstract getKey(filename: string): Promise<string>;

  public setLastFilePath(lastFilePath: string) {
    this.lastFilePath = lastFilePath;
  }

  private async uploadFile(file: File, metadata: UploadFileMetadata): Promise<void> {
    this.currentFiles.push(file);
    await this.uploadFileWithRetry(file, metadata);
    this.fileSizeInBytesUploaded += file.size;
    this.currentFiles = this.currentFiles.filter(f => f !== file);
    await this.uploadNextFile();
  }

  private async uploadFileWithRetry(file: File, metadata: UploadFileMetadata) {
    const ONE_HUNDRED_PERCENT = 100;
    const MAX_RETRY_ATTEMPTS = 50;
    if (!file) {
      console.warn('something went wrong - the file ought not to be null!!');
      return Promise.resolve();
    }

    try {
      await backOff(
        async () => {
          const putResponse = Storage.put(this.lastFilePath ? this.lastFilePath : await this.getKey(file.name), file, {
            bucket: this.bucket,
            metadata: metadata as unknown as {[key: string]: string},
            progressCallback: (progress: any) => {
              if (progress.upload || progress.loaded === progress.total) {
                this.updateProgress(file, progress, ONE_HUNDRED_PERCENT);
              }

              if (!this.shouldUpdateProgress()) {
                return;
              }

              this.updateProgress(file, progress, ONE_HUNDRED_PERCENT);
            },
            useAccelerateEndpoint: true
          });
          this.setLastFilePath(undefined);
          return putResponse;
        },
        {
          retry: (error, attemptNumber) => {
            console.warn(`RETRY ${MAX_RETRY_ATTEMPTS - attemptNumber}  failed to upload ${file?.name}`);
            if (attemptNumber === MAX_RETRY_ATTEMPTS) {
              this.unleashAnalytics.logEvent(EVENTS.UPLOAD_ERROR, error);
              console.error(`Tried ${MAX_RETRY_ATTEMPTS} times but failed to upload ${file.name} due to ${error}`);
              this.updateProgress(file, {loaded: 1, total: 1}, ONE_HUNDRED_PERCENT);
              Sentry.captureException(error);
              return false;
            } else {
              return true;
            }
          },

          numOfAttempts: MAX_RETRY_ATTEMPTS,
          jitter: 'full'
        }
      );
    } catch (e) {
      console.error('some error', e);
    }
  }

  private updateProgress(file: File, progress: any, ONE_HUNDRED_PERCENT: number) {
    this.progressMap.set(file, Math.round((progress.loaded / progress.total) * ONE_HUNDRED_PERCENT));
    let totalProgress = 0;
    this.progressMap.forEach(progressValue => {
      totalProgress += progressValue || 0;
    });
    totalProgress = totalProgress / this.progressMap.size;
    this.totalProgress.next(totalProgress);

    const ONE_SECOND_IN_MILLISECONDS = 1000;
    const averageSpeed =
      this.fileSizeInBytesUploaded / ((new Date().getTime() - this.startTime) / ONE_SECOND_IN_MILLISECONDS);
    const eta = this.readableTimeLeft(this.fileSizeInBytesUploaded, this.fileSizeInBytesTotal, averageSpeed);

    if (totalProgress === ONE_HUNDRED_PERCENT) {
      this.finalizeFileUpload();
    } else {
      this.updateNotification(eta, totalProgress);
    }
  }

  private finalizeFileUpload() {
    if (this.totalFileToUploadCount === 0) {
      return;
    }

    const sessionId = uuidv4();

    const data = {
      sessionId,
      fileCount: this.totalFileToUploadCount,
      durationInSec: differenceInSeconds(new Date(), this.startTime),
      totalUploadSizeInBytes: this.fileSizeInBytesTotal
    };

    if (this.foldersCount > 0) {
      data['foldersCount'] = this.foldersCount;
    }

    this.unleashAnalytics.logEvent(
      this.foldersCount > 0 ? EVENTS.UPLOAD_FOLDER_FINISH : EVENTS.UPLOAD_FILES_FINISH,
      data
    );

    this.displayUploadFinishedNotification();
    this.clearUploadFiles();
  }

  private updateNotification(eta: string, totalProgress: number) {
    this.store.dispatch(
      actionUpdateNotification({
        payload: getNotificationMock({
          createdAt: this.startTime,
          title: 'Uploading',
          message:
            (this.totalFileToUploadCount > 1
              ? `${this.totalFileToUploadCount} files to upload`
              : `${this.totalFileToUploadCount} file to upload`) + ` (ETA: ${eta})`,
          isInProgress: true,
          progress: Math.round(totalProgress * 100) / 100
        })
      })
    );
  }

  private displayUploadFinishedNotification() {
    this.store.dispatch(
      actionUpdateNotification({
        payload: getNotificationMock({
          title: 'Upload complete',
          message:
            this.totalFileToUploadCount > 1
              ? `${this.totalFileToUploadCount} files were successfully uploaded`
              : `${this.totalFileToUploadCount} file was successfully uploaded`,
          isInProgress: false,
          level: NotificationLevel.success,
          state: NotificationState.FINISH,
          progress: 100
        })
      })
    );
  }

  private async uploadNextFile(): Promise<void> {
    while (this.filesToUpload.length() > 0 && this.currentFiles.length < this.concurrentUploads) {
      const file = this.filesToUpload.dequeue();
      const metadata = this.metadataMap.get(file);
      await this.uploadFile(file, metadata);
    }
  }

  public async addToQueue(files: File[], metadata?: UploadFileMetadata): Promise<void> {
    this.isUploading.next(true);
    if (!this.startTime) {
      this.startTime = new Date().getTime();
    }

    let filesToProcess = files;

    this.totalFileToUploadCount = this.totalFileToUploadCount + files.length;

    if (metadata?.isFastUpload) {
      const response = await this.imageResizeService.resizeImages(files, this.ACCEPTED_FILE_TYPES);
      filesToProcess = response.map(fileResponse => fileResponse.file);
    }

    for (const file of filesToProcess) {
      const extraMetadata = {
        name: encodeURIComponent(file.name)
      };

      const metadataToUpload = this.filterObjectToUploadMetadata({
        ...metadata,
        ...extraMetadata
      });

      this.filesToUpload.enqueue(file);
      this.fileSizeInBytesTotal += file.size;

      this.progressMap.set(file, 0);
      this.metadataMap.set(file, metadataToUpload as unknown as UploadFileMetadata);
    }

    await this.uploadNextFile();
  }

  public clearUploadFiles(): void {
    this.currentFiles = [];
    this.metadataMap.clear();
    this.progressMap.clear();
    this.fileSizeInBytesTotal = 0;
    this.fileSizeInBytesUploaded = 0;
    this.isUploading.next(false);
    this.startTime = null;
    this.foldersCount = 0;
    this.totalFileToUploadCount = 0;
    this.updateProcessingFiles();
    this.atlasTimeout = 0;
  }

  public updateProcessingFiles() {
    if (this.atlasTimeout) {
      setTimeout(() => {
        this.isProcessingFiles.next(false);
      }, this.atlasTimeout);
    }
  }

  public setupFoldersCount(folderCount: number): void {
    this.foldersCount += folderCount;
  }

  private readableTimeLeft(totalUploaded, totalSize, speed) {
    let timeleft = (totalSize - totalUploaded) / speed;
    if (!isFinite(timeleft)) {
      return 'N/A';
    }
    const units = ['seconds', 'minutes', 'hours', 'days'];
    let i = 0;
    const SIXTY_SECONDS = 60;
    const HOURS_IN_DAY = 24;
    const THREE = 3;
    const NUM_TRIES = 2;

    while (timeleft >= SIXTY_SECONDS && i < THREE) {
      timeleft /= SIXTY_SECONDS;
      ++i;
    }
    if (i === THREE && timeleft > HOURS_IN_DAY) {
      timeleft /= HOURS_IN_DAY;
    }
    let unit = units[i];
    if (timeleft >= 1 && timeleft < NUM_TRIES) {
      unit = unit.substr(0, unit.length - 1);
    }
    const formattedSeconds = [Math.floor(timeleft), unit].join(' ');
    if (Math.floor(timeleft) === 0) {
      /* less than a second left */
      return 'just finishing';
    }
    return formattedSeconds;
  }

  private filterObjectToUploadMetadata(objectMetadata: Record<any, any>): {[key: string]: string} {
    return Object.entries(objectMetadata)
      .filter(([, value]) => typeof value === 'string' && value.trim().length > 0)
      .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
  }

  /* Optimize CPU and update progress every n seconds*/
  private shouldUpdateProgress(THROTTLE_TIME = 5000) {
    if (Date.now() - this.lastProgressUpdateTimestamp > THROTTLE_TIME) {
      this.lastProgressUpdateTimestamp = Date.now();
      return true;
    } else {
      return false;
    }
  }
}

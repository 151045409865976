import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CompanyModel} from '@app/core/models/api/company-model';
import {UserModel} from '@app/core/models/api/user-model';
import {UserService} from '@app/core/services/api/user.service';
import {environment} from 'environments/environment';
import {BehaviorSubject} from 'rxjs';
import {UPLOAD_LOGO_SOURCE} from '@app/profile/models/upload-logo-source.model';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {DateFormat, DateFormats, dateFormatInfo, timezones} from '@app/profile/models/date-format.model';
import {NgForOf, NgIf} from '@angular/common';
import {AclDisabledFormControlDirective} from '@app/core/directives/acl-disable-form-control.directive';
import {MatSelectModule} from '@angular/material/select';
import {StrftimeToDateFormatPipe} from '@app/profile/pipes/strftime-to-date-format.pipe';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AclDirective} from '@app/core/directives/acl.directive';
import {ThumblerPipe} from '@app/shared/pipes/thumbler.pipe';
import {LogoUploadComponent} from '../logo-upload/logo-upload.component';
import {FlexLayoutModule} from '@angular/flex-layout';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    TranslateModule,
    AclDisabledFormControlDirective,
    MatSelectModule,
    StrftimeToDateFormatPipe,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    AclDirective,
    ThumblerPipe,
    LogoUploadComponent,
    FlexLayoutModule
  ]
})
export class OrganisationComponent implements OnInit {
  @Input() public dateFormatOptions: DateFormat[] = [];
  @Input('company')
  public set fillCompany(company: CompanyModel) {
    if (!!company && Object.keys(company).length > 0) {
      this.company = company;
    } else {
      this.company = this.cleanCompanyValue();
      return;
    }

    this.form.patchValue({
      id: company.id,
      name: company.name,
      slug: company.slug,
      website: company.website,
      industry: company.industry,
      logo: company.logo,
      shared: company.isPublicWatchPageEnabled,
      owner: company.owner,
      dateFormat: company.dateFormat,
      timezone: company.timezone
    });
  }

  @Input('user')
  public set fillUser(user: UserModel) {
    if (user && Object.keys(user).length > 0) {
      this.user = user;
    }
  }

  @Input()
  public isOnboarding: boolean = false;

  @Output()
  public handleUntypedFormGroup: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  @Output()
  // eslint-disable-next-line rxjs/finnish
  public organisationCreating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output()
  public organisationCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public aclPermissions = AclPermissions;
  public company: CompanyModel;
  public user: UserModel;
  public urlRegex: RegExp = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/;
  public oneWordRegex: RegExp = /\w/;
  public form: UntypedFormGroup;
  public industryNames: string[] = [
    'Cities',
    'Construction',
    'Developer',
    'Emergency Services',
    'Energy & Renewables',
    'Infrastructure - Railway',
    'Infrastructure - Road',
    'Mining',
    'Pipeline & Resources',
    'Retail & Spaces',
    'Safety & Security',
    'Sports & Entertainment',
    'Transportation',
    'Other'
  ];
  public updateErrorMessage: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public environment: any = environment;
  // eslint-disable-next-line
  public THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;
  public uploadLogoSource: typeof UPLOAD_LOGO_SOURCE = UPLOAD_LOGO_SOURCE;
  public dateFormatInfo = dateFormatInfo;
  public timezones = timezones;
  public currentDate = new Date();

  constructor(private userService: UserService, private fb: FormBuilder, private translateService: TranslateService) {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      slug: ['', [Validators.required, Validators.pattern(this.oneWordRegex)]],
      website: ['', [Validators.pattern(this.urlRegex)]],
      industry: ['', Validators.required],
      logo: [''],
      shared: [''],
      owner: [''],
      dateFormat: [DateFormats.AustralianDateTime],
      timezone: ['Australia/Sydney']
    });
  }

  public ngOnInit(): void {
    this.sendUntypedFormGroup();
  }

  public update(UntypedFormControlName: string): void {
    this.updateErrorMessage = null;
    const companyId = this.form.controls.id.value ? this.form.controls.id.value : undefined;
    let companyModel: Partial<CompanyModel> = {};
    if (UntypedFormControlName === 'name') {
      const slug = this.slugGenerator(this.form.controls.name.value);
      this.form.controls.slug.setValue(slug);
      companyModel['slug'] = slug;
    }
    if (this.form.valid) {
      this.organisationCreating$.next(true);
      // update
      if (companyId) {
        companyModel[UntypedFormControlName] = this.form.controls[UntypedFormControlName].value;
        this.userService.updateCompany(this.form.value.id, companyModel);
        this.organisationCreating$.next(false);
      } else {
        // create
        companyModel = {
          owner: this.user.id || '',
          name: this.form.controls.name.value || '',
          logo: this.form.controls.logo.value,
          industry: this.form.controls.industry.value || '',
          slug: this.form.controls.slug.value || '',
          isPublicWatchPageEnabled: !(this.form.controls.shared.value === false),
          dateFormat: this.form.controls.dateFormat.value || DateFormats.AustralianDateTime,
          timezone: this.form.controls.timezone.value || 'Australia/Sydney'
        };

        if (this.form.controls.website.value) {
          companyModel['website'] = this.form.controls.website.value;
        }

        this.userService.createCompany(companyModel);
        this.userService.myCompany$.subscribe(
          (company: CompanyModel) => {
            if (!company) {
              console.error('Empty create company response');
              return;
            }
            this.organisationCreating$.next(false);
            this.organisationCreated.emit(true);
          },
          (e: HttpErrorResponse) => this.handleCreateUpdateErrors(e, companyModel)
        );
      }
    } else {
      this.form.controls.name.markAsTouched();
      this.form.controls.slug.markAsTouched();
      this.form.controls.website.markAsTouched();
      this.form.controls.industry.markAsTouched();
      this.form.controls.logo.markAsTouched();
    }
  }

  public updateCompanyLogo(s3Url: string): void {
    this.form.patchValue({logo: s3Url});
    this.update('logo');
  }

  public sendUntypedFormGroup(): void {
    this.handleUntypedFormGroup.next(this.form);
  }

  private slugGenerator(companyName: string): string {
    return (
      companyName
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '') // Remove all spaces
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        // eslint-disable-next-line no-useless-escape
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '')
    ); // Trim - from end of text
  }

  private handleCreateUpdateErrors(err: HttpErrorResponse, companyModel: Partial<CompanyModel>): void {
    const returnedError = err.message ? err.message : err.toString();
    this.organisationCreating$.next(false);
    if (returnedError.includes('slug already exists')) {
      if (this.isOnboarding) {
        this.translateService
          .get('profile.organizationUpdateErrorCompany', {value: companyModel.name})
          .subscribe((res: string) => (this.updateErrorMessage = res));
        this.form.get('name').setErrors({exists: true});
      } else {
        this.translateService
          .get('profile.organizationUpdateErrorDashboard', {value: companyModel.slug})
          .subscribe((res: string) => (this.updateErrorMessage = res));
        this.form.get('slug').setErrors({exists: true});
        this.form.get('slug').markAsTouched();
      }
    }
  }

  private cleanCompanyValue(): CompanyModel {
    return {
      id: null,
      owner: '',
      name: '',
      logo: null,
      website: '',
      industry: '',
      slug: '',
      isPublicWatchPageEnabled: false,
      createdAt: null,
      updatedAt: null,
      dateFormat: DateFormats.AustralianDateTime,
      timezone: 'Australia/Sydney'
    };
  }
}

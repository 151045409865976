import {Directive, HostListener} from '@angular/core';
import {PermissionService} from '@app/core/services/permission.service';
import {MissionSettingsService} from '../services/mission-settings.service';

@Directive({
  selector: '[ulClearRoute]'
})
export class ClearRouteDirective {
  constructor(private permissions: PermissionService, private missionSettingsService: MissionSettingsService) {}
  @HostListener('click', ['$event'])
  public clearRoute() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.missionSettingsService.clearRoute();
    this.missionSettingsService.setEditingMission({mission: null, selectedWaypointIndex: -1});
  }
}

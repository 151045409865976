import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PackageNames, PlanModel} from '@app/core/models/api/plan.model';

@Component({
  selector: 'unleash-plan-features',
  templateUrl: './plan-features.component.html',
  styleUrls: ['./plan-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewPlanFeaturesComponent {
  @Input() plan: PlanModel;
  @Input() isInactive: boolean;
  public packagesName = PackageNames;
}

import {ChangeDetectionStrategy, Component, NgZone, OnInit} from '@angular/core';
import {CalibrationService, CalibrationSidebarMode} from '../../services/calibration.service';
import {DrawStatus} from '@app/shared/image-annotation-shared/models/image-annotation.model';
import {CalibrationLayout} from '../../calibration.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {ColorPickerComponent} from '@app/shared/color-picker/color-picker.component';
import {BehaviorSubject, Subject, Subscription, combineLatest, filter, map, shareReplay, take} from 'rxjs';
import {CommonModule} from '@angular/common';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AnalyticsConfig} from '@app/core/models/api/user-device.model';
import {DeviceService} from '@app/core/services/api/device.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {SharedPipesModule} from '@app/shared/pipes/shared-pipes.module';
import {ImageAnnotationSharedModule} from '@app/shared/image-annotation-shared/image-annotation-shared.module';
import {ManagerZonesDialogService} from '@app/shared/manage-zones-dialog/services/manager-zones-dialog.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {MatDialog} from '@angular/material/dialog';
import {AddLocationDialogComponent} from '../add-location-dialog/add-location-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {CanvasService} from '@app/shared/image-annotation-shared/services/canvas.service';
import {Marker} from '@app/shared/image-annotation-shared/models/marker';
import {MatTabsModule} from '@angular/material/tabs';
import {MapLayersListComponent} from '@app/shared/map-layers-list/map-layers-list.component';
import {MtxTooltipModule} from '@ng-matero/extensions/tooltip';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'unleash-calibration-sidebar-add',
  standalone: true,
  templateUrl: './calibration-sidebar-add.component.html',
  styleUrls: ['./calibration-sidebar-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    CommonModule,
    ColorPickerComponent,
    LaddaModule,
    SharedPipesModule,
    ImageAnnotationSharedModule,
    AddLocationDialogComponent,
    MatTabsModule,
    MapLayersListComponent,
    MtxTooltipModule,
    TranslateModule
  ],
  providers: [CanvasService]
})
export class CalibrationSidebarAddComponent implements OnInit {
  public selectedColor$ = this.calibrationService.selectedColor$;
  public pointsFormArray: FormArray = this.fb.array([]);
  public form: FormGroup = this.fb.group(
    {
      points: this.pointsFormArray
    },
    {updateOn: 'blur'}
  );

  public hightLightMarkerByIndex$ = this.calibrationService.hightLightMarkerByIndex$;
  public imageSnapshot$ = this.managerZonesDialogService.imageSnapshot$.pipe(
    filter(data => !!data),
    shareReplay(1)
  );
  public thumblerConfig = THUMBLER_AVAILABLE_CONFIGS.libraryLarge;
  public pointsLatLng$ = this.calibrationService.pointsLatLng$.pipe(shareReplay(1));
  public currentIndex$ = this.calibrationService.pointsLatLng$.pipe(
    map(points => {
      let lastValidIndex = -1;

      points.forEach((point, index) => {
        if (point[0] !== null && point[1] !== null) {
          lastValidIndex = index;
        }
      });

      return lastValidIndex;
    })
  );
  public points$ = this.calibrationService.pointsLatLng$.pipe(
    map(points => points.map(point => ({lat: point[0], lng: point[1]}))),
    shareReplay(1)
  );
  public canLoadLayersAddMode: boolean = false;
  public canLoadLayersEditMode: boolean = false;

  private pointGeneratedSub: Subscription;
  private pointsValueChangesSub: Subscription;
  private isReadyToAddPointSub: Subscription;
  private calibrationSidebarModeSub: Subscription;

  private isSaving = new Subject<boolean>();
  private isLoadingShapes = new BehaviorSubject<boolean>(true);

  public isSaving$ = this.isSaving.asObservable().pipe(shareReplay());
  public isLoadingShapes$ = this.isLoadingShapes.asObservable().pipe(shareReplay());
  public calibrationSidebarMode = CalibrationSidebarMode;
  public currentCalibrationSidebarMode$ = this.calibrationService.calibrationSidebarMode$;
  public editPointIndex$ = this.calibrationService.editPointIndex$;
  public calibrationMap$ = this.calibrationService.calibrationMap$;

  constructor(
    private fb: FormBuilder,
    private calibrationService: CalibrationService,
    private deviceService: DeviceService,
    private snackbar: MatSnackBar,
    private managerZonesDialogService: ManagerZonesDialogService,
    private dialog: MatDialog,
    private canvasService: CanvasService,
    private zone: NgZone
  ) {}

  public ngOnInit(): void {
    this.calibrationService.emitUpdateDrawStatus(DrawStatus.stop);

    this.pointGeneratedSub = this.calibrationService.pointGenerated$.subscribe(() => {
      this.addPoint();
    });

    this.calibrationSidebarModeSub = this.currentCalibrationSidebarMode$
      .pipe(
        filter(currentCalibrationSidebarMode => currentCalibrationSidebarMode === CalibrationSidebarMode.POINT_LIST)
      )
      .subscribe(() => {
        this.setCanLoadLayersAddMode(false);
        this.setCanLoadLayersEditMode(false);
      });
  }

  public cancelEditing(): void {
    this.calibrationService.sidebarCanvas?.clearCanvas();
    this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.POINT_LIST);
    this.calibrationService.setEditCanvasCoordinates(null);
    this.calibrationService.setEditMapCoordinates(null);
    this.calibrationService.setEditPointIndex(-1);
  }

  public enableEditMode(index: number): void {
    this.calibrationService.enableEditMode(index);
  }

  public setCanLoadLayersAddMode(canLoadLayersAddMode: boolean) {
    this.canLoadLayersAddMode = canLoadLayersAddMode;
  }

  public setCanLoadLayersEditMode(canLoadLayersEditMode: boolean) {
    this.canLoadLayersEditMode = canLoadLayersEditMode;
  }

  public openEditLocationDialog(): void {
    this.zone.run(() => {
      combineLatest([this.calibrationService.editPointIndex$, this.calibrationService.pointsLatLng$])
        .pipe(take(1))
        .subscribe(([index, points]) => {
          const lat = this.calibrationService.editMapCoordinates.lat;
          const lng = this.calibrationService.editMapCoordinates.lng;
          const dialog = this.dialog.open(AddLocationDialogComponent, {
            ...STANDARD_DIALOG_CONFIG,
            width: '60vw',
            data: {
              lat: lat || 0,
              lng: lng || 0,
              index: index + 1
            }
          });

          dialog
            .afterClosed()
            .pipe(
              filter(latLng => !!latLng),
              take(1)
            )
            .subscribe(({lat, lng}) => {
              points[index] = [lat, lng];
              this.calibrationService.setPointLatLng(points);
              this.calibrationService.setEditMapCoordinates({lat, lng});
            });
        });
    });
  }

  public openLocationDialog(): void {
    this.zone.run(() => {
      this.calibrationService.pointsLatLng$.pipe(take(1)).subscribe(points => {
        const index = points.length + 1;
        const dialog = this.dialog.open(AddLocationDialogComponent, {
          ...STANDARD_DIALOG_CONFIG,
          width: '60vw',
          data: {
            index
          }
        });

        dialog
          .afterClosed()
          .pipe(
            filter(latLng => !!latLng),
            take(1)
          )
          .subscribe(({lat, lng}) => {
            this.snackbar.open('Calibration point added', null, {panelClass: 'center', duration: 3000});
            this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.POINT_LIST);
            this.calibrationService.setPointLatLng([...points, [lat, lng]]);
            this.canvasService.clearCanvas();
          });
      });
    });
  }

  public setCalibrationSidebarMode(mode: CalibrationSidebarMode) {
    this.calibrationService.setCalibrationSidebarMode(mode);
  }

  public addPointBack(index?: number) {
    this.currentCalibrationSidebarMode$.pipe(take(1)).subscribe(sidebarMode => {
      switch (sidebarMode) {
        case CalibrationSidebarMode.ADD_MARKER_IN_MAP:
          this.calibrationService.sidebarCanvas.clearCanvas();
          this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.ADD_POINT);
          this.pointsLatLng$.pipe(take(1)).subscribe(points => {
            this.deletePointFromCanvas(points.length);
          });
          break;
        case CalibrationSidebarMode.ADD_POINT:
          this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.POINT_LIST);
          break;
        case CalibrationSidebarMode.EDIT_CANVAS_POINT:
          this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.POINT_LIST);
          this.calibrationService.setInitialEditCanvasCoordinates(index);
          this.calibrationService.setEditPointIndex(-1);
          break;
        case CalibrationSidebarMode.EDIT_MAP_POINT:
          this.calibrationService.draggeableMarkerCanvas.clearCanvas();
          this.calibrationService.sidebarCanvas.clearCanvas();
          this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.EDIT_CANVAS_POINT);
          this.calibrationService.setInitialEditMapCoordinates(index);
          break;
      }
    });
  }

  public loadEditMarker(): void {
    combineLatest([this.calibrationService.editPointIndex$, this.calibrationService.selectedColor$])
      .pipe(take(1))
      .subscribe(([index, color]) => {
        if (!this.calibrationService.editCanvasCoordinates) {
          return;
        }
        const x = this.calibrationService.editCanvasCoordinates.x;
        const y = this.calibrationService.editCanvasCoordinates.y;
        const markerTool = this.canvasService.selectMarker({pathSizeX: 55, pathSizeY: 70, fontSize: 36}) as Marker;
        const viewBoxX = x;
        const viewBoxY = y;

        const textIndex = 1;
        const textInGroup = (markerTool.ref as any).get(textIndex);
        textInGroup.text((index + 1).toString());

        markerTool.updateColor(color);
        markerTool.moveMarker(viewBoxX, viewBoxY);
        const bbox = markerTool.ref.bbox();
        markerTool.ref.dmove(-bbox.width / 2, -bbox.height);
        this.calibrationService.setSidebarCanvas(this.canvasService);
      });
  }

  public loadMarker(): void {
    combineLatest([this.calibrationService.currentMarkerCoordinates$, this.calibrationService.selectedColor$])
      .pipe(take(1))
      .subscribe(([coordinates, color]) => {
        if (!coordinates) {
          return;
        }
        const markerTool = this.canvasService.selectMarker({pathSizeX: 55, pathSizeY: 70, fontSize: 36}) as Marker;
        const viewBoxX = coordinates.x;
        const viewBoxY = coordinates.y;

        const textIndex = 1;
        const textInGroup = (markerTool.ref as any).get(textIndex);
        textInGroup.text((this.calibrationService.markerCounter - 1).toString());

        markerTool.updateColor(color);
        markerTool.moveMarker(viewBoxX, viewBoxY);
        const bbox = markerTool.ref.bbox();
        markerTool.ref.dmove(-bbox.width / 2, -bbox.height);
        this.calibrationService.setSidebarCanvas(this.canvasService);
      });
  }

  public ngOnDestroy(): void {
    if (this.pointGeneratedSub) {
      this.pointGeneratedSub.unsubscribe();
      this.pointGeneratedSub = null;
    }

    if (this.pointsValueChangesSub) {
      this.pointsValueChangesSub.unsubscribe();
      this.pointsValueChangesSub = null;
    }

    if (this.isReadyToAddPointSub) {
      this.isReadyToAddPointSub.unsubscribe();
      this.isReadyToAddPointSub = null;
    }

    if (this.calibrationSidebarModeSub) {
      this.calibrationSidebarModeSub.unsubscribe();
      this.calibrationSidebarModeSub = null;
    }
  }

  public addPoint(): void {
    const pointFormGroup = this.fb.group({
      latitude: [null, [Validators.required, Validators.min(-90), Validators.max(90)]],
      longitude: [null, [Validators.required, Validators.min(-180), Validators.max(180)]]
    });
    this.pointsFormArray.push(pointFormGroup);
  }

  public emitBackToCalibrationMainView(): void {
    this.calibrationService.totalSavedPoints$.pipe(take(1)).subscribe(totalPoints => {
      totalPoints === 0
        ? this.calibrationService.setCurrentLayout(CalibrationLayout.empty)
        : this.calibrationService.setCurrentLayout(CalibrationLayout.list);
    });
    this.calibrationService.setPointLatLng([]);
    this.calibrationService.loadPoints();
  }

  public emitColor(color: LabelColorName): void {
    this.calibrationService.setSelectedColor(color);
  }

  public deletePointByIndex(index: number): void {
    this.calibrationService.confirmDeleteMarkerByIndex(index);
  }

  public deletePointFromCanvas(index: number): void {
    this.calibrationService.emitDeletePointByIndex(index);
    this.calibrationService.removePointOnScreen(index);
  }

  public finishSceneMapping(): void {
    this.calibrationService.pointsLatLng$.pipe(take(1)).subscribe(pointsLatLng => {
      this.calibrationService.setTotalSavedPoints(pointsLatLng.length);
      this.isSaving.next(true);
      const analyticsConfig = {
        [this.calibrationService.addonId]: {
          analytics: {
            ul_camera_calibration_analytics: {
              calibration_points: pointsLatLng.map((point, index) => {
                return {
                  lat: point[0],
                  lng: point[1],
                  x: this.calibrationService.pointsOnScreen[index][0] / this.calibrationService.imageWidth,
                  y: this.calibrationService.pointsOnScreen[index][1] / this.calibrationService.imageHeight
                };
              }),
              color: this.calibrationService.getSelectedColor()
            }
          }
        }
      } as AnalyticsConfig;

      this.deviceService.updateDevice(this.calibrationService.deviceId, {analyticsConfig}).subscribe(() => {
        this.isSaving.next(false);
        this.calibrationService.setCalibrationLabel(true);
        this.calibrationService.setCurrentLayout(CalibrationLayout.list);
        this.calibrationService.updateAnalyticsConfig(this.calibrationService.deviceId, analyticsConfig);
        this.snackbar.open('Scene mapping calibration added', null, {
          duration: 3000,
          panelClass: 'center'
        });
      });
    });
  }

  public isReadyToAddPoint(): void {
    this.calibrationService.setCalibrationSidebarMode(this.calibrationSidebarMode.ADD_POINT);
  }

  public continueEditing(): void {
    this.calibrationService.setCalibrationSidebarMode(this.calibrationSidebarMode.EDIT_MAP_POINT);
  }

  public finishEditing(editPointIndex: number): void {
    this.calibrationService.pointsLatLng$.pipe(take(1)).subscribe(pointsLatLng => {
      const mapPoints = pointsLatLng;
      mapPoints[editPointIndex] = [
        this.calibrationService.editMapCoordinates.lat,
        this.calibrationService.editMapCoordinates.lng
      ];
      this.calibrationService.setPointLatLng(mapPoints);
    });
    const canvasPoints = this.calibrationService.pointsOnScreen;
    canvasPoints[editPointIndex] = [
      this.calibrationService.editCanvasCoordinates.x,
      this.calibrationService.editCanvasCoordinates.y
    ];
    this.calibrationService.updatePointsOnScreen(canvasPoints as any);
    this.canvasService.clearCanvas();
    this.calibrationService.setCalibrationSidebarMode(this.calibrationSidebarMode.POINT_LIST);
    this.snackbar.open('Calibration point updated', null, {panelClass: 'center', duration: 3000});
  }

  public emitHightLightMarkerByIndex(index: number): void {
    this.calibrationService.emitHightLightMarkerByIndex(index);
  }
}

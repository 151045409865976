<form class="search__container" [formGroup]="form">
  <div class="search">
    <button class="search__button" mat-icon-button type="button">
      <mat-icon class="search__icon" [class.active]="form.controls.search.value" svgIcon="search-layer"></mat-icon>
    </button>
    <input
      class="search__input"
      type="text"
      matInput
      #layerSearch
      formControlName="search"
      [placeholder]="placeholder"
      autocomplete="off"
      data-test-id="search-layer-input"
    />
    <button
      *ngIf="form.controls.search.value"
      mat-icon-button
      class="search__button"
      type="button"
      (click)="closeAndCleanDisplayInput()"
      matTooltip="{{ 'atlas.search.clear' | translate }}"
    >
      <mat-icon class="search__custom-icon">close</mat-icon>
    </button>
  </div>
</form>

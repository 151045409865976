<section class="calibration-sidebar-list">
  <div class="calibration-sidebar-list__item" (click)="openAddCalibration()">
    <span class="calibration-sidebar-list__item-left">
      <span [class]="'calibration-sidebar-list__color' + ' ' + (selectedColor$ | async)"></span>
      <span class="calibration-sidebar-list__item-content">
        <ng-container *ngIf="calibrationZoneDimensions$ | async as calibrationZoneDimensions; else pointsTemplate">
          <span class="calibration-sidebar-list__title">{{ 'calibration.perspective' | translate }}</span>
          <span class="calibration-sidebar-list__description">{{ calibrationZoneDimensions }}</span>
        </ng-container>
        <ng-template #pointsTemplate>
          <span class="calibration-sidebar-list__title">{{ 'calibration.sceneMapping' | translate }}</span>
          <span class="calibration-sidebar-list__description">
            {{ points$ | async }} {{ 'calibration.points' | translate }}
          </span>
        </ng-template>
      </span>
    </span>
    <button (click)="$event.preventDefault(); $event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <div class="calibration-sidebar-list__footer">
    <button class="actions__button" mat-button color="primary" (click)="closeDialog()">
      {{ 'common.done' | translate }}
    </button>
    <ng-container *uaHasPermission="aclPermissions.LivestreamApiStreamPublish">
      <button *ngIf="!dialogRef" class="actions__button" mat-raised-button color="primary" (click)="analyze()">
        {{ 'live.manage-zones-dialog.details.action_analyze' | translate }}
      </button>
    </ng-container>
  </div>
</section>

<mat-menu #menu="matMenu">
  <button (click)="editSceneMapping()" mat-menu-item>
    <mat-icon>edit</mat-icon>
    <span>{{ 'calibration.edit' | translate }}</span>
  </button>
  <mat-divider></mat-divider>
  <button (click)="deleteSceneMapping()" mat-menu-item>
    <mat-icon color="red">delete</mat-icon>
    <span class="mat-red">{{ 'calibration.delete' | translate }}</span>
  </button>
</mat-menu>

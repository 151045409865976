import {Component} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {RegistrationStage} from '@app/auth/state/auth.state';
import {REGISTRATION_STAGE} from '@app/auth/models/auth.models';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, share} from 'rxjs/operators';
import {UserService} from '../../../core/services/api/user.service';
import {EVENTS, UnleashAnalyticsService} from '../../../core/services/unleash-analytics.service';
import {PaymentService} from '../../services/payment.service';
import {CancelPlanMessageDialogComponent} from '../cancel-plan-message-dialog/cancel-plan-message-dialog.component';
import {HelpService} from '@app/core/services/help.service';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss']
})
export class CancelPlanDialog {
  public cancelSubscriptionInProgress$: Observable<boolean>;
  private cancelSubscriptionInProgress = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<CancelPlanDialog>,
    private dialog: MatDialog,
    private paymentService: PaymentService,
    private userService: UserService,
    private unleashAnalytics: UnleashAnalyticsService,
    private localStorageService: LocalStorageService,
    private helpService: HelpService
  ) {
    this.cancelSubscriptionInProgress$ = this.cancelSubscriptionInProgress.asObservable().pipe(share());
  }

  public cancelSubscription() {
    this.cancelSubscriptionInProgress.next(true);
    this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.payment);
    this.paymentService
      .deleteUserSubscription()
      .pipe(
        catchError(() => {
          this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.done);
          this.cancelSubscriptionInProgress.next(false);
          return throwError('Cancel subscription fail');
        })
      )
      .subscribe(() => {
        this.userService.updatePlanInUserDataStore(undefined, undefined);
        this.dialogRef.close();
        this.unleashAnalytics.logEvent(EVENTS.SUBSCRIPTION_CANCELLED);
        this.cancelSubscriptionInProgress.next(false);
        this.dialog.open(CancelPlanMessageDialogComponent, {
          width: '80vw',
          maxWidth: '800px'
        } as MatDialogConfig);
      });
  }

  public goToContactUs() {
    this.helpService.goToContactUs();
  }
}

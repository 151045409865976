import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {take} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Directive({
  selector: '[ulChangeLayerColor]',
  standalone: true
})
export class ChangeLayerColorDirective {
  @Input('ulChangeLayerColor') public asset: AtlasAssetModel;
  @Input() public colorIndex: number;

  constructor(
    private atlasService: AtlasService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  @HostListener('click', ['$event'])
  public changeLayerColor(): void {
    const asset = this.asset;
    const colorIndex = this.colorIndex;

    this.atlasService.changeLayerColor(asset, colorIndex);
    this.translateService
      .get('atlas.changeColor.layerColorUpdated')
      .pipe(take(1))
      .subscribe(message => {
        this.snackBar.open(message, null, {
          panelClass: 'center',
          duration: 3000
        });
      });

    this.atlasService.toogleHasToDetectChanges();
  }
}

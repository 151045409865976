<div class="container mat-elevation-z2" [ngClass]="{inactive: isInactive, selected: isSelected}">
  <div class="title content-item">{{ title }}</div>
  <div class="background-image-container">
    <img class="background-image" [src]="backgroundImage" [ngClass]="!isSelected ? 'background-image--inactive' : ''" />
  </div>
  <div class="name-container content-item">
    <div class="name">
      {{ name }}
      <span *ngIf="plan.planName === planNames.professional">Pro</span>
    </div>
    <mat-icon color="success" *ngIf="isSelected">check_circle </mat-icon>
  </div>
  <div class="price content-item">
    <!-- <div class="value-container" *ngIf="!isFirstGroup && plan.planName === 'Professional' && (isEssentialDisabled | async)">
      <span class="value--strikediag value--withpadding">{{ price }}</span>
      <span class="value--discount">149 </span>
      <span class="money money--discount">USD</span>
    </div> -->
    <div class="value-container" *ngIf="plan.planName === planNames.essentials">
      <span class="value">{{ price }}</span>
      <span class="money">USD</span>
    </div>
    <div class="value-container" *ngIf="plan.planName === planNames.professional">
      <span
        class="value"
        [ngClass]="{
          'value--strikediag': !isFirstGroup && (isEssentialDisabled | async),
          'value--withpadding': !isFirstGroup && (isEssentialDisabled | async)
        }"
        >{{ price }}</span
      >
      <span class="value--discount" *ngIf="!isFirstGroup && (isEssentialDisabled | async)">149 </span>
      <span
        class="money"
        [ngClass]="{
          'money--discount': !isFirstGroup && (isEssentialDisabled | async)
        }"
        >USD</span
      >
    </div>
    <div class="type">per month</div>
  </div>
  <div class="content-item" *ngIf="plan.planName === planNames.professional">
    <unleash-alert
      type="warning-light"
      iconName="info"
      label="If you subscribe to a Professional plan, all your plans are upgraded to Professional free of charge"
    >
    </unleash-alert>
  </div>

  <div class="features content-item">
    <unleash-plan-features [plan]="plan" [isInactive]="isInactive"></unleash-plan-features>
  </div>
  <div class="buttons content-item">
    <button
      class="button"
      (click)="clickOnAction(); $event.stopPropagation()"
      [disabled]="isInactive"
      color="primary"
      mat-stroked-button
    >
      {{ buttonName }}
    </button>
  </div>
</div>

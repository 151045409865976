<mat-button-toggle-group class="severity">
  <mat-button-toggle
    *ngFor="let severity of severities"
    (click)="setValue(severity.value)"
    [ngClass]="severity.class"
    [checked]="severity.value === value"
    [aria-label]="severity.arialLabel"
  >
    {{ severity.display }}
  </mat-button-toggle>
</mat-button-toggle-group>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {AddonStoreFacadeService} from './addon-store-facade.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  canUseMissionPlanner$: Observable<boolean>;
  canUseAtlas$: Observable<boolean>;

  private isModellingEnabled: boolean;

  constructor(private addonStoreFacade: AddonStoreFacadeService) {
    const modellingEnabledShared$: Observable<boolean> = this.addonStoreFacade.getIsModellingEnabled().pipe(share());
    this.canUseMissionPlanner$ = modellingEnabledShared$;
    this.canUseAtlas$ = modellingEnabledShared$;
    modellingEnabledShared$.subscribe((isModellingEnabled: boolean) => (this.isModellingEnabled = isModellingEnabled));
  }

  canUseMissionPlanner(): boolean {
    return this.isModellingEnabled;
  }

  canUseAtlas(): boolean {
    return this.isModellingEnabled;
  }
}

import {createReducer, on} from '@ngrx/store';
import * as action from './acl.actions';

export interface AclState {
  permissions: string[];
  isLoading: boolean;
}

const initialState: AclState = {permissions: [], isLoading: false};

export const aclReducer = createReducer(
  initialState,
  on(action.requestACLConfig, state => ({...state, isLoading: true})),
  on(action.requestACLConfigSuccess, (state, {payload}) => ({
    ...state,
    permissions: payload.permissions,
    isLoading: false
  }))
);

import {MenuItemTypeEnum} from '@app/core/models/gui/menu-item-type.enum';
import {MenuItem} from './menu-item';

export class LinkMenuItem extends MenuItem {
  public routerLink: string;
  public type: MenuItemTypeEnum = MenuItemTypeEnum.link;

  constructor(
    label: string,
    imagePath: string,
    routerLink: string,
    fragment?: string,
    queryParams?: {},
    styleClass?: string
  ) {
    super(label, imagePath, styleClass, fragment, queryParams);
    this.routerLink = routerLink;
  }
}

export interface Paginator {
  sizeOptions: number[];
  pageSize: number;
  length: number;
}

export enum SORT_ORDER {
  ASCENDENT = 'asc',
  DESCENDENT = 'desc'
}

export interface SortType {
  active: string;
  direction: SORT_ORDER;
}

export interface FilterAttributes {
  pageIndex: number;
  pageSize: number;
  sort: SortType;
}

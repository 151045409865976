import {Injectable} from '@angular/core';
import {PlanModel} from '@app/core/models/api/plan.model';
import {
  clearSelectedPlans,
  disableEssentialSelection,
  downgradePlanOnStep,
  enableEssentialSelection,
  removePlanOnStep,
  setCombinedPlan,
  setCurrentStep,
  setFirstProfessionalStepIndex,
  setIsSamePackage,
  setPaymentPeriod,
  setPlanOnStep,
  setPlans,
  setTotalPayment,
  upgradePlanOnStep
} from '@app/plans/store/package-selector.actions';
import {
  selectCheckoutPlans,
  selectCurrentStep,
  selectFirstProfessionalStepIndex,
  selectHasProfessionalPlanSelected,
  selectIsSamePackage,
  selectPaymentPeriod,
  selectPlans,
  selectPlansByStep,
  selectTotalPayment
} from '@app/plans/store/package-selector.selectors';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {select, Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class PackageSelectorStoreFacadeService {
  public plansByStep$ = this.store.pipe(select(selectPlansByStep));
  public currentStep$ = this.store.pipe(select(selectCurrentStep));
  public paymentPeriod$ = this.store.pipe(select(selectPaymentPeriod));
  public firstProfessionalStepIndex$ = this.store.pipe(select(selectFirstProfessionalStepIndex));
  public hasProfessionalPlanSelected$ = this.store.pipe(select(selectHasProfessionalPlanSelected));
  public totalPayment$ = this.store.pipe(select(selectTotalPayment));
  public checkoutPlans$ = this.store.pipe(select(selectCheckoutPlans));
  public selectIsSamePackage$ = this.store.pipe(select(selectIsSamePackage));
  public plans$ = this.store.pipe(select(selectPlans));

  constructor(private store: Store) {}

  public setPlans(plans: PlanModel[]): void {
    this.store.dispatch(setPlans({payload: {plans}}));
  }

  public clearSelectedPlans(): void {
    this.store.dispatch(clearSelectedPlans());
  }

  public setCombinedPlan(planId: PlanModel['id']): void {
    this.store.dispatch(setCombinedPlan({payload: {planId}}));
  }

  public setPlanOnStep(stepIndex: number, planId: PlanModel['id']): void {
    this.store.dispatch(setPlanOnStep({payload: {stepIndex, planId}}));
  }

  public removePlanOnStep(stepIndex: number): void {
    this.store.dispatch(removePlanOnStep({payload: {stepIndex}}));
  }

  public setTotalPayment(totalPayment: number): void {
    this.store.dispatch(setTotalPayment({payload: {totalPayment}}));
  }

  public setCurrentStep(currentStep: number): void {
    this.store.dispatch(setCurrentStep({payload: {currentStep}}));
  }

  public setPaymentPeriod(paymentPeriod: PaymentPeriod): void {
    this.store.dispatch(setPaymentPeriod({payload: {paymentPeriod}}));
  }

  public setFirstProfessionalStepIndex(firstProfessionalStepIndex: number): void {
    this.store.dispatch(setFirstProfessionalStepIndex({payload: {firstProfessionalStepIndex}}));
  }

  public disableEssentialSelection(): void {
    this.store.dispatch(disableEssentialSelection());
  }

  public enableEssentialSelection(): void {
    this.store.dispatch(enableEssentialSelection());
  }

  public upgradePlanOnStep(stepIndex: number): void {
    this.store.dispatch(upgradePlanOnStep({payload: {stepIndex}}));
  }

  public downgradePlanOnStep(stepIndex: number): void {
    this.store.dispatch(downgradePlanOnStep({payload: {stepIndex}}));
  }

  public setIsSamePackage(isSamePackage: boolean) {
    this.store.dispatch(setIsSamePackage({payload: {isSamePackage}}));
  }
}

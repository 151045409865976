import '@angular/compiler';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {Auth} from '@aws-amplify/auth';
import {API} from '@aws-amplify/api';
import {Storage} from '@aws-amplify/storage';

import 'hammerjs';

import {AppModule} from './app/app.module';
import {amplifyConfig} from './environments/amplify.config';
import {environment} from './environments/environment';

// configure Amplify using modular approach, Auth must be initialized first
Auth.configure(amplifyConfig);
API.configure(amplifyConfig);
Storage.configure(amplifyConfig);

const isLocal: boolean = /local/.test(document.location.host);

if (!isLocal) {
  enableProdMode();
} else {
  console.debug('DEVELOPMENT MODE');
  // Logger.LOG_LEVEL = 'DEBUG'; // useful for amplify debugging same as window.LOG_LEVEL
}

// disable logs in production
if (environment.production) {
  console.log = () => {
    /*empty*/
  };
  console.debug = () => {
    /*empty*/
  };
  console.time = () => {
    /*empty*/
  };
  console.timeEnd = () => {
    /*empty*/
  };
  console.table = () => {
    /*empty*/
  };
  // console.info = () => {  /*empty*/ };
  // console.error = function() {}
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

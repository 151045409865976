
<div [@growDown] class="container mat-elevation-z3" *ngIf="isOpen">
  <div class="content">
    <p class="message">
      <ng-content select="[slot=message]"></ng-content>
    </p>
    <div class="action-row">
      <ng-content select="[slot=buttons]"></ng-content>
    </div>
  </div>
</div>

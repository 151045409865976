<div
  class="container"
  [class.active]="radioButton.checked || isSelectedCard"
  (click)="radioButton.checked = !radioButton.checked; isSelectedCard = !isSelectedCard; emitCheckedValue(radioButton)"
>
  <div class="option option--big-viewport">
    <mat-radio-button color="primary" #radioButton [value]="value" [checked]="isSelectedCard"></mat-radio-button>
  </div>
  <div class="image__container">
    <img class="image" [src]="imageSrc" alt="logo" />
  </div>
  <div class="spacer"></div>
  <div class="group">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
</div>

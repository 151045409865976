/* eslint-disable no-magic-numbers */
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {DrawTool} from '@app/shared/image-annotation-shared/models/draw-tool';
import {
  Canvas,
  Draw,
  LabelOpacity,
  SingleLabel
} from '@app/shared/image-annotation-shared/models/image-annotation.model';
import {cloneDeep} from 'lodash';
import {Subscription, fromEvent, throttleTime} from 'rxjs';

declare let SVG: any;

export class Perspective extends DrawTool {
  constructor(ref: Draw, canvas: Canvas) {
    super(canvas);
    this.ref = ref;
    this.type = ShapeTypes.perspective;
  }

  private poly: any;
  private line1: any;
  private line2: any;
  private line3: any;
  private line4: any;

  private leftCenter: any;
  private rightCenter: any;
  private topCenter: any;
  private bottomCenter: any;
  private leftCenterText: any;
  private rightCenterText: any;
  private topCenterText: any;
  private bottomCenterText: any;

  private circleRadius: number = 24;

  private dragmoveSub: Subscription;
  private resizingSub: Subscription;
  private drawStopSub: Subscription;

  public startDraw(opts?: Partial<{id: string; color: string; name: string}>) {
    this.ref.remember('hasToDraw', true);
    this.selectedColor = opts.color;
    const id = opts ? opts.id : this.idGenerator(this.type);

    this.poly = this.ref.draw({
      closeLastPoint: false,
      circleDiameter: this.circleDiameter > this.maxCircleDiameter ? this.maxCircleDiameter : this.circleDiameter,
      drawCircles: true,
      maxCircleDiameter: this.maxCircleDiameter,
      maxPoints: 5,
      minPoints: 5,
      skipPointConstrain: true
    });
    this.ref.attr('fill', '#3A7DDF80');
    this.ref.attr('fill-opacity', LabelOpacity.visible);
    this.ref.attr('stroke', '#3A7DDF');
    this.ref.attr('stroke-opacity', '1');
    this.ref.attr('id', id);
    this.ref.attr('name', opts.name);
    this.ref.attr('shape-type', this.type);

    this.clickOnCanvasExtraElements(['pinch-zoom', 'unleash-image-annotation-shared']);

    const drawStop$ = fromEvent(this.ref as any, 'drawstop');
    this.drawStopSub = drawStop$.pipe(throttleTime(5)).subscribe(() => {
      const polyPoints = [...(this.poly as any).array().value];

      const {topLeft, bottomLeft, topRight, bottomRight, leftCenter, rightCenter, topCenter, bottomCenter} =
        this.classifyPoints(polyPoints);
      const [small0, big3] = this.pointsOverLine(topLeft, bottomLeft);
      const [small1, big2] = this.pointsOverLine(topRight, bottomRight);
      const [small4, big7] = this.pointsOverLine(topLeft, topRight);
      const [small5, big6] = this.pointsOverLine(bottomLeft, bottomRight);

      const id = (this.ref as any).node.id;
      this.leftCenter = (this.canvas as any)
        .circle(this.circleRadius)
        .fill('#3A7DDF')
        .attr('data-id', `${id}-left-center`);
      this.leftCenterText = (this.canvas as any)
        .text('B')
        .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
        .attr('data-id', `${id}-left-center-text`);
      this.leftCenter.move(
        leftCenter[0] - this.leftCenter.bbox().width / 2,
        leftCenter[1] - this.leftCenter.bbox().height / 2
      );
      this.leftCenterText.move(
        this.leftCenter.cx() - this.leftCenterText.bbox().width / 2,
        this.leftCenter.cy() - this.leftCenterText.bbox().height / 2
      );

      this.rightCenter = (this.canvas as any)
        .circle(this.circleRadius)
        .fill('#3A7DDF')
        .attr('data-id', `${id}-right-center`);
      this.rightCenterText = (this.canvas as any)
        .text('B')
        .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
        .attr('data-id', `${id}-right-center-text`);
      this.rightCenter.move(
        rightCenter[0] - this.rightCenter.bbox().width / 2,
        rightCenter[1] - this.rightCenter.bbox().height / 2
      );
      this.rightCenterText.move(
        this.rightCenter.cx() - this.rightCenterText.bbox().width / 2,
        this.rightCenter.cy() - this.rightCenterText.bbox().height / 2
      );

      this.topCenter = (this.canvas as any)
        .circle(this.circleRadius)
        .fill('#3A7DDF')
        .attr('data-id', `${id}-top-center`);
      this.topCenterText = (this.canvas as any)
        .text('A')
        .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
        .attr('data-id', `${id}-top-center-text`);
      this.topCenter.move(
        topCenter[0] - this.topCenter.bbox().width / 2,
        topCenter[1] - this.topCenter.bbox().height / 2
      );
      this.topCenterText.move(
        this.topCenter.cx() - this.topCenterText.bbox().width / 2,
        this.topCenter.cy() - this.topCenterText.bbox().height / 2
      );

      this.bottomCenter = (this.canvas as any)
        .circle(this.circleRadius)
        .fill('#3A7DDF')
        .attr('data-id', `${id}-bottom-center`);
      this.bottomCenterText = (this.canvas as any)
        .text('A')
        .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
        .attr('data-id', `${id}-bottom-center-text`);
      this.bottomCenter.move(
        bottomCenter[0] - this.bottomCenter.bbox().width / 2,
        bottomCenter[1] - this.bottomCenter.bbox().height / 2
      );
      this.bottomCenterText.move(
        this.bottomCenter.cx() - this.bottomCenterText.bbox().width / 2,
        this.bottomCenter.cy() - this.bottomCenterText.bbox().height / 2
      );

      this.line1 = (this.canvas as any)
        .line(small0[0], small0[1], small1[0], small1[1])
        .stroke('#3A7DDF')
        .attr('data-id', `${id}-line1`);
      this.line2 = (this.canvas as any)
        .line(big3[0], big3[1], big2[0], big2[1])
        .stroke('#3A7DDF')
        .attr('data-id', `${id}-line2`);
      this.line3 = (this.canvas as any)
        .line(small4[0], small4[1], small5[0], small5[1])
        .stroke('#3A7DDF')
        .attr('data-id', `${id}-line3`);
      this.line4 = (this.canvas as any)
        .line(big7[0], big7[1], big6[0], big6[1])
        .stroke('#3A7DDF')
        .attr('data-id', `${id}-line4`);
    });
  }

  public stopSelection() {
    this.leftCenter?.attr({opacity: 0});
    this.rightCenter?.attr({opacity: 0});
    this.topCenter?.attr({opacity: 0});
    this.bottomCenter?.attr({opacity: 0});
    this.leftCenterText?.attr({opacity: 0});
    this.rightCenterText?.attr({opacity: 0});
    this.topCenterText?.attr({opacity: 0});
    this.bottomCenterText?.attr({opacity: 0});

    this.ref.resize('stop').draggable(false).selectize(false, {deepSelect: true}).selectize(false);
    this.ref.attr('stroke-dasharray', '1');

    if (this.dragmoveSub) {
      this.dragmoveSub.unsubscribe();
      this.dragmoveSub = null;
    }

    if (this.resizingSub) {
      this.resizingSub.unsubscribe();
      this.resizingSub = null;
    }

    if (this.drawStopSub) {
      this.drawStopSub.unsubscribe();
      this.drawStopSub = null;
    }
  }

  private classifyPoints(polyPoints: number[][]) {
    const X = 1;
    const Y = 0;

    const leftPoints = polyPoints
      .sort((a, b) => {
        return a[Y] < b[Y] ? -1 : a[Y] > b[Y] ? 1 : 0;
      })
      .slice(0, 2);

    const rightPoints = polyPoints
      .sort((a, b) => {
        return a[Y] < b[Y] ? -1 : a[Y] > b[Y] ? 1 : 0;
      })
      .slice(2, 4);

    const [topRight, bottomRight] = rightPoints.sort((a, b) => {
      return a[X] < b[X] ? -1 : a[X] > b[X] ? 1 : 0;
    });

    const [topLeft, bottomLeft] = leftPoints.sort((a, b) => {
      return a[X] < b[X] ? -1 : a[X] > b[X] ? 1 : 0;
    });

    const rightCenter = this.middleParallelPoint(topRight, bottomRight, 'right');
    const leftCenter = this.middleParallelPoint(topLeft, bottomLeft, 'left');

    const bottomCenter = this.middleParallelPoint(bottomLeft, bottomRight, 'bottom');
    const topCenter = this.middleParallelPoint(topLeft, topRight, 'top');

    return {topLeft, bottomLeft, topRight, bottomRight, leftCenter, rightCenter, topCenter, bottomCenter};
  }

  private pointsOverLine(pointOne, pointTwo) {
    if (!pointOne || !pointTwo) {
      return null;
    }

    const X = 0;
    const Y = 1;

    const lx2 = Math.abs(pointOne[X] - pointTwo[X]);
    const ly2 = Math.abs(pointOne[Y] - pointTwo[Y]);
    const {smallPointX, namePointX} =
      pointOne[X] <= pointTwo[X]
        ? {smallPointX: pointOne[X], namePointX: 'pointOne'}
        : {smallPointX: pointTwo[X], namePointX: 'pointTwo'};
    const {smallPointY, namePointY} =
      pointOne[Y] <= pointTwo[Y]
        ? {smallPointY: pointOne[Y], namePointY: 'pointOne'}
        : {smallPointY: pointTwo[Y], namePointY: 'pointTwo'};

    const p1x = smallPointX + lx2 / 3;
    const p2x = smallPointX + (2 * lx2) / 3;

    const p1y = smallPointY + ly2 / 3;
    const p2y = smallPointY + (2 * ly2) / 3;

    if (namePointY === 'pointTwo' && namePointX === 'pointOne') {
      return [
        [p1x, p2y],
        [p2x, p1y]
      ];
    }

    return namePointX === 'pointOne'
      ? [
          [p1x, p1y],
          [p2x, p2y]
        ]
      : [
          [p2x, p1y],
          [p1x, p2y]
        ];
  }

  private middleParallelPoint(pointOne, pointTwo, type = 'left') {
    if (!pointOne || !pointTwo) {
      return null;
    }

    const point = this.findPerpendicularPoint(
      {x: pointOne[0], y: pointOne[1]},
      {x: pointTwo[0], y: pointTwo[1]},
      (40 * this.generalScalingFactor) / this.baseScalingFactor,
      type
    );
    return [point.x, point.y];
  }

  private midpoint(P1: Point, P2: Point): Point {
    return {
      x: (P1.x + P2.x) / 2,
      y: (P1.y + P2.y) / 2
    };
  }

  private findPerpendicularPoint(P1: Point, P2: Point, distance: number, side: string): Point {
    const midPoint = this.midpoint(P1, P2);

    let dx, dy;
    if (side === 'top' || side === 'bottom') {
      dx = Math.abs(P2.x - P1.x);
      dy = Math.abs(P2.y - P1.y);
    } else {
      dx = P2.x - P1.x;
      dy = P2.y - P1.y;
    }

    let perpSlope;

    if (dx === 0) {
      perpSlope = 0;
    } else if (dy === 0) {
      perpSlope = Infinity;
    } else {
      perpSlope = -dx / dy;
    }

    const delta = Math.sqrt(distance ** 2 / (1 + perpSlope ** 2));
    let x, y;
    if (Number.isFinite(perpSlope)) {
      if (side === 'top' || side === 'bottom') {
        x = midPoint.x + delta;
        y = midPoint.y + perpSlope * delta * -1;

        if (P1.y < P2.y) {
          x = midPoint.x - delta;
          y = midPoint.y + perpSlope * delta * -1;
        }
      } else {
        x = midPoint.x + delta;
        y = midPoint.y + perpSlope * delta;
      }
    } else {
      x = midPoint.x;
      y = midPoint.y + distance;
    }

    if (side === 'left' || side === 'top') {
      return {
        x: 2 * midPoint.x - x,
        y: 2 * midPoint.y - y
      };
    }

    return {x, y};
  }

  public removeDraw() {
    super.removeDraw();
    document.removeEventListener('click', () => null);
    const id = (this.ref as any).node.id;

    let localSelection = SVG.select(`[data-id="${id}-line1"]`);
    if (localSelection.members.length > 0) {
      this.line1 = localSelection.members[0];
    }

    localSelection = SVG.select(`[data-id="${id}-line2"]`);
    if (localSelection.members.length > 0) {
      this.line2 = localSelection.members[0];
    }

    localSelection = SVG.select(`[data-id="${id}-line3"]`);
    if (localSelection.members.length > 0) {
      this.line3 = localSelection.members[0];
    }

    localSelection = SVG.select(`[data-id="${id}-line4"]`);
    if (localSelection.members.length > 0) {
      this.line4 = localSelection.members[0];
    }

    this.line1?.remove();
    this.line2?.remove();
    this.line3?.remove();
    this.line4?.remove();
  }

  public generateDrawTemplate(): SingleLabel {
    throw new Error('Method not implemented.');
  }
  public exportDraw(singleLabel: SingleLabel): [SingleLabel, string] {
    throw new Error('Method not implemented.');
  }
  public importDraw(
    label: SingleLabel,
    scalingFactor: number,
    canvasSize?: {width: number; height: number},
    originalSize?: {width: number; height: number}
  ): SingleLabel {
    let vertices = label.shape.vertices;
    if (label.shape?.length > 0) {
      vertices = label.shape.map(vertices => ({x: vertices[0], y: vertices[1]}));
    }

    let color = label.color;
    if (typeof label.color === 'object') {
      color = (label as any).color.fill;
    }

    const singleLabel: SingleLabel = {
      id: label.id,
      displayName: (label as any).display_name,
      visibility: true,
      shapeType: label.shapeType,
      category: label.category,
      shape: vertices,
      severity: label.severity,
      comment: label.comment,
      color: color,
      isAI: label.isAI,
      isAccepted: label.isAccepted,
      isModified: label.isModified,
      suggestedCategory: label.suggestedCategory,
      addonId: label.addonId,
      distance: label.distance
    };

    this.setScalingFactorForCircleRadius(scalingFactor);

    this.ref.attr('id', singleLabel.id);
    this.ref.attr('fill', singleLabel.color);
    this.ref.attr('fill-opacity', LabelOpacity.visible);
    this.ref.attr('stroke', '#3A7DDF');
    this.ref.attr('stroke-opacity', '1');
    this.ref.attr('stroke-width', this.vertexStrokeWidth + 'px');
    this.ref.attr('stroke-dasharray', '0');
    this.ref.attr('display-name', singleLabel.displayName);
    this.poly = this.ref.plot(singleLabel.shape);

    const polyPoints = [...(this.poly as any).array().value];

    const {topLeft, bottomLeft, topRight, bottomRight, leftCenter, rightCenter, topCenter, bottomCenter} =
      this.classifyPoints(polyPoints);
    const [small0, big3] = this.pointsOverLine(topLeft, bottomLeft);
    const [small1, big2] = this.pointsOverLine(topRight, bottomRight);
    const [small4, big7] = this.pointsOverLine(topLeft, topRight);
    const [small5, big6] = this.pointsOverLine(bottomLeft, bottomRight);

    const id = (this.ref as any).node.id;

    this.leftCenter = (this.canvas as any)
      .circle(this.circleRadius)
      .move(leftCenter[0], leftCenter[1])
      .fill('#3A7DDF')
      .attr({'data-id': `${id}-left-center`, opacity: LabelOpacity.hidden});
    this.leftCenterText = (this.canvas as any)
      .text('B')
      .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
      .attr({'data-id': `${id}-left-center-text`, opacity: LabelOpacity.hidden});
    this.leftCenterText.move(
      this.leftCenter.cx() - this.leftCenterText.bbox().width / 2,
      this.leftCenter.cy() - this.leftCenterText.bbox().height / 2
    );

    this.rightCenter = (this.canvas as any)
      .circle(this.circleRadius)
      .move(rightCenter[0], rightCenter[1])
      .fill('#3A7DDF')
      .attr({'data-id': `${id}-right-center`, opacity: LabelOpacity.hidden});
    this.rightCenterText = (this.canvas as any)
      .text('B')
      .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
      .attr({'data-id': `${id}-right-center-text`, opacity: LabelOpacity.hidden});
    this.rightCenterText.move(
      this.rightCenter.cx() - this.rightCenterText.bbox().width / 2,
      this.rightCenter.cy() - this.rightCenterText.bbox().height / 2
    );

    this.topCenter = (this.canvas as any)
      .circle(this.circleRadius)
      .move(topCenter[0], topCenter[1])
      .fill('#3A7DDF')
      .attr({'data-id': `${id}-top-center`, opacity: LabelOpacity.hidden});
    this.topCenterText = (this.canvas as any)
      .text('A')
      .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
      .attr({'data-id': `${id}-top-center-text`, opacity: LabelOpacity.hidden});
    this.topCenterText.move(
      this.topCenter.cx() - this.topCenterText.bbox().width / 2,
      this.topCenter.cy() - this.topCenterText.bbox().height / 2
    );

    this.bottomCenter = (this.canvas as any)
      .circle(this.circleRadius)
      .move(bottomCenter[0], bottomCenter[1])
      .fill('#3A7DDF')
      .attr({'data-id': `${id}-bottom-center`, opacity: LabelOpacity.hidden});
    this.bottomCenterText = (this.canvas as any)
      .text('A')
      .attr({fill: '#FFF', 'font-family': 'Roboto', 'font-weight': 700, style: 'user-select: none'})
      .attr({'data-id': `${id}-bottom-center-text`, opacity: LabelOpacity.hidden});
    this.bottomCenterText.move(
      this.bottomCenter.cx() - this.bottomCenterText.bbox().width / 2,
      this.bottomCenter.cy() - this.bottomCenterText.bbox().height / 2
    );

    this.line1 = (this.canvas as any)
      .line(small0[0], small0[1], small1[0], small1[1])
      .stroke('#3A7DDF')
      .attr('data-id', `${id}-line1`);
    this.line2 = (this.canvas as any)
      .line(big3[0], big3[1], big2[0], big2[1])
      .stroke('#3A7DDF')
      .attr('data-id', `${id}-line2`);
    this.line3 = (this.canvas as any)
      .line(small4[0], small4[1], small5[0], small5[1])
      .stroke('#3A7DDF')
      .attr('data-id', `${id}-line3`);
    this.line4 = (this.canvas as any)
      .line(big7[0], big7[1], big6[0], big6[1])
      .stroke('#3A7DDF')
      .attr('data-id', `${id}-line4`);

    this.ref.on('mouseenter', () => {
      document.dispatchEvent(new CustomEvent('highlightZone', {detail: this.id}));
    });

    this.ref.on('mouseleave', () => {
      document.dispatchEvent(new CustomEvent('highlightZone', {detail: ''}));
    });

    return singleLabel;
  }

  public redrawCirclesOnEditMode() {
    this.stopSelection();
    this.startSelection();
    this.updateLinesPosition();
  }

  public restoreBackup(drawBak: any) {
    super.restoreBackup(drawBak);

    if (drawBak?.points) {
      this.ref.attr('points', drawBak.points);
    }

    if (drawBak?.arrayPoints) {
      this.ref._array.value = cloneDeep(drawBak.arrayPoints);
    }

    if (drawBak?.color) {
      this.ref.attr('fill', drawBak.color);
    }

    this.updateLinesPosition();
  }

  public saveShapeBackup() {
    return {
      arrayPoints: cloneDeep(this.ref._array.value),
      points: this.ref.attr('points'),
      color: this.ref.attr('fill')
    };
  }

  public hasDraw(): boolean {
    return this.ref._array && this.ref._array.value.length === 4;
  }

  protected startSelection() {
    this.poly = this.ref
      .selectize({
        rotationPoint: false,
        deepSelect: true,
        pointSize: this.vertexDiameterPointsExtended,
        maxPointSize: this.maxCircleDiameter,
        pointStroke: {width: this.vertexStrokeWidth},
        pointPadding: this.transformBoxSize,
        vertexSize: this.vertexDiameterPoints,
        vertexStroke: {width: this.vertexStrokeWidth},
        transformBoxSize: this.transformBoxSize,
        classPoints: 'perspective-points'
      })
      .resize()
      .draggable(this.constrain);

    this.ref.attr('stroke-dasharray', 1);
    this.poly.stroke('#3A7DDF');

    const id = (this.ref as any).node.id;

    let localSelection = SVG.select(`[data-id="${id}-left-center"]`);
    if (localSelection.members.length > 0) {
      this.leftCenter = localSelection.members[0];
      this.leftCenter.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-left-center-text"]`);
    if (localSelection.members.length > 0) {
      this.leftCenterText = localSelection.members[0];
      this.leftCenterText.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-right-center"]`);
    if (localSelection.members.length > 0) {
      this.rightCenter = localSelection.members[0];
      this.rightCenter.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-right-center-text"]`);
    if (localSelection.members.length > 0) {
      this.rightCenterText = localSelection.members[0];
      this.rightCenterText.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-top-center"]`);
    if (localSelection.members.length > 0) {
      this.topCenter = localSelection.members[0];
      this.topCenter.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-top-center-text"]`);
    if (localSelection.members.length > 0) {
      this.topCenterText = localSelection.members[0];
      this.topCenterText.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-bottom-center"]`);
    if (localSelection.members.length > 0) {
      this.bottomCenter = localSelection.members[0];
      this.bottomCenter.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-bottom-center-text"]`);
    if (localSelection.members.length > 0) {
      this.bottomCenterText = localSelection.members[0];
      this.bottomCenterText.attr({opacity: 1});
    }

    localSelection = SVG.select(`[data-id="${id}-line1"]`);
    if (localSelection.members.length > 0) {
      this.line1 = localSelection.members[0];
    }

    localSelection = SVG.select(`[data-id="${id}-line2"]`);
    if (localSelection.members.length > 0) {
      this.line2 = localSelection.members[0];
    }

    localSelection = SVG.select(`[data-id="${id}-line3"]`);
    if (localSelection.members.length > 0) {
      this.line3 = localSelection.members[0];
    }

    localSelection = SVG.select(`[data-id="${id}-line4"]`);
    if (localSelection.members.length > 0) {
      this.line4 = localSelection.members[0];
    }
    this.updateLinesPosition();

    const resizing$ = fromEvent(this.ref as any, 'resizing');
    this.resizingSub = resizing$.pipe(throttleTime(10)).subscribe(() => {
      this.updateLinesPosition();
    });

    const dragmove$ = fromEvent(this.ref as any, 'dragmove');
    this.dragmoveSub = dragmove$.pipe(throttleTime(10)).subscribe(() => {
      this.updateLinesPosition();
    });
  }

  private updateLinesPosition() {
    const polyPoints = [...(this.poly as any).array().value];

    const {topLeft, bottomLeft, topRight, bottomRight, leftCenter, rightCenter, topCenter, bottomCenter} =
      this.classifyPoints(polyPoints);
    if (
      !topLeft ||
      !bottomLeft ||
      !topRight ||
      !bottomRight ||
      !leftCenter ||
      !rightCenter ||
      !topCenter ||
      !bottomCenter
    ) {
      return;
    }
    const [small0, big3] = this.pointsOverLine(topLeft, bottomLeft);
    const [small1, big2] = this.pointsOverLine(topRight, bottomRight);
    const [small4, big7] = this.pointsOverLine(topLeft, topRight);
    const [small5, big6] = this.pointsOverLine(bottomLeft, bottomRight);

    const circleSize = this.circleDiameter * 1;
    const fontSize = this.generalScalingFactor * 1.2;

    this.leftCenter.radius(circleSize);
    this.leftCenter.move(
      leftCenter[0] - this.leftCenter.bbox().width / 2,
      leftCenter[1] - this.leftCenter.bbox().height / 2
    );
    this.rightCenter.radius(circleSize);
    this.rightCenter.move(
      rightCenter[0] - this.rightCenter.bbox().width / 2,
      rightCenter[1] - this.rightCenter.bbox().height / 2
    );
    this.topCenter.radius(circleSize);
    this.topCenter.move(
      topCenter[0] - this.topCenter.bbox().width / 2,
      topCenter[1] - this.topCenter.bbox().height / 2
    );
    this.bottomCenter.radius(circleSize);
    this.bottomCenter.move(
      bottomCenter[0] - this.bottomCenter.bbox().width / 2,
      bottomCenter[1] - this.bottomCenter.bbox().height / 2
    );

    this.leftCenterText
      .font({size: fontSize})
      .move(
        this.leftCenter.cx() - this.leftCenterText.bbox().width / 2,
        this.leftCenter.cy() - this.leftCenterText.bbox().height / 2
      );
    this.rightCenterText
      .font({size: fontSize})
      .move(
        this.rightCenter.cx() - this.rightCenterText.bbox().width / 2,
        this.rightCenter.cy() - this.rightCenterText.bbox().height / 2
      );
    this.topCenterText
      .font({size: fontSize})
      .move(
        this.topCenter.cx() - this.topCenterText.bbox().width / 2,
        this.topCenter.cy() - this.topCenterText.bbox().height / 2
      );
    this.bottomCenterText
      .font({size: fontSize})
      .move(
        this.bottomCenter.cx() - this.bottomCenterText.bbox().width / 2,
        this.bottomCenter.cy() - this.bottomCenterText.bbox().height / 2
      );

    this.line1.plot(small0[0], small0[1], small1[0], small1[1]).attr('stroke-dasharray', 0);
    this.line2.plot(big3[0], big3[1], big2[0], big2[1]).attr('stroke-dasharray', 0);
    this.line3.plot(small4[0], small4[1], small5[0], small5[1]).attr('stroke-dasharray', 0);
    this.line4.plot(big7[0], big7[1], big6[0], big6[1]).attr('stroke-dasharray', 0);
  }
}
interface Point {
  x: number;
  y: number;
}

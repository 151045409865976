import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {
  reasonFormSubmit,
  reasonFormSubmitSuccess,
  saveReasonToLocalStorage
} from './reason.actions';

@Injectable()
export class ReasonFormEffects {
  public reasonFormSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reasonFormSubmit),
      map(({form}) => {
        this.router.navigate(['/auth/payment']);
        return reasonFormSubmitSuccess();
      })
    )
  );

  public saveReasonToLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveReasonToLocalStorage),
        tap(({reason}): void =>
          localStorage.setItem('selectedReason', reason.reason)
        )
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private router: Router) {}
}

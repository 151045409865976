<form class="settings-form" #settingsForm="ngForm" (change)="onSubmit()">
  <p *ngIf="marker">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'common.latitude' | translate }}</mat-label>
      <input
        matInput
        readonly
        placeholder="{{ 'common.latitude' | translate }}"
        name="latitude"
        ngDefaultControl
        [value]="marker.getLatLng().lat"
      />
      <mat-icon matPrefix="">location_on</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'common.longitude' | translate }}</mat-label>
      <input
        matInput
        readonly
        placeholder="{{ 'common.longitude' | translate }}"
        name="longitude"
        ngDefaultControl
        [value]="marker.getLatLng().lng"
      />
      <mat-icon matPrefix="">location_on</mat-icon>
    </mat-form-field>
  </p>
  <mat-form-field class="narrow" appearance="outline">
    <mat-label>{{ 'common.altitude' | translate }}</mat-label>
    <input
      matInput
      placeholder="{{ 'common.altitude' | translate }}"
      name="altitude"
      (change)="onSubmit()"
      [(ngModel)]="settings.altitude"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    />
    <mat-hint>{{ 'flights.createMission.altHint' | translate }}</mat-hint>
    <mat-slider
      thumbLabel
      tickInterval="1"
      min="1"
      max="200"
      step="1"
      name="altitude"
      (change)="onSubmit()"
      [(ngModel)]="settings.altitude"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    >
    </mat-slider>
  </mat-form-field>
  <mat-form-field class="narrow" appearance="outline">
    <mat-label>{{ 'common.speed' | translate }}</mat-label>
    <input
      matInput
      placeholder="{{ 'common.speed' | translate }}"
      name="speed"
      (change)="onSubmit()"
      [(ngModel)]="settings.speed"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    />
    <mat-hint>{{ 'flights.createMission.speedHint' | translate }}</mat-hint>
    <mat-slider
      thumbLabel
      tickInterval="1"
      min="1"
      max="15"
      step="1"
      name="speed"
      (change)="onSubmit()"
      [(ngModel)]="settings.speed"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    >
    </mat-slider>
  </mat-form-field>
  <mat-form-field class="narrow" appearance="outline">
    <mat-label>{{ 'common.heading' | translate }}</mat-label>
    <input
      matInput
      placeholder="{{ 'common.heading' | translate }}"
      name="heading"
      (change)="onSubmit()"
      [(ngModel)]="settings.heading"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    />
    <mat-hint>{{ 'flights.createMission.headingHint' | translate }}</mat-hint>
    <mat-slider
      thumbLabel
      tickInterval="1"
      min="0"
      max="359"
      step="1"
      name="heading"
      (change)="onSubmit()"
      [(ngModel)]="settings.heading"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    >
    </mat-slider>
  </mat-form-field>
  <mat-form-field class="narrow" appearance="outline">
    <mat-label>{{ 'common.pitch' | translate }}</mat-label>
    <input
      matInput
      placeholder="{{ 'common.pitch' | translate }}"
      name="pitch"
      (change)="onSubmit()"
      [(ngModel)]="settings.pitch"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    />
    <mat-hint>{{ 'flights.createMission.pitchHint' | translate }}</mat-hint>
    <mat-slider
      thumbLabel
      tickInterval="1"
      min="-90"
      max="0"
      step="1"
      name="pitch"
      (change)="onSubmit()"
      [(ngModel)]="settings.pitch"
      ngDefaultControl
      uaDisabledFormControl="{{ aclPermissions.MissionsApiCreate }}"
    >
    </mat-slider>
  </mat-form-field>
</form>

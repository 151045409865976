<div class="mobile-menu" snScrollSpy id="menu">
  <mat-progress-bar mode="indeterminate" *ngIf="isUserManagementLoading$ | async"></mat-progress-bar>
  <a
    class="mobile-menu-item"
    [routerLink]="['/secure/profile/']"
    id="menu-usage"
    fragment="usage"
    snScrollSpyItem
    for="menu"
    href="#usage"
  >
    <div>{{ 'profile.headers.usage' | translate }}</div>
  </a>
  <ng-container *uaHasPermission="aclPermissions.MainApiReadPlan">
    <a
      class="mobile-menu-item"
      [routerLink]="['/secure/profile/']"
      id="menu-plan"
      fragment="plan"
      snScrollSpyItem
      for="menu"
      href="#plan"
    >
      <div>{{ 'profile.headers.plan' | translate }}</div>
    </a>
  </ng-container>
  <a
    class="mobile-menu-item"
    [routerLink]="['/secure/profile/']"
    id="menu-devices"
    fragment="devices"
    snScrollSpyItem
    for="menu"
    href="#devices"
  >
    <div>{{ 'profile.headers.devices' | translate }}</div>
  </a>
  <a
    class="mobile-menu-item"
    [routerLink]="['/secure/profile/']"
    id="menu-streaming"
    fragment="streaming"
    snScrollSpyItem
    for="menu"
    href="#streaming"
  >
    <div>{{ 'profile.headers.streaming' | translate }}</div>
  </a>
  <a
    class="mobile-menu-item"
    [routerLink]="['/secure/profile/']"
    id="menu-profile"
    fragment="profile"
    snScrollSpyItem
    for="menu"
    href="#profile"
  >
    <div>{{ 'profile.headers.profile' | translate }}</div>
  </a>
  <a
    class="mobile-menu-item"
    [routerLink]="['/secure/profile/']"
    id="menu-organisation"
    fragment="organisation"
    snScrollSpyItem
    for="menu"
    href="#organisation"
  >
    <div>{{ 'profile.headers.organization' | translate }}</div>
  </a>
  <ng-container *uaHasPermission="aclPermissions.OrganizationApiGetAdminOrganizationData">
    <a
      class="mobile-menu-item"
      [routerLink]="['/secure/profile/']"
      id="menu-users"
      fragment="users"
      snScrollSpyItem
      for="menu"
      href="#users"
    >
      <div>{{ 'profile.headers.users' | translate }}</div>
    </a>
    <a
      class="mobile-menu-item"
      [routerLink]="['/secure/profile/']"
      id="menu-teams"
      fragment="teams"
      snScrollSpyItem
      for="menu"
      href="#teams"
    >
      <div>Teams</div>
    </a>
  </ng-container>
  <ng-container *uaHasPermission="aclPermissions.AccountApiUlAdmin">
    <a
      class="mobile-menu-item"
      [routerLink]="['/secure/profile/']"
      id="menu-data-privacy"
      fragment="data-privacy"
      snScrollSpyItem
      for="menu"
      href="#data-privacy"
    >
      <div>{{ 'profile.headers.dataPrivacy' | translate }}</div>
    </a>
  </ng-container>

  <ng-container *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany">
    <a
      class="mobile-menu-item"
      [routerLink]="['/secure/profile/']"
      id="menu-authorization"
      fragment="authorization"
      snScrollSpyItem
      for="menu"
      href="#authorization"
    >
      <div>{{ 'profile.headers.authorization' | translate }}</div>
    </a>
  </ng-container>
  <ng-container *uaHasPermission="aclPermissions.AddonApiCliAccess">
    <a
      class="mobile-menu-item"
      [routerLink]="['/secure/profile/']"
      id="menu-developer"
      fragment="developer"
      snScrollSpyItem
      for="menu"
      href="#developer"
    >
      <div>{{ 'profile.headers.developer' | translate }}</div>
    </a>
  </ng-container>
</div>

<div class="container">
  <div>
    <div class="title-container">
      <div class="user-info">
        <!-- <div class="user-name">{{user?.name}}</div> -->
        <div class="company-name">
          {{ company?.name }} <span class="user-email">{{ (user$ | async)?.email }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <mat-panel-title id="usage" appChangeCurrentAnchor (visibleChange)="changeAnchor('usage')">
          <h3 class="title">{{ 'profile.headers.usage' | translate }}</h3>
        </mat-panel-title>
        <app-usage
          *ngIf="user$ | async as user; else showSpinnerTemplate"
          [user]="user"
          [isAwsUser]="isAwsUser$ | async"
        ></app-usage>
        <ng-container *uaHasPermission="aclPermissions.MainApiReadPlan">
          <mat-divider></mat-divider>
          <mat-panel-title id="plan" appChangeCurrentAnchor (visibleChange)="changeAnchor('plan')">
            <h3 class="title">{{ 'profile.headers.package' | translate }}</h3>
          </mat-panel-title>
          <ng-container *ngIf="userPlan$ | async as userPlan; else showSpinnerTemplate">
            <ng-container *ngIf="userPlan">
              <ng-container *ngIf="userPlan.reseller; else miniPlanPackagesTemplate">
                <app-plan *ngIf="user$ | async as user; else showSpinnerTemplate" [user]="user"></app-plan>
              </ng-container>
              <ng-template #miniPlanPackagesTemplate>
                <unleash-mini-plan-viewer
                  [user]="user$ | async"
                  [userPlan]="userPlan$ | async"
                  [isAwsUser]="isAwsUser$ | async"
                ></unleash-mini-plan-viewer>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
        <mat-divider></mat-divider>
        <ng-container *uaHasPermission="aclPermissions.MainApiReadDevice">
          <mat-panel-title id="devices" appChangeCurrentAnchor (visibleChange)="changeAnchor('devices')">
            <h3 class="title">{{ 'profile.headers.devices' | translate }}</h3>
          </mat-panel-title>
          <app-device *ngIf="myDevices$ | async as myDevices; else showSpinnerTemplate"></app-device>
        </ng-container>
        <mat-divider></mat-divider>
        <mat-panel-title id="streaming" appChangeCurrentAnchor (visibleChange)="changeAnchor('streaming')">
          <h3 class="title">{{ 'profile.headers.streaming' | translate }}</h3>
        </mat-panel-title>
        <app-streaming
          *ngIf="!!company && (user$ | async); else showSpinnerTemplate"
          [company]="company"
          [user]="user$ | async"
        ></app-streaming>
        <mat-divider></mat-divider>
        <mat-panel-title id="profile" appChangeCurrentAnchor (visibleChange)="changeAnchor('profile')">
          <h3 class="title">{{ 'profile.headers.profile' | translate }}</h3>
        </mat-panel-title>
        <app-user-profile
          #profileComponent
          *ngIf="user$ | async as user; else showSpinnerTemplate"
          [user]="user"
        ></app-user-profile>
        <mat-divider></mat-divider>
        <mat-panel-title id="organisation" appChangeCurrentAnchor (visibleChange)="changeAnchor('organisation')">
          <h3 class="title">{{ 'profile.headers.organization' | translate }}</h3>
        </mat-panel-title>
        <app-organisation
          *ngIf="!!company && (user$ | async); else showSpinnerTemplate"
          [company]="company"
          [dateFormatOptions]="dateFormatOptions"
          [user]="user$ | async"
        ></app-organisation>

        <ng-container *uaHasPermission="aclPermissions.AccountApiUlAdmin">
          <mat-divider></mat-divider>
          <mat-panel-title id="teams" appChangeCurrentAnchor (visibleChange)="changeAnchor('teams')">
            <h3 class="title">{{ 'profile.headers.dataPrivacy' | translate }}</h3>
          </mat-panel-title>
          <unleash-export-data
            [dataRangeOptions]="dataRangeExportOptions"
            [typeOptions]="typeExportOptions"
            (requestExport)="requestExport($event)"
          ></unleash-export-data>
        </ng-container>
        <!-- [teams]="teams$ | async" -->
        <!-- <app-data-privacy *ngIf="!!user; else showSpinnerTemplate"></app-data-privacy> -->
        <ng-container *uaHasPermission="aclPermissions.OrganizationApiGetAdminOrganizationData">
          <mat-divider></mat-divider>
          <mat-panel-title id="users" appChangeCurrentAnchor (visibleChange)="changeAnchor('users')">
            <h3 class="title">{{ 'profile.headers.users' | translate }}</h3>
          </mat-panel-title>
          <ng-container *ngIf="usersTable$ | async as userPlan; else showSpinnerTemplate">
            <unleash-users-tile
              [form]="userSearchForm"
              [users]="usersTable$ | async"
              [menuItems]="usersMenuActions$ | async"
              [hasMoreThanOneAdmin]="hasMoreThanOneAdmin$ | async"
              [roles]="roles$ | async"
              [teams]="teams$ | async"
              [userScreen]="userScreen$ | async"
              [selectedUser]="selectedUser$ | async"
              [currentTeamId]="currentTeamId$ | async"
              [isAddUserMode]="false"
              (openInviteUsersDialog)="openInviteUsersDialog()"
              (cancel)="displayUsersView()"
              (invite)="addUsers($event)"
              (searchUser)="filterUsers($event)"
              (back)="displayUsersView()"
              (user)="selectUser($event)"
              (action)="executeUserAction($event)"
            ></unleash-users-tile>
          </ng-container>
        </ng-container>
        <!-- 

        <unleash-user-teams-tile
          [selectedUser]="selectedUser$ | async"
          [userTeams]="userTeams$ | async"
          [selectedUserTeam]="selectedUserTeam$ | async"
          [menuItems]="userTeamsMenuActions"
          [userTeamsScreen]="userTeamsScreen$ | async"
          [roles]="roles$ | async"
          (addUsers)="displayAddUserToTeamsView()"
          (invite)="addUserToTeam($event)"
          (cancel)="displayUserTeamsView()"
          (back)="displayUserTeamsView()"
          (searchTeam)="filterUserTeams($event)"
          (action)="executeUserTeamsAction($event)"
          (userTeam)="displayUserTeamDetailsView($event)"
          (userTeamAction)="executeUserTeamsAction($event)"
        ></unleash-user-teams-tile>
        <mat-panel-title>
          <h3 class="title">My {{ 'profile.headers.teams' | translate }}</h3>
        </mat-panel-title>
        <unleash-user-teams-tile
          [hasToDisplaySubtitle]="false"
          [selectedUser]="user$ | async"
          [userTeams]="userTeams$ | async"
          [selectedUserTeam]="selectedUserTeam$ | async"
          [menuItems]="userTeamsMenuActions"
          [userTeamsScreen]="userTeamsScreen$ | async"
          [roles]="roles$ | async"
          (addUsers)="displayAddUserToTeamsView()"
          (invite)="addUserToTeam($event)"
          (cancel)="displayUserTeamsView()"
          (back)="displayUserTeamsView()"
          (searchTeam)="filterUserTeams($event)"
          (action)="executeUserTeamsAction($event)"
          (userTeam)="displayUserTeamDetailsView($event)"
        ></unleash-user-teams-tile> -->
        <ng-container *uaHasPermission="aclPermissions.OrganizationApiGetAdminOrganizationData">
          <mat-divider></mat-divider>
          <mat-panel-title id="teams" appChangeCurrentAnchor (visibleChange)="changeAnchor('teams')">
            <h3 class="title">{{ 'profile.headers.teams' | translate }}</h3>
          </mat-panel-title>
          <unleash-teams-tile
            [userBySelectedTeam]="userBySelectedTeam$ | async"
            [teams]="teamsTable$ | async"
            [roles]="roles$ | async"
            [menuItems]="teamsMenuActions"
            [teamScreen]="teamScreen$ | async"
            [selectedTeam]="selectedTeam$ | async"
            (addTeam)="displayAddTeamsView()"
            (cancel)="displayTeamsView()"
            (createTeam)="addTeam($event)"
            (searchTeam)="filterTeams($event)"
            (back)="displayTeamsView()"
            (team)="selectTeam($event)"
            (openAddUsersToTeam)="openAddUsersToTeam()"
            (action)="executeTeamAction($event)"
            (userAction)="executeUserAction($event)"
            (userTeamAction)="executeUserTeamsAction($event)"
          ></unleash-teams-tile>
        </ng-container>
        <ng-container *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany">
          <mat-divider></mat-divider>
          <mat-panel-title id="authorization" appChangeCurrentAnchor (visibleChange)="changeAnchor('authorization')">
            <h3 class="title">{{ 'profile.headers.authorization' | translate }}</h3>
          </mat-panel-title>

          <label> {{ 'profile.authorization.label' | translate }}</label>

          <div class="authorization">
            <span
              class="disabled-toogle-label"
              [ngClass]="{
                disabled: isMfaEnabled$ | async,
                enabled: !(isMfaEnabled$ | async)
              }"
            >
              {{ 'profile.authorization.disabled' | translate }}
            </span>
            <mat-slide-toggle
              class="authorization__toggle"
              [checked]="isMfaEnabled$ | async"
              [companyId]="companyId$ | async"
              appEnableAuthorizationToggleChange
              color="primary"
            >
              {{ 'profile.authorization.enabled' | translate }}
            </mat-slide-toggle>
          </div>
        </ng-container>
        <ng-container *uaHasPermission="aclPermissions.AddonApiCliAccess">
          <mat-divider></mat-divider>
          <mat-panel-title id="developer" appChangeCurrentAnchor (visibleChange)="changeAnchor('developer')">
            <h3 class="title">{{ 'profile.headers.developerOptions' | translate }}</h3>
          </mat-panel-title>
          <app-developer
            *ngIf="user$ | async as user; else showSpinnerTemplate"
            [isActivating]="isActivating$ | async"
            [isDeveloperMode]="user.developer"
            (activateDeveloperMode)="activateDeveloperMode()"
            (turnOffDeveloperMode)="turnOffDeveloperMode()"
          ></app-developer>
        </ng-container>
        <mat-divider class="divider-sign-out-menu-item"></mat-divider>
        <a [routerLink]="['/auth/logout']" class="sign-out-menu-item">
          <mat-icon class="icon">exit_to_app</mat-icon>
          <div>{{ 'profile.headers.signOut' | translate }}</div>
        </a>
      </div>
    </div>
    <div class="white-space-bottom"></div>
    <div #bottom class="space-bottom"></div>
  </div>
</div>

<ng-template #showSpinnerTemplate>
  <mat-progress-spinner [diameter]="36" [color]="'accent'" [mode]="'indeterminate'"> </mat-progress-spinner>
</ng-template>

<div class="team-switcher__header">
  <mat-icon class="team-switcher__back-icon" mat-dialog-close="true" (click)="goBack()"> arrow_back</mat-icon>
  <span class="team-switcher__header-title">
    {{ 'core.sidebar.menu.changeTeam' | translate }}
  </span>
</div>
<ng-container *ngIf="(totalUserTeams$ | async) === 1 && (userRole$ | async) === teamRole.admin; else teamsMenuTemplate">
  <div
    class="team-switcher__button team-switcher__button--small"
    [class.team-switcher__active]="team.key === (selectedTeamId$ | async)"
    *ngFor="let team of teams$ | async | keyvalue"
  >
    <span class="team-switcher__select-option">
      <span
        class="team-switcher__select-name team-switcher__select-name--small"
        [class.selected]="team.key === (selectedTeamId$ | async)"
        >{{ team.value.name }}
        <span *ngIf="team.key === (selectedTeamId$ | async)">({{ team.value.role | roleName | async }})</span></span
      >
    </span>
    <span *ngIf="team.key === (selectedTeamId$ | async)"
      ><mat-icon class="team-switcher__check-icon">check</mat-icon></span
    >
  </div>
  <div class="team-switcher__one-team">
    <mat-icon class="team-switcher__one-team-icon" svgIcon="no-teams"></mat-icon>
    <div class="team-switcher__one-team-title">{{ 'core.sidebar.noTeamsTitle' | translate }}</div>
    <div class="team-switcher__one-team-description">{{ 'core.sidebar.noTeamsMessage' | translate }}</div>
    <button
      *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany"
      mat-menu-item
      color="primary"
      (click)="goToManageTeams()"
      class="team-switcher__one-team-action"
    >
      <mat-icon color="primary">groups</mat-icon>
      <span class="team-switcher__manage-teams"> {{ 'core.sidebar.menu.manageTeams' | translate }} </span>
    </button>
  </div>
</ng-container>
<ng-template #teamsMenuTemplate>
  <span class="team-switcher__menu-title">
    {{ 'core.sidebar.menu.changeTeam' | translate }}
  </span>
  <div
    [class.team-switcher__active]="team.key === (selectedTeamId$ | async)"
    class="team-switcher__button"
    *ngFor="let team of teams$ | async | keyvalue"
    mat-button
    (click)="switchCurrentTeam(team.key)"
    mat-dialog-close
  >
    <span class="team-switcher__select-option">
      <span class="team-switcher__select-name" [class.selected]="team.key === (selectedTeamId$ | async)"
        >{{ team.value.name }}
        <span *ngIf="team.key === (selectedTeamId$ | async)">({{ team.value.role | roleName | async }})</span></span
      >
    </span>
    <span *ngIf="team.key === (selectedTeamId$ | async)"
      ><mat-icon class="team-switcher__check-icon">check</mat-icon></span
    >
  </div>
  <mat-divider></mat-divider>
  <button
    *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany"
    mat-menu-item
    color="primary"
    (click)="goToManageTeams()"
    class="team-switcher__manage-teams"
  >
    <mat-icon color="primary">groups</mat-icon>
    <span> {{ 'core.sidebar.menu.manageTeams' | translate }} </span>
  </button>
</ng-template>

export const AUTH_KEY = 'AUTH';
export const AWS_KEY = 'AWS_AUTH_KEY';
export const PROMO_CODE = 'PROMO_CODE';
export const REGISTRATION_STAGE = 'REGISTRATION_STAGE';

export enum MFA {
  TEXT_MESSAGE,
  TOTP
}

export enum MFARequiredStates {
  NEEDS_SETUP = '1',
  TOTP = '2',
  SMS = '3',
  TOTP_SMS = '4',
  SMS_TOTP = '5'
}

export enum MFA_SETUP_STATE {
  SETUP,
  VERIFY_TOTP,
  VERIFY_SMS,
  SETUP_SMS,
  CONFIRM_MOBILE_NUMBER,
  CONFIRM_TOTP
}

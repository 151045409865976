import {Component, ChangeDetectionStrategy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'unleash-layer-not-found-dialog',
  templateUrl: './layer-not-found-dialog.component.html',
  styleUrls: ['./layer-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerNotFoundDialogComponent {
  constructor(private dialogRef: MatDialogRef<LayerNotFoundDialogComponent>) {}

  public close(): void {
    this.dialogRef.close();
  }
}

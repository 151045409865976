<div mat-dialog-title>
  <span class="text-4--bold">Delete {{ name$ | async }}</span>
</div>

<ng-container *ngTemplateOutlet="(hasActiveUsers$ | async) ? hasActiveUsers : hasNoActiveUsers"></ng-container>

<ng-template #hasActiveUsers>
  <p>If you proceed, {{ name$ | async }} will be deleted on the {{ expirationDate$ | async | date: 'mediumDate' }}.</p>
  <p>{{ name$ | async }}’s users will be notified immediately so they are able to organize a replacement solution.</p>
  <p>This action cannot be undone. Are you sure?</p>
</ng-template>

<ng-template #hasNoActiveUsers>
  If you proceed, {{ name$ | async }} will be deleted immediately.<br />
  This action cannot be undone. Are you sure?
</ng-template>

<div mat-dialog-actions>
  <button mat-button type="button" color="primary" (click)="cancel()">Cancel</button>
  <button mat-raised-button color="warn" type="button" (click)="save()">Delete {{ name$ | async }}</button>
</div>

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasHeaderService, AtlasHeaderStatus} from '../services/atlas-header.service';
import {AtlasSelectMarkersSettingsService} from '../services/atlas-select-markers-settings.service';
import {AtlasSelectMarkersService} from '../services/atlas-select-markers.service';
import {AtlasService} from '../services/atlas.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Map} from 'leaflet';
import {atlasConfig} from '../atlas.config';
import {MarkerClusterService} from '../services/marker-cluster.service';

@Directive({
  selector: '[ulEditJobSelection]'
})
export class EditJobSelectionDirective {
  @Input('ulEditJobSelection') public jobName: string;
  @Input() public baseAsset: AtlasAssetModel;
  @Input() public polygonAsset: AtlasAssetModel;
  @Input() public map: Map;

  constructor(
    private atlasHeaderService: AtlasHeaderService,
    private atlasSelectMarkersSettingsService: AtlasSelectMarkersSettingsService,
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private atlasService: AtlasService,
    private markerClusterService: MarkerClusterService
  ) {}

  @HostListener('click', ['$event'])
  public enableEditMode(): void {
    const polygonLayer = (this.polygonAsset.leafletLayer as any).getLayers();
    this.atlasHeaderService.setEditJobLayerTitle(this.jobName);
    this.atlasHeaderService.setHeaderStatus(AtlasHeaderStatus.EDIT_JOB_SHAPE);
    this.atlasSelectMarkersSettingsService.showSelectOptions();
    this.atlasSelectMarkersService.setEditingShape(polygonLayer, this.polygonAsset);
    this.atlasSelectMarkersService.setCurrentLayer(this.baseAsset);
    this.atlasSelectMarkersService.addDrawnItems(polygonLayer);
    this.atlasSelectMarkersService.listenEditEvents(this.map, polygonLayer);
    this.atlasSelectMarkersService.enableEditFeature(this.map);
    polygonLayer.forEach(layer => {
      this.atlasSelectMarkersService.verifyMarkersInsideBox(layer);
    });
    this.displaySelectionLayersOnly();
    this.markerClusterService.enableClustering();
    this.map.fitBounds(this.polygonAsset.bounds, atlasConfig.FIT_BOUNDS_OPTIONS);
  }

  public displaySelectionLayersOnly() {
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = this.baseAsset.id === asset.id || this.polygonAsset.id === asset.id;
      asset.isHighlighted = false;
    });
    this.atlasService.toogleHasToDetectChanges();
  }
}

import {Component} from '@angular/core';
import {BannerConfig} from './banner-config';
import {GROW_DOWN_ANIMATION} from './banner.animation';
import {BannerService} from './banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [GROW_DOWN_ANIMATION]
})
export class BannerComponent {
  public isOpen: boolean;
  public message: string;
  public actions: string[];

  constructor(private bannerService: BannerService) {
    this.bannerService.setConfig.subscribe((config: BannerConfig) => {
      if (config) {
        this.message = config.message;
        this.actions = config.actions;
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    });
  }

  public actionClicked(index: number): void {
    this.bannerService.actionSelected.next(index);
  }
}

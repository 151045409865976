import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {map, takeWhile, timer} from 'rxjs';

@Component({
  selector: 'unleash-continue-watching',
  templateUrl: './continue-watching.component.html',
  styleUrls: ['./continue-watching.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContinueWatchingComponent {
  public timer$ = timer(0, 1000).pipe(
    map(elapsedTime => 6000 - (elapsedTime + 1) * 1000),
    takeWhile(remainingTime => remainingTime >= 0)
  );

  constructor(
    private snackBarRef: MatSnackBarRef<ContinueWatchingComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: {duration: number; deviceName: string}
  ) {}

  public continueWatching(): void {
    this.snackBarRef.dismissWithAction();
  }
}

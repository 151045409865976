export interface Filters {
  search: string;
  dateFrom: string;
  hourFrom: string;
  dateTo: string;
  hourTo: string;
  type: string;
  device: string;
  analysis: string;
}

export enum DatePriority {
  'DATE_AND_TIME',
  'UNIX_TIMESTAMP'
}

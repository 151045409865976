import {Component, OnDestroy, OnInit} from '@angular/core';
import {CalibrationSidebarEmptyComponent} from './components/calibration-sidebar-empty/calibration-sidebar-empty.component';
import {AsyncPipe, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {CalibrationAnnotationComponent} from './components/calibration-annotation/calibration-annotation.component';
import {LabelColorName} from '../image-annotation-shared/models/colors';
import {UnleashMaterialSharedModule} from '../unleash-material/unleash-material-shared.module';
import {CalibrationService, CalibrationSidebarMode} from './services/calibration.service';
import {Observable, combineLatest, Subscription, skip, startWith, switchMap, take, timer} from 'rxjs';
import {CalibrationSidebarListComponent} from './components/calibration-sidebar-list/calibration-sidebar-list.component';
import {CalibrationSidebarAddComponent} from './components/calibration-sidebar-add/calibration-sidebar-add.component';
import {CalibrationPerspectiveComponent} from './components/calibration-perspective/calibration-perspective.component';
import {EditCalibrationMarkerComponent} from './components/edit-calibration-marker/edit-calibration-marker.component';

@Component({
  selector: 'ua-calibration',
  templateUrl: './calibration.component.html',
  styleUrls: ['./calibration.component.scss'],
  standalone: true,
  imports: [
    CalibrationSidebarEmptyComponent,
    NgSwitchCase,
    NgSwitch,
    NgIf,
    AsyncPipe,
    CalibrationAnnotationComponent,
    UnleashMaterialSharedModule,
    CalibrationSidebarListComponent,
    CalibrationSidebarAddComponent,
    CalibrationPerspectiveComponent,
    EditCalibrationMarkerComponent
  ]
})
export class CalibrationComponent implements OnInit, OnDestroy {
  public calibrationLayout = CalibrationLayout;

  public color = LabelColorName.green;

  public currentLayout$ = this.calibrationService.currentLayout$;

  public currentCalibrationSidebarMode$ = this.calibrationService.calibrationSidebarMode$;
  public calibrationSidebarMode = CalibrationSidebarMode;
  private calibrationSidebarModeSub: Subscription;

  constructor(private calibrationService: CalibrationService) {}

  public ngOnInit(): void {
    this.calibrationService.pointsLatLng$.pipe(skip(1), take(1)).subscribe(pointsLatLng => {
      if (pointsLatLng.length > 0) {
        this.calibrationService.setCurrentLayout(CalibrationLayout.list);
      }
    });

    combineLatest([this.calibrationService.pointsLatLng$, this.calibrationService.loadCalibrationPoints$])
      .pipe(skip(1), take(1))
      .subscribe(([pointsLatLng, loadCalibrationPoints]) => {
        if (pointsLatLng.length > 0 || loadCalibrationPoints.length > 0) {
          this.calibrationService.setCurrentLayout(CalibrationLayout.list);
          return;
        }
        this.calibrationService.setCurrentLayout(CalibrationLayout.empty);
      });
  }

  public ngOnDestroy(): void {
    if (this.calibrationSidebarModeSub) {
      this.calibrationSidebarModeSub.unsubscribe();
      this.calibrationSidebarModeSub = null;
    }
    this.calibrationService.clearAllReferences();
    this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.POINT_LIST);
  }

  public setupColor(color: LabelColorName): void {
    this.color = color;
  }
}

export enum CalibrationLayout {
  empty = 'empty',
  addCalibration = 'addCalibration',
  list = 'list',
  addPerspectiveCalibration = 'addPerspectiveCalibration'
}

export enum CalibrationType {
  perspective = 'perspective',
  sceneMapping = 'scene mapping'
}

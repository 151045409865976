import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {DeviceService} from '@app/core/services/api/device.service';
import {UserService} from '@app/core/services/api/user.service';
import {PlansService} from '@app/plans/services/plans.service';
import {BehaviorSubject, filter, map, Subscription, take} from 'rxjs';
import {THUMBLER_AVAILABLE_CONFIGS} from '../../../shared/pipes/models/thumbler.model';
import {DeviceDialog} from '../device-dialog/device-dialog.component';
import {AdditionalDeviceDialogComponent} from './additional-device-dialog/additional-device-dialog.component';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {TeamRole} from '@app/profile/models/team.model';
import {AclService} from '@app/core/services/acl.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceComponent implements OnInit {
  // eslint-disable-next-line rxjs/finnish
  public myDevices$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject<UserDeviceJoined[]>([]);
  // eslint-disable-next-line rxjs/finnish
  public firstDataSource$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject<UserDeviceJoined[]>([]);
  public streamKey: string;
  public displayedColumns: string[] = [];
  public isShowingAllDevices = false;
  public showAllDevicesText = '+ SHOW MORE';
  public modeThen4Devices = true;
  public deviceLimit = 0;
  public isReachedDeviceLimit = false;
  public THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;
  // eslint-disable-next-line no-magic-numbers
  public MAX_DEVICES_DISPLAYED_DEVICES = 4;
  public aclPermissions = AclPermissions;

  private userSubscription: Subscription;
  private plansServiceSubscription: Subscription;
  private userDevicesSub: Subscription;
  private deviceDialogCloseSub: Subscription;

  constructor(
    public dialog: MatDialog,
    public deviceService: DeviceService,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private plansService: PlansService,
    private translateService: TranslateService,
    private aclService: AclService
  ) {
    this.translateService
      .get('profile.devices.showMore')
      .subscribe(res => (this.showAllDevicesText = res.toUpperCase()));
  }

  public ngOnInit(): void {
    this.userDevicesSub = this.userService.userDevices$.subscribe(userDevices => {
      this.setDevices(Object.values(userDevices));
    });
    this.userSubscription = this.userService.user$.subscribe(user => {
      this.streamKey = user.streamKey;
      this.aclService.hasSetupPermission$
        .pipe(
          filter(hasSetupPermission => !!hasSetupPermission),
          take(1)
        )
        .subscribe(() => {
          this.displayedColumns = this.aclService.hasPermission(this.aclPermissions.LivestreamApiStreamPublish)
            ? ['device', 'streamUrl', 'manage']
            : ['device', 'streamUrl'];
        });
    });
    this.plansServiceSubscription = this.plansService.userPlan$.subscribe(userPlan => {
      if (!!userPlan) {
        this.deviceLimit = userPlan.maxDevices;
        this.isReachedDeviceLimit = this.myDevices$ && this.myDevices$.value.length >= this.deviceLimit;
      }
    });
  }

  public dispatchOpenDeviceModal($event: any): void {
    $event.stopPropagation();
    if (!this.isReachedDeviceLimit) {
      this.showDeviceDialog();
    } else {
      this.dialog
        .open(AdditionalDeviceDialogComponent, {
          width: '80vw',
          maxWidth: '800px',
          panelClass: 'profile-device-dialog'
        } as MatDialogConfig)
        .afterClosed()
        .subscribe(result => {
          console.info('clicked', result);
          if (result) {
            this.showDeviceDialog();
          }
        });
    }
  }

  public showDeviceDialog(device?: UserDeviceJoined): void {
    this.dialog.open(DeviceDialog, {
      width: '80vw',
      maxWidth: '800px',
      data: device || {},
      panelClass: 'profile-device-dialog',
      restoreFocus: false
    } as MatDialogConfig);
  }

  public onStreamUrlCopied(): void {
    this.translateService
      .get('profile.devices.streamUrlCopied')
      .pipe(take(1))
      .subscribe(res => this.snackBar.open(res, null, {duration: 5000} as MatSnackBarConfig));
  }

  public showCollapseDevices(): void {
    this.isShowingAllDevices = !this.isShowingAllDevices;
  }

  private setDevices(myDevices: UserDeviceJoined[]): void {
    if (!!myDevices) {
      this.myDevices$.next(myDevices.sort(this.sortByUpdatedAt.bind(this)));
      this.modeThen4Devices = this.myDevices$.value.length > this.MAX_DEVICES_DISPLAYED_DEVICES;
      this.firstDataSource$.next(myDevices.slice(0, this.MAX_DEVICES_DISPLAYED_DEVICES));
      const devicesLeft = myDevices.length - this.firstDataSource$.value.length;
      this.translateService
        .get('profile.devices.showXMore', {value: devicesLeft})
        .subscribe(res => (this.showAllDevicesText = devicesLeft > 0 ? `+ ${res.toUpperCase()}` : ''));
      this.isReachedDeviceLimit = this.myDevices$ && this.myDevices$.value.length >= this.deviceLimit;
    }
  }

  private sortByUpdatedAt(deviceOne: UserDeviceJoined, deviceTwo: UserDeviceJoined) {
    if (deviceOne['updatedAt'] > deviceTwo['updatedAt']) {
      return -1;
    }
    if (deviceOne['updatedAt'] < deviceTwo['updatedAt']) {
      return 1;
    }
    return 0;
  }
}

export enum ExportDateRange {
  past24Hours = 'past24Hours',
  past3Days = 'past3Days',
  pastWeek = 'pastWeek',
  pastMonth = 'pastMonth'
}

export enum ExportType {
  aiAppConfiguration = 'aiAppConfiguration',
  aiAppNotifications = 'aiAppNotifications',
  devices = 'devices',
  errors = 'errors',
  media = 'media',
  userActivity = 'userActivity',
  zones = 'zones'
}

export enum teamExport {
  allTeams = 'allTeams'
}

export interface ExportDataOption<T extends ExportDateRange | ExportType | teamExport | string> {
  id: T;
  name: string;
}

export interface ExportData {
  dateRange: ExportDateRange;
  type: ExportType;
  /* team: teamExport | string; */
}

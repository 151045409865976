import {environment} from 'environments/environment';

export function getCognitoIdentityValue(attribute: string): string {
  const userEmail =
    window.localStorage[
      Object.keys(window.localStorage).find(t =>
        t.startsWith(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`)
      )
    ];
  const cookieValue =
    window.localStorage[
      Object.keys(window.localStorage).find(t =>
        t.startsWith(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.${attribute}`)
      )
    ];
  return cookieValue;
}

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TriggerPoint} from './trigger-point.model';

@Component({
  selector: 'unleash-trigger-point',
  templateUrl: './trigger-point.component.html',
  styleUrls: ['./trigger-point.component.scss'],
  imports: [CommonModule, MatTooltipModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TriggerPointComponent {
  @Input() public triggerPoint: TriggerPoint;
  @Input() public isEmptyMode: boolean = false;
  @Output() public setTriggerPoint = new EventEmitter<TriggerPoint>();
  @Output() public hoverBlock = new EventEmitter<TriggerPoint>();

  public blocks = [
    {id: 1, name: TriggerPoint.topLeft},
    {id: 2, name: TriggerPoint.topCenter},
    {id: 3, name: TriggerPoint.topRight},
    {id: 4, name: TriggerPoint.left},
    {id: 5, name: TriggerPoint.center},
    {id: 6, name: TriggerPoint.right},
    {id: 7, name: TriggerPoint.bottomLeft},
    {id: 8, name: TriggerPoint.bottomCenter},
    {id: 9, name: TriggerPoint.bottomRight}
  ];

  public selectBlock(block: {id: number; name: TriggerPoint}) {
    if (this.isEmptyMode) {
      this.triggerPoint = block.name;
      this.setTriggerPoint.emit(block.name);
    }
  }

  public emitHoverTriggerPoint(block: {id: number; name: TriggerPoint}) {
    this.hoverBlock.next(block.name);
  }

  public emitHoverTriggerPointDefault() {
    this.hoverBlock.next(this.triggerPoint);
  }
}

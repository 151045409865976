import {Component, OnInit, ChangeDetectionStrategy, forwardRef, Output, EventEmitter, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'unleash-logic-operator-button',
  templateUrl: './logic-operator-button.component.html',
  styleUrls: ['./logic-operator-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LogicOperatorButtonComponent),
      multi: true
    }
  ]
})
export class LogicOperatorButtonComponent implements OnInit, ControlValueAccessor {
  logicOperators = ['OR', 'AND'];

  @Input() isParentFormValid: boolean = false;
  @Output() changeOperator: EventEmitter<any> = new EventEmitter();

  value = null;

  constructor() {}

  ngOnInit(): void {}

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  setLogicOperator(value: string): void {
    if (value === this.value) {
      value = null;
      this.onChange(value);
      this.changeOperator.emit();
    }
    this.onChange(value);
    this.changeOperator.emit();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

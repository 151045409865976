import {icon} from 'leaflet';

export const createIcon = (selected = false, size = 20, droneIconColor = '#e53935') => {
  const customSvg = `<?xml version="1.0" encoding="iso-8859-1"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg height='${size}' width='${size}' fill-width="10" stroke-width='15' stroke='${droneIconColor}' id='drone-svg' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 511.001 511.001" style="enable-background:new 0 0 511.001 511.001;" xml:space="preserve" >
 <defs>
   <filter height="200%" width="200%" y="-50%" x="-50%" id="blur">
    <feGaussianBlur stdDeviation="9.5" in="SourceGraphic"/>
   </filter>
 </defs>
<g>
    <path filter="url(#blur)" stroke="#ffffff" opacity="1" transform="rotate(-45 281.02890014648443,12.651786804199192) " id="svg_14" d="m159.351966,100.328737l0,-183.353901l183.35388,183.353901l-183.35388,0z" stroke-width="0" fill="#FFFA8D"/>
	<path d="M87.5,416c-1.98,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.32,2.2,5.3,2.2
		c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C91.41,416.8,89.47,416,87.5,416z"/>
	<path d="M498.197,338.197l-58.372,58.372C435.061,393.671,429.472,392,423.5,392c-6.236,0-12.05,1.83-16.947,4.969
		c-0.362-1.011-0.94-1.962-1.749-2.771l-80-80c-0.369-0.369-0.767-0.691-1.186-0.967l5.316-90.368
		c1.418-0.274,2.772-0.961,3.87-2.059l104-104c0.389-0.389,0.723-0.812,1.008-1.257c0.684-0.351,1.359-0.718,2.013-1.116
		l58.372,58.372c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.677,0-10.606l-58.372-58.372
		C453.33,99.061,455,93.472,455,87.5c0-17.369-14.131-31.5-31.5-31.5c-5.972,0-11.561,1.671-16.325,4.569L348.804,2.197
		c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.677,0,10.606l58.372,58.372C393.671,75.939,392,81.528,392,87.5
		c0,1.328,0.092,2.634,0.252,3.919l-75.103,52.572c-2.053-2.897-4.311-5.683-6.798-8.321C295.959,120.406,276.479,112,255.5,112
		s-40.459,8.406-54.851,23.67c-2.487,2.638-4.745,5.423-6.798,8.321l-75.103-52.572c0.16-1.285,0.252-2.591,0.252-3.919
		c0-5.972-1.671-11.561-4.569-16.325l58.372-58.372c2.929-2.929,2.929-7.677,0-10.606c-2.929-2.929-7.678-2.929-10.606,0
		l-58.372,58.372C99.061,57.671,93.472,56,87.5,56C70.131,56,56,70.131,56,87.5c0,5.972,1.671,11.561,4.569,16.325L2.197,162.197
		c-2.929,2.929-2.929,7.677,0,10.606C3.661,174.268,5.581,175,7.5,175s3.839-0.732,5.303-2.197l58.372-58.372
		c0.654,0.398,1.329,0.765,2.013,1.116c0.286,0.445,0.619,0.868,1.008,1.257l104,104c1.098,1.098,2.452,1.785,3.87,2.059
		l5.316,90.368c-0.419,0.276-0.817,0.598-1.186,0.967l-80,80c-0.81,0.81-1.387,1.761-1.749,2.771C99.55,393.83,93.737,392,87.5,392
		c-5.972,0-11.561,1.671-16.325,4.569l-58.372-58.372c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.678,0,10.606
		l58.372,58.372C57.671,411.939,56,417.528,56,423.5c0,17.369,14.131,31.5,31.5,31.5c5.972,0,11.561-1.67,16.325-4.568
		l58.372,58.372c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.678,0-10.606
		l-56.872-56.873l91.45-49.242c6.363,4.364,14.047,6.917,22.292,6.917h51.654c8.245,0,15.929-2.553,22.292-6.917l91.45,49.242
		l-56.872,56.873c-2.929,2.929-2.929,7.678,0,10.606c1.464,1.465,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197l58.372-58.372
		C411.939,453.33,417.528,455,423.5,455c17.369,0,31.5-14.131,31.5-31.5c0-5.972-1.671-11.561-4.569-16.324l58.372-58.372
		c2.929-2.929,2.929-7.678,0-10.606C505.875,335.269,501.126,335.269,498.197,338.197z M394.197,404.804
		c0.81,0.81,1.761,1.387,2.772,1.749c-2.995,4.673-4.789,10.182-4.947,16.095l-77.516-41.739c3.591-5.549,5.84-12.06,6.254-19.088
		l1.692-28.762L394.197,404.804z M423.5,71c9.098,0,16.5,7.402,16.5,16.5c0,9.099-7.402,16.5-16.5,16.5S407,96.599,407,87.5
		C407,78.402,414.402,71,423.5,71z M397.879,105.79c4.057,5.667,9.94,9.936,16.786,11.94l-84.518,84.518l0.614-10.432
		c0.715-12.152-1.444-23.959-6.178-34.718L397.879,105.79z M211.563,145.961c1.419-1.505,2.901-2.925,4.438-4.261v17.798
		c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V135.5c0-1.086-0.236-2.116-0.651-3.048C238.12,128.89,246.639,127,255.5,127
		s17.38,1.89,25.151,5.452c-0.416,0.932-0.651,1.962-0.651,3.048v23.998c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V141.7
		c1.537,1.336,3.019,2.756,4.438,4.261c11.529,12.227,17.334,28.199,16.347,44.975l-10,170.003
		c-0.464,7.892-4.651,14.736-10.785,18.875V191.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v192c0,0.169,0.014,0.334,0.025,0.5
		h-49.05c0.011-0.166,0.025-0.331,0.025-0.5v-192c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v188.314
		c-6.134-4.139-10.32-10.983-10.785-18.875l-10-170.003C194.228,174.16,200.034,158.188,211.563,145.961z M87.5,71
		c9.098,0,16.5,7.402,16.5,16.5c0,9.099-7.402,16.5-16.5,16.5S71,96.599,71,87.5C71,78.402,78.402,71,87.5,71z M96.336,117.73
		c6.846-2.004,12.729-6.273,16.786-11.94l73.297,51.308c-4.734,10.759-6.893,22.566-6.178,34.718l0.614,10.432L96.336,117.73z
		 M188.55,333.058l1.692,28.762c0.414,7.028,2.662,13.54,6.253,19.088l-77.516,41.739c-0.158-5.913-1.952-11.422-4.947-16.095
		c1.011-0.362,1.962-0.939,2.772-1.749L188.55,333.058z M87.5,440c-9.098,0-16.5-7.401-16.5-16.5c0-9.098,7.402-16.5,16.5-16.5
		s16.5,7.402,16.5,16.5C104,432.599,96.598,440,87.5,440z M423.5,440c-9.098,0-16.5-7.401-16.5-16.5c0-9.098,7.402-16.5,16.5-16.5
		s16.5,7.402,16.5,16.5C440,432.599,432.598,440,423.5,440z"/>
	<path d="M423.5,416c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2
		c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C427.41,416.8,425.47,416,423.5,416z"/>
	<path d="M423.5,95c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3c-1.39-1.4-3.33-2.2-5.3-2.2
		c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3C419.59,94.2,421.53,95,423.5,95z"/>
	<path d="M87.5,95c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3c-1.39-1.4-3.33-2.2-5.3-2.2
		c-1.98,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3C83.59,94.2,85.52,95,87.5,95z"/>
	<path d="M255.5,367c4.142,0,7.5-3.357,7.5-7.5v-24c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v24
		C248,363.643,251.358,367,255.5,367z"/>
</g>
</svg>`;
  const svgAsURI = 'data:image/svg+xml;base64,' + btoa(customSvg);

  let className = 'drone-icon';
  if (selected) { className += ' icon-selected'; }
  return icon({
    iconUrl: svgAsURI,
    iconSize: [size, size],
    className
  });
};

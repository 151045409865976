import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {I18nService} from '../i18n/i18n.service';

@Component({
  selector: 'unleash-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit {
  selectedLanguage: {code: string; name: string};

  languageOptions: {code: string; name: string}[];

  constructor(private i18nService: I18nService) {
    this.languageOptions = i18nService.languages;
    this.selectedLanguage = this.languageOptions.find(option => option.code === i18nService.getUserLanguage());
  }

  ngOnInit(): void {}

  setLanguage() {
    this.i18nService.setUserLanguage(this.selectedLanguage.code);
  }
}

import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {DOCUMENT, Location} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {Router} from '@angular/router';
import {selectAuthPlanActive} from '@app/auth/state/auth.selectors';
import {Auth} from '@aws-amplify/auth';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {PackageNames, PlanModel, PlansByPackageName, PlansByPlanName} from '@app/core/models/api/plan.model';
import {UserService} from '@app/core/services/api/user.service';
import {LayoutCheckerService} from '@app/core/services/layout-checker.service';
import {NotificationService} from '@app/core/services/notifications/notification.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {BillingComponent} from '@app/plans/components/billing/billing.component';
import {PreventLoseChangesComponent} from '@app/plans/components/prevent-lose-changes-dialog/prevent-lose-changes.component';
import {PaymentParameters, PaymentService} from '@app/plans/services/payment.service';
import {PlansService} from '@app/plans/services/plans.service';
import {ActionName} from '@app/shared/plans/plan-card/plan-card.component';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {BehaviorSubject, combineLatest, Observable, Subscription, filter, switchMap, take, tap, map} from 'rxjs';
import {actionResetPackageSelector} from '@app/plans/store/package-selector.actions';
import {selectCombinedPlan, selectPlansSelected} from '@app/plans/store/package-selector.selectors';
import {PLAN_TRIAL} from '@app/plans/models/plan-trial.model';
import {PACKAGE_SELECTOR_STEP_INDEX, PLAN_STEP_INDEX} from '@app/plans/models/package-selector-steps.model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {UserModel} from '@app/core/models/api/user-model';
import {HelpService} from '@app/core/services/help.service';
import {PackageSelectorStoreFacadeService} from '@app/plans/services/package-selector-store-facade.service';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './package-selector.page.html',
  styleUrls: ['./package-selector.page.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false}
    }
  ]
})
export class PackageSelectorPage implements OnInit, OnDestroy {
  @ViewChild('stepper', {read: MatStepper, static: false}) public stepper: MatStepper;
  @ViewChild('billing', {read: BillingComponent, static: false})
  public billing: BillingComponent;
  public paymentFormGroup = this.fb.group({
    paymentPeriod: [PaymentPeriod.month, Validators.required],
    paymentMethod: ['new', Validators.required],
    stripeToken: ['', Validators.required]
  });

  public liveStreamOptions: PlansByPlanName;
  public aiOptions: PlansByPlanName;
  public mappingOptions: PlansByPlanName;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public PaymentPeriodType = PaymentPeriod;
  public isSamePackage$: Observable<boolean> = this.packageSelectorStoreFacadeService.selectIsSamePackage$;

  // eslint-disable-next-line rxjs/finnish
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // eslint-disable-next-line rxjs/finnish
  private isBannerActionLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public isLoading$: Observable<boolean> = this.isLoading.asObservable();
  public isBannerActionLoading$: Observable<boolean> = this.isBannerActionLoading.asObservable();

  public cardType = CardType;
  public plansByPackages: PlansByPackageName;
  public plans: PlanModel[];

  public cardInfo: InfoCard = {
    name: 'Need more?',
    backgroundImage: '/assets/icons/plans/question.svg',
    buttonName: 'CONTACT US',
    description:
      'If you don’t see a plan that covers what you need, get in touch with our experts to discuss your use case.'
  };

  public openHub;

  // eslint-disable-next-line rxjs/finnish
  private steps: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>([]);
  public steps$: Observable<Step[]> = this.steps.asObservable().pipe(
    map(steps =>
      steps.map(step => {
        const formGroup = this.fb.group({selectedCardId: step.formGroup.selectedCardId});
        formGroup.patchValue(step.formGroup);
        return {...step, formGroup: formGroup};
      })
    )
  );
  public plansByStep$ = this.packageSelectorStoreFacadeService.plansByStep$;
  public checkoutPlans$ = this.packageSelectorStoreFacadeService.checkoutPlans$;

  public hasProfessionPlanSelected$ = this.packageSelectorStoreFacadeService.hasProfessionalPlanSelected$;
  public totalPayment$: Observable<number> = this.packageSelectorStoreFacadeService.totalPayment$;
  public firstProfessionalStepIndex$: Observable<number> =
    this.packageSelectorStoreFacadeService.firstProfessionalStepIndex$;

  public userPlanId: string;
  public userPlanPeriod: PaymentPeriod;
  public stripeToken: string;

  public selectedPlans$: Observable<PlanModel[]> = this.store.pipe(select(selectPlansSelected));
  public combinedPlan$: Observable<PlanModel>;
  public combinedPlanValue: PlanModel;

  public combinedPlanSub: Subscription;
  public selectedPlansByStep: Subscription;
  public plansByStep: Partial<{
    [PLAN_STEP_INDEX.FIRST]: PlanModel;
    [PLAN_STEP_INDEX.SECOND]: PlanModel;
    [PLAN_STEP_INDEX.THIRD]: PlanModel;
  }>;

  private checkOutPlansStore: {[key: string]: PlanModel} = {};
  private paymentPeriodSub: Subscription;

  constructor(
    private plansService: PlansService,
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private dialog: MatDialog,
    private store: Store,
    private userService: UserService,
    private layoutCheckerService: LayoutCheckerService,
    private unleashAnalyticsService: UnleashAnalyticsService,
    private location: Location,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private helpService: HelpService,
    private packageSelectorStoreFacadeService: PackageSelectorStoreFacadeService
  ) {
    this.layoutCheckerService.setIsShowFooterNav(false);
    this.combinedPlanSub = this.store.pipe(select(selectCombinedPlan)).subscribe(plan => {
      this.combinedPlanValue = plan;
    });
    this.combinedPlan$ = this.store.pipe(select(selectCombinedPlan));
  }

  public ngOnDestroy(): void {
    this.layoutCheckerService.setIsShowFooterNav(true);
  }

  public ngOnInit(): void {
    this.store.dispatch(actionResetPackageSelector());
    this.store
      .pipe(
        select(selectAuthPlanActive),
        switchMap(() =>
          combineLatest([
            this.plansService.userPlan$,
            this.plansService.plansByPackage,
            this.plansService.plans,
            this.userService.user$
          ])
        ),
        take(1)
      )
      .subscribe(([userPlan, plansByPackage, plans, user]) => {
        this.plans = Object.values(plans);
        this.packageSelectorStoreFacadeService.setPlans(this.plans);
        this.userPlanId = userPlan?.id || 'unleashed_1';
        this.userPlanPeriod = user.subscriptionPeriod;

        this.setupPackagesOptions(plansByPackage);

        const packages = this.getPackagesFromPlan(this.userPlanId);
        this.packageSelectorStoreFacadeService.setCombinedPlan(this.userPlanId);

        const liveStreamStep: Step = {
          formGroup: {
            selectedCardId: ''
          },
          label: PackageNames.broadcast,
          cardType: CardType.liveStream,
          options: this.liveStreamOptions,
          formControlName: 'selectedCardId',
          backgroundImage: '/assets/icons/plans/camera.svg'
        };

        const aiStep: Step = {
          formGroup: {
            selectedCardId: ''
          },
          label: PackageNames.insights,
          cardType: CardType.ai,
          options: this.aiOptions,
          formControlName: 'selectedCardId',
          backgroundImage: '/assets/icons/plans/vector_graphic.svg'
        };

        const mappingStep: Step = {
          formGroup: {
            selectedCardId: ''
          },
          label: PackageNames.modelling,
          cardType: CardType.mapping,
          options: this.mappingOptions,
          formControlName: 'selectedCardId',
          backgroundImage: '/assets/icons/plans/location.svg'
        };

        let steps = [liveStreamStep, aiStep, mappingStep];

        const packagesNameList = Object.keys(packages) as PackageNames[];
        const cardTypeList = packagesNameList.map(packageName => this.getCardTypeByPackageName(packageName));

        const orderSteps = [];
        const unOrderSteps = [];

        steps.forEach(step => {
          if (cardTypeList.some(cardType => cardType === step.cardType)) {
            orderSteps.push(step);
          } else {
            unOrderSteps.push(step);
          }
        });

        steps = orderSteps.concat(unOrderSteps);
        this.steps.next(steps);

        steps.forEach((step, stepIndex) => {
          const planId = packages[step.label];
          if (!planId) {
            this.packageSelectorStoreFacadeService.setPlanOnStep(stepIndex, null);
            return;
          }
          this.packageSelectorStoreFacadeService.setPlanOnStep(stepIndex, planId);
        });
        this.isLoading.next(false);
      });

    this.paymentPeriodSub = this.paymentFormGroup.controls.paymentPeriod.valueChanges.subscribe(paymentPeriod =>
      this.packageSelectorStoreFacadeService.setPaymentPeriod(paymentPeriod)
    );
  }

  public selectedCard(
    event: {plansByPlanName: PlanModel; actionName: ActionName},
    cardType: CardType,
    stepIndex: number
  ) {
    const newPlanId = event?.plansByPlanName?.id;

    switch (event.actionName) {
      case ActionName.REMOVE:
        this.packageSelectorStoreFacadeService.removePlanOnStep(stepIndex);
        break;
      case ActionName.ADD:
        this.packageSelectorStoreFacadeService.setPlanOnStep(stepIndex, newPlanId);
        break;
      case ActionName.UPGRADE:
        this.packageSelectorStoreFacadeService.upgradePlanOnStep(stepIndex);
        break;
      case ActionName.DOWNGRADE:
        this.packageSelectorStoreFacadeService.downgradePlanOnStep(stepIndex);
        break;
    }
  }

  public handleBannerAction(action: string) {
    switch (action) {
      case 'action':
        this.continue();
        break;
      case 'simple':
        this.cancel();
        break;
      default:
        break;
    }
  }

  public cancel() {
    if (this.router.url.includes('secure')) {
      this.router.navigate(['/secure/profile']);
    } else {
      this.location.back();
    }
  }

  public continue() {
    this.userService.user$.pipe(take(1)).subscribe(user => {
      if (this.stepper.selectedIndex === PACKAGE_SELECTOR_STEP_INDEX.MODELLING_AND_GIS && !this.combinedPlanValue) {
        this.notificationService.error('You have to select at least one package');
        return;
      }

      if (this.stepper.selectedIndex === PACKAGE_SELECTOR_STEP_INDEX.BILLING) {
        const hasPastTrial: boolean = !user.trialPeriodEnd || new Date().getTime() > user.trialPeriodEnd;
        const hasTrial =
          !!user.currentPlan && hasPastTrial && user.stripeSubscription === PLAN_TRIAL.UNLEASH_TRIAL_SUBSCRIPTION;

        if (
          this.combinedPlanValue.id === this.userPlanId &&
          !hasTrial &&
          this.userPlanPeriod === this.paymentFormGroup.controls.paymentPeriod.value
        ) {
          this.stepper.linear = false;
          this.stepper.selectedIndex = PACKAGE_SELECTOR_STEP_INDEX.DONE;
          this.paymentFormGroup.controls.paymentPeriod.markAsPristine();
          return;
        }
        this.billing.paymentCard.generateCardToken();
        this.stepper.next();
        this.packageSelectorStoreFacadeService.setCurrentStep(this.stepper.selectedIndex);
        return;
      }

      if (this.stepper.selectedIndex === PACKAGE_SELECTOR_STEP_INDEX.CHECKOUT) {
        this.isBannerActionLoading.next(true);
        this.subscribeUser(this.paymentFormGroup.controls.stripeToken.value);
        this.paymentFormGroup.controls.paymentPeriod.markAsPristine();
        return;
      }

      this.document.body.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
      this.stepper.next();
    });
  }

  public setStripeToken(stripeToken: any) {
    this.paymentFormGroup.controls.stripeToken.setValue(stripeToken);
    this.stepper.next();
  }

  public navigateTo() {
    this.router.navigate(['/secure/profile']);
  }

  public navigateToIsSamePackage() {
    if (this.router.url.includes('secure')) {
      this.router.navigate(['/secure/profile']);
    } else {
      this.location.back();
    }
  }

  public goToContactUs() {
    this.helpService.goToContactUs();
  }

  public openPreventLoseChangesDialog() {
    const dialogRef = this.dialog.open(PreventLoseChangesComponent, {
      width: '80vw',
      maxWidth: '800px'
    });
    return dialogRef.afterClosed();
  }

  public subscribeUser(token: any) {
    const stripeToken = this.paymentFormGroup.controls.stripeToken.value as any;
    const period =
      this.paymentFormGroup.controls.paymentPeriod.value === PaymentPeriod.month
        ? PaymentPeriod.month
        : PaymentPeriod.annual;
    const selectedPlan = this.combinedPlanValue;
    Auth.currentAuthenticatedUser().then(user => {
      const stripeEmail = user.email;
      const subscriptionRequestPayload: PaymentParameters = {
        stripeToken: stripeToken.id,
        stripeEmail,
        planId: selectedPlan.id,
        period
      };
      if (!!stripeToken.promoCode) {
        subscriptionRequestPayload.couponCode = stripeToken.promoCode;
      }
      this.paymentService
        .createUserSubscription(subscriptionRequestPayload)
        .pipe(
          switchMap(() => this.userService.findUser()),
          take(1),
          tap((user: UserModel) => this.userService.updateUser({...user, currentPlan: selectedPlan.id}))
        )
        .toPromise()
        .then(() => {
          this.unleashAnalyticsService.logEvent(EVENTS.PLAN_SUBSCRIPTION, {
            planId: selectedPlan.id,
            userEmail: user.email
          });
          this.isBannerActionLoading.next(false);
          this.stepper.next();
        })
        .catch(err => {
          this.notificationService.error(err.message);
          this.isBannerActionLoading.next(false);
        });
    });
  }

  public updateCurrentStep(): void {
    this.packageSelectorStoreFacadeService.setCurrentStep(this.stepper.selectedIndex + 1);
  }

  private getPackagesFromPlan(planId: string) {
    const [name, level] = planId.split('_');
    const packages = {};
    switch (name) {
      case 'operational':
        packages[PackageNames.broadcast] = `broadcast_${level}`;
        packages[PackageNames.modelling] = `modelling_${level}`;
        packages[PackageNames.modelling] = `modelling_${level}`;
        break;
      case 'control':
        packages[PackageNames.modelling] = `modelling_${level}`;
        packages[PackageNames.insights] = `insights_${level}`;
        break;
      case 'supervision':
        packages[PackageNames.broadcast] = `broadcast_${level}`;
        packages[PackageNames.insights] = `insights_${level}`;
        break;
      case 'unleashed':
        packages[PackageNames.broadcast] = `broadcast_${level}`;
        packages[PackageNames.modelling] = `modelling_${level}`;
        packages[PackageNames.insights] = `insights_${level}`;
        break;
      case 'broadcast':
        packages[PackageNames.broadcast] = planId;
        break;
      case 'insights':
        packages[PackageNames.insights] = planId;
        break;
      case 'modelling':
        packages[PackageNames.modelling] = planId;
        break;
    }
    return packages;
  }

  private setupPackagesOptions(plansByPackage: PlansByPackageName) {
    this.plansByPackages = plansByPackage;

    if (Object.values(plansByPackage).length === 0) {
      this.liveStreamOptions = this.plansByPackages[PackageNames.broadcast];
      this.aiOptions = this.plansByPackages[PackageNames.insights];
      this.mappingOptions = this.plansByPackages[PackageNames.modelling];
      return;
    }

    delete this.plansByPackages[PackageNames.broadcast].Enterprise;
    this.liveStreamOptions = this.plansByPackages[PackageNames.broadcast];

    delete this.plansByPackages[PackageNames.insights].Enterprise;
    this.aiOptions = this.plansByPackages[PackageNames.insights];

    delete this.plansByPackages[PackageNames.modelling].Enterprise;
    this.mappingOptions = this.plansByPackages[PackageNames.modelling];
  }

  private getCardTypeByPackageName(packageName: PackageNames) {
    switch (packageName) {
      case PackageNames.operational:
      case PackageNames.supervision:
      case PackageNames.unleashed:
      case PackageNames.broadcast:
        return CardType.liveStream;
      case PackageNames.insights:
        return CardType.ai;
      case PackageNames.control:
      case PackageNames.modelling:
        return CardType.mapping;
    }
  }
}

export enum CardType {
  liveStream = 'live-stream',
  ai = 'ai',
  mapping = 'mapping'
}

interface Step {
  formGroup: any;
  label: string;
  cardType: CardType;
  formControlName: string;
  backgroundImage: string;
  options: PlansByPlanName;
}

interface InfoCard {
  name: string;
  backgroundImage: string;
  buttonName: string;
  description: string;
}

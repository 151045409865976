<div class="container" [ngClass]="{floating: isTextOnTop}">
  <div class="background-image-container">
    <img class="background-image" [src]="backgroundImage" />
  </div>
  <div class="content">
    <div class="name content-item">{{ name }}</div>
    <div class="description content-item">{{ description }}</div>
    <div class="buttons content-item">
      <button class="button" color="primary" (click)="clickOnAction($event)" mat-button>{{ buttonName }}</button>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {authLogout} from '@app/auth/state/auth.actions';
import {selectAwsMarketplaceToken} from '@app/auth/state/auth.selectors';
import {select, Store} from '@ngrx/store';
import {
  actionConfirmRegistrationFormSubmit,
  actionConfirmRegistrationReSendCode
} from '@app/auth/components/confirm-registration/confirm-registration.actions';
import {ConfirmRegistration} from '@app/auth/components/confirm-registration/confirm-registration.model';
import {
  selectConfirmRegistrationError,
  selectConfirmRegistrationFormValue,
  selectConfirmRegistrationInProgress,
  selectConfirmRegistrationResendInProgress,
  selectIsDisplayResendAlert
} from '@app/auth/components/confirm-registration/confirm-registration.selectors';
import {IsUserAuthOnboardingGuard} from '@app/auth/guards/is-auth-onboarding.guard';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {combineLatest, Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';

@Component({
  templateUrl: './confirm-registration.page.html',
  styleUrls: ['./confirm-registration.page.scss']
})
export class ConfirmRegistrationPage {
  public isDisplayResendAlert$: Observable<boolean>;
  public confirmationEmail$: Observable<string>;
  public confirmRegistration$: Observable<ConfirmRegistration>;

  public isInProgress$: Observable<boolean>;
  public isResendInProgress$: Observable<boolean>;
  public errorMessage$: Observable<AuthErrorModel>;
  public hasMarketPlaceToken$: Observable<boolean>;
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;

  constructor(
    private store: Store,
    private isUserAuthOnboardingGuard: IsUserAuthOnboardingGuard,
    private route: ActivatedRoute
  ) {
    this.isDisplayResendAlert$ = this.store.pipe(select(selectIsDisplayResendAlert));
    this.confirmRegistration$ = this.store.pipe(select(selectConfirmRegistrationFormValue));
    this.confirmationEmail$ = this.confirmRegistration$.pipe(
      pluck('email'),
      map(email => {
        if (!email) {
          this.signOut();
        }

        return email;
      })
    );

    this.isInProgress$ = combineLatest([
      this.store.pipe(select(selectConfirmRegistrationInProgress)),
      this.isUserAuthOnboardingGuard.isLoading$
    ]).pipe(map(([confirmIsInProgress, isGuardLoading]) => confirmIsInProgress || isGuardLoading));
    this.isResendInProgress$ = this.store.pipe(select(selectConfirmRegistrationResendInProgress));
    this.errorMessage$ = this.store.pipe(select(selectConfirmRegistrationError));
    this.hasMarketPlaceToken$ = this.store.pipe(
      select(selectAwsMarketplaceToken),
      map(token => !!token)
    );
  }

  public confirm(confirmRegistrationForm: ConfirmRegistration) {
    this.store.dispatch(
      actionConfirmRegistrationFormSubmit({form: confirmRegistrationForm, isDeveloperMode: this.isDeveloperMode})
    );
  }

  reSendCode(event: Pick<ConfirmRegistration, 'email'>) {
    this.store.dispatch(actionConfirmRegistrationReSendCode({form: {email: event.email}}));
  }

  signOut() {
    this.store.dispatch(authLogout({isDeveloperMode: this.isDeveloperMode}));
  }
}

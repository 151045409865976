import {PlanModel} from '@app/core/models/api/plan.model';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {createAction, props} from '@ngrx/store';

export enum PackageSelectorActionTypes {
  ResetPackageSelector = '[Package Selector] Reset Package Selector',

  RemovePlanFromSelection = '[Package Selector] Remove plan from selection',
  CancelSubscription = '[Plans] CancelSubscription',

  SetPlans = '[Package Selector] Set plans',
  clearSelectedPlans = '[Package Selector] Clear all selected plans',

  SetCombinedPlan = '[Package Selector] Set combined plan',

  SetTotalPayment = '[Package Selector] Set total payment',
  SetCurrentStep = '[Package Selector] Set current step',
  SetPaymentPeriod = '[Package Selector] Set payment period',
  SetFirstProfessionalStepIndex = '[Package Selector] Set first professional step index',

  DisableEssentialSelection = '[Package Selector] Disable essential selection',
  EnableEssentialSelection = '[Package Selector] Enable essential selection',

  SetPlanOnStep = '[Package Selector] Set plan on step',
  RemovePlanOnStep = '[Package Selector] Remove plan on step',
  UpgradePlanOnStep = '[Package Selector] Upgrade plan on step',
  DowngradePlanOnStep = '[Package Selector] Downgrade plan on step',

  SetIsSamePackage = '[Package Selector] Set is samePackage'
}

export const actionResetPackageSelector = createAction(PackageSelectorActionTypes.ResetPackageSelector);

export const cancelSubscription = createAction(PackageSelectorActionTypes.CancelSubscription);

export const setPlans = createAction(PackageSelectorActionTypes.SetPlans, props<{payload: {plans: PlanModel[]}}>());
export const clearSelectedPlans = createAction(PackageSelectorActionTypes.clearSelectedPlans);

export const setCombinedPlan = createAction(
  PackageSelectorActionTypes.SetCombinedPlan,
  props<{payload: {planId: PlanModel['id']}}>()
);

export const setPlanOnStep = createAction(
  PackageSelectorActionTypes.SetPlanOnStep,
  props<{payload: {stepIndex: number; planId: PlanModel['id']}}>()
);

export const removePlanOnStep = createAction(
  PackageSelectorActionTypes.RemovePlanOnStep,
  props<{payload: {stepIndex: number}}>()
);

export const upgradePlanOnStep = createAction(
  PackageSelectorActionTypes.UpgradePlanOnStep,
  props<{payload: {stepIndex: number}}>()
);

export const downgradePlanOnStep = createAction(
  PackageSelectorActionTypes.DowngradePlanOnStep,
  props<{payload: {stepIndex: number}}>()
);

export const setTotalPayment = createAction(
  PackageSelectorActionTypes.SetTotalPayment,
  props<{payload: {totalPayment: number}}>()
);

export const setCurrentStep = createAction(
  PackageSelectorActionTypes.SetCurrentStep,
  props<{payload: {currentStep: number}}>()
);

export const setPaymentPeriod = createAction(
  PackageSelectorActionTypes.SetPaymentPeriod,
  props<{payload: {paymentPeriod: PaymentPeriod}}>()
);

export const setFirstProfessionalStepIndex = createAction(
  PackageSelectorActionTypes.SetFirstProfessionalStepIndex,
  props<{payload: {firstProfessionalStepIndex: number}}>()
);

export const disableEssentialSelection = createAction(PackageSelectorActionTypes.DisableEssentialSelection);
export const enableEssentialSelection = createAction(PackageSelectorActionTypes.EnableEssentialSelection);

export const setIsSamePackage = createAction(
  PackageSelectorActionTypes.SetIsSamePackage,
  props<{payload: {isSamePackage: boolean}}>()
);

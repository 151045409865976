<div class="header-container" (click)="$event.stopPropagation()">
  <img src="assets/icons/atlas/book-open.png" alt="annotations icon" />
  <span>Annotations Details</span>
</div>
<form class="content-container" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()">
  <ng-content></ng-content>
</form>
<div class="footer-container" (click)="$event.stopPropagation()">
  <button
    class="min-width-option-btn"
    type="button"
    mat-flat-button
    aria-label="Cancel shape"
    (click)="emitCancelClick(); $event.stopPropagation()"
  >
    CANCEL
  </button>
  <button
    class="min-width-option-btn"
    type="button"
    mat-flat-button
    color="primary"
    aria-label="Done shape"
    (click)="emitDoneClick(); $event.stopPropagation()"
  >
    DONE
  </button>
</div>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {BannerComponent} from './banner/banner.component';

@NgModule({
  declarations: [BannerComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [BannerComponent]
})
export class BannerModule {}

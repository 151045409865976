import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {UploadMissionsComponent} from '../components/upload-missions/upload-missions.component';
import {filter, switchMap, take, tap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploadMissionsService} from '../services/upload-missions.service';

@Directive({
  selector: '[ulUploadMission]'
})
export class UploadMissionDirective {
  @Output() public isProcessingUpload: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private uploadMissionsService: UploadMissionsService
  ) {}

  @HostListener('click', ['$event'])
  public onUpload() {
    const dialog = this.dialog.open(UploadMissionsComponent, {
      ...STANDARD_DIALOG_CONFIG,
      width: '60vw'
    });
    dialog
      .afterClosed()
      .pipe(
        take(1),
        tap(() => {
          this.uploadMissionsService.setMissionsToUpload([]);
        }),
        filter(totalUploadedFiles => !!totalUploadedFiles),
        switchMap(totalUploadedFiles =>
          totalUploadedFiles === 1
            ? this.translateService.get('atlas.mission.uploadSingleMissionSuccess')
            : this.translateService.get('atlas.mission.uploadMissionSuccess', {value: totalUploadedFiles})
        )
      )
      .subscribe((translation: string) => {
        this.snackBar.open(translation, null, {panelClass: 'mission-uploaded', duration: 3000});
      });
  }
}

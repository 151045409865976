export enum CameraVideoStreamSource {
  DEFAULT = 0,
  WIDE = 1,
  ZOOM = 2,
  INFRARED_THERMAL = 3,
  NDVI_CAMERA = 4,
  VISION_CAMERA = 5,
  MS_G_CAMERA = 6,
  MS_R_CAMERA = 7,
  MS_RE_CAMERA = 8,
  MS_NIR_CAMERA = 9,
  RGB_CAMERA = 10,
  UNKNOWN = 255
}

export enum CameraSourceMenuItemNameMap {
  DEFAULT = 'FPV',
  WIDE = 'Wide',
  ZOOM = 'Zoom',
  INFRARED_THERMAL = 'Infrared / Thermal',
  NDVI_CAMERA = 'Multispectral NDVI',
  VISION_CAMERA = 'Multispectral Vision',
  MS_G_CAMERA = 'Multispectral G',
  MS_R_CAMERA = 'Multispectral R',
  MS_RE_CAMERA = 'Multispectral RE',
  MS_NIR_CAMERA = 'Multispectral NIR',
  RGB_CAMERA = 'Multispectral RGB',
  UNKNOWN = 'Unknown'
}

export enum CameraSourceNameButtonNameMap {
  DEFAULT = 'FPV',
  WIDE = 'WIDE',
  ZOOM = 'ZOOM',
  INFRARED_THERMAL = 'IR',
  NDVI_CAMERA = 'NDVI',
  VISION_CAMERA = 'VISION',
  MS_G_CAMERA = 'G',
  MS_R_CAMERA = 'R',
  MS_RE_CAMERA = 'RE',
  MS_NIR_CAMERA = 'NIR',
  RGB_CAMERA = 'RGB',
  UNKNOWN = 'UNKW'
}

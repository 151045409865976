<div class="calibration-perspective">
  <div class="calibration-perspective__header">
    <button (click)="emitBackToCalibrationMainView()" mat-icon-button class="calibration-perspective__back-button">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="calibration-perspective__title">{{ 'calibration.addCalibration' | translate }}</h2>
  </div>

  <mat-divider></mat-divider>

  <section class="calibration-perspective__body">
    <section class="calibration-perspective__details">
      <div class="calibration-perspective__details-section">
        <span class="calibration-perspective__details-label">{{ 'calibration.type' | translate }}</span>
        <span class="calibration-perspective__details-value">{{ 'calibration.perspective' | translate }}</span>
      </div>

      <div class="calibration-perspective__details-section">
        <span class="calibration-perspective__details-label">{{ 'calibration.colour' | translate }}</span>
        <span class="calibration-perspective__details-value">
          <unleash-color-picker
            [selectedColor]="selectedColor$ | async"
            [isLeftLayout]="true"
            (updateColorTag)="emitColor($event)"
          ></unleash-color-picker>
        </span>
      </div>
    </section>

    <section class="calibration-perspective__details">
      <span class="calibration-perspective__details-label">{{
        'live.manage-zones-dialog.details.dimensionsOfZone' | translate
      }}</span>

      <form [formGroup]="perspectiveForm" class="perspective-form">
        <mat-form-field appearance="fill" class="perspective-form__input">
          <input uaDecimalInputMask formControlName="a" type="text" placeholder="00.00" matInput />
          <span class="perspective-form__prefix" matPrefix>A</span>
          <span class="perspective-form__suffix" matSuffix>cm</span>
          <mat-error *ngIf="perspectiveForm.controls.a.invalid && perspectiveForm.controls.a.touched">{{
            'live.manage-zones-dialog.errors.invalidInputFormat' | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="perspective-form__input">
          <input uaDecimalInputMask formControlName="b" type="text" placeholder="00.00" matInput />
          <span class="perspective-form__prefix" matPrefix>B</span>
          <span class="perspective-form__suffix" matSuffix>cm</span>
          <mat-error *ngIf="perspectiveForm.controls.b.invalid && perspectiveForm.controls.b.touched">{{
            'live.manage-zones-dialog.errors.invalidInputFormat' | translate
          }}</mat-error>
        </mat-form-field>
      </form>
    </section>
  </section>

  <div class="calibration-perspective__actions">
    <button mat-button color="primary" class="calibration-perspective__cancel-button" (click)="emitCancel()">
      {{ 'calibration.cancel' | translate }}
    </button>
    <button
      [appLadda]="isSaving$ | async"
      [disabled]="isSaving$ | async"
      mat-raised-button
      color="primary"
      class="calibration-perspective__confirm-button"
      [mtxTooltip]="tooltipTpl"
      [mtxTooltipDisabled]="(calibrationShape$ | async).length === 4"
      (click)="emitForm()"
      (mouseover)="getClibrationShape()"
    >
      {{ 'calibration.addCalibration' | translate }}
    </button>
    <ng-template #tooltipTpl>
      <div class="tooltip-container">
        <span class="tooltip-container__text">{{ 'calibration.perspectiveTooltipTitle' | translate }}</span>
        <ul class="tooltip-container__list">
          <li>{{ 'calibration.perspectiveTooltipMessage' | translate }}</li>
        </ul>
      </div>
    </ng-template>
  </div>
</div>

<form [formGroup]="form">
  <mat-form-field class="form-field-zone" appearance="fill">
    <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.simpleRuleRow.areaOfInterest' | translate }}</mat-label>
    <mat-select formControlName="zone" (selectionChange)="emitFormChange()">
      <mat-option value="all-scene"> Whole scene </mat-option>
      <mat-option *ngFor="let zone of zones" [value]="zone.id">
        {{ zone.display_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-field-zone" appearance="fill">
    <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.simpleRuleRow.condition' | translate }}</mat-label>
    <mat-select formControlName="condition" (selectionChange)="emitFormChange()">
      <mat-option value="all-classes"> Count </mat-option>
      <mat-option *ngFor="let condition of conditions" [value]="condition.id">
        {{ condition.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-field-operator" appearance="fill">
    <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.simpleRuleRow.operator' | translate }}</mat-label>
    <mat-select formControlName="operator" (selectionChange)="emitFormChange()" #mSelect>
      <mat-select-trigger>
        {{ mSelect?.selected?.viewValue | slice: 0:1 }}
      </mat-select-trigger>
      <mat-option *ngFor="let operator of operators" [value]="operator.id">
        {{ operator.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="form-field-value" appearance="fill">
    <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.simpleRuleRow.value' | translate }}</mat-label>
    <input
      matInput
      type="number"
      min="0"
      placeholder="Value"
      formControlName="value"
      #valueInput
      numbersOnly
      (blur)="updateValueOnBlur('value', valueInput)"
    />
  </mat-form-field>
</form>

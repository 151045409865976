import {Rule, RuleItem} from '@app/shared/analysis-configuration/models/rule.model';
import {AddonZones, ZoneConfigData, ZonesConfigBackup} from '@app/shared/annotation-shared/models/annotations.model';
import {ImageSnapshot} from '@app/shared/manage-zones-dialog/model/manager-zones.model';
import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './manager-zones.actions';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';

export const managerZonesFeatureKey = 'manager-zones';

export interface ManagerZonesAnalyzeState {
  zonesConfigData: ZoneConfigData;
  zonesConfigBackup: any;
  isCanvasReady: boolean;
  imageSnapshot: ImageSnapshot;
  zones: Zone[];

  isRuleEditMode: boolean;
  rules: Rule[];
  selectedRule: RuleItem;
  ruleForm: Rule;
  isPerformingAnalysis: boolean;
  selectedZoneId: string;
  hint: string;
  isZonesLoading: boolean;
}

export const initialState: ManagerZonesAnalyzeState = {
  zonesConfigData: null,
  zonesConfigBackup: null,
  isCanvasReady: false,
  imageSnapshot: null,
  zones: [],

  isRuleEditMode: false,
  rules: [],
  selectedRule: null,
  ruleForm: null,
  isPerformingAnalysis: false,
  selectedZoneId: null,
  hint: '',
  isZonesLoading: true
};

export const reducer = createReducer(
  initialState,
  on(actions.setZonesConfigData, (state, {payload}: {payload: {zonesConfigData: ZoneConfigData}}) => ({
    ...state,
    zonesConfigData: payload.zonesConfigData
  })),
  on(actions.setZonesConfigBackup, (state, {payload}: {payload: {zonesConfigBackup: ZonesConfigBackup}}) => ({
    ...state,
    zonesConfigBackup: payload.zonesConfigBackup
  })),
  on(actions.updateCurrentZonesConfigBackup, (state, {payload}: {payload: {addonZones: AddonZones}}) => ({
    ...state,
    zonesConfigBackup: {
      ...state.zonesConfigBackup,
      [state.zonesConfigData.addonId]: payload.addonZones
    }
  })),
  on(actions.canvasIsReady, state => ({...state, isCanvasReady: true})),
  on(actions.canvasIsNotReady, state => ({...state, isCanvasReady: false})),
  on(actions.clearImageSnapshot, state => ({...state, imageSnapshot: null})),
  on(actions.setImageSnapshot, (state, {payload}: {payload: {imageSnapshot: ImageSnapshot}}) => ({
    ...state,
    imageSnapshot: payload.imageSnapshot
  })),
  on(actions.setZones, (state, {payload}: {payload: {zones: Zone[]}}) => ({...state, zones: payload.zones})),
  on(actions.clearZones, state => ({...state, zones: []})),
  on(actions.removeZone, (state, {payload}: {payload: {zone: Zone}}) => {
    const zones = state.zones.map(zone => {
      if (payload.zone.id === zone.id) {
        return {...zone, removed: true} as Zone;
      }

      return zone;
    });

    return {
      ...state,
      zones
    };
  }),
  on(actions.addZone, (state, {payload}: {payload: {zone: Zone}}) => {
    return {
      ...state,
      zones: [...state.zones, payload.zone]
    };
  }),
  on(actions.updateZoneName, (state, {payload}: {payload: {displayName: string; zoneId: string}}) => {
    const newZones = state.zones.map(zone => {
      if (zone.id === payload.zoneId) {
        // eslint-disable-next-line camelcase
        return {...zone, display_name: payload.displayName};
      }

      return zone;
    });

    return {...state, zones: newZones};
  }),
  on(actions.updateZone, (state, {payload}: {payload: {property: Partial<Zone>; zoneId: string}}) => {
    const zones = [...state.zones];
    const zoneIndex = zones.findIndex(zone => payload.zoneId === zone.id);

    if (zoneIndex !== -1) {
      zones[zoneIndex] = {...zones[zoneIndex], ...payload.property};
      return {...state, zones};
    }
    return state;
  }),
  on(actions.setSelectedZoneId, (state, {payload}: {payload: {zoneId: string}}) => {
    return {...state, selectedZoneId: payload.zoneId};
  }),
  on(actions.storePhysicalDimensions, (state, {payload}: {payload: {perspectiveValues: {a: number; b: number}}}) => {
    const zones = state.zones.map(zone => {
      if (zone.id === state.selectedZoneId) {
        return {
          ...zone,
          properties: {
            ...zone.properties,
            target_width: payload.perspectiveValues.a,
            target_height: payload.perspectiveValues.b
          }
        } as Zone;
      }
      return zone;
    });

    return {...state, zones};
  }),
  on(
    actions.updateCurrentColor,
    (
      state,
      {payload}: {payload: {color: {name: LabelColorName; background: LabelColorName; stroke: string; fill: string}}}
    ) => {
      const zones = state.zones.map(zone => {
        if (zone.id === state.selectedZoneId) {
          return {...zone, color: payload.color};
        }
        return zone;
      });

      return {...state, zones};
    }
  ),
  on(
    actions.updateCurrentColorByZone,
    (
      state,
      {
        payload
      }: {
        payload: {
          color: {name: LabelColorName; background: LabelColorName; stroke: string; fill: string};
          zone: Zone;
        };
      }
    ) => {
      const zones = state.zones.map(zone => {
        if (zone.id === payload.zone.id) {
          return {...zone, color: payload.color};
        }
        return zone;
      });

      return {...state, zones};
    }
  ),
  on(actions.storeDisplayName, (state, {payload}) => {
    const zones = state.zones.map(zone => {
      if (zone.id === state.selectedZoneId) {
        // eslint-disable-next-line camelcase
        return {...zone, display_name: payload.displayName};
      }
      return zone;
    });

    return {...state, zones};
  }),
  on(actions.setHint, (state, {payload}) => {
    return {...state, hint: payload.hint};
  }),
  on(actions.hideHint, state => ({...state, hint: ''})),
  on(actions.setTriggerPoint, (state, {payload}) => {
    const newZones = state.zones.map(zone => {
      if (zone.id === payload.zoneId) {
        return {
          ...zone,
          properties: {...zone.properties, bbox_anchor: payload.blockName, anchor_position: payload.blockName}
        };
      }
      return zone;
    });

    return {...state, zones: newZones};
  }),
  on(actions.setIsZonesLoading, (state, {payload}) => {
    return {...state, isZonesLoading: payload.isZonesLoading};
  })
);

export function managerZonesReducer(state: ManagerZonesAnalyzeState | undefined, action: Action) {
  return reducer(state, action);
}

<div *ngIf="!value" class="logic-operator__simple-connector"></div>
<div *ngIf="value" class="logic-operator__double-connector logic-operator__double-connector--up"></div>

<span class="logic-operator">
  <button mat-button class="logic-operator__button" [matMenuTriggerFor]="menu">
    <ng-container *ngIf="!!value; else emptyValueTemplate">
      <span class="logic-operator__text">{{ value }}</span> <mat-icon>arrow_drop_down</mat-icon>
    </ng-container>
    <ng-template #emptyValueTemplate>
      <div [class.logic-operator--empty]="!value">＋</div>
    </ng-template>
  </button>
  <mat-menu #menu="matMenu">
    <button
      [class.active]="logicOperator === value"
      *ngFor="let logicOperator of logicOperators"
      mat-menu-item
      [disabled]="!isParentFormValid"
      (click)="setLogicOperator(logicOperator)"
    >
      {{ logicOperator }}
    </button>
  </mat-menu>
</span>

<div *ngIf="value" class="logic-operator__double-connector logic-operator__double-connector--down"></div>

import {Injectable} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {LeafletEvent, Map, TileLayer, tileLayer, TileLayerOptions} from 'leaflet';
import {environment} from '../../../environments/environment';
import {atlasConfig} from '../atlas.config';
import {AbstractAssetLoaderService} from './abstract-asset-loader.service';

@Injectable({
  providedIn: 'root'
})
export class OrthomapAssetLoaderService extends AbstractAssetLoaderService {
  public async load(asset: AtlasAssetModel, map: Map): Promise<number> {
    const layer = await this.createLayer(asset);
    this.atlasService.handleDetectAssetChanges();
    layer.on('tileload', (event: LeafletEvent): void => this.tileSessionService.addTile(event, asset.id));
    super.addAsset(map, {
      layer,
      name: asset.name,
      bounds: asset.bounds,
      id: asset.id
    });
    return 1;
  }

  private createLayer(asset: AtlasAssetModel): TileLayer {
    if (!asset) {
      return;
    }
    return tileLayer(`${environment.MODELS_CF_CDN}/${asset.key}`, {
      tms: true,
      noWrap: true,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      bounds: asset.bounds,
      isModel: true
    } as TileLayerOptions);
  }
}

import {Injectable} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {AclService} from '@app/core/services/acl.service';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {AddonZones, ZoneConfig} from '@app/shared/annotation-shared/models/annotations.model';
import {ManageZoneService} from '@app/shared/annotation-shared/services/manage-zone.service';
import {CanvasService} from '@app/shared/image-annotation-shared/services/canvas.service';
import {ManagerZonesStoreFacadeService} from '@app/shared/manage-zones-dialog/services/manager-zones-store-facade.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest, filter, map, of, switchMap, take, tap, withLatestFrom, zip} from 'rxjs';
import * as actions from './manager-zones.actions';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {cloneDeep} from 'lodash';
import {ManagerZonesDialogService} from '@app/shared/manage-zones-dialog/services/manager-zones-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {DrawStatus} from '@app/shared/image-annotation-shared/models/image-annotation.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerZonesEffects {
  public watchDrawShapesOnCanvas$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.watchDrawShapesOnCanvas),
        switchMap(() =>
          combineLatest([
            this.managerZonesStoreFacadeService.zonesConfigData$,
            this.managerZonesStoreFacadeService.isCanvasReady$,
            this.managerZonesStoreFacadeService.imageSnapshot$
          ])
        ),
        filter(([data, isCanvasReady, imageSnapshot]) => isCanvasReady && !!data && !!imageSnapshot),
        take(1),
        tap(() => {
          this.canvasService.clearCanvasShape();
          this.managerZonesStoreFacadeService.clearZones();
          this.managerZonesStoreFacadeService.initZones();
        })
      ),
    {
      dispatch: false
    }
  );

  public initZones$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.initZones),
        switchMap(() =>
          zip(
            this.managerZonesStoreFacadeService.zonesConfigData$.pipe(take(1)),
            this.aclService.hasSetupPermission$.pipe(
              filter(hasSetupPermission => !!hasSetupPermission),
              map(() => this.aclService.hasPermission(AclPermissions.LibraryApiSaveAnnotations))
            ),
            this.addonStoreFacadeService.getIsAnalyticsEnabled()
          )
        ),
        tap(([zonesConfigData, hasSaveAnnotationPermissions, isAnalyticsEnabled]) => {
          const zonesConfig: ZoneConfig = cloneDeep(zonesConfigData.zonesConfig);

          let config: AddonZones = {zones: []};
          if (zonesConfig && zonesConfig[zonesConfigData.addonId]) {
            config = zonesConfig[zonesConfigData.addonId] as AddonZones;
          }

          let zones = this.manageZoneService.parseDbData(config, this.canvasService.getCanvasSize());
          zones = this.manageZoneService.setupPropertiesZones(zones);
          zonesConfig[zonesConfigData.addonId] = zones;

          const zonesToImport = this.manageZoneService.parseExcludedZones(zones.zones);
          this.managerZonesStoreFacadeService.setZones(zonesToImport);

          const {originalSize, labelConfigs} = this.manageZoneService.initAllZonesAndShapes(zonesToImport);
          this.canvasService.originalSize = originalSize;
          this.canvasService.loadShapes({
            labels: labelConfigs,
            hasToDisplayShapeLabels: false,
            isAnalyticsEnabled,
            hasToDrawShapeLabels: false,
            hasEditPermissions: hasSaveAnnotationPermissions
          });
        })
      ),
    {dispatch: false}
  );

  public prepareHint$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.prepareHint),
        withLatestFrom(this.canvasService.drawStatus$),
        switchMap(([{payload}, drawStatus]) =>
          zip(
            of(payload),
            of(drawStatus),
            this.translateService.get([
              'live.manage-zones-dialog.hints.perspectiveHintStartDraw',
              'live.manage-zones-dialog.hints.lineInOutStartDraw',
              'live.manage-zones-dialog.hints.lineInOutCompleteDraw',
              'live.manage-zones-dialog.hints.lineInOutEditDraw',
              'live.manage-zones-dialog.hints.polygonTwoPoints',
              'live.manage-zones-dialog.hints.polygonThreePoints',
              'live.manage-zones-dialog.hints.polygonMoreThanFourPoints',
              'live.manage-zones-dialog.hints.polygonEdit',
              'live.manage-zones-dialog.hints.generalDraw'
            ])
          )
        ),
        map(([payload, drawStatus, translations]) => {
          if (payload.shapeType === ShapeTypes.perspective) {
            const text = translations['live.manage-zones-dialog.hints.perspectiveHintStartDraw'];
            this.managerZonesStoreFacadeService.setHint(text);
          } else if (payload.shapeType === ShapeTypes.line_in_out) {
            let text = '';
            if (drawStatus === DrawStatus.draw) {
              const points = (this.canvasService.selectedTool.ref as any).array().value;
              const hasNoPoints = points[0][0] === 0 && points[1][0] === 0;
              if (hasNoPoints) {
                text = translations['live.manage-zones-dialog.hints.lineInOutStartDraw'];
              } else if (points[0][0] === points[1][0] && points[0][1] === points[1][1]) {
                text = translations['live.manage-zones-dialog.hints.lineInOutCompleteDraw'];
              } else {
                text = translations['live.manage-zones-dialog.hints.lineInOutEditDraw'];
              }
            }

            if (drawStatus === DrawStatus.edit && !payload.action) {
              text = translations['live.manage-zones-dialog.hints.lineInOutEditDraw'];
            }

            this.managerZonesStoreFacadeService.setHint(text);
          } else if (
            payload.shapeType.toLocaleLowerCase() === ShapeTypes.polygon ||
            payload.shapeType.toLocaleLowerCase() === ShapeTypes.polygon_excluded
          ) {
            let text = '';
            const points = (this.canvasService.selectedTool.ref as any).array().value.length - 1;

            if (drawStatus === DrawStatus.draw) {
              if (points === 2) {
                text = translations['live.manage-zones-dialog.hints.polygonTwoPoints'];
              } else if (points === 3) {
                text = translations['live.manage-zones-dialog.hints.polygonThreePoints'];
              } else if (points >= 4) {
                text = translations['live.manage-zones-dialog.hints.polygonMoreThanFourPoints'];
              }
            } else if (drawStatus === DrawStatus.edit) {
              text = translations['live.manage-zones-dialog.hints.polygonEdit'];
            }

            this.managerZonesStoreFacadeService.setHint(text);
          } else {
            this.managerZonesStoreFacadeService.setHint(translations['live.manage-zones-dialog.hints.generalDraw']);
          }
        })
      ),
    {dispatch: false}
  );

  public toggleIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.toggleIn),
        tap(() => {
          this.canvasService.toggleIn();
        })
      ),
    {dispatch: false}
  );
  public toggleOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.toggleOut),
        tap(() => {
          this.canvasService.toggleOut();
        })
      ),
    {dispatch: false}
  );

  public atLeastOneInOutPositionEnabled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.atLeastOneInOutPositionEnabled),
        switchMap(() => this.canvasService.atLeastOneInOutPositionEnabled$),
        tap(() => {
          this.translateService
            .get('live.manage-zones-dialog.atLeastOneInOutPositionEnabled')
            .pipe(take(1))
            .subscribe(text => {
              this.snackBar.open(text, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000
              });
            });
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private managerZonesStoreFacadeService: ManagerZonesStoreFacadeService,
    private managerZonesDialogService: ManagerZonesDialogService,
    private manageZoneService: ManageZoneService,
    private canvasService: CanvasService,
    private aclService: AclService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}
}

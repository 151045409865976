/* eslint-disable no-magic-numbers */
import {Injectable} from '@angular/core';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {LabelColor} from '@app/shared/image-annotation-shared/models/colors';
import {AVAILABLE_COLORS} from '@app/shared/image-annotation-shared/models/colors';
import {LineInOutFeature} from '@app/shared/image-annotation-shared/models/lineInOut';
import {PolygonExcluded} from '@app/shared/image-annotation-shared/models/polygon-excluded';
import {BehaviorSubject, filter, fromEvent, merge, Observable, Subject, Subscription} from 'rxjs';
import {DrawTool} from '../models/draw-tool';
import {Canvas, Draw, DrawStatus, LabelOpacity, SingleLabel} from '../models/image-annotation.model';
import {Polygon} from '../models/polygon';
import {Rectangle} from '../models/rectangle';
import {Perspective} from '@app/shared/image-annotation-shared/models/perspective';
import {cloneDeep} from 'lodash';
import tinycolor from 'tinycolor2';
import {Marker} from '../models/marker';

declare let SVG: any;

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  public drawStatus$: Observable<DrawStatus>;
  // eslint-disable-next-line rxjs/finnish
  public shapes: BehaviorSubject<{[key: string]: SingleLabel}> = new BehaviorSubject({});
  public shapes$: Observable<{[key: string]: SingleLabel}> = this.shapes
    .asObservable()
    .pipe(filter(shapes => !!shapes && typeof shapes === 'object'));
  // eslint-disable-next-line rxjs/finnish
  public selectedShape$: BehaviorSubject<SingleLabel> = new BehaviorSubject(null);
  // eslint-disable-next-line rxjs/finnish
  public shapeClick$: Subject<SingleLabel> = new Subject();
  // eslint-disable-next-line rxjs/finnish
  public shapeRightClick$: BehaviorSubject<SingleLabel> = new BehaviorSubject(null);
  // eslint-disable-next-line rxjs/finnish
  public shapeIsResizing$: Subject<boolean> = new Subject();
  public drawPoint$: Subject<void> = new Subject();
  public dragMove$: Subject<void> = new Subject();
  public availableColorsToPick = [];
  public readonly AVAILABLE_COLORS = AVAILABLE_COLORS;
  public zoomScale: number = 1;
  public hasToDraw: boolean = true;
  public originalSize: {width: number; height: number} = {width: 0, height: 0};
  public canvasSize: {width: number; height: number} = {width: 0, height: 0};
  public selectedTool: DrawTool = null;
  public categories: {[key: string]: string} = {};
  public zoomPercent: number = 1;

  private drawStatus: BehaviorSubject<DrawStatus> = new BehaviorSubject(DrawStatus.stop);
  private categoryColor: {[key: string]: string} = {};
  private scalingFactor = 1;
  private canvas: Canvas;
  private stopDrawSub: Subscription;
  private cancelDrawSub: Subscription;
  private drawBak: any = null;
  private resizeStartSub: Subscription;
  private resizeDonSub: Subscription;
  private watchDrawPointSub: Subscription;
  private watchMoveShapeSub: Subscription;
  private watchMoveShapeLineInEndSub: Subscription;
  private vertexStrokeWidth: number = 2;

  private canvasDiagonalWidth: BehaviorSubject<number> = new BehaviorSubject(0);
  public canvasDiagonalWidth$: Observable<number> = this.canvasDiagonalWidth.asObservable();

  private naturalImageDiagonalWidth: BehaviorSubject<number> = new BehaviorSubject(0);
  public naturalImageDiagonalWidth$: Observable<number> = this.naturalImageDiagonalWidth.asObservable();

  private temporalDrawEnds: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public temporalDrawEnds$: Observable<boolean> = this.temporalDrawEnds.asObservable();

  private hasShape: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasShape$: Observable<boolean> = this.hasShape.asObservable();

  private isNewDraw: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isNewDraw$: Observable<boolean> = this.isNewDraw.asObservable();

  private isDragging = new BehaviorSubject<boolean>(false);
  public isDragging$ = this.isDragging.asObservable();

  private inClick = new Subject<void>();
  public inClick$ = this.inClick.asObservable();

  private outClick = new Subject<void>();
  public outClick$ = this.outClick.asObservable();

  private lineInSwapClick = new Subject<void>();
  public lineInSwapClick$ = this.lineInSwapClick.asObservable();

  private lineOutSwapClick = new Subject<void>();
  public lineOutSwapClick$ = this.lineOutSwapClick.asObservable();

  private atLeastOneInOutPositionEnabled = new Subject<void>();
  public atLeastOneInOutPositionEnabled$ = this.atLeastOneInOutPositionEnabled.asObservable();

  private hoverZone = new Subject<string>();
  public hoverZone$ = this.hoverZone.asObservable();

  constructor() {
    this.drawStatus$ = this.drawStatus.asObservable();
    this.bindHighlightZoneEnabled();
  }

  public setCanvas(canvas: Canvas) {
    this.canvas = canvas;
  }

  public getCanvas(): any {
    return this.canvas;
  }

  public clearCanvas() {
    this.canvas = null;
  }

  public selectMarker({pathSizeX, pathSizeY, fontSize}): DrawTool {
    const markerPath =
      'M 9.449 0.068 C 6.97 0.068 4.592 1.051 2.838 2.802 C 1.085 4.552 0.1 6.926 0.1 9.401 C 0.1 16.401 9.449 26.735 9.449 26.735 C 9.449 26.735 18.8 16.401 18.8 9.401 C 18.8 6.926 17.814 4.552 16.062 2.802 C 14.308 1.051 11.93 0.068 9.449 0.068 Z';
    const path = this.canvas.path(markerPath).fill('#008000');
    path.size(pathSizeX, pathSizeY);
    const text = this.canvas
      .text('1')
      .move(path.cx(), -(path.cy() - fontSize - 4))
      .font({
        family: 'Arial',
        size: fontSize,
        anchor: 'middle',
        fill: '#ffffff',
        weight: 600
      })
      .style('user-select', 'none');

    const group = this.canvas.group();
    group.add(path);
    group.add(text);

    this.selectedTool = this.bindTool(group, ShapeTypes.marker);
    return this.selectedTool as DrawTool;
  }

  public selectPolygon(): DrawTool {
    this.selectedTool = this.bindTool(this.canvas.polygon(), ShapeTypes.Polygon);
    return this.selectedTool as DrawTool;
  }

  public selectPolygonExcluded(): DrawTool {
    this.selectedTool = this.bindTool(this.canvas.polygon(), ShapeTypes.polygon_excluded);
    return this.selectedTool as DrawTool;
  }

  public selectRectangle(): DrawTool {
    this.selectedTool = this.bindTool(this.canvas.rect(), ShapeTypes.Rectangle);
    return this.selectedTool as DrawTool;
  }

  public selectLineInOut(): DrawTool {
    this.selectedTool = this.bindTool(this.canvas.line(), ShapeTypes.line_in_out);
    return this.selectedTool as DrawTool;
  }

  public selectPerspective(): DrawTool {
    this.selectedTool = this.bindTool(this.canvas.polygon(), ShapeTypes.perspective);
    return this.selectedTool as DrawTool;
  }

  public setCanvasSize({width, height, top, left}: {width: number; height: number; top: number; left: number}) {
    if (!!this.canvas) {
      this.canvas.attr('width', width);
      this.canvas.attr('height', height);
      this.canvas.attr('style', `position: absolute; top: ${top}px; left: ${left}px`);

      const viewBox = this.canvas.viewbox() as any;
      this.canvasSize = {width: viewBox.width, height: viewBox.height};
      this.setCanvasDiagonalWidth(width, height);
    }
  }

  public setCanvasDiagonalWidth(width: number, height: number): void {
    this.canvasDiagonalWidth.next(Math.sqrt(width * width + height * height));
  }

  public setNaturalImageDiagonalWidth(width: number, height: number): void {
    this.naturalImageDiagonalWidth.next(Math.sqrt(width * width + height * height));
  }

  // TODO: Merge canvasSize with getCanvasSize()
  public getCanvasSize(): {width: number; height: number} {
    const viewBox = this.canvas.viewbox() as any;
    return {
      width: viewBox.width,
      height: viewBox.height
    };
  }

  public setCanvasConstrain() {
    if (!!this.canvas && !!this.selectedTool) {
      if (this.selectedTool.type !== ShapeTypes.perspective) {
        this.selectedTool.setDragConstrain({
          minX: 0,
          minY: 0,
          maxX: (this.canvas.viewbox() as any).width,
          maxY: (this.canvas.viewbox() as any).height
        });

        return;
      }

      const pinchZoom = document.querySelector('pinch-zoom');
      const pinchZoomContent = document.querySelector('.pinch-zoom-content');
      const imageSize = document.getElementById('draw-zones');

      const {width: pinchZoomWidth, height: pinchZoomHeight} = pinchZoom.getBoundingClientRect();
      const {width: imageWidth, height: imageHeight} = imageSize.getBoundingClientRect();

      const style = window.getComputedStyle(pinchZoomContent).transform;
      const matrixValues = style.match(/^matrix\((.+)\)$/) ? style.slice(7, -1).split(', ').map(parseFloat) : [];

      const [scaleX, , , scaleY, translateX, translateY] =
        matrixValues.length === 6 ? matrixValues : [1, 0, 0, 1, 0, 0];

      const finalScaleX = scaleX > 1 ? 0.4 : scaleX;
      const finalScaleY = scaleY > 1 ? 0.4 : scaleY;

      const scaledWidth = pinchZoomWidth / finalScaleX;
      const scaledHeight = pinchZoomHeight / finalScaleY;

      this.selectedTool.setDragConstrain({
        minX: (imageWidth - scaledWidth - translateX) / 2,
        minY: (imageHeight - scaledHeight - translateY) / 2,
        maxX: imageWidth + scaledWidth - translateX,
        maxY: imageHeight + scaledHeight - translateY
      });
    }
  }

  public updateShapes(shapeId: string, label: SingleLabel): void {
    const newShape: {[key: string]: SingleLabel} = {...this.shapes.value};
    newShape[shapeId] = {...newShape[shapeId], ...label};
    this.shapes.next({
      ...this.shapes.value,
      ...newShape
    });
  }

  public updateSelectedShape(form: {category: string; severity: number; comment: string; color: string}) {
    this.removeDashedStroke();
    this.clearBackup();
    this.selectedShape$.next({...this.selectedShape$.value, ...form});
    this.selectedTool.updateColor(form.color);
    this.updateShapeColor(this.selectedShape$.value.id, form.color);
    this.shapes.next({
      ...this.shapes.value,
      [this.selectedShape$.value.id]: {
        ...this.shapes.value[this.selectedShape$.value.id],
        ...this.selectedShape$.value
      }
    });
    const shapes = Object.values(this.shapes.value);
    this.unmarkAsSelectedShapesBulk(shapes);
    shapes.forEach(shape => {
      if (!shape.isRemoved) {
        this.setDefaultOpacity(shape.id);
      }
    });
    this.bindOnClickShape({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.bindOnRightClickShape({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.setDefaultOpacity(this.selectedShape$.value.id);
    this.updateDrawStatus(DrawStatus.stop);
  }

  public deleteShape(shape: SingleLabel) {
    const shapeRef = SVG.get(shape.id);
    if (!shapeRef) {
      console.warn('deleteShape: shapeRef is not defined, id:', shape.id);
      return;
    }
    const selectedTool = this.bindTool(shapeRef, shape.shapeType);
    selectedTool.removeDraw();
    this.shapes.next({
      ...this.shapes.value,
      [shape.id]: {...this.shapes.value[shape.id], isRemoved: true}
    });
  }

  public shapeVisibility({
    id,
    visibility,
    hasToEditDisplayLabels
  }: {
    id: string;
    visibility: boolean;
    hasToEditDisplayLabels: boolean;
  }) {
    const shapeRef = SVG.get(id);
    if (!shapeRef) {
      console.warn('shapeVisibility: shapeRef is not defined, id:', id);
      return;
    }
    const shape = {...this.shapes.value[id]};
    this.shapes.next({
      ...this.shapes.value,
      [id]: {...shape, visibility: !visibility}
    });
    const opacityLevel = !visibility ? LabelOpacity.visible : LabelOpacity.hidden;
    const strokeOpacityLevel = !visibility ? '1' : LabelOpacity.hidden;
    const displayLevel = !visibility ? 'display: unset;' : 'display: none;';

    shapeRef.attr({'fill-opacity': opacityLevel, 'stroke-opacity': strokeOpacityLevel, style: displayLevel});

    let visibilityAttribute = !visibility ? 'visible' : 'hidden';
    visibilityAttribute = hasToEditDisplayLabels ? visibilityAttribute : 'hidden';
    const shapeRefs = SVG.select(`[data-attached]`);
    shapeRefs.attr('visibility', visibilityAttribute);
  }

  public editShapeOpacity(id: string) {
    SVG.get(id).attr('fill-opacity', LabelOpacity.highlight);
  }

  public setDefaultOpacity(id: string) {
    const shapeRef = SVG.get(id);
    if (!shapeRef) {
      console.warn('setDefaultOpacity: shapeRef is not defined, id:', id);
      return;
    }
    if (shapeRef) {
      shapeRef.attr('fill-opacity', LabelOpacity.visible);
      if (shapeRef.type === ShapeTypes.line_in_out) {
        shapeRef.attr({'fill-opacity': LabelOpacity.visible, opacity: LabelOpacity.full});
      }
    }
  }

  public selectShape(shape: SingleLabel, hasToSaveBackup: boolean = true): void {
    const shapeRef = SVG.get(shape.id);
    if (!shapeRef) {
      console.warn('selectShape: shapeRef is not defined, id:', shape.id);
      return;
    }
    this.highlightShape(shape.id);

    if (shape.shapeType !== ShapeTypes.line_in_out) {
      this.dashedStrokeById(shape.id);
    }
    this.markAsSelectedShape(shape.id);
    this.selectedTool = this.bindTool(shapeRef, shape.shapeType);
    this.selectedTool.setScalingFactorForCircleRadius(this.scalingFactor / this.zoomScale);
    this.selectedTool.unbindInteractions();
    if (hasToSaveBackup) {
      this.saveBackup();
    }

    this.setCanvasConstrain();
    this.selectedShape$.next(shape);
    this.editDraw();
    this.watchMoveShape();
  }

  public loadShapes({
    labels,
    hasToDisplayShapeLabels = false,
    isAnalyticsEnabled = true,
    hasToDrawShapeLabels = false,
    hasEditPermissions = true
  }: {
    labels: {[key: string]: SingleLabel};
    hasToDisplayShapeLabels?: boolean;
    isAnalyticsEnabled?: boolean;
    hasToDrawShapeLabels?: boolean;
    hasEditPermissions?: boolean;
  }): void {
    if (!labels) {
      return;
    }

    const newShapes = this.importDraw(labels, hasToDisplayShapeLabels, hasToDrawShapeLabels);
    this.shapes.next(newShapes);

    if (!isAnalyticsEnabled) {
      return;
    }

    if (!hasEditPermissions) {
      return;
    }

    const shapeIds = [];
    Object.values(newShapes).forEach(shape => {
      this.bindOnClickShape({id: shape.id, shapeType: shape.shapeType});
      this.bindOnRightClickShape({id: shape.id, shapeType: shape.shapeType});
      shapeIds.push(shape.id);
    });

    this.scaleZoneTagName(shapeIds);
    const zonesToEnableTagNameEvents = Object.values(newShapes).map(zoneToMap => ({
      id: zoneToMap.id,
      shapeType: zoneToMap.shapeType
    }));
    this.enableTagNameEvents(zonesToEnableTagNameEvents);
    this.clearSelectedTool();
  }

  public hasCanvas(): boolean {
    return !!this.canvas;
  }

  public startDraw(opts?: {id: string; color: string; stroke?: string; name?: string}) {
    this.selectedTool.hasToDraw = this.hasToDraw;
    this.dashedStroke();
    this.selectedTool.setScalingFactorForCircleRadius(this.scalingFactor / this.zoomScale);

    let options = {};
    if (opts) {
      options = {...opts};
      if (!opts.color) {
        options['color'] = this.colorGenerator();
      }
    } else {
      options['color'] = this.colorGenerator();
    }

    this.selectedTool.startDraw(options);
    const drawStatus = DrawStatus.draw;
    this.updateDrawStatus(drawStatus);
    this.watchStopDraw();
    this.watchCancelDraw();
    this.watchDrawPoint();
    this.watchMoveShape();
    this.selectedShape$.next({id: this.selectedTool.id, shapeType: this.selectedTool.type} as SingleLabel);
    this.isNewDraw.next(true);
  }

  public startTemporalDraw() {
    this.selectedTool.hasToDraw = this.hasToDraw;
    this.selectedTool.setScalingFactorForCircleRadius(this.scalingFactor / this.zoomScale);
    this.selectedTool.startDraw({color: this.colorGenerator()});
    this.watchTemporalStopDraw();
    this.watchTemporalCancelDraw();
    this.selectedShape$.next({id: this.selectedTool.id, shapeType: this.selectedTool.type} as SingleLabel);
  }

  public blockOthersOnMagicDraw(): void {
    this.dashedStroke();
    this.updateDrawStatus(DrawStatus.draw);
    this.temporalDrawEnds.next(false);
  }

  public restoreDraw() {
    if (this.selectedTool) {
      this.selectedTool.stopSelection();
      this.selectedTool.unbindInteractions();

      if (this.drawStatus.value === DrawStatus.draw) {
        this.selectedTool.cancelDraw();
      }

      if (!this.drawHasBak()) {
        this.selectedTool.removeDraw();
        this.updateDrawStatus(DrawStatus.stop);
        return;
      }
    }

    this.updateDrawStatus(DrawStatus.stop);
    this.restoreBackup();

    if (this.selectedShape$.value) {
      this.bindOnClickShape({
        id: this.selectedShape$.value.id,
        shapeType: this.selectedShape$.value.shapeType
      });
      this.bindOnRightClickShape({
        id: this.selectedShape$.value.id,
        shapeType: this.selectedShape$.value.shapeType
      });
    }
  }

  public stopSelection() {
    this.selectedTool.stopSelection();
    this.selectedTool.unbindInteractions();
    this.bindOnClickShape({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.bindOnRightClickShape({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
  }

  public completeDraw() {
    if (this.selectedTool.hasDraw()) {
      this.selectedTool.completeDraw();
      this.drawStatus.next(DrawStatus.edit);
    }
  }

  public generateSelectedShape(addonId?: string): void {
    const id = this.selectedTool.id;
    const drawDraft = this.selectedTool.generateDrawTemplate();

    if (!Object.prototype.hasOwnProperty.call(this.shapes.value, id)) {
      this.selectedShape$.next({...drawDraft, addonId});
    } else {
      const singleLabel: SingleLabel = {
        ...this.shapes.value[id],
        shape: drawDraft.shape,
        addonId
      };
      this.shapes.next({...this.shapes.value, [id]: singleLabel});
      this.selectedShape$.next(singleLabel);
    }
  }

  public stopDraw() {
    if (this.selectedTool) {
      this.selectedTool.stopDraw();
      this.scaleZoneTagName([this.selectedTool.id]);
    }
    this.updateDrawStatus(DrawStatus.stop);

    if (this.stopDrawSub) {
      this.stopDrawSub.unsubscribe();
    }

    this.removeDashedStroke();
  }

  public stopTemporalDraw() {
    if (this.selectedTool) {
      this.selectedTool.stopDraw();
    }

    if (this.stopDrawSub) {
      this.stopDrawSub.unsubscribe();
    }
  }

  public stopMoveShapeSub() {
    if (this.watchMoveShapeSub) {
      this.watchMoveShapeSub.unsubscribe();
    }
  }

  public undoDraw() {
    if (this.drawStatus.value === DrawStatus.draw) {
      this.selectedTool.undoDraw();
    }
  }

  public redoDraw() {
    if (this.drawStatus.value === DrawStatus.draw) {
      this.selectedTool.redoDraw();
    }
  }

  public exportDraw(): [SingleLabel, string] {
    return this.selectedTool.exportDraw(this.selectedShape$.value);
  }

  public saveBackup() {
    if (!this.drawHasBak()) {
      this.drawBak = this.selectedTool.saveShapeBackup();
      this.isNewDraw.next(false);
    }
  }

  public reStartDraw() {
    const oldIdShape = this.selectedTool.id;
    const oldShapeColor = this.selectedTool.color;
    if (this.stopDrawSub) {
      this.stopDrawSub.unsubscribe();
    }
    this.selectedTool.cancelDraw();
    this.selectedTool.removeDraw();
    switch (this.selectedTool.type) {
      case ShapeTypes.Polygon:
      case ShapeTypes.polygon:
        this.selectPolygon();
        break;
      case ShapeTypes.Rectangle:
      case ShapeTypes.rectangle:
        this.selectRectangle();
        break;
    }
    this.startDraw({id: oldIdShape, color: oldShapeColor});
  }

  public editDraw() {
    this.setCanvasConstrain();
    this.selectedTool.editDraw();
    this.updateDrawStatus(DrawStatus.edit);
    this.watchResizeStart();
    this.watchResizeDon();
    this.watchMoveShape();
    this.bindClickEvents();
  }

  public setCanvasViewport({width, height}: {width: number; height: number}) {
    this.canvas.viewbox(`0 0 ${width} ${height}`);
    this.canvas.attr('preserveAspectRatio', `xMinYMin slice`);
    this.canvas.attr(
      'style',
      `max-width: -webkit-fill-available;max-height: -webkit-fill-available;width: unset;height: unset;`
    );

    this.scalingFactor = Math.pow(Math.pow(width, 2) + Math.pow(height, 2), 0.5);
  }

  public highlightShape(id: string) {
    const shapeRef = SVG.get(id);
    if (!shapeRef) {
      console.warn('highlightShape: shapeRef is not defined, id:', id);
      return;
    }
    if (shapeRef.type === ShapeTypes.line_in_out && shapeRef.attr('fill-opacity') !== LabelOpacity.hidden) {
      shapeRef.attr({'fill-opacity': LabelOpacity.highlight, opacity: LabelOpacity.highlight});
      return;
    }

    if (shapeRef?.attr('id') === id && shapeRef.attr('fill-opacity') !== LabelOpacity.hidden) {
      shapeRef.attr('fill-opacity', LabelOpacity.highlight);
      return;
    }
  }

  public enableTagNameEvents(zones: {id: string; shapeType: ShapeTypes}[]) {
    zones.forEach(shape => {
      const shapeRef = SVG.get(shape.id);
      if (!shapeRef) {
        console.warn('enableTagNameEvents: shapeRef is not defined, id:', shape.id);
        return;
      }
      const tool = this.bindTool(shapeRef, shape.shapeType);
      tool.enableTagNameEvents();
    });
  }

  public disableTagNameEvents(zones: {id: string; shapeType: ShapeTypes}[]) {
    zones.forEach(shape => {
      const shapeRef = SVG.get(shape.id);
      if (!shapeRef) {
        console.warn('disableTagNameEvents: shapeRef is not defined, id:', shape.id);
        return;
      }
      const tool = this.bindTool(shapeRef, shape.shapeType);
      tool.disableTagNameEvents();
    });
  }

  public dashedStrokeById(id: string) {
    const shapeRef: any[] = (this.canvas as any).children();
    shapeRef.forEach(shape => {
      shape.attr('id') === id ? shape.attr('stroke-dasharray', '0') : shape.attr('stroke-dasharray', '2.5');
    });
  }

  public dashedStroke() {
    if (!this.canvas) {
      return;
    }

    const shapeRef: any[] = (this.canvas as any).children();
    shapeRef.forEach(shape => {
      shape.attr('stroke-dasharray', '2.5');
    });
  }

  public removeDashedStroke() {
    if (!this.canvas) {
      return;
    }

    const shapeRef: any[] = (this.canvas as any).children();
    shapeRef.forEach(shape => {
      if (!shape.attr('id').includes('line_in_out')) {
        shape.attr('stroke-dasharray', '0');
      }
    });
  }

  public restoreShapesOpacity({id, visibility}: {id: string; visibility: boolean}) {
    const shapeRef = SVG.get(id);
    if (!shapeRef) {
      console.warn('restoreShapesOpacity: shapeRef is not defined, id:', id);
      return;
    }

    if (
      shapeRef.type === ShapeTypes.line_in_out &&
      ((shapeRef.attr('id') === id && !visibility) || shapeRef.attr('fill-opacity') === LabelOpacity.hidden)
    ) {
      shapeRef.attr({'fill-opacity': LabelOpacity.hidden, opacity: LabelOpacity.hidden});
      return;
    }

    if ((shapeRef.attr('id') === id && !visibility) || shapeRef.attr('fill-opacity') === LabelOpacity.hidden) {
      shapeRef.attr('fill-opacity', LabelOpacity.hidden);
      return;
    }

    shapeRef.attr('fill-opacity', LabelOpacity.visible);
  }

  public clearBackup() {
    this.drawBak = null;
    this.isNewDraw.next(true);
  }

  public clearShapeRightClick() {
    this.shapeRightClick$.next(null);
  }

  public clearSelectedTool() {
    this.selectedTool = null;
  }

  public clearSelectedShape() {
    this.selectedShape$.next(null);
  }

  public clearHasShape() {
    this.hasShape.next(false);
  }

  public hasColorCategory(colorCategory: string): boolean {
    return !!this.categoryColor[colorCategory];
  }

  public getColorByCategory(category: string, color: string): string {
    if (category in this.categoryColor) {
      return this.categoryColor[category];
    }

    let index = this.availableColorsToPick.findIndex(colorToPick => colorToPick === color);
    let newColor = null;
    if (index >= 0) {
      newColor = this.availableColorsToPick.splice(index, 1)[0];
    } else {
      newColor = this.colorGenerator();
      index = this.availableColorsToPick.findIndex(colorToPick => colorToPick === newColor);
      this.availableColorsToPick.splice(index, 1);
    }
    this.categoryColor[category] = newColor;
    return newColor;
  }

  public setColorByCategory(color: string, category: string) {
    const index = this.availableColorsToPick.findIndex(rgbColor => rgbColor === color);
    if (index >= 0) {
      this.availableColorsToPick.splice(index, 1);
    }
    this.categoryColor[category] = color;
  }

  public resetColorToPick() {
    this.categoryColor = {};
    this.availableColorsToPick = [...this.AVAILABLE_COLORS];
  }

  public clearCanvasShape() {
    this.shapes.next({});

    if (!!this.canvas) {
      this.canvas.clear();
    }
  }

  public clearCanvasShapeOnDestroy() {
    this.selectedShape$.next(null);
    this.shapes.next({});

    if (!!this.canvas) {
      this.canvas.clear();
      this.canvas = null;
    }

    this.clearSelectedTool();
    this.drawStatus.next(DrawStatus.stop);
    this.unbindHighlightZoneEnabled();
  }

  public redrawCircles() {
    this.scaleZoneTagName();

    if (this.selectedTool) {
      this.selectedTool.setScalingFactorForCircleRadius(this.scalingFactor / this.zoomScale);
      switch (this.drawStatus.value) {
        case DrawStatus.edit:
          if (this.selectedTool.type === ShapeTypes.perspective) {
            this.setCanvasConstrain();
          }
          this.selectedTool.redrawCirclesOnEditMode();
          break;
        case DrawStatus.draw:
          this.selectedTool.redrawCirclesOnDrawMode();
          break;
        default:
          break;
      }

      this.bindClickEvents();
    }
  }

  public scaleZoneTagName(shapeIds = []) {
    const ids =
      shapeIds.length > 0
        ? shapeIds
        : SVG.select(`[id$="-tag-name-group"]`).members.map(groupRef =>
            groupRef.attr('id').replace('-tag-name-group', '')
          );

    ids.forEach(shapeId => {
      const id = shapeId;

      const shape = SVG.get(`${id}`);
      if (!shape) {
        console.warn('scaleZoneTagName: shape is not defined, id:', id);
        return;
      }
      const displayName = shape.attr('display-name');
      if (!displayName) {
        console.warn('displayName missing for shape', id);
        return;
      }
      let color = shape.attr('fill');
      const colorEditable = tinycolor(color);
      colorEditable.setAlpha(LabelOpacity.full);
      color = colorEditable.toString();

      const referenceTagName = {
        group: 'tag-name-group',
        text: 'tag-name-text',
        textContainer: 'tag-name-text-container'
      };

      const MAX_LABEL_VALUE = 75;
      const MAX_SCALING_FACTOR_SIZE = 6597;
      const MIN_TEXT_SIZE = 10.23;

      const scalingFactor = this.scalingFactor / this.zoomScale;

      let textSize = ((scalingFactor * MAX_LABEL_VALUE) / MAX_SCALING_FACTOR_SIZE) * 0.8;

      if (Math.floor(this.zoomPercent) === 100) {
        textSize = MIN_TEXT_SIZE;
      }

      if (Math.floor(this.zoomPercent) === 100) {
        textSize = MIN_TEXT_SIZE;
      }

      let textTagName = SVG.get(`${id}-${referenceTagName.text}`);
      if (textTagName) {
        textTagName.attr({
          'font-size': `${textSize}px`
        });
      } else if (displayName) {
        textTagName = this.canvas.text(displayName);
        textTagName.attr({
          fill: '#fff',
          'font-size': `${textSize}px`,
          'font-weight': '400',
          'line-height': '20px',
          id: `${id}-${referenceTagName.text}`
        });
      } else {
        return;
      }

      const displayNameBbox = textTagName.bbox();
      const textContainerWidth = displayNameBbox.width + (10 * textSize) / 5;
      const textContainerHeight = displayNameBbox.height + (10 * textSize) / 5;
      const textContainerRadius = (4 * textSize) / 5;

      let textContainerTagName = SVG.get(`${id}-${referenceTagName.textContainer}`);
      if (!textContainerTagName) {
        textContainerTagName = this.canvas.rect(textContainerWidth, textContainerHeight);
      }

      textContainerTagName
        .size(textContainerWidth, textContainerHeight)
        .radius(textContainerRadius)
        .attr({fill: color, id: `${id}-${referenceTagName.textContainer}`});
      textTagName.center(textContainerTagName.cx(), textContainerTagName.cy());

      const points = shape._array?.value || shape.array().value;
      const {x: cx, y: cy} = DrawTool.findCenterPoint(points);

      let tagNameGroup = SVG.get(`${id}-${referenceTagName.group}`);
      if (tagNameGroup) {
        tagNameGroup.center(cx, cy);
      } else {
        tagNameGroup = this.canvas.group();
        tagNameGroup.add(textContainerTagName);
        tagNameGroup.add(textTagName);
        tagNameGroup.center(cx, cy);
        tagNameGroup.attr({opacity: LabelOpacity.hidden, id: `${id}-${referenceTagName.group}`});
      }
    });
  }

  public setZoomScale(scale: number) {
    this.zoomScale = scale;
  }

  public setZoomPercent(zoomPercent: number) {
    this.zoomPercent = zoomPercent;
  }

  public setIsDragging(isDragging: boolean) {
    this.isDragging.next(isDragging);
  }

  public markAsSelectedShape(id: string): void {
    const newShapes = {
      ...this.shapes.value,
      [id]: {...this.shapes.value[id], isSelected: true}
    };
    this.shapes.next(newShapes);
  }

  public unmarkAsSelectedShape(id: string): void {
    const newShapes = {
      ...this.shapes.value,
      [id]: {...this.shapes.value[id], isSelected: false}
    };
    this.shapes.next(newShapes);
  }

  public unmarkAsSelectedShapesBulk(selectedShapes: SingleLabel[]) {
    const newShapes = selectedShapes
      .map(selectedShape => ({...selectedShape, isSelected: false}))
      .reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

    this.shapes.next({...this.shapes.value, ...newShapes});
  }

  public optimisticUpdateSeverityBulk({
    selectedShapes,
    attribute,
    value,
    extraKey
  }: {
    selectedShapes: SingleLabel[];
    attribute: string;
    value: number | LabelColor | string;
    extraKey?: string;
  }): void {
    const newShapes = cloneDeep(this.shapes.value);
    selectedShapes.forEach(selectedShape => {
      this.clearLabelOnShape(selectedShape.id);
      this.drawLabelOnShape({...selectedShape, [attribute]: value}, false);
      newShapes[selectedShape.id][attribute] = value;
      if (extraKey) {
        newShapes[selectedShape.id][extraKey] = selectedShape[extraKey];
      }
      const shapeRef = SVG.get(selectedShape.id);
      if (!shapeRef) {
        console.warn('optimisticUpdateSeverityBulk: shapeRef is not defined, id:', selectedShape.id);
        return;
      }
      const selectedTool = this.bindTool(shapeRef, selectedShape.shapeType);
      selectedTool.updateColor(selectedShape.color);
      this.updateShapeColor(selectedShape.id, selectedShape.color);
    });

    this.shapes.next(newShapes);
  }

  public updateColorBulk({selectedShapes, color}: {selectedShapes: SingleLabel[]; color: string}) {
    selectedShapes.forEach(selectedShape => {
      const shapeRef = SVG.get(selectedShape.id);
      const selectedTool = this.bindTool(shapeRef, selectedShape.shapeType);
      selectedTool.updateColor(color);
      this.updateShapeColor(selectedShape.id, color);
    });
  }

  public deleteShapeBulk(selectedShapes: SingleLabel[]) {
    const newSelectedShapes = {};

    selectedShapes.forEach(shape => {
      this.clearLabelOnShape(shape.id);
      const shapeRef = SVG.get(shape.id);
      if (!shapeRef) {
        console.warn('deleteShapeBulk: shapeRef is not defined, id:', shape.id);
        return;
      }
      const selectedTool = this.bindTool(shapeRef, shape.shapeType);
      selectedTool.removeDraw();
      newSelectedShapes[shape.id] = {...shape, isRemoved: true, isSelected: false};
    });

    this.shapes.next({...this.shapes.value, ...newSelectedShapes});
  }

  public updateHasToDraw(hasToDraw: boolean) {
    this.selectedTool?.updateHasToDraw(hasToDraw);
  }

  public setLabelCategories(categories: {[key: string]: string}): void {
    this.categories = categories;
  }

  public hideLabels(): void {
    SVG.select('g.label').attr({visibility: 'hidden'});
  }

  public updateShapeColor(id: string, color: string): void {
    const fillColor = tinycolor(color).setAlpha(1).toString();
    const textContainerTagName = SVG.get(`${id}-tag-name-text-container`);
    textContainerTagName?.attr({fill: fillColor});
  }

  public displayLabels(): void {
    const groupRefs = SVG.select('g.label');

    groupRefs.members.forEach(groupRef => {
      const shapeRef = SVG.get(groupRef.attr('data-attached'));

      if (shapeRef?.attr('fill-opacity') === LabelOpacity.hidden) {
        return;
      }
      groupRef.attr({visibility: 'visible'});
    });
  }

  public drawLabelOnShape(label: SingleLabel, isVisible: boolean = true): void {
    if (label.isRemoved || typeof label !== 'object') {
      return;
    }

    const MAX_LABEL_VALUE = 100;
    const MAX_SCALING_FACTOR_SIZE = 6597;
    const MAX_TEXT_SIZE = 20;
    const MAX_Y_OFFSET = 125;
    const MIN_TEXT_SIZE = 10.23;

    let shapeVertices = label.shape;
    if (label.shapeType === ShapeTypes.Rectangle) {
      shapeVertices = [[label.shape.x, label.shape.y]];
    }

    if (label.shape?.vertices) {
      shapeVertices = label.shape.vertices.map((vertex: {x: number; y: number}) => [vertex.x, vertex.y]);
    }

    const scalingFactor = this.scalingFactor / this.zoomScale;

    let textSize = ((scalingFactor * MAX_LABEL_VALUE) / MAX_SCALING_FACTOR_SIZE) * 0.8;

    if (Math.floor(this.zoomPercent) === 100) {
      textSize = MIN_TEXT_SIZE;
    }

    const rectHeight = (textSize * MAX_TEXT_SIZE) / MAX_LABEL_VALUE;

    const localGroups = SVG.select(`[data-attached="${label.id}"]`);
    let localGroup = null;
    if (localGroups.members.length > 0) {
      localGroup = localGroups.members[0];
    }
    const group = localGroup || this.canvas.group();
    group.attr({class: 'label', 'data-attached': `${label.id}`, visibility: isVisible ? 'visibility' : 'hidden'});

    let color = label.color;
    if (typeof label.color === 'object') {
      color = (label as any).color.fill;
    }

    const text = this.canvas.text(this.buildText(label, textSize, color) as any);
    text.build(false);

    const rect = this.canvas.rect(text.length(), textSize + rectHeight).attr({
      fill: color,
      stroke: color,
      'stroke-width': this.vertexStrokeWidth + 'px'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
    let nearToTopVertexIndex = 0;
    shapeVertices.forEach((vertex: number[], index: number) => {
      if (vertex[1] < shapeVertices[nearToTopVertexIndex][1]) {
        nearToTopVertexIndex = index;
      }
      return;
    });

    const yOffset = (scalingFactor * MAX_Y_OFFSET) / MAX_SCALING_FACTOR_SIZE;
    const xOffset = rect.width();

    text.move(shapeVertices[nearToTopVertexIndex][0], shapeVertices[nearToTopVertexIndex][1] - yOffset);
    rect.move(shapeVertices[nearToTopVertexIndex][0], shapeVertices[nearToTopVertexIndex][1] - yOffset);

    // offset right
    if (shapeVertices[nearToTopVertexIndex][0] + rect.width() > this.canvasSize.width) {
      text.move(shapeVertices[nearToTopVertexIndex][0] - xOffset, shapeVertices[nearToTopVertexIndex][1] - yOffset);
      rect.move(shapeVertices[nearToTopVertexIndex][0] - xOffset, shapeVertices[nearToTopVertexIndex][1] - yOffset);
    }

    // offset top
    if (shapeVertices[nearToTopVertexIndex][1] - yOffset + rect.height() < rect.height()) {
      text.move(shapeVertices[nearToTopVertexIndex][0] - xOffset, shapeVertices[nearToTopVertexIndex][1]);
      rect.move(shapeVertices[nearToTopVertexIndex][0] - xOffset, shapeVertices[nearToTopVertexIndex][1]);
    }

    // offset left
    if (shapeVertices[nearToTopVertexIndex][0] - rect.width() < 0) {
      // offset left-top
      const topOffset = shapeVertices[nearToTopVertexIndex][1] - yOffset + rect.height() < 0 ? 0 : yOffset;
      text.move(shapeVertices[nearToTopVertexIndex][0], shapeVertices[nearToTopVertexIndex][1] - topOffset);
      rect.move(shapeVertices[nearToTopVertexIndex][0], shapeVertices[nearToTopVertexIndex][1] - topOffset);
    }

    group.add(rect);
    group.add(text);
  }

  public clearLabelOnShape(id: string): void {
    SVG.select(`[data-attached="${id}"]`).members.forEach(groupRef => {
      groupRef.clear();
    });
  }

  public toggleIn(): void {
    (this.selectedTool as LineInOutFeature).toggleIn();
  }

  public toggleOut(): void {
    (this.selectedTool as LineInOutFeature).toggleOut();
  }

  public unbindHighlightZoneEnabled(): void {
    document.removeEventListener('highlightZone', null);
  }

  private colorGenerator(): string {
    let color: string;
    if (this.availableColorsToPick.length === 0) {
      const maxValue = 255;
      const r = Math.round(Math.random() * maxValue);
      const g = Math.round(Math.random() * maxValue);
      const b = Math.round(Math.random() * maxValue);
      color = `rgb(${r},${g},${b})`;
    } else {
      const colorToPickIndex = Math.floor(Math.random() * this.availableColorsToPick.length);
      color = this.availableColorsToPick[colorToPickIndex];
    }
    return color;
  }

  private bindOnRightClickShape({id, shapeType}: {id: string; shapeType: ShapeTypes}) {
    const shapeRef = SVG.get(id);
    if (!shapeRef) {
      console.warn('bindOnRightClickShape: shapeRef is not defined, id:', id);
    }
    const selectedTool = this.bindTool(shapeRef, shapeType);
    selectedTool.bindOnRightClick(() => {
      if (this.drawStatus.value === DrawStatus.stop && shapeRef.attr('fill-opacity') !== LabelOpacity.hidden) {
        this.shapeRightClick$.next(this.shapes.value[id]);
      }
    });
  }

  private bindOnClickShape({id, shapeType}: {id: string; shapeType: ShapeTypes}) {
    const shapeRef = SVG.get(id);
    if (!shapeRef) {
      console.warn('bindOnClickShape: shapeRef is not defined, id:', id);
      return;
    }
    const selectedTool = this.bindTool(shapeRef, shapeType);
    selectedTool.bindOnClick(() => {
      if (this.drawStatus.value === DrawStatus.stop && shapeRef.attr('fill-opacity') !== LabelOpacity.hidden) {
        this.shapeClick$.next({id, shapeType} as SingleLabel);
        this.selectedTool.hideZoneTag();
      }
    });
  }

  private bindOnInClick({id, shapeType}: {id: string; shapeType: ShapeTypes}): void {
    if (shapeType !== ShapeTypes.line_in_out) {
      return;
    }

    (this.selectedTool as LineInOutFeature).bindOnInClick(() => {
      this.inClick.next();
    });
  }

  private bindOnOutClick({id, shapeType}: {id: string; shapeType: ShapeTypes}): void {
    if (shapeType !== ShapeTypes.line_in_out) {
      return;
    }

    (this.selectedTool as LineInOutFeature).bindOnOutClick(() => {
      this.outClick.next();
    });
  }

  private bindLineInSwapClick({id, shapeType}: {id: string; shapeType: ShapeTypes}): void {
    if (shapeType !== ShapeTypes.line_in_out) {
      return;
    }

    (this.selectedTool as LineInOutFeature).bindLineInSwapClick(() => {
      this.lineInSwapClick.next();
    });
  }

  private bindLineOutSwapClick({id, shapeType}: {id: string; shapeType: ShapeTypes}): void {
    if (shapeType !== ShapeTypes.line_in_out) {
      return;
    }

    (this.selectedTool as LineInOutFeature).bindLineOutSwapClick(() => {
      this.lineOutSwapClick.next();
    });
  }

  private bindAtLeastOneInOutPositionEnabled({shapeType}: {shapeType: ShapeTypes}): void {
    if (shapeType !== ShapeTypes.line_in_out) {
      return;
    }

    (this.selectedTool as LineInOutFeature).bindAtLeastOneInOutPositionEnabled(() => {
      this.atLeastOneInOutPositionEnabled.next();
    });
  }

  private bindHighlightZoneEnabled(): void {
    document.addEventListener('highlightZone', (data: any) => {
      const shapeId = data.detail;
      this.hoverZone.next(shapeId);
    });
  }

  private bindTool(toolRef: Draw, shapeType: ShapeTypes): DrawTool {
    let selectedTool = null;
    toolRef.attr('stroke-width', this.vertexStrokeWidth);
    switch (shapeType) {
      case ShapeTypes.polygon:
      case ShapeTypes.Polygon:
        selectedTool = new Polygon(toolRef, this.canvas);
        break;
      case ShapeTypes.rectangle:
      case ShapeTypes.Rectangle:
        selectedTool = new Rectangle(toolRef, this.canvas);
        break;
      case ShapeTypes.line_in_out:
        selectedTool = new LineInOutFeature(toolRef, this.canvas);
        break;
      case ShapeTypes.polygon_excluded:
        selectedTool = new PolygonExcluded(toolRef, this.canvas);
        break;
      case ShapeTypes.perspective:
        selectedTool = new Perspective(toolRef, this.canvas);
        break;
      case ShapeTypes.marker:
        selectedTool = new Marker(toolRef, this.canvas);
        break;
    }
    return selectedTool;
  }

  private importDraw(
    labels: {[key: string]: SingleLabel},
    hasToDisplayShapeLabels: boolean = false,
    hasToDrawShapeLabels: boolean = false
  ): {
    [key: string]: SingleLabel;
  } {
    let newLabels: {[key: string]: SingleLabel} = {};
    let singleLabel = null;

    for (const key in labels) {
      if (Object.prototype.hasOwnProperty.call(labels, key)) {
        const label = labels[key];
        label.shapeType = (label as any).shape_type ? (label as any).shape_type : label.shapeType;

        if (!!label) {
          switch (label.shapeType) {
            case ShapeTypes.Polygon:
            case ShapeTypes.polygon:
              singleLabel = this.addPolygon({...label, id: key});
              break;
            case ShapeTypes.Rectangle:
            case ShapeTypes.rectangle:
              singleLabel = this.addRectangle({...label, id: key});
              break;
            case ShapeTypes.line_in_out:
              singleLabel = this.addLineInOut({...label, id: key});
              break;
            case ShapeTypes.polygon_excluded:
              singleLabel = this.addPolygonExcluded({...label, id: key});
              break;
            case ShapeTypes.perspective:
              singleLabel = this.addPerspective({...label, id: key});
              break;
            default:
              console.info("Shape type isn't was found on importDraw: ", label.shapeType);
              return;
          }
          newLabels = {...newLabels, [key]: singleLabel};
        }
      }
    }

    if (hasToDrawShapeLabels) {
      Object.values(newLabels).forEach(singleLabel => {
        this.clearLabelOnShape(singleLabel.id);
        this.drawLabelOnShape(singleLabel, hasToDisplayShapeLabels);
      });
    }

    this.scaleZoneTagName();
    return newLabels;
  }

  private addPolygon(label: SingleLabel): SingleLabel {
    const ref = this.selectPolygon();
    const singleLabelPolygon = ref.importDraw(
      label,
      this.scalingFactor / this.zoomScale,
      this.canvasSize,
      this.originalSize
    );
    return singleLabelPolygon;
  }

  private addPolygonExcluded(label: SingleLabel): SingleLabel {
    const ref = this.selectPolygonExcluded();
    const singleLabelPolygonExcluded = ref.importDraw(
      label,
      this.scalingFactor / this.zoomScale,
      this.canvasSize,
      this.originalSize
    );

    return singleLabelPolygonExcluded;
  }

  private addRectangle(label: SingleLabel): SingleLabel {
    const ref = this.selectRectangle();

    return ref.importDraw(label, this.scalingFactor / this.zoomScale, this.canvasSize, this.originalSize);
  }

  private addLineInOut(label: SingleLabel): SingleLabel {
    const ref = this.selectLineInOut();
    const singleLabelLineInOut = ref.importDraw(
      label,
      this.scalingFactor / this.zoomScale,
      this.canvasSize,
      this.originalSize
    );

    return singleLabelLineInOut;
  }

  private addPerspective(label: SingleLabel): SingleLabel {
    const ref = this.selectPerspective();
    const singleLabelPerspective = ref.importDraw(
      label,
      this.scalingFactor / this.zoomScale,
      this.canvasSize,
      this.originalSize
    );

    return singleLabelPerspective;
  }

  private updateDrawStatus(status: DrawStatus) {
    if (!this.canvas) {
      return;
    }

    this.drawStatus.next(status);
    switch (status) {
      case DrawStatus.draw:
        this.canvas.addClass('is-drawing');
        break;
      default:
        this.canvas.removeClass('is-drawing');
        break;
    }
  }

  private watchStopDraw() {
    if (this.stopDrawSub) {
      this.stopDrawSub.unsubscribe();
    }

    this.stopDrawSub = fromEvent(this.selectedTool.ref as any, 'drawstop').subscribe(event => {
      if (this.drawStatus.value === DrawStatus.draw) {
        if (this.selectedTool.type === ShapeTypes.line_in_out) {
          this.buildComponents();
        }
        this.editDraw();
        this.hasShape.next(true);
      }
    });
  }

  private buildComponents() {
    (this.selectedTool as LineInOutFeature).buildComponents();
  }

  private watchCancelDraw() {
    if (this.cancelDrawSub) {
      this.cancelDrawSub.unsubscribe();
    }

    this.cancelDrawSub = fromEvent(this.selectedTool.ref as any, 'drawcancel').subscribe(event => {
      if (this.drawStatus.value === DrawStatus.draw) {
        this.editDraw();
        this.clearHasShape();
      }
    });
  }

  private watchTemporalStopDraw() {
    if (this.stopDrawSub) {
      this.temporalDrawEnds.next(false);
      this.stopDrawSub.unsubscribe();
    }

    this.stopDrawSub = fromEvent(this.selectedTool.ref as any, 'drawstop').subscribe(event => {
      if (this.drawStatus.value === DrawStatus.draw) {
        this.stopTemporalDraw();
        this.temporalDrawEnds.next(true);
        this.hasShape.next(true);
      }
    });
  }

  private watchTemporalCancelDraw() {
    if (this.cancelDrawSub) {
      this.temporalDrawEnds.next(false);
      this.cancelDrawSub.unsubscribe();
    }

    this.cancelDrawSub = fromEvent(this.selectedTool.ref as any, 'drawcancel').subscribe(event => {
      if (this.drawStatus.value === DrawStatus.draw) {
        this.stopTemporalDraw();
        this.temporalDrawEnds.next(false);
      }
    });
  }

  private watchResizeStart() {
    if (this.resizeStartSub) {
      this.resizeStartSub.unsubscribe();
    }

    this.resizeStartSub = fromEvent(this.selectedTool.ref as any, 'resizestart').subscribe(event => {
      if (this.drawStatus.value === DrawStatus.edit) {
        this.shapeIsResizing$.next(true);
      }
    });
  }

  private watchResizeDon() {
    if (this.resizeDonSub) {
      this.resizeDonSub.unsubscribe();
    }

    this.resizeDonSub = fromEvent(this.selectedTool.ref as any, 'resizedone').subscribe(event => {
      if (this.drawStatus.value === DrawStatus.edit) {
        this.shapeIsResizing$.next(false);
      }
    });
  }

  private watchDrawPoint() {
    if (this.watchDrawPointSub) {
      this.watchDrawPointSub.unsubscribe();
    }

    this.watchDrawPointSub = fromEvent(this.selectedTool.ref as any, 'drawpoint').subscribe(event => {
      this.drawPoint$.next();
    });
  }

  private watchMoveShape() {
    if (this.watchMoveShapeSub) {
      this.watchMoveShapeSub.unsubscribe();
    }

    this.watchMoveShapeSub = fromEvent(this.selectedTool.ref as any, 'dragmove').subscribe(event => {
      this.dragMove$.next();
    });

    if (this.selectedTool.type === ShapeTypes.line_in_out) {
      if (this.watchMoveShapeLineInEndSub) {
        this.watchMoveShapeLineInEndSub.unsubscribe();
      }

      if ((this.selectedTool as LineInOutFeature).lineInEnd && (this.selectedTool as LineInOutFeature).lineInEnd) {
        this.watchMoveShapeLineInEndSub = merge(
          fromEvent((this.selectedTool as LineInOutFeature).lineInEnd, 'dragmove'),
          fromEvent((this.selectedTool as LineInOutFeature).lineOutEnd, 'dragmove')
        ).subscribe(event => {
          this.dragMove$.next();
        });
      }
    }
  }

  private restoreBackup() {
    if (!this.drawBak) {
      return;
    }

    switch (this.selectedTool.type) {
      case ShapeTypes.Polygon:
      case ShapeTypes.polygon:
      case ShapeTypes.Rectangle:
      case ShapeTypes.rectangle:
      case ShapeTypes.perspective:
      case ShapeTypes.line_in_out:
        this.selectedTool.restoreBackup(this.drawBak);
        break;
    }
    this.clearBackup();
  }

  public drawHasBak(): boolean {
    return !!this.drawBak && (!!this.drawBak.x || !!this.drawBak.points || !!this.drawBak.color);
  }

  private buildText(label: SingleLabel, textSize, color: string): (any) => void {
    const primaryColor = this.contrastingColor(color);
    const secondaryColor = primaryColor === '#000' ? '#222' : '#ddd';
    const alertIcon = '\ue802';
    const whiteSpace = '\u00A0';
    // const setSquareIcon = '\ue801';
    const setRulerIcon = '\ue800';

    return add => {
      add
        .tspan(whiteSpace + this.categories[label.category] + whiteSpace)
        .font({size: textSize, family: 'Rubik', weight: 500, fill: primaryColor});

      if (label.severity) {
        add.tspan(alertIcon).font({size: textSize, family: 'fontello', weight: 300, fill: secondaryColor});
        add
          .tspan(`${whiteSpace}${label.severity}${whiteSpace}`)
          .font({size: textSize, family: 'Rubik', weight: 300, fill: secondaryColor});
      }

      if (!!label.distance || label.distance === 0) {
        add
          .tspan(label.severity ? '' : whiteSpace + setRulerIcon)
          .font({size: textSize, family: 'fontello', weight: 300, fill: secondaryColor});
        add
          .tspan(`${whiteSpace}${label.distance}m${whiteSpace}`)
          .font({size: textSize, family: 'Rubik', weight: 300, fill: secondaryColor});
      }
    };
  }

  // Idea from https://stackoverflow.com/questions/635022/calculating-contrasting-colours-in-javascript#answer-6511606
  private contrastingColor(color: string): string {
    return (color && this.luma(color)) >= 165 ? '#000' : '#fff';
  }

  private luma(color: string): number {
    const rgb = color
      .replace('rgb(', '')
      .replace(')', '')
      .replaceAll("'", '')
      .split(',')
      .map((num: string) => parseInt(num));
    return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
  }

  public setPolygonPoints(shapeId, polygonPoints: number[][]): void {
    const shapeRef = SVG.get(shapeId);
    if (!shapeRef) {
      console.warn('setPolygonPoints: shapeRef is not defined, id:', shapeId);
      return;
    }
    shapeRef.attr('points', polygonPoints.map(points => points.join(',')).join(' '));
    shapeRef.attr('stroke', '#000000');
    shapeRef.attr('stroke-opacity', '1');
    const selectedTool = this.bindTool(shapeRef, ShapeTypes.Polygon);
    this.selectedTool = selectedTool;
    selectedTool.updatePoints(polygonPoints);
  }

  public clearTemporalDrawEnds() {
    this.temporalDrawEnds.next(false);
  }

  public swapDirections(): void {
    (this.selectedTool as LineInOutFeature).swapDirections();
  }

  public loadSVGfromDom(node: any): Draw {
    return SVG.adopt(node);
  }

  public saveZoneName(displayName: string) {
    this.selectedTool.ref.attr('display-name', displayName);
  }

  public checkIsValidClick(event: MouseEvent) {
    if (this.selectedTool.type === ShapeTypes.line_in_out) {
      this.selectedTool.handleCanvasClick(event, ['pinch-zoom', 'unleash-image-annotation-shared']);
    } else {
      this.selectedTool.handleCanvasClick(event, []);
    }
  }

  private bindClickEvents(): void {
    this.bindOnInClick({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.bindOnOutClick({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.bindLineInSwapClick({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.bindLineOutSwapClick({
      id: this.selectedShape$.value.id,
      shapeType: this.selectedShape$.value.shapeType
    });
    this.bindAtLeastOneInOutPositionEnabled({shapeType: this.selectedShape$.value.shapeType});
  }
}

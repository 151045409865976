import {UserService} from '@app/core/services/api/user.service';
import {AWSIoTProvider, PubSub} from '@aws-amplify/pubsub';
import {Store} from '@ngrx/store';
import {BehaviorSubject, interval, map, Observable, of, ReplaySubject, switchMap, tap} from 'rxjs';
import {ZenObservable} from 'zen-observable-ts';
import {environment} from '../../../environments/environment';
import {iotSensorDataMock} from '../models/iot-sensor-data-mock';
import {DroneState, IoTConnectionStatus} from '../models/remote-cockpit.model';
import {setCurrentDeviceState, setIoTConnectionStatus} from '@app/flights/store/remote-cockpit/remote-cockpit.actions';
import {IotServiceType, IotTopicService} from '@app/flights/services/iot-topic.service';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

export interface DeviceTrackingDataStore<T> {
  [deviceId: string]: T[];
}

export interface DeviceTrackingData$<T> {
  [deviceId: string]: ReplaySubject<T[]>;
}
// Don't remove the following line, it's responsible for the right PubSub pluggable initialization, to avoid PubSub from being treeshaken
// fixes #6139
PubSub.getModuleName();
PubSub.addPluggable(
  new AWSIoTProvider({
    ioTPolicyName: environment.ioTPolicyName,
    aws_pubsub_region: environment.region,
    aws_pubsub_endpoint: `wss://${environment.iotRestURL}/mqtt`
  })
);

export abstract class AbstractTrackingService<T> {
  public readonly allDevicesData$: DeviceTrackingData$<T> = {} as DeviceTrackingData$<T>;
  public mockIntervalData: BehaviorSubject<number> = new BehaviorSubject<number>(4000); // start with 2 sec
  protected _allDevicesDataStore: DeviceTrackingDataStore<T> = {} as DeviceTrackingDataStore<T>;
  private topicSub: {[key: string]: ZenObservable.Subscription} = {};

  protected constructor(
    private userService: UserService,
    protected BUFFER_SIZE: number, // defines how big is the data store for the chart to render
    protected serviceType: IotServiceType, // used for client id and topic generation
    private store: Store,
    private iotTopicService: IotTopicService
  ) {}
  /**
   * Call this method to start receiving data
   **/
  // eslint-disable-next-line rxjs/finnish
  public subscribeToMockDataForceWaitingError(): Observable<any[]> {
    this.store.dispatch(
      setIoTConnectionStatus({
        iotConnectionStatus: IoTConnectionStatus.SUCCESS
      })
    );
    return this.mockIntervalData.pipe(
      switchMap(x => {
        return interval(x);
      }),
      map(x => {
        return [
          {
            ...iotSensorDataMock,
            // eslint-disable-next-line no-magic-numbers
            lng: x / 100
            // droneOn: x < 5000 ? true : false -> mock droneOn
          }
        ];
      }),
      tap(() => {
        this.mockIntervalData.next(this.mockIntervalData.value + 2000);
      })
    );
  }

  // eslint-disable-next-line rxjs/finnish
  public subscribeToMockData(deviceId: string): Observable<any[]> {
    this.store.dispatch(
      setIoTConnectionStatus({
        iotConnectionStatus: IoTConnectionStatus.SUCCESS
      })
    );
    // eslint-disable-next-line no-magic-numbers
    return interval(2000).pipe(
      map(x => {
        return [
          {
            ...iotSensorDataMock,
            lng: x / 100,
            timeElapsed: x,
            preciseLand: true
          }
        ];
      })
    );
  }

  // eslint-disable-next-line rxjs/finnish
  public subscribe(deviceId: string): Observable<T> {
    // initialize data store for a given device
    this._allDevicesDataStore[deviceId] = [];
    this.allDevicesData$[deviceId] = new ReplaySubject<T[]>(this.BUFFER_SIZE);
    this.allDevicesData$[deviceId].next([]);

    return this.getTopic().pipe(
      switchMap(topic => {
        console.info('Subscribing to MQTT topic:', topic);
        return new Observable<T>(observer => {
          this.topicSub[topic] = PubSub.subscribe(topic).subscribe({
            next: msg => {
              let data = msg.value as T;
              // console.log('Received', data);
              this._allDevicesDataStore[deviceId].unshift(data);
              this.allDevicesData$[deviceId].next(this._allDevicesDataStore[deviceId]); // keep history of frames for flight frame readers
              observer.next(data); // send frames to subscribers
              this.store.dispatch(
                setIoTConnectionStatus({
                  iotConnectionStatus: IoTConnectionStatus.SUCCESS
                })
              );
            },
            error: err => {
              console.error('ERROR: Failed to subscribe: ', err);
              observer.error(err);
              this.store.dispatch(
                setIoTConnectionStatus({
                  iotConnectionStatus: IoTConnectionStatus.ERROR
                })
              );
            },
            complete() {
              console.info('COMPLETE: Subscribed to MQTT topic:', topic);
              observer.complete();
            }
          });

          return () => {
            this.topicSub[topic].unsubscribe();
          };
        });
      })
    );
  }

  public unsubscribe(deviceId: string, modelId?: string): void {
    console.info('AbstractTrackingService:unsubscribe', deviceId, modelId);
    this.getTopic().subscribe(topic => {
      console.info('unsubscribing from', topic);
      this.topicSub[topic]?.unsubscribe();
    });
    // this.allDevicesData$[deviceId].unsubscribe();
  }

  public getTopic(): Observable<string> {
    return fromPromise(this.iotTopicService.getTopicName(this.serviceType));
  }

  public setDroneDisconnected(): void {
    this.store.dispatch(
      setCurrentDeviceState({
        currentDeviceState: {
          flycState: 'Offline' as any,
          deviceConnected: false,
          // flightModeStatus: 'ERROR' // dont use enum here, JIT compiler fails
        } as Partial<DroneState>
      })
    );
    this.store.dispatch(setIoTConnectionStatus({iotConnectionStatus: IoTConnectionStatus.DISCONNECTED}));
  }
}

import {UserActions} from '@app/profile/models/user-actions.model';
import {TeamRole} from './team.model';

export enum UpdateUserTeamAction {
  add = 'add',
  remove = 'remove'
}

export interface UserTeamTable {
  id: string;
  name: string;
  // role: TeamRole;
}

export interface UserTeamAction {
  action: UserActions;
  userId: string;
  payload?: TeamRole;
}

export interface UserTeamTableActions {
  name: string;
  type: UserActions;
}

export interface UserTeamInvitation {
  userId: string;
  teamId: string;
  role: TeamRole;
}

<div
  class="map-container"
  leaflet
  [leafletOptions]="options"
  [leafletLayers]="layers"
  [leafletFitBoundsOptions]="fitBoundsOptions"
  (leafletMapReady)="onMapReady($event)"
>
  <div class="toggle" *ngIf="hasToggle">
    <button class="toggle__button" color="primary" mat-mini-fab (click)="emitToggle()">
      <mat-icon svgIcon="chev_up"></mat-icon>
    </button>
  </div>

  <div class="expander" *ngIf="hasExpander && deviceId">
    <a
      color="primary"
      mat-icon-button
      target="_blank"
      [routerLink]="['/secure/flights/cockpit/', deviceId, 'mission']"
      (click)="emitExpander()"
    >
      <mat-icon svgIcon="arrow_expand"></mat-icon>
    </a>
  </div>

  <ng-container *ngIf="isPaused; else flightIndicatorsTemplate">
    <div class="overlay">
      <div class="overlay__icon">
        <mat-icon svgIcon="map_subtract"></mat-icon>
      </div>
      <div class="overlay__title">{{ 'flights.map.paused' | translate }}</div>
      <div class="overlay__description">
        {{ 'flights.map.pausedHelp' | translate }}
      </div>
      <div class="overlay__action">
        <button mat-raised-button color="primary" (click)="emitReconnectToMap()">
          {{ 'flights.map.reconnect' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #flightIndicatorsTemplate>
    <!-- flight indicators -->
    <div *ngIf="timeControlsVisible" class="map-controls dashboard-controls">
      <!--flight mode-->
      <div fxFlex fxLayoutAlign="start" class="control">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="currentFrame.flycState">
          <i class="material-icons"> airplanemode_active </i>
          {{ currentFrame.flycState }}
        </div>
      </div>
      <!--gps signal-->
      <div fxFlex fxLayoutAlign="center" class="control">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="currentFrame.gpsNum">
          <i class="material-icons"> signal_cellular_alt </i>
          {{ currentFrame.gpsNum }}
        </div>
      </div>
      <!--battery level-->
      <div fxFlex fxLayoutAlign="end" class="control">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="currentFrame.relativeCapacity">
          <i class="material-icons"> battery_std </i>
          {{ currentFrame.relativeCapacity }}%
        </div>
      </div>
    </div>
    <!-- flight progress slider-->
    <div class="map-controls time-controls" *ngIf="timeControlsVisible">
      <ua-flight-time-control
        [isSliderVisible]="isSliderVisible"
        (timeChanged)="newFrame($event)"
        [numFrames]="flightFrames && flightFrames.length - 1"
        [map]="map"
      ></ua-flight-time-control>
    </div>

    <!-- back button -->
    <div class="map-controls back-controls" *ngIf="isBackButtonVisible">
      <mat-icon (click)="handleBackButton(); $event.stopPropagation()">arrow_back</mat-icon>
    </div>

    <!-- close button -->
    <div class="map-controls close-controls" *ngIf="isCloseButtonVisible">
      <button (click)="handleCloseButton()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-template>
</div>

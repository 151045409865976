import {Injectable} from '@angular/core';
import {UserActions} from '@app/profile/models/user-actions.model';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {TeamRole, TeamTable} from '../models/team.model';
import {UserTeamTileScreen} from '../models/user-team-tile-screen.model';
import {UserTeamAction, UserTeamInvitation, UserTeamTable, UserTeamTableActions} from '../models/user-team.model';
import {
  actionAddUserToTeam,
  actionDeleteUserFromTeam,
  actionRemoveSelectedUserTeamTable,
  actionSelectUserTeamTable
} from '../store/profile.actions';
import {openInviteUsersDialog} from '@app/profile/store/users-management/users-management.actions';

@Injectable({
  providedIn: 'root'
})
export class UserTeamsAccountManagementService {
  public userTeamScreen$: Observable<UserTeamTileScreen> = of(UserTeamTileScreen.userTeams);
  public selectedUserTeam$: Observable<TeamTable> = this.usersManagementStoreFacadeService.selectedUserTeam$;
  public userTeamMenuActions: UserTeamTableActions[] = [{name: 'Delete', type: UserActions.delete}];
  public teamRoles: TeamRole[] = [TeamRole.contributor, TeamRole.admin, TeamRole.viewer];

  private filter: BehaviorSubject<string> = new BehaviorSubject('');
  private userTeamScreen: BehaviorSubject<UserTeamTileScreen> = new BehaviorSubject(UserTeamTileScreen.userTeams);

  constructor(private store: Store, private usersManagementStoreFacadeService: UsersManagementStoreFacadeService) {
    this.userTeamScreen$ = this.userTeamScreen.asObservable();
  }

  public displayUserTeamsView(): void {
    this.userTeamScreen.next(UserTeamTileScreen.userTeams);
  }

  public displayAddUserToTeamsView(): void {
    this.userTeamScreen.next(UserTeamTileScreen.addToUserTeam);
  }

  public displayUserTeamDetailsView(): void {
    this.userTeamScreen.next(UserTeamTileScreen.userTeamDetails);
  }

  public addUserToTeam(userTeamInvitation: UserTeamInvitation): void {
    this.store.dispatch(
      actionAddUserToTeam({
        userId: userTeamInvitation.userId,
        teamId: userTeamInvitation.teamId,
        role: userTeamInvitation.role
      })
    );
  }

  public filterUserTeams(search: string): void {
    this.filter.next(search);
  }

  public executeUserTeamsAction(userTeamAction: UserTeamAction): void {
    switch (userTeamAction.action) {
      case UserActions.removeUserFromTeam:
        this.usersManagementStoreFacadeService.removeUsersFromTeam(userTeamAction.userId);
        break;
      case UserActions.editRole:
        this.usersManagementStoreFacadeService.updateUserTeamRole({
          userId: userTeamAction.userId,
          role: userTeamAction.payload
        });
        break;
      default:
        break;
    }
  }

  public selectUserTeam(userTeam: UserTeamTable): void {
    this.store.dispatch(actionSelectUserTeamTable({userTeamTable: userTeam}));
  }

  public removeSelectedUserTeam(): void {
    this.store.dispatch(actionRemoveSelectedUserTeamTable());
  }

  public openInviteUsersDialog(): void {
    this.store.dispatch(openInviteUsersDialog());
  }
}

import {Injectable} from '@angular/core';
import {latLngBounds, LatLngBoundsExpression, Map} from 'leaflet';
import {AbstractAssetLoaderService} from './abstract-asset-loader.service';
import { AtlasAssetModel } from '@app/core/models/api/atlas.model';

declare const L; // leaflet global to use leaflet.heat package

@Injectable({
  providedIn: 'root'
})
export class HeatmapAssetLoaderService extends AbstractAssetLoaderService {
  async load(asset: AtlasAssetModel, map: Map): Promise<number> {
    console.info('Loading HEATMAP', asset);
    const data = await this.fetchFile(asset.key);
    this.atlasService.handleDetectAssetChanges();
    const {heatLayer, bounds} = this.createLayer(data, map);
    console.info('heat bounds', heatLayer.getBounds());
    this.addAsset(map, {
      layer: heatLayer,
      name: asset.name,
      bounds,
      id: asset.id
    });
    return data.toString().split('\n').length;
  }

  fetchFile(key: string) {
    return this.atlasService.getAssetData<string>(encodeURIComponent(key), 'text');
  }

  createLayer(data, map) {
    const dataArr: string[] = data.toString().split('\n');
    const columnNames = dataArr[0].split(','); // e.g. Latitude,Longitude,CH4 ppm
    const layerName = columnNames[2];
    const dimHeatData: number[][] = [];
    dataArr.forEach((entry, index) => {
      console.info('entry', entry);
      if (index == 0 || !entry) {
        return;
      }
      const [lat, lng, val] = entry.split(',');
      dimHeatData.push([Number(lat), Number(lng), Number(val)]);
    });
    const maxDimValue = Math.max(...dimHeatData.map(p => p[2]));
    const options = {
      max: maxDimValue,
      radius: 15,
      blur: 10,
      minOpacity: 0.1,
      gradient: {
        0: '#000000',
        0.2: '#570000',
        0.4: '#ff0000',
        0.6: '#ffc800',
        0.8: '#ffff00',
        '1.0': '#FFFFFF'
      }
    };

    const heatLayer = L.heatLayer(dimHeatData, options);

    const lastPointIndex = dimHeatData.length - 1;
    const bounds: LatLngBoundsExpression = latLngBounds([
      [dimHeatData[0][0], dimHeatData[0][1]],
      [dimHeatData[lastPointIndex][0], dimHeatData[lastPointIndex][1]]
    ]);

    return {heatLayer, bounds};
  }
}

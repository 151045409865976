import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AtlasLocalStorageService} from '@app/atlas/services/atlas-local-storage.service';
import {Map, TileLayer} from 'leaflet';
import {EVENTS} from '../../../core/services/unleash-analytics.service';
import {MarkerClusterService} from '@app/atlas/services/marker-cluster.service';
import {atlasConfig} from '@app/atlas/atlas.config';
import {debounceTime} from 'rxjs/operators';
import {fromEvent, merge} from 'rxjs';
import {MAP_VIEW} from '@app/atlas/model/map-view.mode';

declare let L; // leaflet global
@Component({
  selector: 'app-toggle-base-layer',
  templateUrl: './toggle-base-layer.component.html',
  styleUrls: ['./toggle-base-layer.component.scss']
})
export class ToggleBaseLayerComponent implements OnInit {
  events = EVENTS;
  @Input()
  map: Map;
  @Input()
  worldMap: TileLayer;
  @Input()
  streetMap: TileLayer;
  @Input()
  weatherMap: TileLayer = null;
  @Input()
  isWeatherMapEnabledForUser: boolean = false;
  @Input()
  isWeatherMapDisplayed: boolean = true;
  @Input()
  totalAssetsCount: number = 0;
  @Input()
  isLayersControlSideBarOpen: boolean = false;
  @Input()
  isTaskControl: boolean = false;
  @Input()
  isMiniMapControl: boolean = false;
  @Input()
  mapView: MAP_VIEW = MAP_VIEW.SATELLITE;

  @Output()
  toggleWeather: EventEmitter<void> = new EventEmitter();
  @Output()
  toggleMap: EventEmitter<void> = new EventEmitter();
  @Output()
  toggleLayerControlSideBar: EventEmitter<void> = new EventEmitter();
  @Output()
  toggleClusters: EventEmitter<void> = new EventEmitter();
  initializedWithWeatherMap: boolean = false;
  zoomMoreFlag: boolean = false;
  public mapViewMapper = {
    [MAP_VIEW.HYBRID]: {translation: 'map', analyticsType: 'Show Map'},
    [MAP_VIEW.ROADMAP]: {translation: 'terrain', analyticsType: 'Show Terrain'},
    [MAP_VIEW.SATELLITE]: {translation: 'hybrid', analyticsType: 'Show Hybrid'}
  };
  public MAP_VIEW = MAP_VIEW;

  constructor(
    private atlasLocalStorageService: AtlasLocalStorageService,
    private markerClusterService: MarkerClusterService,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.weatherMap;
    if (this.isWeatherMapEnabledForUser) {
      this.isWeatherMapDisplayed = true;
      this.initializedWithWeatherMap = true;
    }
    if (this.atlasLocalStorageService.getItem('isWeatherMapDisplayed') === 'false') {
      this.isWeatherMapDisplayed = false;
    }

    this.setupMapEventListeners();
  }

  private handleMapChange() {
    const zoom = this.map.getZoom();
    console.info('CURRENT ZOOM', zoom);
    let shouldEnableClustering = this.shouldDisableClustering(zoom);
    this.zoomMoreFlag = shouldEnableClustering;
    this.toggleClustering(shouldEnableClustering);
    this.cd.detectChanges();
  }
  private toggleClustering(state: boolean) {
    if (this.markerClusterService.stickyClustersEnabled) {
      console.debug('Sticky clusters enabled');
      return;
    }
    if (state) {
      this.markerClusterService.enableClustering();
    } else {
      this.markerClusterService.disableClustering();
    }
  }

  private setupMapEventListeners() {
    const zoomEnd$ = fromEvent(this.map, 'zoomstart');
    const moveEnd$ = fromEvent(this.map, 'moveend');
    merge(zoomEnd$, moveEnd$)
      .pipe(debounceTime(100))
      .subscribe(() => this.handleMapChange());
  }

  /**
   * Determines if clustering should be disabled based on the zoom level and the number of visible markers.
   * zoom >= DISABLE_CLUSTERING_ZOOM_LEVEL`, clustering is disabled.
   * zoom <= ENABLE_CLUSTERING_ZOOM_LEVEL, clustering is enabled.
   * ENABLE_CLUSTERING_ZOOM_LEVEL < zoom < DISABLE_CLUSTERING_ZOOM_LEVEL,
   * the function calculates the number of visible markers within the map's viewport and returns true if
   * the count is greater than or equal to `DISABLE_CLUSTERING_MARKERS_COUNT`.
   * @param {number} zoom - The current zoom level of the map.
   * @returns {boolean} - True if clustering should be disabled, false otherwise.
   */
  private shouldDisableClustering(zoom: number) {
    if (zoom >= atlasConfig.DISABLE_CLUSTERING_ZOOM_LEVEL) {
      return false;
    } else if (zoom <= atlasConfig.ENABLE_CLUSTERING_ZOOM_LEVEL) {
      return true;
    }
    // perform dynamic calculation based on the visible markers count
    const bounds = this.map.getBounds();
    const layers = this.markerClusterService.cluster.getLayers();
    const threshold = atlasConfig.DISABLE_CLUSTERING_MARKERS_COUNT;
    let visibleMarkerCount = 0;
    for (let i = 0; i < layers.length; i++) {
      const e = layers[i];
      if (e?.feature?.geometry?.type === 'Point' && bounds.contains(e.getLatLng())) {
        visibleMarkerCount++;
      }
      if (visibleMarkerCount > threshold) {
        break;
      }
    }

    console.debug('visibleMarkerCount >= ', visibleMarkerCount);
    return visibleMarkerCount >= threshold;
  }
  emitToggleMap() {
    this.toggleMap.emit();
  }

  emitToggleWeather() {
    this.toggleWeather.emit();
  }

  emitToggleLayerControlSideBar() {
    this.toggleLayerControlSideBar.emit();
  }

  emitToggleClusters() {
    this.toggleClusters.emit();
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'unleash-confirm-close-dialog',
  templateUrl: './confirm-close-dialog.component.html',
  styleUrls: ['./confirm-close-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, MatButtonModule, TranslateModule],
  standalone: true
})
export class ConfirmCloseDialogComponent {}

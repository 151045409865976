import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {TeamRole} from '@app/profile/models/team.model';
import {UserTeamAction, UserTeamTable, UserTeamTableActions} from '@app/profile/models/user-team.model';
import {Roles, UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-user-teams',
  templateUrl: './user-teams.component.html',
  styleUrls: ['./user-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTeamsComponent {
  @Input() public userTeams: UserTeamTable[] = [];
  @Input() public menuItems: UserTeamTableActions[] = [];
  @Input() public disableAddUserTeam: boolean = false;
  @Input() public selectedUser: UserTable = {email: '', userId: '0', lastActive: 0, name: '', role: TeamRole.viewer};
  @Input() public roles: Roles = {};
  @Output() public addUserTeam: EventEmitter<void> = new EventEmitter();
  @Output() public searchUserTeam: EventEmitter<string> = new EventEmitter();
  @Output() public userTeam: EventEmitter<UserTeamTable> = new EventEmitter();
  @Output() public action: EventEmitter<UserTeamAction> = new EventEmitter();

  public performSearch(search: string) {
    this.searchUserTeam.emit(search);
  }

  public emitAddUserTeam(): void {
    this.addUserTeam.emit();
  }

  public emitUserTeam(userTeam: UserTeamTable): void {
    this.userTeam.emit(userTeam);
  }

  public emitAction(action: UserTeamAction): void {
    this.action.emit(action);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyModel} from '@app/core/models/api/company-model';
import {UserModel} from '@app/core/models/api/user-model';
import {UserProfile} from './your-profile.model';

@Component({
  selector: 'unleash-your-profile',
  templateUrl: './your-profile.component.html',
  styleUrls: ['./your-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourProfileComponent implements OnInit {
  @Input() public userProfileForm: Partial<UserProfile>;
  @Input() public isInProgress: boolean;
  @Input() public industries: string[];
  @Input() public isDeveloperMode: boolean;
  @Input() public currentUser: UserModel;
  @Input() public currentUserCompany: CompanyModel;
  @Output() public continue: EventEmitter<UserProfile> = new EventEmitter<UserProfile>();

  public form: UntypedFormGroup;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.createForm();
    this.setFormValues();
  }

  public emitContinue(): void {
    this.continue.emit(this.form.value);
  }

  private createForm(): void {
    const UntypedFormGroup = this.isDeveloperMode
      ? {
          publisherName: ['', Validators.required],
          phone: [null, [Validators.pattern('[0-9 ]{11}')]]
        }
      : {
          organization: ['', Validators.required],
          industry: ['', Validators.required],
          phone: [null, [Validators.pattern('[0-9 ]{11}')]]
        };

    this.form = this.fb.group(UntypedFormGroup);
  }

  private setFormValues(): void {
    const formValue = this.isDeveloperMode
      ? {
          publisherName: this.userProfileForm?.publisherName || this.currentUser?.username,
          phone: this.userProfileForm?.phone || this.currentUserCompany?.phone
        }
      : {
          organization: this.userProfileForm?.organization || this.currentUserCompany?.name,
          industry: this.userProfileForm?.industry || this.currentUserCompany?.industry,
          phone: this.userProfileForm?.phone || this.currentUserCompany?.phone
        };

    this.form.patchValue(formValue);
  }
}

import {Component, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {CalibrationService} from '../../services/calibration.service';
import {CalibrationLayout} from '../../calibration.component';
import {ColorPickerComponent} from '@app/shared/color-picker/color-picker.component';
import {AsyncPipe, NgIf} from '@angular/common';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {Subject, shareReplay, take, filter} from 'rxjs';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {AnalyticsConfig} from '@app/core/models/api/user-device.model';
import {DeviceService} from '@app/core/services/api/device.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DecimalInputMaskDirective} from '@app/shared/decimal-input-directive/decimal-input-mask.directive';
import {MtxTooltipModule} from '@ng-matero/extensions/tooltip';

@Component({
  selector: 'ua-calibration-perspective',
  templateUrl: './calibration-perspective.component.html',
  styleUrls: ['./calibration-perspective.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    ColorPickerComponent,
    AsyncPipe,
    LaddaModule,
    NgIf,
    MatButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDividerModule,
    DecimalInputMaskDirective,
    MtxTooltipModule
  ]
})
export class CalibrationPerspectiveComponent implements OnInit {
  public selectedColor$ = this.calibrationService.selectedColor$;
  private isSaving = new Subject<boolean>();
  public isSaving$ = this.isSaving.asObservable().pipe(shareReplay());
  public calibrationShape$ = this.calibrationService.calibrationShape$;

  public perspectiveForm = this.fb.group({
    a: [
      null,
      [Validators.required, Validators.pattern(/^(?!0+(\.0+)?$)[1-9]\d{0,5}(\.\d{1,2})?$/), Validators.maxLength(6)]
    ],
    b: [
      null,
      [Validators.required, Validators.pattern(/^(?!0+(\.0+)?$)[1-9]\d{0,5}(\.\d{1,2})?$/), Validators.maxLength(6)]
    ]
  });

  constructor(
    private calibrationService: CalibrationService,
    private fb: FormBuilder,
    private deviceService: DeviceService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.calibrationService.loadCalibrationPoints$.pipe(take(1)).subscribe(points => {
      if (points?.length === 4) {
        this.calibrationService.emitDrawPerspectiveZone(points, true);
      } else {
        this.calibrationService.emitStartDrawPerspectiveZone();
      }
    });

    this.calibrationService.loadCalibrationZoneDimensions$
      .pipe(
        filter(points => !!points),
        take(1)
      )
      .subscribe(point => {
        this.perspectiveForm.controls.a.setValue(point.a as any);
        this.perspectiveForm.controls.b.setValue(point.b as any);
      });
  }

  public emitBackToCalibrationMainView(): void {
    this.calibrationService.emitStopDrawPerspective();
    this.calibrationService.restoreSelectedColor();
    this.calibrationService.loadCalibrationPoints$.pipe(take(1)).subscribe(points => {
      this.calibrationService.emitDeleteAllPoints();
      if (points.length === 0) {
        this.calibrationService.setCurrentLayout(CalibrationLayout.empty);
      } else {
        this.calibrationService.setCurrentLayout(CalibrationLayout.list);
        this.calibrationService.emitDrawPerspectiveZone(points, false);
      }
    });
  }

  public emitColor(color: LabelColorName): void {
    this.calibrationService.setSelectedColor(color);
  }

  public emitCancel(): void {
    this.calibrationService.emitStopDrawPerspective();
    this.calibrationService.restoreSelectedColor();
    this.calibrationService.loadCalibrationPoints$.pipe(take(1)).subscribe(points => {
      this.calibrationService.emitDeleteAllPoints();
      if (points.length === 0) {
        this.calibrationService.setCurrentLayout(CalibrationLayout.empty);
      } else {
        this.calibrationService.setCurrentLayout(CalibrationLayout.list);
        this.calibrationService.emitDrawPerspectiveZone(points, false);
      }
    });
  }

  public getClibrationShape(): void {
    this.calibrationService.emitGetCalibrationShape();
  }

  public emitForm(): void {
    this.getClibrationShape();
    this.calibrationShape$.pipe(take(1)).subscribe((zone: number[][]) => {
      if (this.perspectiveForm.invalid) {
        this.perspectiveForm.markAllAsTouched();
        return;
      }
      if (zone.length !== 4) {
        return;
      }

      this.isSaving.next(true);

      const perspectiveSize = this.perspectiveForm.value;

      const analyticsConfig = {
        [this.calibrationService.addonId]: {
          analytics: {
            ul_camera_calibration_analytics: {
              calibration_points: zone.map((point, index) => {
                return {
                  x: point[0] / this.calibrationService.imageWidth,
                  y: point[1] / this.calibrationService.imageHeight,
                  x2: parseFloat(perspectiveSize.a),
                  y2: parseFloat(perspectiveSize.b)
                };
              }),
              color: this.calibrationService.getSelectedColor()
            }
          }
        }
      } as AnalyticsConfig;

      this.deviceService
        .updateDevice(this.calibrationService.deviceId, {analyticsConfig})
        .pipe(take(1))
        .subscribe(() => {
          this.isSaving.next(false);
          this.calibrationService.storePerspectivePoints(zone);
          this.calibrationService.storePerspectiveZoneDimensions(
            this.perspectiveForm.value as any as {a: number; b: number}
          );

          this.calibrationService.setCurrentLayout(CalibrationLayout.list);
          this.calibrationService.updateAnalyticsConfig(this.calibrationService.deviceId, analyticsConfig);
          this.calibrationService.emitDeleteAllPoints();
          this.calibrationService.setCalibrationLabel(true);
          this.calibrationService.emitDrawPerspectiveZone(zone, false);

          this.snackbar.open('Perspective calibration added', null, {
            duration: 3000,
            panelClass: 'confirm-scene-mapping-snackbar__scene-add'
          });
        });
    });
  }
}

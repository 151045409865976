<form [class.main-menu]="mainMenuStyle" class="form" [formGroup]="form" [class.full-input]="active">
  <mat-form-field
    class="form-field"
    [class.white-input]="whiteVersion"
    [ngClass]="{large: isLargeInput}"
    appearance="fill"
  >
    <p class="form-field__title" *ngIf="title">{{ title }}</p>
    <input
      #searchInput
      [attr.data-test-id]="'search-' + formControlName + '-input'"
      matInput
      (input)="searchInputChanges($event.target.value)"
      [placeholder]="customPlaceHolder != null ? customPlaceHolder : ('common.search' | translate)"
      [value]="value"
      [formControlName]="formControlName"
      (keydown.Space)="$event.stopPropagation()"
      autocomplete="off"
    />
    <mat-icon *ngIf="hasIcon" [class.white-input]="whiteVersion" class="search-icon" matPrefix>search</mat-icon>
  </mat-form-field>
</form>

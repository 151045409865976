import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'unleash-leave-edit-mode',
  templateUrl: './leave-edit-mode.component.html',
  styleUrls: ['./leave-edit-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveEditModeComponent {
  constructor(private dialogRef: MatDialogRef<LeaveEditModeComponent>) {}
  public leavePage(): void {
    this.dialogRef.close(true);
  }
}

<ng-container *ngIf="deleteReportType" [ngSwitch]="deleteReportType">
  <ng-container *ngSwitchCase="deleteReportTypes.PDF">
    <h2 class="analysis-delete__title" mat-dialog-title>
      {{ 'analysis.deleteDialog.pdf' | translate }}
    </h2>
  </ng-container>
  <ng-container *ngSwitchCase="deleteReportTypes.AI">
    <h2 class="analysis-delete__title" mat-dialog-title>
      {{ 'analysis.deleteDialog.ai' | translate }}
    </h2>
  </ng-container>
  <ng-container *ngSwitchCase="deleteReportTypes.MULTIPLE">
    <h2 class="analysis-delete__title" mat-dialog-title>
      {{ 'analysis.deleteDialog.multiple' | translate }}
    </h2>
  </ng-container>
</ng-container>

<p class="analysis-delete__confirm">{{ 'analysis.deleteDialog.confirm' | translate }}</p>

<div class="analysis-delete__actions" mat-dialog-actions align="end">
  <button (click)="emitCancel()" mat-button color="primary">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <button (click)="emitRename()" mat-button color="warn">
    {{ 'common.delete' | translate | uppercase }}
  </button>
</div>

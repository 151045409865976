import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {NotificationModel} from '../models/api/notifications.model';

import {notificationModelEntityAdapter, NotificationsState} from './notifications.state';

const getError = (state: NotificationsState): any => state.error;

const getIsLoading = (state: NotificationsState): boolean => state.isLoading;

const getUnreadNotificationsCount = (state: NotificationsState): number => state.unreadNotificationsCount;

const getNewNotification = (state: NotificationsState): NotificationModel => state.newNotification;

const selectNotificationsState: MemoizedSelector<
  object,
  NotificationsState
> = createFeatureSelector<NotificationsState>('notifications');

const selectAllNotifications = notificationModelEntityAdapter.getSelectors().selectAll;

export const selectNotificationsById = (id: string) =>
  createSelector(selectAllNotifications, (allMyFeatures: NotificationModel[]) => {
    if (allMyFeatures) {
      return allMyFeatures.find(p => p.sourceCreatedAt === id);
    }
    return null;
  });

export const selectNotificationsByStatus = (inProgress: boolean) =>
  createSelector(selectAllNotifications, (notifications: NotificationModel[]) => {
    if (!!notifications) {
      return notifications.filter(n => n.isInProgress === inProgress);
    }
    return null;
  });

export const selectNotifications: MemoizedSelector<object, NotificationModel[]> = createSelector(
  selectNotificationsState,
  selectAllNotifications
);

export const selectUnreadNotificationsCount: MemoizedSelector<object, number> = createSelector(
  selectNotificationsState,
  getUnreadNotificationsCount
);

export const selectNotificationsInProgress: MemoizedSelector<object, any> = createSelector(
  selectNotificationsState,
  selectNotificationsByStatus(true)
);

export const selectNewNotification: MemoizedSelector<object, NotificationModel | undefined> = createSelector(
  selectNotificationsState,
  getNewNotification
);

export const selectNotificationsNotInProgress: MemoizedSelector<object, any> = createSelector(
  selectNotificationsState,
  selectNotificationsByStatus(false)
);

export const selectNotificationsError: MemoizedSelector<object, any> = createSelector(
  selectNotificationsState,
  getError
);

export const selectNotificationsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectNotificationsState,
  getIsLoading
);

import {Injectable} from '@angular/core';
import {AssetType, AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {environment} from 'environments/environment';
import {Control, LayerGroup, Map, TileLayer} from 'leaflet';
import {atlasConfig} from '../atlas.config';
import {AtlasService} from './atlas.service';
import 'leaflet-side-by-side';
import {NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, filter, take} from 'rxjs';
import {GeojsonAssetLoaderService} from './geojson-asset-loader.service';
import {createMapIcon} from '../marker-icons/custom-map-pointer';

declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class CompareLayersService {
  public rightPane: HTMLElement;
  public leftPane: HTMLElement;
  private sliderControl: Control;
  private paneCounter: number = 0;
  private map: Map;
  public newMap: Map;
  public compareLayersCounter = 0;

  private isDisplayingCompareLayer = new BehaviorSubject<boolean>(false);
  public isDisplayingCompareLayer$ = this.isDisplayingCompareLayer.asObservable();

  constructor(
    private atlasService: AtlasService,
    private router: Router,
    private geojsonAssetLoaderService: GeojsonAssetLoaderService
  ) {}

  public listenNavigation(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        take(1)
      )
      .subscribe(() => {
        if (this.sliderControl) {
          this.resetCompareLayers(this.map);
        }
      });
  }

  public setCompareLayerMap(map: Map) {
    this.newMap = map;
  }

  public compareLayers(map: Map): void {
    this.moveMarkersPopup('.leaflet-pane.leaflet-popup-pane', '.leaflet-container');
    this.map = map;
    map.invalidateSize();

    this.isDisplayingCompareLayer.next(true);
    this.newMap.invalidateSize();
    this.atlasService.toogleHasToDetectChanges();

    setTimeout(() => {
      this.newMap.invalidateSize();
      this.newMap.fitBounds(this.atlasService.selectedLayers[0].bounds, atlasConfig.FIT_BOUNDS_OPTIONS);

      this.rightPane = this.newMap.createPane(`right`);
      this.leftPane = this.newMap.createPane(`left`);

      const rightLayers = this.getVisibleLayersOnRightSide(this.newMap);
      const leftLayers = this.getVisibleLayersOnLeftSide(this.newMap);

      L.layerGroup([...leftLayers]).addTo(this.newMap);
      L.layerGroup([...rightLayers]).addTo(this.newMap);

      this.sliderControl = L.control
        .sideBySide([L.layerGroup().addTo(this.newMap), ...leftLayers], rightLayers)
        .addTo(this.newMap);
      this.atlasService.toogleHasToDetectChanges();
      // eslint-disable-next-line no-magic-numbers
    }, 500);
  }

  private getVisibleLayersOnLeftSide(map: Map): any[] {
    const assetOnLeft = this.classifyMakersLayers();
    return assetOnLeft
      .flatMap((layer: any) => {
        switch (layer.type) {
          case AssetType.GEOJSON:
            return this.createGeoJsonFromAsset(layer, map, 'left');
          case AssetType.ORTHOPHOTOMAP:
            return this.createOrthoLayer(layer, map, 'left');
        }
      })
      .flatMap(layer => this.discardInvisibleLayers(map, [layer] as any));
  }

  private getVisibleLayersOnRightSide(map: Map): any[] {
    return this.atlasService.selectedLayers
      .flatMap((layer: any) => {
        switch (layer.type) {
          case AssetType.GEOJSON:
            return this.createGeoJsonFromAsset(layer, map, 'right');
          case AssetType.ORTHOPHOTOMAP:
            return this.createOrthoLayer(layer, map, 'right');
        }
      })
      .flatMap(layer => this.discardInvisibleLayers(map, [layer] as any));
  }

  public resetCompareLayers(map: Map): void {
    this.restorePopupPosition();
    this.moveMarkersPopup('.leaflet-pane.leaflet-popup-pane', '.leaflet-pane.leaflet-map-pane');
    this.rightPane.remove();
    this.leftPane.remove();
    this.newMap.removeControl(this.sliderControl);
    this.sliderControl = null;
    this.paneCounter++;

    this.atlasService.selectedLayers.forEach(asset => {
      asset.isDisplaying = true;
    });
    this.isDisplayingCompareLayer.next(false);
    map.fitBounds(this.atlasService.selectedLayers[0].bounds, atlasConfig.FIT_BOUNDS_OPTIONS);

    this.atlasService.toogleHasToDetectChanges();
  }

  public reOpenCompareLayers(map: Map): void {
    if (this.sliderControl) {
      this.resetCompareLayers(map);
      setTimeout(() => {
        this.compareLayers(map);
        // eslint-disable-next-line no-magic-numbers
      }, 500);
    }
  }

  public increaseCompareLayerCounter(): void {
    this.compareLayersCounter++;
  }

  private createGeoJsonFromAsset(asset, map, paneName: string): LayerGroup {
    return L.geoJSON((asset as AtlasGeojsonAssetModel).geojson, {
      pane: paneName,
      style: feature => {
        return {
          color: asset.color || asset.strokeColor || feature.properties.stroke || 'red',
          weight: asset.weight || feature.properties['stroke-width'] || 1,
          opacity: feature.properties['stroke-opacity'] || 1,
          fillColor: asset.fillColor || feature.properties.fill,
          fillOpacity: 0.05
        };
      },
      pointToLayer: (feature, latlng) => {
        return L.marker(latlng, {
          pane: paneName,
          icon: createMapIcon({
            size: [25, 41],
            color: asset?.color || feature.properties?.color || atlasConfig.defaultMarkerColor,
            className: ''
          }),
          bubblingMouseEvents: false
        });
      },
      onEachFeature: (feature, layer) => this.geojsonAssetLoaderService.onEachFeature(feature, layer, asset, map)
    });
  }

  private createOrthoLayer(layer, map, paneName: string): TileLayer {
    return L.tileLayer(`${environment.MODELS_CF_CDN}/${layer.key}`, {
      pane: paneName,
      custom: true,
      tms: true,
      noWrap: true,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      bounds: layer.bounds
    });
  }

  private classifyMakersLayers(): any[] {
    return this.atlasService.assets
      .filter(asset => !this.atlasService.selectedLayers.some(selectedLayer => selectedLayer.id === asset.id))
      .filter(asset => asset.isDisplaying);
  }

  private moveMarkersPopup(classToMove: string, container: string) {
    const moveElement = document.querySelector(classToMove);
    const leafletTopLeft = document.querySelector(container);
    leafletTopLeft.insertBefore(moveElement, leafletTopLeft.firstChild);
  }

  private restorePopupPosition() {
    const popupContainer = document.querySelector('.leaflet-pane.leaflet-popup-pane');
    (popupContainer as HTMLElement).style.transform = null;
  }

  private discardInvisibleLayers(map: Map, assets: any[]): any[] {
    const mapBounds = map.getBounds();
    const filteredLayers = assets.filter((layer: any) => {
      if (!layer) {
        return false;
      }

      if (layer instanceof L.Marker) {
        const point = layer.getLatLng ? layer.getLatLng() : null;
        return !!point && mapBounds.contains(point);
      } else {
        let layerBounds = layer.getBounds ? layer.getBounds() : null;

        if (!layerBounds && layer?.options?.bounds) {
          layerBounds = L.latLngBounds(layer.options.bounds);
        }

        if (!layerBounds) {
          return false;
        }

        if (!layerBounds.getSouthWest() || !layerBounds.getNorthEast()) {
          return true;
        }

        const hasToKeepVisibleLayer = layerBounds && mapBounds.contains(layerBounds);
        return hasToKeepVisibleLayer;
      }
    });
    return filteredLayers;
  }
}

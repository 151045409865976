import {DatePipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import {DeviceContextMenuComponent} from '@app/atlas/components/device-context-menu/device-context-menu.component';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {FlightFrame} from '@app/flights/components/flight-log/flight-log-parser';
import {createDeviceGimYawIcon, createDeviceIcon} from '@app/flights/device-icon';
import {SensorDataConnectionService} from '@app/flights/services/sensor-data-connection.service';
import {SensorsDataIotService} from '@app/flights/services/sensors-data-iot.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {ThumblerPipe} from '@app/shared/pipes/thumbler.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  LatLng,
  latLng,
  LatLngExpression,
  Layer,
  Map,
  Marker,
  marker,
  MarkerOptions,
  point,
  polyline,
  Polyline
} from 'leaflet';
import 'leaflet-rotatedmarker';
import {Observable, skip, Subscription} from 'rxjs';
import {IoTSensorsData} from '@app/flights/models/remote-cockpit.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'atlas-device',
  templateUrl: './atlas-device.component.html',
  styleUrls: ['./atlas-device.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlasDeviceComponent implements OnInit, OnDestroy {
  @Input() public map: Map;
  @Input() public isStreaming$: Observable<boolean>;
  @Input('device')
  public set setDevice(device: UserDeviceJoined) {
    if (device) {
      this.device = {...device};
    }
  }
  @Input() public timeControlsVisible: boolean = true;
  @Input() public hideTooltip: boolean = false;

  @Output() public layersCreated: EventEmitter<Layer[]> = new EventEmitter<Layer[]>();
  @Output() public toggleStream: EventEmitter<string> = new EventEmitter();
  @Output() public emitIotData: EventEmitter<IoTSensorsData> = new EventEmitter();

  public isStreaming: boolean = false; // caches previous state
  public device: UserDeviceJoined;
  public flightFrames: FlightFrame[] = [];
  public currentFrame: FlightFrame = {} as any;
  private droneMarker: Marker | any; // use any because of missing typing in leaflet-rotatedmarker
  private positionDroneMarker: Marker | any;
  private realTimePathPolyline: Polyline;
  private panned: boolean;
  private isStreamingSub: Subscription;
  // private mock: Subscription; // unsubscribe mocked data on stop streaming
  public THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private thumblerPipe: ThumblerPipe,
    private sensorDataIoTService: SensorsDataIotService,
    private sensorDataConnectionService: SensorDataConnectionService,
    private datePipe: DatePipe
  ) {}

  public ngOnDestroy(): void {
    this.isStreaming = null;
    this.droneMarker = null;
    this.removePositionMarker();
  }

  public ngOnInit(): void {
    this.initMap();
    this.isStreamingSub = this.isStreaming$.subscribe(isStreaming => {
      if (!this.droneMarker || this.isStreaming === isStreaming) {
        return;
      }
      this.isStreaming = isStreaming;
      if (isStreaming) {
        this.subscribeToDeviceTrack(this.device);
        return;
      }
      if (this.positionDroneMarker) {
        this.map.removeLayer(this.positionDroneMarker);
      }
    });
  }

  private subscribeToDeviceTrack(device: UserDeviceJoined): void {
    //assign to mock attribute when use mock subscription
    this.sensorDataIoTService
      .subscribe(device.id)
      // .subscribeToMockData(device.id)
      .pipe(skip(1))
      .subscribe((data: IoTSensorsData) => {
        this.emitIotData.emit(data);
        const flightFrame = this.sensorDataConnectionService.mapIoTDataToFlightFrame(data);
        this.flightFrames.push(flightFrame);
        this.newFrame({frameNum: this.flightFrames.length - 1});
      });
  }

  public newFrame({frameNum, redraw}: {frameNum: number; redraw?: boolean}) {
    if (!this.flightFrames || this.flightFrames.length == 0) {
      console.warn('Empty frames information');
      return;
    }
    const frame = this.flightFrames[frameNum];
    if (!frame.latitude || !frame.longitude) {
      console.warn('Flight map: Missing latlng information');
      return;
    }
    const dronePosition = latLng(frame.latitude, frame.longitude);
    this.panTo(frame, dronePosition);
    this.droneMarker.setLatLng(dronePosition);
    if (this.device) {
      this.createPositionMarker(dronePosition, frame.yaw, frame.gimYaw);
    }
    this.currentFrame = frame;

    if (redraw) {
      // used for jumping in time
      this.realTimePathPolyline.setLatLngs(
        this.flightFrames.slice(0, frameNum).map(f => latLng(f.latitude, f.longitude))
      );
    } else {
      if (frame.latitude === 0) {
        return;
      }
      // just mark the next frame, don't redraw
      this.realTimePathPolyline.addLatLng(dronePosition);
    }
  }

  private removePositionMarker(): void {
    if (this.positionDroneMarker) {
      this.map.removeLayer(this.positionDroneMarker);
      this.positionDroneMarker = null;
    }
  }

  private createPositionMarker(dronePosition, yaw, gimYaw): void {
    this.removePositionMarker();
    this.positionDroneMarker = marker(this.getInitialMapCenter(), {
      rotationOrigin: 'center center'
    } as MarkerOptions | any).addTo(this.map);
    this.positionDroneMarker.setLatLng(dronePosition);
    this.positionDroneMarker.setIcon(
      createDeviceGimYawIcon({
        selected: false,
        size: 40,
        droneYaw: yaw,
        gimYaw: gimYaw
      })
    );
  }

  private getInitialMapCenter(): LatLng {
    if (!this.flightFrames || this.flightFrames.length == 0) {
      if (!!this.device.lat && !!this.device.lng) {
        return latLng(this.device.lat, this.device.lng);
      }
      return latLng(0, 0);
    }
    const firstFrame = this.flightFrames[0];
    return latLng(firstFrame.latitude, firstFrame.longitude);
  }

  private initMap() {
    const logo = this.thumblerPipe.transform(
      this.device.logo,
      THUMBLER_AVAILABLE_CONFIGS.atlasDeviceThumbLogo
    ) as string;

    this.isStreamingSub = this.isStreaming$.subscribe(isStreaming => {
      if (this.droneMarker) {
        isStreaming ? this.setDefaultPopup() : this.setOfflinePopup();
        if (isStreaming) {
          this.setDefaultPopup();
        } else {
          this.device.lastSeen = new Date().getTime();
          this.setOfflinePopup();
        }

        this.droneMarker.setIcon(
          createDeviceIcon(
            {
              selected: false,
              size: 40,
              iconUrl: logo,
              iconText: `${this.device.name} ${isStreaming ? '' : '(offline)'}`
            },
            !isStreaming
          )
        );
        return;
      }

      this.droneMarker = marker(this.getInitialMapCenter(), {
        icon: createDeviceIcon(
          {
            selected: false,
            size: 40,
            iconUrl: logo,
            iconText: `${this.device.name} ${isStreaming ? '' : '(offline)'}`
          },
          !isStreaming
        ),
        rotationOrigin: 'center center',
        isDeviceMarker: true
      } as MarkerOptions | any);

      isStreaming ? this.setDefaultPopup() : this.setOfflinePopup();

      this.realTimePathPolyline = polyline([], {
        color: '#ffff00',
        weight: 5,
        opacity: 1
      });

      const layers: Layer[] = [this.droneMarker, this.realTimePathPolyline];

      if (!!this.flightFrames) {
        const recordedPathPolyline = polyline(this.getRecorderPath(), {
          color: '#ffffff',
          weight: 4,
          opacity: 0.3
        });
        layers.push(recordedPathPolyline);
      }
      this.layersCreated.emit(layers);
    });
  }

  private setOfflinePopup(): void {
    if (!this.device?.lastSeen || this.hideTooltip) {
      return;
    }
    const date = this.device?.lastSeen;
    const formattedDate = `${this.datePipe.transform(date, 'y MMM')} '${this.datePipe.transform(
      date,
      'dd'
    )}, ${this.datePipe.transform(date, 'h:mm:ss a')}`;
    const markerPopup = document.createElement('div');
    markerPopup.innerHTML = `Last seen ${formattedDate}`;
    this.droneMarker.bindPopup(markerPopup, {closeButton: false, offset: point(20, 80), className: 'offline-popup'});
    this.droneMarker.on('mouseover', () => {
      this.droneMarker?.openPopup();
    });

    this.droneMarker.on('mouseout', () => {
      this.droneMarker?.closePopup();
    });
  }

  private setDefaultPopup(): void {
    const markerPopup: HTMLElement = this.compilePopup(DeviceContextMenuComponent, c => {
      c.instance.device = this.device;
      c.instance.toggleStream.subscribe(event => {
        this.toggleStream.emit(event);
      });
    });

    this.droneMarker.bindPopup(markerPopup, {
      closeButton: false,
      offset: point(13, 0),
      minWidth: 200,
      autoPan: true,
      className: 'light-popup'
    });

    this.droneMarker.on('contextmenu', () => {
      this.droneMarker.openPopup();
    });

    this.droneMarker.off('click');
    this.droneMarker.off('mouseover');
    this.droneMarker.off('mouseout');
  }

  private panTo(frame: FlightFrame, dronePosition: LatLng) {
    if (!this.panned && frame && this.map && !!frame.latitude && !!frame.longitude) {
      this.panned = true;
      this.map.panTo(dronePosition);
      this.droneMarker.setLatLng(dronePosition);
      this.currentFrame = frame;
    }
  }

  private getRecorderPath(): LatLngExpression[] {
    return this.flightFrames.map((frame: FlightFrame) => latLng(frame.latitude, frame.longitude));
  }

  private compilePopup(
    component: typeof DeviceContextMenuComponent,
    onAttach?: (x: ComponentRef<DeviceContextMenuComponent>) => void
  ): HTMLElement {
    const cFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const compRef = this.viewContainerRef.createComponent(cFactory);

    if (onAttach) onAttach(compRef);

    const div = document.createElement('div');
    div.appendChild(compRef.location.nativeElement);
    return div;
  }
}

import * as actions from '@app/flights/store/flight-log/flight-log.actions';
import {createReducer, on} from '@ngrx/store';
import {FlightLogsItem} from '@app/flights/models/flight-logs.model';

export const flightLogsFeatureKey = 'flight-logs';
export interface FlightLogState {
  items: FlightLogsItem[];
}

export const initialState: FlightLogState = {
  items: null
};

export const reducer = createReducer(
  initialState,
  on(actions.saveItems, (state: FlightLogState, action: {payload: {flightLogsItems: FlightLogsItem[]}}) => {
    return {
      items: action.payload.flightLogsItems
    };
  }),
  on(actions.updateFlightLogItem, (state: FlightLogState, action: {payload: {flightLogItem: FlightLogsItem}}) => {
    const updatedItem = action.payload.flightLogItem;
    const flightLogsItems = [...state.items];
    const updatedItemIndex = flightLogsItems.findIndex(item => item.id === updatedItem.id);
    if (updatedItemIndex !== -1) {
      flightLogsItems[updatedItemIndex] = updatedItem;
    }
    return {
      ...state,
      items: flightLogsItems
    };
  }),
  on(actions.updateCheckedFlightLogsItems, (state: FlightLogState, action: {payload: {checked: boolean}}) => {
    const flightLogsItems = [...state.items];
    const updatedItems = flightLogsItems.map(item => ({...item, checked: action.payload.checked}));
    return {
      ...state,
      items: updatedItems
    };
  }),
  on(
    actions.deleteFlightLogsItemsSuccess,
    (state: FlightLogState, action: {payload: {flightLogsItemsIds: string[]}}) => {
      const flightLogsItems = [...state.items];
      const flightLogsToDelete = action.payload.flightLogsItemsIds;
      const updatedItems = flightLogsItems.filter(currentItem => !flightLogsToDelete.includes(currentItem.id));
      return {
        ...state,
        items: updatedItems
      };
    }
  )
);

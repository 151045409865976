<div class="container">
  <ng-container *ngIf="isOnlyPayments; else allPaymentForm">
    <form class="form" [formGroup]="nameForm" action="/charge" method="post" id="payment-form">
      <mat-form-field class="name-input" appearance="fill">
        <mat-label>{{ 'paymentCard.nameOnCard' | translate }}</mat-label>
        <input #cardName matInput formControlName="name" placeholder="Name on card" />
        <mat-error
          *ngIf="nameForm.controls.name.invalid && (nameForm.controls.name.touched || nameForm.controls.name.dirty)"
          >{{ 'paymentCard.nameOnCardIsRequired' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="form-item__container">
        <div class="form-item">
          <div id="card-number" class="input empty"></div>
          <label class="label" for="card-number">{{ 'paymentCard.cardNumber' | translate }}</label>
          <div class="baseline"></div>
        </div>
      </div>
      <div class="form-item__container">
        <div class="form-item form-item--big">
          <div id="card-expiry" class="input empty"></div>
          <label class="label" for="card-expiry">{{ 'paymentCard.expiration' | translate }}</label>
          <div class="baseline"></div>
        </div>
        <div class="form-item form-item--small">
          <div id="card-cvc" class="input empty"></div>
          <label class="label" for="card-cvc">{{ 'paymentCard.CVV' | translate }}</label>
          <div class="baseline"></div>
        </div>
      </div>
      <mat-error class="card-error" *ngIf="cardError$ | async as cardError">{{ cardError }}</mat-error>
    </form>
  </ng-container>
  <ng-template #allPaymentForm>
    <div class="title">
      <div class="title__text">{{ 'paymentCard.billingCycle' | translate }}</div>
      <div class="title__sub-text">{{ 'paymentCard.billingCycleDescription' | translate }}</div>
    </div>
    <div *ngIf="plan" class="billing-cycle">
      <ng-container *ngIf="plan.model">
        <form class="form" [formGroup]="billingCycleForm">
          <mat-radio-group formControlName="paymentPeriod">
            <unleash-mini-card-option
              *ngIf="!!plan.model.priceAnnual"
              [value]="paymentPeriod.annual"
              [isSelected]="paymentPeriod.annual === billingCycleForm.value.paymentPeriod"
              (selected)="setPaymentPeriod($event)"
              [isShowingBadge]="true"
            >
              <span slot="badge"
                >{{ 'paymentCard.save' | translate }} ${{
                  (plan.model.priceMonthly - plan.model.priceAnnual) * 12
                }}
                (33% OFF)</span
              >
              <div class="mini-card-content" slot="content">
                <div class="item period-title">{{ 'paymentCard.annual' | translate }}</div>
                <div class="item price">
                  <div class="value">{{ plan.model.priceAnnual }} <span class="currency">USD</span></div>
                  <div class="period">/ {{ 'paymentCard.mount' | translate }}</div>
                </div>
                <div class="item description">
                  {{ formatDollars(plan.model.priceAnnual * 12) }} USD {{ 'paymentCard.billedAnnually' | translate }}
                </div>
              </div>
            </unleash-mini-card-option>
            <unleash-mini-card-option
              *ngIf="!!plan.model.priceMonthly"
              [value]="paymentPeriod.month"
              [isSelected]="paymentPeriod.month === billingCycleForm.value.paymentPeriod"
              (selected)="setPaymentPeriod($event)"
              [otherOptionHasBadge]="true"
            >
              <div class="mini-card-content" slot="content">
                <div class="item period-title">{{ 'paymentCard.monthly' | translate }}</div>
                <div class="item price">
                  <div class="value">{{ plan.model.priceMonthly }} <span class="currency">USD</span></div>
                  <div class="period">/ {{ 'paymentCard.month' | translate }}</div>
                </div>
                <div class="item description">{{ 'paymentCard.billedMonthly' | translate }}</div>
              </div>
            </unleash-mini-card-option>
          </mat-radio-group>
        </form>
      </ng-container>
      <unleash-promo-code #promoCode [isSuccess]="isSuccessPromoCode$ | async" (promoCode)="setupPromoCode($event)">
      </unleash-promo-code>
    </div>
    <div class="title">
      <div class="title__text">
        {{ 'paymentCard.paymentDetails' | translate }}
        <button
          [appLadda]="isPaymentInProgress"
          [disabled]="isPaymentInProgress"
          mat-button
          color="primary"
          (click)="skipPaymentDetails()"
        >
          {{ 'paymentCard.skipForNow' | translate }}
        </button>
      </div>
      <div class="title__sub-text">{{ 'paymentCard.cancelAnyTimeBefore' | translate }} {{ trialEndDate$ | async }}</div>
    </div>
    <div class="img-container">
      <img class="title__img" src="/assets/icons/auth/payment-cards.svg" alt="payment cards" />
    </div>
    <form class="form" [formGroup]="nameForm" action="/charge" method="post" id="payment-form" class="payment">
      <mat-form-field class="name-input" appearance="fill">
        <mat-label>{{ 'paymentCard.billingName' | translate }}</mat-label>
        <input matInput formControlName="name" data-test-id="payment-card-name" placeholder="Billing name" />
        <mat-error
          *ngIf="nameForm.controls.name.invalid && (nameForm.controls.name.touched || nameForm.controls.name.dirty)"
          >{{ 'paymentCard.nameOnCardIsRequired' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="form-item__container">
        <div class="form-item">
          <div id="card-number" class="input empty" data-test-id="payment-card-number"></div>
          <label class="label" for="card-number">{{ 'paymentCard.creditCardNumber' | translate }}</label>
          <div class="baseline"></div>
        </div>
      </div>
      <div class="form-item__container">
        <div class="form-item form-item--big">
          <div id="card-expiry" class="input empty" data-test-id="payment-card-expiration"></div>
          <label class="label" for="card-expiry">{{ 'paymentCard.expiryDate' | translate }}</label>
          <div class="baseline"></div>
        </div>
        <div class="form-item form-item--small">
          <div id="card-cvc" class="input empty" data-test-id="payment-card-cvc"></div>
          <label class="label" for="card-cvc">{{ 'paymentCard.CVV' | translate }}</label>
          <div class="baseline"></div>
        </div>
      </div>
      <mat-error class="card-error" *ngIf="cardError$ | async as cardError">{{ cardError }}</mat-error>

      <div class="stripe">
        <img src="/assets/icons/payment/powered-by-stripe.svg" class="stripe__logo" />
        <p class="stripe__tagline">{{ 'paymentCard.stripeMessage' | translate }}</p>
      </div>
    </form>
    <div class="title">
      <div class="title__text">{{ 'paymentCard.billingAddress' | translate }}</div>
    </div>
    <form class="form" [formGroup]="form" class="billing">
      <mat-form-field class="street-input" appearance="fill">
        <mat-label>{{ 'paymentCard.street' | translate }}</mat-label>
        <input matInput formControlName="street" placeholder="Street" data-test-id="payment-card-street" />
        <mat-error *ngIf="(form.controls.street.invalid && form.controls.street.touched) || form.controls.street.dirty">
          {{ 'paymentCard.streetIsRequired' | translate }}
        </mat-error>
      </mat-form-field>
      <div class="form-row">
        <mat-form-field class="city-input" appearance="fill">
          <mat-label>{{ 'paymentCard.city' | translate }}</mat-label>
          <input matInput formControlName="city" placeholder="City" data-test-id="payment-card-city" />
          <mat-error *ngIf="(form.controls.city.invalid && form.controls.city.touched) || form.controls.city.dirty"
            >{{ 'paymentCard.cityIsRequired' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="postcode-input" appearance="fill">
          <mat-label>{{ 'paymentCard.postcode' | translate }}</mat-label>
          <input matInput formControlName="postcode" placeholder="Postcode" data-test-id="payment-card-postcode" />
          <mat-error
            *ngIf="(form.controls.postcode.invalid && form.controls.postcode.touched) || form.controls.postcode.dirty"
            >{{ 'paymentCard.postcodeIsRequired' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="country-input" appearance="fill">
        <mat-label>{{ 'paymentCard.country' | translate }}</mat-label>
        <input matInput formControlName="country" placeholder="County" data-test-id="payment-card-country" />
        <mat-error
          *ngIf="(form.controls.country.invalid && form.controls.country.touched) || form.controls.country.dirty"
          >{{ 'paymentCard.countryIsRequired' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </ng-template>
</div>
<button
  *ngIf="!isOnlyPayments"
  mat-raised-button
  color="primary"
  type="button"
  data-test-id="payment-start-trial"
  (click)="startTrial()"
  [appLadda]="isPaymentInProgress$ | async"
  [disabled]="isPaymentInProgress$ | async"
>
  {{ 'paymentCard.freeTrial' | translate }}
</button>

import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MoveMarkerDestination} from '../components/move-markers-dialog/move-markers-dialog.component';
import {AtlasSelectMarkersService} from '../services/atlas-select-markers.service';
import {firstValueFrom, switchMap, take} from 'rxjs';
import {AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Map} from 'leaflet';

@Directive({
  selector: '[ulCopyMarkers]'
})
export class CopyMarkersDirective {
  @Input('ulCopyMarkers') public destination: MoveMarkerDestination;
  @Input() public layerName: string;
  @Input() public groupName: string;
  @Input() public isInProgress: boolean = false;
  @Input() public currentLayer: AtlasGeojsonAssetModel;
  @Input() public existingLayer: AtlasGeojsonAssetModel;
  @Input() public map: Map;
  @Output() public showSpinner: EventEmitter<boolean> = new EventEmitter();

  @HostListener('click', ['$event'])
  public copyMarkers() {
    if (this.elementRef.nativeElement.hasAttribute('disabled')) {
      return;
    }
    this.elementRef.nativeElement.classList.add('mat-button-disabled');
    this.elementRef.nativeElement.setAttribute('disabled', 'true');
    this.showSpinner.emit(true);
    this.destination === MoveMarkerDestination.NEW_LAYER ? this.createNewLayer() : this.addToExistingLayer();
  }

  private async createNewLayer(): Promise<void> {
    this.atlasSelectMarkersService.avoidLoadLocalStorageAssets(true);
    const parameters = {
      layerName: this.layerName,
      currentLayer: this.currentLayer,
      groupName: this.groupName,
      isInProgress: this.isInProgress
    };
    await this.atlasSelectMarkersService.createNewMarkersLayer(parameters, this.map);
    this.atlasSelectMarkersService.reGenerateSelectedMarkers();
    this.atlasSelectMarkersService.clearSelectedMarkers();
    this.atlasSelectMarkersService.setHasToUpdateAssets(true);
    this.closeDialog(this.isInProgress);
  }

  private async addToExistingLayer(): Promise<void> {
    this.atlasSelectMarkersService.avoidLoadLocalStorageAssets(true);
    await firstValueFrom(this.atlasSelectMarkersService.copyMarkers(this.currentLayer, this.existingLayer));
    this.atlasSelectMarkersService.addMarkersToNewLayer(this.existingLayer);
    this.atlasSelectMarkersService.reGenerateSelectedMarkers();
    this.atlasSelectMarkersService.clearSelectedMarkers();
    this.atlasSelectMarkersService.setHasToUpdateAssets(true);
    this.closeDialog();
  }

  private closeDialog(hasInProgressState?: boolean): void {
    this.atlasSelectMarkersService.hasToUpdateAssets
      .pipe(
        take(1),
        switchMap(() =>
          hasInProgressState
            ? this.translateService.get('atlas.copyMarkers.markersCopiedWithState')
            : this.translateService.get('atlas.copyMarkers.markersCopied')
        )
      )
      .subscribe((snackbarMessage: string) => {
        this.showSpinner.emit(false);
        this.snackBar.open(snackbarMessage, null, {
          duration: 3000,
          panelClass: 'center'
        });
      });
  }

  constructor(
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private elementRef: ElementRef
  ) {}
}

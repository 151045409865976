import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {TeamRole} from '@app/profile/models/team.model';
import {UserActions} from '@app/profile/models/user-actions.model';
import {UserTeamAction} from '@app/profile/models/user-team.model';
import {Roles} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-user-role-picker',
  templateUrl: './user-role-picker.component.html',
  styleUrls: ['./user-role-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRolePickerComponent {
  @Input() public userId: string = '';
  @Input() public role: TeamRole = TeamRole.contributor;
  @Input() public roles: Roles = {};
  @Output() public editUserTeamRole: EventEmitter<UserTeamAction> = new EventEmitter();

  public teamRole = TeamRole;

  public emitUserTeamRole(userTeamRole: TeamRole): void {
    this.editUserTeamRole.emit({
      action: UserActions.editRole,
      userId: this.userId,
      payload: userTeamRole
    });
  }
}

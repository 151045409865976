import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {authLogout} from '@app/auth/state/auth.actions';
import {selectAwsMarketplaceToken} from '@app/auth/state/auth.selectors';
import {RegistrationStage} from '@app/auth/state/auth.state';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {actionYourProfileSubmit} from '@app/auth/components/your-profile/your-profile.actions';
import {CompanyCreationPayload, UserProfile} from '@app/auth/components/your-profile/your-profile.model';
import {
  selectYourProfileFormError,
  selectYourProfileFormValue,
  selectYourProfileIsInProgress
} from '@app/auth/components/your-profile/your-profile.selectors';
import {REGISTRATION_STAGE} from '@app/auth/models/auth.models';
import {COUNTRIES, INDUSTRIES, INDUSTRY_DEVELOPER} from '@app/config';
import {CompanyModel} from '@app/core/models/api/company-model';
import {UserModel} from '@app/core/models/api/user-model';
import {UserService} from '@app/core/services/api/user.service';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {DateFormats} from '@app/profile/models/date-format.model';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './your-profile.page.html',
  styleUrls: ['./your-profile.page.scss']
})
export class YourProfilePage implements OnInit {
  public industries: string[] = INDUSTRIES;
  public industryDeveloper: string = INDUSTRY_DEVELOPER;
  public userProfileForm$: Observable<UserProfile>;
  public isInProgress$: Observable<boolean>;
  public hasMarketPlaceToken$: Observable<boolean>;
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public currentUser$: Observable<UserModel> = this.userService.user$;
  public currentUserCompany$: Observable<CompanyModel> = this.userService.myCompany$;
  public errorMessage$: Observable<string>;

  private hasMarketPlaceToken: boolean = false;

  constructor(
    private store: Store,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.setRegistrationStage();
    this.userProfileForm$ = this.store.pipe(select(selectYourProfileFormValue));
    this.isInProgress$ = this.store.pipe(select(selectYourProfileIsInProgress));
    this.hasMarketPlaceToken$ = this.store.pipe(
      select(selectAwsMarketplaceToken),
      map((token: string): boolean => !!token),
      tap((hasToken: boolean): boolean => (this.hasMarketPlaceToken = hasToken))
    );
    this.errorMessage$ = this.store.pipe(
      select(selectYourProfileFormError),
      map(error => {
        if (!error) {
          console.warn('Empty error was thrown');
          return null;
        }
        return error?.message;
      })
    );
  }

  public continue(userProfile: Partial<UserProfile>): void {
    this.submitUserProfile(userProfile);
    this.registerCompany(userProfile);
  }

  public signOut(): void {
    this.store.dispatch(authLogout({isDeveloperMode: this.isDeveloperMode}));
  }

  private submitUserProfile(userProfile: Partial<UserProfile>): void {
    this.store.dispatch(actionYourProfileSubmit({form: userProfile}));
  }

  private registerCompany(userProfile: Partial<UserProfile>): void {
    console.info('registerCompany', userProfile);

    const companyCreationPayload: CompanyCreationPayload = {
      industry: this.isDeveloperMode ? this.industryDeveloper : userProfile.industry,
      name: this.isDeveloperMode ? userProfile.publisherName : userProfile.organization,
      phone: userProfile.phone,
      timezone: 'Australia/Sydney',
      dateFormat: DateFormats.AustralianDateTime
    };

    this.userService.registerCompany(companyCreationPayload);
  }

  private setRegistrationStage(): void {
    if (this.isDeveloperMode) {
      this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.developerDetails);
      return;
    }
    this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.details);
  }
}

import {Injectable} from '@angular/core';
import {PackageNames, PlanModel} from '@app/core/models/api/plan.model';
import {PACKAGE_SELECTOR_STEP_INDEX, PlansByStep, PLAN_MAPPING} from '@app/plans/models/package-selector-steps.model';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';

@Injectable({
  providedIn: 'root'
})
export class PackageSelectorPageService {
  public calcTotalPlanByStep(
    plansByStep: Partial<{'0': PlanModel; '1': PlanModel; '2': PlanModel}>,
    currentStepIndex: number,
    paymentPeriod: PaymentPeriod,
    professionalIndex: number
  ): number {
    const plans = Object.values(plansByStep);
    let totalPrice = 0;
    const iteration = currentStepIndex ? currentStepIndex : PACKAGE_SELECTOR_STEP_INDEX.LIVE_STREAMING;
    const NUMBER_OF_MONTHS = 12;
    const PLAN_ESSENTIAL_PRICE = 149;
    for (let i = 0; i < iteration + 1; i++) {
      if (plans[i]) {
        if (paymentPeriod === PaymentPeriod.month) {
          totalPrice += professionalIndex === i ? plans[i].priceMonthly : PLAN_ESSENTIAL_PRICE;
        } else {
          totalPrice += plans[i].priceMonthly * NUMBER_OF_MONTHS - plans[i].priceAnnual * NUMBER_OF_MONTHS;
        }
      }
    }

    return totalPrice;
  }

  public updateCheckoutPlans(plansByStep: PlansByStep) {
    const keys = Object.keys(plansByStep)
      .map(key => plansByStep[key]?.id)
      .filter(planId => !!planId);

    let packagesObject = null;
    if (keys.length > 0) {
      const levelIndex = 1;
      const level = parseInt(keys[0].split('_')[levelIndex]);
      packagesObject = this.getPlanFromPackages(keys, level);
    } else {
      packagesObject = {name: null, id: null};
    }

    return packagesObject;
  }

  private getPlanFromPackages(packagesIds: string[], level: number): {id: string; name: string} {
    const TWO_PACKAGE_ID_ITEMS = 2;
    if (packagesIds.length === TWO_PACKAGE_ID_ITEMS) {
      return this.mappingPlans(packagesIds, level);
    }

    if (packagesIds.length === 1) {
      return {
        name: packagesIds[0].split('_')[0].toUpperCase(),
        id: packagesIds[0]
      };
    }

    return {name: 'Unleashed', id: `unleashed_${level}`};
  }

  private mappingPlans(plansIds: string[], level: number) {
    const planKey = plansIds
      .map(planId => {
        const planName = planId.split('_')[0];
        switch (planName) {
          case 'broadcast':
            return PLAN_MAPPING.BROADCAST;
          case 'modelling':
            return PLAN_MAPPING.MODELLING;
          case 'insights':
            return PLAN_MAPPING.INSIGHTS;
        }
      })
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

    switch (planKey) {
      case PLAN_MAPPING.OPERATIONAL:
        return {name: 'Operational', id: `operational_${level}`};
      case PLAN_MAPPING.SUPERVISION:
        return {name: 'Supervision', id: `supervision_${level}`};
      case PLAN_MAPPING.CONTROL:
        return {name: 'Control', id: `control_${level}`};
    }
  }

  public mapSelectedPlansIntoCombinedPlan(currentSelectedPlans: PlanModel[], plans: PlanModel[]): PlanModel {
    const THREE_PLANS = 3;

    if (currentSelectedPlans.length === 0 || !plans) {
      return;
    }
    if (currentSelectedPlans.length === 1) {
      return currentSelectedPlans[0];
    }
    if (currentSelectedPlans.length === THREE_PLANS) {
      return plans
        .filter(
          plan => plan.packageName === PackageNames.unleashed && plan.planName === currentSelectedPlans[0].planName
        )
        .pop();
    }
    const isAISelected = currentSelectedPlans.filter(plan => plan.packageName === PackageNames.insights).length > 0;
    const isLivestreamingSelected =
      currentSelectedPlans.filter(plan => plan.packageName === PackageNames.broadcast).length > 0;
    const isModellingSelected =
      currentSelectedPlans.filter(plan => plan.packageName === PackageNames.modelling).length > 0;
    if (isAISelected && isLivestreamingSelected) {
      return plans
        .filter(
          plan => plan.packageName === PackageNames.supervision && plan.planName === currentSelectedPlans[0].planName
        )
        .pop();
    }
    if (isAISelected && isModellingSelected) {
      return plans
        .filter(plan => plan.packageName === PackageNames.control && plan.planName === currentSelectedPlans[0].planName)
        .pop();
    }
    return plans
      .filter(
        plan => plan.packageName === PackageNames.operational && plan.planName === currentSelectedPlans[0].planName
      )
      .pop();
  }
}

import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeveloperComponent {
  @Input('isDeveloperMode')
  set fillDeveloperMode(isDeveloperMode: BooleanInput) {
    this.isDeveloperMode = coerceBooleanProperty(isDeveloperMode);
  }

  @Input()
  public isActivating: boolean = false;

  @Output()
  public activateDeveloperMode: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public turnOffDeveloperMode: EventEmitter<void> = new EventEmitter<void>();

  public isDeveloperMode: boolean;
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel, AtlasAssetPresentable} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {PermissionService} from '@app/core/services/permission.service';
import {GeoJSON} from 'leaflet';
import {b64toBlob, saveBlob} from '@app/core/services/blob.utils';

@Directive({
  selector: '[ulExportShape]',
  standalone: true
})
export class ExportShapeDirective {
  @Input('ulExportShape') public asset: AtlasAssetModel;

  constructor(
    private atlasService: AtlasService,
    private unleashAnalytics: UnleashAnalyticsService,
    private permissionService: PermissionService
  ) {}

  @HostListener('click', ['$event'])
  public exportShape(): void {
    const atlasAssetModel = this.asset;

    if (!this.permissionService.canUseAtlas()) {
      console.error('Atlas plan not enabled');
      return;
    }

    const asset = atlasAssetModel || this.atlasService.selectedLayers[0];
    const leafletLayer: AtlasAssetPresentable['leafletLayer'] = asset.leafletLayer as GeoJSON;
    const geoJSONObj = leafletLayer.toGeoJSON();
    this.atlasService.convertToShp(geoJSONObj, asset.name).subscribe((response: string) => {
      const blob = b64toBlob(response, 'application/zip');
      saveBlob(blob, asset.name);
    });
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {type: 'Export'});
  }
}

import {Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-can-not-convert-to-mission-dialog',
  templateUrl: './can-not-convert-to-mission-dialog.component.html',
  styleUrls: ['./can-not-convert-to-mission-dialog.component.scss'],
  standalone: true,
  imports: [MatButtonModule, TranslateModule, MatDialogModule]
})
export class CanNotConvertToMissionComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

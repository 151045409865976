import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DeleteMissionDialogComponent} from '../components/delete-mission-dialog/delete-mission-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter, switchMap, take} from 'rxjs';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {Mission, MissionSettingsService, SELECTED_WAYPOINT} from '../services/mission-settings.service';

@Directive({
  selector: '[ulDeleteMission]'
})
export class DeleteMissionDirective {
  @Input('ulDeleteMission') public mission: Mission;
  @Output() public updateSelectedMission: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private missionSettingsService: MissionSettingsService
  ) {}

  @HostListener('click', ['$event'])
  public onUpload() {
    const dialog = this.dialog.open(DeleteMissionDialogComponent, {
      ...STANDARD_DIALOG_CONFIG,
      width: '60vw',
      data: {
        mission: this.mission
      }
    });
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter(confirmDelete => !!confirmDelete),
        switchMap(() => this.translateService.get('atlas.mission.deleteMissionSuccess'))
      )
      .subscribe((translation: string) => {
        this.missionSettingsService.isNewRoute.next(false);
        this.updateSelectedMission.emit();
        this.missionSettingsService.editSave();
        this.snackBar.open(translation, null, {panelClass: 'mission-uploaded', duration: 3000});
        this.missionSettingsService.stopDrawing();
        this.missionSettingsService.setEditingMission({
          mission: null,
          selectedWaypointIndex: SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED
        });
        this.missionSettingsService.undoStack.next([]);
        this.missionSettingsService.redoStack.next([]);
      });
  }
}

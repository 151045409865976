<h1 mat-dialog-title>{{ 'atlas.upload.title' | translate }}</h1>
<div mat-dialog-content class="container" fxLayoutGap="20px">
  <mat-horizontal-stepper class="hidden-header-stepper" [linear]="true" #stepper>
    <mat-step>
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2></h2>
        <div class="upload-content-container">
          <ngx-file-drop contentClassName="ua-drop-zone" (onFileDrop)="dropped($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div class="content" fxLayout="row" fxLayout.lt-md="column">
                <mat-icon class="large">add</mat-icon>
                <span class="message">{{ 'atlas.upload.dragdrop' | translate }}</span>
                <span class="hint">{{ 'atlas.upload.acceptedFormats' | translate }}</span>
                <button mat-stroked-button cdkFocusInitial color="primary" (click)="inputFile.click()">
                  {{ 'atlas.upload.selectFile' | translate }}
                </button>
                <input
                  data-test-id="atlas-upload-input"
                  #inputFile
                  type="file"
                  [accept]="ACCEPT_ASSET_FILES"
                  multiple
                  [hidden]="true"
                  [style.display]="'none'"
                  (change)="onInputFileSelection($event)"
                />
              </div>
            </ng-template>
          </ngx-file-drop>
          <ng-container *ngIf="files.length > 0">
            <div class="file-counter">{{ 'atlas.upload.filesToUpload' | translate }}: {{ files.length }}</div>
            <div *ngIf="files.length < 4" class="file-list-container">
              <div class="file-list-item" *ngFor="let file of files">
                <mat-icon>layers</mat-icon>
                <h4 mat-line>{{ file.name }}</h4>
                <p mat-line>{{ file.size | fileSize: 0 }}</p>
                <p mat-line>{{ file.type }}</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
        <div class="loading-files" *ngIf="isUploadingFiles$ | async; else uploadCompleteTemplate">
          <mat-progress-spinner [value]="totalProgress$ | async" [strokeWidth]="2"> </mat-progress-spinner>
          <p>{{ 'atlas.upload.uploadingFiles' | translate }}</p>
          <ng-container *ngTemplateOutlet="loadingDotsTemplate"></ng-container>
        </div>
        <ng-template #uploadCompleteTemplate>
          <div class="processing-files" *ngIf="isProcessingFiles$ | async; else processCompleteTemplate">
            <mat-progress-spinner [value]="totalProcessing$ | async" [strokeWidth]="2"> </mat-progress-spinner>

            <p>{{ 'library.upload.processing' | translate }}</p>
            <ng-container *ngTemplateOutlet="loadingDotsTemplate"></ng-container>
          </div>
          <ng-template #processCompleteTemplate>
            <div class="uploaded-files">
              <p>{{ 'atlas.upload.success' | translate }}</p>

              <div class="circle-loader load-complete">
                <div class="checkmark draw visible"></div>
              </div>
              <p>{{ 'atlas.upload.viewNow' | translate }}</p>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div mat-dialog-actions>
  <button *ngIf="stepper.selectedIndex === 0" mat-button color="primary" mat-dialog-close>
    {{ 'common.cancel' | translate }}
  </button>
  <button
    *ngIf="stepper.selectedIndex === 0"
    data-test-id="next-upload-button"
    mat-raised-button
    color="primary"
    [disabled]="isUploadingFiles$ | async"
    (click)="upload()"
  >
    {{ 'common.next' | translate }}
  </button>

  <button
    *ngIf="stepper.selectedIndex === 1"
    data-test-id="complete-upload-button"
    mat-raised-button
    color="primary"
    [disabled]="(isProcessingFiles$ | async) === true || (isDone$ | async) === false"
    [mat-dialog-close]="true"
  >
    {{ 'common.complete' | translate }}
  </button>
</div>

<ng-template #loadingDotsTemplate>
  <div class="loading">
    <span class="loading__dot"></span>
    <span class="loading__dot"></span>
    <span class="loading__dot"></span>
  </div>
</ng-template>

import {
  animate,
  AnimationGroupMetadata,
  AnimationQueryMetadata,
  group,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';

const left: Array<AnimationQueryMetadata | AnimationGroupMetadata> = [
  query(
    ':enter, :leave',
    style({
      'box-sizing': 'border-box',
      'min-height': 'min-content',
      position: 'fixed',
      top: '0px',
      left: '{{left}}',
      width: '{{width}}',
      padding: '{{padding}}',
      'min-width': '{{width}}',
      height: '100%'
    }),
    {optional: true}
  ),
  group([
    query(
      ':enter',
      [
        style({transform: 'translateX(-100%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateX(0%)'}))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({transform: 'translateX(0%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateX(100%)'}))
      ],
      {
        optional: true
      }
    )
  ])
];

const right: Array<AnimationQueryMetadata | AnimationGroupMetadata> = [
  query(
    ':enter, :leave',
    style({
      'box-sizing': 'border-box',
      'min-height': 'min-content',
      position: 'fixed',
      top: '0px',
      left: '{{left}}',
      width: '{{width}}',
      padding: '{{padding}}',
      'min-width': '{{width}}',
      height: '100%'
    }),
    {optional: true}
  ),
  group([
    query(
      ':enter',
      [
        style({transform: 'translateX(100%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateX(0%)'}))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({transform: 'translateX(0%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateX(-100%)'}))
      ],
      {
        optional: true
      }
    )
  ])
];

const down: Array<AnimationQueryMetadata | AnimationGroupMetadata> = [
  query(
    ':enter, :leave',
    style({
      'box-sizing': 'border-box',
      'min-height': 'min-content',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      position: 'fixed',
      left: '{{left}}',
      width: '{{width}}',
      padding: '{{padding}}',
      'min-width': '{{width}}',
      height: '100%'
    }),
    {optional: true}
  ),
  group([
    query(
      ':enter',
      [
        style({transform: 'translateY(100%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateY(0%)'}))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({transform: 'translateY(0%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateY(-100%)'}))
      ],
      {
        optional: true
      }
    )
  ])
];

const up: Array<AnimationQueryMetadata | AnimationGroupMetadata> = [
  query(
    ':enter, :leave',
    style({
      'box-sizing': 'border-box',
      'min-height': 'min-content',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      position: 'fixed',
      left: '{{left}}',
      width: '{{width}}',
      padding: '{{padding}}',
      'min-width': '{{width}}',
      height: '100%'
    }),
    {optional: true}
  ),
  group([
    query(
      ':enter',
      [
        style({
          transform: 'translateY(-100%)',
          'align-self': 'center'
        }),
        animate('.5s ease-out', style({transform: 'translateY(0%)'}))
      ],
      {
        optional: true
      }
    ),
    query(
      ':leave',
      [
        style({transform: 'translateY(0%)', 'align-self': 'center'}),
        animate('.5s ease-out', style({transform: 'translateY(100%)'}))
      ],
      {
        optional: true
      }
    )
  ])
];

const fadeInUp = [
  style({opacity: '0', transform: 'translateY(10px)'}),
  animate('500ms', style({opacity: '1', transform: 'translateY(0)'}))
];

export const pageAnimation = trigger('routeAnimations', [
  transition(':increment', right),
  transition(':decrement', left),

  transition('up => down', up),
  transition('down => up', down),

  transition('* => down', left),
  transition('* => up', left),

  transition('up => 1', right),
  transition('down => 1', right),

  transition('* => fadeInUp', fadeInUp)
]);

import {Directive, HostListener} from '@angular/core';
import {PermissionService} from '@app/core/services/permission.service';
import {MissionSettingsService} from '../services/mission-settings.service';

@Directive({
  selector: '[ulEditMission]'
})
export class EditMissionDirective {
  constructor(private permissions: PermissionService, private missionSettingsService: MissionSettingsService) {}
  @HostListener('click', ['$event'])
  public editMission() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.missionSettingsService.editRoute();
  }
}

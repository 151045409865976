import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PreventLosePlanChangesGuard} from './guards/prevent-lose-plan-changes.guard';
import {PackageSelectorPage} from './pages/package-selector/package-selector.page';

const routes: Routes = [
  {path: '', redirectTo: '/plans/package-selector', pathMatch: 'full'},
  {
    path: 'package-selector',
    component: PackageSelectorPage,
    canDeactivate: [PreventLosePlanChangesGuard],
    title: 'Package Selector - Unleash live'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlansRoutingModule {}

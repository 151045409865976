<h2 class="dialog__title">
  {{ 'atlas.deviceNotFound.title' | translate }}
</h2>
<mat-dialog-content>
  <p class="dialog__description">
    {{ 'atlas.deviceNotFound.description' | translate }}
  </p>
  <p class="dialog__description">
    {{ 'atlas.deviceNotFound.contactUsDescription' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="goToContactUs()">
    {{ 'atlas.deviceNotFound.contactUs' | translate }}
  </button>
  <button mat-button color="primary" (click)="close()">
    {{ 'common.ok' | translate }}
  </button>
</mat-dialog-actions>

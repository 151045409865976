<div
  fxFlex="0 1 auto"
  fxLayout="column"
  class="model-selector"
  data-amplify-analytics-on="click"
  data-amplify-analytics-name="live_model_select_click"
  *ngIf="device$ | async as device"
>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-select
        formControlName="currentModel"
        placeholder="Choose AI Model"
        [compareWith]="addonIdComparator"
        class="select-input"
      >
        <mat-option [value]="rawStreamId || null"
          ><span class="bold">{{ 'Raw stream' }}</span></mat-option
        >
        <ng-container *ngFor="let model of models">
          <ng-container *uaHasPermission="aclPermissions.LivestreamApiStreamPublish; else viewerTemplate">
            <mat-option [value]="model.id">
              <span class="model-name {{ device?.runningModels.includes(model.id) ? 'online bold' : 'offline' }}">
                {{ model.name }}
              </span>
              <span *ngIf="device?.runningModels.includes(model.id)" class="live bold"> (live)</span>
            </mat-option>
          </ng-container>
          <ng-template #viewerTemplate>
            <mat-option *ngIf="device?.runningModels.includes(model.id)" [value]="model">
              <span class="model-name {{ device?.runningModels.includes(model.id) ? 'online bold' : 'offline' }}">
                {{ model.name }}
              </span>
              <span class="live bold"> (live)</span>
            </mat-option>
          </ng-template>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="option-description">
    {{ form.controls.currentModel.value | uModelDescription | async }}
  </div>
  <div class="stream-controls">
    <ng-container *uaHasPermission="aclPermissions.LivestreamApiStreamPublish">
      <button
        *ngIf="
          form.controls.currentModel.value &&
          form.controls.currentModel.value !== 'RAW_STREAM' &&
          !!device.runningModels &&
          device.runningModels.length > 0 &&
          device.runningModels.includes(form.controls.currentModel.value)
        "
        (click)="stopAI(device, form.controls.currentModel.value)"
        mat-stroked-button
        color="primary"
        class="stream-controls__stop"
      >
        <mat-icon>stop</mat-icon> STOP ANALYSIS
      </button>
    </ng-container>
    <button
      *ngIf="
        !!device.runningModels.includes(form.controls.currentModel.value) ||
        form.controls.currentModel.value === 'RAW_STREAM'
      "
      mat-raised-button
      color="primary"
      (click)="watchModel(device, form.controls.currentModel.value)"
      class="stream-controls__watch"
    >
      WATCH
    </button>
    <div *uaHasPermission="aclPermissions.LivestreamApiStreamPublish">
      <ng-container
        *ngIf="
          !(
            !!device.runningModels.includes(form.controls.currentModel.value) ||
            form.controls.currentModel.value === 'RAW_STREAM'
          )
        "
      >
        <button
          mat-stroked-button
          color="primary"
          (click)="openZonesDialog(device, form.controls.currentModel.value)"
          [disabled]="
            !!device.waitingModels &&
            device.waitingModels.length > 0 &&
            device.waitingModels.includes(form.controls.currentModel.value)
          "
          class="stream-controls__add-zones"
        >
          ADD ZONES
        </button>

        <ng-container>
          <button
            mat-raised-button
            color="primary"
            (click)="runNewModel(device)"
            [disabled]="
              !!device.waitingModels &&
              device.waitingModels.length > 0 &&
              device.waitingModels.includes(form.controls.currentModel.value)
            "
            class="stream-controls__analyze"
          >
            ANALYZE
          </button>
        </ng-container>

        <ua-model-loading-spinner
          *ngIf="
            !!device.waitingModels &&
            device.waitingModels.length > 0 &&
            device.waitingModels.includes(form.controls.currentModel.value)
          "
          [isSpinning]="
            !!device.waitingModels &&
            device.waitingModels.length > 0 &&
            device.waitingModels.includes(form.controls.currentModel.value)
          "
          (startAgain)="startAgain()"
        >
        </ua-model-loading-spinner>
      </ng-container>
    </div>
  </div>
</div>

import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {DrawTool} from './draw-tool';
import {Canvas, Draw, LabelOpacity, SingleLabel} from './image-annotation.model';
import {cloneDeep} from 'lodash';
declare var SVG: any;

export class Polygon extends DrawTool {
  constructor(ref: Draw, canvas: Canvas) {
    super(canvas);
    this.ref = ref;
    this.type = ShapeTypes.Polygon;
  }

  public importDraw(
    label: SingleLabel,
    scalingFactor: number,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ): SingleLabel {
    let vertices = label.shape.vertices;
    if (label.shape?.length > 0) {
      vertices = label.shape.map(vertices => ({x: vertices[0], y: vertices[1]}));
    } else {
      vertices = this.parseShapeFromDb(vertices, canvasSize, originalSize);
    }

    let color = label.color;
    if (typeof label.color === 'object') {
      color = (label as any).color.fill;
    }

    const singleLabel: SingleLabel = {
      id: label.id,
      displayName: (label as any).display_name,
      visibility: true,
      shapeType: label.shapeType,
      category: label.category,
      shape: vertices,
      severity: label.severity,
      comment: label.comment,
      color: color,
      isAI: label.isAI,
      isAccepted: label.isAccepted,
      isModified: label.isModified,
      suggestedCategory: label.suggestedCategory,
      addonId: label.addonId,
      distance: label.distance
    };

    this.setScalingFactorForCircleRadius(scalingFactor);

    this.ref.attr('id', singleLabel.id);
    this.ref.attr('fill', singleLabel.color);
    this.ref.attr('fill-opacity', LabelOpacity.visible);
    this.ref.attr('stroke', '#000');
    this.ref.attr('stroke-opacity', '1');
    this.ref.attr('stroke-width', this.vertexStrokeWidth + 'px');
    this.ref.attr('display-name', singleLabel.displayName);
    this.ref.attr('shape-type', this.type);
    this.ref.plot(singleLabel.shape);

    return singleLabel;
  }

  public exportDraw(singleLabel: SingleLabel): [SingleLabel, string] {
    const shape = this.ref._array.value;
    const label: SingleLabel = {
      shapeType: singleLabel.shapeType,
      category: singleLabel.category,
      shape: this.parseShape(shape),
      severity: singleLabel.severity,
      comment: singleLabel.comment,
      color: singleLabel.color,
      isAI: singleLabel.isAI,
      isAccepted: singleLabel.isAccepted,
      isModified: singleLabel.isModified,
      suggestedCategory: singleLabel.suggestedCategory,
      distance: singleLabel.distance
    };

    return [label, singleLabel.id];
  }

  public generateDrawTemplate(): SingleLabel {
    const shape = this.ref._array.value;
    const singleLabel: SingleLabel = {
      id: this.ref.attr('id') as string,
      displayName: '',
      visibility: true,
      shapeType: this.type,
      category: '',
      shape,
      severity: null,
      comment: '',
      color: this.selectedColor
    };

    return singleLabel;
  }

  public restoreBackup(drawBak: any) {
    super.restoreBackup(drawBak);

    if (drawBak?.points) {
      this.ref.attr('points', drawBak.points);
    }

    if (drawBak?.arrayPoints) {
      this.ref._array.value = cloneDeep(drawBak.arrayPoints);
    }

    if (drawBak?.color) {
      this.ref.attr('fill', drawBak.color);
    }
  }

  public saveShapeBackup() {
    return {
      arrayPoints: cloneDeep(this.ref._array.value),
      points: this.ref.attr('points'),
      color: this.ref.attr('fill')
    };
  }

  public hasDraw(): boolean {
    return this.ref._array && this.ref._array.value.length > 3;
  }

  public stopSelection() {
    this.ref.resize('stop').draggable(false).selectize(false, {deepSelect: true}).selectize(false);
  }

  private parseShape(shape: any) {
    if (!shape) {
      return {vertices: []};
    }
    const newVertices = shape.map(vertice => ({x: vertice[0], y: vertice[1]}));
    return {vertices: newVertices};
  }

  private parseShapeFromDb(
    shape: any,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ) {
    if (!shape) {
      return [];
    }

    const newVertices = [];

    shape.forEach((vertice: {x: number; y: number}) => {
      newVertices.push([
        (vertice.x * canvasSize.width) / originalSize.width,
        (vertice.y * canvasSize.height) / originalSize.height
      ]);
    });

    return newVertices;
  }
}

<h2 class="title" mat-dialog-title>
  {{ 'library.dialogs.uploadDevice.title' | translate }}
</h2>
<mat-dialog-content>
  <section class="express-upload">
    <mat-icon class="express-upload__icon" svgIcon="file_upload"></mat-icon>
    <section>
      <p class="express-upload__title">{{ 'library.dialogs.uploadDevice.uploadMediaFiles' | translate }}</p>
      <p class="express-upload__supported-files">
        {{ 'library.dialogs.uploadDevice.acceptedFormats' | translate }}
      </p>
    </section>
    <section class="express-upload__total">
      <mat-icon class="express-upload__total-icon">attach_file</mat-icon>
      <span class="express-upload__files-text"
        >{{ data.numberOfFilesToUpload }}
        {{
          data.numberOfFilesToUpload === 1
            ? ('library.dialogs.uploadDevice.file' | translate)
            : ('library.dialogs.uploadDevice.files' | translate)
        }}, {{ data.totalFilesSize }}</span
      >
    </section>
    <section class="express-upload__settings">
      <h2 class="express-upload__settings-title">{{ 'library.dialogs.uploadDevice.settings' | translate }}</h2>
      <form [formGroup]="form" class="express-upload__device">
        <span class="express-upload__device-label"> {{ 'library.dialogs.uploadDevice.device' | translate }}</span>
        <mat-select class="express-upload__device-picker" data-test-id="device-select" formControlName="deviceId">
          <mat-option *ngFor="let device of devices" [value]="device.id">
            {{ device.name }}
          </mat-option>
        </mat-select>

        <mat-checkbox data-test-id="fast-upload-checkbox" formControlName="isFastUpload">{{
          'library.dialogs.uploadDevice.fastUpload' | translate
        }}</mat-checkbox>

        <div class="express-upload__folder" *ngIf="data.hasToShowCreateFolderOption">
          <mat-checkbox formControlName="hasToCreateFolder">
            {{ 'library.dialogs.uploadDevice.createFolder' | translate }}
          </mat-checkbox>
          <span class="express-upload__folder-name">
            {{ 'library.dialogs.uploadDevice.folderName' | translate }} {{ data.userName }}</span
          >
        </div>
      </form>
    </section>
  </section>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button data-test-id="close-dialog-button" mat-button color="primary" (click)="emitClose()">
    {{ 'common.cancel' | translate }}
  </button>
  <button data-test-id="upload-files-dialog-button" mat-raised-button color="primary" (click)="emitUploadFiles()">
    {{ 'library.dialogs.uploadDevice.upload' | translate }}
  </button>
</mat-dialog-actions>

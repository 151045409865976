import {Component, Input, OnInit} from '@angular/core';
import {PlanModel} from '@app/core/models/api/plan.model';
import {UserModel} from '@app/core/models/api/user-model';
import {HelpService} from '@app/core/services/help.service';
import {PlansService} from '@app/plans/services/plans.service';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['../myprofile.component.common.scss', './plan.component.scss']
})
export class PlanComponent implements OnInit {
  @Input('user')
  set fillCompany(user: UserModel) {
    if (user && Object.keys(user).length > 0) {
      this.user = user;
    }
  }
  user: UserModel;

  constructor(public plansService: PlansService, private helpService: HelpService) {}

  ngOnInit() {}

  getPricingTitle(plan: PlanModel): string {
    return plan.planName.replace(' ', '<BR>').toUpperCase();
  }

  pastTrial(): boolean {
    return !this.user.trialPeriodEnd || new Date().getTime() > this.user.trialPeriodEnd;
  }

  contactUs() {
    this.helpService.goToContactUs();
  }
}

/* eslint-disable no-magic-numbers */
/* eslint-disable camelcase */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {Addon} from '@app/store/addon/models/addon';
import {LibraryPageService} from '@app/library/services/library-page.service';
import {ComponentStore} from '@ngrx/component-store';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {DeviceService} from '@app/core/services/api/device.service';
import {
  CoolDownPeriod,
  NestedRule,
  Rule,
  RuleAction,
  RuleAlert,
  RuleAreaOfInterest,
  RuleCondition,
  RuleItem,
  RuleMenuOption,
  RuleOperator,
  RuleType
} from '@app/shared/analysis-configuration/models/rule.model';
import {ManageZoneService} from '@app/shared/annotation-shared/services/manage-zone.service';
import {UnleashAnnotationCanvasService} from '@app/shared/annotation-shared/services/unleash-annotation-canvas.service';

import {
  BehaviorSubject,
  Observable,
  first,
  take,
  filter,
  map,
  Subscription,
  withLatestFrom,
  delay,
  of,
  switchMap,
  tap,
  Subject,
  zip,
  share,
  startWith
} from 'rxjs';
import {AddonStoreFacadeService} from '../../core/services/addon-store-facade.service';
import {AddonService} from '../../core/services/api/addon.service';
import {UserStoreFacadeService} from '../../core/services/user-store-facade.service';
import {AddonZones, Zone, ZoneColor, ZoneConfig} from '@app/shared/annotation-shared/models/annotations.model';
import {ManageZonesDialogStateService} from './manage-zones-dialog.state.service';
import {CanvasService as NewCanvasService} from '@app/shared/image-annotation-shared/services/canvas.service';
import {
  CanvasImageQuality,
  DrawStatus,
  SingleLabel
} from '@app/shared/image-annotation-shared/models/image-annotation.model';
import {ShapeTypeNames, ShapeTypes} from '@app/core/models/api/label-config.model';
import {ZoomService} from '@app/library/services/zoom.service';
import {PinchZoomComponent} from '@app/shared/pinch-zoom/pinch-zoom.component';
import {v4 as uuidv4} from 'uuid';
import {environment} from 'environments/environment';
import {ManagerZonesDialogService} from '@app/shared/manage-zones-dialog/services/manager-zones-dialog.service';
import {MediaObserver} from '@angular/flex-layout';
import {ImageSnapshot} from '@app/shared/manage-zones-dialog/model/manager-zones.model';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {HelpService} from '@app/core/services/help.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '../pipes/models/thumbler.model';
import {LabelColor, LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {cloneDeep} from 'lodash';
import {TriggerPoint} from '../trigger-point/trigger-point.model';
import tinycolor from 'tinycolor2';
import {UntilDestroy} from '@ngneat/until-destroy';
import {LineInOutFeature} from '../image-annotation-shared/models/lineInOut';
import {CalibrationService} from '../calibration/services/calibration.service';

declare const SVG: any;

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-manage-zones-dialog',
  templateUrl: './manage-zones-dialog.component.html',
  styleUrls: ['./manage-zones-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComponentStore]
})
export class ManageZonesDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pinchZoom', {static: false, read: PinchZoomComponent}) public zoom: PinchZoomComponent;
  @ViewChild('zoneList', {read: ElementRef}) public zoneListRef: ElementRef;
  @ViewChild('zone', {read: ElementRef}) public zoneRef: ElementRef;
  @ViewChild('verticalCursor', {read: ElementRef}) public verticalCursor: ElementRef;
  @ViewChild('horizontalCursor', {read: ElementRef}) public horizontalCursor: ElementRef;
  @ViewChild('cursor', {read: ElementRef}) public cursor: ElementRef;

  public imageSnapshot$ = this.managerZonesDialogService.imageSnapshot$;
  // eslint-disable-next-line rxjs/finnish
  public imageSize = {width: null, height: null};

  public shapeTypes: typeof ShapeTypes = ShapeTypes;
  public shapeTypeNames: typeof ShapeTypeNames = ShapeTypeNames;
  public triggerPoint: typeof TriggerPoint = TriggerPoint;
  public selectedFeatureType: string = this.shapeTypes.polygon;
  public showMoreSettings: any = {};
  public supportedZonesTypes: string[] = [];

  public selectedZone$: Observable<Zone> = this.managerZonesDialogService.selectedZone$;
  public zones$: Observable<Zone[]> = this.managerZonesDialogService.zones$;
  public isZonesLoading$: Observable<boolean> = this.managerZonesDialogService.isZonesLoading$;
  public polygonZones$: Observable<Zone[]> = this.managerZonesDialogService.polygonZones$;
  public rules$: Observable<RuleItem[]> = this.manageZonesDialogStateService.rules$;
  public selectedRule$: Observable<RuleItem> = this.manageZonesDialogStateService.selectedRule$;
  public form$: Observable<UntypedFormGroup> = this.manageZonesDialogStateService.form$;
  public simpleForm$: Observable<UntypedFormGroup> = this.manageZonesDialogStateService.simpleForm$;
  public editMode$: Observable<boolean> = this.manageZonesDialogStateService.editMode$;
  public performAnalysisLoading$: Observable<boolean> = this.manageZonesDialogStateService.performAnalysisLoading$;
  public areaOfInterest$: Observable<RuleAreaOfInterest[]> = this.manageZonesDialogStateService.areaOfInterest$;
  public simpleConditions$: Observable<RuleCondition[]> = this.manageZonesDialogStateService.simpleConditions$;
  public isNotificationsConfigLoading$: Observable<boolean> =
    this.manageZonesDialogStateService.isNotificationsConfigLoading$;
  // eslint-disable-next-line rxjs/finnish
  public isLivestream$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // eslint-disable-next-line rxjs/finnish
  public hasToDisplayCoolDown$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  // eslint-disable-next-line rxjs/finnish
  public hasToDisableSeverity$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public menuOptions: RuleMenuOption[] = this.manageZonesDialogStateService.menuOptions;
  public operators: RuleOperator[] = this.manageZonesDialogStateService.operators;
  public conditions: RuleCondition[] = this.manageZonesDialogStateService.conditions;
  public alerts: RuleAlert[] = this.manageZonesDialogStateService.alerts;
  public coolDownPeriods: CoolDownPeriod[] = this.manageZonesDialogStateService.coolDownPeriods;

  public selectedTabIndex: number = 0;
  // eslint-disable-next-line rxjs/finnish
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: Observable<boolean> = this.isLoading.asObservable();
  public isLoadingImage: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public addon: Addon;
  public device: UserDeviceJoined;

  public drawStatus$ = this.newCanvasService.drawStatus$;
  public isDrawMode$ = this.newCanvasService.drawStatus$.pipe(map(drawStatus => DrawStatus.draw === drawStatus));
  public isDrawOrEditMode$ = this.newCanvasService.drawStatus$.pipe(
    map(drawStatus => DrawStatus.draw === drawStatus || DrawStatus.edit === drawStatus)
  );
  private isHoveringShape: BehaviorSubject<SingleLabel> = new BehaviorSubject(null);
  public isHoveringShape$ = this.isHoveringShape.asObservable();

  private hoverBlockName: BehaviorSubject<TriggerPoint> = new BehaviorSubject(TriggerPoint.center);
  public hoverBlockName$: Observable<TriggerPoint> = this.hoverBlockName.asObservable();

  // eslint-disable-next-line rxjs/finnish
  public isMobileView$: Observable<boolean> = this.observableMedia.asObservable().pipe(
    map(change => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      const isMobileView = mediaAlias === 'xs';
      return isMobileView;
    })
  );
  public canvasDiagonalWidth$: Observable<number> = this.newCanvasService.canvasDiagonalWidth$;
  public naturalImageDiagonalWidth$: Observable<number> = this.newCanvasService.naturalImageDiagonalWidth$;
  public isSelectedPerspectiveZone$: Observable<boolean> = this.managerZonesDialogService.isSelectedPerspectiveZone$;
  public selectedZoneColor$: Observable<LabelColorName> = this.managerZonesDialogService.selectedZoneColor$;
  public hasPerspectiveZone$: Observable<boolean> = this.managerZonesDialogService.hasPerspectiveZone$;
  public hint$: Observable<string> = this.managerZonesDialogService.hint$;
  public events: typeof EVENTS = EVENTS;
  public aclPermissions = AclPermissions;
  public thumblerConfig = THUMBLER_AVAILABLE_CONFIGS.libraryLarge;
  public perspectiveForm = this.fb.group({
    a: [0, [Validators.required, Validators.pattern(/^(?!0+(\.0+)?$)\d+(\.\d{1,2})?$/)]],
    b: [0, [Validators.required, Validators.pattern(/^(?!0+(\.0+)?$)\d+(\.\d{1,2})?$/)]]
  });
  public displayNameForm = this.fb.group({
    displayName: ['', Validators.required]
  });
  public defaultZoneForm = this.fb.group({
    isDefaultZone: false
  });
  public inOutForm = this.fb.group({
    isInEnabled: [true, Validators.required],
    isOutEnabled: [true, Validators.required]
  });

  public isNewDraw$: Observable<boolean> = this.newCanvasService.isNewDraw$;
  public drawStatus: typeof DrawStatus = DrawStatus;

  private hasToDisplayTriggerPointTooltip = new BehaviorSubject<boolean>(false);
  public hasToDisplayTriggerPointTooltip$ = this.hasToDisplayTriggerPointTooltip.asObservable();

  public isDragging$ = this.newCanvasService.isDragging$;
  public visibleZonesLength$ = this.zones$.pipe(map(zones => zones.filter(zone => !zone.removed).length));
  public zonesLength$: Observable<string> = this.zones$.pipe(
    map(rawZones => {
      const zones = rawZones.filter(zone => zone.removed !== true);
      return zones.length > 1 ? `${zones.length} zones` : zones.length === 0 ? 'no zones' : '1 zone';
    }),
    share()
  );
  public alertsLength$: Observable<string> = this.rules$.pipe(
    map(rule => {
      return rule.length > 1 ? `${rule.length} alerts` : rule.length === 0 ? 'no alerts' : '1 alert';
    }),
    share()
  );
  public saveTooltipText$ = this.newCanvasService.drawPoint$.pipe(
    startWith(null),
    map(() => {
      const shapeType = this.newCanvasService.selectedTool.type;

      if (shapeType === this.shapeTypes.perspective && this.perspectiveForm.invalid) {
        return 'Add perspective values';
      }

      if (shapeType === this.shapeTypes.line_in_out) {
        const points = (this.newCanvasService.selectedTool.ref as any).array().value;
        const hasTwoToPoints = points[0][0] !== points[1][0] && points[0][1] !== points[1][1];
        return hasTwoToPoints ? '' : 'Draw a gate to add the zone';
      }

      return '';
    })
  );

  private zoneHighlight = new Subject<string>();
  public zoneHighlight$ = this.zoneHighlight.asObservable();

  public calibrationLabel$ = this.calibrationService.calibrationLabel$;

  private zoomChange = new Subject<void>();
  private zoomChange$ = this.zoomChange.asObservable();
  private snapshotData: {data: string; height: number; width: number};
  private openSetting: string = '';
  private zonesConfig: ZoneConfig;
  private deviceId: string;
  private dataSub: Subscription;
  private availableColorsName = [
    LabelColorName.grey,
    LabelColorName.blue,
    LabelColorName.skyblue,
    LabelColorName.purple,
    LabelColorName.pink,
    LabelColorName.red,
    LabelColorName.orange,
    LabelColorName.lightorange,
    LabelColorName.yellow,
    LabelColorName.lightgreen,
    LabelColorName.green,
    LabelColorName.black
  ];
  // eslint-disable-next-line no-magic-numbers
  private MIN_VALID_POINTS: number = 2;
  private zoomChangesSub: Subscription;
  private temporalTriggerPoint: TriggerPoint = TriggerPoint.center;
  private temporalTriggerPointTooltip: TriggerPoint = TriggerPoint.center;
  private inClickSub: Subscription;
  private outClickSub: Subscription;
  private drawPointSub: Subscription;
  private shapeIsResizingSub: Subscription;
  private lineInSwapClickSub: Subscription;
  private lineOutSwapClickSub: Subscription;
  private hoverZoneSub: Subscription;
  private hasShapeSub: Subscription;
  private newZoneId = null;
  private selectedFeature = null;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {deviceId?: string; selectedAddon?: Addon; route?: string},
    public managerZonesDialogService: ManagerZonesDialogService,
    public userStoreFacadeService: UserStoreFacadeService,
    private manageZoneService: ManageZoneService,
    @Optional() public dialogRef: MatDialogRef<ManageZonesDialogComponent>,
    private cd: ChangeDetectorRef,
    private deviceService: DeviceService,
    private newCanvasService: NewCanvasService,
    private unleashAnnotationCanvasService: UnleashAnnotationCanvasService,
    public addonService: AddonService,
    private manageZonesDialogStateService: ManageZonesDialogStateService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private route: ActivatedRoute,
    private libraryPageService: LibraryPageService,
    private zoomService: ZoomService,
    private observableMedia: MediaObserver,
    private unleashAnalyticsService: UnleashAnalyticsService,
    private helpService: HelpService,
    private liveStreamPageService: LiveStreamPageService,
    private fb: FormBuilder,
    private calibrationService: CalibrationService,
    private router: Router
  ) {
    // Feature flag to use only in development
    if (!environment.production) {
      const route = this.route.snapshot.url.length === 0 ? this.data.route : this.route.snapshot.url.join('/');
      this.manageZonesDialogStateService.isLivestream = route.includes('live');
      this.unleashAnalyticsService.logEvent(
        this.manageZonesDialogStateService.isLivestream
          ? this.events.MANAGER_ZONES_OPEN_LIVESTREAM_PROCESSING
          : this.events.MANAGER_ZONES_OPEN_POST_PROCESSING
      );

      this.isLivestream$.next(this.manageZonesDialogStateService.isLivestream);

      const hasToDisplayCoolDown = this.manageZonesDialogStateService.isLivestream;
      this.hasToDisplayCoolDown$.next(hasToDisplayCoolDown);

      const hasToDisableSeverity = !this.manageZonesDialogStateService.isLivestream;
      this.hasToDisableSeverity$.next(hasToDisableSeverity);
    }

    this.managerZonesDialogService.watchDrawShapesOnCanvas();
    this.watchZoomChanges();
    this.watchSwitchInOut();
    this.watchDrawPoints();
    this.watchShapeIsResizing();
    this.watchShapeMove();
    this.watchSwapInOutClick();
    this.watchZoneHighlight();
    this.watchHasShape();
    this.managerZonesDialogService.atLeastOneInOutPositionEnabled();
  }

  public ngOnInit(): void {
    this.deviceId = this.data
      ? this.data.deviceId
      : this.route.snapshot.data.deviceId || this.route.snapshot.queryParams.deviceId;
    const addonId = this.data ? this.data.selectedAddon.id : this.route.snapshot.queryParams.addonId;
    this.calibrationService.dialogRef = this.dialogRef;

    zip(
      this.addonStoreFacadeService.addons$.pipe(
        filter(addons => Object.values(addons).length > 0),
        take(1),
        map(addons => addons[addonId])
      ),
      this.userStoreFacadeService.getDevice(this.deviceId).pipe(
        filter(device => !!device),
        first()
      )
    ).subscribe(([addon, device]: [Addon, UserDeviceJoined]) => {
      this.device = device;
      this.addon = addon;
      const zonesConfig = device.zonesConfig;

      if (!zonesConfig) {
        return;
      }

      this.defaultZoneForm.controls.isDefaultZone.setValue(zonesConfig['default'] === this.addon.id);
    });

    this.newCanvasService.shapeClick$.subscribe(shape => {
      this.managerZonesDialogService.zones$
        .pipe(
          filter(zones => zones.length > 0),
          take(1)
        )
        .subscribe(zones => {
          const zone = zones.find(zone => zone.id === shape.id);
          if (!zone) {
            console.error('no zone', zone);
            return;
          }

          this.selectZone(zone);
          this.managerZonesDialogService.setSelectedZoneId(zone.id);
          this.displayNameForm.controls.displayName.setValue(zone.display_name);
          if (zone.shape_type === ShapeTypes.perspective) {
            this.setPerpectiveZoneValue(zone);
          }
          this.isHoveringShape.next(shape);
        });
    });
  }

  public ngAfterViewInit(): void {
    this.managerZonesDialogService.imageSnapshot$
      .pipe(
        filter(img => !!img),
        take(1)
      )
      .subscribe((img: ImageSnapshot): void => {
        this.snapshotData = img;
        this.imageSize = {
          width: img.width,
          height: img.height
        };
        this.zoomService.zoom = this.zoom;
      });
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.managerZonesDialogService.clearImageSnapshot();
    if (this.dataSub) {
      this.dataSub.unsubscribe();
      this.dataSub = null;
    }
    this.managerZonesDialogService.canvasIsNotReady();
    this.managerZonesDialogService.clearZones();
    this.newCanvasService.clearCanvasShapeOnDestroy();
    this.temporalTriggerPoint = TriggerPoint.center;
    if (this.zoomChangesSub) {
      this.zoomChangesSub.unsubscribe();
      this.zoomChangesSub = null;
    }
  }

  public setSelectedAddon(): void {
    this.deviceId = this.data
      ? this.data.deviceId
      : this.route.snapshot.data.deviceId || this.route.snapshot.queryParams.deviceId;
    const addonId = this.data ? this.data.selectedAddon.id : this.route.snapshot.queryParams.addonId;

    this.calibrationService.setMainInformation(this.deviceId, addonId);

    zip(
      this.addonStoreFacadeService.addons$.pipe(
        filter(addons => Object.values(addons).length > 0),
        take(1),
        map(addons => addons[addonId])
      ),
      this.userStoreFacadeService.devicesSorted$.pipe(
        filter(devices => devices.length > 0),
        take(1),
        map((devices: UserDeviceJoined[]) => devices.find(device => device.id === this.deviceId))
      )
    )
      .pipe(take(1))
      .subscribe(([addon, device]: [Addon, UserDeviceJoined]) => {
        this.zonesConfig = device.zonesConfig || {};
        this.addon = addon;
        this.setSupportedZonesTypes();

        const hasCalibration =
          device.analyticsConfig?.[addon.id]?.analytics?.['ul_camera_calibration_analytics']?.calibration_points
            .length > 0;

        this.calibrationService.setCalibrationLabel(hasCalibration);

        this.defaultZoneForm.controls.isDefaultZone.setValue(this.zonesConfig['default'] === this.addon.id);

        if (this.addon?.data?.formatters) {
          this.manageZonesDialogStateService.setupAreaOfInterest(this.addon.data.formatters);
          this.manageZonesDialogStateService.setupSimpleRuleConditions(this.addon.data.processor);
        }

        this.managerZonesDialogService.setIsZonesLoading(true);
        this.userStoreFacadeService
          .getDevice(this.deviceId)
          .pipe(
            filter(device => !!device),
            first()
          )
          .subscribe((device: UserDeviceJoined) => {
            this.device = device;
            this.zonesConfig = {...device?.zonesConfig};
            this.managerZonesDialogService.setZonesConfigData({zonesConfig: this.zonesConfig, addonId});
            this.reset();

            this.managerZonesDialogService.updateCurrentZonesConfigBackup({zonesConfig: this.zonesConfig, addonId});
            this.managerZonesDialogService.setIsZonesLoading(false);
            if (this.device?.notificationsConfig) {
              this.manageZonesDialogStateService.setIsNotificationsConfigLoading(true);
              this.deviceService
                .getDevice(this.device.id)
                .pipe(take(1))
                .subscribe(device => {
                  if (
                    device.notificationsConfig[this.addon.id] &&
                    Object.values(device.notificationsConfig[this.addon.id]).length > 0
                  ) {
                    this.manageZonesDialogStateService.mapNotificationsConfigToRuleConfig(
                      device.notificationsConfig[this.addon.id].notifications
                    );
                  }
                  this.manageZonesDialogStateService.setIsNotificationsConfigLoading(false);
                });
            } else {
              this.manageZonesDialogStateService.setIsNotificationsConfigLoading(false);
            }
          });
      });
  }

  private reset() {
    this.newCanvasService.clearCanvasShape();
    this.managerZonesDialogService.clearZones();

    this.managerZonesDialogService.zonesConfigData$.pipe(take(1)).subscribe(zonesConfigData => {
      if (zonesConfigData?.zonesConfig) {
        this.managerZonesDialogService.initZones();
      }
    });
  }

  public async save(): Promise<void> {
    this.isLoading.next(true);
    this.managerZonesDialogService.hideHint();
    const canvasState = await this.emitCanvasState();
    await this.unleashAnnotationCanvasService.save(this.addon.id, canvasState, this.saveZones.bind(this));
  }

  public analyze(): void {
    this.managerZonesDialogService.startAi(this.device, this.addon);
    this.closeDialog(true);
  }

  public deleteZone(zone: Zone): void {
    let selectedFeature;
    const shapeType = zone.shape_type as any;
    switch (shapeType) {
      case ShapeTypes.Polygon:
      case ShapeTypes.polygon:
        selectedFeature = this.newCanvasService.selectPolygon();
        break;
      case ShapeTypes.rectangle:
        selectedFeature = this.newCanvasService.selectRectangle();
        break;
      case ShapeTypes.perspective:
        selectedFeature = this.newCanvasService.selectPerspective();
        selectedFeature.id = zone.id;
        break;
      case ShapeTypes.line_in_out:
        selectedFeature = this.newCanvasService.selectLineInOut();
        selectedFeature.id = zone.id;
        break;
    }
    this.managerZonesDialogService.removeZone(zone);
    this.newCanvasService.deleteShape({id: zone.id, shapeType: zone.shape_type} as SingleLabel);

    selectedFeature?.removeDraw();
    this.save();
  }

  private updateNewZone(): void {
    if (this.selectedFeature.type === ShapeTypes.line_in_out || this.selectedFeature.type === ShapeTypes.Polygon) {
      const original_shape = cloneDeep(this.generateOriginalShape(this.selectedFeature.ref));
      this.managerZonesDialogService.updateZone({original_shape}, this.newZoneId);
    }
  }

  public closeDraw(event?: MouseEvent): void {
    this.newCanvasService.drawStatus$
      .pipe(
        take(1),
        switchMap(drawStatus => {
          return zip(of(drawStatus), this.isNewDraw$);
        }),
        switchMap(([drawStatus, isNewDraw]) => {
          if (isNewDraw) {
            this.updateNewZone();
          }
          return of(drawStatus);
        }),
        switchMap(drawStatus => zip(of(drawStatus), this.selectedZone$.pipe(take(1))))
      )
      .subscribe(([drawStatus, selectedZone]: [DrawStatus, Zone]) => {
        if (drawStatus === DrawStatus.draw) {
          if (event) {
            this.newCanvasService.checkIsValidClick(event);
          }

          if (!this.newCanvasService.selectedTool.hasDraw()) {
            return;
          }

          this.closeDrawBasedOnDrawStatus(drawStatus);
          this.newCanvasService.saveZoneName(this.displayNameForm.value.displayName);
          return;
        }

        this.newCanvasService.saveZoneName(this.displayNameForm.value.displayName);

        let hasError = false;
        if (this.displayNameForm.invalid) {
          this.displayNameForm.controls.displayName.markAsTouched();
          hasError = true;
        }

        if (selectedZone.shape_type === ShapeTypes.perspective && this.perspectiveForm.invalid) {
          this.perspectiveForm.controls.a.markAsTouched();
          this.perspectiveForm.controls.b.markAsTouched();
          hasError = true;
        }

        if (!this.newCanvasService.selectedTool) {
          hasError = true;
          console.warn('closeDraw(): selected feature is null');
          return;
        }

        if (!hasError) {
          this.closeDrawBasedOnDrawStatus(drawStatus);
        }
      });
  }

  public cancel(): void {
    const zoneId = this.newCanvasService.selectedTool.id;
    if (!this.newCanvasService.drawHasBak()) {
      this.managerZonesDialogService.removeZone({id: zoneId} as Zone);
    }

    this.managerZonesDialogService.hideHint();
    this.newCanvasService.removeDashedStroke();
    this.newCanvasService.restoreDraw();
    this.zones$.pipe(take(1)).subscribe(zones => {
      const zonesToRestoreTagEvents = zones.map(zone => ({id: zone.id, shapeType: zone.shape_type}));
      this.newCanvasService.enableTagNameEvents(zonesToRestoreTagEvents);
    });
    this.displayNameForm.reset();
    this.perspectiveForm.reset();
    this.inOutForm.reset();
  }

  public storeDisplayName(): void {
    this.managerZonesDialogService.storeDisplayName(this.displayNameForm.controls.displayName.value);
  }

  public addNewZone(selectedFeatureType: string): void {
    this.closeAllSettingsWindows();

    this.temporalTriggerPoint = TriggerPoint.center;
    this.temporalTriggerPointTooltip = TriggerPoint.center;
    this.setHoverBlockName(this.temporalTriggerPoint);

    let color = this.colorGenerator();
    switch (this.shapeTypes[selectedFeatureType]) {
      case ShapeTypes.Polygon:
      case ShapeTypes.polygon:
        this.selectedFeature = this.newCanvasService.selectPolygon();
        break;
      case ShapeTypes.Rectangle:
      case ShapeTypes.rectangle:
        this.selectedFeature = this.newCanvasService.selectRectangle();
        break;
      case ShapeTypes.line_in_out:
        color = this.getColor(LabelColorName.red);
        this.selectedFeature = this.newCanvasService.selectLineInOut();
        break;
      case ShapeTypes.polygon_excluded:
        this.selectedFeature = this.newCanvasService.selectPolygonExcluded();
        break;
      case ShapeTypes.perspective:
        this.selectedFeature = this.newCanvasService.selectPerspective();
        color = this.getColor(LabelColorName.blue);
        break;
    }

    this.managerZonesDialogService.zones$.pipe(take(1)).subscribe(zones => {
      this.newZoneId = this.idGenerator(this.selectedFeature.type);
      this.newCanvasService.setCanvasConstrain();
      const newZone = this.manageZoneService.generateDefaultZone(
        this.selectedFeature.type,
        this.newZoneId,
        color,
        zones
      );
      this.managerZonesDialogService.addZone(newZone);
      this.displayNameForm.controls.displayName.setValue(newZone.display_name);

      if (newZone.shape_type === ShapeTypes.perspective) {
        this.setPerpectiveZoneValue(newZone);
      }

      if (newZone.shape_type === ShapeTypes.line_in_out) {
        this.inOutForm.setValue({isInEnabled: true, isOutEnabled: true});
      }

      this.managerZonesDialogService.setSelectedZoneId(this.newZoneId);

      this.newCanvasService.disableTagNameEvents(zones.map(zone => ({id: zone.id, shapeType: zone.shape_type})));
      this.newCanvasService.startDraw({
        id: this.newZoneId,
        color: color.fill,
        stroke: color.stroke,
        name: newZone.display_name
      });
      this.managerZonesDialogService.prepareHint(newZone.shape_type);
      this.zoneListRef.nativeElement.scrollTop = this.zoneListRef.nativeElement.scrollHeight;
    });
  }

  public toggleMoreSettings(zoneIndex: number): void {
    if (!this.showMoreSettings[zoneIndex]) {
      this.showMoreSettings[zoneIndex] = true;
    }

    const newState = !this.showMoreSettings[zoneIndex];
    this.closeAllSettingsWindows();

    this.showMoreSettings[zoneIndex] = newState;
    if (newState) {
      this.openSetting = '' + zoneIndex;
    }
  }

  public closeDialog(isPositiveAction: boolean = false): void {
    const currentUrl = window.location.href;

    this.manageZonesDialogStateService.clearRules();
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }

    if (this.route.snapshot.queryParams.deviceId) {
      if (currentUrl.includes('/live/analyze')) {
        this.router.navigate(['/secure/live']);
        return;
      }
      return;
    }

    this.libraryPageService.navigateToPreviousUrl('manager zones', isPositiveAction);
  }

  public addRule(): void {
    this.manageZonesDialogStateService.addRule();
    this.unleashAnalyticsService.logEvent(this.events.MANAGER_ZONES_ADD_RULE);
  }

  public executeRuleAction(ruleAction: RuleAction): void {
    this.manageZonesDialogStateService.executeRuleAction(ruleAction);

    let eventName;
    switch (ruleAction.action) {
      case RuleType.delete:
        eventName = this.events.MANAGER_ZONES_RULE_DELETE;
        break;
      case RuleType.disable:
        eventName = this.events.MANAGER_ZONES_RULE_INACTIVE;
        break;
      case RuleType.enable:
        eventName = this.events.MANAGER_ZONES_RULE_ACTIVE;
        break;
    }

    this.unleashAnalyticsService.logEvent(eventName, {ruleId: ruleAction.ruleId});
  }

  public addConditionSelectToRule(action: {formIndex: number; form: Rule}): void {
    this.manageZonesDialogStateService.addConditionSelectToRule(action);
  }

  public addOperatorAndValueSelectToRule(action: {formIndex: number; form: Rule}): void {
    this.manageZonesDialogStateService.addOperatorAndValueSelectToRule(action);
  }

  public deleteNestedRule(action: {formIndex: number; form: Rule}): void {
    this.manageZonesDialogStateService.deleteNestedRule(action);
  }

  public saveValueChange(form: Rule): void {
    this.manageZonesDialogStateService.saveValueChange(form);
  }

  public startEditMode(): void {
    this.manageZonesDialogStateService.startEditMode();
  }

  public stopEditMode(ruleName: string): void {
    this.manageZonesDialogStateService.stopEditMode(ruleName);
  }

  public selectRule(selectedRuleId: string): void {
    this.manageZonesDialogStateService.selectRule(selectedRuleId);
  }

  public changeAlertSeveritySettings(alertId: string): void {
    this.manageZonesDialogStateService.changeAlertSeveritySettings(alertId);
  }

  public moveToZonesTab(): void {
    this.selectedTabIndex = 0;
  }

  public moveToNotificationsTab(): void {
    this.selectedTabIndex = 1;
  }

  public changeSelectedTab(matTabChange: MatTabChangeEvent): void {
    this.selectedTabIndex = matTabChange.index;
  }

  public analyzeWithRules(): void {
    this.manageZonesDialogStateService.performAnalysisLoading();
    this.manageZonesDialogStateService
      .analyzeWithRules$(this.addon.id, this.device.id)
      .pipe(take(1))
      .subscribe(() => {
        this.manageZonesDialogStateService.performStopAnalysisLoading();
      });
  }

  public stopLoader(): void {
    this.isLoading.next(false);
    this.isLoadingImage.next(false);
  }

  public parseSimpleMode(action: {form: Rule; rules: NestedRule[]}): void {
    this.manageZonesDialogStateService.parseSimpleMode(action);
  }

  private setSupportedZonesTypes(): void {
    let supportedZonesTypes = [];

    supportedZonesTypes = !!this.addon.supportedZonesTypes
      ? this.addon.supportedZonesTypes
      : [ShapeTypes.polygon, ShapeTypes.polygon_excluded, ShapeTypes.rectangle, ShapeTypes.line_in_out];

    this.supportedZonesTypes = Object.keys(this.shapeTypes).filter((shapeType: string) =>
      supportedZonesTypes.includes(shapeType)
    );

    this.supportedZonesTypes.push(ShapeTypes.perspective);

    const hasDefaultZoneType = this.supportedZonesTypes.some(
      (zoneType: string) => zoneType === this.selectedFeatureType
    );

    if (!hasDefaultZoneType && this.supportedZonesTypes && this.supportedZonesTypes.length > 0) {
      this.selectedFeatureType = this.supportedZonesTypes[0];
    }
  }

  public changeZoom(): void {
    this.zoomService.zoom = this.zoom;
    this.newCanvasService.setZoomScale(this.zoomService.zoom.pinchZoom.scale);
    this.newCanvasService.redrawCircles();
    this.zoomChange.next();
  }

  private watchZoomChanges() {
    const ZOOM_DELAY_IN_MILLISECONDS = 300;

    this.zoomChangesSub = this.zoomChange$
      .pipe(
        switchMap(() => of(this.zoomService.zoom.pinchZoom.scale)),
        delay(ZOOM_DELAY_IN_MILLISECONDS),
        switchMap((scale: number) => {
          return this.zoomService.zoom.zoomPercentage$.pipe(
            tap(zoomPercent => {
              this.newCanvasService.setZoomPercent(zoomPercent);
              this.newCanvasService.setZoomScale(scale);
              this.newCanvasService.setIsDragging(this.zoomService.zoom.isDragging);
            }),
            delay(ZOOM_DELAY_IN_MILLISECONDS),
            take(1),
            switchMap(() => this.newCanvasService.shapes$),
            tap(() => {
              this.newCanvasService.redrawCircles();
            })
          );
        })
      )
      .subscribe();
  }

  private closeAllSettingsWindows(): void {
    if (!this.openSetting || isNaN(parseInt(this.openSetting))) {
      return;
    }
    this.showMoreSettings[this.openSetting] = false;
    this.openSetting = '';
  }

  private saveZones(addonZones: AddonZones): void {
    addonZones.zones = cloneDeep(addonZones.zones.filter((zone: Zone) => !zone.removed));
    const updateData = {
      zonesConfig: {
        [this.addon.id]: addonZones
      } as ZoneConfig
    };

    updateData.zonesConfig['default'] = this.defaultZoneForm.controls.isDefaultZone.value ? this.addon.id : '';

    this.deviceService
      .updateDevice(this.device.id, updateData)
      .pipe(take(1))
      .subscribe(() => {
        const zonesConfig = this.device.zonesConfig ? {...this.device.zonesConfig} : {};
        zonesConfig[this.addon.id] = addonZones;
        zonesConfig['default'] = this.defaultZoneForm.controls.isDefaultZone.value ? this.addon.id : '';
        this.managerZonesDialogService.setZonesConfigData({zonesConfig, addonId: this.addon.id});

        this.managerZonesDialogService.updateCurrentZonesConfigBackup({
          zonesConfig: this.zonesConfig,
          addonId: this.addon.id
        });

        this.userStoreFacadeService.updateDeviceZonesConfig(this.device.id, zonesConfig);
        this.isLoading.next(false);
      });
  }

  public emitCanvasIsReady(canvasImageQuality: CanvasImageQuality): void {
    this.zoomService.zoom = this.zoom;
    this.newCanvasService.setIsDragging(this.zoomService.zoom.isDragging);
    this.managerZonesDialogService.canvasIsReady();

    const rect = this.zoneRef.nativeElement.getBoundingClientRect();
    this.zoneRef.nativeElement.addEventListener('mousemove', (event: MouseEvent) => {
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
      this.verticalCursor.nativeElement.setAttribute('style', `left: ${mouseX}px; width: ${1}px;`);
      this.horizontalCursor.nativeElement.setAttribute('style', `top: ${mouseY}px; height: ${1}px;`);
    });

    this.zoneRef.nativeElement.addEventListener('mouseleave', () => {
      this.verticalCursor.nativeElement.setAttribute('style', `display: none;`);
      this.horizontalCursor.nativeElement.setAttribute('style', `display: none;`);
    });
  }

  public emitCanvasState(): Promise<AddonZones> {
    return new Promise((resolve, reject) => {
      this.zones$.pipe(take(1)).subscribe(rawZones => {
        const zones = rawZones.filter(zone => !zone.removed).map(zone => ({...zone}));

        const canvas = this.newCanvasService.getCanvas();
        const canvasSize = this.newCanvasService.getCanvasSize();

        for (let index = 0; index < canvas.node.childNodes.length; index++) {
          let feature = canvas.get(index);
          if (feature.type === 'g') {
            if (feature.node.id.includes('line_in_out')) {
              let newNodeId = '';
              let newFeature;
              feature.node.childNodes.forEach(node => {
                if (node.nodeName === 'line') {
                  newNodeId = node.id;
                  newFeature = this.newCanvasService.loadSVGfromDom(node);
                }
              });
              feature = newFeature;
            }
          }

          if (
            !feature ||
            feature.type === 'defs' ||
            feature?._array?.value?.length < this.MIN_VALID_POINTS ||
            zones.length === 0
          ) {
            // filter out objects with lest then 2 points and
            // "defs" which is svg root object
            continue;
          }

          const id = feature.node?.id || feature.id;
          const zone = zones.find(z => z.id === id);

          if (!zone) {
            continue;
          }

          // eslint-disable-next-line camelcase
          zone.canvas_width = canvasSize.width;
          // eslint-disable-next-line camelcase
          zone.canvas_height = canvasSize.height;

          if (id.includes('line_in_out')) {
            feature.type = ShapeTypes.line_in_out;
          }

          switch (feature.type) {
            case ShapeTypes.circle:
            case ShapeTypes.group:
              zone.shape = this.generateShapeForCircle(feature);
              break;
            // they are polygons
            case ShapeTypes.Polygon:
            case ShapeTypes.polygon:
            case ShapeTypes.polygon_excluded:
              // eslint-disable-next-line no-case-declarations
              const rotation = feature.transform('rotation');
              // eslint-disable-next-line no-case-declarations
              const lineInDirection = this.recalculateDirection(rotation, 1);
              // eslint-disable-next-line no-case-declarations
              const lineOutDirection = this.recalculateDirection(rotation, -1);

              zone.shape = this.generateShapeForPolygon(feature);
              zone.original_shape = this.generateOriginalShape(feature);
              zone.matrix = this.getMatrix(feature);
              zone.line_in_direction = lineInDirection;
              zone.line_out_direction = lineOutDirection;
              break;
            case ShapeTypes.line_in_out:
              zone.shape = this.generateShapeForPolygon(feature);
              zone.original_shape = this.generateOriginalShape(feature);
              zone.isInEnabled = feature.attr('isInEnabled') === 'true';
              zone.isOutEnabled = feature.attr('isOutEnabled') === 'true';
              const direction = LineInOutFeature.calculateDirection(
                {x: zone.shape[0][0], y: zone.shape[0][1]},
                {x: zone.shape[1][0], y: zone.shape[1][1]}
              );
              const normalX = direction.x;
              const normalY = direction.y;
              const angleRad = Math.atan2(normalY, normalX);
              let angleDeg = (angleRad * 180) / Math.PI;
              if (angleDeg < 0) {
                angleDeg += 360;
              }
              zone.original_shape;
              zone.matrix = this.getRotationMatrix(
                [
                  {x: zone.shape[0][0], y: zone.shape[0][1]},
                  {x: zone.shape[1][0], y: zone.shape[1][1]}
                ],
                [
                  {x: zone.original_shape[0][0], y: zone.original_shape[0][1]},
                  {x: zone.original_shape[1][0], y: zone.original_shape[1][1]}
                ]
              );
              zone.line_in_direction = this.recalculateDirection(angleDeg, 1);
              zone.line_out_direction = this.recalculateDirection(angleDeg, -1);
              break;
            default:
              console.info(`the selected shape is processing by default export: ${feature.type}`);
              zone.shape = this.generateShapeForPolygon(feature);
              break;
          }

          const metadata = feature.attr('metadata');
          if (!!metadata) {
            zone.metadata = JSON.parse(metadata);
          }

          // eslint-disable-next-line camelcase
          zone.shape_type = this.mapShapeType(zone.shape_type);
        }

        const addonZones: AddonZones = {zones};
        for (const zone of addonZones.zones) {
          if (zone.shape_type === ShapeTypes.circle) {
            zone.shape = {
              r: (zone.shape as any).r,
              cx: (zone.shape as any).cx / this.imageSize.width,
              cy: (zone.shape as any).cy / this.imageSize.height
            };
          } else {
            const savedShape = [];
            if ((zone.shape as any).length < this.MIN_VALID_POINTS) {
              // it shouldn't do anything when is not a valid shape
              zone.shape = [];
              continue;
            }

            for (const point of zone.shape as number[][]) {
              const xy = [point[0] / this.imageSize.width, point[1] / this.imageSize.height];
              savedShape.push(xy);
            }
            zone.shape = savedShape;
          }

          if (zone.shape_type === ShapeTypes.polygon_excluded) {
            zone.shape_type = ShapeTypes.polygon;
            if (!zone.tags || zone?.tags.length === 0) {
              zone.tags = [];
            }

            if (!zone.tags.includes('remove_when_inside_zone')) {
              zone.tags = [...zone.tags, 'remove_when_inside_zone'];
            }
          }

          if (zone.shape_type === ShapeTypes.perspective) {
            zone.shape_type = ShapeTypes.polygon;
            if (!zone.tags || zone?.tags.length === 0) {
              zone.tags = [];
            }

            if (!zone.tags.includes('perspective_zone')) {
              zone.tags = [...zone.tags, 'perspective_zone'];
            }

            delete zone.perspective;
          }
        }

        resolve(addonZones);
      });
    });
  }

  public emitEvent(eventName: EVENTS, value?: any): void {
    this.unleashAnalyticsService.logEvent(eventName, value);
  }

  public storeTriggerPointFromZonesList(blockName: TriggerPoint, zoneId: string): void {
    this.setTemporalTooltipTriggerPoint(blockName);
    this.setTriggerPoint(blockName, zoneId);
  }

  // This is only for Polygon and Rectangle old name values don't map new values here
  private mapShapeType(shape_type: ShapeTypes): ShapeTypes {
    switch (shape_type) {
      case ShapeTypes.Polygon:
        return ShapeTypes.polygon;
      case ShapeTypes.Rectangle:
        return ShapeTypes.rectangle;
      default:
        return shape_type as ShapeTypes;
    }
  }

  private generateShapeForCircle(feature: any) {
    return {
      r: feature.attr('r'),
      cx: feature.attr('cx'),
      cy: feature.attr('cy')
    };
  }

  private recalculateDirection(degree: number, multiplier: number): [number, number] {
    let direction = [1, -1];
    direction = direction.map(x => x * multiplier);
    const DEGREES = 180;

    const radian = degree * (Math.PI / DEGREES);
    if (Math.cos(radian) < 0) {
      direction = direction.map(x => x * -1);
    }
    return [direction[0], direction[1]];
  }

  private generateShapeForPolygon(feature: any) {
    const matrix = new SVG.Matrix(feature.transform());
    const pointArray = feature.array();
    return this.manageZoneService.transformPointArray(pointArray.value, matrix);
  }

  private generateOriginalShape(feature: any): number[][] {
    return feature.array().value;
  }

  private getMatrix(feature: any) {
    return new SVG.Matrix(feature.transform());
  }

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.closeDraw();
    }
  }

  public openHelp(): void {
    this.helpService.goToKnowledgePage();
  }

  public selectZone(zone: Zone): void {
    this.managerZonesDialogService.setSelectedZoneId(zone.id);
    this.temporalTriggerPoint = zone.properties?.anchor_position || TriggerPoint.center;
    this.setHoverBlockName(this.temporalTriggerPoint);

    this.drawStatus$.pipe(take(1)).subscribe(drawStatus => {
      if (drawStatus !== DrawStatus.stop) {
        return;
      }
      this.displayNameForm.controls.displayName.setValue(zone.display_name);
      if (zone.shape_type === ShapeTypes.perspective) {
        this.setPerpectiveZoneValue(zone);
      }

      if (zone.shape_type === ShapeTypes.line_in_out) {
        //Handle old line_in_out shapes they have 4 points
        this.inOutForm.controls.isInEnabled.setValue(zone.original_shape.length > 2 ? true : zone?.isInEnabled);
        this.inOutForm.controls.isOutEnabled.setValue(zone.original_shape.length > 2 ? true : zone?.isOutEnabled);
      }

      this.newCanvasService.selectShape({id: zone.id, shapeType: zone.shape_type} as SingleLabel);
      this.zones$.pipe(take(1)).subscribe(zones => {
        const zonesToDisableMouseEvents = zones.map(zoneToMap => ({id: zoneToMap.id, shapeType: zoneToMap.shape_type}));
        this.newCanvasService.disableTagNameEvents(zonesToDisableMouseEvents);
      });

      const isOutImage = this.isShapeOutsideImage(zone);
      if (isOutImage && this.zoomService.zoom.scaleLevel > 3) {
        this.zoomService.zoom.zoomOut();
        this.zoomService.zoom.zoomOut();
        this.zoomService.zoom.zoomOut();
      }
      this.managerZonesDialogService.prepareHint(zone.shape_type);
    });
  }

  private isShapeOutsideImage(zone: Zone): boolean {
    let isOutImage = false;
    const minX = 0;
    const minY = 0;
    const maxX = zone.canvas_width;
    const maxY = zone.canvas_height;

    zone.original_shape.forEach(point => {
      const [x, y] = point;
      if (x < minX || x > maxX) {
        isOutImage = true;
        return isOutImage;
      }

      if (y < minY || y > maxY) {
        isOutImage = true;
        return isOutImage;
      }
    });

    return isOutImage;
  }

  public updateDisplayName(displayName: string, zoneId: string): void {
    this.managerZonesDialogService.updateZoneName(displayName, zoneId);
  }

  public storePhysicalDimensions(): void {
    this.managerZonesDialogService.storePhysicalDimensions(this.perspectiveForm.value as {a: number; b: number});
  }

  public toggleAdvancedMode(): void {
    this.manageZonesDialogStateService.toggleAdvancedModeState();
  }

  public updateCurrentColorTemporal(colorName: LabelColorName): void {
    const color = this.getColor(colorName);

    this.managerZonesDialogService.selectedZone$.pipe(take(1)).subscribe(zone => {
      const selectedShapes: SingleLabel[] = [{id: zone.id, shapeType: zone.shape_type} as SingleLabel];
      this.newCanvasService.updateColorBulk({selectedShapes, color: color.fill});
    });
  }

  public updateCurrentColor(colorName: LabelColorName): void {
    const color = this.getColor(colorName);
    this.managerZonesDialogService.updateCurrentColor(color);

    this.managerZonesDialogService.selectedZone$.pipe(take(1)).subscribe(zone => {
      const selectedShapes: SingleLabel[] = [{id: zone.id, shapeType: zone.shape_type} as SingleLabel];
      this.newCanvasService.updateColorBulk({selectedShapes, color: color.fill});
    });
  }

  public updateCurrentColorByZone(colorName: LabelColorName, zone: Zone): void {
    const color = this.getColor(colorName);
    this.managerZonesDialogService.updateCurrentColorByZone(color, zone);

    const selectedShapes: SingleLabel[] = [{id: zone.id, shapeType: zone.shape_type} as SingleLabel];
    this.newCanvasService.updateColorBulk({selectedShapes, color: color.fill});
  }

  private getColor(colorName: LabelColorName) {
    const rgba = this.rgbPicker(colorName);

    return {
      name: colorName,
      background: colorName,
      stroke: rgba[0],
      fill: rgba[1]
    };
  }

  private closeDrawBasedOnDrawStatus(drawStatus: DrawStatus): void {
    if (drawStatus === DrawStatus.stop) {
      return;
    }

    if (drawStatus === DrawStatus.draw) {
      this.handleDrawStatus();
      return;
    }

    if (drawStatus === DrawStatus.edit) {
      this.handleEditStatus();
      return;
    }
  }

  private handleDrawStatus(): void {
    this.newCanvasService.completeDraw();
    if (this.newCanvasService.selectedTool.type !== ShapeTypes.line_in_out) {
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type);
    }
  }

  private handleEditStatus(): void {
    if (this.perspectiveForm.invalid && this.newCanvasService.selectedTool.type === ShapeTypes.perspective) {
      return;
    }

    this.newCanvasService.stopSelection();
    this.newCanvasService.stopDraw();
    this.storeDisplayName();
    this.storePhysicalDimensions();
    this.storeTriggerPoint();
    this.updateCurrentColor(this.getColorName(tinycolor(this.newCanvasService.selectedTool.color).toRgbString()));
    this.newCanvasService.clearBackup();
    this.enableTagNameEventsForZones();
    this.save();
    this.resetForms();
  }

  private storeTriggerPoint() {
    this.selectedZone$.pipe(take(1)).subscribe(zone => {
      if (zone.properties?.anchor_position !== this.temporalTriggerPoint) {
        this.setTriggerPoint(this.temporalTriggerPoint, this.newCanvasService.selectedTool.id);
        this.setTemporalTooltipTriggerPoint(this.temporalTriggerPoint);
      }
    });
  }

  private enableTagNameEventsForZones(): void {
    this.zones$.pipe(take(1)).subscribe(zones => {
      const zonesToDisableMouseEvents = zones.map(zoneToMap => ({id: zoneToMap.id, shapeType: zoneToMap.shape_type}));
      this.newCanvasService.enableTagNameEvents(zonesToDisableMouseEvents);
    });
  }

  private resetForms(): void {
    this.displayNameForm.reset();
    this.perspectiveForm.reset();
    this.temporalTriggerPoint = TriggerPoint.center;
  }

  public getColorName(color: string): LabelColorName {
    const [r, g, b] = color.match(/\d+/g).map(x => parseInt(x));

    const rgb = `rgb(${r}, ${g}, ${b})`;

    switch (rgb) {
      case LabelColor.grey:
        return LabelColorName.grey;
      case LabelColor.blue:
        return LabelColorName.blue;
      case LabelColor.skyblue:
        return LabelColorName.skyblue;
      case LabelColor.purple:
        return LabelColorName.purple;
      case LabelColor.pink:
        return LabelColorName.pink;
      case LabelColor.red:
        return LabelColorName.red;
      case LabelColor.orange:
        return LabelColorName.orange;
      case LabelColor.lightorange:
        return LabelColorName.lightorange;
      case LabelColor.yellow:
        return LabelColorName.yellow;
      case LabelColor.lightgreen:
        return LabelColorName.lightgreen;
      case LabelColor.green:
        return LabelColorName.green;
      case LabelColor.black:
        return LabelColorName.black;
      default:
        return LabelColorName.blue;
    }
  }

  public highlightShape(zoneId: string): void {
    this.newCanvasService.highlightShape(zoneId);
  }

  public restoreShapesOpacity(zoneId: string): void {
    this.newCanvasService.setDefaultOpacity(zoneId);
  }

  public setTriggerPoint(blockName: TriggerPoint, zoneId: string): void {
    this.managerZonesDialogService.setTriggerPoint(blockName, zoneId);
  }

  public setTemporalTriggerPoint(blockName: TriggerPoint): void {
    this.temporalTriggerPoint = blockName;
  }

  public setTemporalTooltipTriggerPoint(blockName: TriggerPoint): void {
    this.temporalTriggerPointTooltip = blockName;
  }

  public setHoverBlockName(blockName: TriggerPoint): void {
    this.hoverBlockName.next(blockName);
  }

  public trackByFn(index: number, zone: Zone) {
    return zone.id;
  }

  public toggleIn(): void {
    this.managerZonesDialogService.toggleIn();
  }

  public toggleOut(): void {
    this.managerZonesDialogService.toggleOut();
  }

  private watchZoneHighlight(): void {
    this.hoverZoneSub = this.newCanvasService.hoverZone$.subscribe(zoneId => {
      this.zoneHighlight.next(zoneId);
    });
  }

  private idGenerator(shapeType: string): string {
    return `${shapeType}_${uuidv4()}`;
  }

  private colorGenerator(): ZoneColor {
    const index = Math.floor(Math.random() * this.availableColorsName.length);
    const selectedName = this.availableColorsName[index];
    this.availableColorsName.splice(index, 1);
    return this.getColor(selectedName);
  }

  private rgbPicker(colorName: string): string[] {
    const maxValue = 100;
    let r: number;
    let g: number;
    let b: number;

    switch (colorName) {
      case LabelColorName.grey:
        [r, g, b] = LabelColor.grey.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.blue:
        [r, g, b] = LabelColor.blue.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.skyblue:
        [r, g, b] = LabelColor.skyblue.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.purple:
        [r, g, b] = LabelColor.purple.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.pink:
        [r, g, b] = LabelColor.pink.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.red:
        [r, g, b] = LabelColor.red.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.orange:
        [r, g, b] = LabelColor.orange.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.lightorange:
        [r, g, b] = LabelColor.lightorange.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.yellow:
        [r, g, b] = LabelColor.yellow.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.lightgreen:
        [r, g, b] = LabelColor.lightgreen.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.green:
        [r, g, b] = LabelColor.green.match(/\d+/g).map(x => parseInt(x));
        break;
      case LabelColorName.black:
        [r, g, b] = LabelColor.black.match(/\d+/g).map(x => parseInt(x));
        break;
      default:
        r = Math.round(Math.random() * maxValue);
        g = Math.round(Math.random() * maxValue);
        b = Math.round(Math.random() * maxValue);
        break;
    }

    const rgbaStroke = `rgba(${r},${g},${b},1)`;
    const rgbaFill = `rgba(${r},${g},${b},0.5)`;
    return [rgbaStroke, rgbaFill];
  }

  private watchSwitchInOut(): void {
    this.inClickSub = this.newCanvasService.inClick$.subscribe(() => {
      this.inOutForm.controls.isInEnabled.setValue(!this.inOutForm.value.isInEnabled);
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'inClick');
    });

    this.outClickSub = this.newCanvasService.outClick$.subscribe(() => {
      this.inOutForm.controls.isOutEnabled.setValue(!this.inOutForm.value.isOutEnabled);
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'outClick');
    });
  }

  private watchDrawPoints(): void {
    this.drawPointSub = this.newCanvasService.drawPoint$.subscribe(() => {
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'drawPoint');
    });
  }

  private watchShapeIsResizing(): void {
    this.shapeIsResizingSub = this.newCanvasService.shapeIsResizing$.subscribe(shapeIsResizing => {
      if (this.newCanvasService.selectedTool.type !== ShapeTypes.line_in_out) {
        return;
      }

      if (!shapeIsResizing) {
        this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'shapeIsResizing');
      }
    });
  }

  private watchSwapInOutClick(): void {
    this.lineInSwapClickSub = this.newCanvasService.lineInSwapClick$.subscribe(() => {
      if (this.newCanvasService.selectedTool.type !== ShapeTypes.line_in_out) {
        return;
      }
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'lineInSwapClick');
    });

    this.lineOutSwapClickSub = this.newCanvasService.lineOutSwapClick$.subscribe(() => {
      if (this.newCanvasService.selectedTool.type !== ShapeTypes.line_in_out) {
        return;
      }
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'lineInSwapClick');
    });
  }

  private watchShapeMove(): void {
    this.newCanvasService.dragMove$.subscribe(() => {
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type, 'drawMove');
      this.newCanvasService.stopMoveShapeSub();
    });
  }

  public swapDirections(): void {
    this.newCanvasService.swapDirections();
  }

  private watchHasShape(): void {
    if (this.hasShapeSub) {
      this.hasShapeSub.unsubscribe();
      this.hasShapeSub = null;
    }

    this.hasShapeSub = this.newCanvasService.hasShape$.pipe(filter(hasShape => hasShape)).subscribe(() => {
      this.managerZonesDialogService.prepareHint(this.newCanvasService.selectedTool.type);
    });
  }

  private getRotationMatrix(originalPoints: any[], currentPoints: any[]): any {
    const originalX1 = originalPoints[0].x;
    const originalY1 = originalPoints[0].y;
    const originalX2 = originalPoints[1].x;
    const originalY2 = originalPoints[1].y;
    const currentX1 = currentPoints[0].x;
    const currentY1 = currentPoints[0].y;
    const currentX2 = currentPoints[1].x;
    const currentY2 = currentPoints[1].y;

    const deltaX1 = originalX1 - originalX2;
    const deltaX2 = currentX1 - currentX2;
    const deltaY1 = originalY1 - originalY2;
    const deltaY2 = currentY1 - currentY2;

    const angle1 = Math.atan2(deltaY1, deltaX1);
    const angle2 = Math.atan2(deltaY2, deltaX2);
    const angleInRadians = angle2 - angle1;

    const cosAngle = Math.cos(angleInRadians);
    const sinAngle = Math.sin(angleInRadians);
    const e = currentX1 - (originalX1 * cosAngle - originalY1 * sinAngle);
    const f = currentY1 - (originalX1 * sinAngle + originalY1 * cosAngle);

    const matrix: any = {
      a: cosAngle,
      b: -sinAngle,
      c: sinAngle,
      d: cosAngle,
      e: e,
      f: f
    };

    return matrix;
  }

  private setPerpectiveZoneValue(zone: Zone): void {
    const a = zone?.perspective?.a || zone?.properties?.target_width || 0;
    const b = zone?.perspective?.b || zone?.properties?.target_height || 0;

    this.perspectiveForm.controls.a.setValue(a);
    this.perspectiveForm.controls.b.setValue(b);
  }
}

export interface LiveStreamSnapshot {
  data: string;
  height: number;
  width: number;
}

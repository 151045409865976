<div class="container" *ngIf="plan">
  <div class="title">{{ plan.name }}</div>

  <div class="features" *ngIf="isDeveloperMode; else nonDeveloperFeatures">
    <unleash-developer-plan-features [features]="plan.features" [model]="plan.model"></unleash-developer-plan-features>
  </div>

  <ng-template #nonDeveloperFeatures>
    <div class="features" *ngIf="plan.model">
      <unleash-plan-features [plan]="plan.model"></unleash-plan-features>
    </div>
  </ng-template>

  <div class="more">
    {{ plan.more }}
  </div>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap=""
  (mousedown)="disableMapPanning()"
  (mouseup)="enableMapPanning()"
>
  <mat-slider
    *ngIf="isSliderVisible"
    fxFlex
    color="accent"
    min="0"
    (change)="changeTime($event)"
    thumbLabel
    [displayWith]="formatLabel"
    [max]="numFrames"
    step="1"
    [value]="time"
  ></mat-slider>
  <mat-chip-list fxFlex="120px" aria-orientation="horizontal" color="primary">
    <mat-chip>{{ time * 100 | uUtc | uDateFormat: 'mm:ss' }} </mat-chip>
    <mat-chip (click)="changeSpeed()">{{ speed }}x</mat-chip>
  </mat-chip-list>
  <ng-container *ngIf="isSliderVisible">
    <button *ngIf="!isPlaying" (click)="play(); $event.stopPropagation()" fxFlex="56px" color="primary" mat-fab>
      <mat-icon>play_arrow</mat-icon>
    </button>
    <button *ngIf="isPlaying" (click)="pause(); $event.stopPropagation()" fxFlex="56px" color="primary" mat-fab>
      <mat-icon>pause</mat-icon>
    </button>
  </ng-container>
</div>

<div class="container">
  <div class="logo">
    <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
  </div>

  <div class="title">
    <div class="title__text">{{ 'auth.confirmPhone.title' | translate }}</div>
  </div>
  <div class="confirmation-message">
    {{ 'auth.confirmPhone.confirmationMessage' | translate }}
    <span class="confirmation-message__phone">{{ phoneNumber$ | async }}</span>
  </div>
  <form [formGroup]="verificationForm">
    <mat-form-field appearance="fill" class="code">
      <mat-label>{{ 'auth.confirmPhone.label' | translate }}</mat-label>
      <input matInput formControlName="code" />
      <mat-error *ngIf="verificationForm.controls.code.hasError('required')">{{
        'auth.confirmPhone.required' | translate
      }}</mat-error>
      <mat-error *ngIf="verificationForm.controls.code.hasError('invalidCode')">
        {{ 'auth.confirmPhone.invalidCode' | translate }}
        <a (click)="sendCode()" class="resend"> {{ 'auth.verification.resendCode' | translate }} </a>
      </mat-error>
    </mat-form-field>
    <div class="actions">
      <button type="button" (click)="back()" mat-button color="primary">
        {{ 'auth.confirmPhone.back' | translate }}
      </button>
      <button
        type="submit"
        (click)="continue()"
        mat-flat-button
        color="primary"
        [appLadda]="isInProgress | async"
        [disabled]="isInProgress | async"
      >
        {{ 'auth.confirmPhone.continue' | translate }}
      </button>
    </div>
  </form>
</div>

import {NotificationModel} from '@app/core/models/api/notifications.model';

export const newNotificationSubscription = (userId: string) => {
  return `subscription getNewNotifications {
    newNotifications(userId: "${userId}") {
      title,
      message,
      source,
      sourceCreatedAt,
      sourceType,
      userId,
      state,
      level,
      deviceId,
      data {
        jobId,
        modelId,
        planId,
      },
      action,
      actionUrl,
      progress,
      priority,
      icon,
      isRead,
      createdAt,
      updatedAt,
      isInProgress
    }
  }`;
};

export const notificationUpdatedSubscription = (userId: string) => {
  return `subscription getUpdatedNotifications {
    updatedNotifications(userId: "${userId}") {
      title,
      message,
      source,
      sourceCreatedAt,
      sourceType,
      userId,
      state,
      level,
      deviceId,
      data {
        jobId,
        modelId,
        planId,
      },
      action,
      actionUrl,
      progress,
      isInProgress,
      priority,
      icon,
      isRead,
      createdAt,
      updatedAt
    }
  }`;
};

export const getNotificationQuery = (notificationId: string, createdAt: number) => {
  return `query getNotification{
    get(
        id: "${notificationId}",
        createdAt: ${createdAt}
      ) {
        source
        data
        createdAt
        from
        updatedAt
        status
        isRead
    }
  }`;
};

export const listNotificationQuery = (
  userId: string,
  isInProgress: boolean,
  pageLimit: number = 10,
  nextToken: string = null
) => {
  return `query listNotifications {
    list(userId: "${userId}", isInProgress: ${isInProgress}, limit: ${pageLimit}${
    !!nextToken ? ', nextToken: "' + nextToken + '"' : ''
  }) {
        nextToken,
        items {title, message, source, sourceType, createdAt, updatedAt,
          priority, state, level, action, actionUrl, icon, progress, isRead, userId, sourceCreatedAt, isInProgress
          data {modelId,jobId,sessionId,planId}
        }
    }
  }`;
};

export const updateNotificationQuery = (notification: NotificationModel) => {
  return `mutation markRead{
    markRead(
        userId: "${notification.userId}",
        sourceCreatedAt: "${notification.sourceCreatedAt}",
    ) {
      isRead
    }
  }`;
};

export const getUnreadCount = userId => {
  return `query getUnreadCount{
    getUnreadCount(
        userId: "${userId}",
    ) {
      count
    }
  }`;
};

export const deleteNotificationQuery = (notification: NotificationModel) => {
  return `mutation deleteNotification{
    deleteNotification(
        userId: "${notification.userId}",
        createdAt: ${notification.createdAt},
    ) {
      createdAt
      updatedAt
    }
  }`;
};

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Reason} from '@app/auth/components/reason/reason.model';
import {selectReasonFormValue} from '@app/auth/components/reason/reason.selectors';
import {authLogout, authRegistrationFinished} from '@app/auth/state/auth.actions';
import {selectAuthUserInitializationFinished} from '@app/auth/state/auth.selectors';
import {CompanyModel} from '@app/core/models/api/company-model';
import {PackageNames, PlanModel} from '@app/core/models/api/plan.model';
import {UserModel} from '@app/core/models/api/user-model';
import {UserService} from '@app/core/services/api/user.service';
import {NotificationService} from '@app/core/services/notifications/notification.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {
  PaymentParameters,
  PaymentService,
  PAYMENT_PARAMETERS,
  StripeCustomerAddress
} from '@app/plans/services/payment.service';
import {PlansService} from '@app/plans/services/plans.service';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {Auth} from '@aws-amplify/auth';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {DEV_ACCESS_BENEFITS, PLANS, PlanTemplate, PlanTemplates, PLAN_KEYS} from './payment.config';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss']
})
export class PaymentPage implements OnInit, OnDestroy {
  public selectedPaymentPeriod: PaymentPeriod = PaymentPeriod.month;
  public selectedPlan$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public paymentInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthInitialized$: Observable<boolean> = this.store.select(selectAuthUserInitializationFinished);
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public devAccessBenefits = DEV_ACCESS_BENEFITS;

  private plansTemplate: PlanTemplates = PLANS;

  constructor(
    private router: Router,
    private store: Store,
    private paymentService: PaymentService,
    private notificationService: NotificationService,
    private plansService: PlansService,
    private userService: UserService,
    private unleashAnalyticsService: UnleashAnalyticsService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.setPlans();
  }

  public ngOnDestroy(): void {}

  public signOut() {
    this.store.dispatch(authLogout({isDeveloperMode: this.isDeveloperMode}));
  }

  public subscribeUser(token: {
    id: string;
    address: StripeCustomerAddress;
    promoCode: string;
    hasToSkipPayment: boolean;
  }): void {
    this.paymentInProgress$.next(true);
    Auth.currentAuthenticatedUser().then((user: UserModel): void => {
      this.selectedPlan$
        .pipe(map((planTemplate: PlanTemplate): PlanModel => planTemplate.model))
        .subscribe((selectedPlan: PlanModel): void => {
          const paymentPeriod: PaymentPeriod =
            this.selectedPaymentPeriod === PaymentPeriod.month ? PaymentPeriod.month : PaymentPeriod.annual;
          const paymentParameters: PaymentParameters = {
            stripeToken: token.id,
            stripeEmail: paymentPeriod,
            period: paymentPeriod,
            planId: selectedPlan.id,
            address: token.address
          };

          if (!!token.promoCode) {
            paymentParameters.couponCode = token.promoCode;
          }

          if (token.hasToSkipPayment) {
            delete paymentParameters[PAYMENT_PARAMETERS.STRIPE_TOKEN];
            delete paymentParameters[PAYMENT_PARAMETERS.STRIPE_EMAIL];
            delete paymentParameters[PAYMENT_PARAMETERS.ADDRESS];
          }

          this.paymentService.createUserSubscription(paymentParameters).subscribe(
            success => {
              this.userService.updatePlanInUserDataStore(selectedPlan.id, paymentPeriod);
              this.store.dispatch(authRegistrationFinished());
              this.paymentInProgress$.next(false);
              this.logTrialStartedEventInAnalyticsService(user.email, selectedPlan.id);
            },
            error => {
              this.notificationService.error(error.message);
              this.paymentInProgress$.next(false);
            },
            () => {
              this.paymentInProgress$.next(false);
            }
          );
        });
    });
  }

  public setPaymentPeriod(period: PaymentPeriod): void {
    this.selectedPaymentPeriod = period;
  }

  public termsOfService(): void {
    const win: Window = window.open('https://unleashlive.com/terms-and-conditions.html', '_blank');
    win.focus();
  }

  public back(): void {
    if (this.isDeveloperMode) {
      this.router.navigate(['auth/developer-profile']);
      return;
    }

    this.router.navigate(['auth/reason']);
  }

  private setPlans(): void {
    this.plansService.plans
      .pipe(
        filter((plans: PlanModel[]): boolean => !!plans),
        tap((plans: PlanModel[]): void => {
          if (this.isDeveloperMode) {
            this.setDeveloperPlan(plans);
            return;
          }

          this.setNonDeveloperPlans(plans);
        }),
        switchMap(() => this.translateService.get('auth.payment.plans'))
      )
      .subscribe(translations => this.setTranslations(translations));
  }

  private setDeveloperPlan(plans: PlanModel[]): void {
    const plan: PlanModel = plans.find(
      (plansItem: PlanModel): boolean => plansItem.id === this.plansTemplate[PLAN_KEYS.DEVELOPER].planId
    );
    if (plan) {
      this.plansTemplate[PLAN_KEYS.DEVELOPER].priceMonthly = plan.priceMonthly;
      this.plansTemplate[PLAN_KEYS.DEVELOPER].priceAnnual = plan.priceAnnual;
      this.plansTemplate[PLAN_KEYS.DEVELOPER].model = plan;
      this.selectedPlan$.next({...this.plansTemplate[PLAN_KEYS.DEVELOPER]});
    } else {
      console.error(`Developer plan not found in the database.`);
    }
  }

  private setNonDeveloperPlans(plans: PlanModel[]): void {
    Object.keys(this.plansTemplate)
      .filter(k => k !== PLAN_KEYS.DEVELOPER)
      .forEach((key: string): void => {
        const plan: PlanModel = plans.find(
          (plansItem: PlanModel): boolean => plansItem.id === this.plansTemplate[key].planId
        );
        if (plan) {
          this.plansTemplate[key].priceMonthly = plan.priceMonthly;
          this.plansTemplate[key].priceAnnual = plan.priceAnnual;
          this.plansTemplate[key].model = plan;
        } else {
          console.error(`Plan ${key} not found in the database.`);
        }
      });

    this.store
      .pipe(
        select(selectReasonFormValue),
        map((reason: Reason): Reason | string => reason || 'mapping')
      )
      .subscribe((key: Reason): void => {
        this.selectedPlan$.next({...this.plansTemplate[key.reason]});
      });
  }

  private logTrialStartedEventInAnalyticsService(username: string, selectedPlanId: string): void {
    this.userService.myCompany$.pipe(take(1)).subscribe((company: CompanyModel): void => {
      this.unleashAnalyticsService.logEvent(EVENTS.TRIAL_STARTED, {
        email: username,
        plan: selectedPlanId,
        industry: company.industry
      });
    });
  }

  private setTranslations(translations): void {
    const {broadcastDesc, broadcastMore, insightsDesc, insightsMore, modelingDesc, modelingMore} = translations;

    this.plansTemplate[PLAN_KEYS.MAPPING].name = PackageNames.modelling;
    this.plansTemplate[PLAN_KEYS.MAPPING].description = modelingDesc;
    this.plansTemplate[PLAN_KEYS.MAPPING].more = modelingMore;

    this.plansTemplate[PLAN_KEYS.LIVE_STREAM].name = PackageNames.broadcast;
    this.plansTemplate[PLAN_KEYS.LIVE_STREAM].description = broadcastDesc;
    this.plansTemplate[PLAN_KEYS.LIVE_STREAM].more = broadcastMore;

    this.plansTemplate[PLAN_KEYS.AI_INSIGHTS].name = PackageNames.insights;
    this.plansTemplate[PLAN_KEYS.AI_INSIGHTS].description = insightsDesc;
    this.plansTemplate[PLAN_KEYS.AI_INSIGHTS].more = insightsMore;
  }
}

<section *ngIf="slideToDisplay$ | async as slide; else warningTemplate" class="slideshow">
  <div class="background">
    <div
      class="background__image"
      [ngStyle]="{
        'background-image':
          'linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(' + slide.backgroundImageSrc + ')'
      }"
    ></div>
  </div>
</section>
<ng-template #warningTemplate>
  <section>
    <!-- Please setup slideshow -->
  </section>
</ng-template>

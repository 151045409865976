import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {AnalysisFilter} from '@app/analysis/models/filter.model';
import {Paginator, SortType} from '@app/analysis/models/paginator.model';
import {DELETE_REPORT_TYPES, ParsedReport, ReportModel} from '../models/analysis.model';

export enum ReportsActions {
  GetAllReports = '[Analysis] Get All Reports',
  GetAllReportsSuccess = '[Analysis] Get All Reports Success',
  GetAllReportsFail = '[Analysis] Get All Reports Fail',

  SortAllReports = '[Analysis] Sort All Reports',
  GeneratePaginatedReports = '[Analysis] Generate Paginated Reports',
  GenerateDisplayedReportsSuccess = '[Analysis] Generate Displayed Reports Success',
  FilterReports = '[Analysis] Filter Analysis',
  ClearFilter = '[Analysis] Clear Filter',
  SaveDisplayedReportsSuccess = '[Analysis] Save Displayed Reports Success',

  DeleteAnalysisReports = '[Analysis] Delete Analysis Reports',
  DeleteAnalysisReportsSuccess = '[Analysis] Delete Analysis Reports Success',
  DeleteAnalysisReportsFail = '[Analysis] Delete Analysis Reports Fails',

  ToggleSelectedReports = '[Analysis] Toggle Select Reports',
  ClearSelectedReports = '[Analysis] Clear Selected Reports',
  OpenMobileFilterDialog = '[Analysis] Open Mobile Filter Dialog',
  HideFooter = '[Analysis] Hide Footer',

  GenerateApiKey = '[Analysis] Generate Api Key',
  GenerateApiKeySuccess = '[Analysis] Generate Api Key Success',
  CopyKey = '[Analysis] Copy Key',
  RemoveKeyDialog = '[Analysis] Open Remove Key Dialog',
  RemoveKey = '[Analysis] Remove Key',
  RemoveKeySuccess = '[Analysis] Remove Key Success',
  UpdateKeyDialog = '[Analysis] Update Key Dialog',
  UpdateKey = '[Analysis] Update Key',
  UpdateKeySuccess = '[Analysis] Update Key Success'
}

export const getAllReports = createAction(ReportsActions.GetAllReports);

export const getAllReportsSuccess = createAction(
  ReportsActions.GetAllReportsSuccess,
  props<{reports: ReportModel[]}>()
);

export const getAllReportsFail = createAction(ReportsActions.GetAllReportsFail, props<{error: HttpErrorResponse}>());

export const sortAllReports = createAction(ReportsActions.SortAllReports, props<{payload: SortType}>());

export const generatePaginatedReports = createAction(
  ReportsActions.GeneratePaginatedReports,
  props<{payload: {pageIndex: number; pageSize: number}}>()
);
export const generateDisplayedReportsSuccess = createAction(
  ReportsActions.GenerateDisplayedReportsSuccess,
  props<{payload: {displayedReports: ParsedReport[]; paginator: Paginator}}>()
);

export const filterReports = createAction(ReportsActions.FilterReports, props<{payload: AnalysisFilter}>());
export const clearFilter = createAction(ReportsActions.ClearFilter);

export const saveDisplayedReportsSuccess = createAction(
  ReportsActions.SaveDisplayedReportsSuccess,
  props<{payload: ParsedReport[]}>()
);

export const deleteAnalysisReports = createAction(
  ReportsActions.DeleteAnalysisReports,
  props<{payload: {sessionIds: string[]; deleteReportType: DELETE_REPORT_TYPES}}>()
);

export const deleteAnalysisReportsSuccess = createAction(
  ReportsActions.DeleteAnalysisReportsSuccess,
  props<{payload: {sessionIds: string[]}}>()
);

export const deleteAnalysisReportsFail = createAction(
  ReportsActions.DeleteAnalysisReportsFail,
  props<{payload: {reportIds: number[]}}>()
);

export const toggleSelectedReports = createAction(
  ReportsActions.ToggleSelectedReports,
  props<{payload: {report: ParsedReport}}>()
);

export const clearSelectedReports = createAction(ReportsActions.ClearSelectedReports);
export const openMobileFilterDialog = createAction(ReportsActions.OpenMobileFilterDialog);
export const hideFooter = createAction(ReportsActions.HideFooter, props<{payload: {hasToHideFooter: boolean}}>());
export const generateApiKey = createAction(ReportsActions.GenerateApiKey);
export const generateApiKeySuccess = createAction(
  ReportsActions.GenerateApiKeySuccess,
  props<{payload: {key: string}}>()
);

export const copyKey = createAction(ReportsActions.CopyKey);

export const removeKeyDialog = createAction(ReportsActions.RemoveKeyDialog);
export const removeKey = createAction(ReportsActions.RemoveKey);
export const removeKeySuccess = createAction(ReportsActions.RemoveKeySuccess);

export const updateKeyDialog = createAction(ReportsActions.UpdateKeyDialog);
export const updateKey = createAction(ReportsActions.UpdateKey);
export const updateKeySuccess = createAction(ReportsActions.UpdateKeySuccess, props<{payload: {key: string}}>());

<unleash-users-table
  [dataSource]="users$ | async"
  [menuItems]="menuItems"
  [hasMoreThanOneAdmin]="hasMoreThanOneAdmin$ | async"
  [roles]="roles$ | async"
  [isSelectionEnabled]="true"
></unleash-users-table>

<mat-dialog-actions align="end">
  <button (click)="emitCancel()" mat-button color="primary">
    {{ 'common.cancel' | translate }}
  </button>
  <button *ngIf="(users$ | async).length > 0" (click)="emitAdd()" mat-flat-button color="primary">
    {{ 'common.add' | translate }}
  </button>
</mat-dialog-actions>

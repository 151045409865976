import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AtlasLocalStorageService {
  public setItem(itemName: string, value: string): void {
    window.localStorage.setItem(itemName, value);
  }

  public getItem(itemName: string): string {
    return window.localStorage.getItem(itemName);
  }

  public removeItem(itemName: string): void {
    window.localStorage.removeItem(itemName);
  }
  
  public transformLocalStorageToJSON(itemName: string): {[key: string]: any}[] {
    const transformResult: {[key: string]: string}[] = [];
    const storedLayers: string = window.localStorage.getItem(itemName);
    if (storedLayers) {
      const splittedLayers: string[] = storedLayers.split(';');
      splittedLayers.forEach((layer: string) => {
        const jsonLayer: string = layer.replace(
          /(\w+:)|(\w+ :)/g,
          matchedLayer =>
            '"' + matchedLayer.substring(0, matchedLayer.length - 1) + '":'
        );
        if (jsonLayer !== '{}') {
          return transformResult.push(JSON.parse(jsonLayer));
        }
      });
    }
    return transformResult;
  }
}

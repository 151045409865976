import {Injectable} from '@angular/core';
import {LinkMenuItem} from '@app/core/models/gui/link-menu-item';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MenuItem} from '../models/gui/menu-item';
import {SidenavMenuFacadeService} from '@app/core/services/sidenav-menu-facade.service';

@Injectable({providedIn: 'root'})
export class SidebarStateService {
  public menuItems$: Observable<MenuItem[]> = this.sidenavMenuFacadeService.menuItems$;
  public indexLastTopMenuItems$: Observable<number> = this.sidenavMenuFacadeService.indexLastTopMenuItems$;
  public profileMenuItem$: Observable<LinkMenuItem> = this.sidenavMenuFacadeService.profileMenuItem$;
  public hasToDisplayMobileMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _expanded: boolean = true;

  constructor(private sidenavMenuFacadeService: SidenavMenuFacadeService) {}

  get expanded(): boolean {
    return this._expanded;
  }

  set expanded(expanded: boolean) {
    this._expanded = expanded;
  }

  public toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  public toggleDropdownSubmenu(dropdownId: number): void {
    this.menuItems$.pipe(take(1)).subscribe((menuItems: MenuItem[]) => {
      const items: MenuItem[] = menuItems.map(menu => {
        if (menu.id === dropdownId) {
          return {...menu, showSubmenu: !(menu as any).showSubmenu};
        }
        return menu;
      });
      this.sidenavMenuFacadeService.setMenuItems(items);
    });
  }

  public toggleMobileMenu() {
    this.hasToDisplayMobileMenu.next(!this.hasToDisplayMobileMenu.value);
  }

  public closeMobileMenu(): void {
    this.hasToDisplayMobileMenu.next(false);
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationStage} from '@app/auth/state/auth.state';
import {Auth} from '@aws-amplify/auth';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {REGISTRATION_STAGE} from '@app/auth/models/auth.models';
import {AuthService} from '@app/auth/services/auth.service';
import {AwsTokenService} from '@app/auth/services/aws-token.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {from, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {LocalStorageService} from '../../../core/services/local-storage/local-storage.service';
import {NotificationService} from '../../../core/services/notifications/notification.service';
import {
  actionSignUpFormConfirmationRequired,
  actionSignUpFormError,
  actionSignUpFormReconfirmationRequired,
  actionSignUpFormSubmit,
  actionSignUpFormSuccess,
  actionSignUpFormUpdate
} from './sign-up.actions';
import {SignUpForm} from './sign-up.model';

export const FORM_KEY = 'AUTH_FORMS.SIGNUP_FORM';

@Injectable()
export class SignUpEffects {
  persistForm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignUpFormUpdate),
        switchMap(action => {
          return of(action).pipe(
            tap(a => {
              this.localStorageService.setItem(FORM_KEY, {
                form: this.sanitizeRegisterForm(a.form)
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  performRegistration = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSignUpFormSubmit),
      tap(() => this.authService.listenAuthEvents()),
      exhaustMap(({form, awsMarketplaceToken, isDeveloperMode, promoCode}) => {
        const signUpAwsData: SignUpAwsData = {
          username: form.email.toLowerCase(),
          password: form.password,
          attributes: {
            email: form.email.toLowerCase(),
            nickname: form.name
          },
          validationData: []
        };

        const updatedAwsMarketplaceToken: string = awsMarketplaceToken;

        if (updatedAwsMarketplaceToken) {
          signUpAwsData.attributes['custom:awsMarketplaceToken'] = updatedAwsMarketplaceToken;
          return this.performSignUpWithAwsMarketplaceToken(signUpAwsData, isDeveloperMode);
        }

        if (promoCode) {
          this.localStorageService.setItem('PROMO_CODE', promoCode);
        }
        return this.performSignUp(signUpAwsData, isDeveloperMode);
      })
    )
  );

  redoConfirmation = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignUpFormReconfirmationRequired),
        tap(({form, isDeveloperMode}) => {
          this.router.navigate([`auth/${isDeveloperMode ? 'developer-' : ''}confirm-registration`]).then(result => {
            if (result) {
              Auth.resendSignUp(form.email.toLowerCase()).then(ok => {
                this.notificationService.info(
                  "This account needs email confirmation. We've re-sent the confirmation code to your email address!"
                );
              });
            }
          });
        })
      ),
    {dispatch: false}
  );

  registrationSuccessful = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignUpFormSuccess),
        tap(action => {})
      ),
    {dispatch: false}
  );

  confirmationRequired = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignUpFormConfirmationRequired),
        tap(({form, isDeveloperMode}) => {
          this.router.navigate([`auth/${isDeveloperMode ? 'developer-' : ''}confirm-registration`]).then(res => {
            this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.confirmation);
          });
        })
      ),
    {dispatch: false}
  );

  registrationError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignUpFormError),
        tap(() => this.authService.offAuthEvents())
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private notificationService: NotificationService,
    private unleashAnalyticsService: UnleashAnalyticsService,
    private awsTokenService: AwsTokenService,
    private authService: AuthService
  ) {}

  private performSignUpWithAwsMarketplaceToken(signUpAwsData: SignUpAwsData, isDeveloperMode: boolean) {
    return this.awsTokenService.checkAwsDeviceToken(signUpAwsData.attributes['custom:awsMarketplaceToken']).pipe(
      switchMap(() => this.performSignUp(signUpAwsData, isDeveloperMode)),
      catchError(error =>
        of(
          actionSignUpFormError({
            error: (error && error.response && error.response.data && error.response.data.message) || error
          })
        )
      )
    );
  }

  private performSignUp(signUpAwsData: SignUpAwsData, isDeveloperMode: boolean) {
    return from(Auth.signUp(signUpAwsData)).pipe(
      map(result => {
        window.analytics.identify({
          email: signUpAwsData.attributes.email.toLowerCase(),
          nickname: signUpAwsData.attributes.nickname
        });
        this.unleashAnalyticsService.logEvent(EVENTS.AUTH_SIGN_UP, {
          email: signUpAwsData.attributes.email.toLowerCase()
        });
        return actionSignUpFormConfirmationRequired({form: {email: signUpAwsData.attributes.email}, isDeveloperMode});
      }),
      catchError(err => of(actionSignUpFormError({error: err.message})))
    );
  }

  private sanitizeRegisterForm(form: SignUpForm): Partial<SignUpForm> {
    return (({password, ...rest}) => rest)(form);
  }
}

interface SignUpAwsData {
  username: string;
  password: string;
  attributes: {email: string; nickname: string; 'custom:awsMarketplaceToken'?: string};
  validationData: any[];
}

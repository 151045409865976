<div
  class="container"
  [class.active]="radioButton.checked || isSelectedCard"
  (click)="radioButton.checked = !radioButton.checked; emitCheckedValue(radioButton)"
  [ngClass]="isShowingBadge ? 'container--has-badge' : '' || otherOptionHasBadge ? 'container--has-badge' : ''"
>
  <div class="badge" *ngIf="isShowingBadge">
    <ng-content select="[slot='badge']"></ng-content>
  </div>
  <ng-content select="[slot='content']"></ng-content>
  <div class="option option--big-viewport">
    <mat-radio-button color="primary" #radioButton [value]="value"></mat-radio-button>
  </div>
</div>

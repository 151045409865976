/* eslint-disable no-magic-numbers */
/* eslint-disable rxjs/finnish */
import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalysisApiService {
  constructor(private apiGateway: ApiGateway) {}

  public generateApiKey(): Observable<{id: string; value: string}> {
    return this.apiGateway.post('analytics/tokens/create', null, {});
  }

  public removeApiKey(): Observable<any> {
    return this.apiGateway.delete('analytics/tokens/delete', {});
  }
}

import {Injectable} from '@angular/core';
import {fromEvent, Subject, timer} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NetworkStatusService {
  isOnline = true;
  isOnline$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    fromEvent(window, 'online').subscribe(() => {
      this.isOnline = true;
      this.isOnline$.next(this.isOnline);
    });
    fromEvent(window, 'offline').subscribe(() => {
      this.isOnline = false;
      this.isOnline$.next(this.isOnline);
    });
  }

  isOnlineTimer(start: number, interval: number) {
    return timer(start, interval).pipe(map(() => this.isOnline));
  }
}

import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DELETE_REPORT_TYPES} from '@app/analysis/models/analysis.model';

@Component({
  templateUrl: './analysis-delete-dialog.component.html',
  styleUrls: ['./analysis-delete-dialog.component.scss']
})
export class AnalysisDeleteDialogComponent {
  public deleteReportType: string;
  public deleteReportTypes = DELETE_REPORT_TYPES;

  constructor(
    private dialogRef: MatDialogRef<AnalysisDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {deleteReportType: string}
  ) {
    this.deleteReportType = this.data.deleteReportType;
  }

  public emitCancel(): void {
    this.dialogRef.close();
  }

  public emitRename(): void {
    this.dialogRef.close(this.deleteReportType);
  }
}

import {Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {RoleNamePipe} from '@app/profile/pipes/role-name.pipe';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {AclDirective} from '@app/core/directives/acl.directive';
import {Team, TeamRole} from '@app/profile/models/team.model';
import {TranslateModule} from '@ngx-translate/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {SidebarStateService} from '@app/core/services/sidebar-state.service';
import {Observable, filter, map, shareReplay, take} from 'rxjs';

@Component({
  selector: 'unleash-team-switcher',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatIconModule,
    RoleNamePipe,
    MatDividerModule,
    AclDirective,
    TranslateModule
  ],
  templateUrl: './team-switcher.component.html',
  styleUrls: ['./team-switcher.component.scss']
})
export class TeamSwitcherComponent {
  @Output() public closeDialog: EventEmitter<void> = new EventEmitter();
  public teams$: Observable<{[key: string]: Team}> = this.userStoreFacadeService.userTeams$;
  public userRole$: Observable<TeamRole> = this.userStoreFacadeService.currentTeam$.pipe(
    filter((team: Team) => !!team),
    map((team: Team) => team.role)
  );
  public totalUserTeams$: Observable<number> = this.teams$.pipe(
    map((teams: {[key: string]: Team}) => Object.keys(teams).length)
  );
  public selectedTeamId$: Observable<Team['id']> = this.userStoreFacadeService.currentTeamId$.pipe(shareReplay(1));

  public teamRole: typeof TeamRole = TeamRole;
  public aclPermissions = AclPermissions;

  constructor(
    private userStoreFacadeService: UserStoreFacadeService,
    public sideNavbarStateService: SidebarStateService
  ) {}

  public switchCurrentTeam(teamId: string): void {
    this.selectedTeamId$.pipe(take(1)).subscribe(selectedTeamId => {
      if (selectedTeamId === teamId) {
        return;
      }
      this.userStoreFacadeService.switchCurrentTeam(teamId);
    });
    this.closeDialog.emit();
  }

  public goToManageTeams(): void {
    this.userStoreFacadeService.goToManageTeams();
    this.closeDialog.emit();
  }

  public goBack(): void {
    this.closeDialog.emit();
    this.sideNavbarStateService.toggleMobileMenu();
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {FlightFrame} from '@app/flights/components/flight-log/flight-log-parser';
import {GeoJSON} from 'geojson';
import {GridLayer, icon, LatLng, latLng, Layer, Map, ZoomPanOptions} from 'leaflet';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, zip} from 'rxjs';
import {map, shareReplay, take, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {
  ASSET_GEOMETRY_TYPE,
  AssetType,
  AtlasAssetModel,
  AtlasGeojsonAssetModel
} from '../../core/models/api/atlas.model';
import {AtlasLocalStorageService} from './atlas-local-storage.service';
import {LibraryItem} from '@app/library/models/folder-file.model';
import {createMapIcon} from '../marker-icons/custom-map-pointer';
import {AtlasApiService} from '@app/atlas/services/atlas-api.service';
import {AtlasStoreFacadeService} from '@app/atlas/services/atlas-store-facade.service';
import {MarkerClusterService} from '@app/atlas/services/marker-cluster.service';
import {atlasConfig} from '@app/atlas/atlas.config';
import {MAP_VIEW} from '../model/map-view.mode';
declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class AtlasService {
  public totalAssetsCount: number = 0;
  public failedAssetsCount: number = 0;
  public loadedAssetsCount: number = 0;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public currentViewPosition: string;
  public currentZoom: number;
  public isloadingInitialPosition$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isWeatherMapEnabledForUser: boolean = false;
  public isStoredDefaultHardCodedPosition: boolean = false;
  public isReadytoDetectAtlasChanges$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public hasLayerGroupChanged$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public hasAllLayersLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public activeStreamingDevice$: ReplaySubject<UserDeviceJoined> = new ReplaySubject<UserDeviceJoined>(1);
  public deviceFlightFrame$: ReplaySubject<FlightFrame> = new ReplaySubject<FlightFrame>(1);
  public deviceFlightLayers$: ReplaySubject<Layer[]> = new ReplaySubject<Layer[]>(1);
  public totalAssets: number = 0;
  public totalAtlasItems: number = 0;
  public totalAssetsLoaded: number = 0;
  public isCompareLayersOpen: boolean = false;
  public avoidLoadLocalStorageAssets: boolean = false;
  public hasToRemoveAssetsHiglight: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly selectedLayers$: Observable<AtlasAssetModel[]>;
  public readonly assets$: Observable<AtlasAssetModel[]>;
  public readonly hasDashboardLayer$: Observable<boolean>;
  public hasToSkipLoadAssets: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public availableColors = ['#11ACA4', '#383DC3', '#F57A12', '#D93577', '#7379F9', '#67DC5F'];
  public selectMarkers: Subject<AtlasAssetModel> = new Subject();
  public isMarkerMenuOpened: boolean = false;

  private hasToDetectChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public hasToDetectChanges$: Observable<boolean> = this.hasToDetectChanges.asObservable();
  public goToAtlasItemIndex$: Subject<number> = new Subject<number>();

  private isLayersControlSideBarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLayersControlSideBarOpen$: Observable<boolean> = this.isLayersControlSideBarOpen.asObservable();
  public hasToBlockEvents = false;
  public hasToShowInfoPopup = true;
  public hasToSkipFitBoundsOnLoadLayers = false;
  public hasToMoveToUploadedLayer = false;
  public hasToHideCursor = false;
  public totalGeojsonFeatures: number = 0;

  private _assets = new BehaviorSubject<AtlasAssetModel[]>([]);
  private _selectedLayers = new BehaviorSubject<AtlasAssetModel[]>([]);
  public currentPopupIndex: number = -1;
  public isPopupOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public finishedLoadGeojson: Subject<void> = new Subject<void>();
  public hasAllGeojsonAssetsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public totalGeojsonAssetsLoaded = 0;
  public totalGeojsonAssets = 0;

  get selectedLayers(): AtlasAssetModel[] {
    return this._selectedLayers.getValue();
  }
  get assets(): AtlasAssetModel[] {
    return this._assets.getValue();
  }

  fetchAssetById(id: string): Observable<AtlasAssetModel> {
    return this.atlasApiService.getById(id);
  }

  getAssetById(id: string): AtlasAssetModel {
    // todo PERF: switch from list to dict
    return this.dataStore.assets.find(a => a.id === id);
  }

  private dataStore = {
    assets: [],
    selectedLayers: []
  };

  constructor(
    private atlasLocalStorageService: AtlasLocalStorageService,
    private atlasApiService: AtlasApiService,
    private atlasStoreFacadeService: AtlasStoreFacadeService,
    private http: HttpClient,
    private markerClusterService: MarkerClusterService
  ) {
    this.assets$ = this._assets.asObservable().pipe(shareReplay(1));
    this.selectedLayers$ = this._selectedLayers.asObservable().pipe(shareReplay(1));
    this.hasDashboardLayer$ = this.assets$.pipe(map(assets => assets.some(asset => !!asset.dashboard)));
  }

  public setTotalGeojsonFeatures(totalFeatures: number) {
    this.totalGeojsonFeatures = totalFeatures;
  }

  public setCurrentPopupIndex(currentPopupIndex: number): void {
    this.currentPopupIndex = currentPopupIndex;
  }

  public setIsPopupOpened(isPopupOpened: boolean) {
    this.isPopupOpened.next(isPopupOpened);
  }

  public setIsMarkerMenuOpened(isMarkerMenuOpened: boolean): void {
    this.isMarkerMenuOpened = isMarkerMenuOpened;
  }

  public setHasToHideCursor(hasToHideCursor: boolean): void {
    this.hasToHideCursor = hasToHideCursor;
  }

  public setHasToBlockEvents(hasToBlockEvents: boolean): void {
    this.hasToBlockEvents = hasToBlockEvents;
  }

  public setHasToShowInfoPopup(hasToShowInfoPopup: boolean): void {
    this.hasToShowInfoPopup = hasToShowInfoPopup;
  }

  public setHasToSkipFitBoundsOnLoadLayers(hasToSkipFitBoundsOnLoadLayers: boolean) {
    this.hasToSkipFitBoundsOnLoadLayers = hasToSkipFitBoundsOnLoadLayers;
  }

  public setHasToMoveToUploadedLayer(hasToMoveToUploadedLayer: boolean) {
    this.hasToMoveToUploadedLayer = hasToMoveToUploadedLayer;
  }

  public sethasToSkipLoadAssets(hasToSkipLoadAssets: boolean): void {
    this.hasToSkipLoadAssets.next(hasToSkipLoadAssets);
  }

  public setHasToRemoveAssetsHiglight(hasToRemoveAssetsHiglight: boolean) {
    this.hasToRemoveAssetsHiglight.next(hasToRemoveAssetsHiglight);
  }

  public setAvoidLoadLocalStorageAssets(avoidLoadLocalStorageAssets: boolean) {
    this.avoidLoadLocalStorageAssets = avoidLoadLocalStorageAssets;
  }

  public setIsCompareLayersOpen(value: boolean): void {
    this.isCompareLayersOpen = value;
  }

  public changeLayerColor(asset: AtlasAssetModel, colorIndex: number) {
    const params = {
      color: this.availableColors[colorIndex]
    };
    this.updateAsset(asset, params, true).pipe(take(1)).subscribe();
    asset.color = this.availableColors[colorIndex];
    asset.customColorIndex = colorIndex;
    if (asset.type !== AssetType.GEOJSON) {
      return;
    }
    (asset.leafletLayer as any).eachLayer(layer => {
      //update geometries color
      if (layer.feature.geometry.type !== 'Point') {
        layer.setStyle({color: asset.color});
        return;
      }
      //update marker color
      this.updateMarkerColor(asset, layer);
    });
    //update asset cluster color
    this.markerClusterService.refresh();
  }

  public updateMarkerColor(asset, layer) {
    const updatedIcon = createMapIcon({
      size: [25, 41],
      color: asset.color || atlasConfig.defaultMarkerColor,
      className: layer.options.icon.options.className
    });
    layer.setIcon(updatedIcon);
    layer.update();
  }

  public createLocationIcon(layer) {
    const updatedIcon = icon({
      iconUrl: 'assets/icons/atlas/location.svg',
      iconSize: [26, 35],
      iconAnchor: [13, 35]
    });
    layer.setIcon(updatedIcon);
    layer.update();
  }

  public hasAllMarkersStatusAssigned(asset: AtlasAssetModel) {
    if (asset.type !== AssetType.GEOJSON) {
      return null;
    }
    const filteredFeatures = ((asset as AtlasGeojsonAssetModel).geojson as any).features.filter(
      feature => feature.geometry?.type === ASSET_GEOMETRY_TYPE.POINT
    );
    return (
      filteredFeatures.length > 0 &&
      filteredFeatures.every(feature => {
        return !!feature.properties.state || feature.state;
      })
    );
  }

  addSelectedLayer(asset: AtlasAssetModel): void {
    this.dataStore.selectedLayers.push(asset);
    this._selectedLayers.next([...new Set(this.dataStore.selectedLayers)]);
  }

  removeSelectedLayer(assetId: string) {
    if (this.dataStore.selectedLayers.length === 0) {
      return;
    }
    this.dataStore.selectedLayers = this.dataStore.selectedLayers.filter(sl => sl.id !== assetId);
    this._selectedLayers.next([...new Set(this.dataStore.selectedLayers)]);
  }

  clearSelectedLayers() {
    this.dataStore.assets.forEach(a => (a.isSelected = false));
    this.dataStore.selectedLayers = [];
    this._selectedLayers.next([]);
  }

  selectAllLayers() {
    this.dataStore.assets.forEach(a => (a.isSelected = true));
    this.dataStore.selectedLayers = this.dataStore.assets;
    this._selectedLayers.next([...new Set(this.dataStore.selectedLayers)]);
  }

  convertToShp(geojson: GeoJSON, name: string) {
    return this.atlasApiService.convertToShapeFile(geojson, name);
  }

  fetchAssets(): Observable<AtlasAssetModel[]> {
    return this.atlasApiService.listAtlasItems();
  }

  public getAssignAssetsPreview(libraryItemId: LibraryItem['id']): Observable<any> {
    return this.atlasApiService.getAssignAssetsPreview(libraryItemId);
  }

  public assignAssets({
    folderId,
    atlasAssetId,
    groupNameKey
  }: {
    folderId: string;
    atlasAssetId: string;
    groupNameKey: string;
  }): Observable<any> {
    return this.atlasApiService.assignAssets(folderId, atlasAssetId, groupNameKey);
  }

  public assignAssetsNoLayer({folderId, gimbalPitch}: {folderId: string; gimbalPitch: string}): Observable<any> {
    return this.atlasApiService.assignAssets(folderId, undefined, gimbalPitch);
  }

  public getAssets(getLatestDays?: number) {
    this.isLoading$.next(true);
    return this.atlasApiService.listAtlasItems().pipe(
      tap(async (assets: AtlasAssetModel[]) => {
        if (!!getLatestDays) {
          //temporary workaround for large orthophoto model datasets
          const onlyRecentAssets = assets.filter(asset => {
            if (asset.type !== AssetType.ORTHOPHOTOMAP) {
              // allow all non-orthophoto assets
              return true;
            }
            return asset.createdAt > Date.now() - getLatestDays * 24 * 60 * 60 * 1000;
          });
          assets = onlyRecentAssets;
        }
        this.totalAssetsCount = assets.length;
        this.totalGeojsonAssets = assets.filter(asset => asset.type === AssetType.GEOJSON).length;
        if (this.totalAssetsCount === 0) {
          this.setHasAllLayersLoaded(true);
        }
        console.info(`START LOADING ${this.totalAssetsCount} ASSETS`); //
        this.addAssets(assets);
        this.assetLoadEnd();
      })
    );
  }

  public getCreatedAssets() {
    return this.atlasApiService.listAtlasItems().pipe(
      map(assets => {
        const currentTimestamp = Date.now();
        // eslint-disable-next-line no-magic-numbers
        const fewSecondsAgo = currentTimestamp - 30 * 1000;
        return assets.filter(asset => asset.createdAt >= fewSecondsAgo);
      })
    );
  }

  public updateAsset(asset: AtlasAssetModel, payload: Partial<AtlasAssetModel>, avoidUpdateCache?: boolean) {
    return this.atlasApiService.update(asset.id, payload).pipe(
      tap(() => {
        if (avoidUpdateCache) {
          return;
        }
        this.updateCache([asset], payload);
      })
    );
  }

  public moveMarkers(assetIdFrom: string, assetIdTo: string, indexes: number[]) {
    return this.atlasApiService.moveMarkers(assetIdFrom, assetIdTo, indexes);
  }

  public copyMarkers(assetIdFrom: string, assetIdTo: string, indexes: number[]) {
    return this.atlasApiService.copyMarkers(assetIdFrom, assetIdTo, indexes);
  }

  public changeMarkerState(assetId: string, features: {featureId: string; state: string}[]) {
    return this.atlasApiService.changeMarkerState(assetId, features);
  }

  public createEmptyAsset(payload: {name: string; type: AssetType; key: string; groupName: string; jobId?: string}) {
    return this.atlasApiService.create(payload);
  }

  public startSelectingMarkers(layer: AtlasAssetModel) {
    this.selectMarkers.next(layer);
  }

  updateAssets(assets: AtlasAssetModel[], payload: Partial<AtlasAssetModel>) {
    const updateArr$ = assets.map(a => this.atlasApiService.update(a.id, payload));
    return zip(...updateArr$).pipe(
      tap(() => {
        this.updateCache(assets, payload);
        this.clearSelectedLayers();
      })
    );
  }

  public removeAsset({assetId, avoidUpdateCache}: {assetId: string; avoidUpdateCache: boolean}) {
    return this.atlasApiService.removeById(assetId).pipe(
      tap(() => {
        if (avoidUpdateCache) {
          return;
        }
        this.sethasToSkipLoadAssets(true);
        this.removeFromCache([assetId]);
        this.removeSelectedLayer(assetId);
        this.sethasToSkipLoadAssets(false);
      })
    );
  }

  public removeAssets(assets: AtlasAssetModel[]) {
    const delArr$ = assets.map(a => this.atlasApiService.removeById(a.id));
    return zip(...delArr$).pipe(
      tap(() => {
        this.sethasToSkipLoadAssets(true);
        this.removeFromCache(assets.map(asset => asset.id));
        this.clearSelectedLayers();
        this.sethasToSkipLoadAssets(false);
      })
    );
  }

  convertDMS(lat, lng) {
    function toDegreesMinutesAndSeconds(coordinate) {
      const absolute = Math.abs(coordinate);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

      return degrees + '°' + minutes + "'" + seconds + "''";
    }

    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = Math.sign(lat) >= 0 ? 'N' : 'S';

    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = Math.sign(lng) >= 0 ? 'E' : 'W';

    return latitude + latitudeCardinal + ' ' + longitude + longitudeCardinal;
  }

  public addAssets(assets) {
    if (!this.dataStore.assets.length) {
      this.dataStore.assets = assets;
    } else {
      // deduplicate existing items
      assets.forEach(asset => {
        // add if doesnt exist yet
        if (!this.dataStore.assets.find(i => i.id === asset.id)) {
          this.dataStore.assets.push(asset);
        }
      });
    }
    this._assets.next([...this.dataStore.assets]);
    this.totalAssets = this.dataStore.assets.length;
  }

  public addNewAssets(newAsset, currentAsset) {
    const allAssets = this.assets;
    if (!allAssets.find(i => i.id === newAsset.id)) {
      allAssets.push(newAsset);
    }
    const displayedAssets = allAssets.map(asset => ({
      ...asset,
      isDisplaying: asset.id === currentAsset.id
    }));
    this._assets.next(displayedAssets);
    this.dataStore.assets = displayedAssets;
    this.totalAssets = this.dataStore.assets.length;
  }

  public removeOldAsset(oldAsset) {
    const allAssets = this.assets;
    const oldAssetIndex = allAssets.findIndex(i => i.id === oldAsset.id);
    if (oldAssetIndex >= 0) {
      allAssets.splice(oldAssetIndex, 1);
    }
    this._assets.next(allAssets);
    this.dataStore.assets = allAssets;
    this.totalAssets = this.dataStore.assets.length;
  }

  public displayAssets() {
    this.dataStore.assets.forEach(asset => {
      asset.isDisplaying = true;
    });
    // Due perf issues we update the references instead of mapping the entire array
    // this._assets.next(this.dataStore.assets);
    this.toogleHasToDetectChanges();
  }

  assetLoadEnd() {
    this.isLoading$.next(false);
  }

  getAssetData<T>(key: string, responseType: 'json' | 'text' | 'arraybuffer'): Promise<T> {
    return this.http
      .get<T>(this.getCDNPrefixedUrl(key), {
        responseType: responseType as any
        // withCredentials: true
      })
      .pipe(
        tap(() => {
          ++this.loadedAssetsCount;
        })
      )
      .toPromise()
      .catch(err => {
        return this.handleError(key, err);
      });
  }

  handleError(key, error) {
    this.failedAssetsCount += 1;
    console.error('Failed to fetch asset: ' + key + ' error: ' + JSON.stringify(error));
    return Promise.reject(error);
  }

  loadInitialPositionFromLocalStorage(map: Map): void {
    const coordinates: {lat: number; lng: number} = JSON.parse(this.currentViewPosition);
    const mapCenter = latLng([coordinates.lat, coordinates.lng]);
    map.setView(mapCenter, this.currentZoom, {
      animate: false
    } as ZoomPanOptions);
    this.isloadingInitialPosition$.next(true);
  }

  loadPreviousPositionFromLocalStorage(map: Map): void {
    const mapPosition = this.atlasLocalStorageService.getItem('previousViewPosition');
    const mapZoom = +this.atlasLocalStorageService.getItem('previousZoom');
    const coordinates: {lat: number; lng: number} = JSON.parse(mapPosition);
    const mapCenter = latLng([coordinates.lat, coordinates.lng]);
    map.setView(mapCenter, mapZoom, {
      animate: false
    } as ZoomPanOptions);
  }

  public hasMapPropertiesStored(): boolean {
    this.currentViewPosition = this.atlasLocalStorageService.getItem('currentViewPosition');
    this.currentZoom = +this.atlasLocalStorageService.getItem('currentZoom');
    const mapView = this.atlasLocalStorageService.getItem('mapView');
    const isWeatherMapDisplayed = this.atlasLocalStorageService.getItem('isWeatherMapDisplayed');
    if (this.currentZoom && this.currentViewPosition && mapView && isWeatherMapDisplayed) {
      return true;
    }
    return false;
  }

  public getStoredMapview(): MAP_VIEW {
    return this.atlasLocalStorageService.getItem('mapView') as MAP_VIEW;
  }

  isWeatherMapDisplayed(): boolean {
    return this.atlasLocalStorageService.getItem('isWeatherMapDisplayed') === 'true';
  }

  public setFavouritePropertiesInStorage(
    centerPosition: LatLng,
    zoomValue: number,
    mapView: MAP_VIEW,
    isWeatherMapDisplayed?: boolean
  ) {
    const currentViewPosition = JSON.stringify(centerPosition);
    this.atlasLocalStorageService.setItem('currentViewPosition', currentViewPosition);
    this.atlasLocalStorageService.setItem('currentZoom', zoomValue + '');
    this.atlasLocalStorageService.setItem('mapView', mapView + '');
    this.atlasLocalStorageService.setItem('isWeatherMapDisplayed', isWeatherMapDisplayed + '');
  }

  setPreviousAtlasView(centerPosition: LatLng, zoomValue: number) {
    const currentViewPosition = JSON.stringify(centerPosition);
    this.atlasLocalStorageService.setItem('previousViewPosition', currentViewPosition);
    this.atlasLocalStorageService.setItem('previousZoom', zoomValue + '');
  }

  public setHasAllLayersLoaded(hasAllLayersLoaded: boolean) {
    this.hasAllLayersLoaded$.next(hasAllLayersLoaded);
  }

  public handleDetectAssetChanges() {
    this.totalAssetsLoaded++;
    this.isReadytoDetectAtlasChanges$.next(true);
    if (this.totalAssetsLoaded === this.totalAssets) {
      this.setHasAllLayersLoaded(true);
    }
  }

  public handleGeojsonAssetsLoaded() {
    this.totalGeojsonAssetsLoaded++;
    if (this.totalGeojsonAssetsLoaded === this.totalGeojsonAssets) {
      this.hasAllGeojsonAssetsLoaded.next(true);
    }
  }

  clearAtlasLocalStorage() {
    this.atlasLocalStorageService.removeItem('currentViewPosition');
    this.atlasLocalStorageService.removeItem('currentZoom');
    this.atlasLocalStorageService.removeItem('mapView');
    this.atlasLocalStorageService.removeItem('isWeatherMapDisplayed');
  }

  public deviceUpdated(map: Map): void {
    map.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        if ((layer.options as any)?.isDeviceMarker) {
          map.removeLayer(layer);
        }
      }
    });
  }

  public showOnlyThisLayer(layer: AtlasAssetModel): void {
    this.assets.forEach(asset => {
      asset.isDisplaying = layer.id === asset.id;
      asset.isHighlighted = false;
    });
  }

  public toogleHasToDetectChanges(): void {
    this.hasToDetectChanges.next(!this.hasToDetectChanges.value);
  }

  public setAsOpenLayersControlSideBar(): void {
    this.isLayersControlSideBarOpen.next(true);
  }

  public setAsCloseLayersControlSideBar(): void {
    this.isLayersControlSideBarOpen.next(false);
  }

  private removeExistentLayer(map, layers): void {
    if (map.hasLayer(layers.satelliteView)) {
      map.removeLayer(layers.satelliteView);
      return;
    }
    if (map.hasLayer(layers.roadView)) {
      map.removeLayer(layers.roadView);
      return;
    }
    if (map.hasLayer(layers.hybridView)) {
      map.removeLayer(layers.hybridView);
      return;
    }
  }

  public changeMapView(
    map: Map,
    mapView: MAP_VIEW,
    satelliteView: GridLayer,
    roadView: GridLayer,
    hybridView: GridLayer
  ) {
    if (!map) {
      return;
    }
    const mapViewMapping = {
      [MAP_VIEW.SATELLITE]: satelliteView,
      [MAP_VIEW.HYBRID]: hybridView,
      [MAP_VIEW.ROADMAP]: roadView
    };

    this.removeExistentLayer(map, {satelliteView, roadView, hybridView});
    map.addLayer(mapViewMapping[mapView]);
  }

  public convertAssetToMission(assetId: string, si: string) {
    return this.atlasApiService.convertAssetToMission(assetId, si);
  }

  public disableCluster(layer: AtlasAssetModel) {
    this.markerClusterService.disableClustering();
  }

  public enableCluster(currentAssetId: string) {
    this.markerClusterService.enableClustering();
  }

  public displayAsset(assetId: string, isDisplaying: boolean): void {
    const asset = this.getAssetById(assetId);
    asset.isDisplaying = isDisplaying;
    this.toogleHasToDetectChanges();
  }

  private getCDNPrefixedUrl(key) {
    return `${environment.atlasCDNDomain}/${key}`;
  }

  private removeFromCache(assetsToRemove: string[]) {
    function excludeAssetsToRemove(a) {
      return !assetsToRemove.find(sl => sl === a.id);
    }
    this.dataStore.assets = this.dataStore.assets.filter(excludeAssetsToRemove);
    this._assets.next([...this.dataStore.assets]);
  }

  public updateCache(assets: AtlasAssetModel[], payload: Partial<AtlasAssetModel>) {
    const cachedAssets = this.dataStore.assets;
    const findAndUpdateCachedAsset = asset => {
      const index = cachedAssets.findIndex(a => asset.id === a.id);
      if (index === -1) {
        // asset not found in cache
        return;
      }
      //update cache
      cachedAssets[index] = {...asset, ...payload};
      if (payload.name) {
        this.updatePopup(cachedAssets[index].leafletLayer._layers, payload.name);
      }
    };
    assets.forEach(findAndUpdateCachedAsset);

    this._assets.next([...this.dataStore.assets]);
  }

  private updatePopup(layers: Layer[], name: string): void {
    Object.values(layers).forEach((layer: any) => {
      layer.on('mouseover', () => {
        const modifiedString = this.replacePopup(layer.getPopup()._content, name);
        layer.setPopupContent(modifiedString);
      });
      layer.on('click', () => {
        const modifiedString = this.replacePopup(layer.getPopup()._content, name);
        layer.setPopupContent(modifiedString);
      });
    });
  }

  private replacePopup(content: string, name: string): string {
    const regex = /<span\s+class="property__header-text">(.*?)<\/span>/;
    return content.replace(regex, `<span class="property__header-text">${name}</span>`);
  }

  public destroy() {
    this.markerClusterService.destroy();
  }

  public toggleMap(map: Map, layers: any, mapView) {
    const mapViewMapping = {
      [MAP_VIEW.SATELLITE]: {
        layer: layers.satelliteView,
        nextView: {type: MAP_VIEW.HYBRID, layer: layers.hybridView}
      },
      [MAP_VIEW.HYBRID]: {layer: layers.hybridView, nextView: {type: MAP_VIEW.ROADMAP, layer: layers.roadView}},
      [MAP_VIEW.ROADMAP]: {layer: layers.roadView, nextView: {type: MAP_VIEW.SATELLITE, layer: layers.satelliteView}}
    };

    const currentView = mapViewMapping[mapView];
    map.removeLayer(currentView.layer);
    map.addLayer(currentView.nextView.layer);
    return currentView.nextView.type;
  }
}

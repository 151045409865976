import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UPLOAD_LOGO_SOURCE} from '@app/profile/models/upload-logo-source.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {AddonStoreFacadeService} from '../../../core/services/addon-store-facade.service';
import {DeviceService} from '../../../core/services/api/device.service';
import {UserService} from '../../../core/services/api/user.service';
import {AsyncPipe, NgIf, UpperCasePipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FlexLayoutModule} from '@angular/flex-layout';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FlexLayoutModule, MatProgressBarModule, MatButtonModule, AsyncPipe, TranslateModule, UpperCasePipe]
})
export class LogoUploadComponent implements OnInit {
  @Input() public set url(value: string) {
    this.url$.next(!value ? environment.NO_IMAGE_PATH : value);
  }
  @Input() public inputBucket: UPLOAD_LOGO_SOURCE;
  @Input() public parentId: string; // companyId, deviceId, addonId...
  @Input() public subfolder: string; // company, device, addon...
  @Input() public isShowingButton = true;
  @Input() public manualUpload = false;
  @Output() public s3Url: EventEmitter<string> = new EventEmitter<string>();
  @Output() public isUploadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isUploading: boolean = false;
  public disabled: boolean = false;
  public inputId: number;
  public ACCEPT_ASSET_FILES = ['.jpg', '.png', '.jpeg', '.gif'];

  // eslint-disable-next-line rxjs/finnish
  public url$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private PREFIX: string = 'ualogo_'; // used by CDN to route to bucket
  private userIdentityId: string;
  private userSub: Subscription;
  private companyId: string;
  private translateSub: Subscription;
  private currentFile: File;

  constructor(
    public userService: UserService,
    private deviceService: DeviceService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    public snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.inputId = new Date().getTime();
  }

  public ngOnInit(): void {
    // disable adding logo if there is no parent
    // this.disabled = !this.parentId;
    this.userSub = this.userService.user$.subscribe(user => {
      this.userIdentityId = user.id;
    });

    this.userSub = this.userService.myCompany$.subscribe(company => {
      this.companyId = company.id;
    });
  }

  private removeSpecialCharacters(file: string): string {
    const fileName = file.slice(0, file.lastIndexOf('.'));
    const fileType = file.slice(file.lastIndexOf('.'));
    return fileName.replace(/[^a-zA-Z0-9]/g, '') + fileType;
  }

  public uploadLogo(event): void {
    const file = event.target.files[0];
    Object.defineProperty(file, 'name', {
      value: this.removeSpecialCharacters(file.name)
    });

    const isValid = this.ACCEPT_ASSET_FILES.some(acceptedFile => file.type.split('/')[1] === acceptedFile.slice(1));
    if (file && isValid) {
      this.addPreviewImage(file);
      this.currentFile = file;

      if (this.manualUpload) {
        return;
      }

      this.startFileUpload(file);
    }
    this.translateSub = this.translateService.get('common.allowedFiles').subscribe(res => {
      this.snackBar.open(res, null, {
        duration: 3000,
        horizontalPosition: 'center'
      });
    });
  }

  public async startFileUpload(file?: File, deviceId?: string) {
    const currentFile = file || this.currentFile;
    const instanceId = deviceId || this.parentId;
    if (!currentFile) {
      return;
    }

    let url;

    this.isUploadingEmitter.emit(true);
    this.isUploading = true;
    switch (this.inputBucket) {
      case UPLOAD_LOGO_SOURCE.ACCOUNT:
        url = await firstValueFrom(this.userService.uploadCompanyLogo(currentFile, this.companyId));
        this.finishUpload(url);
        break;
      case UPLOAD_LOGO_SOURCE.DEVICE:
        url = await firstValueFrom(this.deviceService.uploadImage(currentFile, instanceId));
        this.finishUpload(url);
        break;
      case UPLOAD_LOGO_SOURCE.ADDON:
        this.addonStoreFacadeService.uploadImage(currentFile, instanceId, this.s3Url);
        url = this.s3Url;
        break;
    }
    return url;
  }

  private finishUpload(url: string): void {
    this.s3Url.emit(url);
    this.isUploadingEmitter.emit(false);
    this.isUploading = false;
  }

  private getKey(fileName: string): string {
    if (!this.parentId) {
      return `${this.userIdentityId}/${this.subfolder}/${this.PREFIX}${fileName}`;
    }
    return `${this.userIdentityId}/${this.subfolder}/${this.PREFIX}${this.parentId}_${fileName}`;
  }

  // optimistic UI - display image even though its still uploading
  private addPreviewImage(file: File): void {
    const reader = new FileReader();
    reader.onload = e => {
      this.url = (e.target as any).result;
    };
    reader.readAsDataURL(file);
  }
}

import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TeamRole, TeamTable} from '@app/profile/models/team.model';

@Component({
  selector: 'unleash-add-user-to-team-row',
  templateUrl: './add-user-to-team-row.component.html',
  styleUrls: ['./add-user-to-team-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default // to update form state from parent component
})
export class AddUserToTeamRowComponent {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.required),
    teamId: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl('', Validators.required)
  });
  @Input() public teams: TeamTable[] = [];
  @Input() public roles: TeamRole[] = [];
}

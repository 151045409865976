<h1 mat-dialog-title>{{ 'library.dialogs.s3Import.title' | translate }}</h1>
<div mat-dialog-content>
  <div [innerHTML]="'library.dialogs.s3Import.restrictions' | translate"></div>

  <form [formGroup]="s3ImportForm">
    <mat-form-field class="mat-form-field-form" fxFlex="100%" fxLayout>
      <input
        type="text"
        matInput
        formControlName="s3Uri"
        [placeholder]="'library.dialogs.s3Import.placeholder' | translate"
        autocomplete="off"
      />
      <mat-error *ngIf="s3ImportForm.get('s3Uri').touched && s3ImportForm.get('s3Uri').hasError('required')">
        {{ 'library.dialogs.s3Import.errorRequired' | translate }}
      </mat-error>
      <mat-error *ngIf="s3ImportForm.get('s3Uri').touched && s3ImportForm.get('s3Uri').hasError('pattern')">
        {{ 'library.dialogs.s3Import.errorPattern' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-icon fxLayout class="gray" [matTooltip]="'library.dialogs.s3Import.tooltip' | translate"> info </mat-icon>
  </form>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button type="button" mat-dialog-close color="primary" tabindex="0">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    mat-button
    type="submit"
    color="primary"
    tabindex="1"
    (click)="importFromS3()"
    [analyticsTrackerType]="events.GDRIVE_IMPORT_STARTED"
    [analyticsIf]="s3ImportForm.valid"
  >
    {{ 'library.dialogs.s3Import.import' | translate }}
  </button>
</div>

import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {SortType} from '../models/paginator.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private apiGateway: ApiGateway) {}

  // TODO: Implement listPart GET request
  public listPartReport(params: {pageIndex?: number; pageSize?: number; sort?: SortType}) {
    return this.apiGateway.get(`report/list`, params);
  }

  public deleteAnalysisReports(sessionId: string) {
    return this.apiGateway.delete(`report/${encodeURIComponent(sessionId)}`, {});
  }
}

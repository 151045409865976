import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {CardOptionComponent} from './card-option/card-option.component';
import {MiniCardOptionComponent} from './mini-card-option/mini-card-option.component';
import {RadioCardComponent} from './radio-card/radio-card.component';
import {SocialButtonComponent} from './social-button/social-button.component';
import {UnleashAlertComponent} from './unleash-alert/unleash-alert.component';
import {UnleashCheckboxComponent} from './unleash-checkbox/unleash-checkbox.component';
import {UnleashDividerComponent} from './unleash-divider/unleash-divider.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule
  ],
  declarations: [
    UnleashCheckboxComponent,
    UnleashDividerComponent,
    SocialButtonComponent,
    UnleashAlertComponent,
    CardOptionComponent,
    RadioCardComponent,
    MiniCardOptionComponent,
    ConfirmDialogComponent
  ],
  exports: [
    UnleashCheckboxComponent,
    UnleashDividerComponent,
    SocialButtonComponent,
    UnleashAlertComponent,
    CardOptionComponent,
    RadioCardComponent,
    MiniCardOptionComponent
  ]
})
export class UnleashMaterialSharedModule {}

import {createSelector} from '@ngrx/store';
import {selectRouterNavigatedAction, selectRouterRedirectState} from '../app.state';
import {RouterRedirectState, RouterState} from './router.reducer';

export const selectRouterState = (state): RouterState['state'] => {
  return state.router;
};

export const selectRouterRedirect = createSelector(selectRouterRedirectState, (state: RouterRedirectState) => state);

export const selectRouterParamsData = createSelector(selectRouterState, (state: RouterState['state']) => {
  const queryParams: {[key: string]: string | string[]} = {...state.queryParams};

  if (typeof state?.queryParams?.tags === 'string') {
    queryParams.tags = [].concat(queryParams.tags);
  }

  return {
    params: state.params,
    data: state.data,
    queryParams: queryParams
  };
});

export const selectPreviousUrl = createSelector(selectRouterNavigatedAction, (state: RouterState) => state.previousUrl);

export const selectUrl = createSelector(selectRouterState, (state: RouterState['state']) => state.url);

<unleash-loader
  *ngIf="isSwitchingTeam$ | async"
  showLogo="true"
  text="{{ 'core.header.switchingTeams' | translate }}"
></unleash-loader>
<div class="app-wrapper">
  <header class="app-header" *ngIf="mobileHeaderLabel | async as mobileHeaderLabel">
    <app-header-navbar [mobileHeaderLabel]="mobileHeaderLabel" (openMobileMenu)="openMobileMenu()"></app-header-navbar>
  </header>
  <div class="app-body">
    <nav class="app-sidebar">
      <app-sidebar></app-sidebar>
    </nav>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav-content class="sidenav-content">
        <main class="app-content">
          <app-banner></app-banner>
          <router-outlet></router-outlet>

          <mat-card
            class="notification-alert"
            [ngStyle]="!(layoutCheckerService.isShowHeaderNav$ | async) ? {top: 0} : {}"
            *ngIf="notification$ | async as notification"
          >
            <app-notification-card
              [notification]="notification"
              [layout]="notificationLayout.grow"
              [hasCloseButton]="true"
              (close)="hideNotification()"
            ></app-notification-card>
          </mat-card>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <footer
    class="app-footer"
    [ngClass]="(layoutCheckerService.isShowFooterNav$ | async) ? 'app-footer__show' : 'app-footer__hide'"
  >
    <app-footer-navbar [hasMobileHeaderLabel]="!!(mobileHeaderLabel | async)"></app-footer-navbar>
  </footer>
</div>

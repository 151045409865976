import {Pipe, PipeTransform} from '@angular/core';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {Observable, map, take} from 'rxjs';

@Pipe({name: 'uModelDescription', standalone: true})
export class ModelDescriptionPipe implements PipeTransform {
  constructor(private addonStoreFacadeService: AddonStoreFacadeService) {}

  public transform(addonId: string): Observable<string> {
    return this.addonStoreFacadeService.publishedAddonsObject$.pipe(
      take(1),
      map(addons => {
        if (addonId === 'RAW_STREAM') {
          return 'Watch your raw live stream without any A.I. analysis.';
        }

        addons[addonId]?.description || '';
      })
    );
  }
}

import {Addon} from '@app/store/addon/models/addon';
import {AddonStateEnum} from '@app/store/addon/enums/addon-state.enum';

import {createFeatureSelector, createReducer, on} from '@ngrx/store';
import * as actions from './addon.actions';
import {AddonTypeEnum} from '@app/store/addon/enums/addon-type.enum';
import {AddonListParams} from '@app/live/models/addon-list.params';

export interface State {
  addons: {[key: string]: Addon};
  myInDevelopmentAddons: string[];
  publishedAddons: {[key: string]: Addon};
  isLoading: boolean;
  storeFilter: AddonListParams;
}

export const initialState: State = {
  addons: {},
  myInDevelopmentAddons: [],
  publishedAddons: {},
  isLoading: false,
  storeFilter: {
    categories: [],
    types: [AddonTypeEnum.STORE, AddonTypeEnum.STREAM, null]
  }
};

export const selectAddonFeatureKey = 'addon';
export const selectAddonStore = createFeatureSelector(selectAddonFeatureKey);

export const reducer = createReducer(
  initialState,
  on(actions.loadListSuccess, (state: State, action: {payload: {addons: Addon[]}}) => {
    const addons = action.payload?.addons?.reduce((acc: {[key: string]: Addon}, addon: Addon) => {
      return {...acc, [addon.id]: addon};
    }, {});
    return {...state, addons: {...state.addons, ...addons}, isLoading: false};
  }),
  on(actions.loadAddonSuccess, (state: State, action: {payload: {addon: Addon}}) => {
    const addon = state.myInDevelopmentAddons.find(addonId => addonId === action.payload.addon.id);
    const myInDevelopmentAddons = [...state.myInDevelopmentAddons];
    if (!addon) {
      myInDevelopmentAddons.push(action.payload.addon.id);
    }

    return {
      ...state,
      publishedAddons: {
        ...state.publishedAddons,
        [action.payload.addon.id]: {...action.payload.addon, enabled: !!state.addons[action.payload.addon.id]}
      },
      myInDevelopmentAddons
    };
  }),
  on(actions.loadMyInDevelopmentAddonsSuccess, (state: State, action: {payload: {addons: Addon[]}}) => {
    const addons = action.payload.addons.reduce((acc: {[key: string]: Addon}, addon: Addon) => {
      return {...acc, [addon.id]: addon};
    }, {});
    const myInDevelopmentAddons = action.payload.addons.map(addon => addon.id);

    return {...state, addons: {...state.addons, ...addons}, myInDevelopmentAddons};
  }),
  on(actions.loadPublishedAddonsSuccess, (state: State, action: {payload: {addons: Addon[]}}) => {
    const publishedAddons = action.payload.addons.reduce((acc: {[key: string]: Addon}, addon: Addon) => {
      return {...acc, [addon.id]: {...addon, enabled: !!state.addons[addon.id]}};
    }, {});

    return {...state, addons: {...state.addons}, publishedAddons};
  }),
  on(actions.renameAiAppSuccess, (state: State, action: {payload: {addon: Addon}}) => {
    const addonId = action.payload.addon.id;
    return {...state, addons: {...state.addons, [addonId]: {...state.addons[addonId], ...action.payload.addon}}};
  }),
  on(
    actions.deleteAiAppSuccess,
    (
      state: State,
      action: {
        payload: {
          addon: {
            id: Pick<Addon, 'id'>;
          };
        };
      }
    ) => {
      const addons = {...state.addons};
      delete addons[action.payload.addon.id as unknown as string];

      const myInDevelopmentAddons = state.myInDevelopmentAddons.filter(
        addonId => addonId !== (action.payload.addon.id as unknown as string)
      );

      return {...state, myInDevelopmentAddons, addons};
    }
  ),
  on(actions.createAiAppSuccess, (state: State, action: {payload: {addon: Addon}}) => {
    return {
      ...state,
      addons: {...state.addons, [action.payload.addon.id]: {...action.payload.addon, screenshots: []}},
      myInDevelopmentAddons: [...state.myInDevelopmentAddons, action.payload.addon.id]
    };
  }),
  on(actions.startLoading, (state: State) => ({...state, isLoading: true})),
  on(actions.stopLoading, (state: State) => ({...state, isLoading: false})),
  on(actions.unPublishAiAppSuccess, (state: State, action: {payload: {id: string}}) => {
    return {
      ...state,
      addons: {...state.addons, [action.payload.id]: {...state.addons[action.payload.id], state: AddonStateEnum.draft}}
    };
  }),
  on(actions.submitForReviewSuccess, (state: State, actions: {payload: {addonId: Addon['id']}}) => {
    const addonId = actions.payload.addonId;
    return {
      ...state,
      addons: {...state.addons, [addonId]: {...state.addons[addonId], state: AddonStateEnum.inReview}}
    };
  }),
  on(actions.cancelReviewSuccess, (state: State, action: {payload: {addonId: Addon['id']}}) => {
    const addonId = action.payload.addonId;
    return {...state, addons: {...state.addons, [addonId]: {...state.addons[addonId], state: AddonStateEnum.draft}}};
  }),
  on(actions.updateAddonSuccess, (state: State, action: {payload: {addon: Addon}}) => {
    const addon = state.addons[action.payload.addon.id] || state.publishedAddons[action.payload.addon.id];

    return {
      ...state,
      addons: {
        ...state.addons,
        [action.payload.addon.id]: {...addon, ...action.payload.addon}
      },
      publishedAddons: {
        ...state.publishedAddons,
        [action.payload.addon.id]: {...addon, ...action.payload.addon}
      }
    };
  }),
  on(actions.clearAddonStore, (state: State) => ({
    ...state,
    addons: {},
    isLoading: false,
    myInDevelopmentAddons: [],
    publishedAddons: {}
  })),
  on(actions.saveAppStoreFilter, (state: State, action: {payload: {storeFilter: AddonListParams}}) => ({
    ...state,
    storeFilter: {...state.storeFilter, ...action.payload.storeFilter}
  })),
  on(actions.clearStoreFilters, (state: State) => ({
    ...state,
    storeFilter: {
      categories: [],
      types: [AddonTypeEnum.STORE, AddonTypeEnum.STREAM, null]
    }
  })),
  on(actions.updateAddonCache, (state: State, {payload}: {payload: {addon: Pick<Addon, 'id' | 'enabled'>}}) => {
    if (payload.addon.enabled) {
      const addonToCopy = state.publishedAddons[payload.addon.id];
      return {
        ...state,
        addons: {...state.addons, [payload.addon.id]: {...addonToCopy, enabled: payload.addon.enabled}},
        publishedAddons: {
          ...state.publishedAddons,
          [payload.addon.id]: {...state.publishedAddons[payload.addon.id], ...payload.addon}
        }
      };
    }

    const newAddons = {...state.addons};
    delete newAddons[payload.addon.id];

    return {
      ...state,
      addons: newAddons,
      publishedAddons: {
        ...state.publishedAddons,
        [payload.addon.id]: {...state.publishedAddons[payload.addon.id], ...payload.addon}
      }
    };
  })
);

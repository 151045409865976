<div class="leaflet-top leaflet-right stream-container" *ngIf="streamingGpsDevices$ | async as streamingGpsDevices">
  <div *ngIf="atlasService.activeStreamingDevice$ | async as activeStreamingDevice">
    <div *ngIf="activeStreamingDevice.player === streamPlayer.WEBRTC" class="leaflet-webrtc-container">
      <ng-container *ngIf="agoraToken$ | async as agoraToken">
        <unleash-webrtc-player
          *ngIf="!activeStreamingDevice.selectedModel || activeStreamingDevice?.selectedModel?.id === rawStream.id"
          [autoStart]="true"
          [deviceId]="activeStreamingDevice.id"
          [agoraToken]="agoraToken"
          [agoraTokenRefreshCallback]="webrtcService.getWebRTCSubscriberToken"
          [streamAppId]="streamAppId"
          [isShowingControls]="true"
          [isLiveStreaming]="true"
          [hasToListenVideoStats]="true"
          (videoStats)="videoStats($event)"
          class="unleash-webrtc-player"
          #webrtcComponent
        ></unleash-webrtc-player>
      </ng-container>
    </div>
    <app-live-video-player
      *ngIf="
        !activeStreamingDevice.player ||
        activeStreamingDevice.player === streamPlayer.DEFAULT ||
        (activeStreamingDevice.player === streamPlayer.WEBRTC &&
          activeStreamingDevice?.selectedModel &&
          activeStreamingDevice?.selectedModel?.id != rawStream.id)
      "
      [device]="activeStreamingDevice"
      [model]="activeStreamingDevice.selectedModel || rawStream"
    >
    </app-live-video-player>
    <div class="stream-container--details">
      <div class="stream-container--details__row">
        <strong>{{ activeStreamingDevice.name }}</strong>
        <button mat-icon-button [matMenuTriggerFor]="moreOptionsMenu">
          <mat-icon class="action-icon action-icon--dark" inline="true">more_vert</mat-icon>
        </button>
        <mat-menu #moreOptionsMenu="matMenu">
          <button mat-menu-item (click)="emitShowLayer.emit(activeStreamingDevice)">
            <span class="stream-container__button-text">
              <mat-icon inline="true" class="stream-container__icon">gps_fixed</mat-icon>
              {{ 'atlas.control.viewLocation' | translate }}
            </span>
          </button>
          <app-device-context-menu
            [device]="activeStreamingDevice"
            (toggleStream)="toggleStream({id: $event})"
            (deviceUpdated)="emitDeviceUpdated()"
          ></app-device-context-menu>
        </mat-menu>
      </div>
      <div
        *ngIf="activeWebrtcPlayerStats && activeWebrtcPlayerStats.receiveResolutionWidth"
        class="stream-container--details__row"
      >
        {{ activeWebrtcPlayerStats.receiveResolutionWidth }}x{{ activeWebrtcPlayerStats.receiveResolutionHeight }},
        {{ activeWebrtcPlayerStats.receiveFrameRate }}fps,
        {{ activeWebrtcPlayerStats.receiveBitrate / 1024 / 1024 | number: '1.0-1' }}Mbps
      </div>
      <div
        *ngIf="activeStreamingDevice.player === streamPlayer.WEBRTC && ioTSensorsData"
        class="stream-container--details__row"
      >
        H {{ ioTSensorsData.alt }}m, HS {{ horizontalSpeed | number: '1.0-1' }}m/s, VS {{ ioTSensorsData.velZ * -1 }}m/s
      </div>
      <div
        *ngIf="streamingGpsDevices.length > 1"
        class="stream-container--details__row"
        style="justify-content: center"
      >
        <button
          mat-icon-button
          color="primary"
          (click)="prevStreamingDevice(streamingGpsDevices)"
          [disabled]="activeStreamingDeviceIndex === 0"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        {{ activeStreamingDeviceIndex + 1 }} of {{ streamingGpsDevices.length }}
        <button
          mat-icon-button
          color="primary"
          (click)="nextStreamingDevice(streamingGpsDevices)"
          [disabled]="activeStreamingDeviceIndex === streamingGpsDevices.length - 1"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

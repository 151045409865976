export const REASON_OPTIONS = [
  {
    title: 'Live Streaming',
    description: 'Broadcast live video and easily collaborate',
    imageSrc: '/assets/icons/onboarding/live-streaming.svg',
    value: 'live-stream'
  },
  {
    title: 'A.I. Analytics',
    description: 'Extract insights and actionable data with A.I.',
    imageSrc: '/assets/icons/onboarding/ai-insights.svg',
    value: 'ai-insights'
  },
  {
    title: 'Modeling & GIS',
    description: 'Gain clarity with geospatial intelligence and modeling',
    imageSrc: '/assets/icons/onboarding/mapping-modeling.svg',
    value: 'mapping'
  }
];

export interface ReasonOption {
  title: string;
  description: string;
  imageSrc: string;
  value: string;
}

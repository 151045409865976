import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  Mission,
  MissionRoutePoint,
  MissionSettingsService,
  SELECTED_WAYPOINT
} from '@app/atlas/services/mission-settings.service';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';
import {Observable, Subscription, map, tap} from 'rxjs';
import {MissionType, MissionTypeDisplay} from '@app/atlas/model/mission.model';
import {ConnectedPosition} from '@angular/cdk/overlay';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AclPermissions} from '@app/core/models/api/acl.model';
import { MissionExportType } from '@app/atlas/model/mission-geojson.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-mission-control',
  templateUrl: './app-mission-control.component.html',
  styleUrls: ['./app-mission-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMissionControlComponent implements AfterViewInit, OnDestroy {
  @Input() public isMissionDrawerClosed: boolean = true;
  @Input() public selectedWaypointIndex: number = -1;
  @Input('selectedMission') public set setSelectedMission(selectedMission: Mission) {
    this.selectedMission = selectedMission;
    if (selectedMission) {
      this.missionSettingsService.setEditingMission({mission: selectedMission});
    }
  }
  public selectedMission: Mission = null;
  @Output() public selectedWaypoint: EventEmitter<{
    waypoint: MissionRoutePoint;
    index: number;
    isLastIndex: boolean;
  }> = new EventEmitter();
  @Output() public missionViewerEnabled: EventEmitter<Mission> = new EventEmitter();
  @Output() public openMissionViewer: EventEmitter<void> = new EventEmitter();
  @Output() public newWaypoint: EventEmitter<{waypoint: MissionRoutePoint; distance: number; time: number}> =
    new EventEmitter();
  @Output() public missionCopiedToClipboard: EventEmitter<void> = new EventEmitter();
  @Output() public emitOpenPreviewIn3D: EventEmitter<void> = new EventEmitter();

  public completeMissionsGroupedByOwner$: Observable<Mission[]> =
    this.missionManagerService.completeMissionsGroupedByOwner;
  public readyMissionsGroupedByOwner$: Observable<Mission[]> =
    this.missionManagerService.readyMissionsGroupedByOwner.pipe(
      map(missions => {
        return missions.sort((p1, p2) => (p1.updatedAt < p2.updatedAt ? 1 : p1.updatedAt > p2.updatedAt ? -1 : 0));
      })
    );
  public isLoading$: Observable<boolean> = this.missionManagerService.isLoading;
  public hasMissions$: Observable<boolean> = this.missionManagerService.hasMissions;
  public missionType: typeof MissionType = MissionType;
  public tooltipPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: -8
    }
  ];
  public defaultSettings$: Observable<MissionRoutePoint> = this.missionSettingsService.settings$;
  public isNewRoute$: Observable<boolean> = this.missionSettingsService.isNewRoute;
  public isEditing$: Observable<boolean> = this.missionSettingsService.isEditing.pipe(
    tap(() => {
      setTimeout(() => {
        this.cd.detectChanges();
      });
    })
  );
  public missionTypeDisplay: typeof MissionTypeDisplay = MissionTypeDisplay;
  public aclPermissions = AclPermissions;

  private editingMissionSubscription: Subscription;
  private newVertexDrawedSubscription: Subscription;

  constructor(
    private missionManagerService: MissionManagerService,
    private missionSettingsService: MissionSettingsService,
    private cd: ChangeDetectorRef
  ) {
    this.newVertexDrawedSubscription = this.missionSettingsService.newVertexDrawed.subscribe(newVertex => {
      if (newVertex) {
        this.newWaypoint.emit(newVertex);
      }
    });
  }

  public ngOnDestroy(): void {
    this.missionSettingsService.setEditingMission({
      mission: null,
      selectedWaypointIndex: SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED
    });
  }

  public ngAfterViewInit(): void {
    this.editingMissionSubscription = this.missionSettingsService.editingMission.subscribe(editingMission => {
      //used to detect changes on new vertex creation in edit mode
      this.cd.detectChanges();

      if (editingMission?.mission) {
        this.missionViewerEnabled.emit(editingMission.mission);
      }

      if (editingMission?.selectedWaypointIndex >= 0) {
        const route = editingMission.mission.route;
        const index = editingMission.selectedWaypointIndex;
        const waypoint = route[index];
        if (!waypoint) {
          this.emitSelectedWaypoint(null);
          return;
        }
        this.emitSelectedWaypoint({
          waypoint,
          index,
          isLastIndex: false
        });
      }
      if (editingMission?.selectedWaypointIndex === -1) {
        this.emitSelectedWaypoint(null);
      }
    });
  }

  public viewLocation(mission: Mission) {
    this.missionManagerService.viewLocation(mission);
  }

  public openPreviewIn3D(mission: Mission) {
    this.missionManagerService.openPreviewIn3D(mission);
  }

  public viewMission(mission: Mission) {
    this.missionViewerEnabled.emit(mission);
    this.missionManagerService.viewMission(mission);
  }

  public emitSelectedWaypoint(selectedWaypoint: {waypoint: MissionRoutePoint; index: number; isLastIndex: boolean}) {
    this.selectedWaypoint.emit(selectedWaypoint);
  }

  public updateMission(mission: Mission) {
    this.missionViewerEnabled.emit(mission);
    this.missionSettingsService.setEditingMission({mission});
  }

  public showCopyToClipboard(): void {
    this.missionCopiedToClipboard.emit();
  }

  protected readonly MissionExportType = MissionExportType;
}

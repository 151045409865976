import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {Mission, MissionSettingsService} from '../services/mission-settings.service';
import {filter, of, switchMap, take, tap, zip} from 'rxjs';
import {MissionApiService} from '../services/mission-api.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Directive({
  selector: '[ulSmartInspect]'
})
export class SmartInspectDirective {
  @Output() public smartInspectSaved: EventEmitter<void> = new EventEmitter();
  @Output() public smartInspectSaving: EventEmitter<void> = new EventEmitter();
  @HostListener('change', ['$event'])
  public setSmartInspect(event) {
    this.missionSettingsService.editingMission
      .pipe(
        take(1),
        filter(editedMission => !!editedMission.mission && !!editedMission.mission.id),
        switchMap(editedMission =>
          zip(of(editedMission), this.translateService.get('atlas.mission.changesSaved').pipe(take(1)))
        ),
        switchMap(([editedMission, translation]) => {
          this.missionSettingsService.setIsUpdatingMission(true);
          this.smartInspectSaving.next();
          const mission = editedMission.mission;

          return this.missionApiService
            .updateMission(mission.id, {
              isSmartInspect: event.checked
            } as Partial<Mission>)
            .pipe(
              take(1),
              tap(updatedMission => {
                //after close edit it will return to the first undo stack value
                this.missionSettingsService.undoStack.value[0] = {
                  ...this.missionSettingsService.undoStack.value[0],
                  ...updatedMission
                };
                this.snackBar.open(translation, null, {panelClass: 'mission-uploaded', duration: 3000});
                this.missionSettingsService.setIsUpdatingMission(false);
                this.smartInspectSaved.emit();
              })
            );
        })
      )
      .subscribe();
  }

  constructor(
    private missionSettingsService: MissionSettingsService,
    private missionApiService: MissionApiService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}
}

<form [formGroup]="form" (submit)="clickNewPasswordButton()">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.email' | translate }}</mat-label>
    <input matInput formControlName="email" data-test-id="emailInput" />
    <mat-error *ngIf="(form.controls.email.invalid && form.controls.email.touched) || form.controls.email.dirty"
      >{{ 'common.email' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'auth.newPassword.existingPassword' | translate }}</mat-label>
    <input matInput formControlName="password" type="password" data-test-id="passwordInput" />
    <mat-error
      *ngIf="(form.controls.password.invalid && form.controls.password.touched) || form.controls.password.dirty"
      >{{ 'auth.newPassword.existingPassword' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'auth.forgot.newPassword' | translate }}</mat-label>
    <input matInput formControlName="newPassword" type="password" data-test-id="newPasswordInput" />
    <mat-error
      *ngIf="
        (form.controls.newPassword.invalid && form.controls.newPassword.touched) || form.controls.newPassword.dirty
      "
      >{{ 'auth.forgot.passwordPlaceholder' | translate }}</mat-error
    >
  </mat-form-field>
  <button
    class="form__item submit-button"
    mat-raised-button
    color="primary"
    [appLadda]="isInProgress"
    [disabled]="isInProgress"
    data-test-id="submitButton"
  >
    {{ 'common.submit' | translate }}
  </button>
</form>

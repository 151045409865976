import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlightText',
  standalone: true
})
export class HighlightTextPipe implements PipeTransform {
  public transform(value: string, searchText: string): string {
    if (!searchText) return value;
    const regex = new RegExp(searchText, 'gi');
    const highlightedText = value.toString().replace(regex, match => `<span class="custom-highlight">${match}</span>`);
    return highlightedText;
  }
}

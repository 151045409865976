import {selectAddonStore, State} from '@app/core/store/addon/addon.reducer';
import {createSelector} from '@ngrx/store';

export const selectAddonNamesById = createSelector(selectAddonStore, (state: State) => {
  const addonNamesById = {};

  const addonsKeys = Object.keys(state.addons);
  for (let index = 0; index < addonsKeys.length; index++) {
    const addonId = addonsKeys[index];
    addonNamesById[addonId] = state.addons[addonId].name;
  }

  return addonNamesById;
});
export const selectAddons = createSelector(selectAddonStore, (state: State) => state.addons);
export const selectAddonsArray = createSelector(selectAddonStore, (state: State) => {
  return Object.values(state.addons)
    .filter(addons => !!addons)
    .sort((a, b) => a.name.localeCompare(b.name));
});

export const selectMyInDevelopment = createSelector(selectAddonStore, (state: State) =>
  state.myInDevelopmentAddons.map(addonId => state.addons[addonId]).sort((a, b) => a.name.localeCompare(b.name))
);

export const selectPublishedAddons = createSelector(
  selectAddonStore,
  (state: State) => Object.values(state.publishedAddons) || []
);

export const selectPublishedAddonsObject = createSelector(selectAddonStore, (state: State) => state.publishedAddons);

export const selectMyInDevelopmentTableData = createSelector(selectAddonStore, (state: State) =>
  state.myInDevelopmentAddons
    .map(addonId => state.addons[addonId])
    .filter(addon => !!addon)
    .map(addon => ({id: addon.id, name: addon.name, status: '', subscribedUserCount: 0, actions: '', icon: addon.icon}))
    .sort((a, b) => a.name.localeCompare(b.name))
);

export const selectIsLoading = createSelector(selectAddonStore, (state: State) => state.isLoading);

export const selectAllAddonsWithConfig = createSelector(selectAddonStore, (state: State) =>
  Object.values(state.addons)
    .filter(addon => !!addon.labelConfig /* && !!addon.enabled */)
    .sort((a, b) => a.name.localeCompare(b.name))
);

export const selectStoreFilter = createSelector(selectAddonStore, (state: State) => state.storeFilter);

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Addon} from '@app/store/addon/models/addon';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'unleash-delete-ai-app-dialog',
  templateUrl: './delete-ai-app-dialog.component.html',
  styleUrls: ['./delete-ai-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteAiAppDialogComponent {
  public name$: Observable<string>;
  public hasActiveUsers$: Observable<boolean>;
  public expirationDate$: Observable<string>;

  constructor(
    private dialogRef: MatDialogRef<DeleteAiAppDialogComponent, Addon['id']>,
    @Inject(MAT_DIALOG_DATA) private addon: Pick<Addon, 'id' | 'name' | 'usersCount'>
  ) {
    this.name$ = this.setUpName(this.addon);
    this.hasActiveUsers$ = this.setUpHasActiveUsers(this.addon);
    this.expirationDate$ = this.setUpExpirationDate();
  }

  public save(): void {
    this.dialogRef.close(this.addon.id);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  protected setUpName(addon: Pick<Addon, 'id' | 'name' | 'usersCount'>): Observable<string> {
    return of(addon.name);
  }

  protected setUpHasActiveUsers(addon: Pick<Addon, 'id' | 'name' | 'usersCount'>): Observable<boolean> {
    const hasUsers = addon.usersCount > 0;
    return of(hasUsers);
  }

  // TODO: how to set up expiration date?
  protected setUpExpirationDate(): Observable<string> {
    return of(new Date().toString());
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Addon} from '@app/store/addon/models/addon';
import {environment} from 'environments/environment';
import {flatMap, map, share} from 'rxjs/operators';
import {Device, UserDeviceJoined} from '../../models/api/user-device.model';
import {ApiGateway} from './api-gateway.service';

@Injectable({providedIn: 'root'})
export class DeviceService {
  constructor(private apiGateway: ApiGateway, private http: HttpClient) {}

  public addDevice(device: Partial<Device>) {
    return this.apiGateway.post(`device`, {}, device, undefined).pipe(share());
  }

  public removeDevice(device: Partial<Device>) {
    return this.apiGateway.delete(`device/${device.id}`, {}, undefined).pipe(share());
  }

  public updateDevice(deviceId: string, patchBody: Partial<Device>) {
    // temporary workaround for saving transient properties to db
    // For deleting elements in the device we need to set it as empty string ""
    return this.apiGateway.patch(`device/${deviceId}`, {}, this.removeLocalProperties(patchBody)).pipe(share());
  }

  public getDevice(deviceId: string) {
    return this.apiGateway.get(`device/${deviceId}`, {}, undefined);
  }

  public listTeamDevices() {
    return this.apiGateway.get(`device`, {}, undefined);
  }

  public getStreamUrl(device: UserDeviceJoined, streamKey: string) {
    return `${environment.RTMP_STREAM_URL}/rtmp/${device.id}?token=${streamKey}`;
  }

  public runAi(device: UserDeviceJoined, model: Addon, streamKey: string) {
    // TODO flow has to be paired programmed with Harold
    // make sure that the userId is not passed
    const aiStreamEndpoint = `stream/ai/${device.id}/${model.id}/${streamKey}`;
    const data = !!device.agoraToken ? {webrtc: true} : {};
    const observable = this.apiGateway.post(aiStreamEndpoint, {}, data, undefined).pipe(share());
    observable.subscribe(console.info, console.error);
    return observable;
  }

  public stopAi(deviceId: string, modelId: string) {
    const aiStreamEndpoint = `stream/ai/${deviceId}`;
    const observable = this.apiGateway.delete(aiStreamEndpoint, {}, {modelId}).pipe(share());
    observable.subscribe(console.info, console.error);
    return observable;
  }

  // TODO upload logo
  public uploadImage(file: File, deviceId: string) {
    let presignedUrl;
    return this.getLogoPresignedURL(file.type, deviceId, file.name).pipe(
      flatMap(url => {
        presignedUrl = url;
        return this.http.put(url, file);
      }),
      map(response => {
        const url = new URL(presignedUrl);
        const s3Path = url.pathname.slice(1);
        this.updateDevice(deviceId, {logo: s3Path});
        return s3Path;
      })
    );
  }

  public getLogoPresignedURL(contentType: string, instanceId: string, filename: string) {
    return this.apiGateway.post('account/uploadImage', {}, {contentType, instanceId, filename});
  }

  private removeLocalProperties(device: any) {
    const deviceCloned: UserDeviceJoined = {...device};
    delete deviceCloned.id;
    delete deviceCloned.className;
    delete deviceCloned.isDisplayed;
    delete deviceCloned.isLive;
    delete deviceCloned.isRestreamingFrom;
    delete deviceCloned.isRestreamingTo;
    delete deviceCloned.runningModels;
    delete deviceCloned.waitingModels;
    delete deviceCloned.statsRestreamingFrom$;
    delete deviceCloned.statsRestreamingTo$;
    return deviceCloned;
  }
}

<div class="wrapper">
  <section class="welcome">
    <p class="section-title">
      {{ 'core.dashboard.welcome' | translate }}{{ user && user.fullName ? ' ' + user.fullName : '' }}!
    </p>
    <div class="welcome__content">
      <p class="welcome__user">
        <mat-icon class="welcome__icon welcome__icon--user">person</mat-icon>
        {{ user?.email }}
      </p>

      <button mat-button color="primary" class="welcome__logout" (click)="signOut()">
        <mat-icon class="welcome__icon welcome__icon--logout" svgIcon="logout"></mat-icon>
        <span class="welcome__logout-label">{{ 'profile.headers.signOut' | translate }}</span>
      </button>
    </div>
  </section>

  <unleash-alert
    *ngIf="isLastDayFreeTrial && lastDayLabel"
    class="unleash-alert"
    type="warning"
    iconName="info"
    [label]="lastDayLabel"
    buttonText="{{ 'core.dashboard.manageAccount' | translate }}"
    (clicked)="navigateToAccount()"
  >
  </unleash-alert>

  <!-- Cards -->
  <section>
    <p class="section-title">
      {{ 'core.dashboard.gettingStarted' | translate }}
    </p>

    <div class="tutorials">
      <mat-card class="tutorial" *ngFor="let tile of tiles; index as i">
        <div class="tutorial__embed">
          <mat-icon *ngIf="isMobileView && i > 0" class="tutorial__icon">play_circle_outline</mat-icon>
          <video
            width="100%"
            height="100%"
            [controls]="!isMobileView || (isMobileView && i == 0)"
            controlsList="nodownload noremoteplayback"
            poster="{{ tile.videoCover }}"
            class="tutorial__video"
            disablePictureInPicture
            disableRemotePlayback
            preload="none"
            (touchend)="fullscreen($event)"
            (pause)="exitFullscreen($event)"
          >
            <source type="video/mp4" src="{{ tile.videoURL }}" />
          </video>
        </div>

        <div class="tutorial__content">
          <div class="tutorial__header">
            <p class="tutorial__category">{{ tile.category | translate }}</p>
            <p class="tutorial__duration">{{ tile.duration }}</p>
          </div>

          <p class="tutorial__title">{{ tile.title | translate }}</p>
        </div>
      </mat-card>
    </div>
  </section>

  <!-- Resources -->
  <section class="resources">
    <p class="section-title">{{ 'core.dashboard.resources' | translate }}</p>
    <div class="resources__items">
      <a matRipple class="resources__item" href="https://knowledge.unleashlive.com/" target="_blank">
        <mat-icon class="resources__icon">library_books</mat-icon>
        <div class="resources__content">
          <div class="resources__header">
            {{ 'core.dashboard.knowledgeBase' | translate }}
          </div>
          <div class="resources__text">
            {{ 'core.dashboard.commonlyAsked' | translate }}
          </div>
        </div>
      </a>
      <a matRipple class="resources__item" href="#" (click)="goToContactUs($event)">
        <mat-icon class="resources__icon">chat</mat-icon>
        <div class="resources__content">
          <div class="resources__header">
            {{ 'core.dashboard.contact' | translate }}
          </div>
          <div class="resources__text">
            {{ 'core.dashboard.reachOut' | translate }}
          </div>
        </div>
      </a>
    </div>
  </section>
</div>

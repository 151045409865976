import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {UsersTableComponent} from '@app/profile/components/users/users-table/users-table.component';
import {TeamRole} from '@app/profile/models/team.model';
import {UserTeam} from '@app/profile/models/users-table.model';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import {cloneDeep, uniqBy} from 'lodash';
import {map, of, switchMap, take, zip} from 'rxjs';

@Component({
  selector: 'unleash-add-users-to-team',
  templateUrl: './add-users-to-team.component.html',
  styleUrls: ['./add-users-to-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUsersToTeamComponent {
  @ViewChild(UsersTableComponent, {static: true}) public userTable: UsersTableComponent;

  public users$ = this.usersManagementStoreFacadeService.usersTable$.pipe(
    map((users: UserTeam[]) => uniqBy(users, 'userId')),
    map(userTable => cloneDeep(userTable)),
    switchMap(userTable => {
      return zip(of(userTable), this.teamsManagementStoreFacadeService.userBySelectedTeam$);
    }),
    map(([userTableFiltered, userTableList]) =>
      userTableFiltered.filter(user => !userTableList.some(userTable => userTable.userId === user.userId))
    ),
    map(users => users.map(user => ({...user, role: TeamRole.viewer}))),
    take(1)
  );

  public menuItems = [];
  public hasMoreThanOneAdmin$ = this.usersManagementStoreFacadeService.hasMoreThanOneAdmin$;
  public roles$ = this.usersManagementStoreFacadeService.roles$;

  constructor(
    public dialog: MatDialogRef<AddUsersToTeamComponent>,
    private usersManagementStoreFacadeService: UsersManagementStoreFacadeService,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService,
    private userStoreFacadeService: UserStoreFacadeService
  ) {}

  public emitCancel(): void {
    this.dialog.close({hasToAdd: false});
  }

  public emitAdd(): void {
    this.dialog.close({hasToAdd: true, users: this.userTable.emitSelectedValues()});
  }
}

import {PackageNames, PlanModel} from '@app/core/models/api/plan.model';

export interface PlanTemplate {
  planId: string;
  period: string;
  name: string;
  description: string;
  priceMonthly?: number;
  priceAnnual?: number;
  more: string;
  model?: PlanModel;
  features?: PlanFeature[];
}

export interface PlanTemplates {
  [key: string]: PlanTemplate;
}

export interface PlanFeature {
  translationKey: string;
  translationParams?: string | number;
  icon: string;
}

export enum PLAN_KEYS {
  MAPPING = 'mapping',
  LIVE_STREAM = 'live-stream',
  AI_INSIGHTS = 'ai-insights',
  DEVELOPER = 'developer'
}

export enum TRANSLATION_PARAMS {
  MAX_STREAMING_HOURS = 'maxStreamingHours',
  MAX_STORAGE_GB = 'maxStorageGb',
  MAX_DEVICES = 'maxDevices',
  MAX_LIVE_CHANNELS = 'maxLiveChannels',
  MAX_AI_PROCESSING_GB = 'maxAiProcessingGB'
}

export const DEV_ACCESS_BENEFITS = [
  {icon: 'upload', title: 'Upload a test app', label: '+3 months free access'},
  {icon: 'review', title: 'Submit for review', label: '+3 months free access'},
  {icon: 'upload', title: 'Publish an A.I App', label: '+3 months free access'},
  {icon: 'cash', title: 'Sell an A.I. App', label: '+3 months free access'}
];

export const PLANS: PlanTemplates = {
  mapping: {
    planId: 'modelling_1',
    period: 'month',
    name: PackageNames.modelling,
    description: 'Free for the first 14 days (fair use limit applies), then',
    priceMonthly: 149,
    priceAnnual: 99,
    more: 'Need 3D modeling or more storage? Upgrades are available once you’ve signed up'
  },
  'live-stream': {
    planId: 'broadcast_1',
    period: 'month',
    name: PackageNames.broadcast,
    description: 'Free for the first 14 days (fair use limit applies), then',
    priceMonthly: 149,
    priceAnnual: 99,
    more: 'Need more hours or devices? Upgrades are available once you’ve signed up'
  },
  'ai-insights': {
    planId: 'insights_1',
    period: 'month',
    name: PackageNames.insights,
    description: 'Free for the first 14 days (fair use limit applies), then',
    priceMonthly: 149,
    priceAnnual: 99,
    more: 'Need specialized A.I.? Upgrades are available once you’ve signed up'
  },
  developer: {
    planId: 'developer',
    period: 'month',
    name: PackageNames.developer,
    description: '',
    priceMonthly: 99,
    priceAnnual: null,
    more: 'Need more hours or devices? Paid upgrades are available once you’ve started your trial.',
    features: [
      {
        translationKey: 'auth.payment.developerPlan.features.liveStreaming',
        translationParams: TRANSLATION_PARAMS.MAX_STREAMING_HOURS,
        icon: '4'
      },
      {
        translationKey: 'auth.payment.developerPlan.features.uploadAndStorage',
        translationParams: TRANSLATION_PARAMS.MAX_STORAGE_GB,
        icon: '5'
      },
      {
        translationKey: 'auth.payment.developerPlan.features.aiProcessing',
        translationParams: TRANSLATION_PARAMS.MAX_AI_PROCESSING_GB,
        icon: '12'
      },
      {
        translationKey: 'auth.payment.developerPlan.features.devicesCount',
        translationParams: TRANSLATION_PARAMS.MAX_DEVICES,
        icon: '3'
      },
      {
        translationKey: 'auth.payment.developerPlan.features.liveChannelsCount',
        translationParams: TRANSLATION_PARAMS.MAX_LIVE_CHANNELS,
        icon: '2'
      }
    ]
  }
};

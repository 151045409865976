<div class="settings">
  <ng-container *ngFor="let routeSetting of routeSettings">
    <div class="settings__item" *ngIf="routeSetting.value">
      <h1 class="settings__title">
        {{ routeSetting.setting }}
      </h1>
      <p class="settings__value">{{ routeSetting.value }}{{ routeSetting.unit }}</p>
    </div>
  </ng-container>
</div>

<mat-radio-group>
  <unleash-card-option
    *ngFor="let option of options"
    (selected)="selectCard($event)"
    [isSelected]="selectedCard === option.value"
    [title]="option.title"
    [description]="option.description"
    [imageSrc]="option.imageSrc"
    [value]="option.value"
  ></unleash-card-option>
</mat-radio-group>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'unleash-annotation-dialog-ui',
  templateUrl: './annotation-dialog-ui.component.html',
  styleUrls: ['./annotation-dialog-ui.component.scss']
})
export class AnnotationDialogUiComponent implements OnInit {
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() done: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  emitCancelClick() {
    this.cancel.emit(true);
  }

  emitDoneClick() {
    this.done.emit(true);
  }
}

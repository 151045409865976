import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Reason} from './reason.model';

@Component({
  selector: 'unleash-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent implements OnInit {
  @Input() public reasonForm: Reason;
  @Input() public isInProgress: boolean;
  @Input() public isAuthInitialized: boolean;
  @Input() public options: {
    title: string;
    description: string;
    imageSrc: string;
    value: string;
  }[];
  @Output() public continue: EventEmitter<Reason> = new EventEmitter<Reason>();
  @Output() public reasonSelected: EventEmitter<Reason> = new EventEmitter<Reason>();

  public form: UntypedFormGroup = this.fb.group({
    reason: ['', Validators.required]
  });

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    if (!this.reasonForm.reason) {
      const selectedReason: Reason['reason'] = localStorage.getItem('selectedReason');
      this.form.setValue({reason: selectedReason});
      return;
    }

    this.form.setValue(this.reasonForm);
  }

  public clickContinue(): void {
    this.continue.emit(this.form.value);
  }

  public emitReasonSelected(): void {
    this.reasonSelected.emit(this.form.value);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'unleash-color-picker',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorPickerComponent {
  @Input() public isLeftLayout: boolean = false;
  @Input() public selectedColor: LabelColorName = null;
  @Output() public updateColorTag: EventEmitter<LabelColorName> = new EventEmitter<LabelColorName>();

  public labelColorName = LabelColorName;

  public emitUpdateColorTag(color: LabelColorName) {
    this.selectedColor = color;
    this.updateColorTag.emit(color);
  }
}

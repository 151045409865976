import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MissionManagerService} from '../services/mission-manager.service';
import {RenameMissionComponent} from '../components/rename-mission/rename-mission.component';
import {Mission, MissionSettingsService} from '../services/mission-settings.service';
import {of, switchMap, take, zip} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';

@Directive({
  selector: '[ulRenameMission]'
})
export class RenameMissionDirective {
  @Input('ulRenameMission') public mission: Mission;
  @Input() public isNewMission: boolean = false;
  @Output() public updatedMission: EventEmitter<Mission> = new EventEmitter();
  @HostListener('click', ['$event'])
  public onRename() {
    this.missionSettingsService.setIsUpdatingMission(true);
    const dialogRef = this.dialog.open(RenameMissionComponent, {
      ...STANDARD_DIALOG_CONFIG,
      width: '60vw',
      data: {currentName: this.mission.name}
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        switchMap(name => zip(of(name), this.translateService.get('atlas.mission.missionRenamed'))),
        switchMap(([name, translation]: [string, string]) => {
          if (name) {
            return this.isNewMission
              ? zip(of({...this.mission, name}), of(translation))
              : zip(this.missionManagerService.updateMission(this.mission, {name}), of(translation));
          }
        })
      )
      .subscribe(([mission, translation]) => {
        const updatedMission = {...this.mission, ...mission};
        this.missionManagerService.updateMissionUi(updatedMission);
        this.updatedMission.emit(updatedMission);
        this.snackBar.open(translation, null, {panelClass: 'mission-renamed', duration: 3000});
        //after close edit it will return to the first undo stack value
        this.missionSettingsService.undoStack.value[0] = {
          ...this.missionSettingsService.undoStack.value[0],
          ...updatedMission
        };
        this.missionSettingsService.setIsUpdatingMission(false);
      });
  }
  constructor(
    public dialog: MatDialog,
    private missionManagerService: MissionManagerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private missionSettingsService: MissionSettingsService
  ) {}
}

import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy} from '@angular/core';
import {MissionSettingsService} from '../services/mission-settings.service';
import {Subscription, filter, take} from 'rxjs';

@Directive({
  selector: '[ulDeleteWaypoint]'
})
export class DeleteWaypointDirective implements AfterViewInit, OnDestroy {
  @Input('ulDeleteWaypoint') public selectedWaypointIndex: number;
  private editMissionSubscription: Subscription;

  constructor(private missionSettingsService: MissionSettingsService, private elementRef: ElementRef) {}

  public ngOnDestroy(): void {
    if (this.editMissionSubscription) {
      this.editMissionSubscription.unsubscribe();
      this.editMissionSubscription = null;
    }
  }

  public ngAfterViewInit(): void {
    this.editMissionSubscription = this.missionSettingsService.editingMission
      .pipe(filter(editMission => !!editMission.mission))
      .subscribe(editMission => {
        this.setDisabledButton(editMission.mission.route.length);
      });
  }

  @HostListener('click', ['$event'])
  public removeWaypoint() {
    this.missionSettingsService.editingMission.pipe(take(1)).subscribe(editMission => {
      const mission = editMission.mission;
      this.missionSettingsService.indexToRemove = this.selectedWaypointIndex;
      this.missionSettingsService.setEditingMission({
        mission
      });
    });
  }

  private setDisabledButton(totalRoutes: number): void {
    const minTotalWaypoints = 2;
    if (totalRoutes <= minTotalWaypoints) {
      this.elementRef.nativeElement.classList.add('mat-button-disabled');
      this.elementRef.nativeElement.setAttribute('disabled', 'true');
    } else {
      this.elementRef.nativeElement.classList.remove('mat-button-disabled');
      this.elementRef.nativeElement.removeAttribute('disabled');
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {SidebarStateService} from '@app/core/services/sidebar-state.service';
import {TeamSwitcherComponent} from '@app/profile/components/teams/team-switcher/team-switcher.component';
import {Team, TeamRole} from '@app/profile/models/team.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription, debounceTime, fromEvent, take} from 'rxjs';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-sidebar-menu-item-team',
  templateUrl: './sidebar-menu-item-team.component.html',
  styleUrls: ['./sidebar-menu-item-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarMenuItemTeamComponent implements AfterViewInit {
  @Input() public selectedTeamId: Team['id'] = null;
  @Input() public isExpanded: boolean = true;
  @Input() public userRole: TeamRole = null;
  @Input() public totalUserTeams: number = 0;
  @Input() public teams: {[key: string]: Team} = {};
  @Input() public isMobileView: boolean = false;
  @Output() public selectedTeam = new EventEmitter<string>();
  @Output() public goToManageTeams = new EventEmitter<void>();
  @Output() public closeMobileNav = new EventEmitter<void>();
  @ViewChild('menuTrigger') public matMenuTrigger: MatMenuTrigger;
  @ViewChild('menuTrigger', {static: false, read: ElementRef}) public menuTrigger;
  @ViewChild('menuContainer', {static: false, read: ElementRef}) public menu;

  public aclPermissions = AclPermissions;
  public isMenuHover: boolean = false;
  public isItemHover: boolean = false;
  public mouseLeaveMenuSubscription: Subscription;
  public mouseLeaveItemSubscription: Subscription;
  public teamRole: typeof TeamRole = TeamRole;

  constructor(public sideNavbarStateService: SidebarStateService, public dialog: MatDialog) {}

  public ngAfterViewInit(): void {
    this.listenMouseLeaveMenuContainer();
  }

  public emitSelectedTeam(teamId: Team['id']) {
    this.selectedTeam.emit(teamId);
  }

  public emitGoManageTeams(): void {
    this.goToManageTeams.emit();
  }

  public closeMenu(): void {
    if (this.isMobileView) {
      return;
    }
    this.matMenuTrigger.closeMenu();
  }

  public openMenu(): void {
    if (this.isMobileView) {
      return;
    }
    this.matMenuTrigger.openMenu();
  }

  public setItemHover(isItemHover: boolean): void {
    if (this.isMobileView) {
      return;
    }
    this.isItemHover = isItemHover;
    if (isItemHover) {
      this.listenMouseLeaveMenuTrigger();
    }
  }

  public setMenuHover(isHoverMenu: boolean): void {
    this.isMenuHover = isHoverMenu;
  }

  public openMobileTeamSwitcher(): void {
    this.closeMobileNav.emit();
    const dialogRef = this.dialog.open(TeamSwitcherComponent, {
      maxWidth: '100vw',
      panelClass: 'team-switcher-dialog',
      enterAnimationDuration: '0ms'
    });
    dialogRef.componentInstance.closeDialog.pipe(take(1)).subscribe(() => {
      dialogRef.close();
    });
  }

  private listenMouseLeaveMenuTrigger(): void {
    this.mouseLeaveItemSubscription = fromEvent(this.menuTrigger.nativeElement, 'mouseleave')
      // eslint-disable-next-line no-magic-numbers
      .pipe(debounceTime(100), take(1))
      .subscribe(() => {
        if (!this.isMenuHover) {
          this.closeMenu();
        }
      });
  }

  private listenMouseLeaveMenuContainer(): void {
    this.mouseLeaveMenuSubscription = fromEvent(this.menu.nativeElement, 'mouseleave')
      // eslint-disable-next-line no-magic-numbers
      .pipe(debounceTime(100))
      .subscribe(() => {
        if (!this.isItemHover) {
          this.closeMenu();
        }
      });
  }
}

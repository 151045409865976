import {createReducer, on} from '@ngrx/store';
import {getAllIntegrations, getAllIntegrationsError, getAllIntegrationsSuccess} from './integration.actions';
import {
  clearFilter,
  clearSelectedReports,
  deleteAnalysisReportsFail,
  filterReports,
  generateApiKey,
  generateApiKeySuccess,
  generateDisplayedReportsSuccess,
  getAllReports,
  getAllReportsFail,
  getAllReportsSuccess,
  removeKey,
  removeKeySuccess,
  saveDisplayedReportsSuccess,
  toggleSelectedReports,
  updateKey,
  updateKeySuccess
} from './reports.actions';
import {initialState} from './reports.state';

export const reportsFeatureKey: string = 'reports';

export const reportsReducer = createReducer(
  initialState,
  on(getAllReports, state => ({
    ...state,
    isLoading: true
  })),
  on(getAllReportsFail, state => ({...state, isLoading: false})),
  on(getAllReportsSuccess, (state, {reports}) => ({
    ...state,
    isLoading: false,
    reports
  })),
  on(generateDisplayedReportsSuccess, (state, {payload}) => ({
    ...state,
    displayedReports: payload.displayedReports,
    paginator: payload.paginator
  })),
  on(getAllIntegrations, state => ({
    ...state,
    isLoading: true
  })),
  on(getAllIntegrationsError, state => ({...state, isLoading: false})),
  on(getAllIntegrationsSuccess, (state, {payload}) => ({
    ...state,
    integrations: payload,
    isLoading: false
  })),
  on(filterReports, (state, {payload}) => ({...state, filter: payload})),
  on(clearFilter, state => ({
    ...state,
    filter: {
      from: null,
      to: null,
      timeFrom: null,
      timeTo: null,
      search: '',
      type: '',
      status: ''
    }
  })),
  on(saveDisplayedReportsSuccess, (state, {payload}) => ({
    ...state,
    displayedReports: payload
  })),
  on(deleteAnalysisReportsFail, state => ({...state})),
  on(toggleSelectedReports, (state, {payload}) => {
    const reports = state.displayedReports.map(report => ({
      ...report,
      isSelected: payload.report.createdAt === report.createdAt ? !report.isSelected : report.isSelected
    }));

    return {
      ...state,
      displayedReports: reports
    };
  }),
  on(clearSelectedReports, state => {
    const reports = state.displayedReports.map(report => ({
      ...report,
      isSelected: false
    }));

    return {
      ...state,
      displayedReports: reports
    };
  }),
  on(generateApiKey, state => ({...state, isLoadingApiKey: true})),
  on(generateApiKeySuccess, (state, {payload}) => ({...state, apiKey: payload.key, isLoadingApiKey: false})),
  on(removeKey, state => ({...state, isApiKeyActionInProgress: true})),
  on(removeKeySuccess, state => ({...state, isApiKeyActionInProgress: false, apiKey: null})),
  on(updateKey, state => ({...state, isApiKeyActionInProgress: true})),
  on(updateKeySuccess, (state, {payload}) => ({...state, isApiKeyActionInProgress: false, apiKey: payload.key}))
);

<div [@growDown] class="container mat-drawer" *ngIf="isOpen">
  <p class="message mat-body-strong" [innerHTML]="message"></p>
  <div class="action-row">
      <button *ngFor="let action of actions; let index=index;"
              type="button" mat-button color="accent"
              (click)="actionClicked(index)">
          {{action}}
      </button>
  </div>
</div>

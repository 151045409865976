import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appLadda]'
})
export class LaddaDirective {
  @Input('appLadda')
  public set setupLadda(isLoading: boolean) {
    this.appLadda = isLoading;
    if (isLoading) {
      setTimeout(() => {
        this.displaySpinner();
      });
    } else {
      this.destroySpinner();
    }
  }
  public appLadda: boolean;

  constructor(private elementRef: ElementRef) {}

  private displaySpinner() {
    const isDisabled = this.elementRef.nativeElement.hasAttribute('disabled');

    if (this.appLadda) {
      const innerHTML = this.elementRef.nativeElement.getElementsByClassName('mat-button-wrapper')[0].innerHTML;
      this.elementRef.nativeElement.getElementsByClassName('mat-button-wrapper')[0].innerHTML =
        `<span class="unleash-ladda-spinner id=unleash-spinner ${
          isDisabled ? 'unleash-ladda-spinner--disabled' : ''
        }"></span>` + innerHTML;
    }
  }

  private destroySpinner(): void {
    if (this.elementRef) {
      this.elementRef.nativeElement.getElementsByClassName('unleash-ladda-spinner')[0]?.remove();
    }
  }
}

import {Injectable} from '@angular/core';
import {AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserService} from '@app/core/services/api/user.service';
import {WebrtcService} from '@app/core/services/api/webrtc.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {ThumblerPipe} from '@app/shared/pipes/thumbler.pipe';
import {BehaviorSubject, combineLatest, Observable, of, zip} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, share, shareReplay, switchMap, take} from 'rxjs/operators';
import {AtlasService} from './atlas.service';
import {SensorsDataIotService} from '@app/flights/services/sensors-data-iot.service';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {latLngBounds, Map, Marker} from 'leaflet';
import {defaultMarkerSize} from '../atlas.config';
import {GeojsonAssetLoaderService} from './geojson-asset-loader.service';
import {createMapIcon} from '../marker-icons/custom-map-pointer';
import {IoTSensorsData} from '@app/flights/models/remote-cockpit.model';

@Injectable({
  providedIn: 'root'
})
export class AssetsFilterService {
  /** Assets displayed in the layers sidebar */
  public readonly assets$: Observable<AtlasAssetModel[]>;
  public gpsDevices$: Observable<UserDeviceJoined[]>;
  public readonly streamingGpsDevices$: Observable<UserDeviceJoined[]>;
  /** Assets filtered by searching for query */
  public readonly searchedAssets$: Observable<AtlasAssetModel[]>;
  public readonly searchedStreamingGpsDevices$: Observable<AtlasAssetModel[]>;
  /** Assets filtered by map bounds */
  public readonly pannedAssets$: Observable<AtlasAssetModel[]>;

  public readonly flightLogs$: Observable<AtlasAssetModel[]>;
  public searchFilterQuery: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public openGroup: BehaviorSubject<{[key: string]: string}> = new BehaviorSubject(null);
  public updatedAsset: BehaviorSubject<AtlasAssetModel> = new BehaviorSubject(null);
  public searchResult: BehaviorSubject<AtlasAssetModel> = new BehaviorSubject<AtlasAssetModel>(null);
  public searchResult$: Observable<AtlasAssetModel> = this.searchResult.asObservable();

  private searchedMarker: any = null;
  private initialAssetIsDisplaying = false;

  constructor(
    private thumblerPipe: ThumblerPipe,
    private atlasService: AtlasService,
    public webrtcService: WebrtcService,
    private userService: UserService,
    private sensorDataIoTService: SensorsDataIotService,
    private liveStreamPageService: LiveStreamPageService,
    private geojsonAssetLoaderService: GeojsonAssetLoaderService
  ) {
    this.assets$ = this.atlasService.assets$.pipe(distinctUntilChanged(), shareReplay(1));
    this.gpsDevices$ = this.atlasService.activeStreamingDevice$.pipe(
      filter(device => !!device),
      switchMap(activeDevice => zip(of(activeDevice), this.userService.userDevices$)),
      map(([activeDevice, devices]) => devices.map(device => (device.id === activeDevice.id ? activeDevice : device)))
    );
    this.streamingGpsDevices$ = this.liveStreamPageService.liveDevicesId$.pipe(
      mergeMap(
        (streamingDevices: string[]): Observable<UserDeviceJoined[]> =>
          this.gpsDevices$.pipe(
            map((gpsDevices: UserDeviceJoined[]): UserDeviceJoined[] =>
              gpsDevices.filter((device: UserDeviceJoined): boolean => streamingDevices.indexOf(device.id) !== -1)
            )
          )
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      switchMap(devices => {
        return !devices.length
          ? of([])
          : combineLatest(
              devices.map(device => {
                return (
                  this.sensorDataIoTService
                    .subscribe(device.id)
                    // .subscribeToMockData(device.id)
                    .pipe(
                      take(1),
                      map(data => {
                        return Object.keys(data).length > 0 ? {...data, deviceId: device.id} : null;
                      })
                    )
                );
              })
            ).pipe(
              map((iotDevices: IoTSensorsData[]) => {
                const hasData = iotDevices.every(device => !!device);
                return hasData
                  ? devices
                      .filter(({id}) => iotDevices.some(iotDevice => iotDevice.deviceId === id))
                      .map(device => ({...device, icon: '/assets/icons/quadcopter.svg'}))
                  : devices;
              })
            );
      }),
      share()
    );
    const filterByType = (type: string) =>
      map((assets: AtlasAssetModel[]) => assets.filter((a: AtlasAssetModel) => a.type === type));
    this.flightLogs$ = this.atlasService.assets$.pipe(filterByType(AssetType.FLIGHT_LOG));
    this.searchedAssets$ = combineLatest([this.searchFilterQuery.asObservable(), this.atlasService.assets$]).pipe(
      mergeMap(([query, assets]) => of(this.filterBySearchQuery(query, assets))),
      // tap(console.info),
      shareReplay(1)
    );
    this.searchedStreamingGpsDevices$ = combineLatest([
      this.searchFilterQuery.asObservable(),
      this.streamingGpsDevices$.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
    ]).pipe(
      mergeMap(([query, devices]) => {
        const deviceAssets = devices
          .filter(device => !!device)
          .map(device => this.createDevice(device)) as AtlasAssetModel[];
        return of(this.filterBySearchQuery(query, deviceAssets));
      }),
      shareReplay(1)
    );
  }

  public filterAsset(assetId: string) {
    this.assets$.pipe(take(1)).subscribe(assets => {
      const selectedLayer = assets.find(asset => asset.id === assetId);
      if (selectedLayer) {
        this.atlasService.clearSelectedLayers();
        this.atlasService.addSelectedLayer(selectedLayer);
        this.updatedAsset.next({...selectedLayer, isSelected: true});
        if (selectedLayer?.groupName) {
          this.openGroup.next({groupName: selectedLayer.groupName, assetId: assetId});
          return;
        }
        const elementToScroll = document.getElementById(selectedLayer.id);
        elementToScroll.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }
    });
  }

  public updateFilterQuery(name?: string): void {
    if (this.searchFilterQuery.getValue() === name) {
      return;
    }
    this.searchFilterQuery.next(name);
  }

  private createDevice(device) {
    const logo = device.logo
      ? (this.thumblerPipe.transform(device.logo, THUMBLER_AVAILABLE_CONFIGS.atlasDeviceThumbLogo) as string)
      : device?.icon;
    return {
      device: device,
      id: device.id,
      owner: device.owner,
      name: device.name,
      updatedAt: device.updatedAt,
      bounds: [[device.lat, device.lng]],
      type: AssetType.DEVICE,
      deviceIcon: this.generateIcon(logo),
      isDisplaying: true
    };
  }

  private generateIcon(logo): string {
    return !!logo && logo !== '/assets/images/placeholder.png' ? logo : '/assets/icons/atlas/videocam-small.svg';
  }

  private filterBySearchQuery(query: string, assets: AtlasAssetModel[]): AtlasAssetModel[] {
    if (!query) {
      // console.info('return - nosearch query', assets);
      return assets.map(asset => {
        delete asset.searchResult;
        return asset;
      });
    }
    const formattedAssetForSearch = assets.reduce((accumulator, currentValue) => {
      let assetLayer = [];
      if ((currentValue.leafletLayer as any)?.getLayers) {
        assetLayer = (currentValue.leafletLayer as any)?.getLayers();
      }

      return {
        [currentValue.id]: {
          properties: assetLayer.map(leafletLayer => {
            return {
              itemsToSearch: {...leafletLayer.feature.properties, id: leafletLayer.feature?.id},
              type: leafletLayer.feature?.geometry?.type,
              leafletLayer
            };
          }),
          name: currentValue.name,
          groupName: currentValue.groupName
        },
        ...accumulator
      };
    }, {});
    const searchResult = this.searchInObject(formattedAssetForSearch, query);
    return Object.keys(searchResult)
      .map(key =>
        assets
          .filter(asset => asset.id === key)
          .map(asset => {
            asset['searchResult'] = searchResult[key];
            return asset;
          })
      )
      .flat();
  }

  public searchInObject(formattedAssetForSearch: any, searchTerm: string) {
    const containsSearchTerm = (assetId, value, searchTerm) => {
      //For name and group name
      if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
      //For properties array
      if (Array.isArray(value)) {
        const propertiesMatched = this.searchInProperties(value, searchTerm.toLowerCase());
        formattedAssetForSearch[assetId]['properties'] = propertiesMatched;
        return propertiesMatched.length > 0;
      }
      //For objects
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).some(formattedPropertyValue =>
          containsSearchTerm(assetId, formattedPropertyValue, searchTerm)
        );
      }
      return false;
    };

    const searchResults = Object.entries(formattedAssetForSearch).filter(([key, value]) =>
      containsSearchTerm(key, value, searchTerm)
    );
    const formattedResult = searchResults.reduce((accumulator, currentValue) => {
      return {
        [currentValue[0]]: {
          ...formattedAssetForSearch[currentValue[0]]
        },
        ...accumulator
      };
    }, {});
    return formattedResult;
  }

  private searchInProperties(propertiesArray, searchTerm): any[] {
    const resultArray = [];

    propertiesArray.forEach(obj => {
      const filteredObj = {};
      const itemsToSearch = obj?.itemsToSearch;
      Object.keys(itemsToSearch).forEach(key => {
        if (!itemsToSearch[key]) {
          return;
        }
        if (itemsToSearch[key].toString().toLowerCase().includes(searchTerm)) {
          filteredObj[key] = itemsToSearch[key];
        }
      });

      if (Object.keys(filteredObj).length > 0) {
        resultArray.push({...filteredObj, type: obj?.type, leafletLayer: obj?.leafletLayer});
      }
    });

    return resultArray;
  }

  public detectAtlasChanges(): void {
    this.atlasService.toogleHasToDetectChanges();
  }

  public setSearchResultsActive(asset: AtlasAssetModel) {
    this.searchResult.next(asset);
  }

  private highlightMarker(layer: Marker) {
    const defaultMarkerIconOptions = {
      size: defaultMarkerSize,
      color: '#CE1C00', //red marker
      className: ''
    };
    const newIcon = createMapIcon(defaultMarkerIconOptions);
    layer.setIcon(newIcon);
    layer.setZIndexOffset(10);
  }

  public selectSearchedLayer(layer: any, map: Map) {
    if (!layer) return;
    //For shapes
    if (layer.getBounds) {
      map.fitBounds(layer.getBounds());
      return;
    }
    //For markers
    if (layer.getLatLng) {
      this.restoreSearchedMarkerIcon(this.searchResult.value);
      this.setSearchedMarker(layer);
      this.highlightMarker(layer);
      map.setView(layer.getLatLng(), 19);
      return;
    }
    //For models
    if (layer.options?.bounds) {
      map.fitBounds(latLngBounds(layer.options?.bounds));
      return;
    }
    return null;
  }

  public displayLayer(asset: AtlasAssetModel) {
    this.initialAssetIsDisplaying = asset.isDisplaying;
    if (!asset.isDisplaying) {
      this.atlasService.displayAsset(asset.id, true);
    }
  }

  public closeSearchSidebar(asset: AtlasAssetModel) {
    this.setSearchResultsActive(null);
    this.restoreSearchedMarkerIcon(asset);
    this.searchedMarker = null;
    this.atlasService.displayAsset(asset?.id, this.initialAssetIsDisplaying);
  }

  private restoreSearchedMarkerIcon(asset: AtlasAssetModel) {
    if (this.searchedMarker) {
      const layer = this.searchedMarker.layer;
      const newIcon = this.geojsonAssetLoaderService
        .generateMarker(layer.feature, layer.getLatLng(), asset, defaultMarkerSize)
        ?.getIcon();
      layer.setIcon(newIcon);
      layer.setZIndexOffset(1);
    }
  }

  private setSearchedMarker(layer: Marker) {
    this.searchedMarker = {
      layer,
      color: layer.getIcon()?.options?.attribution,
      className: layer.getIcon()?.options?.className
    };
  }
}

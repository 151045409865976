import {HttpParams} from '@angular/common/http';

export class ParamsHelper {
  static getUrlWithParams(url: string, urlQueryParams: any) {
    let params = new HttpParams();
    for (const [k, v] of Object.entries(urlQueryParams)) {
      params = params.set(k, v.toString());
    }
    return `${url}?${params.toString()}`;
  }
}

<div class="promo-link">
  {{ 'stripeElements.doYouHaveA' | translate }}
  <span class="link" (click)="toggleShowingPromoCode()">{{ 'stripeElements.promoCode' | translate | lowercase }}?</span>
</div>

<form [formGroup]="form" *ngIf="isShowingPromoCode$ | async">
  <div class="content">
    <mat-form-field class="form-field-code" appearance="fill">
      <mat-label>{{ 'stripeElements.promoCode' | translate }}</mat-label>
      <input matInput formControlName="promoCode" placeholder="" />
    </mat-form-field>

    <button class="promo-button" color="primary" mat-button (click)="emitPromoCode()">
      {{ 'stripeElements.apply' | translate }}
    </button>
  </div>
</form>

<ng-container *ngIf="isShowingPromoCode$ | async">
  <div class="alert-container" *ngIf="isSuccess === true">
    <unleash-alert
      type="success"
      iconName="check"
      label="{{ 'stripeElements.promoCodeApplied' | translate }}"
    ></unleash-alert>
  </div>
  <div class="alert-container" *ngIf="isSuccess === false">
    <unleash-alert
      type="error"
      iconName="close"
      label="{{ 'stripeElements.promoCodeExpiredOrInvalid' | translate }}"
    ></unleash-alert>
  </div>
</ng-container>

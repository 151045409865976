<div class="container mat-elevation-z2" *ngIf="plan as combinedPlan">
  <div class="header">
    <div class="plan">{{ 'profile.headers.plan' | translate }}</div>
    <div class="cost">{{ 'plans.checkout.monthlyCost' | translate }}</div>
  </div>
  <div class="plans">
    <ng-container *ngFor="let plan of plans | keyvalue; let isFirst = first">
      <div class="plan">
        <div class="plan-name">{{ plan.value.packageName }}</div>
        <div class="dots"></div>
        <div class="price">{{ plan.value.priceMonthly | number: '1.2-2' }} USD</div>
      </div>
      <div class="plan plan--discount" *ngIf="!isFirst && hasProfessionalDiscount">
        <div class="plan-name--small">{{ 'plans.checkout.professionalDiscount' | translate }}</div>
        <div class="dots"></div>
        <div class="price--small">{{ -350 | number: '1.2-2' }} USD</div>
      </div>
      <div class="features">
        <unleash-plan-features [plan]="plan.value"></unleash-plan-features>
      </div>
    </ng-container>
  </div>
  <div class="detail">
    <mat-icon class="icon" color="primary">picture_as_pdf</mat-icon>
    <a href="/assets/docs/Unleash live - Plans - 1Jul20.pdf" target="_blank" class="link">{{
      'plans.checkout.viewInclusionPdf' | translate | uppercase
    }}</a>
  </div>
  <mat-divider></mat-divider>
  <div class="checkout">
    <ng-container *ngIf="hasYearlyDiscount">
      <div class="discount checkout__item">
        <span class="badge">{{ 'plans.checkout.yearlyDiscount' | translate | uppercase }}</span>
        <div class="price">
          <span class="value">{{ combinedPlan.priceMonthly * 12 - combinedPlan.priceAnnual * 12 | number }}</span
          >USD
        </div>
      </div>
      <div class="divider divider-discount"></div>
    </ng-container>
    <div class="monthly checkout__item">
      <span class="text">{{ 'plans.checkout.monthlyTotal' | translate }}</span>
      <div class="price">
        <span class="value">{{
          hasYearlyDiscount ? combinedPlan.priceAnnual : (combinedPlan.priceMonthly | number: '1.2-2')
        }}</span
        >USD
      </div>
    </div>
    <div class="divider divider-monthly" *ngIf="hasYearlyDiscount"></div>
    <div class="total checkout__item" *ngIf="hasYearlyDiscount">
      <span class="text">{{ 'plans.checkout.yearlyTotal' | translate }}</span>
      <div class="price">
        <span class="value">{{ combinedPlan.priceAnnual * 12 | number: '1.2-2' }}</span
        >USD
      </div>
    </div>
  </div>
</div>

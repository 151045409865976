export interface SortMenuModel {
  name: string;
  icon: string;
  value: SortOptions;
}

export enum SortOptions {
  asc = 'asc',
  desc = 'desc'
}

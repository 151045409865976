import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {TeamAction, TeamActions, TeamTable, TeamTableActions} from '@app/profile/models/team.model';
import {UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-teams-table',
  templateUrl: './teams-table.component.html',
  styleUrls: ['./teams-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamsTableComponent {
  @Input() public dataSource: TeamTable[] = [];
  @Input() public menuItems: TeamTableActions[] = [];
  @Output() public action: EventEmitter<TeamAction> = new EventEmitter();
  @Output() public user: EventEmitter<UserTable> = new EventEmitter();

  public displayedColumns = ['name', 'members', /* 'lastActive', */ 'actions'];

  public emitAction(type: TeamActions, teamId: string): void {
    this.action.emit({action: type, teamId});
  }

  public emitUser(user: UserTable): void {
    this.user.emit(user);
  }
}

<div class="container">
  <div class="circle">
    <div class="text">
      {{ currentValue | number: '1.0-2' }}<span *ngIf="unitsInside" class="units">{{ units }}</span>
    </div>
    <div class="donut" [ngClass]="{fill: fillValue > 180}">
      <div class="bar-right" [style]="'transform: rotate(' + fillValue + 'deg)'"></div>
      <div [ngClass]="{'bar-left': fillValue > 180}"></div>
    </div>
  </div>
  <div class="title">{{ title }}</div>
  <ng-container *ngIf="isAwsUser; else gaugeSubtitleTemplate"> </ng-container>
  <ng-template #gaugeSubtitleTemplate>
    <div *ngIf="!isQuotaExceeded">
      <span class="sub-title" *ngIf="remainingValue > 0"
        >{{ remainingValue | number: '1.0-2' }} / {{ maxValue }} {{ units }} {{ subTitle }}</span
      >
      <span class="sub-title" *ngIf="remainingValue === 0">
        {{ 'profile.usage.noMore' | translate }} {{ units }} {{ subTitle }}
      </span>
    </div>
    <div *ngIf="isQuotaExceeded" class="quota-message">
      <span class="sub-title warn"
        >{{ currentValue - maxValue | number: '1.0-2' }} {{ units }} {{ 'profile.usage.overQuota' | translate }}</span
      >
      <mat-icon
        aria-label="{{ 'profile.devices.pricingInfo' | translate }}"
        matTooltip="{{ 'profile.devices.pricingInfoTooltip' | translate }}://faq.unleashlive.com"
        class="icon small-icon"
        >info</mat-icon
      >
    </div>
  </ng-template>
</div>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {UnleashMaterialSharedModule} from '../unleash-material/unleash-material-shared.module';
import {InfoCardComponent} from './info-card/info-card.component';
import {MiniPlanCardComponent} from './mini-plan-card/mini-plan-card.component';
import {PlanCardComponent} from './plan-card/plan-card.component';
import {NewPlanFeaturesComponent} from './plan-features/plan-features.component';

@NgModule({
  declarations: [MiniPlanCardComponent, PlanCardComponent, InfoCardComponent, NewPlanFeaturesComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, UnleashMaterialSharedModule],
  exports: [MiniPlanCardComponent, PlanCardComponent, InfoCardComponent, NewPlanFeaturesComponent]
})
export class PlansSharedModule {}

import {Component, Input} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {CompanyModel} from '@app/core/models/api/company-model';
import {UserService} from '@app/core/services/api/user.service';
import {UserModel} from '@app/core/models/api/user-model';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {AclPermissions} from '@app/core/models/api/acl.model';

@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.scss']
})
export class StreamingComponent {
  @Input('company')
  public set fillCompany(company: CompanyModel) {
    if (company && Object.keys(company).length > 0) {
      this.company = company;
      this.isShared = !!this.company.isPublicWatchPageEnabled;
      this.publicWatchPageURL = this.getWatchPageLink(this.company.slug);
    }
  }

  @Input('user')
  public set fillUser(user: UserModel) {
    if (user && Object.keys(user).length > 0) {
      this.user = user;
    }
  }

  public isShared = false;
  public company: CompanyModel;
  public user: UserModel;
  public publicWatchPageURL: string;
  public getSecureWatchPageURL: string;
  public isSlugLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public aclPermissions = AclPermissions;

  constructor(
    private userService: UserService,
    public snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  public updateCompanySlug() {
    this.isSlugLoaded.next(false);
    this.userService.updateCompany(this.company.id, {slug: this.userService.generateCompanySlug(this.company.slug)});
    this.isSlugLoaded.next(true);
    this.snackBar.open('Secure url created successfully!!', 'OK', {
      duration: 4000
    });
  }

  public getWatchPageLink(slug: string) {
    return environment.STREAM_DASHBOARD_URL + slug;
  }

  public toogleShareBroadcast() {
    this.isShared = !this.isShared;
    if (!this.company) {
      return;
    } // todo handle lack of company in ui
    this.userService.updateCompany(this.company.id, {isPublicWatchPageEnabled: this.isShared});
    this.translateService
      .get(`profile.streaming.shareBroadcastToast.${this.isShared ? '0' : '1'}`)
      .subscribe(res => this.snackBar.open(res, null, {duration: 5000} as MatSnackBarConfig));
  }

  public onUrlCopied() {
    this.translateService
      .get('profile.streaming.urlCopied')
      .subscribe(res => this.snackBar.open(res, null, {duration: 5000} as MatSnackBarConfig));
  }
}

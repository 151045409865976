import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LayoutCheckerService {
  public isShowUploadItem$: Observable<boolean>;
  //eslint-disable-next-line rxjs/finnish
  public isShowFooterNav$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private isShowUploadItem: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {
    this.isShowUploadItem$ = this.isShowUploadItem.asObservable();
  }

  public setIsShowUploadItem(isShowUpload: boolean): void {
    this.isShowUploadItem.next(isShowUpload);
  }

  public setIsShowFooterNav(isShowFooterNav: boolean): void {
    this.isShowFooterNav$.next(isShowFooterNav);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputSizeDirective} from './input-size.directive';

@NgModule({
  declarations: [InputSizeDirective],
  imports: [CommonModule],
  exports: [InputSizeDirective]
})
export class InputSizeModule {}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {LIBRARY_ITEM_TYPE_MODEL} from '@app/library/models/mime-type.model';
import {LibraryFilterService} from '@app/library/services/library-filter.service';
import {Router} from '@angular/router';

@Directive({
  selector: '[ulGoToModel]',
  standalone: true
})
export class GoToModelDirective {
  @Input('ulGoToModel') public asset: AtlasAssetModel;

  constructor(private router: Router, private libraryFilterService: LibraryFilterService) {}

  @HostListener('click', ['$event'])
  public goToModel(): void {
    const asset = this.asset;
    const modelId: AtlasAssetModel['modelId'] = asset.modelId;
    this.libraryFilterService.filterFormValue.value.type = LIBRARY_ITEM_TYPE_MODEL.MODEL;
    this.router.navigateByUrl(`secure/library/models/${modelId}/view/2d`);
  }
}

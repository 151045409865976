import {SignInCodeStatus} from '@app/auth/models/registration-login.types';
import {signInFormStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {SignInFormState} from './sign-in.reducer';

export const selectSignInFormValue = createSelector(signInFormStateSelector, (state: SignInFormState) => state.form);

export const selectSignInFormInProgress = createSelector(
  signInFormStateSelector,
  (state: SignInFormState) => state.inProgress
);

export const selectSignInFormError = createSelector(signInFormStateSelector, (state: SignInFormState) => state.error);

export const selectSignInFormIsSignOutAlert = createSelector(
  signInFormStateSelector,
  (state: SignInFormState) => state.isSignOutAlert
);

export const selectSignInFormIsDeveloperMode = createSelector(
  signInFormStateSelector,
  (state: SignInFormState) => state.isDeveloperMode
);

export const selectSignInForm = createSelector(signInFormStateSelector, (state: SignInFormState) => state.form);

<div class="content-item plan-card-viewer-container">
  <unleash-mini-plan-card
    class="mini-plan-card"
    *ngFor="let plan of plans$ | async | keyvalue"
    [title]="planName"
    [name]="plan.value.name"
    [img]="plan.value.img"
    [isActive]="plan.value.isActive"
  ></unleash-mini-plan-card>
</div>

<div class="summary">
  <ng-container *ngIf="isAwsUser; else normalUserTemplate">
    <p data-test-id="marketplace-summary-text-message">
      {{ 'profile.plan.awsPlan' | translate }}
    </p>
    <p></p>
  </ng-container>
  <ng-template #normalUserTemplate>
    <ng-container *ngIf="userPlan.supportCode === 'enterprise'; else autoReNewTemplate">
      <p data-test-id="enterprise-summary-text-message">
        {{ 'profile.plan.enterprisePlan' | translate }}
      </p>
    </ng-container>
    <ng-template #autoReNewTemplate>
      <ng-container *ngIf="user.subscriptionPeriod === 'month'; else yearTemplate">
        <p>
          {{ 'profile.plan.monthlyPackageCost.0' | translate }}
          <span class="period">{{ 'profile.plan.monthlyPackageCost.1' | translate }}</span>
          {{ 'profile.plan.monthlyPackageCost.2' | translate }}
          <span class="price" *ngIf="userPlan"
            >{{ userPlan.priceMonthly | number: '1.2-2' }} {{ 'profile.plan.monthlyPackageCost.3' | translate }}</span
          >{{ 'profile.plan.monthlyPackageCost.4' | translate }}
        </p>
      </ng-container>
      <ng-template #yearTemplate>
        <p>
          {{ 'profile.plan.annualPackageCost.0' | translate }}
          <span class="period">{{ 'profile.plan.annualPackageCost.1' | translate }}</span>
          {{ 'profile.plan.annualPackageCost.2' | translate }}
          <span class="price" *ngIf="userPlan"
            >{{ userPlan.priceAnnual * 12 | number: '1.2-2' }}
            {{ 'profile.plan.annualPackageCost.3' | translate }}</span
          >{{ 'profile.plan.annualPackageCost.4' | translate }}
        </p>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="!isAwsUser">
    <p *ngIf="renewDate$ | async as renewDate" class="renew-date">
      {{ 'profile.plan.autoRenewal' | translate: {value: (renewDate | date)} }}
    </p>
  </ng-container>
</div>

<div class="content-item buttons">
  <ng-container *ngIf="userPlan.supportCode !== 'enterprise' && !isAwsUser">
    <ng-container *uaHasPermission="aclPermissions.MainApiUnsubscribeUser">
      <button
        data-test-id="cancel-subscription-button"
        class="button"
        mat-stroked-button
        color="primary"
        (click)="cancelSubscription()"
      >
        {{ 'profile.plan.cancelPackage' | translate | uppercase }}
      </button>
    </ng-container>
    <ng-container *uaHasPermission="aclPermissions.MainApiSubscribeToPlan">
      <button
        data-test-id="change-package-button"
        class="button"
        mat-raised-button
        color="primary"
        (click)="changePackage()"
      >
        {{ 'profile.plan.changePackage' | translate | uppercase }}
      </button>
    </ng-container>
  </ng-container>
</div>

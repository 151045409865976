<ng-container *ngIf="dialogMode === apiKeyDialogModeEnum.UPDATE; else deleteTemplate">
  <h1 mat-dialog-title>{{ 'analysis.api.UpdateApiKey' | translate }}</h1>
  <p mat-dialog-content>
    {{ 'analysis.api.UpdateApiKeyDescription' | translate }}
  </p>
  <div mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="emitClose()">{{ 'common.cancel' | translate }}</button>
    <a
      cdkFocusInitial
      mat-raised-button
      color="warn"
      [appLadda]="isApiKeyActionInProgress$ | async"
      [disabled]="isApiKeyActionInProgress$ | async"
      (click)="emitUpdateKey()"
    >
      <ng-container *ngIf="isApiKeyActionInProgress$ | async; else updateButtonTemplate">
        {{ 'analysis.api.updatingKey' | translate }}
      </ng-container>
      <ng-template #updateButtonTemplate> {{ 'analysis.api.updateKey' | translate }} </ng-template>
    </a>
  </div>
</ng-container>
<ng-template #deleteTemplate>
  <h1 mat-dialog-title>{{ 'analysis.api.RemoveApiKey' | translate }}</h1>
  <p mat-dialog-content>
    {{ 'analysis.api.RemoveApiKeyDescription' | translate }}
  </p>
  <div mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="emitClose()">{{ 'common.cancel' | translate }}</button>
    <a
      cdkFocusInitial
      mat-raised-button
      color="warn"
      [appLadda]="isApiKeyActionInProgress$ | async"
      [disabled]="isApiKeyActionInProgress$ | async"
      (click)="emitRemoveKey()"
    >
      <ng-container *ngIf="isApiKeyActionInProgress$ | async; else removeButtonTemplate">
        {{ 'analysis.api.removingKey' | translate }}
      </ng-container>
      <ng-template #removeButtonTemplate>
        {{ 'analysis.api.removeKey' | translate }}
      </ng-template>
    </a>
  </div>
</ng-template>

<div class="color-container" [ngClass]="{'color-container__left-layout': isLeftLayout}">
  <div (click)="emitUpdateColorTag(labelColorName.grey)" class="color color__grey">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.grey">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.blue)" class="color color__blue" data-test-id="tag-color-blue">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.blue">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.skyblue)" class="color color__skyblue">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.skyblue">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.purple)" class="color color__purple">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.purple">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.pink)" class="color color__pink">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.pink">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.red)" class="color color__red">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.red">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.orange)" class="color color__orange">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.orange">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.lightorange)" class="color color__lightorange">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.lightorange">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.yellow)" class="color color__yellow">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.yellow">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.lightgreen)" class="color color__lightgreen">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.lightgreen">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.green)" class="color color__green">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.green">check</mat-icon>
  </div>
  <div (click)="emitUpdateColorTag(labelColorName.black)" class="color color__black">
    <mat-icon class="marker" color="white" *ngIf="selectedColor === labelColorName.black">check</mat-icon>
  </div>
</div>

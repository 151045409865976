import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, share} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyCardService {
  private hasToHide = new BehaviorSubject<boolean>(false);
  public hasToHide$: Observable<boolean> = this.hasToHide.asObservable().pipe(share());

  public hideKey(): void {
    this.hasToHide.next(true);
  }

  public showKey(): void {
    this.hasToHide.next(false);
  }

  public toggleKey(): void {
    this.hasToHide.next(!this.hasToHide.value);
  }
}

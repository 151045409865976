<h1 mat-dialog-title>{{ 'profile.phone.enterConfirmationCode' | translate }}</h1>
<section class="content" [formGroup]="form" mat-dialog-content>
  <span>{{ 'profile.phone.verifyPhoneNumberDescription' | translate: {value: data.phoneNumber} }}</span>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'profile.phone.confirmationCode' | translate }}</mat-label>
    <input matInput formControlName="code" />
  </mat-form-field>
</section>

<section mat-dialog-actions>
  <button [disabled]="isLoading" mat-dialog-close mat-button color="primary">
    {{ 'profile.phone.cancel' | translate }}
  </button>
  <button [disabled]="isLoading" (click)="confirmCode()" [disabled]="form.invalid" mat-flat-button color="primary">
    {{ 'profile.phone.confirm' | translate }}
    <unleash-spinner *ngIf="isLoading" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
  </button>
</section>

import {LibraryItem} from '@app/library/models/folder-file.model';
import {LibraryItemMutateModel} from '../models/delete-items.model';
import {LibraryListQueryParams, LibraryListSubfoldersQueryParams} from '../models/library-filter.model';
import {UpdateTagItemsQuery} from '../models/tag.model';
import {parseQueryParams} from './queryUtils';

const ITEM_METADATA = `
  metadata {
    addonId
    camaperturevalue
    camfnumber
    camfocallength
    camfocallengthin35mmformat
    camiso
    childItemsNumber
    exifimageheight
    exifimagewidth
    gpsalt
    gpslat
    gpslatref
    gpslng
    gpslngref
    height
    isPanoramic
    lastmodified
    make
    model
    orientation
    size
    software
    type
    width
    aggregationFolders {
      archiveId
      resultsId
    }
  }`;

export const newLibraryBatchItem = (ownerId: string) => {
  return `subscription MySubscription {
    newLibraryBatchItem(ownerId: "${ownerId}") {
      libraryItems {
        location
        teamId
        type
        tags
        createdAt
        deviceId
        id
        name
        parentId
        s3Path
        updatedAt
        mimeType
        metadata {
          duration
        }
      }
      ownerId
    }
  }`;
};

export const newLibraryItem = (ownerId: string) => {
  return `subscription newLibraryItem {
    newLibraryItem(ownerId: "${ownerId}") {
      location
      teamId
      type
      tags
      createdAt
      deviceId
      id
      name
      parentId
      s3Path
      updatedAt
      mimeType
    }
  }`;
};

export const deleteLibraryItem = (ownerId: string) => {
  return `subscription DeleteLibraryItemSubscription {
    deleteLibraryItem(ownerId: "${ownerId}") {
      deleteItems {
        id
      },
      ownerId,
    }
  }`;
};

export const getLibraryListQuery = (queryParams: LibraryListQueryParams) => {
  const queryParamsList = parseQueryParams(queryParams);
  return `query list {
    list(${queryParamsList.join(',')}) {
      items {
        teamId
        location
        type
        tags
        createdAt
        deviceId
        id
        name
        parentId
        s3Path
        updatedAt
        mimeType
        metadata {
          isPanoramic
          duration
          aggregationFolders {
            archiveId
            resultsId
          }
        }
      },
      nextToken {
        pk
        sk
        locationCreatedAt
        teamId
        teamIdType
        createdAt
        searchNameCreatedAt
        searchName
        deviceId
        type
      }
    }
  }`;
};

export const getModelsListQuery = (queryParams: LibraryListQueryParams) => {
  const queryParamsList = parseQueryParams(queryParams);
  return `query listModels {
    listModels(${queryParamsList.join(',')}) {
      items {
        id
        teamId
        metadata {
          bounds
        }
        name
        parentId
        s3Path
        mimeType
      },
      nextToken {
        pk
        sk
        locationCreatedAt
        teamId
        teamIdType
        createdAt
        searchNameCreatedAt
        searchName
        deviceId
        type
      }
    }
  }`;
};

export const getLibraryListSubfoldersQuery = (queryParams: LibraryListSubfoldersQueryParams) => {
  const locationParts = queryParams.currentLocation.split('/');
  const parentId = locationParts.length > 1 ? locationParts[locationParts.length - 1] : locationParts[0];

  return `query list {
    listSubfolders(parentId: "${parentId}") {
      items {
        id
        name
        location
      },
      nextToken {
        teamId
        parentIdTypeSk
      }
    }
  }`;
};

export const createNewFolder = (folderName: string, parentId: string, location?: string) => {
  const queryParams = location
    ? `name: "${folderName}", parentId: "${parentId}", location: "${location}"`
    : `name: "${folderName}", parentId: "${parentId}"`;
  return `mutation createNewFolder {
    saveFolder(${queryParams}) {
      location
      mimeType
      name
      parentId
      id
      teamId
      createdAt
      updatedAt
      type
    }
  }`;
};

export const getFolderLocation = (folderId: string) => {
  return `query FolderLocation {
    get(id: "${folderId}") {
      location
    }
  }`;
};

export const getLibraryItem = (id: string) => {
  return `query GetLibraryItem {
    get(item: {id: "${id}"}) {
        teamId
        location
        type
        tags
        createdAt
        deviceId
        id
        name
        parentId
        s3Path
        updatedAt
        mimeType,
        annotations,
        ${ITEM_METADATA}
    }
  }`;
};

export const getLibraryItemAnnotation = (id: string) => {
  return `query GetLibraryItem {
    get(item: {id: "${id}"}) {
        id
        parentId
        annotations
    }
  }`;
};

export const getBatchItemsQuery = (itemIds: LibraryItemMutateModel[], withAnnotations: boolean = false) => {
  const ids = [];
  for (const item of itemIds) {
    ids.push(`{id: "${item.id}"}`);
  }
  let ANNOTATION = '';
  if (withAnnotations) {
    ANNOTATION = 'annotations';
  }

  return `query GetBatchLibraryItem {
    getBatch(items: [${ids.join(',')}]) {
        teamId
        location
        type
        tags
        createdAt
        deviceId
        id
        name
        parentId
        s3Path
        updatedAt
        mimeType
        metadata {
          aggregationFolders {
            archiveId
            resultsId
          }
        }
        ${ANNOTATION}
    }
  }`;
};

export const getMoveItemsQuery = (to: LibraryItemMutateModel, moveItems: LibraryItemMutateModel[]) => {
  const moveItemsL = [];
  for (const item of moveItems) {
    moveItemsL.push(`{id: "${item.id}"}`);
  }

  return `mutation MoveItems {
    move (moveItems: [${moveItemsL.join(',')}], to: {id: "${to.id}"}) {
      id
      parentId
      location
    }
  }`;
};

export const getDeleteItemsQuery = (items: LibraryItemMutateModel[]) => {
  const deleteItems = [];
  for (const item of items) {
    deleteItems.push(`{id: "${item.id}"}`);
  }

  return `mutation DeleteItems {
    delete(deleteItems: [${deleteItems.join(',')}]) {
      deleteItems {
        id
      },
      ownerId
    }
  }`;
};

export const getRenameItemQuery = (item: LibraryItemMutateModel, newName: string) => {
  return `mutation RenameItems {
    rename(item: {id: "${item.id}"}, newName: "${newName}") {
      id
    }
  }`;
};

export const getBatchItemsQueryAnnotations = (itemIds: LibraryItemMutateModel[]) => {
  const ids = [];
  for (const item of itemIds) {
    ids.push(`{id: "${item.id}"}`);
  }
  return `query GetBatchLibraryItemAnnotations {
    getBatch(items: [${ids.join(',')}]) {
        id
        annotations
      }
    }`;
};

export const getLibraryItemMetadata = (id: string) => {
  return `query GetLibraryItemMetadata {
    get(item: {id: "${id}"}) {
        ${ITEM_METADATA}
    }
  }`;
};

export const getLibraryItemGPSMetadata = (id: string) => {
  return `query GetLibraryItemGPSMetadata {
    get(item: {id: "${id}"}) {
        id,
        metadata {
          gpslat,
          gpslng
        },
    }
  }`;
};

export const getModelDataQuery = (modelId: string): string => {
  return `query GetModelData {
    get(teamId: "item: {id: "${modelId}"}) {
        id
        name
        metadata {
          bounds
        }
        s3Path,
        mimeType,
        location
    }
  }`;
};

export const getOneImageFromFolderQuery = (queryParams: LibraryListQueryParams) => {
  const queryParamsList = parseQueryParams(queryParams);
  // TODO below query params should be used instead of current approach
  // this can be replaced as soon as backend will change indexes
  //     list(${queryParamsList.join(',')}, limit: 1, sort: desc, type: "I") {
  return `query list {
    list(${queryParamsList.join(',')}, sort: desc) {
      items {
        teamId
        location
        type
        id
        parentId
        mimeType,
        s3Path,
        deviceId
      }
    }
  }`;
};

export const getLibraryListAiVideoMenuQuery = (queryParams: LibraryListQueryParams) => {
  const queryParamsList = parseQueryParams(queryParams);
  return `query listAiItems {
    listAiItems(${queryParamsList.join(',')}) {
      items {
        tags
        id
        s3Path
        createdAt
      }
    }
  }`;
};

export const getLibraryItemListAiQuery = (id: string) => {
  return `query GetLibraryItemListAiQuery {
    get(item: {id: "${id}"}) {
        id,
        tags
        id
        s3Path
        createdAt
    }
  }`;
};

export const getUpdateLibraryItemsTag = (items: UpdateTagItemsQuery[]) => {
  const parsedItemsParams = [];
  for (const item of items) {
    parsedItemsParams.push(`{item: {id: "${item.item.id}"}, tags: "${item.tags}"}`);
  }

  return `mutation UpdateTagsList {
    updateTags(items: [${parsedItemsParams.join(',')}]) {
      id
    }
  }`;
};

export const getFileCountQuery = (payload: {id: LibraryItem['id']}[]) => {
  const ids = [];
  for (const item of payload) {
    ids.push(`{id: "${item.id}"}`);
  }

  return `query getCount {
    getCount(items: [${ids.join(',')}]) {
    total,
    image,
    video,
    folder,
    report,
    flightLog,
    document,
    model,
    undefined,
    }
  }`;
};

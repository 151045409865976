import {MenuItemTypeEnum} from '@app/core/models/gui/menu-item-type.enum';
import {MenuItem} from './menu-item';

/** Opens a link in a new tab */
export class NewTabMenuItem extends MenuItem {
  public href: string;
  public type: MenuItemTypeEnum = MenuItemTypeEnum.newTab;

  constructor(label: string, imagePath: string, href: string) {
    super(label, imagePath);
    this.href = href;
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {
  actionClearNotifications,
  actionHideNotification,
  actionLoadMoreNotifications,
  actionLoadNotifications,
  actionLoadNotificationsFailure,
  actionLoadNotificationsSuccess,
  actionNewNotification,
  actionRemoveNotification,
  actionSetNotificationAsRead,
  actionShowNotificationProgressUpdate,
  actionUnreadCountLoaded,
  actionUpdateNotification
} from './notifications.actions';
import {initialState, notificationModelEntityAdapter, NotificationsState} from './notifications.state';

const reducer = createReducer(
  initialState,
  on(actionLoadNotifications, state => ({
    ...state,
    isLoading: true,
    error: undefined
  })),
  on(actionLoadNotificationsSuccess, (state, action) =>
    notificationModelEntityAdapter.upsertMany(action.payload, {
      ...state,
      isLoading: false,
      error: undefined,
      unreadNotificationsCount: state.unreadNotificationsCount + action.payload.filter(notification => !notification.isRead).length
    })
  ),
  on(actionLoadNotificationsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  })),
  on(actionNewNotification, (state, action) =>
    notificationModelEntityAdapter.upsertOne(action.payload, {
      ...state,
      newNotification: action.payload,
      unreadNotificationsCount: !!action.payload.isRead
        ? state.unreadNotificationsCount
        : state.unreadNotificationsCount + 1
    })
  ),
  on(actionShowNotificationProgressUpdate, (state, action) => ({
    ...state,
    newNotification: action.payload
  })),
  on(actionHideNotification, (state, action) => ({
    ...state,
    newNotification: undefined
  })),
  on(actionUnreadCountLoaded, (state, action) => ({
    ...state,
    unreadNotificationsCount: action.payload
  })),
  on(actionUpdateNotification, (state, action) => notificationModelEntityAdapter.upsertOne(action.payload, state)),
  on(actionLoadMoreNotifications, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(actionSetNotificationAsRead, (state, action) =>
    notificationModelEntityAdapter.upsertOne(
      {...action.payload, isRead: true},
      {
        ...state,
        unreadNotificationsCount: state.unreadNotificationsCount > 0 ? state.unreadNotificationsCount - 1 : 0
      }
    )
  ),
  on(actionRemoveNotification, (state, action) => {
    let unreadNotifications = 0;

    if (!action.payload.isRead) {
      unreadNotifications = state.unreadNotificationsCount > 0 ? state.unreadNotificationsCount - 1 : 0;
    } else {
      unreadNotifications = state.unreadNotificationsCount;
    }

    return notificationModelEntityAdapter.removeOne(action.payload.sourceCreatedAt, {
      ...state,
      unreadNotificationsCount: unreadNotifications
    });
  }),
  on(actionClearNotifications, state =>
    notificationModelEntityAdapter.removeAll({...state, unreadNotificationsCount: 0})
  )
);

export function notificationsReducer(state: NotificationsState | undefined, action: Action) {
  return reducer(state, action);
}

import {routerNavigatedAction} from '@ngrx/router-store';
import {Action, createReducer, on} from '@ngrx/store';
import {routerRemoveRedirect, routerSaveRedirect, routerSavePreviousUrl} from './router.actions';

export interface RouterState {
  navigationId: number;
  state: {
    params: {[key: string]: string};
    queryParams: {[key: string]: string | string[]};
    url: string;
    data: {[key: string]: any};
  };
  url: string;
  previousUrl: string;
}

export const initialRouterState: RouterState = {
  url: null,
  state: null,
  navigationId: null,
  previousUrl: null
};

export const routerReducer = createReducer(
  initialRouterState,
  on(routerNavigatedAction, (state, {payload}) => {
    return {
      ...state,
      ...payload.routerState
    };
  }),
  on(routerSavePreviousUrl, (state, {url}) => ({
    ...state,
    previousUrl : url
  }))
);

export function routerReducers(state: RouterState | undefined, action: Action): RouterState {
  return routerReducer(state, action);
}

export interface RouterRedirectState {
  url: string;
  params: {[key: string]: string};
  queryParams: {[key: string]: string};
}

export const initialRouterRedirectState: RouterRedirectState = {
  url: null,
  params: null,
  queryParams: null
};

export const routerRedirectReducers = createReducer(
  initialRouterRedirectState,
  on(routerSaveRedirect, (state, redirectParams) => ({
    ...state,
    ...redirectParams
  })),
  on(routerRemoveRedirect, state => ({
    ...state,
    url: null,
    params: null,
    queryParams: null
  }))
);

export function routerRedirectReducer(state: RouterRedirectState | undefined, action: Action): RouterRedirectState {
  return routerRedirectReducers(state, action);
}

export enum BREADCRUMB {
  TEXT = 'text',
  MORE = 'more'
}

export interface Breadcrumb {
  id: string;
  name?: string;
  class: string;
  type: BREADCRUMB;
  index?: number;
  path?: string;
  tokens?: Breadcrumb[];
}

<div>
  <h1 mat-dialog-title>
    {{ 'profile.teams.renameDialog.title' | translate }}
  </h1>

  <div mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field class="rename-team-dialog__input" appearance="fill">
        <mat-label>{{ 'profile.teams.renameDialog.placeholderTeamName' | translate }}</mat-label>
        <input
          matInput
          data-test-id="rename-team-input"
          formControlName="name"
          placeholder="{{ 'profile.teams.renameDialog.placeholderTeamName' | translate }}"
          autocomplete="off"
        />
        <mat-error
          *ngIf="(this.form.controls.name.invalid && this.form.controls.name.touched) || this.form.controls.name.dirty"
        >
          {{ 'profile.teams.renameDialog.nameError' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      (click)="emitCancel()"
      tabindex="1"
      data-test-id="rename-team-cancel-button"
    >
      {{ 'common.cancel' | translate }}
    </button>
    <button
      color="primary"
      mat-raised-button
      tabindex="2"
      (click)="emitRename()"
      data-test-id="rename-team-name-button"
    >
      {{ 'common.rename' | translate }}
    </button>
  </div>
</div>

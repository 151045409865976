import {Directive, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appInputSize]'
})
export class InputSizeDirective implements OnInit, OnDestroy {
  @Input()
  appInputSize: UntypedFormControl;

  @Input()
  spanElement: HTMLElement;

  @HostBinding('style.width')
  width: string;

  private inputRefSub: Subscription;
  constructor() {}

  ngOnInit(): void {
    this.spanElement.innerHTML = this.appInputSize.value.replace(/\s/g, '&nbsp;');
    this.width = this.spanElement.offsetWidth + 'px';

    this.inputRefSub = this.appInputSize.valueChanges.subscribe(value => {
      this.spanElement.innerHTML = value.replace(/\s/g, '&nbsp;');
      this.width = this.spanElement.offsetWidth * 1.3 + 'px';
    });
  }

  ngOnDestroy(): void {
    if (this.inputRefSub) {
      this.inputRefSub.unsubscribe();
    }
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TotpGuard {
  public canActivate(): boolean {
    if (!this.authService.totpToken) {
      this.router.navigate(['/auth/setup-mfa']);
    }
    return true;
  }
  constructor(private authService: AuthService, private router: Router) {}
}

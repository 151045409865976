import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {DashboardService} from '@app/data/services/dashboard.service';

@Directive({
  selector: '[ulShowDashboard]',
  standalone: true
})
export class ShowDashboardDirective {
  @Input('ulShowDashboard') public dashboard: string;
  @Input() public layerName: string;

  constructor(private dashboardService: DashboardService) {}

  @HostListener('click', ['$event'])
  public async showDashboard() {
    const dashboard = this.dashboard;
    const layerName = this.layerName;
    this.dashboardService.setDashboardId(dashboard);
    if (layerName) {
      this.dashboardService.setLgaName(layerName);
    }

    await this.dashboardService.drawerRef.open();
  }
}

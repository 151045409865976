import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MissionApiService} from '../services/mission-api.service';
import {Mission, MissionSettingsService} from '../services/mission-settings.service';
import {of, switchMap, take, tap, zip} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MissionManagerService} from '../services/mission-manager.service';
import {cloneDeep} from 'lodash';

@Directive({
  selector: '[ulSaveRoute]'
})
export class SaveRouteDirective implements AfterViewInit {
  @Input('ulSaveRoute') public selectedWaypointIndex: number;
  @Output() public missionUpdated: EventEmitter<void> = new EventEmitter();
  @HostListener('click', ['$event'])
  public onSaveChanges() {
    this.missionSettingsService.setIsUpdatingMission(true);
    this.missionSettingsService.editingMission
      .pipe(
        take(1),
        switchMap(editedMission => {
          const mission = editedMission.mission;
          return this.missionApiService.updateMission(mission.id, mission).pipe(
            take(1),
            switchMap(updatedMission =>
              zip(of(updatedMission), this.translateService.get('atlas.mission.changesSaved').pipe(take(1)))
            ),
            tap(([updatedMission, translation]: [Mission, string]) => {
              const newMission = {...mission, updatedAt: updatedMission.updatedAt};
              this.missionManagerService.updateMissionUi(newMission);
              this.missionSettingsService.setMission(newMission);
              this.missionSettingsService.selectedWaypointIndex = -1;
              this.missionUpdated.emit();
              this.missionSettingsService.editSave();
              if (this.selectedWaypointIndex !== -1) {
                this.missionSettingsService.selectRoutePoint(this.selectedWaypointIndex);
              }
              this.missionSettingsService.firstMissionState = cloneDeep(newMission);
              this.missionSettingsService.undoStack.next([cloneDeep(newMission)]);
              this.missionSettingsService.redoStack.next([]);
              this.snackBar.open(translation, null, {panelClass: 'mission-uploaded', duration: 3000});
              this.missionSettingsService.setIsUpdatingMission(false);
            })
          );
        })
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    this.missionSettingsService.undoStack.subscribe(stack => {
      this.setDisabledButton(stack);
    });
  }

  private setDisabledButton(stack: Mission[]): void {
    if (stack.length === 1) {
      this.elementRef.nativeElement.classList.add('mat-button-disabled');
      this.elementRef.nativeElement.setAttribute('disabled', 'true');
    } else {
      this.elementRef.nativeElement.classList.remove('mat-button-disabled');
      this.elementRef.nativeElement.removeAttribute('disabled');
    }
  }

  constructor(
    private missionApiService: MissionApiService,
    private missionSettingsService: MissionSettingsService,
    private missionManagerService: MissionManagerService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private elementRef: ElementRef
  ) {}
}

import {Device} from '@app/core/models/api/user-device.model';
import {Team} from '@app/profile/models/team.model';
import { AutoflyScope } from '@app/flights/services/iot-topic.service';

export class CompanyModel {
  id: string;
  owner: string;
  name: string;
  logo: string;
  website: string;
  industry: string;
  createdAt: number;
  updatedAt: number;
  slug?: string;
  isPublicWatchPageEnabled?: boolean;
  phone?: string;
  aiApps?: any; //TODO
  aiAppsPartial?: any; //TODO
  mfaRequired?: boolean;
  dateFormat?: string;
  timezone?: string;
  useUserTask?: boolean;
  isStreamProxyEnabled?: boolean;
  autoflyFullAccess?: any;
  autoflyScope?: AutoflyScope;
}

export class UserCompanyModel {
  companyId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface CompanyRegisterResponse {
  newCredentials: any;
  user: any;
  team: Team;
  teamUser: any;
  company: any;
  device: Device;
}

import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@app/auth/services/auth.service';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {actionSetupPhoneMFA} from '../sign-in/sign-in.actions';
import {Router} from '@angular/router';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {MFA_SETUP_STATE} from '@app/auth/models/auth.models';

@Component({
  selector: 'unleash-setup-mobile-number',
  templateUrl: './setup-mobile-number.component.html',
  styleUrls: ['./setup-mobile-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetupMobileNumberComponent implements AfterViewInit, OnDestroy {
  public setupPhoneForm: FormGroup = this.fb.group({
    phoneNumber: [null, [Validators.required, Validators.pattern(/^\+[0-9\s.-]{6,15}$/)]]
  });
  public isInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPhoneValid: BehaviorSubject<boolean> = this.authService.isPhoneValid;
  public isPhoneValidSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private store: Store,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnDestroy(): void {
    if (this.isPhoneValidSub) {
      this.isPhoneValidSub.unsubscribe();
      this.isPhoneValidSub = null;
    }
  }

  public ngAfterViewInit() {
    this.isPhoneValidSub = this.isPhoneValid.subscribe(isValid => {
      if (!isValid) {
        this.isInProgress.next(false);
        this.setupPhoneForm.controls.phoneNumber.setErrors({invalidCode: true});
      }
    });
  }

  public continue(): void {
    if (this.setupPhoneForm.valid) {
      this.isInProgress.next(true);
      this.store.dispatch(
        actionSetupPhoneMFA({
          phoneNumber: this.setupPhoneForm.value.phoneNumber
        })
      );
    }
  }

  public back(): void {
    this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.SETUP);
    this.router.navigate(['auth/setup-mfa']);
  }
}

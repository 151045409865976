import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Router} from '@angular/router';

@Directive({
  selector: '[ulGoToPhoto]',
  standalone: true
})
export class GoToPhotoDirective {
  @Input('ulGoToPhoto') public asset: AtlasAssetModel;

  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  public goToPhotos(): void {
    const asset = this.asset;
    const parentId: AtlasAssetModel['parentId'] = asset.parentId;
    this.router.navigateByUrl(`secure/library/${parentId}`);
  }
}

<ng-container *ngIf="editMode; else displayNameTemplate">
  <form class="rule-edit" [formGroup]="form">
    <input
      class="rule-edit__input"
      style.width="20px"
      [spanElement]="spanRef"
      [appInputSize]="form.controls.name"
      type="text"
      formControlName="name"
    />
    <span class="rule-edit__input--hidden" #spanRef></span>
    <button mat-icon-button (click)="emitDone()">
      <mat-icon aria-label="done" color="green">done</mat-icon>
    </button>
  </form>
</ng-container>
<ng-template #displayNameTemplate>
  <span class="rule-edit__name">{{ form.value.name }}</span>
  <button class="rule-edit__button" (click)="emitEdit()" mat-icon-button>
    <mat-icon aria-label="done">edit</mat-icon>
  </button>
</ng-template>

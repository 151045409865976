import {forgotPasswordStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {ForgotPasswordFormState} from './forgot-password.reducers';

export const selectForgotPasswordFormValue = createSelector(
  forgotPasswordStateSelector,
  (state: ForgotPasswordFormState) => state.form
);

export const selectForgotPasswordFormInProgress = createSelector(
  forgotPasswordStateSelector,
  (state: ForgotPasswordFormState) => state.inProgress
);

export const selectForgotPasswordFormError = createSelector(
  forgotPasswordStateSelector,
  (state: ForgotPasswordFormState) => state.error
);

export const selectSignInEmail = createSelector(
  forgotPasswordStateSelector,
  (state: ForgotPasswordFormState) => state.form.email
);

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {StreamPlayer, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {Addon} from '@app/store/addon/models/addon';

import {publishLast, refCount, share} from 'rxjs/operators';
import {BatchJobQueryParams} from '../models/ai.model';
import {LibraryItem} from '../models/folder-file.model';
import {ModelAction, ModelRequestOptions} from '../models/models.model';
import {ReportModel} from '@app/analysis/models/analysis.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LibraryApiService {
  constructor(
    private apiGateway: ApiGateway,
    private unleashAnalytics: UnleashAnalyticsService,
    private http: HttpClient
  ) {}

  createModel(libraryItemIds: string[], options: ModelRequestOptions) {
    this.unleashAnalytics.logEvent(EVENTS.SUBMIT_JOB, {
      type: options.is3D ? ModelAction.modelling_2d : ModelAction.modelling_3d
    });
    return this.apiGateway
      .post(
        'jobs/model',
        {},
        {
          files: libraryItemIds,
          options
        }
      )
      .pipe(publishLast(), refCount());
  }

  recognise(payload: BatchJobQueryParams) {
    this.unleashAnalytics.logEvent(EVENTS.SUBMIT_JOB, {
      type: ModelAction.object_detection,
      addonId: payload.options.addonId,
      deviceId: payload.deviceId
    });

    return this.apiGateway.post('jobs/computer-vision', {}, payload).pipe(publishLast(), refCount());
  }

  startLiveAi(device: UserDeviceJoined, modelId: Addon['id'], streamKey: string) {
    this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER_AI, modelId);
    const aiStreamEndpoint = `stream/ai/${device.id}/${modelId}/${streamKey}`;
    const data = device.player === StreamPlayer.WEBRTC ? {webrtc: true} : {};
    const observable = this.apiGateway.post(aiStreamEndpoint, {}, data).pipe(share());
    observable.subscribe(console.info, console.error);
    return observable;
  }

  download(libraryItems: LibraryItem[], currentPath: string[]) {
    const firstItem = libraryItems[0];
    const data = {
      teamId: firstItem.teamId,
      exportQuery: {
        libraryLocation: currentPath.length > 0 ? currentPath.join('/') : firstItem.teamId,
        filterByIds: libraryItems.map((item: LibraryItem) => item.id)
      }
    };
    this.unleashAnalytics.logEvent(EVENTS.LIBRARY_MEDIA_DOWNLOAD, data);
    return this.apiGateway.post('library/download', {}, data).pipe(publishLast(), refCount());
  }

  downloadFromGoogleDrive(parentId: string, shareableLink: string) {
    return this.apiGateway.post('library/import/gdrive', {}, {parentId, shareableLink});
  }

  downloadFromSkydio(parentId: string) {
    return this.apiGateway.post('library/import/skydio', {}, {parentId});
  }

  importFromS3(parentId: string, s3Uri: string) {
    return this.apiGateway.post('library/import/s3', {}, {parentId, s3Uri});
  }

  exportModels(payload) {
    return this.apiGateway.post('library/download/models', {}, payload);
  }

  getReport(libraryItemId: string): Observable<ReportModel[]> {
    return this.apiGateway.get('report', {libraryIds: JSON.stringify([libraryItemId])});
  }
}

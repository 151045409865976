import {LibraryItem} from '@app/library/models/folder-file.model';
import {createAction, props} from '@ngrx/store';

export enum AssingToAssetsActionTypes {
  OpenAssingToAssetsDialog = '[Library] Open assing to assets dialog'
}

export const openAssingToAssetsDialog = createAction(
  AssingToAssetsActionTypes.OpenAssingToAssetsDialog,
  props<{payload: {libraryItem: LibraryItem}}>()
);

import {LibraryFilter} from '@app/library/models/library-filter.model';
import {
  addToArray,
  deleteLastArrayItem,
  getArrayUntilGivenIndex,
  replaceLastItemArray
} from '@app/shared/store-helpers/array-store-operations';
import {createReducer, on} from '@ngrx/store';
import * as fromMoveItemDialog from '../components/move-item-dialog/store/move-item-dialog.reducer';
import {AddonCategoriesFilters, Severities} from '../models/addon-categories-filters';
import {LibraryItem, LibraryViewMode} from '../models/folder-file.model';
import {
  AnnotationsSummaryChip,
  AnnotationsSummaryData,
  LibraryApiMoveResponseModel,
  NextToken
} from '../models/library-api-list-response.model';
import {AnnotationsModel} from '../models/library-list-child-ids-response.model';
import {LIBRARY_ITEM_TYPE_MODEL} from '../models/mime-type.model';
import {ModelData} from '../models/models.model';
import {Tag, UpdateTagItemsQuery} from '../models/tag.model';
import * as annotationsSummaryActions from './annotations-summary/annotations-summary.actions';
import * as actions from './library.actions';

export const libraryFeatureKey = 'library';
export const libraryStoreKey = 'library';

export interface State {
  library: LibraryState;
  moveItemDialog: fromMoveItemDialog.State;
}

export const initialLibraryState: LibraryState = {
  libraryItems: {},
  listView: {
    itemsIds: [],
    nextToken: undefined,
    prevToken: undefined
  },
  specialLibraryItems: {},
  isLoading: false,
  isLoadingMetadata: false,
  path: [],
  rootFolderId: '',
  currentParentId: '',
  tags: {},
  availableModels: [],
  selectedModel: null,
  selectedLibraryItem: null,
  isMapLoading: false,
  aiVideoMenu: [],
  isLoadingAiVideoMenu: false,
  hasAnalyzeOption: false,
  libraryItemForSidebar: null,
  annotationsSummaryItemsIds: [],
  annotationsSummaryData: [],
  currentAddonCategories: [],
  annotationsSummaryActiveTags: [],
  activeAnnotationsSummaryData: [],
  annotationsSummaryChips: [],
  hasInitialAnnotationsSummaryData: false,
  severityHeaderButtons: null,
  isLoadingAnnotationsSummary: false,
  annotationsSummaryHasImages: true,
  tagsLoading: false,
  filters: {},
  libraryItemFromContextMenu: '',
  viewMode: LibraryViewMode.grid
};

export const initialState: State = {
  library: initialLibraryState,
  moveItemDialog: fromMoveItemDialog.initialState
};

export interface LibraryState {
  libraryItems: {[key: string]: LibraryItem};
  specialLibraryItems: {
    assetAssign?: {[key: string]: LibraryItem['id'][]};
  };
  listView: {
    itemsIds: string[];
    nextToken: NextToken | undefined;
    prevToken: NextToken | undefined;
  };
  isLoading: boolean;
  isLoadingMetadata?: boolean;
  path: string[];
  rootFolderId: string;
  currentParentId: string;
  tags: {[key: string]: Tag};
  availableModels: LibraryItem[];
  selectedModel: ModelData;
  selectedLibraryItem: LibraryItem;
  isMapLoading: boolean;
  hasAnalyzeOption: boolean;
  aiVideoMenu: Partial<LibraryItem>[];
  isLoadingAiVideoMenu: boolean;
  libraryItemForSidebar?: LibraryItem;
  annotationsSummaryItemsIds: string[];
  annotationsSummaryData: AnnotationsSummaryData[];
  annotationsSummaryChips: AnnotationsSummaryChip[];
  currentAddonCategories: AddonCategoriesFilters[];
  activeAnnotationsSummaryData: AnnotationsSummaryData[];
  annotationsSummaryActiveTags: Tag[];
  severityHeaderButtons: Severities;
  hasInitialAnnotationsSummaryData: boolean;
  isLoadingAnnotationsSummary: boolean;
  annotationsSummaryHasImages: boolean;
  tagsLoading: boolean;
  filters: {[key: string]: Partial<LibraryFilter>};
  libraryItemFromContextMenu: string;
  viewMode: LibraryViewMode;
}

const initialHeaderSeverityButtons: Severities = {
  5: {level: 5, isActive: false},
  4: {level: 4, isActive: false},
  3: {level: 3, isActive: false},
  2: {level: 2, isActive: false},
  1: {level: 1, isActive: false},
  0: {level: 0, isActive: false}
};

export const reducer = createReducer(
  initialLibraryState,
  on(
    actions.renameLibraryItemSuccess,
    (state: LibraryState, action: {payload: {updatedItemId: string; newName: string}}) => ({
      ...state,
      libraryItems: {
        ...state.libraryItems,
        [action.payload.updatedItemId]: {
          ...state.libraryItems[action.payload.updatedItemId],
          name: action.payload.newName
        }
      }
    })
  ),
  on(
    actions.appendLoadLibraryItemsSuccess,
    (
      state: LibraryState,
      action: {
        payload: {
          libraryItems: {[key: string]: LibraryItem};
          nextToken: NextToken;
        };
      }
    ) => {
      const newListView = {...state.listView};
      newListView.prevToken = state.listView.nextToken ? {...state.listView.nextToken} : null;
      newListView.nextToken = action.payload.nextToken ? {...action.payload.nextToken} : null;
      newListView.itemsIds = [...state.listView.itemsIds, ...Object.keys(action.payload.libraryItems)];
      return {
        ...state,
        listView: newListView,
        libraryItems: {...state.libraryItems, ...action.payload.libraryItems}
      };
    }
  ),
  on(
    actions.saveLibraryItems,
    (
      state: LibraryState,
      action: {
        payload: {
          libraryItems: {[key: string]: LibraryItem};
          nextToken: NextToken;
          specialLibraryItems?: LibraryItem[];
        };
      }
    ) => {
      const currentFolderPath = state.currentParentId;
      let specialListView = [];

      const hasFilters = hasLibraryFilters(state, currentFolderPath);

      if (
        action.payload.specialLibraryItems ||
        (state.specialLibraryItems.assetAssign?.[currentFolderPath] && !hasFilters)
      ) {
        specialListView =
          action.payload.specialLibraryItems.map(libraryItem => libraryItem.id) ||
          state.specialLibraryItems.assetAssign[currentFolderPath];
      }

      const newListView = {...state.listView};
      newListView.prevToken = {...state.listView.nextToken};
      newListView.nextToken = {...action.payload.nextToken};
      newListView.itemsIds = [...Object.keys(action.payload.libraryItems), ...specialListView];

      const libraryItems = {...state.libraryItems};
      Object.values(action.payload.libraryItems).forEach((libraryItem: LibraryItem) => {
        libraryItems[libraryItem.id] = {...libraryItems[libraryItem.id], ...libraryItem};
      });

      if (specialListView.length > 0) {
        action.payload.specialLibraryItems.forEach((specialLibraryItem: LibraryItem) => {
          libraryItems[specialLibraryItem.id] = specialLibraryItem;
        });
      }

      return {
        ...state,
        listView: newListView,
        libraryItems
      };
    }
  ),
  on(actions.addLibraryItem, (state: LibraryState, action: {payload: {libraryItem: LibraryItem}}) => {
    const newListView = {...state.listView};
    const currentFolderPath = state.currentParentId;
    if (isFromCurrentFolder(currentFolderPath, action.payload.libraryItem.location)) {
      newListView.itemsIds = [...newListView.itemsIds, action.payload.libraryItem.id];
    }
    return {
      ...state,
      listView: {
        ...newListView
      },
      libraryItems: {
        ...state.libraryItems,
        [action.payload.libraryItem.id]: {
          ...state.libraryItems[action.payload.libraryItem.id],
          ...action.payload.libraryItem
        }
      }
    };
  }),
  on(
    actions.addLibraryItems,
    (state: LibraryState, action: {payload: {libraryItems: {[key: string]: LibraryItem}}}) => {
      const currentFolderPath = state.currentParentId;
      let specialListView = [];
      const hasFilters = hasLibraryFilters(state, currentFolderPath);

      if (state.specialLibraryItems.assetAssign?.[currentFolderPath] && !hasFilters) {
        specialListView = state.specialLibraryItems.assetAssign[currentFolderPath];
      }

      return {
        ...state,
        listView: {
          ...state.listView,
          itemsIds: [
            ...state.listView.itemsIds.filter(item => !specialListView.includes(item)),
            ...Object.values(action.payload.libraryItems)
              .filter((item: LibraryItem) => isFromCurrentFolder(currentFolderPath, item.location))
              .map((item: LibraryItem) => item.id),
            ...specialListView
          ]
        },
        libraryItems: {
          ...state.libraryItems,
          ...action.payload.libraryItems
        }
      };
    }
  ),
  on(
    actions.saveLibraryItemsSilently,
    (state: LibraryState, action: {payload: {libraryItems: {[key: string]: LibraryItem}}}) => ({
      ...state,
      libraryItems: {...state.libraryItems, ...action.payload.libraryItems},
      isLoading: false
    })
  ),
  on(actions.getAvailableModelsSuccess, (state: LibraryState, action: {availableModels: LibraryItem[]}) => ({
    ...state,
    availableModels: action.availableModels
  })),
  on(actions.clearAvailableModels, (state: LibraryState) => ({
    ...state,
    availableModels: []
  })),
  on(actions.getModelData, (state: LibraryState) => ({
    ...state,
    isLoading: true
  })),
  on(actions.getModelDataSuccess, (state: LibraryState, {modelData}: {modelData: ModelData}) => ({
    ...state,
    selectedModel: modelData,
    isLoading: false
  })),
  on(actions.displayLoader, (state: LibraryState) => ({
    ...state,
    isLoading: true
  })),
  on(actions.hideLoader, (state: LibraryState) => ({
    ...state,
    isLoading: false
  })),
  on(actions.saveFolderPath, (state: LibraryState, action: {payload: {path: string[]}}) => {
    const path = action.payload.path.map(item => {
      if (item.indexOf('archive') > -1) {
        const parts = item.split('#');
        return parts[1];
      }
      return item;
    });
    return {
      ...state,
      path
    };
  }),
  on(actions.deleteLastFolderPath, (state: LibraryState) => ({
    ...state,
    path: deleteLastArrayItem(state.path)
  })),
  on(actions.addFolderPath, (state: LibraryState, action: {payload: {pathId: string}}) => {
    const pathExistsIndex = state.path.findIndex((statePath: string) => statePath === action.payload.pathId);
    let path = [];
    if (pathExistsIndex >= 0) {
      const partialPath = getArrayUntilGivenIndex(pathExistsIndex, state.path);
      path = addToArray(action.payload.pathId, partialPath);
    } else {
      path = state.isLoading
        ? replaceLastItemArray(action.payload.pathId, state.path)
        : addToArray(action.payload.pathId, state.path);
    }
    return {...state, path};
  }),
  on(actions.deleteUntilFolderPathIndex, (state: LibraryState, action: {payload: {pathIndex: number}}) => ({
    ...state,
    path: getArrayUntilGivenIndex(action.payload.pathIndex, state.path)
  })),
  on(actions.clearFolderPath, (state: LibraryState) => ({
    ...state,
    path: []
  })),
  on(actions.markAsSelectLibraryItem, (state: LibraryState, action: {payload: {libraryItemId: string}}) => ({
    ...state,
    libraryItems: {
      ...state.libraryItems,
      [action.payload.libraryItemId]: {
        ...state.libraryItems[action.payload.libraryItemId],
        isSelected: true
      }
    },
    annotationsSummaryItemsIds: state.annotationsSummaryItemsIds.concat([action.payload.libraryItemId])
  })),
  on(actions.markAsUnselectLibraryItem, (state: LibraryState, action: {payload: {libraryItemId: string}}) => ({
    ...state,
    libraryItems: {
      ...state.libraryItems,
      [action.payload.libraryItemId]: {
        ...state.libraryItems[action.payload.libraryItemId],
        isSelected: false
      }
    },
    annotationsSummaryItemsIds: state.annotationsSummaryItemsIds.filter(
      (id: string) => action.payload.libraryItemId !== id
    )
  })),
  on(actions.moveLibraryItemSuccess, (state: LibraryState, action: {payload: LibraryApiMoveResponseModel}) => {
    const libraryItems = {...state.libraryItems};
    const listView = {...state.listView};

    for (const itemId of action.payload.move) {
      if (itemId.id in libraryItems) {
        libraryItems[itemId.id] = {
          ...state.libraryItems[itemId.id],
          ...itemId
        };
      }
    }
    const movedIds = action.payload.move.map((item: {id: string; location: string; parentId: string}) => item.id);
    const newMovedItemsIds = listView.itemsIds.filter((item: string) => !movedIds.includes(item));

    listView.itemsIds = newMovedItemsIds;
    return {
      ...state,
      libraryItems: {...state.libraryItems, ...libraryItems},
      listView
    };
  }),
  on(
    actions.unselectAllLibraryItemsSuccess,
    (state: LibraryState, action: {payload: {selectedItems: LibraryItem[]}}) => {
      const selectedItems = action.payload.selectedItems.reduce(
        (acc: {[key: string]: LibraryItem}, item: LibraryItem) => {
          acc[item.id] = {...item, isSelected: false};
          return acc;
        },
        {}
      );

      return {
        ...state,
        libraryItems: {...state.libraryItems, ...selectedItems}
      };
    }
  ),
  on(
    actions.unselectAllLibraryItemsFromCurrentViewSuccess,
    (state: LibraryState, action: {payload: {selectedItems: LibraryItem[]}}) => {
      const updatedLibraryItems: {[key: string]: LibraryItem} = {
        ...state.libraryItems
      };
      const selectedItems: LibraryItem[] = action.payload.selectedItems;
      for (const index in selectedItems) {
        const selectedItemId = selectedItems[index].id;
        updatedLibraryItems[selectedItemId] = {...updatedLibraryItems[selectedItemId], isSelected: false};
      }
      return {
        ...state,
        libraryItems: updatedLibraryItems,
        annotationsSummaryItemsIds: []
      };
    }
  ),
  on(actions.selectAllLibraryItemsFromCurrentView, (state: LibraryState) => {
    const updatedLibraryItems: {[key: string]: LibraryItem} = {
      ...state.libraryItems
    };
    const currentViewIds: string[] = state.listView.itemsIds;
    currentViewIds.forEach((id: string): void => {
      updatedLibraryItems[id] = {...updatedLibraryItems[id], isSelected: true};
    });
    return {
      ...state,
      libraryItems: updatedLibraryItems,
      annotationsSummaryItemsIds: state.listView.itemsIds
    };
  }),
  on(
    actions.updateAnnotationLibraryItem,
    (state: LibraryState, {payload}: {payload: {libraryItemId: string; annotations: AnnotationsModel}}) => ({
      ...state,
      libraryItems: {
        ...state.libraryItems,
        [payload.libraryItemId]: {
          ...state.libraryItems[payload.libraryItemId],
          annotations: payload.annotations
        }
      }
    })
  ),

  on(actions.createNewTagSuccess, (state: LibraryState, {tag}: {tag: Tag}) => {
    const tags = {...state.tags};
    delete tags['test-tag'];

    return {
      ...state,
      tags: {...tags, [tag.id]: tag},
      tagsLoading: false
    };
  }),
  on(
    actions.renameTagSuccess,
    actions.updateColorTagSuccess,
    (state: LibraryState, {payload}: {payload: {tag: Tag}}) => ({
      ...state,
      tags: payload.tag.id ? {...state.tags, [payload.tag.id]: payload.tag} : {...state.tags},
      tagsLoading: false
    })
  ),
  on(actions.removeLibraryItemFromStore, (state: LibraryState, {payload}: {payload: {libraryItemsIds: string[]}}) => {
    const newLibraryItems = {...state.libraryItems};

    for (const libraryItemIdToDelete of payload.libraryItemsIds) {
      delete newLibraryItems[libraryItemIdToDelete];
    }

    const newItemsIds = state.listView.itemsIds.filter((id: string) => {
      return payload.libraryItemsIds.indexOf(id) === -1;
    });

    return {
      ...state,
      libraryItems: newLibraryItems,
      listView: {...state.listView, itemsIds: newItemsIds}
    };
  }),
  on(actions.clearLibraryStore, () => {
    return {...initialLibraryState};
  }),
  on(
    actions.saveLibraryItemMetadata,
    (state: LibraryState, {payload}: {payload: {libraryItemId: string; metadata: LibraryItem['metadata']}}) => {
      const itemUpdated = {
        ...state.libraryItems[payload.libraryItemId],
        metadata: payload.metadata
      };
      const updatedLibraryItems = {
        ...state.libraryItems,
        [payload.libraryItemId]: itemUpdated
      };
      return {
        ...state,
        libraryItems: updatedLibraryItems
      };
    }
  ),
  on(actions.updateIsLoadingMetadata, (state: LibraryState) => {
    return {
      ...state,
      isLoadingMetadata: !state.isLoadingMetadata
    };
  }),
  on(
    actions.loadLibraryItemGPSMetadataSilentlySuccess,
    (
      state: LibraryState,
      {
        payload
      }: {
        payload: {
          libraryItemId: string;
          metadata: {gpslat: number; gpslng: number};
        };
      }
    ) => {
      const libraryItemToUpdate: LibraryItem = {
        ...state.libraryItems[payload.libraryItemId],
        metadata: {
          ...state.libraryItems[payload.libraryItemId]?.metadata,
          gpslat: payload.metadata.gpslat,
          gpslng: payload.metadata.gpslng
        }
      };
      return {
        ...state,
        libraryItems: {
          ...state.libraryItems,
          [payload.libraryItemId]: libraryItemToUpdate
        }
      };
    }
  ),
  on(
    actions.saveCurrentParentId,
    (
      state: LibraryState,
      action: {
        payload: {currentParentId: string};
      }
    ) => ({...state, currentParentId: action.payload.currentParentId})
  ),
  on(
    actions.hasAnalyzeOptionSuccess,
    (
      state: LibraryState,
      action: {
        payload: {libraryItems: LibraryItem[]};
      }
    ) => {
      const selectedItems = action.payload.libraryItems;
      const acceptedTypes = [
        LIBRARY_ITEM_TYPE_MODEL.FOLDER,
        LIBRARY_ITEM_TYPE_MODEL.IMAGE,
        LIBRARY_ITEM_TYPE_MODEL.VIDEO
      ];

      const hasAcceptedTypes: boolean = selectedItems.some(
        (item: LibraryItem) => !item.metadata?.isPanoramic && acceptedTypes.includes(item.type)
      );

      if (!hasAcceptedTypes) {
        return {...state, hasAnalyzeOption: false};
      }

      return {...state, hasAnalyzeOption: true};
    }
  ),
  on(actions.requestAiVideo, (state: LibraryState) => ({
    ...state,
    aiVideoMenu: [],
    isLoadingAiVideoMenu: true
  })),
  on(
    actions.requestAiVideoSuccess,
    (state: LibraryState, action: {payload: {libraryItems: Partial<LibraryItem>[]}}) => ({
      ...state,
      aiVideoMenu: action.payload.libraryItems,
      isLoadingAiVideoMenu: false
    })
  ),
  on(
    actions.switchVideo,
    (
      state: LibraryState,
      action: {
        payload: {
          libraryItemId: LibraryItem['id'];
          aiVideo: LibraryItem;
        };
      }
    ) => {
      const libraryItem = {...state.libraryItems[action.payload.libraryItemId]};
      libraryItem.s3Path = action.payload.aiVideo.s3Path;

      return {
        ...state,
        libraryItems: {...state.libraryItems, [libraryItem.id]: libraryItem}
      };
    }
  ),
  on(actions.loadLibraryTagsSuccess, (state: LibraryState, {tags}: {tags: Tag[]}) => ({
    ...state,
    tags: tags.reduce(
      (result: {[key: string]: Tag}, tag: Tag) => ({
        ...result,
        [tag['id']]: tag
      }),
      {}
    )
  })),
  on(actions.updateLibraryItemsTagSuccess, (state: LibraryState, {items}: {items: UpdateTagItemsQuery[]}) => {
    const updatedItems = {};

    for (const item of items) {
      updatedItems[item.item.id] = {
        ...state.libraryItems[item.item.id],
        tags: item.tags
      };
    }

    return {
      ...state,
      libraryItems: {
        ...state.libraryItems,
        ...updatedItems
      },
      libraryItemForSidebar: state.libraryItemForSidebar?.id ? updatedItems[state.libraryItemForSidebar.id] : null
    };
  }),
  on(actions.showLibraryItemTags, (state: LibraryState, actions: {payload: {libraryItemId: string}}) => {
    const libraryItem: LibraryItem = {
      ...state.libraryItems[actions.payload.libraryItemId],
      isVisibleInSidebar: true
    };

    return {
      ...state,
      libraryItems: {
        ...state.libraryItems,
        [actions.payload.libraryItemId]: libraryItem
      },
      libraryItemForSidebar: libraryItem
    };
  }),
  on(actions.clearLibraryItemTags, (state: LibraryState) => {
    const libraryItemInSidebar: LibraryItem = {...state.libraryItemForSidebar};
    libraryItemInSidebar.isVisibleInSidebar = false;

    return {
      ...state,
      libraryItems: {
        ...state.libraryItems,
        [libraryItemInSidebar.id]: libraryItemInSidebar
      },
      libraryItemForSidebar: null
    };
  }),
  on(
    annotationsSummaryActions.openAnnotationsSummary,
    (state: LibraryState, action: {payload: {libraryItem: LibraryItem}}) => {
      if (action.payload.libraryItem) {
        return {
          ...state,
          annotationsSummaryItemsIds: [action.payload.libraryItem.id]
        };
      }

      return {
        ...state
      };
    }
  ),
  on(
    annotationsSummaryActions.getAnnotationsSummaryData,
    (
      state: LibraryState,
      {
        libraryItemIdArr
      }: {
        libraryItemIdArr: string[];
      }
    ) => ({
      ...state,
      isLoadingAnnotationsSummary: true,
      annotationsSummaryItemsIds: libraryItemIdArr
    })
  ),
  on(
    annotationsSummaryActions.getAnnotationsSummaryDataSuccess,
    annotationsSummaryActions.getAnnotationsSummaryDataFail,
    (state: LibraryState) => ({
      ...state,
      isLoadingAnnotationsSummary: false
    })
  ),
  on(annotationsSummaryActions.getAnnotationsSummaryDataFail, (state: LibraryState) => ({
    ...state,
    annotationsSummaryHasImages: false
  })),
  on(annotationsSummaryActions.getAnnotationsSummaryDataSuccess, (state: LibraryState, action) => {
    const payload = Object.keys(action).filter(item => item !== 'type');
    const parsedAnnotations = payload.map(key => {
      return {
        ...action[key],
        tags: action[key].tags?.split('#')
      };
    });
    return {
      ...state,
      annotationsSummaryData: parsedAnnotations,
      annotationsSummaryHasImages: true
    };
  }),
  on(annotationsSummaryActions.clearAnnotationsSummaryData, (state: LibraryState) => ({
    ...state,
    annotationsSummaryData: [],
    annotationsSummaryItemsIds: []
  })),
  on(
    actions.loadAnnotationsFromLibraryItemFromViewerSuccess,
    (state: LibraryState, {payload}: {payload: {libraryItem: LibraryItem}}) => {
      return {
        ...state,
        libraryItems: {
          ...state.libraryItems,
          [payload.libraryItem.id]: {
            ...state.libraryItems[payload.libraryItem.id],
            annotations: payload.libraryItem.annotations
          }
        }
      };
    }
  ),
  on(actions.clearListView, (state: LibraryState) => {
    return {
      ...state,
      listView: {...state.listView, itemsIds: []}
    };
  }),
  on(actions.deleteTagSuccess, (state: LibraryState, {payload}: {payload: {tag: Tag}}) => {
    const {tag} = payload;
    const newTags = {...state.tags};
    delete newTags[tag.id];

    return {
      ...state,
      tags: newTags,
      tagsLoading: false
    };
  }),
  on(actions.tagLoading, (state: LibraryState) => ({
    ...state,
    tagsLoading: true
  })),
  on(
    actions.saveFilterParamsSuccess,
    (
      state: LibraryState,
      {payload}: {payload: {libraryFilters: Partial<LibraryFilter>; libraryItemId: LibraryItem['id']}}
    ) => ({
      ...state,
      filters: {...state.filters, [payload.libraryItemId]: payload.libraryFilters}
    })
  ),
  on(actions.clearLibraryFilters, (state: LibraryState) => ({...state, filters: {}})),
  on(actions.clearOtherLibraryFilters, (state: LibraryState) => {
    return {...state, filters: {['undefined']: state.filters['undefined']}};
  }),
  on(
    annotationsSummaryActions.saveCurrentAddonCategories,
    (state: LibraryState, {categories}: {categories: AddonCategoriesFilters[]}) => ({
      ...state,
      currentAddonCategories: categories
    })
  ),
  on(annotationsSummaryActions.saveActiveTags, (state: LibraryState, {tags}: {tags: Tag[]}) => ({
    ...state,
    annotationsSummaryActiveTags: tags
  })),
  on(
    annotationsSummaryActions.updateCheckedAddonCategory,
    (state: LibraryState, {categoryIndex, hasToSelectAll}: {categoryIndex: number; hasToSelectAll?: boolean}) => {
      const currentAddonCategories = [...state.currentAddonCategories];
      const activeSeverityState: Severities = {
        5: {level: 5, isActive: true},
        4: {level: 4, isActive: true},
        3: {level: 3, isActive: true},
        2: {level: 2, isActive: true},
        1: {level: 1, isActive: true},
        0: {level: 0, isActive: true}
      };
      const totalActiveSeverities = Object.values(currentAddonCategories[categoryIndex].severities).filter(
        severity => severity.isActive
      ).length;

      currentAddonCategories[categoryIndex] = {
        ...currentAddonCategories[categoryIndex],
        checked: hasToSelectAll !== undefined ? hasToSelectAll : !currentAddonCategories[categoryIndex].checked,
        severities: totalActiveSeverities === 0 ? activeSeverityState : currentAddonCategories[categoryIndex].severities
      };

      return {
        ...state,
        currentAddonCategories: [...currentAddonCategories]
      };
    }
  ),
  on(
    annotationsSummaryActions.addSeverityLevel,
    (state: LibraryState, {level, categoryIndex}: {level: number; categoryIndex: number}) => {
      const currentAddonCategories = [...state.currentAddonCategories];
      const newSeverity = {};
      newSeverity[level] = {
        ...currentAddonCategories[categoryIndex].severities[level],
        ...{isActive: true}
      };

      currentAddonCategories[categoryIndex] = {
        ...currentAddonCategories[categoryIndex],
        severities: {...currentAddonCategories[categoryIndex].severities, ...newSeverity}
      };
      return {...state, currentAddonCategories: [...currentAddonCategories]};
    }
  ),
  on(
    annotationsSummaryActions.removeSeverityLevel,
    (state: LibraryState, {level, categoryIndex}: {level: number; categoryIndex: number}) => {
      const currentAddonCategories = [...state.currentAddonCategories];
      const newSeverity = {};

      newSeverity[level] = {
        ...currentAddonCategories[categoryIndex].severities[level],
        ...{isActive: false}
      };
      currentAddonCategories[categoryIndex] = {
        ...currentAddonCategories[categoryIndex],
        severities: {...currentAddonCategories[categoryIndex].severities, ...newSeverity}
      };

      const totalActiveSeverities = Object.values(currentAddonCategories[categoryIndex].severities).filter(
        severity => severity.isActive
      ).length;
      currentAddonCategories[categoryIndex] =
        totalActiveSeverities === 0
          ? {
              ...currentAddonCategories[categoryIndex],
              checked: false
            }
          : currentAddonCategories[categoryIndex];
      return {...state, currentAddonCategories: [...currentAddonCategories]};
    }
  ),
  on(annotationsSummaryActions.addAllSeverityLevel, (state: LibraryState, {level}: {level: number}) => {
    let currentAddonCategories = [...state.currentAddonCategories];
    currentAddonCategories = currentAddonCategories.map(category => {
      const newSeverity = {};
      newSeverity[level] = {...category.severities[level], ...{isActive: true}};
      return {
        ...category,
        severities: {...category.severities, ...newSeverity}
      };
    });
    return {...state, currentAddonCategories: [...currentAddonCategories]};
  }),
  on(annotationsSummaryActions.removeAllSeverityLevel, (state: LibraryState, {level}: {level: number}) => {
    let currentAddonCategories = [...state.currentAddonCategories];
    currentAddonCategories = currentAddonCategories.map(category => {
      const newSeverity = {};
      newSeverity[level] = {...category.severities[level], ...{isActive: false}};
      return {
        ...category,
        severities: {...category.severities, ...newSeverity}
      };
    });
    return {...state, currentAddonCategories: [...currentAddonCategories]};
  }),
  on(
    annotationsSummaryActions.saveActiveAnnotationsSummaryData,
    (state: LibraryState, {annotations}: {annotations: AnnotationsSummaryData[]}) => ({
      ...state,
      activeAnnotationsSummaryData: annotations
    })
  ),
  on(annotationsSummaryActions.resetAllFilters, (state: LibraryState) => {
    const currentAddonCategories = [...state.currentAddonCategories];
    const resetedCategories = currentAddonCategories.map(category => ({
      ...category,
      checked: false,
      severities: {...category.severities, ...initialHeaderSeverityButtons}
    }));
    return {
      ...state,
      currentAddonCategories: resetedCategories,
      annotationsSummaryActiveTags: [],
      severityHeaderButtons: initialHeaderSeverityButtons
    };
  }),
  on(annotationsSummaryActions.resetCurrentAddonCategories, (state: LibraryState) => {
    const currentAddonCategories = [...state.currentAddonCategories];
    const resetedCategories = currentAddonCategories.map(category => ({
      ...category,
      checked: false,
      severities: {...category.severities, ...initialHeaderSeverityButtons}
    }));
    return {
      ...state,
      currentAddonCategories: resetedCategories,
      severityHeaderButtons: initialHeaderSeverityButtons
    };
  }),
  on(annotationsSummaryActions.updateCurrentActiveTags, (state: LibraryState, {tag}: {tag: Tag}) => {
    const activeTags = [...state.annotationsSummaryActiveTags];
    const currentTagIndex = activeTags.findIndex(activeTag => activeTag.id === tag.id);
    if (currentTagIndex >= 0) {
      activeTags.splice(currentTagIndex, 1);
      return {...state, annotationsSummaryActiveTags: activeTags};
    }
    activeTags.push(tag);
    return {...state, annotationsSummaryActiveTags: activeTags};
  }),
  on(annotationsSummaryActions.enableActiveTag, (state: LibraryState, {tag}: {tag: Tag}) => {
    const activeTags = [...state.annotationsSummaryActiveTags];
    const currentTagIndex = activeTags.findIndex(activeTag => activeTag.id === tag.id);
    if (currentTagIndex === -1) {
      activeTags.push(tag);
    }
    return {...state, annotationsSummaryActiveTags: activeTags};
  }),
  on(annotationsSummaryActions.updateSeverityHeaderButtons, (state: LibraryState, {level}: {level: number}) => {
    const currentSeverityHeaderButtons = {...state.severityHeaderButtons};
    currentSeverityHeaderButtons[level] = {
      ...currentSeverityHeaderButtons[level],
      isActive: !currentSeverityHeaderButtons[level].isActive
    };
    return {
      ...state,
      severityHeaderButtons: currentSeverityHeaderButtons
    };
  }),
  on(
    annotationsSummaryActions.toggleSeverityHeaderButtons,
    (state: LibraryState, {level, isActive}: {level: number; isActive: boolean}) => {
      const currentSeverityHeaderButtons = {...state.severityHeaderButtons};
      currentSeverityHeaderButtons[level] = {
        ...currentSeverityHeaderButtons[level],
        isActive: isActive
      };
      return {
        ...state,
        severityHeaderButtons: currentSeverityHeaderButtons
      };
    }
  ),
  on(annotationsSummaryActions.resetAnnotationsSummaryChips, (state: LibraryState) => {
    return {...state, annotationsSummaryChips: []};
  }),
  on(
    annotationsSummaryActions.handleAnnotationAndSeverityChips,
    (state: LibraryState, {totalAnnotationLabels}: {totalAnnotationLabels: number}) => {
      const annotationAndSeverityChip = {
        name:
          totalAnnotationLabels > 1
            ? `${totalAnnotationLabels} annotation types`
            : `${totalAnnotationLabels} annotation type`,
        icon: 'annotation-severity',
        chipType: 'annotation-types'
      };
      const chips = [...state.annotationsSummaryChips];
      if (totalAnnotationLabels) {
        const severityChipIndex = chips.findIndex(chip => chip.name.includes('annotation type'));
        if (severityChipIndex >= 0) {
          chips.splice(severityChipIndex, 1);
        }
        chips.unshift(annotationAndSeverityChip);
        return {...state, annotationsSummaryChips: chips};
      }
      const severityChipIndex = chips.findIndex(chip => chip.chipType === 'annotation-types');
      if (severityChipIndex !== -1) {
        chips.splice(severityChipIndex, 1);
      }
      return {...state, annotationsSummaryChips: chips};
    }
  ),
  on(annotationsSummaryActions.handleTagsChips, (state: LibraryState, {tags}: {tags: Tag[]}) => {
    const chips = [...state.annotationsSummaryChips];
    const severityChipIndex = chips.findIndex(chip => chip.name.includes('annotation type'));
    const newChips = [];
    tags.forEach(tag => {
      newChips.push({name: tag.name, color: tag.metadata.color, id: tag.id, chipType: 'tags'});
    });
    if (severityChipIndex !== -1) {
      newChips.unshift(chips[severityChipIndex]);
    }
    return {...state, annotationsSummaryChips: newChips};
  }),
  on(annotationsSummaryActions.enableTagsChips, (state: LibraryState, {tag}: {tag: Tag}) => {
    const chips = [...state.annotationsSummaryChips];
    const tagChipIndex = chips.findIndex(chip => chip.id === tag.id);
    if (tagChipIndex === -1) {
      chips.push({name: tag.name, color: tag.metadata.color, id: tag.id, chipType: 'tags'});
    }
    return {...state, annotationsSummaryChips: chips};
  }),
  on(annotationsSummaryActions.disableAllHeaderSeverityButtonsState, (state: LibraryState) => {
    return {...state, severityHeaderButtons: initialHeaderSeverityButtons};
  }),
  on(
    annotationsSummaryActions.setHasInitialAnnotationsSummaryData,
    (state: LibraryState, {hasSummaryData}: {hasSummaryData: boolean}) => ({
      ...state,
      hasInitialAnnotationsSummaryData: hasSummaryData
    })
  ),
  on(actions.setLibraryItemFromContextMenu, (state, {payload}) => {
    return {...state, libraryItemFromContextMenu: payload.libraryItemId};
  }),
  on(actions.clearLibraryIteamContextMenuSuccess, (state: LibraryState) => ({
    ...state,
    libraryItemFromContextMenu: null
  })),
  on(actions.setListViewState, (state, {payload}) => {
    return {...state, ...payload};
  }),
  on(
    actions.loadSpecialLibraryItemsSuccess,
    (state: LibraryState, action: {payload: {[key: string]: {[key: string]: LibraryItem}}}) => {
      const specialLibraryItems = {...state.specialLibraryItems.assetAssign};

      Object.entries(action.payload).forEach(([parentId, item]) => {
        specialLibraryItems[parentId] = [];
        Object.keys(item).forEach(libraryItemId => {
          specialLibraryItems[parentId].push(libraryItemId);
        });
      });
      return {
        ...state,
        specialLibraryItems: {
          assetAssign: specialLibraryItems
        }
      };
    }
  ),
  on(actions.changeViewMode, (state: LibraryState, {payload}: {payload: {viewMode: LibraryViewMode}}) => ({
    ...state,
    viewMode: payload.viewMode
  }))
);

const isFromCurrentFolder = (currentPath: string, itemLocation: string) => {
  const locations = itemLocation.split('/');
  const itemFolderId = locations.pop();
  const currentFolderId = currentPath;
  return itemFolderId === currentFolderId || (locations.length === 0 && currentPath.length === 0);
};

function hasLibraryFilters(state: LibraryState, currentFolderPath: string) {
  const emptyFiltersTemplate: LibraryFilter = {
    search: null,
    dateFrom: null,
    hourFrom: null,
    dateTo: null,
    hourTo: null,
    type: null,
    device: null,
    analysis: null,
    tags: [],
    timestamp: null
  };

  const hasFilters = Object.keys(emptyFiltersTemplate).some(key => {
    switch (key) {
      case 'search':
      case 'dateFrom':
      case 'hourFrom':
      case 'dateTo':
      case 'hourTo':
      case 'type':
      case 'device':
      case 'analysis':
      case 'timestamp':
        return !!state.filters[currentFolderPath]?.[key];
      case 'tags':
        return !!state.filters[currentFolderPath]?.[key]?.length;
      default:
        return !!state.filters[currentFolderPath]?.[key];
    }
  });
  return hasFilters;
}

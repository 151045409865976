import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {AtlasHeaderService, AtlasHeaderStatus} from '../services/atlas-header.service';
import {take} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {AtlasSnackbarMessageComponent} from '../components/atlas-snackbar-message/atlas-snackbar-message.component';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {Map} from 'leaflet';
import {CompareLayersService} from '../services/compare-layers.service';

@Directive({
  selector: '[ulCompareLayer]',
  standalone: true
})
export class CompareLayerDirective {
  @Input('ulCompareLayer') public asset: AtlasAssetModel;
  @Input() map: Map;

  constructor(
    private translateService: TranslateService,
    private atlasService: AtlasService,
    private atlasHeaderService: AtlasHeaderService,
    private snackBar: MatSnackBar,
    private unleashAnalytics: UnleashAnalyticsService,
    private compareLayersService: CompareLayersService
  ) {}

  @HostListener('click', ['$event'])
  public compareLayers(): void {
    const asset = this.asset;
    const headerStatus = AtlasHeaderStatus.COMPARE_LAYERS;
    this.atlasHeaderService.setHeaderStatus(headerStatus);
    this.atlasService.setIsCompareLayersOpen(true);
    const showMessageLimit = 3;
    if (this.compareLayersService.compareLayersCounter < showMessageLimit) {
      this.translateService
        .get('atlas.actionBar.compareLayersInfo')
        .pipe(take(1))
        .subscribe(message => {
          this.snackBar.openFromComponent(AtlasSnackbarMessageComponent, {
            duration: 3000,
            panelClass: 'atlas-snackbar-message--0',
            data: {
              message
            }
          } as MatSnackBarConfig);
        });
    }
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_COMPARE_LAYERS, {
      totalLayersCompared: this.atlasService.selectedLayers.length,
      typesOfLayersCompared: this.atlasService.selectedLayers.map(layer => layer.type)
    });
    this.compareLayersService.compareLayers(this.map);
    if (!asset.isSelected) {
      asset.isSelected = true;
      this.atlasService.addSelectedLayer(asset);
    }
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UserService} from '@app/core/services/api/user.service';

@Directive({
  selector: '[appEnableAuthorizationToggleChange]',
  standalone: true
})
export class EnableAuthorizationToggleChangeDirective {
  @Input()
  public companyId: string;

  constructor(private userService: UserService) {}

  @HostListener('change', ['$event'])
  public onToggleChange(event: MatSlideToggleChange): void {
    this.userService.updateCompany(this.companyId, {mfaRequired: event.checked});
  }
}

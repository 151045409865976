<!-- flight indicators -->
<div
  class="map-controls dashboard-controls"
  *ngIf="timeControlsVisible"
  fxLayout="row"
  fxLayoutAlign="space-around center"
>
  <!--flight mode-->
  <div fxFlex fxLayoutAlign="start" class="control">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="currentFrame.flycState">
      <i class="material-icons"> airplanemode_active </i>
      {{ currentFrame.flycState }}
    </div>
  </div>
  <!--gps signal-->
  <div fxFlex fxLayoutAlign="center" class="control">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="currentFrame.gpsNum">
      <i class="material-icons"> signal_cellular_alt </i>
      {{ currentFrame.gpsNum }}
    </div>
  </div>
  <!--battery level-->
  <div fxFlex fxLayoutAlign="end" class="control">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="currentFrame.relativeCapacity">
      <i class="material-icons"> battery_std </i>
      {{ currentFrame.relativeCapacity }}%
    </div>
  </div>
</div>

<!-- flight progress slider-->
<div class="map-controls time-controls" *ngIf="timeControlsVisible">
  <ua-flight-time-control
    (timeChanged)="newFrame($event)"
    [numFrames]="flightFrames && flightFrames.length - 1"
    [map]="map"
  ></ua-flight-time-control>
</div>

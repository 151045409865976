import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {catchError, from} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetupPhoneGuard {
  public canActivate() {
    return from(Auth.currentAuthenticatedUser()).pipe(
      catchError(() => {
        return this.router.navigate(['/auth/sign-in']);
      })
    );
  }
  constructor(private router: Router) {}
}

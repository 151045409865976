import {ManagerZonesAnalyzeState} from './manager-zones.state';

export const selectEditMode = (state: ManagerZonesAnalyzeState) => state.isRuleEditMode;

export const selectRules = (state: ManagerZonesAnalyzeState) => state.rules;
export const selectRulesItem = (state: ManagerZonesAnalyzeState) =>
  state.rules.map(rule => ({id: rule.id, name: rule.ruleName.name, enabled: rule.enabled}));

export const selectSelectedRule = (state: ManagerZonesAnalyzeState) => state.selectedRule;

export const selectNotificationsForm = (state: ManagerZonesAnalyzeState) => state.ruleForm;

export const selectPerformAnalysisLoading = (state: ManagerZonesAnalyzeState) => state.isPerformingAnalysis;

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, shareReplay} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AtlasHeaderService {
  private headerStatus: BehaviorSubject<AtlasHeaderStatus> = new BehaviorSubject(AtlasHeaderStatus.DEFAULT);
  public headerStatus$: Observable<AtlasHeaderStatus> = this.headerStatus.asObservable().pipe(shareReplay(1));
  public editJobLayerTitle: BehaviorSubject<string> = new BehaviorSubject(null);
  public editJobLayerTitle$: Observable<string> = this.editJobLayerTitle.asObservable();

  public setHeaderStatus(status: AtlasHeaderStatus): void {
    this.headerStatus.next(status);
  }

  public isHeaderEqualTo(atlasHeader: AtlasHeaderStatus): boolean {
    return this.headerStatus.value === atlasHeader;
  }

  public setEditJobLayerTitle(editJobLayerTitle: string): void {
    this.editJobLayerTitle.next(editJobLayerTitle);
  }
}

export enum AtlasHeaderStatus {
  MISSION_PLANNER = 1,
  COMPARE_LAYERS = 2,
  SELECT_MARKERS = 3,
  ACTION_BAR = 4,
  EDIT_JOB_SHAPE = 5,
  DEFAULT = 6
}

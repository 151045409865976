import {Pipe, PipeTransform} from '@angular/core';
import {LibraryAppAsyncService} from '@app/library/services/library-app-async.service';
import {LibraryStoreFacadeService} from '@app/library/services/library-store-facade.service';
import {Observable, from, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';

@Pipe({
  name: 'ufolderName'
})
export class FolderNamePipe implements PipeTransform {
  constructor(
    private libraryStoreFacadeService: LibraryStoreFacadeService,
    private libraryAppAsyncService: LibraryAppAsyncService
  ) {}

  transform(folderIdValue: string): Observable<string> {
    let folderId = folderIdValue;
    const archivePrefix = 'archive#';
    if (folderId.startsWith(archivePrefix)) {
      folderId = folderId.substring(archivePrefix.length);
    }

    if (!folderId) {
      return of(null);
    }

    return this.libraryStoreFacadeService.selectLibraryItem(folderId).pipe(
      take(1),
      switchMap(libraryItem => {
        if (!libraryItem) {
          return from(
            this.libraryAppAsyncService.getLibraryItem({
              id: folderId
            })
          ).pipe(
            map(response => response.data.get),
            tap(libraryItem => this.libraryStoreFacadeService.saveLibraryItemsSilently({[folderId]: libraryItem}))
          );
        }
        return of(libraryItem);
      }),
      map(libraryItem => {
        if (libraryItem) {
          return libraryItem.name;
        }
      })
    );
  }
}

<form [formGroup]="form">
  <div mat-dialog-title>
    <span class="text-4--bold">Create A.I. App</span>
  </div>

  <div mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-label>{{ 'common.name' | translate }}</mat-label>
      <input matInput formControlName="name" />
      <mat-error
        *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)"
      ></mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button type="button" color="primary" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
    <button mat-raised-button color="primary" (click)="createAiApp()">
      {{ 'common.create' | translate }}
    </button>
  </div>
</form>

import {HttpParams} from '@angular/common/http';
import {Pipe, PipeTransform} from '@angular/core';
import {ThumblerParams} from '@app/library/models/thumbler-params.model';
import {environment} from '../../../environments/environment';
import {ThumblerImageConfig} from './models/thumbler.model';
import {getCognitoIdentityValue} from '@app/core/services/api/cognito-identity-value';

@Pipe({name: 'uThumbler', standalone: true})
export class ThumblerPipe implements PipeTransform {
  private PLACEHOLDER_IMAGE_URL: string = '/assets/images/placeholder.png';

  public transform(
    basePath: string | string[],
    thumbConfig: ThumblerImageConfig,
    defaultImage?: string
  ): string | string[] {
    if (typeof basePath === 'string') {
      if (basePath.startsWith('data:')) {
        return basePath;
      }

      return this.getThumbPath(basePath as string, thumbConfig.imgResolution, thumbConfig.sourceCategory, defaultImage);
    }
    if (Array.isArray(basePath)) {
      const newPaths: string[] = [];
      basePath.forEach((path: string) => {
        newPaths.push(
          this.getThumbPath(path as string, thumbConfig.imgResolution, thumbConfig.sourceCategory, defaultImage)
        );
      });
      return newPaths;
    }
    return this.PLACEHOLDER_IMAGE_URL;
  }

  public getThumbPath(
    basePath: string,
    thumblerParams: ThumblerParams,
    sourceCategory: string,
    defaultImage?: string
  ): string {
    let params = new HttpParams();
    for (const [k, v] of Object.entries(thumblerParams)) {
      params = params.set(k, v);
    }

    params = params.set('token', getCognitoIdentityValue('idToken'));

    const isSupportedFile = /(bmp|jpg|gif|png|jpeg|mp4|mov|mkv|svg|avi|lrv)$/i.test(basePath);
    if (basePath && isSupportedFile) {
      return `${environment.THUMBLER_API_CDN}/${sourceCategory}/${basePath}?${params.toString()}`;
    }

    return defaultImage ? defaultImage : this.PLACEHOLDER_IMAGE_URL;
  }
}

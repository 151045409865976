import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {RuleAreaOfInterest, RuleCondition, RuleOperator} from '../../models/rule.model';

@Component({
  selector: 'unleash-rule-row',
  templateUrl: './rule-row.component.html',
  styleUrls: ['./rule-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleRowComponent implements OnInit {
  @Input() isAdvancedMode: boolean = false;
  @Input() zones: RuleAreaOfInterest[] = [];
  @Input() zoneList: Zone[] = [];
  @Input() conditions: RuleCondition[] = [];
  @Input('operators')
  set setupOperators(operators: RuleOperator[]) {
    if (this.form && this.form.value.condition) {
      if (
        (this.form.value.zone === 'class_name' || this.form.value.zone === 'triggered_zones') &&
        this.form.value.condition === 'disabled'
      ) {
        this.operators = operators.filter(operator => operator.id === 'eq' || operator.id === 'ne');
      } else {
        this.operators = operators;
      }
    }
  }
  @Input() hasDelete: boolean = true;
  @Input() form: UntypedFormGroup = new UntypedFormGroup({zone: new UntypedFormControl('', [Validators.required])});

  @Output() zoneChange: EventEmitter<void> = new EventEmitter();
  @Output() conditionChange: EventEmitter<void> = new EventEmitter();
  @Output() valueChange: EventEmitter<void> = new EventEmitter();
  @Output() logicOperatorChange: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  operators: RuleOperator[] = [];

  constructor() {}

  ngOnInit(): void {}

  emitZoneChange(): void {
    this.zoneChange.emit();
  }

  emitConditionChange(): void {
    this.conditionChange.emit();
  }

  updateValueOnBlur(controlName: string, input: HTMLInputElement): void {
    this.form.controls[controlName].setValue(input.value);
    this.emitValueChange();
  }

  emitValueChange(): void {
    this.valueChange.emit();
  }

  emitAddOperatorValueChange(): void {
    if (this.form.valid) {
      this.logicOperatorChange.emit();
    }
  }

  emitDelete(): void {
    this.delete.emit();
  }
}

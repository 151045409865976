<div fxLayout="row" fxLayout.xs="column" fxFlexAlign="left" class="container">
  <div fxFlexOffset="20px">
    <logo-upload
      subfolder="company"
      [parentId]="companyForm.get('id').value"
      [url]="companyForm.get('logo').value"
      (s3Url)="updateCompanyLogo($event)"
    ></logo-upload>
  </div>
  <div>
    <mat-list>
      <form [formGroup]="companyForm" novalidate id="company-form" autocomplete="off">
        <mat-list-item>
          <mat-form-field>
            <input
              type="text"
              matInput
              (change)="updateCompany('name')"
              formControlName="name"
              placeholder="Name"
              required
            />
            <mat-error *ngIf="companyForm.get('name').hasError('required')"> Name is required </mat-error>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <input
              type="text"
              matInput
              (change)="updateCompany('website')"
              formControlName="website"
              placeholder="Website"
            />
            <mat-error *ngIf="companyForm.get('website').hasError('pattern')"> Website is not valid </mat-error>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-select
              placeholder="Industry"
              formControlName="industry"
              (selectionChange)="updateCompany('industry')"
              required
            >
              <mat-option *ngFor="let industry of industryNames" name="industry" [value]="industry" required>
                {{ industry }}
              </mat-option>
              <mat-divider></mat-divider>
              <mat-option value="other"> Other </mat-option>
            </mat-select>

            <mat-error *ngIf="companyForm.get('industry').hasError('required')"> Choose company industry </mat-error>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-label>Live streaming dashboard name:</mat-label>
            <input
              type="text"
              #slug
              matInput
              (change)="updateCompany('slug')"
              formControlName="slug"
              placeholder="Streaming dashboard"
              required
            />
            <mat-error *ngIf="companyForm.get('slug').hasError('required')"> Dashboard name is required </mat-error>
            <mat-error
              *ngIf="companyForm.get('slug').hasError('pattern') && !companyForm.get('slug').hasError('required')"
            >
              Dashboard name is not valid
            </mat-error>
            <mat-error *ngIf="updateErrorMessage" [innerHTML]="updateErrorMessage"> </mat-error>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item *ngIf="!!slug.value">
          <br />
          <mat-checkbox matInput (change)="updateCompany('shared')" formControlName="shared">
            Share your broadcast
          </mat-checkbox>
        </mat-list-item>
        <mat-list-item *ngIf="!!slug.value">
          <a
            target="_blank"
            [href]="getDashboardUrl()"
            mat-button
            color="accent"
            [disabled]="!companyForm.get('shared').value"
            disabled
          >
            Go to your live streaming dashboard
          </a>
        </mat-list-item>
      </form>
    </mat-list>
  </div>
</div>

export interface NotificationModel {
  action?: string[] /* a short text on the action button */;
  actionUrl?: string[] /* a link to navigate */;
  createdAt?: number;
  data?: {
    /* a generic structure to store event specific information */
    jobId?: string;
    modelId?: string;
    planId?: string;
    sessionId?: string;
  };
  deviceId: string /* the device that was involved */;
  icon: NotificationIcon;
  isRead?: boolean;
  level: NotificationLevel /* informative level */;
  message: string /* the message to the user with more information  */;
  priority?: string;
  progress?: number /* progress value between 0 and 100 */;
  source: string;
  sourceType: string /* which element of the system triggered notification*/;
  state: NotificationState /* the state the event transitioned to */;
  title: string /* very short description*/;
  updatedAt?: number;
  userId: string /* the owner user of the event */;
  isInProgress?: boolean;
  sourceCreatedAt: string;
}

export enum NotificationLevel {
  info = 'info',
  error = 'error',
  warning = 'warn',
  success = 'success'
}

export enum NotificationLayout {
  grow = 'grow',
  simple = 'simple',
  inProgress = 'in-progress'
}

export enum NotificationIcon {
  info = 'info',
  error = 'error',
  warning = 'report_problem',
  success = 'check_circle',

  // in progress notifications
  createModelBlue = 'create_model_blue',
  analysingMediaBlue = 'analysing_media_blue'
}

export enum NotificationState {
  START = 'start',
  FINISH = 'finish',
  RUNNING = 'running',
  CANCEL = 'cancel',
  ERROR = 'error',
  NONE = 'none' /* used for discrete events */
}

export enum NotificationCoolDownPeriod {
  Short = 10,
  Medium = 60,
  Long = 300,
  ExtraLong = 86400
}

export interface NotificationListQuery {
  items: NotificationModel[];
  nextToken: string | null;
}

export enum NotificationSource {
  addon = 'addon',
  batch = 'batch',
  cortexLive = 'cortexLive',
  analytics = 'analytics',
  atlas = 'atlas',
  library = 'library',
  stream = 'stream',
  iot = 'iot',
  account = 'account',
  device = 'device',
  odm = 'odm'
}

export enum NotificationSourceType {
  // analytics
  stream = 'stream',
  job = 'job',
  rowsProcessed = 'rows-processed',
  // atlas
  layer = 'layer',
  annotation = 'annotation',
  flightlog = 'flightlog',
  // stream
  raw = 'raw',
  from = 'from',
  to = 'to',
  ai = 'ai',
  streamCreated = 'streamCreated',
  streamCreatedPost = 'streamCreatedPost',

  webrtcai = 'webrtcai',
  webrtcraw = 'webrtcraw',
  rtmpai = 'rtmpai',
  // iot
  flight = 'flight',
  //batch
  model = 'model',
  pointcloud = 'pointcloud',
  cv = 'cv',
  modelPostCreated = 'modelPostCreated',
  pointcloudPostCreated = 'pointcloudPostCreated',
  cvPostCreated = 'cvPostCreated',
  //library
  downloadReports = 'download-reports',
  download = 'download',
  downloadPreprocessing = 'downloadPreprocessing',
  exportAnnotationsToCsv = 'exportAnnotationsToCsv',
  exportAnnotationsToJson = 'exportAnnotationsToJson',
  exportAnnotationsToPdf = 'exportAnnotationsToPdf',

  // account
  planEnding = 'plan-ending',
  planEndingSoon = 'plan-ending-soon',
  unpaidInvoice = 'unpaid-invoice',
  storageLimit = 'storage-limit',
  streamingLimit = 'stream-limit',
  devicesLimit = 'devices-limit',
  analyticsLimit = 'analytics-limit',
  accountCopy = 'account-copy',
  libraryAggregate = 'library-aggregate',
  //device
  deviceAdded = 'device-added',
  deviceRemoved = 'device-removed',
  //library
  fileAdded = 'file-add',
  automatedFileAdded = 'automated-file-add',
  fileRemoved = 'file-removed',
  //addon
  addonReviewRequested = 'addon-review-requested',
  addonReviewRejected = 'addon-review-rejected',
  addonReviewApproved = 'addon-review-approved',
  //company
  companyCreated = 'company-created'
}

//This has been copied from Autofly Android App
export enum MissionState {
  DISCONNECTED,
  RECOVERING,
  NOT_SUPPORTED,
  READY_TO_UPLOAD,
  UPLOADING,
  READY_TO_EXECUTE,
  EXECUTE_PREPARING,
  GOING_TO_FIRST_POINT,
  EXECUTING,
  RETURNING_TO_START_POINT,
  INTERRUPTED,
  FINISHED,
  UNKNOWN
}

import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@app/auth/services/auth.service';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, from, map, take} from 'rxjs';
import {Auth} from '@aws-amplify/auth';
import {actionConfirmPhoneMFA, actionSetupPhoneMFA} from '../sign-in/sign-in.actions';
import {MFA_SETUP_STATE} from '@app/auth/models/auth.models';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';

@Component({
  selector: 'unleash-confirm-mobile-number',
  templateUrl: './confirm-mobile-number.component.html',
  styleUrls: ['./confirm-mobile-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMobileNumberComponent implements AfterViewInit, OnDestroy {
  public verificationForm: FormGroup = this.fb.group({
    code: ['', Validators.required]
  });
  public isVerificationCodeValid: BehaviorSubject<boolean> = this.authService.isVerificationCodeValid;
  public isInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isVerificationCodeSub: Subscription;
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public phoneNumber$: Observable<string> = from(Auth.currentUserInfo()).pipe(
    map(cognitoUser => {
      return '********' + cognitoUser?.attributes?.phone_number.slice(-3);
    })
  );

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnDestroy(): void {
    if (this.isVerificationCodeSub) {
      this.isVerificationCodeSub.unsubscribe();
      this.isVerificationCodeSub = null;
    }
  }

  public ngAfterViewInit(): void {
    this.isVerificationCodeSub = this.isVerificationCodeValid.subscribe(isValid => {
      if (!isValid) {
        this.isInProgress.next(false);
        this.verificationForm.controls.code.setErrors({invalidCode: true});
      }
    });
  }

  public back(): void {
    this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.SETUP_SMS);
    this.router.navigate(['auth/setup-mobile-number']);
  }

  public continue(): void {
    if (this.verificationForm.valid) {
      this.isInProgress.next(true);
      this.store.dispatch(
        actionConfirmPhoneMFA({
          code: this.verificationForm.value.code
        })
      );
    }
  }

  public sendCode(): void {
    from(Auth.currentUserInfo())
      .pipe(take(1))
      .subscribe(cognitoUser => {
        this.store.dispatch(
          actionSetupPhoneMFA({
            phoneNumber: cognitoUser?.attributes?.phone_number
          })
        );
      });
  }
}

import {TeamRole} from '@app/profile/models/team.model';
import {Roles, UserTeam} from '@app/profile/models/users-table.model';
import * as actions from '@app/profile/store/users-management/users-management.actions';
import {createReducer, on} from '@ngrx/store';

const initialState: UserTeam[] = [];
const initialStateRoles = {
  [TeamRole.viewer]: 'Viewer',
  [TeamRole.admin]: 'Admin',
  [TeamRole.contributor]: 'Contributor'
};
const initialStateSelectedUserId: UserTeam['userId'] = null;
const initialUserManangementLoadingState: boolean = false;

export const usersManagementReducer = createReducer<UserTeam[]>(
  initialState,
  on(actions.setUsers, (state, {payload}) => payload.users.map(user => ({...user, lastActive: new Date()}))),
  on(actions.updateUserTeamRoleSuccess, (state, {payload}) => {
    const userToUpdate = {
      ...state.find(user => user.userId === payload.userId && user.teamId === payload.teamId),
      roles: [payload.role]
    };
    const newUsers = state.map(user =>
      user.userId === payload.userId && user.teamId === payload.teamId ? userToUpdate : user
    );
    return newUsers;
  }),
  on(actions.deleteUserSuccess, (state, {payload: {userId}}) => {
    const newUsers = state.filter(user => user.userId !== userId);
    return newUsers;
  }),
  on(actions.addUsersToTeamSuccess, (state, {payload}) => {
    const newUsers = payload.users.map(userPayload => {
      return {
        ...state.find(user => user.userId === userPayload.userId),
        teamId: payload.teamId,
        roles: [userPayload.role]
      };
    });

    return [...state, ...newUsers];
  }),
  on(actions.removeUsersToTeamSuccess, (state, {payload}) => {
    return state.filter(
      user => !payload.users.find(userPayload => userPayload.userId === user.userId && payload.teamId === user.teamId)
    );
  }),
  on(actions.inviteUsersSuccess, (state, {payload}) => {
    return state.concat(payload.users);
  })
);

export const rolesManagementReducer = createReducer<Roles>(
  initialStateRoles,
  on(actions.setRoles, (state, {payload}) => ({...state, ...payload.roles}))
);

export const selectedUserIdReducer = createReducer<UserTeam['userId']>(
  initialStateSelectedUserId,
  on(actions.setSelectedUserId, (state, {payload}) => payload.userId),
  on(actions.removeSelectedUserId, () => null)
);

export const userManagementLoadingReducer = createReducer<boolean>(
  initialUserManangementLoadingState,
  on(actions.displayLoading, () => true),
  on(actions.hideLoading, () => false)
);

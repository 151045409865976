import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {environment} from 'environments/environment';
import {CoolDownPeriod, RuleAlert} from '../../models/rule.model';

@Component({
  templateUrl: './rule-settings.component.html',
  selector: 'unleash-rule-settings',
  styleUrls: ['./rule-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleSettingsComponent implements OnChanges {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({
    alert: new UntypedFormControl('', Validators.required)
  });
  @Input() public alerts = [];
  @Input() public coolDownPeriods: CoolDownPeriod[] = [];
  @Input() public hasToDisplayCoolDown: boolean = false;
  @Input() public hasToDisableSeverity: boolean = false;
  @Output() public alertChange: EventEmitter<string> = new EventEmitter();
  @Output() public valueChange: EventEmitter<void> = new EventEmitter();

  public alertDisplay: RuleAlert = {
    id: 'info',
    name: 'Info',
    icon: 'info',
    description: 'A notification will only be sent once per rule match'
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['form'] || changes['hasToDisableSeverity']) {
      if (this.form) {
        if (this.hasToDisableSeverity) {
          (this.form.controls.settings as FormGroup).controls.alert.disable();
        } else {
          (this.form.controls.settings as FormGroup).controls.alert.enable();
        }
      }
    }
  }

  public emitAlertChange(selectChange: MatSelectChange): void {
    this.alertChange.emit(selectChange.value);
  }

  public emitValueChange(): void {
    this.valueChange.emit();
  }
}

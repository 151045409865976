<div class="close-dialog">
  <h1 class="close-dialog__title">{{ 'stream-webrtc.leaveTitleDialogMessage' | translate }}</h1>
  <p class="close-dialog__message">{{ 'stream-webrtc.leaveContentDialogMessage' | translate }}</p>
  <mat-dialog-actions class="close-dialog__buttons">
    <button class="close-dialog__cancel" mat-button mat-dialog-close>
      {{ 'stream-webrtc.cancelLeaveDialogMessage' | translate }}
    </button>
    <button class="close-dialog__leave" mat-button [mat-dialog-close]="true" cdkFocusInitial>
      {{ 'stream-webrtc.leavePageDialogMessage' | translate }}
    </button>
  </mat-dialog-actions>
</div>

import {createAction, props} from '@ngrx/store';
import {SignUpForm} from './sign-up.model';

export const actionSignUpFormUpdate = createAction('[SignUpForm] Update', props<{form: SignUpForm}>());

export const actionSignUpFormSubmit = createAction(
  '[SignUpForm] Submit',
  props<{form: SignUpForm; awsMarketplaceToken?: string; promoCode?: string; isDeveloperMode?: boolean}>()
);

export const actionSignUpFormError = createAction('[SignUpForm] Submit error', props<{error: string}>());

export const actionSignUpFormSuccess = createAction('[SignUpForm] Submit success');

export const actionSignUpFormConfirmationRequired = createAction(
  '[SignUpForm] Confirmation required',
  props<{form: Partial<SignUpForm>; isDeveloperMode: boolean}>()
);

export const actionSignUpFormReconfirmationRequired = createAction(
  '[SignUpForm] Reconfirmation required',
  props<{form: Partial<SignUpForm>; isDeveloperMode?: boolean}>()
);

export const actionSignUpClearForm = createAction('[SignUpForm] Clear Form');

export const actionSetupSignUpEmail = createAction(
  '[SignUpForm] Setup Sign Up Email',
  props<{payload: {email: string}}>()
);

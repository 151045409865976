export class TileContent {
  constructor(
    public videoURL: string,
    public videoCover: string,
    public category: string,
    public duration: string,
    public title: string,
    public forPackage: string
  ) {}
}
export interface Resources {
  title: string;
  content: string;
  link?: string;
  openChat?: boolean;
}

export const DASHBOARD_TILES = [
  new TileContent(
    'https://player.vimeo.com/external/366442925.hd.mp4?s=dd591977e2aa79d723026fd19ff121f2a6fbbafc&profile_id=175',
    'assets/images/dashboard/video-live-stream.jpg',
    'core.dashboard.tiles.stream',
    '49s',
    'core.dashboard.tiles.addingDevice',
    'Live Streaming'
  ),
  new TileContent(
    'https://player.vimeo.com/external/366443135.hd.mp4?s=c3a14b9a26ed51fd76a8384f4fe5be8174e90605&profile_id=175',
    'assets/images/dashboard/video-modeling.jpg',
    'core.dashboard.tiles.visualize',
    '57s',
    'core.dashboard.tiles.usingFusionAtlas',
    'Modeling and GIS'
  ),
  new TileContent(
    'https://marketing.unleashlive.com/hubfs/Images%20and%20Videos/Videos/How%20to%20Run%20AI%20Analysis%20on%20a%20Live%20Stream.mp4',
    'assets/images/dashboard/video-ai-analysis.jpg',
    'core.dashboard.tiles.analyze',
    '39s',
    'core.dashboard.tiles.applyingAi',
    'A.I. Analytics'
  )
];

<ng-container *ngIf="!(isLoading$ | async); else unleashLoaderTemplate">
  <div class="container">
    <div class="title">Change your package</div>
    <mat-stepper
      [linear]="true"
      #stepper
      (animationDone)="billing.setCardNameFocus();"
      (selectionChange)="updateCurrentStep()"
    >
      <mat-step *ngFor="let step of (steps$ | async); let isLast = last; let indexStep = index">
        <form class="form-step" [formGroup]="step.formGroup">
          <ng-template matStepLabel>{{step.label}}</ng-template>
          <unleash-package-group
            class="form-content"
            (card)="selectedCard($event, step.cardType, indexStep)"
            [formControlName]="step.formControlName"
            [options]="step.options"
            [backgroundImage]="step.backgroundImage"
            [professionalIndex]="firstProfessionalStepIndex$ | async"
            [selectedCardId]="(plansByStep$ | async)[indexStep]?.id"
            [stepIndex]="indexStep"
          ></unleash-package-group>
          <unleash-info-card
            [name]="cardInfo.name"
            [backgroundImage]="cardInfo.backgroundImage"
            [buttonName]="cardInfo.buttonName"
            [description]="cardInfo.description"
            (action)="goToContactUs()"
          >
          </unleash-info-card>
        </form>
      </mat-step>
      <mat-step [stepControl]="paymentFormGroup">
        <form class="form-step">
          <ng-template matStepLabel>Billing</ng-template>
          <unleash-billing
            #billing
            class="form-content"
            [form]="paymentFormGroup"
            [monthly]="!!(combinedPlan$ | async) ? (combinedPlan$ | async).priceMonthly : 0"
            [annual]="!!(combinedPlan$ | async) ? (combinedPlan$ | async).priceAnnual : 0"
            (stripeToken)="setStripeToken($event)"
          ></unleash-billing>
          <unleash-info-card
            [isTextOnTop]="true"
            [name]="'Billing help'"
            [backgroundImage]="'/assets/icons/plans/question.svg'"
            [buttonName]="'CONTACT US'"
            [description]="'Looking for other billing options such as invoicing or have any questions about payment?'"
            (action)="goToContactUs()"
          >
          </unleash-info-card>
        </form>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Checkout</ng-template>
        <div class="form-step">
          <unleash-checkout
            class="form-content"
            [plan]="combinedPlan$ | async"
            [plans]="checkoutPlans$ | async"
            [monthly]="!!(combinedPlan$ | async) ? (combinedPlan$ | async).priceMonthly : 0"
            [annual]="!!(combinedPlan$ | async) ? (combinedPlan$ | async).priceAnnual : 0"
            [hasYearlyDiscount]="paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual"
            [hasProfessionalDiscount]="hasProfessionPlanSelected$ | async"
          ></unleash-checkout>
          <unleash-info-card
            [isTextOnTop]="true"
            [name]="'Need help?'"
            [backgroundImage]="'/assets/icons/plans/question.svg'"
            [buttonName]="'CONTACT US'"
            [description]="'If you have any questions about your package, plan inclusions or payment, we’re happy to help guide you.'"
            (action)="goToContactUs()"
          >
          </unleash-info-card>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <ng-container *ngIf="isSamePackage$| async; else doneTemplate">
          <unleash-current-plan
            (returnAction)="navigateToIsSamePackage()"
            (changeAction)="stepper.linear = true; stepper.selectedIndex = 0"
          ></unleash-current-plan>
        </ng-container>
        <ng-template #doneTemplate>
          <unleash-done
            [plan]="(combinedPlan$ | async)"
            [isAnnualPrice]="paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual"
            (action)="navigateTo()"
          ></unleash-done>
        </ng-template>
      </mat-step>

      <ng-template matStepperIcon="check">
        <mat-icon>check</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="edit" let-index="index" let-active="active">
        <mat-icon *ngIf="index === stepper.steps.length - 1; else default">done</mat-icon>
        <ng-template #default>{{index + 1}}</ng-template>
      </ng-template>
    </mat-stepper>
  </div>

  <div class="spacer"></div>

  <unleash-banner class="banner-cart" [isOpen]="stepper.selectedIndex !== 5">
    <div slot="message" class="banner-content">
      <div class="image-container"><img src="/assets/icons/plans/cart.svg" /></div>
      <div class="banner-info">
        <ng-container *ngIf="stepper?.selectedIndex > 2 && (combinedPlan$ | async) as combinedPlan">
          <div class="price">
            <span class="value">
              {{ (paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual ? combinedPlan.priceAnnual * 12 :
              combinedPlan.priceMonthly) | number: '1.2-2'}}
            </span>
            USD / {{ paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual ? 'year': 'month'}}
          </div>
          <ng-container *ngTemplateOutlet="selectedPlansTemplate"></ng-container>
        </ng-container>

        <ng-container *ngIf="totalPayment$ | async as totalPayment; else noPackageSelectedTemplate">
          <ng-container *ngIf="totalPayment > 0 && stepper?.selectedIndex <= 2">
            <div class="price">
              <span class="value"> {{ totalPayment | number: '1.2-2' }} </span>
              USD / {{ paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual ? 'year': 'month'}}
            </div>
            <ng-container *ngTemplateOutlet="selectedPlansTemplate"></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noPackageSelectedTemplate>
          <div class="price">
            <span class="value">0</span>
            USD / {{ paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual ? 'year': 'month'}}
          </div>
          <div class="description">You must have at least one plan in your package to continue</div>
        </ng-template>
      </div>
    </div>
    <div slot="buttons">
      <button class="button" type="button" mat-button color="primary" (click)="handleBannerAction('simple')">
        CANCEL
      </button>
      <ng-container [ngSwitch]="stepper.selectedIndex">
        <button
          class="button"
          *ngSwitchCase="3"
          type="button"
          mat-raised-button
          color="primary"
          (click)="handleBannerAction('action')"
        >
          REVIEW PACKAGE
        </button>
        <button
          class="button"
          *ngSwitchCase="4"
          type="button"
          mat-raised-button
          color="primary"
          (click)="handleBannerAction('action')"
          [appLadda]="isBannerActionLoading$ | async"
        >
          CONFIRM & PAY
        </button>
        <button
          class="button"
          *ngSwitchDefault
          type="button"
          mat-raised-button
          color="primary"
          (click)="handleBannerAction('action')"
        >
          CONTINUE
        </button>
      </ng-container>
    </div>
  </unleash-banner>
</ng-container>
<ng-template #unleashLoaderTemplate>
  <unleash-loader [visible]="true" [text]="'Loading package selector'"></unleash-loader>
</ng-template>

<ng-template #selectedPlansTemplate>
  <div class="description">
    Includes
    <ng-container *ngIf="selectedPlans$ | async as selectedPlans">
      <span *ngFor="let plan of selectedPlans; let isLast = last">
        {{plan.packageName}}<span *ngIf="!isLast">,</span>
      </span>
      <ng-container *ngIf="selectedPlans.length > 0">
        {{ selectedPlans[0].planName }} on a {{ paymentFormGroup.value.paymentPeriod === PaymentPeriodType.annual ?
        'year-to-year' : 'month-to-month'}} plan. Excludes add-ons.
      </ng-container>
    </ng-container>
  </div>
</ng-template>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {authLogout, authRegistrationFinished} from '@app/auth/state/auth.actions';
import {select, Store} from '@ngrx/store';
import {PlanModel} from '@app/core/models/api/plan.model';
import {PlansService} from '@app/plans/services/plans.service';
import {selectUserState} from '@app/store/app.state';
import {Observable, of, zip} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Component({
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss']
})
export class WelcomePage implements OnInit {
  currentPlan$: Observable<PlanModel>;
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;

  constructor(
    private router: Router,
    private store: Store,
    private plansService: PlansService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentPlan$ = this.store.pipe(
      select(selectUserState),
      switchMap(userState => zip(of(userState.user.currentPlan), this.plansService.plans)),
      map(([currentPlan, plans]) => plans.find(plan => plan.id === currentPlan))
    );
  }

  signOut() {
    this.store.dispatch(authLogout({isDeveloperMode: this.isDeveloperMode}));
  }

  continue() {
    this.store.dispatch(authRegistrationFinished());
  }

  back() {
    this.router.navigate(['auth/your-profile']);
  }
}

import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SignInForm} from '@app/auth/components/sign-in/sign-in.model';
import {SignUpForm} from '@app/auth/components/sign-up/sign-up.model';
import {Observable, Subscription, zip} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthFacade} from '@app/auth/state/auth.facade';

@Component({
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss']
})
export class SignUpPage implements OnInit, OnDestroy {
  public errorMessage$: Observable<string>;
  public signInForm$: Observable<SignInForm> = this.authStoreFacadeService.signInForm$;
  public inProgress$: Observable<boolean>;
  public hasAwsToken$: Observable<boolean>;
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public isOrganizationSignUp$: Observable<boolean> = this.authStoreFacadeService.isOrganizationSignUp$;
  public organizationName$: Observable<{value: string}> = this.authStoreFacadeService.organizationNameSignUp$.pipe(
    map(organizationName => ({value: organizationName}))
  );

  private queryParamsSub: Subscription;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private authStoreFacadeService: AuthFacade
  ) {}

  public ngOnInit(): void {
    this.authStoreFacadeService.clearAwsMarketPlaceToken();

    this.document.body.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });

    this.inProgress$ = this.authStoreFacadeService.inProgress$;
    this.hasAwsToken$ = this.authStoreFacadeService.hasAwsToken$;

    this.queryParamsSub = this.route.queryParams.subscribe(params => {
      if (params['awsMarketplaceToken']) {
        this.authStoreFacadeService.setupAwsMarketPlaceToken(params['awsMarketplaceToken']);
      }
      if (params['promo-code']) {
        this.authStoreFacadeService.setupPromoCode(params['promo-code']);
      }
      if (params['organizationName'] && params['email']) {
        this.authStoreFacadeService.setupOrganizationName(params['organizationName']);
        this.authStoreFacadeService.setupSignUpEmail(params['email']);
      }
    });

    this.errorMessage$ = this.authStoreFacadeService.signUpFormError$;
  }

  public ngOnDestroy(): void {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }

  public signUp(signUpFormValue: SignUpForm): void {
    zip([
      this.authStoreFacadeService.signUpAdditionalParams$.pipe(take(1)),
      this.authStoreFacadeService.awsMarketplaceToken$.pipe(take(1))
    ]).subscribe(([{promoCode}, token]: [any, string]) =>
      this.authStoreFacadeService.signUpFormSubmit({...signUpFormValue}, token, this.isDeveloperMode, promoCode)
    );
  }

  public signIn(): void {
    this.router.navigate([`/auth/${this.isDeveloperMode ? 'developer-' : ''}sign-in`]);
  }
}

import {Team} from '@app/profile/models/team.model';
import * as actions from '@app/profile/store/teams-management/team-management.actions';
import {createReducer, on} from '@ngrx/store';

const initialState: Team[] = [];
const initialStateSelectedTeamId = null;

export const teamManagementReducer = createReducer<Team[]>(
  initialState,
  on(actions.setTeams, (state, {payload}) => {
    const teams = payload.teams.map(team => ({
      teamId: team.id,
      members: 0,
      ...team
    }));
    return teams;
  }),
  on(actions.updateTeamCounterAfterUserDelete, (state, {payload}) => {
    const teams = state.map(team => {
      if (team.id === payload.teamId) {
        return {...team, members: team.members - 1};
      }
      return team;
    });

    return teams;
  }),
  on(actions.editTeamSuccess, (state, {payload}) => {
    const teamToUpdate = {
      ...state.find(team => team.id === payload.teamId),
      name: payload.name,
      updatedAt: payload.updatedAt
    };

    const teams = state.map(team => {
      if (team.id === payload.teamId) {
        return teamToUpdate;
      }
      return team;
    });

    return teams;
  }),
  on(actions.addTeamSuccess, (state, {payload}) => {
    return [...state, payload.team];
  }),
  on(actions.deleteTeamSuccess, (state, {payload}) => {
    return state.filter(team => team.id !== payload.teamId);
  }),
  on(actions.addUsersToTeamSuccess, (state, {payload}) => {
    return state.map(team =>
      team.id === payload.teamId ? {...team, members: team.members + payload.users.length} : team
    );
  }),
  on(actions.inviteUsersSuccess, (state, {payload}) => {
    const teams = payload.reduce((acc, user) => {
      acc[user.teamId] = acc[user.teamId] ? [...acc[user.teamId], user] : [user];
      return acc;
    }, {});

    return state.map(team => {
      if (teams[team.id]) {
        return {...team, members: team.members + teams[team.id].length};
      }
      return team;
    });
  }),
  on(actions.removeUsersToTeamSuccess, (state, {payload}) => {
    const teams = state.map(team => {
      if (team.id === payload.teamId) {
        return {...team, members: team.members - payload.users.length};
      }

      return team;
    });

    return teams;
  }),
  on(actions.saveDashboard, (state, {payload}) => {
    let teams = [...state] || [];

    teams = teams.map(team => {
      const {id, dashboards} = team;
      const newValue = payload.data[id];
      if (newValue != undefined) {
        return {...team, dashboards: [...dashboards, newValue]};
      }
      return team;
    });

    return [...teams];
  })
);

export const selectedTeamIdReducer = createReducer<Team['id']>(
  initialStateSelectedTeamId,
  on(actions.setSelectedTeamId, (state, {payload}) => payload.teamId),
  on(actions.removeSelectedTeamId, () => null)
);

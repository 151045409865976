import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import {selectRouterRedirect} from '@app/store/router/router.selector';
import {from, Observable, of} from 'rxjs';
import {catchError, switchMap, take} from 'rxjs/operators';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';

@Injectable()
export class IsUserAuthNoNavigateGuard {
  constructor(private store: Store, private router: Router, private localStorageService: LocalStorageService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap(isAuth => {
        if (isAuth) {
          return this.store.pipe(
            select(selectRouterRedirect),
            take(1),
            switchMap(redirect => {
              if (redirect.url) {
                return this.router.navigate([redirect.url], {queryParams: redirect.queryParams});
              } else {
                const MFASetupState = this.localStorageService.getItem('MFASetupState');
                if (MFASetupState !== null) {
                  return of(true);
                }
                return this.router.navigate(['secure/profile']);
              }
            })
          );
        }
        return of(!isAuth);
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}

import {Injectable} from '@angular/core';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {DrawStatus} from '@app/shared/image-annotation-shared/models/image-annotation.model';
import {CanvasService} from '@app/shared/image-annotation-shared/services/canvas.service';
import {BehaviorSubject, Observable, Subject, map, take, zip} from 'rxjs';
import {CalibrationLayout, CalibrationType} from '../calibration.component';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {AnalyticsConfig, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {MatDialogRef} from '@angular/material/dialog';
import {ManageZonesDialogComponent} from '@app/shared/manage-zones-dialog/manage-zones-dialog.component';
import {ManageZonesDialogStateService} from '@app/shared/manage-zones-dialog/manage-zones-dialog.state.service';
import {LibraryPageService} from '@app/library/services/library-page.service';
import {ManagerZonesDialogService} from '@app/shared/manage-zones-dialog/services/manager-zones-dialog.service';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {Map} from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class CalibrationService {
  public deviceId = '';
  public addonId = '';
  public imageWidth = 0;
  public imageHeight = 0;

  private pointGenerated = new Subject<void>();
  public pointGenerated$ = this.pointGenerated.asObservable();

  private deletePointByIndex = new Subject<number>();
  public deletePointByIndex$ = this.deletePointByIndex.asObservable();

  private deleteAllPoints = new Subject<void>();
  public deleteAllPoints$ = this.deleteAllPoints.asObservable();

  private drawPoints = new Subject<number[]>();
  public drawPoints$ = this.drawPoints.asObservable();

  private updateDrawStatus = new Subject<DrawStatus>();
  public updateDrawStatus$ = this.updateDrawStatus.asObservable();

  private updateMarkerColor = new Subject<{index: number; color: LabelColorName}>();
  public updateMarkerColor$ = this.updateMarkerColor.asObservable();

  private selectedColor = new BehaviorSubject(LabelColorName.green);
  public selectedColor$ = this.selectedColor.asObservable();
  public selectedColorBak = null;

  private currentLayout = new BehaviorSubject(CalibrationLayout.empty);
  public currentLayout$ = this.currentLayout.asObservable();

  private currentMarkerCoordinates = new BehaviorSubject(null);
  public currentMarkerCoordinates$ = this.currentMarkerCoordinates.asObservable();

  private pointsLatLng = new BehaviorSubject([]);
  public pointsLatLng$ = this.pointsLatLng.asObservable();

  private hightLightMarkerByIndex = new Subject<number>();
  public hightLightMarkerByIndex$ = this.hightLightMarkerByIndex.asObservable();

  private startDrawPerspectiveZone = new Subject<void>();
  public startDrawPerspectiveZone$ = this.startDrawPerspectiveZone.asObservable();

  private getCalibrationShape = new Subject<void>();
  public getCalibrationShape$ = this.getCalibrationShape.asObservable();

  private calibrationShape = new BehaviorSubject<number[][]>([]);
  public calibrationShape$ = this.calibrationShape.asObservable();

  private loadCalibrationPoints = new BehaviorSubject<number[][]>([]);
  public loadCalibrationPoints$ = this.loadCalibrationPoints.asObservable();

  private loadCalibrationZoneDimensions = new BehaviorSubject<{a: number; b: number}>(null);
  public loadCalibrationZoneDimensions$ = this.loadCalibrationZoneDimensions.asObservable();

  private drawPerspectiveZone = new Subject<{points: number[][]; hasToEdit: boolean}>();
  public drawPerspectiveZone$ = this.drawPerspectiveZone.asObservable();

  private stopPerspectiveZoneEdit = new Subject<void>();
  public stopPerspectiveZoneEdit$ = this.stopPerspectiveZoneEdit.asObservable();

  private stopDrawPerspective = new Subject<void>();
  public stopDrawPerspective$ = this.stopDrawPerspective.asObservable();

  private calibrationLabel = new BehaviorSubject<string>('None');
  public calibrationLabel$ = this.calibrationLabel.asObservable();

  public pointsOnScreen = [];
  public markerCounter = 1;
  public sidebarCanvas: CanvasService = null;
  public draggeableMarkerCanvas: CanvasService = null;

  private calibrationSidebarMode: BehaviorSubject<CalibrationSidebarMode> = new BehaviorSubject<CalibrationSidebarMode>(
    CalibrationSidebarMode.POINT_LIST
  );
  public calibrationSidebarMode$ = this.calibrationSidebarMode.asObservable();

  private editPointIndex: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public editPointIndex$ = this.editPointIndex.asObservable();

  public editCanvasCoordinates = null;
  public editMapCoordinates = null;

  public dialogRef: MatDialogRef<ManageZonesDialogComponent>;

  private calibrationMap: BehaviorSubject<Map> = new BehaviorSubject<Map>(null);
  public calibrationMap$ = this.calibrationMap.asObservable();

  private totalSavedPoints: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalSavedPoints$ = this.totalSavedPoints.asObservable();
  public currentDevice$: Observable<UserDeviceJoined>;
  public isPerspectiveCalibration: boolean = false;

  constructor(
    private userStoreFacadeService: UserStoreFacadeService,
    private manageZonesDialogStateService: ManageZonesDialogStateService,
    private libraryPageService: LibraryPageService,
    private managerZonesDialogService: ManagerZonesDialogService,
    private addonStoreFacadeService: AddonStoreFacadeService
  ) {}

  public getDeviceLocation() {
    return this.userStoreFacadeService.getDevice(this.deviceId).pipe(
      map(device => {
        return {lat: device.lat, lng: device.lng};
      })
    );
  }

  public setCalibrationMap(calibrationMap: Map) {
    this.calibrationMap.next(calibrationMap);
  }

  public setDraggeableMarkerCanvas(canvas: CanvasService): void {
    this.draggeableMarkerCanvas = canvas;
  }

  public setEditMapCoordinates(coordinates: {lat: number; lng: number}): void {
    this.editMapCoordinates = coordinates;
  }

  public setEditCanvasCoordinates(coordinates: {x: number; y: number}): void {
    this.editCanvasCoordinates = coordinates;
  }

  public setEditPointIndex(index: number): void {
    this.editPointIndex.next(index);
  }

  public setSidebarCanvas(sidebarCanvas: CanvasService): void {
    this.sidebarCanvas = sidebarCanvas;
  }

  public setCalibrationSidebarMode(calibrationSidebarMode: CalibrationSidebarMode) {
    this.calibrationSidebarMode.next(calibrationSidebarMode);
  }

  public emitPointGenerated(): void {
    this.pointGenerated.next();
  }

  public emitDeletePointByIndex(index: number) {
    this.deletePointByIndex.next(index);
  }

  public deletePointLatLngByIndex(index: number): void {
    const points = this.pointsLatLng.value;
    points.splice(index, 1);
    this.pointsLatLng.next(points);
  }

  public emitDeleteAllPoints() {
    this.deleteAllPoints.next();
  }

  public emitUpdateDrawStatus(drawStatus: DrawStatus): void {
    this.updateDrawStatus.next(drawStatus);
  }

  public updatePointsOnScreen(points: []): void {
    this.pointsOnScreen = points;
  }

  public addPointOnScreen(pointOnScreen: number[]) {
    this.pointsOnScreen.push(pointOnScreen);
  }

  public removePointOnScreen(pointOnScreenIndex: number) {
    this.pointsOnScreen = this.pointsOnScreen.filter((point, index) => index !== pointOnScreenIndex);
  }

  public removeAllPointOnScreen() {
    this.pointsOnScreen = [];
  }

  public setMainInformation(deviceId: string, addonId: string): void {
    this.deviceId = deviceId;
    this.addonId = addonId;
    this.currentDevice$ = this.userStoreFacadeService.getDevice(this.deviceId);
  }

  public setCurrentMarkerCoordinates(x: number, y: number): void {
    this.currentMarkerCoordinates.next({x, y});
  }

  public setImagePoints(image_points): void {
    this.pointsOnScreen = image_points;
    this.drawPoints.next(image_points);
  }

  public increaseMarkerCounter(): void {
    this.markerCounter++;
  }

  public resetMarkerCounter() {
    this.markerCounter = 1;
  }

  public setSelectedColor(color: LabelColorName) {
    this.selectedColor.next(color);
  }

  public setCurrentLayout(addCalibration: CalibrationLayout) {
    this.currentLayout.next(addCalibration);
  }

  public setTotalSavedPoints(totalSavedPoints: number) {
    this.totalSavedPoints.next(totalSavedPoints);
  }

  public updateMarkerColorByIndex(index: number, color?: LabelColorName) {
    this.updateMarkerColor.next({index, color: color || this.selectedColor.value});
  }

  public loadPoints(): void {
    if (this.pointsLatLng.value.length > 0 && this.pointsOnScreen.length > 0) {
      this.markerCounter = 1;
      this.setImagePoints(this.pointsOnScreen);
      this.setPointLatLng(this.pointsLatLng.value);
      return;
    }
    this.userStoreFacadeService
      .getDevice(this.deviceId)
      .pipe(take(1))
      .subscribe((device: UserDeviceJoined) => {
        if (this.imageHeight === 0 || this.imageWidth === 0) {
          return;
        }

        this.emitDeleteAllPoints();
        this.resetMarkerCounter();

        const calibrationPoints =
          device?.analyticsConfig?.[this.addonId]?.analytics?.ul_camera_calibration_analytics?.calibration_points || [];
        const color =
          device?.analyticsConfig?.[this.addonId]?.analytics?.ul_camera_calibration_analytics?.color ||
          LabelColorName.green;
        this.selectedColorBak = color;
        this.selectedColor.next(color);

        const imagePoints = [];
        const pointsLatLng = [];

        this.isPerspectiveCalibration = calibrationPoints.some(
          calibrationPoint => calibrationPoint.x2 || calibrationPoint.y2
        );

        this.setTotalSavedPoints(calibrationPoints.length);

        if (this.isPerspectiveCalibration) {
          const pointsPerspective = [];
          calibrationPoints.forEach(point => {
            pointsPerspective.push([point.x * this.imageWidth, point.y * this.imageHeight]);
          });

          if (pointsPerspective.length === 0) {
            this.emitStartDrawPerspectiveZone();
            return;
          }

          this.loadCalibrationPoints.next(pointsPerspective);
          this.loadCalibrationZoneDimensions.next({a: calibrationPoints[0].x2, b: calibrationPoints[0].y2});
          return;
        }

        calibrationPoints.forEach(point => {
          imagePoints.push([point.x * this.imageWidth, point.y * this.imageHeight]);
          pointsLatLng.push([point.lat, point.lng]);
        });

        this.setImagePoints(imagePoints);
        this.setPointLatLng(pointsLatLng);
      });
  }

  public setPointLatLng(pointsProcessed: number[]): void {
    this.pointsLatLng.next(pointsProcessed);
  }

  public deleteSceneMapping(calibrationType: CalibrationType, analyticsConfig: AnalyticsConfig) {
    this.userStoreFacadeService.deleteSceneMapping(this.deviceId, this.addonId, calibrationType, analyticsConfig);
  }

  public storeImageSize(imageWidth: number, imageHeight: number): void {
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;
  }

  public emitHightLightMarkerByIndex(index: number): void {
    this.hightLightMarkerByIndex.next(index);
  }

  public deleteSceneMappingSuccess(payload: {deviceId: string; addonId: string}): void {
    this.userStoreFacadeService.deleteSceneMappingSuccess(payload);
  }

  public updateAnalyticsConfig(deviceId: string, analyticsConfig: AnalyticsConfig): void {
    this.userStoreFacadeService.updateAnalyticsConfig(deviceId, analyticsConfig);
  }

  public emitStartDrawPerspectiveZone(): void {
    this.startDrawPerspectiveZone.next();
  }

  public emitGetCalibrationShape(): void {
    this.getCalibrationShape.next();
  }

  public emitCalibrationShape(points: number[][]): void {
    this.calibrationShape.next(points);
  }

  public emitDrawPerspectiveZone(points: number[][], hasToEdit: boolean): void {
    this.drawPerspectiveZone.next({points, hasToEdit});
  }

  public emitStopPerspectiveZoneEdit(): void {
    this.stopPerspectiveZoneEdit.next();
  }

  public storePerspectivePoints(points: number[][]): void {
    this.loadCalibrationPoints.next(points);
  }

  public storePerspectiveZoneDimensions(dimensions: {a: number; b: number}): void {
    this.loadCalibrationZoneDimensions.next(dimensions);
  }

  public clearAllReferences(): void {
    this.loadCalibrationZoneDimensions.next(null);
    this.loadCalibrationPoints.next([]);
    this.calibrationShape.next([]);
    this.setEditCanvasCoordinates(null);
    this.setEditMapCoordinates(null);
    this.setPointLatLng([]);
    this.setEditPointIndex(-1);
    this.setMarkerCounter(1);
    this.removeAllPointOnScreen();
    this.restoreSelectedColor();
    this.isPerspectiveCalibration = false;
  }

  public setMarkerCounter(counter: number): void {
    this.markerCounter = counter;
  }

  public confirmDeleteMarkerByIndex(index: number) {
    this.userStoreFacadeService.confirmDeleteMarkerByIndex(index);
  }

  public setCalibrationLabel(hasCalibration: boolean) {
    this.calibrationLabel.next(`Calibration (${hasCalibration ? 'Done' : 'None'})`);
  }

  public emitStopDrawPerspective(): void {
    this.stopDrawPerspective.next();
  }

  public getSelectedColor(): LabelColorName {
    return this.selectedColor.value;
  }

  public restoreSelectedColor(): void {
    this.selectedColor.next(this.selectedColorBak);
  }

  public closeDialog(isPositiveAction: boolean = false): void {
    this.manageZonesDialogStateService.clearRules();
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }

    this.libraryPageService.navigateToPreviousUrl('manager zones', isPositiveAction);
  }

  public analyze(): void {
    zip(
      this.userStoreFacadeService.getDevice(this.deviceId).pipe(take(1)),
      this.addonStoreFacadeService.addons$.pipe(
        take(1),
        map(addons => addons[this.addonId])
      )
    ).subscribe(([device, addon]) => {
      this.managerZonesDialogService.startAi(device, addon);
      this.closeDialog(true);
    });
  }

  public enableEditMode(index: number): void {
    this.setEditPointIndex(index);
    this.setCalibrationSidebarMode(CalibrationSidebarMode.EDIT_CANVAS_POINT);
    this.setInitialEditMapCoordinates(index);
    this.setInitialEditCanvasCoordinates(index);
  }

  public setInitialEditMapCoordinates(index: number) {
    this.pointsLatLng$.pipe(take(1)).subscribe(points => {
      const selectedPoint = points[index];
      this.setEditMapCoordinates({lat: selectedPoint[0], lng: selectedPoint[1]});
    });
  }

  public setInitialEditCanvasCoordinates(index: number): void {
    const point = this.pointsOnScreen[index];
    this.setEditCanvasCoordinates({x: point[0], y: point[1]});
  }
}

export enum CalibrationSidebarMode {
  POINT_LIST = 'POINT_LIST',
  ADD_POINT = 'ADD_POINT',
  EDIT_CANVAS_POINT = 'EDIT_CANVAS_POINT',
  EDIT_MAP_POINT = 'EDIT_MAP_POINT',
  ADD_MARKER_IN_MAP = 'ADD_MARKER_IN_MAP'
}

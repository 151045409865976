import {Injectable} from '@angular/core';
import {PinchZoomComponent} from '@app/shared/pinch-zoom/pinch-zoom.component';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  public readonly DEFAULT_ZOOM_PROPERTIES = {
    doubleTap: true,
    doubleTapScale: 2,
    transitionDuration: 200,
    limitZoom: 'original image size',
    minScale: 0,
    minPanScale: 1.0001,
    wheel: true,
    wheelZoomFactor: 0.2,
    draggableImage: true,
    listeners: 'auto',
    zoomControlScale: 2,
    disablePan: false,
    backgroundColor: null
  };
  private zoomRef: PinchZoomComponent;

  public get zoom(): PinchZoomComponent {
    return this.zoomRef;
  }

  public set zoom(zoom: PinchZoomComponent) {
    this.zoomRef = zoom;
  }

  public setDisablePanConfig(disablePan: boolean): void {
    if (!this.zoom) {
      console.info('pinch-zoom is not available set pan config');
      return;
    }

    this.zoom.pinchZoom.properties = {
      ...this.DEFAULT_ZOOM_PROPERTIES,
      disablePan
    };
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlanModel} from '@app/core/models/api/plan.model';

@Component({
  selector: 'unleash-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoneComponent implements OnInit {
  @Input() plan: PlanModel;
  @Input() isAnnualPrice: boolean;
  @Output() action: EventEmitter<boolean> = new EventEmitter();

  date = new Date();

  constructor() {}

  ngOnInit(): void {}

  clickAction() {
    this.action.emit(true);
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {format} from 'date-fns';
import {DateFormats} from '../models/date-format.model';

@Pipe({
  name: 'strftimeToDateFormatPipe',
  standalone: true
})
export class StrftimeToDateFormatPipe implements PipeTransform {
  public transform(value: Date | string | number, formatString: string): string {
    let dateFormat = formatString;
    if (!formatString) {
      dateFormat = DateFormats.AustralianDateTime as string;
    }

    return format(new Date(value), dateFormat);
  }
}

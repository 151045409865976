import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {DeviceDialog} from '@app/profile/components/device-dialog/device-dialog.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';
import {filter, skip, take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AclPermissions} from '@app/core/models/api/acl.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-device-context-menu',
  templateUrl: './device-context-menu.component.html',
  styleUrls: ['./device-context-menu.component.scss']
})
export class DeviceContextMenuComponent {
  @Input() public device: UserDeviceJoined;
  @Output() public toggleStream: EventEmitter<string> = new EventEmitter();
  @Output() public deviceUpdated: EventEmitter<void> = new EventEmitter();

  public aclPermissions = AclPermissions;

  constructor(
    public atlasService: AtlasService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private clipboard: Clipboard
  ) {}

  public copyLinkAsset(device: UserDeviceJoined): void {
    this.translateService
      .get('atlas.control.deviceShared', {
        name: this.device.name
      })
      .pipe(take(1))
      .subscribe((copyLink: string) => {
        this.snackBar.open(copyLink, null, {
          horizontalPosition: 'center',
          duration: 5000
        });
        const port = environment.name === 'Local' ? ':8080' : '';
        const url = `https://${environment.name.toLowerCase()}.unleashlive.com${port}/secure/atlas?deviceId=${
          device.id
        }&verifyLiveStream=true`;
        this.clipboard.copy(url);
      });
  }

  public showDeviceDialog(device?: UserDeviceJoined): void {
    const dialog = this.dialog.open(DeviceDialog, {
      width: '80vw',
      maxWidth: '800px',
      data: device || {}
    } as MatDialogConfig);
    dialog.componentInstance.isSaving$
      .pipe(
        skip(1),
        filter(isSaving => !isSaving),
        take(1)
      )
      .subscribe(() => this.deviceUpdated.emit());
  }
}

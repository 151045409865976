import {Injectable} from '@angular/core';
import {
  FlightFrame,
  FlightInfo
} from '@app/flights/components/flight-log/flight-log-parser';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {FlightLogDetailData} from '../flight-log-detail-dialog.component';
import {
  displayLoading,
  hideLoading,
  loadFlightLogs,
  loadFlightLogsFail,
  loadFlightLogsSuccess,
  setFlightLogDialogDetailData
} from '../state/flight-log-detail-dialog.actions';
import {
  selectFlightDate,
  selectFlightFrames,
  selectFlightInfo,
  selectIsLoading
} from '../state/flight-log-detail-dialog.selectors';

@Injectable({
  providedIn: 'root'
})
export class FlightLogDetailDialogFacadeService {
  constructor(private store: Store) {}

  public loadFlightLogs(url: string): void {
    this.store.dispatch(loadFlightLogs({payload: {url}}));
  }

  public loadFlightLogsSuccess(flightLogDetailData: FlightLogDetailData): void {
    this.store.dispatch(
      loadFlightLogsSuccess({payload: {data: flightLogDetailData}})
    );
  }

  public loadFlightLogsFail(error: string): void {
    this.store.dispatch(loadFlightLogsFail({payload: {error}}));
  }

  public selectFlightInfo(): Observable<FlightInfo> {
    return this.store.pipe(select(selectFlightInfo));
  }

  public selectFlightFrames(): Observable<FlightFrame[]> {
    return this.store.pipe(select(selectFlightFrames));
  }

  public selectFlightDate(): Observable<Date> {
    return this.store.pipe(select(selectFlightDate));
  }

  public selectIsLoading(): Observable<boolean> {
    return this.store.pipe(select(selectIsLoading));
  }

  public displayLoading(): void {
    this.store.dispatch(displayLoading());
  }

  public hideLoading(): void {
    this.store.dispatch(hideLoading());
  }

  public setFlightLogDialogDetailData(data: FlightLogDetailData): void {
    this.store.dispatch(setFlightLogDialogDetailData({payload: {data}}));
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {environment} from 'environments/environment';

@Directive({
  selector: '[ulExportToGeojson]',
  standalone: true
})
export class ExportToGeojsonDirective {
  @Input('ulExportToGeojson') public asset: AtlasAssetModel;

  constructor() {}

  @HostListener('click', ['$event'])
  public exportToGeojson(): void {
    const asset = this.asset;
    fetch(`${environment.atlasCDNDomain}/${asset.key}`)
      .then(response => response.blob())
      .then(blob => {
        const fileName = asset.name + '.geojson';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
}

import {Injectable} from '@angular/core';
import {authPlanPaid} from '@app/auth/state/auth.actions';
import {AuthFacade} from '@app/auth/state/auth.facade';
import {selectAuthUserId} from '@app/auth/state/auth.selectors';
import {select, Store} from '@ngrx/store';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {share} from 'rxjs/operators';
import {ApiGateway} from '../../core/services/api/api-gateway.service';
import {UserService} from '../../core/services/api/user.service';
import {UserModel} from '@app/core/models/api/user-model';

export type Period = 'year' | 'month';

export enum PAYMENT_PARAMETERS {
  STRIPE_TOKEN = 'stripeToken',
  STRIPE_EMAIL = 'stripeEmail',
  ADDRESS = 'address'
}

export interface StripeCustomerAddress {
  line1?: string;
  city?: string;
  country?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
}

export interface PaymentParameters {
  stripeToken: string;
  stripeEmail: any;
  planId: string;
  period: PaymentPeriod;
  couponCode?: string;
  address?: StripeCustomerAddress;
}

@Injectable()
export class PaymentService {
  private userIdentityId: string;

  constructor(
    private apiGateway: ApiGateway,
    private userService: UserService,
    private store: Store,
    private authFacade: AuthFacade
  ) {
    this.store.pipe(select(selectAuthUserId)).subscribe(userId => {
      this.userIdentityId = userId;
    });
  }

  public createUserSubscription(params: PaymentParameters) {
    if (!this.userIdentityId) {
      throw new Error('User not correctly authenticated');
    }
    const observable = this.apiGateway.post(`subscription/user/${encodeURIComponent(this.userIdentityId)}`, {}, params);
    observable.subscribe((response: UserModel) => {
      console.info('Subscribed user', response);
      // refresh all user data because for payments from public (/plans) page user is not initialized
      this.store.dispatch(authPlanPaid());
      this.userService.findAllUserDevices();
    });
    return observable;
  }

  public deleteUserSubscription() {
    const observable = this.apiGateway
      .delete(`subscription/user/${encodeURIComponent(this.userIdentityId)}`, {}, {})
      .pipe(share());
    observable.subscribe((data: UserModel) => {
      console.log('Unsubscribed user ', this.userIdentityId);
      this.authFacade.authPlanSelectionRequired();
      this.userService.findAllUserDevices();
    });
    return observable;
  }

  checkPromoCode(code: string) {
    const observable = this.apiGateway.get(`subscription/code/${code}`, {}).pipe(share());
    observable.subscribe((validation: {valid: boolean}) => {
      console.log(`Code is ${validation.valid ? 'valid' : 'not valid'}`);
    });
    return observable;
  }
}

<h2 mat-dialog-title data-test-id="dialog-title">{{ 'jobs.confirmDeleteTitle' | translate }}</h2>
<section class="content" mat-dialog-content data-test-id="dialog-content">
  <ng-container [ngSwitch]="data.deleteOption">
    <span *ngSwitchCase="deleteJobsOptions.JOB"> {{ 'jobs.deleteJobContent' | translate: {value: data.name} }}</span>
    <span *ngSwitchCase="deleteJobsOptions.TASK"> {{ 'jobs.deleteTaskContent' | translate: {value: data.name} }}</span>
  </ng-container>
</section>
<section mat-dialog-actions>
  <a [disabled]="isLoading" mat-flat-button mat-dialog-close color="primary" data-test-id="cancel-button">{{ 'jobs.cancel' | translate }}</a>
  <a [disabled]="isLoading" mat-flat-button class="delete" (click)="deleteAction()" data-test-id="delete-button">
    <ng-container [ngSwitch]="data.deleteOption">
      <span *ngSwitchCase="deleteJobsOptions.JOB" data-test-id="delete-job">{{ 'jobs.deleteJob' | translate }}</span>
      <span *ngSwitchCase="deleteJobsOptions.TASK">{{ 'jobs.deleteTask' | translate }}</span>
    </ng-container>
    <unleash-spinner *ngIf="isLoading" diameter="20" borderWidth="2.1" color="#111" data-test-id="loading-spinner"></unleash-spinner>
  </a>
</section>

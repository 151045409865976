import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class I18nPaginatorService extends MatPaginatorIntl {
  readonly changes: Subject<void>;
  firstPageLabel: string;
  itemsPerPageLabel: string;
  lastPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;

  of: string;

  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe(() => this.getTranslations());
    this.getTranslations();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0) {
      return `1 ${this.of} 1`;
    }

    const from: number = page * pageSize + 1;
    const until: number = Math.min(pageSize * (page + 1), length);

    return `${from} - ${until} ${this.of} ${length}`;
  };

  getTranslations(): void {
    this.translateService
      .get([
        'core.paginator.firstPageLabel',
        'core.paginator.itemsPerPageLabel',
        'core.paginator.lastPageLabel',
        'core.paginator.nextPageLabel',
        'core.paginator.previousPageLabel',
        'common.of'
      ])
      .subscribe(translations => {
        const {
          'core.paginator.firstPageLabel': firstPageLabel,
          'core.paginator.itemsPerPageLabel': itemsPerPageLabel,
          'core.paginator.lastPageLabel': lastPageLabel,
          'core.paginator.nextPageLabel': nextPageLabel,
          'core.paginator.previousPageLabel': previousPageLabel,
          'common.of': of
        } = translations;

        this.firstPageLabel = firstPageLabel;
        this.itemsPerPageLabel = itemsPerPageLabel;
        this.lastPageLabel = lastPageLabel;
        this.nextPageLabel = nextPageLabel;
        this.previousPageLabel = previousPageLabel;
        this.of = of;

        this.changes.next();
      });
  }
}

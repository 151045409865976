import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {EVENTS} from '../../../core/services/unleash-analytics.service';
import {AssetsFilterService} from '../../services/assets-filter.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-search-layer',
  templateUrl: './search-layer.component.html',
  styleUrls: ['./search-layer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchLayerComponent implements OnInit {
  public events = EVENTS;
  @Input('searchValue')
  public set setupSearchValue(value: string) {
    this.form.controls.search.setValue(value);
  }
  @Input() public placeholder: string = '';
  @Input('hasAllLayersLoaded') public set setHasAllLayersLoaded(hasAllLayersLoaded: boolean) {
    const searchControl = this.form?.get('search');
    if (hasAllLayersLoaded && searchControl) {
      searchControl.enable();
    }
  }

  @ViewChild('layerSearch')
  public searchElement: ElementRef;

  public form = this.fb.group({
    search: [{value: '', disabled: true}, Validators.required]
  });

  private searchSub: Subscription;
  private searchFilterQuerySub: Subscription;

  constructor(private assetsFilterService: AssetsFilterService, private fb: FormBuilder) {}

  public ngOnInit() {
    this.form.controls.search.setValue('');
    this.searchSub = this.form.controls.search.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(
      value => this.search(value),
      error => this.handleError(error)
    );
    this.searchFilterQuerySub = this.assetsFilterService.searchFilterQuery.subscribe((query: string) => {
      if (query === null) {
        this.closeAndCleanDisplayInput();
      }
    });
  }

  public search(name: string) {
    this.assetsFilterService.updateFilterQuery(name);
  }

  public closeAndCleanDisplayInput() {
    this.form.reset();
    this.assetsFilterService.updateFilterQuery(null);
  }

  public handleError(error: any) {
    // console.log('error', error);
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {AclService} from '@app/core/services/acl.service';
import {combineLatest, filter, map, Observable, of, tap} from 'rxjs';
import {UserStoreFacadeService} from '../services/user-store-facade.service';

@Injectable({
  providedIn: 'root'
})
export class AclCanActivateGuard {
  constructor(
    private aclService: AclService,
    private userStoreFacadeService: UserStoreFacadeService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const extraConditions = route.data.extraConditions;
    return extraConditions ? this.checkExtraConditions(extraConditions, route) : this.checkAclPermission(route);
  }

  // eslint-disable-next-line rxjs/finnish
  private checkExtraConditions(extraConditions: {page: string}, route: ActivatedRouteSnapshot): Observable<boolean> {
    if (extraConditions?.page === 'jobs') {
      return combineLatest([this.userStoreFacadeService.currentUserCompany$, this.checkAclPermission(route)]).pipe(
        map(([company, hasAclPermission]) => !!company?.useUserTask && hasAclPermission),
        tap(permission => {
          if (!permission) {
            this.router.navigate(['secure/dashboard']);
          }
        })
      );
    }
    return of(false);
  }

  // eslint-disable-next-line rxjs/finnish
  private checkAclPermission(route): Observable<boolean> {
    return this.aclService.hasSetupPermission$.pipe(
      filter(hasSetupPermission => !!hasSetupPermission),
      map(() => this.aclService.hasPermission(route.data.permission)),
      tap(permission => {
        if (!permission) {
          this.router.navigate(['secure/dashboard']);
        }
      })
    );
  }
}

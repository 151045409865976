import {ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {ImageAnnotationSharedModule} from '@app/shared/image-annotation-shared/image-annotation-shared.module';
import {CanvasService} from '@app/shared/image-annotation-shared/services/canvas.service';
import {ManagerZonesDialogService} from '@app/shared/manage-zones-dialog/services/manager-zones-dialog.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {Observable, combineLatest, filter, map, shareReplay, take} from 'rxjs';
import {CalibrationService, CalibrationSidebarMode} from '../../services/calibration.service';
import {Marker} from '@app/shared/image-annotation-shared/models/marker';
import {CommonModule} from '@angular/common';
import {SharedPipesModule} from '@app/shared/pipes/shared-pipes.module';
import {ImageViewerMapComponent} from '@app/image-viewer/components/image-viewer-tabs/image-viewer-map/image-viewer-map.component';
import {CalibrationMapComponent} from '../calibration-map/calibration-map.component';
import {PinchZoomModule} from '@app/shared/pinch-zoom/pinch-zoom.module';
import {ZoomService} from '@app/library/services/zoom.service';
import {PinchZoomComponent} from '@app/shared/pinch-zoom/pinch-zoom.component';

@Component({
  selector: 'unleash-edit-calibration-marker',
  templateUrl: './edit-calibration-marker.component.html',
  styleUrls: ['./edit-calibration-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ImageAnnotationSharedModule,
    CommonModule,
    SharedPipesModule,
    ImageViewerMapComponent,
    CalibrationMapComponent,
    PinchZoomModule
  ],
  providers: [CanvasService]
})
export class EditCalibrationMarkerComponent {
  public imageSnapshot$ = this.managerZonesDialogService.imageSnapshot$.pipe(
    filter(data => !!data),
    shareReplay(1)
  );
  public thumblerConfig = THUMBLER_AVAILABLE_CONFIGS.libraryLarge;
  public currentCalibrationSidebarMode$ = this.calibrationService.calibrationSidebarMode$;
  public calibrationSidebarMode = CalibrationSidebarMode;
  public deviceLocation$ = this.calibrationService.getDeviceLocation();
  public selectedColor$ = this.calibrationService.selectedColor$;
  public editPointIndex$ = this.calibrationService.editPointIndex$;
  public points$ = this.calibrationService.pointsLatLng$.pipe(
    map(points => points.map(point => ({lat: point[0], lng: point[1]})))
  );
  @ViewChild('pinchZoom', {static: false, read: PinchZoomComponent}) public zoom: PinchZoomComponent;
  @ViewChild('pinchZoom') public zoomElement: ElementRef;
  public canvasDiagonalWidth$: Observable<number> = this.canvasService.canvasDiagonalWidth$;
  public naturalImageDiagonalWidth$: Observable<number> = this.canvasService.naturalImageDiagonalWidth$;

  constructor(
    private managerZonesDialogService: ManagerZonesDialogService,
    private calibrationService: CalibrationService,
    private canvasService: CanvasService,
    private zoomService: ZoomService
  ) {}

  public changeZoom(): void {
    this.zoomService.zoom = this.zoom;
    this.canvasService.setZoomScale(this.zoomService.zoom.pinchZoom.scale);
    this.calibrationService.storeImageSize(
      this.canvasService.getCanvasSize().width,
      this.canvasService.getCanvasSize().height
    );
  }

  public loadDraggebaleMarker(): void {
    this.calibrationService.setDraggeableMarkerCanvas(this.canvasService);
    combineLatest([this.calibrationService.editPointIndex$, this.calibrationService.selectedColor$])
      .pipe(take(1))
      .subscribe(([editIndex, color]) => {
        const markerTool = this.canvasService.selectMarker({pathSizeX: 25, pathSizeY: 35, fontSize: 17}) as Marker;
        const point = this.calibrationService.editCanvasCoordinates;
        const viewBoxX = point.x;
        const viewBoxY = point.y;
        const textIndex = 1;
        const textInGroup = (markerTool.ref as any).get(textIndex);
        textInGroup.text((editIndex + 1).toString());

        const marker = (markerTool.ref as any).node;

        let isDragging = false;

        // Set limits for the draggable div
        const minX = 0;
        const minY = 0;
        const maxX = this.canvasService.getCanvas().node.clientWidth - marker.clientWidth;
        const maxY = this.canvasService.getCanvas().node.clientHeight - marker.clientHeight;
        let finalViewBoxX = null;
        let finalViewBoxY = null;

        // Event listeners for mouse events
        marker.addEventListener('mousedown', e => {
          isDragging = true;
          marker.style.cursor = 'grabbing';
          this.zoom.pinchZoom.disableDraggable();
        });
        this.canvasService.getCanvas().node.addEventListener('mousemove', e => {
          if (isDragging) {
            const width = this.canvasService.getCanvas().width();
            const height = this.canvasService.getCanvas().height();
            const viewBoxWidth = this.canvasService.getCanvasSize().width;
            const viewBoxHeight = this.canvasService.getCanvasSize().height;

            const scaleX = viewBoxWidth / width;
            const scaleY = viewBoxHeight / height;

            const x = Math.min(Math.max(e.offsetX, minX), maxX);
            const y = Math.min(Math.max(e.offsetY, minY), maxY);
            finalViewBoxX = x * scaleX;
            finalViewBoxY = y * scaleY;

            markerTool.moveMarker(finalViewBoxX, finalViewBoxY);
            const bbox = markerTool.ref.bbox();
            markerTool.ref.dmove(-bbox.width / 2, -bbox.height / 2);
          }
        });

        this.canvasService.getCanvas().node.addEventListener('mouseup', () => {
          this.zoom.pinchZoom.enableDraggable();
          isDragging = false;
          marker.style.cursor = 'grab';
          if (finalViewBoxX !== null) {
            const bbox = markerTool.ref.bbox();
            this.calibrationService.setEditCanvasCoordinates({
              x: finalViewBoxX,
              y: finalViewBoxY + bbox.height / 2
            });
          }
        });

        marker.style.cursor = 'grab';
        markerTool.updateColor(color);
        markerTool.moveMarker(viewBoxX, viewBoxY);
        const bbox = markerTool.ref.bbox();
        markerTool.ref.dmove(-bbox.width / 2, -bbox.height);
      });
  }

  public setMarkerCoordinates({lat, lng}) {
    this.calibrationService.setEditMapCoordinates({lat, lng});
  }

  public emitHightLightMarkerByIndex(index: number): void {
    this.calibrationService.emitHightLightMarkerByIndex(index);
  }
}

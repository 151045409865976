import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {Observable, of} from 'rxjs';
import {Integration} from '../models/integration.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationApiService {
  constructor(private apiGateway: ApiGateway) {}

  listAllIntegrations(): Observable<Integration[]> {
    const integrations: Integration[] = [];

    return of(integrations) as Observable<Integration[]>; // this.apiGateway.get(`/integration/list`, {});
  }
}

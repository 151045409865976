<div>
  <h1 mat-dialog-title>
    <button
      *ngIf="!isDialogMode"
      class="back-button"
      data-test-id="add-user-back-button"
      mat-icon-button
      (click)="emitBack()"
    >
      <mat-icon aria-label="label">arrow_back</mat-icon>
    </button>
    <div class="title">
      {{ isDialogMode ? ('profile.users.inviteUsers' | translate) : ('profile.users.add' | translate) }}
    </div>
  </h1>
  <mat-divider *ngIf="!isDialogMode"></mat-divider>
  <div mat-dialog-content>
    <div class="add-user">
      <ng-container *ngIf="forms.controls?.length > 1; else oneAddUserRowTemplate">
        <unleash-add-user-row
          *ngFor="let form of forms.controls; let index = index"
          [form]="form"
          [roles]="roles"
          [teams]="teams"
          (delete)="deleteUserRow(index)"
        >
        </unleash-add-user-row>
      </ng-container>
      <ng-template #oneAddUserRowTemplate>
        <unleash-add-user-row
          *ngFor="let form of forms.controls; let index = index"
          [form]="form"
          [roles]="roles"
          [teams]="teams"
          [hasDelete]="false"
          (delete)="deleteUserRow(index)"
        >
        </unleash-add-user-row>
      </ng-template>
    </div>
    <button data-test-id="add-another-user-button" mat-button color="primary" (click)="addAnotherUser()">
      <mat-icon>add</mat-icon> {{ 'profile.users.addAnother' | translate }}
    </button>
  </div>
  <div mat-dialog-actions align="end">
    <button
      data-test-id="cancel-button"
      mat-button
      color="primary"
      (click)="isDialogMode ? emitCloseDialog() : emitCancel()"
    >
      {{ 'common.cancel' | translate }}
    </button>
    <button
      data-test-id="invite-button"
      mat-raised-button
      color="primary"
      (click)="isDialogMode ? emitInviteDialog() : emitInvite()"
    >
      {{ 'profile.users.add' | translate }}
    </button>
  </div>
</div>

import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {UserInvitation} from '../models/user-roles.model';
import {UserTileScreen} from '../models/user-tile-screen.model';
import {Roles, UserAction, UserTable, UserTableActions, UserTeam} from '../models/users-table.model';
import {actionAddUsers} from '../store/profile.actions';
import {selectUsersTable} from '../store/profile.selectors';
import {TranslateService} from '@ngx-translate/core';
import {UserActions} from '@app/profile/models/user-actions.model';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import {AclService} from '@app/core/services/acl.service';
import {AclPermissions} from '@app/core/models/api/acl.model';

@Injectable({
  providedIn: 'root'
})
export class UsersAccountManagementService {
  private filter: BehaviorSubject<string> = new BehaviorSubject('');
  public users$: Observable<UserTeam[]> = combineLatest([this.store.pipe(select(selectUsersTable)), this.filter]).pipe(
    map(([users, filter]) => users.filter(user => user?.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
  );
  public hasMoreThanOneAdmin$ = this.users$.pipe(
    // map(users => users.filter(user => user.role === TeamRole.admin)),
    map(adminCount => adminCount.length > 1)
  );

  public usersTable$: Observable<any[]> = this.users$.pipe(
    map(users => {
      const usersFiltered = {};

      for (let index = 0; index < users.length; index++) {
        const user = users[index];
        if (!usersFiltered[user.name]) {
          usersFiltered[user.name] = user;
        }
      }

      return Object.values(usersFiltered).sort((a: UserTeam, b: UserTeam) => a.name?.localeCompare(b?.name));
    })
  );

  public userScreen$: Observable<UserTileScreen> = of(UserTileScreen.users);
  public selectedUser$: Observable<UserTeam> = this.usersManagementStoreFacadeService.selectedUserTable$;

  private userMenuActions: BehaviorSubject<UserTableActions[]> = new BehaviorSubject([]);
  public userMenuActions$: Observable<UserTableActions[]> = this.userMenuActions.asObservable();
  public roles$: Observable<Roles> = this.usersManagementStoreFacadeService.roles$;

  private userScreen: BehaviorSubject<UserTileScreen> = new BehaviorSubject(UserTileScreen.users);

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private usersManagementStoreFacadeService: UsersManagementStoreFacadeService,
    private aclService: AclService
  ) {
    this.userScreen$ = this.userScreen.asObservable();

    this.aclService.hasSetupPermission$
      .pipe(
        filter((hasSetupPermission: boolean) => !!hasSetupPermission),
        take(1)
      )
      .subscribe(() => {
        if (
          this.aclService.hasPermission(AclPermissions.MainApiDeleteUser) &&
          this.aclService.hasPermission(AclPermissions.MainApiUpdateUser)
        ) {
          this.translateService.get('common.delete').subscribe(res =>
            this.userMenuActions.next([
              {
                name: res,
                type: UserActions.delete,
                icon: 'delete',
                iconColor: 'warn'
              }
            ])
          );
        }
      });
  }

  public displayUsersView(): void {
    this.userScreen.next(UserTileScreen.users);
  }

  public displayAddUsersView(): void {
    this.userScreen.next(UserTileScreen.addUsers);
  }

  public displayUserDetails(): void {
    this.userScreen.next(UserTileScreen.userDetails);
  }

  public addUsers(userInvitations: UserInvitation[]) {
    const usersTable: UserTable[] = userInvitations.map(
      user =>
        ({
          name: '',
          email: user.email,
          lastActive: null,
          role: user.role,
          userId: Math.random().toString(),
          createdAt: null
        } as UserTable)
    );

    this.store.dispatch(actionAddUsers({users: usersTable}));
  }

  public filterUsers(search: string): void {
    this.filter.next(search);
  }

  public selectUser(user: UserTable): void {
    this.usersManagementStoreFacadeService.setSelectedUserId(user.userId);
    this.displayUserDetails();
  }

  public removeSelectedUserId(): void {
    this.usersManagementStoreFacadeService.removeSelectedUserId();
  }

  public executeUserAction(userAction: UserAction) {
    switch (userAction.action) {
      case UserActions.delete:
        this.usersManagementStoreFacadeService.deleteUser(userAction.userId);
        break;
      case UserActions.edit:
        // this.store.dispatch(actionEditUserAction());
        break;
      case UserActions.editRole:
        this.usersManagementStoreFacadeService.updateUserTeamRole({
          userId: userAction.userId,
          role: userAction.payload.role
        });
        break;
      default:
        break;
    }
  }
}

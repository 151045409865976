import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  forwardRef,
  OnDestroy,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchInputComponent implements ControlValueAccessor, OnDestroy {
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({search: new UntypedFormControl()});
  @Input() public formControlName: string = 'search';
  @Input() public hasIcon: boolean = true;
  @Input() public customPlaceHolder: string = null;
  @Input() public title: string = null;
  @Input() public active: boolean = false;
  @Input() public mainMenuStyle: boolean = false;
  @Input() public isLargeInput: boolean = false;
  @Input() public whiteVersion: boolean = false;

  @Output() public search: EventEmitter<string> = new EventEmitter();

  @ViewChild('searchInput', {static: true}) public searchInput: ElementRef;

  public isDisabled: boolean = false;
  public value: string = '';
  private inputValueChangesSub: Subscription = new Subscription();
  private searchSubscription: Subscription = new Subscription();
  private searchInputDelay: Subject<string> = new Subject();

  constructor() {
    const DEBOUNCE_TIME = 400;
    this.searchSubscription = this.searchInputDelay
      .asObservable()
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        tap(search => {
          this.search.emit(search);
          this.writeValue(search);
          this.onTouch();
          this.onChange(search);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    if (this.inputValueChangesSub) {
      this.inputValueChangesSub.unsubscribe();
    }
  }

  public searchInputChanges(search: string): void {
    this.searchInputDelay.next(search);
  }

  public onChange = (_: any) => {};
  public onTouch = () => {};

  public writeValue(value: string): void {
    this.value = value;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

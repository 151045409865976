import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {UserModel} from '@app/core/models/api/user-model';
import {PlansService} from '@app/plans/services/plans.service';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {differenceInDays, isAfter} from 'date-fns';
import {environment} from '../../../../environments/environment';
import {PlanModel} from '../../../core/models/api/plan.model';
import {UserService} from '../../../core/services/api/user.service';
import {BrowserSettingsService} from '../../../core/services/browser-settings.service';
import {LoadingDialogComponent} from '../../../profile/components/loading-dialog/loading-dialog.component';
import {PaymentService} from '../../services/payment.service';
import {CancelPlanDialog} from '../cancel-plan/cancel-plan.component';

@Component({
  selector: 'app-plan-confirmation',
  templateUrl: './plan-confirmation.component.html',
  styleUrls: ['./plan-confirmation.component.scss']
})
export class PlanConfirmationDialog implements OnInit {
  static MONTHS_IN_YEAR = 12;
  static CENTS_IN_DOLLAR = 100;

  plan: PlanModel;
  isPlanActive: boolean;
  userUsedTrial: boolean = false;
  couponCode: string;
  days: number = 14;
  annually = true;

  private handler: any;
  private user: UserModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {plan: PlanModel},
    public dialogRef: MatDialogRef<CancelPlanDialog>,
    private dialog: MatDialog,
    private paymentService: PaymentService,
    private userService: UserService,
    public plansService: PlansService,
    public snackBar: MatSnackBar,
    private router: Router,
    private browserSettings: BrowserSettingsService
  ) {
    this.plan = data.plan;
    this.userService.user$.subscribe(user => {
      this.user = user;
      this.isPlanActive = !!user.currentPlan;
      this.userUsedTrial = !!user.trialPeriodEnd ? isAfter(new Date(), user.trialPeriodEnd) : false;
      this.days = !this.userUsedTrial
        ? this.plan.trialDays
        : Math.round(differenceInDays(user.trialPeriodEnd, new Date()));
    });
  }

  ngOnInit() {
    this.handler = (window as any).StripeCheckout.configure({
      key: environment.STRIPE_PUBLISHABLE_KEY,
      image: '/assets/images/unleash-mark--square.png',
      locale: 'auto',
      token: (token, args) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        const progressDialogRef = this.dialog.open(LoadingDialogComponent, {
          data: {text: 'Transaction in progress..'},
          width: '80vw',
          maxWidth: '800px'
        } as MatDialogConfig);
        console.log('succesfuly created token for ' + this.plan.id + ' subscription');
        const subscriptionData = {
          stripeToken: token.id,
          stripeEmail: token.email,
          planId: this.plan.id,
          period: this.annually ? 'year' : 'month'
        } as any;
        if (!!this.couponCode) {
          subscriptionData.couponCode = this.couponCode;
        }
        this.paymentService.createUserSubscription(subscriptionData).subscribe(
          next => {
            this.snackBar.open(`Subscription successful. Thank you!`, null, {
              duration: 5000
            } as MatSnackBarConfig);
            progressDialogRef.close();
            this.userService.updatePlanInUserDataStore(
              this.plan.id,
              this.annually ? PaymentPeriod.annual : PaymentPeriod.month
            );
            this.browserSettings.setOnboardingFinished(true);
            this.dialogRef.close(true);
            this.goToLandingPage();
          },
          err => {
            let errMessage = JSON.stringify(err);
            if (!!err.error) {
              errMessage = JSON.stringify(err.error);
            }
            if (!!err.message) {
              errMessage = err.message;
            }
            progressDialogRef.close();
            this.dialogRef.close(false);
            this.snackBar.open('Transaction failed - ' + errMessage, null, {
              duration: 8000,
              politeness: 'assertive'
            } as MatSnackBarConfig);
          }
        );
      }
    });
  }

  startSubscription() {
    this.handler.open({
      name: 'UnleashLive ',
      description: `${this.plan.planName} subscription`,
      currency: 'usd',
      amount: this.annually
        ? PlanConfirmationDialog.MONTHS_IN_YEAR * this.plan.priceAnnual * PlanConfirmationDialog.CENTS_IN_DOLLAR
        : this.plan.priceMonthly * PlanConfirmationDialog.CENTS_IN_DOLLAR,
      billingAddress: true,
      panelLabel: '{{amount}}' + (this.annually ? '/year' : '/month')
    });
  }

  private goToLandingPage() {
    const landingPage = this.browserSettings.getLandingPage();
    this.router.navigate([landingPage]);
  }
}

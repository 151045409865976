<ng-container *ngIf="!isExpanded && menuItem">
  <mat-list-item
    [routerLink]="menuItem.routerLink"
    [ulRouterLinkActive]="menuItem.routerLink"
    [fragment]="menuItem.fragment"
    [queryParams]="menuItem.queryParams"
    class="menu-item disable-text-selection collapsed custom-tooltip"
  >
    <ng-container *ngTemplateOutlet="imageWrapper"></ng-container>
    <span class="tooltiptext">{{ menuItem.label | translate }}</span>
  </mat-list-item>
</ng-container>

<ng-container *ngIf="isExpanded">
  <mat-list-item
    [routerLink]="menuItem.routerLink"
    [fragment]="menuItem.fragment"
    [queryParams]="menuItem.queryParams"
    [ulRouterLinkActive]="menuItem.routerLink"
    class="menu-item disable-text-selection"
  >
    <ng-container *ngTemplateOutlet="imageWrapper"></ng-container>
    <p class="menu-item-label">{{ menuItem.label | translate }}</p>
  </mat-list-item>
</ng-container>

<ng-template #imageWrapper>
  <div class="img-wrapper">
    <mat-icon
      class="menu-item-img"
      [ngClass]="menuItem.styleClass ? menuItem.styleClass : null"
      [svgIcon]="menuItem.imagePath"
    ></mat-icon>
  </div>
</ng-template>

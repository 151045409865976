import {Directive, HostListener, Input} from '@angular/core';
import {AtlasService} from '../services/atlas.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';

@Directive({
  selector: '[ulSaveLayerVisibility]',
  standalone: true
})
export class SaveLayerVisibilityDirective {
  @Input('ulSaveLayerVisibility') public asset: AtlasAssetModel;

  constructor(private atlasService: AtlasService) {}

  @HostListener('click', ['$event'])
  public saveLayerVisibility($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.atlasService.displayAsset(this.asset.id, !this.asset.isDisplaying);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {FederatedProviders, SignInForm} from './sign-in.model';
import {emailRegex} from '../../regex';

@Component({
  selector: 'unleash-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent {
  @Input() inProgress: boolean;
  @Output() signIn: EventEmitter<Partial<SignInForm>> = new EventEmitter<Partial<SignInForm>>();
  @Output() federatedSignIn: EventEmitter<FederatedProviders> = new EventEmitter<FederatedProviders>();
  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();

  form = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    password: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder) {}

  signInWithEntra() {
    this.federatedSignIn.emit(FederatedProviders.ENTRA);
  }

  clickSignInButton() {
    if (this.form.valid) {
      this.signIn.emit(this.form.value);
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }

  clickForgotYourPasswordButton() {
    this.forgotPassword.emit(true);
  }
}

import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {Observable, share} from 'rxjs';
import {UserTeam} from '@app/profile/models/users-table.model';
import {UserInvitation} from '@app/profile/models/user-roles.model';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private apiGateway: ApiGateway) {}

  public createUsers(users: UserInvitation[]): Observable<UserTeam[]> {
    return this.apiGateway.post('organization/invite/users', {}, users).pipe(share());
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {NotificationLayout, NotificationLevel, NotificationModel} from '@app/core/models/api/notifications.model';
import {HelpService} from '@app/core/services/help.service';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCardComponent implements OnInit {
  @Input()
  notification: NotificationModel;
  @Input()
  layout: NotificationLayout = NotificationLayout.simple;
  @Input()
  hasCloseButton: boolean = false;
  @Output()
  notificationWasSeen: EventEmitter<boolean> = new EventEmitter();
  @Output()
  openNotificationGroup: EventEmitter<NotificationModel[]> = new EventEmitter();
  @Output()
  deleteNotification: EventEmitter<NotificationModel> = new EventEmitter();
  @Output()
  clickAction: EventEmitter<string> = new EventEmitter();
  @Output()
  close: EventEmitter<boolean> = new EventEmitter();

  isOnHoverCard: boolean = false;
  isOnHoverNotReadCardButton: boolean = false;
  notificationLevel = NotificationLevel;
  notificationLayout = NotificationLayout;

  constructor(private helpService: HelpService) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.layout === NotificationLayout.simple) {
      // TODO: We need mark as seen notifications on hover
      /* this.notificationWasSeen.emit(true); */
    }
    this.isOnHoverCard = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isOnHoverCard = false;
  }

  ngOnInit() {
    //
  }

  emitDeleteNotification() {
    this.deleteNotification.emit(this.notification);
  }

  emitReadNotification() {
    if (!this.notification.isRead) {
      this.notificationWasSeen.emit(true);
    }
  }

  closeSidebar(action: string) {
    this.clickAction.emit(action);
  }

  emitClose() {
    this.close.emit(true);
  }

  goToContactUs() {
    this.helpService.goToContactUs();
  }

  getType(actionUrl: any) {
    console.info('actionUrl', actionUrl);
    console.info('typeof actionUrl', typeof actionUrl);
    return typeof actionUrl;
  }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatButtonToggle} from '@angular/material/button-toggle';
import {MatCheckbox} from '@angular/material/checkbox';

@Component({
  selector: 'app-restreaming-to-url',
  templateUrl: './restreaming-to-url.component.html',
  styleUrls: ['./restreaming-to-url.component.scss']
})
export class RestreamingToUrlComponent implements OnInit {
  @Output()
  outputRtmpUrl = new EventEmitter<string>();

  @Output()
  isStreaming = new EventEmitter<boolean>();

  @Output()
  isCheckedOutputStream = new EventEmitter<boolean>();

  isStreamingToUrl: boolean = false;
  isOutputStream: boolean = false;

  streamForm: UntypedFormGroup = new UntypedFormGroup({
    url: new UntypedFormControl({value: '', disabled: this.isStreamingToUrl}, this.urlValidator)
  });

  constructor() {}

  ngOnInit() {}

  showStreamToUrlInput(event: {source: MatCheckbox; checked: boolean}): void {
    this.isOutputStream = event.checked;
    this.isCheckedOutputStream.emit(this.isOutputStream);
  }

  streamingState(event: {source: MatButtonToggle; value: boolean}) {
    if (!this.streamForm.controls['url'].getError('invalidUrl')) {
      this.isStreamingToUrl = !event.value;
      this.isStreaming.emit(this.isStreamingToUrl);
      this.outputRtmpUrl.emit(this.streamForm.controls['url'].value);
    } else {
      this.streamForm.controls['url'].markAsTouched();
      this.streamForm.controls['url'].updateValueAndValidity();
    }
  }

  private urlValidator(control: UntypedFormControl) {
    if (!/^rtmp:\/\/.*/.test(control.value)) {
      return {invalidUrl: true};
    }
    return null;
  }
}

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UploadFileByDeviceComponent} from '@app/shared/dialogs/upload-file-by-device/upload-file-by-device.component';
import {GoogleDriveDialogComponent} from '@app/shared/google-drive-dialog/google-drive-dialog.component';
import {from, merge, Observable} from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import {LibraryUploadService} from '@app/shared/services/upload/library-upload.service';
import {AtlasUploadService} from '@app/shared/services/upload/atlas-upload.service';
import {ImageMetadata} from '@app/library/models/image-metadata.model';
import {UploadFileMetadata} from '@app/library/models/upload-file-metadata.model';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {FlightLogUploadService} from '@app/shared/services/upload/flight-log-upload.service';
import {S3ImportDialogComponent} from '@app/shared/s3-import-dialog/s3-import-dialog.component';
import {UploadOption} from '@app/library/models/upload-option.model';
import {S3ExportDialogComponent} from '@app/shared/s3-export-dialog/s3-export-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesFacadeService {
  public isUploading$: Observable<boolean> = merge(
    this.libraryUploadService.isUploading$,
    this.atlasUploadService.isUploading$
  );
  public isProcessingFiles$: Observable<boolean> = this.atlasUploadService.isProcessingFiles;
  public totalProgress$: Observable<number> = this.atlasUploadService.totalProgress;

  constructor(
    private dialog: MatDialog,
    private unleashAnalytics: UnleashAnalyticsService,
    private libraryUploadService: LibraryUploadService,
    private atlasUploadService: AtlasUploadService,
    private flightLogUploadService: FlightLogUploadService
  ) {}

  // eslint-disable-next-line rxjs/finnish
  public openUploadFilesDialog(dialogData: {
    numberOfFilesToUpload: number;
    devices: UserDeviceJoined[];
    deviceId: string;
    totalFilesSize: string;
    hasToShowCreateFolderOption: boolean;
    userName: string;
  }): Observable<any> {
    const data = {
      deviceId: dialogData.deviceId,
      isFastUpload: false,
      devices: dialogData.devices,
      numberOfFilesToUpload: dialogData.numberOfFilesToUpload,
      totalFilesSize: dialogData.totalFilesSize,
      hasToShowCreateFolderOption: dialogData.hasToShowCreateFolderOption,
      userName: dialogData.userName
    };
    const uploadFilesDialogRef = this.dialog.open<UploadFileByDeviceComponent, UploadFileMetadata, UploadFileMetadata>(
      UploadFileByDeviceComponent,
      {
        ...STANDARD_DIALOG_CONFIG,
        width: '80vw',
        maxWidth: '800px',
        minHeight: '361px',
        data
      }
    );
    return uploadFilesDialogRef.afterClosed();
  }

  // eslint-disable-next-line rxjs/finnish
  public openUploadFilesByGoogleDrive(): Observable<any> {
    const uploadFilesGoogleDriveDialogRef = this.dialog.open(GoogleDriveDialogComponent, {
      width: '80vw',
      maxWidth: '800px',
      data: {uploadType: UploadOption.googleDrive}
    });

    return uploadFilesGoogleDriveDialogRef.afterClosed();
  }

  public openUploadFilesBySkydio(): Observable<any> {
    const uploadFilesSkydioDialogRef = this.dialog.open(GoogleDriveDialogComponent, {
      width: '80vw',
      maxWidth: '800px',
      data: {uploadType: UploadOption.skydio}
    });

    return uploadFilesSkydioDialogRef.afterClosed();
  }

  // eslint-disable-next-line rxjs/finnish
  public openImportFromS3Dialog(): Observable<any> {
    const openImportFromS3DialogRef = this.dialog.open(S3ImportDialogComponent, {
      width: '80vw',
      maxWidth: '800px'
    });

    return openImportFromS3DialogRef.afterClosed();
  }

  public openExportS3Dialog(): Observable<any> {
    const openExportS3DialogRef = this.dialog.open(S3ExportDialogComponent, {
      width: '80vw',
      maxWidth: '800px'
    });

    return openExportS3DialogRef.afterClosed();
  }

  /**
   * @deprecated The method should not be used
   */
  public matchDeviceWithMetadata(imageMetadata: ImageMetadata, devices: UserDeviceJoined[]): string {
    if (!imageMetadata) {
      return '';
    }
    const matchDeviceByModelAndMake = (d: UserDeviceJoined) =>
      d.model === imageMetadata.model && d.make === imageMetadata.make;
    const device = devices.find(matchDeviceByModelAndMake);

    if (device) {
      return device.id;
    }
    return '';
  }

  // eslint-disable-next-line rxjs/finnish
  public startUpload(files: File[], metadata: UploadFileMetadata): Observable<void> {
    try {
      const fileCount = files.length;
      const sessionId = uuidv4();
      this.unleashAnalytics.logEvent(EVENTS.UPLOAD_FILES_START, {sessionId, fileCount});
      return fromPromise(this.libraryUploadService.addToQueue(files, metadata));
    } catch (e) {
      this.unleashAnalytics.logEvent(EVENTS.UPLOAD_ERROR, e);
      console.error(e);
    }
  }

  // eslint-disable-next-line rxjs/finnish
  public startFolderUpload(payload: {files: File[]; metadata: UploadFileMetadata}[]): Observable<void[]> {
    console.info('startFolderUpload', payload);
    try {
      const fileCount = payload.map(fileWithMeta => fileWithMeta.files.length).reduce((prev, curr) => prev + curr, 0);
      const folderCount = payload.length;
      const sessionId = uuidv4();
      this.libraryUploadService.setupFoldersCount(folderCount);
      this.unleashAnalytics.logEvent(EVENTS.UPLOAD_FOLDER_START, {sessionId, fileCount, folderCount});

      return from(Promise.all(payload.map(data => this.libraryUploadService.addToQueue(data.files, data.metadata))));
    } catch (e) {
      this.unleashAnalytics.logEvent(EVENTS.UPLOAD_ERROR, e);
      console.error(e);
    }
  }

  public async startUploadInAtlas(files: File[], metadata: any): Promise<void> {
    try {
      await this.atlasUploadService.addToQueue(files, metadata);
    } catch (e) {
      this.unleashAnalytics.logEvent(EVENTS.UPLOAD_ERROR, e);
      console.error(e);
    }
  }

  public async startUploadFlightLog(files: File[], metadata: any): Promise<void> {
    try {
      await this.flightLogUploadService.addToQueue(files, metadata);
    } catch (e) {
      this.unleashAnalytics.logEvent(EVENTS.UPLOAD_ERROR, e);
      console.error(e);
    }
  }

  public generateFoldersFromFiles(teamId: string, parentId: string, fileList: File[]): any {
    const folders = {};

    const createFolder = (pathId: string, folderName: string, newParentId: string) => {
      if (!folders[pathId]) {
        folders[pathId] = {
          id: pathId,
          name: folderName,
          parentId: newParentId || teamId,
          location: newParentId || teamId,
          files: []
        };
      }
    };

    fileList.forEach((file: File) => {
      const path = file.webkitRelativePath || (file as any).fullPath;
      const pathTokens = path.split('/').slice(0, -1);
      let newParentId: string | undefined;

      if (parentId && parentId !== teamId) {
        newParentId = parentId;
      }

      for (let i = 0; i < pathTokens.length; i++) {
        const pathId = pathTokens.slice(0, i + 1).join('/');
        const folderName = pathTokens[i];
        createFolder(pathId, folderName, i > 0 ? pathTokens.slice(0, i).join('/') : newParentId);
      }
    });

    fileList.forEach((file: File) => {
      const path = file.webkitRelativePath || (file as any).fullPath;
      const routePath = path.split('/').slice(0, -1).join('/');
      folders[routePath]?.files.push(file);
    });
    return folders;
  }

  public setAtlasUploadTimeout(timeout: number) {
    this.atlasUploadService.isProcessingFiles.next(true);
    this.atlasUploadService.totalProgress.next(0);
    this.atlasUploadService.atlasTimeout = timeout;
  }
}

import {NgForOf, NgIf} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-start-stream-card',
  standalone: true,
  imports: [NgIf, MatIconModule, MatCardModule, NgForOf, MatButtonModule, RouterLink],
  templateUrl: './start-stream-card.component.html',
  styleUrls: ['./start-stream-card.component.scss']
})
export class StartStreamCardComponent implements OnInit {
  @Input()
  card: StartStreamCard;

  constructor() {}

  ngOnInit() {}
}

export interface StartStreamCard {
  title: string;
  description: string;
  actions: ButtonAction[];
  icon: string;
  svgIcon?: string;
}

export interface ButtonAction {
  name: string;
  route?: string;
  click?: () => any;
}

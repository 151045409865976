import {FlightFrame, FlightInfo} from '@app/flights/components/flight-log/flight-log-parser';
import {createReducer, on} from '@ngrx/store';
import {FlightLogDetailData} from '../flight-log-detail-dialog.component';
import * as FlightLogDetailDialogActions from './flight-log-detail-dialog.actions';

export const flightLogDetailDialogFeatureKey = 'dialog';

export interface State {
  flightInfo: FlightInfo;
  flightFrames: FlightFrame[];
  flightDate: Date;
  isLoading: boolean;
}

export const initialState: State = {
  flightInfo: {} as FlightInfo,
  flightFrames: null,
  flightDate: null,
  isLoading: false
};

export const reducer = createReducer(
  initialState,
  on(
    FlightLogDetailDialogActions.loadFlightLogsSuccess,
    (state: State, {payload}: {payload: {data: FlightLogDetailData}}) => ({
      ...state,
      ...payload.data
    })
  ),
  on(FlightLogDetailDialogActions.displayLoading, (state: State) => ({
    ...state,
    isLoading: true
  })),
  on(FlightLogDetailDialogActions.hideLoading, (state: State) => ({
    ...state,
    isLoading: false
  }))
);

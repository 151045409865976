import {createFeatureSelector, createSelector} from '@ngrx/store';
import {orderBy} from 'lodash';
import {LibraryItem} from '../models/folder-file.model';
import {AnnotationsSummaryData} from '../models/library-api-list-response.model';
import {LIBRARY_ITEM_TYPE_MODEL} from '../models/mime-type.model';
import {Tag} from '../models/tag.model';
import * as fromNewLibrary from './library.reducer';
import {LabelColor} from '@app/shared/image-annotation-shared/models/colors';

export const selectNewLibraryState = createFeatureSelector<fromNewLibrary.State>(fromNewLibrary.libraryFeatureKey);
export const selectLibraryState = createSelector(selectNewLibraryState, (state: fromNewLibrary.State) => state.library);

export const selectListViewSorted = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  const listView = state.listView.itemsIds.map(id => {
    return state.libraryItems[id];
  });

  return listView.sort((libraryItemA, libraryItemB) => {
    if (libraryItemA.metadata?.specialFolderResults || libraryItemA.metadata?.specialFolderArchive) {
      return -1;
    }

    return libraryItemB.createdAt - libraryItemA.createdAt;
  });
});

export const selectLibraryIsLoading = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.isLoading
);

export const selectIsLoadingAnnotationsSummary = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.isLoadingAnnotationsSummary
);

export const selectLibraryFolderPath = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => [
  ...state.path
]);

export const selectLibraryItem = (libraryItemId: string) =>
  createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
    return state.libraryItems[libraryItemId];
  });

export const selectTagWithId = (tagId: string) =>
  createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
    return state.tags[tagId];
  });

export const selectSelectedItems = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return state.listView.itemsIds
    .filter((libraryItemId: LibraryItem['id']) => state.libraryItems[libraryItemId].isSelected)
    .map((libraryItemId: LibraryItem['id']) => state.libraryItems[libraryItemId]);
});

export const selectSelectedModel = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.selectedModel
);

export const selectSelectedLibraryItem = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState): LibraryItem => state.selectedLibraryItem
);

export const selectHasSelectedAllCurrentViewItems = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => {
    const selectedItems = Object.values(state.libraryItems).filter((item: LibraryItem) => item.isSelected);
    return selectedItems.length !== 0 && selectedItems.length === state.listView.itemsIds.length;
  }
);

export const selectNextToken = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return state.listView.nextToken;
});

export const selectTags = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return Object.values(state.tags);
});

export const selectTagsObject = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return state.tags;
});

export const selectTagsColors = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  const tagsColors = {};
  Object.values(state.tags).forEach((tag: Tag) => {
    tagsColors[tag.id] = tag.metadata?.color || LabelColor.grey;
  });

  return tagsColors;
});

export const selectCurrentPath = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState): string[] => state.path
);

export const selectMetadataIsLoading = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.isLoadingMetadata
);

export const selectAvailableModels = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState): LibraryItem[] => orderBy(state.availableModels, 'name', 'asc')
);

export const selectIsMapLoading = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.isMapLoading
);

export const selectCurrentParentId = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.currentParentId
);

export const selectIsSelectionMode = createSelector(
  selectLibraryState,
  selectSelectedItems,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[]) => selectedItems.length > 0
);

export const selectIsActionHeaderMode = createSelector(
  selectLibraryState,
  selectSelectedItems,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[]) => selectedItems.length > 1
);

export const selectHasAnalyzeOption = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.hasAnalyzeOption
);

export const selectHasExportAnnotationsOption = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => {
    return Object.values(state.libraryItems)
      .filter((item: LibraryItem): boolean => item.isSelected)
      .some((item: LibraryItem): boolean => !item.metadata?.isPanoramic && (item.type === 'F' || item.type === 'I'));
  }
);

export const selectHasAddToAtlasOption = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return Object.values(state.libraryItems)
    .filter((item: LibraryItem): boolean => item.isSelected)
    .some((item: LibraryItem): boolean => item.type === 'F' || item.type === 'I');
});

export const selectAiVideoMenu = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return [...state.aiVideoMenu].sort((a, b) => b.createdAt - a.createdAt);
});

export const selectIsLoadingAiVideoMenu = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.isLoadingAiVideoMenu
);

export const selectSuggestedTags = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return prepareSuggestedTags(Object.values(state.tags).slice(), Object.values(state.libraryItems));
});

export const selectActiveTags = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return prepareActiveTags(Object.values(state.tags).slice(), Object.values(state.libraryItems));
});

export const activeAnnotationSummaryTags = (tagIds: string[]) =>
  createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
    return Object.values(state.tags).filter(tag => tagIds.includes(tag.id));
  });

export const selectLibraryItemForSidebar = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.libraryItemForSidebar
);

export const selectActiveTagsForSidebar = createSelector(
  selectLibraryState,
  selectActiveTags,
  (state: fromNewLibrary.LibraryState, activeTags: {[key: string]: Tag[]}) =>
    activeTags[state.libraryItemForSidebar?.id || null]
);

export const selectActiveTagsForSelectionBar = createSelector(
  selectLibraryState,
  selectActiveTags,
  (state: fromNewLibrary.LibraryState, activeTags: {[key: string]: Tag[]}) => {
    const selectedLibraryItems = Object.values(state.libraryItems).filter((item: LibraryItem) => item.isSelected);
    const libraryItemsWithTags = selectedLibraryItems.filter((item: LibraryItem) => {
      const tags = item.tags?.split('#');
      if (tags && tags[0].includes('aiResultsNum')) {
        tags.shift();
      }
      return tags?.length > 0;
    });
    return selectedLibraryItems.length === libraryItemsWithTags.length
      ? [...new Set(selectedLibraryItems.map((item: LibraryItem): Tag[] => activeTags[item.id]).flat())]
      : [];
  }
);

export const selectImagesFromLibraryItems = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  const images: LibraryItem[] = [];
  const libraryItems: {[key: string]: LibraryItem} = state.libraryItems;
  for (const property in libraryItems) {
    if (libraryItems[property].type === LIBRARY_ITEM_TYPE_MODEL.IMAGE) {
      images.push(libraryItems[property]);
    }
  }
  return images;
});

export const selectTagsLoading = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return state.tagsLoading;
});

export const selectAnnotationsSummaryItems = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) =>
  state.annotationsSummaryItemsIds.map((id: string) => state.libraryItems[id])
);

export const selectAnnotationsSummaryData = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.annotationsSummaryData
);

export const selectActiveAddonsAnnotationsSummaryData = createSelector(
  selectLibraryState,
  selectAnnotationsSummaryData,
  (state: fromNewLibrary.LibraryState, selectedItems: AnnotationsSummaryData[]) =>
    selectedItems.map(annotationSummaryItem => annotationSummaryItem.addonId)
);

export const selectAnnotations = (libraryItemId: string) =>
  createSelector(
    selectLibraryState,
    (state: fromNewLibrary.LibraryState) => state.libraryItems[libraryItemId].annotations
  );

export const selectFilters = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => state.filters);

const prepareSuggestedTags = (tags: Tag[], libraryItems: LibraryItem[]): Tag[] => {
  if (!tags.length) {
    return [];
  }
  const suggestedTagsObject = new Map();
  tags.sort((tagA, tagB) => tagB.updatedAt - tagA.updatedAt).forEach(tag => (suggestedTagsObject[tag.id] = 0));

  libraryItems.forEach(libraryItem => {
    if (!libraryItem.tags || libraryItem.tags.length === 0) {
      return;
    }

    const itemTags = libraryItem.tags.split('#');
    itemTags.forEach(tag => (suggestedTagsObject[tag] = suggestedTagsObject[tag] + 1));
  });

  const suggestedTagsIds = Object.entries(suggestedTagsObject)
    .sort((tagA, tagB) => tagB[1] - tagA[1])
    .reduce((accu, curr, idx) => {
      const MAX_TAG_ID_LOCATION_INDEX = 3;
      if (idx < MAX_TAG_ID_LOCATION_INDEX) {
        accu.push(curr[0]);
      }
      return accu;
    }, []);

  return suggestedTagsIds.map(id => tags.find(tag => tag.id === id));
};

const prepareActiveTags = (tags: Tag[], libraryItems: LibraryItem[]): {[key: string]: Tag[]} => {
  if (!tags.length) {
    return {};
  }
  const activeTags = {};
  libraryItems.forEach(item => {
    activeTags[item.id] = item.tags
      ? item.tags
          .split('#')
          .map((tagId: string) => tags.find((tag: Tag) => tag.id === tagId))
          .filter(Boolean)
      : [];
  });

  return activeTags;
};

export const totalImagesSelected = createSelector(
  selectLibraryState,
  selectSelectedItems,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[]) =>
    selectedItems.filter((item: LibraryItem) => item.type === LIBRARY_ITEM_TYPE_MODEL.IMAGE).length
);

export const isOnlyItemsTypeImageSelected = createSelector(
  selectLibraryState,
  selectSelectedItems,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[]) =>
    selectedItems.every((item: LibraryItem) => {
      return item.type === LIBRARY_ITEM_TYPE_MODEL.IMAGE;
    })
);

export const selectCurrentAddonCategories = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.currentAddonCategories
);

export const selectActiveAnnotationsSummaryData = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.activeAnnotationsSummaryData
);
export const hasSelectionAFolder = createSelector(
  selectLibraryState,
  selectSelectedItems,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[]) =>
    selectedItems.some((item: LibraryItem) => {
      return item.type === LIBRARY_ITEM_TYPE_MODEL.FOLDER;
    })
);

export const hasPanoramaSelected = createSelector(
  selectLibraryState,
  selectSelectedItems,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[]) =>
    selectedItems.some((item: LibraryItem) => {
      return item.metadata?.isPanoramic;
    })
);

export const selectAnnotationsSummaryActiveTags = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.annotationsSummaryActiveTags
);

export const selectSeverityHeaderButtons = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.severityHeaderButtons
);

export const selectAnnotationsSummaryChips = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.annotationsSummaryChips
);

export const selectHasInitialAnnotationsSummaryData = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.hasInitialAnnotationsSummaryData
);

export const annotationsSummaryHasImages = createSelector(
  selectLibraryState,
  (state: fromNewLibrary.LibraryState) => state.annotationsSummaryHasImages
);

export const isFilterActive = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  const totalActiveFilters = state.currentAddonCategories
    .map(categories => Object.values(categories.severities))
    .flat(1)
    .filter(severity => severity.isActive).length;
  return !!totalActiveFilters || !!state.annotationsSummaryActiveTags.length;
});

export const libraryItemForContextMenu = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return state.libraryItems[state.libraryItemFromContextMenu] || ({} as LibraryItem);
});

export const selectSpecialFoldersIds = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  if (state?.specialLibraryItems?.assetAssign) {
    return Object.keys(state.specialLibraryItems.assetAssign);
  }

  return [];
});

export const hasSelectedSpecialFolders = createSelector(
  selectLibraryState,
  selectSelectedItems,
  libraryItemForContextMenu,
  (state: fromNewLibrary.LibraryState, selectedItems: LibraryItem[], contextLibraryItem) => {
    if (state?.specialLibraryItems?.assetAssign) {
      const foldersContainsSpecialItemsIds = Object.keys(state.specialLibraryItems.assetAssign);
      const allSpecialItemIds = Object.values(state.specialLibraryItems.assetAssign).reduce((acc, curr) => {
        return acc.concat(curr);
      }, []);
      const archiveFoldersIds = allSpecialItemIds
        .reduce((accu, ids) => accu.concat(ids), [])
        .filter(id => state.libraryItems[id]?.metadata?.specialFolderArchive === true);

      let isSpecialContextMenuItem = false;

      if (Object.keys(contextLibraryItem).length > 0) {
        isSpecialContextMenuItem = foldersContainsSpecialItemsIds.some(specialId => {
          if (specialId === contextLibraryItem.parentId) {
            return (
              contextLibraryItem?.metadata?.specialFolderArchive ||
              contextLibraryItem?.metadata?.specialFolderResults ||
              false
            );
          }

          return allSpecialItemIds.find(specialItem => `${contextLibraryItem.location}`.includes(specialItem));
        });

        const isCurrentLibraryItemPartOfArchive = archiveFoldersIds.some(archiveFolderId =>
          `${contextLibraryItem.location}`.includes(archiveFolderId)
        );

        return isCurrentLibraryItemPartOfArchive ? false : isSpecialContextMenuItem;
      }

      const hasSpecialSelectedItems = selectedItems.some(libraryItem =>
        foldersContainsSpecialItemsIds.some(specialId => {
          if (specialId === libraryItem.parentId) {
            return libraryItem?.metadata?.specialFolderArchive || libraryItem?.metadata?.specialFolderResults || false;
          }

          return allSpecialItemIds.find(specialItem => `${libraryItem.location}`.includes(specialItem));
        })
      );

      const isCurrentLibraryItemPartOfArchive = selectedItems.some(libraryItem =>
        archiveFoldersIds.some(archiveFolderId => `${libraryItem.location}`.includes(archiveFolderId))
      );

      return isCurrentLibraryItemPartOfArchive ? false : hasSpecialSelectedItems;
    }

    return false;
  }
);

export const selectLibraryViewMode = createSelector(selectLibraryState, (state: fromNewLibrary.LibraryState) => {
  return state.viewMode;
});

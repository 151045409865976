<ng-container *ngIf="isLoading$ | async; else tableTemplate">
  <unleash-loader [visible]="true"></unleash-loader>
</ng-container>
<ng-template #tableTemplate>
  <table class="table" mat-table [dataSource]="dataSource" [trackBy]="trackById" class="mat-elevation-z8">
    <ng-container matColumnDef="device">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let device">
        <span fxLayoutAlign="start center">
          <div class="device">
            <img
              class="img"
              *ngIf="device.logo"
              [src]="device.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo"
              alt="logo"
            />
            <mat-icon class="icon" *ngIf="!device.logo">live_tv</mat-icon>
          </div>
          <span class="device-description" fxLayout="column">
            <span>{{ device.name }}</span>
            <span class="device-type">{{ device.description }}</span>
          </span>
        </span>
      </td>
    </ng-container>
    <ng-container *uaHasPermission="aclPermissions.LivestreamApiStreamPublish" matColumnDef="restream">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.device-table.headers.0' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <button
          data-test-id="start-restreaming-button"
          mat-icon-button
          *ngIf="!device.isLive && !!device.sourceUrlSet && !device.isRestreamingFrom"
          (click)="verifyStartStream(device)"
        >
          <img src="assets/icons/live-stream/device-table/stream-start.svg" class="filter-blue" alt="stream" />
        </button>
        <button
          data-test-id="stop-restreaming-button"
          mat-icon-button
          *ngIf="(!!device.isLive && !!device.sourceUrlSet) || device.isRestreamingFrom"
          (click)="stopStream(device)"
        >
          <img src="assets/icons/live-stream/device-table/stream-stop.svg" class="filter-black" alt="stream" />
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.device-table.headers.1' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <a mat-icon>
          <img
            src="assets/icons/live-stream/device-table/status.svg"
            matTooltip="{{ device.statsRestreamingFrom }} {{ device.statsRestreamingTo }}"
            class="{{
              device.isLive || device.isRestreamingFrom || device.isRestreamingTo ? 'filter-green' : 'filter-gray'
            }}"
            alt="Stream status"
          />
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="stream">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.device-table.headers.2' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <a mat-icon>
          <img
            src="assets/icons/live-stream/device-table/live.svg"
            [ngClass]="{'filter-red': device.isLive, 'filter-gray': !device.isLive}"
            alt="live"
          />
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="addToViewport">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.device-table.headers.3' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <a mat-icon class="display-icon" [ngClass]="{disabled: !device.isLive}">
          <img
            src="assets/icons/live-stream/device-table/add-to-viewport.svg"
            (click)="addToCinemaViewport(device)"
            class="mat-icon"
            [ngClass]="{'filter-green is-disabled': device.isDisplayed, 'filter-gray': !device.isLive}"
            alt="add to viewport"
          />
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="ai">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.device-table.headers.4' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <a mat-icon>
          <img
            src="assets/icons/live-stream/device-table/ai-app.svg"
            [ngClass]="{
              'filter-black': device.runningModels && device.runningModels.length > 0,
              'filter-gray': !device.runningModels
            }"
            alt="ai app"
          />
        </a>
      </td>
    </ng-container>

    <ng-container *uaHasPermission="aclPermissions.MainApiUpdateDevice">
      <ng-container matColumnDef="manage">
        <th mat-header-cell *matHeaderCellDef>{{ 'live.device-table.headers.5' | translate }}</th>
        <td mat-cell *matCellDef="let device">
          <mat-menu #settingsMenu="matMenu">
            <button
              mat-menu-item
              (click)="toggleFavourite(device)"
              [analyticsTrackerType]="events.DEVICE_FAVOURITED"
              [analyticsIf]="!device.isFavourite"
            >
              <img
                src="assets/icons/live-stream/device-table/star.svg"
                class="mat-icon"
                [ngClass]="{'filter-yellow-favourite': device.isFavourite, 'filter-gray': !device.isFavourite}"
                alt="favourite"
              />
              <span>{{ 'live.device-table.toggler.favourite' | translate }}</span>
            </button>
            <button
              mat-menu-item
              [disabled]="!device.isLive || device.id === (selectedDeviceId$ | async)"
              (click)="addToCinemaViewport(device)"
              [analyticsTrackerType]="events.DEVICE_ADDED_TO_CINEMA"
              [analyticsIf]="!device.isLive || (device.id === (selectedDeviceId$ | async) && !device.isDisplayed)"
            >
              <img
                src="assets/icons/live-stream/device-table/add-to-viewport.svg"
                class="mat-icon"
                [ngClass]="{'filter-green is-disabled': device.isDisplayed, 'filter-gray': !device.isDisplayed}"
                alt="add to viewport"
              />
              <span>{{ 'live.device-table.toggler.add' | translate }}</span>
            </button>
            <button mat-menu-item (click)="showDeviceDialog(device)">
              <img
                src="assets/icons/live-stream/device-table/settings.svg"
                class="mat-icon filter-black-gray"
                alt="settings"
              />
              <span>{{ 'live.device-table.toggler.settings' | translate }}</span>
            </button>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
            <img src="assets/icons/live-stream/device-table/settings.svg" class="filter-blue" alt="settings" />
          </button>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-template>

export interface SlideshowItem {
  index: number;
  backgroundImageSrc: string;
}

export interface SlideshowItems {
  [key: string]: SlideshowItem;
}

export interface AnimationOption {
  width: string;
  padding: string;
  left: string;
}

export interface AnimationOptions {
  [key: string]: AnimationOption;
}

export const NON_DEVELOPER_SLIDESHOW_ITEMS: SlideshowItems = {
  'sign-up': {index: 0, backgroundImageSrc: '/assets/images/onboarding/sign-up.jpg'},
  'sign-in': {index: 1, backgroundImageSrc: '/assets/images/onboarding/sign-in.jpg'},
  'your-profile': {index: 2, backgroundImageSrc: '/assets/images/onboarding/profile.jpg'},
  plan: {index: 3, backgroundImageSrc: '/assets/images/onboarding/plan.jpg'},
  payment: {index: 4, backgroundImageSrc: '/assets/images/onboarding/payment.jpg'},
  confirm: {index: 5, backgroundImageSrc: '/assets/images/onboarding/confirm.jpg'},
  reason: {index: 6, backgroundImageSrc: '/assets/images/onboarding/confirm.jpg'},
  welcome: {index: 6, backgroundImageSrc: '/assets/images/onboarding/confirm.jpg'},
  'new-password': {index: 7, backgroundImageSrc: '/assets/images/onboarding/payment.jpg'},
  'forgot-password': {index: 8, backgroundImageSrc: '/assets/images/onboarding/confirm.jpg'},
  'forgot-password-2': {index: 9, backgroundImageSrc: '/assets/images/onboarding/profile.jpg'},
  logout: {index: 10, backgroundImageSrc: '/assets/images/onboarding/plan.jpg'},
  'confirm-registration': {index: 11, backgroundImageSrc: '/assets/images/onboarding/confirm.jpg'}
};

export const DEVELOPER_SLIDESHOW_ITEMS: SlideshowItems = {
  'developer-sign-up': {index: 0, backgroundImageSrc: '/assets/images/onboarding/developer-sign-up.jpg'},
  'developer-sign-in': {index: 1, backgroundImageSrc: '/assets/images/onboarding/sign-in.jpg'},
  'developer-confirm-registration': {
    index: 2,
    backgroundImageSrc: '/assets/images/onboarding/developer-confirm-registration.jpg'
  },
  'developer-profile': {index: 3, backgroundImageSrc: '/assets/images/onboarding/developer-profile.jpg'},
  'developer-payment': {index: 4, backgroundImageSrc: '/assets/images/onboarding/developer-payment.jpg'},
  'new-password': {index: 5, backgroundImageSrc: '/assets/images/onboarding/payment.jpg'},
  'developer-forgot-password': {index: 6, backgroundImageSrc: '/assets/images/onboarding/confirm.jpg'},
  'developer-forgot-password-2': {index: 7, backgroundImageSrc: '/assets/images/onboarding/profile.jpg'},
  logout: {index: 8, backgroundImageSrc: '/assets/images/onboarding/plan.jpg'}
};

export const ANIMATION_OPTIONS: AnimationOptions = {
  xs: {width: '100%', padding: '24px 32px 96px 32px', left: '0%'},
  sm: {width: '100%', padding: '24px 32px 96px 32px', left: '0%'},
  md: {width: '70%', padding: '48px 48px 96px 96px', left: '30%'},
  lg: {width: '70%', padding: '48px 48px 96px 160px', left: '30%'},
  xl: {width: '70%', padding: '48px 48px 96px 160px', left: '30%'}
};

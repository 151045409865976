import {ZonesConfigBackup, ZoneConfigData, AddonZones} from '@app/shared/annotation-shared/models/annotations.model';
import {ImageSnapshot} from '@app/shared/manage-zones-dialog/model/manager-zones.model';
import {createAction, props} from '@ngrx/store';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {TriggerPoint} from '@app/shared/trigger-point/trigger-point.model';

enum ManagerZonesActionTypes {
  WatchDrawShapesOnCanvas = '[Manager Zones] Watch draw shapes on canvas',
  SetZonesConfigData = '[Manager Zones] Set zones config data',

  SetZonesConfigBackup = '[Manager Zones] Set zones config backup',
  UpdateCurrentZonesConfigBackup = '[Manager Zones] Update current zones config backup',

  CanvasIsReady = '[Manager Zones] Canvas is ready',
  CanvasIsNotReady = '[Manager Zones] Canvas is not ready',

  ClearImageSnapshot = '[Manager Zones] Clear image snapshot',
  SetImageSnapshot = '[Manager Zones] Set image snapshot',

  InitZones = '[Manager Zones] Init zones',
  SetZones = '[Manager Zones] Set zones',
  ClearZones = '[Manager Zones] Clear zones',
  RemoveZone = '[Manager Zones] Remove zone',
  AddZone = '[Manager Zones] Add zone',
  updateZone = '[Manager Zones] Update zone',
  UpdateZoneName = '[Manager Zones] Update zone name',

  SetSelectedZoneId = '[Manager Zones] Set selected zone id',
  StorePhysicalDimensions = '[Manager Zones] Store physical dimensions',
  UpdateCurrentColor = '[Manager Zones] Update current color',
  StoreDisplayName = '[Manager Zones] Store display name',
  UpdateCurrentColorByZone = '[Manager Zones] Update current color by zone',
  PrepareHint = '[Manager Zones] Prepare hint',
  SetHint = '[Manager Zones] Set hint',
  HideInfo = '[Manager Zones] Hide hint',
  SetTriggerPoint = '[Manager Zones] Set trigger point',
  SetIsZonesLoading = '[Manager Zones] Set is zones loading',
  ToggleIn = '[Manager Zones] Toggle in',
  ToggleOut = '[Manager Zones] Toggle out',
  AtLeastOneInOutPositionEnabled = '[Manager Zones] At least one in-out position enabled'
}

export const watchDrawShapesOnCanvas = createAction(ManagerZonesActionTypes.WatchDrawShapesOnCanvas);

export const setZonesConfigData = createAction(
  ManagerZonesActionTypes.SetZonesConfigData,
  props<{payload: {zonesConfigData: ZoneConfigData}}>()
);

export const setZonesConfigBackup = createAction(
  ManagerZonesActionTypes.SetZonesConfigBackup,
  props<{payload: {zonesConfigBackup: ZonesConfigBackup}}>()
);

export const updateCurrentZonesConfigBackup = createAction(
  ManagerZonesActionTypes.UpdateCurrentZonesConfigBackup,
  props<{payload: {addonZones: AddonZones}}>()
);

export const canvasIsReady = createAction(ManagerZonesActionTypes.CanvasIsReady);
export const canvasIsNotReady = createAction(ManagerZonesActionTypes.CanvasIsNotReady);

export const setImageSnapshot = createAction(
  ManagerZonesActionTypes.SetImageSnapshot,
  props<{payload: {imageSnapshot: ImageSnapshot}}>()
);
export const clearImageSnapshot = createAction(ManagerZonesActionTypes.ClearImageSnapshot);

export const initZones = createAction(ManagerZonesActionTypes.InitZones);
export const setZones = createAction(ManagerZonesActionTypes.SetZones, props<{payload: {zones: Zone[]}}>());
export const clearZones = createAction(ManagerZonesActionTypes.ClearZones);
export const removeZone = createAction(ManagerZonesActionTypes.RemoveZone, props<{payload: {zone: Zone}}>());
export const addZone = createAction(ManagerZonesActionTypes.AddZone, props<{payload: {zone: Zone}}>());
export const updateZone = createAction(
  ManagerZonesActionTypes.updateZone,
  props<{payload: {property: Partial<Zone>; zoneId: string}}>()
);
export const updateZoneName = createAction(
  ManagerZonesActionTypes.UpdateZoneName,
  props<{payload: {displayName: string; zoneId: string}}>()
);
export const setSelectedZoneId = createAction(
  ManagerZonesActionTypes.SetSelectedZoneId,
  props<{payload: {zoneId: string}}>()
);

export const storePhysicalDimensions = createAction(
  ManagerZonesActionTypes.StorePhysicalDimensions,
  props<{payload: {perspectiveValues: {a: number; b: number}}}>()
);

export const storeDisplayName = createAction(
  ManagerZonesActionTypes.StoreDisplayName,
  props<{payload: {displayName: string}}>()
);

export const updateCurrentColor = createAction(
  ManagerZonesActionTypes.UpdateCurrentColor,
  props<{payload: {color: {name: LabelColorName; background: LabelColorName; stroke: string; fill: string}}}>()
);

export const updateCurrentColorByZone = createAction(
  ManagerZonesActionTypes.UpdateCurrentColorByZone,
  props<{
    payload: {color: {name: LabelColorName; background: LabelColorName; stroke: string; fill: string}; zone: Zone};
  }>()
);

export const prepareHint = createAction(
  ManagerZonesActionTypes.PrepareHint,
  props<{payload: {shapeType: ShapeTypes; action?: string}}>()
);

export const setHint = createAction(ManagerZonesActionTypes.SetHint, props<{payload: {hint: string}}>());
export const hideHint = createAction(ManagerZonesActionTypes.HideInfo);

export const setTriggerPoint = createAction(
  ManagerZonesActionTypes.SetTriggerPoint,
  props<{payload: {blockName: TriggerPoint; zoneId: string}}>()
);

export const setIsZonesLoading = createAction(
  ManagerZonesActionTypes.SetIsZonesLoading,
  props<{payload: {isZonesLoading: boolean}}>()
);

export const toggleIn = createAction(ManagerZonesActionTypes.ToggleIn);
export const toggleOut = createAction(ManagerZonesActionTypes.ToggleOut);

export const atLeastOneInOutPositionEnabled = createAction(ManagerZonesActionTypes.AtLeastOneInOutPositionEnabled);

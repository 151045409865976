import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ConfirmDeleteDialog} from '@app/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import {featureGroup, FeatureGroup, Map} from 'leaflet';
import 'leaflet-draw';
import {Subject} from 'rxjs';

declare var L; // leaflet global
const drawIconMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 24),
    iconSize: new L.Point(24, 24),
    iconUrl: 'assets/icons/draw-pin.png',
    draggable: true
  }
});

@Injectable({
  providedIn: 'root'
})
export class DrawOnMapService {
  emitOnMoveShape = new Subject<any>();
  emitSelectedShape = new Subject<any>();
  emitDeletedShape = new Subject<any>();
  emitDrawStart = new Subject<any>();
  emitOnDeleteMode = new Subject<any>();

  private isOnDeleteMode: boolean = false;
  private areControlsVisible: boolean = false;
  private drawnItems: FeatureGroup = featureGroup();
  private drawControl: any;
  private editHandler: any;
  private map: Map;
  private matDialog: MatDialog;

  constructor() {}

  public disableDrawHandlers(map): void {
    map.off(L.Draw.Event.DRAWVERTEX);
    map.off(L.Draw.Event.EDITSTART);
    map.off(L.Draw.Event.EDITSTOP);
    map.off(L.Draw.Event.CREATED);
    map.off(L.Draw.Event.EDITREMOVEVERTEX);
    map.off(L.Draw.Event.EDITCLICKVERTEX);
    map.off(L.Draw.Event.EDITVERTEX);
    map.off(L.Draw.Event.EDITED);
    map.off(L.Draw.Event.DELETED);
  }

  public static addCustomPolyVerticesEdit() {
    L.Edit.PolyVerticesEdit = L.Edit.PolyVerticesEdit.extend({
      options: {
        newVertexClass: 'custom-route-waypoint',
        iconSize: [8, 8],
        touchIcon: new L.DivIcon({
          className: 'custom-route-waypoint',
          iconSize: [20, 20]
        })
      }
    });
  }

  public restorePolyVerticesEdit() {
    L.Edit.PolyVerticesEdit = L.Edit.PolyVerticesEdit.extend({
      options: {
        touchIcon: new L.DivIcon({
          iconSize: new L.Point(20, 20),
          className: 'leaflet-div-icon leaflet-editing-icon leaflet-touch-icon'
        })
      }
    });
  }

  public customJobPolyVerticesEdit() {
    L.Edit.PolyVerticesEdit = L.Edit.PolyVerticesEdit.extend({
      options: {
        middleWaypointClass: 'job-shape-edit',
        newVertexClass: 'job-shape-vertex',
        touchIcon: new L.DivIcon({
          iconSize: new L.Point(20, 20),
          className: 'job-shape-vertex'
        })
      }
    });
  }

  public initialize(map: Map, dialog) {
    this.restorePolyVerticesEdit();
    this.matDialog = dialog;
    this.map = map;
    this.areControlsVisible = false;
    this.disableDrawHandlers(map);
    this.listenEditEvents(map);

    this.drawControl = new L.Control.Draw({
      draw: {
        circle: false,
        polyline: true,
        marker: {
          icon: new drawIconMarker()
        },
        polygon: {
          showLength: true,
          showArea: true,
          allowIntersection: false // madatory false to show area,
        },
        rectangle: false,
        circlemarker: false
      },
      options: {
        color: '#f3e401',
        weight: 3,
        opacity: 1,
        fill: true
      },
      edit: {
        featureGroup: this.drawnItems,
        edit: false
      }
    });

    const toolBar = new L.EditToolbar({
      featureGroup: this.drawnItems
    });
    // enable manual edit
    this.editHandler = toolBar.getModeHandlers()[0].handler;
    this.editHandler._map = map;
    this.initializeRemoveAllHandler();
  }

  getDrawItems() {
    return this.drawnItems;
  }

  setDrawItems(drawnItems: any) {
    this.drawnItems = drawnItems;
  }

  clearDrawItems() {
    this.drawnItems.eachLayer(layer => layer.remove());
    this.drawnItems.clearLayers();
  }

  getDrawControl() {
    return this.drawControl;
  }

  setDrawControl(drawControl: any) {
    this.drawControl = drawControl;
  }

  showDrawControl(map: Map) {
    if (!map.hasLayer(this.drawnItems)) {
      map.addLayer(this.drawnItems);
    }

    if (!this.areControlsVisible) {
      this.map.addControl(this.drawControl);
      this.areControlsVisible = true;
    }
  }

  public removeDrawControl(): void {
    if (!this.drawControl) {
      return;
    }
    this.map.removeControl(this.drawControl);
    this.areControlsVisible = false;
  }

  unselectShape(shape: any) {
    if (!shape) {
      this.emitSelectedShape.next(null);
      this.editHandler?.disable();
      return;
    }
    shape.editing.disable();
    this.emitSelectedShape.next(null);
    this.editHandler.disable();
  }

  clearEventListeners(map: Map) {
    if (!map) {
      return;
    }

    map.off(L.Draw.Event.CREATED);
    map.off(L.Draw.Event.EDITED);
    map.off(L.Draw.Event.DELETED);
    map.off('draw:drawstart');
    map.off('draw:deletestart');
    map.off('draw:deletestop');
  }

  private listenEditEvents(map: Map) {
    map.on(L.Draw.Event.CREATED, this.onShapeCreatedEvent.bind(this));
    map.on(L.Draw.Event.EDITED, this.onShapeEditedEvent.bind(this));
    map.on(L.Draw.Event.DELETED, this.onShapeDeletedEvent.bind(this));
    map.on('draw:drawstart', this.onDrawStart.bind(this));
    map.on('draw:deletestart', this.onDeleteStart.bind(this));
    map.on('draw:deletestop', this.onDeleteStop.bind(this));
  }

  private onShapeCreatedEvent(e: any) {
    const {layerType, layer} = e;
    // show markers
    layer.on('click', (e: any) => {
      if (!this.isOnDeleteMode) {
        // I was necessary patch leaflet to avoid setStyle issue.
        this.drawnItems.eachLayer((layers: any) => {
          layers.off('edit');
          layers.off('dragend');
          if (layers.editing) {
            layers.editing.disable();
          }
        });
        e.target.editing.enable();
        if (layerType === 'polyline' || layerType === 'polygon') {
          e.target.on('edit', (event: any) => {
            this.emitOnMoveShape.next({shape: e.target, layerType});
          });
        } else {
          e.target.on('dragend', (event: any) => {
            this.emitOnMoveShape.next({shape: e.target, layerType});
          });
        }
        this.emitSelectedShape.next(e.target);
      }
    });

    // Add new layer for each shape
    this.drawnItems.addLayer(layer);
    this.editHandler.enable();

    // click on created shape
    layer.fireEvent('click');
  }

  private onShapeEditedEvent(e: any) {
    // on edited
  }

  private onShapeDeletedEvent(e: any) {
    if (!e || !e.layers) {
      return;
    }
    this.emitDeletedShape.next(Object.keys(e.layers._layers));
  }

  private onDrawStart(e: any) {
    this.emitDrawStart.next(true);
  }

  private onDeleteStart(e: any) {
    this.emitOnDeleteMode.next(true);
    this.isOnDeleteMode = true;
  }

  private onDeleteStop(e: any) {
    this.emitOnDeleteMode.next(false);
    this.isOnDeleteMode = false;
  }

  private initializeRemoveAllHandler(): void {
    const dialogHandler = this.matDialog;
    L.EditToolbar.DeleteAll = L.EditToolbar.Delete.extend({
      /** @Override() */
      removeAllLayers() {
        this._removeAllLayersWithConfirm();
      },
      _removeAllLayersWithConfirm() {
        dialogHandler
          .open(ConfirmDeleteDialog, {
            disableClose: true, // required to fix on mouse off click
            data: {items: [`${this._deletableLayers.getLayers().length} shapes`]}
          } as MatDialogConfig)
          .afterClosed()
          .subscribe((confirmed: boolean) => {
            this._deletedLayers = new L.LayerGroup();
            if (confirmed) {
              // Iterate of the delateable layers and add remove them
              if (this._deletableLayers) {
                this._deletableLayers.eachLayer(layer => {
                  this._deletableLayers.removeLayer(layer);
                  this._deletedLayers.addLayer(layer);
                });
                this._deletedLayers.eachLayer(layer => {
                  layer.fire('deleted');
                });
              }
              this.save();
            }
          });
      }
    });
    L.EditToolbar.Delete = L.EditToolbar.DeleteAll;
  }
}

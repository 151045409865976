import {AfterViewInit, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MenuItem} from '@app/core/models/gui/menu-item';
import {MenuItemTypeEnum} from '@app/core/models/gui/menu-item-type.enum';
import {SidebarStateService} from '../../../services/sidebar-state.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {BehaviorSubject, Observable, Subscription, combineLatest, filter, map, take, tap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Team, TeamRole} from '@app/profile/models/team.model';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {UserModel} from '@app/core/models/api/user-model';
import {AuthFacade} from '@app/auth/state/auth.facade';
import {LayoutCheckerService} from '@app/core/services/layout-checker.service';
import {MediaObserver} from '@angular/flex-layout';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements AfterViewInit {
  @Input() public hasMobileHeaderLabel: boolean;

  // eslint-disable-next-line
  public menuItemTypes = MenuItemTypeEnum;
  public userTeams$: Observable<{[key: string]: Team}> = this.userStoreFacadeService.userTeams$;
  public totalUserTeams$: Observable<number> = this.userTeams$.pipe(
    map((teams: {[key: string]: Team}) => Object.keys(teams).length)
  );
  public currentTeamId$: Observable<Team['id']> = this.userStoreFacadeService.currentTeamId$;
  public userName$: Observable<string> = this.userStoreFacadeService.currentUser$.pipe(
    map((user: UserModel) => user.fullName)
  );
  public companyName$: Observable<string> = this.userStoreFacadeService.currentUserCompany$.pipe(
    map(company => company.name)
  );
  public avatar$: Observable<string> = this.userName$.pipe(
    map((userName: string) => {
      const splitName = userName.split(' ');
      const initials = splitName[1] ? splitName[0].charAt(0) + splitName[1].charAt(0) : splitName[0].charAt(0);
      return initials.toUpperCase();
    })
  );
  public userRole$: Observable<TeamRole> = this.userStoreFacadeService.currentTeam$.pipe(
    filter((team: Team) => !!team),
    map((team: Team) => team.role)
  );
  public canLoadMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public menuItems$: Observable<MenuItem[]> = this.sideNavbarStateService.menuItems$.pipe(
    filter((menuItems: MenuItem[]) => menuItems.length > 0),
    tap(() => {
      this.canLoadMenu.next(true);
    })
  );
  public isMobileView: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isMobileViewSub: Subscription;

  constructor(
    public sideNavbarStateService: SidebarStateService,
    private snackBar: MatSnackBar,
    private userStoreFacadeService: UserStoreFacadeService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private authFacade: AuthFacade,
    private layoutCheckerService: LayoutCheckerService,
    private observableMedia: MediaObserver
  ) {
    this.userStoreFacadeService.currentUser$.pipe(take(1)).subscribe(user => {
      const savedExpandedSidebarState = this.localStorageService.getItem(`sidebar-state-${user.id}`);
      this.sideNavbarStateService.expanded = savedExpandedSidebarState !== null ? savedExpandedSidebarState : true;
    });
    this.isMobileViewSub = this.observableMedia
      .asObservable()
      .pipe(
        tap(change => {
          const indexFirstPriorityMedia = 0;
          const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
          this.isMobileView.next(mediaAlias === 'sm' || mediaAlias === 'xs');
        })
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    const isCurrentTeamSwitched = this.localStorageService.getItem('team-switched');
    if (!this.sideNavbarStateService.expanded || this.isMobileView.value || isCurrentTeamSwitched) {
      this.displayCurrentTeam(isCurrentTeamSwitched);
    }
  }

  public trackByFn(index: number, menu: MenuItem) {
    return index;
  }

  public switchCurrentTeam(teamId: string): void {
    this.userStoreFacadeService.switchCurrentTeam(teamId);
  }

  public goToManageTeams(): void {
    this.userStoreFacadeService.goToManageTeams();
  }

  public toggleSidebar(): void {
    this.sideNavbarStateService.toggleExpanded();
    this.setSidebarState();
  }

  public logOut(): void {
    this.userStoreFacadeService.currentUser$.pipe(take(1)).subscribe((user: UserModel) => {
      this.authFacade.authLogout(user.developer);
    });
  }

  public closeMobileNav(): void {
    this.sideNavbarStateService.toggleMobileMenu();
    this.hasMobileHeaderLabel
      ? this.layoutCheckerService.isShowFooterNav$.next(false)
      : this.layoutCheckerService.isShowFooterNav$.next(true);
  }

  private setSidebarState(): void {
    this.userStoreFacadeService.currentUser$.pipe(take(1)).subscribe(user => {
      this.localStorageService.setItem(`sidebar-state-${user.id}`, this.sideNavbarStateService.expanded);
    });
  }

  private displayCurrentTeam(teamSwitched?: boolean): void {
    combineLatest([
      this.userStoreFacadeService.currentTeamId$,
      this.userStoreFacadeService.userTeams$.pipe(filter(teams => Object.keys(teams).length > 0)),
      this.translateService.get(teamSwitched ? 'core.sidebar.switchTeam' : 'core.sidebar.teamMessage')
    ])
      .pipe(take(1))
      .subscribe(([currentTeamId, teams, translation]) => {
        const currentTeamName = teams[currentTeamId].name;
        this.snackBar.open(`${translation} ${currentTeamName}`, null, {
          duration: 3000,
          panelClass: 'team-snackbar'
        });
        if (teamSwitched) {
          this.localStorageService.setItem('team-switched', null);
        }
      });
  }
}

<form [formGroup]="form" (submit)="clickConfirmButton()" class="form">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'auth.confirm.code' | translate }}</mat-label>
    <input
      matInput
      formControlName="code"
      autofocus="true"
      autocomplete="off"
      type="tel"
      pattern="[0-9]*"
      data-test-id="codeInput"
    />
    <mat-error *ngIf="(form.controls.code.invalid && form.controls.code.touched) || form.controls.code.dirty">{{
      'auth.confirm.codeRequired' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-error class="confirm-error" *ngIf="error">
    {{ error.message }}
  </mat-error>

  <div class="buttons">
    <button
      type="button"
      class="form__item re-send-button"
      mat-stroked-button
      color="primary"
      (click)="clickReSendButton()"
      [appLadda]="isResendInProgress"
      [disabled]="isResendInProgress"
      data-test-id="resendCodeButton"
      data-spinner-color="blue"
    >
      {{ 'auth.confirm.resend' | translate }}
    </button>
    <button
      class="form__item confirm-button"
      mat-raised-button
      color="primary"
      [appLadda]="isInProgress"
      [disabled]="isInProgress"
      data-test-id="confirmButton"
    >
      {{ 'auth.confirm.confirmCode' | translate }}
    </button>
  </div>
</form>

import {forgotPasswordStepTwoStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {ForgotPasswordStepTwoFormState} from './forgot-password-step-two.reducers';

export const selectForgotPasswordStepTwoFormValue = createSelector(
  forgotPasswordStepTwoStateSelector,
  (state: ForgotPasswordStepTwoFormState) => state.form
);

export const selectForgotPasswordStepTwoFormInProgress = createSelector(
  forgotPasswordStepTwoStateSelector,
  (state: ForgotPasswordStepTwoFormState) => state.inProgress
);

export const selectForgotPasswordStepTwoFormError = createSelector(
  forgotPasswordStepTwoStateSelector,
  (state: ForgotPasswordStepTwoFormState) => state.error
);

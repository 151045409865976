import {createAction, props} from '@ngrx/store';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';

export enum AtlasActionTypes {
  LoadAtlasItems = '[Atlas] Load items from database',
  SaveItems = '[Atlas] Save items to local store'
}

export const loadAtlasItems = createAction(AtlasActionTypes.LoadAtlasItems);

export const saveItems = createAction(AtlasActionTypes.SaveItems, props<{payload: {atlasItems: AtlasAssetModel[]}}>());

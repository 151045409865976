import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AtlasAssetModel, AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Map} from 'leaflet';
import {BehaviorSubject, Observable, Subscription, debounceTime, map} from 'rxjs';
import {GroupList} from '../add-to-group/add-to-group.component';
import {AssetsFilterService} from '@app/atlas/services/assets-filter.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-move-markers-dialog',
  templateUrl: './move-markers-dialog.component.html',
  styleUrls: ['./move-markers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveMarkersDialogComponent {
  public form: FormGroup = this.fb.group({
    destination: new FormControl(null, Validators.required),
    newLayerName: new FormControl(null, Validators.required),
    existingLayer: new FormControl(null, Validators.required),
    search: new FormControl(null),
    groupName: new FormControl(null),
    customGroupName: new FormControl(null),
    isInProgress: new FormControl(false)
  });
  public moveMarkerDestination: typeof MoveMarkerDestination = MoveMarkerDestination;
  public hasToShowSpinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public availableLayers: BehaviorSubject<AtlasGeojsonAssetModel[]> = new BehaviorSubject<AtlasGeojsonAssetModel[]>([]);
  public groups$: Observable<GroupList[]>;
  public selectedGroupIndex: number = -1;
  private searchSubscription: Subscription;
  private destinationSubscription: Subscription;
  private groupNameSubscription: Subscription;
  private customGroupNameSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<MoveMarkersDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentLayer: AtlasGeojsonAssetModel;
      layers: AtlasAssetModel[];
      isCopyDialog: boolean;
      map: Map;
      hasToDisableCheckbox: boolean;
    },
    private assetsFilterService: AssetsFilterService
  ) {
    this.availableLayers.next(this.data.layers);
    this.detectSearchChanges();
    this.detectGroupNameChanges();
    this.setGroups();
  }

  private detectGroupNameChanges(): void {
    this.groupNameSubscription = this.form.controls.groupName.valueChanges.subscribe(groupName => {
      if (!groupName) {
        return;
      }
      this.form.controls.customGroupName.setValue(null);
    });
    this.customGroupNameSubscription = this.form.controls.customGroupName.valueChanges.subscribe(customGroupName => {
      if (!customGroupName) {
        return;
      }
      this.form.controls.groupName.setValue(null);
    });
  }

  private setGroups() {
    this.groups$ = this.assetsFilterService.assets$.pipe(
      map(assets =>
        assets
          .filter(asset => !!asset.groupName)
          .map(asset => ({name: asset.groupName, customColorIndex: asset.customColorIndex, isSelected: false}))
      ),
      map(assets =>
        assets.reduce((accumulator, item) => {
          const existingItem = accumulator.find(existing => existing.name === item.name);
          if (existingItem) {
            existingItem.customColorIndex =
              existingItem.customColorIndex === item.customColorIndex ? item.customColorIndex : -1;
          } else {
            accumulator.push(item);
          }

          return accumulator;
        }, [])
      )
    );
  }

  public searchDevice(event) {
    this.availableLayers.next(
      this.data.layers.filter(device => device.name.toLowerCase().includes(event.toLowerCase()))
    );
  }

  public setHasToShowSpinner(hasToShowSpinner: boolean) {
    this.hasToShowSpinner.next(hasToShowSpinner);
    if (hasToShowSpinner === false) {
      this.dialogRef.close();
    }
  }

  public isActionDisabled(): boolean {
    return this.form.controls.destination.value === MoveMarkerDestination.EXISTING_LAYER
      ? !this.form.controls.existingLayer.valid
      : !this.form.controls.newLayerName.valid;
  }

  private detectSearchChanges(): void {
    this.searchSubscription = this.form.controls.search.valueChanges
      // eslint-disable-next-line no-magic-numbers
      .pipe(debounceTime(100))
      .subscribe((searchText: string) => this.searchDevice(searchText));
  }
}

export enum MoveMarkerDestination {
  NEW_LAYER,
  EXISTING_LAYER
}

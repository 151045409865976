import {Injectable} from '@angular/core';
import {MissionImportCSVService} from '@app/flights/services/mission-import-csv.service';
import {MissionImportJSONService} from '@app/flights/services/mission-import-json.service';
import {MissionImportKMLService} from '@app/flights/services/mission-import-kml.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MissionImportService {
  constructor(
    private importCsvService: MissionImportCSVService,
    private importJsonService: MissionImportJSONService,
    private importKmlService: MissionImportKMLService,
    public snackBar: MatSnackBar
  ) {}

  public async importFile(file: File, content: string) {
    let mission;
    try {
      switch (file.type) {
        case 'text/csv':
          mission = await this.importCsvService.doImport(content);
          break;
        case 'application/json':
          mission = await this.importJsonService.doImport(content);
          break;
        case 'application/vnd.google-earth.kml+xml':
          mission = await this.importKmlService.doImport(content);
          break;
        case '':
          //KML type file in windows has empty mimetype
          mission = await this.importKmlService.doImport(content);
          break;
        default:
          throw new Error('Unsupported file type');
      }
      return mission;
    } catch (e) {
      this.snackBar.open(e, null, {
        duration: 3000
      } as MatSnackBarConfig);
    }
  }
}

import {Component} from '@angular/core';
import {CalibrationService} from '../../services/calibration.service';
import {map, take} from 'rxjs';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {CalibrationLayout, CalibrationType} from '../../calibration.component';
import {MatDividerModule} from '@angular/material/divider';
import {TranslateModule} from '@ngx-translate/core';
import {AclDirective} from '@app/core/directives/acl.directive';
import {AclPermissions} from '@app/core/models/api/acl.model';

@Component({
  selector: 'ua-calibration-sidebar-list',
  templateUrl: './calibration-sidebar-list.component.html',
  styleUrls: ['./calibration-sidebar-list.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    NgIf,
    TranslateModule,
    AclDirective
  ]
})
export class CalibrationSidebarListComponent {
  public selectedColor$ = this.calibrationService.selectedColor$;
  public points$ = this.calibrationService.pointsLatLng$.pipe(map(points => points.length));
  public calibrationZoneDimensions$ = this.calibrationService.loadCalibrationZoneDimensions$.pipe(
    map(points => {
      if (!points || (!!points && !points.a && !points.b)) {
        return null;
      }
      return `${points.a}cm x ${points.b}cm`;
    })
  );

  public aclPermissions = AclPermissions;
  public dialogRef = this.calibrationService.dialogRef;

  constructor(private calibrationService: CalibrationService) {}

  public openAddCalibration(): void {
    this.calibrationZoneDimensions$.pipe(take(1)).subscribe(dimensions => {
      const layout = !!dimensions ? CalibrationLayout.addPerspectiveCalibration : CalibrationLayout.addCalibration;
      if (layout === CalibrationLayout.addPerspectiveCalibration) {
        this.calibrationService.emitDeleteAllPoints();
      }
      this.calibrationService.setCurrentLayout(layout);
    });
  }

  public deleteSceneMapping(): void {
    this.calibrationService.currentDevice$.pipe(take(1)).subscribe(device => {
      const calibrationType = this.calibrationService.isPerspectiveCalibration
        ? CalibrationType.perspective
        : CalibrationType.sceneMapping;
      const analyticsConfig = device?.analyticsConfig;
      this.calibrationService.deleteSceneMapping(calibrationType, analyticsConfig);
      this.calibrationService.setTotalSavedPoints(0);
      this.calibrationService.setMarkerCounter(1);
    });
  }

  public editSceneMapping(): void {
    this.openAddCalibration();
  }

  public closeDialog(): void {
    this.calibrationService.closeDialog();
  }

  public analyze(): void {
    this.calibrationService.analyze();
  }
}

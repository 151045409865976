<h1 mat-dialog-title>Delete device?</h1>
<div mat-dialog-content>
  <div fxFlex="20%" fxLayout="column">
    <img class="device-logo" [src]="data.logo | uThumbler: thumblerConfig" alt="device logo" />
  </div>
  <div fxFlex="80%" fxLayout="column">
    <div class="device-name">{{ data.name }}</div>
    <div class="device-id">Device ID: {{ data.id }}</div>
  </div>
</div>

<div mat-dialog-actions>
  <span fxFlex></span>
  <button color="primary" mat-button [mat-dialog-close]="false" tabindex="1">CANCEL</button>
  <button color="warn" mat-button [mat-dialog-close]="true" tabindex="2">DELETE</button>
</div>

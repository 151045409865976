<ng-container *ngIf="!!form.get('id').value; else newDeviceTitle">
  <h1 class="title" mat-dialog-title>{{ 'profile.devices.manageX' | translate: {value: form.get('name').value} }}</h1>
  <div class="subtitle">{{ 'profile.devices.deviceId' | translate }} {{ form.get('id').value }}</div>
</ng-container>
<ng-template #newDeviceTitle>
  <h1 mat-dialog-title>{{ 'profile.devices.addDevice' | translate }}</h1>
</ng-template>

<form
  [formGroup]="form"
  class="dialog-content"
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutGap="20px"
  mat-dialog-content
>
  <div fxFlex="30%" fxFlex.lt-sm="0 0 auto" fxLayout="row">
    <logo-upload
      subfolder="device"
      [parentId]="form.get('id').value"
      [url]="form.get('logo').value | uThumbler: THUMBLER_AVAILABLE_CONFIGS.deviceLogo"
      [inputBucket]="uploadLogoSource.DEVICE"
      [isShowingButton]="false"
      [manualUpload]="!data.id"
      (isUploadingEmitter)="isLogoUploading = $event"
      (s3Url)="updateDeviceLogo($event)"
    ></logo-upload>
  </div>
  <div fxFlex="70%" fxFlex.lt-sm="1 1 auto" fxLayout="column">
    <div fxFlex="0 0 auto" fxLayout="row">
      <mat-form-field fxFlex>
        <input
          formControlName="name"
          placeholder="{{ 'common.name' | translate }}"
          matInput
          tabindex="1"
          autocomplete="off"
        />
        <mat-error
          *ngIf="
            form.get('name').invalid &&
            (form.get('name').dirty || form.get('name').touched) &&
            (form.get('name').errors?.min || form.get('name').errors?.pattern)
          "
        >
          {{ 'common.errors.cannotStartWithSpace' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="0 0 auto" fxLayout="row">
      <mat-form-field fxFlex>
        <input
          formControlName="description"
          placeholder="{{ 'common.description' | translate }}"
          matInput
          tabindex="1"
          autocomplete="off"
        />
        <mat-error
          *ngIf="
            form.get('description').invalid &&
            (form.get('description').dirty || form.get('description').touched) &&
            (form.get('description').errors?.min || form.get('description').errors?.pattern)
          "
        >
          {{ 'common.errors.cannotStartWithSpace' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="0 0 auto" fxLayout="column">
      <div class="stream-source-title">{{ 'profile.devices.streamSource' | translate }}</div>
      <mat-radio-group formControlName="sourceUrlSet" class="radio-group" fxLayout="column" fxLayoutGap="8px">
        <mat-radio-button #radioButtonDevice [value]="false">{{
          'profile.devices.device' | translate
        }}</mat-radio-button>
        <div *ngIf="!!form.get('id').value && radioButtonDevice.checked" class="stream-source-device">
          <div class="stream-source-device-title">{{ 'profile.devices.streamUrl' | translate }}</div>
          <div class="stream-source-device-url">{{ deviceService.getStreamUrl(form.value, streamKey) }}</div>
          <div class="stream-source-device-buttons" fxLayout="row" fxLayoutGap="20px">
            <button
              class="stream-source-device-button"
              mat-button
              color="primary"
              type="button"
              [cdkCopyToClipboard]="deviceService.getStreamUrl(form.value, streamKey)"
              (copied)="onStreamUrlCopied()"
            >
              {{ 'profile.streaming.copyUrl' | translate | uppercase }}
            </button>
          </div>
        </div>
        <mat-radio-button #radioButtonExternal [value]="true">{{
          'profile.devices.externalURL' | translate
        }}</mat-radio-button>
        <div
          class="stream-source-external-url"
          *ngIf="radioButtonExternal.checked"
          fxLayout="column"
          fxLayoutAlign="start start"
        >
          <mat-form-field class="stream-url-input">
            <input
              formControlName="sourceUrl"
              type="text"
              matInput
              id="sourceUrl"
              placeholder="{{ 'profile.devices.sourceUrlDescription' | translate }}"
              autocomplete="off"
            />
            <mat-error
              *ngIf="
                form.get('sourceUrl').invalid &&
                (form.get('sourceUrl').dirty || form.get('sourceUrl').touched) &&
                form.get('sourceUrl').errors.invalidUrl
              "
            >
              {{ 'common.errors.invalidUrl' | translate }}
            </mat-error>
          </mat-form-field>
          <!--          <button mat-button color="primary" class="start-stream-button" [disabled]="!data.sourceUrl"-->
          <!--                  *ngIf="!!data.deviceId" (click)="startStream()">START STREAM-->
          <!--          </button>-->
        </div>
      </mat-radio-group>
      <div fxFlex="0 0 auto" fxLayout="row" class="show-in-atlas">
        <mat-checkbox formControlName="gps">{{ 'profile.devices.displayFusionAtlas' | translate }}</mat-checkbox>
      </div>
      <div fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="start start" *ngIf="!!form.get('gps').value">
        <div fxFlex="0 0 auto" fxLayout.xs="column" fxLayout="row" fxLayoutGap="8px">
          <mat-form-field class="lat-log-input">
            <mat-label>{{ 'common.latitude' | translate }}</mat-label>
            <input
              formControlName="lat"
              placeholder="e.g 37.806627"
              matInput
              tabindex="1"
              type="number"
              autocomplete="off"
            />
            <mat-error
              *ngIf="
                form.get('lat').invalid &&
                (form.get('lat').dirty || form.get('lat').touched) &&
                form.get('lat').errors?.invalidGPS
              "
            >
              {{ 'profile.errors.invalidLatitude' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                form.get('lat').invalid &&
                (form.get('lat').dirty || form.get('lat').touched) &&
                (form.get('lat').errors?.min || form.get('lat').errors?.max)
              "
            >
              {{ 'profile.errors.latitudeValue' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="lat-log-input">
            <mat-label>{{ 'common.longitude' | translate }}</mat-label>
            <input
              formControlName="lng"
              placeholder="e.g. -122.440254"
              matInput
              tabindex="1"
              type="number"
              autocomplete="off"
            />
            <mat-error
              *ngIf="
                form.get('lng').invalid &&
                (form.get('lng').dirty || form.get('lng').touched) &&
                form.get('lng').errors?.invalidGPS
              "
            >
              {{ 'profile.errors.invalidLongitude' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                form.get('lng').invalid &&
                (form.get('lng').dirty || form.get('lng').touched) &&
                (form.get('lng').errors?.min || form.get('lng').errors?.max)
              "
            >
              {{ 'profile.errors.longitudeValue' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <button
          class="view-in-fusion-atlas-button"
          mat-button
          color="primary"
          type="button"
          [disabled]="!form.controls['lng'].value || !form.controls['lat'].value"
          [routerLink]="['secure/atlas/place']"
          [queryParams]="{
            lat: form.get('lat').value,
            lng: form.get('lng').value,
            deviceId: form.get('id').value
          }"
          (click)="onNoClick()"
        >
          {{ 'profile.devices.viewFusionAtlas' | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</form>
<div mat-dialog-actions>
  <ng-container *uaHasPermission="aclPermissions.MainApiDeleteDevice">
    <button
      mat-stroked-button
      color="warn"
      type="button"
      class="delete-button__big"
      *ngIf="!!form.get('id').value && !isLastDevice"
      mat-button
      [appLadda]="isDeleting$ | async"
      [disabled]="isDeleting$ | async"
      (click)="deleteDevice()"
      tabindex="0"
    >
      {{ 'common.delete' | translate | uppercase }}
    </button>
    <button
      class="delete-button__small"
      mat-stroked-button
      color="warn"
      type="button"
      *ngIf="!!form.get('id').value && !isLastDevice"
      [appLadda]="isDeleting$ | async"
      [disabled]="isDeleting$ | async"
      mat-button
      (click)="deleteDevice()"
      tabindex="0"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <span fxFlex></span>
  <button mat-stroked-button color="primary" type="button" (click)="onNoClick()" tabindex="1">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <ng-container *uaHasPermission="aclPermissions.MainApiCreateDevice">
    <button
      color="primary"
      mat-raised-button
      tabindex="2"
      type="button"
      [disabled]="!form.dirty || !!isLogoUploading || (isSaving$ | async)"
      *ngIf="!form.get('id').value"
      [appLadda]="isSaving$ | async"
      (click)="onAddClick()"
    >
      {{ 'common.create' | translate | uppercase }}
    </button>
  </ng-container>
  <ng-container *uaHasPermission="aclPermissions.MainApiUpdateDevice">
    <button
      color="primary"
      mat-raised-button
      tabindex="2"
      type="button"
      [disabled]="!form.dirty || !!isLogoUploading"
      *ngIf="!!form.get('id').value"
      [appLadda]="isSaving$ | async"
      (click)="onUpdateClick()"
    >
      {{ 'common.saveChanges' | translate | uppercase }}
    </button>
  </ng-container>
</div>

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioButton} from '@angular/material/radio';

@Component({
  selector: 'unleash-mini-card-option',
  templateUrl: './mini-card-option.component.html',
  styleUrls: ['./mini-card-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniCardOptionComponent implements OnInit {
  @ViewChild('radioButton', {static: false, read: MatRadioButton}) radioButton: MatRadioButton;

  @Input() value: string;
  @Input('isSelected')
  set selectedCard(isSelected: boolean) {
    this.isSelectedCard = isSelected;
  }
  @Input() isShowingBadge: boolean = false;
  @Input() otherOptionHasBadge: boolean = false;
  @Output() selected = new EventEmitter<string>();
  isSelectedCard: boolean;

  constructor() {}

  ngOnInit(): void {}

  emitCheckedValue(radioButton: MatRadioButton) {
    if (radioButton.checked) {
      this.selected.emit(radioButton.value);
      return;
    }
    radioButton.checked = true;
  }
}

import {signUpFormStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {SignUpFormState} from './sign-up.reducers';

export const selectSignUpFormValue = createSelector(signUpFormStateSelector, (state: SignUpFormState) => state.form);

export const selectSignUpFormInProgress = createSelector(
  signUpFormStateSelector,
  (state: SignUpFormState) => state.inProgress
);

export const selectSignUpFormError = createSelector(signUpFormStateSelector, (state: SignUpFormState) => state.error);

export const selectSignUpFormIsDeveloperMode = createSelector(
  signUpFormStateSelector,
  (state: SignUpFormState) => state.isDeveloperMode
);

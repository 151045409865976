import {MetadataTypeInput} from '@app/shared/image-annotation-shared/models/image-annotation.model';

export interface SessionInfo {
  order?: number;
  title?: string;
  value?: string | string[];
  placeholder?: string;
  type?: MetadataTypeInput;
}

export interface SessionsInfo {
  [key: string]: SessionInfo;
}

export interface LabelConfig {
  id: number;
  isDefault?: boolean;
  sessionInfo: SessionsInfo;
  supportedShapes: ShapeTypes[];
  categories: CategoryModel[];
}

export enum ShapeTypeNames {
  polygon = 'Polygon',
  line_in_out = 'Line',
  circle = 'Circle',
  polygon_excluded = 'Excluded area (Polygon)',
  rect = 'Rectangle',
  perspective = 'Perspective zone',

  // other shapes
  POLYGON = 'Polygon'
}

export enum ShapeTypes {
  Polygon = 'POLYGON',
  Rectangle = 'RECTANGLE',
  Point = 'POINT',
  ByClick = 'ByClick',
  ByBox = 'ByBox',
  Everything = 'Everything',
  perspective = 'perspective',

  // manager zones
  polygon = 'polygon',
  rectangle = 'rect',
  line_in_out = 'line_in_out',
  polygon_excluded = 'polygon_excluded',

  circle = 'circle',
  group = 'g', // tag from svg.js group()

  // calibration
  marker = 'marker'
}

export interface CategoryModel {
  id: string;
  name: string;
}

import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';

const APP_PREFIX = 'UNLCF-';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {
    this.removeItem('SAM_HINT_BY_CLICK');
    this.removeItem('SAM_HINT_BY_BOX');
    //
  }

  public static loadInitialState(): any {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map(key =>
            key
              .split('-')
              .map((token, index) => (index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1)))
              .join('')
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  public setItem(key: string, value: any): void {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  public updateCognitoCredentials(newTeamId: string, idToken: string, accessToken: string): void {
    const userEmail =
      window.localStorage[
        Object.keys(window.localStorage).find(t =>
          t.startsWith(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`)
        )
      ];

    localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.idToken`, idToken);
    localStorage.setItem(
      `CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.accessToken`,
      accessToken
    );

    const cognitoUserData = JSON.parse(
      localStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.userData`)
    );
    const teamDataIndex = 2;
    cognitoUserData['UserAttributes'][teamDataIndex] = {
      ...cognitoUserData['UserAttributes'][teamDataIndex],
      Value: newTeamId
    };

    localStorage.setItem(
      `CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.userData`,
      JSON.stringify(cognitoUserData)
    );
  }

  public getItem(key: string): any {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }

  public removeItem(key: string): void {
    localStorage.removeItem(`${APP_PREFIX}${key}`);
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  public testLocalStorage(): void {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    // eslint-disable-next-line prefer-const
    retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }
}

import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {SensorsDataIotService} from '@app/flights/services/sensors-data-iot.service';
import {map, skip, take} from 'rxjs';
import {LatLngBoundsExpression, Map} from 'leaflet';
import {atlasConfig} from '../atlas.config';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {AtlasService} from '../services/atlas.service';
import {MarkerClusterService} from '@app/atlas/services/marker-cluster.service';
import {IoTSensorsData} from '@app/flights/models/remote-cockpit.model';

@Directive({
  selector: '[ulShowLayer]',
  standalone: true
})
export class ShowLayerDirective {
  @Input('ulShowLayer') public asset: AtlasAssetModel;
  @Input() public map: Map;
  @Input() public isGpsDevice: boolean = false;

  constructor(
    private sensorsDataIoTService: SensorsDataIotService,
    private unleashAnalytics: UnleashAnalyticsService,
    private browserSettingsService: BrowserSettingsService,
    private markerClusterService: MarkerClusterService,
    private atlasService: AtlasService
  ) {}

  @HostListener('click', ['$event'])
  public showLayer(): void {
    const asset = this.asset;
    asset.isDisplaying = true;
    this.atlasService.toogleHasToDetectChanges();
    this.moveToAssetLocation(asset);
    if (this.isGpsDevice) {
      this.sensorsDataIoTService
        .subscribe(asset.id)
        // .subscribeToMockData(asset.id)
        .pipe(skip(1), take(1))
        .subscribe((data: IoTSensorsData) => {
          if (data && data.lat && data.lng) {
            this.moveToDroneLocation(data.lat, data.lng);
          }
        });
    }
  }

  private moveToDroneLocation(lat: number, lng: number): void {
    this.map.invalidateSize();
    const bounds: LatLngBoundsExpression = [[lat, lng]];

    this.map.fitBounds(bounds, atlasConfig.FIT_BOUNDS_OPTIONS);
    this.viewLocation();
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {
      type: 'View location'
    });
  }

  private moveToAssetLocation(asset) {
    this.map.invalidateSize();
    const bounds: LatLngBoundsExpression = asset.bounds;
    asset.isDisplaying = true;
    this.markerClusterService.enableClustering();
    this.map.fitBounds(bounds, atlasConfig.FIT_BOUNDS_OPTIONS);
    this.viewLocation();
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {
      type: 'View location'
    });
  }

  private viewLocation() {
    if (this.browserSettingsService.getIsMobileBrowser()) {
      this.closeLayersControlSideBar();
    }
  }

  private closeLayersControlSideBar(): void {
    this.map.setMaxBounds(atlasConfig.MAX_BOUNDS_FULL_SCREEN as LatLngBoundsExpression);
    this.atlasService.setAsCloseLayersControlSideBar();
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {EVENTS, UnleashAnalyticsService} from '../services/unleash-analytics.service';

@Directive({
  selector: '[analyticsTrackerType]'
})
export class AnalyticsTrackerDirective {
  @Input()
  analyticsTrackerType: EVENTS;

  @Input()
  analyticsProperties: any;

  @Input()
  analyticsIf: boolean = true;

  constructor(private unleashAnalytics: UnleashAnalyticsService) {}

  @HostListener('click')
  onClick() {
    if (this.analyticsIf) {
      if (this.analyticsProperties) {
        this.unleashAnalytics.logEvent(this.analyticsTrackerType, this.analyticsProperties);
        return;
      }
      this.unleashAnalytics.logEvent(this.analyticsTrackerType);
    }
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-rename-layers-dialog',
  templateUrl: './rename-layers-dialog.component.html',
  styleUrls: ['./rename-layers-dialog.component.scss']
})
export class RenameLayersDialogComponent implements OnInit {
  public form = new UntypedFormGroup({
    name: new UntypedFormControl('')
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  public ngOnInit() {
    if (!!this.data && this.data.name) {
      this.form.setValue({name: this.data.name});
    }
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeComponent implements OnInit, OnChanges {
  @Input() public initValue: number;
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public units: string;
  @Input() public unitsInside: boolean;
  @Input() public isAwsUser: boolean;
  @Input() public maxValue: number;
  @Input() public currentValue: number;

  public isQuotaExceeded: boolean;
  public remainingValue: number;
  public extraValue?: number;
  public fillValue: number; // 0-360

  public ngOnChanges() {
    this.isQuotaExceeded = this.calcIsQuotaExceeded();
    this.setRemainingValue();

    this.setFillDegrees();
  }

  public ngOnInit() {
    if (isNaN(this.initValue)) {
      this.initValue = 0;
    }
  }

  private setFillDegrees() {
    if (this.isQuotaExceeded) {
      this.fillValue = 360;
    } else {
      this.fillValue = (this.currentValue * 360) / this.maxValue;
    }
  }

  /** Example
   * Input: 7 device out of 5 devices max
   * Output 5/5 + 2 extra billable devices
   * */
  private setRemainingValue() {
    if (this.isQuotaExceeded) {
      this.extraValue = this.currentValue - this.maxValue;
      this.remainingValue = 0;
    } else {
      this.remainingValue = this.maxValue - this.currentValue;
    }
  }

  private calcIsQuotaExceeded() {
    return this.currentValue - this.maxValue > 0;
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UnleashMaterialSharedModule} from '../unleash-material/unleash-material-shared.module';
import {LiveVideoPlayerComponent} from './live-video-player.component';
import {VideogularModule} from '@app/core/modules/videogular/videogular.module';
import {BitratePipe} from '@app/live/pipes/bitrate.pipe';

@NgModule({
  declarations: [LiveVideoPlayerComponent, BitratePipe],
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatTooltipModule,
    UnleashMaterialSharedModule,
    VideogularModule
  ],
  exports: [LiveVideoPlayerComponent]
})
export class LiveVideoPlayerModule {}

import {createAction, props} from '@ngrx/store';

enum AclActions {
  requestActions = '[ACL] request ACL Config',
  requestActionsSuccess = '[ACL] request ACL Config Success'
}

export const requestACLConfig = createAction(AclActions.requestActions);
export const requestACLConfigSuccess = createAction(
  AclActions.requestActionsSuccess,
  props<{payload: {permissions: string[]}}>()
);

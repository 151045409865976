<form [formGroup]="form" (submit)="clickForgotPasswordButton()">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'common.email' | translate }}</mat-label>
    <input matInput formControlName="email" data-test-id="emailInput" />
    <mat-error *ngIf="(form.controls.email.invalid && form.controls.email.touched) || form.controls.email.dirty"
      >{{ 'common.email' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>
  <button
    class="form__item submit-button"
    mat-raised-button
    color="primary"
    [appLadda]="isInProgress"
    [disabled]="isInProgress"
    data-test-id="resetPasswordButton"
  >
    {{ 'auth.forgot.resetPassword' | translate }}
  </button>
</form>

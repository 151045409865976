import {Injectable} from '@angular/core';
import { AtlasAssetModel, AtlasGeojsonAssetModel } from '@app/core/models/api/atlas.model';
import {
  latLng,
  LatLngExpression,
  layerGroup,
  Map,
  Marker,
  marker,
  MarkerOptions,
  point,
  Polyline,
  polyline
} from 'leaflet';
import {AbstractAssetLoaderService} from './abstract-asset-loader.service';
import {FlightFrame, FlightInfo, FlightLogParser} from '@app/flights/components/flight-log/flight-log-parser';
import {createIcon} from '@app/flights/drone-icon';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, shareReplay} from 'rxjs/operators';
import {format} from 'date-fns';

export interface FlightLogDict {
  [id: string]: FlightLogData;
}

export interface FlightLogData {
  name: string;
  flightFrames: FlightFrame[];
  flightInfo: FlightInfo;
  droneMarker: Marker;
  realTimePathPolyline: Polyline;
}

@Injectable({
  providedIn: 'root'
})
export class FlightLogAssetLoaderService extends AbstractAssetLoaderService {
  _flights = new BehaviorSubject<FlightLogDict>(null);
  flights$ = this._flights.asObservable().pipe(filter(Boolean), distinctUntilChanged(), shareReplay(1));
  private dataStore: {
    flights: FlightLogDict;
  } = {flights: {}};

  async load(asset: AtlasAssetModel, map: Map): Promise<number> {
    // console.info('LOAD FLIGHT LOG', asset);

    const data = await this.fetchFile(asset.key);
    this.atlasService.handleDetectAssetChanges();
    new FlightLogParser().readBuffer(data, asset.name).subscribe(({title, flightInfo, flightFrames}) => {
      const {layer, bounds, droneMarker, realTimePathPolyline} = this.createLayer(
        flightFrames,
        asset.sessionId,
        flightInfo
      );

      super.addAsset(map, {id: asset.id, layer, name: asset.name, bounds});
      this.dataStore.flights[asset.id] = {
        name: asset.name,
        flightFrames,
        flightInfo,
        droneMarker,
        realTimePathPolyline
      };
      this._flights.next({...this.dataStore.flights});
    });
    return 1;
  }

  private fetchFile(key: string): Promise<ArrayBuffer> {
    return this.atlasService.getAssetData<ArrayBuffer>(encodeURIComponent(key), 'arraybuffer').catch(err => {
      return this.atlasService.handleError(key, err);
    });
  }

  private createLayer(flightFrames: FlightFrame[], sessionId: string, flightInfo: FlightInfo) {
    if (!!flightFrames && flightFrames.length > 0) {
      const recordedPathPolyline = polyline(this.getRecorderPath(flightFrames), {
        color: '#ffffff',
        weight: 4,
        opacity: 0.3,
        interactive: false,
        bubblingMouseEvents: false
      });
      /*empty polyline, points are added when timeline is played */
      const realTimePathPolyline = polyline([], {
        color: '#ffff00',
        weight: 5,
        opacity: 1,
        interactive: false,
        bubblingMouseEvents: false
      });
      const droneStartPoint = latLng(flightFrames[0].latitude, flightFrames[0].longitude);

      const droneMarker = marker(droneStartPoint, {
        zIndexOffset: 100,
        icon: createIcon(false, 40),
        rotationOrigin: 'center center',
        bubblingMouseEvents: false
      } as MarkerOptions | any).bindPopup(this.getPopupContent(flightInfo, sessionId), {
        closeButton: false,
        offset: point(13, 0),
        minWidth: 200,
        autoPan: true,
        className: 'light-popup'
      });
      return {
        layer: layerGroup([recordedPathPolyline, droneMarker, realTimePathPolyline]),
        bounds: recordedPathPolyline.getBounds(),
        droneMarker: droneMarker,
        realTimePathPolyline
      };
    }
  }

  private getRecorderPath(flightFrames): LatLngExpression[] {
    return flightFrames.map((frame: FlightFrame) => latLng(frame.latitude, frame.longitude));
  }

  private getPopupContent(flightInfo: FlightInfo, sessionId: string) {
    return `
<div class='content device-popup'>
<div class="row">
  <div class="column first">
    <span class="date">${this.getTime(flightInfo.updateTime)}</span>
    <span class="description">${flightInfo.droneType || ''}</span>
    <span class="name">${flightInfo.aircraftName || ''}</span>
    <span class="details">Distance: ${Math.round(flightInfo.totalDistance)} meters</span>
    <span class="details">Time: ${Math.round(flightInfo.totalTime / 1000 / 60)} minutes </span>
  </div>
  <div class="column second live-wrapper offline">
    <div class="live-icon">
      <i class="material-icons on">videocam</i>
      <i class="material-icons off">videocam_off</i>
    </div>
    ${sessionId ? '<a href="/secure/library/${sessionId}" class="off"> \
      Open session \
    </a>' : ''}
  </div>
</div>
</div>`;
  }

  private getTime(updateTime: string) {
    return format(new Date(updateTime), 'LL HH:mm:ss');
  }
}

import {Injectable} from '@angular/core';
import {NavigationExtras} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  shouldBackToDefaultRoute$ = new BehaviorSubject<boolean>(false);
  navigationExtras: NavigationExtras = null;

  private newUrl = '';
  private startUrl = '';

  constructor() {}

  storeNavigationExtras(navigationExtras: NavigationExtras) {
    this.navigationExtras = navigationExtras;
  }

  setNewUrl(url: string) {
    this.newUrl = url;
    this.shouldBackToDefaultRoute$.next(this.newUrl === this.startUrl);
  }

  setStartUrl(url: string) {
    this.startUrl = url;
    this.shouldBackToDefaultRoute$.next(this.newUrl === this.startUrl);
  }
}

<h1 mat-dialog-title>{{ 'profile.phone.leavePageDialogTitle' | translate }}</h1>
<section class="content" mat-dialog-content>
  <span>{{ 'profile.phone.leavePageDialogDescription' | translate }} </span>
</section>

<section mat-dialog-actions>
  <ng-container *ngIf="data.hasFullNumber; else partialNumberTemplate">
    <section class="actions">
      <a mat-dialog-close mat-button color="primary">{{ 'profile.phone.cancel' | translate }}</a>
      <div class="actions__right">
        <a (click)="discardNumber()" mat-button color="primary">{{ 'profile.phone.discardNumber' | translate }}</a>
        <a (click)="openVerifyNumber()" mat-flat-button color="primary">{{
          'profile.phone.verifyMobileNumber' | translate
        }}</a>
      </div>
    </section>
  </ng-container>
  <ng-template #partialNumberTemplate>
    <a mat-dialog-close mat-button color="primary">{{ 'profile.phone.cancel' | translate }}</a>
    <a (click)="discardNumber()" mat-flat-button color="primary">{{ 'profile.phone.discardNumber' | translate }}</a>
  </ng-template>
</section>

import {icon} from 'leaflet';

export const createIncompletedMarkerIcon = size => {
  const color = '#CE1C00';
  const customSvg = `
  <svg width="${size[0]}" height="${size[1]}" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="3.66675" width="11" height="11" fill="white"/>
  <path d="M4.5 7.83337L12 5.33337H14L14.5 9.33337L9 15.3334L4 15.8334L2 11.3334L4.5 7.83337Z" fill="white"/>
  <rect x="3" y="4" width="11" height="11" fill="white"/>
  <path d="M8.16667 1C3.66333 1 0 4.66333 0 9.16667C0 15.2917 8.16667 24.3333 8.16667 24.3333C8.16667 24.3333 16.3333 15.2917 16.3333 9.16667C16.3333 4.66333 12.67 1 8.16667 1ZM5.355 4.69833L8.16667 7.51L10.9783 4.69833L12.635 6.34333L9.81167 9.16667L12.635 11.9783L10.9783 13.635L8.16667 10.8117L5.355 13.635L3.69833 11.9783L6.52167 9.16667L3.69833 6.34333" fill="${color}"/>
  </svg>
  
`;
  const svgAsURI = 'data:image/svg+xml;base64,' + btoa(customSvg).replace('#', '%23');

  return icon({
    iconUrl: svgAsURI,
    attribution: color,
    iconAnchor: [size[0] / 2, size[1]]
  });
};

import {Injectable} from '@angular/core';
import {Addon} from '@app/store/addon/models/addon';
import {TranslateService} from '@ngx-translate/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserService} from '@app/core/services/api/user.service';
import {Observable, distinctUntilChanged, filter, map, shareReplay, switchMap, take} from 'rxjs';
import {LiveFacadeService} from '@app/live/services/live-facade.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {GridLayout, GridState} from '@app/live/models/grid-state.model';

@Injectable({
  providedIn: 'root'
})
export class LiveStreamPageService {
  public selectedDevice$ = this.liveFacadeService.selectedDeviceId$.pipe(
    filter(deviceId => !!deviceId),
    switchMap(deviceId =>
      this.userStoreFacadeService.currentUserDevicesObject$.pipe(map(devices => devices[deviceId]))
    ),
    shareReplay(1)
  );
  public selectedDeviceId$: Observable<string> = this.liveFacadeService.selectedDeviceId$.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  );
  public isInitialized$: Observable<boolean> = this.liveFacadeService.isInitialized$;
  public liveDevicesId$: Observable<string[]> = this.userService.liveDevices$.pipe(
    map(devices => devices.map(device => device.id))
  );
  public layoutState$: Observable<GridLayout> = this.liveFacadeService.layoutState$;
  public gridState$: Observable<GridState> = this.liveFacadeService.gridState$;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isShowingStreams$: Observable<boolean> = this.userService.liveDevices$.pipe(
    map(devices => devices.length > 0)
  );

  public rawStream: Partial<Addon>;

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private liveFacadeService: LiveFacadeService,
    private userStoreFacadeService: UserStoreFacadeService
  ) {
    this.translateService
      .get('live.live-stream-page.service.rawStream')
      .pipe(take(1))
      .subscribe(i18rawStream => {
        this.rawStream = {
          id: 'RAW_STREAM',
          ...i18rawStream
        };
      });
  }

  // TODO: implement this.
  public showLimitReachedDialog(ai: boolean): void {
    // const dialogRef = this.dialog.open(StreamLimitDialog, <MatDialogConfig>{
    //   width: '320px',
    //   data: {ai: ai}
    // });
  }

  public setCurrentModel(device: UserDeviceJoined, modelId: Addon['id']): void {
    this.userStoreFacadeService.setCurrentModel(device.id, modelId);
  }

  public setSelectedDeviceId(deviceId: string): void {
    this.liveFacadeService.setSelectedDeviceId(deviceId);
  }

  public stopModelOfSelectedDeviceOptimisticUpdate(modelToStop: {device: UserDeviceJoined; modelId: string}): void {
    this.userStoreFacadeService.removeAddonFromRunningModels(modelToStop.device.id, modelToStop.modelId);
    this.liveFacadeService.setSelectedDeviceId(modelToStop.device.id);
  }

  public removeLiveDevice(device: UserDeviceJoined): void {
    this.liveFacadeService.removeLiveDevice(device);
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {MatDialog} from '@angular/material/dialog';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {filter, take, tap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {ConvertToMissionComponent} from '../components/convert-to-mission-dialog/convert-to-mission-dialog.component';
import {CanNotConvertToMissionComponent} from '../components/can-not-convert-to-mission-dialog/can-not-convert-to-mission-dialog.component';
import {Router} from '@angular/router';
import {MissionManagerService} from '../services/mission-manager.service';

@Directive({
  selector: '[ulConvertToMissionAsset]',
  standalone: true
})
export class ConvertToMissionAssetDirective {
  @Input('ulConvertToMissionAsset') public asset: AtlasAssetModel;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private unleashAnalytics: UnleashAnalyticsService,
    private router: Router,
    private missionManagerService: MissionManagerService
  ) {}

  @HostListener('click', ['$event'])
  public renameAsset(): void {
    const asset = this.asset;
    this.dialog
      .open(ConvertToMissionComponent, {
        ...STANDARD_DIALOG_CONFIG,
        data: asset,
        width: '80vw',
        maxWidth: '800px'
      })
      .afterClosed()
      .pipe(
        tap(({missionResult, hasError}) => {
          if (!missionResult && hasError) {
            this.dialog.open(CanNotConvertToMissionComponent, {
              ...STANDARD_DIALOG_CONFIG,
              data: {name: asset.name},
              width: '80vw',
              maxWidth: '800px'
            });
          }
        }),
        filter(({missionResult}) => !!missionResult)
      )
      .subscribe(({missionResult}: any): void => {
        this.translateService
          .get(['atlas.convertLayerToMission.missionCreatedFromLayer', 'atlas.convertLayerToMission.viewMission'])
          .subscribe(translations => {
            this.snackBar
              .open(
                translations['atlas.convertLayerToMission.missionCreatedFromLayer'],
                translations['atlas.convertLayerToMission.viewMission'],
                {duration: 5000}
              )
              .onAction()
              .pipe(take(1))
              .subscribe(() => {
                this.router.navigate(['/secure/atlas'], {
                  queryParams: {mission: 1, missionId: missionResult.id}
                });
              });
          });
      });
  }
}

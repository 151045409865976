import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {MatIconType} from '@app/core/models/gui/icon-type.model';
import {WebrtcService} from '@app/core/services/api/webrtc.service';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {HelpService} from '@app/core/services/help.service';
import {
  ActionMenu,
  MenuActions,
  actionMenuStreamBottom,
  actionMenuStreamTop
} from '@app/library/models/action-menu.model';
import {CloseDialogComponent} from '@app/live/components/close-dialog/close-dialog.component';
import {StreamingErrors} from '@app/live/models/streaming-errors.models';
import {AgoraService} from '@app/live/services/agora.service';
import {Addon} from '@app/store/addon/models/addon';
import {Clipboard} from '@angular/cdk/clipboard';

import {environment} from 'environments/environment';
import {BehaviorSubject, Observable, filter, map, of, shareReplay, take, tap} from 'rxjs';

@Component({
  selector: 'app-stream-webrtc',
  templateUrl: './stream-webrtc.component.html',
  styleUrls: ['./stream-webrtc.component.scss']
})
export class StreamWebrtcComponent implements OnDestroy, OnInit {
  public isConnected$: Observable<boolean> = this.agoraService.isConnected$;
  public streamStopped$: Observable<boolean> = this.agoraService.streamStopped$;
  public videoTime$: Observable<String> = this.agoraService.videoTime$;
  public models$: Observable<Addon[]> = of(null);
  public videoStats$: Observable<{
    frame: number;
    width: number;
    height: number;
  }> = this.agoraService.videoStats$;
  public errorMessage$: Observable<string> = this.agoraService.errorMessage$;
  public availableCameras$: Observable<MediaDeviceInfo[]> = this.agoraService.availableCameras$;
  public publicWatchPageURL$: Observable<string> = this.agoraService.publicWatchPageURL$;
  public isEnablingLivePage$: Observable<boolean> = this.agoraService.getisEnablingLivePage();
  public isloadingSlug$: Observable<boolean> = this.agoraService.getisLoadingSlug();
  public isStartingStreaming$: Observable<boolean> = this.agoraService.isStartingStreaming$;
  public errorCode$: Observable<StreamingErrors | ''> = this.agoraService.errorCode$;
  public isLoadingCompanyInfo$: BehaviorSubject<boolean> = this.agoraService.isLoadingCompanyInfo$;
  public agoraToken$: Observable<string> = this.agoraService.agoraToken$;
  public isShared$: BehaviorSubject<boolean> = this.agoraService.isShared$;
  public isStreaming$: BehaviorSubject<boolean> = this.agoraService.isStreaming$;
  public activeAddons$: BehaviorSubject<string[]> = this.agoraService.activeAddons$;
  public streamingErrors = StreamingErrors;
  public streamAppId: string = environment.PWA_STREAMING_APP_ID;
  public isIOSPWA: boolean = this.browserSettings.isIOSPWA();
  public startingModel: Addon = {} as Addon;
  public isMenuDisplayed: boolean = false;
  public isMobileView$: Observable<boolean> = this.observableMedia.asObservable().pipe(
    map(change => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      const isMobileView = mediaAlias === 'xs';
      return isMobileView;
    }),
    shareReplay(1)
  );

  public matIconType = MatIconType;
  public menuItemsTop: ActionMenu[] = Object.values(actionMenuStreamTop);
  public menuItemsBottom: ActionMenu[] = Object.values(actionMenuStreamBottom);
  public userDevices$: Observable<UserDeviceJoined[]> = this.agoraService.userDevices$;
  public selectedDeviceId: string = null;
  public isSecureTunnelEnabled: boolean = false;
  public isStreamProxyEnabled$: Observable<boolean> = this.agoraService.currentUserCompany$.pipe(
    filter(company => !!company),
    take(1),
    map(company => !!company.isStreamProxyEnabled)
  );

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public webrtcService: WebrtcService,
    private browserSettings: BrowserSettingsService,
    private agoraService: AgoraService,
    private helpService: HelpService,
    private observableMedia: MediaObserver,
    private clipboard: Clipboard
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: {preventDefault: Function; returnValue: boolean}): void {
    if (this.isStreaming$.value) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  @HostListener('window:unload', ['$event'])
  public onUnload(): void {
    this.agoraService.leave();
    this.agoraService.disableStream();
  }

  public ngOnDestroy(): void {
    this.agoraService.leave();
    this.agoraService.disableStream();
    this.agoraService.streamStopped$.next(false);
  }

  public ngOnInit(): void {
    this.setupAgora();
  }

  public setupAgora(): void {
    this.agoraService.defaultDeviceId$
      .pipe(
        filter(id => !!id),
        take(1),
        tap(defaultDeviceId => {
          this.setSelectedDeviceId(defaultDeviceId);
          this.agoraService.supportIOS();
          this.agoraService.getCompanyInfo();
          this.agoraService.startAgora(defaultDeviceId, false);
        })
      )
      .subscribe();
  }

  public setSelectedDeviceId(selectedDeviceId) {
    this.selectedDeviceId = selectedDeviceId;
  }

  public async reloadAgora(selectedDeviceId) {
    await this.agoraService.leave();
    this.agoraService.startAgora(selectedDeviceId, this.isSecureTunnelEnabled);
  }

  public openDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(CloseDialogComponent, {
      disableClose: true,
      panelClass: 'exit-dialog',
      width: '800px'
    });
    return dialogRef.afterClosed();
  }

  public onUrlCopied(): void {
    this.publicWatchPageURL$.pipe(take(1)).subscribe(publicWatchPageURL => {
      this.clipboard.copy(publicWatchPageURL);
      this.agoraService.onUrlCopied();
    });
  }

  public enablePublicLivePage(): void {
    this.agoraService.enablePublicLivePage();
  }

  public generateCompanySlug(): void {
    this.agoraService.generateCompanySlug();
  }

  public async startStream(): Promise<void> {
    this.agoraService.startStream();
  }

  public async stopStream(): Promise<void> {
    this.agoraService.stopStream();
  }

  public async switchCamera(): Promise<void> {
    this.agoraService.switchCamera();
  }

  public askCameraPermission(): void {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true
      })
      .then(() => {
        this.agoraService.updateErrorMessage(null);
        this.agoraService.updateErrorCode(null);
      })
      .catch((error: {} | string) => {
        this.agoraService.manuallyPermissionErrorMessage();
        console.warn('response ask for permissions', error);
      });
  }

  public refreshPage(): void {
    window.location.reload();
  }

  public contactUs(): void {
    this.helpService.goToContactUs();
  }

  public selectAnalysis(aiApp: Addon): void {
    let isAlreadyActive = false;
    this.startingModel = {...aiApp};
    this.activeAddons$.value.forEach((analysis: string, index: number) => {
      if (aiApp.name === analysis) {
        isAlreadyActive = true;
        this.activeAddons$.value.splice(index, 1);
        this.agoraService.stopAI(this.startingModel.id);
      }
    });
    if (!isAlreadyActive) {
      this.activeAddons$.value.push(aiApp.name);
    } else {
      return;
    }
    this.agoraService.runAi(this.startingModel);
  }

  public async secureTunnel(enabled: boolean) {
    this.isSecureTunnelEnabled = enabled;
    this.reloadAgora(this.selectedDeviceId);
  }

  public back(): void {
    this.router.navigate(['/secure/live']);
  }

  public toggleMenu() {
    this.isMenuDisplayed = !this.isMenuDisplayed;
  }

  public emitAction(action: MenuActions): void {
    switch (action) {
      case MenuActions.COPY_STREAMING_LINK_STREAMING:
        this.onUrlCopied();
        break;
      case MenuActions.REGENERATE_SECURE_LINK_STREAMING:
        this.generateCompanySlug();
        break;
    }
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {catchError, flatMap, map, publishLast, refCount, share, take} from 'rxjs/operators';
import * as secureRandom from 'secure-random';
import {CompanyModel, UserCompanyModel} from '../../models/api/company-model';
import {UserDeviceJoined} from '../../models/api/user-device.model';
import {UserModel} from '../../models/api/user-model';
import {ApiGateway} from './api-gateway.service';
import {UserStoreFacadeService} from '../user-store-facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {RestreamService} from './restream.service';

/*** User, it's relations and company data store*/
@Injectable({providedIn: 'root'})
export class UserService {
  public readonly user$: Observable<UserModel> = this.userStoreFacadeService.currentUser$;
  public readonly myCompany$: Observable<CompanyModel> = this.userStoreFacadeService.currentUserCompany$;
  public readonly companies$: Observable<CompanyModel[]> = this.userStoreFacadeService.companies$;
  public readonly userCompanies$: Observable<UserCompanyModel[]>;
  public readonly userDevices$: Observable<UserDeviceJoined[]> = this.userStoreFacadeService.currentUserDevices$;
  public readonly liveDevices$: Observable<UserDeviceJoined[]> = this.userStoreFacadeService.liveDevices$;
  public readonly currentUserDevicesObject$: Observable<{[key: string]: UserDeviceJoined}> =
    this.userStoreFacadeService.currentUserDevicesObject$;
  public defaultDevice$: Observable<UserDeviceJoined> = this.userStoreFacadeService.defaultDevice$;
  public currentPlan$: Observable<UserModel['currentPlan']> = this.userStoreFacadeService.currentPlan$;
  public currentTeamId$: Observable<CompanyModel['id']> = this.userStoreFacadeService.currentTeamId$;
  public isUpdatingCognitoUser$: Observable<boolean> = this.userStoreFacadeService.isUpdatingCognitoUser$;
  public updateCognitoUserFinished$: ReplaySubject<void> = new ReplaySubject(1);
  public identityIdSet$: ReplaySubject<void> = new ReplaySubject(1);
  /* on first log in default device is created in backend,
   * for some users it's not there yet on page load causing race condition
   * so retry the request multiple times */
  // eslint-disable-next-line no-magic-numbers
  private FETCH_DEVICES_MAX_RETRY = 3;
  private fetchUserDevicesCount: number = 0;
  private identityId: string;

  constructor(
    private apiGateway: ApiGateway,
    private http: HttpClient,
    private userStoreFacadeService: UserStoreFacadeService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private restreamService: RestreamService,
    private store: Store
  ) {
    this.clearDataStore();
  }

  public updateCognitoUserFinished(): void {
    this.updateCognitoUserFinished$.next();
  }

  public updateAutomationConfig(deviceId: string) {
    return this.apiGateway.put(`automation/config/${deviceId}`, null, null);
  }

  public clearDataStore() {
    this.userStoreFacadeService.userStoreData(null);
    this.userStoreFacadeService.userStoreDeviceData(null);
    this.userStoreFacadeService.userStoreCompany(null);
    this.userStoreFacadeService.saveUserCompanies(null);
    this.userStoreFacadeService.userStoreCompanies(null);
  }

  // eslint-disable-next-line rxjs/finnish
  public findUser(): Observable<UserModel> {
    return this.apiGateway.get(`user/${encodeURIComponent(this.identityId)}`, {}).pipe(
      catchError(error => {
        // after migrating to amplify as the requests handling library it's using axios implementation,
        // which returns error as wrapper object - thus need to check for error.response.status
        // also handle a situation when error is regular js error.
        const errorStatus = error.status || (!!error.response && error.response.status);
        if (errorStatus === 404) {
          // user is not created if its freshly registered via cognito
          return throwError({code: 404, message: "User doesn't exist"});
        }
        // most probably 422 or 500, might indicate API problems
        console.error('Could not get user', error);
        throw error;
      }),
      publishLast(),
      refCount(),
      share() // debounce
    );
  }

  public addDeviceToUser(device: UserDeviceJoined) {
    this.userStoreFacadeService.userStoreDeviceData(device);
    this.userStoreFacadeService.setDeviceUsage();
  }

  public updateDeviceCache(device: Partial<UserDeviceJoined>) {
    delete device.statsRestreamingFrom$;
    delete device.statsRestreamingTo$;
    this.userStoreFacadeService.updateDeviceCache(device);
  }

  public updateDevicesCache(device: {[key: string]: Partial<UserDeviceJoined>}) {
    delete device.statsRestreamingFrom$;
    delete device.statsRestreamingTo$;
    this.userStoreFacadeService.updateDevicesCache(device);
  }

  public removeDeviceFromUser(deviceId: string, userId: string) {
    this.userStoreFacadeService.removeDeviceFromUser(deviceId, userId);
  }

  public removeDeviceFromCache(deviceId: string) {
    this.userStoreFacadeService.removeDeviceFromCache(deviceId);
  }

  public findAllUserDevices() {
    this.userStoreFacadeService.findAllUserDevices(
      this.identityId,
      this.fetchUserDevicesCount,
      this.FETCH_DEVICES_MAX_RETRY
    );
  }

  public getCompanyForNonAdmin() {
    this.userStoreFacadeService.getCompanyIfUserIsNotAdmin();
  }

  public updateUser(user: Partial<UserModel>) {
    this.userStoreFacadeService.updateUser(user, this.identityId);
  }

  public updatePlanInUserDataStore(planId: string, period: PaymentPeriod): void {
    this.userStoreFacadeService.updatePlanInUserDataStore(planId, period);
  }

  public createCompany(company: Partial<CompanyModel>) {
    this.userStoreFacadeService.createCompany(company);
  }

  public registerCompany(company: Partial<CompanyModel>) {
    this.userStoreFacadeService.registerCompany(company);
  }
  public getLogoPresignedURL(contentType: string, instanceId: string, filename: string) {
    return this.apiGateway.post('account/uploadImage', {}, {contentType, instanceId, filename});
  }

  public updateCompany(companyId: string, company: Partial<CompanyModel>) {
    this.userStoreFacadeService.updateCompany(companyId, company);
  }

  public uploadCompanyLogo(file: File, companyId: string) {
    let presignedUrl;
    return this.getLogoPresignedURL(file.type, companyId, file.name).pipe(
      flatMap(url => {
        presignedUrl = url;
        return this.http.put(url, file);
      }),
      map(response => {
        const url = new URL(presignedUrl);
        const s3Path = decodeURIComponent(url.pathname.slice(1));
        this.updateCompany(companyId, {logo: s3Path});
        return s3Path;
      })
    );
  }

  public setIdentityId(identityId: string) {
    this.identityId = identityId;
    this.identityIdSet$.next();
  }

  public setupUserDataModel(user: UserModel) {
    this.userStoreFacadeService.setupUserDataModel(user);
    this.userStoreFacadeService.userStoreData({...user});
  }

  public generateCompanySlug(companySlug: string) {
    const secureRandomString = secureRandom.randomArray(7).join('');
    const existentSecureUrlIndex = companySlug.indexOf('-S3C');
    if (existentSecureUrlIndex > 0) {
      companySlug = companySlug.slice(0, existentSecureUrlIndex);
    }
    companySlug = companySlug + '-S3C' + secureRandomString;
    return companySlug;
  }

  public getUserRolesByCompany() {
    this.userStoreFacadeService.getUserRolesByCompany();
  }

  public loadUserTeams() {
    this.userStoreFacadeService.loadUserTeams();
  }

  public stopStream(device: UserDeviceJoined) {
    this.translateService
      .get('live.device-table.stopStreamFrom')
      .pipe(take(1))
      .subscribe(i18StopStreamFrom =>
        this.snackBar.open(`${i18StopStreamFrom} ${device.name}`, null, {
          duration: 2000
        } as MatSnackBarConfig)
      );

    this.user$.subscribe(user => {
      this.restreamService.stopStreamFrom(device.id, user.streamKey).subscribe(null, error => {
        this.snackBar.open(error.message, null, {
          duration: 2000
        } as MatSnackBarConfig);
      });
    });
  }

  public startStream(device: UserDeviceJoined) {
    this.translateService.get('live.device-table.startStreamFrom').subscribe(i18StartStreamFrom =>
      this.snackBar.open(`${i18StartStreamFrom} ${device.name}`, null, {
        duration: 2000
      } as MatSnackBarConfig)
    );

    this.user$.subscribe(user => {
      this.restreamService.startStreamFrom(device.sourceUrl, device.id, user.streamKey).subscribe(null, error => {
        this.snackBar.open(error.message, null, {
          duration: 2000
        } as MatSnackBarConfig);
      });
    });
  }

  public setDisplayedDeviceIds(displayedDevices: string[]): void {
    this.userStoreFacadeService.setDisplayedDeviceIds(displayedDevices);
  }

  public changeDestUrlSetOnSelectedDevice(destUrlSet: boolean, deviceId: string) {
    this.userStoreFacadeService.changeDestUrlSetOnSelectedDevice(destUrlSet, deviceId);
  }

  public setDestUrlOnSelectedDevice(destUrl: string, deviceId: string): void {
    this.userStoreFacadeService.setDestUrlOnSelectedDevice(destUrl, deviceId);
  }

  public removeAddonFromWaitingModelsDueError(deviceId: string, modelId: string): void {
    this.userStoreFacadeService.removeAddonFromWaitingModelsDueError(deviceId, modelId);
  }
}

<div class="sidebar-container">
  <mat-drawer-container #drawerContainer class="drawer-container" autosize>
    <mat-drawer mode="side" position="end" class="drawer-content">
      <ng-container *ngIf="missionSettingsService.isEditing | async; else routeInfo">
        <ng-container *ngIf="selectedWaypoint">
          <unleash-edit-waypoint-settings
            [selectedWaypoint]="selectedWaypoint"
            [selectedWaypointIndex]="selectedWaypointIndex"
            [defaultSettings]="missionSettingsService.settings$ | async"
            (closeDrawer)="closeDrawer(true)"
          ></unleash-edit-waypoint-settings>
        </ng-container>
      </ng-container>
      <ng-template #routeInfo>
        <div class="drawer-content__header">
          {{
            isLastIndex
              ? ('atlas.mission.end' | translate)
              : selectedWaypointIndex === 0
              ? ('atlas.mission.start' | translate)
              : ('atlas.mission.waypoint' | translate) + ' ' + (selectedWaypointIndex + 1)
          }}
          <mat-icon class="drawer-content__close-icon" (click)="closeDrawer()">close</mat-icon>
        </div>
        <div *ngIf="selectedWaypoint?.lat !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.lat' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.lat }}</span>
        </div>
        <div *ngIf="selectedWaypoint?.lng !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.lng' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.lng }}</span>
        </div>

        <div *ngIf="selectedWaypoint?.altitudeWGS !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.altitudeWGS' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.altitudeWGS }}m</span>
        </div>
        <div *ngIf="selectedWaypoint?.altitudeEGM !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.altitudeEGM' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.altitudeEGM }}m</span>
        </div>

        <div *ngIf="selectedWaypoint?.altitude !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.height' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.altitude | number: '0.0-0' }}m</span>
        </div>
        <div *ngIf="selectedWaypoint?.speed !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.speed' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.speed }}m/sec</span>
        </div>
        <div *ngIf="selectedWaypoint?.heading !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.heading' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.heading | number: '0.0-0' }}&deg;</span>
        </div>
        <div *ngIf="selectedWaypoint?.pitch !== undefined" class="drawer-content__route-info">
          <span class="drawer-content__route-title">{{ 'atlas.mission.pitch' | translate }}</span>
          <span class="drawer-content__route-value">{{ selectedWaypoint?.pitch }}&deg;</span>
        </div>
      </ng-template>
    </mat-drawer>
  </mat-drawer-container>
</div>

<nav class="global-process-bar">
  <section class="global-process-bar__container">
    <button class="global-process-bar__button" mat-icon-button (click)="back()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="global-process-bar__info">
      <h1 class="global-process-bar__title">{{ 'stream-webrtc.streamCamera' | translate }}</h1>
      <div class="global-process-bar__title--subtitle">
        <ng-container *ngIf="(errorCode$ | async) === streamingErrors.PERMISSION_DENIED">
          {{ 'stream-webrtc.awaitingCameraAccess' | translate }}
        </ng-container>
        <ng-container *ngIf="(isConnected$ | async) && !(isStartingStreaming$ | async) && !(isStreaming$ | async)">
          {{ 'stream-webrtc.ready' | translate }}
        </ng-container>
        <div class="connecting-container" *ngIf="isStartingStreaming$ | async">
          <unleash-spinner [diameter]="16" [borderWidth]="2" [color]="'#666'"></unleash-spinner>
          <span>{{ 'stream-webrtc.connecting' | translate }}</span>
        </div>
        <ng-container *ngIf="isStreaming$ | async">
          <div class="stats">
            {{ 'stream-webrtc.streaming' | translate }}
            •
            <span>{{ (videoStats$ | async)?.width }}x{{ (videoStats$ | async)?.height }}</span>
            •
            <span>{{ (videoStats$ | async)?.frame }}fps</span>
            <!-- •
            <span class="stats__time" *ngIf="videoTime$ | async">{{ videoTime$ | async }}</span> -->
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</nav>

<nav class="global-action-bar global-action-bar--z-index-1">
  <section
    class="global-action-bar__options global-action-bar__options--mobile-menu-hidden"
    (click)="$event.stopPropagation()"
  >
    <button
      [disabled]="!(isConnected$ | async) || (isStartingStreaming$ | async) || (isStreaming$ | async)"
      class="global-action-bar__button"
      mat-button
      (click)="startStream()"
    >
      <ng-container *ngIf="(isConnected$ | async) && !(isStartingStreaming$ | async); else loadingStreamingTemplate">
        <mat-icon class="global-action-bar__icon-svg" svgIcon="start_streaming"></mat-icon>
      </ng-container>
      <ng-template #loadingStreamingTemplate>
        <unleash-spinner
          class="global-action-bar__icon-svg"
          [diameter]="15"
          [borderWidth]="2"
          [color]="'#fff'"
        ></unleash-spinner>
      </ng-template>
      <span>{{ 'stream-webrtc.startStreaming' | translate }}</span>
    </button>

    <ng-container *ngIf="isMobileView$ | async; else desktopMenuTemplate">
      <button [disabled]="!(isConnected$ | async)" class="global-action-bar__button" mat-button (click)="toggleMenu()">
        <mat-icon class="global-action-bar__icon">share</mat-icon>
        <span>Share</span>
        <mat-icon class="global-action-bar__icon">arrow_drop_down</mat-icon>
      </button>
    </ng-container>
    <ng-template #desktopMenuTemplate>
      <button class="global-action-bar__button" mat-button [matMenuTriggerFor]="streamingMenu">
        <mat-icon class="global-action-bar__icon">share</mat-icon>
        <span>Share</span>
        <mat-icon class="global-action-bar__icon">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #streamingMenu="matMenu" xPosition="before">
        <button (click)="onUrlCopied()" mat-menu-item>
          <mat-icon class="global-action-bar__icon-svg" svgIcon="link"></mat-icon>
          <span class="action-bar__submenu-text">Copy link</span>
        </button>
        <button (click)="generateCompanySlug()" mat-menu-item>
          <mat-icon class="global-action-bar__icon-svg" svgIcon="lock_plus"></mat-icon>
          <span class="action-bar__submenu-text">Re-generate secure link</span>
        </button>
      </mat-menu>
    </ng-template>
  </section>
</nav>

<nav
  class="global-action-bar global-action-bar--hide-bar"
  *ngIf="isMenuDisplayed"
  (click)="isMenuDisplayed = false"
  [ngClass]="{'global-action-bar--mobile-menu': (isMobileView$ | async)}"
>
  <section class="global-action-bar__options" (click)="$event.stopPropagation()">
    <ng-container *ngFor="let menuItem of menuItemsBottom">
      <ng-container *ngIf="menuItem.submenuItems as submenuItems; else singleButton">
        <button
          [ngClass]="{
            'global-action-bar__mobile-menu-button': (isMobileView$ | async),
            'global-action-bar__button': !(isMobileView$ | async)
          }"
          mat-button
          [matMenuTriggerFor]="streamingMenu"
        >
          <ng-container
            [ngTemplateOutlet]="handleIcons"
            [ngTemplateOutletContext]="{menuItem: menuItem}"
          ></ng-container>
          <span class="action-bar__button-text"
            >{{ menuItem.name }}<mat-icon class="action-bar-mobile__menu-icon">play_arrow</mat-icon></span
          >
          <mat-menu #streamingMenu="matMenu" xPosition="before">
            <button (click)="emitAction(submenuItem.actionType)" *ngFor="let submenuItem of submenuItems" mat-menu-item>
              <ng-container
                [ngTemplateOutlet]="handleIcons"
                [ngTemplateOutletContext]="{menuItem: submenuItem}"
              ></ng-container>
              <span class="action-bar__submenu-text">{{ submenuItem.name }}</span>
            </button>
          </mat-menu>
        </button>
      </ng-container>
      <ng-template #singleButton>
        <button
          [ngClass]="{
            'global-action-bar__mobile-menu-button': (isMobileView$ | async),
            'global-action-bar__button': !(isMobileView$ | async)
          }"
          (click)="emitAction(menuItem.actionType)"
          mat-button
        >
          <ng-container
            [ngTemplateOutlet]="handleIcons"
            [ngTemplateOutletContext]="{menuItem: menuItem}"
          ></ng-container>
          <span [class]="'action-bar__button-text action-bar__button-text--' + menuItem.color">{{
            menuItem.name
          }}</span>
        </button>
        <mat-divider class="global-action-bar__divider" *ngIf="menuItem.hasMobileSeparator"></mat-divider>
      </ng-template>
    </ng-container>
  </section>
</nav>

<ng-template #handleIcons let-menuItem="menuItem">
  <ng-container *ngIf="menuItem.iconType === matIconType.SimpleIcon; else svgIconTemplate">
    <mat-icon class="global-action-bar__icon global-action-bar__icon--with-margin" [color]="menuItem.color">{{
      menuItem.iconName
    }}</mat-icon>
  </ng-container>
  <ng-template #svgIconTemplate>
    <mat-icon
      class="global-action-bar__icon-svg global-action-bar__icon-svg--with-margin"
      [svgIcon]="menuItem.iconName"
    ></mat-icon>
  </ng-template>
</ng-template>

<div class="container" id="stream_local">
  <ng-container *ngIf="errorMessage$ | async; else streamTemplate">
    <div class="stream-viewer stream-viewer--error">
      <ng-container *ngIf="isIOSPWA; else showPWATemplate">
        <div class="error">
          <div class="error__title">{{ 'stream-webrtc.featureNotSupported' | translate }}</div>
          <div class="error__message">
            {{ 'stream-webrtc.featureNotSupportedMessage' | translate }}
          </div>
          <br />
          <a [href]="router.url" target="_blank">{{ 'stream-webrtc.featureNotSupportedAction' | translate }}</a>
        </div>
      </ng-container>
      <ng-template #showPWATemplate>
        <ng-container *ngIf="(errorCode$ | async) === streamingErrors.PERMISSION_DENIED; else timeoutError">
          <div class="error">
            <mat-icon class="error__icon">warning</mat-icon>
            <div class="error__information">
              <div class="error__camera">
                <h1 class="error__title">{{ 'stream-webrtc.cameraAccessDenied' | translate }}</h1>
                <p class="error__description">{{ 'stream-webrtc.cameraDeniedMessage' | translate }}</p>
                <p class="error__description" [innerHTML]="'stream-webrtc.cameraDeniedDescription' | translate"></p>
              </div>
              <div class="error__button">
                <button mat-button (click)="askCameraPermission()">Request access</button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #timeoutError>
          <ng-container *ngIf="(errorCode$ | async) === streamingErrors.JOIN_FAILED; else defaultError">
            <div class="error">
              <mat-icon class="error__icon">warning</mat-icon>
              <div class="error__information">
                <div class="error__camera">
                  <h1 class="error__title">{{ 'stream-webrtc.timeoutErrorTitle' | translate }}</h1>
                  <p class="error__description">
                    {{ 'stream-webrtc.timeoutErrorMessage' | translate }}
                  </p>
                  <p class="error__description">{{ 'stream-webrtc.timeoutErrorDescription' | translate }}</p>
                  <p class="error__description">{{ 'stream-webrtc.timeoutErrorDescription2' | translate }}</p>
                </div>
                <div class="error__button">
                  <button mat-button (click)="contactUs()">{{ 'stream-webrtc.contact' | translate }}</button>
                  <button mat-button (click)="refreshPage()">{{ 'stream-webrtc.refresh' | translate }}</button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #defaultError>
            <div class="error">
              <mat-icon class="error__icon">warning</mat-icon>
              <div class="error__information">
                <div class="error__camera">
                  <h1 class="error__title">{{ 'stream-webrtc.defaultErrorTitle' | translate }}</h1>
                  <p class="error__description">
                    {{ 'stream-webrtc.defaultErrorMessage' | translate }}
                  </p>
                  <p class="error__description">{{ 'stream-webrtc.defaultErrorDescription' | translate }}</p>
                </div>
                <div class="error__button">
                  <button mat-button (click)="contactUs()">{{ 'stream-webrtc.contact' | translate }}</button>
                  <button mat-button (click)="refreshPage()">{{ 'stream-webrtc.refresh' | translate }}</button>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #streamTemplate>
    <div class="stream-viewer" [class.clear-screen]="(isStartingStreaming$ | async) || (isStreaming$ | async)">
      <div class="stream-viewer__menu">
        <div class="stream-viewer__information">
          <unleash-streaming-info
            *ngIf="!(isStartingStreaming$ | async)"
            [isStreaming]="isStreaming$ | async"
            [streamStopped]="streamStopped$ | async"
            [isConnected]="isConnected$ | async"
            [isShared]="isShared$ | async"
            [isloadingSlug]="isloadingSlug$ | async"
            [publicWatchPageURL]="publicWatchPageURL$ | async"
            [isStartingStreaming]="isStartingStreaming$ | async"
            [isEnablingLivePage]="isEnablingLivePage$ | async"
            [isLoadingCompanyInfo]="isLoadingCompanyInfo$ | async"
            [userDevices]="userDevices$ | async"
            [isStreamProxyEnabled]="isStreamProxyEnabled$ | async"
            (startStreaming)="startStream()"
            (generateUrl)="generateCompanySlug()"
            (enablePage)="enablePublicLivePage()"
            (copyUrl)="onUrlCopied()"
            (secureTunnel)="secureTunnel($event)"
            (newDeviceId)="setSelectedDeviceId($event); reloadAgora($event)"
          ></unleash-streaming-info>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="isStreaming$ | async">
    <div class="actions" [class.move-actions]="!(activeAddons$ | async)?.length">
      <div class="actions__stream-options">
        <!-- <button *ngIf="(models$ | async)?.length > 0" mat-button class="analyze" [matMenuTriggerFor]="aiMenu">
          <mat-icon class="analyze__icon">center_focus_strong</mat-icon>
          <p>{{ 'stream-webrtc.analyze' | translate }}</p>
          <div *ngIf="(activeAddons$ | async)?.length > 0" class="indicator">{{ (activeAddons$ | async)?.length }}</div>
        </button>
        <mat-menu class="ai-menu" #aiMenu="matMenu">
          <div class="menu-header">
            <p>{{ 'stream-webrtc.aiApps' | translate }}</p>
            <mat-icon class="menu-header__icon">close</mat-icon>
          </div>
          <div *ngFor="let model of models$ | async" class="radio-button" (click)="$event.stopPropagation()">
            <label class="radio-button__content">
              <input
                [ngStyle]="{display: (activeAddons$ | async)?.length > 0 ? 'flex' : 'none'}"
                type="checkbox"
                name="options"
                (input)="selectAnalysis(model)"
                [checked]="false"
              />
              <span class="radio-button__description"> {{ model.name }}</span>
            </label>
          </div>
        </mat-menu> -->
        <button class="stop" mat-button (click)="stopStream()">
          <mat-icon class="stop__icon">videocam_off</mat-icon>
          <p>{{ 'stream-webrtc.stop' | translate }}</p>
        </button>
      </div>
      <div class="actions__video-options">
        <button
          class="actions__switch-camera"
          mat-fab
          (click)="switchCamera()"
          *ngIf="(availableCameras$ | async) && (availableCameras$ | async)?.length > 1"
        >
          <mat-icon class="actions__switch-camera-icon" aria-label="switch camera">switch_camera</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="(agoraToken$ | async) && (activeAddons$ | async)?.length > 0" class="streaming-ai">
    <unleash-webrtc-player
      [autoStart]="true"
      #webrtcPlayer
      [deviceId]="selectedDeviceId | async"
      [agoraToken]="agoraToken$ | async"
      [agoraTokenRefreshCallback]="webrtcService.getWebRTCSubscriberToken"
      [streamAppId]="streamAppId"
      [isLiveStreaming]="false"
    ></unleash-webrtc-player>
  </div>
</div>

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'unleash-low-battery-dialog',
  templateUrl: './low-battery-dialog.component.html',
  styleUrls: ['./low-battery-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LowBatteryDialog {
  constructor(
    private dialogRef: MatDialogRef<LowBatteryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {deviceName: string}
  ) {}

  public startStreaming(): void {
    this.dialogRef.close(true);
  }
}

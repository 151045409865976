<form class="rules-form" [formGroup]="form">
  <div class="header">
    <unleash-rule-name
      [form]="form.controls.ruleName"
      [editMode]="editMode"
      (done)="emitDone($event)"
      (edit)="emitEdit()"
    ></unleash-rule-name>
    <mat-slide-toggle class="header__toggle" formControlName="enabled" (change)="emitValueChange()"></mat-slide-toggle>
  </div>
  <mat-divider></mat-divider>
  <h3 class="subtitle">{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.subtitleRule' | translate }}</h3>
  <div class="rules-container" *ngIf="form">
    <ng-container *ngIf="form.value.advancedMode; else simpleModeTemplate">
      <unleash-rule-row
        *ngFor="let rule of form.controls.rules.controls; let index = index"
        [form]="rule"
        [zones]="zones"
        [zoneList]="zoneList"
        [conditions]="conditions"
        [operators]="operators"
        [isAdvancedMode]="true"
        [hasDelete]="form.controls.rules.controls.length > 1"
        (zoneChange)="emitZoneChange(index)"
        (conditionChange)="emitConditionChange(index)"
        (valueChange)="emitValueChange()"
        (delete)="emitDelete(index)"
        (logicOperatorChange)="validateLogicOperatorChange(index)"
      ></unleash-rule-row>
    </ng-container>
    <ng-template #simpleModeTemplate>
      <unleash-simple-rule-row
        [form]="simpleForm"
        [zones]="zoneList"
        [operators]="operators"
        [conditions]="simpleConditions"
        (formChange)="emitSimpleModeChange($event)"
      ></unleash-simple-rule-row>
    </ng-template>
  </div>
  <h3 class="subtitle">{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.subtitleSettings' | translate }}</h3>
  <unleash-rule-settings
    [form]="form"
    [alerts]="alerts"
    [hasToDisplayCoolDown]="hasToDisplayCoolDown"
    [hasToDisableSeverity]="hasToDisableSeverity"
    [coolDownPeriods]="coolDownPeriods"
    (alertChange)="emitAlertChange($event)"
    (valueChange)="emitValueChange()"
  ></unleash-rule-settings>
</form>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <button class="back-button" mat-icon-button (click)="emitBack()">
        <mat-icon aria-label="label">arrow_back</mat-icon>
      </button>
      <div class="title">{{ team.name }}</div>
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="metadata">
      <div class="metadata__label">{{ 'common.name' | translate }}</div>
      <div class="metadata__text">{{ team.name }}</div>
    </div>

    <div class="metadata">
      <div class="metadata__label">{{ 'profile.teams.creationDate' | translate }}</div>
      <div class="metadata__text">{{ team.createdAt | date: 'mediumDate' }}</div>
    </div>

    <!-- <div class="metadata">
      <div class="metadata__label">{{ 'common.lastActive' | translate }}</div>
      <div class="metadata__text">{{ team.lastActive | date: 'mediumDate' }}</div>
    </div> -->

    <div class="actions">
      <button mat-raised-button color="primary" (click)="emitOpenAddUsersToTeam()">Add users</button>
    </div>
    <unleash-users-table
      hasMoreThanOneAdmin="true"
      [dataSource]="userBySelectedTeam"
      [menuItems]="menuItems"
      [roles]="roles"
      (action)="emitAction($event)"
    ></unleash-users-table>
  </mat-card-content>
</mat-card>

import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import {
  ConfirmRegistrationState,
  confirmRegistrationStateReducer
} from '../components/confirm-registration/confirm-registration.reducers';
import {
  ForgotPasswordStepTwoFormState,
  forgotPasswordStepTwoStateReducer
} from '../components/forgot-password-step-two/forgot-password-step-two.reducers';
import {
  ForgotPasswordFormState,
  forgotPasswordStateReducer
} from '../components/forgot-password/forgot-password.reducers';
import {
  NewPasswordFormState,
  newPasswordStateReducer
} from '../components/new-password/new-password.reducers';
import {
  ReasonState,
  reasonStateReducer
} from '../components/reason/reason.reducer';
import {
  SignInFormState,
  signInStateReducer
} from '../components/sign-in/sign-in.reducer';
import {
  SignUpFormState,
  signUpStateReducer
} from '../components/sign-up/sign-up.reducers';
import {
  YourProfileState,
  yourProfileStateReducer
} from '../components/your-profile/your-profile.reducers';

export const authFormFeatureKey = 'authForms';
export const selectAuthForms = createFeatureSelector<State, AuthFormsState>(
  authFormFeatureKey
);

export const signInFormStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.signInForm
);

export const signUpFormStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.signUpForm
);

export const confirmRegistrationStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.confirmRegistration
);

export const yourProfileStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.yourProfile
);

export const reasonStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.reason
);

export const newPasswordStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.newPassword
);

export const forgotPasswordStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.forgotPassword
);

export const forgotPasswordStepTwoStateSelector = createSelector(
  selectAuthForms,
  (state: AuthFormsState) => state.forgotPasswordStepTwo
);

export const authFormFeatureReducers: ActionReducerMap<AuthFormsState> = {
  signInForm: signInStateReducer,
  signUpForm: signUpStateReducer,
  confirmRegistration: confirmRegistrationStateReducer,
  yourProfile: yourProfileStateReducer,
  reason: reasonStateReducer,
  newPassword: newPasswordStateReducer,
  forgotPassword: forgotPasswordStateReducer,
  forgotPasswordStepTwo: forgotPasswordStepTwoStateReducer
};

export interface AuthFormsState {
  signInForm: SignInFormState;
  signUpForm: SignUpFormState;
  confirmRegistration: ConfirmRegistrationState;
  yourProfile: YourProfileState;
  reason: ReasonState;
  newPassword: NewPasswordFormState;
  forgotPassword: ForgotPasswordFormState;
  forgotPasswordStepTwo: ForgotPasswordStepTwoFormState;
}

export interface State {
  authForms: AuthFormsState;
}

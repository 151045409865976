<form [formGroup]="form" (submit)="clickForgotPasswordButton()" autocomplete="off">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'auth.forgot.verificationCode' | translate }}</mat-label>
    <input
      matInput
      formControlName="code"
      autofocus="true"
      autocomplete="off"
      type="tel"
      pattern="[0-9]*"
      data-test-id="codeInput"
    />
    <mat-error *ngIf="(form.controls.code.invalid && form.controls.code.touched) || form.controls.code.dirty"
      >{{ 'auth.forgot.verificationCode' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'auth.forgot.newPassword' | translate }}</mat-label>
    <input
      matInput
      type="password"
      formControlName="password"
      placeholder="{{ 'auth.forgot.passwordPlaceholder' | translate }}"
      data-test-id="newPasswordInput"
      autocomplete="off"
    />
    <mat-error
      *ngIf="(form.controls.password.invalid && form.controls.password.touched) || form.controls.password.dirty"
      >{{ 'auth.forgot.passwordPlaceholder' | translate }}</mat-error
    >
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'auth.forgot.passwordConfirmation' | translate }}</mat-label>
    <input
      matInput
      type="password"
      formControlName="passwordConfirmation"
      placeholder="{{ 'auth.forgot.passwordPlaceholder' | translate }}"
      data-test-id="passwrodConfirmationInput"
      autocomplete="off"
    />
    <mat-error
      *ngIf="form.controls.passwordConfirmation.errors?.mismatch && form.controls.passwordConfirmation.touched"
      >{{ 'auth.forgot.passwordConfirmationPlaceHolder' | translate }}</mat-error
    >
  </mat-form-field>
  <button
    class="form__item submit-button"
    mat-raised-button
    color="primary"
    [appLadda]="inProgress"
    [disabled]="inProgress"
    data-test-id="updatePasswordButton"
  >
    {{ 'auth.forgot.updatePassword' | translate }}
  </button>
</form>

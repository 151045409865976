<div class="edit-mission">
  <section class="header">
    <mat-icon class="header__icon" (click)="emitGoBack()">arrow_back</mat-icon>
    {{ 'common.settings' | translate }}
  </section>

  <div class="content">
    <section class="general">
      <span class="general__title">{{ 'atlas.mission.general' | translate }}</span>
      <div class="general__smart-inspect">
        <mat-checkbox
          ulSmartInspect
          (change)="emitIsSmartInspect($event)"
          (smartInspectSaved)="stopLoader()"
          (smartInspectSaving)="startLoader()"
          [checked]="isSmartInspect"
          class="general__smart-inspect-checkbox"
        ></mat-checkbox>
        <div class="general__smart-inspect-info">
          <div class="general__smart-inspect-title">
            <span>{{ 'atlas.mission.smartInspect' | translate }}</span>
            <unleash-spinner *ngIf="isLoadingSmartInspect" diameter="20"></unleash-spinner>
          </div>
          <span class="general__smart-inspect-description">{{
            'atlas.mission.smartInspectDescription' | translate
          }}</span>
        </div>
      </div>
    </section>

    <section class="default-waypoint">
      <span class="default-waypoint__title">{{ 'atlas.mission.defaultWaypoint' | translate }}</span>
      <span class="default-waypoint__setting">{{ 'atlas.mission.altitude' | translate }}</span>
      <span class="default-waypoint__setting-value"
        >{{ altitude | number: '0.0-0' }} {{ 'atlas.mission.altitudeMeasurement' | translate }}</span
      >
      <div class="default-waypoint__slider-container">
        0m
        <mat-slider
          ulDefaultSettings="altitude"
          class="default-waypoint__slider"
          [min]="0"
          [max]="200"
          [value]="altitude"
          (input)="setAltitude($event)"
          color="primary"
        >
          <input />
        </mat-slider>
        200m
      </div>

      <span class="default-waypoint__setting">{{ 'atlas.mission.speed' | translate }}</span>
      <span class="default-waypoint__setting-value"
        >{{ speed }} {{ 'atlas.mission.speedMeasurement' | translate }}</span
      >
      <div class="default-waypoint__slider-container">
        0m
        <mat-slider
          ulDefaultSettings="speed"
          class="default-waypoint__slider"
          [min]="0"
          [max]="20"
          [value]="speed"
          (input)="setSpeed($event)"
          color="primary"
        >
          <input />
        </mat-slider>
        20m/s
      </div>

      <span class="default-waypoint__setting">{{ 'atlas.mission.heading' | translate }}</span>
      <span class="default-waypoint__setting-value">{{ heading | number: '0.0-0' }} &deg;</span>
      <div class="default-waypoint__slider-container">
        0 &deg;
        <mat-slider
          ulDefaultSettings="heading"
          class="default-waypoint__slider"
          [min]="0"
          [max]="360"
          [value]="heading"
          (input)="setHeading($event)"
          color="primary"
        >
          <input />
        </mat-slider>
        360 &deg;
      </div>

      <span class="default-waypoint__setting">{{ 'atlas.mission.pitch' | translate }}</span>
      <span class="default-waypoint__setting-value">{{ pitch }} &deg;</span>
      <div class="default-waypoint__slider-container">
        -90 &deg;
        <mat-slider
          ulDefaultSettings="pitch"
          class="default-waypoint__slider"
          [min]="-90"
          [max]="30"
          [value]="pitch"
          (input)="setPitch($event)"
          color="primary"
        >
          <input />
        </mat-slider>
        30 &deg;
      </div>

      <span class="default-waypoint__setting">{{ 'atlas.mission.gimbalHeading' | translate }}</span>
      <span class="default-waypoint__setting-value">{{ gimbal }} &deg;</span>
      <div class="default-waypoint__slider-container">
        -180 &deg;
        <mat-slider
          ulDefaultSettings="gimbal"
          class="default-waypoint__slider"
          [min]="-180"
          [max]="180"
          [value]="gimbal"
          (input)="setGimbal($event)"
          color="primary"
        >
          <input />
        </mat-slider>
        180 &deg;
      </div>
    </section>
  </div>
</div>

<ng-container *ngIf="(myDevices$ | async)?.length > 0; else noDevicesTemplate">
  <table class="table" mat-table [dataSource]="isShowingAllDevices ? (myDevices$ | async) : (firstDataSource$ | async)">
    <ng-container matColumnDef="device">
      <th mat-header-cell *matHeaderCellDef>{{ 'profile.devices.device' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <span fxLayoutAlign="start center">
          <div class="device">
            <img
              class="img"
              *ngIf="device.logo"
              [src]="device.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo"
              alt="logo"
            />
            <mat-icon class="icon" *ngIf="!device.logo">live_tv</mat-icon>
          </div>
          <span class="device-description" fxLayout="column">
            <span>{{ device.name }}</span>
            <span class="device-type">{{ device.description }}</span>
          </span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="streamUrl">
      <th mat-header-cell *matHeaderCellDef>{{ 'profile.devices.streamUrl' | translate }}</th>
      <td mat-cell *matCellDef="let device">
        <button
          class="icon-button"
          mat-icon-button
          aria-label="{{ 'profile.devices.copyStreamUrl' | translate }}"
          [cdkCopyToClipboard]="deviceService.getStreamUrl(device, streamKey)"
          (copied)="onStreamUrlCopied()"
        >
          <div class="center-icon" mat-icon>
            <img src="assets/icons/profile/copy.svg" alt="{{ 'profile.devices.copyStreamUrl' | translate }}" />
          </div>
        </button>
      </td>
    </ng-container>

    <ng-container *uaHasPermission="aclPermissions.MainApiUpdateDevice">
      <ng-container matColumnDef="manage">
        <th mat-header-cell *matHeaderCellDef>{{ 'profile.devices.manage' | translate }}</th>
        <td mat-cell *matCellDef="let device">
          <button
            class="icon-button"
            mat-icon-button
            aria-label="{{ 'profile.devices.manage' | translate }}"
            (click)="$event.stopPropagation(); showDeviceDialog(device)"
          >
            <div class="center-icon" mat-icon>
              <img src="assets/icons/profile/pen.svg" alt="{{ 'profile.devices.manage' | translate }}" />
            </div>
          </button>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="show-collapse-button" (click)="showCollapseDevices()" *ngIf="modeThen4Devices">
    <div class="show-collapse-button-text">
      {{ isShowingAllDevices ? '- ' + ('common.collapse' | translate | uppercase) : showAllDevicesText }}
    </div>
  </div>
  <mat-divider></mat-divider>
  <ng-container *uaHasPermission="aclPermissions.MainApiSubscribeToPlan">
    <div *ngIf="isReachedDeviceLimit" class="limit-reached">
      <span class="limit-reached-text">
        {{ 'profile.devices.limitReached.0' | translate }}
        <span class="change-plan-link" routerLink="/secure/plans">{{
          'profile.devices.limitReached.1' | translate
        }}</span
        >&nbsp;{{ 'profile.devices.limitReached.2' | translate }}</span
      >
      <mat-icon
        aria-label="{{ 'profile.devices.pricingInfo' | translate }}"
        matTooltip="{{ 'profile.devices.pricingInfoTooltip' | translate }}"
        class="icon small-icon"
        >info
      </mat-icon>
    </div>
  </ng-container>
</ng-container>
<ng-template #noDevicesTemplate>
  <div class="no-devices">
    <ng-container *uaHasPermission="aclPermissions.MainApiCreateDevice; else noDeviceWithoutPermissionsTemplate">
      <span class="no-devices-text">{{ 'profile.devices.noDevices' | translate }}</span>
    </ng-container>
    <ng-template #noDeviceWithoutPermissionsTemplate>
      <span class="no-devices-text">{{ 'profile.devices.noDevicesWithoutPermissions' | translate }}</span>
    </ng-template>
  </div>
</ng-template>
<button
  *uaHasPermission="aclPermissions.MainApiCreateDevice"
  class="add-device-button"
  mat-raised-button
  color="primary"
  (click)="dispatchOpenDeviceModal($event)"
>
  {{ 'profile.devices.addDevice' | translate | uppercase }}
</button>

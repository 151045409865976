import {Injectable} from '@angular/core';
import {DateFormat, dateFormatInfo} from '../models/date-format.model';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
  dateFormatOptions: DateFormat[] = Object.values(dateFormatInfo);

  constructor() {}
}

<unleash-plan-card
  *ngFor="let option of options | keyvalue"
  [title]="option.value.planName | uppercase"
  [name]="option.value.packageName"
  [backgroundImage]="backgroundImage"
  [price]="option.value.priceMonthly"
  [plan]="option.value"
  [buttonName]="(initButtonName$ | async)[option.key]"
  [isFirstGroup]="stepIndex === 0 || stepIndex <= professionalIndex"
  [isSelected]="selectedCardId === option.value.id"
  [isInactive]="stepIndex !== 0 && (isEssentialSelectionDisabled | async) && option.value.planName === 'Essentials'"
  (action)="doAction($event, option.key)"
></unleash-plan-card>

import {ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {GeojsonFeatureState} from '@app/atlas/model/marker.model';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {TranslateService} from '@ngx-translate/core';
import {LatLng} from 'leaflet';
import {BehaviorSubject, Observable, from, take, tap} from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import {MAT_MENU_DEFAULT_OPTIONS} from '@angular/material/menu';
import {LibraryAppAsyncService} from '@app/library/services/library-app-async.service';
import {AclPermissions} from '@app/core/models/api/acl.model';

@Component({
  selector: 'unleash-marker-state-menu',
  templateUrl: './marker-state-menu.component.html',
  styleUrls: ['./marker-state-menu.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {overlayPanelClass: 'marker-state-menu'}
    }
  ]
})
export class MarkerStateMenuComponent implements OnInit {
  @Input() public feature: any;
  @Input() public markerGenerator: any;
  @Input() public layer: any;
  @Input() public asset: AtlasAssetModel;
  @Input() public mapCenter: LatLng;
  @Input() public mapZoom: number;

  public hasLibraryItem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasToCloseComponent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public geojsonFeatureState = GeojsonFeatureState;
  public hasToShowViewInMediadrive: boolean = false;
  public isLoadingLibraryItem: boolean = true;
  public hasToShowTooltip: boolean = false;
  public aclPermissions = AclPermissions;

  constructor(
    private cd: ChangeDetectorRef,
    private atlasService: AtlasService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router,
    private clipboard: Clipboard,
    private libraryAppAsyncService: LibraryAppAsyncService,
    private zone: NgZone
  ) {}

  public ngOnInit(): void {
    this.validateDisplayViewInMediadrive();
  }

  public setHasToShowTooltip(hasToShowTooltip: boolean): void {
    this.hasToShowTooltip = hasToShowTooltip;
    this.detectChanges();
  }

  // eslint-disable-next-line rxjs/finnish
  public getLibraryItem(): Observable<any> {
    return from(
      this.libraryAppAsyncService.getLibraryItem({
        id: this.feature.libraryItemId
      })
    ).pipe(
      take(1),
      tap(response => {
        this.hasLibraryItem.next(!!response.data.get);
        this.isLoadingLibraryItem = false;
      })
    );
  }

  public detectChanges(): void {
    this.cd.detectChanges();
  }

  public validateDisplayViewInMediadrive(): void {
    const hasImage =
      !!this.feature?.properties.rawPath ||
      (!!this.feature?.properties.description && this.feature?.properties.description.includes('img'));
    const isCompletedMarker = this.feature?.properties.state === this.geojsonFeatureState.COMPLETED;
    this.hasToShowViewInMediadrive = (hasImage && !this.feature?.properties.state) || (hasImage && isCompletedMarker);
  }

  public setMarkerState(state: GeojsonFeatureState) {
    if (state === this.feature.properties.state) {
      return;
    }
    this.feature.properties.state = state;
    const marker = this.markerGenerator(this.layer.getLatLng(), state);
    this.layer.setIcon(marker.options.icon);
    this.translateService
      .get('atlas.markerMenu.markerStatusChanged')
      .pipe(take(1))
      .subscribe(translation => {
        this.snackBar.open(translation, null, {panelClass: 'mission-uploaded', duration: 3000});
      });
    this.atlasService
      .changeMarkerState(this.asset.id, [{state: state, featureId: this.feature.index}])
      .pipe(take(1))
      .subscribe((response: any) => {
        this.asset.key = response.key;
      });
  }

  public viewInMediaDrive(): void {
    this.atlasService.setPreviousAtlasView(this.mapCenter, this.mapZoom);
    if ((this.feature as any).libraryItemId) {
      this.router.navigate([`/secure/view/${(this.feature as any).libraryItemId}`], {
        queryParams: {hasClose: true, skipSavePreviousUrl: true}
      });
      return;
    }

    this.router.navigate([`/secure/view/${this.layer.geojson.parentId}`], {
      queryParams: {name: this.feature.properties.name, hasClose: true, skipSavePreviousUrl: true}
    });
    return;
  }

  public copyToClipboard(): void {
    this.clipboard.copy(`${this.layer.getLatLng().lat},${this.layer.getLatLng().lng}`);
    this.translateService
      .get('atlas.map.copiedToClipboard')
      .pipe(take(1))
      .subscribe(res => {
        this.zone.run(() => {
          this.snackBar.open(res, null, {
            duration: 3000,
            panelClass: 'center'
          });
        });
      });
  }

  public selectMarkers(): void {
    this.hasToCloseComponent.next(true);
    const completeAsset = this.atlasService.getAssetById(this.asset.id);
    this.atlasService.startSelectingMarkers(completeAsset);
  }
}

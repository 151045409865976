/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-case-declarations */
import {Injectable} from '@angular/core';
import {ActivatedRoute, GuardsCheckEnd, Router} from '@angular/router';
import {LIBRARY_ITEM_TYPE_MODEL} from '@app/library/models/mime-type.model';
import {environment} from 'environments/environment';
import {combineLatest, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {UserService} from './api/user.service';

const {version: appVersion} = require('../../../../package.json');

declare global {
  interface Window {
    analytics: any;
    mixpanel: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UnleashAnalyticsService {
  private userMyCompanySubscription: Subscription;
  private watchRouteChanges: Subscription;

  constructor(public userService: UserService, private router: Router) {
    this.loadSegmentIoAnalytics();
    this.watchRouteChangesEvent();
  }

  public resetAnalytics() {
    if (this.userMyCompanySubscription) {
      this.userMyCompanySubscription.unsubscribe();
      this.userMyCompanySubscription = null;
    }
    window.analytics?.reset();
    window.mixpanel?.reset();
  }

  public initAnalytics() {
    if (this.userMyCompanySubscription) {
      console.warn('Analytics already loaded. Throttling...');
      return;
    }

    this.userMyCompanySubscription = combineLatest([this.userService.user$, this.userService.myCompany$]).subscribe(
      ([user, company]) => {
        if (company && company.name && company.industry) {
          const userIdentityId = user.id;
          const userAttributes = {
            name: user.fullName,
            email: user.email,
            plan: user.currentPlan,
            isDeveloper: user.developer,
            company: company.name,
            industry: company.industry,
            appVersion
          };
          this.setUser(userIdentityId, userAttributes);
        }
      }
    );
  }

  public logEvent(event: EVENTS, data?: any) {
    window.analytics.track(event, data);

    const eventDescription = `ANALYTICS: event ${event} logged`;
    if (data) {
      this.analyticsLogger(AnalyticsLoggerType.group, {eventDescription, data});
    } else {
      this.analyticsLogger(AnalyticsLoggerType.simple, {eventDescription});
    }
  }

  public logOpenPaymentEvent(url: string) {
    window.analytics.page(`${url}?type=OPEN_${EVENTS.PAYMENT_DIALOG}`);
    this.analyticsLogger(AnalyticsLoggerType.simple, {
      eventDescription: `ANALYTICS: page ${url}?type=OPEN_${EVENTS.PAYMENT_DIALOG} logged`
    });
  }

  public listenAsQueryParam(route: ActivatedRoute, queryParams: {[key: string]: string}) {
    this.router.navigate([], {
      relativeTo: route,
      queryParams,
      skipLocationChange: true,
      preserveFragment: false
    });
  }

  private watchRouteChangesEvent() {
    let page = null;
    this.watchRouteChanges = this.router.events
      .pipe(filter(event => event instanceof GuardsCheckEnd))
      .subscribe((event: GuardsCheckEnd) => {
        if (/annotation/.test(event.url)) {
          return;
        }

        if (/secure\/library/.test(event.url)) {
          const queryMatch = new RegExp(
            `((${LIBRARY_ITEM_TYPE_MODEL.IMAGE}|${LIBRARY_ITEM_TYPE_MODEL.VIDEO}|${LIBRARY_ITEM_TYPE_MODEL.MODEL}))`,
            'g'
          );
          const urlTokens = event.url.split('/');
          const urlTolenIndexType = 2;
          const indexType = 0;
          const match = urlTokens[urlTolenIndexType].match(queryMatch);
          const isSessionItem = urlTokens.length > 3;

          let type = null;
          let eventName = null;

          if (match) {
            type = match[indexType];
            page = type;
          }

          switch (page) {
            case LIBRARY_ITEM_TYPE_MODEL.IMAGE:
              eventName = 'Library#photos';
              break;
            case LIBRARY_ITEM_TYPE_MODEL.VIDEO:
              eventName = 'Library#videos';
              break;
            case LIBRARY_ITEM_TYPE_MODEL.MODEL:
              eventName = 'Library#models';
              break;
            case 'aiclips':
              eventName = 'Library#aiclips';
              break;
            case '2d':
              eventName = 'Model - 2D';
              break;
            case '3d':
              eventName = 'Model - 3D';
              break;
            case 'pc':
              eventName = 'Model - Point Cloud';
              break;
            default:
              eventName = 'Library#all';
              page = 'all';
              break;
          }

          if (isSessionItem) {
            eventName = eventName + ' - Session';
          }

          window.analytics.page(eventName);
          this.analyticsLogger(AnalyticsLoggerType.simple, {
            eventDescription: `ANALYTICS: page ${eventName} logged`
          });
        } else {
          let urlTokens = event.url.split('/');
          let pageRootName = urlTokens.slice(2, 3)[0];
          let queryParamsString = '';

          if (/\?/.test(event.url)) {
            const queryTokens = event.url.split('?');
            urlTokens = queryTokens[0].split('/');
            pageRootName = urlTokens.slice(2, 3)[0];
            if (queryTokens.length > 1) queryParamsString = queryTokens[1];
          }

          let eventName = '';
          switch (pageRootName) {
            case 'dashboard':
            case 'live':
            case 'analysis':
            case 'store':
            case 'plans':
            case 'coming-soon':
            case 'developer':
            case 'analytics':
              eventName = pageRootName;
              break;
            case 'atlas':
            case 'flights':
              const subPageRootName = urlTokens.slice(2, 4).join(' ');
              eventName = subPageRootName;
              let data = {};

              if (queryParamsString) {
                data = this.parseUrlParams(queryParamsString);
              }

              eventName = subPageRootName;
              console.info('eventName', eventName, 'data', {...data});

              window.analytics.page(eventName);
              this.analyticsLogger(AnalyticsLoggerType.simple, {
                eventDescription: `ANALYTICS: page ${eventName} logged`,
                data: {...data}
              });
              return;
            case 'profile':
              const match = event.url.match(
                // eslint-disable-next-line no-useless-escape
                /(\#(usage|plan|devices|streaming|profile|organisation|data-privacy|developer|experimental-features))/
              );
              const indexType = 2;
              eventName = 'profile';
              if (match && match.length > 3) {
                eventName += ' ' + match[indexType];
              }
              break;
            case 'image-comparison':
              eventName = 'image-comparison';

              const imagesValues = queryParamsString.match(/images=([^&]+)/g);
              const images = imagesValues.filter(value => value).map(value => value.replace('images=', ''));
              const params = this.parseUrlParams(queryParamsString);

              console.info('eventName', eventName, 'data', {...params, images});
              window.analytics.page(eventName);
              this.analyticsLogger(AnalyticsLoggerType.simple, {
                eventDescription: `ANALYTICS: page ${eventName} logged`,
                data: {...params, images}
              });
              return;
            default:
              eventName = pageRootName;
              break;
          }

          window.analytics.page(eventName);
          this.analyticsLogger(AnalyticsLoggerType.simple, {
            eventDescription: `ANALYTICS: page ${eventName} logged`
          });
        }
      });
  }

  private setUser(
    userId: string,
    userAttributes: {
      name: string;
      email: string;
      plan: string;
      isDeveloper: boolean;
      appVersion: string;
    }
  ) {
    window.analytics.identify(userId, userAttributes);
    this.analyticsLogger(AnalyticsLoggerType.group, {
      eventDescription: `ANALYTICS: user ${userAttributes.email} set`,
      data: userAttributes
    });
  }

  private analyticsLogger(type: AnalyticsLoggerType, metadata: {eventDescription: string; data?: any}) {
    if (!environment.production) {
      switch (type) {
        case AnalyticsLoggerType.group:
          console.groupCollapsed(metadata.eventDescription, metadata.data);
          console.groupEnd();
          break;
        case AnalyticsLoggerType.simple:
          console.info(metadata.eventDescription);
          break;
      }
    }
  }

  private loadSegmentIoAnalytics() {
    // Create a queue, but don't obliterate an existing one!
    const analytics = (window.analytics = window.analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
      return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      console.error('Segment snippet included twice.');
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on'
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        const args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function (key, options) {
      // Create an async script element based on your key.
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.13.1';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(environment.SEGMENT_IO_API_KEY);

    // Make the first page call to load the integrations. If
    // you'd like to manually name or tag the page, edit or
    // move this call however you'd like.
    analytics.page();
  }

  private parseUrlParams(queryParams: string): {[key: string]: any} {
    const searchParams = new URLSearchParams(queryParams);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    return params;
  }
}

export enum AnalyticsLoggerType {
  group = 'group',
  simple = 'simple'
}

export enum EVENTS {
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT = 'LOGOUT',
  AUTH_FAILED = 'AUTH_FAILED',
  AUTH_SIGN_UP = 'AUTH_SIGN_UP',
  AUTH_PLAN_EXPIRED = 'AUTH_PLAN_EXPIRED',
  TRIAL_STARTED = 'TRIAL_STARTED',
  DELETE_LIBRARY_ITEMS = 'DELETE_LIBRARY_ITEMS',
  UPLOAD_FILES_START = 'UPLOAD_FILES_START',
  UPLOAD_FILES_FINISH = 'UPLOAD_FILES_FINISH',
  UPLOAD_FOLDER_START = 'UPLOAD_FOLDER_START',
  UPLOAD_FOLDER_FINISH = 'UPLOAD_FOLDER_FINISH',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  BROWSER_ERROR = 'BROWSER_ERROR',
  SUBMIT_JOB = 'SUBMIT_JOB',
  MODEL_EXPORT = 'MODEL_EXPORT',
  STREAM_START = 'STREAM_START',
  STREAM_STOP = 'STREAM_STOP',
  STREAM_SWITCH_CAMERA = 'STREAM_SWITCH_CAMERA',
  STREAM_FAILED = 'STREAM_FAILED',
  STREAM_LEAVE_CHANNEL = 'STREAM_LEAVE_CHANNEL',
  DEVICE_ADD_TO_USER = 'DEVICE_ADD_TO_USER',
  DEVICE_REMOVE_FROM_USER = 'DEVICE_REMOVE_FROM_USER',
  DEVICE_FAILED = 'DEVICE_FAILED',
  DEVICE_UPDATE_LOGO = 'DEVICE_UPDATE_LOGO',
  APP_STORE_START_TRIAL = 'APP_STORE_START_TRIAL',
  APP_STORE_ACTIVATE_ADDON = 'APP_STORE_ACTIVATE_ADDON',
  APP_STORE_DEACTIVATE_ADDON = 'APP_STORE_REMOVE_ADDON',
  APP_STORE_FAILED = 'APP_STORE_FAILED',
  APP_STORE_MODAL = 'APP_STORE_MODAL',
  APP_STORE_CONTACT = 'APP_STORE_CONTACT',
  APP_STORE_FILTER = 'APP_STORE_FILTER',
  APP_STORE_FILTER_ITEM = 'APP_STORE_FILTER_ITEM',
  APP_STORE_SORT = 'APP_STORE_SORT',
  HELP_OPENED = 'HELP_OPENED',
  LIBRARY_RENAME = 'LIBRARY_RENAME',
  LIBRARY_FILTER = 'LIBRARY_FILTER',
  LIBRARY_MEDIA_DOWNLOAD = 'LIBRARY_MEDIA_DOWNLOAD',
  LIBRARY_MEDIA_OPEN = 'LIBRARY_MEDIA_OPEN',
  GDRIVE_IMPORT_STARTED = 'GDRIVE_IMPORT_STARTED',
  SKYDIO_IMPORT_STARTED = 'SKYDIO_IMPORT_STARTED',
  S3_IMPORT_STARTED = 'S3_IMPORT_STARTED',
  S3_EXPORT_STARTED = 'S3_EXPORT_STARTED',
  DEVICE_FAVOURITED = 'DEVICE_FAVOURITED',
  DEVICES_SORTED = 'DEVICES_SORTED',
  DEVICE_ADDED_TO_CINEMA = 'DEVICE_ADDED_TO_CINEMA',
  DEVICES_CHANGED_TAB = 'DEVICES_CHANGED_TAB',

  ATLAS_MAP_SEARCH = 'ATLAS_MAP_SEARCH',
  ATLAS_CONTROLS = 'ATLAS_CONTROLS',
  ATLAS_LAYER_VISIBILITY = 'ATLAS_LAYER_VISIBILITY',
  ATLAS_LAYER_ACTION = 'ATLAS_LAYER_ACTION',
  ATLAS_LAYER_SEARCH = 'ATLAS_LAYER_SEARCH',
  ATLAS_LAYER_UPLOAD = 'ATLAS_LAYER_UPLOAD',
  ATLAS_ANNOTATIONS = 'ATLAS_ANNOTATIONS',
  ATLAS_LAYERS_LOADED = 'ATLAS_LAYERS_LOADED',
  ATLAS_COMPARE_LAYERS = 'ATLAS_COMPARE_LAYERS',

  SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED',
  WELCOME_SKIPPED = 'WELCOME_SKIPPED',
  STREAM_PLAYER = 'STREAM_PLAYER',
  STREAM_PLAYER_RESTREAM = 'STREAM_PLAYER_RESTREAM',
  STREAM_PLAYER_AI = 'STREAM_PLAYER_AI',
  PAYMENT_DIALOG = 'PAYMENT_DIALOG',
  PLAN_SUBSCRIPTION = 'PLAN_SUBSCRIPTION',
  USER_CONFIRMED_REGISTRATION = 'USER_CONFIRMED_REGISTRATION',
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
  OPEN_NOTIFICATION_SIDEBAR = 'OPEN_NOTIFICATION_SIDEBAR',
  PRESS_NOTIFICATION_ACTION_BUTTON = 'PRESS_NOTIFICATION_ACTION_BUTTON',
  REMOTE_COCKPIT_USER_CLICK_LEARN_MORE = 'REMOTE_COCKPIT_USER_CLICK_LEARN_MORE',
  REMOTE_COCKPIT_USER_CLICK_REQUEST_UPGRADE = 'REMOTE_COCKPIT_USER_CLICK_REQUEST_UPGRADE',
  REMOTE_COCKPIT_USER_CLICK_GOOGLE_PLAY = 'REMOTE_COCKPIT_USER_CLICK_GOOGLE_PLAY',

  MANAGER_ZONES_OPEN_ALERT_TAB = 'MANAGER_ZONES_OPEN_ALERT_TAB',
  MANAGER_ZONES_OPEN_ZONE_TAB = 'MANAGER_ZONES_OPEN_ZONE_TAB',
  MANAGER_ZONES_OPEN_LIVESTREAM_PROCESSING = 'MANAGER_ZONES_OPEN_LIVESTREAM_PROCESSING',
  MANAGER_ZONES_OPEN_POST_PROCESSING = 'MANAGER_ZONES_OPEN_POST_PROCESSING',
  MANAGER_ZONES_RULE_ACTIVE = 'MANAGER_ZONES_RULE_ACTIVE',
  MANAGER_ZONES_RULE_INACTIVE = 'MANAGER_ZONES_RULE_INACTIVE',
  MANAGER_ZONES_RULE_DELETE = 'MANAGER_ZONES_RULE_DELETE',
  MANAGER_ZONES_ADD_RULE = 'MANAGER_ZONES_ADD_RULE',

  LIBRARY_EXPORT_ANNOTATION = 'LIBRARY_EXPORT_ANNOTATION',
  LIBRARY_SHARE_FILE = 'LIBRARY_SHARE_FILE',
  LIBRARY_CREATE_NEW_TAG = 'LIBRARY_CREATE_NEW_TAG',
  LIBRARY_ADD_TAG = 'LIBRARY_ADD_TAG',
  LIBRARY_REMOVE_TAG = 'LIBRARY_REMOVE_TAG',
  LIBRARY_MOVE_FILE = 'LIBRARY_MOVE_FILE',
  LIBRARY_DELETE_ITEM = 'LIBRARY_DELETE_ITEM',

  IMAGE_VIEWER_ADD_ANNOTATION = 'IMAGE_VIEWER_ADD_ANNOTATION',
  IMAGE_VIEWER_OPEN_ANNOTATION_TAB = 'IMAGE_VIEWER_OPEN_ANNOTATION_TAB',

  IMAGE_VIEWER_SAM_REFINEMENT_ADD_BUTTON = 'IMAGE_VIEWER_SAM_REFINEMENT_ADD_BUTTON',
  IMAGE_VIEWER_SAM_REFINEMENT_REMOVE_BUTTON = 'IMAGE_VIEWER_SAM_REFINEMENT_REMOVE_BUTTON',
  IMAGE_VIEWER_SAM_REFINEMENT_RESET_BUTTON = 'IMAGE_VIEWER_SAM_REFINEMENT_RESET_BUTTON',
  IMAGE_VIEWER_SAM_REFINEMENT_REDO_BUTTON = 'IMAGE_VIEWER_SAM_REFINEMENT_REDO_BUTTON',
  IMAGE_VIEWER_SAM_REFINEMENT_UNDO_BUTTON = 'IMAGE_VIEWER_SAM_REFINEMENT_UNDO_BUTTON',

  ANNOTATIONS_SUMMARY_NAVIGATION_BACK = 'ANNOTATIONS_SUMMARY_NAVIGATION_BACK',
  ANNOTATIONS_SUMMARY_SELECT_ADDON = 'ANNOTATIONS_SUMMARY_SELECT_ADDON',
  ANNOTATIONS_SUMMARY_ENTER = 'ANNOTATIONS_SUMMARY_ENTER',
  ANNOTATIONS_SUMMARY_CHANGE_FILTERS = 'ANNOTATIONS_SUMMARY_CHANGE_FILTERS',
  ANNOTATIONS_SUMMARY_EXPORT_PDF = 'ANNOTATIONS_SUMMARY_EXPORT_PDF',
  ANNOTATIONS_SUMMARY_EXPORT_CSV = 'ANNOTATIONS_SUMMARY_EXPORT_CSV',
  ANNOTATIONS_SUMMARY_EXPORT_COCO_JSON = 'ANNOTATIONS_SUMMARY_EXPORT_COCO_JSON',

  PANORAMA_IMAGE_VIEWED = 'PANORAMA_IMAGE_VIEWED',
  PANORAMA_IMAGE_OPENED_FULLSCREEN = 'PANORAMA_IMAGE_OPENED_FULLSCREEN',

  MISSION_PLANNER_SURVEY_MISSION_UPLOADED = 'MISSION_PLANNER_SURVEY_MISSION_UPLOADED',
  MISSION_PLANNER_SURVEY_MISSION_VIEWED = 'MISSION_PLANNER_SURVEY_MISSION_VIEWED'
}

import {Pipe, PipeTransform} from '@angular/core';
import {Addon} from '@app/store/addon/models/addon';
import {AddonStateEnum} from '@app/store/addon/enums/addon-state.enum';

@Pipe({name: 'addonIsEditable'})
export class AddonIsEditablePipe implements PipeTransform {
  private readonly disabledEditState: AddonStateEnum[] = [
    AddonStateEnum.inReview,
    AddonStateEnum.published,
    AddonStateEnum.approved
  ];

  public transform(value: Addon): boolean {
    return this.disabledEditState.indexOf(value.state) === -1;
  }
}

<nav class="global-process-bar">
  <div class="global-process-bar__container">
    <ng-container *ngIf="isLoading$ | async; else backButtonTemplate">
      <div class="spinner-container">
        <unleash-spinner [diameter]="20"></unleash-spinner>
      </div>
    </ng-container>
    <ng-template #backButtonTemplate>
      <button class="global-process-bar__button" mat-icon-button (click)="closeDialog()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </ng-template>
    <div class="global-process-bar__info">
      <ng-container *ngIf="addon; else addonNotSelected">
        <h1 class="global-process-bar__title">{{ 'live.manage-zones-dialog.headerTitleEmptyAddon' | translate }}</h1>
        <div class="global-process-bar__title--subtitle">
          {{ device.name }}
          &#8226;
          {{ addon.name }}
          &#8226;
          <ng-container *ngIf="(isZonesLoading$ | async) === false; else isLoadingTemplate">
            {{ zonesLength$ | async }}
          </ng-container>
          <ng-container *ngIf="addon?.canSendNotifications">
            &#8226;
            <ng-container *ngIf="(isNotificationsConfigLoading$ | async) === false; else isLoadingTemplate">
              {{ alertsLength$ | async }}
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #addonNotSelected>
        <div class="global-process-bar__title">
          <h1 class="global-process-bar__title">{{ 'live.manage-zones-dialog.headerTitleEmptyAddon' | translate }}</h1>
        </div>
      </ng-template>
    </div>
  </div>
</nav>

<mat-tab-group
  [preserveContent]="true"
  [selectedIndex]="selectedTabIndex"
  (selectedTabChange)="changeSelectedTab($event)"
>
  <mat-tab
    label="{{ 'live.manage-zones-dialog.tabTitle' | translate }}"
    (click)="emitEvent(events.MANAGER_ZONES_OPEN_ALERT_TAB)"
  >
    <ng-container *ngIf="imageSnapshot$ | async as image">
      <div class="content">
        <div class="detail" [style.display]="image ? 'unset' : 'none'">
          <ng-container *ngIf="!(isDrawOrEditMode$ | async); else zoneEditTemplate">
            <section class="detail__header">
              <button mat-button class="detail__header__button" [matMenuTriggerFor]="menu" color="primary">
                <mat-icon svgIcon="u_leading"></mat-icon>
                <span class="detail__header__text--spacing">{{ 'live.manage-zones-dialog.addZone' | translate }}</span>
                <mat-icon svgIcon="u_trailing"></mat-icon>
              </button>
            </section>
            <mat-divider></mat-divider>
            <ng-container *ngIf="(isZonesLoading$ | async) === false; else isLoadingTemplate">
              <div class="detail__zones-container" *ngIf="supportedZonesTypes.length > 0">
                <div #zoneList class="detail__actions">
                  <ng-container *ngIf="(visibleZonesLength$ | async) > 0; else noZonesTemplate">
                    <div class="detail__actions__zones">
                      <div *ngFor="let zone of zones$ | async; let i = index; trackBy: trackByFn">
                        <section
                          class="action"
                          *ngIf="!zone.removed"
                          (click)="selectZone(zone); $event.preventDefault()"
                          (mouseenter)="highlightShape(zone.id)"
                          (mouseleave)="restoreShapesOpacity(zone.id)"
                          [class.hovered-shape]="(zoneHighlight$ | async) === zone.id"
                        >
                          <div class="action__info">
                            <div class="action__info__color" [ngClass]="['color__' + zone.color.name]"></div>
                            <div class="action__info__text">
                              <span class="action__info__text--name">{{ zone.display_name }}</span>
                              <span class="action__info__text--type">{{ shapeTypeNames[zone.shape_type] }}</span>
                            </div>
                          </div>
                          <div class="action__buttons">
                            <ng-container *ngIf="zone.shape_type !== shapeTypes.perspective">
                              <unleash-trigger-point
                                [mtxTooltip]="triggerPointTooltip"
                                [mtxTooltipPosition]="'right'"
                                [triggerPoint]="zone.properties?.bbox_anchor || triggerPoint.center"
                                (click)="
                                  setHoverBlockName(zone.properties?.bbox_anchor);
                                  $event.stopPropagation();
                                  $event.preventDefault()
                                "
                                class="trigger-point"
                                [matMenuTriggerFor]="triggerPointMenu"
                                [matMenuTriggerData]="{zone: zone}"
                                (menuClosed)="save()"
                                (mouseenter)="setTemporalTooltipTriggerPoint(zone.properties?.bbox_anchor)"
                              ></unleash-trigger-point>
                            </ng-container>
                            <button
                              (click)="$event.stopPropagation(); $event.preventDefault()"
                              mat-icon-button
                              [matMenuTriggerFor]="menu"
                            >
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item (click)="selectZone(zone)">
                                <mat-icon svgIcon="u_edit_zone"></mat-icon>
                                <span>{{ 'live.manage-zones-dialog.menu.editZone' | translate }}</span>
                              </button>
                              <button mat-menu-item [matMenuTriggerFor]="colorMenu" [matMenuTriggerData]="{zone: zone}">
                                <mat-icon svgIcon="u_color_picker"></mat-icon>
                                <span>{{ 'live.manage-zones-dialog.menu.zoneColor' | translate }}</span>
                              </button>
                              <mat-divider></mat-divider>
                              <button mat-menu-item (click)="deleteZone(zone)">
                                <mat-icon color="warn">delete</mat-icon>
                                <span class="delete-text">{{ 'common.delete' | translate }}</span>
                              </button>
                            </mat-menu>
                          </div>
                        </section>

                        <ng-container *ngIf="showMoreSettings[i]">
                          <div class="more-settings">
                            <mat-icon class="more-settings__close-button" (click)="toggleMoreSettings(i)"
                              >close</mat-icon
                            >
                            <mat-form-field>
                              <input matInput [(ngModel)]="zone.csv_label" required autocomplete="off" />
                              <mat-hint>{{ 'live.manage-zones-dialog.details.csvLabel' | translate }}</mat-hint>
                            </mat-form-field>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <div class="detail__footer"></div>
            <div class="detail__bottom-actions">
              <button class="actions__button" mat-button color="primary" (click)="closeDialog()">
                {{ 'common.done' | translate }}
              </button>
              <ng-container *uaHasPermission="aclPermissions.LivestreamApiStreamPublish">
                <button
                  *ngIf="!dialogRef"
                  class="actions__button"
                  mat-raised-button
                  color="primary"
                  (click)="analyze()"
                  [disabled]="(isDrawOrEditMode$ | async) || (isLoadingImage | async)"
                >
                  {{ 'live.manage-zones-dialog.details.action_analyze' | translate }}
                </button>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="zone" [class.is-dragging]="isDragging$ | async" #zone>
          <div #cursor class="cursor">
            <div #verticalCursor class="cursor__vertical"></div>
            <div #horizontalCursor class="cursor__horizontal"></div>
          </div>
          <ng-container *ngIf="hint$ | async as hint">
            <div class="alert-container">
              <unleash-alert class="alert" [label]="hint" type="warning-dark" iconName="info"></unleash-alert>
            </div>
          </ng-container>
          <pinch-zoom
            #pinchZoom
            (zoomInEnded)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
            (zoomOutEnded)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
            (wheel)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
            [properties]="{
              maxScaleConfig: 100,
              disableZoomControl: 'disable',
              zoomModel: 'white',
              minPanScale: 0.5,
              minScale: 0.3
            }"
            [zoomControlScale]="0.2"
            [canvasDiagonalWidth]="canvasDiagonalWidth$ | async"
            [naturalImageDiagonalWidth]="naturalImageDiagonalWidth$ | async"
            [backgroundColor]="null"
            class="zoom"
            [ngClass]="{
              'is-drawing':
                (drawStatus$ | async) === drawStatus.draw &&
                (selectedZone$ | async)?.shape_type === shapeTypes.perspective
            }"
          >
            <unleash-loader
              *ngIf="isLoadingImage | async"
              class="loader"
              [visible]="true"
              [text]="'live.manage-zones-dialog.loading' | translate"
            ></unleash-loader>
            <unleash-image-annotation-shared
              #imageAnnotationShared
              (canvasLoaded)="stopLoader(); setSelectedAddon(); emitCanvasIsReady($event)"
              [srcLowRes]="image.data | uThumbler: thumblerConfig"
              [zoom]="pinchZoom.scale"
              [isMobileView]="isMobileView$ | async"
              [src]="image.data | uThumbler: thumblerConfig"
              [drawStatus]="drawStatus$ | async"
              [isHoveringShape]="isHoveringShape$ | async"
              [style.width]="(isLoadingImage | async) ? '0' : '100%'"
            ></unleash-image-annotation-shared>
          </pinch-zoom>
        </div>
      </div>
    </ng-container>
  </mat-tab>
  <mat-tab [label]="calibrationLabel$ | async">
    <ng-template matTabContent>
      <ua-calibration></ua-calibration>
    </ng-template>
  </mat-tab>
  <mat-tab
    [label]="
      ((isLivestream$ | async)
        ? 'live.manage-zones-dialog.notificationsTab.title'
        : 'live.manage-zones-dialog.notificationsTab.title2'
      ) | translate
    "
    [disabled]="isLoading$ | async"
    (click)="emitEvent(events.MANAGER_ZONES_OPEN_ZONE_TAB)"
    *ngIf="addon?.canSendNotifications"
  >
    <unleash-notifications-tab
      [rules]="rules$ | async"
      [selectedRule]="selectedRule$ | async"
      [menuOptions]="menuOptions"
      [form]="form$ | async"
      [simpleForm]="simpleForm$ | async"
      [editMode]="editMode$ | async"
      [zones]="areaOfInterest$ | async"
      [zoneList]="polygonZones$ | async"
      [conditions]="conditions"
      [simpleConditions]="simpleConditions$ | async"
      [operators]="operators"
      [alerts]="alerts"
      [performAnalysisLoading]="performAnalysisLoading$ | async"
      [coolDownPeriods]="coolDownPeriods"
      [hasToDisplayCoolDown]="hasToDisplayCoolDown$ | async"
      [hasToDisableSeverity]="hasToDisableSeverity$ | async"
      (addRule)="addRule()"
      (action)="executeRuleAction($event)"
      (zoneChange)="addConditionSelectToRule($event)"
      (conditionChange)="addOperatorAndValueSelectToRule($event)"
      (valueChange)="saveValueChange($event)"
      (editName)="startEditMode()"
      (editNameDone)="stopEditMode($event)"
      (selectedRuleId)="selectRule($event)"
      (alertChange)="changeAlertSeveritySettings($event)"
      (back)="moveToZonesTab()"
      (analyze)="analyzeWithRules()"
      (delete)="deleteNestedRule($event)"
      (simpleModeChange)="parseSimpleMode($event)"
      (openHelp)="openHelp()"
      (toggleAdvancedMode)="toggleAdvancedMode()"
    ></unleash-notifications-tab>
  </mat-tab>
</mat-tab-group>

<ng-template #zoneEditTemplate>
  <div class="detail__header">
    <div class="detail__header__button">
      <button mat-icon-button (click)="cancel()"><mat-icon>arrow_back</mat-icon></button>
      <span class="detail__header__text">
        {{ (isNewDraw$ | async) ? ('common.add' | translate) : ('common.edit' | translate) }}
        {{ shapeTypeNames[(selectedZone$ | async)?.shape_type] }}
      </span>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="detail__content">
    <form class="detail__content--block" [formGroup]="displayNameForm">
      <mat-form-field class="detail__input" appearance="fill">
        <mat-label>{{ 'common.name' | translate }}</mat-label>
        <input matInput formControlName="displayName" />
      </mat-form-field>
    </form>
    <section class="detail__content--block">
      <span class="detail__info__subtitle">{{ 'common.type' | translate }}</span>
      <span>{{ shapeTypeNames[(selectedZone$ | async)?.shape_type] }}</span>
    </section>

    <section class="detail__content--block">
      <span class="detail__info__subtitle">{{ 'live.manage-zones-dialog.details.colour' | translate }}</span>
      <unleash-color-picker
        [selectedColor]="selectedZoneColor$ | async"
        [isLeftLayout]="true"
        (updateColorTag)="updateCurrentColorTemporal($event)"
      ></unleash-color-picker>
    </section>

    <section class="detail__content--block">
      <span class="detail__info__subtitle" *ngIf="isSelectedPerspectiveZone$ | async">{{
        'live.manage-zones-dialog.details.dimensionsOfZone' | translate
      }}</span>

      <form *ngIf="isSelectedPerspectiveZone$ | async" [formGroup]="perspectiveForm" class="perspective-form">
        <mat-form-field appearance="fill" class="perspective-form__input">
          <input uaDecimalInputMask formControlName="a" type="text" placeholder="00.00" matInput />
          <span class="perspective-form__prefix" matPrefix>A</span>
          <span class="perspective-form__suffix" matSuffix>cm</span>
          <mat-error *ngIf="perspectiveForm.controls.a.invalid && perspectiveForm.controls.a.touched">{{
            'live.manage-zones-dialog.errors.invalidInputFormat' | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="perspective-form__input">
          <input uaDecimalInputMask formControlName="b" type="text" placeholder="00.00" matInput />
          <span class="perspective-form__prefix" matPrefix>B</span>
          <span class="perspective-form__suffix" matSuffix>cm</span>
          <mat-error *ngIf="perspectiveForm.controls.b.invalid && perspectiveForm.controls.b.touched">{{
            'live.manage-zones-dialog.errors.invalidInputFormat' | translate
          }}</mat-error>
        </mat-form-field>
      </form>
    </section>

    <section *ngIf="selectedZone$ | async as selectedZone" class="detail__content--block">
      <ng-container *ngIf="selectedZone.shape_type !== shapeTypes.perspective">
        <span class="detail__info__subtitle"
          >{{ 'live.manage-zones-dialog.triggerPoint.triggerPoint' | translate }}
          <mat-icon
            color="grey-6"
            class="trigger-point__info-icon"
            [mtxTooltip]="infoTriggerPointTooltipTemplate"
            mtxTooltipPosition="right"
            mtxTooltipClass="trigger-point__tooltip-info"
            >info</mat-icon
          >
        </span>
        <section class="trigger-point-menu trigger-point-menu--detail">
          <unleash-trigger-point
            [isEmptyMode]="true"
            [triggerPoint]="selectedZone.properties?.bbox_anchor || triggerPoint.center"
            (click)="$event.stopPropagation(); $event.preventDefault()"
            class="trigger-point"
            (setTriggerPoint)="setTemporalTriggerPoint($event)"
            (hoverBlock)="setHoverBlockName($event)"
            #triggerPointComponent
          ></unleash-trigger-point>
          <div class="trigger-point-menu__info trigger-point-menu__info--only-subtitle">
            <span class="trigger-point-menu__info--only-subtitle-text">{{
              hoverBlockName$ | async | uTriggerPointDisplayName
            }}</span>
          </div>
        </section>

        <section class="movement-direction" *ngIf="selectedZone.shape_type === shapeTypes.line_in_out">
          <span class="detail__info__subtitle"> Movement direction </span>
          <form [formGroup]="inOutForm" class="movement-direction__form">
            <mat-checkbox
              class="movement-direction__checkbox"
              formControlName="isInEnabled"
              [disabled]="!inOutForm.value.isOutEnabled && inOutForm.value.isInEnabled"
              (change)="toggleIn()"
              >In</mat-checkbox
            >
            <mat-checkbox
              class="movement-direction__checkbox"
              formControlName="isOutEnabled"
              [disabled]="!inOutForm.value.isInEnabled && inOutForm.value.isOutEnabled"
              (change)="toggleOut()"
              >Out</mat-checkbox
            >
          </form>
          <div>
            <button mat-button color="primary" class="movement-direction__sync" (click)="swapDirections()">
              <mat-icon>sync</mat-icon> Swap directions
            </button>
          </div>
        </section>
      </ng-container>
    </section>
  </div>
  <div class="detail__bottom-actions">
    <button class="actions__button" mat-button color="primary" (click)="cancel()">
      {{ 'common.cancel' | translate }}
    </button>
    <button
      [matTooltip]="saveTooltipText$ | async"
      mat-raised-button
      color="primary"
      class="actions__button close-draw__button"
      (click)="closeDraw($event)"
    >
      {{
        (isNewDraw$ | async) ? ('common.add' | translate) : ('live.manage-zones-dialog.details.action_save' | translate)
      }}
    </button>
  </div>
</ng-template>

<mat-menu #colorMenu="matMenu">
  <ng-template matMenuContent let-zone="zone">
    <unleash-color-picker
      [selectedColor]="zone.color.name"
      (updateColorTag)="updateCurrentColorByZone($event, zone); save()"
    ></unleash-color-picker>
  </ng-template>
</mat-menu>

<mat-menu
  #triggerPointMenu="matMenu"
  class="trigger-point-menu__container"
  backdropClass="trigger-point-menu__backdrop"
>
  <ng-template matMenuContent let-zone="zone">
    <section class="trigger-point-menu" (click)="$event.stopPropagation(); $event.preventDefault()">
      <unleash-trigger-point
        #menuTriggerPoint
        [isEmptyMode]="true"
        [triggerPoint]="zone.properties?.bbox_anchor || triggerPoint.center"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        class="trigger-point"
        (setTriggerPoint)="storeTriggerPointFromZonesList($event, zone.id)"
        (hoverBlock)="setHoverBlockName($event)"
      ></unleash-trigger-point>
      <div class="trigger-point-menu__info">
        <span class="trigger-point-menu__info--title">{{
          'live.manage-zones-dialog.triggerPoint.triggerPoint' | translate
        }}</span>
        <span class="trigger-point-menu__info--subtitle">{{ hoverBlockName$ | async | uTriggerPointDisplayName }}</span>
      </div>
    </section>
  </ng-template>
</mat-menu>

<ng-template #triggerPointTooltip let-blockName="blockName">
  <div class="trigger-point__tooltip">
    <span class="trigger-point__title">{{ 'live.manage-zones-dialog.triggerPoint.triggerPoint' | translate }}</span>
    <span class="trigger-point__subtitle">{{ temporalTriggerPointTooltip | uTriggerPointDisplayName }}</span>
  </div>
</ng-template>

<ng-template #infoTriggerPointTooltipTemplate>
  <div class="trigger-point__tooltip-container">
    <div>Trigger point is the part of a bounding box that</div>
    <div>counts as a detection when it is in the zone</div>
  </div>
</ng-template>

<ng-template #isLoadingTemplate>
  <span class="spinner-container--center">
    <unleash-spinner [diameter]="14"></unleash-spinner>
  </span>
</ng-template>

<ng-template #noZonesTemplate>
  <section class="no-zones">
    <div class="no-zones__image">
      <img class="no-zones__image-src" src="/assets/icons/manager-zones/no_zones.svg" />
    </div>

    <div class="no-zones__title">{{ 'live.manage-zones-dialog.noZones.title' | translate }}</div>
    <div class="no-zones__description">{{ 'live.manage-zones-dialog.noZones.description' | translate }}</div>

    <button
      mat-button
      class="detail__header__button detail__header__button--center"
      [matMenuTriggerFor]="menu"
      color="primary"
    >
      <mat-icon svgIcon="u_leading"></mat-icon>
      <span class="detail__header__text--spacing">{{ 'live.manage-zones-dialog.addZone' | translate }}</span>
      <mat-icon svgIcon="u_trailing"></mat-icon>
    </button>
  </section>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let type of supportedZonesTypes">
    <ng-container *ngIf="type === shapeTypes.perspective; else otherShapesTemplate">
      <span
        [matTooltip]="
          (hasPerspectiveZone$ | async)
            ? ('live.manage-zones-dialog.details.tooltipPerspectiveConstraint' | translate)
            : ''
        "
      >
        <button mat-menu-item [value]="type" [disabled]="hasPerspectiveZone$ | async" (click)="addNewZone(type)">
          {{ shapeTypeNames[type] | titlecase }}
        </button>
      </span>
    </ng-container>
    <ng-template #otherShapesTemplate>
      <button mat-menu-item [value]="type" (click)="addNewZone(type)">
        {{ shapeTypeNames[type] | titlecase }}
      </button>
    </ng-template>
  </ng-container>
</mat-menu>

import {HttpErrorResponse} from '@angular/common/http';
import {LibraryLayout} from '@app/library/models/library-layout.model';
import {UPLOAD_TYPES} from '@app/library/models/upload-types.model';
import {ApiPayloadV2} from '@app/shared/payload-transformer/payload-transformer';
import {Addon} from '@app/store/addon/models/addon';
import {createAction, props} from '@ngrx/store';
import {LibraryItemMutateModel} from '../models/delete-items.model';
import {LibraryItem, LibraryViewMode} from '../models/folder-file.model';
import {LibraryApiMoveResponseModel, NextToken} from '../models/library-api-list-response.model';
import {LibraryFilter, LibraryListQueryParams} from '../models/library-filter.model';
import {AnnotationsModel} from '../models/library-list-child-ids-response.model';
import {LibraryMimeType} from '../models/mime-type.model';
import {ModelData, ModelRequestOptions} from '../models/models.model';
import {Tag, UpdateTagItemsQuery} from '../models/tag.model';
import {LibraryState} from '@app/library/store/library.reducer';
import {MenuActions} from '@app/library/models/action-menu.model';

/* eslint-disable max-classes-per-file */

export enum LibraryActionTypes {
  LoadLibraryItems = '[Library] Load Library many Items from database',
  LoadLibraryItemsFromStoreCache = '[Library] Load Library Items From Store Cache',
  AppendLoadLibraryItems = '[Library] Load Library many Items from database for appending response to the items list',
  AppendLoadLibraryItemsSuccess = '[Library] Load Library many Items from database and push items to the listView',

  GetAvailableModels = '[Library] Get Available Models',
  GetAvailableModelsSuccess = '[Library] Get Available Models Success',
  GetAvailableModelsFail = '[Library] GetAvailable Models Fail',

  ClearAvailableModels = '[Library] Clear Available Models',

  OpenSelectedModel = '[Library] Open Selected Model',
  SaveSelectedModel = '[Library] Save Selected Model',

  GetModelData = '[Library] Get Model Data',
  GetModelDataSuccess = '[Library] Get Model Data Success',
  GetModelDataFail = '[Library] Get Model Data Fail',

  SaveLibraryItems = '[Library] Save Library Items to store what will replace display',
  LoadLibraryItemsSilently = '[Library] Load Library Items without display replace',
  SaveLibraryItemsSilently = '[Library] Save Library Items without display replace',

  AddLibraryItem = '[Library] Add Library Item to store what will update display',
  AddLibraryItems = '[Library] Add Library Items to store what will update display',
  AddLibraryItemError = '[Library] Add Library Item error',

  WatchUploadedFilesLibraryItems = '[Library] Watch Uploaded Files Library Items',
  WatchUploadedFilesLibraryItem = '[Library] Watch Uploaded Files Library Item',
  WatchDeleteLibraryItem = '[Library] Watch Delete Library Item',
  WatchBackStateLibraryItem = '[Library] Watch Back State Library Item',

  CreateEmptyFolder = '[Library] Create Empty Folder',

  DisplayLoader = '[Library] Display loader',
  HideLoader = '[Library] Hide loader',

  RequestFolderLocation = '[Library] Request Folder Location',
  LoadBreadcrumb = '[Library] Load breadcrumb',
  AddFolderPath = '[Library] Add folder path',
  SaveFolderPath = '[Library] Save folder path',
  DeleteUntilFolderPathIndex = '[Library] Delete until folder path index',
  DeleteLastFolderPath = '[Library] Delete last folder path',
  ClearFolderPath = '[Library] Clear folder path',

  OpenFolder = '[Library] Open folder',
  OpenFolderFromBreadcrumb = '[Library] Open folder from breadcrumb',
  OpenUploadDialog = '[Library] Open upload dialog',
  OpenUploadFilesFromGoogleDriveDialog = '[Library] Open upload files From Google drive dialog',
  OpenUploadFilesFromSkydio = '[Library] Open upload files from Skydio',
  OpenImportFromS3Dialog = '[Library] Open Import From S3 dialog',

  MarkAsSelectLibraryItem = '[Library] Mark As Select library items',
  MarkAsUnselectLibraryItem = '[Library] Mark As Unselect library items',
  UnselectAllLibraryItems = '[Library] Unselect all library items',
  UnselectAllLibraryItemsSuccess = '[Library] Unselect all library items success',
  SelectAllLibraryItemsFromCurrentView = '[Library] Select all library items from current view',
  UnselectAllLibraryItemsFromCurrentView = '[Library] Unselect all library items from current view',
  UnselectAllLibraryItemsFromCurrentViewSuccess = '[Library] Unselect all library items from current view success',

  OpenMoveLibraryItemsDialog = '[Library] Open move Library Items dialog',

  OpenDeleteLibraryItemsDialog = '[Library] Open delete Library Items dialog',
  DeleteLibraryItems = '[Library] Delete Library Items from database and start archive process',
  DeleteLibraryItemsSuccess = '[Library] Delete Library Items from store',

  OpenRenameLibraryItemDialog = '[Library] Open rename Library Item dialog',
  RenameLibraryItemStart = '[Library] Start rename Library Item',
  RenameLibraryItemSuccess = '[Library] Success rename Library Item',
  RenameLibraryItemError = '[Library] Error rename Library Item',

  ShowLibraryItemTags = '[Library] Show library item tags in sidebar',
  ClearLibraryItemTags = '[Library] Clear library item tags in sidebar',
  CreateSuggestedTags = '[Library] Create suggested tags',

  UpdateLibraryItemTag = '[Library] Update Library Item Tag',
  UpdateLibraryItemTagSuccess = '[Library] Update Library Items Tag Success',
  UpdateLibraryItemTagFail = '[Library] Update Library Item Tag Fail',

  MoveLibraryItemsSuccess = '[Library] Move library items Success',

  OpenModelPickerDialog = '[Library] Open Model Picker Dialog',
  OpenModelPickerDialogFromImage = '[Library] Open Model Picker Dialog From Image',

  OpenAnnotate = '[Library] Open annotate Library Item',
  NoMoreLibraryItems = '[Library] No More Library Items To Load',
  WatchLoadLibraryItemsOnScroll = '[Library] Watch Load Library Items on Scroll',
  UpdateAnnotationLibraryItem = '[Library] Update Annotation Library Item',

  SetFilterUrlParams = '[Library] Set Filter Url Params',
  UpdateFilterUrlParams = '[Library] Update Filter Url Params',

  OpenVideoModelDialog = '[Library] Open Video Model Dialog',
  VideoModel = '[Library] Video `Model',

  OpenImageModelDialog = '[Library] Open Image Model Dialog',
  ImageModel = '[Library] Image Model',
  Recognise = '[Library] Recognise',

  CreateNewTag = '[Library] Create New Tag',
  CreateNewTagSuccess = '[Library] Create New Tag Success',
  CreateNewTagFail = '[Library] Create New Tag Fail',

  RenameTag = '[Library] Rename Tag',
  RenameTagSuccess = '[Library] Rename Tag Success',
  RenameTagFail = '[Library] Rename Tag Fail',

  UpdateColorTag = '[Library] Update Color Tag',
  UpdateColorTagSuccess = '[Library] Update Color Tag Success',
  UpdateColorTagFail = '[Library] Update Color Tag Fail',

  DeleteTag = '[Library] Delete Tag',
  DeleteTagSuccess = '[Library] Delete Tag Success',
  DeleteTagFail = '[Library] Delete Tag Fail',
  TagLoading = '[Library] Tag Loading',
  DownloadStart = '[Library] Download',
  Share = '[Library] Share',

  RemoveLibraryItemFromStore = '[Library] Remove Library Item From Store',

  ClearStore = '[Library] Clear store',

  downloadLibraryItemMetadata = '[Library] Download image metadata',
  saveLibraryItemMetadata = '[Library] Save image metadata',
  updateIsLoadingMetadata = '[Library] Updating metadata status',

  OpenFlightLogDialog = '[Library] Open Flight Log Dialog',

  OpenExportToS3Dialog = '[Library] Open Export To S3 Dialog',

  OpenNoImagesAvailableDialog = '[Library] Open No Images Available Dialog',

  LoadOneImageFromFolder = '[Library] Load One Image From Folder',
  LoadOneImageFromFolderSuccess = '[Library] Load One Image From Folder Success',
  LoadOneImageFromFolderFail = '[Library] Load One Image From Folder Fail',

  LoadAnnotationsFromLibraryItem = '[Library] Load Annotations From Library Item',
  LoadAnnotationsFromLibraryItemSuccess = '[Library] Load Annotations From Library Item Success',
  LoadAnnotationsFromLibraryItemFail = '[Library] Load Annotations From Library Item Fail',
  LoadRequirementsForModelPickerDialog = '[Library] Load Requirements For Model Picker Dialog',

  LoadLibraryItemGPSMetadataSilently = '[Library] Load Library Item GPS Metadata Silently',
  LoadLibraryItemGPSMetadataSilentlySuccess = '[Library] Load Library Item GPS Metadata Silently Success',
  LoadLibraryItemGPSMetadataSilentlyFail = '[Library] Load Library Item GPS Metadata Silently Fail',
  LoadLibraryItemsGPSMetadataSilently = '[Library] Load Library Items GPS Metadata Silently',

  SaveCurrentParentId = '[Library] Save current parent id',
  ScrollUp = '[Library] Scroll up',

  checkIfHasAnalyzeOption = '[Library] Has library item analyze option',
  HasAnalyzeOptionSuccess = '[Library] Has library item analyze option success',
  HasAnalyzeOptionFail = '[Library] Has library item analyze option fail',

  ExportCocoJson = '[Library] Export COCO JSON',
  ExportCocoJsonSuccess = '[Library] Export COCO JSON Success',
  ExportCocoJsonFail = '[Library] Export COCO JSON Fail',

  ExportCsv = '[Library] Export CSV',
  ExportCsvSuccess = '[Library] Export CSV Success',
  ExportCsvFail = '[Library] Export CSV Fail',

  ExportModels = '[Library] Export Models',
  ExportModelsSuccess = '[Library] Export Models Success',
  ExportModelsFail = '[Library] Export Models Fail',

  RequestAiVideo = '[Library] Request Ai video from raw',
  RequestAiVideoSuccess = '[Library] Request Ai video from raw success',
  RequestAiVideoFail = '[Library] Request Ai video from raw fail',
  SwitchVideo = '[Library] Switch library item video',

  AddToAtlas = '[Library] Add to Atlas',
  AddToAtlasSuccess = '[Library] Add to Atlas Success',
  AddToAtlasFail = '[Library] Add to Atlas Fail',

  LoadLibraryTags = '[Library] Load Library Tags',
  LoadLibraryTagsSuccess = '[Library] Load Library Tags Success',
  LoadLibraryTagsFail = '[Library] Load Library Tags Fail',

  OpenAnalyzeDialogClosed = '[Library] Open Analyze Dialog Closed',

  LoadAnnotationsFromLibraryItemFromViewerSuccess = '[Library] Load Annotations From Library Item From Viewer Success',
  ClearListView = '[Library] Clear List View',
  ListenPreviousUrl = '[Library] Listen Previous url',
  NavigateToPreviousUrl = '[Library] Navigate to previous url',

  SaveFilterParams = '[Library] Save Filter Params',
  SaveFilterParamsSuccess = '[Library] Save Filter Params Success',
  ClearLibraryFilters = '[Library] Clear Library Filters',
  ClearOtherLibraryFilters = '[Library] Clear Other Library Filters',
  CompareImages = '[Library] Compare Images',

  FileCount = '[Library] Count Files',
  FileCountSuccess = '[Library] Count Files Success',

  SetLibraryItemFromContextMenu = '[Library] Set Library Item From ContextMenu',
  ClearLibraryIteamContextMenu = '[Library] Clear Library Iteam From ContextMenu',
  ClearLibraryIteamContextMenuSuccess = '[Library] Clear Library Iteam From ContextMenu Success',

  SetListViewState = '[Library] SetListViewState',
  ClearCurrentPositionStore = '[Library] Clear current position store',

  LoadSpecialLibraryItemsSuccess = '[Library] Load special library items Success',

  ChangeViewMode = '[Library] Change view mode'
}

export const loadLibraryItems = createAction(
  LibraryActionTypes.LoadLibraryItems,
  props<{payload: LibraryListQueryParams}>()
);

export const loadLibraryItemsFromStoreCache = createAction(LibraryActionTypes.LoadLibraryItemsFromStoreCache);

export const appendLoadLibraryItems = createAction(LibraryActionTypes.AppendLoadLibraryItems);

export const appendLoadLibraryItemsSuccess = createAction(
  LibraryActionTypes.AppendLoadLibraryItemsSuccess,
  props<{
    payload: {
      libraryItems: {[key: string]: LibraryItem};
      nextToken: NextToken;
    };
  }>()
);

export const saveLibraryItems = createAction(
  LibraryActionTypes.SaveLibraryItems,
  props<{
    payload: {libraryItems: {[key: string]: LibraryItem}; nextToken: NextToken; specialLibraryItems?: LibraryItem[]};
  }>()
);

export const saveLibraryItemsSilently = createAction(
  LibraryActionTypes.SaveLibraryItemsSilently,
  props<{payload: {libraryItems: {[key: string]: LibraryItem}}}>()
);

export const addLibraryItem = createAction(
  LibraryActionTypes.AddLibraryItem,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const addLibraryItems = createAction(
  LibraryActionTypes.AddLibraryItems,
  props<{payload: {libraryItems: {[key: string]: LibraryItem}}}>()
);

export const addLibraryItemError = createAction(LibraryActionTypes.AddLibraryItemError);

export const watchUploadedFilesLibraryItem = createAction(
  LibraryActionTypes.WatchUploadedFilesLibraryItem,
  props<{payload: {userId: string}}>()
);

export const watchUploadedFilesLibraryItems = createAction(
  LibraryActionTypes.WatchUploadedFilesLibraryItems,
  props<{payload: {userId: string}}>()
);

export const watchDeleteLibraryItem = createAction(LibraryActionTypes.WatchDeleteLibraryItem);

export const watchBackStateLibraryItem = createAction(LibraryActionTypes.WatchBackStateLibraryItem);

export const createEmptyFolder = createAction(
  LibraryActionTypes.CreateEmptyFolder,
  props<{
    payload: {
      folderName: string;
      parentId: string;
      location?: string;
    };
  }>()
);

export const displayLoader = createAction(LibraryActionTypes.DisplayLoader);

export const hideLoader = createAction(LibraryActionTypes.HideLoader);

export const saveFolderPath = createAction(LibraryActionTypes.SaveFolderPath, props<{payload: {path: string[]}}>());

export const deleteLastFolderPath = createAction(LibraryActionTypes.DeleteLastFolderPath);

export const addFolderPath = createAction(LibraryActionTypes.AddFolderPath, props<{payload: {pathId: string}}>());

export const deleteUntilFolderPathIndex = createAction(
  LibraryActionTypes.DeleteUntilFolderPathIndex,
  props<{payload: {pathIndex: number}}>()
);

export const clearFolderPath = createAction(LibraryActionTypes.ClearFolderPath);

export const openFolder = createAction(
  LibraryActionTypes.OpenFolder,
  props<{payload: {libraryItemId: string; openInNewTab: boolean}}>()
);
export const openFolderFromBreadcrumb = createAction(
  LibraryActionTypes.OpenFolderFromBreadcrumb,
  props<{payload: {libraryItemId: string}}>()
);

export const openUploadDialog = createAction(
  LibraryActionTypes.OpenUploadDialog,
  props<{payload: {files: File[]; parentId: string; uploadType: UPLOAD_TYPES; totalItemsDropped: number}}>()
);

export const openUploadFilesFromGoogleDriveDialog = createAction(
  LibraryActionTypes.OpenUploadFilesFromGoogleDriveDialog
);

export const openUploadFilesFromSkydioDialog = createAction(LibraryActionTypes.OpenUploadFilesFromSkydio);

export const openImportFromS3Dialog = createAction(LibraryActionTypes.OpenImportFromS3Dialog);
export const openExportToS3Dialog = createAction(LibraryActionTypes.OpenExportToS3Dialog);

export const markAsSelectLibraryItem = createAction(
  LibraryActionTypes.MarkAsSelectLibraryItem,
  props<{payload: {libraryItemId: string}}>()
);

export const markAsUnselectLibraryItem = createAction(
  LibraryActionTypes.MarkAsUnselectLibraryItem,
  props<{payload: {libraryItemId: string}}>()
);

export const selectAllLibraryItemsFromCurrentView = createAction(
  LibraryActionTypes.SelectAllLibraryItemsFromCurrentView
);

export const unselectAllLibraryItemsFromCurrentView = createAction(
  LibraryActionTypes.UnselectAllLibraryItemsFromCurrentView
);

export const unselectAllLibraryItemsFromCurrentViewSuccess = createAction(
  LibraryActionTypes.UnselectAllLibraryItemsFromCurrentViewSuccess,
  props<{payload: {selectedItems: LibraryItem[]}}>()
);

export const unselectAllLibraryItems = createAction(LibraryActionTypes.UnselectAllLibraryItems);

export const unselectAllLibraryItemsSuccess = createAction(
  LibraryActionTypes.UnselectAllLibraryItemsSuccess,
  props<{payload: {selectedItems: LibraryItem[]}}>()
);

export const openMoveLibraryItemsDialog = createAction(LibraryActionTypes.OpenMoveLibraryItemsDialog);

export const openDeleteLibraryItemsDialog = createAction(LibraryActionTypes.OpenDeleteLibraryItemsDialog);

export const renameLibraryItemStart = createAction(
  LibraryActionTypes.RenameLibraryItemStart,
  props<{payload: {newName: string; libraryItem: LibraryItem}}>()
);

export const renameLibraryItemSuccess = createAction(
  LibraryActionTypes.RenameLibraryItemSuccess,
  props<{payload: {updatedItemId: string; newName: string}}>()
);

export const deleteLibraryItems = createAction(
  LibraryActionTypes.DeleteLibraryItems,
  props<{payload: {deleteItems: LibraryItemMutateModel[]}}>()
);

export const deleteLibraryItemsSuccess = createAction(
  LibraryActionTypes.DeleteLibraryItemsSuccess,
  props<{payload: {deleteItemsIds: {deleteItems: LibraryItemMutateModel[]}}}>()
);

export const moveLibraryItemSuccess = createAction(
  LibraryActionTypes.MoveLibraryItemsSuccess,
  props<{payload: LibraryApiMoveResponseModel}>()
);

export const loadBreadcrumbs = createAction(LibraryActionTypes.LoadBreadcrumb, props<{payload: {location: string}}>());

export const loadLibraryItemsSilently = createAction(
  LibraryActionTypes.LoadLibraryItemsSilently,
  props<{
    payload: {
      libraryItemIds: LibraryItemMutateModel[];
      withAnnotations: boolean;
    };
  }>()
);

export const requestFolderLocation = createAction(
  LibraryActionTypes.RequestFolderLocation,
  props<{payload: {folderId: string}}>()
);

export const openAnnotate = createAction(
  LibraryActionTypes.OpenAnnotate,
  props<{payload: {libraryItem: LibraryItem; addonId: string}}>()
);

export const openRenameLibraryItemDialog = createAction(
  LibraryActionTypes.OpenRenameLibraryItemDialog,
  props<{payload: {libraryItemId: string}}>()
);

export const openVideoModelDialog = createAction(
  LibraryActionTypes.OpenVideoModelDialog,
  props<{payload: {libraryItemId: string}}>()
);

export const videoModel = createAction(LibraryActionTypes.VideoModel, props<{payload: {libraryItemIds: string[]}}>());

export const openImageModelDialog = createAction(LibraryActionTypes.OpenImageModelDialog);

export const imageModel = createAction(
  LibraryActionTypes.ImageModel,
  props<{payload: {libraryItemIds: string[]; options: ModelRequestOptions}}>()
);

export const noMoreLibraryItemsToLoad = createAction(LibraryActionTypes.NoMoreLibraryItems);

export const watchLoadLibraryItemsOnScroll = createAction(LibraryActionTypes.WatchLoadLibraryItemsOnScroll);

export const setFilterUrlParams = createAction(
  LibraryActionTypes.SetFilterUrlParams,
  props<{payload: {filterParams: LibraryFilter}}>()
);

export const updateFilterUrlParams = createAction(
  LibraryActionTypes.UpdateFilterUrlParams,
  props<{payload: {filterParams: Partial<LibraryFilter>}}>()
);

export const updateAnnotationLibraryItem = createAction(
  LibraryActionTypes.UpdateAnnotationLibraryItem,
  props<{payload: {libraryItemId: string; annotations: AnnotationsModel}}>()
);

export const recognise = createAction(LibraryActionTypes.Recognise, props<{payload: ApiPayloadV2}>());

export const openModelPickerDialog = createAction(
  LibraryActionTypes.OpenModelPickerDialog,
  props<{payload: {libraryItem: LibraryItem; addonsWithConfig: Addon[]}}>()
);

export const renameTag = createAction(LibraryActionTypes.RenameTag, props<{payload: {tag: Tag}}>());

export const renameTagSuccess = createAction(LibraryActionTypes.RenameTagSuccess, props<{payload: {tag: Tag}}>());

export const renameTagFail = createAction(
  LibraryActionTypes.RenameTagFail,
  props<{payload: {error: HttpErrorResponse}}>()
);

export const updateColorTag = createAction(LibraryActionTypes.UpdateColorTag, props<{payload: {tag: Tag}}>());

export const updateColorTagSuccess = createAction(
  LibraryActionTypes.UpdateColorTagSuccess,
  props<{payload: {tag: Tag}}>()
);

export const updateColorTagFail = createAction(
  LibraryActionTypes.UpdateColorTagFail,
  props<{payload: {error: HttpErrorResponse}}>()
);

export const deleteTag = createAction(LibraryActionTypes.DeleteTag, props<{payload: {tag: Tag}}>());

export const deleteTagSuccess = createAction(LibraryActionTypes.DeleteTagSuccess, props<{payload: {tag: Tag}}>());

export const deleteTagFail = createAction(
  LibraryActionTypes.DeleteTagFail,
  props<{payload: {error: HttpErrorResponse}}>()
);

export const tagLoading = createAction(LibraryActionTypes.TagLoading);

export const downloadStart = createAction(LibraryActionTypes.DownloadStart);

export const share = createAction(LibraryActionTypes.Share, props<{payload: {libraryItem: LibraryItem}}>());

export const removeLibraryItemFromStore = createAction(
  LibraryActionTypes.RemoveLibraryItemFromStore,
  props<{payload: {libraryItemsIds: string[]}}>()
);

export const clearLibraryStore = createAction(LibraryActionTypes.ClearStore);

export const downloadLibraryItemMetadata = createAction(
  LibraryActionTypes.downloadLibraryItemMetadata,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const saveLibraryItemMetadata = createAction(
  LibraryActionTypes.saveLibraryItemMetadata,
  props<{
    payload: {
      libraryItemId: LibraryItem['id'];
      metadata: LibraryItem['metadata'];
    };
  }>()
);

export const updateIsLoadingMetadata = createAction(LibraryActionTypes.updateIsLoadingMetadata);

export const getAvailableModels = createAction(
  LibraryActionTypes.GetAvailableModels,
  props<{libraryItemId: string; location?: string}>()
);

export const getAvailableModelsSuccess = createAction(
  LibraryActionTypes.GetAvailableModelsSuccess,
  props<{availableModels: LibraryItem[]}>()
);

export const getAvailableModelsFail = createAction(
  LibraryActionTypes.GetAvailableModelsFail,
  props<{error: HttpErrorResponse}>()
);

export const clearAvailableModels = createAction(LibraryActionTypes.ClearAvailableModels);

export const openSelectedModel = createAction(
  LibraryActionTypes.OpenSelectedModel,
  props<{modelMimeType: LibraryMimeType; libraryItemId: string}>()
);

export const saveSelectedModel = createAction(
  LibraryActionTypes.SaveSelectedModel,
  props<{selectedModel: LibraryItem}>()
);

export const getModelData = createAction(LibraryActionTypes.GetModelData, props<{libraryItemId: LibraryItem['id']}>());

export const getModelDataSuccess = createAction(
  LibraryActionTypes.GetModelDataSuccess,
  props<{modelData: ModelData}>()
);

export const getModelDataFail = createAction(LibraryActionTypes.GetModelDataFail, props<{error: HttpErrorResponse}>());

export const openFlightLogDialog = createAction(
  LibraryActionTypes.OpenFlightLogDialog,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const openNoImagesAvailableDialog = createAction(
  LibraryActionTypes.OpenNoImagesAvailableDialog,
  props<{payload: {libraryItemId: string}}>()
);

export const loadRequirementsForModelPickerDialog = createAction(
  LibraryActionTypes.LoadRequirementsForModelPickerDialog,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const loadOneImageFromFolder = createAction(
  LibraryActionTypes.LoadOneImageFromFolder,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const loadOneImageFromFolderSuccess = createAction(
  LibraryActionTypes.LoadOneImageFromFolderSuccess,
  props<{payload: {libraryItemId: LibraryItem['id']}}>()
);

export const loadOneImageFromFolderFail = createAction(
  LibraryActionTypes.LoadOneImageFromFolderFail,
  props<{payload: {error: string}}>()
);

export const openModelPickerDialogFromImage = createAction(
  LibraryActionTypes.OpenModelPickerDialogFromImage,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const loadAnnotationsFromLibraryItem = createAction(
  LibraryActionTypes.LoadAnnotationsFromLibraryItem,
  props<{payload: {libraryItemId: string}}>()
);

export const loadAnnotationsFromLibraryItemSuccess = createAction(
  LibraryActionTypes.LoadAnnotationsFromLibraryItemSuccess,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const loadAnnotationsFromLibraryItemFail = createAction(
  LibraryActionTypes.LoadAnnotationsFromLibraryItemFail,
  props<{payload: {error: string}}>()
);

export const loadLibraryItemGPSMetadataSilently = createAction(
  LibraryActionTypes.LoadLibraryItemGPSMetadataSilently,
  props<{payload: {libraryItemId: string}}>()
);

export const loadLibraryItemsGPSMetadataSilently = createAction(
  LibraryActionTypes.LoadLibraryItemsGPSMetadataSilently,
  props<{payload: {libraryItemIds: string[]}}>()
);

export const loadLibraryItemGPSMetadataSilentlySuccess = createAction(
  LibraryActionTypes.LoadLibraryItemGPSMetadataSilentlySuccess,
  props<{
    payload: {
      libraryItemId: string;
      metadata: {gpslat: number; gpslng: number};
    };
  }>()
);

export const loadLibraryItemGPSMetadataSilentlyFail = createAction(
  LibraryActionTypes.LoadLibraryItemGPSMetadataSilentlyFail,
  props<{payload: {error: string}}>()
);

export const saveCurrentParentId = createAction(
  LibraryActionTypes.SaveCurrentParentId,
  props<{payload: {currentParentId: string}}>()
);

export const scrollUp = createAction(LibraryActionTypes.ScrollUp);

export const checkIfHasAnalyzeOption = createAction(LibraryActionTypes.checkIfHasAnalyzeOption);

export const hasAnalyzeOptionSuccess = createAction(
  LibraryActionTypes.HasAnalyzeOptionSuccess,
  props<{
    payload: {
      libraryItems: LibraryItem[];
    };
  }>()
);

export const hasAnalyzeOptionFail = createAction(LibraryActionTypes.HasAnalyzeOptionFail, props<{error: string}>());
export const exportCocoJson = createAction(LibraryActionTypes.ExportCocoJson, props<{libraryItem?: LibraryItem}>());

export const exportCocoJsonSuccess = createAction(
  LibraryActionTypes.ExportCocoJsonSuccess,
  props<{response: string}>()
);

export const exportCocoJsonFail = createAction(
  LibraryActionTypes.ExportCocoJsonFail,
  props<{error: HttpErrorResponse}>()
);

export const exportCsv = createAction(LibraryActionTypes.ExportCsv, props<{libraryItem?: LibraryItem}>());

export const exportCsvSuccess = createAction(LibraryActionTypes.ExportCsvSuccess, props<{response: string}>());

export const exportCsvFail = createAction(LibraryActionTypes.ExportCsvFail, props<{error: HttpErrorResponse}>());

export const requestAiVideo = createAction(
  LibraryActionTypes.RequestAiVideo,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const requestAiVideoSuccess = createAction(
  LibraryActionTypes.RequestAiVideoSuccess,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{payload: {libraryItems: Partial<LibraryItem>[]}}>()
);

export const requestAiVideoFail = createAction(LibraryActionTypes.RequestAiVideoFail, props<{error: string}>());

export const switchVideo = createAction(
  LibraryActionTypes.SwitchVideo,
  props<{
    payload: {
      libraryItemId: LibraryItem['id'];
      aiVideo: LibraryItem;
    };
  }>()
);

export const addToAtlas = createAction(LibraryActionTypes.AddToAtlas);

export const loadLibraryTags = createAction(LibraryActionTypes.LoadLibraryTags);

export const loadLibraryTagsSuccess = createAction(LibraryActionTypes.LoadLibraryTagsSuccess, props<{tags: Tag[]}>());

export const loadLibraryTagsFail = createAction(
  LibraryActionTypes.LoadLibraryTagsFail,
  props<{error: HttpErrorResponse}>()
);

export const createNewTag = createAction(
  LibraryActionTypes.CreateNewTag,
  props<{tagName: Tag['name']; libraryItem?: LibraryItem; libraryLayout?: LibraryLayout}>()
);

export const createNewTagSuccess = createAction(LibraryActionTypes.CreateNewTagSuccess, props<{tag: Tag}>());

export const createNewTagFail = createAction(LibraryActionTypes.CreateNewTagFail, props<{error: HttpErrorResponse}>());

export const updateLibraryItemTag = createAction(
  LibraryActionTypes.UpdateLibraryItemTag,
  props<{
    tag: Tag;
    libraryItem: LibraryItem;
    libraryLayout: LibraryLayout;
  }>()
);

export const updateLibraryItemsTagSuccess = createAction(
  LibraryActionTypes.UpdateLibraryItemTagSuccess,
  props<{items: UpdateTagItemsQuery[]}>()
);

export const updateLibraryItemTagFail = createAction(
  LibraryActionTypes.UpdateLibraryItemTagFail,
  props<{error: HttpErrorResponse}>()
);

export const showLibraryItemTags = createAction(
  LibraryActionTypes.ShowLibraryItemTags,
  props<{payload: {libraryItemId: string}}>()
);

export const clearLibraryItemTags = createAction(LibraryActionTypes.ClearLibraryItemTags);

export const openAnalyzeDialogClosed = createAction(LibraryActionTypes.OpenAnalyzeDialogClosed);

export const loadAnnotationsFromLibraryItemFromViewerSuccess = createAction(
  LibraryActionTypes.LoadAnnotationsFromLibraryItemFromViewerSuccess,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const clearListView = createAction(LibraryActionTypes.ClearListView);

export const listenPreviousUrl = createAction(LibraryActionTypes.ListenPreviousUrl);

export const navigateToPreviousUrl = createAction(
  LibraryActionTypes.NavigateToPreviousUrl,
  props<{payload: {redirectedFrom: string; isPositiveAction: boolean}}>()
);

export const saveFilterParams = createAction(
  LibraryActionTypes.SaveFilterParams,
  props<{payload: {libraryFilters: Partial<LibraryFilter>; libraryItemId: LibraryItem['id']}}>()
);

export const saveFilterParamsSuccess = createAction(
  LibraryActionTypes.SaveFilterParamsSuccess,
  props<{payload: {libraryFilters: Partial<LibraryFilter>; libraryItemId: LibraryItem['id']}}>()
);

export const clearLibraryFilters = createAction(LibraryActionTypes.ClearLibraryFilters);
export const clearOtherLibraryFilters = createAction(LibraryActionTypes.ClearOtherLibraryFilters);
export const compareImages = createAction(LibraryActionTypes.CompareImages);

export const fileCount = createAction(
  LibraryActionTypes.FileCount,
  props<{payload: {libraryItems: {id: LibraryItem['id']}[]}}>()
);

export const exportModels = createAction(
  LibraryActionTypes.ExportModels,
  props<{payload: {id: LibraryItem['id']; type: MenuActions}}>()
);

export const fileCountSuccess = createAction(LibraryActionTypes.FileCountSuccess);

export const setLibraryItemFromContextMenu = createAction(
  LibraryActionTypes.SetLibraryItemFromContextMenu,
  props<{payload: {libraryItemId: string}}>()
);

export const clearLibraryIteamContextMenu = createAction(LibraryActionTypes.ClearLibraryIteamContextMenu);
export const clearLibraryIteamContextMenuSuccess = createAction(LibraryActionTypes.ClearLibraryIteamContextMenuSuccess);

export const setListViewState = createAction(
  LibraryActionTypes.SetListViewState,
  props<{
    payload: Partial<LibraryState>;
  }>()
);

export const loadSpecialLibraryItemsSuccess = createAction(
  LibraryActionTypes.LoadSpecialLibraryItemsSuccess,
  props<{
    payload: {[key: string]: {[key: string]: LibraryItem}};
  }>()
);

export const clearCurrentPositionStore = createAction(LibraryActionTypes.ClearCurrentPositionStore);

export const changeViewMode = createAction(
  LibraryActionTypes.ChangeViewMode,
  props<{payload: {viewMode: LibraryViewMode}}>()
);

import {createAction, props} from '@ngrx/store';
import {Reason} from './reason.model';

export const reasonFormSubmit = createAction(
  '[ReasonForm] Submit Form',
  props<{form: Reason}>()
);

export const reasonFormSubmitSuccess = createAction(
  '[ReasonForm] Submit Form Success'
);

export const saveReasonToLocalStorage = createAction(
  '[ReasonForm] Save Reason to Local Storage',
  props<{reason: Reason}>()
);

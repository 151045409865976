import {AfterViewInit, Directive, ElementRef, HostListener} from '@angular/core';
import {Mission, MissionSettingsService} from '../services/mission-settings.service';
import {take} from 'rxjs';

@Directive({
  selector: '[ulRedoMissionEdit]'
})
export class RedoMissionEditDirective implements AfterViewInit {
  constructor(private missionSettingsService: MissionSettingsService, private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  public onRedoChanges() {
    this.missionSettingsService.editingMission.pipe(take(1)).subscribe(editingMission => {
      this.missionSettingsService.redo(editingMission.mission);
    });
  }

  public ngAfterViewInit(): void {
    this.missionSettingsService.redoStack.subscribe(stack => {
      this.setDisabledButton(stack);
    });
  }

  private setDisabledButton(stack: Mission[]): void {
    if (stack.length === 0) {
      this.elementRef.nativeElement.classList.add('mat-button-disabled');
      this.elementRef.nativeElement.setAttribute('disabled', 'true');
    } else {
      this.elementRef.nativeElement.classList.remove('mat-button-disabled');
      this.elementRef.nativeElement.removeAttribute('disabled');
    }
  }
}

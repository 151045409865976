import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchInputComponent} from './search-input.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {I18nSharedModule} from '../i18n/i18n-shared.module';
import {RoleNamePipe} from '@app/profile/pipes/role-name.pipe';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatIconModule, I18nSharedModule],
  exports: [SearchInputComponent]
})
export class SearchInputModule {}

/* eslint-disable no-magic-numbers */
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {loadStripe} from '@stripe/stripe-js/pure';
import {environment} from 'environments/environment';
import {BehaviorSubject, from, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PlanModel} from '../../../core/models/api/plan.model';
import {PaymentService, StripeCustomerAddress} from '../../../plans/services/payment.service';
import {PaymentPeriod} from '../payment.model';
import {PromoCodeComponent} from '../promo-code/promo-code.component';

let stripe: any;
loadStripe.setLoadParameters({advancedFraudSignals: false});

@Component({
  selector: 'unleash-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCardComponent implements OnInit {
  @ViewChild('cardName', {static: false, read: MatInput}) public cardName: MatInput;
  @ViewChild('promoCode') public promoCodeComponent: PromoCodeComponent;

  @Input() public isPaymentInProgress: boolean;
  @Input() public plan: {
    name: string;
    description: string;
    price: number;
    features: string[];
    more: string;
    priceAnnual: number;
    model: PlanModel;
  };
  @Input() public isOnlyPayments: boolean;
  @Output() public paymentPeriodChange: EventEmitter<PaymentPeriod> = new EventEmitter();
  @Output() public stripeToken: EventEmitter<any> = new EventEmitter();

  public cardNumber: any;
  public cardExpiry: any;
  public cardCvc: any;
  // eslint-disable-next-line rxjs/finnish
  public trialEndDate$: Subject<string> = new Subject();
  public promoCode: string = '';

  public nameForm = this.fb.group({
    name: ['', Validators.required]
  });

  public billingCycleForm = this.fb.group({
    paymentPeriod: [PaymentPeriod.month, Validators.required]
  });

  public form = this.fb.group({
    street: ['', Validators.required],
    city: ['', Validators.required],
    country: ['', Validators.required],
    postcode: ['', Validators.required]
  });
  // eslint-disable-next-line rxjs/finnish
  public cardError$ = new Subject<string>();

  public paymentPeriod = PaymentPeriod;
  // eslint-disable-next-line rxjs/finnish
  public isSuccessPromoCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor(private fb: FormBuilder, private paymentService: PaymentService) {}

  public ngOnInit(): void {
    loadStripe(environment.STRIPE_PUBLISHABLE_KEY).then(stripeLoaded => {
      stripe = stripeLoaded;
      const elements = stripe.elements();

      this.billingCycleForm.get('paymentPeriod').valueChanges.subscribe(newValue => {
        this.paymentPeriodChange.emit(newValue);
      });

      const inputs = document.querySelectorAll('.input');
      Array.prototype.forEach.call(inputs, input => {
        input.addEventListener('focus', () => {
          input.classList.add('focused');
        });
        input.addEventListener('blur', () => {
          input.classList.remove('focused');
        });
        input.addEventListener('keyup', () => {
          if (input.value?.length === 0) {
            input.classList.add('empty');
          } else {
            input.classList.remove('empty');
          }
        });
      });

      const elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid'
      };

      this.cardNumber = elements.create('cardNumber', {
        classes: elementClasses
      });

      this.cardExpiry = elements.create('cardExpiry', {
        classes: elementClasses,
        placeholder: 'MM/YY'
      });

      this.cardCvc = elements.create('cardCvc', {
        classes: elementClasses,
        placeholder: 'CVV'
      });

      const date = new Date();
      const months = [];
      months[0] = 'January';
      months[1] = 'February';
      months[2] = 'March';
      months[3] = 'April';
      months[4] = 'May';
      months[5] = 'June';
      months[6] = 'July';
      months[7] = 'August';
      months[8] = 'September';
      months[9] = 'October';
      months[10] = 'November';
      months[11] = 'December';

      date.setDate(date.getDate() + 14);
      this.trialEndDate$.next(date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear().toString());
      this.cardNumber.mount('#card-number');
      this.cardExpiry.mount('#card-expiry');
      this.cardCvc.mount('#card-cvc');
    });
  }

  public startTrial(): void {
    from(stripe.createToken(this.cardNumber))
      .pipe(
        tap(() => {
          if (!this.billingCycleForm.valid) {
            Object.keys(this.billingCycleForm.controls).forEach(key =>
              this.billingCycleForm.controls[key].markAsTouched()
            );
          }

          if (!this.nameForm.valid) {
            Object.keys(this.nameForm.controls).forEach(key => this.nameForm.controls[key].markAsTouched());
          }

          if (!this.form.valid) {
            Object.keys(this.form.controls).forEach(key => this.form.controls[key].markAsTouched());
          }
        })
      )
      .subscribe(
        (result: any) => {
          if (result.error) {
            this.cardError$.next(result.error.message);
          } else {
            this.cardError$.next(null);
            if (!this.form.valid || !this.nameForm.valid || !this.billingCycleForm.valid) {
              return;
            }
            const address: StripeCustomerAddress = {
              city: this.form.value.city,
              // eslint-disable-next-line camelcase
              postal_code: this.form.value.postcode,
              line2: this.form.value.street
            };
            result.token.address = address;
            result.token.name = this.nameForm.value.name;
            result.token.period = this.billingCycleForm.value.paymentPeriod;
            if (!!this.promoCode && this.isSuccessPromoCode$.value) {
              result.token.promoCode = this.promoCode;
            }
            this.stripeToken.emit(result.token);
          }
        },
        error => ({})
      );
  }

  public generateCardToken() {
    from(stripe.createToken(this.cardNumber))
      .pipe(
        tap(() => {
          if (!this.nameForm.valid) {
            Object.keys(this.nameForm.controls).forEach(key => this.nameForm.controls[key].markAsTouched());
          }
        })
      )
      .subscribe((result: any) => {
        if (result.error) {
          console.info('Error creating payment method.');
          this.cardError$.next(result.error.message);
          return;
        }
        this.cardError$.next(null);

        if (!this.nameForm.valid) {
          return;
        }

        result.token.name = this.nameForm.value.name;
        this.stripeToken.emit(result.token);
      });
  }

  public setPaymentPeriod(paymentPeriod: string) {
    this.billingCycleForm.setValue({paymentPeriod} as any);
  }

  public formatDollars(dollarAmount: number) {
    const dollarString = dollarAmount.toLocaleString('en');
    return dollarString;
  }

  public setCardNameFocus() {
    this.cardName.focus();
  }

  public skipPaymentDetails(): void {
    const emptyStripeToken = {id: '', address: '', promoCode: '', hasToSkipPayment: true};
    this.stripeToken.emit(emptyStripeToken);
  }

  public async setupPromoCode(promoCode: string) {
    const validation: {valid: boolean} = await this.paymentService.checkPromoCode(promoCode).toPromise();
    this.isSuccessPromoCode$.next(validation.valid);
    if (validation.valid) {
      this.promoCode = promoCode;
    }
  }
}

import {createAction, props} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {ForgotPasswordStepTwo} from './forgot-password-step-two.model';

export const actionForgotPasswordStepTwoFormSubmit = createAction(
  '[ForgotPasswordStepTwoForm] Submit',
  props<{form: ForgotPasswordStepTwo}>()
);

export const actionForgotPasswordStepTwoFormError = createAction(
  '[ForgotPasswordStepTwoForm] Submit error',
  props<{error: AuthErrorModel}>()
);

export const actionForgotPasswordStepTwoFormSuccess = createAction('[ForgotPasswordStepTwoForm] Submit success');

import {createAction, props} from '@ngrx/store';
import {Roles, UserTeam} from '@app/profile/models/users-table.model';
import {TeamRole} from '@app/profile/models/team.model';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';
import {UserInvitation} from '@app/profile/models/user-roles.model';

export enum UserManagementActionTypes {
  SetUsers = '[Users Management] Set users',
  SetRoles = '[Users Management] Set roles',

  UpdateUserTeamRole = '[User Management] Update user team role',
  UpdateUserTeamRoleSuccess = '[User Management] Update user team role Success',

  SetSelectedUserId = '[Users Management] Set selected user id',
  RemoveSelectedUserId = '[Users Management] Remove selected user id',

  OpenInviteUsersDialog = '[Users Management] Open invite users dialog',
  InviteUsers = '[Users Management] Invite users',

  // To test
  DeleteUser = '[Users Management] Delete user',
  DeleteUserSuccess = '[Users Management] Delete user success',
  AddUsersToTeamSuccess = '[Users Management] Add users to team success',

  RemoveUsersFromTeam = '[Users Management] Remove users from team',
  RemoveUsersFromTeamSuccess = '[Users Management] Remove users from team success',
  InviteUsersSuccess = 'InviteUsersSuccess',

  DisplayLoading = '[Users Management] Display loading',
  HideLoading = '[Users Management] Hide loading'
}

export const setUsers = createAction(UserManagementActionTypes.SetUsers, props<{payload: {users: UserTeam[]}}>());
export const setRoles = createAction(UserManagementActionTypes.SetRoles, props<{payload: {roles: Roles}}>());

export const updateUserTeamRole = createAction(
  UserManagementActionTypes.UpdateUserTeamRole,
  props<{payload: {userId: string; role: TeamRole}}>()
);
export const updateUserTeamRoleSuccess = createAction(
  UserManagementActionTypes.UpdateUserTeamRoleSuccess,
  props<{payload: {userId: string; role: TeamRole; teamId: string}}>()
);

export const setSelectedUserId = createAction(
  UserManagementActionTypes.SetSelectedUserId,
  props<{payload: {userId: UserTeam['userId']}}>()
);
export const removeSelectedUserId = createAction(UserManagementActionTypes.RemoveSelectedUserId);

export const deleteUser = createAction(UserManagementActionTypes.DeleteUser, props<{payload: {userId: string}}>());
export const deleteUserSuccess = createAction(
  UserManagementActionTypes.DeleteUserSuccess,
  props<{payload: {userId: string}}>()
);

export const addUsersToTeamSuccess = createAction(
  UserManagementActionTypes.AddUsersToTeamSuccess,
  props<{
    payload: {users: {userId: string; role: TeamRole; teamId: string}[]; teamId: string; action: UpdateUserTeamAction};
  }>()
);

export const removeUsersFromTeam = createAction(
  UserManagementActionTypes.RemoveUsersFromTeam,
  props<{payload: {userId: string}}>()
);

export const removeUsersToTeamSuccess = createAction(
  UserManagementActionTypes.RemoveUsersFromTeamSuccess,
  props<{payload: {users: {userId: string; role: TeamRole}[]; teamId: string; action: UpdateUserTeamAction}}>()
);

export const openInviteUsersDialog = createAction(UserManagementActionTypes.OpenInviteUsersDialog);

export const inviteUsers = createAction(
  UserManagementActionTypes.InviteUsers,
  props<{payload: {users: UserInvitation[]}}>()
);

export const inviteUsersSuccess = createAction(
  UserManagementActionTypes.InviteUsersSuccess,
  props<{payload: {users: UserTeam[]}}>()
);

export const displayLoading = createAction(UserManagementActionTypes.DisplayLoading);
export const hideLoading = createAction(UserManagementActionTypes.HideLoading);

import {Injectable} from '@angular/core';

import {Store} from '@ngrx/store';
import {UserService} from '@app/core/services/api/user.service';
import {AbstractTrackingService} from './abstract-tracking-service.service';
import { IotTopicService, IotServiceType } from '@app/flights/services/iot-topic.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesIotService extends AbstractTrackingService<IoTMessage> {
  constructor(userService: UserService, store: Store, iotTopicService: IotTopicService) {
    super(userService, 1, IotServiceType.MESSAGES, store, iotTopicService);
  }
}
/*example:
{
  "code": "confirm_take_off",
  "title": "Take off?",
  "message": "You are about to take off. Are you sure?",
  "time": 1611582330
}
 */
export enum IoTMessageCode {
  CONFIRM_TAKE_OFF = 'confirm_take_off',
  CONFIRM_LAND = 'confirm_land',
  CONFIRM_START_MISSION = 'confirm_start_mission',
  CONFIRM_END_MISSION = 'confirm_end_mission',
  CONFIRM_END_MISSION_AND_START_NEW = 'confirm_end_mission_start_new',
  CONFIRM_REMOTE_CAM_CONTROL = 'confirm_remote_cam_control',
  CONFIRM_REMOTE_FULL_CONTROL = 'confirm_remote_full_control'
}

export interface IoTMessage {
  code: IoTMessageCode;
  title: string;
  message: string;
  time: number;
  severity: IoTSeverityMessage;
}

export enum IoTSeverityMessage {
  INFO = 'INFO',
  CONFIRM = 'CONFIRM',
  ERROR = 'ERROR'
}

import {ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';
import {environment} from 'environments/environment';
import * as fromRemoteCockpit from '@app/flights/store/remote-cockpit/remote-cockpit.reducer';
import * as fromFlightLog from '@app/flights/store/flight-log/flight-log.reducer';

export const flightsFeatureKey = 'flights';

export const selectFlightsSelectorState = createFeatureSelector<State>(flightsFeatureKey);

export const selectRemoteCockpitState = createSelector(
  selectFlightsSelectorState,
  (state: State) => state[fromRemoteCockpit.remoteCockpitFeatureKey]
);

export const selectFlightLogsState = createSelector(
  selectFlightsSelectorState,
  (state: State) => state[fromFlightLog.flightLogsFeatureKey]
);
export interface State {
  [fromRemoteCockpit.remoteCockpitFeatureKey]: fromRemoteCockpit.State;
  [fromFlightLog.flightLogsFeatureKey]: fromFlightLog.FlightLogState;
}

export const reducers: ActionReducerMap<State> = {
  [fromRemoteCockpit.remoteCockpitFeatureKey]: fromRemoteCockpit.reducer,
  [fromFlightLog.flightLogsFeatureKey]: fromFlightLog.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

import {Component, ChangeDetectionStrategy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HelpService} from '@app/core/services/help.service';

@Component({
  selector: 'unleash-device-not-found-dialog',
  templateUrl: './device-not-found-dialog.component.html',
  styleUrls: ['./device-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceNotFoundDialogComponent {
  constructor(private helpService: HelpService, private dialogRef: MatDialogRef<DeviceNotFoundDialogComponent>) {}

  public goToContactUs(): void {
    this.helpService.goToContactUs();
  }

  public close(): void {
    this.dialogRef.close();
  }
}

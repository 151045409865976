import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Observable} from 'rxjs';
import {LibraryItemMutateModel} from '../models/delete-items.model';
import {LibraryItem} from '../models/folder-file.model';
import {
  LibraryAPIAiVideoMenuResponseModel,
  LibraryApiDeleteResponseModel,
  LibraryApiGetBatchResponseModel,
  LibraryApiFileCountResponseModel,
  LibraryAPIGetGPSMetadataResponseModel,
  LibraryApiGetResponseModel,
  LibraryApiListResponseChildModel,
  LibraryApiListResponseModel,
  LibraryApiListSubfoldersResponseModel,
  LibraryApiMoveResponseModel,
  LibraryApiRenameResponseModel,
  LibraryApiSaveFolderResponseModel,
  LibraryModelDataResponseModel,
  LibrarySubscriptionApiDeleteResponseModel
} from '../models/library-api-list-response.model';
import {
  LibraryItemQueryParams,
  LibraryListQueryParams,
  LibraryListSubfoldersQueryParams
} from '../models/library-filter.model';
import {UpdateTagItemsQuery} from '../models/tag.model';
import {
  createNewFolder,
  deleteLibraryItem,
  getBatchItemsQuery,
  getBatchItemsQueryAnnotations,
  getDeleteItemsQuery,
  getFileCountQuery,
  getFolderLocation,
  getLibraryItem,
  getLibraryItemAnnotation,
  getLibraryItemGPSMetadata,
  getLibraryItemListAiQuery,
  getLibraryItemMetadata,
  getLibraryListAiVideoMenuQuery,
  getLibraryListQuery,
  getLibraryListSubfoldersQuery,
  getModelDataQuery,
  getModelsListQuery,
  getMoveItemsQuery,
  getOneImageFromFolderQuery,
  getRenameItemQuery,
  getUpdateLibraryItemsTag,
  newLibraryBatchItem,
  newLibraryItem
} from '../queries/library.queries';
import {LibraryAwsAppAsyncClientService} from './library-aws-appsync-client.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryAppAsyncService extends LibraryAwsAppAsyncClientService {
  public watchNewFileItems(userId: string): Observable<{data?: {newLibraryBatchItem: {libraryItems: LibraryItem[]}}}> {
    const batchCreateSubscriberQuery = newLibraryBatchItem(userId);
    return this.client.subscribe<any>({query: gql(batchCreateSubscriberQuery)}) as any;
  }

  public watchNewFileItem(userId: string): Observable<{data: {newLibraryItem: LibraryItem}}> {
    const query = newLibraryItem(userId);
    return this.client.subscribe({query: gql(query)}) as any;
  }

  public watchDeleteFileItem(userId: string): Observable<{data: LibrarySubscriptionApiDeleteResponseModel}> {
    const query = deleteLibraryItem(userId);
    return this.client.subscribe({query: gql(query)}) as any;
  }

  public getLibraryItem(queryParams: LibraryItemQueryParams): Promise<{data: LibraryApiGetResponseModel}> {
    const query = getLibraryItem(queryParams.id);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public getLibraryItemAnnotation(queryParams: LibraryItemQueryParams): Promise<{data: LibraryApiGetResponseModel}> {
    const query = getLibraryItemAnnotation(queryParams.id);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public getBatchItems(
    items: LibraryItemMutateModel[],
    withAnnotations: boolean = false
  ): Promise<{data: LibraryApiGetBatchResponseModel}> {
    const query = getBatchItemsQuery(items, withAnnotations);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public getBatchItemsQueryAnnotations(
    items: LibraryItemMutateModel[]
  ): Promise<{data: LibraryApiGetBatchResponseModel}> {
    const query = getBatchItemsQueryAnnotations(items);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public getLibrary(queryParams: LibraryListQueryParams): Promise<{data: LibraryApiListResponseModel}> {
    const query = getLibraryListQuery(queryParams);
    return this.client.query({
      query: gql(query),
      fetchPolicy: 'no-cache'
    });
  }

  public getOneImageFromFolderQuery(queryParams: LibraryListQueryParams): Promise<{data: LibraryApiListResponseModel}> {
    const query = getOneImageFromFolderQuery(queryParams);
    return this.client.query({
      query: gql(query),
      fetchPolicy: 'no-cache'
    });
  }

  public getModelData(queryParams: LibraryItemQueryParams): Observable<{data: LibraryModelDataResponseModel}> {
    const {id} = queryParams;
    const query: string = getModelDataQuery(id);
    return this.client.query({
      query: gql(query),
      fetchPolicy: 'no-cache'
    }) as any;
  }

  public getModels(queryParams: LibraryListQueryParams): Promise<{data: LibraryApiListResponseChildModel}> {
    const query = getModelsListQuery(queryParams);
    return this.client.query({
      query: gql(query),
      fetchPolicy: 'no-cache'
    });
  }

  public getLibrarySubfolders(
    queryParams: LibraryListSubfoldersQueryParams
  ): Promise<{data: LibraryApiListSubfoldersResponseModel}> {
    const query = getLibraryListSubfoldersQuery(queryParams);
    return this.client.query({
      query: gql(query),
      fetchPolicy: 'no-cache'
    });
  }

  public createNewFolder(
    folderName: string,
    parentId: string,
    location?: string
  ): Promise<{data?: LibraryApiSaveFolderResponseModel}> {
    const query = createNewFolder(folderName, parentId, location);
    return this.client.mutate({mutation: gql(query)});
  }

  public requestFolderLocation(folderId: string): Promise<{data: any}> {
    const query = getFolderLocation(folderId);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public moveLibraryItems(
    to: LibraryItemMutateModel,
    moveItems: LibraryItemMutateModel[]
  ): Promise<{data?: LibraryApiMoveResponseModel}> {
    const query = getMoveItemsQuery(to, moveItems);
    return this.client.mutate({mutation: gql(query)});
  }

  public deleteLibraryItems(deleteItems: LibraryItemMutateModel[]): Promise<{data?: LibraryApiDeleteResponseModel}> {
    const query = getDeleteItemsQuery(deleteItems);
    return this.client.mutate({mutation: gql(query)});
  }

  public renameLibraryItem(
    item: LibraryItemMutateModel,
    newName: string
  ): Promise<{data?: LibraryApiRenameResponseModel}> {
    const query = getRenameItemQuery(item, newName);
    return this.client.mutate({mutation: gql(query)});
  }

  public getLibraryItemMetadata(id: string): Promise<{data: {get: {metadata: {[key: string]: string}}}}> {
    const query = getLibraryItemMetadata(id);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public getLibraryItemGPSMetadata(libraryItemId: string): Promise<{
    data: LibraryAPIGetGPSMetadataResponseModel;
  }> {
    const query = getLibraryItemGPSMetadata(libraryItemId);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }

  public getLibraryAiVideoMenu(queryParams: LibraryListQueryParams): Promise<{
    data: LibraryAPIAiVideoMenuResponseModel;
  }> {
    const query = getLibraryListAiVideoMenuQuery(queryParams);
    return this.client.query({query: gql(query), fetchPolicy: 'cache-first'});
  }

  public getLibraryItemListAiQuery(queryParams: LibraryItemQueryParams): Promise<{data: {get: Partial<LibraryItem>}}> {
    const {id} = queryParams;
    const query: string = getLibraryItemListAiQuery(id);
    return this.client.query({
      query: gql(query),
      fetchPolicy: 'cache-first'
    });
  }

  public updateLibraryItemsTag(items: UpdateTagItemsQuery[]): Promise<{data?: any}> {
    const query = getUpdateLibraryItemsTag(items);
    return this.client.mutate({mutation: gql(query)});
  }

  public getFileCount(libraryItems: {id: LibraryItem['id']}[]): Promise<{data: LibraryApiFileCountResponseModel}> {
    const query: string = getFileCountQuery(libraryItems);
    return this.client.query({query: gql(query), fetchPolicy: 'no-cache'});
  }
}

import * as DJIParser from 'dji-log-parser';
import {Observable, fromEvent, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

export interface FlightInfo {
  droneType?: string;
  appType: string;
  appVersion: string;
  longitude: number;
  latitude: number;
  totalDistance: number;
  totalTime: number;
  maxHeight: number;
  maxHSpeed: number;
  maxVSpeed: number;
  subStreet: string;
  street: string;
  city: string;
  area: string;
  updateTime: string;
  aircraftName: string;
}

export interface FlightFrame {
  longitude: number;
  latitude: number;
  height: number;
  XspeedX: number;
  Yspeed: number;
  Zspeed: number;
  pitch: number;
  roll: number;
  yaw: number;
  gimYaw?: number;
  gpsNum: number;
  relativeCapacity: number[];
  flycState: string;
}

// todo extract to service
export class FlightLogParser {
  private reader: FileReader;
  private flightInfo: FlightInfo;
  private flightFrames: FlightFrame[];
  private parser: DJIParser;

  constructor() {}

  readFile(flightLogFile: File): Observable<any> {
    this.flightInfo = {} as FlightInfo;
    this.flightFrames = [];
    this.reader = new FileReader();
    this.parser = new DJIParser(); // important to reinitialize for each file read
    const fileReader$ = fromEvent(this.reader, 'load');
    this.reader.readAsArrayBuffer(flightLogFile);
    return fileReader$.pipe(
      switchMap(() => {
        return this.read(this.reader.result, flightLogFile.name);
      })
    );
  }

  readBuffer(flightBuffer: any, name: string): Observable<any> {
    this.flightInfo = {} as FlightInfo;
    this.flightFrames = [];
    this.parser = new DJIParser(); // important to reinitialize for each file read
    return this.read(flightBuffer, name);
  }

  private read(result, name: string): Observable<any> {
    // init parser

    // init flightFrames
    let currentFrame: FlightFrame;
    let currentFlyTime;

    const newFrame = flyTime => {
      const lastFrame = this.flightFrames.slice(-1)[0];

      // Detect droped flightFrames and fill it with a copy of the last frame
      if (frames.length && flyTime - currentFlyTime > 1) {
        for (let i = currentFlyTime + 1; i < flyTime; i += 1) {
          this.flightFrames.push(lastFrame);
        }
      }

      // Add a new frame
      currentFlyTime = flyTime;
      if (currentFrame) {
        this.flightFrames.push({...lastFrame, ...currentFrame});
      }
      currentFrame = {} as FlightFrame;
    };

    this.parser.on('DETAILS', obj => {
      this.flightInfo.subStreet = ''; // obj.getSubStreet();
      this.flightInfo.street = obj.getStreet();
      this.flightInfo.city = obj.getCity();
      this.flightInfo.area = obj.getArea();
      this.flightInfo.longitude = obj.getLongitude();
      this.flightInfo.latitude = obj.getLatitude();
      this.flightInfo.totalDistance = obj.getTotalDistance();
      this.flightInfo.totalTime = obj.getTotalTime();
      this.flightInfo.maxHeight = obj.getMaxHeight();
      this.flightInfo.maxHSpeed = obj.getMaxHSpeed();
      this.flightInfo.maxVSpeed = obj.getMaxVSpeed();
      this.flightInfo.updateTime = obj.getUpdateTime();

      // see https://github.com/unleashlive/unleashcloudfront/issues/249
      try {
        this.flightInfo.aircraftName = obj.getAircraftName();
      } catch (e) {
        console.warn('Could not retrieve aircraft name');
        this.flightInfo.aircraftName = '';
      }
    });

    this.parser.on('RECOVER', obj => {
      this.flightInfo.droneType = obj.getDroneType();
      this.flightInfo.appType = obj.getAppType();
      this.flightInfo.appVersion = obj.getAppVersion();
    });

    this.parser.on('OSD', obj => {
      newFrame(obj.getFlyTime() * 10);
      currentFrame.longitude = obj.getLongitude();
      currentFrame.latitude = obj.getLatitude();
      currentFrame.height = obj.getHeight();
      currentFrame.XspeedX = obj.getXSpeed();
      currentFrame.Yspeed = obj.getYSpeed();
      currentFrame.Zspeed = obj.getZSpeed();
      currentFrame.pitch = obj.getPitch();
      currentFrame.roll = obj.getRoll();
      currentFrame.yaw = Math.round(obj.getYaw() / 10);
      currentFrame.gpsNum = obj.getGpsNum();
      currentFrame.flycState = obj.getFlycState();
    });

    this.parser.on('CENTER_BATTERY', obj => {
      currentFrame.relativeCapacity = Array.isArray(obj.getRelativeCapacity())
        ? obj.getRelativeCapacity()
        : [obj.getRelativeCapacity()];
    });

    /* this.parser.on('HOME', () => console.log('HOME'));
    this.parser.on('GIMBAL', () => console.log('GIMBAL'));
    this.parser.on('RC', () => console.log('RC'));
    this.parser.on('CENTER_BATTERY', () => console.log('CENTER_BATTERY'));
    this.parser.on('SMART_BATTERY', () => console.log('SMART_BATTERY'));
    this.parser.on('RC_GPS', () => console.log('RC_GPS'));
    this.parser.on('APP_GPS', () => console.log('APP_GPS'));
    this.parser.on('RECOVER', () => console.log('RECOVER'));
    this.parser.on('CUSTOM', () => console.log('CUSTOM'));
    this.parser.on('DEFORM', () => console.log('DEFORM'));
    this.parser.on('APP_TIP', () => console.log('APP_TIP'));
    this.parser.on('APP_WARN', () => console.log('APP_WARN'));
    */

    try {
      this.parser.parse(result);
    } catch (e) {
      console.warn('Flight log parser error', e);
    }

    return of({
      title: name,
      flightInfo: this.flightInfo,
      flightFrames: this.flightFrames
    });
  }
}

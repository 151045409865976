import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'unleash-divider',
  templateUrl: './unleash-divider.component.html',
  styleUrls: ['./unleash-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnleashDividerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

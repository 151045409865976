import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {authLogout} from '@app/auth/state/auth.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'awscognito-angular2-app',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {
  constructor(private store: Store, private route: ActivatedRoute) {
    this.store.dispatch(authLogout({isDeveloperMode: this.route.snapshot.data.isDeveloperMode}));
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'unleash-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoCardComponent implements OnInit {
  @Input() name;
  @Input() backgroundImage;
  @Input() buttonName;
  @Input() description;
  @Input() isTextOnTop: boolean;

  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  clickOnAction(event: any) {
    this.action.emit(event);
  }
}

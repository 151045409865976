import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PaymentCardComponent} from '@app/shared/stripe-elements/payment-card/payment-card.component';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {BehaviorSubject, Observable, take} from 'rxjs';
import {PaymentService} from '../../services/payment.service';

@Component({
  selector: 'unleash-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingComponent {
  @ViewChild('paymentCard', {static: false, read: PaymentCardComponent}) public paymentCard: PaymentCardComponent;

  @Input() public form: UntypedFormGroup;
  @Input() public monthly: number;
  @Input() public annual: number;
  @Output() public stripeToken: EventEmitter<any> = new EventEmitter();

  public paymentPeriod = PaymentPeriod;
  public promoCode: string;

  private isSuccessPromoCode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  public isSuccessPromoCode$: Observable<boolean> = this.isSuccessPromoCode.asObservable();

  constructor(private paymentService: PaymentService) {}

  public emitStripeToken(token: any): void {
    if (!!this.promoCode && this.isSuccessPromoCode.value) {
      token.promoCode = this.promoCode;
    }
    this.stripeToken.emit(token);
  }

  public setCardNameFocus() {
    this.paymentCard.setCardNameFocus();
  }

  public setupPromoCode(promoCode: string): void {
    this.paymentService
      .checkPromoCode(promoCode)
      .pipe(take(1))
      .subscribe((validation: {valid: boolean}) => {
        this.isSuccessPromoCode.next(validation.valid);
        if (validation.valid) {
          this.promoCode = promoCode;
        }
      });
  }
}

<h1 mat-dialog-title>Rename</h1>
<div mat-dialog-content [formGroup]="form">
  <mat-form-field class="group-name-input">
    <input
      data-test-id="layer-name-input"
      matInput
      placeholder="{{ 'atlas.renameLayer.layerName' | translate }}"
      formControlName="name"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button color="primary" [mat-dialog-close]="false">{{ 'common.cancel' | translate }}</button>
  <button
    mat-button
    color="primary"
    [disabled]="!form.value.name"
    [mat-dialog-close]="form.value.name"
    data-test-id="rename-button"
  >
    {{ 'atlas.control.rename' | translate }}
  </button>
</div>

import {Injectable} from '@angular/core';
import {Team, TeamRole, TeamTable} from '@app/profile/models/team.model';
import * as actions from '@app/profile/store/teams-management/team-management.actions';
import {
  selectCurrentTeam,
  selectSelectedTeamTable,
  selectTeams,
  selectTeamsTable,
  selectUsersByTeam
} from '@app/profile/store/profile.selectors';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UserTable, UserTeam} from '@app/profile/models/users-table.model';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsManagementStoreFacadeService {
  public teams$: Observable<Team[]> = this.store.select(selectTeams);
  public currentTeam$: Observable<Team> = this.store.select(selectCurrentTeam);
  public teamsTable$: Observable<TeamTable[]> = this.store.select(selectTeamsTable);
  public selectedTeamTable$: Observable<TeamTable> = this.store.select(selectSelectedTeamTable);
  public userBySelectedTeam$: Observable<UserTable[]> = this.store.select(selectUsersByTeam);

  constructor(private store: Store) {}

  public setTeams(teams: Team[]) {
    this.store.dispatch(actions.setTeams({payload: {teams}}));
  }

  public addTeam(name: Team['name']): void {
    this.store.dispatch(actions.addTeam({payload: {name}}));
  }

  public addTeamSuccess(team: Team): void {
    this.store.dispatch(actions.addTeamSuccess({payload: {team}}));
  }

  public deleteTeam(teamId: Team['id']): void {
    this.store.dispatch(actions.deleteTeam({payload: {teamId}}));
  }

  public deleteTeamSuccess(teamId: Team['id']) {
    this.store.dispatch(actions.deleteTeamSuccess({payload: {teamId}}));
  }

  public setSelectedTeamId(teamId: Team['id']): void {
    this.store.dispatch(actions.setSelectedTeamId({payload: {teamId}}));
  }

  public removeSelectedTeamId(): void {
    this.store.dispatch(actions.removeSelectedTeamId());
  }

  public editTeam(id: string): void {
    this.store.dispatch(actions.editTeam({payload: {id}}));
  }

  public editTeamSuccess(payload: {teamId: Team['id']; name: string; updatedAt: number}): void {
    this.store.dispatch(
      actions.editTeamSuccess({payload: {teamId: payload.teamId, name: payload.name, updatedAt: payload.updatedAt}})
    );
  }

  public updateTeamCounterAfterUserDelete(teamId: string): void {
    this.store.dispatch(
      actions.updateTeamCounterAfterUserDelete({
        payload: {teamId}
      })
    );
  }

  public openAddUsersToTeam(): void {
    this.store.dispatch(actions.openAddUsersToTeam());
  }

  public addUsersToTeam(users: UserTable[]): void {
    this.store.dispatch(actions.addUsersToTeam({payload: {users, action: UpdateUserTeamAction.add}}));
  }

  public addUsersToTeamSuccess(response: {
    users: {userId: string; role: TeamRole; teamId: string}[];
    teamId: string;
    action: UpdateUserTeamAction;
  }) {
    this.store.dispatch(actions.addUsersToTeamSuccess({payload: response}));
  }

  public removeUsersToTeamSuccess(payload: {teamId: string; users: {userId: string}[]}) {
    this.store.dispatch(actions.removeUsersToTeamSuccess({payload}));
  }

  public saveDashboard(data: any): void {
    const payload = {data};
    this.store.dispatch(actions.saveDashboard({payload}));
  }

  public inviteUsersSuccess(users: UserTeam[]): void {
    this.store.dispatch(actions.inviteUsersSuccess({payload: users}));
  }

  public setTeamsSuccess(): void {
    this.store.dispatch(actions.setTeamsSuccess());
  }
}

<mat-accordion displayMode="default" multi="false">
  <mat-expansion-panel #panel *ngIf="devices" [expanded]="activeDevicesNum == 0" >
    <mat-expansion-panel-header expandedHeight="64px" collapsedHeight="64px">
      <mat-panel-title>
        <h4 *ngIf="activeDevicesNum > 0">There are {{devices.length}} inactive devices</h4>
        <h4 *ngIf="activeDevicesNum === 0">You have no active devices.</h4>
      </mat-panel-title>
      <mat-panel-description>
        Show inactive
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutGap="10px">
      <ng-container *ngFor="let d of devices; let i = index">
      <div fxLayout="row" class="device-row inactive {{d.isLive?'hidden':''}}">

        <!--device avatar-->
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 25vw"
             class="device-left">
          <div class="device-avatar"
               [style.background-image]="'url('+d.logo+')'">
            <mat-icon *ngIf="!d.logo">live_tv</mat-icon>
          </div>
          <span class="device-title">{{ d.name }} </span>
          <span class="device-description">{{ d.description }} </span>
        </div>

        <!--device player-->
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="3 3 75vw"
             class="device-stream">
          <div class="no-stream-message">Offline</div>
        </div>
      </div>
      </ng-container>
    </div>

  </mat-expansion-panel>
</mat-accordion>

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AddUsersToTeamComponent} from '@app/profile/components/teams/add-users-to-team/add-users-to-team.component';
import {DeleteTeamDialogComponent} from '@app/profile/components/teams/delete-team-dialog/delete-team-dialog.component';
import {EditTeamDialogComponent} from '@app/profile/components/teams/edit-team-dialog/edit-team-dialog.component';
import {Team, TeamTable} from '@app/profile/models/team.model';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';
import {UserTable} from '@app/profile/models/users-table.model';
import {TeamsApiService} from '@app/profile/services/teams-api.service';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import * as actions from '@app/profile/store/teams-management/team-management.actions';
import {PayloadAction} from '@app/shared/models/payload-action';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, merge, of, switchMap, take, tap, withLatestFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsManagementEffects {
  public actionAddTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.addTeam),
        switchMap(({payload}: PayloadAction<{name: Team['name']}>) => {
          return this.teamApiService.createTeam(payload.name);
        }),
        tap((team: Team) => {
          this.teamsManagementStoreFacadeService.addTeamSuccess({...team, members: 0});
        })
      ),
    {dispatch: false}
  );

  public deleteTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteTeam),
        switchMap(({payload}: PayloadAction<{teamId: Team['id']}>) => {
          return this.teamsManagementStoreFacadeService.teams$.pipe(
            take(1),
            map(teams => teams.find(team => team.id === payload.teamId))
          );
        }),
        switchMap((team: Team) => {
          return this.dialog
            .open(DeleteTeamDialogComponent, {
              ...STANDARD_DIALOG_CONFIG,
              width: '80vw',
              maxWidth: '800px',
              data: {
                team: {
                  teamId: team.id,
                  name: team.name
                }
              }
            })
            .afterClosed();
        }),
        switchMap(({hasToDelete, teamId}) => {
          if (hasToDelete) {
            return merge(of(teamId), this.teamApiService.deleteTeam(teamId).pipe(map(team => team.id)));
          }
          return of(null);
        }),
        tap((team: Team['id']) => {
          if (!team) {
            return;
          }

          this.teamsManagementStoreFacadeService.deleteTeamSuccess(team);
        })
      ),
    {
      dispatch: false
    }
  );

  public editTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.editTeam),
        switchMap(({payload}: PayloadAction<{id: Team['id']}>) =>
          this.teamsManagementStoreFacadeService.teams$
            .pipe(map(teams => teams.find(team => team.id === payload.id)))
            .pipe(take(1))
        ),
        switchMap((team: Team) => {
          return this.dialog
            .open(EditTeamDialogComponent, {
              ...STANDARD_DIALOG_CONFIG,
              width: '80vw',
              maxWidth: '800px',
              data: {team: {name: team.name, teamId: team.id}}
            })
            .afterClosed();
        }),
        switchMap(({hasToUpdate, teamId, name}: {hasToUpdate: boolean; teamId: string; name: string}) => {
          if (hasToUpdate) {
            return merge(
              of({teamId, name, updatedAt: new Date().getTime()}),
              this.teamApiService.editTeam({teamId, name})
            );
          }
          return of(null);
        }),
        tap((team: {teamId: Team['id']; name: string; updatedAt: number}) => {
          if (!team) {
            return;
          }

          this.teamsManagementStoreFacadeService.editTeamSuccess(team);
        })
      ),
    {dispatch: false}
  );

  public openAddUsersToTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.openAddUsersToTeam),
        switchMap(() => {
          return this.dialog.open(AddUsersToTeamComponent, {...STANDARD_DIALOG_CONFIG, width: '800px'}).afterClosed();
        }),
        tap((response: {hasToAdd: boolean; users: UserTable[]}) => {
          if (!response?.hasToAdd || response.users?.length === 0) {
            return;
          }

          this.teamsManagementStoreFacadeService.addUsersToTeam(response.users);
        })
      ),
    {
      dispatch: false
    }
  );

  public addUsersToTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.addUsersToTeam),
        withLatestFrom(this.teamsManagementStoreFacadeService.selectedTeamTable$),
        switchMap(
          ([{payload}, team]: [PayloadAction<{users: UserTable[]; action: UpdateUserTeamAction}>, TeamTable]) => {
            const usersPayload = payload.users.map(user => ({
              userId: user.userId,
              roles: [user.role],
              action: payload.action
            }));

            return merge(
              of({
                users: payload.users.map(user => ({userId: user.userId, role: user.role, teamId: user.teamId})),
                teamId: team.teamId,
                action: payload.action
              }),
              this.teamApiService.editUserTeam({users: usersPayload, teamId: team.teamId})
            );
          }
        ),
        tap(response => {
          if (!response) {
            return;
          }

          this.teamsManagementStoreFacadeService.addUsersToTeamSuccess(response);
          this.usersManagementStoreFacadeService.addUsersToTeamSuccess(response);
        })
      ),
    {dispatch: false}
  );

  public saveDashboard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.saveDashboard),
        tap(({payload}) => {
          // TODO call to console.log('payload', payload);
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private teamApiService: TeamsApiService,
    private dialog: MatDialog,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService,
    private usersManagementStoreFacadeService: UsersManagementStoreFacadeService
  ) {}
}

<ng-container *ngIf="entryType === uploadTypes.FILE; else uploadFolder">
  <button
    [disabled]="disabled"
    data-test-id="upload-files-menu-item"
    mat-menu-item
    (click)="file.click(); file.value = ''"
    class="upload-button"
  >
    <input
      data-test-id="upload-files-input"
      data-amplify-analytics-on="click"
      data-amplify-analytics-name="click_upload_icon"
      id="navbar_upload_icon"
      type="file"
      #file
      multiple
      (change)="emitFile($event.target.files, uploadTypes.FILE)"
      [style.display]="'none'"
    />
    <mat-icon svgIcon="u_upload_file"></mat-icon> {{ 'core.header.uploadFiles' | translate }}
  </button>
</ng-container>
<ng-template #uploadFolder>
  <button class="upload-button" (click)="folderInput.click()" [disabled]="disabled" mat-menu-item>
    <mat-icon svgIcon="u_upload_folder"></mat-icon> {{ 'core.header.uploadFolders' | translate }}
  </button>
  <input
    #folderInput
    type="file"
    webkitDirectory
    (change)="emitFile(folderInput.files, uploadTypes.FOLDER); folderInput.value = ''"
    [style.display]="'none'"
  />
</ng-template>

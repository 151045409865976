<div class="dialog">
  <section class="dialog__title">{{ data.title }}</section>
  <section class="dialog__content">
    <span *ngFor="let paragraph of data.paragraphs">{{ paragraph }}</span>
  </section>
  <section class="dialog__actions">
    <button [disabled]="hasToShowSpinner" mat-button color="primary" (click)="closeDialog()">
      {{ 'common.cancel' | translate }}
    </button>
    <button [disabled]="hasToShowSpinner" (click)="executeAction()" mat-button class="dialog__button">
      {{ data.actionName }}
      <unleash-spinner *ngIf="hasToShowSpinner" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
    </button>
  </section>
</div>

import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '@app/auth/services/auth.service';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {actionVerifyMfa} from '../sign-in/sign-in.actions';

@Component({
  selector: 'unleash-totp-verification',
  templateUrl: './totp-verification.component.html',
  styleUrls: ['./totp-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotpVerificationComponent implements OnDestroy, AfterViewInit {
  public totpForm: FormGroup = this.fb.group({
    code: [null, [Validators.required, Validators.maxLength(6)]]
  });
  public token: string = this.authService.totpToken;
  public isInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isTotpCodeValid: BehaviorSubject<boolean> = this.authService.isTotpCodeValid;
  private isTotpValidCodeSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {}

  public ngOnDestroy(): void {
    if (this.isTotpValidCodeSub) {
      this.isTotpValidCodeSub.unsubscribe();
      this.isTotpValidCodeSub = null;
    }
  }

  public ngAfterViewInit(): void {
    this.isTotpValidCodeSub = this.isTotpCodeValid.subscribe(isValid => {
      if (!isValid) {
        this.isInProgress.next(false);
        this.totpForm.controls.code.setErrors({invalidCode: true});
      }
    });
  }

  public continue(): void {
    if (this.totpForm.valid) {
      this.isInProgress.next(true);
      this.store.dispatch(
        actionVerifyMfa({
          code: this.totpForm.value.code
        })
      );
    }
  }
  public signOut(): void {
    this.router.navigate(['/auth/sign-in']);
  }
}

import {EventEmitter, Injectable} from '@angular/core';
import {Addon} from '@app/store/addon/models/addon';
import {select, Store} from '@ngrx/store';

import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {LabelConfig} from '../models/api/label-config.model';
import {UserDeviceJoined} from '../models/api/user-device.model';
import * as addonActions from '@app/core/store/addon/addon.actions';
import {ApiAddonListParams} from '@app/store/addon/models/api-list-params';
import {
  selectAddons,
  selectAddonsArray,
  selectAddonNamesById,
  selectAllAddonsWithConfig,
  selectIsLoading,
  selectMyInDevelopment,
  selectMyInDevelopmentTableData,
  selectPublishedAddons,
  selectPublishedAddonsObject,
  selectStoreFilter
} from '@app/core/store/addon/addon.selectors';
import {PlansService} from '@app/plans/services/plans.service';
import {AddonListParams} from '@app/developer-dashboard/addon/shared/models/addon-list-params';

@Injectable({providedIn: 'root'})
export class AddonStoreFacadeService {
  public addonsWithConfig$: Observable<Addon[]> = this.store.pipe(select(selectAllAddonsWithConfig));
  public addonsWithConfigSelectionDropdownOnly$: Observable<Addon[]> = this.store.pipe(
    select(selectAllAddonsWithConfig)
  );

  public addons$: Observable<{[key: string]: Addon}> = this.store.pipe(select(selectAddons));
  public addonNamesById$: Observable<{[key: string]: string}> = this.store.pipe(select(selectAddonNamesById));
  public addonsArray$: Observable<Addon[]> = this.store.pipe(select(selectAddonsArray));
  public myInDevelopmentAddons$: Observable<Addon[]> = this.store.pipe(select(selectMyInDevelopment));
  public myInDevelopmentTableData$: Observable<any> = this.store.pipe(select(selectMyInDevelopmentTableData));
  public isLoading$: Observable<boolean> = this.store.pipe(select(selectIsLoading));
  public publishedAddons$: Observable<Addon[]> = this.store.pipe(select(selectPublishedAddons));
  public publishedAddonsObject$: Observable<{[key: string]: Addon}> = this.store.pipe(
    select(selectPublishedAddonsObject)
  );
  public storeFilter$: Observable<AddonListParams> = this.store.pipe(select(selectStoreFilter));

  constructor(private store: Store, private plansService: PlansService) {}

  // eslint-disable-next-line rxjs/finnish
  public getAllActiveAddons(): Observable<Addon[]> {
    return this.addonsArray$;
  }

  // eslint-disable-next-line rxjs/finnish
  public selectEnabledAddons(): Observable<Addon[]> {
    return this.addonsArray$;
  }

  // eslint-disable-next-line rxjs/finnish
  public getAddon(addonId: string): Observable<Addon> {
    return this.addons$.pipe(map(addons => addons[addonId]));
  }

  // eslint-disable-next-line rxjs/finnish
  public getAddonLabelConfig(addonId: string): Observable<LabelConfig> {
    return this.getAddon(addonId).pipe(map((addon: Addon) => addon?.labelConfig));
  }

  public startAIProcess(userDevice: UserDeviceJoined, addon: Addon): void {
    this.store.dispatch(addonActions.startAiProcess({payload: {userDevice, addon}}));
  }

  // eslint-disable-next-line rxjs/finnish
  public getIsAnalyticsEnabled(): Observable<boolean> {
    return this.plansService._isAIAnalyticsEnabled.asObservable();
  }

  // eslint-disable-next-line rxjs/finnish
  public getIsModellingEnabled(): Observable<boolean> {
    return this.plansService.isModelingEnabled$;
  }

  public setIsAnalyticsEnabled(isAnalyticsEnabled: boolean): void {
    this.plansService._isAIAnalyticsEnabled.next(isAnalyticsEnabled);
  }

  public setIsModellingEnabled(isModellingEnabled: boolean): void {
    this.plansService._isModelingEnabled.next(isModellingEnabled);
  }

  public uploadImage(file: File, addonId: string, urlEmit: EventEmitter<string>): void {
    this.store.dispatch(addonActions.uploadLogo({payload: {addonId, file, urlEmit}}));
  }

  public loadAddons(params: ApiAddonListParams): void {
    this.store.dispatch(addonActions.loadAddons({payload: {params}}));
  }

  public loadListSuccess(addons: Addon[]) {
    this.store.dispatch(addonActions.loadListSuccess({payload: {addons}}));
  }

  public loadAddon(addonId: Addon['id']) {
    this.store.dispatch(addonActions.loadAddon({payload: {addonId}}));
  }

  public loadAddonSuccess(addon: Addon) {
    this.store.dispatch(addonActions.loadAddonSuccess({payload: {addon}}));
  }

  public loadMyInDevelopmentAddons(): void {
    this.store.dispatch(addonActions.loadMyInDevelopmentAddons());
  }

  public loadMyInDevelopmentAddonsSuccess(addons: Addon[]): void {
    this.store.dispatch(addonActions.loadMyInDevelopmentAddonsSuccess({payload: {addons}}));
  }

  public loadPublishedAddons(): void {
    this.store.dispatch(addonActions.loadPublishedAddons());
  }

  public loadPublishedAddonsSuccess(addons: Addon[]): void {
    this.store.dispatch(addonActions.loadPublishedAddonsSuccess({payload: {addons}}));
  }

  public createAiApp(): void {
    this.store.dispatch(addonActions.createAiApp());
  }

  public createAiAppSuccess(addon: Addon): void {
    this.store.dispatch(addonActions.createAiAppSuccess({payload: {addon}}));
  }

  public renameAiApp(payload: Pick<Addon, 'id' | 'name'>): void {
    this.store.dispatch(addonActions.renameAiApp({payload}));
  }

  public renameAiAppSuccess(addon: Addon) {
    this.store.dispatch(addonActions.renameAiAppSuccess({payload: {addon}}));
  }

  public deleteAiApp(addon: Pick<Addon, 'id' | 'name' | 'usersCount'>) {
    this.store.dispatch(addonActions.deleteAiApp({payload: {addon}}));
  }

  public deleteAiAppSuccess(addonId: Pick<Addon, 'id'>): void {
    this.store.dispatch(addonActions.deleteAiAppSuccess({payload: {addon: {id: addonId}}}));
  }

  public startLoading(): void {
    this.store.dispatch(addonActions.startLoading());
  }

  public stopLoading(): void {
    this.store.dispatch(addonActions.stopLoading());
  }

  public unPublishAiApp(payload: Pick<Addon, 'id' | 'name' | 'usersCount'>): void {
    this.store.dispatch(addonActions.unPublishAiApp({payload: {addon: payload}}));
  }

  public unPublishAiAppSuccess(addonId: Pick<Addon, 'id'>): void {
    this.store.dispatch(addonActions.unPublishAiAppSuccess({payload: addonId}));
  }

  public submitForReview(addon: Addon): void {
    this.store.dispatch(addonActions.submitForReview({payload: {addon}}));
  }

  public submitForReviewSuccess(addonId: Addon['id']): void {
    this.store.dispatch(addonActions.submitForReviewSuccess({payload: {addonId}}));
  }

  public cancelReview(addon: Addon): void {
    this.store.dispatch(addonActions.cancelReview({payload: {addonId: addon.id}}));
  }

  public cancelReviewSuccess(addonId: Addon['id']): void {
    this.store.dispatch(addonActions.cancelReviewSuccess({payload: {addonId}}));
  }

  public updateAddon(addon: Addon): void {
    this.store.dispatch(addonActions.updateAddon({payload: {addon}}));
  }

  public updateAddonSuccess(addon: Addon): void {
    this.store.dispatch(addonActions.updateAddonSuccess({payload: {addon}}));
  }

  public updateAddonCache(addon: Pick<Addon, 'id' | 'enabled'>) {
    this.store.dispatch(addonActions.updateAddonCache({payload: {addon}}));
  }

  public clearAddonStore(): void {
    this.store.dispatch(addonActions.clearAddonStore());
  }

  public saveStoreFilter(storeFilter: AddonListParams): void {
    this.store.dispatch(addonActions.saveAppStoreFilter({payload: {storeFilter}}));
  }

  public clearStoreFilters(): void {
    this.store.dispatch(addonActions.clearStoreFilters());
  }

  public watchAppStoreQueryParams(): void {
    this.store.dispatch(addonActions.watchAppStoreQueryParams());
  }
}

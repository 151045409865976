import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'unleash-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input() diameter: number = 20;
  @Input() borderWidth: number = 3;
  @Input() color: string = '#006ba8';
  @ViewChild('loader') loader: ElementRef;
  @ViewChild('loaderContainer') loaderContainer: ElementRef;

  ngAfterViewInit() {
    this.loaderContainer.nativeElement.setAttribute('style', `width: ${this.diameter}px; height: ${this.diameter}px`);
    this.loader.nativeElement.setAttribute(
      'style',
      `width: ${this.diameter - this.borderWidth * 2}px; height: ${
        this.diameter - this.borderWidth * 2
      }px; border-width:${this.borderWidth}px; border-color:${this.color};border-left:${
        this.borderWidth
      }px solid transparent`
    );
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {PermissionService} from '@app/core/services/permission.service';
import {MissionManagerService} from '../services/mission-manager.service';
import {Mission} from '../services/mission-settings.service';

@Directive({
  selector: '[ulOpenPreviewIn3d]',
  standalone: true
})
export class OpenPreviewIn3dDirective {
  @Input() public mission: Mission;

  constructor(private permissions: PermissionService, private missionManagerService: MissionManagerService) {}
  @HostListener('click', ['$event'])
  public openPreviewIn3d() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }

    this.missionManagerService.openPreviewIn3D(this.mission);
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {TileLayer} from 'leaflet';

@Directive({
  selector: '[ulBringToFrontLayer]',
  standalone: true
})
export class BringToFrontLayerDirective {
  @Input('ulBringToFrontLayer') public asset: AtlasAssetModel;

  constructor() {}

  @HostListener('click', ['$event'])
  public bringToFrontLayer(): void {
    const asset = this.asset;
    (asset.leafletLayer as TileLayer).bringToFront();
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RenameLayersDialogComponent} from '@app/atlas/components/rename-layers-dialog/rename-layers-dialog.component';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {TileLayer} from 'leaflet';
import {delay, filter, take} from 'rxjs/operators';
import {BehaviorSubject, ReplaySubject, Subscription} from 'rxjs';
import {LocalStorageGroupLayer} from './layers-control-group.models';
import {AtlasLocalStorageService} from '@app/atlas/services/atlas-local-storage.service';
import {ActivatedRoute} from '@angular/router';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {FlatNode} from '../layers-control-items.component';
import {LocalStorageLayer} from '../layers-control-item/layers-control-item.models';
import {taskLayers} from '@app/atlas/atlas.config';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layers-control-group',
  templateUrl: './layers-control-group.component.html',
  styleUrls: ['./layers-control-group.component.scss'],
  providers: [{provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: 'noop'}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersControlGroupComponent implements OnInit {
  public events = EVENTS;
  // eslint-disable-next-line rxjs/finnish
  public saveLayersView$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  // eslint-disable-next-line rxjs/finnish
  public goToDefaultLayers$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public layerGroupSavedInLocalStorage: LocalStorageGroupLayer[] = [];
  @Input() public node: any;
  @Input() public treeControl: FlatTreeControl<FlatNode>;
  @Input()
  public isExpanded: boolean;
  @Input()
  public groupName: string;
  @Input()
  public groupId: string;
  @Input()
  public children: AtlasAssetModel[];
  @Input('saveLayersView')
  public set saveLayersView(hasToSave: boolean | null) {
    if (hasToSave !== null) {
      this.saveLayersView$.next(hasToSave);
    }
  }
  @Input('goToDefaultLayers')
  public set DefaultLayers(hasToSetLayers: boolean | null) {
    if (hasToSetLayers !== null) {
      this.goToDefaultLayers$.next(hasToSetLayers);
    }
  }
  @Input('hasToDetectChanges')
  public set hasToDetectChanges(hasToDetectChanges: boolean | null) {
    if (hasToDetectChanges !== undefined) {
      this.refreshIsDisplaying();
      this.refreshColor();
    }
  }
  @Input() public hasToBlockMenu: boolean = false;
  @Input() public totalSelectedLayers: number;
  @Input() public isListOnly: boolean = false;
  @Output() public openLayerControlItemMenu: EventEmitter<MouseEvent> = new EventEmitter();

  // eslint-disable-next-line rxjs/finnish
  public isDisplaying$ = new BehaviorSubject<boolean>(true);
  // eslint-disable-next-line rxjs/finnish
  public isSelected$ = new BehaviorSubject<boolean>(false);
  public aclPermissions = AclPermissions;
  public availableColors = this.atlasService.availableColors;
  public customIconColorIndex: number = -1;
  public hasAllLayersLoaded$ = this.atlasService.hasAllLayersLoaded$.asObservable();

  private selectedLayersSub: Subscription;
  private saveLayersViewSub: Subscription;
  private goToDefaultLayersSub: Subscription;
  private isReadytoDetectAtlasChangesSub: Subscription;

  constructor(
    private atlasService: AtlasService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private atlasLocalStorageService: AtlasLocalStorageService
  ) {}

  public ngOnInit(): void {
    if (this.route.snapshot.queryParams.id) {
      this.isDisplaying$.next(!this.route.snapshot.queryParams.id);
    }
    this.selectedLayersSub = this.atlasService.selectedLayers$.subscribe(selectedAssets => {
      const assetIsSelected = a => !!selectedAssets.find(s => s.id === a.id);
      const allAssetsSelected = this.children.every(assetIsSelected);
      this.isSelected$.next(allAssetsSelected);
    });
    if (this.atlasService.avoidLoadLocalStorageAssets) {
      return;
    }
    this.loadDefaultSavedAssets();
    this.saveGroupLayersView();
    this.goToDefaultView();
    this.refreshColor();
    this.hideTaskAssets();
  }

  public changeGroupColor(colorIndex: number): void {
    this.customIconColorIndex = colorIndex;
    this.children.forEach(child => {
      this.atlasService.changeLayerColor(child, colorIndex);
    });
    this.atlasService.hasLayerGroupChanged$.next(true);
    this.translateService
      .get('atlas.changeColor.groupColorUpdated')
      .pipe(take(1))
      .subscribe(message => {
        this.snackBar.open(message, null, {
          panelClass: 'center',
          duration: 3000
        });
      });
  }

  public saveGroupLayersView(): void {
    this.saveLayersViewSub = this.saveLayersView$.subscribe(() => {
      const groupLayersLocalStorage: LocalStorageGroupLayer = {
        id: this.groupId,
        isDisplaying: this.isDisplaying$.value
      };
      this.atlasLocalStorageService.setItem(
        'savedGroup',
        this.atlasLocalStorageService.getItem('savedGroup') + ';' + JSON.stringify(groupLayersLocalStorage)
      );
    });
  }

  public goToDefaultView(): void {
    this.goToDefaultLayersSub = this.goToDefaultLayers$.subscribe(() => {
      this.layerGroupSavedInLocalStorage = [];
      this.layerGroupSavedInLocalStorage = this.atlasLocalStorageService.transformLocalStorageToJSON(
        'savedGroup'
      ) as LocalStorageGroupLayer[];

      this.handleVisibilityGroupLayers();
    });
  }

  public loadDefaultSavedAssets(): void {
    this.layerGroupSavedInLocalStorage = this.atlasLocalStorageService.transformLocalStorageToJSON(
      'savedGroup'
    ) as LocalStorageGroupLayer[];
    this.handleVisibilityGroupLayers();
  }

  public handleVisibilityLayers(layerSavedInLocalStorage: LocalStorageLayer[]): void {
    layerSavedInLocalStorage.forEach((item: LocalStorageLayer) => {
      this.children.forEach((layer: AtlasAssetModel) => {
        if (layer.id === item.id) {
          const asset = this.atlasService.assets.find(a => a.id === item.id);
          if (!!asset && !!item && asset.id === item.id) {
            asset.isDisplaying = item.isDisplaying;
          }
        }
      });
    });
  }

  public handleVisibilityGroupLayers(): void {
    this.isReadytoDetectAtlasChangesSub = this.atlasService.isReadytoDetectAtlasChanges$
      .pipe(delay(500), take(1))
      .subscribe(() => {
        this.layerGroupSavedInLocalStorage.forEach((item: LocalStorageGroupLayer) => {
          if (!item) {
            return;
          }
          if (this.groupId === item.id) {
            this.isDisplaying$.next(item.isDisplaying);
            this.changeLayersVisibility();
          }
        });

        const layerSavedInLocalStorage = this.atlasLocalStorageService.transformLocalStorageToJSON(
          'savedLayer'
        ) as LocalStorageLayer[];
        this.handleVisibilityLayers(layerSavedInLocalStorage);
      });
  }

  public changeLayersVisibility(): void {
    if (this.isDisplaying$.value) {
      this.children.forEach((layer: AtlasAssetModel) => (layer.isDisplaying = true));
      return;
    }
    this.children.forEach((layer: AtlasAssetModel) => (layer.isDisplaying = false));
  }

  public toggleSelectGroup(groupName: string) {
    const newSelectedState = !this.isSelected$.value;
    this.children.forEach(a => {
      if (a.groupName === groupName) {
        a.isSelected = newSelectedState;
        if (newSelectedState) {
          this.atlasService.addSelectedLayer(a);
        } else {
          this.atlasService.removeSelectedLayer(a.id);
        }
      }
    });
    this.atlasService.hasLayerGroupChanged$.next(true);
  }

  public toggleIsDisplayingGroup() {
    this.isDisplaying$.next(!this.isDisplaying$.value);
    this.children.forEach(layer => (layer.isDisplaying = this.isDisplaying$.value));
    this.atlasService.hasLayerGroupChanged$.next(true);
  }

  public renameGroup(groupName: string): void {
    const groupedLayers: AtlasAssetModel[] = this.atlasService.assets.filter(
      (asset: AtlasAssetModel): boolean => asset.groupName === groupName
    );
    const isRenamed: (name: string) => boolean = (name: string): boolean => !!name && groupName !== name;
    this.dialog
      .open(RenameLayersDialogComponent, {
        ...STANDARD_DIALOG_CONFIG,
        data: {name: groupName}
      })
      .afterClosed()
      .pipe(untilDestroyed(this), filter(isRenamed))
      .subscribe((name: string): void => {
        this.atlasService
          .updateAssets(groupedLayers, {groupName: name})
          .pipe(untilDestroyed(this))
          .subscribe(response => {
            this.translateService
              .get('atlas.control.renameSuccess', {value: response.length})
              .pipe(untilDestroyed(this))
              .subscribe(message => {
                this.snackBar.open(message, null, {duration: 5000});
              });
          });
      });
  }

  public ungroup(groupName: string): void {
    const groupedLayers: AtlasAssetModel[] = this.atlasService.assets.filter(
      (asset: AtlasAssetModel) => asset.groupName === groupName
    );
    this.atlasService
      .updateAssets(groupedLayers, {groupName: null})
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.translateService
          .get('atlas.control.ungroupSuccess', {value: response.length})
          .pipe(untilDestroyed(this))
          .subscribe(message => {
            this.snackBar.open(message, null, {duration: 5000});
          });
      });
  }

  public bringToFrontGroup(groupName: string): void {
    const groupedLayers: AtlasAssetModel[] = this.atlasService.assets.filter(
      (asset: AtlasAssetModel) => asset.groupName === groupName
    );
    groupedLayers.forEach((asset: AtlasAssetModel) => (asset.leafletLayer as TileLayer).bringToFront());
  }

  public bringToBackGroup(groupName: string): void {
    const groupedLayers: AtlasAssetModel[] = this.atlasService.assets.filter(
      (asset: AtlasAssetModel): boolean => asset.groupName === groupName
    );
    groupedLayers.forEach((asset: AtlasAssetModel) => (asset.leafletLayer as TileLayer).bringToBack());
  }

  public refreshIsDisplaying() {
    this.isDisplaying$.next(this.children.some((layer: AtlasAssetModel) => layer.isDisplaying));
  }

  public refreshColor() {
    const colorReference = this.children[0].customColorIndex;
    this.customIconColorIndex = this.children.every(
      (layer: AtlasAssetModel) => layer?.customColorIndex === colorReference
    )
      ? colorReference
      : -1;
  }

  public emitOpenLayerControlItemMenu(event: MouseEvent): void {
    this.openLayerControlItemMenu.emit(event);
  }

  private hideTaskAssets(): void {
    if (this.groupName === taskLayers) {
      this.isDisplaying$.next(false);
    }
  }
}

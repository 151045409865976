<h1 mat-dialog-title>{{ 'profile.dataPrivacy.deleteMedia' | translate }}</h1>
<div mat-dialog-content>{{ 'profile.dataPrivacy.deleteMediaWarning' | translate }}</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button [mat-dialog-close]="false" color="primary" tabindex="0">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <button color="warn" mat-button (click)="runDeleteAllMedia()" [mat-dialog-close]="true" tabindex="1">
    {{ 'common.delete' | translate | uppercase }}
  </button>
</div>

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {take} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from 'environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';

@Directive({
  selector: '[ulCopyLinkToAsset]',
  standalone: true
})
export class CopyLinkToAssetDirective {
  @Input('ulCopyLinkToAsset') public asset: AtlasAssetModel;

  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard
  ) {}

  @HostListener('click', ['$event'])
  public copyLinkAsset(): void {
    const asset = this.asset;
    this.translateService
      .get('atlas.control.copyToClipboard')
      .pipe(take(1))
      .subscribe((copyLink: string) => {
        this.snackBar.open(copyLink, null, {
          horizontalPosition: 'left',
          duration: 5000
        });
        const port = environment.name === 'Local' ? ':8080' : '';
        const url = `https://${environment.name.toLowerCase()}.unleashlive.com${port}/secure/atlas?id=${asset.id}`;
        this.clipboard.copy(url);
      });
  }
}

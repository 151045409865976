import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ReplaySubject, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CompanyModel} from '../../../core/models/api/company-model';
import {UserModel} from '../../../core/models/api/user-model';
import {UserService} from '../../../core/services/api/user.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() companyChanged: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();

  userIdentityId: string;
  user: UserModel = new UserModel();
  industryNames: string[] = [
    'Cities',
    'Construction',
    'Developer',
    'Emergency Services',
    'Energy & Renewables',
    'Infrastructure - Railway',
    'Infrastructure - Road',
    'Mining',
    'Pipeline & Resources',
    'Retail & Spaces',
    'Safety & Security',
    'Sports & Entertainment',
    'Transportation',
    'Other'
  ];
  companyForm: UntypedFormGroup;
  streamDashboardUrl: string = environment.STREAM_DASHBOARD_URL;
  companyStreamDashboardUrl: string = this.streamDashboardUrl;
  updateErrorMessage: string = null;
  companyModel: ReplaySubject<CompanyModel> = new ReplaySubject<CompanyModel>(1);

  private userSub: Subscription;
  private companyFormSub: Subscription;
  private companyModelSub: Subscription;

  constructor(
    public router: Router,
    public userService: UserService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.createForm();
  }

  @Input('company')
  set fillCompany(company: CompanyModel) {
    if (company && Object.keys(company).length > 0) {
      this.companyModel.next(company);
    }
  }

  ngOnChanges(): void {
    this.companyStreamDashboardUrl = this.streamDashboardUrl + this.companyForm.controls['slug'].value;
  }

  ngOnInit(): void {
    this.userSub = this.userService.user$.subscribe(user => {
      this.userIdentityId = user.id;
    });
    this.companyModelSub = this.companyModel.subscribe(company => {
      delete company['createdAt'];
      delete company['updatedAt'];
      this.companyForm.setValue(company);
      this.companyStreamDashboardUrl = this.streamDashboardUrl + this.companyForm.controls['slug'].value;
    });
  }

  ngOnDestroy(): void {
    // needed for AutoUnsubscribe
  }

  createForm(): void {
    const withoutHttpURLRegex = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/;
    const oneWordRegex = /\w/;
    this.companyForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      website: ['', [Validators.pattern(withoutHttpURLRegex)]],
      industry: ['', Validators.required],
      slug: ['', [Validators.required, Validators.pattern(oneWordRegex)]],
      shared: [''],
      logo: [''],
      owner: ['']
    });
  }

  displayFn(company: CompanyModel): any {
    if (!company) {
      return '';
    }
    return typeof company === 'object' ? company.name : company;
  }

  updateCompanyLogo(s3Url: string): void {
    this.companyForm.patchValue({logo: s3Url});
    this.updateCompany('logo');
  }

  updateCompany(UntypedFormControlName: string): void {
    this.updateErrorMessage = null;
    const companyId = this.companyForm.controls['id'].value ? this.companyForm.controls['id'].value : undefined;
    if (companyId) {
      if (this.companyForm.status === 'INVALID') {
        return;
      }
      const companyModel = {};
      if (UntypedFormControlName === 'name') {
        this.companyForm.controls.slug.setValue(this.slugGenerator(this.companyForm.controls.name.value));
        companyModel['slug'] = this.companyForm.controls['slug'].value;
      }
      companyModel[UntypedFormControlName] = this.companyForm.controls[UntypedFormControlName].value;
      this.userService.updateCompany(companyId, companyModel);
      this.userService.myCompany$.subscribe(
        value => {
          if (!value) {
            return;
          }
          value.id = companyId;
          this.companyStreamDashboardUrl = this.streamDashboardUrl + this.companyForm.value.slug;
          this.companyChanged.emit({...this.companyForm.value, ...value});
        },
        err => this.handleCreateUpdateErrors(err, companyModel as CompanyModel)
      );
    } else {
      if (UntypedFormControlName === 'name') {
        this.companyForm.controls.slug.setValue(this.slugGenerator(this.companyForm.controls.name.value));
      }
      if (this.companyForm.status === 'INVALID') {
        return;
      }
      const companyModel: CompanyModel = {
        id: '',
        owner: this.userIdentityId || '',
        name: this.companyForm.controls.name.value || '',
        logo: this.companyForm.controls.logo.value,
        website: this.companyForm.controls.website.value || '',
        industry: this.companyForm.controls.industry.value || '',
        createdAt: undefined,
        updatedAt: undefined,
        slug: this.companyForm.controls.slug.value || '',
        isPublicWatchPageEnabled: !(this.companyForm.controls.isPublicWatchPageEnabled.value === false)
      };

      delete companyModel['id'];
      delete companyModel['updatedAt'];
      delete companyModel['createdAt'];

      this.userService.createCompany(companyModel);
      this.userService.myCompany$.subscribe(
        (company: CompanyModel) => {
          if (!company) {
            return;
          }
          this.companyChanged.emit({...this.companyForm.value, ...company});
        },
        e => this.handleCreateUpdateErrors(e, companyModel)
      );
    }
  }

  slugGenerator(companyName: string): string {
    return companyName
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '') // Remove all spaces
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  getDashboardUrl(): string {
    return this.companyStreamDashboardUrl;
  }

  private handleCreateUpdateErrors(err: any, companyModel: CompanyModel): void {
    const returnedError = err.message ? err.message : err.toString();
    if (returnedError.includes('slug already exists')) {
      this.updateErrorMessage = `Dashboard name ${companyModel.slug} is already taken`;
      this.companyForm.get('slug').setErrors(['exists']);
    }
  }
}

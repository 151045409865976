<div class="container" fxLayout.gt-sm="row" fxLayout.lt-md="column-reverse">
  <div *ngIf="!isFlightMapFullScreen" fxFlex="100%" class="flight-info">
    <ng-container *ngIf="!isMobileView">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <mat-divider></mat-divider>
    </ng-container>
    <mat-tab-group *ngIf="!(isLoading$ | async)">
      <mat-tab
        *ngIf="flightInfo$ | async as flightInfo"
        label="{{ 'flights.flightlogs.details.flight' | translate | uppercase }}"
      >
        <div class="flight-info__data" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="15px">
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.flightTime' | translate }}
            </div>
            <div class="info__content">
              {{ flightInfo.totalTime || 0 | uDuration: 'seconds' }}
            </div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.maxHeight' | translate }}
            </div>
            <div class="info__content">{{ flightInfo.maxHeight || 0 | number }} m</div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.maxHorizontalSpeed' | translate }}
            </div>
            <div class="info__content">{{ flightInfo.maxHSpeed || 0 | number }} m/s</div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.maxVerticalSpeed' | translate }}
            </div>
            <div class="info__content">{{ flightInfo.maxVSpeed || 0 | number }} m/s</div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.device' | translate }}
            </div>
            <div class="info__content">
              {{ flightInfo.appType || ('common.unknown' | translate) }}
            </div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.version' | translate }}
            </div>
            <div class="info__content">
              {{ flightInfo.appVersion || ('common.unknown' | translate) }}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        *ngIf="flightInfo$ | async as flightInfo"
        label="{{ 'flights.flightlogs.device' | translate | uppercase }}"
      >
        <div class="flight-info__data" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="15px">
          <div class="info">
            <div class="info__title">{{ 'common.name' | translate }}</div>
            <div class="info__content">
              {{ flightInfo.aircraftName || ('common.unknown' | translate) }}
            </div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.model' | translate }}
            </div>
            <div class="info__content">
              {{ flightInfo.droneType || ('common.unknown' | translate) }}
            </div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.distance' | translate }}
            </div>
            <div class="info__content">{{ flightInfo.totalDistance || 0 | number: '1.2-2' }} m</div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.description' | translate }}
            </div>
            <div class="info__content">
              {{ (device$ | async).description || ('common.unknown' | translate) }}
            </div>
          </div>
          <div class="info">
            <div class="info__title">
              {{ 'flights.flightlogs.details.owner' | translate }}
            </div>
            <div class="info__content">
              {{ (device$ | async).owner || ('common.unknown' | translate) }}
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div fxFlex="100%" fxLayout="column" class="flight-map">
    <ng-container *ngIf="isMobileView">
      <ng-container fxFlex="100%" *ngTemplateOutlet="headerTemplate"></ng-container>
    </ng-container>
    <ua-flight-map
      fxFlex="100%"
      (click)="isFlightMapFullScreen ? null : makeFlightMapFullScreen()"
      (closeButton)="handleCloseButton()"
      (backButton)="changeFlightLogDetailMode(flightLogDetailMode.NORMAL)"
      [isBackButtonVisible]="isFlightMapFullScreen"
      [isSliderVisible]="isSliderVisible"
      [isZoomControlVisible]="false"
      [isCloseButtonVisible]="!isMobileView"
      [flightFrames]="flightFrames$ | async"
    >
    </ua-flight-map>
  </div>
</div>

<ng-template #headerTemplate>
  <span class="container__device" *ngIf="device$ | async as device" fxLayoutAlign="start center">
    <div *ngIf="isMobileView" class="close" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
    <div class="device">
      <img
        class="img"
        *ngIf="device.logo"
        [src]="device.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo"
        alt="logo"
      />
      <mat-icon class="icon" *ngIf="!device.logo">live_tv</mat-icon>
    </div>
    <span class="device__description" fxLayout="column">
      <span class="device__description--bold">{{ device.name }}</span>
      <span class="device__sub-description--gray" *ngIf="flightDate$ | async as flightDate"
        >{{ data.flightDate | date: 'd MMM, h:mm' }}{{ data.flightDate | date: 'aa' | lowercase }}</span
      >
    </span>
  </span>
</ng-template>

<div
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign="space-between start"
  fxLayoutAlign.lt-sm="start"
  class="container"
>
  <form [formGroup]="form" fxFlex="100%" fxFlex.lt-sm="100%">
    <div class="option">
      <mat-form-field class="form-field-name">
        <input
          type="text"
          matInput
          (change)="update('name')"
          formControlName="name"
          placeholder="{{ 'profile.organizationName' | translate }}"
          required
          [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
        />
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{ 'profile.organizationName' | translate }} {{ 'common.errors.isRequired' | translate }}
        </mat-error>
        <mat-error *ngIf="form.get('name').hasError('maxlength')">
          {{ 'profile.organizationName' | translate }} {{ 'common.errors.tooLong' | translate }}
        </mat-error>
        <mat-error *ngIf="isOnboarding && form.get('name').hasError('exists')">
          {{ updateErrorMessage }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!isOnboarding" class="option">
      <mat-form-field class="form-field-slug">
        <input
          type="text"
          matInput
          (change)="update('slug')"
          formControlName="slug"
          placeholder="{{ 'profile.livestreamDashboardName' | translate }}"
          required
          [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
        />
        <mat-error *ngIf="form.get('slug').hasError('required')">
          {{ 'profile.livestreamDashboardName' | translate }} {{ 'common.errors.isRequired' | translate }}
        </mat-error>
        <mat-error *ngIf="updateErrorMessage" [innerHTML]="updateErrorMessage"> </mat-error>
      </mat-form-field>
    </div>
    <div class="option">
      <mat-form-field class="form-field-website">
        <input
          type="text"
          matInput
          (change)="update('website')"
          formControlName="website"
          placeholder="{{ 'common.website' | translate }}"
          [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
        />
        <mat-error *ngIf="form.get('website').hasError('pattern')">
          {{ 'profile.invalidURL' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="option">
      <mat-form-field class="form-field-industry">
        <mat-select
          placeholder="{{ 'common.industry' | translate }}"
          formControlName="industry"
          (selectionChange)="update('industry')"
          [uaDisabledFormControl]="aclPermissions.OrganizationApiUpdateCompany"
        >
          <mat-option *ngFor="let industry of industryNames" name="industry" [value]="industry">
            {{ industry }}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option value="other">
            {{ 'common.other' | translate }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('industry').hasError('required')">
          {{ 'profile.chooseIndustry' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="option">
      <mat-form-field class="form-field-name">
        <mat-select
          placeholder="{{ 'profile.dateFormat' | translate }}"
          (selectionChange)="update('dateFormat')"
          formControlName="dateFormat"
        >
          <mat-select-trigger>
            {{ currentDate | strftimeToDateFormatPipe: dateFormatInfo[form.controls.dateFormat.value]?.value }}
          </mat-select-trigger>

          <mat-option *ngFor="let dateFormat of dateFormatOptions" [value]="dateFormat.value">
            <div class="date-format">{{ currentDate | strftimeToDateFormatPipe: dateFormat.value }}</div>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('dateFormat').hasError('required')">
          {{ 'profile.dateFormatRequired' | translate }}</mat-error
        >
      </mat-form-field>
    </div>
    <div class="option">
      <mat-form-field class="form-field-name">
        <mat-select
          placeholder="{{ 'profile.timezone' | translate }}"
          (selectionChange)="update('timezone')"
          formControlName="timezone"
        >
          <mat-option *ngFor="let timezone of timezones" [value]="timezone">
            {{ timezone }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('timezone').hasError('required')">
          {{ 'profile.timezoneRequired' | translate }}</mat-error
        >
      </mat-form-field>
    </div>
    <button
      *ngIf="isOnboarding"
      [appLadda]="organisationCreating$ | async"
      class="continue-button"
      (click)="sendFormGroup()"
      type="submit"
      mat-raised-button
      color="primary"
    >
      {{ 'common.continue' | translate }} <mat-icon> keyboard_arrow_right</mat-icon>
    </button>
  </form>
  <div
    *ngIf="!isOnboarding"
    fxFlex="50%"
    fxFlex.lt-sm="100%"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-sm="start start"
  >
    <div class="picture">
      <div class="picture-title">{{ 'common.logo' | translate }}</div>

      <ng-container *uaHasPermission="aclPermissions.AccountApiUploadImage; else showLogoTemplate">
        <logo-upload
          subfolder="company"
          [parentId]="company?.id"
          [url]="company.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.organisationCompanyLogo"
          [inputBucket]="uploadLogoSource.ACCOUNT"
          (s3Url)="updateCompanyLogo($event)"
        ></logo-upload>
      </ng-container>
      <ng-template #showLogoTemplate>
        <div class="picture__container">
          <img
            class="picture__image"
            [src]="company.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.organisationCompanyLogo"
          />
        </div>
      </ng-template>
    </div>
  </div>
</div>

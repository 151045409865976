import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'unleash-mini-plan-card',
  templateUrl: './mini-plan-card.component.html',
  styleUrls: ['./mini-plan-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniPlanCardComponent implements OnInit {
  @Input() title: string;
  @Input() name: string;
  @Input() img: string;
  @Input() isActive: boolean;

  constructor() {}

  ngOnInit(): void {}
}

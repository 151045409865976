import {Action, createReducer, on} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {
  actionForgotPasswordStepTwoFormError,
  actionForgotPasswordStepTwoFormSubmit,
  actionForgotPasswordStepTwoFormSuccess
} from './forgot-password-step-two.actions';
import {ForgotPasswordStepTwo} from './forgot-password-step-two.model';

export interface ForgotPasswordStepTwoFormState {
  form: ForgotPasswordStepTwo;
  error?: AuthErrorModel;
  inProgress: boolean;
}

export const initialState: ForgotPasswordStepTwoFormState = {
  form: {code: '', password: '', passwordConfirmation: ''},
  inProgress: false
};

const reducer = createReducer(
  initialState,
  on(actionForgotPasswordStepTwoFormSubmit, (state, {form}) => ({
    ...state,
    error: undefined,
    inProgress: true,
    form
  })),
  on(actionForgotPasswordStepTwoFormError, (state, {error}) => ({
    ...state,
    error,
    inProgress: false
  })),
  on(actionForgotPasswordStepTwoFormSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: false
  }))
);

export function forgotPasswordStepTwoStateReducer(
  state: ForgotPasswordStepTwoFormState | undefined,
  action: Action
): ForgotPasswordStepTwoFormState {
  return reducer(state, action);
}

<h2 mat-dialog-title data-test-id="add-users-dialog-title">
  {{ originalAssignedUsers.length === 0 ? ('jobs.addUsers' | translate) : ('jobs.editUsers' | translate) }}
</h2>
<section mat-dialog-content>
  <div class="team" data-test-id="team-name">{{ 'jobs.team' | translate }} {{ data.teamName }}</div>
  <div
    (click)="isLoading ? '' : toggleSelectedUser(user.key)"
    class="user"
    [class.selected]="data.jobAssignedUsers?.includes(user.key)"
    *ngFor="let user of data.teamMembers | keyvalue"
    data-test-id="user"

  >
    <div class="user__info">
      <mat-icon class="user__icon">person</mat-icon>
      {{ user.value.name }}
    </div>
    <mat-icon *ngIf="data.jobAssignedUsers?.includes(user.key)" class="user__check" data-test-id="user-check">check</mat-icon>
  </div>
</section>

<section mat-dialog-actions>
  <button [disabled]="isLoading" mat-button mat-dialog-close color="primary" data-test-id="cancel-button">{{ 'jobs.cancel' | translate }}</button>
  <button [disabled]="isLoading || hasOriginalUserIds()" mat-flat-button color="primary" (click)="addUsers()" data-test-id="add-button">
    {{ 'jobs.submit' | translate }}
    <unleash-spinner *ngIf="isLoading" diameter="20" borderWidth="2.1" color="#111" data-test-id="spinner"></unleash-spinner>
  </button>
</section>

import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {NotificationsStoreEffects} from './notifications.effects';
import {notificationsReducer} from './notifications.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('notifications', notificationsReducer),
    EffectsModule.forFeature([NotificationsStoreEffects])
  ]
})
export class NotificationsModule {}

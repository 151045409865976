import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {TranslateModule} from '@ngx-translate/core';
import {ReplaySubject} from 'rxjs';

@Component({
  selector: 'unleash-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, SpinnerModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDialogComponent {
  public hasToExecute: ReplaySubject<void> = new ReplaySubject();
  public hasToShowSpinner: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {title: string; paragraphs: string[]; actionName: string}
  ) {}

  public executeAction() {
    this.hasToExecute.next();
    this.hasToShowSpinner = true;
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}

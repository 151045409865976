<div class="videogular" data-test-id="live-video-player">
  <div class="videogular__player full-width">
    <div class="buffering" *ngIf="isBuffering">
      <div class="buffering__indicator buffering__indicator--one"></div>
      <div class="buffering__indicator buffering__indicator--two"></div>
      <div class="buffering__indicator buffering__indicator--three"></div>
    </div>
    <vg-player (onPlayerReady)="onPlayerReady($event)">
      <vg-controls #vgControls *ngIf="hasToShowControls && !isBuffering">
        <vg-play-pause
          (click)="changeVideoState()"
          matTooltipClass="custom-video-player-tooltip"
          #play="matTooltip"
          #playPause
          [matTooltip]="playTooltip"
          matTooltipPosition="above"
        ></vg-play-pause>
        <vg-mute
          *ngIf="hasToShowAudioControl"
          matTooltipClass="custom-video-player-tooltip"
          #mute="matTooltip"
          [matTooltip]="soundTooltip"
          matTooltipPosition="above"
        ></vg-mute>
        <div *ngIf="hlsBitrates.length > 0" class="videogular__bitrate-picker">
          <div
            #menuTrigger="matMenuTrigger"
            class="quality"
            [matMenuTriggerFor]="menu"
            mat-button
            matTooltipClass="custom-video-player-tooltip"
            #quality="matTooltip"
            matTooltip="Bitrate"
            matTooltipPosition="above"
            (menuOpened)="setCanCloseControls(false)"
          ></div>
          <mat-menu #menu="matMenu" xPosition="before" yPosition="above" class="videogular__bitrate">
            <div class="radio-button" (click)="$event.stopPropagation()" (mouseleave)="menuTrigger.closeMenu()">
              <label *ngFor="let bitrateItem of hlsBitrates; let i = index">
                <input
                  type="radio"
                  name="options"
                  (input)="selectBitrate(bitrateItem, i)"
                  [checked]="bitrateItem.checked"
                />
                {{ bitrateItem?.label === 'AUTO' ? 'AUTO' : (bitrateItem.bitrate | bitrate) }}
              </label>
            </div>
          </mat-menu>
        </div>
        <vg-fullscreen
          #vgFullScreen
          matTooltipClass="custom-video-player-tooltip"
          #fullscreen="matTooltip"
          [matTooltip]="vgFullScreen.isFullscreen ? 'Exit Full screen' : 'Full screen'"
          matTooltipPosition="above"
        ></vg-fullscreen>
      </vg-controls>
      <video
        #media
        [vgMedia]="media"
        [vgHls]="hlsPlaylistUrl"
        #vgHls="vgHls"
        [id]="device.id"
        (onGetBitrates)="initializePlayerBitrates($event)"
        playsinline
        (mouseleave)="hideControls()"
        (mouseenter)="setCanCloseControls(true)"
      ></video>
    </vg-player>
  </div>
</div>

<canvas id="canvas-player-{{ device.id }}"></canvas>

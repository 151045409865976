<div class="header">
  <div class="header__links">
    <p class="header__text">
      {{'auth.signIn.dontHave' | translate}}
      <a class="header__link" href="https://unleashlive.com/contact">{{'auth.contactUs.contactSales' | translate}}</a>
    </p>
  </div>
</div>
<div class="spacer"></div>
<unleash-alert
  class="unleash-alert"
  *ngIf="hasSignedOut$ | async"
  type="success"
  label="{{'auth.signIn.signedOut' | translate}}"
  iconName="check"
></unleash-alert>
<unleash-alert
  class="unleash-alert form--fixed"
  *ngIf="errorMessage$ | async as errorMessage"
  type="error"
  [label]="errorMessage"
  iconName="close"
  (clicked)="goToPasswordResetPage()"
></unleash-alert>

<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>

<div class="title">
  <div class="title__text">{{'common.signIn' | translate}}</div>
</div>

<unleash-sign-in
  class="form form--fixed"
  [inProgress]="inProgress$ | async"
  (signIn)="signIn($event)"
  (federatedSignIn)="federatedSignIn($event)"
  (forgotPassword)="goToPasswordResetPage()"
></unleash-sign-in>

<div class="spacer"></div>

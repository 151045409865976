import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SignInCodeStatus} from '@app/auth/models/registration-login.types';
import {Auth} from '@aws-amplify/auth';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AuthService} from '@app/auth/services/auth.service';
import {from, of, zip} from 'rxjs';
import {catchError, exhaustMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {LocalStorageService} from '../../../core/services/local-storage/local-storage.service';
import {actionSaveEmail} from '../forgot-password/forgot-password.actions';
import {actionSignUpFormReconfirmationRequired} from '../sign-up/sign-up.actions';
import {
  actionConfirmPhoneMFA,
  actionSetupMFA,
  actionSetupPhoneMFA,
  actionFederatedSignIn,
  actionSignInFormError,
  actionSignInFormSubmit,
  actionSignInFormUpdate,
  actionSignInRequired,
  actionVerificationFormSubmit,
  actionVerifyMfa,
  actionConfirmMfaToken
} from './sign-in.actions';
import {SignInForm} from './sign-in.model';
import {MFA, MFARequiredStates, MFA_SETUP_STATE} from '@app/auth/models/auth.models';

export const FORM_KEY = 'AUTH_FORMS.SIGN_IN_FORM';

@Injectable()
export class SignInEffects {
  public persistForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignInFormUpdate),
        tap(action => {
          this.localStorageService.setItem(FORM_KEY, {
            form: this.sanitizeLoginForm(action.form)
          });
        })
      ),
    {dispatch: false}
  );
  /***
   * Login action is mapped using exhaustMap to avoid resolving multiple Auth operations
   * when previous is still in progress (login button mashing)
   */

  public actionConfirmPhoneMFA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionConfirmPhoneMFA),
        switchMap(action => zip(of(action), from(Auth.currentAuthenticatedUser()))),
        exhaustMap(([{code}, cognitoUser]) =>
          from(Auth.verifyCurrentUserAttributeSubmit('phone_number', code)).pipe(
            switchMap(() => {
              const authPromises = [
                Auth.setPreferredMFA(cognitoUser, 'SMS_MFA'),
                Auth.updateUserAttributes(cognitoUser, {
                  'custom:mfaRequired': MFARequiredStates.SMS
                })
              ];
              Promise.all(authPromises).catch(error => console.error('Auth error:', error));
              this.localStorageService.setItem('MFASetupState', null);
              this.authService.finalizeLogInBasedOnCurrentSession();
              return this.authService.identityIdSet$;
            }),
            tap(() => {
              this.authService.updateUser({phone: cognitoUser.attributes.phone_number});
            }),
            catchError(error => {
              this.authService.offAuthEvents();
              this.authService.setIsVerificationCodeValid(false);
              return of(error);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public actionSetupPhoneMFA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSetupPhoneMFA),
        switchMap(action => zip(of(action), from(Auth.currentAuthenticatedUser()))),
        exhaustMap(([{phoneNumber}, cognitoUser]) => {
          return from(
            Auth.updateUserAttributes(cognitoUser, {
              // eslint-disable-next-line camelcase
              phone_number: phoneNumber
            })
          ).pipe(
            switchMap(() => from(Auth.verifyCurrentUserAttribute('phone_number'))),
            tap(() => {
              this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.CONFIRM_MOBILE_NUMBER);
              this.router.navigate(['auth/confirm-mobile-number']);
            }),
            catchError(error => {
              this.authService.offAuthEvents();
              this.authService.setIsPhoneValid(false);
              return of(error);
            })
          );
        })
      ),
    {dispatch: false}
  );

  public actionVerifyMfa$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionVerifyMfa),
        exhaustMap(({code}) =>
          from(Auth.confirmSignIn(this.authService.cognitoUser, code, 'SOFTWARE_TOKEN_MFA')).pipe(
            tap(() => {
              this.localStorageService.setItem('MFASetupState', null);
              this.authService.finalizeLogInBasedOnCurrentSession();
            }),
            catchError(error => {
              this.authService.offAuthEvents();
              this.authService.setIsTotpCodeValid(false);
              return of(error);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public actionConfirmMfaToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionConfirmMfaToken),
        switchMap(action => zip(of(action), from(Auth.currentAuthenticatedUser()))),
        exhaustMap(([{code}, cognitoUser]) =>
          from(Auth.verifyTotpToken(cognitoUser, code)).pipe(
            tap(() => {
              const authPromises = [
                Auth.setPreferredMFA(cognitoUser, 'TOTP'),
                Auth.updateUserAttributes(cognitoUser, {
                  'custom:mfaRequired': MFARequiredStates.TOTP
                })
              ];
              Promise.all(authPromises).catch(error => console.error('Auth error:', error));
              this.authService.finalizeLogInBasedOnCurrentSession();
              this.localStorageService.setItem('MFASetupState', null);
            }),
            catchError(error => {
              this.authService.offAuthEvents();
              this.authService.setIsTotpCodeValid(false);
              return of(error);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public actionSetupMFA$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSetupMFA),
        switchMap(action => zip(of(action), from(Auth.currentAuthenticatedUser()))),
        exhaustMap(([{mfaType}, cognitoUser]) => {
          if (mfaType === MFA.TOTP) {
            return from(Auth.setupTOTP(cognitoUser)).pipe(
              tap(token => {
                this.authService.setTotpToken(token);
                this.router.navigate(['auth/confirm-totp-token']);
                this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.CONFIRM_TOTP);
              }),
              catchError(error => {
                this.authService.offAuthEvents();
                this.authService.setHasSetupTotpError(true);
                return of(error);
              })
            );
          }
          this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.SETUP_SMS);
          return this.router.navigate(['auth/setup-mobile-number']);
        })
      ),
    {dispatch: false}
  );

  public actionVerificationFormSubmit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionVerificationFormSubmit),
        exhaustMap(({code}) =>
          from(Auth.confirmSignIn(this.authService.cognitoUser, code)).pipe(
            tap(() => {
              this.localStorageService.setItem('MFASetupState', null);
            }),
            catchError(error => {
              this.authService.offAuthEvents();
              this.authService.setIsVerificationCodeValid(false);
              return of(error);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public performLoginAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignInFormSubmit),
        tap(() => this.authService.listenAuthEvents()),
        exhaustMap(({form, isDeveloperMode}) =>
          from(
            Auth.signIn({
              username: form.email.toLowerCase(),
              password: form.password
            })
          ).pipe(
            tap(cognitoUser => {
              this.authService.setCognitoUser(cognitoUser);
              if (cognitoUser?.challengeName === 'SMS_MFA') {
                console.info('The user is required to provide an SMS MFA code');
                this.router.navigate(['auth/sms-verification']);
                this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.VERIFY_SMS);
                return;
              }
              if (cognitoUser?.challengeName === 'SOFTWARE_TOKEN_MFA') {
                console.info('The user is required to provide an TOTP MFA code');
                this.router.navigate(['auth/totp-verification']);
                this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.VERIFY_TOTP);
                return;
              }
              if (cognitoUser?.attributes['custom:mfaRequired'] === MFARequiredStates.NEEDS_SETUP) {
                this.router.navigate(['auth/setup-mfa']);
                this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.SETUP);
                return;
              }
              this.localStorageService.setItem('MFASetupState', null);
            }),
            catchError(error => {
              this.authService.offAuthEvents();
              this.store.dispatch(
                actionSignInFormError({
                  form,
                  error,
                  isDeveloperMode
                })
              );
              return of(error);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public performFederatedLoginAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionFederatedSignIn),
        tap(() => this.authService.listenAuthEvents()),
        exhaustMap(({provider}) =>
          from(Auth.federatedSignIn({provider} as any)).pipe(
            catchError(error => {
              this.authService.offAuthEvents();
              console.error(error);
              return of(error);
            })
          )
        )
      ),
    {dispatch: false}
  );

  public onLoginRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionSignInRequired),
        tap(action => {
          this.router.navigate(['auth/sign-in']);
        })
      ),
    {dispatch: false}
  );

  public onLoginFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSignInFormError),
      tap(payload => {
        if (payload.error && payload.error.code === SignInCodeStatus.NOT_AUTHORIZED) {
          this.store.dispatch(actionSaveEmail({payload: {email: payload.form?.email}}));
        }
      }),
      filter(({form, error, isDeveloperMode}): boolean => error && error.code === 'UserNotConfirmedException'),
      map(({form, isDeveloperMode}) => {
        return actionSignUpFormReconfirmationRequired({
          form: {email: form.email},
          isDeveloperMode
        });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private store: Store,
    private authService: AuthService
  ) {}

  private sanitizeLoginForm(form: SignInForm) {
    return (({password, ...rest}) => rest)(form);
  }
}

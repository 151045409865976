import {createAction, props} from '@ngrx/store';
import {Team, TeamRole} from '../../models/team.model';
import {UserTable, UserTeam} from '@app/profile/models/users-table.model';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';

export enum TeamManagementActionTypes {
  SetTeams = '[Teams Management] Set Teams',
  SetTeamsSuccess = '[Teams Management] Set teams success',

  AddTeam = '[Teams Management] Add team',
  AddTeamSuccess = '[Teams Management] Add team success',

  UpdateTeamCounterAfterUserDelete = '[Teams Management] Update team counter after user delete',

  SetSelectedTeamId = '[Teams Management] Set selected team id',
  RemoveSelectedTeamId = '[Teams Management] Remove selected team id',

  EditTeam = '[Teams Management] Edit team',
  EditTeamSuccess = '[Teams Management] Edit team success',

  DeleteTeam = '[Teams Management] Delete team',
  DeleteTeamSuccess = '[Teams Management] Delete team success',

  OpenAddUsersToTeam = '[Teams Management] Open add users to team',

  AddUsersToTeam = '[Teams Management] Add users to team',
  AddUsersToTeamSuccess = '[Teams Management] Add users to team success',

  RemoveUsersFromTeam = '[Teams Management] Remove users from team',
  RemoveUsersFromTeamSuccess = '[Teams Management] Remove users from team success',

  SaveDashboard = '[Dashboard] Save dashboard',

  InviteUsersSuccess = '[Teams Management] Invite users success'
}

export const setTeams = createAction(TeamManagementActionTypes.SetTeams, props<{payload: {teams: Team[]}}>());
export const setTeamsSuccess = createAction(TeamManagementActionTypes.SetTeamsSuccess);

export const addTeam = createAction(TeamManagementActionTypes.AddTeam, props<{payload: {name: Team['name']}}>());
export const addTeamSuccess = createAction(TeamManagementActionTypes.AddTeamSuccess, props<{payload: {team: Team}}>());

export const deleteTeam = createAction(TeamManagementActionTypes.DeleteTeam, props<{payload: {teamId: string}}>());
export const deleteTeamSuccess = createAction(
  TeamManagementActionTypes.DeleteTeamSuccess,
  props<{
    payload: {teamId: string};
  }>()
);

export const setSelectedTeamId = createAction(
  TeamManagementActionTypes.SetSelectedTeamId,
  props<{payload: {teamId: Team['id']}}>()
);
export const removeSelectedTeamId = createAction(TeamManagementActionTypes.RemoveSelectedTeamId);

export const updateTeamCounterAfterUserDelete = createAction(
  TeamManagementActionTypes.UpdateTeamCounterAfterUserDelete,
  props<{payload: {teamId: Team['id']}}>()
);

export const editTeam = createAction(TeamManagementActionTypes.EditTeam, props<{payload: {id: Team['id']}}>());
export const editTeamSuccess = createAction(
  TeamManagementActionTypes.EditTeamSuccess,
  props<{payload: {teamId: Team['id']; name: string; updatedAt: number}}>()
);

export const openAddUsersToTeam = createAction(TeamManagementActionTypes.OpenAddUsersToTeam);

export const addUsersToTeam = createAction(
  TeamManagementActionTypes.AddUsersToTeam,
  props<{payload: {users: UserTable[]; action: UpdateUserTeamAction}}>()
);

export const addUsersToTeamSuccess = createAction(
  TeamManagementActionTypes.AddUsersToTeamSuccess,
  props<{
    payload: {users: {userId: string; role: TeamRole; teamId: string}[]; teamId: string; action: UpdateUserTeamAction};
  }>()
);

export const removeUsersToTeam = createAction(
  TeamManagementActionTypes.RemoveUsersFromTeam,
  props<{payload: {users: UserTable[]; action: UpdateUserTeamAction}}>()
);

export const removeUsersToTeamSuccess = createAction(
  TeamManagementActionTypes.RemoveUsersFromTeamSuccess,
  props<{payload: {teamId: string; users: {userId: string}[]}}>()
);

export const saveDashboard = createAction(TeamManagementActionTypes.SaveDashboard, props<{payload: {data: any}}>());

export const inviteUsersSuccess = createAction(
  TeamManagementActionTypes.InviteUsersSuccess,
  props<{payload: UserTeam[]}>()
);

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AtlasAnnotationsSettigsService {
  private hasToOpenAnnotationsOptions = new BehaviorSubject<boolean>(false);
  public hasToOpenAnnotationsOptions$: Observable<boolean> = this.hasToOpenAnnotationsOptions.asObservable();

  public openAnnotationsOptions(): void {
    this.hasToOpenAnnotationsOptions.next(true);
  }

  public closeAnnotationsOptions(): void {
    this.hasToOpenAnnotationsOptions.next(false);
  }

  public toggleAnnotationsOptions(): void {
    this.hasToOpenAnnotationsOptions.value ? this.closeAnnotationsOptions() : this.openAnnotationsOptions();
  }
}

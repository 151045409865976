import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'stream-limit-dialog',
  templateUrl: './stream-limit.component.html',
  styleUrls: ['./stream-limit.component.scss']
})
export class StreamLimitDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
}

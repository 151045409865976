import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Addon} from '@app/store/addon/models/addon';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'unleash-rename-ai-app-dialog',
  templateUrl: './rename-ai-app-dialog.component.html',
  styleUrls: ['./rename-ai-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameAiAppDialogComponent {
  form = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RenameAiAppDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private addon: Pick<Addon, 'id' | 'name'>
  ) {
    this.form.controls.name.setValue(addon.name);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.form.controls.name.invalid) {
      this.form.controls.name.markAsTouched();
      return;
    }

    this.dialogRef.close({...this.form.value, id: this.addon.id});
  }
}

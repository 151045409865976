<button
  class="square-button"
  mat-flat-button
  color="basic"
  (click)="emitToggleMap()"
  matTooltip="{{ 'atlas.toggle.' + mapViewMapper[mapView].translation | translate }}"
  matTooltipPosition="right"
  [analyticsTrackerType]="events.ATLAS_CONTROLS"
  [analyticsProperties]="mapViewMapper[mapView].analyticsType"
>
  <ng-container [ngSwitch]="mapView">
    <mat-icon *ngSwitchCase="MAP_VIEW.SATELLITE">satellite_alt</mat-icon>
    <mat-icon *ngSwitchCase="MAP_VIEW.HYBRID">map</mat-icon>
    <mat-icon *ngSwitchCase="MAP_VIEW.ROADMAP">terrain</mat-icon>
  </ng-container>
</button>
<button
  *ngIf="!isTaskControl"
  matTooltip="{{
    isLayersControlSideBarOpen ? ('atlas.map.hideLayers' | translate) : ('atlas.map.showLayers' | translate)
  }}"
  matTooltipPosition="right"
  mat-raised-button
  (click)="emitToggleLayerControlSideBar()"
  color="white"
  class="mat-elevation-z8 square-button"
>
  <mat-icon *ngIf="!isLayersControlSideBarOpen; else clearLayersTemplate">layers</mat-icon>
  <ng-template #clearLayersTemplate>
    <mat-icon> layers_clear</mat-icon>
  </ng-template>
</button>
<button
  class="square-button"
  mat-flat-button
  color="basic"
  *ngIf="initializedWithWeatherMap"
  (click)="emitToggleWeather()"
  matTooltip="{{ (isWeatherMapDisplayed ? 'atlas.toggle.weather.hide' : 'atlas.toggle.weather.show') | translate }}"
  matTooltipPosition="right"
  [analyticsTrackerType]="events.ATLAS_CONTROLS"
  [analyticsProperties]="isWeatherMapDisplayed ? {type: 'Hide Weather Map'} : {type: 'Show Weather Map'}"
>
  <mat-icon *ngIf="!isWeatherMapDisplayed">umbrella</mat-icon>
  <mat-icon *ngIf="isWeatherMapDisplayed">wb_sunny</mat-icon>
</button>
<button
  *ngIf="!isTaskControl"
  class="square-button"
  mat-flat-button
  color="basic"
  [disabled]="zoomMoreFlag"
  (click)="emitToggleClusters()"
  matTooltip="{{ zoomMoreFlag ? ('atlas.toggle.clustersPlsZoom' | translate) : ('atlas.toggle.clusters' | translate) }}"
  matTooltipPosition="right"
  [analyticsTrackerType]="events.ATLAS_CONTROLS"
  [analyticsProperties]="{type: 'toggle clusters'}"
>
  <mat-icon>bubble_chart</mat-icon>
</button>

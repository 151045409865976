import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserModel} from '@app/core/models/api/user-model';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {PLAN_TRIAL} from '@app/plans/models/plan-trial.model';
import {differenceInMinutes} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ExpiryService {
  constructor(private router: Router, private browserSettingsService: BrowserSettingsService) {}

  static setExpirySeen(flag: boolean) {
    window.localStorage['expiry-seen'] = flag;
  }

  static isExpirySeen() {
    return window.localStorage['expiry-seen'] === 'true';
  }

  /***
   * Check if trial expired or soon to be expired.
   * If expired or soon to be expired, then redirect to a route
   * If it's ongoing or plan is active, then call the callback which will pass the execution further
   ***/
  hasTrialConstraints(user: UserModel): boolean {
    // Let's check first if user is on the UNLEASH_TRIAL CC bypass
    const minutesTillTrialEnd = this.getMinutesBetween(user.trialPeriodEnd, new Date().getTime());
    if (
      !!user.currentPlan &&
      minutesTillTrialEnd <= 0 &&
      user.stripeSubscription === PLAN_TRIAL.UNLEASH_TRIAL_SUBSCRIPTION
    ) {
      this.redirectToPlanTrialExpiredPage();
      return true;
    }

    if (!user.currentPlan && !user.trialPeriodEnd) {
      // user is freshly registered and has no plan or trial
      // reset local storage flag
      this.browserSettingsService.setOnboardingFinished(false);
      return false;
    }

    // if we are past this slot we are good to assume that onboarding was finished
    // so let's not block welcome page anymore
    this.browserSettingsService.setOnboardingFinished(true);

    if (!user.currentPlan && !!user.trialPeriodEnd) {
      // user failed to pay for his plan and it was removed or old user
      this.redirectToPlanNotPaidPage();
      return true;
    }

    const trialPeriodInMinutes = PLAN_TRIAL.TRIAL_PERIOD_DAYS * 24 * 60;
    if (minutesTillTrialEnd > 0 && minutesTillTrialEnd <= trialPeriodInMinutes) {
      if (ExpiryService.isExpirySeen()) {
        return false;
      }
      ExpiryService.setExpirySeen(true);
      return true;
    }

    if (!!user.currentPlan) {
      // user has a plan
      return false;
    }
  }

  getMinutesBetween(trialEndTimestamp: number, nowTimestamp: number) {
    let date1 = new Date(trialEndTimestamp);
    let date2 = new Date(nowTimestamp);

    date1 = new Date(
      Date.UTC(
        date1.getUTCFullYear(),
        date1.getUTCMonth(),
        date1.getUTCDate(),
        date1.getUTCHours(),
        date1.getUTCMinutes(),
        date1.getUTCSeconds(),
        date1.getUTCMilliseconds()
      )
    );
    date2 = new Date(
      Date.UTC(
        date2.getUTCFullYear(),
        date2.getUTCMonth(),
        date2.getUTCDate(),
        date2.getUTCHours(),
        date2.getUTCMinutes(),
        date2.getUTCSeconds(),
        date2.getUTCMilliseconds()
      )
    );

    return differenceInMinutes(date1, date2);
  }

  private redirectToPlanTrialExpiredPage() {
    return this.router.navigate(['plans/trial/expired']);
  }

  private redirectToPlanNotPaidPage() {
    return this.router.navigate(['plans/unpaid']);
  }
}

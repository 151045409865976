<div class="container">
  <section class="header">
    <mat-icon (click)="emitGoBack()" class="header__back">arrow_back</mat-icon>
    <div class="header__info">
      <span class="header__title">{{ 'atlas.search.searchResults' | translate }}</span>
      <span class="header__description">
        {{ 'atlas.search.searchInfo' | translate: {query: searchFilterQuery, layer: asset?.name} }}
      </span>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section class="content">
    <cdk-virtual-scroll-viewport autosize class="scroll-viewport">
      <div
        [class.highlight]="property.key === selectedKey"
        (click)="emitSelectSearchedLayer(property.value?.leafletLayer)"
        (mouseenter)="setSelectedKey(property.key)"
        (mouseleave)="setSelectedKey(null)"
        *cdkVirtualFor="let property of asset?.searchResult?.properties | keyvalue"
        class="content__item"
      >
        <section>
          <span class="content__property-title">
            {{ property.value.type === 'Point' ? 'Marker' : property.value.type }}
          </span>
          <ng-container *ngFor="let layerProperty of property.value | keyvalue">
            <div class="content__properties" *ngIf="!propertiesToIgnore.includes(layerProperty.key)">
              <mat-icon class="content__property-nested" svgIcon="nested-layer"></mat-icon>
              <div class="content__property-value">
                <span class="content__property-subtitle">{{ layerProperty.key }}</span>
                <span
                  class="content__property-result"
                  [innerHTML]="
                    sanitizer.bypassSecurityTrustHtml(layerProperty.value | highlightText: searchFilterQuery)
                  "
                ></span>
              </div>
            </div>
          </ng-container>
        </section>
        <mat-icon class="content__arrow">keyboard_arrow_right</mat-icon>
      </div>
    </cdk-virtual-scroll-viewport>
  </section>
</div>

import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Mission, MissionSettingsService} from '../services/mission-settings.service';
import {MissionApiService} from '../services/mission-api.service';
import {take, tap} from 'rxjs';
import {MissionManagerService} from '../services/mission-manager.service';
import {MissionType} from '../model/mission.model';
import {cloneDeep} from 'lodash';

@Directive({
  selector: '[ulCompleteRoute]'
})
export class CompleteRouteDirective {
  @Input('ulCompleteRoute') public mission: Mission;
  @Output() public routeSaved: EventEmitter<void> = new EventEmitter();
  constructor(
    private missionApiService: MissionApiService,
    private missionManagerService: MissionManagerService,
    private missionSettingsService: MissionSettingsService
  ) {}
  @HostListener('click', ['$event'])
  public onCompleteRoute() {
    delete (this.mission as any).isNewMission;
    this.missionApiService
      .createMission({
        ...this.mission,
        type: MissionType.WAYPOINT
      })
      .pipe(
        take(1),
        tap((createdMission: Mission) => {
          const missionWithTimeAndDistance = this.missionManagerService.calculateTimeAndDistance(createdMission);
          const newMission = {...createdMission, ...missionWithTimeAndDistance};
          this.missionManagerService.addNewMission(newMission);
          this.missionSettingsService.setMission(newMission);
          this.missionSettingsService.editSave();
          this.routeSaved.emit();
          this.missionManagerService.updateMissionUi(newMission);
          this.missionSettingsService.undoStack.next([cloneDeep(newMission)]);
        })
      )
      .subscribe();
  }
}

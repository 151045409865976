import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MissionType} from '@app/atlas/model/mission.model';
import {MissionApiService} from '@app/atlas/services/mission-api.service';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';
import {Mission, MissionRoutePoint, MissionSettingsService} from '@app/atlas/services/mission-settings.service';
import {UploadMissionsService} from '@app/atlas/services/upload-missions.service';
import {NotificationIcon, NotificationLevel} from '@app/core/models/api/notifications.model';
import {UserService} from '@app/core/services/api/user.service';
import {NotificationStoreFacadeService} from '@app/core/services/notifications/notification-store-facade.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {TaskType} from '@app/jobs/models/jobs.models';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {JobsFacadeService} from '@app/jobs/services/jobs-facade.service';
import {TeamRole} from '@app/profile/models/team.model';
import {TranslateService} from '@ngx-translate/core';
import {NgxFileDropEntry} from 'ngx-file-drop';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs';

@Component({
  selector: 'unleash-upload-missions',
  templateUrl: './upload-missions.component.html',
  styleUrls: ['./upload-missions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadMissionsComponent implements OnDestroy {
  private isUploadingMissions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUploadingMissions$: Observable<boolean> = this.isUploadingMissions.asObservable().pipe(shareReplay(1));
  public totalFilesToUpload: BehaviorSubject<any[]> = this.uploadMissionsService.missionsToUpload;
  public totalKmzFilesToUpload: BehaviorSubject<any[]> = this.uploadMissionsService.kmzMissionsToUpload;
  public totalFiles$ = combineLatest([this.totalFilesToUpload, this.totalKmzFilesToUpload]).pipe(
    map(([files, kmzFiles]) => [...files, ...kmzFiles])
  );
  public acceptedFiles = [
    'text/csv',
    'application/json',
    'application/vnd.google-earth.kml+xml',
    'application/vnd.google-earth.kmz'
  ];
  public isDropContainerHovered: boolean = false;
  private queuedMission: Promise<void>[] = [];
  public csvRegex = /\.csv$/;
  public form = this.fb.group({
    takePhotoEachWaypoint: [false],
    setCustomSpeed: [false],
    customSpeed: [7, [Validators.required, Validators.min(0), Validators.max(1000)]]
  });
  public addMissionToJobsForm = this.fb.group({
    hasToAddMissionToJob: [false],
    selectedJob: [null, [Validators.required]]
  });

  private hasCsvFiles = new BehaviorSubject<boolean>(false);
  public hasCsvFiles$: Observable<boolean> = this.hasCsvFiles.asObservable();

  private hasMixedFiles = new BehaviorSubject<boolean>(false);
  public hasMixedFiles$: Observable<boolean> = this.hasMixedFiles.asObservable();

  public jobsInProgress$ = this.jobsFacadeService.inProgressJobs$.pipe(shareReplay(1));
  public draftJobs$ = this.jobsFacadeService.draftJobs$.pipe(shareReplay(1));

  public hasJobs$ = combineLatest([this.jobsInProgress$, this.draftJobs$]).pipe(
    map(([jobsInProgress, draftJobs]) => {
      return jobsInProgress.length > 0 || draftJobs.length > 0;
    })
  );
  public isLoadingJobs$ = this.jobsFacadeService.isLoadingJobs$.pipe(shareReplay(1));
  public isAdmin$ = this.userStoreFacadeService.currentRole$.pipe(
    filter(role => !!role),
    map(role => role === TeamRole.admin),
    shareReplay(1)
  );

  constructor(
    private dialogRef: MatDialogRef<UploadMissionsComponent>,
    private missionApiService: MissionApiService,
    private missionManagerService: MissionManagerService,
    private uploadMissionsService: UploadMissionsService,
    private missionSettingsService: MissionSettingsService,
    private fb: FormBuilder,
    private unleashAnalytics: UnleashAnalyticsService,
    private snackBar: MatSnackBar,
    private jobsFacadeService: JobsFacadeService,
    private jobsApiService: JobsApiService,
    private userService: UserService,
    private notificationStoreFacadeService: NotificationStoreFacadeService,
    private translateService: TranslateService,
    private userStoreFacadeService: UserStoreFacadeService
  ) {
    this.jobsFacadeService.listJobs();
  }

  public ngOnDestroy(): void {
    this.uploadMissionsService.resetMissionsToUpload();
  }

  public dropped(event: NgxFileDropEntry[]) {
    this.isDropContainerHovered = false;
    const droppedFiles = event
      .map(droppedFile => (droppedFile.fileEntry as FileSystemFileEntry).file(file => file))
      .filter(file => this.validFiles(file as any as File));
    this.checkIfContainsCsvFiles(droppedFiles as unknown as File[]);

    this.uploadMissionsService.importMission(droppedFiles);
  }

  public selectedFiles(event: InputEvent) {
    const files = Object.values((event.target as any).files);
    const selectedFiles: File[] = files.filter((file: File) => this.validFiles(file)) as File[];
    this.checkIfContainsCsvFiles(selectedFiles);

    this.uploadMissionsService.importMission(selectedFiles);
  }

  public validFiles(file: File) {
    const kmlRegex = /\.kml$/;
    const kmzRegex = /\.kmz$/;
    return this.acceptedFiles.includes(file.type) || kmlRegex.test(file.name) || kmzRegex.test(file.name);
  }

  public async uploadFiles(): Promise<void> {
    if (this.form.controls.setCustomSpeed) {
      if (this.form.invalid) {
        this.form.controls.customSpeed.markAsTouched();
        return;
      }
    }

    if (this.addMissionToJobsForm.controls.hasToAddMissionToJob.value) {
      if (this.addMissionToJobsForm.invalid) {
        this.addMissionToJobsForm.controls.selectedJob.markAsTouched();
        return;
      }
    }

    if (!this.totalFilesToUpload.value.length && !this.totalKmzFilesToUpload.value.length) {
      return;
    }
    this.isUploadingMissions.next(true);
    const missionsToUpload = this.totalFilesToUpload.value;
    const kmzMissionsToUpload = this.totalKmzFilesToUpload.value;
    kmzMissionsToUpload.forEach(kmzMissionToUpload => {
      this.queuedMission.push(lastValueFrom(this.uploadKmz(kmzMissionToUpload.result)));
    });
    missionsToUpload.forEach((mission, index) => {
      const missionToUpload = mission.result;
      const isLastIndex = index === missionsToUpload.length - 1;
      if (missionToUpload.isCorridorMission) {
        const postMethod$ = this.createCorridorMission(missionToUpload, isLastIndex);
        this.queuedMission.push(firstValueFrom(postMethod$));
        return;
      }
      missionToUpload.route = this.fillWaypointsWithDefaultSettings(missionToUpload);
      const hasToCreateNewMission =
        (missionToUpload?.isImported && !missionToUpload?.createdAt) || missionToUpload?.isCorridorMission;
      const postMethod$ = hasToCreateNewMission
        ? this.createMission(missionToUpload, isLastIndex)
        : this.updateMission(missionToUpload, isLastIndex);
      this.queuedMission.push(lastValueFrom(postMethod$));
    });

    this.form.controls.customSpeed.disable();
    try {
      await Promise.all(this.queuedMission).then((missions: any) => {
        if (!this.addMissionToJobsForm.controls.hasToAddMissionToJob.value) {
          return new Promise<void>(resolve => resolve());
        }

        const createJobsPromise = missions.map(mission =>
          lastValueFrom(
            this.userService.user$.pipe(
              take(1),
              switchMap(user => {
                return this.jobsApiService.createJobTask({
                  type: TaskType.FLIGHT_REQUEST,
                  title: mission.name,
                  assignedId: user.id,
                  description: '',
                  jobId: this.addMissionToJobsForm.controls.selectedJob.value,
                  context: {
                    mission: {
                      id: mission.id
                    } as unknown as Mission
                  }
                });
              }),
              tap(task => {
                this.translateService
                  .get(['atlas.mission.missionUploaded', 'atlas.mission.taskCreated', 'atlas.mission.goToTask'])
                  .pipe(take(1))
                  .subscribe(translations => {
                    const {
                      'atlas.mission.missionUploaded': missionUploaded,
                      'atlas.mission.taskCreated': taskCreated,
                      'atlas.mission.goToTask': goToTask
                    } = translations;

                    this.notificationStoreFacadeService.displayNewNotificationOnClientSide({
                      title: missionUploaded,
                      message: taskCreated,
                      icon: NotificationIcon.success,
                      level: NotificationLevel.success,
                      action: [goToTask],
                      actionUrl: [`/secure/jobs/${task.jobId}`]
                    });
                  });
              })
            )
          )
        );

        return Promise.all(createJobsPromise).then(() => {});
      });
      this.dialogRef.close(this.totalFilesToUpload.value.length);
    } catch (error) {
      this.snackBar.open(error?.message, null, {
        panelClass: 'center',
        duration: 3000
      });
      this.dialogRef.close(this.totalFilesToUpload.value.length);
    }
  }

  private createCorridorMission(missionToUpload: Mission, isLastMission: boolean) {
    delete missionToUpload.isCorridorMission;
    return this.missionApiService.importMission(missionToUpload as any).pipe(
      tap(mission => {
        const completeMission = this.listMission(mission);
        if (isLastMission) {
          this.missionManagerService.viewMission(completeMission);
        }
      })
    );
  }

  private uploadKmz(missionToUpload: any) {
    return this.missionApiService.importMissionKMZ(missionToUpload as any).pipe(
      tap(mission => {
        if (mission) {
          this.unleashAnalytics.logEvent(EVENTS.MISSION_PLANNER_SURVEY_MISSION_UPLOADED);
          this.listMission({...mission, type: MissionType.MAPPING_2D});
        }
      })
    );
  }

  // eslint-disable-next-line rxjs/finnish
  private createMission(missionToUpload: Mission, isLastMission: boolean): Observable<any> {
    return this.missionApiService.createMission({...missionToUpload, name: missionToUpload.name}).pipe(
      tap(mission => {
        const completeMission = this.listMission(mission);
        if (isLastMission) {
          this.missionManagerService.viewMission(completeMission);
        }
      })
    );
  }

  // eslint-disable-next-line rxjs/finnish
  private updateMission(missionToUpload: Mission, isLastMission: boolean): Observable<any> {
    return this.missionApiService
      .updateMission(missionToUpload.id, {
        name: missionToUpload?.name,
        route: missionToUpload?.route
      })
      .pipe(
        tap(mission => {
          const completeMission = {...missionToUpload, ...mission};
          this.listMission(completeMission);
          if (isLastMission) {
            this.missionManagerService.viewLocation(completeMission);
          }
        })
      );
  }

  private fillWaypointsWithDefaultSettings(missionToUpload: Mission): MissionRoutePoint[] {
    return missionToUpload.route.map(route => {
      const mergedObject: MissionRoutePoint = {} as MissionRoutePoint;
      this.missionSettingsService.settings$.pipe(take(1)).subscribe(settings => {
        for (const key in settings) {
          mergedObject[key] = route[key] !== null && route[key] !== undefined ? route[key] : settings[key];

          if (missionToUpload.type !== MissionType.DRONE_HARMONY) {
            continue;
          }

          if (key === 'speed') {
            mergedObject[key] = this.form.value.setCustomSpeed ? this.form.value.customSpeed : mergedObject[key];
          }

          if (key === 'actions') {
            let actions = [];
            if (this.form.value.takePhotoEachWaypoint) {
              actions.push({
                action: 'TAKE_PHOTO'
              });
            }

            if (mergedObject?.[key]?.length > 0) {
              actions = actions.concat(mergedObject[key]);
            }

            mergedObject[key] = actions;
          }
        }
      });
      return mergedObject;
    });
  }

  public listMission(missionToUpload: Mission): Mission {
    const missionWithTimeAndDistance = this.missionManagerService.calculateTimeAndDistance(missionToUpload);
    this.missionManagerService.addNewMission(missionWithTimeAndDistance);
    return missionWithTimeAndDistance;
  }

  public fileOver() {
    this.isDropContainerHovered = true;
  }

  public fileLeave() {
    this.isDropContainerHovered = false;
  }

  public fetchMissionsToDisplay() {
    this.jobsFacadeService.listJobs();
  }

  private checkIfContainsCsvFiles(files: File[]) {
    const hasOnlyCsvFiles = files.every((file: File) => this.csvRegex.test(file.name));

    if (hasOnlyCsvFiles) {
      this.hasCsvFiles.next(hasOnlyCsvFiles);
      return;
    }

    const hasMixedFiles = files.some((file: File) => this.csvRegex.test(file.name));
    this.hasCsvFiles.next(hasMixedFiles);
    this.hasMixedFiles.next(hasMixedFiles);
  }
}

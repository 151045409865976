<div fxLayout.gt-sm="row" fxLayoutGap="48px" fxLayout.lt-sm="column" fxLayoutAlign="start">
  <app-gauge
    *ngIf="!!user?.currentUsage?.devicesMax"
    class="gauge"
    [initValue]="0"
    [maxValue]="user?.currentUsage?.devicesMax"
    [currentValue]="user?.currentUsage?.devices"
    [title]="'profile.usage.devicesTitle' | translate"
    [subTitle]="'profile.usage.available' | translate"
    [units]="'profile.usage.devicesUnits' | translate"
    [isAwsUser]="isAwsUser"
  ></app-gauge>
  <!--  Remove because of no changes to livestreaming for production-->
  <!--  <app-gauge *ngIf="!!user?.currentUsage?.streamsRawMax"-->
  <!--             class="gauge"-->
  <!--             [initValue]="0"-->
  <!--             [maxValue]="user?.currentUsage?.streamsRawMax"-->
  <!--             [currentValue]="user?.currentUsage?.streamsRaw + user?.currentUsage?.streamsAi"-->
  <!--             [title]="'Livestream hours'"-->
  <!--             [subTitle]="'available'"-->
  <!--             [units]="'hours'"-->
  <!--  ></app-gauge>-->
  <!-- <app-gauge
    *ngIf="user?.currentUsage?.librarySize != undefined"
    class="gauge"
    [initValue]="0"
    [maxValue]="librarySizeMax"
    [currentValue]="librarySize"
    [title]="'profile.usage.storageTitle' | translate"
    [subTitle]="'profile.usage.available' | translate"
    [units]="'profile.usage.storageUnits' | translate"
    [unitsInside]="true"
    [isAwsUser]="isAwsUser"
  ></app-gauge> -->
  <!--    <app-gauge-->
  <!--            class="gauge"-->
  <!--            [initValue]="0"-->
  <!--            [finalValue]="5000"-->
  <!--            [actualValue]="1340"-->
  <!--            [title]="'A.I. Analysis Minutes'"-->
  <!--            [subTitle]="'included'"-->
  <!--    ></app-gauge>-->
</div>

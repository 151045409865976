import {createAction, props} from '@ngrx/store';
import {TeamRole} from '../models/team.model';
import {UserTeamTable} from '../models/user-team.model';
import {UserTable} from '../models/users-table.model';

export enum ProfileActionTypes {
  AddUsers = '[Users Management] Add user',
  DeleteUser = '[Users Management] Delete user',
  DeleteUserSuccess = '[Users Management] Delete user success',
  SearchUser = '[Users Management] Search user',

  DeleteTeam = '[Teams Management] Delete team',
  SearchTeam = '[Teams Management] Search team',

  AddUserToTeam = '[User Teams Management] Add user to team',
  DeleteUserFromTeam = '[User Teams Management] Delete user from team',
  SearchUserTeam = '[User Teams Management] Search user team',
  SelectUserTeam = '[User Teams Management] Select user team',

  RemoveSelectedUserTeam = '[User Teams Management] Remove selected user team'
}

export const actionAddUsers = createAction(ProfileActionTypes.AddUsers, props<{users: UserTable[]}>());

export const actionSearchUser = createAction(ProfileActionTypes.SearchUser, props<{search: string}>());
export const actionSearchTeam = createAction(ProfileActionTypes.SearchTeam, props<{search: string}>());

export const actionAddUserToTeam = createAction(
  ProfileActionTypes.AddUserToTeam,
  props<{userId: string; teamId: string; role: TeamRole}>()
);
export const actionDeleteUserFromTeam = createAction(
  ProfileActionTypes.DeleteUserFromTeam,
  props<{payload: {userId: string}}>()
);
export const actionSearchUserTeam = createAction(ProfileActionTypes.SearchUserTeam, props<{search: string}>());
export const actionSelectUserTeamTable = createAction(
  ProfileActionTypes.SelectUserTeam,
  props<{userTeamTable: UserTeamTable}>()
);
export const actionRemoveSelectedUserTeamTable = createAction(ProfileActionTypes.RemoveSelectedUserTeam);

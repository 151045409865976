export enum TriggerPoint {
  topLeft = 'top_left',
  topCenter = 'top_center',
  topRight = 'top_right',
  left = 'left',
  center = 'centroid',
  right = 'right',
  bottomLeft = 'bottom_left',
  bottomCenter = 'bottom_center',
  bottomRight = 'bottom_right'
}

export enum TriggerPointName {
  'top_left' = 'Top Left',
  'top_center' = 'Top Center',
  'top_right' = 'Top Right',
  'left' = 'Left',
  'centroid' = 'Center',
  'right' = 'Right',
  'bottom_left' = 'Bottom Left',
  'bottom_center' = 'Bottom Center',
  'bottom_right' = 'Bottom Right'
}

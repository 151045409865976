import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {Observable, switchMap} from 'rxjs';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'any'
})
export class DashboardApiService {
  constructor(private apiGateway: ApiGateway, private userStoreFacadeService: UserStoreFacadeService) {}

  public getDashboardId$(): Observable<string> {
    //TODO Get dashboard id from a query
    return this.userStoreFacadeService.currentUser$.pipe(map(user => user.dataDashboardId));
  }

  public getReportLink$(dashboardId: string): Observable<string> {
    return dashboardId
      ? this.getDashboardUrl$(dashboardId)
      : this.getDashboardId$().pipe(switchMap(dashboardId => this.getDashboardUrl$(dashboardId)));
  }

  private getDashboardUrl$(dashboardId: string): Observable<string> {
    return this.apiGateway.get('analytics/dashboard/' + dashboardId, {});
  }
}

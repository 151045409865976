<h1 mat-dialog-title>{{ 'profile.deleteUserTeam.deleteUserTitle' | translate }} {{ data.name }}?</h1>
<div mat-dialog-content>
  <span>{{ 'profile.deleteUserTeam.deleteUserDescription' | translate }}</span>
</div>

<div mat-dialog-actions>
  <button color="primary" mat-button (click)="cancel()">{{ 'common.cancel' | translate }}</button>
  <button color="warn" mat-raised-button (click)="deleteUser()">
    {{ 'profile.deleteUserTeam.deleteUser' | translate }}
  </button>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FlightFrame, FlightInfo} from '@app/flights/components/flight-log/flight-log-parser';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {environment} from 'environments/environment';
import {Observable, Subscription, firstValueFrom, shareReplay, tap} from 'rxjs';
import {FlightLogDetailDialogFacadeService} from './services/flight-log-detail-dialog-facade.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {FlightLogsItem} from '@app/flights/models/flight-logs.model';
import {LibraryApiService} from '@app/library/services/library-api.service';
import {getCognitoIdentityValue} from '@app/core/services/api/cognito-identity-value';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-flight-log-detail-dialog',
  templateUrl: './flight-log-detail-dialog.component.html',
  styleUrls: ['./flight-log-detail-dialog.component.scss']
})
export class FlightLogDetailDialogComponent implements OnInit {
  public flightInfo$: Observable<FlightInfo> = this.flightLogDetailDialogFacadeService.selectFlightInfo();
  public flightFrames$: Observable<FlightFrame[]> = this.flightLogDetailDialogFacadeService.selectFlightFrames();
  public flightDate$: Observable<Date> = this.flightLogDetailDialogFacadeService.selectFlightDate();
  public isLoading$: Observable<boolean> = this.flightLogDetailDialogFacadeService.selectIsLoading();
  public isSliderVisible: boolean = true;
  public isFlightMapFullScreen: boolean = false;
  public device$: Observable<UserDeviceJoined>;
  public loadFromUrlSub: Subscription;
  public isMobileView: boolean = false;
  public THUMBLER_AVAILABLE_CONFIGS: typeof THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;
  public flightLogDetailMode: typeof FLIGHT_LOG_DETAIL_MODE = FLIGHT_LOG_DETAIL_MODE;

  private observableMediaSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FlightLogsItem,
    private observableMedia: MediaObserver,
    private dialogRef: MatDialogRef<FlightLogDetailDialogComponent>,
    private flightLogDetailDialogFacadeService: FlightLogDetailDialogFacadeService,
    private userStoreFacadeService: UserStoreFacadeService,
    private libraryApiService: LibraryApiService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.device$ = this.userStoreFacadeService.getDevice(this.data.deviceId).pipe(shareReplay(1));
    let url = `${environment.FLIGHT_LOGS_CF_CDN}/${this.data.s3Path}`;
    if (this.data.isFightLogFromLibrary) {
      url = `${environment.LIBRARY_CF_CDN}/${this.data.libraryItem.s3Path}?token=${getCognitoIdentityValue('idToken')}`;
    }

    this.flightLogDetailDialogFacadeService.loadFlightLogs(url);
    this.observableMediaSub = this.observableMedia.asObservable().subscribe((change: MediaChange[]) => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      this.isMobileView = mediaAlias === 'xs' || mediaAlias === 'sm';
      this.isSliderVisible = this.isFlightMapFullScreen ? true : !this.isMobileView;
    });
  }

  public makeFlightMapFullScreen(): void {
    if (this.isMobileView && !this.isFlightMapFullScreen) {
      this.changeFlightLogDetailMode(this.flightLogDetailMode.FULLSCREEN);
    }
  }

  public changeFlightLogDetailMode(mode: typeof FLIGHT_LOG_DETAIL_MODE[keyof typeof FLIGHT_LOG_DETAIL_MODE]): void {
    this.isFlightMapFullScreen = mode;
    this.isSliderVisible = mode;
  }

  public handleCloseButton(): void {
    this.dialogRef.close();
  }
}

export interface FlightLogDetailData {
  flightInfo: FlightInfo;
  flightFrames: FlightFrame[];
  flightDate: Date;
}

const FLIGHT_LOG_DETAIL_MODE = {
  FULLSCREEN: true,
  NORMAL: false
};

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AtlasMapComponent} from './components/atlas-map/atlas-map.component';
import {PointMapComponent} from './components/point-map/point-map.component';
import {MissionResolver} from './resolvers/mission-resolver';

const routes: Routes = [
  {
    path: '3d-preview',
    resolve: {
      mission: MissionResolver
    },
    loadComponent: () => import('./components/three-d-preview/three-d-preview.page').then(m => m.ThreeDPreviewPage)
  },
  {path: 'place', component: PointMapComponent},
  {path: 'assets', component: AtlasMapComponent},
  {path: '', component: AtlasMapComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtlasRoutingModule {}

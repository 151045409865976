import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'unleash-leave-page-dialog',
  templateUrl: './leave-page-dialog.component.html',
  styleUrls: ['./leave-page-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeavePageDialogComponent {
  constructor(public dialogRef: MatDialogRef<LeavePageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public discardNumber() {
    this.dialogRef.close({discardNumber: true});
  }
  public openVerifyNumber(): void {
    this.dialogRef.close({openVerifyNumber: true});
  }
}

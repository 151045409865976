import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {RuleItem, RuleAction, RuleMenuOption} from '../../models/rule.model';

@Component({
  selector: 'unleash-rule-notifications-sidebar',
  templateUrl: './rule-notifications-sidebar.component.html',
  styleUrls: ['./rule-notifications-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleNotificationsSidebarComponent implements OnInit {
  @Input() rules: RuleItem[] = [];
  @Input() selectedRule: RuleItem = {id: '', name: '', enabled: true};
  @Input() menuOptions: RuleMenuOption[] = [];
  @Input() performAnalysisLoading: boolean = false;
  @Input() disabledAddRules = false;

  @Output() addRule: EventEmitter<void> = new EventEmitter();
  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() analyze: EventEmitter<void> = new EventEmitter();
  @Output() selectedRuleId: EventEmitter<string> = new EventEmitter();
  @Output() action: EventEmitter<RuleAction> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  emitBack(): void {
    this.back.emit();
  }

  emitAnalyze(): void {
    this.analyze.emit();
  }

  emitSelectedRuleId(selectedRuleId: string): void {
    this.selectedRuleId.emit(selectedRuleId);
  }

  emitAction(ruleAction: RuleAction): void {
    this.action.emit(ruleAction);
  }

  emitAddRule(): void {
    this.addRule.emit();
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {S3ImportDialogComponent} from './s3-import-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UnleashMaterialModule} from '@app/theme/unleash-material.module';

@NgModule({
  declarations: [S3ImportDialogComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, MatIconModule, MatTooltipModule, UnleashMaterialModule],
  exports: [S3ImportDialogComponent]
})
export class S3ImportDialogModule {}

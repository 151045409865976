<ng-container *ngIf="imageSnapshot$ | async as image">
  <div class="zone" #zone>
    <unleash-loader
      *ngIf="isLoadingImage$ | async"
      class="loader"
      [visible]="true"
      [text]="'live.manage-zones-dialog.loading' | translate"
    ></unleash-loader>

    <unleash-calibration-map
      *ngIf="(currentCalibrationSidebarMode$ | async) === calibrationSidebarMode.ADD_MARKER_IN_MAP"
      [markerColor]="selectedColor$ | async"
      [redrawPoints]="true"
      [hasToDisplayMarkerCount]="true"
      [currentIndex]="newMarkerIndex$ | async"
      [deviceLocation]="deviceLocation$ | async"
      [imageCoordinates]="points$ | async"
      (onMarkerHover)="emitHightLightMarkerByIndex($event)"
      (markerCoordinates)="setMarkerCoordinates($event)"
    >
    </unleash-calibration-map>
    <pinch-zoom
      #pinchZoom
      (zoomInEnded)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
      (zoomOutEnded)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
      (wheel)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
      [properties]="{
        maxScaleConfig: 100,
        disableZoomControl: 'disable',
        zoomModel: 'white',
        minPanScale: 0.5,
        minScale: 0.3
      }"
      [zoomControlScale]="0.2"
      [canvasDiagonalWidth]="canvasDiagonalWidth$ | async"
      [naturalImageDiagonalWidth]="naturalImageDiagonalWidth$ | async"
      [backgroundColor]="null"
      class="zoom"
      [style.display]="
        (currentCalibrationSidebarMode$ | async) === calibrationSidebarMode.ADD_MARKER_IN_MAP ||
        (currentCalibrationSidebarMode$ | async) === calibrationSidebarMode.EDIT_CANVAS_POINT
          ? 'none'
          : 'block'
      "
    >
      <unleash-image-annotation-shared
        (mousedown)="onMouseDown()"
        (mouseup)="onMouseUp()"
        (mousemove)="onMouseMove()"
        (click)="drawMarker($event)"
        #imageAnnotationShared
        [canvasId]="'calibration'"
        [zoom]="pinchZoom.scale"
        (canvasLoaded)="stopLoader()"
        [srcLowRes]="image.data | uThumbler: thumblerConfig"
        [isMobileView]="isMobileView$ | async"
        [src]="image.data | uThumbler: thumblerConfig"
        [drawStatus]="drawStatus$ | async"
        [style.width]="(isLoadingImage$ | async) ? '0' : '100%'"
      ></unleash-image-annotation-shared>
    </pinch-zoom>
  </div>
</ng-container>

<unleash-context-menu #contextMenu [matMenu]="matMenu">
  <mat-menu #matMenu>
    <ng-template matMenuContent let-index="index">
      <button (click)="enableEditMode(index)" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>{{ 'calibration.edit' | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="deleteMarker(index)">
        <mat-icon color="red">delete</mat-icon>
        <span class="mat-red">{{ 'calibration.delete' | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
</unleash-context-menu>

<div class="custom-cursor" id="canvas-cursor" *ngIf="selectedColor$ | async as markerColor">
  <img [src]="'assets/icons/image-viewer/map-pin--' + markerColor + '.svg'" class="custom-cursor__icon-canvas" />
  <span [class]="markerColor" class="custom-cursor__index">
    {{ (newMarkerIndex$ | async) + 1 }}
  </span>
</div>

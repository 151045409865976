import {Injectable} from '@angular/core';
import {divIcon, point, Map} from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster.layersupport';
import 'leaflet.markercluster.freezable';

declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class MarkerClusterService {
  public cluster: any;
  public stickyClustersEnabled = false;
  private isOnMap: boolean;
  private clustersDisabled: boolean;
  constructor() {
    this.cluster = this.getMarkerClusterConfig();
  }

  public destroy() {
    this.cluster.remove();
    this.clustersDisabled = false;
    this.setIsOnMap(false);
  }

  public refresh() {
    try {
      this.cluster.refreshClusters();
    } catch (e) {
      console.warn('Could not refresh clusters');
    }
  }

  public checkIn(geoJsonLayerGroup) {
    this.cluster.checkIn(geoJsonLayerGroup);
  }

  public addToMap(map: Map) {
    if (this.isOnMap) {
      // Cluster is rendered on the map already
      return;
    }
    this.cluster.addTo(map);
    this.setIsOnMap(true);
  }

  public removeFromMap(map: Map) {
    this.cluster.removeFrom(map);
    this.setIsOnMap(false);
  }

  public enableClustering() {
    if (this.clustersDisabled && this.isOnMap) {
      this.clustersDisabled = false;
      this.cluster.enableClustering();
    }
  }

  public disableClustering() {
    if (!this.clustersDisabled && this.isOnMap) {
      this.clustersDisabled = true;
      this.cluster.disableClustering();
    }
  }

  public setIsOnMap(isOnMap: boolean) {
    this.isOnMap = isOnMap;
  }

  private getMarkerClusterConfig() {
    return L.markerClusterGroup.layerSupport({
      spiderfyOnMaxZoom: true,
      removeOutsideVisibleBounds: true,
      chunkedLoading: true,
      chunkInterval: 200,
      chunkDelay: 50,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      animate: false,
      iconCreateFunction: getIconCreateFunction()
    });
  }

  public toggleClusters() {
    if (this.clustersDisabled) {
      this.enableClustering();
      this.stickyClustersEnabled = true;
    } else {
      this.disableClustering();
      this.stickyClustersEnabled = false;
    }
  }
}

function getClusterHtml(colorCounts: {[key: string]: number}, total: number) {
  let cumulativePercentage = 0;
  let gradientParts = [];

  for (let color in colorCounts) {
    let percentage = (colorCounts[color] / total) * 100;
    gradientParts.push(`${color} ${cumulativePercentage}% ${cumulativePercentage + percentage}%`);
    cumulativePercentage += percentage;
  }
  const gradient = `conic-gradient(${gradientParts.join(', ')})`;
  const containerStyle = `background: ${gradient};`;

  return `<div class="custom-cluster__container" style="${containerStyle}"></div>
          <div class="custom-cluster__icon size-indefinite">${total}</div>`;
}

export function getIconCreateFunction() {
  return function (cluster) {
    const markers = cluster.getAllChildMarkers();
    const childCount = markers.length;
    const groupColors = markers.reduce((acc, m) => {
      let color = m.options?.icon?.options?.attribution;
      if (color) {
        acc[color] = (acc[color] || 0) + 1;
      }
      return acc;
    }, {});

    return divIcon({
      html: getClusterHtml(groupColors, childCount),
      className: 'custom-cluster',
      iconSize: point(40, 40)
    });
  };
}

import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {PROMO_CODE} from '../../../auth/models/auth.models';
import {LocalStorageService} from '../../../core/services/local-storage/local-storage.service';

@Component({
  selector: 'unleash-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCodeComponent implements AfterViewInit {
  @Input() isSuccess: boolean;
  @Output() promoCode: EventEmitter<string> = new EventEmitter();

  isShowingPromoCode$: Observable<boolean>;
  private isShowingPromoCode = new BehaviorSubject(false);
  private form = this.fb.group({
    promoCode: ['']
  });

  constructor(private fb: FormBuilder, private localStorageService: LocalStorageService) {
    this.isShowingPromoCode$ = this.isShowingPromoCode.asObservable();
  }

  ngAfterViewInit(): void {
    const promoCodeFromLink = this.localStorageService.getItem(PROMO_CODE);
    if (promoCodeFromLink) {
      this.form.controls.promoCode.setValue(promoCodeFromLink);
      this.isShowingPromoCode.next(true);
    }
  }

  emitPromoCode() {
    if (this.form.valid) {
      this.promoCode.emit(this.form.value.promoCode);
    } else {
      this.form.controls.promoCode.markAsTouched();
    }
  }

  toggleShowingPromoCode() {
    this.isShowingPromoCode.next(!this.isShowingPromoCode.value);
  }
}

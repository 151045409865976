<mat-card>
  <mat-card-header>
    <div class="header">
      <unleash-search-input (search)="performSearch($event)"></unleash-search-input>
      <button
        (click)="emitAddUserTeam()"
        data-test-id="add-users-button"
        mat-button
        color="primary"
        [disabled]="disableAddUserTeam"
      >
        <mat-icon>group_add</mat-icon> {{ 'profile.users.addToTeam' | translate }}
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="content">
      <unleash-user-teams-table
        [dataSource]="userTeams"
        [menuItems]="menuItems"
        [selectedUser]="selectedUser"
        [roles]="roles"
        (userTeam)="emitUserTeam($event)"
        (action)="emitAction($event)"
        (editUserTeamRole)="emitAction($event)"
      ></unleash-user-teams-table>
    </div>
  </mat-card-content>
</mat-card>

import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-set-layer-name-dialog',
  templateUrl: './set-layer-name-dialog.component.html',
  styleUrls: ['./set-layer-name-dialog.component.scss']
})
export class SetLayerNameDialogComponent {
  form = new UntypedFormGroup({
    name: new UntypedFormControl('')
  });

  constructor() {}
}

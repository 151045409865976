import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'unleash-rename-mission',
  templateUrl: './rename-mission.component.html',
  styleUrls: ['./rename-mission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameMissionComponent {
  public form = this.fb.group({
    name: [this.data.currentName, [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RenameMissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {currentName: string}
  ) {}

  public renameMission(): void {
    this.dialogRef.close(this.form.controls.name.value);
  }
}

import {ParsedReport} from '@app/analysis/models/analysis.model';
import {createSelector} from '@ngrx/store';
import {ReportsState} from './reports.state';

const select = (state: any) => state.reports;

export const selectAllReports = createSelector(select, (state: ReportsState) => {
  return state.reports;
});

export const selectDisplayedReports = createSelector(select, (state: ReportsState) => {
  return state.displayedReports;
});

export const selectPaginator = createSelector(select, (state: ReportsState) => {
  return state.paginator;
});

export const selectIsLoadingReports = createSelector(select, (state: ReportsState) => {
  return state.isLoading;
});

export const selectAllIntegrations = createSelector(select, (state: ReportsState) => {
  return state.integrations;
});

export const selectDisplayedIntegrations = createSelector(select, (state: ReportsState) => {
  return state.displayedIntegrations;
});

export const selectIsLoadingIntegrations = createSelector(select, (state: ReportsState) => {
  return state.isIntegrationLoading;
});

export const selectFilter = createSelector(select, (state: ReportsState) => {
  return {
    ...state.filter,
    from: state.filter.from ? (state.filter.from as Date).getTime() : null,
    to: state.filter.to ? (state.filter.to as Date).getTime() : null,
    timeFrom: state.filter.timeFrom ? (state.filter.timeFrom as Date).getTime() : null,
    timeTo: state.filter.timeTo ? (state.filter.timeTo as Date).getTime() : null
  };
});

export const selectReportsTypes = createSelector(select, (state: ReportsState) => {
  return [...new Set(state.reports?.map(report => report.type))].map(report => ({value: report, name: report}));
});

export const selectSelectedReportsNumber = createSelector(
  select,
  (state: ReportsState) => state.displayedReports.filter((report: ParsedReport) => report.isSelected).length
);

export const selectSelectedReportsId = createSelector(select, (state: ReportsState) => {
  return state.displayedReports
    .filter((report: ParsedReport) => report.isSelected)
    .map((report: ParsedReport) => report.id);
});

export const selectActiveFilters = createSelector(select, (state: ReportsState) => {
  let totalActiveFilters = 0;
  for (const filter in state.filter) {
    if (!!state.filter[filter]) {
      totalActiveFilters++;
    }
  }
  return totalActiveFilters;
});

export const selectIsLoadingApiKey = createSelector(select, (state: ReportsState) => {
  return state.isLoadingApiKey;
});

export const selectApiKey = createSelector(select, (state: ReportsState) => {
  return state.apiKey;
});

export const selectIsApiKeyActionInProgress = createSelector(select, (state: ReportsState) => {
  return state.isApiKeyActionInProgress;
});

import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {
  ManagerZonesAnalyzeState,
  managerZonesFeatureKey
} from '@app/shared/manage-zones-dialog/store/manager-zones.newer-reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const selectManagerZonesState = createFeatureSelector<ManagerZonesAnalyzeState>(managerZonesFeatureKey);

export const selectZonesConfigData = createSelector(
  selectManagerZonesState,
  (state: ManagerZonesAnalyzeState) => state.zonesConfigData
);

export const selectZonesConfigBackup = createSelector(
  selectManagerZonesState,
  (state: ManagerZonesAnalyzeState) => state.zonesConfigBackup
);

export const selectIsCanvasReady = createSelector(
  selectManagerZonesState,
  (state: ManagerZonesAnalyzeState) => state.isCanvasReady
);

export const selectImageSnapshot = createSelector(
  selectManagerZonesState,
  (state: ManagerZonesAnalyzeState) => state.imageSnapshot
);

export const selectZones = createSelector(selectManagerZonesState, (state: ManagerZonesAnalyzeState) => state.zones);

export const selectPolygonZones = createSelector(selectManagerZonesState, (state: ManagerZonesAnalyzeState) =>
  state.zones.filter(
    zone =>
      !zone.removed &&
      (zone.shape_type === ShapeTypes.polygon ||
        zone.shape_type === ShapeTypes.Polygon ||
        zone.shape_type === ShapeTypes.polygon_excluded)
  )
);

export const selectIsSelectedPerspectiveZone = createSelector(
  selectManagerZonesState,
  (state: ManagerZonesAnalyzeState) => {
    if (!state.zones.length) {
      return false;
    }

    const zone = state.zones.find(zone => zone.id === state.selectedZoneId);
    return zone.shape_type === ShapeTypes.perspective;
  }
);

export const selectSelectedZoneColor = createSelector(selectManagerZonesState, (state: ManagerZonesAnalyzeState) => {
  const zone = state.zones.find(zone => zone.id === state.selectedZoneId);
  return zone ? zone.color.name : null;
});

export const selectSelectedZone = createSelector(selectManagerZonesState, (state: ManagerZonesAnalyzeState) => {
  return state.zones.find(zone => zone.id === state.selectedZoneId);
});

export const selectHasPerspectiveZone = createSelector(selectManagerZonesState, (state: ManagerZonesAnalyzeState) => {
  return state.zones.some(zone => zone.shape_type === ShapeTypes.perspective && !zone.removed);
});

export const selectHint = createSelector(selectManagerZonesState, (state: ManagerZonesAnalyzeState) => state.hint);

export const selectIsZonesLoading = createSelector(
  selectManagerZonesState,
  (state: ManagerZonesAnalyzeState) => state.isZonesLoading
);

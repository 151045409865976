<form class="form" [formGroup]="form.controls.settings">
  <mat-form-field appearance="fill">
    <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.severity' | translate }}</mat-label>
    <mat-select
      panelClass="rule-alert-panel"
      placeholder="{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.severity' | translate }}"
      formControlName="alert"
      #mSelect
      (selectionChange)="emitAlertChange($event)"
    >
      <mat-select-trigger>
        <div class="alert-display">
          <mat-icon class="alert-display__icon alert-display__icon--{{ mSelect.value }}">{{
            mSelect.value | alertDisplayIcon
          }}</mat-icon>
          <span>{{ mSelect.value | alertDisplayName }}</span>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let alert of alerts" [value]="alert.id">
        <div class="alert">
          <div class="alert__icon">
            <mat-icon class="alert__icon--{{ alert.id }}">{{ alert.icon }}</mat-icon>
          </div>
          <div class="alert__info">
            <div class="alert__info__name">
              {{ alert.name }}
            </div>
            <div class="alert__info__description">{{ alert.description }}</div>
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="hasToDisplayCoolDown">
    <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.cooldown' | translate }}</mat-label>
    <mat-select
      placeholder="{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.cooldown' | translate }}"
      formControlName="coolDownPeriod"
      (selectionChange)="emitValueChange()"
    >
      <mat-option *ngFor="let coolDownPeriod of coolDownPeriods" [value]="coolDownPeriod.value">
        {{ coolDownPeriod.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container>
    <mat-form-field appearance="fill" class="text-input">
      <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.title' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.title' | translate }}"
        formControlName="title"
        (change)="emitValueChange()"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="text-input">
      <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.message' | translate }}</mat-label>
      <textarea
        matInput
        placeholder="{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.message' | translate }}"
        formControlName="message"
        (change)="emitValueChange()"
      ></textarea>
    </mat-form-field>
  </ng-container>
</form>

import {icon} from 'leaflet';

export const createCompletedMarkerIcon = size => {
  const color = '#5CB85C';
  const customSvg = `
  <svg width="${size[0]}" height="${size[1]}" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3" y="3.33337" width="11" height="11" fill="white"/>
<path d="M4.5 6.5L12 4H14L14.5 8L9 14L4 14.5L2 10L4.5 6.5Z" fill="white"/>
<path d="M8.16667 1C12.67 1 16.3333 4.66333 16.3333 9.16667C16.3333 15.2917 8.16667 24.3333 8.16667 24.3333C8.16667 24.3333 0 15.2917 0 9.16667C0 4.66333 3.66333 1 8.16667 1ZM6.38167 15L14 7.31167L12.3667 5.66667L6.38167 11.71L3.96667 9.27167L2.33333 10.9167L6.38167 15Z" fill="#5CB85C"/>
</svg>
`;
  const svgAsURI = 'data:image/svg+xml;base64,' + btoa(customSvg).replace('#', '%23');

  return icon({
    iconUrl: svgAsURI,
    attribution: color,
    iconAnchor: [size[0] / 2, size[1]]
  });
};

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <button class="back-button" mat-icon-button (click)="emitBack()">
        <mat-icon aria-label="label">arrow_back</mat-icon>
      </button>
      <div class="title">{{ 'profile.teams.create' | translate }}</div>
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="add-team">
      <unleash-add-team-row [form]="form"> </unleash-add-team-row>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button data-test-id="cancel-button" mat-button color="primary" (click)="emitCancel()">
      {{ 'common.cancel' | translate }}
    </button>
    <button data-test-id="create-team-button" mat-raised-button color="primary" (click)="emitCreateTeam()">
      {{ 'profile.teams.create' | translate }}
    </button>
  </mat-card-actions>
</mat-card>

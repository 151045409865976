<form class="form" [formGroup]="form">
  <ng-container *ngFor="let control of form.controls | keyvalue">
    <ng-container [ngSwitch]="control.key">
      <ng-container *ngSwitchCase="'zone'">
        <div class="zone-container">
          <mat-form-field class="form-field-zone" appearance="fill">
            <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleRow.property' | translate }}</mat-label>
            <mat-select formControlName="zone" (selectionChange)="emitZoneChange()">
              <mat-option *ngFor="let zone of zones" [value]="zone.id">
                {{ zone.display_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <unleash-logic-operator-button
            *ngIf="isAdvancedMode"
            formControlName="logicOperator"
            [isParentFormValid]="form.valid && form.value.condition !== 'null'"
            (changeOperator)="emitAddOperatorValueChange()"
          ></unleash-logic-operator-button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'condition'">
        <mat-form-field class="form-field-condition" appearance="fill">
          <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleRow.selectAggregate' | translate }}</mat-label>
          <mat-select formControlName="condition" (selectionChange)="emitConditionChange()">
            <mat-option *ngFor="let condition of conditions" [value]="condition.id">
              {{ condition.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'operator'">
        <mat-form-field class="form-field-operator" appearance="fill">
          <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleRow.operator' | translate }}</mat-label>
          <mat-select formControlName="operator" (selectionChange)="emitConditionChange()" #mSelect>
            <mat-select-trigger>
              {{ mSelect?.selected?.viewValue | slice: 0:1 }}
            </mat-select-trigger>
            <mat-option *ngFor="let operator of operators" [value]="operator.id">
              {{ operator.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngSwitchCase="'value'">
        <ng-container
          *ngIf="form.value.zone === 'triggered_zones' && form.value.condition === 'disabled'; else normalValueTemplate"
        >
          <mat-form-field class="form-field-value" appearance="fill">
            <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleRow.value' | translate }}</mat-label>
            <mat-select
              formControlName="value"
              (selectionChange)="updateValueOnBlur('value', mSelectValue)"
              #mSelectValue
            >
              <mat-option *ngFor="let zone of zoneList" [value]="zone.id">
                {{ zone.display_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-template #normalValueTemplate>
          <mat-form-field class="form-field-value" appearance="fill">
            <mat-label>{{ 'live.manage-zones-dialog.notificationsTab.ruleRow.value' | translate }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="Value"
              formControlName="value"
              #valueInput
              (blur)="updateValueOnBlur('value', valueInput)"
            />
          </mat-form-field>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchDefault> </ng-container>
    </ng-container>
  </ng-container>
  <button mat-icon-button class="delete-button" *ngIf="hasDelete" (click)="emitDelete()">
    <mat-icon aria-hidden="delete">delete</mat-icon>
  </button>
</form>

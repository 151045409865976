import {MissionActionWithParam, MissionSurveyModel} from './survey-mission.model';

export enum MissionTypeDisplay {
  'waypoint' = 'Waypoint Mission',
  'corridor' = 'Corridor Mission',
  'pilot' = 'DJI Pilot Mission',
  'smart-inspect' = 'Smart Inspect Mission',
  'drone-harmony' = 'Drone Harmony Mission',
  'mapping2d' = 'Survey Mission'
}

export enum MissionType {
  WAYPOINT = 'waypoint', // generic mission uploaded by user or created via mission planner
  CORRIDOR = 'corridor', // waypoint mission generated via mission corridor generator (e.g. for powerlines inspection)
  DJI_PILOT = 'pilot', // mission recorded in DJI Pilot and imported as KML
  SMART_INSPECT = 'smart-inspect', // mission template that is oriented to the base point collected when mission starts
  DRONE_HARMONY = 'drone-harmony', // mission imported from drone harmony (csv)
  MAPPING_2D = 'mapping2d' //survey mission
}
export interface SmartInspectMissionModel extends MissionModel {
  start?: string; // only smart inspect
  overlay?: number; // only smart inspect
}

export interface MissionModel extends MissionSurveyModel {
  isImported?: boolean;
  isSmartInspect?: boolean;
  description?: string; // only smart inspect
  heightMode?: HeightMode;
  speed?: number;
  name: string;
  route?: MissionPoint[];
  type?: MissionType;
  lastFlight?: number;
}

export enum MissionPositioningType {
  GPS = 'GPS',
  RTK_BASE_STATION = 'RTKBaseStation',
  QIAN_XUN = 'QianXun',
  CUSTOM = 'Custom'
}

export enum HeightMode {
  RelativeToStartPoint = 'relativeToStartPoint',
  EGM96 = 'EGM96',
  WGS84 = 'WGS84',
  RealTimeFollowSurface = 'realTimeFollowSurface',
  AboveGroundLevel = 'aboveGroundLevel'
}

export enum MissionPointType {
  POINT = 'point',
  POLE = 'pole'
}

export interface MissionPoint {
  lat: number;
  lng: number;
  altitude?: number; // only when heightMode='relativeToStartPoint'
  altitudeWGS?: number; // only when heightMode='WGS84'
  altitudeEGM?: number; // only when heightMode='EGM96'
  heading?: number;
  pitch?: number;
  speed?: number;
  actions?: (MissionActionWithParam | null)[];
  actionGroups?: MissionActionGroupDTO[];
  headingParam?: WaypointHeadingParamDTO;
  turnParam?: WaypointTurnParamDTO;
  gimbalHeadingParam?: WaypointGimbalHeadingParamDTO;
  useGlobalHeadingParam?: boolean;
  useGlobalTurnParam?: boolean;
  useGlobalHeight?: boolean;
  useGlobalSpeed?: boolean;
  useStraightLine?: boolean;
  isRisky?: boolean;
  type?: MissionPointType;
  label?: string;
}

interface MissionActionGroupDTO {
  actions?: MissionActionWithParam[];
  triggerParam?: ActionTriggerDTO;
  startIndex?: number;
  endIndex?: number;
  actionGroupId?: number;
}

interface ActionTriggerDTO {
  triggerType?: ActionTriggerTypeDTO;
  triggerParam?: number;
}

enum ActionTriggerTypeDTO {
  REACH_POINT = 'reachPoint',
  MULTIPLE_TIMING = 'multipleTiming',
  MULTIPLE_DISTANCE = 'multipleDistance',
  BETWEEN_ADJACENT_POINTS = 'betweenAdjacentPoints'
}

interface WaypointTurnParamDTO {
  turnMode: WaypointTurnModeDTO;
  dampingDist?: number;
}

interface WaypointGimbalHeadingParamDTO {
  waypointGimbalPitchAngle?: number;
  waypointGimbalYawAngle?: number;
}

export interface Action {
  action: string;
  param?: string;
}

export interface BelongsToTeam {
  teamId: string;
  companyId: string;
  ownerId?: string;
  createdAtId?: string;
}

export enum WayPointType {
  ROUTE_POINT = 'waypoint',
  POLE_1 = 'dist-1',
  POLE_2 = 'dist-2',
  POLE_3 = 'dist-3',
  POLE_4 = 'dist-4'
}

export interface CorridorRoutePoint {
  pole: string;
  wo: string;
  altEGM?: number;
  altitude: number;
  lat: number;
  lng: number;
  si: string | WayPointType;
}

export interface InputCorridorMission {
  name: string;
  desc: string;
  speed: number;
  overlay?: number;
  route: CorridorRoutePoint[];
}

export interface TemplateFolderDTO {
  autoFlightSpeed?: number;
  globalHeight?: number;
  caliFlightEnable?: boolean;
  gimbalPitchMode?: GimbalPitchModeDTO;
  globalHeadingParam?: WaypointHeadingParamDTO;
  globalTurnMode?: WaypointTurnModeDTO;
  useStraightLine?: boolean;
}

export enum GimbalPitchModeDTO {
  MANUAL = 'manual',
  USE_POINT_SETTING = 'usePointSetting'
}

export interface WaypointHeadingParamDTO {
  headingMode: WaypointHeadingModeDTO;
  headingAngle?: number;
  waypointPoiPoint?: AutoflyLocationCoordinate3D;
  waypointHeadingAngleEnable?: boolean;
  waypointHeadingPoiIndex?: number;
}

export enum WaypointHeadingModeDTO {
  FOLLOW_WAYLINE = 'followWayline',
  MANUALLY = 'manually',
  SMOOTH_TRANSITION = 'smoothTransition',
  FIXED = 'fixed',
  TOWARD_POI = 'towardPOI'
}

export enum WaypointTurnModeDTO {
  COORDINATE_TURN = 'coordinateTurn',
  TO_POINT_AND_STOP_WITH_DISCONTINUITY_CURVATURE = 'toPointAndStopWithDiscontinuityCurvature',
  TO_POINT_AND_STOP_WITH_CONTINUITY_CURVATURE = 'toPointAndStopWithContinuityCurvature',
  TO_POINT_AND_PASS_WITH_CONTINUITY_CURVATURE = 'toPointAndPassWithContinuityCurvature'
}

// Assuming AutoflyLocationCoordinate3D is defined elsewhere. If not, define it as needed.
export interface AutoflyLocationCoordinate3D {
  latitude: number;
  longitude: number;
  altitude: number;
}

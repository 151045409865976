import {Injectable} from '@angular/core';
import {Mission} from '@app/atlas/services/mission-settings.service';
import {MissionApiService} from '@app/atlas/services/mission-api.service';
import {MissionModel, MissionPoint, MissionType, SmartInspectMissionModel} from '@app/atlas/model/mission.model';
import {MissionBuilderService} from '@app/flights/services/mission-builder.service';

@Injectable({
  providedIn: 'root'
})
export class MissionImportJSONService {
  constructor(private missionBuilder: MissionBuilderService, protected missionApiService: MissionApiService) {}

  public async doImport(jsonString: string): Promise<Mission> {
    const content = JSON.parse(jsonString);

    if (this.isCorridorMission(content)) {
      return {...content, isCorridorMission: true};
    }

    const points = content.route || (content as MissionPoint[]);
    const waypoints = this.mapWaypoints(points);
    const mission = this.mapMission(content, waypoints);
    return this.missionBuilder.buildMissionFromWaypoints(mission);
  }

  private mapMission(
    content: MissionModel & SmartInspectMissionModel,
    points
  ): MissionModel & SmartInspectMissionModel {
    return {
      ...((content.name && {name: content.name}) || {name: 'Waypoint mission'}),
      ...(content.description && {description: content.description}),
      ...(content.overlay && {overlay: content.overlay}),
      ...(content.heightMode && {heightMode: content.heightMode}),
      ...(content.start && {start: content.start}),
      ...(content.speed && {speed: content.speed}),
      route: points,
      type: content.overlay || content.isSmartInspect ? MissionType.SMART_INSPECT : MissionType.WAYPOINT,
      isSmartInspect: !!content.overlay || content.isSmartInspect
    };
  }

  private mapWaypoints(points: MissionPoint[]): MissionPoint[] {
    return points.map(p => {
      let point;
      try {
        point = {
          ...(Object.hasOwn(p, 'lat') && {lat: parseFloat(p.lat.toString())}),
          ...(Object.hasOwn(p, 'lng') && {lng: parseFloat(p.lng.toString())}),
          ...(Object.hasOwn(p, 'altitude') && {altitude: parseFloat(p.altitude.toString())}),
          ...(Object.hasOwn(p, 'altitudeWGS') && {altitudeWGS: parseFloat(p.altitudeWGS.toString())}),
          ...(Object.hasOwn(p, 'altitudeEGM') && {altitudeEGM: parseFloat(p.altitudeEGM.toString())}),
          ...(Object.hasOwn(p, 'speed') && {speed: parseFloat(p.speed.toString())}),
          ...(Object.hasOwn(p, 'pitch') && {pitch: parseFloat(p.pitch.toString())}),
          ...(Object.hasOwn(p, 'heading') && {heading: parseFloat(p.heading.toString())}),
          ...(p.actions && {actions: p.actions})
        };
      } catch (e) {
        const msg = `Failed to parse waypoint ${p} ${e}`;
        console.error(msg);
        throw new Error(msg);
      }
      this.validatePoint(point);
      return p;
    });
  }

  private validatePoint(point: MissionPoint) {
    if (
      !Object.hasOwn(point, 'altitude') &&
      !Object.hasOwn(point, 'altitudeWGS') &&
      !Object.hasOwn(point, 'altitudeEGM')
    ) {
      throw Error('Missing altitude');
    }
    const requiredProps = ['lat', 'lng' /*, 'heading', 'pitch'*/]; // heading and pitch might be required
    this.missionBuilder.validate(point, requiredProps);
  }

  private isCorridorMission(jsonWaypoints) {
    return jsonWaypoints.route && jsonWaypoints.route.length > 0 && jsonWaypoints.route[0].hasOwnProperty('si');
  }
}

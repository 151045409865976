import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MissionApiService} from '@app/atlas/services/mission-api.service';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';
import {Mission, MissionSettingsService} from '@app/atlas/services/mission-settings.service';
import {switchMap, take} from 'rxjs';

@Component({
  selector: 'unleash-delete-mission-dialog',
  templateUrl: './delete-mission-dialog.component.html',
  styleUrls: ['./delete-mission-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteMissionDialogComponent {
  public isDeleting: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteMissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {mission: Mission},
    private missionApiService: MissionApiService,
    private missionManagerService: MissionManagerService,
    private missionSettingsService: MissionSettingsService
  ) {}

  public deleteMission() {
    this.isDeleting = true;
    const mission = this.data.mission;
    this.missionApiService
      .removeMission(mission.id)
      .pipe(switchMap(() => this.missionSettingsService.currentMission$.pipe(take(1))))
      .subscribe(currentMission => {
        const isCurrentlyLoaded = !!currentMission && currentMission.id === mission.id;
        if (isCurrentlyLoaded) {
          this.missionSettingsService.setMission(undefined);
          this.missionSettingsService.clearRoute();
        }
        this.missionManagerService.removeMission(mission);
        this.dialogRef.close(true);
      });
  }
}

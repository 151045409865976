<ng-container [ngSwitch]="teamScreen">
  <ng-container *ngSwitchCase="teamTileScreen.teams">
    <unleash-teams
      [teams]="teams"
      [menuItems]="menuItems"
      (addTeam)="emitAddTeam()"
      (searchTeam)="performSearch($event)"
      (team)="emitTeam($event)"
      (action)="emitAction($event)"
    ></unleash-teams>
  </ng-container>
  <ng-container *ngSwitchCase="teamTileScreen.addTeam">
    <unleash-add-team
      (cancel)="emitCancel()"
      (createTeam)="emitCreateTeam($event)"
      (back)="emitBack()"
    ></unleash-add-team>
  </ng-container>
  <ng-container *ngSwitchCase="teamTileScreen.teamDetails">
    <unleash-team-detail
      [team]="selectedTeam"
      [roles]="roles"
      [userBySelectedTeam]="userBySelectedTeam"
      (back)="emitBack()"
      (openAddUsersToTeam)="emitOpenAddUsersToTeam()"
      (action)="emitAction($event)"
      (userTeamAction)="emitUserTeamAction($event)"
      (userAction)="emitUserAction($event)"
    ></unleash-team-detail>
  </ng-container>
  <ng-container *ngSwitchDefault> </ng-container>
</ng-container>

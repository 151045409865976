import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UntilDestroy} from '@ngneat/until-destroy';
import {PlanModel, SupportCodeNames} from '@app/core/models/api/plan.model';
import {UserModel} from '@app/core/models/api/user-model';
import {addMonths, addYears} from 'date-fns';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CancelPlanDialog} from '../../../plans/components/cancel-plan/cancel-plan.component';
import {HelpService} from '@app/core/services/help.service';
import {AclPermissions} from '@app/core/models/api/acl.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-mini-plan-viewer',
  templateUrl: './mini-plan-viewer.component.html',
  styleUrls: ['./mini-plan-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniPlanViewerComponent {
  @Input() public user: UserModel;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('userPlan') public set setupUserPlan(userPlan: PlanModel) {
    this.userPlan = userPlan;
    const [id, level] = userPlan.id.split('_');
    this.planName = userPlan.planName;
    const REGULAR_PLANS_LEVEL = 2;
    if (parseInt(level) <= REGULAR_PLANS_LEVEL) {
      this.setSelectedPackages(id);
    }
    this.generateAutoRenewPlan();
  }
  public userPlan: PlanModel;

  @Input() public isAwsUser: boolean;

  public plans = {
    1: {
      name: 'Live Streaming',
      img: '/assets/icons/plans/camera.svg',
      isActive: false
    },
    2: {
      name: 'Modeling and GIS',
      img: '/assets/icons/plans/location.svg',
      isActive: false
    },
    3: {
      name: 'A.I. Analytics',
      img: '/assets/icons/plans/vector_graphic.svg',
      isActive: false
    }
  };

  public aclPermissions = AclPermissions;
  public supportCodeNames = SupportCodeNames;
  public userPlanSub: Subscription;
  public planName: string;
  public userSub: Subscription;
  // eslint-disable-next-line rxjs/finnish
  public renewDate$: BehaviorSubject<number> = new BehaviorSubject(null);
  // eslint-disable-next-line rxjs/finnish
  public plans$: BehaviorSubject<MiniPlanViewerModel> = new BehaviorSubject(this.plans);

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private helpService: HelpService
  ) {}

  public cancelSubscription(): void {
    const dialog = this.dialog.open(CancelPlanDialog, {
      width: '80vw',
      maxWidth: '800px'
    } as MatDialogConfig);
    dialog.afterClosed().subscribe();
  }

  public changePackage(): void {
    this.router.navigate(['/secure/plans/package-selector']);
  }

  public openBeacon(): void {
    this.helpService.goToKnowledgePage();
  }

  public setSelectedPackages(planName: string): void {
    const PLAN_ONE = 1;
    const PLAN_TWO = 2;
    const PLAN_THREE = 3;

    switch (planName) {
      case 'broadcast':
        this.plans[PLAN_ONE].isActive = true;
        this.plans[PLAN_TWO].isActive = false;
        this.plans[PLAN_THREE].isActive = false;
        break;
      case 'modelling':
        this.plans[PLAN_TWO].isActive = true;
        this.plans[PLAN_ONE].isActive = false;
        this.plans[PLAN_THREE].isActive = false;
        break;
      case 'insights':
        this.plans[PLAN_THREE].isActive = true;
        this.plans[PLAN_ONE].isActive = false;
        this.plans[PLAN_TWO].isActive = false;
        break;
      case 'control':
        this.plans[PLAN_TWO].isActive = true;
        this.plans[PLAN_THREE].isActive = true;
        this.plans[PLAN_ONE].isActive = false;
        break;
      case 'operational':
        this.plans[PLAN_ONE].isActive = true;
        this.plans[PLAN_TWO].isActive = true;
        this.plans[PLAN_THREE].isActive = false;
        break;
      case 'supervision':
        this.plans[PLAN_ONE].isActive = true;
        this.plans[PLAN_THREE].isActive = true;
        this.plans[PLAN_TWO].isActive = false;
        break;
      case 'unleashed':
        this.plans[PLAN_ONE].isActive = true;
        this.plans[PLAN_TWO].isActive = true;
        this.plans[PLAN_THREE].isActive = true;
        break;
    }
    this.plans$.next(this.plans);
  }

  public generateAutoRenewPlan(): void {
    const momentSubscriptionStart = this.getSubscriptionEnd() || new Date();
    const renewDate =
      this.user.subscriptionPeriod === 'month'
        ? addMonths(momentSubscriptionStart, 1)
        : addYears(momentSubscriptionStart, 1);

    this.renewDate$.next(renewDate.valueOf());
    this.cd.detectChanges();
  }

  public getSubscriptionEnd(): Date {
    if (this.user.subscriptionStart) {
      return new Date(this.user.subscriptionStart);
    }
    if (this.user.trialPeriodEnd) {
      return new Date(this.user.trialPeriodEnd);
    }
  }
}
interface MiniPlanViewerModel {
  [key: number]: {name: string; img: string; isActive: boolean};
}

import {Component, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {MatStepper} from '@angular/material/stepper';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {FileSizePipe} from '../../pipes/file-size.pipe';
import {EVENTS, UnleashAnalyticsService} from '../../../core/services/unleash-analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {filter, interval, map, Observable, take} from 'rxjs';
import {UploadFilesFacadeService} from '@app/shared/services/upload/upload-files-facade.service';
import {AclService} from '@app/core/services/acl.service';
import {AclPermissions} from '@app/core/models/api/acl.model';

@Component({
  selector: 'app-atlas-upload',
  templateUrl: './atlas-upload.component.html',
  providers: [FileSizePipe],
  styleUrls: ['./atlas-upload.component.scss']
})
export class AtlasUploadComponent {
  public ACCEPT_ASSET_FILES = [];
  @ViewChild('stepper')
  public stepper: MatStepper;
  public files: File[] = [];
  public translations = {ok: '', supportedAssets: ''};
  public isUploadingFiles$: Observable<boolean> = this.uploadFilesService.isUploading$;
  public isDone$: Observable<boolean> = this.isUploadingFiles$.pipe(map(isUploading => !isUploading));
  public isProcessingFiles$: Observable<boolean> = this.uploadFilesService.isProcessingFiles$;
  public totalProgress$: Observable<number> = this.uploadFilesService.totalProgress$;
  // eslint-disable-next-line no-magic-numbers
  public totalProcessing$: Observable<number> = interval(100).pipe(
    // eslint-disable-next-line no-magic-numbers
    map(interval => (interval * 10000) / this.uploadTimeout)
  );
  private uploadTimeout: number = 0;

  constructor(
    private uploadFilesService: UploadFilesFacadeService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AtlasUploadComponent>,
    private unleashAnalytics: UnleashAnalyticsService,
    private translateService: TranslateService,
    private aclService: AclService
  ) {
    this.translateService.get(['common.ok', 'atlas.upload.supportedAssets']).subscribe(translations => {
      const {'common.ok': ok, 'atlas.upload.supportedAssets': supportedAssets} = translations;
      this.translations = {ok, supportedAssets};
    });

    this.aclService.hasSetupPermission$
      .pipe(
        filter(hasSetupPermission => !!hasSetupPermission),
        take(1)
      )
      .subscribe(() => {
        let acceptAssetFiles = [];
        // Provisional
        if (this.aclService.hasPermission(AclPermissions.AtlasApiCreate)) {
          acceptAssetFiles = ['.kmz', '.geojson', '.json', '.txt', 'csv', '.kml', '.tif', '.tiff', '.geotif'];
        }
        this.ACCEPT_ASSET_FILES = acceptAssetFiles;
      });
  }

  /* drag and drop handler */
  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.fileListContainsExactlyCorrectFileTypes(file)) {
            this.files.push(file);
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  /* file picker handler */
  public onInputFileSelection($event) {
    const fileList: FileList = $event.target.files;
    for (let i = 0; i < fileList.length; ++i) {
      const file: File = fileList.item(i);
      if (this.fileListContainsExactlyCorrectFileTypes(file)) {
        this.files.push(file);
      }
    }
    if (this.files.length === 0) {
      this.snackBar.open(this.translations.supportedAssets, this.translations.ok, {
        duration: 3000,
        verticalPosition: 'bottom',
        politeness: 'assertive'
      } as MatSnackBarConfig);
    }
    this.clearSelection($event);
  }

  public upload() {
    if (this.files.length === 0) {
      return;
    }
    const minimumTimeout = 5000;
    this.uploadTimeout = Math.max(minimumTimeout, this.totalUploadTime());
    this.uploadFilesService.setAtlasUploadTimeout(this.uploadTimeout);
    this.uploadFilesService.startUploadInAtlas(this.files, {});

    this.uploadFilesService.isUploading$
      .pipe(
        filter(isUploading => !isUploading),
        take(1)
      )
      .subscribe(
        () => {
          this.next();
          this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_UPLOAD);
        },
        error => {
          console.info('err', error);
          this.snackBar.open(error, this.translations.ok, {
            duration: 3000,
            verticalPosition: 'bottom',
            politeness: 'assertive'
          } as MatSnackBarConfig);
        }
      );
  }

  public next() {
    this.files = [];
    this.stepper.next();
  }

  /**
   * clear selection so that change event will fire on selecting same file again
   * */
  public clearSelection(event) {
    event.target.value = '';
  }

  private fileListContainsExactlyCorrectFileTypes(file: File) {
    return this.ACCEPT_ASSET_FILES.reduce((prev, curr) => {
      return prev || file?.name.toLowerCase().endsWith(curr);
    }, false);
  }

  private totalUploadTime(): number {
    return (
      this.files
        // eslint-disable-next-line no-magic-numbers
        .reduce((accumulator, currentValue) => accumulator + currentValue.size, 0) / 10000
    );
  }
}

import {Action, createReducer, on} from '@ngrx/store';
import {
  actionNewPasswordFormError,
  actionNewPasswordFormSubmit,
  actionNewPasswordFormSuccess
} from './new-password.actions';
import {NewPassword} from './new-password.model';

export interface NewPasswordFormState {
  form: NewPassword;
  error?: string;
  inProgress: boolean;
}

export const initialState: NewPasswordFormState = {
  form: {email: '', password: '', newPassword: ''},
  inProgress: false
};

const reducer = createReducer(
  initialState,
  on(actionNewPasswordFormSubmit, (state, {form}) => ({
    ...state,
    error: undefined,
    inProgress: true,
    form
  })),
  on(actionNewPasswordFormError, (state, {error}) => ({
    ...state,
    error,
    inProgress: false
  })),
  on(actionNewPasswordFormSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: false
  }))
);

export function newPasswordStateReducer(state: NewPasswordFormState | undefined, action: Action): NewPasswordFormState {
  return reducer(state, action);
}

<div class="plan-unpaid__container">
  <div class="plan-unpaid__image">
    <img src="assets/images/onboarding/globe.png" alt="Unleash live trial expired image" />
  </div>

  <div class="plan-unpaid__content">
    <div class="plan-unpaid__link" (click)="signOut()">
      <mat-icon class="plan-unpaid__link__icon">vpn_key</mat-icon>
      <span class="plan-unpaid__link__text" translate="plans.expired.signOut"></span>
    </div>

    <div class="plan-unpaid__message">
      <h2 class="plan-unpaid__message__title" translate="plans.expired.noPlanActive"></h2>
      <p class="plan-unpaid__message__info" translate="plans.expired.planExpiredText"></p>
      <p class="plan-unpaid__message__info" translate="plans.expired.longText"></p>
      <p class="plan-unpaid__message__info" translate="plans.expired.shortText"></p>
    </div>

    <div class="plan-unpaid__actions">
      <button
        class="plan-unpaid__action plan-unpaid__action--upgrade"
        mat-flat-button
        color="primary"
        translate="plans.expired.upgrade"
        routerLink="/plans/package-selector"
      ></button>
      <button
        class="plan-unpaid__action plan-unpaid__action--contact"
        mat-flat-button
        translate="plans.expired.contactUs"
        (click)="goToContactUs()"
      ></button>
    </div>
  </div>

  <div class="plan-unpaid__beacon">
    <a
      class="plan-unpaid__beacon__link help"
      mat-icon-button
      (click)="goToContactUs()"
      matTooltip="{{ 'common.needHelp' | translate }}"
    >
      <mat-icon title="{{ 'common.help' | translate }}">help</mat-icon>
    </a>
  </div>
</div>

import {Directive, HostListener, Input} from '@angular/core';
import {MissionSettingsService} from '../services/mission-settings.service';

@Directive({
  selector: '[ulDefaultSettings]'
})
export class DefaultSettingsDirective {
  @Input('ulDefaultSettings') public setting: string;

  @HostListener('change', ['$event'])
  public changeSetting(event) {
    this.missionSettingsService.updateSettings({[this.setting]: event.value});
    this.missionSettingsService.editSettings();
  }

  constructor(private missionSettingsService: MissionSettingsService) {}
}

import {Observable, map, tap} from 'rxjs';
import {ImagePath} from '../models/gui/image-path';
import {LinkMenuItem} from '../models/gui/link-menu-item';
import {MenuItem} from '../models/gui/menu-item';
import {NewTabMenuItem} from '../models/gui/newtab-menu-item';
import {MediaObserver} from '@angular/flex-layout';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavMenuService {
  public MENU_ITEMS = [];

  public isMobileView$: Observable<boolean> = this.observableMedia.asObservable().pipe(
    map(change => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      const isMobileView = mediaAlias === 'sm' || mediaAlias === 'xs';
      return isMobileView;
    }),
    tap(isMobileView => {
      if (isMobileView) {
        this.MENU_ITEMS = this.MENU_ITEMS_MOBILE_LIST;
        return;
      }
      this.MENU_ITEMS = [
        new LinkMenuItem('core.sidebar.menu.stream', ImagePath.LIVE_STREAM_ICON_IMG_PATH, '/secure/live'),
        new LinkMenuItem('core.sidebar.menu.library', ImagePath.LIBRARY_ICON_IMG_PATH, '/secure/library'),
        new LinkMenuItem('core.sidebar.menu.atlas', ImagePath.FUSION_ATLAS_ICON_IMG_PATH, '/secure/atlas'),
        new LinkMenuItem('core.sidebar.menu.analysis', ImagePath.ANALYSIS_ICON_IMG_PATH, '/secure/analysis'),
        new LinkMenuItem(
          'core.sidebar.menu.remoteCockpit',
          ImagePath.MISSION_CONTROL_ICON_IMG_PATH,
          '/secure/flights/cockpit'
        )
      ];
    })
  );

  public MENU_ITEMS_MOBILE = [
    new LinkMenuItem('core.sidebar.menu.stream', ImagePath.LIVE_STREAM_ICON_IMG_PATH, '/secure/live'),
    new LinkMenuItem('core.sidebar.menu.library', ImagePath.LIBRARY_ICON_IMG_PATH, '/secure/library'),
    new LinkMenuItem('core.sidebar.menu.atlas', ImagePath.FUSION_ATLAS_ICON_IMG_PATH, '/secure/atlas'),
    new LinkMenuItem('core.sidebar.menu.account', ImagePath.ACCOUNT_ICON_IMG_PATH, '/secure/profile')
  ];

  public MENU_ITEMS_MOBILE_LIST = [
    new LinkMenuItem(
      'core.sidebar.menu.remoteCockpit',
      ImagePath.MISSION_CONTROL_ICON_IMG_PATH,
      '/secure/flights/cockpit'
    ),
    new LinkMenuItem('core.sidebar.menu.analysis', ImagePath.ANALYSIS_ICON_IMG_PATH, '/secure/analysis')
  ];

  public AI_APPS_STORE_MENU_ITEMS = new LinkMenuItem(
    'core.sidebar.menu.aiAppStore',
    ImagePath.AI_APPS_STORE_ICON_IMG_PATH,
    '/secure/store',
    null,
    {
      clearFilters: true
    }
  );

  public MENU_ITEMS_JOBS = new LinkMenuItem('core.sidebar.menu.jobs', ImagePath.JOBS, '/secure/jobs');

  public MENU_ITEMS_END = [
    new NewTabMenuItem('core.sidebar.menu.notifications', ImagePath.NOTIFICATIONS, ''),
    new NewTabMenuItem('core.sidebar.menu.help', ImagePath.HELP_ICON_IMG_PATH, 'https://knowledge.unleashlive.com/')
  ];

  public DEVELOPER_DASHBOARD_MENU_ITEM: MenuItem[] = [
    new LinkMenuItem(
      'core.sidebar.menu.developer-dashboard',
      ImagePath.DEVELOPER_DASHBOARD_IMG_PATH,
      '/secure/developer-dashboard'
    )
  ];

  constructor(private observableMedia: MediaObserver) {}
}

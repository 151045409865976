import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {GeojsonFeatureState} from '@app/atlas/model/marker.model';
import {ThumblerParams} from '@app/library/models/thumbler-params.model';
import {ParamsHelper} from '@app/library/services/params-helper';
import {ThumblerSourceCategoryModel} from '@app/shared/pipes/models/thumbler.model';
import {environment} from 'environments/environment';

@Component({
  selector: 'unleash-marker-status-info',
  templateUrl: './marker-status-info.component.html',
  styleUrls: ['./marker-status-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerStatusInfoComponent implements OnInit {
  @Input() public feature: any;
  @Input() public assetName: string;
  public parsedUrl: string;
  public geojsonFeatureState = GeojsonFeatureState;

  public ngOnInit(): void {
    const thumbParams: ThumblerParams = {
      width: '400',
      height: '400',
      quality: '75'
    };
    this.parsedUrl = ParamsHelper.getUrlWithParams(
      `${environment.THUMBLER_API_CDN}/${ThumblerSourceCategoryModel.library}/${this.feature.properties.rawPath}`,
      thumbParams
    );
  }
}

<button
  *uaHasPermission="aclPermissions.MainApiUpdateDevice"
  class="layer__button"
  mat-menu-item
  (click)="showDeviceDialog(device)"
>
  <span class="device-menu">
    <mat-icon inline="true" class="layer__icon">settings</mat-icon>
    {{ 'atlas.control.deviceSettings' | translate }}
  </span>
</button>
<button class="layer__button" mat-menu-item (click)="copyLinkAsset(device)">
  <span class="device-menu">
    <mat-icon inline="true" class="layer__icon">share</mat-icon>
    {{ 'atlas.control.share' | translate }}
  </span>
</button>
<button
  class="layer__button"
  *ngIf="(atlasService.activeStreamingDevice$ | async)?.id !== device?.id"
  mat-menu-item
  (click)="toggleStream.emit(device.id)"
>
  <span class="device-menu">
    <mat-icon class="layer__icon" svgIcon="videocam_on"></mat-icon>
    {{ 'atlas.control.showStream' | translate }}
  </span>
</button>
<button
  class="layer__button"
  *ngIf="device?.id === (atlasService.activeStreamingDevice$ | async)?.id"
  mat-menu-item
  (click)="toggleStream.emit(null)"
>
  <span class="device-menu">
    <mat-icon class="layer__icon" svgIcon="videocam_off"></mat-icon>
    {{ 'atlas.control.hideStream' | translate }}
  </span>
</button>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AngularResizeEventModule} from 'angular-resize-event';
import {UnleashMaterialModule} from '@app/theme/unleash-material.module';
import {AnnotationDialogUiComponent} from './components/annotation-dialog-ui/annotation-dialog-ui.component';
import {UnleashChipListComponent} from './components/unleash-chip-list/unleash-chip-list.component';
import {UnleashSeverityButtonGroupComponent} from './components/unleash-severity-button-group/unleash-severity-button-group.component';
import {ManageZoneService} from './services/manage-zone.service';

@NgModule({
  imports: [CommonModule, UnleashMaterialModule, AngularResizeEventModule],
  declarations: [AnnotationDialogUiComponent, UnleashChipListComponent, UnleashSeverityButtonGroupComponent],
  providers: [ManageZoneService],
  exports: [
    AnnotationDialogUiComponent,
    UnleashChipListComponent,
    UnleashSeverityButtonGroupComponent,
    AngularResizeEventModule
  ]
})
export class AnnotationSharedModule {}

import {createAction, props} from '@ngrx/store';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {ForgotPassword} from './forgot-password.model';

export const actionForgotPasswordFormSubmit = createAction(
  '[ForgotPasswordForm] Submit',
  props<{form: ForgotPassword}>()
);

export const actionForgotPasswordFormError = createAction(
  '[ForgotPasswordForm] Submit error',
  props<{error: AuthErrorModel}>()
);

export const actionForgotPasswordFormSuccess = createAction('[ForgotPasswordForm] Submit success');

export const actionSaveEmail = createAction('[SignInForm] Save email', props<{payload: {email: string}}>());

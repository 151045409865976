import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UserService} from '../../../core/services/api/user.service';
import {LoadingDialogComponent} from '../loading-dialog/loading-dialog.component';
import {timer} from 'rxjs';
import {debounce, filter} from 'rxjs/operators';
import {Addon} from '@app/store/addon/models/addon';
import {UPLOAD_LOGO_SOURCE} from '@app/profile/models/upload-logo-source.model';
import {LogoUploadComponent} from '../logo-upload/logo-upload.component';
import {MatListModule} from '@angular/material/list';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'addon-details',
  templateUrl: './addon-details.component.html',
  styleUrls: ['./addon-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, ReactiveFormsModule, MatListModule, LogoUploadComponent]
})
export class AddonDetailsComponent implements OnInit, OnChanges {
  @Output() addonSaved: EventEmitter<Addon> = new EventEmitter<Addon>();
  @Output() addonSaveFailed: EventEmitter<Addon> = new EventEmitter<Addon>();
  @Input() isReadOnly = true;
  @Input() addon: Addon;
  @Input() expanded: boolean = false;
  @Input() allowPhotoEdit = false;
  logoUploadDialogRef: MatDialogRef<LoadingDialogComponent>;
  addonForm: UntypedFormGroup;
  CLIPPING_TYPES: string[] = ['Stream', 'Clipping', 'Media'];
  CATEGORIES: string[] = [
    'General A.I.',
    'Solutions',
    'Real Estate',
    'Construction',
    'Sport',
    'Entertainment',
    'Agriculture',
    'Safety'
  ];
  public uploadLogoSource: typeof UPLOAD_LOGO_SOURCE = UPLOAD_LOGO_SOURCE;

  constructor(
    public router: Router,
    public userService: UserService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.createForm();
  }

  ngOnChanges() {
    if (this.isReadOnly) {
      return;
    }
    this.addonForm.setValue({
      name: this.addon.name || '',
      subtitle: this.addon.subtitle || '',
      description: this.addon.description || '',
      developer: this.addon.developer || '',
      category: this.addon.category || 'General A.I.',
      price: this.addon.price === 0 ? 0 : this.addon.price || '',
      currency: this.addon.currency || '',
      logo: this.addon.logo,
      clipping: (this.addon.data ? this.addon.data.clipping : false) || false,
      stream: (this.addon.data ? this.addon.data.stream : false) || true,
      image: (this.addon.data ? this.addon.data.image : false) || false,
      classes: (this.addon.data ? this.addon.data.classes : false) || '',
      screenshots: !!this.addon.screenshots ? this.addon.screenshots : []
    });
  }

  ngOnInit() {
    this.addonForm.valueChanges
      .pipe(filter(data => this.addonForm.valid))
      .pipe(debounce(val => timer(1000)))
      .subscribe(data => this.updateAddon(data));
    this.addonForm.valueChanges.pipe(filter(data => this.addonForm.invalid)).subscribe(data => this.failAddon(data));
  }

  createForm() {
    this.addonForm = this.formBuilder.group({
      name: ['', Validators.required],
      subtitle: ['', Validators.required],
      description: ['', Validators.required],
      developer: ['', Validators.required],
      category: ['', Validators.required],
      price: ['', [Validators.required, Validators.pattern(/[0-9]+/)]],
      currency: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      logo: ['', Validators.required],
      clipping: [false, this.isReadOnly ? [] : Validators.required],
      stream: [false, this.isReadOnly ? [] : Validators.required],
      image: [false, this.isReadOnly ? [] : Validators.required],
      classes: ['', [Validators.required, Validators.min(0)]],
      screenshots: [[]]
    });
  }

  setLogoUrl(logoUrl: string) {
    this.addonForm.controls['logo'].setValue(logoUrl);
  }

  imageUpload(isUploading: boolean) {
    if (isUploading) {
      this.logoUploadDialogRef = this.dialog.open(LoadingDialogComponent, {
        data: {text: 'Image upload in progress'},
        width: '80vw',
        maxWidth: '800px'
      } as MatDialogConfig);
    }

    if (!!this.logoUploadDialogRef) {
      this.logoUploadDialogRef.close();
    }
  }

  setScreenshotUrl(screenshotUrl: string, i: number) {
    this.addonForm.controls['screenshots'].value()[i] = screenshotUrl;
  }

  addNewScreenshot(screenshotUrl: string) {
    this.addonForm.controls['screenshots'].value().push(screenshotUrl);
  }

  private updateAddon(formData: any) {
    if (!this.addon) {
      return;
    }
    this.addon.subtitle = formData.subtitle;
    this.addon.name = formData.name;
    this.addon.description = formData.description;
    this.addon.developer = formData.developer;
    this.addon.logo = formData.logo;
    this.addon.price = Number(formData.price);
    this.addon.currency = formData.currency;
    this.addon.category = formData.category;
    this.addon.data.clipping = formData.clipping;
    this.addon.data.stream = formData.stream;
    this.addon.data.image = formData.image;
    this.addon.data.classes = formData.classes;
    this.addon.screenshots = formData.screenshots;
    this.addonSaved.emit(this.addon);
  }

  private failAddon(formData: any) {
    if (!this.addon) {
      return;
    }
    this.addonSaveFailed.emit(this.addon);
  }

  private addPreviewImage(file: any) {
    const reader = new FileReader();
    reader.onload = e => {
      this.addon.logo = (e.target as any).result;
    };
    reader.readAsDataURL(file);
  }
}

import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MFA} from '@app/auth/models/auth.models';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {actionSetupMFA} from '../sign-in/sign-in.actions';
import {AuthService} from '@app/auth/services/auth.service';

@Component({
  selector: 'unleash-setup-mfa',
  templateUrl: './setup-mfa.component.html',
  styleUrls: ['./setup-mfa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetupMfaComponent implements OnDestroy, AfterViewInit {
  public setupMfaForm: FormGroup = this.fb.group({
    mfa: [MFA.TEXT_MESSAGE]
  });
  public mfa: typeof MFA = MFA;
  public isInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasSetupTotpError: BehaviorSubject<boolean> = this.authService.hasSetupTotpError;
  private hasSetupTotpErrorSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store,
    private authService: AuthService
  ) {}

  public ngOnDestroy(): void {
    if (this.hasSetupTotpErrorSub) {
      this.hasSetupTotpErrorSub.unsubscribe();
      this.hasSetupTotpErrorSub = null;
    }
  }

  public ngAfterViewInit(): void {
    this.hasSetupTotpErrorSub = this.hasSetupTotpError.subscribe(hasError => {
      if (hasError) {
        this.isInProgress.next(false);
        this.setupMfaForm.controls.code.setErrors({invalidCode: true});
      }
    });
  }

  public continue(): void {
    this.isInProgress.next(true);
    this.store.dispatch(
      actionSetupMFA({
        mfaType: this.setupMfaForm.value.mfa
      })
    );
  }

  public signOut(): void {
    this.router.navigate(['/auth/sign-in']);
  }
}

import {createAction, props} from '@ngrx/store';
import {FlightLogDetailData} from '../flight-log-detail-dialog.component';

export enum FlightLogDetailDialogActionTypes {
  LoadFlightLogs = '[FlightLogDetailDialog] Load Flight Logs',
  LoadFlightLogsSuccess = '[FlightLogDetailDialog] Load Flight Logs Success',
  LoadFlightLogsFail = '[FlightLogDetailDialog] Load Flight Logs Fail',

  DisplayLoading = '[FlightLogDetailDialog] Display Loading',
  HideLoading = '[FlightLogDetailDialog] Hide Loading',
  SetFlightLogDetailData = '[FlightLogDetailDialog] Set Flight Log Detail Data'
}

export const loadFlightLogs = createAction(
  FlightLogDetailDialogActionTypes.LoadFlightLogs,
  props<{payload: {url: string}}>()
);

export const loadFlightLogsSuccess = createAction(
  FlightLogDetailDialogActionTypes.LoadFlightLogsSuccess,
  props<{payload: {data: FlightLogDetailData}}>()
);

export const loadFlightLogsFail = createAction(
  FlightLogDetailDialogActionTypes.LoadFlightLogsFail,
  props<{payload: {error: string}}>()
);

export const displayLoading = createAction(
  FlightLogDetailDialogActionTypes.DisplayLoading
);

export const hideLoading = createAction(
  FlightLogDetailDialogActionTypes.HideLoading
);

export const setFlightLogDialogDetailData = createAction(
  FlightLogDetailDialogActionTypes.SetFlightLogDetailData,
  props<{payload: {data: FlightLogDetailData}}>()
);

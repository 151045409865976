import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDeleteDialog} from './confirm-delete-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [ConfirmDeleteDialog],
  imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule, MatIconModule],
  exports: [ConfirmDeleteDialog]
})
export class ConfirmDeleteDialogModule {}

import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {embedDashboard} from 'amazon-quicksight-embedding-sdk';
import {DashboardApiService} from '@app/data/services/dashboard-api.service';
import {DashboardService} from '@app/data/services/dashboard.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-data-dashboard-component',
  templateUrl: './data-dashboard.component.html',
  styleUrls: ['./data-dashboard.component.scss']
})
export class DataDashboardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('embeddingContainer', {read: ElementRef, static: false})
  dashboardRef: ElementRef;
  @Input() isAtlasDashboard: boolean = false;
  dashboard;
  isLoading$ = new BehaviorSubject(true);
  getReportLinkSubscription: Subscription;
  getLgaNameSubscription: Subscription;

  @Input()
  dashboardLink: string = null;

  constructor(private dashboardApiService: DashboardApiService, private dashboardService: DashboardService) {}

  ngAfterViewInit(): void {
    this.loadDashboard();
  }

  ngOnDestroy(): void {
    if (this.getReportLinkSubscription) {
      this.getReportLinkSubscription.unsubscribe();
    }
    if (this.getLgaNameSubscription) {
      this.getLgaNameSubscription.unsubscribe();
    }
  } 

  loadDashboard() {
    let isDashboardAlreadyLoaded = this.dashboardRef.nativeElement.firstChild?.contentWindow;
    if (isDashboardAlreadyLoaded) {
      return;
    }

    let dashboardId = this.isAtlasDashboard ? this.dashboardService.dashboardId : '';

    if (this.dashboardLink) {
      const urlPart = this.dashboardLink.split('?')[0];
      const dashboardIdPart = urlPart.split('/');
      dashboardId = dashboardIdPart[dashboardIdPart.length - 1];
    }

    this.getReportLinkSubscription = this.dashboardApiService
      .getReportLink$(dashboardId)
      .pipe(take(1))
      .subscribe((reportLink: string) => {
        const options = {
          url: reportLink,
          container: this.dashboardRef.nativeElement,
          parameters: {
            LGA: '[ALL]'
          },
          scrolling: 'no',
          iframeResizeOnSheetChange: false,
          locale: 'en-US',
          footerPaddingEnabled: true,
          sheetTabsDisabled: false,
          printEnabled: false,
          undoRedoDisabled: this.isAtlasDashboard,
          resetDisabled: this.isAtlasDashboard
        };
        this.isLoading$.next(false);
        this.dashboard = embedDashboard(options);
        this.dashboard.on('load', () => {
          this.getLgaNameSubscription = this.dashboardService.lgaName$.subscribe(lga => {
            this.dashboard.setParameters({LGA: lga});
          });
        });
      });
  }
}

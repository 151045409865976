import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'alertDisplayIcon'
})
export class AlertDisplayIconPipe implements PipeTransform {
  alertsIcon = {info: 'info', warning: 'warning', alert: 'info'};

  transform(alertId: string): string {
    return this.alertsIcon[alertId];
  }
}

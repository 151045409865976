<ng-container *ngIf="selectedUser">
  <h3 *ngIf="hasToDisplaySubtitle" class="subtitle">{{ 'profile.users.teams' | translate }}</h3>
  <ng-container [ngSwitch]="userTeamsScreen">
    <ng-container *ngSwitchCase="userTeamTileScreen.userTeams">
      <unleash-user-teams
        [userTeams]="userTeams"
        [menuItems]="menuItems"
        [selectedUser]="selectedUser"
        [roles]="roles"
        (addUserTeam)="emitAddUsers()"
        (searchUserTeam)="performSearch($event)"
        (userTeam)="emitUserTeam($event)"
        (action)="emitAction($event)"
      ></unleash-user-teams>
    </ng-container>
    <ng-container *ngSwitchCase="userTeamTileScreen.addToUserTeam">
      <unleash-add-user-to-team
        (cancel)="emitCancel()"
        (invite)="emitInvite($event)"
        (back)="emitBack()"
        [roles]="roles"
        [user]="selectedUser"
      ></unleash-add-user-to-team>
    </ng-container>
    <ng-container *ngSwitchCase="userTeamTileScreen.userTeamDetails">
      <unleash-team-detail [team]="selectedUserTeam" (back)="emitBack()"></unleash-team-detail>
    </ng-container>
    <ng-container *ngSwitchDefault> </ng-container>
  </ng-container>
</ng-container>

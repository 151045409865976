import {AnalysisFilter} from '@app/analysis/models/filter.model';
import {Integration} from '@app/analysis/models/integration.model';
import {Paginator} from '@app/analysis/models/paginator.model';
import {ParsedReport, ReportModel} from '../models/analysis.model';

export interface ReportsState {
  isLoading: boolean;
  reports: ReportModel[];
  displayedReports: ParsedReport[];
  paginator: Paginator;
  isIntegrationLoading: boolean;
  integrations: Integration[];
  displayedIntegrations: Integration[];
  apiKey: string;
  isLoadingApiKey: boolean;
  filter: AnalysisFilter;
  isApiKeyActionInProgress: boolean;
}

export const initialState: ReportsState = {
  reports: [],
  displayedReports: [],
  paginator: null,
  isLoading: false,
  integrations: [],
  displayedIntegrations: [],
  isIntegrationLoading: false,
  apiKey: null,
  isLoadingApiKey: false,
  isApiKeyActionInProgress: false,
  filter: {
    from: null,
    to: null,
    timeFrom: null,
    timeTo: null,
    search: '',
    type: '',
    status: ''
  }
};

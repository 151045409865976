import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../core/services/local-storage/local-storage.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  languages: ILanguage[] = [
    {code: 'en', name: 'English'},
    {code: 'de', name: 'Deutsch'}
  ];

  private storageKey = 'LANGUAGE';

  constructor(private localStorageService: LocalStorageService, private translateService: TranslateService) {}

  useUserLanguage() {
    const userLanguage: string = this.localStorageService.getItem(this.storageKey);

    if (userLanguage) {
      this.translateService.setDefaultLang(userLanguage);
      this.translateService.use(userLanguage);
      // moment.locale(userLanguage);
    } else {
      const languageMatch = RegExp(this.languages.map(language => language.code).join('/'));
      const browserLanguage = this.translateService.getBrowserLang();
      const defaultLanguage = browserLanguage.match(languageMatch) ? browserLanguage : 'en';

      this.translateService.setDefaultLang(defaultLanguage);
      this.translateService.use(defaultLanguage);
      this.localStorageService.setItem(this.storageKey, defaultLanguage);
      // moment.locale(defaultLanguage);
    }
  }

  setUserLanguage(languageCode: string) {
    this.translateService.use(languageCode);
    this.translateService.setDefaultLang(languageCode);
    this.localStorageService.setItem(this.storageKey, languageCode);
    // moment.locale(languageCode);
  }

  getUserLanguage(): string {
    this.useUserLanguage();
    return this.localStorageService.getItem(this.storageKey);
  }
}

interface ILanguage {
  code: string;
  name: string;
}

<button
  mat-raised-button
  color="basic"
  class="mat-elevation-z8 square-button"
  #matMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="annotationControlMenu"
  (menuClosed)="onCloseMenu()"
  (click)="onOpenMenu()"
  matTooltip="{{ 'library.actions.annotate' | translate }}"
  matTooltipPosition="right"
  [analyticsTrackerType]="events.ATLAS_ANNOTATIONS"
  [analyticsProperties]="{type: 'Open'}"
  *uaHasPermission="aclPermissions.AtlasApiUpdate"
>
  <mat-icon>edit</mat-icon>
</button>
<mat-menu
  class="custom-mat-menu-annotation"
  id="annotation-control-menu"
  #annotationControlMenu="matMenu"
  xPosition="after"
  hasBackdrop="false"
>
  <unleash-annotation-dialog-ui [formGroup]="formAnnotation" (cancel)="cancel()" (done)="done()">
    <ng-container>
      <ng-container *ngIf="formAnnotation.controls.type">
        <strong class="item-title">{{ 'analysis.data.type' | translate }}</strong>
        <unleash-chip-list
          formControlName="type"
          placeholder="{{ 'atlas.annotation.enterType' | translate }}"
        ></unleash-chip-list>
      </ng-container>

      <ng-container *ngIf="!!privateData">
        <mat-form-field *ngIf="formAnnotation?.controls?.shape?.value?.geometry?.type">
          <mat-label>{{ 'atlas.annotation.assetType' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="label" [compareWith]="valueComparator">
            <mat-option
              *ngFor="let level of privateData[formAnnotation.controls.shape.value.geometry.type]; let index = index"
              [value]="privateData[formAnnotation.controls.shape.value.geometry.type][index]"
            >
              {{ level.attr_label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="formAnnotation.controls.label && formAnnotation.controls.label.value">
          <ng-container *ngFor="let facility of formAnnotation.controls.label.value.attr_keys; let index = index">
            <mat-form-field *ngIf="facility.values">
              <mat-label>{{ facility.label }}</mat-label>
              <mat-select [formControlName]="facility.key">
                <mat-option *ngFor="let value of facility.values" [value]="value">
                  {{ value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!facility.values">
              <mat-label>{{ facility.label }}</mat-label>
              <input
                matInput
                placeholder="{{ 'common.value' | translate }}"
                autocomplete="off"
                [formControlName]="facility.key"
              />
            </mat-form-field>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="formAnnotation.controls.severity">
        <strong class="item-title">{{ 'library.imageAnnotation.editAnnotation.severity' | translate }}</strong>
        <unleash-severity-button-group formControlName="severity"></unleash-severity-button-group>
        <mat-error
          class="item-title__error-message"
          *ngIf="
            formAnnotation.controls.severity.invalid &&
            (formAnnotation.controls.severity.dirty || formAnnotation.controls.severity.touched)
          "
          >Severity is required</mat-error
        >
      </ng-container>

      <ng-container *ngIf="formAnnotation.controls.comment">
        <strong class="item-title">{{ 'library.imageAnnotation.editAnnotation.comment' | translate }}</strong>
        <mat-form-field>
          <textarea
            matInput
            rows="2"
            autocomplete="off"
            formControlName="comment"
            placeholder="{{ 'atlas.annotation.addComment' | translate }}"
          ></textarea>
        </mat-form-field>
      </ng-container>
    </ng-container>
  </unleash-annotation-dialog-ui>
</mat-menu>
<div class="fab-buttons over-map" *ngIf="annotationData.length > 0">
  <button
    *uaHasPermission="aclPermissions.AtlasApiCreate"
    class="save-layer-icon"
    color="red"
    mat-fab
    (click)="saveAllShapes()"
    matTooltipPosition="right"
    matTooltip="{{ 'atlas.annotation.saveLayer' | translate }}"
  >
    <mat-icon class="icon-size-16">save</mat-icon>
  </button>
</div>

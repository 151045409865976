<form [formGroup]="form">
  <mat-form-field class="full-width">
    <input
      type="text"
      matInput
      formControlName="name"
      placeholder="{{ 'atlas.move.newGroupName' | translate }}"
      autocomplete="off"
    />
    <mat-error *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)">
      {{ 'atlas.move.required' | translate }}
    </mat-error>
  </mat-form-field>
</form>

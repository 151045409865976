import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {StreamingErrors} from '../models/streaming-errors.models';
import * as actions from '../store/live.actions';
import {
  selectErrorCode,
  selectErrorMessage,
  selectFirstDeviceId,
  selectGridState,
  selectIsInitialized,
  selectIsSelectedDeviceRunningModel,
  selectLayoutState,
  selectLiveDevicesId,
  selectSelectedDeviceId,
  selectStreamisEnablingLivePage,
  selectStreamisLoadingSlug
} from '../store/live.selectors';
import {Addon} from '@app/store/addon/models/addon';
import {GridLayout, GridState} from '../models/grid-state.model';

@Injectable({
  providedIn: 'root'
})
export class LiveFacadeService {
  public liveDevicesId$ = this.store.select(selectLiveDevicesId);
  public isInitialized$ = this.store.select(selectIsInitialized);
  public layoutState$ = this.store.select(selectLayoutState);
  public isSelectedDeviceRunningModel$ = this.store.select(selectIsSelectedDeviceRunningModel);
  public selectedDeviceId$ = this.store.select(selectSelectedDeviceId);
  public gridState$ = this.store.select(selectGridState);
  public firstDeviceId$ = this.store.select(selectFirstDeviceId);

  constructor(private store: Store) {}

  public enablePublicLivePage = (companyId: string, shared: {[key: string]: boolean}) =>
    this.store.dispatch(actions.enablePublicLivePage({companyId, shared}));

  public generateCompanySlug = (companyId: string, companySlug: string) =>
    this.store.dispatch(actions.generateCompanySlug({companyId, companySlug}));

  public showCopyUrlMessage = () => this.store.dispatch(actions.showCopyUrlMessage());

  public isLoadingSlug = () => this.store.dispatch(actions.loadingSlug());

  public isEnablingLivePage = () => this.store.dispatch(actions.enablingLivePage());

  public supportIOSErrorMessage = () => this.store.dispatch(actions.supportIOSErrorMessage());

  public updateErrorMessage = (errorMessage: string) => this.store.dispatch(actions.updateErrorMessage({errorMessage}));

  public updateErrorCode = (errorCode: StreamingErrors) => this.store.dispatch(actions.updateErrorCode({errorCode}));

  public updateDevice = (device: UserDeviceJoined) => this.store.dispatch(actions.updateDevice({device}));

  public verifyDevicesAndJoinChannel = (deviceId: string) =>
    this.store.dispatch(actions.verifyDevicesAndJoinChannel({deviceId}));

  public joinChannel = () => this.store.dispatch(actions.joinChannel());

  public renewToken = () => this.store.dispatch(actions.renewToken());

  public manuallyPermissionErrorMessage = () => this.store.dispatch(actions.manuallyPermissionErrorMessage());

  public getStreamKey = () => this.store.dispatch(actions.getStreamKey());

  public getAllDevices = () => this.store.dispatch(actions.getAllDevices());

  public getCompanyInfo = () => this.store.dispatch(actions.getCompanyInfo());

  public askPermissionErrorMessage = () => this.store.dispatch(actions.askPermissionErrorMessage());

  public waitForUserAndJoinChannel = (deviceId: string) =>
    this.store.dispatch(actions.waitForUserAndJoinChannel({deviceId}));

  public getAgoraToken = (deviceId: string) => this.store.dispatch(actions.getAgoraToken({deviceId}));

  public startLiveAi = (device: UserDeviceJoined, model: Addon, streamKey: string) =>
    this.store.dispatch(actions.startLiveAi({device, model, streamKey}));

  public stopLiveAi = (deviceId: string, modelId: string) =>
    this.store.dispatch(actions.stopLiveAi({deviceId, modelId}));

  public getisLoadingSlug = () => this.store.select(selectStreamisLoadingSlug);

  public getisEnablingLivePage = () => this.store.select(selectStreamisEnablingLivePage);

  public getErrorMessage = () => this.store.select(selectErrorMessage);

  public getErrorCode = () => this.store.select(selectErrorCode);

  public watchForStreamingDevicesChange(): void {
    this.store.dispatch(actions.watchForStreamingDevicesChange());
  }

  public removeLiveDevice(device: UserDeviceJoined): void {
    this.store.dispatch(actions.removeLiveDevice({payload: {device}}));
  }

  public setLiveDevicesId(liveDevicesId: string[]): void {
    this.store.dispatch(actions.setLiveDevicesId({payload: {liveDevicesId}}));
  }

  public addLiveDevice(device: UserDeviceJoined): void {
    this.store.dispatch(actions.addLiveDevice({payload: {device}}));
  }

  public setIsInitialized(isInitialized: boolean): void {
    this.store.dispatch(actions.setIsInitialized({payload: {isInitialized}}));
  }

  public watchForStreamSourceChanges(): void {
    this.store.dispatch(actions.watchForStreamSourceChanges());
  }

  public watchForRestreamChanges(): void {
    this.store.dispatch(actions.watchForRestreamChanges());
  }

  public startPolling(): void {
    this.store.dispatch(actions.startPolling());
  }

  public addDevice(): void {
    this.store.dispatch(actions.addDevice());
  }

  public setLayoutState(layoutState: GridLayout) {
    this.store.dispatch(actions.setLayoutState({payload: {layoutState}}));
  }

  public watchSelectModelWhenDeviceChange(): void {
    this.store.dispatch(actions.watchSelectModelWhenDeviceChange());
  }

  public setIsSelectedDeviceRunningModel(isSelectedDeviceRunningModel: boolean): void {
    this.store.dispatch(actions.setIsSelectedDeviceRunningModel({payload: {isSelectedDeviceRunningModel}}));
  }

  public setDisplayedDeviceIds(displayedDeviceIds: string[]): void {
    this.store.dispatch(actions.setDisplayedDeviceIds({payload: {displayedDeviceIds}}));
  }

  public setSelectedDeviceId(selectedDeviceId: string): void {
    this.store.dispatch(actions.setSelectedDeviceId({payload: {selectedDeviceId}}));
  }

  public updateGridState(gridState: GridState) {
    this.store.dispatch(actions.updateGridState({payload: {gridState}}));
  }
}

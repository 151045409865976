import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AtlasSelectMarkersSettingsService {
  private hasToShowSelectOptions = new BehaviorSubject<boolean>(false);
  public hasToShowSelectOptions$ = this.hasToShowSelectOptions.asObservable();

  public showSelectOptions(): void {
    this.hasToShowSelectOptions.next(true);
  }

  public hideSelectOptions(): void {
    this.hasToShowSelectOptions.next(false);
  }
}

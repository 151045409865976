<button mat-button data-test-id="roles-menu-button" [matMenuTriggerFor]="menu">
  <span class="menu-text">{{ role | roleName | async }}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #menu="matMenu" class="user-team-roles-menu">
  <button
    *ngFor="let rolModel of roles | keyvalue"
    [attr.data-test-id]="'roles-menu-item-' + rolModel.value"
    [class.active]="role === rolModel.key"
    mat-menu-item
    (click)="emitUserTeamRole(rolModel.key)"
  >
    <div class="role">
      <div class="role__title">{{ rolModel.key | roleName | async }}</div>
      <div class="role__description"></div>
    </div>
  </button>
</mat-menu>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadFilesMenuItemComponent} from './upload-files-menu-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [UploadFilesMenuItemComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule, MatMenuModule, MatIconModule],
  exports: [UploadFilesMenuItemComponent]
})
export class UploadFilesMenuItemModule {}

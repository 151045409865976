<div class="developer-plan-features__container">
  <div class="developer-plan-features__feature" *ngFor="let feature of features">
    <div class="developer-plan-features__feature__icon">
      <mat-icon color="primary" [svgIcon]="feature.icon"></mat-icon>
    </div>
    <div class="developer-plan-features_feature__name">
      {{ feature.translationKey | translate: {value: model[feature.translationParams]} }}
    </div>
  </div>
</div>

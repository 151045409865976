import {Injectable} from '@angular/core';
import {TileEvent} from 'leaflet';

declare var Number;

@Injectable({
  providedIn: 'root'
})
/** Maintain relation between tile and session
 * Needed for displaying a popup on clicking tile layers
 * @more https://github.com/unleashlive/unleashcloudfront/issues/942
 * */
export class TileSessionService {
  private tileAssetMap = {};

  public addTile(tileLoadEvent: TileEvent | any, assetId: string): void {
    const coords = tileLoadEvent.coords;
    if (coords.z < 15) {
      // if zoom is far out there is no need for this feature
      // disable for better performance
      return;
    }
    const tileKey = `${coords.x}-${coords.y}-${coords.z}`;
    this.tileAssetMap[tileKey] = assetId;
  }

  public getAssetIdFromMapClick(e: any) {
    const tile = this.getTileURL(e.latlng.lat, e.latlng.lng, e.target.getZoom());
    return this.tileAssetMap[`${tile.x}-${tile.y}-${tile.z}`];
  }

  private getTileURL(lat, lon, zoom) {
    if (typeof Number.prototype.toRad === 'undefined') {
      Number.prototype.toRad = function () {
        return (this * Math.PI) / 180;
      };
    }

    const x = parseInt(String(Math.floor(((lon + 180) / 360) * (1 << zoom))));
    const y = parseInt(
      String(
        Math.floor(((1 - Math.log(Math.tan(lat.toRad()) + 1 / Math.cos(lat.toRad())) / Math.PI) / 2) * (1 << zoom))
      )
    );
    return {x, y, z: zoom};
  }
}

import {Directive, ElementRef, Output, EventEmitter} from '@angular/core';

@Directive({
  selector: '[appChangeCurrentAnchor]',
  standalone: true
})
export class ChangeCurrentAnchorDirective {
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  private observer: IntersectionObserver;

  constructor(private el: ElementRef) {
    this.observer = new IntersectionObserver(([entry]) => {
      this.visibleChange.emit(entry.isIntersecting);
    });

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}

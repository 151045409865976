import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationStage} from '@app/auth/state/auth.state';
import {Auth} from '@aws-amplify/auth';
import {Hub} from '@aws-amplify/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {REGISTRATION_STAGE} from '@app/auth/models/auth.models';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {from, of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {LocalStorageService} from '../../../core/services/local-storage/local-storage.service';
import {NotificationService} from '../../../core/services/notifications/notification.service';
import {
  actionConfirmRegistrationFormError,
  actionConfirmRegistrationFormSubmit,
  actionConfirmRegistrationReSendCode,
  actionConfirmRegistrationReSendCodeSuccess,
  actionConfirmRegistrationSuccess
} from './confirm-registration.actions';

@Injectable()
export class ConfirmRegistrationEffects {
  performVerification = createEffect(() =>
    this.actions$.pipe(
      ofType(actionConfirmRegistrationFormSubmit),
      exhaustMap(action =>
        from(Auth.confirmSignUp(action.form.email.toLowerCase(), action.form.code)).pipe(
          map(() => {
            this.unleashAnalyticsService.logEvent(EVENTS.USER_CONFIRMED_REGISTRATION, {
              email: action.form.email.toLowerCase()
            });
            return actionConfirmRegistrationSuccess();
          }),
          catchError(err => {
            return of(actionConfirmRegistrationFormError({error: err}));
          })
        )
      )
    )
  );

  reSendCode = createEffect(() =>
    this.actions$.pipe(
      ofType(actionConfirmRegistrationReSendCode),
      exhaustMap(action =>
        from(Auth.resendSignUp(action.form.email.toLowerCase())).pipe(
          map(() => actionConfirmRegistrationReSendCodeSuccess())
        )
      )
    )
  );

  registrationFinishedSuccessfully = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionConfirmRegistrationSuccess),
        tap(action => {
          this.notificationService.success('Registration successful! Signing in...');
          this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.details);
          Hub.dispatch('auth', {
            event: 'userConfirmed'
          });
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private router: Router,
    private unleashAnalyticsService: UnleashAnalyticsService
  ) {}
}

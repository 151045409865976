import {Directive, HostListener, Input} from '@angular/core';
import {AtlasService} from '../services/atlas.service';

@Directive({
  selector: '[ulShowOnlyThisGroup]',
  standalone: true
})
export class ShowOnlyThisGroupDirective {
  @Input('ulShowOnlyThisGroup') public groupName: string;

  constructor(private atlasService: AtlasService) {}

  @HostListener('click', ['$event'])
  public showOnlyThisGroup(): void {
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = this.groupName === asset?.groupName;
    });
    this.atlasService.toogleHasToDetectChanges();
  }
}

import {Injectable} from '@angular/core';
import {requestACLConfig, requestACLConfigSuccess} from '@app/core/store/acl/store/acl.actions';
import {selectAclLoading, selectAclPermissions} from '@app/core/store/acl/store/acl.selectors';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AclStoreFacadeService {
  public permissions$: Observable<string[]> = this.store.pipe(select(selectAclPermissions));
  public isLoading$: Observable<boolean> = this.store.pipe(select(selectAclLoading));

  constructor(private store: Store) {}

  public requestAclConfig(): void {
    this.store.dispatch(requestACLConfig());
  }

  public requestAclConfigSuccess(permissions: string[]): void {
    this.store.dispatch(requestACLConfigSuccess({payload: {permissions}}));
  }
}

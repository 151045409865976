<mat-card>  
  <div class="card-icon">
    <ng-container *ngIf="card.icon; else svgIconTemplate">
      <mat-icon color="gray">{{card.icon}}</mat-icon>
    </ng-container>
    <ng-template #svgIconTemplate>
      <mat-icon [svgIcon]="card.svgIcon" aria-hidden="false"></mat-icon>
    </ng-template>
  </div>
  <div class="card-content">
    <mat-card-header>
      <mat-card-title>{{card.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      {{card.description}}
    </mat-card-content>
    <div class="spacer"></div>
    <mat-card-actions>
      <ng-container *ngFor="let action of card.actions">
        <ng-container *ngIf="action.click; else actionRouteTemplate">
          <button mat-button color="primary" (click)="action.click()">{{action.name}}</button>
        </ng-container>
        <ng-template #actionRouteTemplate>
          <button mat-button color="primary" [routerLink]="[action.route]">{{action.name}}</button>
        </ng-template>
      </ng-container>
    </mat-card-actions>
  </div>
</mat-card>
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MissionRoutePoint} from '@app/atlas/services/mission-settings.service';

@Component({
  selector: 'unleash-edit-mission-settings',
  templateUrl: './edit-mission-settings.component.html',
  styleUrls: ['./edit-mission-settings.component.scss']
})
export class EditMissionSettingsComponent {
  @Output() public goBack: EventEmitter<void> = new EventEmitter();
  @Output() public smartInspect: EventEmitter<boolean> = new EventEmitter();
  @Input('defaultSettings') public set setDefaultSettings(defaultSettings: MissionRoutePoint) {
    if (!!defaultSettings) {
      this.altitude = defaultSettings.altitude;
      this.speed = defaultSettings.speed;
      this.heading = defaultSettings.heading;
      this.pitch = defaultSettings.pitch;
      this.gimbal = defaultSettings.gimbal;
    }
  }
  @Input() public isSmartInspect: boolean = false;

  // eslint-disable-next-line no-magic-numbers
  public altitude: number = 80;
  // eslint-disable-next-line no-magic-numbers
  public speed: number = 2;
  public heading: number = 0;
  public pitch: number = 0;
  public gimbal: number = 0;
  public isLoadingSmartInspect: boolean = false;

  public setAltitude(e): void {
    this.altitude = e.value;
  }
  public setSpeed(e): void {
    this.speed = e.value;
  }
  public setHeading(e): void {
    this.heading = e.value;
  }
  public setPitch(e): void {
    this.pitch = e.value;
  }
  public setGimbal(e): void {
    this.gimbal = e.value;
  }
  public emitGoBack(): void {
    this.goBack.emit();
  }
  public emitIsSmartInspect(e): void {
    this.smartInspect.emit(e.checked);
  }
  public startLoader() {
    this.isLoadingSmartInspect = true;
  }
  public stopLoader() {
    this.isLoadingSmartInspect = false;
  }
}

import {NgModule} from '@angular/core';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgStreamingModule} from '@videogular/ngx-videogular/streaming';

const videogularModules = [VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule, VgStreamingModule];
@NgModule({
  declarations: [],
  imports: videogularModules,
  exports: videogularModules
})
export class VideogularModule {}

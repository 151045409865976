<div class="container mat-elevation-z2">
  <div class="image-container content-item">
    <img src="/assets/icons/plans/done.svg" alt="plan done icon" />
  </div>
  <div class="title content-item">{{ 'plans.currentPlan.selectedCurrentPackage' | translate }}</div>
  <div class="description content-item">
    {{ 'plans.currentPlan.remainTheSame' | translate }}
  </div>
  <div class="buttons content-item">
    <button class="button" mat-stroked-button color="primary" (click)="clickChangeAction()">
      {{ 'plans.currentPlan.changePackage' | translate }}
    </button>
    <button class="button" mat-raised-button color="primary" (click)="clickReturnAction()">
      {{ 'plans.currentPlan.returnToAccount' | translate }}
    </button>
  </div>
</div>

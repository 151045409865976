<ng-container *ngIf="uploadType === uploadOption.googleDrive; else skydioTemplate">
  <h1 mat-dialog-title>{{ 'library.dialogs.googleDrive.title' | translate }}</h1>
  <div mat-dialog-content>
    <div [innerHTML]="'library.dialogs.googleDrive.restrictions' | translate"></div>
    <form [formGroup]="shareableLinkForm">
      <mat-form-field class="mat-form-field-form" fxFlex="100%" fxLayout>
        <input
          type="text"
          matInput
          formControlName="shareableLink"
          [placeholder]="'library.dialogs.googleDrive.placeholder' | translate"
          autocomplete="off"
        />
        <mat-error
          *ngIf="
            shareableLinkForm.get('shareableLink').touched &&
            shareableLinkForm.get('shareableLink').hasError('required')
          "
        >
          {{ 'library.dialogs.googleDrive.errorRequired' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            shareableLinkForm.get('shareableLink').touched && shareableLinkForm.get('shareableLink').hasError('pattern')
          "
        >
          {{ 'library.dialogs.googleDrive.errorPattern' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-icon fxLayout class="gray" [matTooltip]="'library.dialogs.googleDrive.tooltip' | translate"> info </mat-icon>
    </form>
  </div>
  <div mat-dialog-actions>
    <span fxFlex></span>
    <button mat-button type="button" mat-dialog-close color="primary" tabindex="0">
      {{ 'common.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      tabindex="1"
      (click)="downloadFrom()"
      [analyticsTrackerType]="events.GDRIVE_IMPORT_STARTED"
      [analyticsIf]="shareableLinkForm.valid"
    >
      {{ 'library.dialogs.googleDrive.import' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #skydioTemplate>
  <h1 mat-dialog-title>{{ 'library.dialogs.skydio.title' | translate }}</h1>
  <div mat-dialog-content>
    <div [innerHTML]="'library.dialogs.skydio.restrictions' | translate"></div>
  </div>
  <div mat-dialog-actions>
    <span fxFlex></span>
    <button mat-button type="button" mat-dialog-close color="primary" tabindex="0">
      {{ 'common.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      tabindex="1"
      (click)="downloadFrom()"
      [analyticsTrackerType]="events.SKYDIO_IMPORT_STARTED"
      [analyticsIf]="shareableLinkForm.valid"
    >
      {{ 'library.dialogs.skydio.import' | translate }}
    </button>
  </div>
</ng-template>

import {LinkMenuItem} from '@app/core/models/gui/link-menu-item';
import {MenuItem} from '@app/core/models/gui/menu-item';
import {createAction, props} from '@ngrx/store';

const enum SidenavMenuActionsTypes {
  SetMenuItems = '[SidenavMenu] Set Menu Items',
  SetLastTopIndex = '[SidenavMenu] Set Last Top Index',
  SetProfileMenuItem = '[SidenavMenu] Set Profile Menu Item'
}

export const setMenuItems = createAction(
  SidenavMenuActionsTypes.SetMenuItems,
  props<{payload: {menuItems: MenuItem[]}}>()
);

export const setLastTopIndex = createAction(
  SidenavMenuActionsTypes.SetLastTopIndex,
  props<{payload: {index: number}}>()
);

export const setProfileMenuItem = createAction(
  SidenavMenuActionsTypes.SetProfileMenuItem,
  props<{payload: {profileMenuItem: LinkMenuItem}}>()
);

import {createAction, props} from '@ngrx/store';
import {Integration} from '@app/analysis/models/integration.model';
import {SortType} from '@app/analysis/models/paginator.model';

export enum AnalysisIntegrationActions {
  ListAllIntegrations = '[Analysis Integrations] List Integrations',
  ListAllIntegrationsSuccess = '[Analysis Integrations] List Integrations Success',
  ListAllIntegrationsError = '[Analysis Integrations] List Integrations Error',
  SortAllIntegrations = '[Analysis Integrations] Sort All Integrations'
}

export const getAllIntegrations = createAction(AnalysisIntegrationActions.ListAllIntegrations);

export const getAllIntegrationsSuccess = createAction(
  AnalysisIntegrationActions.ListAllIntegrationsSuccess,
  props<{payload: Integration[]}>()
);

export const getAllIntegrationsError = createAction(AnalysisIntegrationActions.ListAllIntegrationsError);

export const sortAllIntegrations = createAction(
  AnalysisIntegrationActions.SortAllIntegrations,
  props<{payload: SortType}>()
);

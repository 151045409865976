import {Directive, ElementRef} from '@angular/core';
import {environment} from '../../../environments/environment';

/**
 * Hide certain elements that are visible only in development environment
 */
@Directive({
  selector: '[experimental]'
})
export class ExperimentalDirective {

  constructor(private elementRef: ElementRef) {
    if (environment.production) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {AtlasModule} from '@app/atlas/atlas.module';
import {Map} from 'leaflet';

@Component({
  selector: 'unleash-map-layers-list',
  templateUrl: './map-layers-list.component.html',
  styleUrls: ['./map-layers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AtlasModule, MatInputModule]
})
export class MapLayersListComponent {
  @Input() public map: Map;
}

import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'unleash-checkbox',
  templateUrl: './unleash-checkbox.component.html',
  styleUrls: ['./unleash-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnleashCheckboxComponent),
      multi: true
    }
  ]
})
export class UnleashCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() onToggle: () => void;

  value = false;

  constructor() {}

  ngOnInit() {}

  toggleCheck(isChecked: boolean) {
    this.writeValue(isChecked);
    this.onChange(isChecked);
  }

  onChange = (isChecked: boolean) => {};

  onTouched = () => {};
  writeValue(isChecked: boolean): void {
    this.value = isChecked;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

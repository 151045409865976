<h1 mat-dialog-title>{{ 'library.dialogs.s3Export.title' | translate }}</h1>
<div mat-dialog-content>
  <div [innerHTML]="'library.dialogs.s3Export.restrictions' | translate"></div>

  <form [formGroup]="form">
    <mat-form-field class="mat-form-field-form" fxFlex="100%" fxLayout>
      <input
        type="text"
        matInput
        formControlName="s3Uri"
        [placeholder]="'library.dialogs.s3Export.placeholder' | translate"
        autocomplete="off"
      />
      <mat-error *ngIf="form.get('s3Uri').touched && form.get('s3Uri').hasError('required')">
        {{ 'library.dialogs.s3Export.errorRequired' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('s3Uri').touched && form.get('s3Uri').hasError('pattern')">
        {{ 'library.dialogs.s3Export.errorPattern' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-icon fxLayout class="gray" [matTooltip]="'library.dialogs.s3Export.tooltip' | translate"> info </mat-icon>
  </form>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button type="button" mat-dialog-close color="primary" tabindex="0">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    mat-button
    type="submit"
    color="primary"
    tabindex="1"
    (click)="exportToS3()"
    [analyticsTrackerType]="events.S3_EXPORT_STARTED"
    [analyticsIf]="form.valid"
  >
    {{ 'library.dialogs.s3Export.action' | translate }}
  </button>
</div>

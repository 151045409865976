<div class="pinch-zoom-content">
  <ng-content></ng-content>
</div>

<!-- Control: one button -->
<div
  class="pz-zoom-button {{ zoomControlPositionClass }}"
  [class.pz-zoom-button-out]="isZoomIn$ | async"
  *ngIf="hasOneControl$ | async"
  (click)="toggleZoom()"
></div>

<!-- Control: two buttons -->
<div
  class="pz-zoom-control {{ zoomControlPositionClass }}"
  [class.pz-zoomed-in]="isZoomIn$ | async"
  *ngIf="hasTwoControls$ | async"
>
  <div class="pz-zoom-in" [class.pz-disabled]="isZoomLimitReached$ | async" (click)="zoomIn()"></div>
  <div class="pz-zoom-out" [class.pz-disabled]="(isZoomIn$ | async) === false" (click)="zoomOut()"></div>
</div>

<div class="zoom-buttons">
  <ng-content select="[slot=samRefineSelection]"></ng-content>
  <div class="zoom-buttons__group">
    <button mat-button class="zoom-button" (click)="zoomOut()" [disabled]="(isZoomIn$ | async) === false">
      <mat-icon>zoom_out</mat-icon>
    </button>

    <div class="zoom-percent">{{ zoomPercentage$ | async | round }}%</div>
    <button mat-button class="zoom-button" (click)="zoomIn()" [disabled]="isZoomLimitReached$ | async">
      <mat-icon>zoom_in</mat-icon>
    </button>
  </div>
</div>

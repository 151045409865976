import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AnalysisDeleteDialogComponent} from '@app/analysis/components/analysis-delete-dialog/analysis-delete-dialog.component';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Dictionary} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {AnalysisFilter} from '@app/analysis/models/filter.model';
import {Integration} from '@app/analysis/models/integration.model';
import {Paginator, SortType} from '@app/analysis/models/paginator.model';
import {IntegrationApiService} from '@app/analysis/services/integration-api.service';
import {IntegrationOperationsService} from '@app/analysis/services/integration-operations.service';
import {ReportOperationsService} from '@app/analysis/services/report-operations.service';
import {groupBy} from 'lodash';
import {forkJoin, of, zip} from 'rxjs';
import {catchError, delay, filter, map, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {UserStoreFacadeService} from '../../core/services/user-store-facade.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  DELETE_REPORT_TYPES,
  ParsedReport,
  ReportModel,
  ReportsFilterOptions,
  ReportType
} from '../models/analysis.model';
import {ReportsService} from '../services/reports.service';
import {
  getAllIntegrations,
  getAllIntegrationsError,
  getAllIntegrationsSuccess,
  sortAllIntegrations
} from './integration.actions';
import {
  clearFilter,
  deleteAnalysisReports,
  deleteAnalysisReportsFail,
  deleteAnalysisReportsSuccess,
  filterReports,
  generateDisplayedReportsSuccess,
  generatePaginatedReports,
  getAllReports,
  getAllReportsFail,
  getAllReportsSuccess,
  sortAllReports,
  openMobileFilterDialog,
  hideFooter,
  generateApiKey,
  copyKey,
  removeKeyDialog,
  updateKeyDialog,
  removeKey,
  updateKey
} from './reports.actions';
import {selectAllIntegrations, selectAllReports, selectFilter, selectPaginator} from './reports.selectors';
import {ReportsState} from './reports.state';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AnalysisPageService} from '../services/analysis-page.service';
import {AnalysisMobileFilterComponent} from '../components/analysis-mobile-filter/analysis-mobile-filter.component';
import {LayoutCheckerService} from '@app/core/services/layout-checker.service';
import {MediaObserver} from '@angular/flex-layout';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {ReportsStoreFacadeService} from '@app/analysis/services/reports-store-facade.service';
import {ApiKeyDialogComponent} from '@app/analysis/components/api-key-dialog/api-key-dialog.component';
import {ApiKeyDialogMode} from '@app/analysis/components/api-key-dialog/api-key-dialog.model';
import {AnalysisApiService} from '@app/analysis/services/analysis-api.service';
import {ApiKeyCardService} from '@app/analysis/components/api-key-card/api-key-card.service';

@Injectable()
export class ReportsEffects {
  public getAllReports = createEffect((): any =>
    this.actions$.pipe(
      ofType(getAllReports),
      switchMap(() => {
        return this.reportsService.listPartReport({}).pipe(
          map(reports => getAllReportsSuccess({reports})),
          catchError(error => [getAllReportsFail(error)])
        );
      })
    )
  );

  public getAllReportsSuccess = createEffect((): any =>
    this.actions$.pipe(
      ofType(getAllReportsSuccess, clearFilter),
      switchMap(() =>
        of(
          generatePaginatedReports({
            payload: {
              pageIndex: DEFAULT_PAGE_INDEX,
              pageSize: DEFAULT_PAGE_SIZE
            }
          })
        )
      )
    )
  );

  public paginatedReports = createEffect((): any =>
    this.actions$.pipe(
      ofType(generatePaginatedReports),
      switchMap(result =>
        zip(
          this.store.pipe(select(selectAllReports), take(1)),
          this.store.pipe(select(selectFilter), take(1)),
          of(result.payload)
        )
      ),
      switchMap(([reports, filterOption, sortOptions]) => {
        const parsedReports = this.parseReports(reports);
        const filteredReports = this.getFilterReports(parsedReports, filterOption);

        if (!filteredReports) {
          return;
        }

        if (!sortOptions) {
          return of(
            generateDisplayedReportsSuccess({
              payload: {displayedReports: [], paginator: null}
            })
          );
        }

        const pageSize = sortOptions ? sortOptions.pageSize : DEFAULT_PAGE_SIZE;
        const pageIndex = sortOptions ? sortOptions.pageIndex : DEFAULT_PAGE_INDEX;
        const displayedReports = this.reportOperationsService.generateDisplayedReports(
          filteredReports,
          pageSize,
          pageIndex
        );
        const paginator = this.reportOperationsService.generatePaginator(filteredReports?.length, pageSize);
        return of(
          generateDisplayedReportsSuccess({
            payload: {displayedReports, paginator}
          })
        );
      })
    )
  );

  public sortAllReports = createEffect((): any =>
    this.actions$.pipe(
      ofType(sortAllReports),
      switchMap(sortOption => zip(this.store.pipe(select(selectAllReports)), of(sortOption.payload))),
      switchMap(([reports, sortOptions]: [ReportModel[], SortType]) => {
        const sortedReports = this.reportOperationsService.sortReports(reports, sortOptions);
        return of(getAllReportsSuccess({reports: sortedReports}));
      })
    )
  );

  public getAllIntegrations = createEffect((): any =>
    this.actions$.pipe(
      ofType(getAllIntegrations),
      switchMap(() => this.integrationApiService.listAllIntegrations()),
      switchMap((integrations: Integration[]) => of(getAllIntegrationsSuccess({payload: integrations}))),
      catchError(() => of(getAllIntegrationsError()))
    )
  );

  public sortAllIntegrations = createEffect((): any =>
    this.actions$.pipe(
      ofType(sortAllIntegrations),
      tap(() => console.debug('sortAllIntegrations$')),
      switchMap(sortOption => zip(this.store.pipe(select(selectAllIntegrations), take(1)), of(sortOption.payload))),
      switchMap(([integrations, sortOptions]: [Integration[], SortType]) => {
        const sortedReports = this.integrationOperationsService.sortReports(integrations, sortOptions);
        return of(getAllIntegrationsSuccess({payload: sortedReports}));
      })
    )
  );

  public filterReports = createEffect((): any =>
    this.actions$.pipe(
      ofType(filterReports),
      switchMap(action =>
        zip(
          this.store.pipe(select(selectAllReports), take(1)),
          this.store.pipe(select(selectPaginator), take(1)),
          of(action.payload)
        )
      ),
      switchMap(([reports, paginator, filterOption]: [ReportModel[], Paginator, AnalysisFilter]) => {
        const parsedReports: ParsedReport[] = this.parseReports(reports);
        const filteredReports: ParsedReport[] = this.getFilterReports(parsedReports, filterOption);

        const pageSize: number = paginator ? paginator.pageSize : DEFAULT_PAGE_SIZE;
        const pageIndex: number = 0;
        const displayedReports: ParsedReport[] = this.reportOperationsService.generateDisplayedReports(
          filteredReports,
          pageSize,
          pageIndex
        );
        const paginators: Paginator = this.reportOperationsService.generatePaginator(filteredReports.length, pageSize);
        return of(
          generateDisplayedReportsSuccess({
            payload: {displayedReports, paginator: paginators}
          })
        );
      })
    )
  );

  public deleteAnalysisReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAnalysisReports),
      switchMap(action => {
        const dialogRef = this.dialog.open(AnalysisDeleteDialogComponent, {
          ...STANDARD_DIALOG_CONFIG,
          autoFocus: false,
          width: '60vw',
          maxWidth: '800px',
          height: '152px',
          minWidth: '341px',
          data: {
            reportIds: action.payload.sessionIds,
            deleteReportType: action.payload.deleteReportType
          }
        });

        return zip(
          dialogRef.afterClosed().pipe(
            filter((deleteReportType: DELETE_REPORT_TYPES): boolean => !!deleteReportType),
            take(1)
          ),
          of(action.payload.sessionIds)
        );
      }),
      switchMap(([dialogResponse, sessionIds]: [DELETE_REPORT_TYPES, string[]]) =>
        zip(
          of(dialogResponse),
          forkJoin(sessionIds.map((sessionId: string) => this.reportsService.deleteAnalysisReports(sessionId)))
        )
      ),
      switchMap(([dialogResponse, deleteResponse]: [DELETE_REPORT_TYPES, string[]]) => {
        switch (dialogResponse) {
          case DELETE_REPORT_TYPES.MULTIPLE:
            return zip(of(deleteResponse), this.translateService.get('analysis.deleteDialog.mutipleReportsDeleted'));
          case DELETE_REPORT_TYPES.AI:
            return zip(of(deleteResponse), this.translateService.get('analysis.deleteDialog.aiReportDeleted'));
          case DELETE_REPORT_TYPES.PDF:
            return zip(of(deleteResponse), this.translateService.get('analysis.deleteDialog.pdfReportDeleted'));
        }
      }),
      map(([response, translation]: [string[], string]) => {
        this.snackBar.open(translation, null, {
          duration: 3000,
          panelClass: 'report-deleted'
        });
        return deleteAnalysisReportsSuccess({payload: {sessionIds: response}});
      }),
      catchError(error => of(deleteAnalysisReportsFail(error)))
    )
  );

  public deleteAnalysisReportsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAnalysisReportsSuccess),
      switchMap(action => zip(this.store.pipe(select(selectAllReports)), of(action.payload.sessionIds))),
      switchMap(([reports, deletedReportCreatedAts]: [ReportModel[], string[]]) => {
        return of(
          getAllReportsSuccess({
            reports: reports.filter((report: ReportModel) => !deletedReportCreatedAts.includes(report.sessionCreatedAt))
          })
        );
      })
    )
  );

  public openMobileFilterDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openMobileFilterDialog),
        withLatestFrom(this.analysisPageService.filter$),
        switchMap(([action, filters]: [unknown, AnalysisFilter]) =>
          zip(of(filters), this.analysisPageService.reportsTypes$.pipe(take(1)))
        ),
        switchMap(([filters, types]: [AnalysisFilter, ReportType[]]) => {
          const dialogRef = this.dialog.open(AnalysisMobileFilterComponent, {
            ...STANDARD_DIALOG_CONFIG,
            panelClass: 'analysis-mobile-filter',
            data: {
              types: types,
              filters: filters
            }
          });
          return dialogRef.afterClosed();
        }),
        tap((result: AnalysisFilter) => {
          if (result) {
            this.analysisPageService.filterAnalysis(result);
          }
        })
      ),
    {dispatch: false}
  );

  public hideFooter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideFooter),
        switchMap(action => {
          return this.observableMedia.asObservable().pipe(
            tap(change => {
              const indexFirstPriorityMedia = 0;
              const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
              action.payload.hasToHideFooter
                ? this.layoutCheckerService.setIsShowFooterNav(mediaAlias !== 'xs')
                : this.layoutCheckerService.setIsShowFooterNav(true);
            })
          );
        })
      ),
    {dispatch: false}
  );

  public generateApiKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(generateApiKey),
        switchMap(() => {
          return this.analysisApiService.generateApiKey();
        }),
        tap((response: {id: string; value: string}) => {
          this.reportsStoreFacadeService.generateApiKeySuccess(response.value);
          this.apiKeyCardService.showKey();
        })
      ),
    {dispatch: false}
  );

  public copyKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(copyKey),
        switchMap(() => this.reportsStoreFacadeService.apiKey$.pipe(take(1))),
        tap((apiKey: string) => {
          this.clipboard.copy(apiKey);
        }),
        switchMap(() => this.translateService.get('analysis.api.copyToClipboardSuccess')),
        tap(translation => {
          this.snackBar.open(translation, null, {
            duration: 3000,
            panelClass: 'center'
          });
        })
      ),
    {dispatch: false}
  );

  public removeKeyDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeKeyDialog),
        switchMap(() =>
          this.dialog
            .open(ApiKeyDialogComponent, {
              ...STANDARD_DIALOG_CONFIG,
              width: '80vw',
              maxWidth: '800px',
              data: {dialogMode: ApiKeyDialogMode.DELETE}
            })
            .afterClosed()
        )
      ),
    {dispatch: false}
  );

  public removeKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeKey),
        switchMap(() => this.analysisApiService.removeApiKey()),
        tap(() => {
          this.dialog.closeAll();
          this.reportsStoreFacadeService.removeKeySuccess();
          this.apiKeyCardService.hideKey();
        })
      ),
    {dispatch: false}
  );

  public updateKeyDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateKeyDialog),
        switchMap(() =>
          this.dialog
            .open(ApiKeyDialogComponent, {
              ...STANDARD_DIALOG_CONFIG,
              width: '80vw',
              maxWidth: '800px',
              data: {dialogMode: ApiKeyDialogMode.UPDATE}
            })
            .afterClosed()
        )
      ),
    {dispatch: false}
  );

  public updateKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateKey),
        switchMap(() => this.analysisApiService.generateApiKey()),
        tap((response: {id: string; value: string}) => {
          this.dialog.closeAll();
          this.reportsStoreFacadeService.updateKeySuccess(response.value);
          this.apiKeyCardService.showKey();
        })
      ),
    {dispatch: false}
  );

  constructor(
    private reportsService: ReportsService,
    private actions$: Actions,
    private store: Store<ReportsState>,
    private reportOperationsService: ReportOperationsService,
    private integrationApiService: IntegrationApiService,
    private integrationOperationsService: IntegrationOperationsService,
    private userStoreFacadeService: UserStoreFacadeService,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private analysisPageService: AnalysisPageService,
    private observableMedia: MediaObserver,
    private layoutCheckerService: LayoutCheckerService,
    private reportsStoreFacadeService: ReportsStoreFacadeService,
    private clipboard: Clipboard,
    private analysisApiService: AnalysisApiService,
    private apiKeyCardService: ApiKeyCardService
  ) {}

  private getFilterReports(reports: ParsedReport[], filterOption: ReportsFilterOptions): ParsedReport[] {
    const validFilterOptions: [string, string][] = Object.entries(filterOption).filter(
      (option: [string, string]): boolean => !!option[1]
    );

    if (!reports) {
      return;
    }

    return reports.filter((report: ParsedReport): boolean => {
      return validFilterOptions.every((option: [string, string]): boolean => {
        const keyName: string = option[0];
        const filterValue: string = option[1];
        let currentReport = null;
        let filterDate = null;
        let hours = null;
        let minutes = null;
        let isValid: boolean = true;
        switch (keyName) {
          case 'from':
            isValid = isValid && report.updatedAt > Number(filterValue);
            break;
          case 'to':
            isValid = isValid && report.updatedAt < Number(filterValue);
            break;
          case 'timeFrom':
            currentReport = new Date(report.updatedAt);
            filterDate = new Date(filterValue as unknown as number);
            hours = currentReport.getHours() >= filterDate.getHours();
            minutes = currentReport.getMinutes() >= filterDate.getMinutes();
            isValid = isValid && hours && minutes;
            break;
          case 'timeTo':
            currentReport = new Date(report.updatedAt);
            filterDate = new Date(filterValue as unknown as number);
            hours = currentReport.getHours() <= filterDate.getHours();
            minutes = currentReport.getMinutes() <= filterDate.getMinutes();
            isValid = isValid && hours && minutes;
            break;
          case 'search':
            isValid = isValid && report.name.toLowerCase().includes((filterValue as string).toLowerCase());
            break;
          case 'type':
            isValid = isValid && report.type.toLowerCase().includes((filterValue as string).toLowerCase());
            break;
          default:
            break;
        }
        return isValid;
      });
    });
  }

  private parseReports(reports: ReportModel[]): ParsedReport[] {
    const groupedReports: Dictionary<ReportModel[]> = groupBy(reports, 'id');

    return Object.keys(groupedReports).map(
      (key: string): ParsedReport => ({
        id: groupedReports[key][0].sessionCreatedAt,
        sessionId: groupedReports[key][0].sessionId,
        name: groupedReports[key][0].name,
        createdAt: groupedReports[key][0].createdAt,
        updatedAt: groupedReports[key][0].updatedAt,
        type: groupedReports[key][0].type,
        paths: groupedReports[key].map((report: ReportModel): string => report.path)
      })
    );
  }
}

<form [formGroup]="form">
  <div mat-dialog-title>
    <span class="text-4--bold">Rename A.I. App</span>
  </div>

  <div mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
      <mat-error
        *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)"
      ></mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <button mat-button type="button" color="primary" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Update</button>
  </div>
</form>

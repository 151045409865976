import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'session-expired-dialog',
  template: ` <h2 mat-dialog-title>{{ 'auth.session.expired' | translate }}</h2>
    <mat-dialog-content>{{ 'auth.session.clickOk' | translate }}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true">{{ 'common.ok' | translate }}</button>
    </mat-dialog-actions>`
})
export class SessionExpiredDialog {
  constructor(public dialogRef: MatDialogRef<SessionExpiredDialog>) {}
}

export const INDUSTRIES: string[] = [
  'Cities',
  'Construction',
  'Emergency Services',
  'Energy & Renewables',
  'Infrastructure - Railway',
  'Infrastructure - Road',
  'Mining',
  'Pipeline & Resources',
  'Retail & Spaces',
  'Safety & Security',
  'Sports & Entertainment',
  'Transportation',
  'Other'
];

export const INDUSTRY_DEVELOPER: string = 'Developer';

import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AclService} from '@app/core/services/acl.service';
import * as actions from '@app/atlas/store/atlas.actions';
import {Observable} from 'rxjs';
import * as selectors from '@app/atlas/store/atlas.selectors';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';

@Injectable({
  providedIn: 'root'
})
export class AtlasStoreFacadeService {
  public atlasItems$: Observable<AtlasAssetModel[]> = this.store.pipe(select(selectors.selectAtlasItems));

  constructor(public store: Store, private aclService: AclService) {}

  public loadAtlasItems() {
    this.store.dispatch(actions.loadAtlasItems());
  }
}

import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-additional-device-dialog',
  templateUrl: './additional-device-dialog.component.html',
  styleUrls: ['./additional-device-dialog.component.scss']
})
export class AdditionalDeviceDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdditionalDeviceDialogComponent>,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }
}

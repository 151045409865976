import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'simple-text-dialog',
  templateUrl: './embed-code.component.html',
  styleUrls: ['./embed-code.components.scss']
})
export class EmbedCodeDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public code: string) {
  }
}

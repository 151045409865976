<div mat-dialog-title>
  <span class="text-4--bold">Un-publish {{ name$ | async }}</span>
</div>

<ng-container *ngTemplateOutlet="(hasActiveUsers$ | async) ? hasActiveUsers : hasNoActiveUsers"></ng-container>

<ng-template #hasActiveUsers>
  If you proceed, {{ name$ | async }} will be unpublished on the
  {{ expirationDate$ | async | date: 'mediumDate' }}.<br />
  {{ name$ | async }}’s users will be notified immediately so they are able to organize a replacement solution.<br />
  This action cannot be undone. Are you sure?
</ng-template>

<ng-template #hasNoActiveUsers>
  If you proceed, {{ name$ | async }} will be un-published immediately.<br />
  This action cannot be undone. Are you sure?
</ng-template>

<div mat-dialog-actions>
  <button mat-button type="button" color="primary" (click)="cancel()">Cancel</button>
  <button mat-raised-button color="warn" type="button" (click)="save()">Un-publish {{ name$ | async }}</button>
</div>

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  templateUrl: './export-data-dialog.component.html',
  styleUrls: ['./export-data-dialog.component.scss']
})
export class ExportDataDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {email: string}) {}

  ngOnInit(): void {}
}

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {ExportDataDialogComponent} from '../components/data-privacy/export-data-dialog/export-data-dialog.component';
import {ExportDateRange, ExportData, ExportDataOption, ExportType} from '../models/export-data.model';
import {b64toBlob, saveBlob} from '../../core/services/blob.utils';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService {
  dataRangeExportOptions: ExportDataOption<ExportDateRange>[] = [];

  typeExportOptions: ExportDataOption<ExportType>[] = [];

  constructor(private apiGateway: ApiGateway, private dialog: MatDialog, private translateService: TranslateService) {
    this.translateService.get(['profile.export.dateRanges', 'profile.export.types']).subscribe(translations => {
      const {'profile.export.dateRanges': dateRanges, 'profile.export.types': types} = translations;
      this.dataRangeExportOptions = [
        {id: ExportDateRange.past24Hours, name: dateRanges.past24Hours},
        {id: ExportDateRange.past3Days, name: dateRanges.past3Days},
        {id: ExportDateRange.pastWeek, name: dateRanges.pastWeek},
        {id: ExportDateRange.pastMonth, name: dateRanges.pastMonth}
      ];

      this.typeExportOptions = [
        {id: ExportType.aiAppConfiguration, name: types.aiAppConfiguration},
        {id: ExportType.aiAppNotifications, name: types.aiAppNotifications},
        {id: ExportType.devices, name: types.devices},
        {id: ExportType.errors, name: types.errors},
        {id: ExportType.media, name: types.media},
        {id: ExportType.userActivity, name: types.userActivity},
        {id: ExportType.zones, name: types.zones}
      ];
    });
  }

  displayExportDataDialog(email: string): void {
    this.dialog.open(ExportDataDialogComponent, {
      data: {email}
    });
  }

  export(exportData: ExportData): void {
    const param = {
      date: exportData.dateRange,
      type: exportData.type
    };
    this.apiGateway.get('/export', param, {responseType: 'text'}).subscribe(response => {
      const blob = b64toBlob(response, 'application/zip');
      saveBlob(blob, `export-${exportData.type}.zip`);
    });
  }
}

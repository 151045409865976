import {createAction, props} from '@ngrx/store';
import {LibraryListSubfoldersQueryParams} from '@app/library/models/library-filter.model';
import {LibraryItemMoveModel} from '@app/library/models/move-items.model';
import {LibraryItemMutateModel} from '../../../models/delete-items.model';

export enum MoveItemsDialogActionTypes {
  LoadMoveItemsDialogSubfolders = '[MoveItemsDialog] Load sub-folders',
  SaveMoveItemsDialogSubfolders = '[MoveItemsDialog] Save sub-folders',

  StartLoadingMoveItemsDialogSubfolders = '[MoveItemsDialog] Start loading',
  StopLoadingMoveItemsDialogSubfolders = '[MoveItemsDialog] Stop loading',

  StartMoveLibraryItems = '[MoveItemsDialog] Start Move library items',
  MoveLibraryItems = '[MoveItemsDialog] Move library items',
  ReturnToParent = '[MoveItemsDialog] Return to prev',
  InitStore = '[MoveItemsDialog] Init first folder in store',
  AddToStore = '[MoveItemsDialog] Add folder to store',
  AddToCachedStore = '[MoveItemsDialog] Add folder to cached store if exist',
  ClearMoveStore = '[MoveItemsDialog] Clear Move store',

  CreateNewFolder = '[MoveItemsDialog] Create new folder'
}

export const loadMoveItemsDialogSubfolders = createAction(
  MoveItemsDialogActionTypes.LoadMoveItemsDialogSubfolders,
  props<{payload: {queryParams: LibraryListSubfoldersQueryParams}}>()
);
export const saveMoveItemsDialogSubfolders = createAction(
  MoveItemsDialogActionTypes.SaveMoveItemsDialogSubfolders,
  props<{payload: {currentLocation: string; folders: LibraryItemMoveModel[]}}>()
);

export const returnToParent = createAction(
  MoveItemsDialogActionTypes.ReturnToParent,
  props<{payload: {teamId: string}}>()
);

export const startLoadingMoveItemsDialogSubfolders = createAction(
  MoveItemsDialogActionTypes.StartLoadingMoveItemsDialogSubfolders
);

export const stopLoadingMoveItemsDialogSubfolders = createAction(
  MoveItemsDialogActionTypes.StopLoadingMoveItemsDialogSubfolders
);

export const moveLibraryItems = createAction(
  MoveItemsDialogActionTypes.MoveLibraryItems,
  props<{
    payload: {
      to: LibraryItemMutateModel;
      moveItems: LibraryItemMutateModel[];
    };
  }>()
);

export const startMoveLibraryItems = createAction(
  MoveItemsDialogActionTypes.StartMoveLibraryItems,
  props<{
    payload: {
      folderId: string;
    };
  }>()
);

export const initStore = createAction(
  MoveItemsDialogActionTypes.InitStore,
  props<{payload: {item: LibraryItemMoveModel}}>()
);

export const addToStore = createAction(
  MoveItemsDialogActionTypes.AddToStore,
  props<{payload: {item: LibraryItemMoveModel}}>()
);

export const addToCachedStore = createAction(
  MoveItemsDialogActionTypes.AddToCachedStore,
  props<{payload: {item: LibraryItemMoveModel}}>()
);

export const clearStore = createAction(MoveItemsDialogActionTypes.ClearMoveStore);

export const createFolder = createAction(
  MoveItemsDialogActionTypes.CreateNewFolder,
  props<{payload: {folderName: string}}>()
);

<form [formGroup]="form" class="move-markers">
  <span class="move-markers__title">
    {{ data.isCopyDialog ? ('atlas.copyMarkers.title' | translate) : ('atlas.moveMarkers.title' | translate) }}
  </span>
  <span class="move-markers__text">{{ 'atlas.moveMarkers.text' | translate }} </span>
  <mat-radio-group formControlName="destination" class="move-markers__options">
    <mat-radio-button
      [class.opaque-radio]="form.controls.destination.value === moveMarkerDestination.EXISTING_LAYER"
      [value]="moveMarkerDestination.NEW_LAYER"
    >
      <span> {{ 'atlas.moveMarkers.newLayer' | translate }} </span>
      <mat-icon class="move-markers__arrow-icon">arrow_drop_down</mat-icon></mat-radio-button
    >
    <div
      *ngIf="form.controls.destination.value === moveMarkerDestination.NEW_LAYER"
      class="move-markers__new-layer-container"
    >
      <div class="move-markers__new-layer">
        <span>{{ 'atlas.moveMarkers.layer' | translate }}* </span>
        <mat-form-field
          appearance="fill"
          [class.no-error]="form.controls.newLayerName.valid || !form.controls.newLayerName.touched"
        >
          <mat-label>{{ 'atlas.moveMarkers.name' | translate }} </mat-label>
          <input formControlName="newLayerName" matInput />
          <mat-icon
            *ngIf="!form.controls.newLayerName.valid && form.controls.newLayerName.touched"
            color="warn"
            matSuffix
            >error</mat-icon
          >
          <mat-error *ngIf="!form.controls.newLayerName.valid">{{ 'atlas.moveMarkers.error' | translate }} </mat-error>
        </mat-form-field>
        <mat-checkbox [disabled]="data.hasToDisableCheckbox" *ngIf="data.isCopyDialog" formControlName="isInProgress">{{
          'atlas.copyMarkers.markAsInProgress' | translate
        }}</mat-checkbox>
      </div>
      <div *ngIf="data.isCopyDialog" class="move-markers__new-layer">
        <span>{{ 'atlas.copyMarkers.addTo' | translate }}</span>
        <mat-form-field class="no-error" appearance="fill">
          <mat-label>{{ 'atlas.copyMarkers.groupOfLayers' | translate }}</mat-label>

          <mat-select #test formControlName="groupName" value="test">
            <mat-option
              ><mat-icon class="move-markers__folder-icon">folder_off</mat-icon
              >{{ 'atlas.copyMarkers.none' | translate }}</mat-option
            >
            <mat-option *ngFor="let group of groups$ | async; let i = index" [value]="group.name">
              <mat-icon class="move-markers__folder-icon" svgIcon="generic_folder"></mat-icon
              >{{ group.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div class="move-markers__custom-group">
          {{ 'atlas.copyMarkers.or' | translate }}
          <mat-form-field class="no-error" appearance="fill">
            <mat-label>{{ 'atlas.copyMarkers.newGroup' | translate }}</mat-label>
            <input formControlName="customGroupName" matInput type="text" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-radio-button
      [class.opaque-radio]="form.controls.destination.value === moveMarkerDestination.NEW_LAYER"
      [value]="moveMarkerDestination.EXISTING_LAYER"
    >
      <span> {{ 'atlas.moveMarkers.existingLayer' | translate }} </span>
      <mat-icon class="move-markers__arrow-icon">arrow_drop_down</mat-icon></mat-radio-button
    >
    <div
      *ngIf="form.controls.destination.value === moveMarkerDestination.EXISTING_LAYER"
      class="move-markers__new-layer"
    >
      <span>{{ 'atlas.moveMarkers.layer' | translate }} </span>
      <mat-form-field
        appearance="fill"
        [class.no-error]="form.controls.existingLayer.valid || !form.controls.existingLayer.touched"
        (click)="searchInput.focus()"
      >
        <mat-label>{{ 'atlas.moveMarkers.name' | translate }} </mat-label>
        <mat-select formControlName="existingLayer">
          <div class="move-markers__search">
            <mat-icon class="move-markers__search-icon">search</mat-icon>
            <input
              #searchInput
              formControlName="search"
              matInput
              type="text"
              [placeholder]="'atlas.moveMarkers.searchLayerName' | translate"
            />
          </div>
          <mat-option
            [value]="layer"
            *ngFor="let layer of availableLayers | async"
            [disabled]="layer.id === data.currentLayer.id"
            >{{ layer.name }}</mat-option
          >
        </mat-select>

        <mat-error *ngIf="!form.controls.existingLayer.valid">{{ 'atlas.moveMarkers.error' | translate }} </mat-error>
      </mat-form-field>
    </div>
  </mat-radio-group>
  <section class="move-markers__actions">
    <div class="move-markers__info">
      <mat-icon>subject</mat-icon>
      {{ 'atlas.moveMarkers.message' | translate }}
    </div>
    <div class="move-markers__buttons">
      <a [disabled]="hasToShowSpinner | async" mat-dialog-close mat-button class="move-markers__cancel-button"
        >{{ 'common.cancel' | translate }}
      </a>
      <a
        *ngIf="!data.isCopyDialog; else copyButtonTemplate"
        [disabled]="isActionDisabled() || (hasToShowSpinner | async)"
        mat-button
        class="move-markers__move-button"
        [ulMoveMarkers]="form.controls.destination.value"
        [layerName]="form.controls.newLayerName.value"
        [existingLayer]="form.controls.existingLayer.value"
        [currentLayer]="data.currentLayer"
        [map]="data.map"
        (showSpinner)="setHasToShowSpinner($event)"
        >{{ 'atlas.moveMarkers.move' | translate }}
        <unleash-spinner
          *ngIf="hasToShowSpinner | async"
          diameter="20"
          borderWidth="2.1"
          color="#111"
        ></unleash-spinner>
      </a>
      <ng-template #copyButtonTemplate>
        <a
          [disabled]="isActionDisabled() || (hasToShowSpinner | async)"
          mat-button
          class="move-markers__move-button"
          [ulCopyMarkers]="form.controls.destination.value"
          [layerName]="form.controls.newLayerName.value"
          [existingLayer]="form.controls.existingLayer.value"
          [currentLayer]="data.currentLayer"
          [groupName]="
            form.controls.groupName.value ? form.controls.groupName.value : form.controls.customGroupName.value
          "
          [isInProgress]="form.controls.isInProgress.value"
          [map]="data.map"
          (showSpinner)="setHasToShowSpinner($event)"
          >{{ 'atlas.copyMarkers.copy' | translate }}
          <unleash-spinner
            *ngIf="hasToShowSpinner | async"
            diameter="20"
            borderWidth="2.1"
            color="#111"
          ></unleash-spinner>
        </a>
      </ng-template>
    </div>
  </section>
</form>

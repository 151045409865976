import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PlanTemplate} from '@app/auth/pages/payment/payment.config';

@Component({
  selector: 'unleash-developer-plan-features',
  templateUrl: './developer-plan-features.component.html',
  styleUrls: ['./developer-plan-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeveloperPlanFeaturesComponent {
  @Input() features: PlanTemplate['features'];
  @Input() model: Partial<PlanTemplate['model']>;
}

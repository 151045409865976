import {RuleItem, Rule} from '@app/shared/analysis-configuration/models/rule.model';

export interface ManagerZonesAnalyzeState {
  isRuleEditMode: boolean;
  rules: Rule[];
  selectedRule: RuleItem;
  ruleForm: Rule;
  isPerformingAnalysis: boolean;
}

export const initialState: ManagerZonesAnalyzeState = {
  isRuleEditMode: false,
  rules: [],
  selectedRule: null,
  ruleForm: null,
  isPerformingAnalysis: false
};

import {Injectable} from '@angular/core';
import {format} from 'date-fns';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  LibraryFilter,
  LibraryFilterChip,
  LibraryFilterChipDisplayName,
  LibraryFilterChipIcon
} from '../models/library-filter.model';

@Injectable({
  providedIn: 'root'
})
export class LibraryFilterService {
  private emptyFiltersTemplate: LibraryFilter = {
    search: null,
    dateFrom: null,
    hourFrom: null,
    dateTo: null,
    hourTo: null,
    type: null,
    device: null,
    analysis: null,
    tags: [],
    timestamp: null,
    filterAllLevels: false
  };
  public filterChipsForm: {[key: string]: LibraryFilterChip} = {};
  public filterFormValue: BehaviorSubject<LibraryFilter> = new BehaviorSubject(this.emptyFiltersTemplate);
  public filterChips: BehaviorSubject<{[key: string]: LibraryFilterChip}> = new BehaviorSubject({});

  public filterFormValue$: Observable<LibraryFilter>;
  public filterChips$: Observable<{[key: string]: LibraryFilterChip}>;

  constructor() {
    this.filterFormValue$ = this.filterFormValue.asObservable();
    this.filterChips$ = this.filterChips.asObservable();
  }

  public parseFilter(
    key: keyof LibraryFilter | 'date',
    value: string | string[] | {dateFrom: number; dateTo: number} | boolean
  ): {[key: string]: string | string[]} {
    let parseFilter;
    let parseChip;

    if (typeof value === 'string') {
      switch (key) {
        case 'analysis':
          parseFilter = this.parseAnalysisFilter(value as string);
          parseChip = this.parseAnalysisChip(value as string);
          break;
        case 'device':
          parseFilter = this.parseDeviceFilter(value as string);
          parseChip = this.parseDeviceChip(value as string);
          break;
        case 'search':
          parseFilter = this.parseSearchFilter(value as string);
          parseChip = this.parseSearchChip(value as string);
          break;
        case 'type':
          parseFilter = this.parseTypeFilter(value as string);
          parseChip = this.parseTypeChip(value as string);
          break;
      }
    } else if (Array.isArray(value)) {
      parseFilter = this.parseTagsFilter(value as string[]);
      parseChip = this.parseTagsChip(value as string[]);
    } else if (typeof value === 'boolean') {
      if (key === 'filterAllLevels') {
        parseFilter = this.parseFilterAllLevelsFilter(value as boolean);
        parseChip = this.parseFilterAllLevelsChip(value as boolean);
      }
    } else {
      value = value as {dateFrom: number; dateTo: number};
      parseFilter = {
        ...this.parseDateFromFilter(value.dateFrom),
        ...this.parseDateToFilter(value.dateTo),
        ...this.parseHourFromFilter(value.dateFrom),
        ...this.parseHourToFilter(value.dateTo)
      };

      parseChip = {
        ...this.parseDateFromChip(value.dateFrom),
        ...this.parseDateToChip(value.dateTo)
      };
    }

    this.updateChips(parseChip);
    this.updateFilter(parseFilter);
    return parseFilter;
  }

  public updateAllFormFilter(formFilter: Partial<LibraryFilter>): void {
    const filterFormValue = !formFilter ? this.emptyFiltersTemplate : {...this.filterFormValue.value, ...formFilter};

    if (formFilter.timestamp) {
      this.filterFormValue.next({
        ...filterFormValue
      });
      this.filterChipsForm = this.generateAllFilterChips(formFilter);
      this.filterChips.next(this.filterChipsForm);
      return;
    }

    const dateFrom = !!formFilter?.dateFrom ? parseInt(formFilter.dateFrom as string) : null;
    const dateTo = !!formFilter?.dateTo ? parseInt(formFilter.dateTo as string) : null;
    this.filterFormValue.next({
      ...filterFormValue,
      dateFrom,
      dateTo
    });

    this.filterChipsForm = this.generateAllFilterChips(formFilter);
    this.filterChips.next(this.filterChipsForm);
  }

  public setAllFormFilter(formFilter: Partial<LibraryFilter>): void {
    const dateFrom = !!formFilter?.dateFrom ? parseInt(formFilter.dateFrom as string) : null;
    const dateTo = !!formFilter?.dateTo ? parseInt(formFilter.dateTo as string) : null;
    const filterFormValue = !formFilter ? this.emptyFiltersTemplate : {...this.emptyFiltersTemplate, ...formFilter};

    console.log('setAllFormFilter filterFormValue', filterFormValue);

    this.filterFormValue.next({
      ...filterFormValue,
      dateFrom,
      dateTo
    });

    this.filterChipsForm = this.generateAllFilterChips(formFilter);
    this.filterChips.next(this.filterChipsForm);
  }

  public deleteFilter(filterKey: string): void {
    const newFormValue = {...this.filterFormValue.value};
    const newFilterChipsFormValue = {...this.filterChipsForm};
    if (!filterKey.includes('tags#')) newFormValue[filterKey] = null;

    switch (filterKey) {
      case 'dateFrom':
        newFormValue['dateFrom'] = null;
        newFormValue['hourFrom'] = null;
        delete newFilterChipsFormValue['dateFrom'];
        delete newFilterChipsFormValue['hourFrom'];
        break;
      case 'dateTo':
        newFormValue['dateTo'] = null;
        newFormValue['hourTo'] = null;
        delete newFilterChipsFormValue['dateTo'];
        delete newFilterChipsFormValue['hourTo'];
        break;
      default:
        if (filterKey.includes('tags#')) {
          const tagId = filterKey.slice(5);
          newFormValue.tags = newFormValue.tags.filter(id => id !== tagId);
        } else {
          newFormValue[filterKey] = null;
        }
        delete newFilterChipsFormValue[filterKey];
        break;
    }
    this.filterChipsForm = newFilterChipsFormValue;
    this.filterFormValue.next({...this.filterFormValue.value, ...newFormValue});
    this.filterChips.next(newFilterChipsFormValue);
  }

  deleteAllFilters(): void {
    const newFormValue = {...this.filterFormValue.value};
    const newFilterChipsFormValue = {...this.filterChipsForm};

    Object.keys(this.filterFormValue.value).forEach(key => {
      newFormValue[key] = key === 'tags' ? [] : null;
      delete newFilterChipsFormValue[key];
    });

    this.filterFormValue.next({...newFormValue});
    this.filterChipsForm = newFilterChipsFormValue;
    this.filterChips.next(newFilterChipsFormValue);
  }

  private getHoursFromDate(date: number): string {
    return format(date, 'HH:mm');
  }

  private updateFilter(parseFilter: {[key: string]: string}): void {
    this.filterFormValue.next({...this.filterFormValue.value, ...parseFilter});
  }

  private updateChips(libraryFilter: {[key: string]: LibraryFilterChip}): void {
    this.filterChipsForm = {
      ...this.filterChipsForm,
      ...libraryFilter
    };
  }

  private generateAllFilterChips(formFilter: Partial<LibraryFilter>): {
    [key: string]: LibraryFilterChip;
  } {
    return {
      ...this.parseAnalysisChip(formFilter?.analysis),
      ...this.parseDateFromChip(parseInt(formFilter?.dateFrom as string)),
      ...this.parseDateToChip(parseInt(formFilter?.dateTo as string)),
      ...this.parseDeviceChip(formFilter?.device),
      ...this.parseSearchChip(formFilter?.search),
      ...this.parseTypeChip(formFilter?.type),
      ...this.parseTagsChip(formFilter?.tags),
      ...this.parseTimestampChip(formFilter?.timestamp),
      ...this.parseFilterAllLevelsChip(formFilter?.filterAllLevels)
    };
  }

  private parseAnalysisFilter(analysis: string): {
    analysis: LibraryFilter['analysis'];
  } {
    return {analysis: !!analysis && analysis !== 'All' ? analysis : null};
  }

  private parseDateFromFilter(dateFrom: number): {
    dateFrom: LibraryFilter['dateFrom'];
  } {
    return {dateFrom};
  }

  private parseDateToFilter(dateTo: number): {dateTo: LibraryFilter['dateTo']} {
    return {dateTo};
  }

  private parseDeviceFilter(deviceId: string): {
    device: LibraryFilter['device'];
  } {
    return {device: deviceId};
  }

  private parseHourFromFilter(dateFrom: number): {
    hourFrom: LibraryFilter['hourFrom'];
  } {
    let hourFrom = null;
    if (dateFrom) {
      hourFrom = this.getHoursFromDate(dateFrom);
    }
    return {hourFrom};
  }

  private parseHourToFilter(dateTo: number): {hourTo: LibraryFilter['hourTo']} {
    let hourTo = null;
    if (dateTo) {
      hourTo = this.getHoursFromDate(dateTo);
    }
    return {hourTo};
  }

  private parseSearchFilter(search: string): {search: LibraryFilter['search']} {
    return {search: !!search ? search : null};
  }

  private parseTypeFilter(type: string): {type: LibraryFilter['type']} {
    return {type};
  }

  private parseTagsFilter(tags: string[]): {tags: LibraryFilter['tags']} {
    return {tags};
  }

  private parseFilterAllLevelsFilter(filterAllLevels: boolean): {filterAllLevels: LibraryFilter['filterAllLevels']} {
    return {filterAllLevels};
  }

  private parseAnalysisChip(analysis: string):
    | {
        analysis: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (!!analysis && analysis !== 'All') {
      chip['analysis'] = {
        name: LibraryFilterChipDisplayName.analysis,
        icon: LibraryFilterChipIcon.analysis,
        value: analysis
      };
    }
    return chip;
  }

  private parseDateFromChip(dateFrom: number):
    | {
        dateFrom: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (!!dateFrom) {
      const date = new Date(dateFrom);
      chip['dateFrom'] = {
        name: LibraryFilterChipDisplayName.dateFrom,
        icon: LibraryFilterChipIcon.dateFrom,
        value: date
      };
    }
    return chip;
  }

  private parseDateToChip(dateTo: number):
    | {
        dateTo: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (!!dateTo) {
      const date = new Date(dateTo);
      chip['dateTo'] = {
        name: LibraryFilterChipDisplayName.dateTo,
        icon: LibraryFilterChipIcon.dateTo,
        value: date
      };
    }
    return chip;
  }

  private parseDeviceChip(deviceId: string):
    | {
        device: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (deviceId) {
      chip['device'] = {
        key: 'device',
        name: LibraryFilterChipDisplayName['device'],
        icon: LibraryFilterChipIcon['device'],
        value: deviceId
      };
    }
    return chip;
  }

  private parseTimestampChip(timestamp: number):
    | {
        timestamp: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (timestamp) {
      chip['timestamp'] = {
        key: 'timestamp',
        name: LibraryFilterChipDisplayName['timestamp'],
        icon: LibraryFilterChipIcon['timestamp'],
        value: timestamp
      };
    }
    return chip;
  }

  private parseSearchChip(search: string):
    | {
        search: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (search) {
      chip['search'] = {
        key: 'search',
        name: LibraryFilterChipDisplayName['search'],
        icon: LibraryFilterChipIcon['search'],
        value: search
      };
    }
    return chip;
  }

  private parseTypeChip(type: string):
    | {
        search: LibraryFilterChip;
      }
    | {} {
    const chip = {};
    if (type) {
      chip['type'] = {
        key: 'type',
        name: LibraryFilterChipDisplayName['type'],
        icon: LibraryFilterChipIcon['type'],
        value: type
      };
    }
    return chip;
  }

  private parseTagsChip(tagIds: string[]): {} {
    const chips = [];
    if (tagIds?.length > 0) {
      tagIds.forEach(tagId => {
        chips.push({
          key: 'tags#' + tagId,
          name: LibraryFilterChipDisplayName['tag'],
          icon: LibraryFilterChipIcon['tags'],
          value: tagId
        });
      });
    }
    return {tags: {name: LibraryFilterChipDisplayName['tag'], icon: LibraryFilterChipIcon['tags'], chips}};
  }

  private parseFilterAllLevelsChip(hasFilterAllLevels: boolean): {} {
    if (hasFilterAllLevels) {
      return {
        filterAllLevels: {
          key: 'filterAllLevels',
          name: LibraryFilterChipDisplayName['filterAllLevels'],
          icon: LibraryFilterChipIcon['filterAllLevels'],
          value: hasFilterAllLevels
        }
      };
    }
    return {};
  }
}

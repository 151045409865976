import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '@app/auth/services/auth.service';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription} from 'rxjs';
import {actionConfirmMfaToken, actionSetupMFA} from '../sign-in/sign-in.actions';
import {MFA, MFA_SETUP_STATE} from '@app/auth/models/auth.models';
import {Router} from '@angular/router';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';

@Component({
  selector: 'unleash-totp-token',
  templateUrl: './totp-token.component.html',
  styleUrls: ['./totp-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotpTokenComponent implements OnDestroy, AfterViewInit {
  public token: string = this.authService.totpToken;
  public isInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public totpForm: FormGroup = this.fb.group({
    code: [null, [Validators.required, Validators.maxLength(6)]]
  });
  public isTotpCodeValid: BehaviorSubject<boolean> = this.authService.isTotpCodeValid;
  private isTotpValidCodeSub: Subscription;

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnDestroy(): void {
    if (this.isTotpValidCodeSub) {
      this.isTotpValidCodeSub.unsubscribe();
      this.isTotpValidCodeSub = null;
    }
  }

  public ngAfterViewInit(): void {
    this.isTotpValidCodeSub = this.isTotpCodeValid.subscribe(isValid => {
      if (!isValid) {
        this.isInProgress.next(false);
        this.totpForm.controls.code.setErrors({invalidCode: true});
      }
    });
  }

  public continue(): void {
    if (this.totpForm.valid) {
      this.isInProgress.next(true);
      this.store.dispatch(
        actionConfirmMfaToken({
          code: this.totpForm.value.code
        })
      );
    }
  }

  public back(): void {
    this.localStorageService.setItem('MFASetupState', MFA_SETUP_STATE.SETUP);
    this.router.navigate(['auth/setup-mfa']);
  }

  public copyToken(): void {
    this.snackbar.open('Token copied to the clipboard', null, {
      duration: 3000,
      panelClass: 'center'
    });
  }

  public generateNewToken(): void {
    this.store.dispatch(
      actionSetupMFA({
        mfaType: MFA.TOTP
      })
    );
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {LIBRARY_ITEM_TYPE_MODEL} from '@app/library/models/mime-type.model';
import {Observable} from 'rxjs';
import {SidebarStateService} from '../../../services/sidebar-state.service';
import {SideNavMenuService} from '@app/core/services/side-navbar-menu.service';

@Component({
  selector: 'app-footer-navbar',
  templateUrl: './footer-navbar.component.html',
  styleUrls: ['./footer-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterNavbarComponent {
  @Input() public hasMobileHeaderLabel: boolean;

  public LIBRARY_ITEM_TYPE_MODEL: typeof LIBRARY_ITEM_TYPE_MODEL = LIBRARY_ITEM_TYPE_MODEL;
  public aclPermissions = AclPermissions;
  public hasToDisplayMobileMenu$: Observable<boolean> = this.sideNavbarStateService.hasToDisplayMobileMenu;
  public isSidenavExpanded = this.sideNavbarStateService.hasToDisplayMobileMenu;
  public menuItems = this.sideNavMenuService.MENU_ITEMS_MOBILE;

  constructor(private sideNavbarStateService: SidebarStateService, private sideNavMenuService: SideNavMenuService) {}

  public toggleMenu(): void {
    this.sideNavbarStateService.expanded = true;
    this.sideNavbarStateService.toggleMobileMenu();
  }

  public closeMobileNav(): void {
    this.sideNavbarStateService.closeMobileMenu();
  }
}

<ng-container *ngIf="streamStopped && !isStreaming; else initialInfo">
  <div class="stream-options">
    <div class="stream-options__item">
      <mat-icon class="stream-options__icon">collections</mat-icon>
      <p class="stream-options__description">
        {{ 'stream-webrtc.libraryInfoMenu' | translate }}
      </p>
    </div>
    <button mat-button class="go-library streaming-button" [routerLink]="'/secure/library/'">
      <mat-icon class="stream-options__library-icon">collections</mat-icon>
      <p class="stream-start__text">
        {{ 'stream-webrtc.goToLibrary' | translate }}
      </p>
    </button>
    <button
      [disabled]="!isConnected"
      [ngStyle]="isConnected ? {opacity: '1'} : {opacity: '0.5'}"
      data-test-id="stream-again"
      mat-button
      class="stream-again streaming-button"
      (click)="emitStartStreaming()"
    >
      <mat-icon>refresh</mat-icon>
      <p class="stream-start__text">
        {{ 'stream-webrtc.streamAgain' | translate }}
      </p>
    </button>
  </div>
</ng-container>
<ng-template #initialInfo>
  <div class="stream-options" *ngIf="!isStreaming">
    <div class="stream-options__item">
      <mat-icon class="stream-options__icon" svgIcon="movie_open"></mat-icon>
      <p class="stream-options__description">
        {{ 'stream-webrtc.livePageInfoMenu' | translate }}
      </p>
    </div>
    <div class="stream-options__item">
      <mat-icon class="stream-options__icon" svgIcon="upload"></mat-icon>
      <p class="stream-options__description">
        {{ 'stream-webrtc.libraryStreamsInfoMenu' | translate }}
      </p>
    </div>
    <div class="stream-options__item stream-options__item--hide-on-mobile">
      <mat-icon class="stream-options__icon">share</mat-icon>
      <div class="stream-options__description">
        <p>{{ 'stream-webrtc.shareStreamInfoMenu' | translate }}</p>
        <ng-container *ngIf="isLoadingCompanyInfo; else sharedOptions">
          <unleash-spinner [diameter]="20" [color]="'#fff'"></unleash-spinner>
        </ng-container>
        <ng-template #sharedOptions>
          <ng-container *ngIf="isShared; else enableShare">
            <a mat-button class="shared-options" data-test-id="copy-url" (click)="emitCopyUrl()">
              <mat-icon svgIcon="copy"></mat-icon>
              <p class="shared-options__text">
                {{ 'stream-webrtc.copyLink' | translate }}
              </p>
            </a>
            <a data-test-id="generate-url" mat-button class="shared-options" (click)="emitGenerateUrl()">
              <mat-icon *ngIf="!isloadingSlug">loop</mat-icon>
              <unleash-spinner [diameter]="20" *ngIf="isloadingSlug"></unleash-spinner>

              <p class="shared-options__text">
                {{ 'stream-webrtc.generateSecureUrl' | translate }}
              </p>
            </a>
          </ng-container>
          <ng-template #enableShare>
            <a data-test-id="enable-page" mat-button class="shared-options" (click)="emitEnablePage()">
              <unleash-spinner [diameter]="20" *ngIf="isEnablingLivePage"></unleash-spinner>

              <p>{{ 'stream-webrtc.enableLivePage' | translate }}</p>
            </a>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="stream-options__item">
      <mat-icon class="stream-options__icon">warning</mat-icon>
      <p class="stream-options__description">
        {{ 'stream-webrtc.warningInfoMenu' | translate }}
      </p>
    </div>
    <div *ngIf="isStreamProxyEnabled" class="stream-options__item">
      <mat-icon class="stream-options__icon" svgIcon="secure-proxy"></mat-icon>
      <div class="stream-options__description stream-options__secure-proxy">
        <mat-checkbox
          [disabled]="!isConnected"
          class="stream-options__checkbox"
          (change)="emitSecureTunnel($event.checked)"
        >
        </mat-checkbox>
        <div class="stream-options__secure-content">
          <span class="stream-options__secure-title">{{ 'stream-webrtc.secureTunnel' | translate }} </span>
          <span> {{ 'stream-webrtc.secureProxy' | translate }} </span>
        </div>
      </div>
    </div>
    <div *ngIf="deviceForm" class="stream-options__item">
      <mat-icon class="stream-options__icon">camera</mat-icon>
      <div [formGroup]="deviceForm" class="stream-options__description">
        <mat-form-field class="stream-options__device-selector" appearance="fill">
          <mat-label> {{ 'stream-webrtc.device' | translate }}</mat-label>
          <mat-select [disabled]="!isConnected" (selectionChange)="emitNewDevice($event)" formControlName="deviceId">
            <mat-option *ngFor="let device of userDevices" [value]="device.id">{{ device.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span> {{ 'stream-webrtc.deviceInfo' | translate }} </span>
      </div>
    </div>
    <div *ngIf="!isConnected; else startStreaming" class="stream-start__loading">
      <unleash-spinner [diameter]="30" [borderWidth]="4" [color]="'#fff'"></unleash-spinner>
      {{ 'stream-webrtc.connectingToServer' | translate }}
    </div>
    <ng-template #startStreaming>
      <button
        data-test-id="start-streaming"
        mat-button
        [ngStyle]="{visibility: isConnected ? 'visible' : 'hidden'}"
        class="stream-start streaming-button streaming-button--hide-on-mobile"
        (click)="emitStartStreaming()"
      >
        <mat-icon svgIcon="streaming" class="stream-start__icon" *ngIf="!isStartingStreaming"></mat-icon>
        <p class="stream-start__text">
          {{ 'stream-webrtc.startStreaming' | translate }}
        </p>
      </button>
    </ng-template>
  </div>
</ng-template>

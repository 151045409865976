import {CompanyModel, UserCompanyModel} from '@app/core/models/api/company-model';
import {AnalyticsConfig, StreamPlayer, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserModel} from '@app/core/models/api/user-model';
import {Team} from '@app/profile/models/team.model';
import {AddonZones} from '@app/shared/annotation-shared/models/annotations.model';
import {CalibrationType} from '@app/shared/calibration/calibration.component';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {createAction, props} from '@ngrx/store';

export enum UserActionTypes {
  getTeam = '[Team] Get team data',
  userStoreData = '[User] Store user data',
  userStoreDeviceData = '[User] Store user devices data',
  userStoreRemoveDeviceData = '[User] Remove user device',
  userStoreCompany = '[User] Store company',
  getCompanyDataIfUserIsAdmin = '[User] Get company data if user is admin',

  updateDeviceZonesConfig = '[Device] Update device zones config',
  setDeviceUsage = '[Device] set device usage',
  updateDeviceCache = '[Device] update device cache',
  removeDeviceFromUser = '[Device] remove device from user',
  removeDeviceFromCache = '[Device] remove device from cache',
  findAllUserDevices = '[Device] find all user devices',
  getCompanyIfUserIsNotAdmin = '[Device] get company if user is not admin',
  updateUser = '[Device] update user',
  updatePlanInUserDataStore = '[Device] update plan in user data store',
  saveCompanies = '[Company] save companies',
  saveUserCompanies = '[Company] save user companies', // TODO
  createCompany = '[Company] create company', // TODO
  registerCompany = '[Company] create company', // TODO
  updateCompany = '[Company] update company', // TODO
  setupUserDataModel = '[Device] set up user data model',
  saveSetOfDevices = '[Device] save set of devices',

  getUserRolesByCompany = '[User] Get user roles by company',
  setUserStoreRole = '[User] Set user store role',

  loadUserTeams = '[User] Load user teams',
  loadUserTeamsSuccess = '[User] Load user teams success',

  switchCurrentTeam = '[User] Switch current team',
  switchCurrentTeamSuccess = '[User] Switch current team success',
  goToManageTeams = '[User] Go to manage teams',

  updateAnalyticsConfig = '[Device] Update analytics config',

  deleteSceneMapping = '[Device] Delete scene mapping',
  deleteSceneMappingSuccess = '[Device] Delete scene mapping success',

  confirmDeleteMarkerByIndex = '[Device] Confirm delete marker by index scene mapping',

  addLiveDevice = '[Device Stream] Add device live properties',
  setRunningModelsByDeviceId = '[Device Stream] Set running models by device id',
  setCurrentModel = '[Device Stream] set current model by device id',
  removeAddonFromWaitingModels = '[Device Stream] revemove addon from waiting models',
  removeAddonFromWaitingModelsDueError = '[Device Stream] revemove addon from waiting models due error',
  updateRestreamsStats = '[Device Stream] update restreams stats',
  removeAddonFromRunningModels = '[Device Stream] remove addon from running models',
  setDisplayedDeviceIds = '[Device Stream] set displayed device ids',
  changeDestUrlSetOnSelectedDevice = '[Device Stream] show/hide dest url set on selected device',
  setDestUrlOnSelectedDevice = '[Device Stream] set dest url on selected device',
  updateDevicesCache = '[Device] update devices cache',
  setupMobilePhone = '[User] Setup mobile phone',
  setIsUpdatingCognitoUser = '[User] set is updating cognito mode',
  verifyMobileNumber = '[User] verify mobile number'
}

export const userStoreData = createAction(UserActionTypes.userStoreData, props<{user: UserModel}>());

// Devices
export const userStoreDeviceData = createAction(
  UserActionTypes.userStoreDeviceData,
  props<{devices: {[key: string]: UserDeviceJoined}}>()
);
export const userStoreRemoveDeviceData = createAction(
  UserActionTypes.userStoreRemoveDeviceData,
  props<{deviceId: string}>()
);

export const userStoreCompany = createAction(UserActionTypes.userStoreCompany, props<{company: CompanyModel | null}>());

export const updateDeviceZonesConfig = createAction(
  UserActionTypes.updateDeviceZonesConfig,
  props<{deviceId: string; zonesConfig: {[x: string]: string | AddonZones}}>()
);

export const updateAnalyticsConfig = createAction(
  UserActionTypes.updateAnalyticsConfig,
  props<{deviceId: string; analyticsConfig: AnalyticsConfig}>()
);

export const setDeviceUsage = createAction(UserActionTypes.setDeviceUsage);
export const updateDeviceCache = createAction(
  UserActionTypes.updateDeviceCache,
  props<{device: Partial<UserDeviceJoined>}>()
);
export const removeDeviceFromUser = createAction(
  UserActionTypes.removeDeviceFromUser,
  props<{deviceId: string; userId: string}>()
);
export const removeDeviceFromCache = createAction(UserActionTypes.removeDeviceFromCache, props<{deviceId: string}>());
export const findAllUserDevices = createAction(
  UserActionTypes.findAllUserDevices,
  props<{identityId: string; fetchUserDevicesCount: number; FETCH_DEVICES_MAX_RETRY: number}>()
);

export const getCompanyIfUserIsNotAdmin = createAction(UserActionTypes.getCompanyIfUserIsNotAdmin);
export const getTeam = createAction(UserActionTypes.getTeam);

export const getCompanyDataIfUserIsAdmin = createAction(UserActionTypes.getCompanyDataIfUserIsAdmin);

export const updateUser = createAction(
  UserActionTypes.updateUser,
  props<{user: Partial<UserModel>; identityId: string}>()
);

export const updatePlanInUserDataStore = createAction(
  UserActionTypes.updatePlanInUserDataStore,
  props<{planId: string; period: PaymentPeriod}>()
);

export const loadUserTeams = createAction(UserActionTypes.loadUserTeams);
export const loadUserTeamsSuccess = createAction(
  UserActionTypes.loadUserTeamsSuccess,
  props<{payload: {teams: {[key: string]: Team}}}>()
);

export const switchCurrentTeam = createAction(UserActionTypes.switchCurrentTeam, props<{payload: {teamId: string}}>());

export const switchCurrentTeamSuccess = createAction(
  UserActionTypes.switchCurrentTeamSuccess,
  props<{payload: {teamId: string}}>()
);

export const saveCompanies = createAction(UserActionTypes.saveCompanies, props<{companies: CompanyModel[]}>());

export const saveUserCompanies = createAction(
  UserActionTypes.saveUserCompanies,
  props<{companies: UserCompanyModel[]}>()
);

export const createCompany = createAction(UserActionTypes.createCompany, props<{company: Partial<CompanyModel>}>());
export const registerCompany = createAction(UserActionTypes.registerCompany, props<{company: Partial<CompanyModel>}>());

export const updateCompany = createAction(
  UserActionTypes.updateCompany,
  props<{companyId: string; company: Partial<CompanyModel>}>()
);

export const setupUserDataModel = createAction(UserActionTypes.setupUserDataModel, props<{user: UserModel}>());

export const saveSetOfDevices = createAction(
  UserActionTypes.saveSetOfDevices,
  props<{devices: {[key: string]: UserDeviceJoined}}>()
);

export const getUserRolesByCompany = createAction(UserActionTypes.getUserRolesByCompany);

export const setUserStoreRole = createAction(UserActionTypes.setUserStoreRole, props<{payload: {roles: string[]}}>());

export const goToManageTeams = createAction(UserActionTypes.goToManageTeams);

export const deleteSceneMapping = createAction(
  UserActionTypes.deleteSceneMapping,
  props<{
    payload: {deviceId: string; addonId: string; calibrationType: CalibrationType; analyticsConfig: AnalyticsConfig};
  }>()
);

export const deleteSceneMappingSuccess = createAction(
  UserActionTypes.deleteSceneMappingSuccess,
  props<{payload: {deviceId: string; addonId: string}}>()
);

export const confirmDeleteMarkerByIndex = createAction(
  UserActionTypes.confirmDeleteMarkerByIndex,
  props<{payload: {index: number}}>()
);

export const addLiveDevice = createAction(
  UserActionTypes.addLiveDevice,
  props<{payload: {deviceId: UserDeviceJoined['id']; player: StreamPlayer}}>()
);

export const setRunningModelsByDeviceId = createAction(
  UserActionTypes.setRunningModelsByDeviceId,
  props<{payload: {deviceId: string; runningModels: string[]}}>()
);

export const setCurrentModel = createAction(
  UserActionTypes.setCurrentModel,
  props<{payload: {addonId: string; deviceId: string}}>()
);

export const updateRestreamsStats = createAction(
  UserActionTypes.updateRestreamsStats,
  props<{
    payload: {
      [key: string]: {
        isRestreamingFrom?: boolean;
        statsRestreamingFrom?: string;
        isRestreamingTo?: boolean;
        statsRestreamingTo?: string;
      };
    };
  }>()
);

export const removeAddonFromRunningModels = createAction(
  UserActionTypes.removeAddonFromRunningModels,
  props<{payload: {deviceId: string; modelId: string}}>()
);

export const removeAddonFromWaitingModels = createAction(
  UserActionTypes.removeAddonFromWaitingModels,
  props<{payload: {deviceId: string}}>()
);

export const removeAddonFromWaitingModelsDueError = createAction(
  UserActionTypes.removeAddonFromWaitingModelsDueError,
  props<{payload: {deviceId: string; modelId: string}}>()
);

export const setDisplayedDeviceIds = createAction(
  UserActionTypes.setDisplayedDeviceIds,
  props<{payload: {displayedDeviceIds: string[]}}>()
);

export const changeDestUrlSetOnSelectedDevice = createAction(
  UserActionTypes.changeDestUrlSetOnSelectedDevice,
  props<{payload: {destUrlSet: boolean; deviceId: string}}>()
);

export const setDestUrlOnSelectedDevice = createAction(
  UserActionTypes.setDestUrlOnSelectedDevice,
  props<{payload: {destUrl: string; deviceId: string}}>()
);

export const updateDevicesCache = createAction(
  UserActionTypes.updateDevicesCache,
  props<{payload: {[key: string]: Partial<UserDeviceJoined>}}>()
);

export const setupMobilePhone = createAction(UserActionTypes.setupMobilePhone, props<{phoneNumber: string}>());
export const setIsUpdatingCognitoUser = createAction(
  UserActionTypes.setIsUpdatingCognitoUser,
  props<{isUpdatingCognitoUser: boolean}>()
);
export const verifyMobileNumber = createAction(UserActionTypes.verifyMobileNumber, props<{phoneNumber: string}>());

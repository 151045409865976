import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserTeamTable} from '@app/profile/models/user-team.model';

@Component({
  selector: 'unleash-delete-user-team-dialog',
  templateUrl: './delete-user-team-dialog.component.html',
  styleUrls: ['./delete-user-team-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteUserTeamDialogComponent {
  constructor(
    private dialog: MatDialogRef<DeleteUserTeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserTeamTable
  ) {}

  public cancel(): void {
    this.dialog.close();
  }

  public deleteUser(): void {
    this.dialog.close(this.data.id);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GeoJSON} from 'geojson';
import {Map} from 'leaflet';
import {UntilDestroy} from '@ngneat/until-destroy';
import {EMPTY, from, Subscription} from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import {PermissionService} from '../../../core/services/permission.service';
import {EVENTS, UnleashAnalyticsService} from '../../../core/services/unleash-analytics.service';
import {AtlasAnnotationService} from '../../services/atlas-annotation.service';
import {DrawOnMapService} from '../draw-on-map.service';
import {SetLayerNameDialogComponent} from '../set-layer-name-dialog/set-layer-name-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {AtlasUploadService} from '@app/shared/services/upload/atlas-upload.service';
import {AclPermissions} from '@app/core/models/api/acl.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-annotation-control',
  templateUrl: './annotation-control.component.html',
  styleUrls: ['./annotation-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationControlComponent implements OnInit, OnDestroy {
  public events = EVENTS;
  @Input() public map: Map;
  @Input() public isSelectingMarkers: boolean = false;
  @Input('hasToOpenAnnotationsOptions') private set setHasToOpenAnnotations(hasToOpenAnnotations: boolean) {
    if (hasToOpenAnnotations === undefined) {
      return;
    }
    this.toggleDrawer(hasToOpenAnnotations);
  }

  @Input('isComparingLayers') public set setIsComparingLayers(isComparingLayers: boolean) {
    if (isComparingLayers) {
      this.ngOnDestroy();
    }
  }

  @Output()
  public assetsUploaded = new EventEmitter<any>();

  @ViewChild('matMenuTrigger')
  public matMenuTrigger: MatMenuTrigger;

  public formAnnotation: UntypedFormGroup = this.fb.group({
    type: [],
    severity: ['', Validators.required],
    comment: '',
    shape: ['', Validators.required],
    label: ''
  });

  public privateData;
  public annotationData = [];
  public selectedShape;
  public selectedShapeSubscription: Subscription;
  public annotationDataSubscription: Subscription;
  public onMoveShapeSubscription: Subscription;
  public aclPermissions = AclPermissions;

  private controlNamesToRemove = [];
  private isOnDeleteMode = false;

  constructor(
    public snackBar: MatSnackBar,
    public annotationService: AtlasAnnotationService,
    public dialog: MatDialog,
    private drawOnMap: DrawOnMapService,
    private atlasUploadService: AtlasUploadService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private permissions: PermissionService,
    private unleashAnalytics: UnleashAnalyticsService,
    private translateService: TranslateService
  ) {
    if (!!this.privateData) {
      this.formAnnotation.removeControl('type');
      this.formAnnotation.removeControl('severity');
      this.formAnnotation.removeControl('comment');
      this.controlNamesToRemove.push('type');
      this.controlNamesToRemove.push('severity');
      this.controlNamesToRemove.push('comment');
    }
  }

  public ngOnInit(): void {
    this.subscribeToPrivateAnnotationData();
    this.watchShapesDeleted();
    this.watchShapesSelected();
    this.watchDrawStarted();
    this.watchOnDeleteMode();
    this.watchOnMoveSelectedShape();
  }

  public ngOnDestroy(): void {
    this.drawOnMap.removeDrawControl();
    this.drawOnMap.clearDrawItems();
    this.annotationData = [];
    // Autounsubscribe()
    this.drawOnMap.clearEventListeners(this.map);
    this.cleanAndCloseAnnotationMenu();
    this.cd.detectChanges();
  }

  public toggleDrawer(hasToOpenAnnotations: boolean): void {
    if (hasToOpenAnnotations) {
      this.ngOnInit();
      this.drawOnMap.initialize(this.map, this.dialog);
      this.onOpenMenu();
      return;
    }
    this.ngOnDestroy();
  }

  public onOpenMenu(): void {
    if (!this.selectedShape || this.isOnDeleteMode) {
      this.matMenuTrigger.closeMenu();
    }
    this.drawOnMap.showDrawControl(this.map);
  }

  public onCloseMenu(): void {
    this.cleanMenuData();
  }

  public done(): void {
    if (!this.formAnnotation.controls.severity.valid) {
      this.formAnnotation.controls.severity.markAllAsTouched();
      return;
    }

    if (
      !!this.formAnnotation.valid &&
      !!this.formAnnotation.value.shape &&
      !!this.formAnnotation.controls.severity.valid
    ) {
      const annotationIndex = this.annotationData.findIndex(
        a => a.shape.id_leaflet === this.formAnnotation.value.shape.id_leaflet
      );
      const formValue = this.formAnnotation.value;

      if (!!this.privateData) {
        formValue.label = formValue.label.attr_label;
      } else {
        delete formValue.label;
      }

      // Save on Annotation Data
      if (annotationIndex >= 0) {
        this.annotationData[annotationIndex] = formValue;
      } else {
        this.annotationData.push(formValue);
      }
      this.cleanAndCloseAnnotationMenu();
      this.unleashAnalytics.logEvent(this.events.ATLAS_ANNOTATIONS, {type: 'Created'});
    } else {
      console.warn('form not valid');
    }
  }

  public cancel(): void {
    this.cleanAndCloseAnnotationMenu();
  }

  public watchShapesDeleted(): void {
    this.drawOnMap.emitDeletedShape.subscribe((deletedIds: string[]) => {
      this.annotationData = this.annotationData.filter(annotation => {
        return deletedIds.indexOf(annotation.shape.id_leaflet.toString()) < 0;
      });
      this.cleanAndCloseAnnotationMenu();
    });
  }

  public saveAllShapes(): void {
    if (!this.permissions.canUseAtlas()) {
      console.error('Atlas plan not enabled');
      return;
    }
    const annotationsGeoJSON = this.annotationData.map(annotation => {
      const newGeoJSON = annotation.shape;
      const properties = {...annotation};
      if (!!this.privateData) {
        properties['Install_Date'] = properties['Install_Date']
          ? properties['Install_Date']
          : Number(new Date().toISOString().split('T')[0].replace(/-/g, ''));
      }
      delete properties['shape'];
      newGeoJSON.properties = properties;
      return newGeoJSON;
    });

    const saveFeatureCollection = {
      features: annotationsGeoJSON,
      type: 'FeatureCollection'
    };

    this.dialog
      .open(SetLayerNameDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        closeOnNavigation: true,
        width: '400px'
      })
      .afterClosed()
      .subscribe((layerName: string) => {
        if (layerName) {
          const finalLayerName = layerName.toLowerCase().split(' ').join('-');
          const file = this.createFile(JSON.stringify(saveFeatureCollection), finalLayerName + '.geojson');
          this.atlasUploadService.addToQueue([file]).then(
            () => {
              this.translateService.get('atlas.annotation.allSaved').subscribe(res => {
                this.snackBar.open(`${res} ${finalLayerName}`, null, {
                  duration: 3000
                });
                this.unleashAnalytics.logEvent(EVENTS.ATLAS_ANNOTATIONS, {type: 'Saved'});
                const FIVE_SECONDS_IN_MILLISECONDS = 5000;
                setTimeout(() => {
                  this.assetsUploaded.emit(true);
                  this.drawOnMap.clearDrawItems();
                  this.annotationData = [];
                  this.cleanAndCloseAnnotationMenu();
                }, FIVE_SECONDS_IN_MILLISECONDS);
              });
            },
            error => {
              this.translateService.get('common.ok').subscribe(res => {
                this.snackBar.open(error, res, {
                  duration: 3000
                });
              });
            }
          );
        }
      });
  }

  public valueComparator(a, b): boolean {
    if (a && b) {
      return a.label === b.label;
    }
    return false;
  }

  private createFile(data: string, filename: string): File {
    const contentType = {type: 'text'};
    const blob = new Blob([data], contentType);
    return new File([blob], filename, {type: 'text', lastModified: Date.now()});
  }

  /**
   * If annotation data is available initialize UI, do nothing if not
   */
  private subscribeToPrivateAnnotationData(): void {
    this.annotationDataSubscription = this.annotationService
      .getAnnotationAssetDataUrl()
      .pipe(
        switchMap(dataUrl => (!dataUrl ? EMPTY : from(this.annotationService.getAssetTypesData(dataUrl)))),
        filter(assetTypesData => !!assetTypesData),
        map(
          assetTypesData =>
            (this.privateData = {
              Point: assetTypesData['point'],
              LineString: assetTypesData['line'],
              Polygon: assetTypesData['polygon']
            })
        ),
        tap(this.watchDropdownChanges.bind(this))
      )
      .subscribe(assetTypesData => {
        /* asset types data available*/
      });
  }

  private watchShapesSelected(): void {
    this.selectedShapeSubscription = this.drawOnMap.emitSelectedShape
      .pipe(
        distinctUntilChanged((prev, curr) => {
          if (!!prev && !!curr) {
            return prev._leaflet_id === curr._leaflet_id;
          }
          return false;
        })
      )
      .subscribe(selectedShape => {
        // update selected shape
        this.selectedShape = selectedShape;
        if (!selectedShape) {
          return;
        }
        // set shape id
        const shapeGeoJSON = selectedShape.toGeoJSON();
        // eslint-disable-next-line camelcase
        shapeGeoJSON.id_leaflet = selectedShape._leaflet_id;

        const shapeAnnotation = this.annotationData.find(a => a.shape.id_leaflet === shapeGeoJSON.id_leaflet);
        if (!!shapeAnnotation) {
          this.loadDataToForm(shapeAnnotation, shapeGeoJSON);
        } else {
          this.loadCleanForm(shapeGeoJSON);
        }
        this.cd.detectChanges();
        if (this.isSelectingMarkers) {
          return;
        }
        this.isOnDeleteMode ? this.matMenuTrigger.closeMenu() : this.matMenuTrigger.openMenu();
      });
  }

  private watchOnMoveSelectedShape(): void {
    this.onMoveShapeSubscription = this.drawOnMap.emitOnMoveShape.subscribe(selectedShapeOptions => {
      const {shape, layerType} = selectedShapeOptions;
      const shapeGeoJSON = shape.toGeoJSON();
      // eslint-disable-next-line camelcase
      shapeGeoJSON.id_leaflet = shape._leaflet_id;

      const shapeAnnotation = this.annotationData.find(a => a.shape.id_leaflet === shapeGeoJSON.id_leaflet);
      let newCoordinates = [];
      switch (layerType) {
        case 'polyline':
          newCoordinates = shape._latlngs.map(coordinate => [coordinate.lng, coordinate.lat]);
          break;
        case 'polygon':
          newCoordinates = [shape._latlngs[0].map(coordinate => [coordinate.lng, coordinate.lat])];
          break;
        case 'marker':
          newCoordinates = [shape._latlng.lng, shape._latlng.lat];
          break;
      }

      if (!!shapeAnnotation) {
        shapeAnnotation.shape.geometry.coordinates = newCoordinates;
      }
      const form = this.formAnnotation.value;
      form.shape.geometry.coordinates = newCoordinates;
      this.formAnnotation.setValue(form);
    });
  }

  private loadCleanForm(shapeGeoJSON: GeoJSON): void {
    const formAnnotation = {
      type: [],
      severity: '',
      comment: '',
      shape: shapeGeoJSON,
      label: ''
    };

    if (!!this.privateData) {
      delete formAnnotation['type'];
      delete formAnnotation['severity'];
      delete formAnnotation['comment'];
      this.controlNamesToRemove.push('type');
      this.controlNamesToRemove.push('severity');
      this.controlNamesToRemove.push('comment');
      this.activateDropdown('label', '');
    }

    this.controlNamesToRemove.forEach(name => this.formAnnotation.removeControl(name));
    this.controlNamesToRemove = [];

    this.formAnnotation.setValue(formAnnotation);
  }

  private loadDataToForm(shapeAnnotation: any, shapeGeoJSON: any): void {
    const properties = {...shapeAnnotation};
    delete properties['shape'];
    properties['shape'] = shapeGeoJSON;

    const formValue = properties;

    if (!!this.privateData && shapeAnnotation.label) {
      const attrLabel = this.privateData[shapeGeoJSON.geometry.type].find(
        facility => facility.attr_label === shapeAnnotation.label
      );
      formValue['label'] = attrLabel as any;
      this.activateDropdown('label', attrLabel);
    }
    this.formAnnotation.patchValue(formValue);
  }

  private watchDropdownChanges(): void {
    this.formAnnotation.get('label').valueChanges.subscribe(label => {
      if (label) {
        this.controlNamesToRemove.forEach(name => this.formAnnotation.removeControl(name));
        this.controlNamesToRemove = [];
        label.attr_keys.forEach(attrKey => {
          this.formAnnotation.addControl(attrKey.key, new UntypedFormControl(''));
          this.controlNamesToRemove.push(attrKey.key);
        });
        this.controlNamesToRemove.push('type');
        this.controlNamesToRemove.push('severity');
        this.controlNamesToRemove.push('comment');
        this.cd.detectChanges();
      }
    });
  }

  private activateDropdown(dropdownName: string, value: any): void {
    this.formAnnotation.controls[dropdownName].reset();
    this.formAnnotation.controls[dropdownName].setValue(value);
    this.formAnnotation.controls[dropdownName].setValidators([Validators.required]);
  }

  private cleanAndCloseAnnotationMenu(): void {
    this.matMenuTrigger?.closeMenu(); // TODO: Issue on close annotation button color;
    this.cleanMenuData();
  }

  private cleanMenuData(): void {
    this.drawOnMap.unselectShape(this.selectedShape);
    this.formAnnotation.reset();
    this.cd.detectChanges();
  }

  private watchDrawStarted(): void {
    this.drawOnMap.emitDrawStart.subscribe(isDrawStarted => this.cleanAndCloseAnnotationMenu());
  }

  private watchOnDeleteMode(): void {
    this.drawOnMap.emitOnDeleteMode.subscribe(isOnDeleteMode => {
      this.isOnDeleteMode = isOnDeleteMode;
      if (this.isOnDeleteMode) {
        this.cleanAndCloseAnnotationMenu();
      }
    });
  }
}

import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Team, TeamInvitation, TeamRole} from '@app/profile/models/team.model';
import {UserTable} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-add-user-to-team',
  templateUrl: './add-user-to-team.component.html',
  styleUrls: ['./add-user-to-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUserToTeamComponent {
  @Input() public roles: TeamRole[] = [TeamRole.admin, TeamRole.contributor];
  @Input() public teams: Team[] = [];
  @Input() public user: UserTable = {email: '', userId: '0', lastActive: 0, name: '', role: TeamRole.viewer};
  @Output() public invite: EventEmitter<Partial<TeamInvitation[]>> = new EventEmitter();
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();

  public isDialogMode: boolean = false;
  public form = this.fb.group({
    email: ['', Validators.required],
    teamId: ['', Validators.required],
    role: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {isDialogMode: boolean; teams: Team[]},
    private dialog: MatDialogRef<AddUserToTeamComponent>
  ) {
    if (this.data?.isDialogMode) {
      this.isDialogMode = this.data.isDialogMode;
      this.teams = this.data.teams;
    }
  }

  public emitInvite(): void {
    if (this.form.invalid) {
      this.form.controls.teamId.markAsTouched();
      this.form.controls.role.markAsTouched();
      return;
    }
    this.invite.emit(this.form.value as any);
  }

  public emitCancel(): void {
    this.cancel.emit();
  }

  public emitBack(): void {
    this.back.emit();
  }

  public emitCloseDialog(): void {
    this.dialog.close({hasToAddUsers: false});
  }

  public emitInviteDialog(): void {
    this.dialog.close({hasToAddUsers: true, users: this.form.value});
  }
}

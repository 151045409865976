import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatSliderModule} from '@angular/material/slider';
import {MatTabsModule} from '@angular/material/tabs';
import {RouterModule} from '@angular/router';
import {SharedPipesModule} from '@app/shared/pipes/shared-pipes.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {FlightMapComponent} from './components/flight-map/flight-map.component';
import {FlightTimeControlComponent} from './components/flight-time-control/flight-time-control.component';
import {FlightLogDetailDialogComponent} from './flight-log-detail-dialog.component';
import {FlightLogDetailDialogEffects} from './state/flight-log-detail-dialog.effects';
import * as fromFlightLogDetailDialog from './state/flight-log-detail-dialog.state';

@NgModule({
  declarations: [FlightLogDetailDialogComponent, FlightMapComponent, FlightTimeControlComponent],
  imports: [
    CommonModule,
    LeafletModule,
    MatTabsModule,
    MatButtonModule,
    FlexModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    SharedPipesModule,
    MatChipsModule,
    MatSliderModule,
    MatDividerModule,
    RouterModule,
    EffectsModule.forFeature([FlightLogDetailDialogEffects]),
    StoreModule.forFeature(fromFlightLogDetailDialog.flightLogDetailKey, fromFlightLogDetailDialog.reducers)
  ],
  exports: [FlightLogDetailDialogComponent, FlightMapComponent, FlightTimeControlComponent]
})
export class FlightLogDetailDialogModule {}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SidebarMenuItemLinkComponent} from '@app/core/components/navigation/sidebar/sidebar-menu-item-link/sidebar-menu-item-link.component';
import {SidebarStateService} from '@app/core/services/sidebar-state.service';
import {ThumblerImageConfig, THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';

@Component({
  selector: 'unleash-sidebar-menu-item-account',
  templateUrl: './sidebar-menu-item-account.component.html',
  styleUrls: ['./sidebar-menu-item-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarMenuItemAccountComponent extends SidebarMenuItemLinkComponent {
  @Input() public userName: string;
  @Input() public companyName: string;
  @Input() public avatar: string;
  @Input() public isExpanded: boolean = true;
  @Output() public logOut: EventEmitter<void> = new EventEmitter();

  public thumblerConfig: ThumblerImageConfig = THUMBLER_AVAILABLE_CONFIGS.sideBarCompanyThumbLogo;

  constructor(sideNavbarStateService: SidebarStateService) {
    super(sideNavbarStateService);
  }

  public emitLogOut() {
    this.logOut.emit();
  }
}

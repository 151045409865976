<div class="global-action-bar">
  <section class="global-action-bar__options global-action-bar__options--mobile-menu-hidden">
    <button
      *uaHasPermission="aclPermissions.MainApiCreateDevice"
      (click)="emitAddDevice()"
      mat-button
      class="global-action-bar__button"
    >
      <mat-icon class="global-action-bar__icon">add</mat-icon>
      {{ 'profile.devices.addDevice' | translate }}
    </button>
    <button
      *uaHasPermission="aclPermissions.LivestreamApiStreamPublish"
      routerLink="/secure/live/stream/"
      mat-button
      class="global-action-bar__button"
    >
      <mat-icon class="global-action-bar__icon">video_camera_front</mat-icon>
      {{ 'core.header.startStream' | translate }}
    </button>
  </section>
</div>

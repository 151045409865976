import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ufilter'
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], filterFn: (values: any) => boolean = () => true): unknown {
    return !!value ? value.filter(filterFn) : value;
  }
}

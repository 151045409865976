import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AngularResizeEventModule} from 'angular-resize-event';
import {ImageAnnotationSharedComponent} from './image-annotation-shared.component';
import {MousemoveDirective} from '@app/shared/mouse-move-directive/mouse-move-directive';

@NgModule({
  declarations: [ImageAnnotationSharedComponent],
  imports: [CommonModule, AngularResizeEventModule, MousemoveDirective],
  exports: [ImageAnnotationSharedComponent, AngularResizeEventModule]
})
export class ImageAnnotationSharedModule {}

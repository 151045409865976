import {EventEmitter} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AddonListParams} from '@app/developer-dashboard/addon/shared/models/addon-list-params';
import {Addon} from '@app/store/addon/models/addon';

import {ApiAddonListParams} from '@app/store/addon/models/api-list-params';
import {createAction, props} from '@ngrx/store';

export enum AddonActionsTypes {
  LoadAddons = '[Addon] Load Addons from database',
  LoadListSuccess = '[Addon] Load List Success',

  LoadAddon = '[Addon] Load Addon',
  LoadAddonSuccess = '[Addon] Load Addon Success',

  LoadMyInDevelopmentAddons = '[Addon] Load My In Development Addon',
  LoadMyInDevelopmentAddonsSuccess = '[Addon] Load My In Development Addon Success',

  LoadPublishedAddons = '[Addon] Load Published Addons',
  LoadPublishedAddonsSuccess = '[Addon] Load Published Addons Success',

  CreateAiApp = '[Addon] Create Ai App',
  CreateAiAppSuccess = '[Addon] Create Ai App Success',

  RenameAiApp = '[Addon] Rename Ai App',
  RenameAiAppSuccess = '[Addon] Rename Ai App Success',

  DeleteAiApp = '[Addon] Delete Ai App',
  DeleteAiAppSuccess = '[Addon] Delete Ai App Success',

  StartLoading = '[Addon] Start Loading',
  StopLoading = '[Addon] Stop Loading',

  UnPublishAiApp = '[Addon] UnPublish Ai App',
  UnPublishAiAppSuccess = '[Addon] UnPublish Ai App Success',

  SubmitForReview = '[Addon] Submit For Review',
  SubmitForReviewSuccess = '[Addon] Submit For Review Success',

  CancelReview = '[Addon] Cancel Review',
  CancelReviewSuccess = '[Addon] Cancel Review Success',

  UpdateAddon = '[Addon] Update Addon',
  UpdateAddonSuccess = '[Addon] Update Addon Success',
  UpdateAddonCache = '[Addon] Update Addon Cache',

  StartAiProcess = '[Addon] Start Ai process',
  UploadLogo = '[Addon] UploadLogo',

  ClearAddonStore = '[Addon] Clear Addon Store',

  SaveAppStoreFilter = '[Addon] Save App Store Filter',
  ClearStoreFilters = '[Addon] Clear App Store Filters',
  WatchAppStoreQueryParams = '[Addon] Watch App Store Query Params'
}

export const loadAddons = createAction(AddonActionsTypes.LoadAddons, props<{payload: {params: ApiAddonListParams}}>());
export const loadListSuccess = createAction(AddonActionsTypes.LoadListSuccess, props<{payload: {addons: Addon[]}}>());

export const loadAddon = createAction(AddonActionsTypes.LoadAddon, props<{payload: {addonId: Addon['id']}}>());
export const loadAddonSuccess = createAction(AddonActionsTypes.LoadAddonSuccess, props<{payload: {addon: Addon}}>());

export const loadMyInDevelopmentAddons = createAction(AddonActionsTypes.LoadMyInDevelopmentAddons);
export const loadMyInDevelopmentAddonsSuccess = createAction(
  AddonActionsTypes.LoadMyInDevelopmentAddonsSuccess,
  props<{payload: {addons: Addon[]}}>()
);

export const loadPublishedAddons = createAction(AddonActionsTypes.LoadPublishedAddons);
export const loadPublishedAddonsSuccess = createAction(
  AddonActionsTypes.LoadPublishedAddonsSuccess,
  props<{payload: {addons: Addon[]}}>()
);

export const createAiApp = createAction(AddonActionsTypes.CreateAiApp);
export const createAiAppSuccess = createAction(
  AddonActionsTypes.CreateAiAppSuccess,
  props<{payload: {addon: Addon}}>()
);

export const renameAiApp = createAction(AddonActionsTypes.RenameAiApp, props<{payload: Pick<Addon, 'id' | 'name'>}>());
export const renameAiAppSuccess = createAction(
  AddonActionsTypes.RenameAiAppSuccess,
  props<{payload: {addon: Addon}}>()
);

export const deleteAiApp = createAction(
  AddonActionsTypes.DeleteAiApp,
  props<{payload: {addon: Pick<Addon, 'id' | 'name' | 'usersCount'>}}>()
);

export const deleteAiAppSuccess = createAction(
  AddonActionsTypes.DeleteAiAppSuccess,
  props<{payload: {addon: {id: Pick<Addon, 'id'>}}}>()
);

export const startLoading = createAction(AddonActionsTypes.StartLoading);
export const stopLoading = createAction(AddonActionsTypes.StopLoading);

export const unPublishAiApp = createAction(
  AddonActionsTypes.UnPublishAiApp,
  props<{payload: {addon: Pick<Addon, 'id' | 'name' | 'usersCount'>}}>()
);
export const unPublishAiAppSuccess = createAction(
  AddonActionsTypes.UnPublishAiAppSuccess,
  props<{payload: Pick<Addon, 'id'>}>()
);

export const submitForReview = createAction(AddonActionsTypes.SubmitForReview, props<{payload: {addon: Addon}}>());
export const submitForReviewSuccess = createAction(
  AddonActionsTypes.SubmitForReviewSuccess,
  props<{payload: {addonId: Addon['id']}}>()
);

export const cancelReview = createAction(AddonActionsTypes.CancelReview, props<{payload: {addonId: Addon['id']}}>());
export const cancelReviewSuccess = createAction(
  AddonActionsTypes.CancelReviewSuccess,
  props<{payload: {addonId: Addon['id']}}>()
);

export const updateAddon = createAction(AddonActionsTypes.UpdateAddon, props<{payload: {addon: Addon}}>());
export const updateAddonSuccess = createAction(
  AddonActionsTypes.UpdateAddonSuccess,
  props<{payload: {addon: Addon}}>()
);

export const updateAddonCache = createAction(
  AddonActionsTypes.UpdateAddonCache,
  props<{payload: {addon: Pick<Addon, 'id' | 'enabled'>}}>()
);

export const startAiProcess = createAction(
  AddonActionsTypes.StartAiProcess,
  props<{
    payload: {
      addon: Addon;
      userDevice: UserDeviceJoined;
    };
  }>()
);

export const uploadLogo = createAction(
  AddonActionsTypes.UploadLogo,
  props<{payload: {addonId: string; file: File; urlEmit: EventEmitter<string>}}>()
);

export const clearAddonStore = createAction(AddonActionsTypes.ClearAddonStore);

export const saveAppStoreFilter = createAction(
  AddonActionsTypes.SaveAppStoreFilter,
  props<{payload: {storeFilter: AddonListParams}}>()
);
export const clearStoreFilters = createAction(AddonActionsTypes.ClearStoreFilters);
export const watchAppStoreQueryParams = createAction(AddonActionsTypes.WatchAppStoreQueryParams);

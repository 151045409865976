import {Component, OnInit} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '@app/core/services/api/user.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  public events = EVENTS;
  // eslint-disable-next-line rxjs/finnish
  public allDevices$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject<UserDeviceJoined[]>([]);
  // eslint-disable-next-line rxjs/finnish
  public favouriteDevices$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject<UserDeviceJoined[]>([]);
  // eslint-disable-next-line rxjs/finnish
  public onlineDevices$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject<UserDeviceJoined[]>([]);
  // eslint-disable-next-line rxjs/finnish
  public offlineDevices$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject<UserDeviceJoined[]>([]);
  public sortType: SortType = 'updatedAt';

  private deviceSubscription: Subscription;

  constructor(private unleashAnalytics: UnleashAnalyticsService, private userService: UserService) {}

  public ngOnInit() {
    this.deviceSubscription = this.userService.userDevices$
      .pipe(map(devices => devices.sort(this.sortDevices.bind(this))))
      .subscribe(devices => {
        this.updateDataTable(devices);
      });
  }

  public logTabSwitch(event: any) {
    this.unleashAnalytics.logEvent(EVENTS.DEVICES_CHANGED_TAB, {tab: event.tab.textLabel});
  }

  public sortData(key: SortType, hasToChangeSort?: boolean): void {
    if (hasToChangeSort) {
      this.sortType = key === 'name' ? 'updatedAt' : 'name';
    }
    this.updateDataTable(this.allDevices$.value.sort(this.sortDevices.bind(this)));
  }

  private sortDevices(devOne: UserDeviceJoined, devTwo: UserDeviceJoined) {
    if (devTwo.isLive !== devOne.isLive) {
      return Number(!!devTwo.isLive) - Number(!!devOne.isLive);
    }
    if (devTwo.isDisplayed !== devOne.isDisplayed) {
      return Number(!!devTwo.isDisplayed) - Number(!!devOne.isDisplayed);
    }
    return this.sortByType(devOne, devTwo);
  }

  private sortByType(devOne: UserDeviceJoined, devTwo: UserDeviceJoined) {
    if (this.sortType === 'name') {
      return this.sortByString(devOne, devTwo);
    }

    if (this.sortType === 'updatedAt') {
      return this.sortByNumber(devOne, devTwo);
    }
  }

  private sortByString(devOne: UserDeviceJoined, devTwo: UserDeviceJoined) {
    return devOne.name.localeCompare(devTwo.name, 'en', {
      numeric: true,
      sensitivity: 'base'
    });
  }

  private sortByNumber(devOne: UserDeviceJoined, devTwo: UserDeviceJoined) {
    if (devOne[this.sortType] > devTwo[this.sortType]) {
      return -1;
    }
    if (devOne[this.sortType] < devTwo[this.sortType]) {
      return 1;
    }
    return 0;
  }

  private updateDataTable(devices: UserDeviceJoined[]) {
    this.allDevices$.next([...devices]);
    this.onlineDevices$.next(devices.filter(device => device.isLive));
    this.offlineDevices$.next(devices.filter(device => !device.isLive));
    this.favouriteDevices$.next(devices.filter(device => device.isFavourite));
  }
}

type SortType = 'name' | 'updatedAt';

import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Tag} from '../models/tag.model';
import {LibraryStoreFacadeService} from '../services/library-store-facade.service';

@Pipe({
  name: 'appFilterTagName'
})
export class FilterTagNamePipe implements PipeTransform {
  constructor(private libraryStoreFacadeService: LibraryStoreFacadeService) {}

  public transform(tagId: string): Observable<string> {
    return this.libraryStoreFacadeService.selectTagWithId(tagId).pipe(
      filter((tag: Tag): boolean => !!tag),
      map((tag: Tag): string => tag.name)
    );
  }
}

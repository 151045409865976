<button *ngIf="isJobsEnabled$ | async" (click)="goToJob()" mat-menu-item>
  <mat-icon class="menu-icon" svgIcon="jobs"></mat-icon>
  {{ 'jobs.goToJob' | translate }}
</button>
<button
  *ngIf="baseAsset && (headerStatus$ | async) === atlasHeaderStatus.DEFAULT && (isJobsEnabled$ | async)"
  [ulEditJobSelection]="jobName"
  [baseAsset]="baseAsset"
  [polygonAsset]="polygonAsset"
  [map]="map"
  (click)="editJobSelection()"
  mat-menu-item
>
  <mat-icon class="menu-icon">edit</mat-icon>
  {{ 'jobs.editJobSelection' | translate }}
</button>
<ng-container *ngIf="(headerStatus$ | async) !== atlasHeaderStatus.EDIT_JOB_SHAPE">
  <mat-divider></mat-divider>
  <button
    [disabled]="isLoadingJob"
    [class.disabled]="isLoadingJob"
    class="menu-warn"
    (click)="deleteJob()"
    mat-menu-item
  >
    <div>
      <mat-icon class="menu-icon--warn">delete</mat-icon>
      {{ 'jobs.delete' | translate }}
    </div>
    <unleash-spinner *ngIf="isLoadingJob" diameter="20" color="#999"></unleash-spinner>
  </button>
</ng-container>

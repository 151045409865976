import {Injectable} from '@angular/core';
import {BannerConfig} from '@app/core/components/banner/banner-config';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BannerService {
  public setConfig: BehaviorSubject<BannerConfig> = new BehaviorSubject<BannerConfig>(null);
  public actionSelected: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private pending: BannerConfig[] = [];

  public open(config: BannerConfig): void {
    if (this.pending.length === 0) {
      this.doOpen(config);
    }
    this.pending.push(config);
  }

  private doOpen(config: BannerConfig): void {
    if (config.actions.length === 0) {
      throw new Error('Tried to open banner without any action buttons.');
    }

    this.setConfig.next(config);
    this.actionSelected.subscribe(() => {
      this.setConfig.next(null);
      const args = this.pending.shift();
      if (args) {
        setTimeout(() => this.doOpen(args), 500);
      }
    });
  }
}

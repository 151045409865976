export class RegistrationUser {
  name?: string;
  email: string;
  password?: string;
  username?: string;
  fullName?: string;
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  policyAgreed?: boolean;
  activationCode?: string;
}

export interface ResellerActivationLink {
  reseller: string;
  activationLink: string;
  planId: string;
  activationStatus: ActivationCodeStatus;
  expiryDate: number;
}

export enum ActivationCodeStatus {
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  USED = 'USED',
  INVALID = 'INVALID'
}

export enum SignInCodeStatus {
  USER_EXISTS = 'UL1000',
  NOT_AUTHORIZED = 'NotAuthorizedException'
}

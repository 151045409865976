<ng-container
  *ngIf="(currentCalibrationSidebarMode$ | async) === calibrationSidebarMode.EDIT_CANVAS_POINT; else editMapTemplate"
>
  <pinch-zoom
    #pinchZoom
    (zoomInEnded)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
    (zoomOutEnded)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
    (wheel)="changeZoom(); imageAnnotationShared.imageResizeOnZoom(pinchZoom.pinchZoom.scale)"
    [properties]="{
      maxScaleConfig: 100,
      disableZoomControl: 'disable',
      zoomModel: 'white',
      minPanScale: 0.5,
      minScale: 0.3
    }"
    [zoomControlScale]="0.2"
    [canvasDiagonalWidth]="canvasDiagonalWidth$ | async"
    [naturalImageDiagonalWidth]="naturalImageDiagonalWidth$ | async"
    [backgroundColor]="null"
    class="zoom"
    *ngIf="imageSnapshot$ | async as image"
  >
    <unleash-image-annotation-shared
      #imageAnnotationShared
      [canvasId]="'calibration'"
      (canvasLoaded)="loadDraggebaleMarker()"
      [srcLowRes]="image.data | uThumbler: thumblerConfig"
      [src]="image.data | uThumbler: thumblerConfig"
    ></unleash-image-annotation-shared>
  </pinch-zoom>
</ng-container>
<ng-template #editMapTemplate>
  <unleash-calibration-map
    [markerColor]="selectedColor$ | async"
    [redrawPoints]="true"
    [hasToDisplayMarkerCount]="true"
    [currentIndex]="editPointIndex$ | async"
    [deviceLocation]="deviceLocation$ | async"
    [imageCoordinates]="points$ | async"
    [isCalibrationEditMode]="true"
    (markerCoordinates)="setMarkerCoordinates($event)"
  >
    ></unleash-calibration-map
  >
</ng-template>

<div class="upload-logo {{ disabled ? 'disabled' : '' }}" fxLayoutAlign="start start" fxLayout="column">
  <label [for]="inputId">
    <div class="img-container">
      <img [src]="url$ | async" class="img-responsive" />
      <ng-container *ngIf="url; else addNewLogoTemplate">
        <i class="material-icons">replay</i>
      </ng-container>
      <ng-template #addNewLogoTemplate>
        <i class="material-icons">add</i>
      </ng-template>
    </div>
  </label>
  <label *ngIf="isShowingButton" [for]="inputId" (click)="$event.currentTarget.click()">
    <button class="replace-image-button" mat-button color="primary" data-test-id="replace-image-button">
      {{ 'profile.replaceImage' | translate | uppercase }}
    </button>
  </label>
  <input
    [id]="inputId"
    type="file"
    (change)="uploadLogo($event)"
    [accept]="ACCEPT_ASSET_FILES"
    data-test-id="upload-logo-input"
  />
  <mat-progress-bar *ngIf="isUploading" mode="buffer"></mat-progress-bar>
</div>

<h1 mat-dialog-title>{{ 'atlas.grouplayers.create' | translate }}</h1>
<div mat-dialog-content>
  <app-move-new-group (nameChanged)="groupName = $event"></app-move-new-group>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button color="primary" [mat-dialog-close]="false">{{ 'common.cancel' | translate }}</button>
  <button mat-button color="primary" [disabled]="!groupName" [mat-dialog-close]="groupName">
    {{ 'atlas.grouplayers.create' | translate }}
  </button>
</div>

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {Subscription, timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DisplayedIoTSensorsData, DroneState, IoTSensorsData} from '../models/remote-cockpit.model';
import {setCurrentDeviceState} from '@app/flights/store/remote-cockpit/remote-cockpit.actions';
import {SensorsDataIotService} from '@app/flights/services/sensors-data-iot.service';

@Injectable({
  providedIn: 'root'
})
export class SensorDataConnectionService {
  private sensorDataIoTServiceSub: Subscription;
  private CONNECT_TIMEOUT_MS = 7000;
  private inactivityCheckSub: Subscription;
  private isOffline = false;
  private lastDataTime = 0;

  constructor(private sensorDataIoTService: SensorsDataIotService, private store: Store) {}

  public connect(deviceId: string) {
    this.sensorDataIoTServiceSub = this.sensorDataIoTService
      .subscribe(deviceId)
      .pipe(
        tap(data => {
          this.lastDataTime = Date.now();
          this.isOffline = false;
        }),
        tap((data: IoTSensorsData) => {
          const flightFrame = this.mapIoTDataToFlightFrame(data);
          const currentDeviceState: DroneState = this.mapIoTDataToDeviceState(flightFrame, data);
          console.info('Update device state', currentDeviceState);
          this.store.dispatch(setCurrentDeviceState({currentDeviceState}));
        })
      )
      .subscribe();

    this.inactivityCheckSub = timer(0, this.CONNECT_TIMEOUT_MS).subscribe(() => {
      if (Date.now() - this.lastDataTime >= this.CONNECT_TIMEOUT_MS && !this.isOffline) {
        this.sensorDataIoTService.setDroneDisconnected();
        this.isOffline = true;
      }
    });
  }

  private mapIoTDataToDeviceState(flightFrame: DisplayedIoTSensorsData, data: IoTSensorsData) {
    return {
      flightFrame,
      deviceConnected: true,
      gpsConnected: !!data.lng && !!data.lat,
      cameraMode: data.cameraMode,
      isFlightInProgress: data.isFlying,
      isHoldOn: data.isHoldOn,
      isMissionInProgress: data.isMissionInProgress,
      isRecording: data.isRecording,
      signalStrength: data.signalStrength,
      remoteAccess: data.remoteAccess,
      timeLeft: data.timeLeft,
      flycState: flightFrame.flycState,
      gpsNum: flightFrame.gpsNum,
      relativeCapacity: flightFrame.relativeCapacity,
      missionState: data.missionState,
      lenses: flightFrame.lenses,
      currentLens: flightFrame.currentLens,
      isMultiLens: flightFrame.isMultiLens,
      droneStatus: data.droneStatus,
      wifiLevel: data.wifiLevel,
      rcSignal: data.rcSignal,
      flightModeStatus: data.flightModeStatus,
      droneOn: data.droneOn,
      velX: data.velX,
      velY: data.velY,
      velZ: data.velZ,
      distHome: data.distHome,
      alt: data.alt,
      yaw: data.yaw,
      pitch: data.pitch,
      gimPitch: data.gimPitch,
      gimYaw: data.gimYaw,
      altEGM96: data.altEGM96,
      timeElapsed: data.timeElapsed,
      missionTimeRemaining: data.missionTimeRemaining,
      preciseLand: data.preciseLand
    };
  }

  public disconnect(device: UserDeviceJoined) {
    if (this.sensorDataIoTServiceSub) {
      this.sensorDataIoTService.unsubscribe(device.id);
      this.sensorDataIoTServiceSub.unsubscribe();
    }
    if (this.inactivityCheckSub) {
      this.inactivityCheckSub.unsubscribe();
    }
  }

  public mapIoTDataToFlightFrame(data: IoTSensorsData): DisplayedIoTSensorsData {
    return {
      longitude: data.lng,
      latitude: data.lat,
      height: data.height,
      XspeedX: data.velX,
      Yspeed: data.velY,
      Zspeed: data.velZ,
      pitch: data.pitch,
      roll: data.roll,
      yaw: data.yaw,
      gimYaw: data.gimYaw,
      gpsNum: data.gpsLevel,
      relativeCapacity: data.batt,
      signalStrength: data.signalStrength, // number 0 - 4
      streamNum: data.streamNum,
      remoteAccess: data.remoteAccess,
      flycState: data.flightMode || 'N/A',
      cameraMode: data.cameraMode,
      lenses: data.lenses,
      currentLens: data.currentLens,
      isMultiLens: data.isMultiLens
    };
  }
}

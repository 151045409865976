import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {urlValidator} from '../shared/urlValidator';

@Directive({
  selector: '[appRtmpUrlValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: RtmpUrlValidatorDirective, multi: true}],
})
export class RtmpUrlValidatorDirective implements Validator {
  @Input('appRtmpUrlValidator') validator: string;

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.validator ? urlValidator(new RegExp(this.validator, 'i'))(control)
      : null;
  }
}

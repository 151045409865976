<h1 mat-dialog-title>{{ 'live.device.lowBatteryTitle' | translate: {value: data.deviceName} }}</h1>
<section mat-dialog-content>
  {{ 'live.device.lowBatteryDescription' | translate }}
</section>
<section mat-dialog-actions>
  <button mat-dialog-close mat-button color="primary">{{ 'common.cancel' | translate }}</button>
  <button (click)="startStreaming()" mat-flat-button color="primary">
    {{ 'live.live-stream-page.title' | translate }}
  </button>
</section>

import {Injectable} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';

import {Addon} from '@app/store/addon/models/addon';
import {ManagerZonesStoreFacadeService} from '@app/shared/manage-zones-dialog/services/manager-zones-store-facade.service';
import {Zone, ZoneConfigData} from '@app/shared/annotation-shared/models/annotations.model';
import {Observable} from 'rxjs';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {TriggerPoint} from '@app/shared/trigger-point/trigger-point.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerZonesDialogService {
  public zonesConfigBackup$ = this.managerZonesStoreFacadeService.zonesConfigBackup$;
  public zonesConfigData$ = this.managerZonesStoreFacadeService.zonesConfigData$;
  public isCanvasReady$ = this.managerZonesStoreFacadeService.isCanvasReady$;
  public imageSnapshot$ = this.managerZonesStoreFacadeService.imageSnapshot$;
  public zones$ = this.managerZonesStoreFacadeService.zones$;
  public isZonesLoading$: Observable<boolean> = this.managerZonesStoreFacadeService.isZonesLoading$;
  public polygonZones$ = this.managerZonesStoreFacadeService.polygonZones$;
  public isSelectedPerspectiveZone$: Observable<boolean> =
    this.managerZonesStoreFacadeService.isSelectedPerspectiveZone$;
  public selectedZoneColor$: Observable<LabelColorName> = this.managerZonesStoreFacadeService.selectedZoneColor$;
  public selectedZone$: Observable<Zone> = this.managerZonesStoreFacadeService.selectedZone$;
  public hasPerspectiveZone$: Observable<boolean> = this.managerZonesStoreFacadeService.hasPerspectiveZone$;
  public hint$: Observable<string> = this.managerZonesStoreFacadeService.hint$;

  constructor(
    private managerZonesStoreFacadeService: ManagerZonesStoreFacadeService,
    private addonStoreFacadeService: AddonStoreFacadeService
  ) {}

  public startAi(device: UserDeviceJoined, addon: Addon) {
    this.addonStoreFacadeService.startAIProcess(device, addon);
  }

  public watchDrawShapesOnCanvas(): void {
    this.managerZonesStoreFacadeService.watchDrawShapesOnCanvas();
  }

  public setZonesConfigData(zonesConfigData: ZoneConfigData): void {
    this.managerZonesStoreFacadeService.setZonesConfigData(zonesConfigData);
  }

  public updateCurrentZonesConfigBackup(addonZones: any) {
    this.managerZonesStoreFacadeService.updateCurrentZonesConfigBackup(addonZones);
  }

  public canvasIsReady(): void {
    this.managerZonesStoreFacadeService.canvasIsReady();
  }

  public canvasIsNotReady(): void {
    this.managerZonesStoreFacadeService.canvasIsNotReady();
  }

  public clearImageSnapshot(): void {
    this.managerZonesStoreFacadeService.clearImageSnapshot();
  }

  public clearZones(): void {
    this.managerZonesStoreFacadeService.clearZones();
  }

  public removeZone(zone: Zone): void {
    this.managerZonesStoreFacadeService.removeZone(zone);
  }

  public addZone(zone: Zone): void {
    this.managerZonesStoreFacadeService.addZone(zone);
  }

  public updateZone(property: Partial<Zone>, zoneId: string): void {
    this.managerZonesStoreFacadeService.updateZone(property, zoneId);
  }

  public initZones(): void {
    this.managerZonesStoreFacadeService.initZones();
  }

  public updateZoneName(displayName: string, zoneId: string): void {
    this.managerZonesStoreFacadeService.updateZoneName(displayName, zoneId);
  }

  public setSelectedZoneId(zoneId: string): void {
    this.managerZonesStoreFacadeService.setSelectedZoneId(zoneId);
  }

  public storePhysicalDimensions(perspectiveValues: {a: number; b: number}) {
    this.managerZonesStoreFacadeService.storePhysicalDimensions(perspectiveValues);
  }

  public storeDisplayName(displayName: string) {
    this.managerZonesStoreFacadeService.storeDisplayName(displayName);
  }

  public setZones(zones: Zone[]): void {
    this.managerZonesStoreFacadeService.setZones(zones);
  }

  public updateCurrentColor(color: {
    name: LabelColorName;
    background: LabelColorName;
    stroke: string;
    fill: string;
  }): void {
    this.managerZonesStoreFacadeService.updateCurrentColor(color);
  }

  public updateCurrentColorByZone(
    color: {name: LabelColorName; background: LabelColorName; stroke: string; fill: string},
    zone: Zone
  ): void {
    this.managerZonesStoreFacadeService.updateCurrentColorByZone(color, zone);
  }

  public prepareHint(shapeType: ShapeTypes, action?: string) {
    this.managerZonesStoreFacadeService.prepareHint(shapeType, action);
  }

  public hideHint() {
    this.managerZonesStoreFacadeService.hideHint();
  }

  public setTriggerPoint(blockName: TriggerPoint, zoneId: string) {
    this.managerZonesStoreFacadeService.setTriggerPoint(blockName, zoneId);
  }

  public setIsZonesLoading(isZonesLoading: boolean) {
    this.managerZonesStoreFacadeService.setIsZonesLoading(isZonesLoading);
  }

  public toggleIn(): void {
    this.managerZonesStoreFacadeService.toggleIn();
  }

  public toggleOut(): void {
    this.managerZonesStoreFacadeService.toggleOut();
  }

  public atLeastOneInOutPositionEnabled(): void {
    this.managerZonesStoreFacadeService.atLeastOneInOutPositionEnabled();
  }
}

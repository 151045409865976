import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'unleash-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseDialogComponent {
  constructor(private translateService: TranslateService) {}
}

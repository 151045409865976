<div class="container mat-elevation-z2">
  <form *ngIf="form" [formGroup]="form">
    <div class="title">{{ 'plans.billing.billingPeriod' | translate }}</div>
    <mat-radio-group formControlName="paymentPeriod">
      <mat-radio-button color="primary" [value]="paymentPeriod.month">{{
        'plans.billing.monthToMonth' | translate
      }}</mat-radio-button>
      <mat-radio-button color="primary" [value]="paymentPeriod.annual"
        >{{ 'plans.billing.annualPricing' | translate }}
        <span class="badge"
          >{{ 'plans.billing.save' | translate | uppercase }} ${{ monthly * 12 - annual * 12 | number }} ({{
            ((monthly * 12 - annual * 12) * 100) / (monthly * 12) | number: '1.2-2'
          }}% OFF)</span
        ></mat-radio-button
      >
    </mat-radio-group>
  </form>

  <div class="payment-info" *ngIf="form && form.value.paymentMethod === 'new'">
    <div class="img-container">
      <img class="img" src="/assets/icons/auth/payment-cards.svg" alt="payment cards" />
    </div>
    <unleash-payment-card
      #paymentCard
      [isOnlyPayments]="true"
      (stripeToken)="emitStripeToken($event)"
    ></unleash-payment-card>
  </div>

  <div class="payment-user-info">
    <div class="image-container">
      <img src="/assets/icons/payment/powered-by-stripe.svg" alt="powered by stripe" />
    </div>
    <div class="text">{{ 'plans.billing.stripeInfo' | translate }}</div>
  </div>

  <div class="promo-code-content">
    <unleash-promo-code [isSuccess]="isSuccessPromoCode$ | async" (promoCode)="setupPromoCode($event)">
    </unleash-promo-code>
  </div>
</div>

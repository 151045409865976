<div class="container">
  <div class="logo">
    <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
  </div>
  <div class="info">{{ 'auth.setUpMFA.infoMessage' | translate }}</div>
  <div class="title">
    <div class="title__text">{{ 'auth.setUpMFA.title' | translate }}</div>
  </div>
  <form [formGroup]="setupMfaForm">
    <h2 class="form-title">{{ 'auth.setUpMFA.formTitle' | translate }}</h2>
    <mat-radio-group class="radio" formControlName="mfa">
      <mat-radio-button [value]="mfa.TEXT_MESSAGE">{{ 'auth.setUpMFA.textMessage' | translate }}</mat-radio-button>
      <mat-radio-button [value]="mfa.TOTP">{{ 'auth.setUpMFA.totp' | translate }}</mat-radio-button>
    </mat-radio-group>
    <div class="error" *ngIf="hasSetupTotpError | async">{{ 'auth.setUpMFA.generateError' | translate }}</div>
    <div class="actions">
      <button type="button" (click)="signOut()" mat-button color="primary">
        {{ 'auth.setUpMFA.signOut' | translate }}
      </button>
      <button
        type="submit"
        [appLadda]="isInProgress | async"
        [disabled]="isInProgress | async"
        (click)="continue()"
        mat-flat-button
        color="primary"
      >
        {{ 'auth.setUpMFA.continue' | translate }}
      </button>
    </div>
  </form>
</div>

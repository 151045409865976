<div class="header">
  <div class="hyperlink hyperlink--upper">
    <span>{{'auth.newPassword.already' | translate}} </span
    ><span class="link" (click)="signIn()">{{'common.signIn' | translate}}</span>
  </div>
</div>

<div class="spacer"></div>
<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>

<div class="title">
  <div class="title__text">{{'auth.newPassword.setNewPassword' | translate}}</div>
</div>

<unleash-new-password
  class="form form--fixed"
  [newPasswordForm]="newPasswordForm$ | async"
  [isInProgress]="isInProgress$ | async"
  (submit)="continue($event)"
></unleash-new-password>
<div class="spacer"></div>

import {Pipe, PipeTransform} from '@angular/core';
import {Breadcrumb, BREADCRUMB} from '../breadcrumb/breadcrumb.model';

@Pipe({
  name: 'ubreadcrumbs'
})
export class BreadcrumbsPipe implements PipeTransform {
  transform(value: string[]): Breadcrumb[] {
    const tokens: Breadcrumb[] = value.map(
      (id: string, index: number): Breadcrumb => ({
        id,
        class: '',
        type: BREADCRUMB.TEXT,
        index
      })
    );
    let breadcrumbs: Breadcrumb[] = [];

    if (tokens.length > 4) {
      const moreTokens: Breadcrumb[] = [];
      for (let index: number = 1; index < tokens.length - 2; index++) {
        moreTokens.push(tokens[index]);
      }

      breadcrumbs.push({...tokens[0], index: 0});
      breadcrumbs.push({
        id: '•••',
        class: 'more',
        type: BREADCRUMB.MORE,
        tokens: moreTokens
      });
      breadcrumbs.push({
        ...tokens[tokens.length - 2],
        index: tokens.length - 2
      });
      breadcrumbs.push({
        ...tokens[tokens.length - 1],
        index: tokens.length - 1
      });
    } else {
      breadcrumbs = tokens;
    }

    return breadcrumbs;
  }
}

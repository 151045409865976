<div>
  <h1 mat-dialog-title>
    {{ 'profile.teams.deleteDialog.title' | translate }}
  </h1>

  <div mat-dialog-content>
    {{ 'profile.teams.deleteDialog.deleteDescription' | translate: {teamName: data.team.name} }}
  </div>
  <div mat-dialog-actions>
    <button
      mat-stroked-button
      color="primary"
      (click)="emitCancel()"
      tabindex="1"
      data-test-id="delete-team-cancel-button"
    >
      {{ 'common.cancel' | translate }}
    </button>
    <button
      color="primary"
      mat-raised-button
      tabindex="2"
      (click)="emitDelete()"
      data-test-id="delete-team-name-button"
    >
      {{ 'common.delete' | translate }}
    </button>
  </div>
</div>

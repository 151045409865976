<div class="upload-missions">
  <section class="upload-missions__title" data-test-id="upload-missions-title">{{ 'atlas.mission.uploadMissions' | translate }}</section>
  <section class="upload-missions__content">
    <mat-icon class="upload-missions__image" svgIcon="upload-mission"></mat-icon>
    <p class="upload-missions__message">{{ 'atlas.mission.uploadMissionsMessage' | translate }}</p>
    <p class="upload-missions__formats">{{ 'atlas.mission.uploadMissionsFormats' | translate }}</p>
    <a
      *ngIf="!(totalFilesToUpload | async)?.length"
      (change)="selectedFiles($event)"
      (click)="file.click(); file.value = ''"
      class="upload-missions__select-files"
      color="primary"
      mat-button
      data-test-id="select-files-button"
    >
      <mat-icon class="upload-missions__icon">attach_file</mat-icon>
      {{ 'atlas.mission.selectFiles' | translate }}
      <input
        data-amplify-analytics-on="click"
        data-amplify-analytics-name="click_import_mission_icon"
        id="mission-import-button"
        type="file"
        #file
        multiple
        accept=".csv,.json,.kml,.kmz"
        [style.display]="'none'"
        data-test-id="file-input"
      />
    </a>
    <section class="upload-missions__drag-drop">
      <p *ngIf="!(totalFilesToUpload | async)?.length" class="upload-missions__or">
        {{ 'atlas.mission.or' | translate }}
      </p>
      <ngx-file-drop
        [class.upload-missions__hover]="isDropContainerHovered"
        [class.upload-missions__selected-state]="(totalFilesToUpload | async)?.length"
        (onFileDrop)="dropped($event)"
        (onFileOver)="fileOver()"
        (onFileLeave)="fileLeave()"
      >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <ng-container *ngIf="isDropContainerHovered; else initialDragDropTemplate">
            <div class="mat-elevation-z3 upload-missions__drop-here">
              <mat-icon>file_upload</mat-icon>
              {{ 'atlas.mission.dropMessage' | translate }}
            </div>
          </ng-container>
          <ng-template #initialDragDropTemplate>
            <ng-container *ngIf="totalFiles$ | async as totalFilesToUpload">
              <ng-container *ngIf="totalFilesToUpload.length > 0; else dragDropTemplate">
                <mat-icon class="upload-missions__icon">attach_file</mat-icon>
                <ng-container *ngIf="totalFilesToUpload.length === 1; else multipleUploadsTemplate">
                  {{ totalFilesToUpload[0].name }}, {{ totalFilesToUpload[0].size }}
                </ng-container>
                <ng-template #multipleUploadsTemplate>
                  {{ totalFilesToUpload.length }}
                  {{ 'atlas.mission.files' | translate }}
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-template #dragDropTemplate>
              <mat-icon svgIcon="drag-drop"></mat-icon>
              <div class="upload-missions__drag-drop-message">{{ 'atlas.mission.dragAndDropMessage' | translate }}</div>
            </ng-template>
          </ng-template>
        </ng-template>
      </ngx-file-drop>
    </section>

    <ng-container *ngIf="isAdmin$ | async">
      <ng-container *ngIf="isLoadingJobs$ | async; else displayJobsTemplate">
        <unleash-spinner></unleash-spinner>
      </ng-container>
      <ng-template #displayJobsTemplate>
        <form *ngIf="hasJobs$ | async" [formGroup]="addMissionToJobsForm" class="upload-missions__add-mission-to-jobs">
          <div class="upload-missions__toggle" data-test-id="add-mission-to-job-section">
            <mat-slide-toggle formControlName="hasToAddMissionToJob" (toggleChange)="fetchMissionsToDisplay()">{{
              'atlas.mission.addMissionsToAJob' | translate
            }}</mat-slide-toggle>
            <mat-icon
              class="upload-missions__add-mission-to-jobs-icon"
              color="grey-4"
              [matTooltip]="'atlas.mission.addMisssionToAJobHint' | translate"
              matTooltipClass="upload-missions__add-mission-to-jobs-tooltip"
              matTooltipPosition="after"
            >
              info
            </mat-icon>
          </div>
          <div class="upload-missions__selector" *ngIf="addMissionToJobsForm.controls.hasToAddMissionToJob.value">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'atlas.mission.jobs' | translate }}</mat-label>
              <mat-select formControlName="selectedJob">
                <mat-label class="upload-missions__selector-label">{{
                  'atlas.mission.jobsInProgress' | translate
                }}</mat-label>
                <mat-option *ngFor="let jobInProgress of jobsInProgress$ | async" [value]="jobInProgress.id">{{
                  jobInProgress.title
                }}</mat-option>
                <mat-divider></mat-divider>
                <mat-label class="upload-missions__selector-label">{{
                  'atlas.mission.jobsNotReleased' | translate
                }}</mat-label>
                <mat-option *ngFor="let draftJobs of draftJobs$ | async" [value]="draftJobs.id">{{
                  draftJobs.title
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </ng-template>
    </ng-container>
  </section>
  <section class="upload-missions__actions">
    <div class="upload-missions__left-actions upload-missions__left-actions--extends">
      <form class="upload-missions__form" [formGroup]="form" *ngIf="hasCsvFiles$ | async">
        <h3 class="upload-missions__advanced-options-title">Advanced Options</h3>
        <div class="upload-missions__option-container">
          <span class="upload-missions__option">
            <mat-checkbox formControlName="takePhotoEachWaypoint" [disabled]="isUploadingMissions$ | async">{{
              'atlas.mission.takePhotoEachWaypoint' | translate
            }}</mat-checkbox>
          </span>
          <span class="upload-missions__option">
            <mat-checkbox formControlName="setCustomSpeed" [disabled]="isUploadingMissions$ | async">{{
              'atlas.mission.setCustomSpeedAllWaypoints' | translate
            }}</mat-checkbox>
            <ng-container *ngIf="form.controls.setCustomSpeed.value">
              <mat-form-field appearance="fill" class="upload-missions__speed-input">
                <mat-label>{{ 'atlas.mission.speed' | translate }}</mat-label>
                <input matInput formControlName="customSpeed" autofocus="true" type="number" />
                <span class="upload-missions__speed-suffix" matSuffix>m/s</span>
              </mat-form-field>
            </ng-container>
          </span>
          <span class="upload-missions__option" *ngIf="hasMixedFiles$ | async">
            <unleash-alert
              label="{{ 'atlas.mission.uploadMissionAlertCsv' | translate }}"
              type="warning-dark"
              iconName="info"
            ></unleash-alert>
          </span>
        </div>
      </form>
    </div>
  </section>
  <section class="upload-missions__footer">
    <div class="upload-missions__left-actions">
      <a
        target="_blank"
        href="https://knowledge.unleashlive.com/mission-import"
        class="upload-missions__actions-format"
      >
        <mat-icon class="upload-missions__icon" svgIcon="mission-format-list"></mat-icon>
        {{ 'atlas.mission.missionsFormatMessage' | translate }}
      </a>
    </div>
    <div class="upload-missions__right-actions">
      <a color="primary" mat-button mat-dialog-close>{{ 'atlas.mission.cancel' | translate }}</a>
      <button
        [matTooltip]="!(totalFilesToUpload | async)?.length ? ('atlas.mission.uploadWarning' | translate) : ''"
        matTooltipPosition="above"
        matTooltipClass="upload-missions-warning"
        [disabled]="isUploadingMissions$ | async"
        class="upload-missions__upload"
        mat-button
        (click)="uploadFiles()"
        data-test-id="upload-missions-button"

      >
        <ng-container *ngIf="isUploadingMissions$ | async; else uploadTemplate">
          <unleash-spinner diameter="20" borderWidth="2.1" color="#111" data-test-id="upload-spinner"></unleash-spinner>
          {{ 'atlas.mission.uploading' | translate }}
        </ng-container>
        <ng-template #uploadTemplate> {{ 'atlas.mission.upload' | translate }} </ng-template>
      </button>
    </div>
  </section>
</div>

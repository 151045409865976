import {Pipe, PipeTransform} from '@angular/core';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {Addon} from '@app/store/addon/models/addon';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({name: 'uAddonNameFromId'})
export class AddonNameFromIdPipe implements PipeTransform {
  constructor(private addonStoreFacadeService: AddonStoreFacadeService) {}

  public transform(addonId: string): Observable<string> {
    return this.addonStoreFacadeService.getAddon(addonId).pipe(map((addon: Addon) => addon?.name));
  }
}

<div class="header">
  <div class="header__steps" [translate]="isDeveloperMode ? 'auth.payment.step3of3' : 'auth.payment.step4of4'"></div>
  <div class="header__links">
    <span class="header__link header__link--back" (click)="back()">← {{'common.back' | translate}}</span>
    <span class="header__link header__link--sign-out" (click)="signOut()">
      <mat-icon class="icon">vpn_key</mat-icon>
      {{'common.signOut' | translate}}
    </span>

    <button mat-icon-button [matMenuTriggerFor]="menu" class="header__link header__link--more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="back()" class="header__menu-item">
        <mat-icon>arrow_back</mat-icon>
        <span>{{'common.back' | translate}}</span>
      </button>
      <button mat-menu-item (click)="signOut()" class="header__menu-item">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'common.signOut' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
<div class="spacer"></div>

<div class="wrapper">
  <div class="logo">
    <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
  </div>
  <div class="title">
    <div
      class="title__text"
      [translate]="isDeveloperMode ? 'auth.payment.developerPlan.title' : 'auth.payment.recommendedPlan'"
    ></div>
    <div class="title__sub-text">
      {{'auth.payment.recommendedHelp' | translate}}
      <span class="title__terms">{{'auth.payment.recommendedFairUse' | translate}}</span>
    </div>
  </div>
  <unleash-plan-viewer
    class="unleash-plan-viewer"
    [plan]="selectedPlan$ | async"
    [paymentPeriod]="this.selectedPaymentPeriod"
    [isDeveloperMode]="isDeveloperMode"
  ></unleash-plan-viewer>

  <div *ngIf="isDeveloperMode">
    <p class="title__text">Unlock free dev access</p>
    <p class="title__sub-text">
      By progressing your A.I. App development with Unleash live, you can continue to gain free access to our suite of
      cloud processing tools.
    </p>
    <div class="dev-access-benefits">
      <div class="dev-access-benefit" *ngFor="let devAccessBenefit of devAccessBenefits">
        <div class="dev-access-benefit__header">
          <mat-icon class="dev-access-benefit__icon" color="primary" [svgIcon]="devAccessBenefit.icon"></mat-icon>
          <p class="dev-access-benefit__title">{{ devAccessBenefit.title }}</p>
        </div>
        <p class="dev-access-benefit__label">{{ devAccessBenefit.label }}</p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isAuthInitialized$ | async; else loadingTemplate">
    <unleash-payment-card
      class="form unleash-payment-card"
      [plan]="selectedPlan$ | async"
      [isPaymentInProgress]="paymentInProgress$ | async"
      (stripeToken)="subscribeUser($event)"
      (paymentPeriodChange)="setPaymentPeriod($event)"
    ></unleash-payment-card>
  </ng-container>
  <ng-template #loadingTemplate>
    <unleash-loader></unleash-loader>
  </ng-template>

  <footer class="terms">
    {{'auth.payment.terms.0' | translate}}
    <span class="terms__link" (click)="termsOfService()">{{'auth.payment.terms.1' | translate}}</span
    >{{'auth.payment.terms.2' | translate}}
  </footer>
</div>

<div class="spacer"></div>

<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="!isDialogMode">
      <button class="back-button" data-test-id="add-user-to-team-back-button" mat-icon-button (click)="emitBack()">
        <mat-icon aria-label="label">arrow_back</mat-icon>
      </button>
      <div class="title">Add {{ user.name }} to team</div>
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="add-team">
      <unleash-add-user-to-team-row [form]="form" [roles]="roles" [teams]="teams"> </unleash-add-user-to-team-row>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      data-test-id="cancel-button"
      mat-button
      color="primary"
      (click)="isDialogMode ? emitCloseDialog() : emitCancel()"
    >
      {{ 'common.cancel' | translate }}
    </button>
    <button
      data-test-id="invite-button"
      mat-raised-button
      color="primary"
      (click)="isDialogMode ? emitInviteDialog() : emitInvite()"
    >
      {{ 'common.invite' | translate }}
    </button>
  </mat-card-actions>
</mat-card>

<div class="container">
  <ng-container *ngIf="isNewGroup; else existingGroupTemplate">
    <section class="new-group__header">
      <mat-icon (click)="setIsNewGroup(false)" class="new-group__arrow">arrow_back</mat-icon>
      {{ 'atlas.move.newGroup' | translate }}
    </section>
    <section [formGroup]="form">
      <mat-form-field class="new-group__input" appearance="fill">
        <input matInput [placeholder]="'atlas.move.newGroupName' | translate" formControlName="name" />
      </mat-form-field>
      <section class="new-group__actions">
        <a [disabled]="hasToDisplaySpinner" mat-dialog-close mat-button color="primary">{{
          'common.cancel' | translate
        }}</a>
        <a
          [disabled]="!form.controls.name.valid || hasToDisplaySpinner"
          mat-button
          class="actions__confirm-button"
          (click)="addToGroup()"
        >
          {{ 'atlas.move.addToGroup' | translate }}
          <unleash-spinner *ngIf="hasToDisplaySpinner" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
        </a>
      </section>
    </section>
  </ng-container>
  <ng-template #existingGroupTemplate>
    <h1 class="title">{{ 'atlas.move.addToGroup' | translate }}</h1>
    <section>
      <div
        (click)="selectLayer(group, i)"
        [class.selected]="selectedGroupIndex === i"
        class="item"
        *ngFor="let group of groups$ | async; let i = index"
      >
        <div class="item__info">
          <mat-icon
            class="item__folder"
            [class]="group.customColorIndex >= 0 ? 'custom-icon-' + group.customColorIndex : ''"
            >folder</mat-icon
          >
          {{ group.name }}
        </div>
        <mat-icon class="item__check" *ngIf="selectedGroupIndex === i">check_circle</mat-icon>
      </div>
    </section>
    <section class="actions">
      <a (click)="setIsNewGroup(true)" mat-button>
        <mat-icon class="actions__icon">create_new_folder</mat-icon>
        <span> {{ 'atlas.move.newGroup' | translate }} </span>
      </a>
      <div class="actions__right">
        <a [disabled]="hasToDisplaySpinner" mat-dialog-close mat-button color="primary">{{
          'common.cancel' | translate
        }}</a>
        <a
          [disabled]="!form.controls.name.valid || hasToDisplaySpinner"
          mat-button
          class="actions__confirm-button"
          (click)="addToGroup()"
        >
          {{ 'atlas.move.addToGroup' | translate }}
          <unleash-spinner *ngIf="hasToDisplaySpinner" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
        </a>
      </div>
    </section>
  </ng-template>
</div>

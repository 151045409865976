import {Pipe, PipeTransform} from '@angular/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserService} from '@app/core/services/api/user.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Pipe({
  name: 'appDeviceName'
})
export class DeviceNamePipe implements PipeTransform {
  constructor(private userService: UserService) {}
  //eslint-disable-next-line rxjs/finnish
  public transform(deviceId: string): Observable<string> {
    return this.userService.userDevices$.pipe(
      take(1),
      map((devices: UserDeviceJoined[]): string => devices.find(device => device.id === deviceId).name || deviceId)
    );
  }
}

import {ChangeDetectionStrategy, Component, HostListener, Inject} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '@app/core/services/api/user.service';
import {Auth} from '@aws-amplify/auth';
import {TranslateService} from '@ngx-translate/core';
import {catchError, from, switchMap, take, tap} from 'rxjs';

@Component({
  selector: 'unleash-verify-mobile-number-dialog',
  templateUrl: './verify-mobile-number-dialog.component.html',
  styleUrls: ['./verify-mobile-number-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyMobileNumberDialogComponent {
  public form = this.fb.group({
    code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
  });
  public isLoading = false;

  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: {preventDefault: any; returnValue: boolean}): void {
    event.preventDefault();
    event.returnValue = false;
  }

  constructor(
    public dialogRef: MatDialogRef<VerifyMobileNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private userService: UserService
  ) {}
  public confirmCode(): void {
    this.isLoading = true;
    from(Auth.verifyCurrentUserAttributeSubmit('phone_number', this.form.value.code))
      .pipe(
        take(1),
        switchMap(() => this.translate.get('profile.phone.phoneConfirmed')),
        tap((translation: string) => {
          this.userService.updateCognitoUserFinished();
          this.dialogRef.close();
          this.snackbar.open(translation, null, {
            panelClass: 'center',
            duration: 3000
          });
        }),
        catchError(() => {
          return this.translate.get('profile.phone.phoneNotConfirmed').pipe(
            tap(translation => {
              this.dialogRef.close();
              this.snackbar.open(translation, null, {
                panelClass: 'center',
                duration: 3000
              });
            })
          );
        })
      )
      .subscribe();
  }
}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LiveAnalyzeState} from './live.state';

export const selectStreamData = createFeatureSelector<LiveAnalyzeState>('liveStore');
export const selectStreamisLoadingSlug = createSelector(
  selectStreamData,
  (state: LiveAnalyzeState) => state.isLoadingSlug
);
export const selectStreamisEnablingLivePage = createSelector(
  selectStreamData,
  (state: LiveAnalyzeState) => state.isEnablingLivePage
);
export const selectErrorMessage = createSelector(selectStreamData, (state: LiveAnalyzeState) => state.errorMessage);
export const selectErrorCode = createSelector(selectStreamData, (state: LiveAnalyzeState) => state.errorCode);

export const selectLiveDevicesId = createSelector(selectStreamData, (state: LiveAnalyzeState) => state.liveDevicesId);
export const selectIsInitialized = createSelector(selectStreamData, (state: LiveAnalyzeState) => state.isInitialized);
export const selectLayoutState = createSelector(selectStreamData, (state: LiveAnalyzeState) => state.layoutState);
export const selectIsSelectedDeviceRunningModel = createSelector(
  selectStreamData,
  (state: LiveAnalyzeState) => state.isSelectedDeviceRunningModel
);
export const selectSelectedDeviceId = createSelector(
  selectStreamData,
  (state: LiveAnalyzeState) => state.selectedDeviceId
);
export const selectGridState = createSelector(selectStreamData, (state: LiveAnalyzeState) => state.gridState);
export const selectFirstDeviceId = createSelector(selectStreamData, (state: LiveAnalyzeState) => {
  for (const [key, value] of Object.entries(state.gridState)) {
    if (value === 'one') {
      return key;
    }
  }
  return null;
});

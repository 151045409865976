import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {
  actionWelcomeAwsTokenSuccess,
  actionYourProfileError,
  actionYourProfileSubmit,
  actionYourProfileSuccess
} from './your-profile.actions';
import {UserProfile} from './your-profile.model';
import {AuthErrorModel} from '@app/auth/models/amplify-error';

export interface YourProfileState {
  form: Partial<UserProfile>;
  error?: AuthErrorModel;
  isInProgress: boolean;
}

export const initialState: YourProfileState = {
  form: {organization: '', industry: '', phone: '', publisherName: ''},
  isInProgress: false
};

const reducer: ActionReducer<YourProfileState, Action> = createReducer(
  initialState,
  on(
    actionYourProfileSubmit,
    (
      state: YourProfileState,
      {form}: {form: Partial<UserProfile>}
    ): {error: AuthErrorModel; isInProgress: boolean; form: Partial<UserProfile>} => ({
      ...state,
      error: undefined,
      isInProgress: true,
      form
    })
  ),
  on(
    actionYourProfileError,
    (
      state: YourProfileState,
      {error}: {error: AuthErrorModel}
    ): {error: AuthErrorModel; isInProgress: boolean; form: Partial<UserProfile>} => ({
      ...state,
      error,
      isInProgress: false
    })
  ),
  on(
    actionYourProfileSuccess,
    (state: YourProfileState): {error: AuthErrorModel; isInProgress: boolean; form: Partial<UserProfile>} => ({
      ...state,
      error: undefined,
      isInProgress: false
    })
  ),
  on(
    actionWelcomeAwsTokenSuccess,
    (state: YourProfileState): {error: AuthErrorModel; isInProgress: boolean; form: Partial<UserProfile>} => ({
      ...state,
      error: undefined,
      isInProgress: false
    })
  )
);

export function yourProfileStateReducer(state: YourProfileState | undefined, action: Action): YourProfileState {
  return reducer(state, action);
}

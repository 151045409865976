import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NewPassword} from './new-password.model';
import {emailRegex, passwordRegex} from '../../regex';

@Component({
  selector: 'unleash-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewPasswordComponent implements OnInit {
  @Input() newPasswordForm: NewPassword;
  @Input() isInProgress: boolean;
  @Output() submitForm: EventEmitter<Partial<NewPassword>> = new EventEmitter<Partial<NewPassword>>();

  form = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
    newPassword: ['', [Validators.required, Validators.pattern(passwordRegex)]]
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.setValue(this.newPasswordForm);
  }

  clickNewPasswordButton() {
    if (this.form.valid) {
      this.submitForm.emit(this.form.value);
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }
}

import {createSelector} from '@ngrx/store';
import * as fromRemoteCockpit from './remote-cockpit.reducer';
import * as fromFlights from '../remote-cockpit/flights.state';
import {
  CameraSourceMenuItemNameMap,
  CameraSourceNameButtonNameMap,
  CameraVideoStreamSource
} from '@app/flights/models/remote-cockpit-lens.model';

export const selectSelectedDevice = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.currentDevice
);

export const selectCurrentDeviceLogs = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.currentDeviceLogs
);

export const selectCurrentDeviceState = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.currentDeviceState
);

export const selectRemoteCockpitMissions = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.missions
);

export const selectCurrentMission = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.currentMission
);

export const selectCurrentMissionState = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.currentDeviceState.missionState
);

export const selectIotConnectionStatus = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.iotConnectionStatus
);

export const selectRemoteCockpitIsCurrentMissionMapPaused = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.isCurrentMissionPaused
);

export const selectRemoteCockpitAvailableLens = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) =>
    state.currentDeviceState.lenses
      ? state.currentDeviceState.lenses.map(len => {
          const lensEnumName = CameraVideoStreamSource[len];
          return {
            id: CameraVideoStreamSource[lensEnumName],
            name: CameraSourceMenuItemNameMap[lensEnumName],
            nickname: CameraSourceNameButtonNameMap[lensEnumName]
          };
        })
      : []
);

export const selectRemoteCockpitCurrentLens = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => {
    const lensId = state.currentDeviceState.currentLens;
    const lensEnumName = CameraVideoStreamSource[lensId];
    return {
      id: CameraVideoStreamSource[lensEnumName],
      name: CameraSourceMenuItemNameMap[lensEnumName],
      nickname: CameraSourceNameButtonNameMap[lensEnumName]
    };
  }
);

export const selectRemoteCockpitIsMultiLens = createSelector(
  fromFlights.selectRemoteCockpitState,
  (state: fromRemoteCockpit.State) => state.currentDeviceState.isMultiLens
);

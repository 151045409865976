<h1 mat-dialog-title>{{ 'plans.cancelPlan.title' | translate }}</h1>

<div mat-dialog-content>
  <p>{{ 'plans.cancelPlan.beforeYouLeave' | translate }}</p>
  <ul>
    <li [innerHTML]="'plans.cancelPlan.bullet1' | translate"></li>
    <li [innerHTML]="'plans.cancelPlan.bullet2' | translate"></li>
    <li [innerHTML]="'plans.cancelPlan.bullet3' | translate"></li>
    <li>
      {{ 'plans.cancelPlan.bullet4.0' | translate }}
      <a class="link" [mat-dialog-close]="false" (click)="goToContactUs()">{{
        'plans.cancelPlan.bullet4.1' | translate
      }}</a>
    </li>
  </ul>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" color="primary">
    {{ 'plans.cancelPlan.keepPackage' | translate }}
  </button>
  <button
    mat-button
    (click)="cancelSubscription()"
    [disabled]="cancelSubscriptionInProgress$ | async"
    color="warn"
    [appLadda]="cancelSubscriptionInProgress$ | async"
  >
    {{ 'plans.cancelPlan.cancelPackage' | translate }}
  </button>
</div>

import {createSelector} from '@ngrx/store';
import * as fromSignUp from '@app/auth/components/sign-up/sign-up.selectors';
import {selectUserState} from '../app.state';
import {UserState} from './user.reducer';

export const selectUser = createSelector(selectUserState, (state: UserState) => state.user);

export const selectUserId = createSelector(selectUserState, (state: UserState) => state.user?.id);
export const selectUserTeamId = createSelector(
  selectUserState,
  (state: UserState) => state.user?.activeTeamAndCompany.teamId
);

export const selectDevices = createSelector(selectUserState, (state: UserState) => Object.values(state.devices));
export const selectDevicesObject = createSelector(selectUserState, (state: UserState) => state.devices);

export const selectDevicesSorted = createSelector(selectUserState, (state: UserState) => {
  return Object.values(state.devices).sort((deviceA, deviceB) => deviceA.name?.localeCompare(deviceB.name));
});

export const selectDevice = createSelector(
  selectUserState,
  (state: UserState, props: {deviceId: string}) => state.devices[props.deviceId]
);

export const selectDefaultDevice = createSelector(selectUserState, (state: UserState) => {
  const devices = Object.values(state.devices);
  if (devices.length > 0) {
    const defaultDevice = devices.find(d => d.usage === 'default' || d.id === 'BROWSER');
    return defaultDevice || devices[0];
  }
  return null;
});

export const selectCurrentCompanyId = createSelector(selectUserState, (state: UserState) => {
  if (state.user?.activeTeamAndCompany) {
    return state.user.activeTeamAndCompany.teamId;
  }
  return null;
});

export const selectIsDeveloperMode = createSelector(selectUserState, (state: UserState) => {
  return state.user?.developer;
});

export const selectIsAutoflyEnabled = createSelector(selectUserState, (state: UserState) => {
  return state?.company?.autoflyFullAccess;
});

export const selectHasDeveloperMode = createSelector(
  fromSignUp.selectSignUpFormIsDeveloperMode,
  isDeveloperMode => isDeveloperMode
);

export const selectCompany = createSelector(selectUserState, (state: UserState) => state.company);

export const selectCurrentPlan = createSelector(selectUserState, (state: UserState): string => state.user.currentPlan);

export const selectCompanies = createSelector(selectUserState, (state: UserState) => state.companies);

export const selectUserTeams = createSelector(selectUserState, (state: UserState) => state.userTeams);
export const selectCurrentTeamId = createSelector(
  selectUserState,
  (state: UserState) => state.user?.activeTeamAndCompany?.teamId
);

export const selectCompanyId = createSelector(
  selectUserState,
  (state: UserState) => state.user?.activeTeamAndCompany?.companyId
);

export const selectCurrentOrganizationName = createSelector(
  selectUserState,
  (state: UserState) => state.company?.name || ''
);

export const selectIsSwitchingTeam = createSelector(selectUserState, (state: UserState) => state.isSwitchingTeam);

export const selectCurrentTeam = createSelector(
  selectUserState,
  (state: UserState) => state.userTeams[state.user.activeTeamAndCompany.teamId]
);

export const selectIsMfaEnabled = createSelector(selectUserState, (state: UserState) => {
  return state.company?.mfaRequired;
});

export const selectLiveDevices = createSelector(selectUserState, (state: UserState) => {
  return Object.values(state.devices).filter(device => device.isLive);
});

export const selectCurrentRole = createSelector(selectUserState, (state: UserState) => {
  return Object.values(state.userTeams).length > 0
    ? state.userTeams[state.user?.activeTeamAndCompany?.teamId]?.role
    : null;
});

export const selectIsUpdatingCognitoUser = createSelector(
  selectUserState,
  (state: UserState) => state.isUpdatingCognitoUser
);

import {Injectable} from '@angular/core';
import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync';
import {environment} from '../../../environments/environment';
import {Auth} from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class LibraryAwsAppAsyncClientService {
  public client: AWSAppSyncClient<any>;

  constructor() {
    this.generateAppSyncClient();
  }

  public generateAppSyncClient(): void {
    this.client = new AWSAppSyncClient({
      url: environment.APPSYNC_LIBRARY_URI,
      region: environment.region,
      disableOffline: true,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
      }
    });
  }
}

import {AsyncPipe, CommonModule, NgIf} from '@angular/common';
import {Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {ImageAnnotationSharedModule} from '@app/shared/image-annotation-shared/image-annotation-shared.module';
import {DrawStatus, LabelOpacity, SingleLabel} from '@app/shared/image-annotation-shared/models/image-annotation.model';
import {Marker} from '@app/shared/image-annotation-shared/models/marker';
import {CanvasService} from '@app/shared/image-annotation-shared/services/canvas.service';
import {ManagerZonesDialogService} from '@app/shared/manage-zones-dialog/services/manager-zones-dialog.service';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {SharedPipesModule} from '@app/shared/pipes/shared-pipes.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {BehaviorSubject, Observable, Subscription, filter, fromEvent, map, shareReplay, take} from 'rxjs';
import {CalibrationLayout} from '../../calibration.component';
import {LabelColor, LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {CalibrationService, CalibrationSidebarMode} from '../../services/calibration.service';
import {ContextMenuModule} from '@app/shared/context-menu/context-menu.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {ContextMenuComponent} from '@app/shared/context-menu/context-menu/context-menu.component';
import {MatDividerModule} from '@angular/material/divider';
import {ColorPickerComponent} from '@app/shared/color-picker/color-picker.component';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {CalibrationMapComponent} from '../calibration-map/calibration-map.component';
import {PinchZoomModule} from '@app/shared/pinch-zoom/pinch-zoom.module';
import {ZoomService} from '@app/library/services/zoom.service';
import {PinchZoomComponent} from '@app/shared/pinch-zoom/pinch-zoom.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'ua-calibration-annotation',
  templateUrl: './calibration-annotation.component.html',
  styleUrls: ['./calibration-annotation.component.scss'],
  standalone: true,
  imports: [
    ImageAnnotationSharedModule,
    UnleashLoaderModule,
    AsyncPipe,
    SharedPipesModule,
    NgIf,
    ContextMenuModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    ColorPickerComponent,
    CommonModule,
    CalibrationMapComponent,
    PinchZoomModule,
    TranslateModule
  ],
  providers: [CanvasService, ZoomService]
})
export class CalibrationAnnotationComponent implements OnInit, OnDestroy {
  @ViewChild('pinchZoom', {static: false, read: PinchZoomComponent}) public zoom: PinchZoomComponent;
  @ViewChild('contextMenu', {static: false, read: ContextMenuComponent})
  public contextMenu: ContextMenuComponent;
  @Input('currentLayout')
  public set setupCurrentLayout(currentLayout: CalibrationLayout) {
    if (currentLayout === CalibrationLayout.addCalibration) {
      this.startDraw();
    }
  }

  public color = LabelColorName.green;
  public thumblerConfig = THUMBLER_AVAILABLE_CONFIGS.libraryLarge;
  public newMarkerIndex$ = this.calibrationService.pointsLatLng$.pipe(
    map(points => {
      return points.length;
    })
  );

  private isLoadingImage = new BehaviorSubject<boolean>(false);
  public isLoadingImage$ = this.isLoadingImage.asObservable().pipe(shareReplay(1));

  private drawStatus = new BehaviorSubject<DrawStatus>(DrawStatus.stop);
  public drawStatus$ = this.drawStatus.pipe(shareReplay(1));

  private MARKER_SVG_INDEX = 0;
  private TEXT_INDEX = 1;

  public imageSnapshot$ = this.managerZonesDialogService.imageSnapshot$.pipe(
    filter(data => !!data),
    shareReplay(1)
  );

  public isMobileView$: Observable<boolean> = this.observableMedia.asObservable().pipe(
    map(change => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      const isMobileView = mediaAlias === 'xs';
      return isMobileView;
    })
  );
  public selectedColor$ = this.calibrationService.selectedColor$;
  public deviceLocation$ = this.calibrationService.getDeviceLocation();
  public currentCalibrationSidebarMode$ = this.calibrationService.calibrationSidebarMode$;
  public calibrationSidebarMode = CalibrationSidebarMode;
  public canvasDiagonalWidth$: Observable<number> = this.canvasService.canvasDiagonalWidth$;
  public naturalImageDiagonalWidth$: Observable<number> = this.canvasService.naturalImageDiagonalWidth$;
  public points$ = this.calibrationService.pointsLatLng$.pipe(
    map(points => points.map(point => ({lat: point[0], lng: point[1]})))
  );

  private isReadyToDraw: boolean = false;
  private isMouseDown: boolean = false;
  private deletePointByIndexSub: Subscription;
  private deleteAllPointsSub: Subscription;
  private updateDrawStatusSub: Subscription;
  private selectedColorSub: Subscription;
  private updateMarkerColorSub: Subscription;
  private drawPointsSub: Subscription;
  private hightLightMarkerByIndexSub: Subscription;
  private startDrawPerspectiveZoneSub: Subscription;
  private getCalibrationShapeSub: Subscription;
  private drawPerspectiveZoneSub: Subscription;
  private stopPerspectiveZoneEditSub: Subscription;
  private loadCalibrationPointsSub: Subscription;
  private stopDrawPerspectiveSub: Subscription;
  private calibrationSidebarModeSub: Subscription;
  private customCursorMouseMoveSub: Subscription;
  private customCursorMouseLeaveSub: Subscription;

  constructor(
    private managerZonesDialogService: ManagerZonesDialogService,
    private canvasService: CanvasService,
    private observableMedia: MediaObserver,
    private calibrationService: CalibrationService,
    private zoomService: ZoomService,
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  public ngOnInit(): void {
    this.selectedColorSub = this.calibrationService.selectedColor$.subscribe(color => {
      this.updateMarkerColor(color);
      this.color = color;
    });

    this.updateDrawStatusSub = this.calibrationService.updateDrawStatus$.subscribe((drawStatus: DrawStatus) => {
      this.drawStatus.next(drawStatus);
    });

    this.deleteAllPointsSub = this.calibrationService.deleteAllPoints$.subscribe(() => {
      this.canvasService.getCanvas()?.clear();
    });

    this.deletePointByIndexSub = this.calibrationService.deletePointByIndex$.subscribe(pointIndex => {
      this.canvasService
        .getCanvas()
        .select(`#marker-${pointIndex + 1}`)
        .first()
        .remove();

      this.reCalcMarkerLabels();
    });

    this.drawPointsSub = this.calibrationService.drawPoints$.subscribe(points => {
      points.forEach(point => {
        const markerTool = this.canvasService.selectMarker({pathSizeX: 25, pathSizeY: 35, fontSize: 17}) as Marker;
        markerTool.ref.attr('id', `marker-${this.calibrationService.markerCounter}`);
        (markerTool.ref as any).node.style.cursor = 'pointer';
        const textIndex = 1;
        const textInGroup = (markerTool.ref as any).get(textIndex);
        textInGroup.attr('id', `text-${this.calibrationService.markerCounter}`);
        textInGroup.text(this.calibrationService.markerCounter.toString());

        const viewBoxX = point[0];
        const viewBoxY = point[1];

        markerTool.updateColor(this.color);
        markerTool.moveMarker(viewBoxX, viewBoxY);

        const bbox = markerTool.ref.bbox();
        markerTool.ref.dmove(-bbox.width / 2, -bbox.height);
        this.addMarkerEvents(markerTool);

        this.calibrationService.increaseMarkerCounter();
      });
    });

    this.loadCalibrationPointsSub = this.calibrationService.loadCalibrationPoints$.subscribe(points => {
      if (points?.length === 4) {
        this.calibrationService.emitDrawPerspectiveZone(points, false);
      }
    });

    this.updateMarkerColorSub = this.calibrationService.updateMarkerColor$.subscribe(updateMarkerPayload => {
      const markers = this.canvasService
        .getCanvas()
        ?.children()
        .filter(marker => marker.type === 'g');

      if (markers && markers.length > 0) {
        const marker = markers[updateMarkerPayload.index];

        if (updateMarkerPayload.color === LabelColorName.white) {
          marker?.get(this.TEXT_INDEX).attr('fill', this.color);
          marker?.get(this.MARKER_SVG_INDEX).attr('fill', LabelColor[LabelColorName.white]);
        } else {
          marker?.get(this.TEXT_INDEX).attr('fill', LabelColor.white);
          marker?.get(this.MARKER_SVG_INDEX).attr('fill', LabelColor[updateMarkerPayload.color]);
        }
      }
    });

    this.hightLightMarkerByIndexSub = this.calibrationService.hightLightMarkerByIndex$.subscribe(index => {
      if (index !== -1) {
        this.hightlightMarker(index);
        return;
      }

      this.restorHightlightMarker();
    });

    this.startDrawPerspectiveZoneSub = this.calibrationService.startDrawPerspectiveZone$.subscribe(() => {
      this.startDrawPerspectiveZone();
    });

    this.getCalibrationShapeSub = this.calibrationService.getCalibrationShape$.subscribe(() => {
      this.canvasService
        .getCanvas()
        ?.children()
        .forEach(item => {
          if (!!item && item.type === 'polygon') {
            this.calibrationService.emitCalibrationShape(item.array().value);
          }
        });
    });

    this.drawPerspectiveZoneSub = this.calibrationService.drawPerspectiveZone$
      .pipe(filter(({points}) => points?.length === 4))
      .subscribe(({points, hasToEdit}) => {
        const perspective = this.canvasService.selectPerspective();

        const label = {
          shapeType: ShapeTypes.perspective,
          shape: points,
          severity: 0,
          comment: '',
          color: this.color,
          isAI: false,
          isAccepted: false,
          isModified: false,
          suggestedCategory: '',
          isRemoved: false,
          visibility: true,
          isSelected: false,
          distance: 0,
          addonId: ''
        } as SingleLabel;

        perspective.importDraw(label, 1000, null, null);
        if (hasToEdit) {
          perspective.editDraw();
        } else {
          perspective.bindOnClick(() => {
            this.calibrationService.emitDeleteAllPoints();
            this.calibrationService.setCurrentLayout(CalibrationLayout.addPerspectiveCalibration);
          });
        }
      });

    this.stopPerspectiveZoneEditSub = this.calibrationService.stopPerspectiveZoneEdit$.subscribe(() => {
      this.canvasService.selectedTool.stopSelection();
    });

    this.stopDrawPerspectiveSub = this.calibrationService.stopDrawPerspective$.subscribe(() => {
      this.canvasService.selectedTool.cancelDraw();
    });

    this.calibrationSidebarModeSub = this.currentCalibrationSidebarMode$.subscribe((mode: CalibrationSidebarMode) => {
      mode === CalibrationSidebarMode.ADD_POINT ? this.addCustomCursorEvents() : this.removeCustomCursorEvents();
      if (mode === CalibrationSidebarMode.POINT_LIST && this.zoomService.zoom) {
        this.canvasDiagonalWidth$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe(() => {
            this.zoomService.zoom.reset();
          });
      }
    });
  }

  public removeCustomCursorEvents(): void {
    this.customCursorMouseLeaveSub?.unsubscribe();
    this.customCursorMouseMoveSub?.unsubscribe();
    const cursor = document.querySelector('#canvas-cursor');
    const canvasContainer = this.canvasService.getCanvas()?.node as any;
    if (cursor) {
      (cursor as any).style.visibility = `hidden`;
    }
    if (canvasContainer) {
      canvasContainer.style.cursor = 'default';
      canvasContainer.childNodes.forEach(marker => {
        (marker as any).style.pointerEvents = `auto`;
      });
    }
  }

  public addCustomCursorEvents(): void {
    const canvasContainer = this.canvasService.getCanvas().node as any;
    const cursor = document.querySelector('#canvas-cursor');
    this.customCursorMouseMoveSub = fromEvent(canvasContainer, 'mousemove').subscribe((event: any) => {
      canvasContainer.style.cursor = 'none';
      (cursor as any).style.left = `${event.pageX}px`;
      (cursor as any).style.top = `${event.pageY}px`;
      (cursor as any).style.visibility = `visible`;
    });
    this.customCursorMouseLeaveSub = fromEvent(canvasContainer, 'mouseleave').subscribe(() => {
      (cursor as any).style.visibility = `hidden`;
    });
    canvasContainer.childNodes.forEach(marker => {
      (marker as any).style.pointerEvents = `none`;
    });
  }

  public reCalcMarkerLabels() {
    this.calibrationService.resetMarkerCounter();

    this.canvasService
      .getCanvas()
      ?.children()
      .forEach(item => {
        if (!!item && item.type === 'g') {
          const text = item.get(this.TEXT_INDEX);
          text.text(this.calibrationService.markerCounter.toString());
          text.attr('id', `text-${this.calibrationService.markerCounter}`);
          item.attr('id', `marker-${this.calibrationService.markerCounter}`);
          this.calibrationService.increaseMarkerCounter();
        }
      });
  }

  public ngOnDestroy(): void {
    this.drawStatus.next(DrawStatus.stop);

    if (this.deletePointByIndexSub) {
      this.deletePointByIndexSub.unsubscribe();
      this.deletePointByIndexSub = null;
    }

    if (this.updateDrawStatusSub) {
      this.updateDrawStatusSub.unsubscribe();
      this.updateDrawStatusSub = null;
    }

    if (this.deleteAllPointsSub) {
      this.deleteAllPointsSub.unsubscribe();
      this.deleteAllPointsSub = null;
    }

    if (this.updateMarkerColorSub) {
      this.updateMarkerColorSub.unsubscribe();
      this.updateMarkerColorSub = null;
    }

    if (this.selectedColorSub) {
      this.selectedColorSub.unsubscribe();
      this.selectedColorSub = null;
    }

    if (this.drawPointsSub) {
      this.drawPointsSub.unsubscribe();
      this.drawPointsSub = null;
    }

    if (this.hightLightMarkerByIndexSub) {
      this.hightLightMarkerByIndexSub.unsubscribe();
      this.hightLightMarkerByIndexSub = null;
    }

    if (this.startDrawPerspectiveZoneSub) {
      this.startDrawPerspectiveZoneSub.unsubscribe();
      this.startDrawPerspectiveZoneSub = null;
    }

    if (this.getCalibrationShapeSub) {
      this.getCalibrationShapeSub.unsubscribe();
      this.getCalibrationShapeSub = null;
    }

    if (this.drawPerspectiveZoneSub) {
      this.drawPerspectiveZoneSub.unsubscribe();
      this.drawPerspectiveZoneSub = null;
    }

    if (this.stopPerspectiveZoneEditSub) {
      this.stopPerspectiveZoneEditSub.unsubscribe();
      this.stopPerspectiveZoneEditSub = null;
    }

    if (this.loadCalibrationPointsSub) {
      this.loadCalibrationPointsSub.unsubscribe();
      this.loadCalibrationPointsSub = null;
    }

    if (this.stopDrawPerspectiveSub) {
      this.stopDrawPerspectiveSub.unsubscribe();
      this.stopDrawPerspectiveSub = null;
    }
  }

  public stopLoader(): void {
    this.isLoadingImage.next(false);
    this.calibrationService.storeImageSize(
      this.canvasService.getCanvasSize().width,
      this.canvasService.getCanvasSize().height
    );
    this.calibrationService.loadPoints();
  }

  public startDraw(): void {
    this.drawStatus.next(DrawStatus.draw);
  }

  public addMarkerEvents(markerTool): void {
    const index = +(markerTool.ref as any).node.textContent - 1;
    markerTool.ref.on('contextmenu', (event: MouseEvent) => {
      this.calibrationService.currentLayout$.pipe(take(1)).subscribe(currentLayout => {
        event.preventDefault();
        if (currentLayout !== CalibrationLayout.addCalibration) {
          return;
        }
        this.contextMenu.open(event);
        this.contextMenu.menuTrigger.menuData.index = index;
      });
    });

    markerTool.ref.on('click', () => {
      this.calibrationService.calibrationSidebarMode$.pipe(take(1)).subscribe(calibrationSidebarMode => {
        if (calibrationSidebarMode === CalibrationSidebarMode.ADD_POINT) {
          return;
        }
        this.calibrationService.setCurrentLayout(CalibrationLayout.addCalibration);
        this.calibrationService.enableEditMode(index);
      });
    });

    markerTool.ref.on('mouseover', () => {
      this.calibrationService.currentLayout$.pipe(take(1)).subscribe(layout => {
        if (layout !== CalibrationLayout.list) {
          this.calibrationService.emitHightLightMarkerByIndex(index + 1);
        }
      });
    });

    markerTool.ref.on('mouseleave', () => {
      this.calibrationService.emitHightLightMarkerByIndex(-1);
    });
  }

  public drawMarker(mouseEvent: MouseEvent): void {
    if (!this.isReadyToDraw) {
      this.isReadyToDraw = true;
      return;
    }
    this.currentCalibrationSidebarMode$.pipe(take(1)).subscribe(sidebarMode => {
      if (sidebarMode === CalibrationSidebarMode.POINT_LIST) {
        return;
      }

      if (this.drawStatus.value !== DrawStatus.draw) {
        return;
      }

      const canvasId = this.canvasService.getCanvas().id();
      const tagName = 'svg';

      if ((mouseEvent.target as any).tagName !== tagName && (mouseEvent.target as any).id !== canvasId) {
        return;
      }

      const markerTool = this.canvasService.selectMarker({pathSizeX: 25, pathSizeY: 35, fontSize: 17}) as Marker;
      markerTool.ref.attr('id', `marker-${this.calibrationService.markerCounter}`);
      (markerTool.ref as any).node.style.cursor = 'pointer';

      const textIndex = 1;
      const textInGroup = (markerTool.ref as any).get(textIndex);
      textInGroup.attr('id', `text-${this.calibrationService.markerCounter}`);
      textInGroup.text(this.calibrationService.markerCounter.toString());

      const width = this.canvasService.getCanvas().width();
      const height = this.canvasService.getCanvas().height();
      const viewBoxWidth = this.canvasService.getCanvasSize().width;
      const viewBoxHeight = this.canvasService.getCanvasSize().height;

      const scaleX = viewBoxWidth / width;
      const scaleY = viewBoxHeight / height;

      const viewBoxX = mouseEvent.offsetX * scaleX;
      const viewBoxY = mouseEvent.offsetY * scaleY;

      this.calibrationService.setCurrentMarkerCoordinates(viewBoxX, viewBoxY);

      markerTool.updateColor(this.color);
      markerTool.moveMarker(viewBoxX, viewBoxY);

      const bbox = markerTool.ref.bbox();
      markerTool.ref.dmove(-bbox.width / 2, -bbox.height);
      this.addMarkerEvents(markerTool);

      this.calibrationService.increaseMarkerCounter();
      this.calibrationService.emitPointGenerated();
      this.calibrationService.addPointOnScreen([viewBoxX, viewBoxY]);
      this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.ADD_MARKER_IN_MAP);
    });
  }

  public hightlightMarker(index: number): void {
    this.canvasService
      .getCanvas()
      ?.children()
      .forEach(item => {
        if (!!item && item.type === 'g') {
          const currentIndex = item.id().split('-')[1];
          const opacity = currentIndex === index.toString() ? LabelOpacity.full : LabelOpacity.highlight;
          item.attr('opacity', opacity);
        }
      });
  }

  public restorHightlightMarker(): void {
    this.canvasService
      .getCanvas()
      ?.children()
      .forEach(item => {
        if (!!item && item.type === 'g') {
          item.attr('opacity', LabelOpacity.full);
        }
      });
  }

  public updateMarkerColor(color: LabelColorName) {
    if (this.canvasService.selectedTool?.type === ShapeTypes.perspective) {
      this.canvasService.selectedTool.updateColor(color);
      return;
    }

    this.canvasService
      .getCanvas()
      ?.children()
      .forEach(item => {
        if (!!item && item.type === 'g') {
          const currentColor = item.get(this.MARKER_SVG_INDEX).attr('fill');
          if (currentColor === '#fafafa') {
            item.get(this.TEXT_INDEX).attr('fill', LabelColor[color]);
            return;
          }
          item.get(this.MARKER_SVG_INDEX).attr('fill', LabelColor[color]);
        }
      });
  }

  public setColor(colorName: LabelColorName): void {
    this.calibrationService.setSelectedColor(colorName);
  }

  public deleteMarker(index: number): void {
    this.calibrationService.confirmDeleteMarkerByIndex(index);
  }

  public startDrawPerspectiveZone(): void {
    const perspective = this.canvasService.selectPerspective();
    perspective.setScalingFactorForCircleRadius(1000);
    perspective.startDraw({color: this.color});

    fromEvent(perspective.ref as any, 'drawstop').subscribe(() => {
      perspective.editDraw();
    });
  }

  public emitHightLightMarkerByIndex(index: number): void {
    this.calibrationService.emitHightLightMarkerByIndex(index);
  }

  public setMarkerCoordinates({lat, lng}) {
    this.calibrationService.setCalibrationSidebarMode(CalibrationSidebarMode.POINT_LIST);
    this.calibrationService.pointsLatLng$.pipe(take(1)).subscribe(points => {
      this.calibrationService.setPointLatLng([...points, [lat, lng]]);
      this.calibrationService.sidebarCanvas.clearCanvas();
      this.zone.run(() => {
        this.snackBar.open('Calibration point added', null, {panelClass: 'center', duration: 3000});
      });
    });
  }

  public changeZoom(): void {
    this.zoomService.zoom = this.zoom;
    this.canvasService.setZoomScale(this.zoomService.zoom.pinchZoom.scale);
  }

  public onMouseDown(): void {
    this.isMouseDown = true;
  }

  public onMouseUp(): void {
    this.isMouseDown = false;
  }

  public onMouseMove(): void {
    this.isReadyToDraw = !this.isMouseDown;
  }

  public enableEditMode(index: number): void {
    this.calibrationService.enableEditMode(index);
  }
}

/* eslint-disable no-magic-numbers */
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {Canvas, DragConstrain, Draw, LabelOpacity, SingleLabel} from './image-annotation.model';
import {fromEvent, merge, take} from 'rxjs';
declare const SVG: any;

export abstract class DrawTool {
  public selectedColor: string;
  public ref: Draw;
  public type: ShapeTypes;
  public hasToDraw: boolean = true;
  public _id: string;
  public vertexStrokeWidth: number = 2;

  private CIRCLE_DIAMETER_STANDARD = 300;
  private MIN_CIRCLE_DIAMETER = 3;
  private SMALL_SCALING_FACTOR = 200;
  private maxFontSize = 30;

  protected circleDiameter = 25;
  protected baseScalingFactor = 20;
  protected generalScalingFactor = 14;
  protected transformBoxSize = 25.65;
  protected vertexDiameterPoints = 30.8;
  protected vertexDiameterPointsExtended = 102.67;
  protected constrain: DragConstrain;
  protected maxCircleDiameter = 30;

  protected tagNameGroup: Draw;
  protected textTagName: Draw;
  protected textContainerTagName: Draw;
  protected referenceTagName = {
    group: 'tag-name-group',
    text: 'tag-name-text',
    textContainer: 'tag-name-text-container'
  };
  protected isValidClick = false;

  constructor(public canvas: Canvas) {}

  public setScalingFactorForCircleRadius(scalingFactor: number) {
    const generalScalingFactor = Math.floor(scalingFactor / 100);
    const currentMinCircleDiameter =
      scalingFactor < this.SMALL_SCALING_FACTOR ? this.MIN_CIRCLE_DIAMETER : generalScalingFactor;

    const circleDiameterScale = scalingFactor / this.CIRCLE_DIAMETER_STANDARD;
    let calcCircleDiameter =
      circleDiameterScale >= currentMinCircleDiameter ? circleDiameterScale : currentMinCircleDiameter;
    calcCircleDiameter = calcCircleDiameter > this.maxCircleDiameter ? this.maxCircleDiameter : calcCircleDiameter;

    const boxPadding =
      scalingFactor > this.SMALL_SCALING_FACTOR ? Math.floor(scalingFactor / 100) : Math.floor(scalingFactor / 1000);
    this.transformBoxSize = calcCircleDiameter + boxPadding + 5;

    this.circleDiameter = calcCircleDiameter;
    this.vertexDiameterPoints = calcCircleDiameter;
    this.vertexDiameterPointsExtended = this.vertexDiameterPoints / 20 + boxPadding;
    this.vertexStrokeWidth =
      scalingFactor > this.SMALL_SCALING_FACTOR ? Math.floor(scalingFactor / 1000) + 0.01 : 0.001;
    this.generalScalingFactor = generalScalingFactor > this.maxFontSize ? this.maxFontSize : generalScalingFactor;
  }

  public setDragConstrain(constrain: DragConstrain) {
    this.constrain = constrain;
  }

  public redrawCirclesOnEditMode() {
    this.stopSelection();
    this.startSelection();
  }

  public redrawCirclesOnDrawMode() {
    if (this.hasDraw()) {
      this.ref.draw('drawCircles', this.circleDiameter);
    }
  }

  public updateHasToDraw(hasToDraw: boolean) {
    this.ref.remember('hasToDraw', hasToDraw);
  }

  public startDraw(opts?: Partial<{id: string; color: string; name: string}>) {
    this.ref.remember('hasToDraw', true);
    this.selectedColor = opts.color;
    const id = opts ? opts.id : this.idGenerator(this.type);

    this.ref.draw({
      closeLastPoint: true,
      circleDiameter: this.circleDiameter,
      fill: '#ee1a46cc',
      skipPointConstrain: false
    });
    this.ref.attr('fill', this.selectedColor);
    this.ref.attr('fill-opacity', LabelOpacity.highlight);
    this.ref.attr('stroke', '#000');
    this.ref.attr('stroke-opacity', '1');
    this.ref.attr('stroke-width', this.vertexStrokeWidth + 'px');
    this.ref.attr('id', id);
    this.ref.attr('display-name', opts.name);

    if (this.type !== ShapeTypes.line_in_out) {
      this.ref.attr('stroke-dasharray', '0');
    }

    this.clickOnCanvasExtraElements();
  }

  public stopDraw() {
    this.stopSelection();
  }

  public completeDraw() {
    this.ref.draw('complete');
  }

  public undoDraw() {
    this.ref.draw('undo');
  }

  public redoDraw() {
    this.ref.draw('redo');
  }

  public editDraw() {
    this.bringToFront();
    this.startSelection();
    this.unBindOnClick();
  }

  public stopSelection() {
    this.ref.resize('stop').draggable(false).selectize(false, {deepSelect: true}).selectize(false);
    this.ref.on('mouseenter', () => {
      document.dispatchEvent(new CustomEvent('highlightZone', {detail: this.id}));
    });
    this.ref.on('mouseleave', () => {
      document.dispatchEvent(new CustomEvent('highlightZone', {detail: ''}));
    });
  }

  public cancelDraw() {
    this.ref.draw('cancel');
    this.unBindOnClick();
    this.stopSelection();
  }

  public removeDraw() {
    this.tagNameGroup = SVG.get(`${this.id}-${this.referenceTagName.group}`);
    this.textTagName = SVG.get(`${this.id}-${this.referenceTagName.text}`);
    this.textContainerTagName = SVG.get(`${this.id}-${this.referenceTagName.textContainer}`);

    this.ref.remove();
    this.tagNameGroup?.remove();
    this.textTagName?.remove();
    this.textContainerTagName?.remove();
  }

  public bindOnClick(selectShapeCallback: (data?: any) => any) {
    this.ref.click(() => {
      if (this.ref.attr('fill-opacity') !== LabelOpacity.hidden) {
        selectShapeCallback();
      }
    });
  }

  public bindOnRightClick(rightClickShapeCallback: (data?: any) => any) {
    this.ref.on('contextmenu', () => rightClickShapeCallback());
  }

  public unBindOnClick() {
    this.ref.click(null);
  }

  public updateColor(color: string) {
    this.ref.attr('fill', color);
  }

  public set id(id: string) {
    this.ref.attr('id', id);
    this._id = id;
  }

  public get id(): string {
    return this.ref.attr('id') as string;
  }

  public get color(): string {
    return this.ref.attr('fill') as string;
  }

  public abstract generateDrawTemplate(): SingleLabel;

  public abstract exportDraw(singleLabel: SingleLabel): [SingleLabel, string];

  public abstract importDraw(
    label: SingleLabel,
    scalingFactor: number,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ): SingleLabel;

  public abstract saveShapeBackup(): any;

  public abstract hasDraw(): boolean;

  protected idGenerator(shapeType: string): string {
    return Math.random()
      .toString(36)
      .replace('0.', shapeType || '');
  }

  protected startSelection() {
    if (this.type !== ShapeTypes.line_in_out) {
      this.ref.attr('stroke-dasharray', '0');
    }

    this.ref
      .selectize({
        pointType: 'rect',
        pointSize: this.vertexDiameterPoints,
        pointStroke: {width: this.vertexStrokeWidth},
        pointPadding: this.transformBoxSize,
        rotationPoint: false,
        vertexSize: this.vertexDiameterPoints,
        vertexStroke: {width: this.vertexStrokeWidth},
        transformBoxSize: this.transformBoxSize
      })
      .selectize({
        rotationPoint: false,
        deepSelect: true,
        pointSize: this.vertexDiameterPointsExtended,
        pointStroke: {width: this.vertexStrokeWidth},
        pointPadding: this.transformBoxSize,
        vertexSize: this.vertexDiameterPoints,
        vertexStroke: {width: this.vertexStrokeWidth},
        transformBoxSize: this.transformBoxSize
      })
      .resize({constraint: this.constrain})
      .draggable(this.constrain);
  }

  protected bringToFront() {
    this.ref.front();
  }

  protected customRotationConstrain(x, y, m) {
    const rotation = new SVG.Matrix(m).extract().rotation;

    const canvas = (this as any).node.parentNode.instance.viewbox();
    const constrain = {
      minX: 0,
      minY: 0,
      maxX: canvas.width,
      maxY: canvas.height
    };

    // Workaround for line-in-out with rotation point
    if (rotation !== 0) {
      return {x: true, y: true};
    }

    return {
      x: x >= constrain.minX && x <= constrain.maxX - (this as any).bbox().w,
      y: y >= constrain.minY && y <= constrain.maxY - (this as any).bbox().h
    };
  }

  static findCenterPoint(points: [number, number][]): {x: number; y: number} {
    let sumX = 0;
    let sumY = 0;
    const numPoints = points.length;

    for (const [x, y] of points) {
      sumX += x;
      sumY += y;
    }

    const centerX = sumX / numPoints;
    const centerY = sumY / numPoints;

    return {x: centerX, y: centerY};
  }

  public unbindInteractions() {}

  public disableTagNameEvents() {
    if (!this.ref) {
      return;
    }

    this.ref.off('mouseenter');
    this.ref.off('mouseleave');

    this.tagNameGroup = SVG.get(`${this.ref.attr('id')}-${this.referenceTagName.group}`);
    if (!this.tagNameGroup) {
      return;
    }

    this.tagNameGroup?.off('mouseenter');
    this.tagNameGroup?.off('mouseleave');
  }

  public enableTagNameEvents() {
    if (!this.ref) {
      return;
    }

    this.ref.on('mouseenter', () => this.displayZoneTag());
    this.ref.on('mouseleave', () => this.hideZoneTag());

    this.tagNameGroup = SVG.get(`${this.ref.attr('id')}-${this.referenceTagName.group}`);
    if (!this.tagNameGroup) {
      return;
    }

    this.tagNameGroup?.on('mouseenter', () => this.displayZoneTag());
    this.tagNameGroup?.on('mouseleave', () => this.hideZoneTag());
    this.tagNameGroup?.on('click', () => this.ref.fire('click'));
  }

  public updatePoints(points: number[][]) {
    this.ref._array.value = points;
  }

  public restoreBackup(drawBak: any): void {
    this.textContainerTagName = SVG.get(`${this.id}-${this.referenceTagName.textContainer}`);

    if (this.textContainerTagName) {
      this.textContainerTagName.attr('fill', drawBak.color);
    }
  }

  protected displayZoneTag(): void {
    this.tagNameGroup = SVG.get(`${this.id}-${this.referenceTagName.group}`);

    if (!this.tagNameGroup) {
      return;
    }

    this.tagNameGroup.attr({opacity: LabelOpacity.full});
    this.tagNameGroup.front();

    document.dispatchEvent(new CustomEvent('highlightZone', {detail: this.id}));
  }

  public hideZoneTag(): void {
    this.tagNameGroup = SVG.get(`${this.id}-${this.referenceTagName.group}`);

    if (!this.tagNameGroup) {
      return;
    }

    this.tagNameGroup.attr({opacity: LabelOpacity.hidden});
    this.tagNameGroup.back();

    document.dispatchEvent(new CustomEvent('highlightZone', {detail: ''}));
  }

  protected clickOnCanvasExtraElements(extraClick: string[] = []): void {
    const clickEvent = event => this.handleCanvasClick(event, extraClick);
    document.addEventListener('click', clickEvent);

    const drawStop$ = fromEvent(this.ref as any, 'drawstop');
    const drawCancel$ = fromEvent(this.ref as any, 'drawcancel');
    const drawDone$ = fromEvent(this.ref as any, 'drawdone');

    merge(drawStop$, drawCancel$, drawDone$)
      .pipe(take(1))
      .subscribe(() => {
        document.removeEventListener('click', clickEvent);
      });
  }

  public handleCanvasClick = (event, extraClick) => {
    event.preventDefault();

    const commonElements = ['svg', 'polygon', 'rect', 'circle', 'tspan'];
    const tagNameClicked = (event.target as any).localName;

    this.isValidClick = [...commonElements, ...extraClick].some(tagName => tagNameClicked === tagName);
    const hasExtraClick = extraClick.some(tagName => tagNameClicked === tagName);

    if (hasExtraClick) {
      this.ref.draw('point', event);
    }
  };
}

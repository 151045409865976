import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AsyncPipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  template: `
    <h2 mat-dialog-title class="title">
      {{ 'calibration.deleteMakerTitle' | translate: {value: data?.index + 1} }}
    </h2>
    <mat-dialog-content>
      <p>{{ 'calibration.deleteMarkerConfirmation' | translate }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close color="primary">{{ 'calibration.cancel' | translate }}</button>
      <button mat-raised-button (click)="deleteCalibration()" color="warn">
        <mat-icon>delete</mat-icon>
        {{ 'calibration.delete' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .title {
        margin: 0;
      }
    `
  ],
  standalone: true,
  imports: [MatDialogModule, AsyncPipe, MatButtonModule, MatIconModule, TranslateModule]
})
export class CalibrationConfirmMarkerDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CalibrationConfirmMarkerDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public deleteCalibration(): void {
    this.dialogRef.close({hasBeenDeleted: true, index: this.data.index});
  }
}

import {Injectable} from '@angular/core';
import {CreateEffectMetadata} from '@ngrx/effects/src/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as actions from '@app/atlas/store/atlas.actions';
import {switchMap, map, tap} from 'rxjs/operators';
import {AtlasApiService} from '@app/atlas/services/atlas-api.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';

@Injectable()
export class AtlasEffects {
  public loadAtlasItems$: CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAtlasItems),
      switchMap(() => {
        return this.atlasApiService.listAtlasItems();
      }),
      map((atlasItems: AtlasAssetModel[]) => actions.saveItems({payload: {atlasItems}}))
    )
  );

  constructor(private actions$: Actions, public atlasApiService: AtlasApiService) {}
}

import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialog {
  public itemsToDelete: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteDialog, boolean>,
    @Inject(MAT_DIALOG_DATA) private data: {items: string[]}
  ) {
    this.itemsToDelete = this.data.items;
  }

  public emitCancel(): void {
    this.dialogRef.close(false);
  }

  public emitConfirm(): void {
    this.dialogRef.close(true);
  }
}

<mat-form-field>
  <mat-chip-list aria-label="Select type" #typeChipList>
    <mat-chip *ngFor="let chipName of chipList; let index = index" color="primary" selected removable="true">
      {{ chipName }}
      <mat-icon matChipRemove (click)="removeFromChipList(index)">cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      autocomplete="off"
      [disabled]="disabled"
      #inputChip
      [matChipInputAddOnBlur]="true"
      [matChipInputFor]="typeChipList"
      (matChipInputTokenEnd)="addToChipList($event); inputChip.value = ''"
    />
  </mat-chip-list>
</mat-form-field>

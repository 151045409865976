<ng-container *ngIf="isInitialized$ | async as isLiveStreamInitialized; else unleashLoaderTemplate">
  <unleash-header-stream (addDevice)="addDevice()"></unleash-header-stream>
  <ng-container *ngIf="isShowingStreams$ | async as isShowingStreams; else showEmptyStateTemplate">
    <div class="grid-nav-bar" *ngIf="(liveDevicesId$ | async).length > 0">
      <button class="btn-grid btn-column bg-white" (click)="setLayoutState('state-one')">
        <img
          [ngClass]="{'filter-red': (layoutState$ | async) === 'state-one'}"
          src="assets/icons/live-stream/grid-bar/state-one.svg"
          alt="{{ 'live.live-stream-page.alt.fgl' | translate }}"
        />
      </button>
      <button class="btn-grid btn-column bg-white" (click)="setLayoutState('state-two')">
        <img
          class="mb-2"
          [ngClass]="{'filter-red': (layoutState$ | async) === 'state-two'}"
          src="assets/icons/live-stream/grid-bar/state-two-maximized.svg"
          alt="{{ 'live.live-stream-page.alt.cgl' | translate }}"
        />
        <img
          src="assets/icons/live-stream/grid-bar/state-two-minimized.svg"
          alt="{{ 'live.live-stream-page.alt.cgl' | translate }}"
        />
      </button>
    </div>

    <div class="container">
      <div class="device-wrapper">
        <div class="device-info" *ngIf="selectedDevice$ | async as selectedDevice">
          <div class="device-avatar" [ngClass]="{live: selectedDevice.isLive}">
            <ng-container *ngIf="selectedDevice.logo">
              <img [src]="selectedDevice.logo | uThumbler: THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo" />
            </ng-container>
            <mat-icon *ngIf="!selectedDevice.logo">live_tv</mat-icon>
          </div>
          <div fxFlex="nogrow" fxLayoutAlign="space-between center">
            <div class="device-title">
              {{ selectedDevice.name }}
            </div>
            <div fxLayoutAlign="center center" *ngIf="(layoutState$ | async) === 'state-two'">
              <button class="btn-grid btn-column bg-white btn-circle" (click)="setDeviceOnCinema(selectedDevice)">
                <img
                  class="mb-2"
                  [ngClass]="{'filter-red': selectedDevice.isLive && selectedDevice.id === (firstDeviceId$ | async)}"
                  src="assets/icons/live-stream/grid-bar/state-two-maximized.svg"
                  alt="{{ 'live.live-stream-page.alt.cgl' | translate }}"
                />
                <img
                  src="assets/icons/live-stream/grid-bar/state-two-minimized.svg"
                  alt="{{ 'live.live-stream-page.alt.cgl' | translate }}"
                />
              </button>
            </div>
          </div>
          <div fxFlex="nogrow" fxLayout="column" *ngIf="selectedDevice.make || selectedDevice.lat">
            <div fxFlex="nogrow" fxLayout="row" *ngIf="selectedDevice.make && selectedDevice.model" fxLayoutGap="7px">
              <div fxFlex="10" fxLayoutAlign="center center">
                <img
                  src="assets/icons/live-stream/device-type.png"
                  alt="{{ 'live.live-stream-page.alt.dvt' | translate }}"
                />
              </div>
              <div fxFlex="80" class="device-metadata-title">{{ selectedDevice.make }} {{ selectedDevice.model }}</div>
            </div>
            <div fxFlex="nogrow" fxLayout="row" *ngIf="selectedDevice.lat && selectedDevice.lng" fxLayoutGap="7px">
              <div fxFlex="10" fxLayoutAlign="center center">
                <img
                  src="assets/icons/live-stream/location-marker.png"
                  alt="{{ 'live.live-stream-page.alt.lcm' | translate }}"
                />
              </div>
              <div fxFlex="80" class="device-metadata-title">
                {{ selectedDevice.lat | number: '1.2-4' }}, {{ selectedDevice.lng | number: '1.2-4' }}
              </div>
            </div>
          </div>
          <div class="device-description">
            {{ selectedDevice.description }}
          </div>
          <div fxFlex="nogrow" fxLayout="column">
            <div
              *uaHasPermission="aclPermissions.LivestreamApiStreamPublish"
              class="device-stream-option-item"
              fxFlex="nogrow"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-slide-toggle
                [checked]="selectedDevice.destUrlSet"
                (change)="changeDestUrlSetOnSelectedDevice($event, selectedDevice.id)"
              >
                {{ 'live.live-stream-page.device-stream-option.0.toggle-label' | translate }}
              </mat-slide-toggle>
              <div class="device-stream-option-title" fxLayoutGap="15px" fxLayoutAlign="start center">
                <img
                  *ngIf="!!selectedDevice.isLive"
                  class="icon-info"
                  src="assets/icons/live-stream/info.png"
                  alt="{{ 'live.live-stream-page.device-stream-option.0.alt' | translate }}"
                  matTooltip="{{ 'live.live-stream-page.device-stream-option.0.toolTip' | translate }}"
                />
              </div>
            </div>
            <div class="app-restream-wrapper" *ngIf="selectedDevice.destUrlSet" fxLayout="column">
              <app-restream [device]="selectedDevice" [streamKey]="streamKey$ | async"> </app-restream>
            </div>
            <div class="device-stream-option-item" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="space-between center">
              <mat-slide-toggle
                #data
                [checked]="hasToDisplayModelSelector$ | async"
                (change)="onAIModelToggleSet($event)"
              >
                {{ 'live.live-stream-page.device-stream-option.1.toggle-label' | translate }}
              </mat-slide-toggle>
              <div class="device-stream-option-title" fxLayoutGap="15px" fxLayoutAlign="start center">
                <img
                  src="assets/icons/live-stream/info.png"
                  alt="{{ 'live.live-stream-page.device-stream-option.1.alt' | translate }}"
                  class="icon-info"
                  matTooltip="{{ 'live.live-stream-page.device-stream-option.1.toolTip' | translate }}"
                />
              </div>
            </div>
            <div class="model-selector-wrapper" *ngIf="(toggleModelSelector | async) || data.checked" fxLayout="column">
              <model-selector
                [device]="selectedDevice$ | async"
                [models]="modelSelectorOptions$ | async"
                [streamKey]="streamKey$ | async"
                (openZone)="openZoneDialog($event.device, $event.model)"
                (stopModel)="stopModelOfSelectedDeviceOptimisticUpdate($event)"
                (setCurrentModel)="setCurrentModel($event.device, $event.modelId)"
                (errorStartModel)="errorStartModel($event.deviceId, $event.modelId)"
              >
              </model-selector>
            </div>
          </div>
        </div>
        <div class="device-stream">
          <app-four-grid-layout></app-four-grid-layout>
        </div>
      </div>
      <app-device></app-device>
    </div>
  </ng-container>

  <ng-template #showEmptyStateTemplate>
    <div class="container">
      <unleash-empty-state-stream-cards></unleash-empty-state-stream-cards>
      <div class="all-devices">
        <app-device class="all-devices__list"></app-device>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #unleashLoaderTemplate>
  <unleash-loader [visible]="true" [text]="'live.live-stream-page.loaderMessage' | translate"></unleash-loader>
</ng-template>

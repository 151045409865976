import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'unleash-severity-button-group',
  templateUrl: './unleash-severity-button-group.component.html',
  styleUrls: ['./unleash-severity-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UnleashSeverityButtonGroupComponent,
      multi: true
    }
  ]
})
export class UnleashSeverityButtonGroupComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;

  @Input()
  severities = [
    {display: '1', value: '1', class: 'low', arialLabel: 'Low'},
    {display: '2', value: 'medium', class: 'medium', arialLabel: 'Medium'},
    {display: '3', value: 'normal', class: 'normal', arialLabel: 'Normal'},
    {display: '4', value: 'high', class: 'high', arialLabel: 'High'},
    {display: '5', value: 'very-high', class: 'very-high', arialLabel: 'Very high'},
    {display: '?', value: 'unknown', class: 'unknown', arialLabel: 'Unknown'}
  ];

  private value = '';

  constructor() {}

  ngOnInit() {}

  setValue(value: string) {
    this.writeValue(value);
    this.onChange(value);
  }

  onChange = (value: string) => {};
  onTouched = () => {};
  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

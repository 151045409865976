import {Injectable} from '@angular/core';
import {BehaviorSubject, shareReplay} from 'rxjs';
import {ActionMenu} from '../models/action-menu.model';

@Injectable({
  providedIn: 'root'
})
export class DownloadAnalyticsService {
  private availableAnalyticsMenuItems: BehaviorSubject<ActionMenu[]> = new BehaviorSubject([]);
  public availableAnalyticsMenuItems$ = this.availableAnalyticsMenuItems.asObservable();

  private hasAnalyticsMenuItems: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasAnalyticsMenuItems$ = this.hasAnalyticsMenuItems.asObservable();

  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$ = this.isLoading.asObservable().pipe(shareReplay(1));

  constructor() {}

  public setAvailableAnalyticsActions(actionMenuItems: ActionMenu[]) {
    this.availableAnalyticsMenuItems.next(actionMenuItems);
  }

  public clearDownloadAnalyticsItems() {
    this.hasAnalyticsMenuItems.next(false);
    this.availableAnalyticsMenuItems.next([]);
  }

  public activateDownloadAnalyticsMenuItem(): void {
    this.hasAnalyticsMenuItems.next(true);
  }

  public startLoading(): void {
    this.isLoading.next(true);
  }

  public stopLoading(): void {
    this.isLoading.next(false);
  }
}

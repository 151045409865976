import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AtlasLocalStorageService} from '@app/atlas/services/atlas-local-storage.service';
import {BehaviorSubject, ReplaySubject, Subscription} from 'rxjs';
import {delay} from 'rxjs/operators';
import {LocalStorageLayer} from './layers-control-item.models';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {taskLayers} from '@app/atlas/atlas.config';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layers-control-item',
  templateUrl: './layers-control-item.component.html',
  styleUrls: ['./layers-control-item.component.scss'],
  providers: [{provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: 'noop'}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersControlItemComponent implements OnInit {
  public events = EVENTS;
  // eslint-disable-next-line rxjs/finnish
  public saveLayersView$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  // eslint-disable-next-line rxjs/finnish
  public goToDefaultLayers$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  // eslint-disable-next-line rxjs/finnish
  public asset$ = new BehaviorSubject<AtlasAssetModel>(null);
  public layerSavedInLocalStorage: LocalStorageLayer[] = [];
  public assetType = AssetType;
  public hasToRemoveAssetsHiglight = this.atlasService.hasToRemoveAssetsHiglight;

  @Input('asset')
  public set setupAsset(asset: AtlasAssetModel) {
    if (asset) {
      if (asset.groupName === taskLayers) {
        asset.isDisplaying = false;
      }
      this.asset$.next(asset);
    }
  }
  @Input('saveLayersView')
  public set saveLayersView(hasToSave: boolean | null) {
    if (hasToSave !== null) {
      this.saveLayersView$.next(hasToSave);
    }
  }
  @Input('goToDefaultLayers')
  public set DefaultLayers(hasToSetLayers: boolean | null) {
    if (hasToSetLayers !== null) {
      this.goToDefaultLayers$.next(hasToSetLayers);
    }
  }
  @Input('hasToDetectChanges')
  public set hasToDetectChanges(hasToDetectChanges: boolean) {
    if (hasToDetectChanges !== undefined) {
      this.cd.detectChanges();
    }
  }
  @Input('hasToDetectChangesSidebar')
  public set hasToDetectChangesSidebar(hasToDetectChanges: boolean) {
    if (hasToDetectChanges !== null) {
      this.cd.detectChanges();
    }
  }
  @Input() public hasToBlockMenu: boolean = false;
  @Input() public isGpsDevice: boolean = false;
  @Input() public isListOnly: boolean = false;

  @Output() public refreshView: EventEmitter<void> = new EventEmitter();
  @Output() public openLayerControlItemMenu: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public openSearchResults: EventEmitter<any> = new EventEmitter();

  public aclPermissions = AclPermissions;

  private hasLayerGroupChangedSub: Subscription;
  private isReadytoDetectAtlasChangesSub: Subscription;
  private goToDefaultLayersSub: Subscription;
  private saveLayersViewSub: Subscription;

  constructor(
    public atlasService: AtlasService,
    private cd: ChangeDetectorRef,
    private atlasLocalStorageService: AtlasLocalStorageService
  ) {}

  public ngOnInit(): void {
    this.hasLayerGroupChangedSub = this.atlasService.hasLayerGroupChanged$.subscribe(() => {
      this.cd.detectChanges();
    });
    this.isReadytoDetectAtlasChangesSub = this.atlasService.isReadytoDetectAtlasChanges$
      .pipe(delay(300))
      .subscribe(() => {
        this.cd.detectChanges();
        if (this.atlasService.avoidLoadLocalStorageAssets) {
          return;
        }
        this.loadDefaultSavedAssets();
        this.saveLayersVisibility();
        this.goToDefaultLayers();
      });
  }

  public goToDefaultLayers(): void {
    this.goToDefaultLayersSub = this.goToDefaultLayers$.pipe().subscribe(() => {
      this.layerSavedInLocalStorage = [];
      this.atlasService.totalAtlasItems++;
      this.layerSavedInLocalStorage = this.atlasLocalStorageService.transformLocalStorageToJSON(
        'savedLayer'
      ) as LocalStorageLayer[];
      this.handleVisibilityLayers(this.layerSavedInLocalStorage);
    });
  }

  public saveLayersVisibility(): void {
    this.saveLayersViewSub = this.saveLayersView$.subscribe(() => {
      const savedLayer: LocalStorageLayer = {
        id: this.asset$.value?.id,
        isDisplaying: this.asset$.value?.isDisplaying
      };
      const savedLayerStored = this.atlasLocalStorageService.getItem('savedLayer');
      if (!savedLayerStored?.includes(JSON.stringify(savedLayer))) {
        this.atlasLocalStorageService.setItem('savedLayer', savedLayerStored + ';' + JSON.stringify(savedLayer));
      }
    });
  }

  public loadDefaultSavedAssets(): void {
    this.layerSavedInLocalStorage = this.atlasLocalStorageService.transformLocalStorageToJSON(
      'savedLayer'
    ) as LocalStorageLayer[];
    this.handleVisibilityLayers(this.layerSavedInLocalStorage);
  }

  public handleVisibilityLayers(layerSavedInLocalStorage: LocalStorageLayer[]): void {
    layerSavedInLocalStorage.forEach((item: LocalStorageLayer) => {
      const asset = this.asset$.value;
      if (!!asset && !!item && asset.id === item.id) {
        asset.isDisplaying = item.isDisplaying;
      }
    });
    this.refreshView.emit();
  }

  public toggleSelect(asset: AtlasAssetModel) {
    asset.isSelected = !asset.isSelected;

    if (asset.isSelected) {
      this.atlasService.addSelectedLayer(asset);
      return;
    }

    this.atlasService.removeSelectedLayer(asset.id);
  }

  public emitOpenLayerControlItemMenu(event: MouseEvent): void {
    this.openLayerControlItemMenu.emit(event);
  }

  public emitOpenSearchResults(asset: any): void {
    this.openSearchResults.emit(asset);
  }
}

/* eslint-disable no-magic-numbers */
import {Directive, Output, EventEmitter, HostListener} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Directive({
  selector: '[appMousemove]',
  standalone: true
})
export class MousemoveDirective {
  @Output() public throttledMousemove = new EventEmitter<{x: number; y: number}>();

  private distance: number = 0;
  private readonly delay = 0; // The delay time in milliseconds
  private readonly threshold = 10; // The minimum distance the mouse must move to trigger the event
  private move = new Subject<{x: number; y: number}>();
  private move$ = this.move.asObservable().pipe();

  private lastX = 0;
  private lastY = 0;

  @HostListener('mousemove', ['$event'])
  public onMousemove(event: MouseEvent): void {
    const deltaX = event.offsetX - this.lastX;
    const deltaY = event.offsetY - this.lastY;
    this.distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    if (this.distance >= this.threshold) {
      this.lastX = event.offsetX;
      this.lastY = event.offsetY;
      this.move.next({
        x: this.lastX,
        y: this.lastY
      });
    }
  }

  constructor() {
    this.move$.pipe(debounceTime(this.delay), distinctUntilChanged()).subscribe(event => {
      this.throttledMousemove.next(event);
    });
  }
}

import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Zone} from '@app/shared/annotation-shared/models/annotations.model';
import {environment} from 'environments/environment';
import {
  RuleItem,
  RuleAction,
  RuleAlert,
  RuleCondition,
  RuleMenuOption,
  RuleOperator,
  CoolDownPeriod,
  Rule,
  RuleAreaOfInterest,
  NestedRule
} from '../../models/rule.model';

@Component({
  selector: 'unleash-notifications-tab',
  templateUrl: './notifications-tab.component.html',
  styleUrls: ['./notifications-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsTabComponent {
  @Input() public rules: RuleItem[] = [];
  @Input() public selectedRule: RuleItem = {id: '', name: '', enabled: true};
  @Input() public menuOptions: RuleMenuOption[] = [];
  @Input() public form = new UntypedFormGroup({});
  @Input() public simpleForm = new UntypedFormGroup({});
  @Input() public editMode: boolean = false;
  @Input() public zones: RuleAreaOfInterest[] = [];
  @Input() public zoneList: Zone[] = [];
  @Input() public conditions: RuleCondition[] = [];
  @Input() public simpleConditions: RuleCondition[] = [];
  @Input() public operators: RuleOperator[] = [];
  @Input() public alerts: RuleAlert[] = [];
  @Input() public performAnalysisLoading: boolean = false;
  @Input() public coolDownPeriods: CoolDownPeriod[] = [];
  @Input() public hasToDisplayCoolDown: boolean = false;
  @Input() public hasToDisableSeverity: boolean = false;

  @Output() public addRule: EventEmitter<void> = new EventEmitter();
  @Output() public action: EventEmitter<RuleAction> = new EventEmitter();
  @Output() public zoneChange: EventEmitter<{formIndex: number; form: Rule}> = new EventEmitter();
  @Output() public conditionChange: EventEmitter<{formIndex: number; form: Rule}> = new EventEmitter();
  @Output() public alertChange: EventEmitter<string> = new EventEmitter();
  @Output() public editNameDone: EventEmitter<string> = new EventEmitter();
  @Output() public editName: EventEmitter<void> = new EventEmitter();
  @Output() public selectedRuleId: EventEmitter<string> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();
  @Output() public valueChange: EventEmitter<void> = new EventEmitter();
  @Output() public analyze: EventEmitter<void> = new EventEmitter();
  @Output() public delete: EventEmitter<{formIndex: number; form: Rule}> = new EventEmitter();
  @Output() public simpleModeChange: EventEmitter<{form: Rule; rules: NestedRule[]}> = new EventEmitter();
  @Output() public openHelp: EventEmitter<void> = new EventEmitter();
  @Output() public toggleAdvancedMode: EventEmitter<void> = new EventEmitter();

  public emitAddRule(): void {
    this.addRule.emit();
  }

  public emitAction(action: RuleAction): void {
    this.action.emit(action);
  }

  public emitZoneChange(formIndex: number): void {
    this.zoneChange.emit({formIndex, form: this.form.value});
  }

  public emitConditionChange(formIndex: number): void {
    this.conditionChange.emit({formIndex, form: this.form.value});
  }

  public emitAlertChange(alertId: string): void {
    this.alertChange.emit(alertId);
  }

  public emitEditNameDone(ruleName: string): void {
    this.editNameDone.emit(ruleName);
  }

  public emitEditName(): void {
    this.editName.emit();
  }

  public emitSelectedRuleId(selectedRuleId: string): void {
    this.selectedRuleId.emit(selectedRuleId);
  }

  public emitBack(): void {
    this.back.emit();
  }

  public emitValueChange(): void {
    this.valueChange.emit(this.form.value);
  }

  public emitAnalyze(): void {
    this.analyze.emit();
  }

  public emitDelete(formIndex: number): void {
    this.delete.emit({formIndex, form: this.form.value});
  }

  public emitAdvancedModeChange(): void {
    if (this.simpleForm.valid) {
      this.valueChange.emit(this.form.value);
    } else {
      console.warn('AdvancedModeChange form error');
    }
  }

  public emitSimpleModeChange(simpleModeChange: {rules: NestedRule[]}): void {
    this.simpleModeChange.emit({form: this.form.value, rules: simpleModeChange.rules});
  }

  public emitOpenHelp(): void {
    this.openHelp.emit();
  }

  public emitToggleAdvancedMode(): void {
    this.toggleAdvancedMode.emit();
  }
}

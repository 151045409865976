<h1 mat-dialog-title>{{ 'atlas.convertLayerToMission.cannotConvertLayerToMission' | translate }}</h1>
<div mat-dialog-content>
  <p
    class="cannot-convert-layer-to-mission__content"
    [innerHTML]="'atlas.convertLayerToMission.cannotConvertLayerToMissionContent' | translate: {value: data.name}"
  ></p>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button color="primary" [mat-dialog-close]="false">{{ 'common.ok' | translate }}</button>
</div>

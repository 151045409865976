/* eslint-disable rxjs/finnish */
import {Injectable} from '@angular/core';
import {LimitModel} from '@app/core/models/api/extension.model';
import {UserModel} from '@app/core/models/api/user-model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';

import {Observable} from 'rxjs';
import {first, flatMap, map, share, shareReplay, switchMap} from 'rxjs/operators';
import {ApiGateway} from './api-gateway.service';
import {ApiAddonListParams} from '@app/store/addon/models/api-list-params';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {cloneDeep, orderBy} from 'lodash';
import {Addon} from '@app/store/addon/models/addon';
import {UserService} from './user.service';

@Injectable({providedIn: 'root'})
export class AddonService {
  public readonly currentAddonsWithConfig: Observable<Addon[]>;

  private dataStore: {
    addons: Addon[];
    devAddons: Addon[];
    userLimit?: LimitModel;
  };

  constructor(
    private apiGateway: ApiGateway,
    // TODO: avoid using store in services, use store on effects level
    private userService: UserService,
    private http: HttpClient
  ) {}

  public startTrialForUser(addonId: string): Observable<Addon> {
    return this.userService.user$.pipe(
      first(),
      map((user: UserModel) => user.id),
      switchMap((userId: string) => this.apiGateway.post(`user/${encodeURIComponent(userId)}/trial/${addonId}`, {}, {}))
    );
  }

  public updateDevAddon(addonObject: Addon): Observable<void> {
    const {id, ...updateAddonObject} = addonObject;
    return this.apiGateway.patch(`addon/${id}`, {}, updateAddonObject).pipe(share());
  }

  public createDevAddon(addonObject: Addon): Observable<void> {
    return this.apiGateway.post(`addon`, {}, addonObject).pipe(share());
  }

  public lookupModelName(modelId: string): string | null {
    if (!modelId) {
      return null;
    }
    // todo not ideal, need to prefill this in ua-api
    const addonModel = this.dataStore.devAddons.concat(this.dataStore.addons).find((a: Addon) => a.id === modelId);
    if (!addonModel) {
      // console.warn('User has no model installed with id: ' + modelId);
    }
    return (!!addonModel && addonModel.name) || null;
  }

  public isModelRunning(device: UserDeviceJoined, modelId: Addon['id']): boolean {
    const isRaw = modelId === 'RAW_STREAM';
    const isModelAlreadyStreaming = !!device?.runningModels && device?.runningModels.indexOf(modelId) !== -1;
    return isModelAlreadyStreaming || isRaw;
  }

  public isModelScheduled(device: UserDeviceJoined, modelId: Addon['id']): boolean {
    return (
      (!!device.waitingModels && !!device.waitingModels.find((waitingId: string) => waitingId === modelId)) ||
      !!device.runningModels.find((runningId: string) => runningId === modelId)
    );
  }

  public scheduleModel(device: UserDeviceJoined, startingModelId: Addon['id']): Observable<void> {
    if (this.isModelScheduled(device, startingModelId)) {
      console.info('Stream model already in progress', startingModelId);
      return;
    }
    if (!device.waitingModels) {
      device.waitingModels = [];
    }
    this.userService.updateDeviceCache({...device, waitingModels: [...device.waitingModels, startingModelId]});
  }

  public getLogoPresignedURL(contentType: string, instanceId: string, filename: string): Observable<string> {
    return this.apiGateway.post('account/uploadLogo', {}, {contentType, instanceId, filename});
  }

  // TODO: optimistic UI processing move to effects level
  public setUserAddon(addonId: string): Observable<void> {
    return this.apiGateway.post('addon/activate', {}, {addonId}).pipe(
      // tap(() => {
      // optimistic UI - add to datastore without fetching from api
      // this.dataStore.userAddons.push({userId, addonId});
      // this._userAddons.next([...this.dataStore.userAddons]);
      // }),
      shareReplay(1)
    );
  }

  // TODO: optimistic UI processing move to effects level
  public removeUserAddon(addonId: string): Observable<void> {
    return this.apiGateway.delete('addon/deactivate', {}, {addonId}).pipe(
      // tap(() => {
      // optimistic UI - add to datastore without fetching from api
      // this.dataStore.userAddons = this.dataStore.userAddons.filter(uA => {
      //   return uA.addonId !== addonId;
      // });
      // this._userAddons.next([...this.dataStore.userAddons]);
      // }),
      shareReplay(1)
    );
  }

  /**
   * Fetches addon list
   *
   * { enabled: 1 }
   * List addons that were activated only by currently logged in user (in other words, addons from user-addons relation table)
   *
   * { mine: 1 }
   * List addons where owner is currently logged in user
   *
   * no parameters
   * List all published addons to AI App store together with information from user-addons table.
   * Addons from user-addons table will have enabled=true
   */
  public loadList(params?: ApiAddonListParams): Observable<Addon[]> {
    return this.apiGateway
      .get('addon', params, undefined)
      .pipe(map((data: Addon[]) => orderBy(data, ['createdAt'], ['desc'])));
  }

  public loadDetails(id: string): Observable<Addon> {
    return this.apiGateway.get(`addon/${id}`, {}, undefined);
  }

  public add(data: Addon): Observable<Addon> {
    return this.apiGateway.post(`addon`, {}, data, undefined);
  }

  public update(id: string, data: Addon): Observable<Addon> {
    const addon = cloneDeep(data);
    // TODO handle image properly
    addon.icon = 'path';
    addon.screenshots = [
      {
        isCover: true,
        url: 'path'
      }
    ];
    // addon.icon = decodeURI(addon.icon);
    // if (addon.screenshots?.length > 0) {
    //   addon.screenshots.forEach(screenshot => {
    //     screenshot.url = decodeURI(screenshot.url);
    //   });
    // }
    return this.apiGateway.patch(`addon/${id}`, {}, addon).pipe(map((res: Addon) => ({...res, id})));
  }

  public getImagePresignedURL(contentType: string, instanceId: string, filename: string): Observable<string> {
    return this.apiGateway.post('account/uploadImage', {}, {contentType, instanceId, filename}, undefined);
  }

  public uploadImage(file: File, instanceId: string): Observable<string> {
    let presignedUrl;
    return this.getImagePresignedURL(file.type, instanceId, file.name).pipe(
      flatMap((url: string) => {
        presignedUrl = url;
        return this.http.put(url, file);
      }),
      map(() => {
        const url = new URL(presignedUrl);
        const s3Path = url.pathname.slice(1);
        return s3Path;
      })
    );
  }

  public remove(id: string): Observable<void> {
    return this.apiGateway.delete(`addon/${id}`, {}, null);
  }

  public publish(id: string, data: Addon): Observable<HttpResponse<Addon>> {
    return this.apiGateway.post(`addon/${id}/publish`, {}, data, undefined).pipe(
      map((res: Addon) => ({...res, id})),
      map((res: Addon) => new HttpResponse<Addon>({body: res}))
    );
  }

  public unPublish(id: string): Observable<Addon> {
    return this.apiGateway.post(`addon/${id}/unpublish`, {}, null, undefined);
  }

  public submitToReview(id: string): Observable<Addon> {
    return this.apiGateway.post(`addon/${id}/submitToReview`, {}, null, undefined);
  }

  public withdrawFromReview(id: string): Observable<Addon> {
    return this.apiGateway.post(`addon/${id}/withdrawFromReview`, {}, null, undefined);
  }
}

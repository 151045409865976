import {LayoutModule} from '@angular/cdk/layout';
import {NgModule} from '@angular/core';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthEffects} from '@app/auth/state/auth.effects';
import {SharedPipesModule} from '@app/shared/pipes/shared-pipes.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {IsUserAuthNoNavigateGuard} from '@app/auth/guards/is-auth-no-navigate.guard';
import {IsUserAuthOnboardingGuard} from '@app/auth/guards/is-auth-onboarding.guard';
import {IsUserAuthGuard} from '@app/auth/guards/is-auth.guard';
import {IsNotAwsUserGuard} from '@app/auth/guards/is-not-aws-user-guard.service';
import {LiveModule} from '@app/live/live.module';
import {GoogleDriveDialogModule} from '@app/shared/google-drive-dialog/google-drive-dialog.module';
import {SlideshowModule} from '@app/shared/slideshow/slideshow.module';
import {UnleashMaterialSharedModule} from '@app/shared/unleash-material/unleash-material-shared.module';
import {UploadFilesMenuItemModule} from '@app/shared/upload-files-menu-item/upload-files-menu-item.module';
import {metaReducers, reducers} from '@app/store/app.state';
import {RouterEffects} from '@app/store/router/router.effects';
import {AuthModule} from '../auth/auth.module';
import {PlansModule} from '../plans/plans.module';
import {ProfileModule} from '../profile/profile.module';
import {UnleashMaterialModule} from '../theme/unleash-material.module';
import {BannerComponent} from './components/banner/banner.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {FooterNavbarComponent} from './components/navigation/footer-navbar/footer-navbar.component';
import {HeaderNavbarComponent} from './components/navigation/header-navbar/header-navbar.component';
import {SidebarMenuItemAccountComponent} from './components/navigation/sidebar/sidebar-menu-item-account/sidebar-menu-item-account.component';
import {SidebarMenuItemLinkComponent} from './components/navigation/sidebar/sidebar-menu-item-link/sidebar-menu-item-link.component';
import {SidebarMenuItemNewtabComponent} from './components/navigation/sidebar/sidebar-menu-item-newtab/sidebar-menu-item-newtab.component';
import {SidebarComponent} from './components/navigation/sidebar/sidebar.component';
import {PageNotFoundComponent} from './components/not-found/notFound.component';
import {NotificationCardComponent} from './components/notification-card/notification-card.component';
import {NotificationSidebarComponent} from './components/notification-sidebar/notification-sidebar.component';
import {HomeComponent} from './components/public-home/home.component';
import {SecureHomeComponent} from './components/secure-home/home.component';
import {WelcomePageComponent} from './components/welcome-page/welcome-page.component';
import {CoreRoutingModule} from './core-routing.module';
import {MatIconRegistryModule} from './modules/icon-register/icon-register.module';
import {VideogularModule} from './modules/videogular/videogular.module';
import {NotificationsModule} from './notifications/notifications.module';
import {NetworkStatusService} from './services/network-status.service';
import {AddonEffects} from '@app/core/store/addon/addon.effects';
import {SidenavMenuEffects} from '@app/core/store/sidenav-menu/sidenav-menu.effects';
import {UserEffects} from '@app/store/user/user.effects';
import {AclEffects} from '@app/core/store/acl/store/acl.effects';
import {RouterLinkUrlPipe} from '@app/shared/pipes/router-link-url.pipe';
import {RouterLinkQueryParamsPipe} from '@app/shared/pipes/router-link-query-params.pipe';
import {TeamSwitcherComponent} from '@app/profile/components/teams/team-switcher/team-switcher.component';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {SidebarMenuItemTeamComponent} from './components/navigation/sidebar/sidebar-menu-item-team/sidebar-menu-item-team.component';
import {S3ImportDialogModule} from '@app/shared/s3-import-dialog/s3-import-dialog.module';
import {S3ExportDialogModule} from '@app/shared/s3-export-dialog/s3-export-dialog.module';

// feature non-lazy loaded modules
const storeModules = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true
    }
  }),
  EffectsModule.forRoot([AuthEffects, RouterEffects, AddonEffects, SidenavMenuEffects, UserEffects, AclEffects])
];

@NgModule({
  declarations: [
    DashboardComponent,
    HeaderNavbarComponent,
    SidebarComponent,
    SidebarMenuItemLinkComponent,
    SidebarMenuItemNewtabComponent,
    FooterNavbarComponent,
    WelcomePageComponent,
    PageNotFoundComponent,
    SecureHomeComponent,
    HomeComponent,
    BannerComponent,
    NotificationSidebarComponent,
    NotificationCardComponent,
    SidebarMenuItemAccountComponent,
    SidebarMenuItemTeamComponent
  ],
  imports: [
    ...storeModules,
    CoreRoutingModule,
    SlideshowModule,
    LaddaModule,
    BrowserAnimationsModule,
    LayoutModule,
    AuthModule,
    PlansModule,
    ProfileModule,
    VideogularModule,
    NotificationsModule,
    LiveModule,
    UnleashMaterialModule,
    ReactiveFormsModule,
    FlexModule,
    // AmplifyUIAngularModule,
    UnleashMaterialSharedModule,
    UploadFilesMenuItemModule,
    GoogleDriveDialogModule,
    S3ImportDialogModule,
    S3ExportDialogModule,
    HammerModule,
    MatIconRegistryModule,
    SharedPipesModule,
    RouterLinkUrlPipe,
    RouterLinkQueryParamsPipe,
    TeamSwitcherComponent,
    // ThemePickerModule,
    UnleashLoaderModule
  ],
  exports: [HeaderNavbarComponent, SidebarComponent, FooterNavbarComponent],
  providers: [
    NetworkStatusService,
    IsUserAuthNoNavigateGuard,
    IsUserAuthGuard,
    IsUserAuthOnboardingGuard,
    IsNotAwsUserGuard
  ]
})
export class CoreModule {}

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NumbersOnlyDirectiveModule} from '@app/shared/numbers-only-directive/numbers-only.module';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'unleash-add-location-dialog',
  templateUrl: './add-location-dialog.component.html',
  styleUrls: ['./add-location-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatDialogModule,
    NumbersOnlyDirectiveModule,
    TranslateModule
  ]
})
export class AddLocationDialogComponent implements OnInit {
  public form: FormGroup = this.fb.group({
    lat: ['', [Validators.required, Validators.pattern(/^(-?[0-9]*|-?[0-9]*\.[0-9]*)$/)]],
    lng: ['', [Validators.required, Validators.pattern(/^(-?[0-9]*|-?[0-9]*\.[0-9]*)$/)]]
  });
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {lat: number; lng: number; index: number}
  ) {}

  public ngOnInit(): void {
    if (this.data.lat !== null && this.data.lng !== null) {
      this.form.controls.lat.setValue(this.data.lat);
      this.form.controls.lng.setValue(this.data.lng);
    }
  }
  public sendCoordinates() {
    this.dialogRef.close(this.form.value);
  }
}

import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter} from '@angular/core';
import {TeamAction, TeamTable, TeamTableActions} from '@app/profile/models/team.model';

@Component({
  selector: 'unleash-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamsComponent {
  @Input() public teams: TeamTable[] = [];
  @Input() public menuItems: TeamTableActions[] = [];
  @Output() public addTeam: EventEmitter<void> = new EventEmitter();
  @Output() public searchTeam: EventEmitter<string> = new EventEmitter();
  @Output() public team: EventEmitter<TeamTable> = new EventEmitter();
  @Output() public action: EventEmitter<TeamAction> = new EventEmitter();

  public performSearch(search: string) {
    this.searchTeam.emit(search);
  }

  public emitAddTeam(): void {
    this.addTeam.emit();
  }

  public emitTeam(team: TeamTable): void {
    this.team.emit(team);
  }

  public emitAction(action: TeamAction): void {
    this.action.emit(action);
  }
}

<h1 mat-dialog-title>{{ 'atlas.convertLayerToMission.convertLayerToMission' | translate }}</h1>
<div mat-dialog-content [formGroup]="form">
  <mat-label class="label">{{ 'atlas.convertLayerToMission.layer' | translate }}</mat-label>
  <p>
    {{ this.data.name }}
  </p>

  <mat-label class="label">{{ 'atlas.convertLayerToMission.settings' | translate }}</mat-label>
  <mat-form-field class="settings" appearance="fill">
    <mat-label>{{ 'atlas.convertLayerToMission.smartInspectMissionProfile' | translate }}</mat-label>
    <mat-select formControlName="smartInspectMissionProfile" data-test-id="smartInspectMissionProfile">
      <mat-option
        *ngFor="let smartInspectMissionProfile of smartInspectMissionProfiles"
        name="martInspectMissionProfile"
        [value]="smartInspectMissionProfile.key"
      >
        {{ smartInspectMissionProfile.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button color="primary" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!form.value.smartInspectMissionProfile || isLoading$ | async"
    [appLadda]="isLoading$ | async"
    (click)="convertAssetToMission()"
    data-test-id="convert-asset-to-mission-button"
  >
    {{ 'atlas.convertLayerToMission.convertMission' | translate }}
  </button>
</div>

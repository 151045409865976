import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RuleItemComponent} from './components/rule-item/rule-item.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {RuleNameComponent} from './components/rule-name/rule-name.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InputSizeModule} from '../input-size/input-size.module';
import {RuleRowComponent} from './components/rule-row/rule-row.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {RuleSettingsComponent} from './components/rule-settings/rule-settings.component';
import {RuleTabComponent} from './components/rule-tab/rule-tab.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {RuleNotificationsSidebarComponent} from './components/rule-notifications-sidebar/rule-notifications-sidebar.component';
import {NotificationsTabComponent} from './components/notifications-tab/notifications-tab.component';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {AlertDisplayNamePipe} from './pipes/alert-display-name.pipe';
import {AlertDisplayIconPipe} from './pipes/alert-display-icon.pipe';
import {LoadingSpinnerModule} from '../loading-spinner/loading-spinner';
import {NotificationChannelsTabComponent} from './components/notification-channels-tab/notification-channels-tab.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {UnleashMaterialSharedModule} from '../unleash-material/unleash-material-shared.module';
import {LogicOperatorButtonComponent} from './components/logic-operator-button/logic-operator-button.component';
import {SimpleRuleRowComponent} from './components/simple-rule-row/simple-rule-row.component';
import {TranslateModule} from '@ngx-translate/core';
import { NumbersOnlyDirectiveModule } from '../numbers-only-directive/numbers-only.module';

@NgModule({
  declarations: [
    RuleItemComponent,
    RuleNameComponent,
    RuleRowComponent,
    RuleSettingsComponent,
    RuleTabComponent,
    RuleNotificationsSidebarComponent,
    NotificationsTabComponent,
    AlertDisplayNamePipe,
    AlertDisplayIconPipe,
    NotificationChannelsTabComponent,
    LogicOperatorButtonComponent,
    SimpleRuleRowComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    InputSizeModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    LoadingSpinnerModule,
    MatCheckboxModule,
    UnleashMaterialSharedModule,
    TranslateModule,
    NumbersOnlyDirectiveModule
  ],
  exports: [
    RuleItemComponent,
    RuleNameComponent,
    RuleRowComponent,
    RuleSettingsComponent,
    RuleTabComponent,
    RuleNotificationsSidebarComponent,
    NotificationsTabComponent,
    AlertDisplayNamePipe,
    AlertDisplayIconPipe,
    NotificationChannelsTabComponent,
    LogicOperatorButtonComponent,
    SimpleRuleRowComponent
  ]
})
export class AnalysisConfigurationModule {}

<section class="calibration">
  <div class="calibration__header">
    <span class="calibration__image-container">
      <img
        class="calibration__image"
        src="/assets/icons/manager-zones/calibration/u_marker_on_map.svg"
        alt="no calibration image"
      />
    </span>
    <span class="calibration__title">{{ 'calibration.noCalibration' | translate }}</span>
  </div>
  <div class="calibration__content">
    <p class="calibration__description">
      {{ 'calibration.noCalibrationMessage' | translate }}
    </p>
    <button mat-button color="primary" class="calibration__button" (click)="emitOpenCalibrateByPerspective()">
      <mat-icon svgIcon="u_calibrate_by_perspective"></mat-icon> {{ 'calibration.calibrateByPerspective' | translate }}
    </button>
    <button mat-button color="primary" class="calibration__button" (click)="emitOpenCalibrateBySceneMapping()">
      <mat-icon svgIcon="u_calibrate_by_scene_mapping"></mat-icon>
      {{ 'calibration.calibrateBySceneMapping' | translate }}
    </button>
  </div>
</section>

<div class="actions">
  <button mat-button color="primary" class="calibration__action-button">{{ 'calibration.done' | translate }}</button>
  <button mat-raised-button color="primary" class="calibration__action-button">
    {{ 'calibration.analyze' | translate }}
  </button>
</div>

import {Addon} from '@app/store/addon/models/addon';
import {Subject} from 'rxjs';
import {ZoneConfig} from '@app/shared/annotation-shared/models/annotations.model';
import {LabelColorName} from '@app/shared/image-annotation-shared/models/colors';
import {Stream} from './stream.model';

export interface UserDevice {
  userId: string;
  deviceId: string;
}

export interface Device {
  id: string;
  owner?: string;
  name: string;
  description?: string;
  logo?: string;
  hwstats?: string;
  usage?: string;
  price?: string;
  make?: string;
  model?: string;
  software?: string;
  gps?: boolean;
  lat?: number;
  lng?: number;
  zonesConfig?: ZoneConfig;
  analyticsConfig?: AnalyticsConfig;
  isFavourite?: boolean;
  sourceUrl?: string;
  sourceUrlSet?: boolean;
  destUrl?: string;
  destUrlSet?: boolean;
  streamVR?: boolean;
  agoraToken?: string;
  notificationsConfig?: any;
  skyChargeDeviceUUID?: string;
  lastSeen?: number;
  automation?: DeviceAutomation;
}

export interface DeviceAutomation {
  batteryPowered: {
    stopAfter?: number;
  };
}

export interface DeviceAISettings {
  selectedModel?: Addon['id'];
  waitingModels?: Addon['id'][];
  runningModels?: Addon['id'][];
  statsRestreamingFrom?: string;
  statsRestreamingTo?: string;
}

export interface UserDeviceJoined extends UserDevice, Device, DeviceAISettings, DevicePresentable {
  updatedAt?: number;
}

export interface UserDevices {
  [key: string]: UserDeviceJoined;
}

export interface AnalyticsConfig {
  [key: string]: {
    analytics: {
      [key: string]: {
        calibration_points: {x: number; y: number; lat?: number; lng?: number; x2?: number; y2?: number}[];
        color: LabelColorName;
      };
    };
  };
}

export enum StreamPlayer {
  DEFAULT = 'default',
  NONE = 'none',
  WEBRTC = 'webrtc'
}

export interface DevicePresentable {
  player?: StreamPlayer;
  isDisplayed?: boolean;
  className?: string;
  isLive?: boolean;
  isRestreamingFrom?: boolean;
  isRestreamingTo?: boolean;
  statsRestreamingTo$?: Subject<string>;
  statsRestreamingFrom$?: Subject<string>;
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AssetsFilterService} from '@app/atlas/services/assets-filter.service';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, firstValueFrom, map, take} from 'rxjs';

@Component({
  selector: 'unleash-add-to-group',
  templateUrl: './add-to-group.component.html',
  styleUrls: ['./add-to-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToGroupComponent {
  public groups$: Observable<GroupList[]>;
  public selectedGroupIndex: number = -1;
  public hasToDisplaySpinner: boolean = false;
  public isNewGroup: boolean = false;
  public form: FormGroup = this.fb.group({
    name: new FormControl('', Validators.required)
  });

  constructor(
    public atlasService: AtlasService,
    public assetsFilterService: AssetsFilterService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private unleashAnalystService: UnleashAnalyticsService,
    private dialogRef: MatDialogRef<AddToGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {assets: AtlasAssetModel[]}
  ) {
    const selectedGroupLayers = this.atlasService.selectedLayers
      .filter(asset => !!asset.groupName)
      .map(asset => asset.groupName);

    const hasToDisplayParent = this.atlasService.selectedLayers.some(layer => layer.groupName);
    this.groups$ = this.assetsFilterService.assets$.pipe(
      map(assets =>
        assets
          .filter(asset => !!asset.groupName)
          .map(asset => ({name: asset.groupName, customColorIndex: asset.customColorIndex, isSelected: false}))
          .filter(groupNameAssets => !selectedGroupLayers.includes(groupNameAssets.name))
      ),
      map(assets =>
        assets.reduce((accumulator, item) => {
          const existingItem = accumulator.find(existing => existing.name === item.name);
          if (existingItem) {
            existingItem.customColorIndex =
              existingItem.customColorIndex === item.customColorIndex ? item.customColorIndex : -1;
          } else {
            accumulator.push(item);
          }

          return accumulator;
        }, [])
      ),
      map(assets =>
        hasToDisplayParent ? [{name: 'Parent', customColorIndex: -1, isSelected: false}, ...assets] : assets
      )
    );
  }
  public selectLayer(group: GroupList, index: number) {
    this.selectedGroupIndex = index;
    this.form.controls.name.setValue(group.name);
  }

  public async addToGroup() {
    const name = this.form.controls.name.value;
    const groupName: string = name === 'Parent' ? '' : name;
    this.hasToDisplaySpinner = true;
    if (this.data.assets.length === 1) {
      this.updateAsset(groupName, name);
      return;
    }
    this.updateAssets(groupName);
  }

  public setIsNewGroup(isNewGroup): void {
    this.isNewGroup = isNewGroup;
    this.selectedGroupIndex = -1;
    this.form.controls.name.setValue('');
    this.form.controls.name.markAsUntouched();
  }

  private async updateAsset(groupName: string, name: string) {
    await firstValueFrom(this.atlasService.updateAsset(this.data.assets[0], {groupName}));
    this.translateService
      .get('atlas.control.addToGroupSuccess', {
        name: this.data.assets[0].name,
        groupName: name
      })
      .pipe(take(1))
      .subscribe(message => {
        this.atlasService.clearSelectedLayers();
        this.snackBar.open(message, null, {duration: 5000});
      });
    this.closeDialog();
  }

  private async updateAssets(groupName: string) {
    await firstValueFrom(this.atlasService.updateAssets(this.data.assets, {groupName}));
    this.translateService
      .get('atlas.control.addSetToGroupSuccess', {
        totalAssets: this.data.assets.length
      })
      .pipe(take(1))
      .subscribe(message => {
        this.atlasService.clearSelectedLayers();
        this.snackBar.open(message, null, {duration: 5000});
      });
    this.closeDialog();
  }

  private closeDialog(): void {
    this.unleashAnalystService.logEvent(EVENTS.ATLAS_LAYER_ACTION, {
      type: 'Group'
    });
    this.dialogRef.close();
  }
}

export interface GroupList {
  name: string;
  customColorIndex: string;
  isSelected: boolean;
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {MatDialog} from '@angular/material/dialog';
import {RenameLayersDialogComponent} from '../components/rename-layers-dialog/rename-layers-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {filter} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';

@Directive({
  selector: '[ulRenameAsset]',
  standalone: true
})
export class RenameAssetDirective {
  @Input('ulRenameAsset') public asset: AtlasAssetModel;

  constructor(
    private atlasService: AtlasService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private unleashAnalytics: UnleashAnalyticsService
  ) {}

  @HostListener('click', ['$event'])
  public renameAsset(): void {
    const asset = this.asset;
    const isRenamed = (name: AtlasAssetModel['name']): boolean => !!name && asset.name !== name;
    this.dialog
      .open(RenameLayersDialogComponent, {
        ...STANDARD_DIALOG_CONFIG,
        data: {name: asset.name},
        width: '80vw',
        maxWidth: '800px'
      })
      .afterClosed()
      .pipe(filter(isRenamed))
      .subscribe((name: AtlasAssetModel['name']): void => {
        this.atlasService.updateAsset(asset, {name}).subscribe(() => {
          this.translateService.get('atlas.control.renameSuccessName', {value: asset.name}).subscribe(message => {
            this.snackBar.open(message, null, {duration: 5000});
          });
          this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {
            type: 'Rename'
          });
        });
      });
  }
}

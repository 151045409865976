import {Location} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RouterFacadeStoreService} from '@app/core/services/api/router-store-facade.service';
import {HistoryService} from '@app/core/services/history.service';
import {take} from 'rxjs';

@Component({
  selector: 'app-nav-back',
  templateUrl: './nav-back.component.html',
  styleUrls: ['./nav-back.component.scss']
})
export class NavBackComponent implements OnInit {
  @Input() public text: string;
  @Input() public defaultRoute: string;
  @Input() public hasToUseStoredNavigation: boolean;

  constructor(
    private location: Location,
    private router: Router,
    private historyService: HistoryService,
    private routerFacadeStoreService: RouterFacadeStoreService
  ) {}

  public ngOnInit() {
    this.historyService.setNewUrl(this.router.url);
  }

  public goBack() {
    if (this.hasToUseStoredNavigation) {
      this.routerFacadeStoreService
        .selectPreviousUrl()
        .pipe(take(1))
        .subscribe(prevUrl => {
          this.router.navigateByUrl(prevUrl);
        });
      return;
    }
    if (this.historyService.shouldBackToDefaultRoute$.value && !!this.defaultRoute) {
      this.historyService.navigationExtras
        ? this.router.navigate([this.defaultRoute], this.historyService.navigationExtras)
        : this.router.navigate([this.defaultRoute]);
      this.historyService.navigationExtras = null;
    } else {
      this.location.back();
    }
  }
}

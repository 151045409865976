<mat-toolbar class="app-footer" color="primary">
  <mat-nav-list>
    <mat-list-item
      *ngFor="let menuItem of menuItems"
      (click)="closeMobileNav()"
      [routerLink]="menuItem.routerLink"
      routerLinkActive="active"
      [class.disable-highlight]="isSidenavExpanded | async"
    >
      <div class="img-wrapper">
        <mat-icon class="menu-item-img" [svgIcon]="menuItem.imagePath"></mat-icon>
      </div>
      <div>
        <p class="menu-item-label">{{ menuItem.label | translate }}</p>
      </div>
    </mat-list-item>
    <mat-list-item (click)="toggleMenu()" [class.active]="isSidenavExpanded | async">
      <div class="img-wrapper">
        <mat-icon class="more-options-icon">more_vert</mat-icon>
      </div>
      <div>
        <p class="menu-item-label">{{ 'core.sidebar.menu.more' | translate }}</p>
      </div>
    </mat-list-item>
  </mat-nav-list>
</mat-toolbar>
<app-sidebar
  class="mobile-navbar"
  [hasMobileHeaderLabel]="hasMobileHeaderLabel"
  [ngClass]="(hasToDisplayMobileMenu$ | async) ? 'mobile-navbar__visible' : 'mobile-navbar__hidden'"
></app-sidebar>

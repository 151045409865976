import {NgIfContext} from '@angular/common';
import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AclService} from '@app/core/services/acl.service';
import {filter, take} from 'rxjs';

@Directive({
  selector: '[uaHasPermission]',
  standalone: true
})
export class AclDirective<T> implements OnInit {
  @Input() public uaHasPermission: string | string[];
  @Input() public uaHasPermissionElse: TemplateRef<NgIfContext<T>> | null;
  @Input() public uaHasPermissionCondition: boolean | null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private aclService: AclService
  ) {}

  public ngOnInit() {
    let condition = true;
    this.aclService.hasSetupPermission$
      .pipe(
        filter(hasSetupPermission => hasSetupPermission),
        take(1)
      )
      .subscribe(() => {
        let permissions = [];
        if (typeof this.uaHasPermission === 'string') {
          permissions.push(this.uaHasPermission);
        } else {
          permissions = this.uaHasPermission;
        }

        condition = Boolean(this.uaHasPermissionCondition);

        if (permissions.some(permission => this.aclService.hasPermission(permission)) || condition) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          return;
        }
        this.viewContainer.clear();
        if (this.uaHasPermissionElse) {
          this.viewContainer.createEmbeddedView(this.uaHasPermissionElse);
        }
      });
  }
}

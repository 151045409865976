<table mat-table #table [dataSource]="dataSource">
  <ng-container matColumnDef="team">
    <th mat-header-cell *matHeaderCellDef>{{ 'profile.users.team' | translate }}</th>
    <td mat-cell *matCellDef="let element" (click)="emitUserTeam(element)">{{ element.name }}</td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>{{ 'profile.users.role' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <unleash-user-role-picker
        [role]="element.role"
        [roles]="roles"
        [userId]="selectedUser.userId"
        (editUserTeamRole)="emitUserTeamRole($event)"
      ></unleash-user-role-picker>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button data-test-id="menu-actions-button" [matMenuTriggerFor]="menu">
        <mat-icon color="grey-5">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          [attr.data-test-id]="'actions-' + menuItem.name + '-button'"
          *ngFor="let menuItem of menuItems"
          mat-menu-item
          (click)="emitAction(menuItem.type, element.userId)"
        >
          {{ menuItem.name }}
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {filter, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserDeviceJoined} from '../../core/models/api/user-device.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ManagerZonesStoreFacadeService} from '@app/shared/manage-zones-dialog/services/manager-zones-store-facade.service';
import {BitrateOptions, IMediaSubscriptions, VgApiService, VgMediaDirective} from '@videogular/ngx-videogular/core';
import {BrowserSettingsService} from '@app/core/services/browser-settings.service';
import {VgHlsDirective} from '@videogular/ngx-videogular/streaming';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {Addon} from '@app/store/addon/models/addon';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {interval, Subscription} from 'rxjs';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-live-video-player',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './live-video-player.component.html',
  styleUrls: ['./live-video-player.component.scss']
})
export class LiveVideoPlayerComponent implements OnInit {
  //TODO use reactive forms to handle bitrates
  @Input() public isSelected = false;
  @Input() public model: Addon;
  hideControlsTimeout: NodeJS.Timeout;
  @Input('device')
  private set setupDevice(device: UserDeviceJoined) {
    if (device) {
      this.device = device;
      this.setHlsPlaylistUrl();
    }
  }

  @ViewChild('vgHls') public vgHls: VgHlsDirective;
  @ViewChild('media', {static: false}) public media: ElementRef;
  @ViewChild(VgMediaDirective, {static: false, read: VgMediaDirective}) public mediaRef: VgMediaDirective;
  @ViewChild('vgControls') public vgControls: any;

  public device: UserDeviceJoined;
  public isBuffering: boolean = true;
  public hlsPlaylistUrl: string = null;
  public api: VgApiService;
  public hlsBitrates: BitrateOptions[] = [];
  public hasToShowControls: boolean = false;
  public hasToShowAudioControl: boolean = false;
  public minBitrate: BitrateOptions;
  public isSafari: boolean = this.browserSettingsService.isSafari();
  public playTooltip: string = 'Pause';
  public soundTooltip: string = 'Mute';

  private readonly bufferLimit: number = 8000;
  private readonly hideControlsTimer: number = 5000;
  private emptyBitrate: BitrateOptions = {
    qualityIndex: 0,
    width: 0,
    height: 0,
    bitrate: 0,
    mediaType: 'video',
    scanType: 'AUTO',
    checked: true
  } as BitrateOptions;
  private bufferFristAttemptSubscription: Subscription;
  private waitingBufferSubscription: Subscription;
  private dialogSubscription: Subscription;
  private volumeChangeSubscription: Subscription;
  private triggerSnapshotSub: Subscription;
  private canCloseControls: boolean = true;

  //TODOS -> store

  constructor(
    private browserSettingsService: BrowserSettingsService,
    private managerZonesStoreFacadeService: ManagerZonesStoreFacadeService,
    private unleashAnalytics: UnleashAnalyticsService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) {
    this.triggerSnapshotSub = this.managerZonesStoreFacadeService.takeSnapshot$.subscribe(deviceId => {
      if (this.device?.id === deviceId) {
        this.captureSnapshot();
      }
    });
  }

  public ngOnInit(): void {}

  public hideControls(): void {
    if (this.hideControlsTimeout) {
      clearTimeout(this.hideControlsTimeout);
    }

    this.hideControlsTimeout = setTimeout(() => {
      if (this.canCloseControls && this.vgControls) {
        this.vgControls.hideControls = true;
        this.cd.detectChanges();
      }
    }, this.hideControlsTimer);
  }

  public setCanCloseControls(canCloseControls: boolean): void {
    this.canCloseControls = canCloseControls;
    this.vgControls.hideControls = false;
    this.cd.detectChanges();
  }

  public onPlayerReady(api: VgApiService): void {
    this.api = api;
    this.api.volume = 0;

    const videoSubscriptions = this.api.getDefaultMedia().subscriptions;
    this.watchLoadedMetadata(videoSubscriptions);
    this.watchLoadedData(videoSubscriptions);
    this.watchWaiting(videoSubscriptions);
    this.watchPlaying(videoSubscriptions);
    this.watchPause(videoSubscriptions);
    this.watchError(videoSubscriptions);
    this.watchStalled(videoSubscriptions);
    this.watchVolumeChange(videoSubscriptions);
  }

  private watchVolumeChange(videoSubscriptions: IMediaSubscriptions) {
    this.volumeChangeSubscription = videoSubscriptions.volumeChange
      .pipe(
        tap(() => {
          if (this.api.volume === 1) {
            this.soundTooltip = 'Mute';
          } else {
            this.soundTooltip = 'Volume Up';
          }
        })
      )
      .subscribe();
  }

  public initializePlayerBitrates(bitrates: BitrateOptions[]): void {
    if (!this.hlsBitrates.length) {
      this.setPlayerBitrates(bitrates);
    }
  }

  public selectBitrate(bitrateItem: BitrateOptions, index: number) {
    this.hlsBitrates = this.hlsBitrates.map(hlsBitrate =>
      hlsBitrate === this.hlsBitrates[index]
        ? ({...hlsBitrate, checked: true} as BitrateOptions)
        : ({...hlsBitrate, checked: false} as BitrateOptions)
    );
    this.hlsBitrates[index] = {...this.hlsBitrates[index], checked: true} as BitrateOptions;
    this.vgHls.setBitrate(bitrateItem);
  }

  public changeVideoState(): void {
    if (this.api.state === VideoStatus.playing) {
      this.api.state = VideoStatus.paused;
      this.playTooltip = 'Play';
      this.isBuffering = false;
    } else {
      this.api.state = VideoStatus.playing;
      this.playTooltip = 'Pause';
      this.isBuffering = true;
    }
  }

  private setPlayerBitrates(bitrates: BitrateOptions[]): void {
    bitrates.forEach((bitrate, index) => {
      bitrate.bitrate === 0
        ? this.hlsBitrates.push({...bitrate, checked: true} as BitrateOptions)
        : this.hlsBitrates.push({...bitrate, checked: false} as BitrateOptions);
    });
  }

  private setHlsPlaylistUrl(): void {
    this.hlsPlaylistUrl = this.getSource();
  }

  private getSource(): string {
    const model = this.model || this.device.selectedModel;
    const isAIstream = !!this.device.selectedModel && this.device.selectedModel !== 'RAW_STREAM';
    const deviceId = isAIstream ? this.device.id + '-opencv-' + model : this.device.id;
    return `${environment.STREAM_URL}/hls/${deviceId}/index.m3u8`;
  }

  private verifyAudioControl(): void {
    this.hasToShowAudioControl =
      !!this.vgHls.hls.bufferController.media.webkitAudioDecodedByteCount ||
      this.vgHls.hls.bufferController.media.mozHasAudio ||
      this.vgHls.hls.bufferController.media.audioTracks?.length > 0;
  }

  private restartLiveStream(): void {
    this.vgHls.createPlayer();
    const lowerBitrate = this.hlsBitrates.filter(hlsBitrate => hlsBitrate.bitrate !== 0);
    if (lowerBitrate.length > 0) {
      const finalLowerBitrate = lowerBitrate.reduce((prev, curr) => {
        return prev.bitrate < curr.bitrate ? prev : curr;
      });
      const lowerBitrateIndex = this.hlsBitrates.findIndex(hlsBitrate => hlsBitrate === finalLowerBitrate);
      this.selectBitrate(finalLowerBitrate, lowerBitrateIndex);
    }
  }

  private showReloadNotification(): void {
    this.translateService.get('live.live-video-player.showReloadNotification').subscribe(i18showReloadNotification =>
      this.snackBar.open(`${this.device.name} ${i18showReloadNotification}`, null, {
        duration: 2500
      } as MatSnackBarConfig)
    );
  }

  private handleAnalyticsError(): void {
    this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER, {type: 'Error'});
    this.translateService.get('common.errors.streamNotLoad').subscribe(i18err =>
      this.snackBar.open(i18err, null, {
        duration: 2000
      } as MatSnackBarConfig)
    );
  }

  private watchLoadedMetadata(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.loadedMetadata
      .pipe(
        tap(() => {
          this.api.play();
          this.hideControls();
        })
      )
      .subscribe();
  }

  private watchLoadedData(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.loadedData
      .pipe(
        tap(() => {
          this.hasToShowControls = true;
          this.media.nativeElement.style.height = '100%';
          this.api.play();
          this.verifyAudioControl();
          this.mediaRef.progressObs.unsubscribe();
          this.mediaRef.timeUpdateObs.unsubscribe();
        })
      )
      .subscribe();
  }

  private watchWaiting(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.waiting
      .pipe(
        tap(() => {
          this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER, {type: 'Buffering'});
          this.isBuffering = true;
          this.waitingBufferSubscription?.unsubscribe();
          this.waitingBufferSubscription = interval(this.bufferLimit).subscribe(() => {
            this.restartLiveStream();
            this.showReloadNotification();
            console.info(`Refreshing the stream for ${this.device.name} because its buffering too much.`);
          });
        })
      )
      .subscribe();
  }

  private watchPlaying(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.playing
      .pipe(
        tap(() => {
          this.waitingBufferSubscription?.unsubscribe();
          this.bufferFristAttemptSubscription?.unsubscribe();
          this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER, {type: 'Play'});
          this.isBuffering = false;
        })
      )
      .subscribe();
  }

  private watchPause(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.pause
      .pipe(
        tap(() => {
          this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER, {type: 'Paused'});
          this.isBuffering = false;
        })
      )
      .subscribe();
  }

  private watchError(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.error.pipe(filter(error => !!error.target.error)).subscribe(e => {
      if (this.isSafari) {
        this.handleAnalyticsError();
      }
    });
  }

  private watchStalled(videoSubscriptions: IMediaSubscriptions): void {
    videoSubscriptions.stalled.subscribe(() => {
      if (!this.isSafari) {
        this.handleAnalyticsError();
      }
    });
  }

  public captureSnapshot(): void {
    const canvas = document.getElementById(`canvas-player-${this.device.id}`) as HTMLCanvasElement;
    const ratio = this.media.nativeElement.videoWidth / this.media.nativeElement.videoHeight;
    const w = this.media.nativeElement.videoWidth;
    const h = w / ratio;

    canvas.setAttribute('width', w.toString());
    canvas.setAttribute('height', h.toString());
    canvas.getContext('2d').drawImage(this.media.nativeElement, 0, 0, w, h);
    const snapshotUrl = canvas.toDataURL('image/png');
    this.managerZonesStoreFacadeService.setImageSnapshot({
      data: snapshotUrl,
      height: h,
      width: w
    });
  }
}

export enum VideoStatus {
  playing = 'playing',
  paused = 'paused'
}

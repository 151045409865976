import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {MatTabGroup} from '@angular/material/tabs';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {EVENTS, UnleashAnalyticsService} from '../unleash-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public isOpenNotificationsSidebar$: Observable<boolean>;
  private _isOpenNotificationSidebar = new BehaviorSubject<boolean>(false);
  public sidebarMatGroup: MatTabGroup;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private unleashAnalyticsService: UnleashAnalyticsService
  ) {
    this.isOpenNotificationsSidebar$ = this._isOpenNotificationSidebar.asObservable().pipe(distinctUntilChanged());
  }

  public default(message: string): void {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  public info(message: string): void {
    this.show(message, {
      duration: 2000,
      panelClass: 'info-notification-overlay'
    });
  }

  public success(message: string): void {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay'
    });
  }

  public warn(message: string): void {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    });
  }

  public error(message: string): void {
    this.show(message, {
      duration: 3000,
      panelClass: 'error-notification-overlay'
    });
  }

  public toggleNotificationSidebar(): void {
    if (this._isOpenNotificationSidebar.value) {
      this._isOpenNotificationSidebar.next(false);
      return;
    }

    this._isOpenNotificationSidebar.next(true);
    this.unleashAnalyticsService.logEvent(EVENTS.OPEN_NOTIFICATION_SIDEBAR);
  }

  public openNotificationSidebar(): void {
    this._isOpenNotificationSidebar.next(true);
    this.unleashAnalyticsService.logEvent(EVENTS.OPEN_NOTIFICATION_SIDEBAR);
  }

  public focusTab(index: number) {
    this.sidebarMatGroup.selectedIndex = index;
  }

  public closeNotificationSidebar(): void {
    this._isOpenNotificationSidebar.next(false);
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }
}

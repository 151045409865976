<div class="grid-container" [class.grid-container--is-empty-mode]="isEmptyMode">
  <div
    *ngFor="let block of blocks"
    [class.selected]="triggerPoint === block.name"
    [class.is-empty-mode]="isEmptyMode"
    (click)="selectBlock(block)"
    (mouseenter)="emitHoverTriggerPoint(block)"
    (mouseleave)="emitHoverTriggerPointDefault()"
  ></div>
</div>

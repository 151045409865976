<div class="account">
  <div class="account__header" [class.account__header--collapsed]="!isExpanded">
    <div
      class="account__avatar-navigation custom-tooltip"
      [class.account__avatar-navigation--expanded]="isExpanded"
      [routerLink]="menuItem.routerLink"
      routerLinkActive="active"
    >
      <span *ngIf="!isExpanded" class="tooltiptext">{{ 'core.sidebar.menu.account' | translate }}</span>
      <div
        *ngIf="menuItem.imagePath; else initialsAvatar"
        class="account__avatar image"
        style="background-image: url({{ menuItem.imagePath | uThumbler: thumblerConfig }})"
      ></div>
      <ng-template #initialsAvatar>
        <div *ngIf="avatar" class="account__avatar">
          {{ avatar }}
        </div>
      </ng-template>
    </div>
    <div class="account__icon-container custom-tooltip" (click)="emitLogOut()">
      <span class="tooltiptext" [class.sign-out-tooltip]="isExpanded">{{ 'common.signOut' | translate }}</span>
      <span class="account__signout-label">{{ 'common.signOut' | translate }}</span>
      <mat-icon
        class="account__logout"
        [class.account__logout--collapsed]="!isExpanded"
        svgIcon="logout-nav"
      ></mat-icon>
    </div>
  </div>
  <ng-container *ngIf="isExpanded">
    <mat-list-item [routerLink]="menuItem.routerLink" routerLinkActive="active" class="account__action">
      <span class="account__user-name">{{ userName }}</span>
      <span class="account__company-name">{{ companyName }}</span>
    </mat-list-item>
  </ng-container>
</div>

<div class="account-landscape" [ngClass]="isExpanded ? 'open' : 'close'">
  <div class="account-landscape__info-container">
    <div
      class="account__avatar-navigation"
      [class.account__avatar-navigation--expanded]="isExpanded"
      [routerLink]="menuItem.routerLink"
      routerLinkActive="active"
    >
      <div
        *ngIf="menuItem.imagePath; else initialsAvatar"
        class="account__avatar"
        style="background-image: url({{ menuItem.imagePath | uThumbler: thumblerConfig }})"
      ></div>
      <ng-template #initialsAvatar>
        <div class="account__avatar">
          {{ avatar }}
        </div>
      </ng-template>
    </div>
    <mat-list-item [routerLink]="menuItem.routerLink" routerLinkActive="active" class="account__action">
      <span class="account__user-name">{{ userName }}</span>
      <span class="account__company-name">{{ companyName }}</span>
    </mat-list-item>
  </div>
  <div class="account__icon-container">
    <mat-icon
      class="account__logout"
      [class.account__logout--collapsed]="!isExpanded"
      svgIcon="logout"
      (click)="emitLogOut()"
    ></mat-icon>
  </div>
</div>

<ng-container *ngIf="!isExpanded; else expandedMenuTemplate">
  <mat-list-item
    [matMenuTriggerFor]="userRole !== teamRole.admin && totalUserTeams < 2 ? '' : menu"
    #menuTrigger="matMenuTrigger"
    (mouseenter)="openMenu(); setItemHover(true)"
    (mouseleave)="setItemHover(false)"
    class="menu-item disable-text-selection collapsed"
  >
    <div class="team-switcher__icon-container">
      <mat-icon class="team-switcher__collapsed-icon">groups</mat-icon>
      <mat-icon
        [class.hide-team-switcher]="userRole !== teamRole.admin && totalUserTeams < 2"
        class="team-switcher__menu-icon collapsed-arrow"
        >keyboard_arrow_right</mat-icon
      >
    </div>
  </mat-list-item>
</ng-container>
<ng-template #expandedMenuTemplate>
  <div
    class="team-switcher"
    #menuTrigger="matMenuTrigger"
    (mouseenter)="openMenu(); setItemHover(true)"
    (mouseleave)="setItemHover(false)"
    [matMenuTriggerFor]="(userRole !== teamRole.admin && totalUserTeams < 2) || isMobileView ? '' : menu"
    (click)="isMobileView ? openMobileTeamSwitcher() : ''"
  >
    <div class="team-switcher__info">
      <div class="team-switcher__header">
        <mat-icon class="team-switcher__icon">group</mat-icon>
        <span class="team-switcher__title">
          {{ 'core.sidebar.menu.team' | translate }}
        </span>
      </div>
      <span class="team-switcher__name"> {{ teams[selectedTeamId]?.name }} </span>
    </div>
    <mat-icon
      [class.hide-team-switcher]="userRole !== teamRole.admin && totalUserTeams < 2"
      class="team-switcher__menu-icon"
      >keyboard_arrow_right</mat-icon
    >
  </div>
</ng-template>
<mat-menu
  #menu="matMenu"
  class="teams-menu"
  [backdropClass]="isExpanded ? 'teams-backdrop' : 'teams-backdrop-collapsed'"
>
  <div #menuContainer (mouseenter)="setMenuHover(true)" (mouseleave)="setMenuHover(false)">
    <unleash-team-switcher></unleash-team-switcher>
  </div>
</mat-menu>

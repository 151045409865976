<h2 class="title" mat-dialog-title data-test-id="dialog-title">{{ 'jobs.newJob' | translate }}</h2>
<form [formGroup]="form">
  <mat-form-field appearance="fill" class="name">
    <mat-label>{{ 'jobs.jobName' | translate }}</mat-label>
    <input matInput formControlName="name" data-test-id="job-name-input"/>
    <mat-error *ngIf="form.controls.name.invalid && form.touched" data-test-id="job-name-error">{{ 'jobs.invalidJobName' | translate }}</mat-error>
  </mat-form-field>
  <div class="mat-dialog-actions">
    <button type="button" mat-dialog-close mat-button color="primary" data-test-id="cancel-button">{{ 'jobs.cancel' | translate }}</button>
    <button [disabled]="isLoading" type="submit" (click)="continue()" mat-flat-button color="primary" data-test-id="continue-button">
      {{ 'jobs.continue' | translate }}
      <unleash-spinner *ngIf="isLoading" diameter="20" borderWidth="2.1" color="#111" data-test-id="loading-spinner"></unleash-spinner>
    </button>
  </div>
</form>

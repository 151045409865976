import {createAction, props} from '@ngrx/store';
import {Mission} from '@app/atlas/services/mission-settings.service';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {CameraVideoStreamSource} from '@app/flights/models/remote-cockpit-lens.model';
import {DroneState, RemoteCockpitLog} from '@app/flights/models/remote-cockpit.model';

export const setSelectedDevice = createAction(
  '[RemoteCockpit] Set Selected Device',
  props<{device: UserDeviceJoined}>()
);

export const addCurrentDeviceLog = createAction(
  '[RemoteCockpit] Add Log To Current Device',
  props<{log: Partial<RemoteCockpitLog>}>()
);

export const addCurrentDeviceLogSuccess = createAction(
  '[RemoteCockpit] Add Log To Current Device Success',
  props<{log: RemoteCockpitLog}>()
);

export const setCurrentDeviceState = createAction(
  '[RemoteCockpit] Set Current Device Stream',
  props<{currentDeviceState: Partial<DroneState>}>()
);

export const setSkyChargeState = createAction('[RemoteCockpit] Set Skycharge State', props<{deviceId: string}>());

export const disconnectCurrentDevice = createAction('[RemoteCockpit] Disconnect Current Device Stream');

export const setCurrentMission = createAction('[RemoteCockpit] Set Current Mission', props<{mission: Mission}>());

export const loadMissions = createAction('[RemoteCockpit] Load missions');

export const setMissionInProgress = createAction(
  '[RemoteCockpit] Set Mission inProgress',
  props<{isMissionInProgress: boolean}>()
);
export const setFlightInProgress = createAction(
  '[RemoteCockpit] Set isFlightInProgress',
  props<{isFlightInProgress: boolean}>()
);

export const setMissions = createAction('[RemoteCockpit] Set missions', props<{missions: {[key: string]: Mission}}>());

export const setIoTConnectionStatus = createAction(
  '[RemoteCockpit] Set Camera Mode',
  props<{iotConnectionStatus: string}>()
);

export const pauseCurrentMissionMap = createAction('[RemoteCockpit] Pause Current Mission Map');

export const reconnectCurrentMissionMap = createAction('[RemoteCockpit] Reconnect Current Mission Map');

export const updateCurrentLens = createAction(
  '[RemoteCockpit] Update Current Lens',
  props<{lensId: CameraVideoStreamSource}>()
);

import {Component, Inject} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {UploadOption} from '@app/library/models/upload-option.model';
@Component({
  selector: 'app-google-drive-dialog',
  templateUrl: './google-drive-dialog.component.html',
  styleUrls: ['./google-drive-dialog.component.scss']
})
export class GoogleDriveDialogComponent {
  public events: typeof EVENTS = EVENTS;
  // eslint-disable-next-line max-len
  public googleDrivePattern: RegExp =
    /^(https:\/\/drive\.google\.com)((?=.open\?id=.*)|(?=.file\/.+\/.+\/view.*)|(?=.drive\/folders\/.*)|(?=.drive\/file\/.*)|(?=.file\/.+\/.+\/view.*))/;

  public shareableLinkForm: UntypedFormGroup = this.formBuilder.group({
    shareableLink: ['', [Validators.required, Validators.pattern(this.googleDrivePattern)]]
  });

  public uploadOption = UploadOption;
  public uploadType: UploadOption = UploadOption.googleDrive;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<GoogleDriveDialogComponent>
  ) {
    this.uploadType = this.data.uploadType;

    if (this.uploadType === UploadOption.googleDrive) {
      this.shareableLinkForm.setValidators([Validators.required, Validators.pattern(this.googleDrivePattern)]);
    } else if (this.uploadType === UploadOption.skydio) {
      this.shareableLinkForm.setValidators([]);
    }
  }

  public downloadFrom(): void {
    if (this.uploadType === UploadOption.googleDrive) {
      if (this.shareableLinkForm.controls.shareableLink.invalid) {
        this.shareableLinkForm.controls.shareableLink.markAsTouched();
        return;
      }
    }

    this.dialogRef.close({shareableLink: this.shareableLinkForm.value.shareableLink, uploadType: this.uploadType});
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LibraryItem} from '../models/folder-file.model';
import {LibraryStoreFacadeService} from './library-store-facade.service';

@Injectable({
  providedIn: 'root'
})
export class ModelMenuService {
  public availableModels$: Observable<LibraryItem[]> = this.libraryFacade.availableModels$;
  public mimeType$: Observable<LibraryItem['mimeType']>;

  private mimeType: BehaviorSubject<LibraryItem['mimeType']> = new BehaviorSubject<LibraryItem['mimeType']>(null);

  constructor(private libraryFacade: LibraryStoreFacadeService) {
    this.mimeType$ = this.mimeType.asObservable();
  }

  public init(libraryItemId: LibraryItem['id'], mimeType: LibraryItem['mimeType']): void {
    this.clearAvailableModels();
    this.getAvailableModels(libraryItemId);
    this.setMimeType(mimeType);
  }

  private getAvailableModels(libraryItemId: LibraryItem['id']): void {
    this.libraryFacade.getAvailableModels(libraryItemId);
  }

  private clearAvailableModels(): void {
    this.libraryFacade.clearAvailableModels();
  }

  private setMimeType(mimeType: LibraryItem['mimeType']): void {
    this.mimeType.next(mimeType);
  }
}

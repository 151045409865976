/* eslint-disable camelcase */
import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  Rule,
  RuleItem,
  RuleAction,
  RuleAlert,
  RuleCondition,
  RuleMenuOption,
  RuleOperator,
  RuleType,
  CoolDownPeriod,
  NestedRule,
  RuleAreaOfInterest
} from '@app/shared/analysis-configuration/models/rule.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {ComponentStore} from '@ngrx/component-store';

import {v4 as uuidv4} from 'uuid';

import {DeviceService} from '@app/core/services/api/device.service';
import {TranslateService} from '@ngx-translate/core';
import {
  selectEditMode,
  selectNotificationsForm,
  selectPerformAnalysisLoading,
  selectRules,
  selectRulesItem,
  selectSelectedRule
} from '@app/shared/manage-zones-dialog/store/manager-zones.selectors';
import {initialState, ManagerZonesAnalyzeState} from './store/manager-zones.state';
import {
  reducerAddAnalyzeNotification,
  reducerClearRules,
  reducerDeleteAnalyzeNotification,
  reducerDisableAnalyzeNotification,
  reducerEnableAnalyzeNotification,
  reducerLoadRulesState,
  reducerPerformAnalysisLoading,
  reducerSetRuleConfig,
  reducerSetRuleConfigSelectedRule,
  reducerSetSelectedRule,
  reducerStartEditMode,
  reducerStopEditMode,
  reducerStopPerformAnalysisLoading,
  reducerToggleAdvancedModeState,
  reducerUpdateAlertSeverityOnSelectedRule,
  reducerUpdateRuleNameOnSelectedRule
} from './store/manager-zones.reducer';
import {NotificationCoolDownPeriod} from '@app/core/models/api/notifications.model';

@Injectable({
  providedIn: 'root'
})
export class ManageZonesDialogStateService extends ComponentStore<ManagerZonesAnalyzeState> {
  public operators: RuleOperator[] = [
    {id: 'eq', name: '= (equals)'},
    {id: 'ne', name: '≠ (does not equal)'},
    {id: 'gt', name: '> (greater than)'},
    {id: 'ge', name: '≥ (greater or equal)'},
    {id: 'lt', name: '< (less than)'},
    {id: 'le', name: '≤ (less or equal)'}
  ];
  public conditions: RuleCondition[] = [
    {id: 'count', name: 'Count'},
    {id: 'max', name: 'Max.'},
    {id: 'min', name: 'Min.'},
    {id: 'avg', name: 'Average'},
    {id: 'sum', name: 'Sum'},
    {id: 'disabled', name: 'Without aggregation'}
  ];
  public alerts: RuleAlert[] = [
    {id: 'info', name: 'Info', icon: 'info', description: 'A notification will only be sent once per rule match'},
    {
      id: 'warning',
      name: 'Warning',
      icon: 'warning',
      description: 'Multiple notifications will be sent if the rule continues to be matched'
    },
    {
      id: 'alert',
      name: 'Alert',
      icon: 'info',
      description:
        'Multiple notifications will be sent if the rule continues to be matched. A notification will be sent when rule is no longer matched. '
    }
  ];

  public menuOptions: RuleMenuOption[] = [
    {name: 'Enable', type: RuleType.enable, icon: 'done', color: 'black'},
    {name: 'Disable', type: RuleType.disable, icon: 'hide_source', color: 'black'},
    {name: 'Delete', type: RuleType.delete, icon: 'delete', color: 'red'}
  ];

  public coolDownPeriods: CoolDownPeriod[] = [
    {value: NotificationCoolDownPeriod.Short, name: 'Short (10 seconds)'},
    {value: NotificationCoolDownPeriod.Medium, name: 'Medium (1 minute)'},
    {value: NotificationCoolDownPeriod.Long, name: 'Long (5 minutes)'}
  ];

  public editMode$: Observable<boolean>;
  public rules$: Observable<RuleItem[]>;
  public selectedRule$: Observable<RuleItem>;
  public form$: Observable<UntypedFormGroup>;
  public performAnalysisLoading$: Observable<boolean>;

  private isNotificationsConfigLoading = new BehaviorSubject(true);
  public isNotificationsConfigLoading$: Observable<boolean> = this.isNotificationsConfigLoading.asObservable();

  // eslint-disable-next-line rxjs/finnish
  private areaOfInterest: BehaviorSubject<RuleAreaOfInterest[]> = new BehaviorSubject([]);
  // eslint-disable-next-line rxjs/finnish
  private simpleConditions: BehaviorSubject<RuleCondition[]> = new BehaviorSubject([]);
  // eslint-disable-next-line rxjs/finnish
  private simpleForm: BehaviorSubject<UntypedFormGroup> = new BehaviorSubject(new UntypedFormGroup({}));
  public areaOfInterest$: Observable<RuleAreaOfInterest[]> = this.areaOfInterest.asObservable();
  public simpleConditions$: Observable<RuleCondition[]> = this.simpleConditions.asObservable();
  public simpleForm$: Observable<UntypedFormGroup> = this.simpleForm.asObservable();
  public isLivestream: boolean;

  private actionSetSelectedRule = this.updater(reducerSetSelectedRule);
  private actionSetRuleConfigSelectedRule = this.updater(reducerSetRuleConfigSelectedRule);
  private actionAddAnalyzeNotification = this.updater(reducerAddAnalyzeNotification);
  private actionSetRuleConfig = this.updater(reducerSetRuleConfig);
  private actionEnableAnalyzeNotification = this.updater(reducerEnableAnalyzeNotification);
  private actionDisableAnalyzeNotification = this.updater(reducerDisableAnalyzeNotification);
  private actionDeleteAnalyzeNotification = this.updater(reducerDeleteAnalyzeNotification);
  private actionStartEditMode = this.updater(reducerStartEditMode);
  private actionStopEditMode = this.updater(reducerStopEditMode);
  private actionUpdateRuleNameOnSelectedRule = this.updater(reducerUpdateRuleNameOnSelectedRule);
  private actionUpdateAlertSeverityOnSelectedRule = this.updater(reducerUpdateAlertSeverityOnSelectedRule);
  private actionPerformAnalysisLoading = this.updater(reducerPerformAnalysisLoading);
  private actionStopPerformAnalysisLoading = this.updater(reducerStopPerformAnalysisLoading);
  private actionClearRules = this.updater(reducerClearRules);
  private actionLoadRulesState = this.updater(reducerLoadRulesState);
  private actionToggleAdvancedModeState = this.updater(reducerToggleAdvancedModeState);

  constructor(
    private fb: FormBuilder,
    private deviceService: DeviceService,
    private translateService: TranslateService
  ) {
    super(initialState);
    this.editMode$ = this.select(selectEditMode);
    this.rules$ = this.select(selectRulesItem);
    this.selectedRule$ = this.select(selectSelectedRule);
    this.performAnalysisLoading$ = this.select(selectPerformAnalysisLoading);
    this.form$ = this.select(selectNotificationsForm).pipe(
      map(notificationsForm => {
        const formValue = {...notificationsForm};
        if (formValue && formValue.settings && !this.isLivestream) {
          formValue.settings.alert = 'info';
        }
        const form = this.fb.group({});

        if (formValue && Object.values(formValue).length > 0) {
          if (formValue.rules?.length > 0) {
            const lastRule = formValue.rules[formValue.rules.length - 1];

            if (!!lastRule.logicOperator) {
              const newNestedRule = this.getNewNestedRule();
              formValue.rules = [...formValue.rules, newNestedRule];
            }

            const ruleGroups = [];
            const simpleModeUntypedFormGroup = this.mapToSimpleModeRulesFromFormValue(formValue.rules);
            this.simpleForm.next(simpleModeUntypedFormGroup);
            formValue.rules.forEach(formRule => {
              const group: {
                zone?: [string, Validators];
                condition?: [string, Validators];
                operator?: [string, Validators];
                value?: [string, {validators: Validators; updateOn: string}];
                logicOperator?: string;
              } = {};
              group.zone = [formRule.zone, Validators.required];
              group.logicOperator = formRule.logicOperator;

              if (formRule.condition) {
                group.condition = [formRule.condition, Validators.required];
              }

              if (formRule.operator) {
                group.operator = [formRule.operator, Validators.required];
              }

              if (formRule.value || formRule.operator) {
                group.value = [formRule.value, {validators: Validators.required, updateOn: 'blur'}];
              }

              ruleGroups.push(this.fb.group(group));
            });
            form.addControl('rules', new FormArray(ruleGroups));
          }
          form.addControl('id', new UntypedFormControl(formValue.id));
          form.addControl('enabled', new UntypedFormControl(formValue.enabled));
          form.addControl('advancedMode', new UntypedFormControl(formValue.advancedMode));

          if (formValue.ruleName) {
            form.addControl('ruleName', this.fb.group({name: formValue.ruleName.name}));
          }

          if (formValue.settings) {
            form.addControl(
              'settings',
              this.fb.group({
                alert: formValue.settings.alert,
                coolDownPeriod: [
                  {
                    value: formValue.settings.alert === 'info' ? null : formValue.settings.coolDownPeriod,
                    disabled: formValue.settings.alert === 'info'
                  }
                ],
                title: formValue.settings.title,
                message: formValue.settings.message
              })
            );
          }

          form.addControl(
            'channels',
            this.fb.group({
              email: formValue.channels.email,
              sms: formValue.channels.sms,
              support: formValue.channels.support,
              app: formValue.channels.app
            })
          );
        }
        return Object.values(form.value).length > 0 ? form : null;
      })
    );

    setTimeout(() => this.initTranslations(), 200);
  }

  public selectRule(selectedRuleId: string): void {
    this.actionSetSelectedRule({ruleId: selectedRuleId});
    this.actionSetRuleConfigSelectedRule();
  }

  public addRule(): void {
    const ruleId = uuidv4();
    const rule: Rule = {
      id: ruleId,
      ruleName: {name: 'New Rule'},
      enabled: true,
      advancedMode: false,
      rules: [
        {zone: 'triggered_zones', logicOperator: 'AND', condition: 'null'},
        {zone: 'triggered_zones', logicOperator: '', condition: 'disabled'}
      ],
      settings: {alert: 'info', coolDownPeriod: this.coolDownPeriods[0].value, title: '', message: ''},
      channels: {email: true, sms: false, support: false, app: true}
    };
    this.actionAddAnalyzeNotification({rule});
    this.actionSetSelectedRule({ruleId: rule.id});
    this.actionSetRuleConfig({rule});
  }

  public getNewNestedRule(): NestedRule {
    return {zone: '', logicOperator: ''};
  }

  public executeRuleAction(ruleAction: RuleAction): void {
    switch (ruleAction.action) {
      case RuleType.enable:
        this.actionEnableAnalyzeNotification({ruleId: ruleAction.ruleId});
        break;
      case RuleType.disable:
        this.actionDisableAnalyzeNotification({ruleId: ruleAction.ruleId});
        break;
      case RuleType.delete:
        this.actionDeleteAnalyzeNotification({ruleId: ruleAction.ruleId});
        break;
      default:
        break;
    }
  }

  public addOperatorAndValueSelectToRule(action: {formIndex: number; form: Rule}): void {
    const newNestedRule = {...action.form.rules[action.formIndex]};
    if (!action.form.rules[action.formIndex].operator) {
      newNestedRule.operator = action.form.rules[action.formIndex].operator || 'gt';
      newNestedRule.value = action.form.rules[action.formIndex].value || '0';
    }

    const newRule = {...action.form};
    newRule.rules = newRule.rules.map((rule, ruleIndex) => {
      if (ruleIndex === action.formIndex) {
        return newNestedRule;
      }
      return rule;
    });

    this.actionSetRuleConfig({rule: newRule});
  }

  public toggleAdvancedModeState(): void {
    this.actionToggleAdvancedModeState();
  }

  public addConditionSelectToRule(action: {formIndex: number; form: Rule}): void {
    const newNestedRule = {...action.form.rules[action.formIndex]};
    if (!action.form.rules[action.formIndex].condition) {
      newNestedRule.condition = 'null';
    }

    let nextNestedRule = null;
    const MIN_RULES = 2;

    if (!action.form.advancedMode && action.form.rules.length === MIN_RULES) {
      nextNestedRule = {...action.form.rules[1]};
      // copy the same zone as first rule
      nextNestedRule.zone = action.form.rules[0].zone;
    }

    const newRule = {...action.form};
    newRule.rules = newRule.rules.map((rule, ruleIndex) => {
      if (ruleIndex === action.formIndex) {
        return newNestedRule;
      }

      if (!!nextNestedRule && action.form.rules.length === MIN_RULES && ruleIndex === 1) {
        return nextNestedRule;
      }

      return rule;
    });

    this.actionSetRuleConfig({rule: newRule});
  }

  public deleteNestedRule(action: {formIndex: number; form: Rule}) {
    const newRule = {...action.form};

    if (action.form.rules.length > 1 && action.formIndex === action.form.rules.length - 1) {
      const newNestedRule = {...action.form.rules[action.formIndex - 1]};
      newNestedRule.logicOperator = '';
      newRule.rules = newRule.rules.map((rule, ruleIndex) => {
        if (ruleIndex === action.formIndex - 1) {
          return newNestedRule;
        }
        return rule;
      });
    }

    const newNestedRules = newRule.rules.filter((nestedRule, index) => index !== action.formIndex);
    const newRuleWithNestedRules: Rule = {...newRule, rules: newNestedRules};

    this.actionSetRuleConfig({rule: newRuleWithNestedRules});
  }

  public saveValueChange(form: Rule) {
    const rule = {...form};
    this.actionSetRuleConfig({rule});
  }

  public startEditMode() {
    this.actionStartEditMode();
  }

  public stopEditMode(ruleName: string) {
    this.actionStopEditMode();
    this.actionUpdateRuleNameOnSelectedRule({ruleName});
  }

  public changeAlertSeveritySettings(alertId: string): void {
    this.actionUpdateAlertSeverityOnSelectedRule({alertId});
  }

  public getRulesPayload$(addonId: string): Observable<any> {
    return this.select(selectRules).pipe(
      take(1),
      switchMap(rules => {
        const notifications = rules.reduce((rulesAcc, currRuleValue) => {
          const {id, ...ruleValue} = currRuleValue;

          let ruleTriggers: any = {};
          if (ruleValue.rules && ruleValue.rules.length > 0) {
            ruleTriggers = this.mapRulesToNestedRules(ruleValue.rules);
          }

          const newNotificationFormat = {
            name: ruleValue.ruleName.name,
            triggers: ruleTriggers,
            changeToState: ruleValue.settings.alert,
            channels: {
              email: ruleValue.channels.email,
              sms: ruleValue.channels.sms
            },
            enabled: ruleValue.enabled,
            advancedMode: ruleValue.advancedMode,
            coolDownPeriod:
              ruleValue.settings.alert === 'info'
                ? NotificationCoolDownPeriod.ExtraLong
                : ruleValue.settings.coolDownPeriod,
            message: ruleValue.settings.message,
            title: ruleValue.settings.title
          };
          rulesAcc['notifications'] = {...rulesAcc['notifications'], [id]: newNotificationFormat};
          return rulesAcc;
        }, {});

        const hasAtLeastOneEnableRule = rules.some(rule => rule.enabled);

        const options: {kinesisThreadEnabled: boolean; kinesisThreadInterval: number} = {
          kinesisThreadEnabled: false,
          kinesisThreadInterval: 5
        };
        if (hasAtLeastOneEnableRule) {
          options.kinesisThreadEnabled = true;
        }

        const payload = {
          notificationsConfig: {
            [addonId]: notifications
          },
          ...options
        };
        return of(payload);
      })
    );
  }

  public analyzeWithRules$(addonId: string, deviceId: string): Observable<any> {
    return this.getRulesPayload$(addonId).pipe(
      switchMap(payload => {
        return this.deviceService
          .updateDevice(deviceId, {
            notificationsConfig: {
              [addonId]: null
            }
          })
          .pipe(switchMap(() => this.deviceService.updateDevice(deviceId, payload)));
      })
    );
  }

  public performAnalysisLoading(): void {
    this.actionPerformAnalysisLoading();
  }

  public performStopAnalysisLoading(): void {
    this.actionStopPerformAnalysisLoading();
  }

  public clearRules() {
    this.actionClearRules();
  }

  public setupAreaOfInterest(formatters: any) {
    if (!formatters) {
      return;
    }

    const formattersWithKinesis: any[] = Object.values(formatters).filter((formatter: any) => formatter.kinesis);

    const areaOfInterest: {field: string; label: string}[] = formattersWithKinesis.reduce((acc, curr) => {
      let formattersColumns: any[] = Object.values(curr.columns);
      let formattersRows: any[] = Object.values(curr.rows);

      formattersColumns = formattersColumns.reduce((acc, curr) => {
        return [...acc, ...curr];
      }, []);

      formattersRows = formattersRows.reduce((acc, curr) => {
        return [...acc, ...curr];
      }, []);

      return [...acc, ...formattersColumns, ...formattersRows];
    }, []);

    areaOfInterest.push({field: 'triggered_zones', label: 'triggered_zones'});
    areaOfInterest.push({field: 'class_name', label: 'class_name'});
    areaOfInterest.push({field: 'any', label: 'any'});

    const areaOfInterestFormatted = areaOfInterest
      .map(areaInterest => ({
        display_name: areaInterest.label,
        id: areaInterest.field
      }))
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    this.areaOfInterest.next(areaOfInterestFormatted);
  }

  public setupSimpleRuleConditions(processor: any): void {
    let propertiesList: any[][] = [];
    if (processor.grouped_class_names) {
      propertiesList = Object.values(processor.grouped_class_names);
    }
    if (propertiesList.length > 0) {
      const simpleClassConditions = propertiesList
        .reduce((acc, curr) => {
          curr.forEach(property => acc.push(property));
          return acc;
        }, [])
        .map(name => ({name, id: name}));
      this.simpleConditions.next(simpleClassConditions);
    }
  }

  public mapNotificationsConfigToRuleConfig(notifications: any) {
    const allRules = [];
    let rule: any = {};

    for (const key in notifications) {
      if (Object.prototype.hasOwnProperty.call(notifications, key)) {
        const element = notifications[key];

        const rules = [];

        const sets = Object.keys(element.triggers)
          .sort()
          .reduce((acc, setSey) => {
            acc = [...acc, element.triggers[setSey]];
            return acc;
          }, []);
        const orSetsLength = sets.length;

        sets.forEach((ruleSet, orSetIndex) => {
          const nestedRules = Object.keys(ruleSet)
            .sort()
            .reduce((acc, nestedRulesKey) => {
              acc = [...acc, ruleSet[nestedRulesKey]];
              return acc;
            }, []);
          const ruleSetLength = nestedRules.length;
          const newNestedRules = nestedRules.map((nestedRuleSet: any, nestedRuleSetIndex) => {
            let logicOperator = '';

            if (ruleSetLength - 1 === nestedRuleSetIndex) {
              if (orSetsLength > 1) {
                logicOperator = orSetIndex !== orSetsLength - 1 ? 'OR' : '';
              } else {
                logicOperator = '';
              }
            } else {
              logicOperator = 'AND';
            }

            return {
              zone: nestedRuleSet.field,
              logicOperator,
              condition: nestedRuleSet.aggregate,
              operator: nestedRuleSet.condition,
              value: nestedRuleSet.filter
            };
          });
          rules.push(...newNestedRules);
        });

        rule = {
          id: key,
          ruleName: {name: element.name},
          rules,
          enabled: element.enabled,
          advancedMode: element.advancedMode,
          channels: element.channels,
          settings: {
            alert: element.changeToState,
            coolDownPeriod: element.coolDownPeriod,
            title: element.title,
            message: element.message
          }
        };
        allRules.push(rule);
      }
    }
    this.actionLoadRulesState({rules: allRules});
  }

  public parseSimpleMode(action: {form: Rule; rules: NestedRule[]}) {
    const newRuleWithNestedRules = {...action.form};
    newRuleWithNestedRules.rules = action.rules;
    this.actionSetRuleConfig({rule: newRuleWithNestedRules});
  }

  public mapToSimpleModeRulesFromFormValue(rules: any): UntypedFormGroup {
    let untypedFormGroup = new UntypedFormGroup({
      zone: new UntypedFormControl('', Validators.required),
      condition: new UntypedFormControl('', Validators.required),
      operator: new UntypedFormControl('', Validators.required),
      value: new UntypedFormControl('', {validators: Validators.required, updateOn: 'blur'})
    });

    const ONE_RULE = 1;
    const TWO_RULES = 2;
    const THREE_RULES = 3;

    switch (rules.length) {
      case ONE_RULE:
        untypedFormGroup = new UntypedFormGroup({
          zone: new UntypedFormControl(
            rules[0].zone === 'triggered_zones' ? 'all-scene' : rules[0].value,
            Validators.required
          ),
          condition: new UntypedFormControl('all-classes', Validators.required),
          operator: new UntypedFormControl(rules[0].operator, Validators.required),
          value: new UntypedFormControl(rules[0].value, {validators: Validators.required, updateOn: 'blur'})
        });
        break;
      case TWO_RULES:
        untypedFormGroup = new UntypedFormGroup({
          zone: new UntypedFormControl(rules[0].value, Validators.required),
          condition: new UntypedFormControl('all-classes', Validators.required),
          operator: new UntypedFormControl(rules[1].operator, Validators.required),
          value: new UntypedFormControl(rules[1].value, {validators: Validators.required, updateOn: 'blur'})
        });
        break;
      case THREE_RULES:
        untypedFormGroup = new UntypedFormGroup({
          zone: new UntypedFormControl(rules[0].value, Validators.required),
          condition: new UntypedFormControl(rules[1].value, Validators.required),
          operator: new UntypedFormControl(rules[TWO_RULES].operator, Validators.required),
          value: new UntypedFormControl(rules[TWO_RULES].value, {validators: Validators.required, updateOn: 'blur'})
        });
        break;
      default:
        break;
    }

    return untypedFormGroup;
  }

  public setIsNotificationsConfigLoading(isNotificationsConfigLoading: boolean): void {
    this.isNotificationsConfigLoading.next(isNotificationsConfigLoading);
  }

  private mapRulesToNestedRules(rules: any): any {
    return rules.reduce((acc, cur, index) => {
      if (Object.values(acc).length === 0) {
        acc['triggerSet1'] = {};
      }

      const triggerSetName = 'triggerSet' + Object.values(acc).length;

      if (cur.logicOperator === 'AND' || cur.logicOperator === '') {
        acc[triggerSetName]['trigger' + index] = {
          field: cur.zone,
          condition: cur.operator,
          aggregate: cur.condition,
          filter: cur.value
        };
      } else {
        const newTriggerSet = 'triggerSet' + (Object.values(acc).length + 1);
        acc[newTriggerSet] = {};
        acc[triggerSetName]['trigger' + index] = {
          field: cur.zone,
          condition: cur.operator,
          aggregate: cur.condition,
          filter: cur.value
        };
      }
      return acc;
    }, {});
  }

  private initTranslations() {
    this.translateService.get('live.manage-zones-dialog.notificationsTab.options').subscribe(translations => {
      const {
        equals,
        notEqual,
        greaterThan,
        greaterEqual,
        lessThan,
        lessEqual,
        count,
        max,
        min,
        average,
        sum,
        withoutAggregate,
        info,
        infoDesc,
        warning,
        warningDesc,
        alert,
        alertDesc,
        enable,
        disable,
        del,
        short,
        medium,
        long
      } = translations;

      this.operators = [
        {id: 'eq', name: equals},
        {id: 'ne', name: notEqual},
        {id: 'gt', name: greaterThan},
        {id: 'ge', name: greaterEqual},
        {id: 'lt', name: lessThan},
        {id: 'le', name: lessEqual}
      ];
      this.conditions = [
        {id: 'count', name: count},
        {id: 'max', name: max},
        {id: 'min', name: min},
        {id: 'avg', name: average},
        {id: 'sum', name: sum},
        {id: 'disabled', name: withoutAggregate}
      ];
      this.alerts = [
        {id: 'info', name: info, icon: 'info', description: infoDesc},
        {
          id: 'warning',
          name: warning,
          icon: 'warning',
          description: warningDesc
        },
        {
          id: 'alert',
          name: alert,
          icon: 'info',
          description: alertDesc
        }
      ];
      this.menuOptions = [
        {name: enable, type: RuleType.enable, icon: 'done', color: 'black'},
        {name: disable, type: RuleType.disable, icon: 'hide_source', color: 'black'},
        {name: del, type: RuleType.delete, icon: 'delete', color: 'red'}
      ];

      this.coolDownPeriods = [
        {value: NotificationCoolDownPeriod.Short, name: short},
        {value: NotificationCoolDownPeriod.Medium, name: medium},
        {value: NotificationCoolDownPeriod.Long, name: long}
      ];
    });
  }
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Addon} from '@app/store/addon/models/addon';
import {Observable, of} from 'rxjs';
@Component({
  selector: 'unleash-un-publish-ai-app-dialog',
  templateUrl: './un-publish-ai-app-dialog.component.html',
  styleUrls: ['./un-publish-ai-app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnPublishAiAppDialogComponent {
  name$: Observable<string>;
  hasActiveUsers$: Observable<boolean>;
  expirationDate$: Observable<string>;

  constructor(
    private dialogRef: MatDialogRef<UnPublishAiAppDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private addon: Pick<Addon, 'id' | 'name' | 'usersCount'>
  ) {
    this.name$ = this.setUpName(this.addon);
    this.hasActiveUsers$ = this.setUpHasActiveUsers(this.addon);
    this.expirationDate$ = this.setUpExpirationDate();
  }

  public save(): void {
    this.dialogRef.close(this.addon.id);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  protected onEditSuccess() {
    this.dialogRef.close(true);
  }

  protected setUpName(addon: Pick<Addon, 'id' | 'name' | 'usersCount'>): Observable<string> {
    return of(addon.name);
  }

  protected setUpHasActiveUsers(addon: Pick<Addon, 'id' | 'name' | 'usersCount'>): Observable<boolean> {
    return of(addon.usersCount > 0);
  }

  protected setUpExpirationDate(): Observable<string> {
    // ... the app remains in Pending un-publish or Pending delete until 2 months pass.
    const date = new Date();
    date.setMonth(date.getMonth() + 2);
    return of(date.toString());
  }
}

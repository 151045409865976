import {SessionInfo, ShapeTypes} from '@app/core/models/api/label-config.model';

export interface ImageSize {
  width: number;
  height: number;
}

export interface Canvas {
  circle(diameter: number): Draw;
  path(arg0: string): Draw;
  svg(svgShape: string): Draw;
  line(x1?: number, y1?: number, x2?: number, y2?: number): Draw;
  get: (id: string) => Draw;
  clear: () => void;
  group: () => Draw;
  rect: (x?: number, y?: number) => Draw;
  plain: (text: string) => Draw;
  text: (any: string) => any;
  polygon(): Draw;
  polyline(args?: any): Draw;
  attr: (name?: string, value?: any) => any;
  viewbox(size?: string): () => any;
  removeClass(cls: string): null;
  addClass(cls: string): null;
}

export enum CanvasImageQuality {
  hiRes = 'hiResolution',
  midRes = 'midResolution',
  lowRes = 'lowResolution'
}

export enum DrawStatus {
  draw = 'draw',
  stop = 'stop',
  edit = 'edit'
}

export interface Draw {
  type: ShapeTypes;
  array(): {value: any};
  back(): Draw;
  size(width: number, height: number): Draw;
  text(arg: string): Draw;
  id(): string;
  radius(arg0: number): Draw;
  cy(): number;
  cx(): number;
  width(): number;
  rbox(): any;
  bbox(): any;
  off(event: string): unknown;
  fire(arg0: string, obj?: any): unknown;
  stroke(arg0: any): any;
  center(cx: any, cy: any): Draw;
  draw: (command?: any, opts?: any) => {};
  on: (event: string, callback?: (arg0?: any) => any) => any;
  _array: {value: any};
  _memory: any;
  attr: (attr: string | any, value?: any) => any;
  style: (attr: string, value?: string) => {};
  selectize: (opt?: any, newOpt?: any) => Draw;
  resize: (opt?: any) => Draw;
  draggable: (opt?: any) => Draw;
  remember: (attr: string, value?: any) => {};
  front(): void;
  remove(): void;
  plot(shape: any): Draw;
  click(callback?: (data?: any) => void): void;
  font: (attr: string | any, size?: number | string) => Draw;
  move: (x: number, y: number) => Draw;
  dmove: (x: number, y: number) => Draw;
  length: () => number;
  add: (draw: Draw) => void;
  fill: (color: string) => Draw;
  transform: (matrix: any) => Draw;
}

export interface SingleLabel {
  id?: string;
  displayName?: string;
  shapeType: ShapeTypes;
  category: string;
  shape: any;
  severity: number;
  comment: string;
  color: string;

  // auto labelling
  isAI?: boolean;
  isAccepted?: boolean;
  isModified?: boolean;
  readonly suggestedCategory?: string;

  // filled on UI to know if need to be removed
  isRemoved?: boolean;
  visibility?: boolean;
  isSelected?: boolean;
  distance?: number;
  addonId?: string;

  // LineInOutFeature
  matrix?: any;
  original_shape?: any;
  metadata?: any;
  isInEnabled?: boolean;
  isOutEnabled?: boolean;
}

export interface ImageLabelLabels {
  [key: string]: SingleLabel;
}

export interface ImageLabel {
  key: string;
  sessionId: string;
  deviceId: string;
  labels?: ImageLabelLabels;
  labelConfigId?: number;
  sessionInfo?: {[key: string]: SessionInfo};
}

export interface DragConstrain {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  snapToGrid?: number;
}

export enum AnnotationStep {
  main = 'main',
  shortcuts = 'shortcuts',
  editor = 'editor'
}

export enum LabelOpacity {
  visible = 0.25,
  hidden = 0,
  highlight = 0.5,
  full = 1
}

export enum MetadataTypeInput {
  text = 'text',
  list = 'list'
}

import {Injectable} from '@angular/core';
import {actionSetupSignUpEmail, actionSignUpFormSubmit} from '@app/auth/components/sign-up/sign-up.actions';
import {SignUpForm} from '@app/auth/components/sign-up/sign-up.model';
import {
  selectSignUpFormValue,
  selectSignUpFormInProgress,
  selectSignUpFormError
} from '@app/auth/components/sign-up/sign-up.selectors';
import {
  selectAwsMarketplaceToken,
  selectOrganizationName,
  selectSignUpAdditionalParams
} from '@app/auth/state/auth.selectors';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs';
import * as actions from './auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  public signInForm$ = this.store.pipe(select(selectSignUpFormValue));
  public inProgress$ = this.store.pipe(select(selectSignUpFormInProgress));
  public hasAwsToken$ = this.store.pipe(
    select(selectAwsMarketplaceToken),
    map(token => !!token)
  );

  public signUpAdditionalParams$ = this.store.pipe(select(selectSignUpAdditionalParams));
  public awsMarketplaceToken$ = this.store.pipe(select(selectAwsMarketplaceToken));

  public signUpFormError$ = this.store.pipe(
    select(selectSignUpFormError),
    map(error => (!error ? null : error))
  );
  public isOrganizationSignUp$ = this.store.pipe(
    select(selectOrganizationName),
    map(organizationName => !!organizationName)
  );
  public organizationNameSignUp$ = this.store.pipe(select(selectOrganizationName));

  constructor(private store: Store) {}

  public authLogout(isDeveloperMode?: boolean): void {
    this.store.dispatch(actions.authLogout({isDeveloperMode}));
  }

  public authPlanSelectionRequired(isDeveloperMode?: boolean): void {
    this.store.dispatch(actions.authPlanSelectionRequired({isDeveloperMode}));
  }

  public clearAwsMarketPlaceToken(): void {
    this.store.dispatch(actions.actionClearAwsMarketPlaceToken());
  }

  public setupAwsMarketPlaceToken(awsMarketplaceToken: string): void {
    this.store.dispatch(actions.actionSetupAwsMarketPlaceToken({awsMarketplaceToken}));
  }

  public setupPromoCode(promoCode: string): void {
    this.store.dispatch(actions.actionSetupPromoCode({promoCode}));
  }

  public setupOrganizationName(organizationName: any): void {
    this.store.dispatch(actions.actionSetupOrganizationName({payload: {organizationName}}));
  }

  public setupSignUpEmail(email: string) {
    this.store.dispatch(actionSetupSignUpEmail({payload: {email}}));
  }

  public signUpFormSubmit(
    signUpFormValue: SignUpForm,
    token: string,
    isDeveloperMode: boolean,
    promoCode: string
  ): void {
    this.store.dispatch(
      actionSignUpFormSubmit({
        form: {...signUpFormValue},
        awsMarketplaceToken: token,
        isDeveloperMode,
        promoCode
      })
    );
  }
}

<div class="container">
  <div class="header">
    <div class="header__info">
      <mat-icon (click)="closeSidebar()" class="header__back-icon">arrow_back</mat-icon>
      <span class="header__title">{{ 'core.header.notifications' | translate }}</span>
    </div>
    <mat-icon
      class="header__read-icon"
      (click)="markAllNotificationsAsSeen()"
      svgIcon="open-notifications"
      matTooltip=" {{ 'core.notifications.markAllRead' | translate }}"
    ></mat-icon>
  </div>
  <ng-container
    *ngIf="
      (notificationsInProgress$ | async)?.length > 0 || (notifications$ | async)?.length > 0;
      else emptyStateTemplate
    "
  >
    <ng-container *ngIf="(notificationsInProgress$ | async)?.length > 0">
      <app-notification-card
        *ngFor="let notification of notificationsInProgress$ | async; trackBy: trackByNotificationCreatedAt"
        (clickAction)="closeSidebar(); triggerEventTracking(notification)"
        (notificationWasSeen)="updateNotificationCounter(notification)"
        [notification]="notification"
        [layout]="notificationLayout.inProgress"
      ></app-notification-card>
    </ng-container>

    <ng-container *ngIf="(notifications$ | async)?.length > 0">
      <app-notification-card
        *ngFor="let notification of notifications$ | async; trackBy: trackByNotificationCreatedAt"
        (clickAction)="closeSidebar(); triggerEventTracking($event)"
        (notificationWasSeen)="updateNotificationCounter(notification)"
        (deleteNotification)="deleteNotification(notification)"
        [notification]="notification"
        [layout]="'simple'"
        class="notification-completed"
      ></app-notification-card>
    </ng-container>
  </ng-container>
</div>

<ng-template #emptyStateTemplate>
  <div class="empty-state">
    <img class="empty-state__img" src="assets/icons/notifications/no-new-notifications.svg" />
    <p class="empty-state__text">{{ 'core.notifications.noNew' | translate }}</p>
    <p class="empty-state__text">{{ currentTeamName$ | async }}</p>
  </div>
</ng-template>

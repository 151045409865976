import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {createErrorHandler} from '@sentry/angular';
import {environment} from 'environments/environment';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {MinimalRouterSerializer} from './core/minimal-router-serializer';
import {GeneralHammerConfig} from './shared/hammer-config/general-config';
import {AppStoreModule} from './store/app-store.module';
import {UnleashLoaderModule} from './theme/components/unleash-loader/unleash-loader.module';
import {UnleashMaterialModule} from './theme/unleash-material.module';

// required by segment.io window.analytics variable
declare global {
  interface Window {
    analytics: any;
    mixpanel: any;
  }
}

// required for AOT compilation
function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    UnleashLoaderModule,
    CoreModule,
    AppStoreModule,
    RouterModule.forRoot(
      [
        {
          path: 'data',
          loadChildren: () => import('./data/data.module').then(m => m.DataModule)
        }
      ],
      {
        anchorScrolling: 'enabled'
      }
    ),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.serviceWorker
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: MinimalRouterSerializer
    }),
    UnleashMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    // SentryErrorHandler conflicts with StoreDevTools, circular dependency for ErrorHandler
    ...(environment.production
      ? [
          {
            provide: ErrorHandler,
            useValue: createErrorHandler({
              showDialog: false
            })
          }
        ]
      : []),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GeneralHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

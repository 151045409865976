import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface RadioCardData {
  title: string;
  description: string;
  imageSrc: string;
  value: string;
}

@Component({
  selector: 'unleash-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioCardComponent),
      multi: true
    }
  ]
})
export class RadioCardComponent implements ControlValueAccessor {
  @Input() public disabled: boolean = false;
  @Input() public options: RadioCardData[] = [];
  @Input() public selectedCard: string = '';

  @Output() public valueSelected: EventEmitter<void> = new EventEmitter();

  public selectCard(value: string): void {
    this.selectedCard = value;
    this.writeValue(value);
    this.valueSelected.emit();
  }

  public onChange = (value: string): void => {};
  public onTouched = (): void => {};
  public writeValue(value: string): void {
    this.onChange(value);
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

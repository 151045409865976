import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {TranslateModule} from '@ngx-translate/core';
import {UnleashMaterialSharedModule} from '../unleash-material/unleash-material-shared.module';
import {PaymentCardComponent} from './payment-card/payment-card.component';
import {PromoCodeComponent} from './promo-code/promo-code.component';

@NgModule({
  declarations: [PaymentCardComponent, PromoCodeComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatRadioModule,
    UnleashMaterialSharedModule,
    LaddaModule,
    TranslateModule
  ],
  exports: [PaymentCardComponent, PromoCodeComponent]
})
export class StripeElementsModule {}

<div class="container">
  <div class="logo">
    <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
  </div>
  <div class="title">
    <div class="title__text">{{ 'auth.verification.totpVerification' | translate }}</div>
  </div>
  <form [formGroup]="totpForm">
    <mat-form-field appearance="fill" class="code">
      <mat-label>{{ 'auth.verification.label' | translate }}</mat-label>
      <input matInput formControlName="code" />
      <mat-error *ngIf="totpForm.controls.code.hasError('required')"
        >{{ 'auth.verification.code' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
      >
      <mat-error *ngIf="totpForm.controls.code.hasError('invalidCode')">
        {{ 'auth.verification.invalidCode' | translate }}
      </mat-error>
      <mat-error *ngIf="totpForm.controls.code.hasError('maxlength')">
        {{ 'auth.verification.maxLengthValidation' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="actions">
      <button type="button" (click)="signOut()" mat-button color="primary">
        {{ 'auth.verification.signOut' | translate }}
      </button>
      <button
        type="submit"
        (click)="continue()"
        mat-flat-button
        color="primary"
        [appLadda]="isInProgress | async"
        [disabled]="isInProgress | async"
      >
        {{ 'auth.verification.continue' | translate }}
      </button>
    </div>
  </form>
</div>

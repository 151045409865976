import {Directive, HostListener, Input} from '@angular/core';
import {MissionSettingsService} from '../services/mission-settings.service';
import {take} from 'rxjs';

@Directive({
  selector: '[ulWaypointSettings]'
})
export class WaypointSettingsDirective {
  @Input('ulWaypointSettings') public setting: string;
  @Input() public waypointIndex: number;
  @HostListener('change', ['$event'])
  public changeWypointSetting(event) {
    this.missionSettingsService.editingMission.pipe(take(1)).subscribe(editMission => {
      const mission = editMission.mission;
      mission.route[this.waypointIndex] = {
        ...mission.route[this.waypointIndex],
        [this.setting]: event.value === 0 || !!event.value ? event.value : +event?.srcElement.value
      };

      if (this.setting === 'lat' || this.setting === 'lng') {
        this.missionSettingsService.sethasToRedraw(true);
      }
      if (this.setting === 'heading') {
        this.missionSettingsService.setHasToRegenerateVertex(true);
      }
      this.missionSettingsService.setEditingMission({mission, selectedWaypointIndex: this.waypointIndex});
    });
  }

  constructor(private missionSettingsService: MissionSettingsService) {}
}

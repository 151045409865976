import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UnleashLoaderComponent} from './unleash-loader.component';

@NgModule({
  declarations: [UnleashLoaderComponent],
  imports: [CommonModule],
  exports: [UnleashLoaderComponent]
})
export class UnleashLoaderModule {}

import {Pipe, PipeTransform} from '@angular/core';
import {formatDuration, intervalToDuration} from 'date-fns';

@Pipe({name: 'uDuration', standalone: true})
export class DurationPipe implements PipeTransform {
  public transform(endDate: Date | number, typeFormat: string, startDate: Date): string {
    let end = endDate;
    let start = startDate || 0;
    if (typeFormat === 'shortFormatFlight') {
      const secondOverMilliseconds = 1000;
      end = (endDate as number) * secondOverMilliseconds;
      if (!end) {
        end = 0;
        console.warn('No end duration value invalid');
      }

      const durationInterval = intervalToDuration({
        start: 0,
        end: end
      });
      const formatDistanceLocale = {
        xSeconds: '{{count}}s',
        xMinutes: '{{count}}m',
        xHours: '{{count}}h',
        xDays: '{{count}}',
        xWeeks: '{{count}}',
        xMonths: '{{count}}',
        xYears: '{{count}}'
      };
      const shortEnLocale = {
        formatDistance: (token, count) => {
          const plurals = {
            xDays: 'Days',
            xWeeks: 'Weeks',
            xMonths: 'Months',
            xYears: 'Years'
          };

          const singulars = {
            xDays: 'Day',
            xWeeks: 'Week',
            xMonths: 'Month',
            xYears: 'Year'
          };

          const plural = plurals[token];
          const singular = singulars[token];
          if (['xDays', 'xWeeks', 'xMonths', 'xYears'].includes(token)) {
            return count > 1
              ? formatDistanceLocale[token]?.replace('{{count}}', count + plural)
              : formatDistanceLocale[token]?.replace('{{count}}', count + singular);
          }
          return formatDistanceLocale[token]?.replace('{{count}}', count);
        }
      };

      return (
        formatDuration(durationInterval, {
          format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
          locale: shortEnLocale
        }) || '0s'
      );
    }
    if (typeFormat === 'secondsFlight') {
      const secondOverMilliseconds = 1000;
      end = (endDate as number) * secondOverMilliseconds;
      const durationInterval = intervalToDuration({
        start: 0,
        end: end
      });
      return formatDuration(durationInterval);
    }

    if (typeFormat === 'completeFormat') {
      end = end as number;
      start = start as number;
      const durationInterval = intervalToDuration({
        start,
        end
      });

      const formatDate = formatDuration(durationInterval, {format: ['days', 'hours', 'minutes']});
      if (formatDate === '') {
        return 'less than 1 minute';
      }
      return formatDate;
    }

    const durationInterval = intervalToDuration({
      start,
      end
    });
    return formatDuration(durationInterval, {format: [typeFormat]});
  }
}

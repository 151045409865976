import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'uRouterLinkUrl', standalone: true})
export class RouterLinkUrlPipe implements PipeTransform {
  public transform(url: string): string {
    if (!url) {
      return './';
    }
    const tokens = url.split('?');
    return tokens.length > 0 ? tokens[0] : url;
  }
}

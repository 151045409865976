/* eslint-disable camelcase */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AddonZones, Zone, ZoneColor} from '../models/annotations.model';
import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {TriggerPoint} from '@app/shared/trigger-point/trigger-point.model';

declare let SVG: any;

@Injectable({
  providedIn: 'root'
})
export class ManageZoneService {
  public isSetupMinimalConfig$: Observable<boolean>;
  // eslint-disable-next-line rxjs/finnish
  private isSetupMinimalConfig = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isSetupMinimalConfig$ = this.isSetupMinimalConfig.asObservable();
  }

  public generateDefaultZone(shapeTypes: ShapeTypes, id: string, color: ZoneColor, zones: Zone[]): Zone {
    const newZone: Zone = {
      display_name: '',
      csv_label: '',
      id,
      color,
      shape: [],
      shape_type: shapeTypes,
      visibility: true
    };

    const {display_name, csv_label} = this.generateZoneNames(zones, newZone);
    newZone.display_name = display_name;
    newZone.csv_label = csv_label;

    switch (shapeTypes) {
      case ShapeTypes.line_in_out:
        newZone.line_in_direction = [1, -1];
        newZone.line_out_direction = [-1, 1];
        newZone.isInEnabled = true;
        newZone.isOutEnabled = true;
        break;
    }

    return newZone;
  }

  public transformPointArray(pointArray: any, matrix: any) {
    const newPointArray = [];
    for (const point of pointArray) {
      const p = new SVG.Point(point[0], point[1]);
      const transformedPoint = p.transform(matrix);
      newPointArray.push([transformedPoint.x, transformedPoint.y]);
    }
    return newPointArray;
  }

  public transformPoint(point: {x: number; y: number}, matrix: any): {x: number; y: number} {
    const p = new SVG.Point(point.x, point.y);
    const transformedPoint = p.transform(matrix);
    return {
      x: transformedPoint.x,
      y: transformedPoint.y
    };
  }

  public parseDbData(addonZones: AddonZones, canvasSize: {width: number; height: number}) {
    for (const zone of addonZones.zones) {
      if (zone.shape_type === ShapeTypes.circle) {
        zone.shape = zone.shape as object;
        zone.shape = {
          r: (zone.shape as any).r,
          cx: (zone.shape as any).cx * canvasSize.width,
          cy: (zone.shape as any).cy * canvasSize.height
        };
      } else if (zone.shape_type === ShapeTypes.line_in_out) {
        const widthScale = canvasSize.width / zone.canvas_width;
        const heightScale = canvasSize.height / zone.canvas_height;
        zone.shape = this.scaleLineInOutShape(zone.original_shape, {
          width_scale: widthScale,
          height_scale: heightScale
        });
        if (zone.matrix) {
          zone.matrix = this.scaleMatrix(zone.matrix, {
            width_scale: widthScale,
            height_scale: heightScale
          });
        }
      } else {
        if (zone?.tags?.includes('perspective_zone')) {
          zone.shape_type = ShapeTypes.perspective;
        }

        const newShape: any = [];
        for (const point of zone.shape as number[][]) {
          const xy = [point[0] * canvasSize.width, point[1] * canvasSize.height];
          newShape.push(xy);
        }
        zone.shape = newShape;
      }
    }
    return addonZones;
  }

  public scaleLineInOutShape(
    shape: number[][],
    canvasSizeScale: {width_scale: number; height_scale: number}
  ): number[][] {
    const newShape: any = [];
    for (const point of shape as number[][]) {
      const xy = [point[0] * canvasSizeScale.width_scale, point[1] * canvasSizeScale.height_scale];
      newShape.push(xy);
    }
    return newShape;
  }

  public scaleMatrix(
    matrix: {a: number; b: number; c: number; d: number; e: number; f: number},
    canvasSizeScale: {width_scale: number; height_scale: number}
  ): any {
    const normalizedMatrix = {...matrix};
    if (normalizedMatrix && normalizedMatrix.e) {
      normalizedMatrix.e *= canvasSizeScale.width_scale;
    }

    if (normalizedMatrix && normalizedMatrix.f) {
      normalizedMatrix.f *= canvasSizeScale.height_scale;
    }
    return normalizedMatrix;
  }

  public parseExcludedZones(zones: Zone[]): Zone[] {
    const zonesToExport = zones.map(zone => {
      if (zone.shape_type === ShapeTypes.polygon && zone?.tags?.includes('remove_when_inside_zone')) {
        const newZones = zone.tags.filter(tag => tag === 'remove_when_inside_inside');
        zone.tags = newZones;
        zone.shape_type = ShapeTypes.polygon_excluded;
      }

      if (zone.shape_type === ShapeTypes.polygon && zone?.tags?.includes('perspective_zone')) {
        zone.shape_type = ShapeTypes.perspective;
      }
      return zone;
    });

    return zonesToExport;
  }

  public initAllZonesAndShapes(zones: Zone[]) {
    const labelConfigs = {};
    let originalSize = {width: 0, height: 0};
    zones.forEach(zone => {
      // eslint-disable-next-line camelcase
      labelConfigs[zone.id] = {...zone, shape_type: zone.shape_type};
      originalSize = {width: zone.canvas_width, height: zone.canvas_height};
    });

    return {originalSize, labelConfigs};
  }

  // This functions is used to map old zone options in properties attribute
  public setupPropertiesZones(zones: AddonZones): AddonZones {
    zones.zones = zones.zones.map(zone => {
      const properties = zone?.properties || ({} as any);

      if ((zone as any).triggerPoint) {
        switch ((zone as any).triggerPoint) {
          case 'Center' as TriggerPoint:
            (zone as any).triggerPoint = TriggerPoint.center;
            break;
          case 'Center Left' as TriggerPoint:
            (zone as any).triggerPoint = TriggerPoint.left;
            break;
          case 'Center Right' as TriggerPoint:
            (zone as any).triggerPoint = TriggerPoint.right;
            break;
          default:
            const triggerPointOldToken = (zone as any).triggerPoint.split(' ');
            if (triggerPointOldToken.length > 1) {
              (zone as any).triggerPoint = triggerPointOldToken
                .map(triggerToken => triggerToken.toLocaleLowerCase())
                .join('_') as TriggerPoint;
            }
            break;
        }
        properties.bbox_anchor = (zone as any).triggerPoint;
        properties.anchor_position = (zone as any).triggerPoint;
      }

      delete (zone as any).triggerPoint;

      return {...zone, properties: {...properties}};
    });
    return zones;
  }

  private generateZoneNames(zones: Zone[], newZone: Zone): {display_name: string; csv_label: string} {
    const availableZones = zones.filter(zone => !zone.removed);

    const prefixName = newZone.shape_type === ShapeTypes.line_in_out ? 'Line' : 'Zone';
    const display_name =
      newZone.shape_type === ShapeTypes.perspective ? `Area 1` : `${prefixName} ${availableZones.length + 1}`;
    const csv_label =
      newZone.shape_type === ShapeTypes.perspective
        ? `Area 1`
        : `${prefixName} ${availableZones.length + 1}`.replace(/\s/g, '_');

    return {display_name, csv_label};
  }
}

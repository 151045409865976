import {LatLngBoundsExpression} from 'leaflet';
import {LibraryMimeType} from './mime-type.model';

export interface ModelRequestOptions {
  is2D: boolean;
  is3D: boolean;
  isPC: boolean;
}

export enum ModelAction {
  modelling_2d = 'modelling_2d',
  modelling_3d = 'modelling_3d',
  object_detection = 'object_detection',
  modelling_video = 'modelling_video'
}

export interface ModelData {
  id: string;
  metadata: {bounds: LatLngBoundsExpression};
  name: string;
  parentId: string;
  s3Path: string;
  mimeType: LibraryMimeType;
  location: string;
}

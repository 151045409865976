import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  public goToKnowledgePage(): void {
    window.open('https://knowledge.unleashlive.com/', '_blank');
  }

  public goToContactUs(): void {
    window.open('https://knowledge.unleashlive.com/?chat=true', '_blank');
  }
}

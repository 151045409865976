import {Component, ChangeDetectionStrategy, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Team} from '@app/profile/models/team.model';

@Component({
  selector: 'unleash-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTeamComponent {
  @Output() public createTeam: EventEmitter<Team['name']> = new EventEmitter();
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();

  public form = this.fb.group({
    name: ['', Validators.required]
  });

  constructor(private fb: FormBuilder) {}

  public emitCreateTeam(): void {
    if (this.form.invalid) {
      this.form.controls.name.markAsTouched();
      return;
    }
    this.createTeam.emit(this.form.value.name);
  }

  public emitCancel(): void {
    this.cancel.emit();
  }

  public emitBack(): void {
    this.back.emit();
  }
}

<div
  #leafletMap
  class="map"
  (contextmenu)="$event.preventDefault()"
  leaflet
  [leafletOptions]="options"
  [leafletPanOptions]="panOptions"
  [leafletZoomOptions]="zoomOptions"
  [leafletZoomPanOptions]="zoomPanOptions"
  (leafletMapReady)="onMapReady($event)"
>
  <ng-container *ngFor="let l of assets$ | async">
    <!-- ALL LAYERS ARE ADDED TO MAP HERE -->
    <ng-container *ngIf="l.isDisplaying" [leafletLayer]="l.leafletLayer"></ng-container>
  </ng-container>
  <ng-container *ngFor="let gpsDevice of devices$ | async">
    <atlas-device
      [device]="gpsDevice"
      [hideTooltip]="true"
      [isStreaming$]="isDeviceStreaming$(gpsDevice)"
      (layersCreated)="addLayers($event)"
    ></atlas-device>
  </ng-container>
  <div class="custom-cursor in-front" id="map-cursor">
    <img [src]="iconUrl" class="custom-cursor__icon-map" />
    <span [class]="markerColor" class="custom-cursor__index">
      {{ currentIndex + 1 }}
    </span>
  </div>
</div>

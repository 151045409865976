import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {EMPTY, catchError, take, tap} from 'rxjs';

@Component({
  selector: 'unleash-new-job-dialog',
  templateUrl: './new-job-dialog.component.html',
  styleUrls: ['./new-job-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewJobDialog {
  public form: FormGroup = this.fb.group({
    name: ['', Validators.required]
  });
  public isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewJobDialog>,
    private jobsApiService: JobsApiService
  ) {}

  public continue(): void {
    if (this.form.valid) {
      this.isLoading = true;
      this.jobsApiService
        .createJob({title: this.form.value.name})
        .pipe(
          take(1),
          tap(response => this.dialogRef.close(response)),
          catchError(() => {
            this.dialogRef.close({error: true});
            return EMPTY;
          })
        )
        .subscribe();
    }
  }
}

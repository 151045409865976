import {Action, createReducer, on} from '@ngrx/store';
import * as actions from './package-selector.actions';
import {createFeatureSelector} from '@ngrx/store';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {PlanModel} from '@app/core/models/api/plan.model';
import {PlansByStepId} from '@app/plans/models/package-selector-steps.model';

export const packageSelectorFeatureKey = 'packageSelectorState';

export const selectPackageSelectorState = createFeatureSelector<PackageSelectorState>(packageSelectorFeatureKey);

export interface PackageSelectorState {
  isEssentialSelectionDisabled: boolean;
  combinedPlan?: PlanModel['id'];
  lastSelectedPlan?: PlanModel['id'];
  plansByStep?: PlansByStepId;
  plans: {[key: string]: PlanModel};
  totalPayment: number;
  currentStep: number;
  paymentPeriod: PaymentPeriod;
  firstProfessionalStepIndex: number;
  isSamePackage: boolean;
}

export const initialState: PackageSelectorState = {
  isEssentialSelectionDisabled: false,
  combinedPlan: undefined,
  plansByStep: {'0': null, '1': null, '2': null},
  lastSelectedPlan: null,
  plans: {},
  totalPayment: 0,
  currentStep: 0,
  paymentPeriod: PaymentPeriod.month,
  firstProfessionalStepIndex: 0,
  isSamePackage: false
};

const reducer = createReducer(
  initialState,
  on(actions.actionResetPackageSelector, state => initialState),
  on(actions.setCombinedPlan, (state, {payload}: {payload: {planId: PlanModel['id']}}) => {
    if (payload.planId) {
      const [name, level] = payload.planId.split('_');
      const PRO_LEVEL_PACKAGE = 2;
      const plan = state.plans[payload.planId];
      if (parseInt(level) > PRO_LEVEL_PACKAGE || plan?.reseller) {
        return {...state, combinedPlan: undefined};
      }
      return {
        ...state,
        combinedPlan: payload.planId
      };
    }
    return {
      ...state,
      combinedPlan: undefined
    };
  }),
  on(actions.disableEssentialSelection, state => ({
    ...state,
    isEssentialSelectionDisabled: true
  })),
  on(actions.enableEssentialSelection, state => ({
    ...state,
    isEssentialSelectionDisabled: false
  })),
  on(actions.setPlanOnStep, (state, {payload}: {payload: {stepIndex: number; planId: PlanModel['id']}}) => {
    if (!payload.planId) {
      return {...state, plansByStep: {...state.plansByStep, [payload.stepIndex]: null}};
    }

    const [, level] = payload.planId.split('_');
    const PRO_LEVEL_PACKAGE = 2;
    const plan = state.plans[payload.planId];
    let lastSelectedPlan = payload.planId;

    if (parseInt(level) > PRO_LEVEL_PACKAGE || plan?.reseller) {
      lastSelectedPlan = undefined;
    }

    const plansByStep = {...state.plansByStep, [payload.stepIndex]: payload.planId};

    if (parseInt(level) === PRO_LEVEL_PACKAGE) {
      Object.values(state.plansByStep).forEach((stepId, stepIndex) => {
        if (stepIndex === payload.stepIndex) {
          plansByStep[stepIndex] = payload.planId;
          return;
        }

        if (!stepId) {
          plansByStep[stepIndex] = stepId;
          return;
        }

        const [name, level] = stepId.split('_');
        if (parseInt(level) === 1) {
          plansByStep[stepIndex] = `${name}_2`;
        }
      });
    }

    return {
      ...state,
      plansByStep,
      lastSelectedPlan
    };
  }),
  on(actions.removePlanOnStep, (state, {payload}: {payload: {stepIndex: number}}) => {
    return {
      ...state,
      plansByStep: {...state.plansByStep, [payload.stepIndex]: null}
    };
  }),
  on(actions.upgradePlanOnStep, state => {
    const plansByStep = {} as PlansByStepId;

    Object.values(state.plansByStep).forEach((stepId, stepIndex) => {
      if (!stepId) {
        plansByStep[stepIndex] = null;
        return;
      }

      const [name] = stepId.split('_');
      plansByStep[stepIndex] = `${name}_2`;
    });

    return {
      ...state,
      plansByStep
    };
  }),
  on(actions.downgradePlanOnStep, state => {
    const plansByStep = {} as PlansByStepId;

    Object.values(state.plansByStep).forEach((stepId, stepIndex) => {
      if (!stepId) {
        plansByStep[stepIndex] = null;
        return;
      }

      const [name] = stepId.split('_');
      plansByStep[stepIndex] = `${name}_1`;
    });

    return {
      ...state,
      plansByStep
    };
  }),
  on(actions.setPlans, (state, {payload}: {payload: {plans: PlanModel[]}}) => {
    const plansById = {};
    payload.plans.forEach(plan => (plansById[plan.id] = plan));

    return {
      ...state,
      plans: plansById
    };
  }),
  on(actions.clearSelectedPlans, state => ({...state, selectedPlans: []})),
  on(actions.setTotalPayment, (state, {payload}: {payload: {totalPayment: number}}) => ({
    ...state,
    totalPayment: payload.totalPayment
  })),
  on(actions.setCurrentStep, (state, {payload}: {payload: {currentStep: number}}) => ({
    ...state,
    currentStep: payload.currentStep
  })),
  on(actions.setIsSamePackage, (state, {payload}: {payload: {isSamePackage: boolean}}) => ({
    ...state,
    isSamePackage: payload.isSamePackage
  })),
  on(actions.setPaymentPeriod, (state, {payload}: {payload: {paymentPeriod: PaymentPeriod}}) => ({
    ...state,
    paymentPeriod: payload.paymentPeriod
  }))
);

export function packageSelectorReducer(state: PackageSelectorState | undefined, action: Action): PackageSelectorState {
  return reducer(state, action);
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';
import {TeamTileScreen} from '../models/team-tile-screen.model';
import {Team, TeamAction, TeamActions, TeamRole, TeamTable, TeamTableActions} from '../models/team.model';
import {TranslateService} from '@ngx-translate/core';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {UserTable} from '@app/profile/models/users-table.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsAccountManagementService {
  public teams$: Observable<Team[]> = this.teamsManagementStoreFacadeService.teams$;
  public teamsTable$: Observable<TeamTable[]> = this.teamsManagementStoreFacadeService.teamsTable$;

  public teamScreen$: Observable<TeamTileScreen> = of(TeamTileScreen.teams);
  public selectedTeamTable$: Observable<TeamTable> = this.teamsManagementStoreFacadeService.selectedTeamTable$;
  public teamMenuActions: TeamTableActions[] = [
    {name: 'Edit', type: TeamActions.edit},
    {name: 'Delete', type: TeamActions.delete}
  ];
  public userMenuActions: TeamTableActions[] = [
    {name: 'Delete', type: TeamActions.delete}
    /* {name: 'Edit', type: UserActions.edit} */
  ];
  public roles: TeamRole[] = [TeamRole.admin, TeamRole.viewer, TeamRole.contributor];

  private filter: BehaviorSubject<string> = new BehaviorSubject('');
  private teamScreen: BehaviorSubject<TeamTileScreen> = new BehaviorSubject(TeamTileScreen.teams);
  public userBySelectedTeam$: Observable<UserTable[]> = this.teamsManagementStoreFacadeService.userBySelectedTeam$;

  constructor(
    private translateService: TranslateService,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService
  ) {
    this.teamsTable$ = combineLatest([this.teamsManagementStoreFacadeService.teamsTable$, this.filter]).pipe(
      map(([teams, filter]) => teams.filter(team => team.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
    );
    this.teamScreen$ = this.teamScreen.asObservable();

    this.translateService.get('common.delete').subscribe(
      res =>
        (this.userMenuActions = [
          {
            name: res,
            type: TeamActions.delete
          }
        ])
    );
  }

  public displayTeamsView(): void {
    this.teamScreen.next(TeamTileScreen.teams);
  }

  public displayAddTeamsView(): void {
    this.teamScreen.next(TeamTileScreen.addTeam);
    this.filter.next('');
  }

  public displayTeamDetails() {
    this.teamScreen.next(TeamTileScreen.teamDetails);
  }

  public hideTeamDetails() {
    this.teamScreen.next(TeamTileScreen.teams);
  }

  public addTeam(name: Team['name']) {
    this.teamsManagementStoreFacadeService.addTeam(name);
  }

  public filterTeams(search: string) {
    this.filter.next(search);
  }

  public selectTeam(team: TeamTable) {
    this.teamsManagementStoreFacadeService.setSelectedTeamId(team.teamId);
    this.displayTeamDetails();
    this.filter.next('');
  }

  public executeTeamAction(teamAction: TeamAction) {
    switch (teamAction.action) {
      case TeamActions.delete:
        this.teamsManagementStoreFacadeService.deleteTeam(teamAction.teamId);
        break;
      case TeamActions.edit:
        this.teamsManagementStoreFacadeService.editTeam(teamAction.teamId);
        break;
      default:
        break;
    }
  }

  public openAddUsersToTeam(): void {
    this.teamsManagementStoreFacadeService.openAddUsersToTeam();
  }

  public saveDashboard(data: any): void {
    this.teamsManagementStoreFacadeService.saveDashboard(data);
  }

  public uploadDashboardCover(file: File, companyId: string) {
    let presignedUrl;
    const presignedRequest$ = of('https://presigned-url.com/path-name'); //this.apiGateway.post('account/uploadImage', {}, {contentType: file.type, companyId, filename: file.name})

    return presignedRequest$.pipe(
      flatMap(url => {
        presignedUrl = url;
        return of('after-presigned'); // this.http.put(url, file);
      }),
      map(() => {
        const url = new URL(presignedUrl);
        const s3Path = decodeURIComponent(url.pathname.slice(1));
        return s3Path;
      })
    );
  }
}

import {Injectable} from '@angular/core';
import Bowser from 'bowser';

const bowser = Bowser.getParser(window.navigator.userAgent);

@Injectable({
  providedIn: 'root'
})
export class BrowserSettingsService {
  os: Bowser.Parser.OSDetails;
  browserName = '';
  browserVersion = '';
  isValidBrowser = true;
  isMacOS = false;

  private welcomeSeenKey = 'welcome-page-seen';
  private onboardingCompletedKey = 'onboarding-completed';
  private welcomePageUrl = '/welcome';
  private supportedBrowserSeenKey = 'supported-browser-seen';
  private hasFirstWelcomeKey = 'first-welcome';
  private LANDING_PAGE = '/secure/dashboard';

  constructor() {
    this.os = bowser.getOS();
    this.browserName = bowser.getBrowserName();
    this.browserVersion = bowser.getBrowserVersion();
    this.isValidBrowser = bowser.satisfies({
      chrome: '>=22',
      firefox: '>66',
      opera: '>57'
    });
    this.isMacOS = /iOS|macOS/i.test(bowser.getOSName());
  }

  getLandingPage() {
    if (this.getWelcomeSeenFlag() || !this.isOnboardingFinished()) {
      return this.LANDING_PAGE;
    }
    return this.welcomePageUrl;
  }

  setOnboardingFinished(flag: boolean) {
    window.localStorage[this.onboardingCompletedKey] = flag;
  }

  isOnboardingFinished() {
    return window.localStorage[this.onboardingCompletedKey] === 'true';
  }

  setWelcomeSeenFlag(flag: boolean) {
    window.localStorage[this.welcomeSeenKey] = flag;
  }

  getWelcomeSeenFlag() {
    return window.localStorage[this.welcomeSeenKey] === 'true';
  }

  setSupportedBrowserSeenFlag(flag: boolean) {
    window.localStorage[this.supportedBrowserSeenKey] = flag;
  }

  getSupportedBrowserFlag() {
    return window.localStorage[this.supportedBrowserSeenKey] === 'true';
  }

  getIsMobileBrowser() {
    return bowser.is('mobile') === true;
  }

  isIOSPWA(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return (
      browser.getPlatform().type === 'mobile' &&
      browser.getBrowserName().toLowerCase() === 'safari' &&
      !!window.navigator['standalone']
    );
  }

  public isIOS(): boolean {
    return bowser.getOSName() === 'iOS';
  }

  isSafari() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName().toLowerCase() === 'safari';
  }

  setHasFirstWelcome(flag: boolean) {
    window.localStorage[this.hasFirstWelcomeKey] = flag;
  }

  getHasFirstWelcome(): boolean {
    return window.localStorage[this.hasFirstWelcomeKey] === 'true';
  }
}

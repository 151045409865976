import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {atlasConfig, getSatelliteViewLayer} from '@app/atlas/atlas.config';
import {FlightFrame} from '@app/flights/components/flight-log/flight-log-parser';
import {createIcon} from '@app/flights/drone-icon';
import {
  Control,
  FitBoundsOptions,
  LatLng,
  latLng,
  LatLngExpression,
  Layer,
  Map,
  MapOptions,
  Marker,
  marker,
  MarkerOptions,
  polyline,
  Polyline
} from 'leaflet';
import 'leaflet-rotatedmarker';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'ua-flight-map',
  templateUrl: './flight-map.component.html',
  styleUrls: ['./flight-map.component.scss']
})
export class FlightMapComponent implements OnInit {
  @Input('flightFrames')
  set fillFlightFrames(flightFrames: FlightFrame[]) {
    if (!this.panned && flightFrames && flightFrames.length > 0) {
      this.flightFrames = flightFrames;
      const dronePosition = latLng(this.flightFrames[0].latitude, this.flightFrames[0].longitude);
      this.panTo(this.flightFrames[0], dronePosition);
    }
  }
  @Input() flightFrames$: Observable<FlightFrame>;
  @Input() timeControlsVisible: boolean = true;

  @Input() isSliderVisible: boolean = true;
  @Input() isZoomControlVisible: boolean = true;
  @Input() isBackButtonVisible: boolean = false;
  @Input() isCloseButtonVisible: boolean = false;
  @Input() hasExpander = false;
  @Input() hasToggle = false;
  @Input() deviceId = '';
  @Input() isPaused = false;
  @Output() backButton = new EventEmitter();
  @Output() closeButton = new EventEmitter();
  @Output() toggle = new EventEmitter();
  @Output() reconnectToMap = new EventEmitter();
  @Output() expander = new EventEmitter();

  flightFrames: FlightFrame[] = [];
  options: MapOptions;
  fitBoundsOptions: FitBoundsOptions = atlasConfig.FIT_BOUNDS_OPTIONS;
  map: Map;
  layers: Layer[];
  currentFrame: FlightFrame = {} as any;
  private droneMarker: Marker | any; // use any because of missing typing in leaflet-rotatedmarker
  private realTimePathPolyline: Polyline;
  private panned: boolean;

  ngOnInit() {
    this.initMap();
    this.subscribeToFlightFrames();
  }

  onMapReady(map: Map) {
    setTimeout(() => {
      map.invalidateSize();
      if (this.isZoomControlVisible) {
        map.addControl(new Control.Zoom({position: 'bottomleft'}));
      }
    }, 20); // see https://github.com/Asymmetrik/ngx-leaflet/issues/104
    this.map = map;
  }

  newFrame({frameNum, redraw}: {frameNum: number; redraw?: boolean}) {
    if (!this.flightFrames || this.flightFrames.length == 0) {
      console.warn('Empty frames information');
      return;
    }
    const frame = this.flightFrames[frameNum];
    if (!frame.latitude || !frame.longitude) {
      console.warn('Flight map: Missing latlng information');
      return;
    }
    const dronePosition = latLng(frame.latitude, frame.longitude);
    this.panTo(frame, dronePosition);
    this.droneMarker.setLatLng(dronePosition);
    this.droneMarker.setRotationAngle(frame.yaw);
    this.currentFrame = frame;

    if (redraw) {
      // used for jumping in time
      this.realTimePathPolyline.setLatLngs(
        this.flightFrames.slice(0, frameNum).map(f => latLng(f.latitude, f.longitude))
      );
    } else {
      if (frame.latitude === 0) {
        // console.warn('Skipping empty latlng' );
        return;
      }
      // just mark the next frame, don't redraw
      this.realTimePathPolyline.addLatLng(dronePosition);
    }
  }

  emitToggle() {
    this.toggle.emit(true);
  }

  emitReconnectToMap() {
    this.reconnectToMap.emit(true);
  }

  emitExpander() {
    this.expander.emit(true);
  }

  handleBackButton() {
    this.backButton.emit(true);
  }

  handleCloseButton() {
    this.closeButton.emit(true);
  }

  private getInitialMapCenter(): LatLng {
    if (!this.flightFrames || this.flightFrames.length == 0) {
      console.warn('Could not set map center');
      return latLng(0, 0);
    }
    const firstFrame = this.flightFrames[0];
    return latLng(firstFrame.latitude, firstFrame.longitude);
  }

  private initMap() {
    this.droneMarker = marker(this.getInitialMapCenter(), {
      icon: createIcon(false, 40),
      rotationOrigin: 'center center'
    } as MarkerOptions | any);

    this.realTimePathPolyline = polyline([], {
      color: '#ffff00',
      weight: 5,
      opacity: 1
    });
    this.layers = [getSatelliteViewLayer(), this.droneMarker, this.realTimePathPolyline];

    if (!!this.flightFrames) {
      const recordedPathPolyline = polyline(this.getRecorderPath(), {
        color: '#ffffff',
        weight: 4,
        opacity: 0.3
      });
      this.layers.push(recordedPathPolyline);
    }

    this.options = {
      attributionControl: false,
      zoomControl: false, // need to turn it off and add own in another position
      zoom: atlasConfig.INITIAL_ZOOM_LEVEL,
      center: this.getInitialMapCenter()
    };
  }

  private panTo(frame: FlightFrame, dronePosition: LatLng) {
    if (!this.panned && frame && this.map && !!frame.latitude && !!frame.longitude) {
      this.panned = true;
      this.map.panTo(dronePosition);
      this.droneMarker.setLatLng(dronePosition);
      this.droneMarker.setRotationAngle(frame.yaw);
      this.currentFrame = frame;
    }
  }

  private subscribeToFlightFrames() {
    !!this.flightFrames$ &&
      this.flightFrames$.pipe(filter(f => !!f)).subscribe((ff: FlightFrame) => {
        this.flightFrames.push(ff);
        this.newFrame({frameNum: this.flightFrames.length - 1});
      });
  }

  private getRecorderPath(): LatLngExpression[] {
    return this.flightFrames.map((frame: FlightFrame) => latLng(frame.latitude, frame.longitude));
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {MissionManagerService} from '../services/mission-manager.service';
import {Mission} from '../services/mission-settings.service';

@Directive({
  selector: '[ulExportMission]'
})
export class ExportMissionDirective {
  @Input('ulExportMission') public mission: Mission;
  @HostListener('click', ['$event'])
  public onExport() {
    this.missionManagerService.exportMission(this.mission);
  }
  constructor(private missionManagerService: MissionManagerService) {}
}

import {Injectable} from '@angular/core';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, map, zip} from 'rxjs';
import {DELETE_REPORT_TYPES, ParsedReport, ReportType} from '../models/analysis.model';
import {AnalysisFilter} from '../models/filter.model';
import {FilterAttributes, Paginator} from '../models/paginator.model';
import {ReportsStoreFacadeService} from '@app/analysis/services/reports-store-facade.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {Team} from '@app/profile/models/team.model';

@Injectable({
  providedIn: 'root'
})
export class AnalysisPageService {
  public filterForm$: BehaviorSubject<UntypedFormGroup>;
  public filter$: Observable<AnalysisFilter> = this.reportsStoreFacadeService.filter$;
  public reportsTypes$: Observable<ReportType[]> = this.reportsStoreFacadeService.reportsTypes$;
  public paginator$: Observable<Paginator> = this.reportsStoreFacadeService.paginator$;
  public displayedReports$: Observable<ParsedReport[]> = this.reportsStoreFacadeService.displayedReports$;
  public isLoading$: Observable<boolean> = this.reportsStoreFacadeService.isLoading$;
  public selectedReportsNumber$: Observable<number> = this.reportsStoreFacadeService.selectedReportsNumber$;
  public selectSelectedReportsId$: Observable<string[]> = this.reportsStoreFacadeService.selectSelectedReportsId$;
  public activeFilters$: Observable<number> = this.reportsStoreFacadeService.activeFilters$;

  public isLoadingApiKey$: Observable<boolean> = this.reportsStoreFacadeService.isLoadingApiKey$;
  public apiKey$: Observable<string> = this.reportsStoreFacadeService.apiKey$;
  public userEmail$: Observable<string> = this.userStoreFacadeService.currentUser$.pipe(map(user => user.email));
  public currentTeamId$: Observable<string> = this.userStoreFacadeService.currentUser$.pipe(
    map(user => user.activeTeamAndCompany.teamId)
  );
  public teams$: Observable<Team[]> = this.teamsManagementStoreFacadeService.teams$;
  public currentDashboard$ = zip(this.currentTeamId$, this.teams$).pipe(
    map(([teamId, teams]) => teams.find(team => team.id === teamId)),
    map(team => team.dashboards)
  );
  public isApiKeyActionInProgress$: Observable<boolean> = this.reportsStoreFacadeService.isApiKeyActionInProgress$;

  constructor(
    private fb: FormBuilder,
    private reportsStoreFacadeService: ReportsStoreFacadeService,
    private userStoreFacadeService: UserStoreFacadeService,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService
  ) {
    this.filterForm$ = new BehaviorSubject(
      this.fb.group({
        from: '',
        to: '',
        timeFrom: '',
        timeTo: '',
        search: '',
        type: '',
        status: ''
      })
    );
  }

  public fetchAllReports(): void {
    this.reportsStoreFacadeService.fetchAllReports();
  }

  public performSort(sortOptions: FilterAttributes): void {
    this.reportsStoreFacadeService.performSort(sortOptions);
  }

  public performPagination(sortOptions: {pageIndex: number; pageSize: number}): void {
    this.reportsStoreFacadeService.performPagination(sortOptions);
  }

  public filterAnalysis(analysisFilter: AnalysisFilter): void {
    this.reportsStoreFacadeService.filterAnalysis(analysisFilter);
  }

  public clearFilter(): void {
    this.reportsStoreFacadeService.clearFilter();
  }

  public deleteAnalysisReports(sessionIds: string[], deleteReportType: DELETE_REPORT_TYPES): void {
    this.reportsStoreFacadeService.deleteAnalysisReports(sessionIds, deleteReportType);
  }

  public toggleSelectedReports(report: ParsedReport): void {
    this.reportsStoreFacadeService.toggleSelectedReports(report);
  }

  public clearSelectedReports(): void {
    this.reportsStoreFacadeService.clearSelectedReports();
  }

  public openMobileFilterDialog(): void {
    this.reportsStoreFacadeService.openMobileFilterDialog();
  }

  public hideFooter(hasToHideFooter: boolean): void {
    this.reportsStoreFacadeService.hideFooter(hasToHideFooter);
  }

  public generateApiKey(): void {
    this.reportsStoreFacadeService.generateApiKey();
  }

  public copyKey(): void {
    this.reportsStoreFacadeService.copyKey();
  }

  public removeKeyDialog(): void {
    this.reportsStoreFacadeService.removeKeyDialog();
  }

  public updateKeyDialog(): void {
    this.reportsStoreFacadeService.updateKeyDialog();
  }

  public updateKey(): void {
    this.reportsStoreFacadeService.updateKey();
  }

  public removeKey(): void {
    this.reportsStoreFacadeService.removeKey();
  }
}

import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Mission} from '@app/atlas/services/mission-settings.service';

@Component({
  selector: 'unleash-survey-mission-info',
  templateUrl: './survey-mission-info.component.html',
  styleUrls: ['./survey-mission-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyMissionInfoComponent implements OnInit {
  @Input() public mission: Mission = null;
  public routeSettings = [];

  public ngOnInit(): void {
    this.routeSettings = [
      {
        setting: 'Drone',
        value: this.checkDrones(this.mission?.missionConfig?.droneInfo?.droneEnumValue)
      },
      {
        setting: 'Camera',
        value: this.checkCameras(this.mission?.missionConfig?.payloadInfo?.payloadEnumValue)
      },
      {
        setting: 'Altitude (WGS)',
        value: this.mission?.surveySettings?.globalShootHeight,
        unit: 'm'
      },
      {
        setting: 'Flight speed (meters / sec)',
        value: this.mission?.missionConfig?.globalTransitionalSpeed,
        unit: 'm/s'
      },
      {
        setting: 'Gimbal angle (°)',
        value: this.mission?.surveySettings?.polygon?.smartObliqueGimbalPitch,
        unit: '°'
      },
      {
        setting: 'Course angle (°)',
        value: this.mission?.surveySettings?.polygon?.direction,
        unit: '°'
      },
      {
        setting: 'Side overlap (%)',
        value: this.mission?.surveySettings?.polygon?.overlap?.orthoCameraOverlapW,
        unit: '%'
      },
      {
        setting: 'Forward overlap (%)',
        value: this.mission?.surveySettings?.polygon?.overlap?.orthoCameraOverlapH,
        unit: '%'
      }
    ];
  }

  private checkDrones(drone: number): void {
    const drones = {
      89: 'M350 RTK',
      60: 'M300 RTK',
      67: 'M30/M30T',
      77: 'M3E/M3T/M3M',
      91: 'M3D/M3TD'
    };
    return drones[drone];
  }

  private checkCameras(camera: number): void {
    const cameras = {
      42: 'H20',
      43: 'H20T',
      52: 'M30',
      53: 'M30T',
      61: 'H20N',
      66: 'Mavic 3E Camera',
      67: 'Mavic 3T Camera',
      68: ' Mavic 3M Camera',
      65534: 'PSDK Payload Device'
    };
    return cameras[camera];
  }
}

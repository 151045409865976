import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {BrowserSettingsService} from '../../services/browser-settings.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  events = EVENTS;
  hasFirstWelcome: boolean;
  steps = [1, 2, 3, 4, 5];
  private STEP_NAME = ['GET_STARTED', 'LIVE_STREAM', 'MODELS', 'LIBRARY', 'AI_APP_STORE', 'SETTINGS'];

  constructor(
    public browserSettingsService: BrowserSettingsService,
    private unleashAnalytics: UnleashAnalyticsService,
    private matDialogRef: MatDialogRef<WelcomePageComponent>,
    private route: ActivatedRoute
  ) {}
  ngOnDestroy(): void {
    this.browserSettingsService.setHasFirstWelcome(true);
  }

  ngOnInit() {
    this.hasFirstWelcome = this.browserSettingsService.getHasFirstWelcome();
    if (this.hasFirstWelcome) {
      this.steps = [1, 2, 3, 4];
    }
    this.browserSettingsService.setWelcomeSeenFlag(true);
  }

  close() {
    this.browserSettingsService.setWelcomeSeenFlag(true);
    this.matDialogRef.close();
  }

  onSkipClicked() {
    this.browserSettingsService.setWelcomeSeenFlag(true);
  }

  onStepChange(event: any): void {
    this.unleashAnalytics.listenAsQueryParam(this.route, {
      type: `WELCOME_${this.STEP_NAME[event.selectedIndex]}`
    });
  }
}

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {PayloadAction} from '@app/shared/models/payload-action';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {AddonService} from '@app/core/services/api/addon.service';
import {Addon} from '@app/store/addon/models/addon';
import {CreateAiAppDialogComponent} from '@app/developer-dashboard/ai-apps/shared/components/create-ai-app-dialog/create-ai-app-dialog.component';
import {DeleteAiAppDialogComponent} from '@app/developer-dashboard/ai-apps/shared/components/delete-ai-app-dialog/delete-ai-app-dialog.component';
import {RenameAiAppDialogComponent} from '@app/developer-dashboard/ai-apps/shared/components/rename-ai-app-dialog/rename-ai-app-dialog.component';
import {UnPublishAiAppDialogComponent} from '@app/developer-dashboard/ai-apps/shared/components/unpublish-ai-app-dialog/un-publish-ai-app-dialog.component';

import {ApiAddonListParams} from '@app/store/addon/models/api-list-params';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, merge, of, switchMap, tap, withLatestFrom, zip} from 'rxjs';
import * as actions from './addon.actions';
import {RouterFacadeStoreService} from '@app/core/services/api/router-store-facade.service';

@Injectable({
  providedIn: 'root'
})
export class AddonEffects {
  public loadAddons$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadAddons),
        switchMap(({payload}: PayloadAction<{params: ApiAddonListParams}>) => {
          return this.addonService.loadList(payload.params);
        }),
        tap((addons: Addon[]) => {
          this.addonStoreFacadeService.loadListSuccess(addons);
        }),
        catchError(err => of(err))
      ),
    {dispatch: false}
  );

  public loadAddon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadAddon),
        switchMap(({payload}: PayloadAction<{addonId: Addon['id']}>) =>
          zip(
            this.addonStoreFacadeService.publishedAddonsObject$.pipe(map(addons => addons[payload.addonId])),
            of(payload.addonId)
          )
        ),
        switchMap(([addon, addonId]: [Addon, Addon['id']]) => {
          return addon ? of(addon) : this.addonService.loadDetails(addonId);
        }),
        tap((addon: Addon) => {
          this.addonStoreFacadeService.loadAddonSuccess(addon);
        })
      ),
    {dispatch: false}
  );

  public loadMyInDevelopmentAddons$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadMyInDevelopmentAddons),
        switchMap(() => this.addonService.loadList({mine: 1})),
        tap((addons: Addon[]) => {
          this.addonStoreFacadeService.loadMyInDevelopmentAddonsSuccess(addons);
        }),
        catchError(err => of(err))
      ),
    {dispatch: false}
  );

  public loadPublishedAddons$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadPublishedAddons),
        switchMap(() => this.addonService.loadList()),
        tap((addons: Addon[]) => {
          this.addonStoreFacadeService.loadPublishedAddonsSuccess(addons);
        }),
        catchError(err => of(err))
      ),
    {dispatch: false}
  );

  public createAiApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.createAiApp),
        switchMap(() =>
          this.dialog
            .open(CreateAiAppDialogComponent, {
              maxWidth: '530px',
              width: '90vw'
            })
            .afterClosed()
        ),
        filter(addon => !!addon),
        tap(() => this.addonStoreFacadeService.startLoading()),
        switchMap(addon => this.addonService.add(addon)),
        tap((addon: Addon) => this.addonStoreFacadeService.createAiAppSuccess(addon))
      ),
    {dispatch: false}
  );

  public renameAiApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.renameAiApp),
        switchMap(({payload}: PayloadAction<Pick<Addon, 'id' | 'name'>>) => {
          return this.dialog
            .open(RenameAiAppDialogComponent, {
              data: payload,
              maxWidth: '530px',
              width: '90vw'
            })
            .afterClosed();
        }),
        filter(data => !!data),
        withLatestFrom(this.addonStoreFacadeService.addons$),
        switchMap(([data, addons]) =>
          merge(
            this.addonService.update(data.id, {...addons[data.id], name: data.name}).pipe(
              catchError(() => {
                console.error('Update addon fail');
                return of({...addons[data.id]});
              })
            ),
            of({...addons[data.id], name: data.name})
          )
        ),
        tap((addon: Addon) => this.addonStoreFacadeService.renameAiAppSuccess(addon))
      ),
    {dispatch: false}
  );

  public deleteAiApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteAiApp),
        switchMap(({payload}: PayloadAction<{addon: Pick<Addon, 'id' | 'name' | 'usersCount'>}>) => {
          return this.dialog
            .open<DeleteAiAppDialogComponent, Pick<Addon, 'id' | 'name' | 'usersCount'>, Addon['id']>(
              DeleteAiAppDialogComponent,
              {
                data: payload.addon,
                maxWidth: '800px',
                width: '90vw'
              }
            )
            .afterClosed();
        }),
        filter(addonId => !!addonId),
        switchMap(addonId => merge(this.addonService.remove(addonId).pipe(map(() => addonId)), of(addonId))),
        tap((addonId: string) =>
          this.addonStoreFacadeService.deleteAiAppSuccess(addonId as unknown as Pick<Addon, 'id'>)
        )
      ),
    {dispatch: false}
  );

  public deleteAiAppSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteAiAppSuccess),
        tap(() => this.router.navigate(['/secure/developer-dashboard/ai-apps']))
      ),
    {dispatch: false}
  );

  public startLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadMyInDevelopmentAddons, actions.loadAddon, actions.loadPublishedAddons),
        tap(() => this.addonStoreFacadeService.startLoading())
      ),
    {dispatch: false}
  );

  public stopLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actions.loadMyInDevelopmentAddonsSuccess,
          actions.createAiAppSuccess,
          actions.loadAddonSuccess,
          actions.loadPublishedAddonsSuccess
        ),
        tap(() => this.addonStoreFacadeService.stopLoading())
      ),
    {dispatch: false}
  );

  public unPublishAiApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.unPublishAiApp),
        switchMap(({payload}: PayloadAction<{addon: Pick<Addon, 'id' | 'name' | 'usersCount'>}>) =>
          this.dialog
            .open(UnPublishAiAppDialogComponent, {
              data: payload,
              maxWidth: '800px',
              width: '90vw'
            })
            .afterClosed()
        ),
        filter(data => !!data),
        // TODO: Check if all is working correctly.
        switchMap(addonId => merge(this.addonService.unPublish(addonId).pipe(map(() => addonId)), of(addonId))),
        tap(addonId => this.addonStoreFacadeService.unPublishAiAppSuccess(addonId))
      ),
    {dispatch: false}
  );

  public submitForReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.submitForReview),
        switchMap(({payload}: PayloadAction<{addon: Addon}>) =>
          merge(
            this.addonService.submitToReview(payload.addon.id).pipe(map(() => payload.addon.id)),
            of(payload.addon.id)
          )
        ),
        tap((addonId: Addon['id']) => this.addonStoreFacadeService.submitForReviewSuccess(addonId))
      ),
    {dispatch: false}
  );

  public cancelReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.cancelReview),
        switchMap(({payload}: PayloadAction<{addonId: Addon['id']}>) =>
          merge(
            this.addonService.withdrawFromReview(payload.addonId).pipe(map(() => payload.addonId)),
            of(payload.addonId)
          )
        ),
        tap((addonId: Addon['id']) => this.addonStoreFacadeService.cancelReviewSuccess(addonId))
      ),
    {dispatch: false}
  );

  public updateAddon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateAddon),
        switchMap(({payload}: PayloadAction<{addon: Addon}>) =>
          merge(
            // this.addonService.update(payload.addon.id, payload.addon).pipe(map(() => payload.addon)),
            of(payload.addon)
          )
        ),

        // switchMap(({payload}: PayloadAction<{addon: Addon}>) => merge(of(payload.addon))),
        tap((addon: Addon) => {
          this.addonStoreFacadeService.updateAddonSuccess(addon);
        })
      ),
    {dispatch: false}
  );

  public watchAppStoreQueryParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.watchAppStoreQueryParams),
        switchMap(() => this.routerFacadeStoreService.selectRouteParams()),
        tap(({queryParams}) => {
          if (queryParams['clearFilters']) {
            this.addonStoreFacadeService.clearStoreFilters();
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {},
              skipLocationChange: false,
              preserveFragment: false,
              replaceUrl: true
            });
          }
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private addonService: AddonService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private dialog: MatDialog,
    private routerFacadeStoreService: RouterFacadeStoreService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

import {Component, ChangeDetectionStrategy, EventEmitter, Input, Output} from '@angular/core';
import {TeamTileScreen} from '@app/profile/models/team-tile-screen.model';
import {Team, TeamAction, TeamRole, TeamTable} from '@app/profile/models/team.model';
import {UserTable, UserTableActions} from '@app/profile/models/users-table.model';

@Component({
  selector: 'unleash-teams-tile',
  templateUrl: './teams-tile.component.html',
  styleUrls: ['./teams-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamsTileComponent {
  @Input() public teams: TeamTable[] = [];
  @Input() public selectedTeam: TeamTable | null = null;
  @Input() public menuItems: UserTableActions[] = [];
  @Input() public teamScreen: TeamTileScreen = TeamTileScreen.teams;
  @Input() public userBySelectedTeam: UserTable[] = [];
  @Input() public roles: TeamRole[];
  @Input() public hasMoreThanOneAdmin: boolean = false;
  @Output() public addTeam: EventEmitter<void> = new EventEmitter();
  @Output() public searchTeam: EventEmitter<string> = new EventEmitter();
  @Output() public createTeam: EventEmitter<Team['name']> = new EventEmitter();
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public back: EventEmitter<void> = new EventEmitter();
  @Output() public team: EventEmitter<TeamTable> = new EventEmitter();
  @Output() public action: EventEmitter<TeamAction> = new EventEmitter();
  @Output() public openAddUsersToTeam: EventEmitter<void> = new EventEmitter();
  @Output() public userTeamAction = new EventEmitter<UserTableActions>();
  @Output() public userAction: EventEmitter<UserTableActions> = new EventEmitter();

  public teamTileScreen = TeamTileScreen;

  public performSearch(search: string): void {
    this.searchTeam.emit(search);
  }

  public emitAddTeam(): void {
    this.addTeam.emit();
  }

  public emitCreateTeam(name: Team['name']): void {
    this.createTeam.emit(name);
  }

  public emitCancel(): void {
    this.cancel.emit();
  }

  public emitBack(): void {
    this.back.emit();
  }

  public emitTeam(team: TeamTable): void {
    this.team.emit(team);
  }

  public emitAction(action: TeamAction): void {
    this.action.emit(action);
  }

  public emitOpenAddUsersToTeam(): void {
    this.openAddUsersToTeam.emit();
  }

  public emitUserTeamAction($event): void {
    this.userTeamAction.emit($event);
  }

  public emitUserAction($event): void {
    this.userAction.emit($event);
  }
}

<h1 mat-dialog-title>{{ 'profile.devices.addDeviceOverLimit' | translate }}</h1>
<div mat-dialog-content>
  {{ 'profile.devices.addDeviceOverLimitDescription' | translate }}
</div>
<div mat-dialog-actions>
  <button mat-button color="primary" [mat-dialog-close]="false" tabindex="0">
    {{ 'common.cancel' | translate | uppercase }}
  </button>
  <button color="primary" mat-button tabindex="1" [mat-dialog-close]="true" tabindex="1">
    {{ 'profile.devices.addDevice' | translate | uppercase }}
  </button>
</div>

import {Injectable} from '@angular/core';
import {MissionModel, MissionType} from '@app/atlas/model/mission.model';
import {Mission, MissionRoutePoint} from '@app/atlas/services/mission-settings.service';

@Injectable({
  providedIn: 'root'
})
export class MissionBuilderService {
  public buildMissionFromWaypoints(mission?: MissionModel): Mission {
    const routePoints: MissionRoutePoint[] = mission.route.map(point => {
      return new MissionRoutePoint(
        point.lat,
        point.lng,
        point.altitude,
        point.speed,
        point.heading,
        point.pitch,
        point.actions,
        point.altitudeWGS,
        point.altitudeEGM
      );
    });
    return new Mission(mission.name, routePoints, true, mission.type === MissionType.SMART_INSPECT, mission);
  }

  validate(point, properties = ['latitude', 'longitude', 'altitude', 'speed', 'heading', 'pitch']) {
    properties.forEach(p => {
      if (point[p] === undefined || point[p] === null) {
        throw new Error(`Empty ${p} provided`);
      }

      if (isNaN(parseFloat(point[p]))) {
        throw new Error(`${p} is not a number`);
      }
    });
  }
}

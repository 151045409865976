import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectRouterState} from '@app/store/router/router.selector';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, switchMap, take} from 'rxjs/operators';
import {Stream} from '../../models/api/stream.model';
import {UserModel} from '../../models/api/user-model';
import {NetworkStatusService} from '../network-status.service';
import {RestreamService} from './restream.service';

@Injectable({providedIn: 'root'})
export class StatusService {
  readonly liveDeviceIdArr$: Observable<string[]>;
  readonly streams: Observable<Stream[]>;
  readonly restreams: Observable<Stream[]>;

  private liveDeviceIdArr = new BehaviorSubject<string[]>([]);
  private _streams = new ReplaySubject<any[]>(1);
  private _restreams = new ReplaySubject<Stream[]>(1);

  private networkStatusSub: Subscription;

  private dataStore: {
    streams: Stream[];
    restreams: Stream[];
  } = {
    streams: [],
    restreams: []
  };

  constructor(
    private restreamService: RestreamService,
    private networkStatusService: NetworkStatusService,
    private store: Store
  ) {
    this.liveDeviceIdArr$ = this.liveDeviceIdArr.asObservable();
    this.streams = this._streams.asObservable();
    this.restreams = this._restreams.asObservable();
  }

  clearDataStore() {
    this.dataStore = {
      streams: [],
      restreams: []
    };
    this.propagateDataStore();
  }

  startPolling(config: {user: UserModel; startInterval: number; endInterval: number}): void {
    this.stopPolling();
    if (!config.user) {
      console.warn('no user id to poll active devices');
      return;
    }
    this.pollActiveStreamsTimer$(config.startInterval, config.endInterval, config.user.streamKey);
  }

  stopPolling() {
    if (this.networkStatusSub) {
      this.networkStatusSub.unsubscribe();
    }
  }

  getStreamById(deviceId) {
    return this.dataStore.streams.find(s => s.deviceId === deviceId);
  }

  addStream(newStream: Stream) {
    this.dataStore.streams.push(newStream);
    this.propagateDataStore();
  }

  public getActiveStream(streamKey: UserModel['streamKey']): void {
    this.restreamService
      .getActiveStreams(streamKey)
      .pipe(take(1))
      .subscribe(
        (allStreams: Stream[]) => this.updateStreamDataStore(allStreams),
        error => console.error(`error on pollActiveStreams: ${error}`)
      );
  }

  public updateStreamDataStore(allStreams: Stream[]) {
    this.dataStore.restreams = allStreams.filter(stream => stream.type === 'TO' || stream.type === 'FROM');
    const {liveDeviceIdArr, streams} = this.transformStreamsIntoStatusArrays(allStreams);
    this.dataStore.streams = streams;
    this.liveDeviceIdArr.next(liveDeviceIdArr);
    this.propagateDataStore();
  }

  public getStreamsDataStore() {
    return this.dataStore.streams;
  }

  private pollActiveStreamsTimer$(startInterval: number, endInterval: number, streamKey: string) {
    this.networkStatusSub = this.networkStatusService
      .isOnlineTimer(startInterval, endInterval)
      .pipe(
        filter(isOnline => !!isOnline),
        switchMap(() => this.store.pipe(select(selectRouterState), take(1))),
        filter(router => /live|atlas/.test(router.url)),
        switchMap(() => this.restreamService.getActiveStreams(streamKey).pipe(take(1))),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(
        (allStreams: Stream[]) => this.updateStreamDataStore(allStreams),
        error => console.error(`error on pollActiveStreams: ${error}`)
      );
  }

  private transformStreamsIntoStatusArrays(streams: Stream[]) {
    const rawStreams = streams.filter(stream => stream.type === 'RAW');
    const aiStreams = streams.filter(stream => stream.type === 'AI');
    const liveDeviceIdArr: string[] = rawStreams.map(item => item.deviceId);
    const streamsArr = rawStreams.map(rawStream => {
      return {
        deviceId: rawStream.deviceId,
        player: rawStream.player,
        streamId: rawStream.streamId,
        models: aiStreams
          .filter(aiStream => aiStream.deviceId === rawStream.deviceId)
          .map(aiStream => aiStream.modelId),
        type: 'RAW'
      };
    }) as unknown as Stream[];
    return {liveDeviceIdArr, streams: streamsArr};
  }

  private propagateDataStore() {
    this._streams.next({...this.dataStore}.streams);
    this._restreams.next({...this.dataStore}.restreams);
  }
}

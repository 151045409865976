import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';

@Component({
  selector: 'unleash-header-stream',
  templateUrl: './header-stream.component.html',
  styleUrls: ['./header-stream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderStreamComponent {
  @Output() public addDevice: EventEmitter<void> = new EventEmitter();
  public aclPermissions: typeof AclPermissions = AclPermissions;

  public emitAddDevice(): void {
    this.addDevice.emit();
  }
}

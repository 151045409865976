import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import {
  actionForgotPasswordFormError,
  actionForgotPasswordFormSubmit,
  actionForgotPasswordFormSuccess
} from '@app/auth/components/forgot-password/forgot-password.actions';
import {ForgotPassword} from '@app/auth/components/forgot-password/forgot-password.model';
import {
  selectForgotPasswordFormInProgress,
  selectSignInEmail
} from '@app/auth/components/forgot-password/forgot-password.selectors';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {from, Observable, zip} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss']
})
export class ForgotPasswordPage implements OnInit {
  isInProgress$: Observable<boolean>;
  savedEmail$: Observable<string>;
  public isDeveloperMode = this.route.snapshot.data.isDeveloperMode;

  constructor(private router: Router, private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.isInProgress$ = this.store.pipe(select(selectForgotPasswordFormInProgress));
    this.savedEmail$ = zip([this.store.pipe(select(selectSignInEmail)), from(Auth.currentUserInfo())]).pipe(
      map(([email, user]) => {
        if (email) {
          return email;
        }
        if (user) {
          return user.attributes.email;
        }
        return '';
      })
    );
  }

  backLastLocation() {
    const backTo = history.state.backTo
      ? history.state.backTo
      : this.isDeveloperMode
      ? '/auth/developer-sign-in'
      : '/auth/sign-in';
    this.router.navigate([backTo]);
  }

  continue(event: ForgotPassword) {
    this.store.dispatch(actionForgotPasswordFormSubmit({form: event}));
    Auth.forgotPassword(event.email)
      .then(() => {
        this.store.dispatch(actionForgotPasswordFormSuccess());
        this.router.navigate([`/auth/${this.isDeveloperMode ? 'developer-' : ''}change-password`]);
      })
      .catch((error: AuthErrorModel) => {
        this.store.dispatch(actionForgotPasswordFormError({error}));
      });
  }
}

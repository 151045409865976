import {createAction, props} from '@ngrx/store';
import {NotificationModel} from '@app/core/models/api/notifications.model';

export const enum NotificationsActionsTypes {
  LOAD = '[Notifications] Load request',
  LOAD_SUCCESS = '[Notifications] Load success',
  LOAD_FAILURE = '[Notifications] Load failure',
  NEW_NOTIFICATION = '[Notifications] New notification incoming',
  NOTIFICATION_PROGRESS_UPDATE = '[Notifications] Notification progress update',
  NOTIFICATION_UPDATE = '[Notifications] Notification update',
  HIDE_NOTIFICATION = '[Notifications] Hide notification',
  UNREAD_COUNT_LOADED = '[Notifications] Unread notifications counter loaded',
  SET_AS_READ = '[Notification] Set notification as read',
  SET_AS_UNREAD = '[Notification] Set notification as unread',
  LOAD_MORE = '[Notifications] Load more notifications',
  DELETE = '[Notifications] Delete notification',
  SUCCESS_DELETE = '[Notifications] Success delete notification',
  CLEAR_NOTIFICATIONS = '[Notifications] Clear notifications'
}

export const actionLoadNotifications = createAction(NotificationsActionsTypes.LOAD);

export const actionLoadNotificationsSuccess = createAction(
  NotificationsActionsTypes.LOAD_SUCCESS,
  props<{payload: NotificationModel[]}>()
);
export const actionLoadNotificationsFailure = createAction(
  NotificationsActionsTypes.LOAD_FAILURE,
  props<{payload: {error: string}}>()
);

export const actionNewNotification = createAction(
  NotificationsActionsTypes.NEW_NOTIFICATION,
  props<{payload: NotificationModel}>()
);

export const actionShowNotificationProgressUpdate = createAction(
  NotificationsActionsTypes.NOTIFICATION_PROGRESS_UPDATE,
  props<{payload: NotificationModel}>()
);

export const actionUpdateNotification = createAction(
  NotificationsActionsTypes.NOTIFICATION_UPDATE,
  props<{payload: NotificationModel}>()
);

export const actionHideNotification = createAction(NotificationsActionsTypes.HIDE_NOTIFICATION);

export const actionUnreadCountLoaded = createAction(
  NotificationsActionsTypes.UNREAD_COUNT_LOADED,
  props<{payload: number}>()
);

export const actionSetNotificationAsRead = createAction(
  NotificationsActionsTypes.SET_AS_READ,
  props<{payload: NotificationModel}>()
);

export const actionRemoveNotification = createAction(
  NotificationsActionsTypes.DELETE,
  props<{payload: NotificationModel}>()
);

export const actionLoadMoreNotifications = createAction(
  NotificationsActionsTypes.LOAD_MORE,
  props<{inProgress: boolean}>()
);

export const actionClearNotifications = createAction(NotificationsActionsTypes.CLEAR_NOTIFICATIONS);

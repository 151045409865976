import {Injectable} from '@angular/core';
import {Mission} from '@app/atlas/services/mission-settings.service';
import {MissionModel, MissionPoint, MissionType} from '@app/atlas/model/mission.model';
import {MissionBuilderService} from '@app/flights/services/mission-builder.service';

interface Waypoint {
  Index: string;
  Type: string;
  Latitude: string;
  Longitude: string;
  Altitude: string;
  GimbalPitch: string;
  Azimuth: string;
}

@Injectable({
  providedIn: 'root'
})
export class MissionImportCSVService {
  constructor(private missionBuilder: MissionBuilderService) {}
  public doImport(csvString: string): Mission {
    const waypoints = this.parseCSVToWaypoints(csvString);
    const mission: MissionModel = this.mapMission(waypoints);
    return this.missionBuilder.buildMissionFromWaypoints(mission);
  }

  private mapMission(waypoints: Waypoint[]) {
    return {
      name: 'Drone Harmony Mission',
      route: this.mapPoints(waypoints),
      type: MissionType.DRONE_HARMONY
    };
  }

  // Based on
  // https://stackoverflow.com/questions/27979002/convert-csv-data-into-json-format-using-javascript#answer-27979069
  private parseCSVToWaypoints(csv: string): Waypoint[] {
    csv = this.removeSpecialCharacters(csv);
    let lines = csv.split('\n');
    let result: Waypoint[] = [];
    let headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(',');
      if (currentline.length <= 1) continue; //skip empty line
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj as Waypoint);
    }
    return result;
  }

  private removeSpecialCharacters(csv: string) {
    // get only allowed characters: letters, space, new line, minus, comma and dot
    return csv.replace(/[^\w,\n -.]/gi, '');
  }

  private mapPoints(waypoints: Waypoint[]): MissionPoint[] {
    return waypoints
      .map((w: Waypoint) => {
        if (w.Type === 'liftoff' || w.Type === 'landing') {
          return null;
        }

        const point = {
          lat: parseFloat(w.Latitude.toString()),
          lng: parseFloat(w.Longitude.toString()),
          altitude: parseFloat(w.Altitude.toString()),
          heading: parseFloat(w.Azimuth.toString()),
          pitch: parseFloat(w.GimbalPitch.toString()),
          actions: []
        };
        this.missionBuilder.validate(point, ['lat', 'lng', 'altitude', 'heading', 'pitch']);
        return point;
      })
      .filter((waypoint: MissionPoint) => waypoint !== null);
  }
}

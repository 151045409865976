<div [class]="'unleash-alert unleash-alert--' + type">
  <div class="unleash-alert__message">
    <mat-icon *ngIf="iconName" [color]="type">{{ iconName }}</mat-icon>
    <div [class]="'unleash-alert__label unleash-alert__label--' + type" [innerHTML]="label"></div>
  </div>
  <div class="unleash-alert__button-container" *ngIf="buttonText">
    <button [class]="'unleash-alert__button' + ' ' + buttonClass" [color]="type" mat-button (click)="onClick()">
      <mat-icon *ngIf="buttonIcon" class="unleash-alert__button-icon">{{ buttonIcon }}</mat-icon>
      {{ buttonText }}
    </button>
  </div>
</div>

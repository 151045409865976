import {Injectable} from '@angular/core';
import {AclStoreFacadeService} from '@app/core/services/acl-store-facade.service';
import {AclService} from '@app/core/services/acl.service';
import {AclApiService} from '@app/core/services/api/acl-api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, switchMap, tap} from 'rxjs';
import * as actions from './acl.actions';

@Injectable({
  providedIn: 'root'
})
export class AclEffects {
  public requestACLConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.requestACLConfig),
        switchMap(() => this.aclApiService.acl$()),
        tap(res => {
          this.aclStoreFacadeService.requestAclConfigSuccess(res);
          this.aclService.setPermissions(res);
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private aclStoreFacadeService: AclStoreFacadeService,
    private aclService: AclService,
    private aclApiService: AclApiService
  ) {}
}

import {HttpErrorResponse} from '@angular/common/http';
import {AddonCategoriesFilters} from '@app/library/models/addon-categories-filters';
import {LibraryItem} from '@app/library/models/folder-file.model';
import {AnnotationsSummaryData} from '@app/library/models/library-api-list-response.model';
import {Tag} from '@app/library/models/tag.model';
import {Addon} from '@app/store/addon/models/addon';
import {createAction, props} from '@ngrx/store';

export enum AnnotationsSummaryActionTypes {
  StartOpenAnnotationSummaryOneItem = '[AnnotationsSummary] Start Open Annotation Summary One Item',
  StartOpenAnnotationSummarySelectedItems = '[AnnotationSummary] Start Open Annotation Summary Selected Items',

  GetAnnotationsForLibraryItems = '[AnnotationsSummary] Get Annotations For Library Items',
  GetAnnotationsForLibraryItemSuccess = '[AnnotationsSummary] Get Annotations For Library Item Success',
  GetAnnotationsForLibraryItemFail = '[AnnotationsSummary] Get Annotations For Library Item Fail',

  InferAddonFromLibraryItemAnnotation = '[AnnotationSummary] Infer Addon From Library Item Annotation',

  StartOpenAnnotationsSummaryMultipleItems = '[AnnotationSummary] Start Open Annotations Summary Multiple Items',
  SetCurrentAddonCategories = '[AnnotationSummary] Set Current Addon Categories',
  SaveCurrentAddonCategories = '[AnnotationSummary] Save Current Addon Categories',
  UpdateCheckedAddonCategory = '[AnnotationSummary] Update Current Addon Category',
  ToggleFilters = '[AnnotationSummary] Toggle Filters',
  AddSeverityLevel = '[AnnotationSummary] Add Severity Level',
  RemoveSeverityLevel = '[AnnotationSummary] Remove Severity Level',
  AddAllSeverityLevel = '[AnnotationSummary] Add All Severity Level',
  RemoveAllSeverityLevel = '[AnnotationSummary] Remove All Severity Level',
  UpdateActiveAnnotationsSummaryData = '[AnnotationSummary] Update Active Annotations Summary Data',
  SaveActiveAnnotationsSummaryData = '[AnnotationSummary] Save Active Annotations Summary Data',
  ResetCurrentAddonCategories = '[AnnotationSummary] Reset Current Addon Categories',
  ResetAllFilters = '[AnnotationSummary] Reset All Filters',
  UpdateCurrentActiveTags = '[AnnotationSummary] Update Current Active Tags',
  enableActiveTag = '[AnnotationSummary] Enable Active Tag',
  UpdateSeverityHeaderButtons = '[AnnotationSummary] Update severity header buttons',
  VerifyActiveHeaderButtons = '[AnnotationSummary] Verify active header buttons',
  ToggleSeverityHeaderButtons = '[AnnotationSummary] Toggle severity header buttons',
  ResetAnnotationsSummaryChips = '[AnnotationSummary] Reset Annotation Summary Chips',
  HandleAnnotationAndSeverityChips = '[AnnotationSummary] Handle Annotation And Severity Chips',
  HandleTagsChips = '[AnnotationSummary] Handle Tags Chips',
  EnableTagsChips = '[AnnotationSummary] Enable Tags Chips',
  SaveActiveTags = '[AnnotationSummary] Save Active Tags',
  DisableAllHeaderSeverityButtonsState = '[AnnotationSummary] Disable All Header Severity Buttons State',
  SetHasInitialAnnotationsSummaryData = '[AnnotationSummary] Set Has Initial Annotations Summary Data',

  ExportPdf = '[AnnotationSummary] Export PDF',
  ExportPdfSuccess = '[AnnotationSummary] Export PDF Success',
  ExportPdfFail = '[AnnotationSummary] Export PDF Fail',

  ExportCsvAnnotationsSummary = '[AnnotationSummary] Export CSV Annotations Summary',
  ExportCsvSuccess = '[AnnotationSummary] Export CSV Success',
  ExportCsvFail = '[AnnotationSummary] Export CSV Fail',

  ExportCocoJsonAnnotationsSummary = '[AnnotationSummary] Export Coco Json Annotations Summary',
  ExportCocoJsonSuccess = '[AnnotationSummary] Export COCO JSON Success',
  ExportCocoJsonFail = '[AnnotationSummary] Export COCO JSON Fail',

  OpenAnnotationsSummary = '[AnnotationSummary] Open Annotations Summary',
  OpenAnnotationsSummarySuccess = '[AnnotationSummary] Open Annotations Summary Success',
  OpenAnnotationsSummaryFail = '[AnnotationSummary] Open Annotations Summary Fail',

  GetAnnotationsSummaryData = '[AnnotationSummary] Get Annotations Summary Data',
  GetAnnotationsSummaryDataSuccess = '[AnnotationSummary] Get Annotations Summary Data Sucess',
  GetAnnotationsSummaryDataFail = '[AnnotationSummary] Get Annotations Summary Data Fail',

  ClearAnnotationsSummaryData = '[AnnotationSummary] Clear Annotations Summary Data'
}

export const startOpenAnnotationSummaryOneItem = createAction(
  AnnotationsSummaryActionTypes.StartOpenAnnotationSummaryOneItem,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const getAnnotationsForLibraryItems = createAction(
  AnnotationsSummaryActionTypes.GetAnnotationsForLibraryItems,
  props<{payload: {libraryItems: LibraryItem[]}}>()
);

export const getAnnotationsForLibraryItemSuccess = createAction(
  AnnotationsSummaryActionTypes.GetAnnotationsForLibraryItemSuccess,
  props<{payload: {libraryItems: LibraryItem[]}}>()
);

export const getAnnotationsForLibraryItemFail = createAction(
  AnnotationsSummaryActionTypes.GetAnnotationsForLibraryItemFail,
  props<{payload: {error: string}}>()
);

export const inferAddonFromLibraryItemAnnotation = createAction(
  AnnotationsSummaryActionTypes.InferAddonFromLibraryItemAnnotation,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const startOpenAnnotationsSummaryMultipleItems = createAction(
  AnnotationsSummaryActionTypes.StartOpenAnnotationsSummaryMultipleItems
);

export const startOpenAnnotationSummarySelectedItems = createAction(
  AnnotationsSummaryActionTypes.StartOpenAnnotationSummarySelectedItems
);
export const resetCurrentAddonCategories = createAction(AnnotationsSummaryActionTypes.ResetCurrentAddonCategories);
export const resetAllFilters = createAction(AnnotationsSummaryActionTypes.ResetAllFilters);

export const toggleFilters = createAction(
  AnnotationsSummaryActionTypes.ToggleFilters,
  props<{hasToSelectAll: boolean}>()
);
export const addSeverityLevel = createAction(
  AnnotationsSummaryActionTypes.AddSeverityLevel,
  props<{level: number; categoryIndex: number}>()
);
export const removeSeverityLevel = createAction(
  AnnotationsSummaryActionTypes.RemoveSeverityLevel,
  props<{level: number; categoryIndex: number}>()
);
export const addAllSeverityLevel = createAction(
  AnnotationsSummaryActionTypes.AddAllSeverityLevel,
  props<{level: number}>()
);
export const removeAllSeverityLevel = createAction(
  AnnotationsSummaryActionTypes.RemoveAllSeverityLevel,
  props<{level: number}>()
);
export const updateActiveAnnotationsSummaryData = createAction(
  AnnotationsSummaryActionTypes.UpdateActiveAnnotationsSummaryData,
  props<{addonId: string}>()
);
export const saveActiveAnnotationsSummaryData = createAction(
  AnnotationsSummaryActionTypes.SaveActiveAnnotationsSummaryData,
  props<{annotations: AnnotationsSummaryData[]}>()
);
export const setCurrentAddonCategories = createAction(
  AnnotationsSummaryActionTypes.SetCurrentAddonCategories,
  props<{addonId: string}>()
);

export const updateCheckedAddonCategory = createAction(
  AnnotationsSummaryActionTypes.UpdateCheckedAddonCategory,
  props<{categoryIndex: number; hasToSelectAll?: boolean}>()
);

export const saveCurrentAddonCategories = createAction(
  AnnotationsSummaryActionTypes.SaveCurrentAddonCategories,
  props<{categories: AddonCategoriesFilters[]}>()
);

export const updateCurrentActiveTags = createAction(
  AnnotationsSummaryActionTypes.UpdateCurrentActiveTags,
  props<{tag: Tag}>()
);

export const updateSeverityHeaderButtons = createAction(
  AnnotationsSummaryActionTypes.UpdateSeverityHeaderButtons,
  props<{level: number}>()
);

export const verifyActiveHeaderButtons = createAction(
  AnnotationsSummaryActionTypes.VerifyActiveHeaderButtons,
  props<{levels: number[]; checkedCategories: AddonCategoriesFilters[]}>()
);

export const toggleSeverityHeaderButtons = createAction(
  AnnotationsSummaryActionTypes.ToggleSeverityHeaderButtons,
  props<{level: number; isActive: boolean}>()
);

export const enableActiveTag = createAction(AnnotationsSummaryActionTypes.enableActiveTag, props<{tag: Tag}>());

export const resetAnnotationsSummaryChips = createAction(AnnotationsSummaryActionTypes.ResetAnnotationsSummaryChips);

export const handleAnnotationAndSeverityChips = createAction(
  AnnotationsSummaryActionTypes.HandleAnnotationAndSeverityChips,
  props<{totalAnnotationLabels: number}>()
);

export const handleTagsChips = createAction(AnnotationsSummaryActionTypes.HandleTagsChips, props<{tags: Tag[]}>());
export const enableTagsChips = createAction(AnnotationsSummaryActionTypes.EnableTagsChips, props<{tag: Tag}>());
export const saveActiveTags = createAction(AnnotationsSummaryActionTypes.SaveActiveTags, props<{tags: Tag[]}>());
export const disableAllHeaderSeverityButtonsState = createAction(
  AnnotationsSummaryActionTypes.DisableAllHeaderSeverityButtonsState
);
export const setHasInitialAnnotationsSummaryData = createAction(
  AnnotationsSummaryActionTypes.SetHasInitialAnnotationsSummaryData,
  props<{hasSummaryData: boolean}>()
);

export const exportPdf = createAction(
  AnnotationsSummaryActionTypes.ExportPdf,
  props<{addonId?: Addon['id']; path: string[]; libraryItem?: LibraryItem}>()
);

export const exportPdfSuccess = createAction(
  AnnotationsSummaryActionTypes.ExportPdfSuccess,
  props<{response: string}>()
);

export const exportPdfFail = createAction(
  AnnotationsSummaryActionTypes.ExportPdfFail,
  props<{error: HttpErrorResponse}>()
);

export const exportCsvAnnotationsSummary = createAction(
  AnnotationsSummaryActionTypes.ExportCsvAnnotationsSummary,
  props<{addonId: string; teamId: string}>()
);

export const exportCsvSuccess = createAction(
  AnnotationsSummaryActionTypes.ExportCsvSuccess,
  props<{response: string}>()
);

export const exportCsvFail = createAction(
  AnnotationsSummaryActionTypes.ExportCsvFail,
  props<{error: HttpErrorResponse}>()
);

export const exportCocoJsonSuccess = createAction(
  AnnotationsSummaryActionTypes.ExportCocoJsonSuccess,
  props<{response: string}>()
);

export const exportCocoJsonFail = createAction(
  AnnotationsSummaryActionTypes.ExportCocoJsonFail,
  props<{error: HttpErrorResponse}>()
);

export const exportCocoJsonAnnotationsSummary = createAction(
  AnnotationsSummaryActionTypes.ExportCocoJsonAnnotationsSummary,
  props<{addonId: string; teamId: string}>()
);

export const openAnnotationsSummary = createAction(
  AnnotationsSummaryActionTypes.OpenAnnotationsSummary,
  props<{payload: {libraryItem: LibraryItem}}>()
);

export const getAnnotationsSummaryData = createAction(
  AnnotationsSummaryActionTypes.GetAnnotationsSummaryData,
  props<{libraryItemIdArr: string[]}>()
);

export const getAnnotationsSummaryDataSuccess = createAction(
  AnnotationsSummaryActionTypes.GetAnnotationsSummaryDataSuccess,
  props<{annotations: AnnotationsSummaryData[]}>()
);

export const getAnnotationsSummaryDataFail = createAction(
  AnnotationsSummaryActionTypes.GetAnnotationsSummaryDataFail,
  props<{error: HttpErrorResponse}>()
);

export const clearAnnotationsSummaryData = createAction(AnnotationsSummaryActionTypes.ClearAnnotationsSummaryData);

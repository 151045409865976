<h2 mat-dialog-title data-test-id="dialog-title">
    <ng-container [ngSwitch]="data.renameOption">
    <span *ngSwitchCase="renameJobsOptions.JOB"> {{ 'jobs.renameJob' | translate }}</span>
    <span *ngSwitchCase="renameJobsOptions.TASK"> {{ 'jobs.renameTask' | translate }}</span>
  </ng-container>
</h2>
<section class="content" mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field class="name-input" appearance="fill">
      <mat-label> {{ 'jobs.name' | translate }}</mat-label>
      <input matInput formControlName="name" data-test-id="name-input"/>
      <mat-error *ngIf="form.controls.name.invalid" data-test-id="name-input-error"> {{ 'jobs.nameError' | translate }} </mat-error>
    </mat-form-field>
  </form>
</section>
<section mat-dialog-actions>
  <a mat-button mat-dialog-close color="primary" data-test-id="cancel-button">{{ 'jobs.cancel' | translate }}</a>
  <a [disabled]="isLoading" mat-flat-button color="primary" (click)="renameAction()" data-test-id="save-button">
    {{ 'jobs.save' | translate }}
    <unleash-spinner *ngIf="isLoading" diameter="20" borderWidth="2.1" color="#111" data-test-id="loading-spinner"></unleash-spinner>
  </a>
</section>

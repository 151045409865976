import {Injectable} from '@angular/core';
import {Auth} from '@aws-amplify/auth';
import {from} from 'rxjs';
import {flatMap, map, share, shareReplay} from 'rxjs/operators';
import {ApiGateway} from '../../core/services/api/api-gateway.service';
import {Mission} from './mission-settings.service';

@Injectable({
  providedIn: 'root'
})
export class MissionApiService {
  private endpoint: string = 'mission';

  constructor(private apiGateway: ApiGateway) {}

  public createMission(mission: Mission) {
    return this.apiGateway.post(this.endpoint, {}, mission).pipe(share());
  }

  public listMissions() {
    const newestFirst = (a, b) => b.updatedAt - a.updatedAt;
    return from(Auth.currentAuthenticatedUser()).pipe(
      flatMap(identityId => this.apiGateway.get(this.endpoint, {}, undefined)),
      map((missions: Mission[]) => missions.sort(newestFirst)),
      shareReplay(1)
    );
  }

  public updateMission(missionId: string, body: Partial<Mission>) {
    return this.apiGateway.patch(this.endpoint + '/' + missionId, {}, body).pipe(share());
  }

  public removeMission(missionId: string) {
    return this.apiGateway.delete(this.endpoint + '/' + missionId, {}, undefined).pipe(share());
  }

  public importMission(mission: string) {
    return this.apiGateway.post(`${this.endpoint}/import`, {}, mission).pipe(share());
  }

  public importMissionKMZ(mission: string) {
    const blob = new Blob([mission], {type: 'application/octet-stream'});
    const formData = new FormData();
    formData.append('file', blob, 'mision.kmz');
    return this.apiGateway
      .post(`${this.endpoint}/import`, {}, formData, 'json', {
        'Content-Type': 'multipart/form-data'
      })
      .pipe(share());
  }
}

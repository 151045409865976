import {Injectable} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'any'
})
export class DashboardService {
  public dashboardId: string = '';
  public lgaName$: ReplaySubject<string> = new ReplaySubject<string>(1);

  private hasDashboardId: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasDashboardId$: Observable<boolean> = this.hasDashboardId.asObservable();
  public drawerRef: MatDrawer;

  public setDashboardId(dashboardId: string) {
    this.dashboardId = dashboardId;
    this.hasDashboardId.next(!!this.dashboardId);
  }

  public setLgaName(name: string) {
    this.lgaName$.next(name);
  }

  public setDrawerRef(ref: MatDrawer) {
    this.drawerRef = ref;
  }
}

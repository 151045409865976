import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAtlas from '../store/atlas.reducer';
import {AtlasState} from '../store/atlas.reducer';

export const selectAtlasState = createFeatureSelector<fromAtlas.AtlasState>(fromAtlas.atlasFeatureKey);

export const selectAtlasItems = createSelector(selectAtlasState, (state: AtlasState) => {
  if (!state.items) {
    return [];
  }

  return [...state.items];
});

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromProfile from './profile.reducer';
import {UserTable} from '@app/profile/models/users-table.model';
import {TeamRole, TeamTable} from '@app/profile/models/team.model';

export const selectProfileState = createFeatureSelector<fromProfile.State>(fromProfile.profileFeatureKey);

export const selectUsersTable = createSelector(selectProfileState, (state: fromProfile.State) => state.users);

export const selectSelectedUserTable = createSelector(selectProfileState, (state: fromProfile.State) => {
  if (!state.selectedUserId) {
    return null;
  }
  const user = state.users.find(user => user.userId === state.selectedUserId);
  return user;
});

export const selectUsers = createSelector(selectProfileState, (state: fromProfile.State) => state.users);

export const selectSelectedUser = createSelector(selectProfileState, (state: fromProfile.State) =>
  state.users.find(user => user.userId === state.selectedUserId)
);

export const selectTeams = createSelector(selectProfileState, (state: fromProfile.State) => state.teams);
export const selectCurrentTeam = createSelector(selectProfileState, (state: fromProfile.State) => state.teams[0]);
export const selectTeamsTable = createSelector(selectProfileState, (state: fromProfile.State) => {
  return state.teams
    .map(team => ({
      teamId: team.id,
      name: team.name,
      members: team.members || 0,
      lastActive: new Date().getTime(),
      createdAt: team.createdAt
    }))
    .sort((teamA, teamB) => teamA.name?.localeCompare(teamB.name));
});

export const selectSelectedTeamTable = createSelector(selectProfileState, (state: fromProfile.State) => {
  if (!state.selectedTeamId) {
    return null;
  }

  const team = state.teams.find(team => team.id === state.selectedTeamId);

  return {
    teamId: team.id,
    name: team.name,
    members: 0,
    lastActive: new Date().getTime(),
    createdAt: team.createdAt
  } as TeamTable;
});

export const selectUsersByTeam = createSelector(selectProfileState, (state: fromProfile.State) => {
  if (!state.selectedTeamId) {
    return [];
  }

  const usersByTeam = state.users
    .filter(user => user.teamId === state.selectedTeamId)
    .map(
      user =>
        ({
          userId: user.userId,
          name: user.name,
          email: 'fillme@example.com_manual_hack',
          lastActive: user.updatedAt,
          createdAt: user.createdAt,
          logo: user.logo,
          pk: user.pk,
          role: user.roles[0],
          sk: user.sk,
          updatedAt: user.updatedAt
        } as UserTable)
    )
    .sort((userA, userB) => userA.name?.localeCompare(userB.name));
  return usersByTeam;
});

export const selectSelectedTeam = createSelector(selectProfileState, (state: fromProfile.State) =>
  state.teams.find(team => team.id === state.selectedTeamId)
);

export const selectSelectedTeamId = createSelector(
  selectProfileState,
  (state: fromProfile.State) => state.selectedTeamId
);

export const selectSelectedUserTeamTable = createSelector(
  selectProfileState,
  (state: fromProfile.State) => (state as any).selectedUserTeam
);

export const selectRoles = createSelector(selectProfileState, (state: fromProfile.State) => state.roles);

export const selectHasMoreThanOneAdmin = createSelector(selectProfileState, (state: fromProfile.State) => {
  const adminUsers = state.users.filter(user => user.roles[0] === TeamRole.admin);
  return adminUsers.length > 1;
});

export const selectIsUserManagementLoading = createSelector(selectProfileState, (state: fromProfile.State) => {
  return state.isUserManagementLoading;
});

import {AsyncPipe, NgForOf} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormControl, ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {TranslateModule} from '@ngx-translate/core';
import {BehaviorSubject, shareReplay, take} from 'rxjs';
import {AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';

export enum WayPointType {
  POLE_1 = 'dist-1',
  POLE_2 = 'dist-2',
  POLE_3 = 'dist-3',
  POLE_4 = 'dist-4',
  POLE_5 = 'dist-5',
  POLE_10 = 'dist-10',
  POLE_11 = 'dist-11',
  EE_WIDE = 'ee-w2',
  EE_NARROW = 'ee-n2',
  EE_WIDE_TALL = 'ee-w4',
  EE_NARROW_TALL = 'ee-n4'
}

@Component({
  selector: 'app-convert-to-mission-dialog',
  templateUrl: './convert-to-mission-dialog.component.html',
  styleUrls: ['./convert-to-mission-dialog.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    TranslateModule,
    MatDialogModule,
    AsyncPipe,
    LaddaModule,
    MatSelectModule,
    NgForOf
  ]
})
export class ConvertToMissionComponent implements OnInit {
  private isLoading = new BehaviorSubject(false);
  public isLoading$ = this.isLoading.asObservable().pipe(shareReplay(1));

  public smartInspectMissionProfiles = Object.values(WayPointType).map(value => ({key: value, name: `SI-${value}`}));

  public form = new FormGroup({
    smartInspectMissionProfile: new FormControl(WayPointType.POLE_3)
  });

  constructor(
    private dialogRef: MatDialogRef<ConvertToMissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AtlasGeojsonAssetModel,
    private atlasService: AtlasService,
    private missionManagerService: MissionManagerService
  ) {}

  public ngOnInit() {}

  public convertAssetToMission() {
    this.isLoading.next(true);
    this.atlasService
      .convertAssetToMission(this.data.id, this.form.value.smartInspectMissionProfile)
      .pipe(take(1))
      .subscribe({
        next: mission => {
          const missionWithTimeAndDistance = this.missionManagerService.calculateTimeAndDistance(mission);
          const newMission = {...mission, ...missionWithTimeAndDistance};
          this.missionManagerService.addNewMission(newMission);
          this.isLoading.next(false);
          this.dialogRef.close({missionResult: mission, hasError: false});
        },
        error: error => {
          this.dialogRef.close({missionResult: null, hasError: true});
        }
      });
  }

  public cancel() {
    this.dialogRef.close({missionResult: null, hasError: false});
  }
}

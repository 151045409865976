<table mat-table #table [dataSource]="dataSource">
  <ng-container *ngIf="isSelectionEnabled">
    <form [formGroup]="formGroup">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="dataSource.length > 0">
            <mat-checkbox formControlName="allCheckbox" (change)="toggleAllRowsWithFormArray($event)"> </mat-checkbox>
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let row; let i = index">
          <div formArrayName="checkBoxControls">
            <mat-checkbox [formControlName]="i" value="row" (change)="isAllCheckboxSelected()"> </mat-checkbox>
          </div>
        </td>
      </ng-container>
    </form>
  </ng-container>

  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      *matHeaderCellDef
      [ngClass]="{isSelectionEnabled: isSelectionEnabled, hasToHideRole: hasToHideRole}"
    >
      {{ 'common.name' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = index"
      [ngClass]="{isSelectionEnabled: isSelectionEnabled, hasToHideRole: hasToHideRole}"
      (click)="emitUser(element); isSelectionEnabled ? toggleSelection(i) : null"
    >
      {{ !!element.name ? element.name : 'N/A' }}
    </td>
  </ng-container>
  <ng-container *ngIf="!isSelectionEnabled" matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.email' | translate }}</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef [ngClass]="{isSelectionEnabled: isSelectionEnabled}">
      {{ 'profile.users.role' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="aclPermissions.MainApiUpdateUser; else simpleRoleTemplate">
        <ng-container *ngIf="!hasMoreThanOneAdmin && element.role === teamRole.admin; else multiAdminTemplate">
          <span class="disable-color">Admin</span>
        </ng-container>
        <ng-template #multiAdminTemplate>
          <span class="user-role-picker-container">
            <unleash-user-role-picker
              [role]="element.role"
              [roles]="roles"
              [userId]="element.userId"
              (editUserTeamRole)="isSelectionEnabled ? (element.role = $event.payload) : emitUserTeamRole($event)"
            ></unleash-user-role-picker>
          </span>
        </ng-template>
      </ng-container>
      <ng-template #simpleRoleTemplate>
        <span class="disable-color">{{ element.role }}</span>
      </ng-template>
    </td>
  </ng-container>
  <ng-container *ngIf="!isSelectionEnabled" matColumnDef="lastActive">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.lastActive' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.lastActive | uDateFormat: 'Do MMM' }} '{{ element.lastActive | uDateFormat: 'yy' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!hasToHideRole">
        <ng-container *ngIf="menuItems.length === 1; else actionMenuItemsTemplate">
          <ng-container
            ><!-- *ngIf="!!element.name; else showInvitationPendingTemplate" -->
            <button
              [attr.data-test-id]="'actions-' + menuItem.name + '-button'"
              *ngFor="let menuItem of menuItems"
              mat-icon-button
              (click)="emitAction(menuItem.type, element.userId)"
              [disabled]="!hasMoreThanOneAdmin && element.role === teamRole.admin"
            >
              <mat-icon [color]="!hasMoreThanOneAdmin && element.role === teamRole.admin ? '' : menuItem.iconColor">{{
                menuItem.icon
              }}</mat-icon>
            </button>
          </ng-container>
          <ng-template #showInvitationPendingTemplate>
            <span class="disable-color">{{ 'profile.users.invitePending' | translate }}</span>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-template #actionMenuItemsTemplate>
        <ng-container *ngIf="menuItems.length > 1">
          <button mat-icon-button data-test-id="menu-actions-button" [matMenuTriggerFor]="menu">
            <mat-icon color="grey-5">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              [attr.data-test-id]="'actions-' + menuItem.name + '-button'"
              *ngFor="let menuItem of menuItems"
              mat-menu-item
              (click)="emitAction(menuItem.type, element.userId)"
            >
              <mat-icon color="warn">{{ menuItem.icon }}</mat-icon>
              {{ menuItem.name }}
            </button>
          </mat-menu>
        </ng-container>
      </ng-template>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="3">
      <div class="no-data-row">
        {{ 'profile.users.noUsers' | translate }}
        <!-- <button class="no-data-row__button" (click)="emitClearFilter()" color="primary" mat-button>
          {{ 'common.clear' | translate }}
        </button> -->
      </div>
    </td>
  </tr>
</table>

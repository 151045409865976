import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {DeleteUserTeamDialogComponent} from '@app/profile/components/delete-user-team-dialog/delete-user-team-dialog.component';
import {AddUserComponent} from '@app/profile/components/users/add-user/add-user.component';
import {TeamRole, TeamTable} from '@app/profile/models/team.model';
import {UpdateUserTeamAction} from '@app/profile/models/user-team.model';
import {UserTeam} from '@app/profile/models/users-table.model';
import {TeamsApiService} from '@app/profile/services/teams-api.service';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {UserApiService} from '@app/profile/services/user-api.service';
import {UserTeamsApiService} from '@app/profile/services/user-teams-api.service';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import * as actions from '@app/profile/store/users-management/users-management.actions';
import {PayloadAction} from '@app/shared/models/payload-action';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY, catchError, filter, map, merge, of, switchMap, take, tap, withLatestFrom, zip} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersManagementEffects {
  public deleteUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteUser),
        switchMap(({payload}: PayloadAction<{userId: string}>) => {
          return this.usersManagementStoreFacadeService.users$.pipe(
            take(1),
            map(users => users.filter(user => user.userId === payload.userId)),
            map(users => users[0])
          );
        }),
        switchMap(user => {
          const dialogRef = this.dialog.open(DeleteUserTeamDialogComponent, {
            ...STANDARD_DIALOG_CONFIG,
            width: '80vw',
            maxWidth: '800px',
            data: user
          });
          return dialogRef.afterClosed().pipe(
            filter(result => !!result),
            tap(() => {
              this.usersManagementStoreFacadeService.deleteUserSuccess(user.userId);
              this.teamsManagementStoreFacadeService.updateTeamCounterAfterUserDelete(user.teamId);
            })
          );
        })
      ),
    {dispatch: false}
  );

  public updateUserTeamRole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateUserTeamRole),
        withLatestFrom(this.userStoreFacadeService.selectedTeamId$),
        switchMap(([{payload}, teamId]: [PayloadAction<{userId: string; role: TeamRole}>, string]) => {
          return merge(
            of({...payload, teamId}),
            this.userTeamsApiService
              .updateUserTeamRole({
                userId: payload.userId,
                role: payload.role,
                teamId: teamId
              })
              .pipe(
                map(() => null),
                catchError(error => {
                  console.error('update user team role error', error);
                  return of();
                })
              )
          );
        }),
        tap((data: {userId: string; role: TeamRole; teamId: string}) => {
          if (!data) {
            return;
          }
          this.usersManagementStoreFacadeService.updateUserTeamRoleSuccess(data);
        })
      ),
    {
      dispatch: false
    }
  );

  public removeUsersFromTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.removeUsersFromTeam),
        withLatestFrom(this.teamsManagementStoreFacadeService.selectedTeamTable$),
        switchMap(([{payload}, team]: [PayloadAction<{userId: string}>, TeamTable]) => {
          return merge(
            this.teamsApiService.editUserTeam({
              users: [{userId: payload.userId, action: UpdateUserTeamAction.remove}],
              teamId: team.teamId
            }),
            of({teamId: team.teamId, users: [{userId: payload.userId}]})
          );
        }),
        tap(response => {
          if (!response) {
            return;
          }

          this.teamsManagementStoreFacadeService.removeUsersToTeamSuccess(response);
          this.usersManagementStoreFacadeService.removeUsersToTeamSuccess(response);
        })
      ),
    {dispatch: false}
  );

  public openInviteUsersDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.openInviteUsersDialog),
        switchMap(() =>
          zip(
            this.teamsManagementStoreFacadeService.teams$.pipe(take(1)),
            this.usersManagementStoreFacadeService.roles$.pipe(take(1))
          )
        ),
        switchMap(([teams, roles]) => {
          return this.dialog
            .open(AddUserComponent, {
              ...STANDARD_DIALOG_CONFIG,
              width: '80vw',
              maxWidth: '800px',
              data: {isDialogMode: true, teams, roles}
            })
            .afterClosed();
        }),
        tap(data => {
          if (!data?.hasToAddUsers) {
            return;
          }
          this.usersManagementStoreFacadeService.displayLoading();
          this.usersManagementStoreFacadeService.inviteUsers(data.users);
        })
      ),
    {dispatch: false}
  );

  public inviteUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.inviteUsers),
        switchMap(({payload}) => {
          return this.userApiService.createUsers(payload.users).pipe(
            catchError(error => {
              this.usersManagementStoreFacadeService.hideLoading();
              this.translateService
                .get('profile.users.errorSendingInviteUsers')
                .pipe(take(1))
                .subscribe(errorSendingInviteUsersTranslation => {
                  this.snackBar.open(errorSendingInviteUsersTranslation, null, {
                    duration: 3000
                  });
                });
              console.error('invite users error', error);
              return EMPTY;
            })
          );
        }),
        tap((response: UserTeam[]) => {
          if (!response) {
            return;
          }

          const users = response.map(user => ({...user, role: user.roles[0]}));
          this.usersManagementStoreFacadeService.hideLoading();
          this.usersManagementStoreFacadeService.inviteUsersSuccess(users);
          this.teamsManagementStoreFacadeService.inviteUsersSuccess(users);
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private userTeamsApiService: UserTeamsApiService,
    private userStoreFacadeService: UserStoreFacadeService,
    private usersManagementStoreFacadeService: UsersManagementStoreFacadeService,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService,
    private dialog: MatDialog,
    private teamsApiService: TeamsApiService,
    private userApiService: UserApiService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}
}

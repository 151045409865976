import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {DrawTool} from './draw-tool';
import {Canvas, Draw, LabelOpacity, SingleLabel} from './image-annotation.model';

export class Rectangle extends DrawTool {
  constructor(ref: Draw, canvas: Canvas) {
    super(canvas);
    this.ref = ref;
    this.type = ShapeTypes.Rectangle;
  }

  public startDraw(opts?: {id: string; color: string}) {
    this.selectedColor = opts.color;
    const id = opts ? opts.id : this.idGenerator(this.type);

    this.ref.draw('initMouseDown');
    this.ref.attr('fill', this.selectedColor);
    this.ref.attr('fill-opacity', LabelOpacity.visible);
    this.ref.attr('stroke', '#000');
    this.ref.attr('stroke-opacity', '1');
    this.ref.attr('id', id);

    this.clickOnCanvasExtraElements();
  }

  public importDraw(
    label: SingleLabel,
    scalingFactor: number,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ): SingleLabel {
    const shape = this.parseShapeFromDb(label.shape, canvasSize, originalSize);

    const singleLabel: SingleLabel = {
      id: label.id,
      displayName: '',
      visibility: true,
      shapeType: label.shapeType,
      category: label.category,
      shape,
      severity: label.severity,
      comment: label.comment,
      color: label.color,
      isAI: label.isAI,
      isAccepted: label.isAccepted,
      isModified: label.isModified,
      suggestedCategory: label.suggestedCategory,
      addonId: label.addonId,
      distance: label.distance
    };

    this.setScalingFactorForCircleRadius(scalingFactor);

    this.ref.attr('id', singleLabel.id);
    this.ref.attr('fill', singleLabel.color);
    this.ref.attr('fill-opacity', LabelOpacity.visible);
    this.ref.attr('stroke', '#000');
    this.ref.attr('stroke-opacity', '1');
    this.ref.attr('stroke-width', this.vertexStrokeWidth + 'px');
    this.ref.attr('x', singleLabel.shape.x);
    this.ref.attr('y', singleLabel.shape.y);
    this.ref.attr('width', singleLabel.shape.width);
    this.ref.attr('height', singleLabel.shape.height);
    this.ref.attr('shape-type', this.type);
    return singleLabel;
  }

  public exportDraw(singleLabel: SingleLabel): [SingleLabel, string] {
    const shape = {
      x: this.ref.attr('x'),
      y: this.ref.attr('y'),
      width: this.ref.attr('width'),
      height: this.ref.attr('height')
    };

    const label: SingleLabel = {
      shapeType: singleLabel.shapeType,
      category: singleLabel.category,
      shape,
      severity: singleLabel.severity,
      comment: singleLabel.comment,
      color: singleLabel.color,
      isAI: singleLabel.isAI,
      isAccepted: singleLabel.isAccepted,
      isModified: singleLabel.isModified,
      suggestedCategory: singleLabel.suggestedCategory,
      distance: singleLabel.distance
    };

    return [label, singleLabel.id];
  }

  public generateDrawTemplate(): SingleLabel {
    const shape = {
      x: this.ref.attr('x'),
      y: this.ref.attr('y'),
      width: this.ref.attr('width'),
      height: this.ref.attr('height')
    };
    const singleLabel: SingleLabel = {
      id: this.ref.attr('id') as string,
      displayName: '',
      visibility: true,
      shapeType: this.type,
      category: '',
      shape,
      severity: null,
      comment: '',
      color: this.selectedColor
    };

    return singleLabel;
  }

  public restoreBackup(drawBak: any) {
    super.restoreBackup(drawBak);

    this.ref.attr('x', drawBak.x);
    this.ref.attr('y', drawBak.y);
    this.ref.attr('width', drawBak.width);
    this.ref.attr('height', drawBak.height);
    return;
  }

  public saveShapeBackup() {
    return {
      height: this.ref.attr('height'),
      width: this.ref.attr('width'),
      x: this.ref.attr('x'),
      y: this.ref.attr('y')
    };
  }

  public hasDraw(): boolean {
    return !!this.ref.attr('height') && !!this.ref.attr('width');
  }

  private parseShapeFromDb(
    shape: any,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ) {
    if (!shape) {
      return null;
    }

    const newShape = {height: 0, width: 0, x: 0, y: 0};
    newShape.height = (shape.height * canvasSize.height) / originalSize.height;
    newShape.y = (shape.y * canvasSize.height) / originalSize.height;
    newShape.width = (shape.width * canvasSize.width) / originalSize.width;
    newShape.x = (shape.x * canvasSize.width) / originalSize.width;

    return newShape;
  }
}

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {RouterFacadeStoreService} from '@app/core/services/api/router-store-facade.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {RouterAction, routerNavigationAction} from '@ngrx/router-store';
import {NotificationService} from '@app/core/services/notifications/notification.service';
import {filter, map, pairwise, skip, switchMap, take, tap} from 'rxjs/operators';
import {listenNavigation, listenPreviousUrl, routerSaveRedirect} from './router.actions';

@Injectable()
export class RouterEffects {
  public onRouterNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigationAction),
        tap(() => {
          this.notificationService.closeNotificationSidebar();
        })
      ),
    {dispatch: false}
  );

  public watchRedirectNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      take(1),
      filter(router => {
        const url = router.payload.event.url;
        return router && /secure/.exec(url) && url !== '/secure/dashboard';
      }),
      map((action: RouterAction<any, any>) => {
        // TODO search type of action.
        const url = action.payload.routerState.url;
        const queryParams = action.payload.routerState.queryParams;
        const params = action.payload.routerState.params;
        return routerSaveRedirect({url, queryParams, params});
      })
    )
  );

  public listenPreviousUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(listenPreviousUrl),
        switchMap(() => this.router.events.pipe(filter(event => event instanceof NavigationEnd))),
        pairwise(),
        tap(([previousUrl, currentUrl]: [NavigationEnd, NavigationEnd]) => {
          if (previousUrl.url?.includes('skipSavePreviousUrl=true')) {
            return;
          }

          if (previousUrl?.url) {
            this.routerFacadeStoreService.savePreviousUrl(previousUrl.url);
          }
        })
      ),
    {dispatch: false}
  );

  public listenNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(listenNavigation),
        switchMap(() => this.router.events.pipe(filter(event => event instanceof NavigationEnd))),
        tap(() => {
          if (history.length < 3) {
            window.localStorage.removeItem('unleash-navigation-start');
          }
        }),
        skip(1),
        tap(() => {
          window.localStorage.setItem('unleash-navigation-start', 'navigate');
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private router: Router,
    private routerFacadeStoreService: RouterFacadeStoreService
  ) {}
}

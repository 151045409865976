import {Action, createReducer, on} from '@ngrx/store';
import {actionSignInFormSubmit} from '../sign-in/sign-in.actions';
import {
  actionSetupSignUpEmail,
  actionSignUpClearForm,
  actionSignUpFormConfirmationRequired,
  actionSignUpFormError,
  actionSignUpFormSubmit,
  actionSignUpFormSuccess,
  actionSignUpFormUpdate
} from './sign-up.actions';
import {SignUpForm} from './sign-up.model';

export const signUpFeatureKey = 'signUp';

export interface SignUpFormState {
  form: SignUpForm;
  error?: string;
  inProgress: boolean;
  isDeveloperMode: boolean;
}

export const initialState: SignUpFormState = {
  form: {name: '', password: '', email: '', terms: false},
  inProgress: false,
  isDeveloperMode: false
};

const reducer = createReducer(
  initialState,
  on(actionSignUpFormUpdate, (state, {form}) => ({
    ...state,
    error: undefined,
    inProgress: false,
    form
  })),
  on(actionSignUpFormSubmit, (state, {form, isDeveloperMode}) => ({
    ...state,
    error: undefined,
    inProgress: true,
    form,
    isDeveloperMode
  })),
  on(actionSignUpFormConfirmationRequired, state => ({
    ...state,
    error: undefined,
    inProgress: false
  })),
  on(actionSignUpFormError, (state, {error}) => ({
    ...state,
    inProgress: false,
    error
  })),
  on(actionSignUpFormSuccess, state => ({
    ...state,
    error: undefined,
    inProgress: false
  })),
  on(actionSignInFormSubmit, (state, action) => ({
    ...state,
    error: undefined,
    inProgress: false,
    form: {...state.form, email: action.form.email, password: action.form.password}
  })),
  on(actionSignUpClearForm, state => ({
    ...state,
    form: {email: null, password: null, name: null, terms: false}
  })),
  on(actionSetupSignUpEmail, (state, {payload}) => ({...state, form: {...state.form, email: payload.email}}))
);

export function signUpStateReducer(state: SignUpFormState | undefined, action: Action): SignUpFormState {
  return reducer(state, action);
}

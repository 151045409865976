import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {Router} from '@angular/router';
import {AuthFacade} from '@app/auth/state/auth.facade';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {PlanModel} from '@app/core/models/api/plan.model';
import {UserModel} from '@app/core/models/api/user-model';
import {UserService} from '@app/core/services/api/user.service';
import {PlansService} from '@app/plans/services/plans.service';
import {differenceInDays} from 'date-fns';
import {Subscription, zip} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DASHBOARD_TILES, TileContent} from '../../models/gui/tile-content.model';
import {HelpService} from '@app/core/services/help.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  tiles: TileContent[] = DASHBOARD_TILES;
  user: UserModel;
  isMobileView = false;
  isLastDayFreeTrial = false;
  lastDayLabel: string;
  userPlan: PlanModel;
  private observableMediaSub: Subscription;

  private userSub: Subscription;

  private isDeveloperMode: boolean;

  constructor(
    private observableMedia: MediaObserver,
    private userService: UserService,
    private plansService: PlansService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private authFacade: AuthFacade,
    private helpService: HelpService
  ) {
    this.observableMediaSub = this.observableMedia.asObservable().subscribe(change => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      this.isMobileView = mediaAlias === 'xs' || mediaAlias === 'sm';
    });
  }

  ngOnInit(): void {
    this.userSub = zip(this.userService.user$, this.plansService.userPlan$.pipe(filter(userPlan => !!userPlan)))
      .pipe(filter(([user, userPlan]) => !!user))
      .subscribe(([user, userPlan]) => {
        this.user = user;
        if (userPlan) {
          this.userPlan = userPlan;
          this.tiles = this.tiles.sort(this.sortVideosByPackage(this.userPlan.packageName));
        }
        this.loadIsLastDayFreeTrial();
        this.isDeveloperMode = user.developer;
      });
    this.scrollToTop();
  }

  fullscreen(event) {
    const target = event.target || event.srcElement || event.currentTarget;

    if (this.isMobileView && target.requestFullscreen) {
      target.play();
      target.requestFullscreen();
    }
  }

  exitFullscreen(event) {
    const target = event.target || event.srcElement || event.currentTarget;

    if (this.isMobileView && this.document.exitFullscreen) {
      this.document.exitFullscreen();
    }
  }

  sortVideosByPackage(packageName: string): (a: TileContent, b: TileContent) => number {
    return (a, b) => {
      return packageName.includes(a.forPackage) ? -1 : packageName.includes(b.forPackage) ? 1 : 0;
    };
  }

  ngOnDestroy(): void {
    // needed for AutoUnsubscribe()
  }

  goToContactUs(event) {
    event.stopPropagation();
    event.preventDefault();
    this.helpService.goToContactUs();
  }

  navigateToAccount() {
    this.router.navigate(['/secure/profile']);
  }

  loadIsLastDayFreeTrial() {
    if (differenceInDays(new Date(), this.user.trialPeriodEnd) === 1) {
      this.translateService.get('core.dashboard.lastDay').subscribe(translations => {
        this.lastDayLabel = `${translations.prefix} ${this.userPlan.priceMonthly}${translations.postfix}`;
        this.isLastDayFreeTrial = true;
      });
    }
  }

  public signOut(): void {
    this.authFacade.authLogout(this.isDeveloperMode);
  }

  private scrollToTop() {
    this.document.body.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {NotificationModel, NotificationState} from '../models/api/notifications.model';

export const notificationModelEntityAdapter: EntityAdapter<NotificationModel> = createEntityAdapter<NotificationModel>({
  selectId: model => model.sourceCreatedAt,
  sortComparer: (a: NotificationModel, b: NotificationModel): number => {
    if (a.state === NotificationState.RUNNING && b.state === NotificationState.RUNNING) {
      // avoid dancing notification progress updates
      return b.createdAt - a.createdAt;
    }
    if (b.updatedAt && a.updatedAt) {
      return b.updatedAt - a.updatedAt;
    }

    if (b.updatedAt && a.createdAt) {
      return b.updatedAt - a.createdAt;
    }

    if (b.createdAt && a.updatedAt) {
      return b.createdAt - a.updatedAt;
    }

    return b.createdAt - a.createdAt;
  }
});

export interface NotificationsState extends EntityState<NotificationModel> {
  newNotification: NotificationModel;
  unreadNotificationsCount: number;
  isLoading?: boolean;
  error?: any;
}

export const initialState: NotificationsState = notificationModelEntityAdapter.getInitialState({
  newNotification: undefined,
  unreadNotificationsCount: 0,
  isLoading: false,
  error: null
});

export interface State {
  notifications: NotificationsState;
}

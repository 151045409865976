import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'unleash-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent {
  @Input() public matMenu: MatMenu;
  @Input() public hasToDisplay: boolean = true;
  @ViewChild('menuTrigger', {static: false, read: MatMenuTrigger})
  public menuTrigger: MatMenuTrigger;

  @Output() public closed: EventEmitter<boolean> = new EventEmitter();

  // eslint-disable-next-line rxjs/finnish
  public close$: Subject<boolean> = new Subject<boolean>();
  private menuTopLeftPositionX = new BehaviorSubject<string>('');
  private menuTopLeftPositionY = new BehaviorSubject<string>('');
  public menuTopLeftPositionX$ = this.menuTopLeftPositionX.asObservable();
  public menuTopLeftPositionY$ = this.menuTopLeftPositionY.asObservable();

  constructor(private cd: ChangeDetectorRef) {}

  public open({x, y}: MouseEvent, data?: unknown): void {
    if (!this.hasToDisplay) {
      return;
    }

    this.close();
    this.menuTopLeftPositionX.next(x + 'px');
    this.menuTopLeftPositionY.next(y + 'px');
    this.menuTrigger.menuData = {data: data};
    this.menuTrigger.openMenu();
    this.cd.detectChanges();
  }

  public close(): void {
    if (this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
      this.close$.next(true);
      this.closed.emit(true);
    }
  }

  public menuClosed(): void {
    this.menuTrigger.closeMenu();
    this.close$.next(true);
    this.closed.emit(true);
  }
}

import {confirmRegistrationStateSelector} from '@app/auth/state/auth-forms.state';
import {createSelector} from '@ngrx/store';
import {ConfirmRegistrationState} from './confirm-registration.reducers';

export const selectConfirmRegistrationFormValue = createSelector(
  confirmRegistrationStateSelector,
  (state: ConfirmRegistrationState) => state.form
);

export const selectConfirmRegistrationInProgress = createSelector(
  confirmRegistrationStateSelector,
  (state: ConfirmRegistrationState) => state.inProgress
);

export const selectConfirmRegistrationError = createSelector(
  confirmRegistrationStateSelector,
  (state: ConfirmRegistrationState) => state.error
);

export const selectConfirmRegistrationResendInProgress = createSelector(
  confirmRegistrationStateSelector,
  (state: ConfirmRegistrationState) => state.resendInProgress
);

export const selectIsDisplayResendAlert = createSelector(
  confirmRegistrationStateSelector,
  (state: ConfirmRegistrationState) => state.isDisplayResendAlert
);

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  selector: 'app-move-new-group',
  templateUrl: './move-new-group.component.html',
  styleUrls: ['./move-new-group.component.scss']
})
export class MoveNewGroupComponent implements OnInit, OnDestroy {
  @Input()
  cleanForm$: BehaviorSubject<boolean>;

  @Output()
  nameChanged = new EventEmitter<string>();

  form: UntypedFormGroup = this.fb.group({
    name: [null, Validators.required]
  });

  private namaValueChangesSub: Subscription;
  private cleanFormSub: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.namaValueChangesSub = this.form.controls.name.valueChanges.subscribe(name => this.nameChanged.next(name));

    if (this.cleanForm$) {
      this.cleanFormSub = this.cleanForm$.subscribe(shouldCleanForm => {
        if (shouldCleanForm) {
          this.form.reset();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.cleanFormSub) {
      this.cleanFormSub.unsubscribe();
    }

    if (this.namaValueChangesSub) {
      this.namaValueChangesSub.unsubscribe();
    }
  }
}

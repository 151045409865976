<div *ngIf="plansService.userPlan$ | async as userPlan" class="plan">
  <div *ngIf="!!userPlan.reseller && userPlan.reseller === 'skyward'">
    <img src="assets/images/skyward-logo.svg" class="reseller-logo" />
  </div>
  <div class="item-title">{{ userPlan.packageName }} {{ userPlan.planName }}</div>
  <div class="item-subtitle" *ngIf="!pastTrial()">
    {{ 'profile.plan.firstPayment' | translate }} {{ user.trialPeriodEnd | uTimeAgo | async }}
  </div>
  <ul class="features">
    <li>
      {{ userPlan.maxDevices }}
      {{
        userPlan.maxDevices > 1
          ? ('profile.plan.connectedDevices' | translate)
          : ('profile.plan.connectedDevice' | translate)
      }}
    </li>
    <li *ngIf="userPlan.isLivestreaming">
      {{ userPlan.maxStreamingHours || 'Custom' }} {{ 'profile.plan.hoursStreaming' | translate }}
    </li>
    <li *ngIf="userPlan.isAiAnalytics">
      {{ userPlan.maxAiProcessingHours || 'Custom' }} {{ 'profile.plan.processingHours' | translate }}
    </li>
    <li *ngIf="userPlan.isAiAnalytics">
      {{ userPlan.aiInsightsNum || 'Custom' }} {{ 'profile.plan.aiApps' | translate }}
    </li>
    <li *ngIf="userPlan.isLivestreaming">
      {{ userPlan.maxLiveChannels || 'Custom' }} {{ 'profile.plan.concurrentChannels' | translate }}
    </li>
    <li *ngIf="userPlan.maxStorageGb">{{ userPlan.maxStorageGb }} {{ 'profile.plan.storageGb' | translate }}</li>
    <li *ngIf="userPlan.isModelling">{{ 'profile.plan.modeling' | translate }}</li>
  </ul>
  <div class="buttons">
    <button class="button" mat-stroked-button color="primary" (click)="contactUs()">
      {{ 'profile.plan.cancelPackage' | translate | uppercase }}
    </button>
    <button class="button" mat-raised-button color="primary" (click)="contactUs()">
      {{ 'profile.plan.changePackage' | translate | uppercase }}
    </button>
  </div>
</div>

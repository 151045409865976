import {Component, Inject} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EVENTS} from '@app/core/services/unleash-analytics.service';

@Component({
  selector: 'app-s3-import-dialog',
  templateUrl: './s3-import-dialog.component.html',
  styleUrls: ['./s3-import-dialog.component.scss']
})
export class S3ImportDialogComponent {
  public events: typeof EVENTS = EVENTS;
  // eslint-disable-next-line max-len
  public s3UriPattern: RegExp = /s3:\/\/([a-zA-Z0-9\-]+)\/([a-zA-Z0-9\-\/_.\s]+\/)$/i;
  public s3ImportForm: UntypedFormGroup = this.formBuilder.group({
    s3Uri: ['', [Validators.required, Validators.pattern(this.s3UriPattern)]]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<S3ImportDialogComponent>
  ) {}

  public importFromS3(): void {
    if (this.s3ImportForm.valid) {
      this.dialogRef.close(this.s3ImportForm.value.s3Uri);
    } else {
      this.s3ImportForm.controls.s3Uri.markAsTouched();
    }
  }
}

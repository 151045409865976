import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RegistrationStage} from '@app/auth/state/auth.state';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {REGISTRATION_STAGE} from '@app/auth/models/auth.models';
import {tap} from 'rxjs/operators';
import {LocalStorageService} from '../../../core/services/local-storage/local-storage.service';
import {
  actionWelcomeAwsTokenSuccess,
  actionYourDeveloperProfileSuccess,
  actionYourProfileError,
  actionYourProfileSuccess
} from './your-profile.actions';

@Injectable()
export class YourProfileEffects {
  profileUpdateSuccessful = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionYourProfileSuccess),
        tap(action => {
          this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.payment);
          this.router.navigate(['/auth/reason']);
        })
      ),
    {dispatch: false}
  );

  profileUpdateError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionYourProfileError),
        tap(action => {
          console.error('Error on profile update', action);
        })
      ),
    {dispatch: false}
  );

  profileUpdateSuccessfulAwsToken = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionWelcomeAwsTokenSuccess),
        tap(action => {
          this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.welcome);
          this.router.navigate(['/auth/welcome']);
        })
      ),
    {dispatch: false}
  );

  developerProfileSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionYourDeveloperProfileSuccess),
        tap(action => {
          this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.developerPayment);
          this.router.navigate(['/auth/developer-payment']);
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private localStorageService: LocalStorageService, private router: Router) {}
}

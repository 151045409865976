<div class="rule-notifications-sidebar">
  <unleash-rule-notifications-sidebar
    [rules]="rules"
    [selectedRule]="selectedRule"
    [menuOptions]="menuOptions"
    [performAnalysisLoading]="performAnalysisLoading"
    (addRule)="emitAddRule()"
    (action)="emitAction($event)"
    (selectedRuleId)="emitSelectedRuleId($event)"
    (back)="emitBack()"
    (analyze)="emitAnalyze()"
  ></unleash-rule-notifications-sidebar>
</div>

<ng-container *ngIf="form && rules && !!selectedRule?.id; else emptySelectedRuleTemplate">
  <div class="main-content">
    <mat-slide-toggle
      class="header__toggle"
      [value]="form.controls?.advancedMode?.value"
      (change)="emitToggleAdvancedMode()"
      >{{ 'live.manage-zones-dialog.notificationsTab.advancedMode' | translate }}</mat-slide-toggle
    >

    <mat-card>
      <mat-card-content>
        <mat-tab-group>
          <mat-tab label="{{ 'live.manage-zones-dialog.notificationsTab.ruleTab.subtitleRule' | translate }}">
            <unleash-rule-tab
              [form]="form"
              [simpleForm]="simpleForm"
              [alerts]="alerts"
              [conditions]="conditions"
              [editMode]="editMode"
              [operators]="operators"
              [zones]="zones"
              [zoneList]="zoneList"
              [coolDownPeriods]="coolDownPeriods"
              [simpleConditions]="simpleConditions"
              [hasToDisplayCoolDown]="hasToDisplayCoolDown"
              [hasToDisableSeverity]="hasToDisableSeverity"
              (zoneChange)="emitZoneChange($event)"
              (conditionChange)="emitConditionChange($event)"
              (valueChange)="emitValueChange()"
              (alertChange)="emitAlertChange($event)"
              (done)="emitEditNameDone($event)"
              (edit)="emitEditName()"
              (delete)="emitDelete($event)"
              (simpleModeChange)="emitSimpleModeChange($event)"
            ></unleash-rule-tab>
          </mat-tab>
          <mat-tab label="{{ 'live.manage-zones-dialog.notificationsTab.notificationChannelTab.title' | translate }}">
            <unleash-notification-channels-tab
              [form]="form.controls.channels"
              (channelChange)="emitValueChange()"
              (openHelp)="emitOpenHelp()"
            ></unleash-notification-channels-tab>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
<ng-template #emptySelectedRuleTemplate>
  <div class="empty-main-content">
    <div>
      <mat-icon class="empty-main-content__icon">settings</mat-icon>
    </div>
    <div class="empty-main-content__description">
      {{ 'live.manage-zones-dialog.notificationsTab.empty.description' | translate }}
    </div>
  </div>
</ng-template>
<ng-template #noNotificationsConfigTemplate>
  <div class="no-notifications-config">
    <div class="no-notifications-config__icon">
      <mat-icon>notifications_off</mat-icon>
    </div>
    <div class="no-notifications-config__title">
      {{ 'live.manage-zones-dialog.notificationsTab.noNotifications.title' | translate }}
    </div>
    <div class="no-notifications-config__description">
      {{ 'live.manage-zones-dialog.notificationsTab.noNotifications.description' | translate }}
    </div>
    <div class="no-notifications-config__actions">
      <button mat-stroked-button color="primary" (click)="emitBack()">
        {{ 'live.manage-zones-dialog.notificationsTab.noNotifications.actions.go_back' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="emitAnalyze()">
        {{ 'live.manage-zones-dialog.notificationsTab.noNotifications.actions.save' | translate }}
      </button>
    </div>
  </div>
</ng-template>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {
  actionForgotPasswordStepTwoFormError,
  actionForgotPasswordStepTwoFormSubmit,
  actionForgotPasswordStepTwoFormSuccess
} from '@app/auth/components/forgot-password-step-two/forgot-password-step-two.actions';
import {ForgotPasswordStepTwo} from '@app/auth/components/forgot-password-step-two/forgot-password-step-two.model';
import {
  selectForgotPasswordStepTwoFormError,
  selectForgotPasswordStepTwoFormInProgress,
  selectForgotPasswordStepTwoFormValue
} from '@app/auth/components/forgot-password-step-two/forgot-password-step-two.selectors';
import {selectForgotPasswordFormValue} from '@app/auth/components/forgot-password/forgot-password.selectors';
import {actionSignInFormSubmit} from '@app/auth/components/sign-in/sign-in.actions';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {actionForgotPasswordFormSubmit} from '@app/auth/components/forgot-password/forgot-password.actions';

@Component({
  templateUrl: './forgot-password-step-two.page.html',
  styleUrls: ['./forgot-password-step-two.page.scss']
})
export class ForgotPasswordStepTwoPage implements OnInit {
  public errorMessage$: Observable<string>;
  public forgotPasswordForm$: Observable<ForgotPasswordStepTwo>;
  public isInProgress$: Observable<boolean>;
  public isDeveloperMode = this.route.snapshot.data.isDeveloperMode;

  constructor(
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.handleFormValues();
    this.initializeFormValues();
  }

  public handleFormValues(): void {
    const email = decodeURIComponent(this.route.snapshot.queryParams['email']);
    const emailIsEmpty = 'undefined';

    if (email !== emailIsEmpty) {
      this.store.dispatch(actionForgotPasswordFormSubmit({form: {email}}));
    }

    this.store.pipe(select(selectForgotPasswordFormValue), take(1)).subscribe(form => {
      if (!form.email) {
        this.goBack();
      }
    });

    this.forgotPasswordForm$ = this.store.pipe(
      select(selectForgotPasswordStepTwoFormValue),
      map((item: ForgotPasswordStepTwo) => {
        const code = this.route.snapshot.queryParams['code'];
        const newForm = {...item};
        if (code) {
          newForm.code = code;
        }

        return newForm;
      })
    );
  }

  public initializeFormValues(): void {
    this.translateService.get('auth.forgot.invalidVerification').subscribe(res => {
      this.errorMessage$ = this.store.pipe(
        select(selectForgotPasswordStepTwoFormError),
        map(error => (error?.code === 'CodeMismatchException' ? res : null))
      );
    });

    this.isInProgress$ = this.store.pipe(select(selectForgotPasswordStepTwoFormInProgress));
  }

  public goBack(): void {
    this.router.navigate([`/auth/${this.isDeveloperMode ? 'developer-' : ''}forgot-password`]);
  }

  public submit(event: ForgotPasswordStepTwo) {
    this.store.dispatch(actionForgotPasswordStepTwoFormSubmit({form: event}));
    this.store.select(selectForgotPasswordFormValue).subscribe(form => {
      Auth.forgotPasswordSubmit(form.email, event.code, event.password)
        .then(() => {
          this.store.dispatch(actionSignInFormSubmit({form: {email: form.email, password: event.password}}));
        })
        .catch((error: AuthErrorModel) => {
          this.store.dispatch(actionForgotPasswordStepTwoFormError({error}));
        });
    });
  }
}

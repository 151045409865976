import {inject, Injectable} from '@angular/core';
import {UserService} from '@app/core/services/api/user.service';
import {ImageResizeService} from '@app/shared/services/upload/image-resize/image-resize.service';
import {Store} from '@ngrx/store';
import {environment} from 'environments/environment';
import {firstValueFrom} from 'rxjs';
import {UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {UploadService} from '@app/shared/services/upload/upload.service';
import {map} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class FlightLogUploadService extends UploadService {
  protected bucket = environment.FLIGHT_LOG_FILE_BUCKET;

  constructor(private userService: UserService) {
    const store = inject(Store);
    const imageResizeService = inject(ImageResizeService);
    const unleashAnalyticsService = inject(UnleashAnalyticsService);

    super(store, imageResizeService, unleashAnalyticsService);
  }

  public getKey(filename: string): Promise<string> {
    return firstValueFrom(
      this.userService.user$.pipe(
        map(
          user =>
            `input/${user.activeTeamAndCompany.companyId}/${user.activeTeamAndCompany.teamId}/${
              user.id
            }/${uuidv4()}/${filename}`
        )
      )
    );
  }
}

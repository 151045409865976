import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {GuardsCheckEnd, Router} from '@angular/router';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {SentryErrorHandler} from '@app/core/services/sentry-error-handler';
import {Store} from '@ngrx/store';
import {Observable, timer} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {SessionExpiredDialog} from './auth/components/session-expired/session-expired.component';
import {AuthService} from './auth/services/auth.service';
import {BannerService} from './core/components/banner/banner.service';
import {RouterFacadeStoreService} from './core/services/api/router-store-facade.service';
import {UserService} from './core/services/api/user.service';
// import 'cookieconsent';
import {BrowserSettingsService} from './core/services/browser-settings.service';
import {HistoryService} from './core/services/history.service';
import {NetworkStatusService} from './core/services/network-status.service';
import {UnleashAnalyticsService} from './core/services/unleash-analytics.service';
import {I18nService} from './shared/i18n/i18n.service';
import {GoogleMapsScriptService} from '@app/core/services/gmaps-script.service';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const {version: appVersion} = require('../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  public isExpiredDialogShowed: boolean = false;
  public isLoading$: Observable<boolean>;

  constructor(
    private store: Store,
    public userService: UserService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private updates: SwUpdate,
    private browserSettingsService: BrowserSettingsService,
    private bannerService: BannerService,
    private dialog: MatDialog,
    private router: Router,
    private networkStatus: NetworkStatusService,
    private matIconRegistry: MatIconRegistry,
    private unleashAnalytics: UnleashAnalyticsService,
    // Auth service is required for authentication callbacks working in whole application
    private authService: AuthService,
    private historyService: HistoryService,
    private i18nService: I18nService,
    // Required to config sentry error handler
    private sentryErrorHandler: SentryErrorHandler,
    private routerFacadeStoreService: RouterFacadeStoreService,
    private gmapsService: GoogleMapsScriptService
  ) {
    this.gmapsService.init();
    this.watchSWUpdate();
    this.watchUnSupportedBrowserMessage();
    this.storeStartUrlHistory();
    // todo display cookie popup only for EU citizens
    // this.showCookieConsent();
    // this.setupGA();
    this.setLanguage();
  }

  public ngOnInit(): void {
    this.removeAriaOwnsBruteForce();
    this.networkStatus.isOnline$.subscribe((isOnline: boolean) => {
      this.displayNetworkStatus(isOnline);
    });
    const color = environment.production ? '#4F86F7' : '#ff000a';
    console.info(`%cUnleash ${environment.name} v${appVersion}`, `color: ${color}`);
    this.routerFacadeStoreService.listenPreviousUrl();
    this.routerFacadeStoreService.listenNavigation();
  }

  // TODO: Remove this  once the bug is fixed
  /**
   * Temporary code to fix Chrome browser crashing.
   * See: https://issues.chromium.org/issues/335553723?pli=1
   */
  private removeAriaOwnsBruteForce() {
    document.addEventListener('DOMNodeInserted', function () {
      const elements = document.querySelectorAll('[aria-owns]');
      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    });
  }

  private storeStartUrlHistory(): void {
    this.router.events.pipe(take(1)).subscribe((event: GuardsCheckEnd) => {
      this.historyService.setStartUrl(event.url);
    });
  }

  /** Display small notification when offline/online */
  private displayNetworkStatus(isOnline: boolean): void {
    if (isOnline) {
      this.snackBar.open('Back online!', null, {
        duration: 3000,
        horizontalPosition: 'left',
        extraClasses: ['short-snackbar']
      } as MatSnackBarConfig);
    } else {
      this.snackBar.open('Offline', null, {
        duration: 3000,
        horizontalPosition: 'left',
        extraClasses: ['short-snackbar']
      } as MatSnackBarConfig);
    }
  }

  private watchSWUpdate(): void {
    const isLocal: boolean = /local/.test(location.host);
    if ('serviceWorker' in navigator && !isLocal) {
      timer(0, 1000 * 60 /* every minute */).subscribe(() => this.updates.checkForUpdate());
      this.updates.versionUpdates
        .pipe(filter((event: VersionEvent) => event?.type === 'VERSION_READY'))
        .subscribe(() => {
          window.caches.open('ngsw:/:db:control').then((cache: Cache) => {
            cache.match(`${window.location.origin}/latest`).then((value: Response) => {
              if (value) {
                value.json().then((res: {latest: boolean}) => {
                  // the following fixes issue mentioned in https://github.com/unleashlive/unleashcloudfront/issues/829
                  if (!res.latest) {
                    cache
                      .delete('/latest')
                      .then((remove: boolean) => console.info('cache storage ngsw /latest removed'));
                  }
                });
              }
            });
          });
          const snackBarRef = this.snackBar.open('A new version of the Unleash live app is available', 'Update now', {
            horizontalPosition: 'left',
            verticalPosition: 'bottom'
          });
          snackBarRef.onAction().subscribe(() => this.updates.activateUpdate().then(() => location.reload()));
        });
    }
  }

  private watchUnSupportedBrowserMessage(): void {
    if (!this.browserSettingsService.getSupportedBrowserFlag() && !this.browserSettingsService.isValidBrowser) {
      this.bannerService.open({
        message: `We noticed you are using ${this.browserSettingsService.browserName} ${this.browserSettingsService.browserVersion}.
        This version may not be fully supported and performance may suffer. Please check our docs for supported browsers or download the latest version of Chrome for optimal experience.`,
        actions: ['Dismiss', 'Download']
      });
      this.bannerService.actionSelected.subscribe((optionNumber: number) => {
        this.browserSettingsService.setSupportedBrowserSeenFlag(true);
        if (optionNumber === 1) {
          window.open('https://www.google.com/chrome/', '_blank').focus();
        }
      });
    }
  }

  private showExpiredDialog(): void {
    this.isExpiredDialogShowed = true;
    this.dialog
      .open(SessionExpiredDialog)
      .afterClosed()
      .subscribe(() => {
        this.router.navigate(['/auth/logout']);
      });
  }

  /* private setupMomentLocale(): void {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'few seconds',
        m: '1 minute',
        mm: '%d minutes',
        h: '1 hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
      }
    });
  } */

  private disableCookies(): void {
    console.info('Disabling cookies');
    // todo find tracking cookies and remove them
  }

  private setLanguage(): void {
    this.i18nService.useUserLanguage();
  }
}

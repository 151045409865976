import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {Marker} from 'leaflet';
import {MissionRoutePoint} from '../../../../services/mission-settings.service';

@Component({
  selector: 'ua-generic-settings',
  templateUrl: './generic-settings.component.html',
  styleUrls: ['./generic-settings.component.scss']
})
export class GenericSettingsComponent implements OnInit, OnChanges {
  @Input() public marker: Marker;
  @Input() public settingsIn: MissionRoutePoint;
  @Output() public settingsOut: EventEmitter<MissionRoutePoint> = new EventEmitter<MissionRoutePoint>();
  public settings: MissionRoutePoint = {} as MissionRoutePoint;
  public aclPermissions = AclPermissions;

  public ngOnInit(): void {
    if (!this.settingsIn) {
      return;
    }

    this.settings = {...this.settingsIn};
  }

  public ngOnChanges() {
    if (!this.settingsIn) {
      return;
    }
    this.settings = {...this.settingsIn};
  }

  public onSubmit(): void {
    this.settingsOut.emit(this.settings);
  }
}

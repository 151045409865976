import {icon} from 'leaflet';

export const createHouseIcon = (selected = false, size = 20, iconColor = '#e53935') => {
  const customSvg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Svg Vector Icons : http://www.onlinewebfonts.com/icon -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg fill='${iconColor}' version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" xml:space="preserve">
<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
<g><g><polygon points="805.8,335.5 805.8,138.3 699.2,138.3 699.2,250.3 500,90.9 10,482.9 144.9,482.9 144.9,909.1 373.7,909.1 373.7,909.1 855.1,909.1 855.1,482.9 990,482.9 "/></g></g>
</svg>`;
  const svgAsURI = 'data:image/svg+xml;base64,' + btoa(customSvg).replace('#', '%23');

  let className = 'house-icon';
  if (selected) { className += ' icon-selected'; }
  return icon({
    iconUrl: svgAsURI,
    iconSize: [size, size],
    className
  });
};

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {icon, LatLngTuple, Marker, marker} from 'leaflet';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {HttpParams} from '@angular/common/http';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-point-map',
  templateUrl: './point-map.component.html',
  styleUrls: ['./point-map.component.scss']
})
export class PointMapComponent implements OnInit {
  public latLng: LatLngTuple;
  public pointMarker: Marker;
  // eslint-disable-next-line rxjs/finnish
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public placeImgUrl: string;
  constructor(private activatedRoute: ActivatedRoute, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      let hasToDisplayIcon = params['hasToDisplayIcon'];

      if (typeof hasToDisplayIcon === 'string') {
        hasToDisplayIcon = hasToDisplayIcon.toLowerCase() === 'true';
      }

      if (params['lat'] && params['lng']) {
        const pinLatLng: LatLngTuple = [parseFloat(params['lat']), parseFloat(params['lng'])];
        this.latLng = pinLatLng;
        this.placeImgUrl = params['imgurl'];
        this.placeImgUrl
          ? this.createShowPhotoMarker(this.latLng, this.placeImgUrl, params['deviceIcon'], hasToDisplayIcon)
          : this.displayDeviceIcon(this.latLng, params['deviceIcon'], hasToDisplayIcon);
      }
      this.initialized$.next(true);
    });
  }

  private displayDeviceIcon(pinLatLng: LatLngTuple, deviceIcon: string, hasToDisplayIcon?: boolean): void {
    const iconSize = 24;
    const iconUrl = this.generateIconUrl(deviceIcon, hasToDisplayIcon);

    let m = marker(pinLatLng, {
      icon: icon({
        iconSize: [iconSize, iconSize],
        popupAnchor: [0, -iconSize],
        iconAnchor: [iconSize / 2, iconSize],
        iconUrl
      })
    });

    this.pointMarker = m;
    m = marker(pinLatLng);
  }

  private createShowPhotoMarker(pinLatLng: LatLngTuple, placeImgUrl, deviceIcon?: string, hasToDisplayIcon?: boolean) {
    this.translateService.get('atlas.pointmap.middlePoint').subscribe(res => {
      const iconSize = 24;
      const iconUrl = this.generateIconUrl(deviceIcon, hasToDisplayIcon);

      const m = marker(pinLatLng, {
        icon: icon({
          iconSize: [iconSize, iconSize],
          popupAnchor: [0, -iconSize],
          iconAnchor: [iconSize / 2, iconSize],
          iconUrl
        }),
        zIndexOffset: 10000
      });
      m.bindPopup(`<img alt="${res}" src="${placeImgUrl}" />`, {
        minWidth: 400,
        closeOnClick: true,
        closeButton: false,
        autoPan: false
      });
      m.on('mouseover', () => {
        m.openPopup();
      });
      m.on('mouseout', () => {
        m.closePopup();
      });
      this.pointMarker = m;
    });
  }

  private generateIconUrl(deviceIcon: string, hasToDisplayIcon: boolean) {
    if (!deviceIcon || hasToDisplayIcon) {
      return 'assets/icons/pin.png';
    }

    const basePath = deviceIcon;
    let params = new HttpParams();
    for (const [k, v] of Object.entries(THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo.imgResolution)) {
      params = params.set(k, v);
    }

    return `${environment.THUMBLER_API_CDN}/${
      THUMBLER_AVAILABLE_CONFIGS.deviceThumbLogo.sourceCategory
    }/${basePath}?${params.toString()}`;
  }
}

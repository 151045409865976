import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  actionNewPasswordFormSubmit,
  actionNewPasswordFormSuccess
} from '@app/auth/components/new-password/new-password.actions';
import {NewPassword} from '@app/auth/components/new-password/new-password.model';
import {
  selectNewPasswordFormInProgress,
  selectNewPasswordFormValue
} from '@app/auth/components/new-password/new-password.selectors';
import {Observable} from 'rxjs';

@Component({
  templateUrl: './new-password.page.html',
  styleUrls: ['./new-password.page.scss']
})
export class NewPasswordPage implements OnInit {
  slideshowItems = [
    {
      backgroundImageSrc: '/assets/images/sign/signup.jpg'
    }
  ];

  newPasswordForm$: Observable<NewPassword>;
  isInProgress$: Observable<boolean>;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.newPasswordForm$ = this.store.pipe(select(selectNewPasswordFormValue));
    this.isInProgress$ = this.store.pipe(select(selectNewPasswordFormInProgress));
  }

  signIn() {
    this.router.navigate(['/auth/sign-in']);
  }

  continue(event: NewPassword) {
    this.store.dispatch(actionNewPasswordFormSubmit({form: event}));
    this.store.dispatch(actionNewPasswordFormSuccess());
  }
}

import {LibraryItem} from '@app/library/models/folder-file.model';
import {createAction, props} from '@ngrx/store';

enum AnalyzeActionTypes {
  OpenAnalyze = '[Analyze] Open analyze Library Item',
  OpenAnalyzeDialog = '[Analyze] Open Analyze Dialog'
}

export const openAnalyze = createAction(AnalyzeActionTypes.OpenAnalyze);

export const openAnalyzeDialog = createAction(
  AnalyzeActionTypes.OpenAnalyzeDialog,
  props<{payload: {libraryItem: LibraryItem}}>()
);

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {ContextMenuComponent} from './context-menu/context-menu.component';

@NgModule({
  declarations: [ContextMenuComponent],
  imports: [CommonModule, MatMenuModule],
  exports: [ContextMenuComponent]
})
export class ContextMenuModule {}

import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {reasonFormSubmit, saveReasonToLocalStorage} from '@app/auth/components/reason/reason.actions';
import {Reason} from '@app/auth/components/reason/reason.model';
import {selectReasonFormValue, selectReasonIsInProgress} from '@app/auth/components/reason/reason.selectors';
import {authLogout} from '@app/auth/state/auth.actions';
import {selectAuthUserInitializationFinished} from '@app/auth/state/auth.selectors';
import {Observable} from 'rxjs';
import {ReasonOption, REASON_OPTIONS} from './reason.config';

@Component({
  templateUrl: './reason.page.html',
  styleUrls: ['./reason.page.scss']
})
export class ReasonPage {
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public reasonForm$: Observable<Reason> = this.store.select(selectReasonFormValue);
  public isInProgress$: Observable<boolean> = this.store.select(selectReasonIsInProgress);
  public isAuthInitialized$: Observable<boolean> = this.store.select(selectAuthUserInitializationFinished);
  public options: ReasonOption[] = REASON_OPTIONS;

  constructor(
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.translateService.get('auth.reason.options').subscribe(translations => {
      const {
        aiPlanDescription,
        aiPlanTitle,
        liveStreamingPlanDescription,
        liveStreamingPlanTitle,
        modellingPlanDescription,
        modellingPlanTitle
      } = translations;

      this.options = [
        {
          title: liveStreamingPlanTitle,
          description: liveStreamingPlanDescription,
          imageSrc: '/assets/icons/onboarding/live-streaming.svg',
          value: 'live-stream'
        },
        {
          title: aiPlanTitle,
          description: aiPlanDescription,
          imageSrc: '/assets/icons/onboarding/ai-insights.svg',
          value: 'ai-insights'
        },
        {
          title: modellingPlanTitle,
          description: modellingPlanDescription,
          imageSrc: '/assets/icons/onboarding/mapping-modeling.svg',
          value: 'mapping'
        }
      ];
    });
  }

  public saveReasonToLocalStorage(reason: Reason): void {
    this.store.dispatch(saveReasonToLocalStorage({reason}));
  }

  public signOut(): void {
    this.store.dispatch(authLogout({isDeveloperMode: this.isDeveloperMode}));
  }

  public continue(reason: Reason): void {
    this.store.dispatch(reasonFormSubmit({form: reason}));
  }

  public back(): void {
    this.router.navigate(['auth/your-profile']);
  }
}
